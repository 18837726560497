import React from "react";
import styled from "styled-components";
import SwipeableDrawerBase, {
  SwipeableDrawerProps,
} from "@material-ui/core/SwipeableDrawer";

import mediaQueries from "themes/mediaQueries";
import { remCalc } from "themes/helpers";
import { variantType } from "./components/HandleBar";

const Drawer = styled.div`
  width: 100%;
`;

const SwipeableDrawer = styled<React.FC<SwipeableDrawerProps>>(
  ({ ...otherProps }) => (
    <SwipeableDrawerBase {...otherProps} classes={{ root: "root" }} />
  )
)``;

const DrawerBackground = styled.div`
  height: 100%;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.background};
  z-index: 1100;


  @media (${mediaQueries.minDesktop}) {
    min-width: ${remCalc(408)};
    /* padding: ${remCalc(12)} ${remCalc(16)}; */
  }
`;

const HandleBar = styled.div<{ variant: variantType }>`
  display: flex;
  justify-content: flex-end;
  width: 100%;

  ${({ variant }) =>
    variant === "detached" &&
    `
    margin-top: 35px;
  `}
`;

const Handle = styled.div<{ variant: variantType }>`
  background-color: ${({ theme }) => theme.masterColors.mediumGrey};
  border-radius: 2.5px;
  height: 5px;
  position: absolute;
  left: 50%;
  top: 16px;
  width: 36px;
`;

const CloseButton = styled.button<{ variant: variantType }>`
  border: none;
  border-radius: 100%;
  height: 28px;
  width: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.masterColors.mediumGrey};
  margin-top: 28px;
  margin-right: 28px;

  &:hover {
    cursor: pointer;
  }

  /* HANDLE VARIATION FOR DETACHED START NEW FORM */
  ${({ variant }) =>
    variant === "detached" &&
    `
    position: absolute;
    top: 0;
    right: 0;
    margin-top: 16px;
    margin-right: 16px;
  `}
`;

const CloseButtonIcon = styled.span<{ className?: string }>`
  color: ${({ theme }) => theme.colors.background};
  font-size: 11px;
`;

export {
  SwipeableDrawer,
  Drawer,
  HandleBar,
  Handle,
  CloseButton,
  CloseButtonIcon,
  DrawerBackground,
};
