import React, { useMemo } from "react";
import moment from "moment";
import styled from "styled-components";

import { ToastChildren, ToastIcon } from "components/common/Toast/styles";
import { prettifyDocumentStatus } from "components/helpers";

const EditLink = styled.a`
  color: ${({ theme }) => theme.masterColors.primary};
  margin-left: 10px;
  text-decoration: underline !important;
  text-transform: uppercase;
`;

const SubmissionStatus = ({ document }) => {
  // if (document.submissionType !== "SUBMIT") return null;

  const submittedAt = moment(document.submissionDate).format("HH:mm M/D/YYYY");

  const documentStatus = prettifyDocumentStatus(document.status);

  const toastVariant = useMemo(() => {
    switch (documentStatus) {
      case "In Progress":
      case "New":
        return "save";
      case "Submitted":
        return "success";
      case "N/A":
        return "error";
      default:
        return "error";
    }
  }, [documentStatus]);

  return (
    <ToastChildren
      variant={toastVariant}
      className="mx-0"
      style={{ padding: "12px 4px", display: "flex", justifyContent: "center" }}
    >
      <ToastIcon
        style={{ marginRight: "0.75rem" }}
        className="icon icon-icons8-checkmark"
      />
      {toastVariant !== "error" ? documentStatus : "N/A status"}{" "}
      {document.formSummary.name} at {submittedAt}
      {!document.readOnly && (
        <EditLink
          onClick={() =>
            window.open(`/document/${document.id}`, "", "target=blank")
          }
        >
          edit
        </EditLink>
      )}
    </ToastChildren>
  );
};

export default SubmissionStatus;
