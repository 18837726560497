import { 
  FBForm,
  FBOEWidget,
  FBQuestion,
  OperationalExperiencesWidgetQuestionDTO,
  FBItem
} from "components/clientAdmin/formBuilder/types";
import { getAllFormItems } from "components/clientAdmin/formBuilder/helpers";

import { Tag } from "./types";
import { TagDTO } from "store/tagLibrary/types";

/**
 * Build a tag object for the OE widget properties
 * @param tag tag title (string)
 * @param included tag is included or not
 * @param excludedClassName className to decorate the excluded items
 */
function buildTag(tag: string, included: boolean, excludedClassName?: string) {
  return {
    title: tag,
    id: Date.now(),
    icon: included ? "delete_sign" : "plus_math",
    className: !included ? excludedClassName : undefined
  };
}

/**
 * get the suppressed tags on the OE widget for a form question
 * @param widgetQuestions OEWidget.questions
 * @param question Form question
 */
function getSuppressedTags(
  widgetQuestions: OperationalExperiencesWidgetQuestionDTO[],
  question: FBQuestion
) {
  return widgetQuestions.find((wQuestion) => wQuestion.questionRootId === question.rootId)?.suppressedTags || [];
}



/**
 * 
 * @param widget 
 * @param question 
 * @param excludedItemClass className to style excluded items
 */
export function renderQuestionTags(
  widget: FBOEWidget,
  question: FBQuestion,
  excludedItemClass?: string
) {
  const tags: TagDTO[] = question.selections?.flatMap((selection) => selection.tags) || [];
  const suppressedTags = getSuppressedTags(widget.questions, question);
  const includedTags = tags.filter((tag) => !suppressedTags.includes(tag.name));
  const builtIncludedTags = includedTags.map((tag: TagDTO) => buildTag(tag.name, true, excludedItemClass));
  const builtSuppressedTags = suppressedTags.map((tag: string) => buildTag(tag, false, excludedItemClass));
  return [...builtIncludedTags, ...builtSuppressedTags];
}

/**
 * Add or remove a tag from an OE Widget question's suppressedTags
 * @param widgetQuestions questions on OEWidget
 * @param question question to update
 * @param tag tag to add/remove
 */
export function suppressOrIncludeOeTag(
  widgetQuestions: OperationalExperiencesWidgetQuestionDTO[], 
  question: FBQuestion, 
  tag: Tag, 
) {
  const tagSuppressed = getSuppressedTags(widgetQuestions, question).includes(tag.title);
  if (tagSuppressed) {
    // remove the tag from the widget question's suppressedTags
    return widgetQuestions.map((wQuestion) => {
      if (wQuestion.questionRootId === question.rootId) {
        const suppressedTags = wQuestion.suppressedTags.filter((sTag) => sTag !== tag.title); 
        return { ...wQuestion,  suppressedTags };
      }
      return wQuestion;
    });
  } else {
    // add the tag to the widget question's suppressedTags
    if (widgetQuestions.find((wQuestion) => wQuestion.questionRootId === question.rootId)) {
      // if the question exists in widget.questions, add the tag to suppressedTags
      return widgetQuestions.map((wQuestion) => {
        if (wQuestion.questionRootId === question.rootId) {
          return { ...wQuestion, suppressedTags: [...wQuestion.suppressedTags, tag.title] };
        } else {
          return wQuestion;
        }
      });
    } else {
      // if the question doesn't exist in the widget.questions, add it along with the tag
      return [
        ...widgetQuestions, 
        {
          questionRootId: question.rootId,
          answerSource: question.answerSource,
          suppressedTags: [tag.title]
        }
      ];
    }
  }
}

/**
 * Get all the questions on the form that have OE tags on selections
 * @param form Form values
 */
export function getQuestionsWithOeTags(form: FBForm) {
  const allFormQuestions: FBItem[] = getAllFormItems(form);
  const questions: FBQuestion[] = allFormQuestions.filter((item) => item.type === "QUESTION") as FBQuestion[];
  return questions.filter((question) => !!question.selections && question.selections.some((selection) => selection.tags.length > 0));
}

/**
 * Check if a question has OE tags associated to its selections
 * @param question 
 */
export function getQuestionHasTags(question: FBQuestion) {
  return question.selections?.some((selection) => selection.tags?.length > 0);
}
