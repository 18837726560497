import { ThemeTypes } from "themes/colors";
import { StyleRules, makeStyles, Theme } from "@material-ui/core";
import { useContext, useMemo } from "react";
import { ThemeContext } from "styled-components";

interface HasTheme {
  theme: ThemeTypes;
}

type PropsWithTheme<Props> = Props & HasTheme;

export type MuiStyles<Props = {}, ClassKey extends string = string> = (
  props: PropsWithTheme<Props>
) => StyleRules<ClassKey, PropsWithTheme<Props>>;

/**
 * This hook provides a convenient interface for 'makeStyles' that passes our props and theme.
 *
 * @param styles A function that takes the theme and props and returns rules to pass to 'makeStyles'.
 * @param props Any properties needed for dynamic styling. These should be memoized so the stylesheet isn't
 *   remade unnecessarily.
 */
export function useMuiStyles<Props>(styles: MuiStyles<Props>, props?: Props) {
  const theme = useContext(ThemeContext);
  const propsWithTheme = {
    theme,
    ...props,
  } as PropsWithTheme<Props>;

  const useStyles = useMemo(() => {
    return makeStyles<Theme, PropsWithTheme<Props>>(styles(propsWithTheme));
  }, [props, theme]);

  return useStyles(propsWithTheme);
}
