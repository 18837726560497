import { LocationResponseLngLat } from "components/FormController/helpers";
import { JSONAddress } from "../components/FormController/types";
import { GeoPoint } from "../store/common/types";

const rootURL = "https://nominatim.openstreetmap.org";

/** Use Geolocation API to get user's current position */
export function getCurrentPosition(): Promise<Position | PositionError> {
  return new Promise((resolve, reject) => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (pos) => resolve(pos),
        (err) => reject(err)
      );
    } else {
      reject(console.error("Error: Geolocation API not available"));
    }
  });
}

/** Convert lat/lng coords into street address */
export async function reverseGeolocation(
  geolocation: GeoPoint
): Promise<JSONAddress> {
  const url = `${rootURL}/reverse?lat=${geolocation.latitude}&lon=${geolocation.longitude}&format=json`;
  let reverseGeo: Response | undefined;
  try {
    reverseGeo = await fetch(url);
  } catch (e) {
    console.error("Error: Geolocation API not available");
  }

  return reverseGeo?.json();
}

/** Test if string is formatted coordinates **/
export function isCoords(string: string) {
  const regMatch = /\s*\(?-?\d+(.\d+)?,\s*-?\d+(.\d+)\)?\s*/;
  return regMatch.test(string);
}

/** Parse the string of format "(<lat>,<lng>)" into numbers **/
export function parseGeolocation(str: string): GeoPoint {
  if (!isCoords(str)) {
    return { latitude: 0, longitude: 0 };
  }
  str = str.replace(/[()\s]/g, "");
  const coords = str.split(",");
  return {
    latitude: parseFloat(coords[0]),
    longitude: parseFloat(coords[1]),
  };
}

/**
 * calculates the distance in KM between the first 2
 * geoLocations in the given array of locations.
 * If only 1 geoLocation exists or any of our
 * values are null then we'll early return null
 */
export function getDistanceBetweenGeoLocations(
  locations: Array<LocationResponseLngLat>
): number | null {
  if (locations.length === 1) {
    return null;
  }

  function deg2rad(deg) {
    return deg * (Math.PI / 180);
  }

  const earthRadius = 6367;

  const [{ lat: lat1, lng: lng1 }, { lat: lat2, lng: lng2 }] = locations;

  if (!lat1 || !lat2 || !lng1 || !lng2) return null;

  const diffLng = deg2rad(lng2 - lng1);
  const diffLat = deg2rad(lat2 - lat1);

  const a =
    Math.sin(diffLat / 2) * Math.sin(diffLat / 2) +
    Math.cos(deg2rad(lat1)) *
      Math.cos(deg2rad(lat2)) *
      Math.sin(diffLng / 2) *
      Math.sin(diffLng / 2);

  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  return earthRadius * c; //distance in km
}

/**
 * Get geo coordinates for a given address string. Returns false on error
 * @param address
 */
export async function geoCode(
  address: string
): Promise<{ geolocation: GeoPoint; address: string } | false> {
  if (address.length < 3) {
    return false;
  }
  if (isCoords(address)) {
    const coords = parseGeolocation(address);
    return {
      geolocation: coords,
      address: address,
    };
  }

  const url = `${rootURL}/search.php?q=${encodeURIComponent(
    address
  )}&format=json`;

  const json = await fetch(url).then((response) => {
    return response.json();
  });

  if (!json.length) {
    return false;
  }

  let closest = json[0];

  // if there is more than one, find the closest
  if (json.length > 1) {
    const position = await getCurrentPosition();
    if ("coords" in position && position.coords) {
      const userLocation = {
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      };
      let closestDist = Number.MAX_VALUE;
      for (const coord of json) {
        const dist = getDistanceBetweenGeoLocations([
          userLocation,
          { lat: parseFloat(coord.lat), lng: parseFloat(coord.lon) },
        ]);
        if (dist && dist < closestDist) {
          closest = coord;
          closestDist = dist;
        }
      }
    }
  }

  return {
    geolocation: {
      latitude: parseFloat(closest.lat),
      longitude: parseFloat(closest.lon),
    },
    address: closest.display_name,
  };
}

/** Format an address returned from reverse geolocation service */
export function formatAddress({ address }: JSONAddress): string | undefined {
  if (!address.house_number || !address.postcode || !address.road) {
    return undefined;
  }
  return `${address.house_number} ${address.road}, ${address.city} ${address.county} ${address.state}`;
}

/** Format a geolocation for display */
export function formatGeolocation(geolocation: GeoPoint): string {
  return `(${geolocation.latitude}, ${geolocation.longitude})`;
}

/**
 * return the center between all given geoLocations
 */
export function getMidPoint(
  locations: Array<LocationResponseLngLat>
): LocationResponseLngLat {
  if (locations.length === 1) {
    return locations[0];
  }

  if (!locations[0].lat && locations[1].lat) {
    return locations[1];
  }

  let x = 0,
    y = 0,
    z = 0;

  for (const location of locations) {
    if (!location.lng || !location.lat) break;

    const latitude = (location.lat * Math.PI) / 180;
    const longitude = (location.lng * Math.PI) / 180;

    x += Math.cos(latitude) * Math.cos(longitude);
    y += Math.cos(latitude) * Math.sin(longitude);
    z += Math.sin(latitude);
  }

  const total = locations.length;

  x = x / total;
  y = y / total;
  z = z / total;

  const centralLongitude = Math.atan2(y, x);
  const centralSquareRoot = Math.sqrt(x * x + y * y);
  const centralLatitude = Math.atan2(z, centralSquareRoot);

  return {
    lat: (centralLatitude * 180) / Math.PI,
    lng: (centralLongitude * 180) / Math.PI,
  };
}

export function calculateZoom(distance: number | null) {
  // closest: 15 furthest: 0
  if (!distance || distance < 1) return 13;

  if (distance < 10) return 10;

  if (distance < 20) return 9;

  if (distance < 50) return 7;

  return 5;
}
