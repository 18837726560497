import React from "react";
import { css } from "aphrodite/no-important";
import { useFormikContext } from "formik";

import { FBForm, FBSection, FBItem } from "../../types";
import { ItemsMutator, SectionsMutator } from "../Create";
import { Placeholder } from "./Placeholder";
import { isFormPublished, scrollToElement } from "../../helpers";
import baseStyles from "./../../styles";
import PublishedBanner from "../PublishedBanner";
import Section from "./Section";
import ValidationBanner from "../validation/ValidationBanner";
import DeactivatedBanner from "../DeactivatedBanner";

interface Props {
  currentItem: FBItem | FBSection | null;
  removeItem: ItemsMutator;
  removeSection: SectionsMutator;
  setCurrentItem: (item: FBItem | FBSection) => void;
  onUpdateItem: (
    item: FBItem | FBSection,
    index?: number
  ) => Promise<FBForm | undefined>;
}

function Content({
  currentItem,
  removeItem,
  removeSection,
  setCurrentItem,
  onUpdateItem,
}: Props) {
  const { values } = useFormikContext<FBForm>();

  const bs = baseStyles();

  /**
   * Swap the position of two items in an array
   * @param item  - item to shift
   * @param pos   - current position of item
   * @param shift - shift direction (e.g. 1 or -1)
   */
  function shiftItems(item: FBItem | FBSection, pos: number, shift: number) {
    onUpdateItem(item, pos + shift);
  }

  /**
   * Increments the index at which an item is positioned in an array
   * @param item    - item to shift
   * @param pos     - item's current index
   * @param arrLen  - length of item's containing array
   */
  function incrementItemIndex(
    item: FBItem | FBSection,
    pos: number,
    arrLen: number
  ): void {
    if (pos >= arrLen - 1) {
      return;
    }
    shiftItems(item, pos, 1);
  }

  /**
   * Decrement the index at which an item is positioned in an array
   * @param item  - item to shift
   * @param pos   - item's current index
   */
  function decrementItemIndex(item: FBItem | FBSection, pos: number): void {
    if (pos <= 0) {
      return;
    }
    shiftItems(item, pos, -1);
  }

  const isPublished = isFormPublished(values.workflowType);

  return (
    <div className={css(bs.content)}>
      <div className={css(bs.contentContainer)}>
        <PublishedBanner visible={isPublished} />
        <DeactivatedBanner visible={values.workflowType === "DEACTIVATED"} />
        <ValidationBanner
          errorType="create"
          onJumpToError={(errorKey, errorItem) => {
            if (errorItem) {
              setCurrentItem(errorItem);
              scrollToElement(errorItem.id.toString());
            }
          }}
        />
        {values.sections.length > 0 ? (
          values.sections.map((s, i) => (
            <Section
              key={`section_${i + 1}_${s.title}`}
              currentItem={currentItem}
              incrementItemIndex={incrementItemIndex}
              decrementItemIndex={decrementItemIndex}
              position={i}
              removeItem={removeItem}
              removeSection={removeSection}
              section={s}
              setCurrentItem={setCurrentItem}
              siblings={values.sections}
            />
          ))
        ) : (
          <Placeholder />
        )}
      </div>
    </div>
  );
}

export default Content;
