import { GET_CLIENT_CONFIG, GET_CLIENT_GROUP_CONFIG, UPDATE_CLIENT_GROUP_CONFIG } from "./types";
import { CALL_API } from "../../middleware/api";

// Get Client config
export const getClientConfig = (keyName: string) => (dispatch) => {
  return dispatch({
    keyName: keyName,
    [CALL_API]: {
      endpoint: `client-group-configs/${keyName}`,
      options: {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      },
      types: [GET_CLIENT_CONFIG.REQUEST, GET_CLIENT_CONFIG.SUCCESS, GET_CLIENT_CONFIG.FAILURE],
    },
  });
};

// Get Client config for group
export const getClientGroupConfig = (groupId, config) => (dispatch, getState) => {
  const { system } = getState();
  return dispatch({
    config,
    [CALL_API]: {
      endpoint: `client-groups/${groupId}/configs/${config}`,
      options: {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${system.authToken}`,
        },
      },
      types: [GET_CLIENT_GROUP_CONFIG.REQUEST, GET_CLIENT_GROUP_CONFIG.SUCCESS, GET_CLIENT_GROUP_CONFIG.FAILURE],
    },
  });
};

// Get Client config for group
export const updateClientGroupConfig = (body) => (dispatch, getState) => {
  const { system } = getState();
  return dispatch({
    [CALL_API]: {
      endpoint: "client-group-configs",
      options: {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${system.authToken}`,
        },
        body: JSON.stringify(body),
      },
      types: [
        UPDATE_CLIENT_GROUP_CONFIG.REQUEST,
        UPDATE_CLIENT_GROUP_CONFIG.SUCCESS,
        UPDATE_CLIENT_GROUP_CONFIG.FAILURE,
      ],
    },
  });
};
