import * as React from "react";
import Tab from "@material-ui/core/Tab";
import TabsBase from "@material-ui/core/Tabs";
import Typography from "@material-ui/core/Typography";
import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";
import { withTheme } from "styled-components";

const useStyles = (props) => {
  const { colors } = props;
  return makeStyles({
    indicator: {
      backgroundColor: colors.primary,
    },
  })(props);
};

// Tabs doesn't need to be a styled component but no extra time to convert, so leaving it like that for now.
const Tabs: any = withTheme(styled((props) => {
  const { ...classes } = useStyles({
    colors: {
      primary: props.theme.colors.primary,
    },
  });
  return <TabsBase classes={classes} {...props} />;
})`
  border-bottom: solid 1px ${({ theme }) => theme.masterColors.lightGrey};

  // TODO Ugly selector -- how else can I style this indicator with our primary theme color?
  [role="tablist"] > span {
    background-color: ${({ theme }) => theme.colors.primary};
  }

  [role="tab"] {
    min-width: 120px;
    outline: none !important;
  }

  [aria-selected="true"] {
    color: ${({ theme }) => theme.colors.primary};
  }
`);

interface TabPanelProps {
  children: any;
  index: number;
  value: number;
}

const TabPanel = ({ children, value, index, ...other }: TabPanelProps) => {
  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {children}
    </Typography>
  );
};

const tabA11yProps = (index) => {
  return {
    id: `tab-${index}`,
    "aria-controls": `tabpanel-${index}`,
  };
};

export { Tabs, TabPanel, Tab, tabA11yProps };
