import React, { FC } from "react";
import { StyleSheet, css } from "aphrodite";

import {
  Autocomplete,
  AutocompleteSuggestion,
} from "components/forms/Autocomplete";
import {
  getSubOrdinatesOptions,
  mapFormTypesToFilter,
  timeFilterOptions,
  TimeFilterType,
} from "components/helpers/filters";
import { FormTypeDTO } from "store/forms/types";
import { SearchDocumentsParams, Owners } from "store/documents/types";
import Select from "components/common/form/Select";

import * as S from "../styles";
import { DateRange } from "components/common/DateRange";
import { useSelector } from "react-redux";
import { documentsFiltersSelector } from "store/filters/documents/selectors";
import moment from "moment";
import { participantTreeOptionsSelector } from "store/participantTreeFilter/selectors";

const styles = StyleSheet.create({
  dateRange: {
    alignItems: "flex-start",
    display: "flex",
    flexDirection: "column",
    marginBottom: "16px",
  },
  dateRangeLabel: {
    color: "#181818",
    fontSize: "0.875rem",
    marginBottom: 0,
    marginTop: "16px",
  },
});

interface Props {
  documentType: number;
  formTypes: Array<FormTypeDTO>;
  getOwnerIds: (q?: string) => Promise<Owners>;
  handleApplySearchFilters: () => void;
  handleTempFilterChange: (name: any, value: any) => void;
  handleTempOwnerIdChange: (ownerId: number | null) => void;
  mapSuggestions: (suggestions: Owners) => AutocompleteSuggestion[] | undefined;
  query: string;
  ownerIds: Owners;
  resetOwnerIds: () => void;
  searchDocuments: (props: SearchDocumentsParams) => void;
  tempDocumentOwner: number | null;
  tempDocumentType: number;
  tempFilterDocumentsLength?: number;
  tempSubordinatesFilter: string;
  // Date range filter
  tempTimeFilterType: TimeFilterType;
}

const MobileSearchFilterSidebar: FC<Props> = ({
  formTypes,
  getOwnerIds,
  handleApplySearchFilters,
  handleTempFilterChange,
  handleTempOwnerIdChange,
  mapSuggestions,
  tempDocumentType,
  tempFilterDocumentsLength,
  tempSubordinatesFilter,
  // Date range filter
  tempTimeFilterType,
  ownerIds,
  resetOwnerIds,
}) => {
  const filtersSelect = useSelector(documentsFiltersSelector);
  const participantTreeOptions = useSelector(participantTreeOptionsSelector);
  const subordinatesOptions = getSubOrdinatesOptions(participantTreeOptions);
  const { filterParams } = filtersSelect;

  const initialCustomDates = filterParams.dateRange
    ? {
        // @ts-ignore
        from: moment(filterParams.dateRange?.startDate),
        // @ts-ignore
        to: moment(filterParams.dateRange?.endDate),
      }
    : {};

  return (
    <S.MobileSearchFilterSidebar>
      <S.WrapperForNegMargin>
        <S.MobileFiltersHeader>Search Filters</S.MobileFiltersHeader>
        <S.DividerNegMargin />
        {/* Document Type section */}
        <S.FilterSectionHeader>Document Type</S.FilterSectionHeader>
        <S.FilterOption>
          <Select
            active={tempDocumentType !== -1}
            name="tempDocumentType"
            onChange={(e) =>
              handleTempFilterChange("tempDocumentType", e.target.value)
            }
            options={mapFormTypesToFilter(formTypes)}
            value={tempDocumentType}
          />
        </S.FilterOption>
        <S.DividerNegMargin />
        {/* Date Range section */}
        <S.FilterSectionHeader>Date Range</S.FilterSectionHeader>
        <S.FilterOption>
          <Select
            active={tempTimeFilterType !== "ALL_TIME"}
            name="tempTimeFilterType"
            onChange={(e) =>
              handleTempFilterChange("tempTimeFilterType", e.target.value)
            }
            options={timeFilterOptions}
            value={tempTimeFilterType}
          />
          {tempTimeFilterType === "CUSTOM_RANGE" && (
            <DateRange
              className={css(styles.dateRange)}
              handleChange={(value) =>
                handleTempFilterChange("customRange", value)
              }
              labelId="custom-range-label"
              name="customRange"
              labels={{
                labelFrom: {
                  text: "From",
                  className: css(styles.dateRangeLabel),
                },
                labelTo: {
                  text: "To",
                  className: css(styles.dateRangeLabel),
                },
              }}
              initialValues={initialCustomDates}
            />
          )}
        </S.FilterOption>
        <S.DividerNegMargin />
        {/* Subordinates Filter section */}
        {!participantTreeOptions.hideFilter && (
          <>
            <S.FilterSectionHeader>Subordinates Filter</S.FilterSectionHeader>
            <S.FilterOption>
              <Select
                active={tempSubordinatesFilter !== "ALL_USERS"}
                name="subordinatesFilter"
                onChange={(e) =>
                  handleTempFilterChange(
                    "tempSubordinatesFilter",
                    e.target.value
                  )
                }
                options={subordinatesOptions}
                value={tempSubordinatesFilter}
              />
            </S.FilterOption>
            <S.DividerNegMargin />
          </>
        )}
        {/* Document Owner section */}
        <S.FilterSectionHeader>Document Owner</S.FilterSectionHeader>
        <S.FilterOption>
          <Autocomplete
            handleOwnerIdChange={handleTempOwnerIdChange}
            mobile
            requestOptions={(q?: string) => {
              return getOwnerIds(q);
            }}
            resetSuggestions={resetOwnerIds}
            suggestions={mapSuggestions(ownerIds)}
            documentOwner={filterParams.documentOwner}
          />
        </S.FilterOption>
        <S.DividerNegMargin />
        <S.ApplyButtonWrapper>
          <S.ApplyButton onClick={() => handleApplySearchFilters()}>
            Show Results ({tempFilterDocumentsLength})
          </S.ApplyButton>
        </S.ApplyButtonWrapper>
      </S.WrapperForNegMargin>
    </S.MobileSearchFilterSidebar>
  );
};

export default MobileSearchFilterSidebar;
