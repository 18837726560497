import React, { Dispatch, SetStateAction } from "react";

import Drawer from "components/common/Drawer";
import { DocumentSummary, DocumentVM } from "store/documents/types";
import ShareDocumentSidebarContent from 
  "components/screens/documents/components/ShareDocumentSidebar/ShareDocumentSidebar";

interface Props {
  isDesktop?: boolean;
  isOpen: boolean;
  selectedDocument: DocumentSummary | DocumentVM | null;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}

const ShareDocumentSidebar = ({ selectedDocument, isOpen, setIsOpen, isDesktop }: Props) => {
  return (
    <Drawer
      content={
        <ShareDocumentSidebarContent
          selectedDocument={selectedDocument}
          shareDocumentVisible={isOpen}
          toggleShareDocumentVisibility={() => setIsOpen(false)}
        />
      }
      anchor="right"
      disableBackdrop
      paperProps={{ elevation: 0 }}
      id="ShareDocumentSidebar"
      onClose={() => setIsOpen(false)}
      // onOpen={() => {}}
      open={isOpen}
      overrideClasses={{
        container: "drawerContainerNoBackdrop",
        content: isDesktop ? "myDocumentsDrawerDesktop" : "",
      }}
      showCloseIcon={false}
    />
  );
};

export default ShareDocumentSidebar;
