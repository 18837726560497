import React from "react";
import { css } from "aphrodite/no-important";
import { Field, useFormikContext } from "formik";
import { get } from "lodash";

import {
  FBForm,
  Property as PropertyType,
  FBItem,
  FBSection,
  OperationalExperiencesWidgetQuestionDTO,
  FBQuestion,
} from "../../../../types";
import AnswerOptions from "../AnswerOptions";
import AreasToReview from "../AreasToReview/AreasToReview";
import styles from "../../styles";
import Tags from "../Tags";
import Property from "../../Property";
import { DataSource } from "store/dataSource/types";
import { NewSelection } from "store/builder/types";
import { isFormPublished } from "components/clientAdmin/formBuilder/helpers";

interface Props {
  itemType: string;
  itemPath: string;
  property: PropertyType;
  onUpdateItem: (item: FBItem | FBSection) => Promise<FBForm | undefined>;
  item: FBItem;
  dataSource?: DataSource;
}

const Selections = ({
  item,
  property,
  itemType,
  itemPath,
  onUpdateItem,
  dataSource,
}: Props) => {
  const { values, setFieldValue, errors, touched } = useFormikContext<FBForm>();
  const s = styles();
  const isPublished = isFormPublished(values.workflowType);

  const propertyPath = `${itemPath}.${property.name}`;

  async function handleUpdateOptions(options: NewSelection[]) {
    // if an option has been marked as commentRequired, make sure comments are enabled for the question
    const properties = item.properties ? { ...item.properties } : {};
    if (
      property.name === "selections" &&
      Array.isArray(options) &&
      options.find((s) => s.properties?.commentRequired)
    ) {
      properties.enableComments = true;
    }

    // if an option has defenses, set hasDefenses: true on the question
    const hasDefenses = options.some((option) => option.defenseIds.length > 0);

    // normal update
    const res = await onUpdateItem({
      ...item,
      hasDefenses,
      properties,
      [property.name]: options,
    } as FBQuestion);
    return get(res, `${itemPath}.${property.name}`, []);
  }

  const selectionDrawer = [
    "MULTI_SELECT",
    "CHECKBOX",
    "DROP_DOWN",
    "RADIO_BUTTONS",
  ].includes(item.subType);

  function renderSelectionsInput() {
    switch (itemType) {
      case "SAFETY_RATING":
        return (
          <Field
            label={property.label}
            helperText={property.helperText}
            as={AreasToReview}
            name={propertyPath}
            item={get(values, `${itemPath}`)}
            onUpdate={(options) => setFieldValue(propertyPath, options, false)}
            areas={get(values, propertyPath, [])}
            error={get(errors, propertyPath)}
            touched={get(touched, propertyPath)}
          />
        );
      case "OPERATIONAL_EXPERIENCES":
        return (
          <Field
            label={property.label}
            helperText={property.helperText}
            as={Tags}
            name={propertyPath}
            item={get(values, `${itemPath}`)}
            onUpdate={(options: OperationalExperiencesWidgetQuestionDTO[]) =>
              setFieldValue(propertyPath, options, false)
            }
            disabled={isPublished}
          />
        );
      default:
        return (
          <Field
            as={AnswerOptions}
            label={property.label}
            helperText={property.helperText}
            name={propertyPath}
            itemPath={itemPath}
            itemRootId={item.rootId}
            detailedSearchProps={property.detailedSearchProps}
            selectedValues={get(values, propertyPath, [])}
            onOptionUpdate={handleUpdateOptions}
            error={get(errors, propertyPath)}
            touched={get(touched, propertyPath)}
          />
        );
    }
  }

  return (
    <div className={css(s.selections)}>
      {renderSelectionsInput()}
      {!selectionDrawer &&
        item.answerSource &&
        property.detailedSearchProps?.map((dsp) => (
          <Property
            itemPath={itemPath}
            dataSource={dataSource}
            key={`${itemPath}.${dsp.name}`}
            property={dsp}
            item={item}
          />
        ))}
    </div>
  );
};

export default Selections;
