import { DataSourceState, DataSourceValueDTO } from "./types";
import { AppState } from "store";

export const getDataSources = (store: AppState) => store.dataSource.data;
export const getDataSourceErrors = (store: AppState) => store.dataSource.error;
export const getIsLastDSPage = (store: AppState) => store.dataSource.isLastPage;
export const getLastDSPageLoaded = (store: AppState) => store.dataSource.lastPageLoaded;

interface ValuesForDataSourceKeyArgs {
  dataSourceKey?: string | null;
  dataSourceKeys?: Array<string>;
  state: DataSourceState;
}

/**
 * selector which parses the matching array of values within
 * the DataSource store by finding the `dataSourceKey` which matches
 *
 * @param dataSourceKey
 * @param state
 */
export const valuesForDataSourceKey = ({
  dataSourceKey,
  state,
}: ValuesForDataSourceKeyArgs): Array<DataSourceValueDTO> => {
  if (!dataSourceKey) {
    return [];
  }

  const values: Array<DataSourceValueDTO> = state.data[dataSourceKey];

  if (values && values.length > 0) {
    return values;
  }

  return [];
};

interface ValuesForDataSourceKeysArgs {
  dataSourceKeys?: Array<string>;
  state: DataSourceState;
}

export const valuesForMultipleDataSourceKeys = ({
  dataSourceKeys,
  state,
}: ValuesForDataSourceKeysArgs): {
  [key: string]: Array<DataSourceValueDTO>;
} => {
  if (!dataSourceKeys || dataSourceKeys.length === 0) {
    return {};
  }
  const responseObject = {};

  dataSourceKeys.map((k) => (responseObject[k] = state.data[k]));

  return responseObject;
};
