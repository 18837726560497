import { isNil, reduce } from "lodash";

import { FilterParams } from "./types";

/**
 * Construct a querystring for a given endpoint
 * @param baseEndpoint
 * @param filterParams
 * TODO update when SortParams changes -JA
 */
export function buildFilteredEndpoint(baseEndpoint: string, filterParams?: FilterParams) {
  if (filterParams) {
    const p = reduce(filterParams, (result, v, k) => {
      if (k === "sort" && Array.isArray(v)) {
        // handle multiple sorts
        let sortResult = "";
        v.forEach((s) => sortResult = `${sortResult}&${k}=${s.replace("*","")}`);
        return `${result}&${sortResult.replace("&", "")}`;
      }
      if (!isNil(v) && v !== "" && !Array.isArray(v)) {
        const strippedValue = typeof v === "string" ? v.replace("*", "") : v;
        return `${result}&${k}=${encodeURIComponent(strippedValue)}`;
      }
      return result;
    }, "");
    return `${baseEndpoint}?${p.replace("&", "")}`;
  }
  return baseEndpoint;
}

/**
 * Filter an object of filter params by a value
 * @param filterParams Object of filters
 * @param value value used to filter - any key with this value will be removed from the object
 */
export function removeFiltersByValue(filterParams: FilterParams, value: string | number) {
  const p = reduce(filterParams, (result, val, key) => {
    if (val !== value) {
      result[key] = val;
    }
    return result;
  }, {});
  return p;
}
