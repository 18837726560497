import styled from "styled-components";

import { remCalc } from "themes/helpers";
import TagBase from "components/common/Tag";

export const DesktopHeader = styled.div.attrs({
  className: "fixed-top",
})`
  display: flex;
  justify-content: space-between;
  align-items: center;

  background: ${(props) => props.theme.colors.background};
  box-shadow: ${(props) => props.theme.masterColors.lightGrey} 0px 1px 3px;

  margin-bottom: 8px;

  padding-top: ${remCalc(16)};
  padding-bottom: ${remCalc(16)};
  padding-left: 30px;
  padding-right: 30px;
`;

export const MobileHeader = styled.div.attrs({})`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: ${remCalc(16)};
  background: ${(props) => props.theme.colors.background};
`;

// DocumentInfoDrawer

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${remCalc(0)};
`;

export const DocumentInfoDrawer = styled.div`
  padding: ${remCalc(17)} ${remCalc(16)};
  min-width: ${remCalc(304)};
`;

export const DocumentHeader = styled.div`
  display: flex;
  flex-direction: column;
`;
export const Title = styled.h6`
  font-size: ${remCalc(20)};
  font-weight: 500;
  margin-right: ${remCalc(8)};
`;
export const SubTitle = styled.span`
  font-size: ${remCalc(16)};
  font-weight: 400;
`;

export const ListItemText = styled.span`
  font-size: ${remCalc(14)};
  color: ${({ theme }) => theme.masterColors.darkGrey};
`;

export const FadedText = styled.span`
  color: ${({ theme }) => theme.masterColors.darkGrey};
`;

export const Tag = styled(TagBase)`
  margin-right: ${remCalc(46)};
  margin-bottom: ${remCalc(10)};
  max-height: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
