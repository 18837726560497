import React from "react";

import * as S from "../styles";
import useGroupTerm from "util/hooks/whiteLabel/useGroupTerm";

interface Props {
  availableOEs: number;
  minimumIncluded: boolean;
  requiredOEs: number;
  requiredText: string;
}

function OEHeader({
  availableOEs,
  minimumIncluded,
  requiredOEs,
  requiredText
}: Props) {
  // Group config terms
  const operationalExperiencesTerm = useGroupTerm(
    "operationalExperiences",
    "noun",
    "plural",
    "Operational Experiences"
  );
  return (
    <S.OperationalExperienceHeader>

      <S.OEHeaderTitle as="h3">
        {operationalExperiencesTerm}
      </S.OEHeaderTitle>

      {availableOEs > 0 ? (
        <>
          <span>
            Select {requiredOEs} real scenarios below to discuss with your team. After each
            discussion, select <em><b>Include</b></em> to include it in this
            form. If the displayed cards aren’t relevant, you can&nbsp;
            <em><b>Reshuffle</b></em> to view more scenarios.
          </span>
          <S.RequiredText success={minimumIncluded}>
            {requiredText}
          </S.RequiredText>
        </>
      ) : (
        <span>
          Please make your task selections first and then we’ll provide&nbsp;
          {operationalExperiencesTerm} to talk through with your team
        </span>
      )}

    </S.OperationalExperienceHeader>
  );
}

export default OEHeader;
