import React, { useContext } from "react";
import styled, { ThemeContext } from "styled-components";

import Icon from "components/common/Icon";
import { DocumentDrawerSections } from "../DocumentDrawer";
import { ThemeTypes } from "themes/colors";
import { remCalc } from "themes/helpers";

const ActionBarContainer = styled.div.attrs({ className: "p-2 d-flex" })`
  background: ${(props) => props.theme.colors.primary};
  color: ${(props) => props.theme.colors.white};
  font-size: ${remCalc(12)};

  > a {
    border-right: solid 1px ${({ theme }) => theme.colors.lightGrey};
    color: ${(props) => props.theme.colors.white};
    text-decoration: none;
    cursor: pointer;
    &:last-child {
      border-right: none;
    }
  }
`;

interface WhiteIconProps {
  theme: ThemeTypes;
  type: string;
}

const WhiteIcon = styled<React.FC<WhiteIconProps>>(({ type }) => {
  const theme = useContext(ThemeContext);
  return (
    <Icon
      className="d-block"
      type={type}
      color={theme.colors.white}
      framed={false}
    />
  );
})``;

interface ActionBarProps {
  setShowing: (bool) => void;
}

const ActionBar = ({
  setShowing,
}: ActionBarProps) => {
  return (
    <ActionBarContainer style={{ marginLeft: "-10px", marginRight: "-10px" }}>
      <a className="flex-grow-1" onClick={() => setShowing(DocumentDrawerSections.Document)}>
        <WhiteIcon type="document" />
        View Document
      </a>
      <a className="flex-grow-1" onClick={() => setShowing(DocumentDrawerSections.History)}>
        <WhiteIcon type="documents" />
        Document History
      </a>
      <a className="flex-grow-1" onClick={() => setShowing(DocumentDrawerSections.Share)}>
        <WhiteIcon type="icon-share" />
        Share
      </a>
    </ActionBarContainer>
  );
};

export default ActionBar;
