import React from "react";

import { ProfileOptionField as Props } from "../types";
import Locked from "../Locked";
import RenderGroups from "../RenderGroups";

import DesktopOptionField from "./DesktopOptionField";

const ProfileOptionField = ({ isDesktop, locked, label, value }: Props) => {
  // @TODO: Add logic to nav to view
  // dedicated to selecting new option
  const onClick = () => {
    if (locked) return null;
  };

  if (isDesktop && label) {
    return <DesktopOptionField groups={value} label={label} name="test" locked={locked} />;
  }

  if (locked) {
    return <Locked label={label} lockedValue={value} />;
  }

  return <RenderGroups onClick={onClick} values={value} label={label} />;
};

export default ProfileOptionField;
