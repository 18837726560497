import React, { useState, useMemo } from "react";
import styled from "styled-components";

import Popper from "components/common/Popper";
import { MoreVertIcon } from "components/common/Card/styles";
import { TagDTO } from "store/tagLibrary/types";
import { prettifyTagStatusMenuItem } from "../helpers";

// Resources
import { PopperChevron, PopperRow, PopperWrapper } from "components/clientAdmin/resources/styles";

const ChangeTagStatusItem = styled.span`
  color: ${({ theme }) => theme.colors.error};
`;


interface Props {
  onTagStatusUpdate: (tag: TagDTO) => void;
  tag: TagDTO;
}

const TagActionMenu = ({ onTagStatusUpdate, tag, }: Props) => {
  const [popperIsOpen, setPopperIsOpen] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState(null);
  // const history = useHistory();

  const handleClose = () => {
    if (popperIsOpen) {
      setPopperIsOpen(false);
    }
  };

  const handleOpen = (e) => {
    setAnchorEl(e.currentTarget);
    setTimeout(() => setPopperIsOpen(true));
  };

  // Archive or Activate tag menu item label
  const statusMenuLabel = useMemo(() => prettifyTagStatusMenuItem(tag.archived), [tag.archived]);

  return (
    <>
      <MoreVertIcon onClick={handleOpen} />
      <Popper anchorEl={anchorEl} onClose={handleClose} open={popperIsOpen} placement="left">
        <PopperWrapper>
          {/* <PopperRow onClick={() => history.push(`/content/tag-library/tag/${tag.id}`)}>
            <span>Edit Tag</span>
            <PopperChevron />
          </PopperRow> */}
          <PopperRow onClick={() => onTagStatusUpdate(tag)}>
            <ChangeTagStatusItem>{statusMenuLabel}</ChangeTagStatusItem>
            <PopperChevron />
          </PopperRow>
        </PopperWrapper>
      </Popper>
    </>
  );
};

export default TagActionMenu;
