import React from "react";
import InputBase from "@material-ui/core/Input";
import MenuItemBase from "@material-ui/core/MenuItem";
import MenuListBase from "@material-ui/core/MenuList";
import PopperBase from "@material-ui/core/Popper";
import styled from "styled-components";

import { remCalc } from "themes/helpers";
import ListItemBase from "components/common/ListItem";

export const MultiInput = styled.div``;

export const MultiInputContent = styled.div<{
  error?: boolean;
  ref?: any;
  disabled?: boolean;
}>`
  background-color: ${({ theme }) => theme.colors.fieldInputBackground};
  border-radius: ${remCalc(4)};
  border: 1px solid
    ${({ theme, error }) =>
      error ? theme.colors.error : theme.masterColors.darkGrey};
  background: ${({ error, disabled, theme }) =>
    error
      ? theme.colors.errorFill
      : disabled
      ? theme.colors.lightGrey
      : theme.colors.fieldInputBackground};
  border-radius: 0.25rem;
`;

export const Input = styled(InputBase)``;

export const Popper = styled(({ ...props }) => (
  <PopperBase {...props} classes={{ root: "root" }} />
))`
  z-index: 1200; // 1200 to get it over the Material UI Drawer
  max-height: 100px;
  && .root {
    background: ${({ theme }) => theme.colors.white};
  }
`;

export const Options = styled(({ parentWidth, ...props }) => (
  <MenuListBase
    {...props}
    classes={{
      root: "root",
    }}
  />
))`
  &&.root {
    width: ${({ parentWidth }) =>
      parentWidth ? remCalc(parentWidth) : "100%"};
    box-shadow: 0px 1px 2px ${({ theme }) => theme.masterColors.mediumGrey};
    border-radius: ${remCalc(4)};
    max-height: inherit;
    overflow-y: scroll;
    overflow-x: none;
  }
`;

export const Option = styled(({ ...props }) => (
  <MenuItemBase
    {...props}
    classes={{
      root: "root",
      selected: "selected",
    }}
  />
))`
  &&.root {
    // z-index: 1000000000000; // @TODO lol what? remove this or update it to a logical value if we still need it. z-index has been updated on Popper instead
    &:hover {
      background: ${({ theme }) => theme.colors.activeFill};
    }
  }
`;

export const ListItems = styled.ul`
  padding: ${remCalc(12)};
  margin: 0;
  /* width: 100%; */
`;

export const ListItemOption = styled(ListItemBase)`
  margin-bottom: ${remCalc(3)};
`;

export const IconForEndAdornment = styled.i`
  color: ${({ theme }) => theme.masterColors.darkGrey};
  &:hover {
    cursor: pointer;
  }
`;
