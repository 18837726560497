import React, { RefObject } from "react";

import * as newDocumentActions from "store/newDocument/actions";
import Toast from "components/common/Toast";
import usePrevious from "util/hooks/usePrevious";

interface Props {
  errorKeys: Array<string>;
  itemRefs: { [key: string]: RefObject<HTMLElement> };
  orderedItemIds: number[];
  submissionType?: newDocumentActions.SubmissionType;
  submitCount: number;
}

// safe wrapper of window.scrollTo
const scrollToError = (targetScrollLocation?: number) => {
  if (!targetScrollLocation) {
    return;
  }
  // Chrome is too fast for the scroll handler for we need this timeout - MW 6/15/22
  // https://stackoverflow.com/a/15694294
  setTimeout(() => {
    window.scroll({
      top: targetScrollLocation,
      behavior: "smooth",
    });
  }, 10);
};

/**
 * **ErrorScroll** is a react component in charge of handling
 * scrolling to the first error in a document.
 */
const ErrorScroll = ({
  errorKeys,
  itemRefs,
  orderedItemIds,
  submissionType,
  submitCount,
}: Props) => {
  /** only want to autoscroll when they hit the submit button (can still manually by clicking toast) */
  const [hasScrolled, setHasScrolled] = React.useState<boolean>(false);
  const prevCount = usePrevious(submitCount);

  React.useEffect(() => {
    if (submissionType !== "SUBMIT") {
      setHasScrolled(false);
    }
  }, [submissionType]);

  /** memo to calculate target scroll location */
  const targetScrollLocation: number | undefined = React.useMemo(() => {
    if (!errorKeys.length) {
      return undefined;
    }

    for (const id of orderedItemIds) {
      const ref = itemRefs[id];
      if (!(ref && ref.current && ref.current.getBoundingClientRect)) {
        continue;
      }
      // find any error key that starts with the item id
      if (errorKeys.some((e) => e.replace(/_.*/, "") === String(id))) {
        return (
          ref.current.getBoundingClientRect().top + window.pageYOffset - 200
        );
      }
    }

    return undefined;
  }, [errorKeys, orderedItemIds, itemRefs]);

  React.useEffect(() => {
    if (
      submissionType === "SUBMIT" &&
      targetScrollLocation &&
      (!hasScrolled || submitCount !== prevCount)
    ) {
      scrollToError(targetScrollLocation);
      setHasScrolled(true);
    }
  }, [targetScrollLocation, submissionType, submitCount]);

  if (errorKeys.length) {
    return (
      <Toast
        visible={true}
        onClick={() => scrollToError(targetScrollLocation)}
        variant="error"
      >
        Form is incomplete. See highlighted details below.
      </Toast>
    );
  }

  return null;
};

export default ErrorScroll;
