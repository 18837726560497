import React from "react";

import { MenuDivider as MenuDividerType } from "../types";
import * as S from "../styles";
interface Props {
  menuDivider: MenuDividerType;
}

const MenuDivider = ({ menuDivider: { title } }: Props) => {
  return (
    <S.MenuDivider>
      <S.DividerTitle as="p">{title}</S.DividerTitle>
    </S.MenuDivider>
  );
};

export default MenuDivider;
