import {
  ParticipantConfigsState,
  GET_PARTICIPANT_CONFIG,
  ParticipantConfigsActionTypes,
  POST_PARTICIPANT_CONFIG,
} from "./types";

const initialState: ParticipantConfigsState = {
  data: {},
};

export function participantConfigsReducer(
  state = initialState,
  action: ParticipantConfigsActionTypes,
): ParticipantConfigsState {
  const { active, keyName, participantId, properties } = action;
  switch (action.type) {
    case GET_PARTICIPANT_CONFIG.SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          [action.keyName]: action.response,
        },
      };
    case GET_PARTICIPANT_CONFIG.FAILURE:
      if (action.keyName === "tutorial") {
        return {
          ...state,
          data: {
            ...state.data,
            tutorial: {
              properties: {
                showTutorial: true
              },
            }
          },
        };
      }
      return state;
    case POST_PARTICIPANT_CONFIG.SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          [action.keyName]: {
            active,
            keyName,
            participantId,
            properties,
          },
        },
      };
    default:
      return state;
  }
}
