import React from "react";
import { useMediaQuery } from "react-responsive";

import { devices } from "themes/mediaQueries";

import { ProfileFieldTextField as Props } from "../../types";

import DesktopTextField from "./DesktopTextField";
import * as S from "./styles";

const ProfileFieldTextField = (props: Props) => {
  const isDesktop = useMediaQuery({
    minWidth: devices.minDesktop,
  });

  if (isDesktop) {
    return <DesktopTextField {...props} />;
  }

  return <S.TextField {...props} />;
};

export default ProfileFieldTextField;
