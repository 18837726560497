import React from "react";
import styled from "styled-components";

import { ThemeTypes } from "themes/colors";
import { remCalc } from "themes/helpers";
import mediaQueries from "themes/mediaQueries";

import { Props as HeaderProps } from "./Heading";

export const Header = styled(({ as: Element, variant, ...props }: HeaderProps) => <Element {...props} />)`
  font-family: "Roboto", sans-serif;
  color: ${({ theme }: { theme: ThemeTypes }) => theme.colors.black};

  ${({ variant }) => {
    if (variant === "section-title") {
      return `
        font-size: ${remCalc(36)};
        margin-bottom: ${remCalc(21)};
        font-weight: 400;

        @media(${mediaQueries.maxPhone}) {
          font-size: ${remCalc(24)};
        }
      `;
    }
  }}
`;
