import React, { useState } from "react";
import { useMediaQuery } from "react-responsive";
import { useSelector } from "react-redux";

import { devices } from "themes/mediaQueries";
import { getUserProfile } from "store/user/selectors";
import topography from "assets/svg/topography.svg";
import Star from "components/common/Icon/Star";

import * as S from "./styles";
import useGroupTerm from "util/hooks/whiteLabel/useGroupTerm";

export interface UserForm {
  count: number;
  icon?: string | null;
  iconColor?: string | null;
  id: number;
  name: string;
}

interface GroupLabel {
  mobile?: string;
  desktop?: string;
}

interface Props {
  email?: string;
  groupLabel: GroupLabel;
  name?: string;
  userForms?: Array<UserForm>;
}

interface FormCountProps {
  userForm: UserForm;
  desktop?: boolean;
}
const FormCount = ({
  userForm: { iconColor, icon, count, id },
  desktop
}: FormCountProps) => {
  return (
    <S.FormCountLink href={`/documents/${id}`}>
      <S.FormCount desktop={desktop}>
        {iconColor && icon && <S.Icon color={iconColor} className={icon} />}
        <S.Count desktop={desktop}>{count}</S.Count>
      </S.FormCount>
    </S.FormCountLink>
  );
};

/**
 * Used in Mobile View, renders a clickable area
 * around the Group Label. When clicked, switches
 * between rendering a truncated label
 * and all of the groups.
 */
const GroupLabel = ({ groupLabel }: {groupLabel: GroupLabel}) => {
  const [renderFull, setRenderFull ] = useState(false);
 
  const onClick = () => {
    setRenderFull(!renderFull);
  };
 
  return (
    <S.Clickable onClick={onClick}>
      {
        renderFull ? (
          <S.GroupRow>
            <Star /> <S.Group as="p">{groupLabel.desktop}</S.Group>
          </S.GroupRow>
        ) : (
          <S.GroupRow>
            <Star /> <S.Group as="p">{groupLabel.mobile}</S.Group>
          </S.GroupRow>
        )
      }
    </S.Clickable> 
  );
};

const MobileView = ({ name, email, groupLabel, userForms }: Props) => {
  return (
    <S.ProfileCard bg={topography}>
      <S.Username as="p">{name}</S.Username>
      <S.Email as="p">{email}</S.Email>

      <GroupLabel 
        groupLabel={groupLabel}
      />

      {userForms && (
        <S.FormCounts>
          {userForms.map((userForm) => (
            <FormCount key={userForm.id} userForm={userForm} />
          ))}
        </S.FormCounts>
      )}
    </S.ProfileCard>
  );
};

const DesktopView = ({ name, email, groupLabel, userForms }: Props) => {
  const { supervisor, adfsEnabled } = useSelector(getUserProfile);

  // Group config terms
  const supervisorTerm = useGroupTerm("supervisor", "noun", undefined, "Supervisor");
  const supervisorTermUpper = supervisorTerm.toUpperCase();
  return (
    <S.ProfileCard bg={topography} adfsEnabled={adfsEnabled}>
      <S.Card>
        <S.Username as="p" desktop>
          {name}
        </S.Username>
  
        <S.Row>
          <S.LeftCol>
            <S.LeftTop>
              <S.GroupCol>
                <S.SectionLabel as="p">GROUP</S.SectionLabel>
                <S.GroupRow>
                  <Star /> <S.Group as="p">{groupLabel.desktop}</S.Group>
                </S.GroupRow>
              </S.GroupCol>
  
              <S.Col>
                <S.SectionLabel as="p">{supervisorTermUpper}</S.SectionLabel>
                <S.Supervisor as="p">
                  {supervisor ? supervisor : "-"}
                </S.Supervisor>
              </S.Col>
            </S.LeftTop>
            
            <S.Col>
              <S.SectionLabel as="p">EMAIL</S.SectionLabel>
              <S.Email as="p">{email}</S.Email>
            </S.Col>
          </S.LeftCol>
          <S.RightCol>
            <S.SectionLabel as="p">SUBMISSIONS</S.SectionLabel>
            {userForms && (
              <S.FormCounts desktop>
                {userForms.map((userForm) => (
                  <FormCount desktop key={userForm.id} userForm={userForm} />
                ))}
              </S.FormCounts>
            )}
          </S.RightCol>
        </S.Row>
      </S.Card>
    </S.ProfileCard>
  );
};

/**
 * Uses a hook to determine which component to render
 * based on the view port.
 */
const ProfileCard = (props: Props) => {
  const isDesktop = useMediaQuery({
    minWidth: devices.minDesktop
  });
  
  if (isDesktop) {
    return <DesktopView {...props} />;
  }

  return <MobileView {...props} />;
};

export default ProfileCard;
