import { Participant } from "../participants/types";
import { WorkLocation } from "../workLocation/types";

// Describing the shape of the system's slice of state
export interface WorkOrdersState {
  loading: {
    searchWorkOrders: {
      GET?: boolean;
    };
  };
  data: searchWorkOrdersData;
}

interface searchWorkOrdersData {
  searchWorkOrders: searchWorkOrdersItem[];
}

export interface searchWorkOrdersItem {
  dateOfParticipantCreation: string;
  description: string;
  geolocation?: geolocationItem[];
  id: number | null | undefined | string;
  locationId: string;
  participantCreatedById?: number;
  participants?: participantItem[];
  supervisorId?: number;
  workOrderId: string;
}

export interface WorkOrderType {
  address?: string;
  dateOfParticipantCreation: string;
  description: string;
  geolocation?: {
    latitude: number;
    longitude: number;
  };
  id: number;
  location?: string;
  locationId?: string; //@FIXME: Which is correct? location or locationId?
  participantCreatedById: number;
  participants?: Array<Participant>;
  supervisor?: Participant;
  workLocation?: WorkLocation;
  workOrderId: string;
}

interface participantItem {
  email: string;
  firstName?: string;
  groups?: groupItem[];
  id?: number | null | undefined | string;
  isActive: boolean;
  isCompletedData: boolean;
  lastName?: string;
  nickname?: string;
  supervisorId?: number;
  teams?: teamItem[];
  workLocationId?: number;
}

interface teamItem {
  description?: string;
  externalId?: string;
  id?: number | null | undefined | string;
  name: string;
}

interface groupItem {
  detail?: string;
  id?: number | null | undefined | string;
  idCompletedData?: boolean;
  name?: string;
}

interface geolocationItem {
  latitude?: number;
  longitude?: number;
}

export const SEARCH_WORK_ORDERS = {
  FAILURE: "SEARCH_WORK_ORDERS_FAILURE",
  REQUEST: "SEARCH_WORK_ORDERS_REQUEST",
  SUCCESS: "SEARCH_WORK_ORDERS_SUCCESS",
};

interface searchWorkOrders {
  response?: any;
  type?:
    | typeof SEARCH_WORK_ORDERS.FAILURE
    | typeof SEARCH_WORK_ORDERS.REQUEST
    | typeof SEARCH_WORK_ORDERS.SUCCESS;
}

export type workOrdersActionTypes = searchWorkOrders;
