import React, { useState, FC } from "react";
import { CategoryDTO } from "store/categories/types";
import { Resource } from "store/resources/types";
import { TagVm } from "store/tagLibrary/types";
import CheckboxGroup from "../CheckboxGroup";
import { SelectInputProps } from "../Select/Select";
import * as S from "./styles";

interface Option {
  checked: boolean;
  disabled: boolean;
  label: string;
  value: string;
}

interface SelectCheckboxProps extends SelectInputProps {
  options: Option[];
  resource: Resource;
  onClose?: () => void;
  updateResource?: (resource, newCat) => void;
  value: CategoryDTO[] | TagVm[];
  loading: boolean;
}

export const CheckboxDropdown: FC<SelectCheckboxProps> = ({
  active,
  assistiveLink,
  children,
  className,
  disabled,
  error,
  helperText,
  name,
  noMargin,
  onBlur,
  onChange,
  options = [],
  required,
  touched,
  value,
  wrapperClassName,
  resource,
  loading,
  updateResource,
  onClose,
}) => {
  const [isFocusedByMouse, setIsFocusedByMouse] = useState(false);

  const handleBlur = (e) => {
    setIsFocusedByMouse(false);
    onBlur && onBlur(e);
  };

  const handleMouseDown = () => {
    setIsFocusedByMouse(true);
  };

  const renderValues =
    name === "categories"
      ? () =>
          resource.categories
            .map((c) => c.title)
            .sort((a, b) =>
              a.localeCompare(b, undefined, { sensitivity: "base" })
            )
            .join(", ")
      : () =>
          resource.tags
            .map((t) => t.name)
            .sort((a, b) =>
              a.localeCompare(b, undefined, { sensitivity: "base" })
            )
            .join(", ");

  return (
    <S.SelectInput width={125}>
      <S.Select
        error={!!error}
        active={active}
        className={className}
        disabled={disabled}
        variant="outlined"
        MenuProps={{
          getContentAnchorEl: null,
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
          MenuListProps: {
            style: {
              display: "flex",
              flexDirection: "column",
              alignItems: "space-evenly",
              paddingLeft: 16,
              width: 225,
            },
          },
          PaperProps: {
            style: {
              maxHeight: 410,
            },
          },
        }}
        multiple
        name={name ? name.toString() : undefined}
        onBlur={handleBlur}
        onChange={onChange}
        onMouseDown={handleMouseDown}
        renderValue={renderValues}
        value={value}
      >
        <CheckboxGroup
          initialOptions={options}
          updateResource={updateResource}
          resource={resource}
          loading={loading}
        />
      </S.Select>
    </S.SelectInput>
  );
};
