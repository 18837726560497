import { Drawer } from "components/common/Drawer/futureUiKit";
import React from "react";
import MediaQuery from "react-responsive";
import { devices } from "themes/mediaQueries";
import { ItemSelectorForm } from "./ItemSelectorForm";
import {
  DataSourceItem,
  SubmitButtonLabel,
} from "./ItemSelectorForm/ItemSelectorForm";
import styles from "./styles.module.scss";

interface ItemSelectorDrawerProps {
  allowWriteIn?: boolean;
  error?: string;
  handleChangeOpen: (isOpen: boolean) => void;
  handleChangeSearch: (query: string) => void;
  handleChangeSelection?: (selectedOptions: DataSourceItem[]) => void;
  handleSubmit: (selectedOptions: DataSourceItem[]) => void;
  hasMultiSelect?: boolean;
  isLastPage?: boolean;
  isLoadingMore?: boolean;
  isLoadingSearchResults?: boolean;
  isOpen: boolean;
  listTitle: string;
  name: string;
  noResultsMessage: string;
  onLoadMore?: () => void;
  searchLabel: string;
  searchPlaceholder?: string;
  options: DataSourceItem[];
  selected: DataSourceItem[];
  submitButtonLabel?: SubmitButtonLabel;
  subtitle?: string;
  title: string;
  titleId: string;
}

export const ItemSelectorDrawer = ({
  handleChangeOpen,
  isOpen,
  name,
  options,
  ...formProps
}: ItemSelectorDrawerProps) => {
  const drawerTitleId = `${name}-drawerTitle`;

  const handleClose = () => {
    handleChangeOpen(false);
  };

  const handleOpen = () => {
    handleChangeOpen(true);
  };

  return (
    <>
      {/* MOBILE */}
      <MediaQuery maxWidth={devices.maxPhone}>
        <Drawer
          anchor="bottom"
          className={styles.drawer}
          isOpen={isOpen}
          labelId={drawerTitleId}
          onClose={handleClose}
          onOpen={handleOpen}
        >
          <ItemSelectorForm
            className={styles.form}
            handleClose={handleClose}
            name={name}
            shouldShowHandleBar={true}
            options={options}
            {...formProps}
          />
        </Drawer>
      </MediaQuery>

      {/* TABLET / DESKTOP */}

      <MediaQuery minWidth={devices.iPad}>
        <Drawer
          anchor="right"
          className={styles.drawer}
          isOpen={isOpen}
          labelId={drawerTitleId}
          onClose={handleClose}
          onOpen={handleOpen}
        >
          <ItemSelectorForm
            className={styles.form}
            handleClose={handleClose}
            name={name}
            options={options}
            {...formProps}
          />
        </Drawer>
      </MediaQuery>
    </>
  );
};
