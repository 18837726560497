import React from "react";
import { useDispatch } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { Action } from "redux";
import { shuffle } from "lodash";

import { AppState } from "store";
import { FormDTO, OEWidgetDTO } from "store/forms/types";
import { OperationalExperience } from "store/resources/types";
import { fetchDocumentOEs } from "store/operationalExperiences/actions";

import OEHeader from "./components/OEHeader";
import RenderOEs from "./components/RenderOEs";
import Reshuffle from "./components/Reshuffle";
import { useFormikContext } from "formik";
import { DocumentFormValuesType } from "../document/Document";
import { ErrorText, OEWrapper } from "./styles";
import { useSelectedTags, useSubmittedSelectedTags } from "./hooks";

/** Number of OEs displayed at once on screen */
const OES_DISPLAYED = 6;

export interface FCOperationalExperience extends OperationalExperience {
  /** denotes whether an oe has been included in the document - remove before uploading form */
  included: boolean;
}

interface Props {
  form: FormDTO;
  oeError: boolean;
  widget: OEWidgetDTO;
  autoSyncDocument: (autoSaveValues: DocumentFormValuesType) => void;
  setRequiredOEs: (requiredOEs: number) => void; // used for form validation
  submitStatus?: string;
  submitCount: number;
}

/** Operational Experiences Widget */
export function OperationalExperiences({
  form,
  oeError,
  widget,
  autoSyncDocument,
  setRequiredOEs,
  submitStatus,
  submitCount,
}: Props) {
  const dispatch = useDispatch<ThunkDispatch<AppState, void, Action>>();

  const { values, setValues, isSubmitting } = useFormikContext<
    DocumentFormValuesType
  >();

  // from values, extract the OEs selected by the document
  const selectedOEs = (values.operationalExperiences || []).map((oe) => ({
    ...oe,
    included: true,
  }));

  /** get list of tags selected by question responses */
  const selectedTags = useSelectedTags(form, widget, values.responses);

  /** A copy of the tags array that is only updated after a document has auto-synced */
  const submittedSelectedTags = useSubmittedSelectedTags(
    selectedTags,
    isSubmitting,
    submitCount
  );

  /** All OEs for document, included and available */
  const [oes, setOEs] = React.useState<Array<FCOperationalExperience>>(
    selectedOEs
  );

  /** Number of OEs to skip in each call. Should be multiple of PAGE_SIZE */
  const [skipCount, setSkipCount] = React.useState<number>(0);
  /** Number of OEs included */
  const [numIncluded, setNumIncluded] = React.useState<number>(
    selectedOEs.length
  );
  /** OEs are being loaded */
  const [fetching, setFetching] = React.useState<boolean>(true);
  const [lessThanOnePage, setLessThanOnePage] = React.useState<boolean>(false);

  /** complicated logic for setting the min OEs based on widget and how many are available */
  const requiredOEsByForm = widget.numberRequired || 0;
  const [localRequiredOEs, setLocalRequiredOEs] = React.useState<number>(
    requiredOEsByForm
  );
  React.useEffect(() => {
    const required = Math.min(requiredOEsByForm, oes.length);
    setLocalRequiredOEs(required);
    setRequiredOEs(required);
  }, [setLocalRequiredOEs, requiredOEsByForm, setRequiredOEs, oes]);

  const localError = oeError ? numIncluded < localRequiredOEs : oeError;

  const headerRef = React.useRef<HTMLDivElement>(null);

  // fetch/update OEs
  function fetchAdditionalOEs(skip) {
    // if no tags, then no OEs - clear unselected
    if (submittedSelectedTags.length === 0) {
      setFetching(false);
      setLessThanOnePage(false);
      setOEs((curr) => curr.filter((oe) => oe.included));
      return;
    }

    const requestSize = OES_DISPLAYED - selectedOEs.length + 1; // request an extra to check if last page
    setTimeout(() => {
      dispatch(fetchDocumentOEs(requestSize, skip, false)).then((res) => {
        setFetching(false);

        const nextOEs = res.response;
        if (nextOEs) {
          // if there was no extra and on first page, then must be a single page of OEs
          setLessThanOnePage(nextOEs.length < requestSize && skip === 0);

          // remove extra and shuffle up the rest
          setOEs([
            ...selectedOEs,
            ...shuffle(nextOEs.slice(0, requestSize - 1)),
          ]);

          // if last page, reset skip count
          setSkipCount(
            nextOEs.length < requestSize ? 0 : skip + requestSize - 1
          );
        }
      });
    }, 500);
  }

  /** When selected OE Tags change, show the loading spinner - then actually fetch OEs after document submission */
  React.useEffect(() => {
    // don't do this is doc is already submitted, since autosave has been disabled on submitted docs, per DOM's request
    if (submitStatus !== "SUBMITTED") {
      setFetching(true);
    }
  }, [selectedTags]);
  React.useEffect(() => {
    fetchAdditionalOEs(0);
  }, [submittedSelectedTags]);

  /** Update document with newly included/excluded OEs */
  function updateOEs(updatedOEs: Array<FCOperationalExperience>): void {
    /* set local oes to update ui instantly */
    setOEs(updatedOEs);

    const included = updatedOEs.filter((oe) => oe.included);
    setNumIncluded(included.length);
    const updatedValues = { ...values, operationalExperiences: included };
    setValues(updatedValues);
    autoSyncDocument(updatedValues);
  }

  /**
   * Include an OE in the document
   * @param opex
   */
  function includeOE(opex: OperationalExperience): void {
    /* include oe in state matching arg oe */
    const updatedOEs = [...oes].map((oe) => {
      if (oe.id === opex.id) {
        return { ...oe, included: true };
      }
      return oe;
    });
    /* put oe changes to server */
    updateOEs(updatedOEs);
  }

  /**
   * Exclude an OE from the document
   * @param opex
   */
  function excludeOE(opex: OperationalExperience): void {
    /* exclude oe in state matching arg oe */
    const updatedOEs = [...oes].map((oe) => {
      if (oe.id === opex.id) {
        return { ...oe, included: false };
      }
      return oe;
    });
    /* put oe changes to server */
    updateOEs(updatedOEs);
  }

  /** Display next OEs and fetch more from API if necessary */
  function reshuffle() {
    setFetching(true);
    fetchAdditionalOEs(skipCount);
  }

  return (
    <OEWrapper error={localError}>
      <div ref={headerRef}>
        <OEHeader
          availableOEs={oes.length}
          minimumIncluded={numIncluded >= localRequiredOEs}
          requiredOEs={requiredOEsByForm}
          requiredText={`${numIncluded}/${requiredOEsByForm} Required`}
        />
      </div>
      <RenderOEs
        loading={fetching}
        excludeOE={excludeOE}
        includeOE={includeOE}
        displayedOEs={oes.slice(0, OES_DISPLAYED)}
      />
      {oes.length > 0 && (
        <Reshuffle
          disabled={lessThanOnePage}
          loading={fetching}
          minimumIncluded={numIncluded >= localRequiredOEs}
          requiredText={`${numIncluded}/${requiredOEsByForm} Required`}
          reshuffle={reshuffle}
        />
      )}
      {localError && (
        <ErrorText>You must include at least {requiredOEsByForm} OEs</ErrorText>
      )}
    </OEWrapper>
  );
}
