import React, { useContext } from "react";
import { bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";
import { get } from "lodash";
import { RouteChildrenProps } from "react-router";
import MediaQuery from "react-responsive";

import { AppConfigsState } from "store/appConfigs/types";
import { AppState } from "store";
import {
  ClearStatusActionTypes,
  ClearStatusPayloadTypes,
  NewDocumentState,
} from "store/newDocument/types";
import { getMinMaxSubmissionDatesFromDate } from "components/helpers/filters";
import { devices } from "themes/mediaQueries";
import {
  DocumentOwner,
  DocumentSummary,
  Owners,
  SearchDocumentsParams,
} from "store/documents/types";
import { FormTypeDTO } from "store/forms/types";
import { SystemState } from "store/system/types";
import * as documentsActions from "store/documents/actions";
import * as formsActions from "store/forms/actions";
import * as newDocumentActions from "store/newDocument/actions";
import * as operationalExperiencesActions from "store/operationalExperiences/actions";
import * as participantConfigsActions from "store/participantConfigs/actions";
import * as participantsActions from "store/participants/actions";
import * as userActions from "store/user/actions";
import BottomNavigationContext from "components/dashboard/DashboardBottomNavigation/Context";
import Tutorial from "components/dashboard/Tutorial";
import ViewTitle from "components/common/styled/ViewTitle";
import DocumentSidebarDrawer from "components/DocumentSidebarDrawer";

import DashboardDrawer from "./DashboardDrawer";
import RenderQuickStartButtons from "./RenderQuickStartButtons";
import ViewSubtitle from "components/common/styled/ViewSubtitle";
import { ClientGroupConfig } from "store/clientConfigs/types";
import { handleNoun } from "util/hooks/whiteLabel/useGroupTerm";

/**
 * Used when rendering Quick Start buttons for Form Types
 */
export type FormType = "pre job brief" | "safety observation";

export interface DashboardProps extends RouteChildrenProps {
  store: {
    appConfigs: AppConfigsState;
    formTypes?: Array<FormTypeDTO>;
    formTypesLoading: boolean;
    newDocument: NewDocumentState;
    ownerIds: Owners;
    showTutorial?: boolean;
    system: SystemState;
    tempFilterDocumentsIsLoading?: boolean;
    tempFilterDocumentsLength?: number;
    workLocationIDFromUserReducer: number | null;
  };

  actions: {
    documents: {
      getOwnerIds: any; // This should be coming from the doc types
      resetOwnerIds: () => void;
      searchDocuments: (props: SearchDocumentsParams) => void;
      searchTempFilterDocuments: (props: SearchDocumentsParams) => void;
    };
    forms: {
      getAllForms: () => void;
      getAllFormTypes: () => void;
    };
    newDocument: {
      clearStatus: ({
        actionType,
        payload,
      }: {
        actionType: ClearStatusActionTypes;
        payload: ClearStatusPayloadTypes;
      }) => void;
      continueDocument: (documentId: number) => void;
      createDocument: ({
        submissionDate,
        formId,
      }: {
        submissionDate: string;
        formId: number;
      }) => void;
      resetCurrentDocument: () => void;
      // fetchSummarizedForms: () => void;
      startNewForm: ({
        selectedFormTypeId,
        selectedFormTemplateId,
        selectedWorkOrderId,
      }: {
        selectedFormTypeId: number | null;
        selectedFormTemplateId: number | null;
        selectedWorkOrderId: number | null;
      }) => void;
      clearWorkOrders: () => void;
    };
    operationalExperiencesActions: {
      fetchOperationalExperiences: () => void;
      searchOperationalExperiences: (
        searchTerm: string,
        searchType?: "incident" | "description"
      ) => void;
    };
    participants: {
      getParticipants: (workLocationIDFromUserReducer: number) => void;
    };
    participantConfigs: {
      hideTempTutorial: () => void;
      setParticipantConfig: (
        active: boolean,
        keyName: string,
        properties: any
      ) => void;
    };
    user: {
      getUser: () => void;
    };
  };

  setDrawerIsVisible?: (v: boolean) => void;

  // White label
  terms: {
    document?: string;
  };
}

export interface DashboardState {
  /**
   * when clicking on a Document, this value will be populated
   * with that Document's data
   */
  selectedDocument: DocumentSummary | null;
  query: string;
  showContextMenu: boolean;
  contextMenuDocument: {
    title: string;
    id: string | number;
    submissionDate: string;
  };

  documentOwner: number | null;
  documentType: number;
  pastDays: number;

  /**
   * used to track window height in order
   * to update anchor for Start New Document modal/drawer
   */
  windowWidth: number;

  // Mobile filters drawer
  filtersDrawerVisible: boolean;
  tempDocumentOwner: number | null;
  tempDocumentType: number;
  tempPastDays: number;

  // Tutorial
  tutorialIsOpen: boolean;
}

class Dashboard extends React.Component<DashboardProps, DashboardState> {
  constructor(props: DashboardProps) {
    super(props);
    this.state = {
      query: "",
      selectedDocument: null,
      showContextMenu: false,
      contextMenuDocument: {
        title: "",
        id: "",
        submissionDate: "",
      },
      documentOwner: null,
      documentType: -1,
      pastDays: -1,
      windowWidth: window.innerWidth,
      filtersDrawerVisible: false,
      tempDocumentOwner: null,
      tempDocumentType: -1,
      tempPastDays: -1,
      tutorialIsOpen: true,
    };
  }
  componentDidMount() {
    const {
      actions,
      store: { workLocationIDFromUserReducer },
    } = this.props;

    // get participants based on `workOrderLocationId`
    workLocationIDFromUserReducer &&
      actions.participants.getParticipants(workLocationIDFromUserReducer);

    actions.documents.searchDocuments({
      query: "",
      value: 0,
      minSubmissionDate: "",
      maxSubmissionDate: "",
      ownerIds: null,
      formTypeIds: "",
      sortName: "submissionDate",
      sortOrder: "desc",
    });

    actions.forms.getAllForms();

    // returns `newDocument` to initial state
    // @NOTE: be sure to call before `fetchSummarizedForms`
    // @NOTE: should we change this process so those values are not reset and
    // we don't always re-call `fetchSummarizedForms`?
    actions.newDocument.resetCurrentDocument();

    // dispatch API call to get data for Start New Document
    // actions.newDocument.fetchSummarizedForms();

    /// remove if already exists, add event listener to get
    // current window width for setting anchor for Start New Form
    window.removeEventListener("resize", this.handleListenToWindowWidth);
    window.addEventListener("resize", this.handleListenToWindowWidth);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleListenToWindowWidth);
  }

  mapSuggestions(suggestions: Owners) {
    const result =
      suggestions.content &&
      suggestions.content.map((e: DocumentOwner) => {
        return {
          id: e.participantId,
          value: `${e.lastName}, ${e.firstName}`,
        };
      });
    return result;
  }

  handleListenToWindowWidth = () => {
    const { windowWidth } = this.state;

    if (windowWidth !== window.innerWidth) {
      return this.setState({
        windowWidth: window.innerWidth,
      });
    }
  };

  openContextMenu = (selectedDocument: DocumentSummary) => {
    if (document) {
      this.setState({
        selectedDocument,
        // contextMenuDocument: document,
        showContextMenu: true,
      });
    }
  };

  handleChange = (value: string) => {
    this.setState({ query: value });
  };

  handleFilterChange = (e: any) => {
    const {
      target: { name, value },
    } = e;

    if (name && value) {
      let formTypeIds = this.state.documentType;
      const ownerIds = this.state.documentOwner;
      let pastDays = this.state.pastDays;

      switch (name) {
        case "pastDays":
          pastDays = value !== -1 ? parseInt(value) : -1; // @TODO handle fallback val
          this.setState(() => ({ pastDays }));
          break;
        case "documentType":
          formTypeIds = value;
          this.setState(() => ({ documentType: value }));
          break;
        default:
          break;
      }

      // Submission date filter
      const {
        minSubmissionDate,
        maxSubmissionDate,
      } = getMinMaxSubmissionDatesFromDate(pastDays);

      // Call things
      this.props.actions.documents.searchDocuments({
        query: this.state.query,
        value: 0,
        minSubmissionDate,
        maxSubmissionDate,
        ownerIds,
        formTypeIds,
        sortName: "submissionDate",
        sortOrder: "desc",
      });
    }
  };

  // Handles onChange for Mobile's Date Range and Document Type
  handleTempFilterChange = (name, value) => {
    if (name && value) {
      let formTypeIds = this.state.tempDocumentType;
      const ownerIds = this.state.tempDocumentOwner;
      let pastDays = this.state.tempPastDays;
      switch (name) {
        case "tempPastDays":
          pastDays = value !== -1 ? parseInt(value) : -1; // @TODO handle fallback val
          this.setState(() => ({ tempPastDays: pastDays }));
          break;
        case "tempDocumentType":
          formTypeIds = value;
          this.setState(() => ({ tempDocumentType: value }));
          break;
        default:
          break;
      }

      // Submission date filter
      const {
        minSubmissionDate,
        maxSubmissionDate,
      } = getMinMaxSubmissionDatesFromDate(pastDays);

      // Call
      this.props.actions.documents.searchTempFilterDocuments({
        query: this.state.query,
        value: 0,
        minSubmissionDate,
        maxSubmissionDate,
        ownerIds,
        formTypeIds,
        sortName: "submissionDate",
        sortOrder: "desc",
      });
    }
  };

  handleOwnerIdChange = (ownerId) => {
    const { pastDays, documentType } = this.state;
    const {
      minSubmissionDate,
      maxSubmissionDate,
    } = getMinMaxSubmissionDatesFromDate(pastDays);

    this.setState({
      documentOwner: ownerId,
    });
    // Call things
    this.props.actions.documents.searchDocuments({
      query: this.state.query,
      value: 0,
      minSubmissionDate,
      maxSubmissionDate,
      ownerIds: ownerId,
      formTypeIds: documentType,
      sortName: "submissionDate",
      sortOrder: "desc",
    });
  };

  handleTempOwnerIdChange = (ownerId) => {
    const { tempPastDays, tempDocumentType } = this.state;
    const {
      minSubmissionDate,
      maxSubmissionDate,
    } = getMinMaxSubmissionDatesFromDate(tempPastDays);

    this.setState({
      tempDocumentOwner: ownerId,
    });
    // Call things
    this.props.actions.documents.searchDocuments({
      query: this.state.query,
      value: 0,
      minSubmissionDate,
      maxSubmissionDate,
      ownerIds: ownerId,
      formTypeIds: tempDocumentType,
      sortName: "submissionDate",
      sortOrder: "desc",
    });
  };

  getSearchParams = () => {
    const { pastDays, documentType, documentOwner } = this.state;
    const {
      minSubmissionDate,
      maxSubmissionDate,
    } = getMinMaxSubmissionDatesFromDate(pastDays);

    return {
      query: this.state.query,
      value: 0,
      minSubmissionDate,
      maxSubmissionDate,
      ownerIds: documentOwner,
      formTypeIds: documentType,
      sortName: "submissionDate",
      sortOrder: "desc",
    };
  };

  /**
   * **selectFormTypeAndOpenNewDocumentDrawerFor** is invoked when the user clicks
   * on one of the Dashboard "start" buttons. We'll pre-select the "Form type"
   * and open the "Start New Document" drawer
   */
  selectFormTypeAndOpenNewDocumentDrawerFor = (formType: FormType) => {
    const {
      store: { formTypes = [] },
      actions: { newDocument },
      setDrawerIsVisible,
    } = this.props;

    // open new doc drawer
    if (setDrawerIsVisible) {
      setDrawerIsVisible(true);
    }

    const selectedFormType = formTypes.find(
      (f) => f.name.toLowerCase() === formType
    );

    if (!selectedFormType) {
      return null;
    }

    newDocument.startNewForm({
      selectedFormTypeId: selectedFormType.id,
      selectedFormTemplateId: null,
      selectedWorkOrderId: null,
    });
  };

  // refs used to set an anchor point for
  // the StartNewDoc modal/drawer element
  startNewDocMobileAnchor = React.createRef<HTMLAnchorElement>();
  startNewDocTabletAnchor = React.createRef<HTMLAnchorElement>();
  startNewDocDesktopAnchor = React.createRef<HTMLAnchorElement>();

  render() {
    const {
      store: { showTutorial },
    } = this.props;

    const { selectedDocument } = this.state;

    // Disabled until/if it's converted to not be a class
    // Is it desktop
    // const isDesktop = useMediaQuery({
    //   query: `(min-device-width: ${devices.minDesktop})`,
    // });

    // Disabled until/if it's converted to not be a class
    // Is it desktop
    // const isDesktop = useMediaQuery({
    //   query: `(min-device-width: ${devices.minDesktop})`,
    // });

    // White label
    const documentTerm = this.props.terms?.document || "Document";
    const documentTermLower = documentTerm.toLowerCase();

    return (
      <>
        <DocumentSidebarDrawer
          selectedDocument={selectedDocument}
          open={this.state.showContextMenu}
          onClose={() => this.setState({ showContextMenu: false })}
          onOpen={() => this.setState({ showContextMenu: true })}
        />

        {/* Tutorial */}
        <Tutorial
          open={showTutorial}
          endTutorial={() => {
            this.props.actions.participantConfigs.setParticipantConfig(
              true,
              "tutorial",
              { showTutorial: false }
            );
          }}
        />

        {/* START NEW FORM */}
        <DashboardDrawer />
        {/* Used for desktop views */}
        <MediaQuery minWidth={devices.minDesktop}>
          <div className="d-flex flex-row">
            <div className="col overflow-hidden">
              <div className="row">
                <div className="col-12">
                  <div className="ml-2 mb-4">
                    <ViewTitle>
                      Welcome to Dominion Energy Safety Tools
                    </ViewTitle>
                    <ViewSubtitle>
                      Select a {documentTermLower} type to begin.
                    </ViewSubtitle>
                  </div>
                </div>
              </div>
              {/* New Document Cards */}
              <div className="row mb-2">
                <div className="col-12 d-flex pl-4">
                  <RenderQuickStartButtons
                    mobile={false}
                    selectFormTypeAndOpenNewDocumentDrawerFor={
                      this.selectFormTypeAndOpenNewDocumentDrawerFor
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </MediaQuery>
        {/* Mobile and Tablet View will render the components below */}
        <MediaQuery maxWidth={devices.maxTablet}>
          {/* Mobile filter drawer */}
          <div
            className="d-flex flex-column justify-content-center"
            style={{ height: "73vh", padding: "0 15px" }}
          >
            <RenderQuickStartButtons
              mobile={true}
              selectFormTypeAndOpenNewDocumentDrawerFor={
                this.selectFormTypeAndOpenNewDocumentDrawerFor
              }
            />
          </div>
        </MediaQuery>
        {/* End of mobile view */}
      </>
    );
  }
}

const mapStateToProps = (state: AppState) => {
  let _documentTerm =
    get(state.clientGroupConfigs, ["data", "terms"], []).find(
      (term: ClientGroupConfig) => term.visibleId === "document"
    )?.val || "";
  _documentTerm = handleNoun(_documentTerm, undefined);
  return {
    store: {
      appConfigs: state.appConfigs,
      formTypes: state.forms.data.formTypes || [],
      formTypesLoading: state.forms.loading.formTypes,
      newDocument: state.newDocument,
      ownerIds: (state.documents.data && state.documents.data.ownerIds) || [],
      showTutorial: get(
        state.participantConfigs.data,
        ["tutorial", "properties", "showTutorial"],
        false
      ),
      system: state.system,
      tempFilterDocumentsIsLoading: get(
        state.documents.data,
        ["tempFilterDocuments", "loading", "GET"],
        false
      ),
      tempFilterDocumentsLength: get(
        state.documents.data,
        ["tempFilterDocuments", "totalElements"],
        0
      ),
      workLocationIDFromUserReducer: get(
        state.user.data,
        ["participant", "workLocation", "id"],
        null
      ),
      // White label
      terms: {
        document: _documentTerm,
      },
    },
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  actions: {
    documents: bindActionCreators(documentsActions, dispatch),
    forms: bindActionCreators(formsActions, dispatch),
    newDocument: bindActionCreators(newDocumentActions, dispatch),
    operationalExperiencesActions: bindActionCreators(
      operationalExperiencesActions,
      dispatch
    ),
    participantConfigs: bindActionCreators(participantConfigsActions, dispatch),
    participants: bindActionCreators(participantsActions, dispatch),
    user: bindActionCreators(userActions, dispatch),
  },
});

/**
 * @NOTE: Hopefully we can convert this
 * component to a functional component,
 * until then we'll wrap with Context
 */
const WithBottomNavigationContext = (props: DashboardProps) => {
  const { setDrawerIsVisible } = useContext(BottomNavigationContext);
  return <Dashboard {...props} setDrawerIsVisible={setDrawerIsVisible} />;
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WithBottomNavigationContext);
