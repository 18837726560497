import React, { useCallback, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import VisibilitySensor from "react-visibility-sensor";
import { useHistory } from "react-router";

import { DocumentsList } from "components/common/List";
import { searchDocumentsNextPage } from "store/documents/actions";
import { selectSearchDocuments, selectMyDocumentsProps } from "store/documents/selectors";
import { WidgetHeader } from "components/common/styled/WidgetHeader";
import Loader from "components/common/Loader";
import Text from "components/common/Text";
import BottomNavigationContext from "components/dashboard/DashboardBottomNavigation/Context";

import * as S from "./styles";
import useGroupTerm from "util/hooks/whiteLabel/useGroupTerm";

const LoadMore = styled.div`
  color: ${(props) => props.theme.colors.background};
`;

export interface Props {
  openContextMenu: any;
}

const MyDocuments = ({ openContextMenu }: Props) => {
  const { push } = useHistory();
  const { drawerIsVisible, setDrawerIsVisible } = useContext(BottomNavigationContext);
  const searchDocuments = useSelector(selectSearchDocuments);
  const { isLoadingSearchInfinite, isSearchLoading, lastPageSearch, searchDocumentsError } = useSelector(
    selectMyDocumentsProps,
  );
  const dispatch = useDispatch();

  const loadMore = useCallback(
    (isVisible: boolean, isLoadingInfinite?: boolean) =>
      isVisible && !isLoadingInfinite && dispatch(searchDocumentsNextPage()),
    [dispatch],
  );

  const toggleDashboardDrawerVisibility = () => setDrawerIsVisible(!drawerIsVisible);

  // Group config terms
  const documentsTerm = useGroupTerm("document", "noun", "plural") || "Documents";
  // const documentsTermLower = documentTerm.toLowerCase();

  return (
    <S.MyDocuments>
      <WidgetHeader>My {documentsTerm}</WidgetHeader>

      {searchDocumentsError && (
        <Text variant="error" as="p">
          Error fetching Documents
        </Text>
      )}

      <Loader loading={isSearchLoading}>
        <DocumentsList
          toggleDashboardDrawerVisibility={toggleDashboardDrawerVisibility}
          handleOnClick={(documentId) => push(`/document/${documentId}`)}
          openContextMenu={(document) => openContextMenu(document)}
          searchDocuments={searchDocuments}
        />

        {!lastPageSearch && (
          <Loader loading={isLoadingSearchInfinite}>
            <VisibilitySensor onChange={(e) => loadMore(e, isLoadingSearchInfinite)}>
              <LoadMore>Load More</LoadMore>
            </VisibilitySensor>
          </Loader>
        )}
      </Loader>
    </S.MyDocuments>
  );
};

export default MyDocuments;
