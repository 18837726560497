import React, { useMemo, useState } from "react";
import moment from "moment";
import { useHistory } from "react-router";

import { PaginationParams } from "store/common/types";
import { Resource, SAVE_RESOURCE } from "store/resources/types";
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "components/common/styled/Table";
import Link from "components/common/Link";
import NoResults from "components/common/TableUI/NoResults";
import TableSummary from "components/common/TableUI/TableSummary";

import { getExportUrl } from "../helpers";
import { getResourceTypeString, getResourceStatusString } from "./helpers";
import ResourceActionMenu from "./ResourceActionMenu";
import TableHeader from "./TableHeader";
import ReadOnlyContent from "components/common/permissions/ReadOnlyContent";
import useGroupTerm from "util/hooks/whiteLabel/useGroupTerm";
import { CheckboxDropdown } from "components/common/form/CheckboxDropdown/CheckboxDropdown";
import { useDispatch } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { AppState } from "store";
import { Action } from "redux";
import { CategoryDTO } from "store/categories/types";
import {
  addTagToOE,
  removeTagFromOE,
  updateResourceResourceCategories,
  updateResourceStatus,
} from "store/resources/actions";
import { TagDTO, TagVm } from "store/tagLibrary/types";
import { ResourceStatus } from "@rtslabs/field1st-fe-common";
import { ConfirmationModal } from "./ConfirmationModal";

type ResourcesTableProps = {
  categories: CategoryDTO[];
  handleUpdateCategories: (id: number, categories: CategoryDTO[]) => void;
  handleUpdateTags: (id: number, tags: TagVm[]) => void;
  onSort: (sort: string) => void;
  onToggleResourceStatus: (resourceId: number, status: ResourceStatus) => void;
  params: PaginationParams;
  resources: Resource[];
  totalElements: number;
  tags: TagDTO[];
};

interface Option {
  checked: boolean;
  disabled: boolean;
  label: string;
  value: string;
}

const ResourcesTable = ({
  onSort,
  params,
  totalElements,
  resources,
  categories,
  onToggleResourceStatus,
  handleUpdateCategories,
  handleUpdateTags,
  tags,
}: ResourcesTableProps) => {
  const history = useHistory();
  const dispatch = useDispatch<ThunkDispatch<AppState, void, Action>>();

  const [confirmDeleteResource, setConfirmDeleteResource] = useState<
    Resource | undefined
  >(undefined);
  const [loading, setLoading] = useState<boolean>(false);
  // Group config terms
  const operationExperiencesTerm = useGroupTerm(
    "operationalExperiences",
    "noun",
    "singular",
    "Operational Experience"
  );
  const defensesTerm = useGroupTerm("defenses", "noun", "singular", "Defense");

  const whiteLabelTerms = useMemo(
    () => ({
      operationalExperience: operationExperiencesTerm,
      defense: defensesTerm,
    }),
    [operationExperiencesTerm, defensesTerm]
  );

  const updateResourceCategories = async (resource, catId) => {
    setLoading(true);
    const parsedId = parseInt(catId);
    if (resource.categories.some((rCat) => rCat.id === parsedId)) {
      await dispatch(
        updateResourceResourceCategories(
          resource,
          resource.categories.filter((cat) => cat.id !== parsedId)
        )
      );
      handleUpdateCategories(
        resource.id,
        resource.categories.filter((cat) => cat.id !== parsedId)
      );
    } else {
      const newCat = categories.find((cat) => cat.id === parsedId);
      await dispatch(
        updateResourceResourceCategories(resource, [
          ...resource.categories,
          newCat,
        ])
      );
      handleUpdateCategories(resource.id, [...resource.categories, newCat]);
    }
    setLoading(false);
  };

  const updateResourceTags = async (resource: Resource, tagId) => {
    setLoading(true);
    const parsedId = parseInt(tagId);
    if (resource.tags.some((tag) => tag.id === parsedId)) {
      await dispatch(removeTagFromOE(resource.id, parsedId));
      handleUpdateTags(
        resource.id,
        resource.tags.filter((tag) => tag.id !== parsedId)
      );
    } else {
      const newTag = tags.find((tag) => tag.id === parsedId);
      await dispatch(addTagToOE(resource.id, parsedId));
      handleUpdateTags(resource.id, [
        ...resource.tags,
        { id: newTag!.id, name: newTag!.name },
      ]);
    }
    setLoading(false);
  };

  const formatTagsCell = (resource: Resource) => {
    if (resource.resourceType === "DEFENSE") return "--";
    return (
      <CheckboxDropdown
        loading={loading}
        options={mapTagCheckboxOptions(resource, tags)}
        resource={resource}
        value={resource.tags}
        name="tags"
        onChange={() => {}}
        updateResource={updateResourceTags}
      />
    );
  };

  const mapCategoryCheckboxOptions = (
    resource: Resource,
    categories: CategoryDTO[]
  ): Option[] => {
    return categories.map((category) => ({
      label: category.title,
      value: String(category.id),
      disabled: !!category.archived,
      checked: resource.categories.some((rCat) => rCat.id === category.id),
    }));
  };

  const mapTagCheckboxOptions = (
    resource: Resource,
    tags: TagDTO[]
  ): Option[] => {
    return tags.map((tag) => ({
      label: tag.name,
      value: String(tag.id),
      disabled: !!tag.archived,
      checked: resource.tags.some((rTag) => rTag.id === tag.id),
    }));
  };

  const handleDeleteResourceStatus = async () => {
    if (confirmDeleteResource) {
      const status = "DELETED";
      const response = await dispatch(
        updateResourceStatus(confirmDeleteResource, status)
      );
      if (response.type === SAVE_RESOURCE.SUCCESS) {
        onToggleResourceStatus(confirmDeleteResource.id, status);
      }
      setConfirmDeleteResource(undefined);
    }
  };

  return (
    <>
      <ConfirmationModal
        modalOpen={!!confirmDeleteResource}
        closeModal={() => setConfirmDeleteResource(undefined)}
        callback={handleDeleteResourceStatus}
      />
      <TableSummary
        pageSize={params.size}
        currentPage={params.page}
        totalElements={totalElements}
        ofWhat="resources"
        exports={[
          {
            type: "print",
          },
          {
            type: "xls",
            exportUrl: getExportUrl(
              "resources",
              params.query,
              `sort=${params.sort}`
            ),
          },
          {
            type: "csv",
            exportUrl: getExportUrl(
              "resources",
              params.query,
              `sort=${params.sort}`
            ),
          },
        ]}
      />
      <Table>
        <TableHeader
          onSort={onSort}
          currentSort={params.sort}
          columns={[
            { id: "resourceType", label: "type" },
            { id: "title" },
            { id: "tags", label: "Tags", notSortable: true },
            { id: "categories", notSortable: true },
            { id: "lastModifiedDate", label: "Last Updated" },
            { id: "status" },
          ]}
        />
        <TableBody>
          {resources.length > 0 ? (
            resources.map((resource, i) => (
              <TableRow key={i}>
                <TableCell>
                  {getResourceTypeString(
                    resource.resourceType,
                    whiteLabelTerms
                  )}
                </TableCell>
                <TableCell width="200px">
                  <ReadOnlyContent component={<>{resource.id}</>}>
                    <Link to={`/content/resources/${resource.id}`}>
                      {resource.title}
                    </Link>
                  </ReadOnlyContent>
                </TableCell>
                <TableCell>{formatTagsCell(resource)}</TableCell>
                <TableCell>
                  <CheckboxDropdown
                    loading={loading}
                    name="categories"
                    resource={resource}
                    onChange={() => {}}
                    updateResource={updateResourceCategories}
                    options={mapCategoryCheckboxOptions(resource, categories)}
                    value={resource.categories}
                  />
                </TableCell>
                <TableCell>
                  {resource.lastModifiedDate
                    ? moment(resource.lastModifiedDate).format("MM/DD/YYYY")
                    : "--"}
                </TableCell>
                <TableCell width="98px">
                  {getResourceStatusString(resource.status)}
                </TableCell>
                <TableCell width="22px">
                  {/* Leaving cell to keep table consistent. 
                      None of the menu links apply for Client Reviewer -- GK 
                  */}
                  <ReadOnlyContent>
                    <ResourceActionMenu
                      resource={resource}
                      onToggleResourceStatus={onToggleResourceStatus}
                      setConfirmDeleteResource={setConfirmDeleteResource}
                    />
                  </ReadOnlyContent>
                </TableCell>
              </TableRow>
            ))
          ) : (
            <NoResults
              icon="document"
              header="No resources were found."
              body="Create a new resource to match your needs."
              button={{
                children: "Create a resource",
                onClick: () => history.push("/content/resources/new"),
              }}
            />
          )}
        </TableBody>
      </Table>
    </>
  );
};

export default ResourcesTable;
