import { MuiStyles } from "util/hooks/useMuiStyles";
import { StyleDeclaration, StyleSheet } from "aphrodite";
import { remCalc } from "themes/helpers";
import { CreateStyleSheet } from "util/hooks/useThemedStyleSheet";

interface DateRangeStyle {
  clearButton: StyleDeclaration;
  container: StyleDeclaration;
  rangeSeparator: StyleDeclaration;
}

export const popoverStyles: MuiStyles = ({ theme }) => {
  return {
    outlined: {
      borderColor: theme.masterColors.greyBlue,
    },
  };
};

export const styles: CreateStyleSheet<DateRangeStyle> = (theme) => {
  return StyleSheet.create({
    container: {
      alignItems: "center",
      display: "flex",
    },
    rangeSeparator: {
      color: theme.colors.black,
      padding: remCalc(16),
    },
    clearButton: {
      color: theme.colors.error,
      cursor: "pointer",
      marginLeft: "1rem",
      marginRight: "1rem",
    }
  });
};
