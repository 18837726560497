import * as R from "ramda";
import React, { useMemo, useState, useEffect } from "react";
import moment from "moment";
import { get } from "lodash";
import { useDispatch } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { useSelector } from "react-redux";

import { prettifyWorkorderID } from "components/helpers";
import { devices } from "themes/mediaQueries";
import { DocumentHeader } from "components/dashboard/helpers/SidebarDrawer/DocumentHeader";
import {
  DocumentSummary,
  DocumentVM,
  RelatedDocumentVm,
} from "store/documents/types";
import { getAppConfigsState } from "store/appConfigs/selectors";
import Drawer from "components/common/Drawer";
import { prettifyDocumentStatus } from "components/helpers";

import * as S from "./styles";
import DocumentHistorySidebar from "./DocumentHistorySidebar";
import DocumentSidebarMenu from "./DocumentSidebarMenu";
import DrawerContentWrapper from "components/common/Drawer/components/DrawerContentWrapper";
import FormTemplatesDrawer from "./FormTemplatesDrawer/FormTemplatesDrawer";
import FormTypesDrawer from "./FormTypesDrawer";
import InterstitialDrawer from "./InterstitialDrawer";
import RehuddleSidebar from "./RehuddleSidebar";
import ShareDocumentSidebar from "./ShareDocumentSidebar";
import SubmitFeedbackDrawer from "./SubmitFeedbackDrawer";
import useGroupTerm from "util/hooks/whiteLabel/useGroupTerm";
import { getSubmissionTimeError } from "./helpers";
import { Action } from "redux";
import { AppState } from "store";
import { FormDTO, FormTypeDTO } from "store/forms/types";
import { getRelatedDocuments } from "store/documents/actions";
import { ThunkDispatch } from "redux-thunk";

interface Props {
  anchor?: "right" | "bottom" | "left" | "top";
  onClose?: () => void;
  onOpen?: () => void;
  open: boolean;
  selectedDocument: DocumentSummary | DocumentVM | null;
  overrideClasses?: {
    [key: string]: string;
  };
}

/**
 * Handles rendering the Document Sidebar and all children sidebar menus
 */
const DocumentSidebar = ({
  anchor = "right",
  onClose,
  onOpen,
  open,
  selectedDocument,
}: Props) => {
  const [submitFeedbackVisible, setSubmitFeedbackVisible] = useState(false);
  const [rehuddleIsVisible, setRehuddleIsVisible] = useState(false);
  const [shareDocumentIsVisible, setShareDocumentIsVisible] = useState<boolean>(
    false
  );
  const [documentHistoryIsVisible, setDocumentHistoryIsVisible] = useState<
    boolean
  >(false);
  const [relatedDocumentsIsVisible, setRelatedDocumentsIsVisible] = useState<
    boolean
  >(false);
  const [formTypesDrawerIsVisible, setFormTypesDrawerIsVisible] = useState<
    boolean
  >(false);
  const [
    formTemplatesDrawerIsVisible,
    setFormTemplatesDrawerIsVisible,
  ] = useState<boolean>(false);
  const [
    interstitialDrawerIsVisible,
    setInterstitialDrawerIsVisible,
  ] = useState<boolean>(false);

  // Related Documents State
  const [relatedDocuments, setRelatedDocuments] = useState<RelatedDocumentVm[]>(
    []
  );
  const [isRelatedDocumentsLoading, setIsRelatedDocumentsLoading] = useState<
    boolean
  >(false);
  const [hasRelatedDocumentsError, setHasRelatedDocumentsError] = useState<
    boolean
  >(false);

  const dispatch = useDispatch<ThunkDispatch<AppState, void, Action>>();

  useEffect(() => {
    setIsRelatedDocumentsLoading(true);
    setHasRelatedDocumentsError(false);

    if (selectedDocument) {
      dispatch(getRelatedDocuments(selectedDocument.id)).then((res) => {
        setIsRelatedDocumentsLoading(false);
        if (res.type === "GET_RELATED_DOCUMENTS_SUCCESS") {
          setRelatedDocuments(res.response);
        } else {
          setHasRelatedDocumentsError(true);
        }
      });
    }
  }, [selectedDocument?.id]);

  // Group config terms
  const documentsTerm = useGroupTerm("document", "noun", "plural", "Documents");

  // Selected Form Type
  const [selectedFormType, setSelectedFormType] = useState<FormTypeDTO | null>(
    null
  );
  // Selected Form Template
  const [selectedTemplate, setSelectedTemplate] = useState<FormDTO | null>(
    null
  );

  const isDesktop = useMediaQuery({
    query: `(min-width: ${devices.minDesktop}px)`,
  });

  const appConfigs = useSelector(getAppConfigsState);

  const computedOverrideClasses = useMemo(
    () =>
      isDesktop
        ? {
            content: "myDocumentsDrawerDesktop",
          }
        : {
            container: "",
            content: "myDocumentsDrawer",
          },
    [isDesktop]
  );

  // Get all form types
  const formTypes = useSelector(
    (state: AppState) => state.forms?.data?.formTypes || []
  );

  // Get relationshipTypes from selected document
  const relationshipTypes = useMemo(
    () => selectedDocument?.formSummary?.relationshipTypes || [],
    [selectedDocument?.id]
  );

  // Filtered form types, by available relationship types
  const filteredFormTypes = formTypes.filter((type) =>
    relationshipTypes.some(
      (relationship) => relationship.formTypeId === type.id
    )
  );

  // On successful related document submission
  const onSuccessfulRelatedDocumentSubmission = () => {
    setInterstitialDrawerIsVisible(false);
    setFormTemplatesDrawerIsVisible(false);
    setFormTypesDrawerIsVisible(false);
    onClose && onClose();
  };

  const onSuccessfulRehuddle = () => {
    setRehuddleIsVisible(false);
    onClose && onClose();
  };

  const submissionType = useMemo(
    () => prettifyDocumentStatus(get(selectedDocument, "status", "NEW")),
    [selectedDocument]
  );

  if (!selectedDocument) {
    return null;
  }

  const formType = get(selectedDocument, ["formSummary", "name"], undefined);
  const ownerName = get(selectedDocument, ["owner", "name"], undefined);

  return (
    <>
      <Drawer
        open={open}
        overrideClasses={computedOverrideClasses}
        onOpen={onOpen}
        onClose={onClose}
        anchor={anchor}
        id="DocumentSidebar"
        content={
          <DrawerContentWrapper>
            <S.DocumentSidebar>
              <DocumentHeader
                formType={formType}
                ownerName={ownerName}
                isRehuddle={get(selectedDocument, "isRehuddle")}
                subtitle1={selectedDocument ? selectedDocument.title : ""}
                subtitle2={`ID: ${prettifyWorkorderID(
                  R.pathOr(
                    "N/A",
                    ["workOrder", "workOrderId"],
                    selectedDocument
                  )
                )}`}
                subtitle3={
                  selectedDocument && selectedDocument.submissionDate
                    ? moment(selectedDocument.submissionDate).format(
                        "MMMM Do, YYYY"
                      )
                    : ""
                }
                parentId={get(selectedDocument, "parentId")}
                submissionType={submissionType}
                title={get(selectedDocument, "formSummary.type.name", "")}
                deadline={get(selectedDocument, "deadline", undefined)}
              />

              <DocumentSidebarMenu
                appConfigs={appConfigs}
                document={selectedDocument}
                formTypes={filteredFormTypes}
                onSelectFormType={(type: FormTypeDTO) => {
                  setSelectedFormType(type);
                  setFormTypesDrawerIsVisible(false); // Hide this drawer
                  setFormTemplatesDrawerIsVisible(true); // Show next drawer (templates)
                }}
                relatedDocuments={{
                  data: relatedDocuments,
                  isLoading: isRelatedDocumentsLoading,
                  hasError: hasRelatedDocumentsError,
                }}
                toggleDocumentHistory={() => setDocumentHistoryIsVisible(true)}
                toggleFormTypesVisibility={() =>
                  setFormTypesDrawerIsVisible(true)
                }
                toggleRehuddleSidebarVisibility={() =>
                  setRehuddleIsVisible(true)
                }
                toggleRelatedDocumentsVisibility={() =>
                  setRelatedDocumentsIsVisible(true)
                }
                toggleShareDocumentVisibility={() =>
                  setShareDocumentIsVisible(true)
                }
                toggleSubmitFeedbackVisibility={() =>
                  setSubmitFeedbackVisible(true)
                }
              />
            </S.DocumentSidebar>
          </DrawerContentWrapper>
        }
      />
      <SubmitFeedbackDrawer
        isOpen={submitFeedbackVisible}
        setIsOpen={setSubmitFeedbackVisible}
        isDesktop={isDesktop}
      />

      <RehuddleSidebar
        isOpen={rehuddleIsVisible}
        setIsOpen={setRehuddleIsVisible}
        isDesktop={isDesktop}
        onSuccess={onSuccessfulRehuddle}
        selectedDocument={selectedDocument}
      />

      <ShareDocumentSidebar
        isOpen={shareDocumentIsVisible}
        setIsOpen={setShareDocumentIsVisible}
        isDesktop={isDesktop}
        selectedDocument={selectedDocument}
      />

      <DocumentHistorySidebar
        isOpen={documentHistoryIsVisible}
        setIsOpen={setDocumentHistoryIsVisible}
        isDesktop={isDesktop}
        document={selectedDocument}
      />

      {/* View Related Documents */}
      <DocumentHistorySidebar
        isOpen={relatedDocumentsIsVisible}
        setIsOpen={setRelatedDocumentsIsVisible}
        isDesktop={isDesktop}
        document={selectedDocument}
        historyType="related"
        relatedDocuments={{
          data: relatedDocuments,
          isLoading: isRelatedDocumentsLoading,
          hasError: hasRelatedDocumentsError,
        }}
        title={`View Related ${documentsTerm}`}
      />

      <FormTypesDrawer
        formTypes={filteredFormTypes}
        isDesktop={isDesktop}
        isOpen={formTypesDrawerIsVisible}
        relationshipTypes={relationshipTypes}
        setIsOpen={setFormTypesDrawerIsVisible}
        onSelectFormType={(type: FormTypeDTO) => {
          setSelectedFormType(type); // Store form type
          setFormTypesDrawerIsVisible(false); // Hide this drawer
          setFormTemplatesDrawerIsVisible(true); // Show next drawer (templates)
        }}
      />

      <FormTemplatesDrawer
        isOpen={formTemplatesDrawerIsVisible}
        setIsOpen={setFormTemplatesDrawerIsVisible}
        isDesktop={isDesktop}
        onSelectFormTemplate={(template: FormDTO) => {
          setSelectedTemplate(template); // Store form template
          setFormTemplatesDrawerIsVisible(false); // Hide this drawer
          setInterstitialDrawerIsVisible(true); // Show next drawer (interstitial)
        }}
        selectedFormType={selectedFormType}
        title={`Start Related ${selectedFormType?.name}`}
      />

      <InterstitialDrawer
        displayConditions={selectedTemplate?.displayConditions}
        formId={selectedTemplate?.id}
        formType={selectedFormType?.name}
        submissionTimeError={getSubmissionTimeError(
          selectedTemplate?.formSubmissionConstraint
        )}
        isDesktop={isDesktop}
        isOpen={interstitialDrawerIsVisible}
        onSuccess={onSuccessfulRelatedDocumentSubmission}
        selectedDocument={selectedDocument}
        setIsOpen={setInterstitialDrawerIsVisible}
        title={selectedTemplate?.name}
      />
    </>
  );
};

export default DocumentSidebar;
