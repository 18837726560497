import { PaginationParams } from "store/common/types";
import {
  ReportsFiltersParams,
  REPORTS_FILTERS,
  SetReportsFiltersAction,
  SetReportsPaginationAction,
} from "./types";

export const setReportsFilters = (
  filters: ReportsFiltersParams
): SetReportsFiltersAction => ({
  response: filters,
  type: REPORTS_FILTERS.SET,
});

export const setReportsPagination = (
  pagination: PaginationParams
): SetReportsPaginationAction => ({
  response: pagination,
  type: REPORTS_FILTERS.PARAMS_SET,
});
