import React from "react";

import * as S from "./styles";

interface Props {
  title: string;
  subTitle: string;
}

const Title = ({ title, subTitle }: Props) => (
  <S.Title>
    <S.Heading as="h1">{title}</S.Heading>
    <S.SubHeading as="p">{subTitle}</S.SubHeading>
  </S.Title>
);

export default Title;
