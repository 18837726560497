import React from "react";

import Breadcrumbs from "components/common/Breadcrumbs";
import PrivacyPolicyContent from "./PrivacyPolicyContent";
import { ContentWrapper } from "components/common/Wrappers";

const Privacy = () => {
  return (
    <ContentWrapper>
      <Breadcrumbs paths={[{ pathName: "Privacy" }]} />
      <PrivacyPolicyContent />
    </ContentWrapper>
  );
};

export default Privacy;
