import React, { useState, useEffect, useMemo } from "react";
import moment from "moment";
import styled from "styled-components";
import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { useDispatch } from "react-redux";

import Loader from "components/common/Loader";
import useGroupTerm from "util/hooks/whiteLabel/useGroupTerm";
import { DocumentVM } from "store/documents/types";
import { getDocument, getDocumentHistory } from "store/documents/actions";

const Wrapper = styled.div<{ padding?: string }>`
  padding: ${({ padding }) => padding || "10px"};
`;

interface Props {
  id: DocumentVM["id"];
  isRehuddle: DocumentVM["isRehuddle"];
  padding?: string;
  parentId: DocumentVM["parentId"];
}

export const RehuddleInfo = ({ id, isRehuddle, parentId, padding }: Props) => {
  // State
  const [parentLoading, setParentLoading] = useState<boolean>(false);
  const [historyLoading, setHistoryLoading] = useState<boolean>(false);
  const [parentData, setParentData] = useState({
    formTypeName: "",
    documentTitle: "",
    documentOwner: "",
  });
  const [historyData, setHistoryData] = useState({
    date: "",
  });

  // Dispatch
  const dispatch = useDispatch<ThunkDispatch<null, void, Action>>();

  // Fetch parent document
  const fetchParentDocument = async () => {
    setParentLoading(true);
    const res = parentId
      ? await dispatch(getDocument(Number(parentId)))
      : undefined;
    if (res?.response) {
      setParentData({
        formTypeName: res.response.form.type.name,
        documentTitle: res.response.title,
        documentOwner: res.response.owner.name,
      });
    }
    setParentLoading(false);
  };

  // Fetch document history
  const fetchDocumentHistory = async () => {
    setHistoryLoading(true);
    const res = id ? await dispatch(getDocumentHistory(Number(id))) : undefined;
    if (res?.response) {
      const historyDate = res.response?.find((item) => item.isRehuddle)
        ?.historyDate;
      setHistoryData({
        date: historyDate,
      });
    }
    setHistoryLoading(false);
  };

  // White label
  const rehuddleTerm = useGroupTerm("rehuddle", "noun", undefined, "Rehuddle");
  const rehuddleTermLower = rehuddleTerm.toLowerCase();

  // Local content
  const content = useMemo(() => {
    return `This document is a ${rehuddleTermLower} from "${
      parentData.formTypeName
    }: ${parentData.documentTitle}" by 
    ${parentData.documentOwner} on ${moment(historyData.date).format(
      "M/DD/YYYY"
    )} at ${moment(historyData.date).format("HH:mm")}.`;
  }, [parentLoading, historyLoading]);

  // On mount
  useEffect(() => {
    (async () => {
      if (isRehuddle) {
        await fetchParentDocument();
        await fetchDocumentHistory();
      }
    })();
  }, [id, isRehuddle, parentId]);

  // Hide if not a rehuddle
  if (!isRehuddle) return null;

  return (
    <>
      <Loader
        loading={parentLoading || historyLoading}
        spinnerProps={{ size: 16, style: { lineHeight: 0 } }}
      >
        <Wrapper padding={padding}>{content}</Wrapper>
      </Loader>
    </>
  );
};
