import { SignatureType } from "components/clientAdmin/formBuilder/types";
import Heading from "components/common/Heading";
import Text from "components/common/Text";
import {
  hasValidDrawnSignature,
  hasValidDrawnTypedSignature,
  typedSignatureValidationError,
} from "components/FormController/validation";
import React from "react";
import { DocumentParticipant } from "store/documents/types";
import { allowsTypedSignatures } from "util/signatures";
import Signature from "./Signature";
import * as S from "./styles";
import { sortBy, uniqBy } from "lodash";

interface Props {
  /** attaches a drawn signature URL to a participant */
  attachURL: (participant: DocumentParticipant) => void;
  clearSignature: (participant: DocumentParticipant) => void;
  participants: Array<DocumentParticipant>;
  handleOnChangeTypedSignature: (
    value: string,
    participant: DocumentParticipant
  ) => void;
  error?: string;
  allowedTypes: Array<SignatureType>;
}

function Signatures({
  attachURL,
  clearSignature,
  error,
  participants,
  handleOnChangeTypedSignature,
  allowedTypes,
}: Props) {
  const filteredParticipants = uniqBy(
    participants.filter((participant) => participant.role !== "SUPERVISOR"),
    (participant) => participant.participantId ?? participant.name
  );

  const hasValidSignature = allowsTypedSignatures(allowedTypes)
    ? hasValidDrawnTypedSignature
    : hasValidDrawnSignature;

  const notAllParticipantsSigned = filteredParticipants.some(
    (p) => !hasValidSignature(p, allowedTypes)
  );

  const sortedParticipants = sortBy(filteredParticipants, (p) => p.timeAdded);
  return (
    <S.Signatures
      error={typeof error != "undefined" && notAllParticipantsSigned}
    >
      <Heading variant="section-title" as="h3">
        Signatures
      </Heading>
      {sortedParticipants.map((participant) => {
        return (
          <S.SignatureWrapper
            key={
              participant.participantId
                ? participant.participantId
                : participant.name
            }
          >
            <Signature
              attachURL={attachURL}
              clearSignature={clearSignature}
              handleOnChangeTypedSignature={handleOnChangeTypedSignature}
              typedSignature={participant.signatureTextValue}
              validationError={typedSignatureValidationError({
                allowedTypes: allowedTypes,
                typedSig: participant.signatureTextValue || "",
                name:
                  participant.name ||
                  `${participant.firstName} ${participant.lastName}`,
                email: participant.email || "",
                fullName: participant.fullName || "",
              })}
              label={getLabel(participant)}
              participant={participant}
              placeholder={getPlaceholderText(allowedTypes)}
              signatureUrl={participant.signatureUrl}
              allowedTypes={allowedTypes}
            />
          </S.SignatureWrapper>
        );
      })}
      {error && notAllParticipantsSigned && (
        <Text variant="error">{error}</Text>
      )}
    </S.Signatures>
  );
}

export default Signatures;

function getPlaceholderText(allowedTypes: Array<SignatureType>) {
  if (
    allowedTypes.includes("TYPED_ANYTHING") ||
    (allowedTypes.includes("TYPED_EMAIL") &&
      allowedTypes.includes("TYPED_NAME"))
  ) {
    return "Enter the participant's name or email";
  }
  if (allowedTypes.includes("TYPED_NAME")) {
    return "Enter the participant's name";
  }
  if (allowedTypes.includes("TYPED_EMAIL")) {
    return "Enter the participant's email";
  }
  return "Enter the participant's signature";
}

function getLabel(participant: DocumentParticipant): string {
  if (participant.firstName && participant.lastName) {
    // First Last (Nickname) or First Last
    return `${participant.firstName} ${participant.lastName}${
      participant.nickname ? ` (${participant.nickname})` : ""
    }`;
  } else {
    // Write in participant, show the name as written in
    return participant.name!;
  }
}
