import React from "react";
import { css } from "aphrodite";
import { rgbToHex } from "@material-ui/core/styles";

import { Label } from "../Label/futureUiKit/Label";
import styles from "./styles";
import { TextInput } from "../TextInput/futureUiKit/TextInput";

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  withText?: boolean;
  onColorChange: (val: string) => void;
  colorValue: string | null;
  disabled?: boolean;
}

/**
 * Shorthand for converting rgb to hex
 * @param value
 */
function getHex(value: string | null): string {
  if (value?.includes("rgb")) {
    return rgbToHex(value);
  }
  return value || "";
}

function ColorPicker({
  label,
  name,
  id,
  onColorChange,
  colorValue,
  withText,
  disabled,
}: Props) {
  const [localVal, setLocalVal] = React.useState<string>(() =>
    getHex(colorValue)
  );

  React.useEffect(() => {
    if (localVal !== getHex(colorValue)) {
      onColorChange(localVal);
    }
  }, [localVal]);

  const s = styles();

  if (withText) {
    return (
      <div className={css(s.col)}>
        {label && (
          <Label
            content={label}
            className={css(s.label)}
            htmlFor={name || ""}
          />
        )}
        <div className={css(s.row)}>
          <input
            name={name}
            type="color"
            value={localVal}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setLocalVal(e.target.value)
            }
            className={css(s.picker)}
            disabled={disabled}
          />
          <TextInput
            name={name}
            placeholder="Hexadecimal value"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setLocalVal(e.target.value)
            }
            value={localVal}
            disabled={disabled}
          />
        </div>
      </div>
    );
  }

  return (
    <div className={css(s.row)}>
      <input
        name={name}
        type="color"
        value={localVal}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          setLocalVal(e.target.value)
        }
        className={css(s.picker)}
      />
      {label && (
        <Label content={label} className={css(s.label)} htmlFor={id || ""} />
      )}
    </div>
  );
}

export default ColorPicker;
