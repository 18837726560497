import React from "react";
import styled from "styled-components";
import { ButtonBase } from "@material-ui/core";
import StarOutlineBase from "@material-ui/icons/StarBorder";
import StarBase from "@material-ui/icons/Star";

import Loader from "components/common/Loader";
import { SearchInput as SearchInputBase } from "components/forms/SearchInput";
import { remCalc, toRgba } from "themes/helpers";
import TextBase from "components/common/Text";
import mediaQueries from "themes/mediaQueries";
import { omit } from "lodash";

// --------------------------------------------------------------------------
// ? STYLES FOR: OperationalExperienceHeader
// --------------------------------------------------------------------------

export const OEWrapper = styled.div<{ error: boolean }>`
${({ error, theme }) => error ? `
border: 2px solid ${theme.masterColors.error};
padding: 20px;
` : ""}
`;

export const OperationalExperienceHeader = styled.div`
  display: flex;
  flex-direction: column;
`;
export const OEHeaderTitle = styled(TextBase)`
  color: ${({ theme }) => theme.colors.black};

  font-family: "Roboto", sans-serif;
  font-size: ${remCalc(34)};
  font-weight: 400;
  margin-bottom: ${remCalc(21)};

  @media (${mediaQueries.maxPhone}) {
    /* font-size: ${remCalc(24)}; */
  }
`;
export const SearchInput = styled(SearchInputBase)``;
export const BoldText = styled(TextBase)`
  font-weight: 700;
`;

export const RequiredText = styled(TextBase) <{
  success?: boolean;
}>`
  margin: 20px 0;
  padding: 0;

  color: ${({ theme }) => theme.masterColors.error};
  font-size: ${remCalc(20)};
  text-align: center;

  ${({ success, theme }) => {
    if (success) {
      return `
        color: ${theme.masterColors.success};
      `;
    }
  }}
`;
// --------------------------------------------------------------------------
// ? STYLES FOR: OperationalExperiences // RenderOperationalExperiences Copy
// --------------------------------------------------------------------------

export const OperationalExperiences = styled.div`
  @media (${mediaQueries.minDesktop}) {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-content: center;
  }
`;

export const BlankCard = styled.div`
  min-width: 32%;

  @media (${mediaQueries.maxTablet}) {
    display: none;
  }
`;

export const ErrorText = styled(TextBase)`
  margin-top: 10px;
  display: block;
  font-size: ${remCalc(16)};
  color: ${({ theme }) => theme.masterColors.error};
`;

export const SectionSeparator = styled.div`
  height: ${remCalc(1)};
  width: 100%;
  background-color: ${({ theme }) => theme.masterColors.mediumGrey};
  margin-top: ${remCalc(40)};
  margin-bottom: ${remCalc(40)};
`;

export const Italic = styled(TextBase)`
  font-style: italic;
  font-weight: 500;
`;

// --------------------------------------------------------------------------
// ? STYLES FOR: OECard
// --------------------------------------------------------------------------

export const OperationalExperienceCard = styled.div<{ included: boolean }>`
  background-color: ${({ theme, included }) =>
    included ? theme.colors.activeFill : theme.colors.white};
  padding: ${remCalc(24)};
  border: ${remCalc(1)} solid ${({ theme }) => theme.masterColors.lightGrey};
  border-radius: ${remCalc(4)};
  margin-bottom: ${remCalc(24)};
  min-width: 0;

  @media (${mediaQueries.minDesktop}) {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 32%;
  }
`;

/**
 * Based on the given char count (@count), we'll hide the
 * "Show More" button. We're also taking into account the
 * viewport width.
 *
 * @param count number
 */
const renderOECardActionRowBasedOnDescriptionCount = (count: number) => {
  const hideIfCountLessThan = (count: number, cutoff: number) =>
    count < cutoff && "display: none;";

  return `
    justify-content: center;
    margin-bottom: ${remCalc(26)};
    @media (${mediaQueries.maxPhone})  {
      ${hideIfCountLessThan(count, 200)}
    }

    @media (${mediaQueries.minTablet}, ${mediaQueries.maxTablet}) {
      ${hideIfCountLessThan(count, 390)}
    }

    @media (${mediaQueries.minDesktop})  {
      ${hideIfCountLessThan(count, 150)}
    }
  `;
};

export const OECardActionRow = styled.div<{
  variant: "top" | "bottom";
  descriptionCharCount?: number;
}>`
  display: flex;
  justify-content: space-between;

  ${({ variant, descriptionCharCount }) => {
    if (variant === "top") {
      return `
        margin-bottom: ${remCalc(24)};
      `;
    }

    // When used to house the "Show More" button
    if (variant === "bottom" && descriptionCharCount) {
      return renderOECardActionRowBasedOnDescriptionCount(descriptionCharCount);
    }

    if (variant === "bottom") {
      return `
        margin-bottom: ${remCalc(26)};
        justify-content: center;
      `;
    }
  }}
`;

export const OECardTitleRow = styled.div``;

export const OECardTitle = styled(TextBase)`
  font-size: ${remCalc(20)};
  letter-spacing: ${remCalc(0.26)};
  color: ${({ theme }) => theme.colors.black};
`;

export const OECardIDLabel = styled(TextBase)`
  color: ${({ theme }) => theme.masterColors.darkGrey};
  font-size: ${remCalc(14)};
  letter-spacing: ${remCalc(0.24)};
`;

export const OECardHTML = styled.div`
  color: ${({ theme }) => theme.masterColors.darkGrey};
  letter-spacing: ${remCalc(0.25)};
  font-size: ${remCalc(14)};
  max-width: 100%;
  text-overflow: ellipsis;
  word-break: break-word;
`;

/**
 * Based on the given char count, we'll set the height of the div
 * to a "collapsed" state
 * @param count number
 */
const handleCollapse = (count: number) => {
  const height = (count: number, cutoff: number) =>
    count >= cutoff && `height: ${remCalc(98)};`;
  return `
    @media (${mediaQueries.maxPhone}) {
      ${height(count, 200)}
    }

    @media (${mediaQueries.minTablet}) {
      ${height(count, 150)}
    }
  `;
};

/**
 * Creates a linear-gradient style used for the OE collapsible body
 * @NOTE: logic like this messes up formatting and I think it's
 * easier to read this way. - Trevor Kirpaul
 */
const createGradient = (included: boolean, theme: any) => {
  const color = included ? theme.colors.activeFill : theme.colors.white;
  return `linear-gradient(180deg, ${toRgba(theme.colors.black, 0)} 0%, ${color} 50%)`;
};

// formatting + styled-components = :(
// eslint-disable-next-line no-unexpected-multiline
export const OECardCollapsibleBody = styled.div<{
  collapsed: boolean; // eslint-disable-line @typescript-eslint/indent
  descriptionCharCount: number; // eslint-disable-line @typescript-eslint/indent
  included: boolean; // eslint-disable-line @typescript-eslint/indent
}>`
  ${({ descriptionCharCount, collapsed }) => {
    if (!collapsed) {
      return `
        height: auto;
      `;
    }
    return handleCollapse(descriptionCharCount);
  }}

  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
  margin-bottom: ${remCalc(30)};

  ${({ collapsed, included, theme }) => {
    if (collapsed) {
      return `
      ::after {
        content: "";
        position: absolute;
        right: 0;
        bottom: -10px;
        width: 100%;
        height: ${remCalc(20)};
        background: ${createGradient(included, theme)};
      }
      `;
    }
  }}
`;

// --------------------------------------------------------------------------
// ? STYLES FOR: Button Components
// --------------------------------------------------------------------------
export const IncludeButton = styled((props) => (
  <ButtonBase
    { ...omit(props, "included") }
    classes={{
      root: "root"
    }}
  />
))`
  &&.root {
    border: ${remCalc(1)} solid
      ${({ theme, included }) =>
    included ? theme.colors.primary : theme.colors.black};
    border-radius: ${remCalc(100)};
    height: ${remCalc(30)};
    width: ${remCalc(115)};
    font-size: ${remCalc(14)};
    padding: ${remCalc(0)} ${remCalc(17)};
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${({ theme, included }) =>
    included ? theme.colors.white : theme.colors.black};
    background-color: ${({ included, theme }) =>
    included ? theme.colors.primary : theme.colors.white};
    ${({ disabled }) => {
    if (disabled) {
      return `
          padding-left: ${remCalc(0)};
        `;
    }
  }}

    &::hover {
      cursor: pointer;
    }
  }
`;

export const ShowMoreButton = styled(({ ...props }) => (
  <ButtonBase
    {...props}
    classes={{
      root: "root"
    }}
  />
))`
  &&.root {
    border: ${remCalc(1)} solid ${({ theme }) => theme.colors.mediumGrey};
    border-radius: ${remCalc(4)};
    color: ${({ theme }) => theme.colors.primary};
    padding: ${remCalc(5)} ${remCalc(45)};
  }
`;

export const Icon = styled.i<{
  variant: "include" | "show";
  collapsed?: boolean;
}>`
  ${({ variant, collapsed }) => {
    if (variant === "include") {
      return `
         margin-right: ${remCalc(5)};
      `;
    }

    if (variant === "show") {
      return `
        margin-left: ${remCalc(9)};
        padding-bottom: ${remCalc(4)};
        font-size: ${remCalc(30)};
        transform: ${collapsed ? "" : "rotate(180deg)"};
      `;
    }
  }}
`;

export const FavoriteButton = styled.button`
  background: none;
  border: none;
  &::hover {
    cursor: pointer;
  }
`;

export const Star = styled(({ ...props }) => (
  <StarBase
    {...props}
    classes={{
      root: "root"
    }}
  />
))`
  &&.root {
    width: ${remCalc(30)};
    height: ${remCalc(30)};
    color: ${({ theme }) => theme.masterColors.warning};
  }
`;

export const StarOutline = styled(({ ...props }) => (
  <StarOutlineBase
    {...props}
    classes={{
      root: "root"
    }}
  />
))`
  &&.root {
    width: ${remCalc(30)};
    height: ${remCalc(30)};
    color: ${({ theme }) => theme.colors.primary};
  }
`;

export const Loading = styled(Loader)`
  margin: 0px;
  padding: 0px;
`;

// --------------------------------------------------------------------------
// ? STYLES FOR: Reshuffle
// --------------------------------------------------------------------------

export const Reshuffle = styled.div`
  align-items: stretch;
  display: flex;
  flex-direction: column;
  margin-bottom: ${remCalc(24)};
  text-align: center;
`;

export const ReshuffleText = styled(TextBase)`
  margin-top: ${remCalc(8)};
  font-size: ${remCalc(14)};
  color: ${({ theme }) => theme.masterColors.darkGrey};
`;

export const NoReshuffleText = styled(TextBase)`
  color: ${({ theme }) => theme.masterColors.darkGrey};
  font-family: Roboto;
  font-size: ${remCalc(14)};
  font-weight: 500;
  margin-top: ${remCalc(8)};
`;
