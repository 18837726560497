import { useContext, useMemo } from "react";
import { ThemeContext } from "styled-components";
import { ThemeTypes } from "themes/colors";
import { StyleDeclarationValue } from "aphrodite";

export type StyleSheetDefinitions<T> = { [K in keyof T]: StyleDeclarationValue };

export type CreateStyleSheet<T> = (theme: ThemeTypes) => StyleSheetDefinitions<T>;

export type CreateStyleSheetWithProps<T, P> = (
  theme: ThemeTypes, props: P
) => StyleSheetDefinitions<T>;

export function useThemedStyleSheet<T>(
  createThemedStyleSheet: CreateStyleSheet<T>
): StyleSheetDefinitions<T> {
  const theme = useContext(ThemeContext);

  const stylesheet = useMemo(() => {
    return createThemedStyleSheet(theme);
  }, [theme]);

  return stylesheet;
}

export function useThemedStyleSheetWithProps<T, P>(
  createThemedStyleSheet: CreateStyleSheetWithProps<T, P>,
  props: P
): StyleSheetDefinitions<T> {
  const theme = useContext(ThemeContext);

  const stylesheet = useMemo(() => {
    return createThemedStyleSheet(theme, props);
  }, [props, theme]);

  return stylesheet;
}
