import ResourceDefenseDocUploadField from "components/clientAdmin/resources/fields/ResourceDefenseDocUploadField";
import ResourceDescriptionField from "components/clientAdmin/resources/fields/ResourceDescriptionField";
import ResourceTitleField from "components/clientAdmin/resources/fields/ResourceTitleField";
import { Field, useFormikContext } from "formik";
import React from "react";
import { DefenseDocument } from "store/documents/types";
import { CustomAnswerOption } from "../../drawers/CustomAnswerOptionsDrawer";

const CustomDefenseForm: React.FC = () => {
  const { setFieldValue, values } = useFormikContext<CustomAnswerOption>();

  const defenseDocuments = values.defense.defenseDocuments || [];
  return (
    <div>
      <Field
        as={ResourceTitleField} 
        name="defense.title" 
      />
      <Field
        as={ResourceDescriptionField}
        label="Description"
        name="defense.description"
        placeholder="Resource description"
        onChangeContent={(content: string) => setFieldValue("defense.description", content)}
      />
      <Field
        as={ResourceDefenseDocUploadField}
        name="defense.defenseDocuments"
        files={defenseDocuments}
        onUpdateFiles={(files: (DefenseDocument | File)[]) => 
          setFieldValue("defense.defenseDocuments", files)
        }
      />
    </div>
  );
};

export default CustomDefenseForm;
