import React, { useState } from "react";
import { Action } from "redux";
import { css } from "aphrodite/no-important";
import { debounce } from "lodash";
import { Field, Form, Formik } from "formik";
import { ThunkDispatch } from "redux-thunk";
import { useDispatch } from "react-redux";

import { AppState } from "store";
import { Defense } from "store/documents/types";
import { getDefenseById, getDefenses } from "store/builder/actions";
import { getTags } from "store/tagLibrary/actions";
import { TagDTO } from "store/tagLibrary/types";
import { NewSelection } from "store/builder/types";
import Button from "components/common/Button";
import Checkbox from "components/common/form/Checkbox";
import DrawerHeader from "components/common/Drawer/components/Header";
import Label from "components/common/form/Label";
import MultiInput, {
  SuggestionType,
} from "components/common/form/MultiInput/MultiInput";
import RadioButtonsGroup from "components/common/form/RadioButtons";
import TextInput from "components/common/form/TextInput";

import {
  addResource,
  saveResource,
  uploadDefenseDocuments,
} from "store/resources/actions";
import { RadioButtonOption } from "components/common/form/RadioButtons/RadioButtonsGroup";
import { ResourceValues } from "store/resources/types";
import AddDefense from "../customFields/AddDefense/AddDefense";
import Drawer from "./Drawer";
import propertiesStyles from "../styles";
import { useAsyncEffect } from "@rtslabs/field1st-fe-common";

type DefenseType = "global" | "custom" | "none";

export type CustomAnswerOption = NewSelection & {
  defenseType: DefenseType;
  defense: ResourceValues;
  saveAsGlobal?: boolean;
};

interface DefenseOptions extends RadioButtonOption {
  value: DefenseType;
}

const addDefenseOptions: DefenseOptions[] = [
  {
    value: "global",
    label: "Choose from Global Defenses Library",
  },
  {
    value: "custom",
    label: "Create a Custom Defense",
  },
  {
    value: "none",
    label: "No Defense",
  },
];

interface Props {
  closeDrawer: () => void;
  initialValues: NewSelection;
  onSaveOption: (
    option: NewSelection,
    defense?: Defense,
    removeDefense?: boolean
  ) => Promise<void>;
  show: boolean;
  disabled: boolean;
}

const MultiSelectOptionsDrawer: React.FC<Props> = ({
  initialValues,
  onSaveOption,
  closeDrawer,
  show,
  disabled,
}) => {
  const [defense, setDefense] = useState<Defense | undefined>();
  const [error, setError] = useState<string>("");
  const [defensesLoading, setDefensesLoading] = useState<boolean>(false);
  const [defensesPage, setDefensesPage] = useState<number>(0);
  const [globalDefenses, setGlobalDefenses] = useState<Defense[]>([]);
  const [isFinalPage, setIsFinalPage] = useState<boolean>(false);
  const [oeTagSuggestions, setOeTagSuggestions] = useState<TagDTO[]>([]);
  const [saving, setSaving] = useState<boolean>(false);

  const dispatch = useDispatch<ThunkDispatch<AppState, void, Action>>();

  const ps = propertiesStyles();

  /** Reset all defense and close the drawer */
  function onCloseDrawer(): void {
    setDefensesPage(0);
    setDefense(undefined);
    setGlobalDefenses([]);
    closeDrawer();
  }

  /*************** DEFENSES ***************/

  /** get the initial defense by id
   * @param id - defense id
   */
  async function getDefense(id: number): Promise<void> {
    const res = await dispatch(getDefenseById(id));
    if (res.response) {
      setDefense(res.response);
    }
  }

  /** Load a page of defenses */
  async function getGlobalDefenses(): Promise<void> {
    setDefensesLoading(true);
    const res = await dispatch(
      getDefenses({ page: defensesPage, sort: "title" })
    );
    if (res.response) {
      setGlobalDefenses([...globalDefenses, ...res.response.content]);
      setIsFinalPage(res.response.last);
    }
    setDefensesLoading(false);
  }

  /** Load more defenses */
  function handleLoadMoreDefenses(): void {
    // Increase the defenses page number to trigger the effect
    if (!isFinalPage) {
      setDefensesPage((prevDefPage) => prevDefPage + 1);
    }
  }

  /** Fetch the first/next page of defenses */
  useAsyncEffect(async () => {
    await getGlobalDefenses();
  }, [defensesPage]);

  /*************** OES ***************/

  /**
   * Search for OE tags
   * @param query
   */
  async function searchOeTags(query: string): Promise<void> {
    const res = await dispatch(getTags({ query }, { archived: false }));
    if (res.response) {
      setOeTagSuggestions(res.response.content);
    }
  }

  const debouncedSearchTags = debounce(searchOeTags, 1000, {
    leading: true,
    trailing: false,
  });

  /*************** Save / Update Option ***************/

  /**
   * POST a new defense and upload any attached defense documents
   * @param defense Defense values
   */
  async function addCustomDefense(
    defense: ResourceValues
  ): Promise<Defense | undefined> {
    const { defenseDocuments = [] } = defense;
    const arResponse = await dispatch(addResource(defense));
    if (arResponse.response) {
      const { id } = arResponse.response;
      // if there are files to upload, upload them and re-submit
      if (id && defenseDocuments.length) {
        // this is always a new defense, so we don't have existing documents
        const docsResponse = await dispatch(
          uploadDefenseDocuments(id, defenseDocuments as File[])
        );
        if (docsResponse.uploadedDocuments) {
          const srResponse = await dispatch(
            saveResource({
              ...defense,
              id,
              defenseDocuments: docsResponse.uploadedDocuments,
            })
          );
          return srResponse.response as Defense;
        }
        if (docsResponse.uploadErrors) {
          setError(docsResponse.uploadErrors.join("\n"));
        }
      } else {
        return arResponse.response as Defense;
      }
    }
  }

  /**
   * Save the option to the form
   */
  async function saveOption(
    values: NewSelection & {
      defense?: ResourceValues;
      defenseType: DefenseType;
    }
  ): Promise<void> {
    setSaving(true);
    let newDefense: Defense | undefined = defense;
    if (values.defense && values.defenseType === "custom") {
      newDefense = await addCustomDefense(values.defense);
    }
    await onSaveOption(values, newDefense, values.defenseType === "none");

    setSaving(false);
    onCloseDrawer();
  }

  /** Load the initial values */
  useAsyncEffect(async () => {
    if (initialValues.defenseIds.length > 0) {
      /* we currently only allow 1 defense per selection, if we add more in the future, we'll need to map these */
      await getDefense(initialValues.defenseIds[0]);
    }
  }, [initialValues]);

  function mapInitialValues(): CustomAnswerOption {
    let defenseType: DefenseType = "none";

    if (initialValues.defenseIds.length > 0) {
      defenseType = "global";
    }

    return {
      ...initialValues,
      defenseType,
      defense: {
        categories: defense?.categories || [],
        defenseDocuments: defense?.defenseDocuments || [],
        status: defense?.status || "DRAFT",
        title: defense?.title || "",
        description: defense?.description || "",
        resourceType: "DEFENSE",
      },
    };
  }

  return (
    <Drawer
      anchor="right"
      content={
        <Drawer.Content>
          <DrawerHeader
            closeDrawer={onCloseDrawer}
            text="Custom Answer Options"
          />
          <Formik
            onSubmit={async (values) => {
              await saveOption(values);
            }}
            initialValues={mapInitialValues()}
          >
            {({ values, setFieldValue }) => (
              <Form translate="yes">
                <Drawer.Section>
                  <Label className={css(ps.label)} htmlFor="">
                    Custom Answer Option
                  </Label>
                  <Drawer.Description>
                    Custom choices are displayed as a list under a multi-select
                    question field. Each choice has the option of an attached
                    Defense and Operational Experience.
                  </Drawer.Description>
                  <Field
                    as={TextInput}
                    className={css(ps.textInput)}
                    name="title"
                    label="Choice Label"
                    helperText="Assistive text"
                    placeholder="Choice Label"
                    disabled={disabled}
                  />
                  <Field
                    as={Checkbox}
                    className={css(ps.checkbox)}
                    name="properties.commentRequired"
                    label="Comment required (*) when answer is selected"
                    checked={values.properties?.commentRequired}
                    disabled={disabled}
                  />
                </Drawer.Section>
                <Drawer.Section>
                  <Label className={css(ps.label)} htmlFor="defenseType">
                    Add a Defense (optional)
                  </Label>
                  <Drawer.Description>
                    If you choose to add a defense, it will display in the
                    Defenses section when its related custom choice is selected.
                    Choose a defense from the global library or create a custom
                    defense.
                  </Drawer.Description>
                  <Field
                    as={RadioButtonsGroup}
                    name="defenseType"
                    label=""
                    options={addDefenseOptions}
                    disabled={disabled}
                  />
                  {values.defenseType && (
                    <AddDefense
                      defense={defense || null}
                      defensesLoading={defensesLoading}
                      defenseType={values.defenseType}
                      globalDefenses={globalDefenses}
                      isFinalPage={isFinalPage}
                      onLoadMore={handleLoadMoreDefenses}
                      onSelectDefense={setDefense}
                      disabled={disabled}
                    />
                  )}
                </Drawer.Section>
                <Drawer.Section>
                  <Label className={css(ps.label)} htmlFor="oeTags">
                    Add OE Tags (optional)
                  </Label>
                  <Drawer.Description>
                    OE tags will determine what OEs have the possibility of{" "}
                    displaying and being included in the team discussion.
                  </Drawer.Description>
                  <Field
                    as={MultiInput}
                    className={css(ps.multiInput)}
                    canUseCustomValues={false}
                    labelField="name"
                    idField="name"
                    name="tags"
                    placeholder="Begin typing OE tag..."
                    disabled={disabled}
                    autoCompleteSuggestions={
                      (oeTagSuggestions as unknown) as SuggestionType[]
                    }
                    selectedValues={
                      (values.tags as unknown) as SuggestionType[]
                    }
                    onUpdateValues={(tags) => {
                      setFieldValue("tags", tags, false);
                    }}
                    onChangeInput={debouncedSearchTags}
                  />
                </Drawer.Section>

                {!disabled && (
                  <Drawer.ButtonContainer>
                    <Button type="submit" loading={saving}>
                      {initialValues.id ? "Update" : "Add"} Option
                    </Button>
                  </Drawer.ButtonContainer>
                )}
              </Form>
            )}
          </Formik>
        </Drawer.Content>
      }
      id="multiSelectOptionsDrawer"
      onClose={onCloseDrawer}
      onOpen={() => {}}
      open={show}
      showCloseIcon={false}
      variant="persistent"
    />
  );
};

export default MultiSelectOptionsDrawer;
