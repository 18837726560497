import React from "react";

import * as S from "./styles";

export interface AdornmentProps {
  handleClick?: () => void;
  position: string;
  visible?: boolean;
  label?: string;
  userDefined?: boolean;
  icon?: string;
  loadingSpinner?: boolean;
}

const Adornment = ({ icon, position, handleClick, userDefined, label, visible, loadingSpinner }: AdornmentProps) => {
  // intended to render the Search icon
  if (position === "start") {
    if (!visible) {
      return null;
    }
    return (
      <S.InputAdornment position={position}>
        <S.SearchIcon className={icon} />
      </S.InputAdornment>
    );
  }

  // Used if we want to pass in a defined onClick and label
  // whiles also supporting conditional rendering
  if (userDefined) {
    if (!visible) {
      return null;
    }

    return (
      <S.InputAdornment position={position}>
        <S.AdornmentButton type="reset" onClick={handleClick}>
          {loadingSpinner ? <S.LoadingSpinner /> : label}
        </S.AdornmentButton>
      </S.InputAdornment>
    );
  }

  // generally, this should be position === 'end'
  // for the SearchInput, this should be the "chip" icon
  // which will trigger a dropdown for search options
  return (
    <S.InputAdornment position={position}>
      <S.FilterButton onClick={handleClick}>
        <S.FilterIcon />
      </S.FilterButton>
    </S.InputAdornment>
  );
};

Adornment.defaultProps = {
  position: "start",
  icon: "icon icon-icons8-search",
  visible: true,
};

export interface SearchInputProps {
  error?: string;
  /**
   * `object` which defines the behavior of the end adornment, which
   * can be a button with an onClick behavior.
   */
  endAdornment?: {
    visible: boolean;
    label: string;
    handleClick: () => void;
    /**
     * overrides the`label` prop and will
     * render a "loading spinner" using MUI CircularProgress
     */
    loadingSpinner?: boolean;
  };
  className?: string;
  /**
   * `object` which defines the behavior for the "start adornment".
   * Usually, within **SearchInput**, this will be a "Search Icon" which
   * can be conditionally rendered using `visible`.
   *
   * Note: `visible` also affects the padding for the `S.TextField` input class.
   * By default, when `visible` is `true`, padding left and right will be `0px`.
   * When `visible` is false, padding left and right will be `15px`. This is necessary
   * for ensuring the value text has enough padding when the `startAdornment` is *not* rendered.
   */
  startAdornment?: {
    visible: boolean;
    label?: string;
    handleClick?: () => void;
    icon?: string;
  };
  fullWidth?: boolean;
  helperText?: string;
  label?: string;
  name: string;
  placeholder?: string;
  /**
   * `boolean`, when `true` will add a border radius to
   * the input
   */
  rounded?: boolean;
  value: string;
  /**
   * based on MUI functionality, this `string` defaults
   * to **outlined** which is the value we'll be using the
   * majority of the time.
   */
  variant?: string;
  handleBlur(e: React.FocusEvent<any>): void;
  handleBlur<T = string | any>(fieldOrEvent: T): T extends string ? ((e: any) => void) : void;
  handleChange: React.FormEventHandler<HTMLInputElement> | React.Dispatch<React.SetStateAction<string>>;
  // handleChange(e: React.ChangeEvent<any>): void;
  // handleChange<T = string | React.ChangeEvent<any>>(
  //   field: T,
  // ): T extends React.ChangeEvent<any> ? void : ((e: string | React.ChangeEvent<any>) => void);
}

const SearchInput = ({
  className,
  endAdornment,
  error,
  fullWidth,
  handleBlur,
  handleChange,
  helperText,
  label,
  name,
  placeholder,
  rounded,
  startAdornment,
  value,
  variant,
}: SearchInputProps) => {
  return (
    <S.SearchInput className={className}>
      {label && <S.InputLabel>{label}</S.InputLabel>}

      <S.TextField
        autoComplete="off"
        startAdornmentVisible={startAdornment ? startAdornment.visible : true}
        name={name}
        helperText={helperText}
        onChange={handleChange}
        onBlur={handleBlur}
        variant={variant}
        fullWidth={fullWidth}
        placeholder={placeholder}
        rounded={rounded}
        value={value}
        InputProps={{
          startAdornment: <Adornment position="start" {...startAdornment} />,
          endAdornment: endAdornment ? (
            <Adornment position="end" userDefined {...endAdornment} />
          ) : (
            <Adornment position="end" />
          ),
        }}
      />
      {error && <S.ErrorLabel>{error}</S.ErrorLabel>}
    </S.SearchInput>
  );
};

SearchInput.defaultProps = {
  variant: "outlined",
  handleBlur: () => {},
};

export { SearchInput };
