import React from "react";
import { History } from "history";

import {
  Table,
  TableCell,
  TableHead,
  TableBody,
  TableRow,
} from "components/common/styled/Table";
import { DataSourceVM } from "store/dataSource/types";
import { SortParams } from "store/common/types";
import Pagination, {
  PaginationWrapper,
} from "components/common/TableUI/Pagination";
import BackToTop from "components/common/TableUI/BackToTop/BackToTop";
import RowsPerPage from "components/common/TableUI/RowsPerPage/RowsPerPage";
import { ActionMenu, ActionMenuItem } from "components/common/ActionMenu";

import S from "./styles";
import NoResults from "../../common/TableUI/NoResults";
import { toTitleCase } from "../../../util";
import { remCalc } from "../../../themes/helpers";
import ReadOnlyContent from "components/common/permissions/ReadOnlyContent";
import Link from "components/common/Link";

/**
 * Generate an appropriate URL for navigating to view or edit a Data Set.
 * Default behavior is edit for basic data sets, and view for all others (if not basic, it's advanced).
 * @param type - the type of Data Set
 * @param id   - id of the Data Set
 */
const generateViewEditURL = (type: string, id: number) =>
  `/forms/data-sets/${
    type !== "BASIC" ? `advanced/${id}/view` : `basic/${id}`
  }`;

type Props = {
  dataSources: Array<DataSourceVM>;
  downloadCSV: (id: number, title: string) => void;
  history: History;
  page: number;
  setConfirmModalOpen: (open: boolean) => void;
  setCreateModalOpen: (open: boolean) => void;
  setPage: (page: number) => void;
  setSize: (size: number) => void;
  setSort: (sort: SortParams) => void;
  setSourceSelected: (ds: DataSourceVM) => void;
  size: number;
  sort: SortParams;
  totalPages: number;
};

/** Table for rendering Data Sets */
export const DSTable = ({
  dataSources,
  downloadCSV,
  history,
  page,
  setConfirmModalOpen,
  setCreateModalOpen,
  setPage,
  setSize,
  setSort,
  setSourceSelected,
  size,
  sort,
  totalPages,
}: Props) => (
  <>
    <Table>
      <TableHead>
        <TableRow noBottomBorder>
          <TableCell
            active={sort[0] === "title"}
            onClick={(dir) => setSort(["title", dir])}
            sortDirection={sort[1]}
            width={remCalc(275)}
          >
            Title & Description
          </TableCell>
          {/* Hidden per POW-2005 -- GK */}
          {/* <TableCell
            active={sort[0] === "type"}
            onClick={(dir) => setSort(["type", dir])}
            sortDirection={sort[1]}
            width={remCalc(118)}
          >
              Type
          </TableCell> */}
          <TableCell
            active={sort[0] === "author"}
            onClick={(dir) => setSort(["author", dir])}
            sortDirection={sort[1]}
            width={remCalc(169)}
          >
            Author
          </TableCell>
          <TableCell
            active={sort[0] === "managed"}
            onClick={(dir) => setSort(["managed", dir])}
            sortDirection={sort[1]}
            width={remCalc(136)}
          >
            Managed
          </TableCell>
          <TableCell
            active={sort[0] === "uses"}
            onClick={(dir) => setSort(["uses", dir])}
            sortDirection={sort[1]}
            width={remCalc(82)}
          >
            Uses
          </TableCell>
          <TableCell
            active={sort[0] === "status"}
            onClick={(dir) => setSort(["status", dir])}
            sortDirection={sort[1]}
            width={remCalc(121)}
          >
            Status
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {dataSources.length > 0 ? (
          dataSources.map((source, idx) => (
            <TableRow key={`data_source_${source.title}_${idx}`}>
              <TableCell width={334}>
                <Link to={generateViewEditURL(source.type, source.id)}>
                  {source.title}
                </Link>

                <S.SmallText>{source.description}</S.SmallText>
              </TableCell>
              {/* Hidden per POW-2005 -- GK */}
              {/* <TableCell width={118}>{
                ["ADVANCED", "BASIC"].includes(source.type) ? toTitleCase(source.type) : source.type
              }</TableCell> */}
              <TableCell width={228}>{source.author || "N/A"}</TableCell>
              <TableCell width={136}>{toTitleCase(source.managed)}</TableCell>
              <TableCell width={82}>{source.uses}</TableCell>
              <TableCell width={121}>{toTitleCase(source.status)}</TableCell>
              <TableCell>
                <ActionMenu>
                  <ReadOnlyContent
                    component={
                      <ActionMenuItem
                        onClick={() =>
                          history.push(
                            generateViewEditURL(source.type, source.id)
                          )
                        }
                        label="View data set"
                      />
                    }
                  >
                    <ActionMenuItem
                      onClick={() =>
                        history.push(
                          generateViewEditURL(source.type, source.id)
                        )
                      }
                      label="View/Edit data set"
                    />
                  </ReadOnlyContent>

                  <ActionMenuItem
                    onClick={() => downloadCSV(source.id, source.title)}
                    label="Download CSV"
                  />

                  <ReadOnlyContent>
                    <ActionMenuItem
                      onClick={() => {
                        setSourceSelected(source);
                        setConfirmModalOpen(true);
                      }}
                      label={
                        source.status === "DISABLED"
                          ? "Enable data set"
                          : "Disable data set"
                      }
                      danger={source.status !== "DISABLED"}
                    />
                  </ReadOnlyContent>
                </ActionMenu>
              </TableCell>
            </TableRow>
          ))
        ) : (
          <NoResults
            icon="document"
            header="No Data Set found"
            body="Create a new Data Set"
            button={{
              children: "Create a Data Set",
              onClick: () => setCreateModalOpen(true),
            }}
          />
        )}
      </TableBody>
    </Table>
    <PaginationWrapper>
      <RowsPerPage
        pageSize={size}
        onClick={(size) => {
          setSize(size);
          setPage(0);
        }}
      />
      <Pagination
        currentPage={page}
        totalPages={totalPages}
        onClick={(page) => setPage(page)}
      />
      <BackToTop />
    </PaginationWrapper>
  </>
);
