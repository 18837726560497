import React, { useEffect, useState } from "react";
import { css } from "aphrodite/no-important";
import { useFormikContext } from "formik";

import {
  FBForm,
  FBOEWidget,
  FBQuestion,
  OperationalExperiencesWidgetQuestionDTO,
} from "components/clientAdmin/formBuilder/types";
import { Input } from "components/common/form/types";
import { SuggestionType } from "components/common/form/MultiInput/MultiInput";
import Label from "components/common/form/Label";

import TagsSection from "./TagsSection";
import propertiesStyles from "../../styles";
import tagsStyles from "./styles";
import { Tag } from "./types";
import {
  getQuestionsWithOeTags,
  suppressOrIncludeOeTag,
  renderQuestionTags,
} from "./helpers";
import { isFormPublished } from "components/clientAdmin/formBuilder/helpers";

interface Props extends Input {
  name: string;
  item: FBOEWidget;
  onUpdate: (
    widgetQuestions: OperationalExperiencesWidgetQuestionDTO[]
  ) => void;
}

const Tags = ({ name, label, item: widget, helperText, onUpdate }: Props) => {
  const { values } = useFormikContext<FBForm>();
  const [questions, setQuestions] = useState<FBQuestion[]>([]);

  // get questions with OE tags
  useEffect(() => {
    const questionsWithTags = getQuestionsWithOeTags(values);
    setQuestions(questionsWithTags);
  }, [values.sections]);

  /**
   * Include or exclude (suppress) an OE tag for a form question
   * @param tag
   * @param question
   */
  function includeOrExcludeTag(tag: Tag, question: FBQuestion) {
    const updatedQuestions = suppressOrIncludeOeTag(
      widget.questions,
      question,
      tag
    );
    onUpdate(updatedQuestions);
  }

  const ps = propertiesStyles();
  const ts = tagsStyles();

  return (
    <>
      <Label className={css(ps.label)} htmlFor={name}>
        {label}
      </Label>
      <p className={css(ps.text)}>{helperText}</p>
      {questions.map((question) => {
        const questionTags: Tag[] = renderQuestionTags(
          widget,
          question,
          css(ts.excludedItem)
        );
        return (
          <TagsSection
            key={question.id}
            title={question.title || "Untitled Question"}
            id={question.id}
            tags={questionTags}
            toggleTag={(tag: SuggestionType) =>
              includeOrExcludeTag((tag as unknown) as Tag, question)
            }
            disabled={isFormPublished(values.workflowType)}
          />
        );
      })}
    </>
  );
};

export default Tags;
