import React, { useState, useMemo, useEffect } from "react";
import { Action } from "redux";
import { useFormikContext, Field } from "formik";
import { ThunkDispatch } from "redux-thunk";
import { useDispatch } from "react-redux";
import { withRouter, RouteComponentProps } from "react-router";
import moment, { Moment } from "moment";

import { AppState } from "store";
import { DefenseDocument } from "store/documents/types";
import { Resource, ResourceValues } from "store/resources/types";
import Button, { TextButton } from "components/common/Button";
import Select from "components/common/form/Select";
import Toast from "components/common/Toast";

import * as S from "./styles";
import { H1 } from "../styles";
import { buildResourceAuthorName } from "./helpers";
import {
  GET_TAG_LIBRARIES,
  GetTagLibrariesAction,
  TagDTO,
} from "store/tagLibrary/types";
import { getTags } from "store/tagLibrary/actions";
import ResourceTitleField from "./fields/ResourceTitleField";
import ResourceDescriptionField from "./fields/ResourceDescriptionField";
import useDebounce from "util/hooks/useDebounce";
import ResourceDefenseDocUploadField from "./fields/ResourceDefenseDocUploadField";
import useGroupTerm from "util/hooks/whiteLabel/useGroupTerm";
import ResourceOECommentField from "./fields/ResourceOECommentField";
import { CategoryDTO } from "store/categories/types";
import { getResourceCategories } from "store/resourceCategories/actions";
import { GET_RESOURCE_CATEGORIES } from "store/resourceCategories/types";

const ResourceHistoryItem = ({
  author,
  label,
  date,
}: {
  author: string;
  label: string;
  date: Moment;
}) => {
  return (
    <S.ResourceHistoryItem>
      <b>{label}:</b> {date.format("MM/DD/YYYY")} at {date.format("HH:mm")} by{" "}
      {author}
    </S.ResourceHistoryItem>
  );
};

const ResourceHistory = ({
  resourceSummary,
}: {
  resourceSummary: Resource;
}) => {
  const { createdDate, lastModifiedDate } = resourceSummary;
  const createdAuthor = buildResourceAuthorName(resourceSummary, "createdBy");
  const createdMoment = moment(createdDate);
  const lastUpdatedAuthor = buildResourceAuthorName(
    resourceSummary,
    "lastModified"
  );
  const lastUpdatedMoment = moment(lastModifiedDate);

  return (
    <S.ResourceHistoryContainer>
      <ResourceHistoryItem
        label="Last Updated"
        author={lastUpdatedAuthor}
        date={lastUpdatedMoment}
      />
      <ResourceHistoryItem
        label="Created"
        author={createdAuthor}
        date={createdMoment}
      />
    </S.ResourceHistoryContainer>
  );
};

interface ResourceFormProps extends RouteComponentProps {
  /** The resource summary from /resources/{id} */
  resourceSummary?: Resource;
}

const ResourceForm = ({ history, resourceSummary }: ResourceFormProps) => {
  const {
    setFieldValue,
    values,
    isSubmitting,
    setStatus,
    status,
  } = useFormikContext<ResourceValues>();
  const [oeTags, setOeTags] = useState<TagDTO[]>([]);
  const [resourceCategories, setResourceCategories] = useState<CategoryDTO[]>(
    []
  );
  const [submitCount, setSubmitCount] = useState<number>(0);

  const dispatch = useDispatch<ThunkDispatch<AppState, void, Action>>();

  const searchOeTags = async (query) => {
    const res: GetTagLibrariesAction = await dispatch(
      getTags({ query }, { archived: false })
    );
    if (res.type === GET_TAG_LIBRARIES.SUCCESS) {
      setOeTags(res.response.content);
    }
  };
  const searchResourceCategories = async (query: string) => {
    const res = await dispatch(
      getResourceCategories({ sort: "title,asc", query })
    );
    if (res.type === GET_RESOURCE_CATEGORIES.SUCCESS) {
      setResourceCategories(
        res.response.content.filter((cat) => cat.archived !== true)
      );
    }
  };

  const debouncedSearchTags = useDebounce({
    method: (query) => searchOeTags(query),
    delayAmount: 300,
  });
  const debouncedSearchCategories = useDebounce({
    method: (query) => searchResourceCategories(query),
    delayAmount: 300,
  });

  const tags = values.tags || [];
  const defenseDocuments = values.defenseDocuments || [];
  const categories = values.categories || [];

  // Group config terms
  const operationExperiencesTerm = useGroupTerm(
    "operationalExperiences",
    "noun",
    "plural",
    "Operational Experiences"
  );
  const defensesTerm = useGroupTerm("defenses", "noun", "plural", "Defenses");

  // Resource types for filter
  const resourceTypes = useMemo(() => {
    return [
      { id: -1, value: "Select resource type" },
      { id: "OPERATIONAL_EXPERIENCE", value: operationExperiencesTerm },
      { id: "DEFENSE", value: defensesTerm },
    ];
  }, [operationExperiencesTerm, defensesTerm]);

  useEffect(() => {
    searchResourceCategories("");
  }, []);

  return (
    <div className="resource-form">
      {/* Error Toast */}
      <Toast
        variant="error"
        onClick={() => setStatus({})}
        visible={status?.type === "failure"}
      >
        {status?.error}
      </Toast>

      <H1>{values.title}</H1>
      <Field as={ResourceTitleField} name="title" />
      <S.ShortFieldWrapper id="resourceType">
        <Field
          as={Select}
          disabled={values.id} // it's already been saved, cannot update the type
          name="resourceType"
          label="Type"
          options={resourceTypes}
        />
      </S.ShortFieldWrapper>
      <Field
        as={ResourceDescriptionField}
        label="Description"
        name="description"
        placeholder="Resource description"
        onChangeContent={(content: string) =>
          setFieldValue("description", content)
        }
      />
      {values.resourceType === "OPERATIONAL_EXPERIENCE" && (
        <>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <S.ShortFieldWrapper>
              <Field
                autoCompleteSuggestions={oeTags}
                as={S.TagsInput}
                idField="name"
                label="Tags"
                labelField="name"
                name="tags"
                placeholder={`Add a${tags.length ? "nother" : ""} tag...`}
                canUseCustomValues={false}
                onChangeInput={debouncedSearchTags}
                selectedValues={tags}
                inputProps={{
                  autoComplete: "off",
                }}
                onUpdateValues={(tags) => setFieldValue("tags", tags)}
              />
            </S.ShortFieldWrapper>
            <S.ShortFieldWrapper>
              <Field
                autoCompleteSuggestions={resourceCategories}
                as={S.TagsInput}
                idField="title"
                label="Categories"
                labelField="title"
                name="categories"
                placeholder={`Add a${
                  categories.length ? "nother" : ""
                } category...`}
                canUseCustomValues={false}
                onChangeInput={debouncedSearchCategories}
                selectedValues={categories}
                inputProps={{
                  autoComplete: "off",
                }}
                onUpdateValues={(categories) =>
                  setFieldValue("categories", categories)
                }
                showSuggestions={true}
              />
            </S.ShortFieldWrapper>
          </div>
          <Field
            as={ResourceOECommentField}
            label="Notes"
            placeholder="Note"
            name="notes"
          />
        </>
      )}
      {values.resourceType === "DEFENSE" && (
        <>
          <S.ShortFieldWrapper>
            <Field
              autoCompleteSuggestions={resourceCategories}
              as={S.TagsInput}
              idField="title"
              label="Categories"
              labelField="title"
              name="categories"
              placeholder={`Add a${tags.length ? "nother" : ""} category...`}
              canUseCustomValues={false}
              onChangeInput={debouncedSearchCategories}
              selectedValues={categories}
              inputProps={{
                autoComplete: "off",
              }}
              onUpdateValues={(categories) =>
                setFieldValue("categories", categories)
              }
            />
          </S.ShortFieldWrapper>
          <S.ShortFieldWrapper>
            <Field
              as={ResourceDefenseDocUploadField}
              name="defenseDocuments"
              files={defenseDocuments}
              onUpdateFiles={(files: (DefenseDocument | File)[]) =>
                setFieldValue("defenseDocuments", files)
              }
            />
          </S.ShortFieldWrapper>
        </>
      )}
      {resourceSummary && <ResourceHistory resourceSummary={resourceSummary} />}
      <div className="resource-buttons">
        <Button
          type="submit"
          onClick={() =>
            setSubmitCount((currSubmitCount) => currSubmitCount + 1)
          }
          loading={isSubmitting}
        >
          Save
        </Button>
        <TextButton variant="cancel" onClick={() => history.goBack()}>
          Cancel
        </TextButton>
      </div>
    </div>
  );
};

export default withRouter(ResourceForm);
