import styled from "styled-components";
import { StyleSheet } from "aphrodite";

import TextInputBase from "components/forms/TextInput";
import { remCalc } from "themes/helpers";

export const TextInput = styled(TextInputBase)`
  :not(:first-of-type) {
    margin-top: ${remCalc(16)};
  }
`;

export const Error = styled.span`
  display: block;
  color: ${(props) => props.theme.colors.error};
  font-size: 0.875em; // 14px
  margin: 10px 5px 0px;
`;

export const RecaptchaContainer = styled.div`
  margin-top: 1em;
  display: flex;
  justify-content: center;
`;

export const styles = StyleSheet.create({
  button: {
    marginBottom: remCalc(8),
    marginTop: remCalc(16),
    width: "100%",
  },
});
