import React from "react";
import { sortBy } from "lodash";

import Loader from "components/common/Loader";
import { FormDTO, FormSummaryVm } from "store/forms/types";
import { getSubmissionTimeError } from "components/DocumentSidebarDrawer/helpers";

import * as S from "../styles";

const filterFormTemplatesByFormTypeID = (formTypeID: number, formTemplates) => {
  return formTemplates.filter((x) => x.type.id === formTypeID);
};

export interface SelectFormProps {
  formTemplates: Array<FormSummaryVm>;
  handleSelectFormTemplate: (formTemplate: FormDTO | null) => void;
  loading: boolean;
  selectedFormTemplateId: number | null;
  selectedFormTypeId: number | null;
}

/**
 * **SelectFormTemplate** handles the second step of the generalized
 * "Add New Form" feature. It takes in an array of "subTypes" which
 * will usually be based on the selected "type" from the companion
 * component **SelectFormType**
 */
const SelectForm = ({
  loading,
  handleSelectFormTemplate,
  selectedFormTemplateId,
  selectedFormTypeId,
  formTemplates,
}: SelectFormProps) => {
  // render loading based on redux loading flag
  // is loading when API call for documents is in flight
  if (loading) {
    return <Loader loading />;
  }

  // render nothing is user hasn't selected a Form or there
  // are no Form Templates
  if (!selectedFormTypeId || formTemplates.length === 0) {
    return null;
  }

  const formTemplatesOfSelectedFormType = selectedFormTypeId
    ? filterFormTemplatesByFormTypeID(selectedFormTypeId, formTemplates)
    : [];

  const onSelectFormTemplate = (formTemplate: FormDTO) => {
    // if one is already selected, pass null to deselect
    const selected = selectedFormTemplateId ? null : formTemplate;
    handleSelectFormTemplate(selected);
  };

  return (
    <>
      {sortBy(formTemplatesOfSelectedFormType, "name").map((formTemplate) => {
        if (selectedFormTemplateId && selectedFormTemplateId !== formTemplate.id) {
          return null;
        }

        const submissionTimeError = getSubmissionTimeError(formTemplate.formSubmissionConstraint);

        return (
          <S.ListItem
            disabled={!!submissionTimeError}
            key={formTemplate.id}
            active={selectedFormTemplateId === formTemplate.id}
            onClick={() => !submissionTimeError && onSelectFormTemplate(formTemplate)}
            label={formTemplate.name}
            subLabel={submissionTimeError}
            endAdornment={{
              type: "button",
              visible: selectedFormTemplateId === formTemplate.id,
              label: "Change",
              buttonType: "change",
            }}
            listItemColumnVariant="form-list-item"
          />
        );
      })}
    </>
  );
};

export default SelectForm;
