import React from "react";
import { css } from "aphrodite/no-important";

import LetsStartCreating from "assets/png/form_builder_empty_state.png";

import styles from "./styles";

export const Placeholder = () => {
  const s = styles();
  return (
    <div className={css(s.placeholder)}>
      <img
        src={LetsStartCreating}
        className={css(s.emptyStateImage)}
        alt="Lets Start Creating"
      />
      <span className={css(s.placeholderTitle)}>
        Let&apos;s Start Creating
      </span>
      <span className={css(s.placeholderText)}>
        To start building your form, select an element to add from the left sidebar.
      </span>
    </div>
  );};
