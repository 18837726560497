import { FilterParams, PaginationParams } from "store/common/types";

export const FORM_TEMPLATES_FILTERS = {
  SET: "FORM_TEMPLATES_FILTERS_SET",
  PARAMS_SET: "FORM_TEMPLATES_FILTERS_PARAMS_SET",
};

export interface FormTemplatesFiltersParams extends FilterParams {
  groupId: number;
  formType: number;
  query: string;
}

export interface FormTemplatesFiltersArgs {
  filterParams: FormTemplatesFiltersParams;
  paginationParams: PaginationParams;
}

export interface SetFormTemplatesFiltersAction {
  type: typeof FORM_TEMPLATES_FILTERS.SET;
  response: FormTemplatesFiltersParams;
}

export interface SetFormTemplatesPaginationAction {
  type: typeof FORM_TEMPLATES_FILTERS.PARAMS_SET;
  response: PaginationParams;
}

export type FiltersActions = SetFormTemplatesFiltersAction;
