import { clearToken } from "store/system/actions";

/** Clear auth token on Unauthorized (401) response from API */
export default (store) => (next) => (action) => {
  // only clear if key exists and status 401 received in previous middleware
  if (action.status && action.status === 401) {
    const { system } = store.getState();
    if (system && system.authToken !== null) {
      next(action);
      return store.dispatch(clearToken());
    }
  }
  // otherwise pass action to next middleware
  return next(action);
};
