import nlp from "compromise";
import { get } from "lodash";
import { useSelector } from "react-redux";

import { AppState } from "store";
import { ClientGroupConfig } from "store/clientConfigs/types";

export const handleNoun = (noun: string, modifier: Props["modifier"]) => {
  const _noun = nlp(noun);

  // If we add more cases, convert to switch -- GK
  if (modifier === "plural") {
    _noun.nouns().toPlural();
  }
  if (modifier === "singular") {
    _noun.nouns().toSingular();
  }
  return _noun.text() || noun;
};

export const handleVerb = (verb: string, modifier: Props["modifier"]) => {
  const _verb = nlp(verb);

  if (modifier === "past") {
    _verb.verbs().toPastTense();
  }

  return _verb.text() || verb;
};

// Order is intentionally not alphabetical
interface Props {
  visibleId: string;
  role?: "verb" | "noun";
  modifier?: "past" | "future" | "plural" | "singular";
  fallback?: string;
}

/**
 * Hook for client group config terms
 * @visibleId - string identifier for term
 * @role  - function of speech
 * @modifier  - tense, plural/singular, etc
 */
export const useGroupTerm = (
  visibleId: Props["visibleId"], 
  role?: Props["role"], 
  modifier?: Props["modifier"],
  fallback?: Props["fallback"],
) => {

  // Find term
  const termValue = useSelector(({ clientGroupConfigs }: AppState) => {
    const allTerms = get(clientGroupConfigs, ["data", "terms"], []);
    return allTerms.find((term: ClientGroupConfig) => term.id === visibleId)?.value || "";
  });

  let result = fallback || "";

  if (termValue !== "") {
    switch(role) {
      case "noun":
        result = handleNoun(termValue, modifier);
        break;
      case "verb":
        result = handleVerb(termValue, modifier);
        break;
      default:
        break;
    }
  }

  return result;
};

export default useGroupTerm;
