import React from "react";
import { useDispatch } from "react-redux";
import { useHistory, useRouteMatch } from "react-router";
import { ThunkDispatch } from "redux-thunk";
import { Action } from "redux";
import withTheme from "@material-ui/core/styles/withTheme";

import { ContentWrapper } from "components/common/Wrappers";
import Loader from "components/common/Loader";
import Breadcrumbs from "components/common/Breadcrumbs";
import Button from "components/common/Button";
import {
  getDataSourceById, getDataSourceCSV,
  getDataSourceValuesTemplate,
  postDataSourceCSV,
  putDataSource,
} from "store/dataSource/actions";
import {
  CSVMapping,
  DataSource,
  GetDataSourceTemplateResponse,
} from "store/dataSource/types";
import { downloadFile } from "util/index";
import DragToUpload from "components/common/Upload/DragToUpload";

import S from "./styles";
import { DSForm } from "./DSForm";

/* Component behavior is dictated by the existence of an "id" param in the URL:
 * 1. "id" exists: user is editing an existing source. fetch source and pre-fill form.
 * 2. "id" undefined: user is creating a new source. provide empty form. */

/* How source submission works:
 * 1. User fills out the metadata and column headers of the form
 *   - when this form is submitted:
 *     . a data source is created in "DRAFT" status
 *     . form complete boolean is set to true
 *     . data source is set in state
 *     . column headers are set in state
 * 2. Since formComplete is true, step 2 is enabled
 *   - user downloads the source headers in CSV format
 *   - user fills in any data records into the CSV and saves it locally
 *   - templateDownloaded is set to true
 * 3. Since formComplete and templateDownloaded are true, step 3 is enabled
 *   - user drags to upload or selects the file manually to upload
 *   - user saves the form, returning them to /forms/data-sets */

type RouteParams = {
  id?: string;
};

/**
 * Format an incoming data list into a EOL delimited string for rendering / user interaction
 * @param csvMappings
 */
const formatDataList = (csvMappings: CSVMapping[]) =>
  csvMappings.map((m) => m.columnName.replace("*", ""));

/** Parent view for adding, editing and viewing an advanced Data Set */
function AdvancedDS() {
  const dispatch = useDispatch<ThunkDispatch<null, void, Action>>();
  const history = useHistory();
  const match = useRouteMatch<RouteParams>();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [uploading, setUploading] = React.useState<boolean>(false);
  const [dataSource, setDataSource] = React.useState<DataSource | null>(null);
  const [formComplete, setFormComplete] = React.useState<boolean>(false);
  const [colHeaders, setColHeaders] = React.useState<string[]>([]);
  const [templateDownloaded, setTemplateDownloaded] = React.useState<boolean>(false);
  const [csvUploaded, setCsvUploaded] = React.useState<boolean>(false);
  const [file, setFile] = React.useState<File | null>(null);

  /** Format and set column headers for display */
  const fmtAndSetHeaders = (csvMappings) => setColHeaders(formatDataList(csvMappings));

  /** If an ID was supplied in the URL, fetch and store the data source matching it */
  React.useEffect(() => {
    (async () => {
      if (match.params.id) {
        setLoading(true);
        const ds = await dispatch(getDataSourceById(match.params.id));
        if (ds.response) {
          setDataSource(ds.response);
          fmtAndSetHeaders(ds.response.csvMappings);
          setFormComplete(true);
          setLoading(false);
        }
      }
    })();
  }, []);

  /** When a file is added to the form, upload it */
  React.useEffect(() => {
    (async () => {
      if (file && dataSource?.id) {
        setUploading(true);
        const fd = new FormData();
        fd.append("file", file);
        const uploadRes = await dispatch(postDataSourceCSV(dataSource.id, fd));
        // TODO handle errors -JA
        if (uploadRes?.response?.status === "FILE_UPLOAD_SUCCESS") {
          setCsvUploaded(true);
        }
        setUploading(false);
      }
    })();
  }, [file]);

  /** Fetch the CSV template (column headers) for a data source */
  const downloadTemplate = () =>
    dataSource?.id && dataSource?.title && dispatch(getDataSourceValuesTemplate(dataSource.id))
      .then((res: GetDataSourceTemplateResponse) => {
        if (res.response) {
          downloadFile(res.response, dataSource.title.split(" ").join("_") + ".csv");
          setTemplateDownloaded(true);
        }
      })
      .catch((err) => {
        throw new Error(err);
      });

  /** Fetch a data source CSV for an existing data source */
  const downloadSource = () =>
    dataSource?.id && dataSource?.title && dispatch(getDataSourceCSV(dataSource.id.toString()))
      .then((res: GetDataSourceTemplateResponse) => {
        if (res.response) {
          downloadFile(res.response, dataSource.title.split(" ").join("_") + ".csv");
        }
      })
      .catch((err) => {
        throw new Error(err);
      });

  const publishSource = async () => dataSource &&
    await dispatch(putDataSource({
      ...dataSource,
      apiConfig: null,
      softDeleteMissing: false,
      status: "PUBLISHED",
    }));

  return (
    <ContentWrapper>
      <Breadcrumbs
        paths={[
          { pathName: "Forms" },
          { pathName: "Data Sets", href: "/forms/data-sets" },
          { pathName: `Advanced Data Set${dataSource?.title ? `: ${dataSource.title}` : ""}` }
        ]}
      />
      <Loader loading={loading}>
        <S.Title>{dataSource?.title ? dataSource.title : "Create an Advanced Data Set"}</S.Title>
        <S.Subtitle>
          Advanced data sets allow for multiple multi-columned rows that can be uploaded through the use of a CSV file.
          The three step process below can be used to create an advanced data set. These data sets can then be used 
          when building a form in Form Builder. If you have a short list of data with only one column,&nbsp;
          <S.Link onClick={() => history.push("/forms/data-sets/basic")}>
            create a basic data set instead.
          </S.Link>
        </S.Subtitle>
        <S.TitleWrapper>
          <S.SectionHeader disabled={false}>1. Create title, description, and column headers</S.SectionHeader>
          <S.EditButton onClick={() => setFormComplete(false)}>EDIT</S.EditButton>
        </S.TitleWrapper>
        <S.FormSection>
          {!formComplete ? (
            <DSForm
              advanced
              dataSource={dataSource}
              setFormComplete={setFormComplete}
              setDataSource={setDataSource}
              setColumnHeaders={fmtAndSetHeaders}
            />
          ) : (
            <div>
              {colHeaders.map((ch) => <S.BlockSpan key={`col_header_${ch}`}>{ch}</S.BlockSpan>)}
            </div>
          )}
        </S.FormSection>
        <hr />
        <S.SectionHeader disabled={!formComplete && !dataSource}>
          2. {match.params.id ? "Download current data set (CSV template)" : "Download template"}
        </S.SectionHeader>
        {formComplete && dataSource && (
          <S.FormSection>
            {match.params.id ? (
              <Button onClick={() => downloadSource()}>
                DOWNLOAD DATA SET
              </Button>
            ) : (
              <>
                <S.Subtitle>
                The column headers assigned in Step 1 are used to create a template for the data set. The step is
                optional and allows you to download an empty template to ensure column headers and data are mapped
                correctly. You can skip this step if you already have a data file that you&apos;re confident matches
                the column headers above.
                </S.Subtitle>
                <Button onClick={() => downloadTemplate()}>
                  {templateDownloaded ? "RE-DOWNLOAD" : "DOWNLOAD"} TEMPLATE
                </Button>
              </>
            )}
          </S.FormSection>
        )}
        <hr />
        <S.SectionHeader disabled={!formComplete}>3. Import data set</S.SectionHeader>
        {formComplete && (
          <S.FormSection>
            <S.Subtitle>
              Once you have a CSV file with all the pertinent data, upload it. Select the CSV file you wish to upload
              by locating on your local machine and dragging the file into the blue box below or click the link to
              browse for the file. Once the system indicates the upload is complete, select Save &amp; Continue.
            </S.Subtitle>
            <DragToUpload
              handleFile={setFile}
              parentLoading={uploading}
              successState={csvUploaded}
            />
            <Button
              onClick={() => {
                publishSource();
                history.push("/forms/data-sets");
              }}
              disabled={!csvUploaded}
            >
              SAVE & CONTINUE
            </Button>
          </S.FormSection>
        )}
      </Loader>
    </ContentWrapper>
  );
}

export default withTheme(AdvancedDS);
