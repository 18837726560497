import styled, { css } from "styled-components";
import { remCalc } from "../../themes/helpers";

export const CommonStyles = {
  FlexDiv: styled.div`
    display: flex;
  `,

  get ColumnDiv() {
    return styled(this.FlexDiv)`
      flex-direction: column;
    `;
  },

  get RowDiv() {
    return styled(this.FlexDiv)`
      flex-direction: row;
    `;
  },

  BlockSpan: styled.span`
    display: block;
  `,

  DivWithBottomMargin: styled.div<{ margin: number}>`
    margin-bottom: ${({ margin }) => remCalc(margin)};
  `,
};

export const CommonCSS = {
  flexColumn: css`
    display: flex;
    flex-direction: column;
  `,

  flexRow: css`
    display: flex;
    flex-direction: row;
  `,
};
