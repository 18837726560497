import React from "react";

import * as S from "components/common/TableUI/TableSummary/styles";
import DownloadResultsButton from "components/clientAdmin/DownloadResultsButton";
import { CurrentPage, PageSize, TotalElements } from "../Pagination/types";

type ExportFormat = "csv" | "print" | "xls";

type ExportType =
  | {
      type: ExportFormat;
      exportUrl?: string;
    }
  | ExportFormat;

export interface TableSummary {
  currentPage?: CurrentPage;
  exportUrl?: string;
  exports?: ExportType[];
  ofWhat: string;
  pageSize?: PageSize;
  summary?: string | JSX.Element | null; // pass this to manually customize the summary text
  totalElements?: TotalElements;
}

const getFrom = (currentPage, pageSize, totalElements) => {
  if (currentPage === 0) {
    if (totalElements === 0) return 0;
    return 1;
  }
  return currentPage * pageSize + 1;
};

const getTo = (
  currentPage: CurrentPage,
  pageSize: PageSize,
  totalElements: TotalElements
) => {
  return Math.min((currentPage + 1) * pageSize, totalElements);
};

const getExports = (
  options: ExportType[],
  exportFileName: string,
  exportUrl?: string
) =>
  options.map((option: ExportType, ix: number) => {
    let exportType;
    let url;
    if (typeof option === "string") {
      exportType = option;
      url = exportUrl;
    } else if (typeof option === "object") {
      exportType = option.type;
      if (option.type !== "print") {
        url = option.exportUrl || exportUrl;
      }
    }
    switch (exportType) {
      case "xls":
        return (
          <DownloadResultsButton
            className="p-2"
            url={url}
            type="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            fileName={`${exportFileName}.xlsx`}
            key={ix}
          >
            <i className="icon-icons8-xls_export" />
          </DownloadResultsButton>
        );
      case "print":
        return (
          <i
            key={ix}
            className="icon-icons8-print p-2"
            onClick={() => window.print()}
          />
        );
      case "csv":
        return (
          <DownloadResultsButton
            className="p-2"
            url={url}
            type="text/csv"
            fileName={`${exportFileName}.csv`}
            key={ix}
          >
            <i className="icon-icons8-export_csv" />
          </DownloadResultsButton>
        );
      default:
        return true;
    }
  });

/**
 * Format a summary string into the following format:
 * "Showing (starting number) to (ending number) of (total number) (items)"
 * @param page    - current page
 * @param size    - current page size
 * @param totalEl - total number of elements
 * @param ofWhat  - type of element
 */
const summaryText = (
  page: number,
  size: number,
  totalEl: number,
  ofWhat: string
) =>
  `Showing ${getFrom(page, size, totalEl)} to ${getTo(
    page,
    size,
    totalEl
  )} of ${totalEl} ${ofWhat}`;

/** Banner for rendering a summary of data shown in a table */
const TableSummary = ({
  summary = null,
  currentPage = 0,
  pageSize = 0,
  totalElements = 0,
  ofWhat,
  exports,
  exportUrl,
}: TableSummary) => {
  return (
    <S.Wrapper>
      <S.SummaryText>
        {summary || summaryText(currentPage, pageSize, totalElements, ofWhat)}
      </S.SummaryText>
      {exports && (
        <S.SummaryExports>
          {getExports(exports, ofWhat, exportUrl)}
        </S.SummaryExports>
      )}
    </S.Wrapper>
  );
};

export default TableSummary;
