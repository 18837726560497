import React, { useMemo, useState, useContext } from "react";
import { bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";
import { orderBy } from "lodash";

import { AppState } from "store";
import { getSafetyResponseComments } from "store/reporting/comments/actions";

import { pluralize } from "components/helpers";

import * as S from "./styles";
import DownloadResultsButton from "components/clientAdmin/DownloadResultsButton";
import SafetyResponseCommentsDrawer from "./drawer/SafetyResponseCommentsDrawer";
import { H3, SmallText } from "../../../styles";
import { SafetyResponse } from "../FormReport";
import { useFetchComments } from "../helpers";
import { ThemeContext } from "styled-components";

interface SafetyReportProps {
  actions: { getComments: (props) => void };
  formId: number;
  totalResponses: number;
  ratingsFilter: string[];
  mappedRiskResponses: {
    Risk: SafetyResponse[];
    "Coaching Moment": SafetyResponse[];
    Satisfactory: SafetyResponse[];
    "Best Practice": SafetyResponse[];
  };
}

const SafetySection = ({ color, riskLevelCounts, riskLevel, questions, openDrawer, totalResponses }) => {
  const percentage = !totalResponses ? 0 : `${((riskLevelCounts / totalResponses) * 100).toFixed(1)}%`;
  const questionsSorted = orderBy(questions, ["responseCount"], ["desc"]);
  const selectionIds = questions && questions.map((question) => question.selectionId) || [];
  const showAllCommentsLink = !(!selectionIds || selectionIds.length === 0);
  const showExportAllResponsesLink = !!(riskLevelCounts);

  return (
    <>
      <div className="d-flex mt-4 mb-3">
        <S.Dot color={color} />
        <H3>{riskLevel}</H3>
      </div>
      <div className="d-flex mb-4">
        <div className="col-4x w-25 mr-3">
          <SmallText className="d-block">{pluralize("Response", riskLevelCounts, true)}</SmallText>
          <S.PercentageText className="d-block mb-3">{`${percentage} Total Responses`}</S.PercentageText>
          
          {showAllCommentsLink && 
            <SmallText className="d-block mb-1 caExportAllResponsesLink">
              <S.StyledDownloadButton onClick={() => openDrawer(selectionIds, riskLevel)} bold>
              View All Comments
              </S.StyledDownloadButton>
            </SmallText>
          }
          {showExportAllResponsesLink && 
            <SmallText className="d-block caExportAllResponsesLink">
              <DownloadResultsButton
                url={`reports/comments/selection?selectionQuestionIds=${selectionIds}&accept=text/csv`}
                type="text/csv"
                fileName={`Export ${riskLevel}.csv`}
              >
                <S.StyledDownloadButton bold>Export All Comments</S.StyledDownloadButton>
              </DownloadResultsButton>
            </SmallText>
          }
        </div>

        <div className="w-75">
          <SmallText className="font-weight-bold d-block mb-3">
            {`Most Frequently Reported with “${riskLevel}” Rating`}
          </SmallText>
          {questionsSorted && questionsSorted.length > 0 ? (
            questionsSorted.map((question) => (
              <SafetySectionDetails
                key={question.selectionId}
                openDrawer={() => openDrawer([question.selectionId], riskLevel)}
                question={question}
              />
            ))
          ) : (
            <>No responses</>
          )}
        </div>
      </div>
      <hr />
    </>
  );
};

const SafetySectionDetails = ({ question, openDrawer }) => (
  <div className="mb-3">
    <div style={{ display: "flex", alignItems: "flex-start" }}>
      <S.QuestionText className="font-weight-bold mr-4">{question.responseCount}</S.QuestionText>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <S.QuestionText>{question.question.title}</S.QuestionText>
        <S.AreaText>{`Area: ${question.area}`}</S.AreaText>
      </div>
      <SmallText className="d-flex ml-auto caExportAllResponsesLink">
        <S.StyledDownloadButton onClick={openDrawer}>View Comments</S.StyledDownloadButton>
        <S.SendCommentIcon />
      </SmallText>
    </div>
  </div>
);

const SafetyReport = ({ actions, totalResponses, ratingsFilter, mappedRiskResponses }: SafetyReportProps) => {
  const theme = useContext(ThemeContext);
  const colors = [
    theme.colors.error,
    theme.masterColors.warning,
    theme.colors.primary,
    theme.colors.success
  ];
  const keyIndexs = Object.keys(mappedRiskResponses);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [drawerRiskLevel, setDrawerRiskLevel] = useState<"Risk" | "Coaching Moment" | "Satisfactory" | "Best Practice">(
    "Risk",
  );
  const [selectedQuestionIds, setSelectedQuestionIds] = useState<number[]>([]);
  const responseComments = useFetchComments(actions.getComments, mappedRiskResponses[drawerRiskLevel]);
  const openDrawer = (selectionIds: number[], riskLevel) => {
    setSelectedQuestionIds(selectionIds);
    setDrawerRiskLevel(riskLevel);
    setDrawerOpen(true);
  };
  const headerText = useMemo(
    () => (
      <div className="d-flex align-items-center">
        <S.Dot color={colors[keyIndexs.indexOf(drawerRiskLevel)]} />
        <span className="d-block">
          {drawerRiskLevel} Comments ({responseComments.length})
        </span>
      </div>
    ),
    [drawerRiskLevel, responseComments],
  );
  const responsesLoaded =
    !!responseComments.length && !!(responseComments.length === mappedRiskResponses[drawerRiskLevel].length);
  const handleExpansionChange = (id: number) => (event: React.ChangeEvent<{}>) => {
    setSelectedQuestionIds((prevState) => {
      if (prevState.includes(id)) return prevState.filter((s) => s !== id);
      return [...prevState, id];
    });
  };
  return (
    <>
      <SafetyResponseCommentsDrawer
        closeDrawer={() => {
          setDrawerOpen(false);
          setSelectedQuestionIds([]);
        }}
        drawerRiskLevel={drawerRiskLevel} // This is used to set file name when exporting comments
        handleExpansionChange={handleExpansionChange}
        headerText={headerText}
        responseComments={responseComments}
        responsesLoaded={responsesLoaded}
        selectedQuestionIds={selectedQuestionIds}
        show={drawerOpen}
      />
      {Object.keys(mappedRiskResponses).map((riskLevel, i) => {
        const riskLevelCountsMap = mappedRiskResponses[riskLevel].map((x) => x.responseCount);
        if (ratingsFilter.includes(riskLevel) || !ratingsFilter.length) {
          return (
            <SafetySection
              riskLevel={riskLevel}
              key={riskLevel}
              color={colors[i]}
              questions={mappedRiskResponses[riskLevel]}
              riskLevelCounts={riskLevelCountsMap.length ? riskLevelCountsMap.reduce((acc, cur) => acc + cur) : 0}
              openDrawer={openDrawer}
              totalResponses={totalResponses}
            />
          );
        }
      })}
    </>
  );
};

const mapStateToProps = (state: AppState, props) => ({});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  actions: {
    getComments: bindActionCreators(getSafetyResponseComments, dispatch),
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SafetyReport);
