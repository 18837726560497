import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Fade from "@material-ui/core/Fade";
import Paper from "@material-ui/core/Paper";
import PopperBase from "@material-ui/core/Popper";
import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { ThemeContext } from "styled-components";
import { toRgba } from "themes/helpers";

const useStyles = () => {
  const theme = useContext(ThemeContext);
  return makeStyles({
    root: {
      border: `1px solid ${toRgba(theme.colors.black, 0.1)}`,
      boxShadow: `0px 16px 24px ${toRgba(theme.colors.lightGrey, 0.2)}`,
    },
  })();
};

interface PopperPropsType {
  anchorEl: any;
  children: any;
  className?: string;
  onClose: () => void;
  open: boolean;
  placement?:
    | "bottom-end"
    | "bottom-start"
    | "bottom"
    | "left-end"
    | "left-start"
    | "left"
    | "right-end"
    | "right-start"
    | "right"
    | "top-end"
    | "top-start"
    | "top";
}

const Popper = (props: PopperPropsType) => {
  const { className, anchorEl, children, onClose, open, placement } = props;
  const classes = useStyles();
  return (
    <ClickAwayListener onClickAway={() => onClose()}>
      <PopperBase className={className} anchorEl={anchorEl} open={open} placement={placement} transition>
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper classes={classes} elevation={0}>
              {children}
            </Paper>
          </Fade>
        )}
      </PopperBase>
    </ClickAwayListener>
  );
};

export default Popper;
