import React from "react";
import { css, StyleSheet } from "aphrodite";
import VisibilitySensor from "react-visibility-sensor";

import { remCalc } from "themes/helpers";
import Loader from "components/common/Loader";

import { SelectInputProps, Select } from "./Select";

const styles = StyleSheet.create({
  selectMenu: {
    height: "24rem",
  },
  loader: {
    padding: `${remCalc(8)} 0`,
  }
});

export interface LazyLoadProps extends SelectInputProps {
  onLoadMore?: () => void;
  isLoadingMore?: boolean;
  isFinalPage?: boolean;
}

const LazyLoadSelect = ({
  onLoadMore,
  isLoadingMore,
  options,
  isFinalPage,
  ...selectProps
}: LazyLoadProps) => {
  return (
    <Select {...selectProps} options={options} MenuProps={{ className: css(styles.selectMenu) }}>
      {options && options.length > 0 && onLoadMore && (
        <VisibilitySensor
          active={!isFinalPage && !isLoadingMore}
          onChange={(isVisible) => isVisible && onLoadMore()}
          intervalDelay={500}
          delayedCall={true}
        >
          <Loader className={css(styles.loader)} loading={!isFinalPage} spinnerProps={{ size: "1rem" }} />
        </VisibilitySensor>
      )}
    </Select>
  );
};

export { LazyLoadSelect };
