import { CALL_API } from "middleware/api";

import {
  GET_ALL_FORM_STATS,
  GET_FORM_TYPE_STATS,
  GET_PARTICIPANT_SUBMISSION_REPORTS,
  GetAllFormStatsProps,
  GetAllFormStatsResponse,
  GetParticipantSubmissionReportsProps,
} from "./types";

/**
 * Get stats for all forms matching query
 * @param clientGroupIds
 * @param maxSubmissionDate
 * @param minSubmissionDate
 * @param page
 * @param size
 * @param formTypeId
 * @param query
 * @param sort
 * @param onlySubordinates
 */
export const getAllFormStats = ({
  clientGroupIds,
  maxSubmissionDate,
  minSubmissionDate,
  participantTreeFilter,
  page,
  size,
  formTypeId,
  query,
  sort,
  onlySubordinates = false,
}: GetAllFormStatsProps) => (dispatch, getState) => {
  const { system } = getState();

  let endpoint = "reports/forms?";

  endpoint += `onlySubordinates=${onlySubordinates}`;
  if (participantTreeFilter) {
    endpoint += `&participantTreeFilter=${participantTreeFilter}`;
  }
  if (clientGroupIds) endpoint += `&clientGroupIds=${clientGroupIds}`;
  if (minSubmissionDate) endpoint += `&minSubmissionDate=${minSubmissionDate}`;
  if (maxSubmissionDate) endpoint += `&maxSubmissionDate=${maxSubmissionDate}`;
  if (page) endpoint += `&page=${page}`;
  if (size) endpoint += `&size=${size}`;
  if (formTypeId) endpoint += `&formTypeId=${formTypeId}`;
  if (query) endpoint += `&query=${query}`;
  if (sort && sort.length == 2 && sort[0])
    endpoint += `&sort=${sort[0]},${sort[1]}`;

  return dispatch({
    [CALL_API]: {
      endpoint: endpoint,
      options: {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${system.authToken}`,
        },
      },
      types: [
        GET_ALL_FORM_STATS.REQUEST,
        GET_ALL_FORM_STATS.SUCCESS,
        GET_ALL_FORM_STATS.FAILURE,
      ],
    },
  })
    .then((res: GetAllFormStatsResponse) => res)
    .catch((err) => {
      throw new Error(err);
    });
};
export type GetAllFormStats = typeof getAllFormStats;

// Get Submissions
export const getParticipantSubmissionReports = ({
  clientGroupIds,
  formIds,
  formTypeIds,
  maxSubmissionDate,
  minSubmissionDate,
  participantId,
  query,
  statuses,
}: GetParticipantSubmissionReportsProps) => (dispatch, getState) => {
  const { system } = getState();

  // Build endpoint url
  let endpoint = `reports/participants/${participantId}/submissions?`;
  if (clientGroupIds && clientGroupIds !== -1)
    endpoint += `&clientGroupIds=${clientGroupIds}`;
  if (formIds && formIds !== -1) endpoint += `&formIds=${formIds}`;
  if (formTypeIds && formTypeIds !== -1)
    endpoint += `&formTypeIds=${formTypeIds}`;
  if (maxSubmissionDate) endpoint += `&maxSubmissionDate=${maxSubmissionDate}`;
  if (minSubmissionDate) endpoint += `&minSubmissionDate=${minSubmissionDate}`;
  if (participantId) endpoint += `&participantId=${participantId}`;
  if (statuses && statuses !== -1) endpoint += `&statuses=${statuses}`;
  if (query) endpoint += `&query=${query}`;

  return dispatch({
    [CALL_API]: {
      endpoint: endpoint,
      options: {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${system.authToken}`,
        },
      },
      types: [
        GET_PARTICIPANT_SUBMISSION_REPORTS.REQUEST,
        GET_PARTICIPANT_SUBMISSION_REPORTS.SUCCESS,
        GET_PARTICIPANT_SUBMISSION_REPORTS.FAILURE,
      ],
    },
  });
};

// Get form type stats
export const getFormTypeStats = ({
  participantId,
}: {
  participantId: string | number;
}) => (dispatch, getState) => {
  const { system } = getState();
  return dispatch({
    [CALL_API]: {
      endpoint: `reports/participants/${participantId}/form-type-stats`,
      options: {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${system.authToken}`,
        },
      },
      types: [
        GET_FORM_TYPE_STATS.REQUEST,
        GET_FORM_TYPE_STATS.SUCCESS,
        GET_FORM_TYPE_STATS.FAILURE,
      ],
    },
  });
};
