import styled from "styled-components";
import { StyleSheet } from "aphrodite/no-important";

import TextBase from "components/common/Text";
import { remCalc } from "themes/helpers";
import mediaQueries from "themes/mediaQueries";
import { ThemeTypes } from "themes/colors";

export type ColumnVariant = "DEFAULT" | "LEFT" | "RIGHT";

export const AddCommentBubble = styled.button`
  border: none;
  max-width: ${remCalc(203)};
  min-width: ${remCalc(203)};
  min-height: ${remCalc(48)};
  background-color: ${({ theme }) => theme.colors.activeFill};
  color: ${({ theme }) => theme.colors.primary};
  border-radius: ${remCalc(24)};

  display: flex;
  justify-content: space-around;
  align-items: center;

  &:hover {
    cursor: pointer;
  }

  @media (${mediaQueries.maxPhone}) {
    max-height: ${remCalc(60)};
    max-width: ${remCalc(180)};
    background-color: ${({ theme }) => theme.colors.white};
    min-height: 0;
    min-width: 0;
  }
`;
export const AddCommentIcon = styled.i`
  font-size: ${remCalc(24)};

  @media (${mediaQueries.maxPhone}) {
    display: none;
  }
`;

export const AddCommentText = styled(TextBase)`
  color: ${({ theme }) => theme.colors.primary};
  font-size: ${remCalc(21)};
  font-weight: 400;

  @media (${mediaQueries.maxPhone}) {
    font-size: ${remCalc(16)};
  }
`;
export const Column = styled.div<{ flex?: string; variant?: ColumnVariant }>`
  flex: ${({ flex }) => flex && flex};

  ${({ variant }) => {
    if (variant === "LEFT") {
      return `
        padding-right: ${remCalc(33)}
        
        @media (${mediaQueries.maxPhone}) {
          margin-bottom: ${remCalc(8)};

          display: flex;
          flex-direction: row;
          justify-content: space-between;

          padding: ${remCalc(0)};
          width: 100%;
        }
      `;
    }
  }}
`;
export const Rating = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media (${mediaQueries.maxPhone}) {
    flex-direction: column;
  }
`;
export const RatingLabel = styled(TextBase)`
  font-size: ${remCalc(24)};
  font-weight: 400;

  @media (${mediaQueries.maxPhone}) {
    margin-bottom: ${remCalc(0)};
    font-size: ${remCalc(20)};
  }
`;
export const Row = styled.div<{ flexEnd?: boolean }>`
  ${({ flexEnd }) => {
    if (flexEnd) {
      return `
        display: flex;
        justify-content: flex-end;
      `;
    }
  }}
`;
export const Text = styled(TextBase)``;


// Safety Rating Scale styles
export const scaleStyles = (theme: ThemeTypes) => {
  return StyleSheet.create({
    scaleContainer: {
      background: theme.masterColors.lightWhite,
      padding: remCalc(10),
      display: "flex",
      flexDirection: "row",

      "@media (max-width: 767px)": {
        flexDirection: "column",
      },
    },
    headerContainer: {
      
      marginRight: remCalc(24),

      "@media (min-width: 767px)": {
        flexBasis: remCalc(220),
        marginBottom: 16
      }
    },
    header: {
      fontSize: remCalc(24),

      "@media (max-width: 767px)": {
        fontSize: remCalc(20)
      },
    },
    scaleRow: {
      fontSize: remCalc(20),
      marginBottom: remCalc(8)
    }
  });
};
