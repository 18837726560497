import React from "react";

import { DocumentVM } from "store/documents/types";
import { QuestionDTO } from "store/forms/types";
import * as S from "./styles";
import moment from "moment";
import { parseTime } from "util/dateUtilities";

interface MinResponse {
  answer: string;
  comments?: string | null;
}

interface CommentProps {
  comments?: string | null;
}

const Comment = ({ comments }: CommentProps) => {
  if (!comments) {
    return null;
  }

  return (
    <S.CommentWrapper>
      <S.ResponseLabel>Comments</S.ResponseLabel>
      <S.Value>{comments}</S.Value>
    </S.CommentWrapper>
  );
};

function toDisplayResponse(answer: string, questionType: string): string {
  switch (questionType) {
    case "YES_OR_NO":
      return answer === "true" ? "Yes" : "No";
    case "DATE":
      return moment(answer).format("YYYY-MM-DD");
    case "TIME":
      const timeMoment = parseTime(answer);
      return timeMoment.isValid() ? timeMoment.local().format("HH:mm") : answer;
    default:
      return answer;
  }
}

function ReadOnlyQuestionResponses({
  document,
  question,
}: {
  document: DocumentVM;
  question: QuestionDTO;
}) {
  const responses: MinResponse[] =
    document.responses?.filter((r) => r.questionId === question.id) || [];

  if (responses.length === 0) {
    return null; // PPP-1970 requested that we don't display N/A responses of a question.
  }
  return (
    <S.Response key={question.id}>
      <S.Row>
        <S.Label>{question.title}</S.Label>
      </S.Row>
      {responses.map((r) => (
        <div key={r.answer}>
          {/* RESPONSE */}
          <S.Row>
            <S.ResponseLabel>Response</S.ResponseLabel>
            <S.Value>{toDisplayResponse(r.answer, question.subType)}</S.Value>
          </S.Row>
          {/* COMMENT */}
          <Comment comments={r.comments} />
        </div>
      ))}
    </S.Response>
  );
}

interface Props {
  document: DocumentVM;
  questions: Array<QuestionDTO>;
}

export function ReadOnlyResponses({ document, questions }: Props) {
  return (
    <S.ViewResponses>
      {questions.map((question) => (
        <ReadOnlyQuestionResponses
          key={question.id}
          document={document}
          question={question}
        />
      ))}
    </S.ViewResponses>
  );
}
