import React from "react";
import { css } from "aphrodite";

import { Label } from "../../Label/futureUiKit/Label";
import styles from "./styles";
import { AssistiveLink } from "../../types";
import { FormikErrors, FormikTouched } from "formik";

interface Props extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  assistiveLink?: AssistiveLink;
  error?: FormikErrors<unknown>;
  helperText?: string;
  label?: string;
  touched?: FormikTouched<unknown>;
  smallLabel?: boolean;
}

/** Basic html textarea with optional label */
export function TextArea({
  assistiveLink,
  autoFocus,
  className = "TextArea",
  cols,
  disabled,
  error,
  helperText,
  id,
  label,
  name,
  onBlur,
  onChange,
  onFocus,
  onKeyDown,
  placeholder,
  required,
  rows,
  smallLabel,
  touched,
  value,
}: Props) {
  const s = styles({ disabled });
  return (
    <div className={css(s.wrapper)}>
      {label && (
        <Label
          assistiveLink={assistiveLink}
          content={label}
          htmlFor={name}
          smallLabel={smallLabel}
          required={required}
        />
      )}
      <textarea
        autoFocus={autoFocus}
        className={`${className} ${css(s.textarea)}`}
        cols={cols}
        disabled={disabled}
        id={id}
        name={name}
        onBlur={onBlur}
        onChange={onChange}
        onFocus={onFocus}
        onKeyDown={onKeyDown}
        placeholder={placeholder}
        rows={rows}
        value={value}
      />
      {helperText && <span className={css(s.helperText)}>{helperText}</span>}
      {error && touched && <span className={css(s.errorText)}>{error}</span>}
    </div>
  );
}
