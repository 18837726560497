import React from "react";

import colors from "themes/colors";
import Icon from "components/common/Icon";

import DesktopTextField from "./TextField/DesktopTextField";
import LockedDesktopSelectField from "./SelectField/LockedDesktopSelectField";
import ProfileFieldSelectField from "./SelectField";
import ProfileFieldTextField from "./TextField";

import { ProfileFieldProps } from "../types";
import * as S from "../styles";

interface RenderInputProps extends ProfileFieldProps {
  isDesktop?: boolean;
}

const RenderInput = ({
  fieldProps,
  isDesktop,
  locked,
  lockedValue,
  selectProps,
  variant = "DEFAULT",
}: RenderInputProps) => {
  if (locked) {
    if (typeof lockedValue === "string") {
      if (isDesktop && fieldProps) {
        return <DesktopTextField {...fieldProps} locked={locked} />;
      }

      if (isDesktop && selectProps) {
        return <LockedDesktopSelectField label={selectProps.label} name={selectProps.name} lockedValue={lockedValue} />;
      }
      return (
        <S.ValueWrapper>
          <S.Value>{lockedValue}</S.Value>
          {variant === "SELECT" && <Icon type="sort_down" color={colors.masterColors.mediumGrey} />}
        </S.ValueWrapper>
      );
    }

    return (
      <S.GroupList>
        {lockedValue &&
          lockedValue.map((x) => (
            <S.GroupLabel locked key={x}>
              {x}
            </S.GroupLabel>
          ))}
      </S.GroupList>
    );
  }

  if (selectProps) {
    return (
      <>
        <ProfileFieldSelectField {...selectProps} isDesktop={isDesktop} />
      </>
    );
  }

  if (fieldProps) {
    return <ProfileFieldTextField {...fieldProps} />;
  }

  return null;
};

const RenderLeft = ({ locked, label }: ProfileFieldProps) => {
  if (locked) {
    return (
      <S.LockedLabel>
        <Icon type="lock" framed={false} color={colors.masterColors.mediumGrey} />
        <S.Label as="p">{label || "..."}</S.Label>
      </S.LockedLabel>
    );
  }

  return (
    <>
      <S.Label as="p" locked={locked}>
        {label || "..."}
      </S.Label>
    </>
  );
};

const ProfileField = ({ isDesktop, ...props }: ProfileFieldProps) => {
  return (
    <S.ProfileField isDesktop={isDesktop}>
      {!isDesktop && (
        <S.Left>
          <RenderLeft {...props} />
        </S.Left>
      )}
      <S.Right>
        <RenderInput {...props} isDesktop={isDesktop} />
      </S.Right>
    </S.ProfileField>
  );
};

export default ProfileField;
