import {
  ParticipantsAggregateSummaryState,
  GET_PARTICIPANTS_AGGREGATE_SUMMARY,
  ParticipantsAggregateSummaryActions,
} from "./types";

const initialState: ParticipantsAggregateSummaryState = {
  loading: false,
  error: false,
  errorMessage: "",
  data: {
    numberOfActiveUsers: 0,
    numberOfAllUsers: 0,
    numberOfGroups: 0,
  },
};

export function participantsAggregateSummaryReducer(
  state = initialState,
  action: ParticipantsAggregateSummaryActions,
): ParticipantsAggregateSummaryState {
  switch (action.type) {
    case GET_PARTICIPANTS_AGGREGATE_SUMMARY.FAILURE:
      return {
        ...state,
        error: true,
        loading: false,
      };
    case GET_PARTICIPANTS_AGGREGATE_SUMMARY.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_PARTICIPANTS_AGGREGATE_SUMMARY.SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.response,
      };
    case "RESET_PARTICIPANTS_SUMMARY":
      return {
        ...state,
        loading: false,
        data: {
          numberOfActiveUsers: 0,
          numberOfAllUsers: 0,
          numberOfGroups: 0,
        },
      };
    default:
      return state;
  }
}
