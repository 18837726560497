import { useContext, CSSProperties } from "react";
import { ThemeContext } from "styled-components";
import { StyleSheet } from "aphrodite";

import { remCalc } from "themes/helpers";

export default function (): { [key: string]: CSSProperties } {
  const theme = useContext(ThemeContext);

  return StyleSheet.create({
    alwaysOption: {
      padding: `${remCalc(6)} 0`,
    },
  
    settingsForm: {
      background: theme.colors.white,
      border: `1px solid ${theme.masterColors.mediumGrey}`,
      borderRadius: "4px",
      padding: "2rem",
      paddingTop: 0
    },

    editTimeframeOption: {
      display: "flex",
      alignItems: "center",
    },

    timeLimitInput: {
      width: "4rem",
      margin: "0 0.5rem",
  
      ":nth-child(1n) .MuiInputBase-input": {
        textAlign: "center",
      }
    },

    // Select
    timeLimitUnit: {
      width: "6rem",
      
      ":nth-child(1n) .select": {
        height: "auto !important",
        padding: "0.5rem !important",
      }
    },

    // TextInput
    timeToInput: {
      width: "125px",
      position: "relative",
      margin: "0 0.5rem", 

      ":nth-child(1n) .MuiInputBase-input": {
        textAlign: "center",
      },
      ":nth-child(1n) input[type=time]::-webkit-clear-button": {
        display: "none",
      },
      ":nth-child(1n) input[type=time]::-webkit-inner-spin-button": {
        position: "absolute",
        right: 0,
      },
    },
  });
};
