import React from "react";
import styled from "styled-components";
import ExpansionPanelBase from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummaryBase from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetailsBase from "@material-ui/core/ExpansionPanelDetails";

import { SmallText } from "components/clientAdmin/styles";
import { remCalc, toRgba } from "themes/helpers";


export const ActionBarContainer = styled.div.attrs({ className: "p-2 d-flex" })`
  background: ${(props) => props.theme.colors.primary};
  color: ${(props) => props.theme.colors.white};
  font-size: ${remCalc(12)};

  margin-left: -10px;

  > a {
    &:last-child {
      border-right: none;
    }
  }
`;

type LinkContainerProps = {
  disabled?: boolean; // If action bar is disabled or link temporarily leads nowhere
}

// This is split from the container to be able to change individual link styles -- GK
export const LinkContainer = styled.a<LinkContainerProps>`
 border-right: solid 1px ${({ theme }) => theme.colors.lightGrey};
 color: ${(props) => props.theme.colors.white};
 cursor: ${({ disabled }) => disabled ? "auto" : "pointer"};
 flex-grow: 1;
 opacity: ${({ disabled }) => disabled ? "0.5" : "1"};
 text-decoration: none;
`;

export const IconContainer = styled.div`
  color: ${({ theme }) => theme.colors.white};
  height: 2rem;
  background: transparent;
  padding: 4px 3px;
  border-radius: 4px;
`;

export const SortDown = styled.i.attrs({ className: "icon-icons8-sort_down" })`
  font-size: 1rem;
`;
export const Message = styled.i.attrs({ className: "icon-icons8-messaging" })`
  font-size: 1rem;
  margin-right: 0.5rem;
`;
export const ExpansionPanel = styled((props) => (
  <ExpansionPanelBase classes={{ expanded: "expanded", root: "root" }} {...props} />
))`
  &&.root {
    border-bottom: 1px solid ${({ theme }) => toRgba(theme.colors.black, 0.12)};
    ::before {
      top: -1px;
      left: 0;
      right: 0;
      height: 1px;
      content: "";
      opacity: 1;
      position: absolute;
      transition: opacity 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
        background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      background-color: ${({ theme }) => toRgba(theme.colors.black, 0.12)};
    }
  }
  &&.expanded {
    margin: 0;
    padding: 0;
  }
`;
export const ExpansionPanelSummary = styled((props) => (
  <ExpansionPanelSummaryBase classes={{ content: "content", root: "root" }} {...props} />
))`
  &&.root {
    min-height: 100px;
  }
  && .content {
    flex-direction: column;
  }
`;
export const ExpansionPanelDetails = styled((props) => (
  <ExpansionPanelDetailsBase classes={{ root: "root" }} {...props} />
))`
  &&.root {
    flex-direction: column;
    padding: 0.5rem 1.5rem;
  }
`;

export const TitleText = styled(SmallText)`
  text-align: left;
  font-weight: 500;
  font-size: 1.25rem;
`;
export const DetailsText = styled(TitleText)`
  text-align: left;
  display: flex;
  align-items: center;
  color: ${({ theme }) => toRgba(theme.masterColors.darkGrey, 0.87)};
  font-weight: 400;
  font-size: 1rem;
`;
export const LinkText = styled(SmallText)`
  color: ${({ onClick, theme }) => onClick && theme.colors.primary};
  cursor: ${({ onClick }) => onClick && "pointer"};
  font-weight: 500;
  text-align: left;
  text-decoration: ${({ onClick }) => onClick && "underline"};
`;
export const DateText = styled(SmallText)`
  text-align: left;
  color: ${({ theme }) => toRgba(theme.masterColors.darkGrey, 0.87)};
  font-size: 0.875rem;
  margin-bottom: 0.5rem;
`;
export const CommentText = styled(SmallText)`
  text-align: left;
  font-size: 0.875rem;
  padding-left: 1.25rem;
  border-left: solid 2px ${({ theme }) => theme.colors.lightGrey};
`;
export const PanelDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1.25rem;
`;
