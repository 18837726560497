import React, { useEffect, FC } from "react";
import { Resource } from "store/resources/types";

import Checkbox from "../Checkbox";
import S from "./styles";

interface Option {
  checked: boolean;
  disabled: boolean;
  label: string;
  value: string;
}

function mapOptions(options: Option[]): Map<string, Option> {
  const optMap = new Map();
  options.forEach((opt, i) => optMap.set(`opt_${i}`, opt));
  return optMap;
}

function renderBoxes(
  options: Map<string, Option>,
  toggleChecked: (key: string) => void,
  loading: boolean,
  outlined?: boolean
) {
  const boxes: JSX.Element[] = [];
  options.forEach((opt, key) =>
    boxes.push(
      <S.CheckboxContainer key={key} outlined={outlined}>
        <Checkbox
          name={opt.label}
          checked={opt.checked}
          disabled={loading || opt.disabled}
          label={opt.label}
          onChange={() => toggleChecked(key)}
          value={opt.value}
        />
      </S.CheckboxContainer>
    )
  );
  return boxes;
}

interface Props {
  initialOptions: Option[];
  loading?: boolean;
  outlined?: boolean;
  resource?: Resource;
  updateResource?: (resource, newCat) => void;
}

export const CheckboxGroup: FC<Props> = ({
  initialOptions,
  loading = false,
  outlined,
  resource,
  updateResource,
}) => {
  const [options, setOptions] = React.useState<Map<string, Option>>(
    mapOptions([])
  );

  useEffect(() => {
    if (initialOptions) {
      setOptions(mapOptions(initialOptions));
    }
  }, [initialOptions]);

  const toggleChecked = (key: string) => {
    const checkedOpt = options.get(key);
    setOptions((prevOptions) => {
      if (checkedOpt) {
        const newOpts = new Map(
          prevOptions.set(key, {
            ...checkedOpt,
            checked: !checkedOpt.checked,
          })
        );
        return newOpts;
      }
      return prevOptions;
    });
    if (updateResource && checkedOpt) {
      updateResource(resource, checkedOpt.value);
    }
  };

  return <>{renderBoxes(options, toggleChecked, loading, outlined)}</>;
};
