import { animations } from "./animations";
import sassVariables from "sass/jsExports.module.scss";

const masterColors = {
  black: sassVariables.black,
  darkGrey: sassVariables.darkGrey,
  darkGreyBlue: sassVariables.darkGreyBlue,
  greyBlue: sassVariables.greyBlue,
  lightBlue: sassVariables.lightBlue,
  lightGrey: sassVariables.lightGrey,
  lightWhite: sassVariables.lightWhite,
  mediumGrey: sassVariables.mediumGrey,
  primary: sassVariables.primary,
  secondary: sassVariables.secondary,
  success: sassVariables.success,
  successFill: sassVariables.successFill,
  error: sassVariables.error,
  errorFill: sassVariables.errorFill,
  visited: sassVariables.visited,
  warning: sassVariables.warning,
  warningFill: sassVariables.warningFill,
  white: sassVariables.white,
  yellow: sassVariables.yellow,
};

const lightTheme = {
  activeFill: "#E5F1FA", // #0 E5F1FA
  black: masterColors.black,
  lightGrey: masterColors.lightGrey,
  white: masterColors.white,
  background: masterColors.white,
  error: masterColors.error,
  errorFill: masterColors.errorFill,
  warning: masterColors.warning,
  warningFill: masterColors.warningFill,
  primary: masterColors.primary,
  success: masterColors.success,
  successFill: masterColors.successFill,
  fieldInputBackground: masterColors.white,
  fieldInputBorder: masterColors.darkGrey,
  header: masterColors.black,
  activeHeader: masterColors.black,
  assistiveText: masterColors.darkGreyBlue,

  //! TEMPORARY UNTIL WE FIGURE OUT A FINAL THEME SHAPE
  //! should remove
  formFieldLabel: masterColors.black,
  formFieldBorder: masterColors.darkGrey,
  formFieldBackground: masterColors.white,
  formFieldColor: masterColors.black,
  formFieldDisabledBackground: masterColors.lightGrey,
  formFieldErrorBackground: masterColors.errorFill,
  radioCircleBorder: masterColors.darkGrey,

  focusIndicatorOutline: masterColors.yellow,

  iconFill: masterColors.white,
  backIcon: "rbg(102, 102, 102)", //#666
  actionIcon: "rgb(0,0,0)", // #000
  dropShadow: "rgba(0, 0, 0, 0.1294)",

  // LIST ITEMS
  listItemsActiveText: masterColors.black,
  listItemsInActiveText: masterColors.black,

  listItemsActiveSubText: masterColors.black,
  listItemsInActiveSubText: masterColors.black,
};

const darkTheme: typeof lightTheme = {
  activeFill: "rgb(255, 247, 231)", // #FFF7E7
  black: "rgb(255,255,255)", // #FFF
  lightGrey: masterColors.lightGrey,
  white: "rgb(0,0,0)", // #000000
  background: masterColors.black, // #000
  error: "rgb(249, 25,25)", // #F91919
  errorFill: "rgb(255,231,231)", // #FFE7E7
  warning: masterColors.warning,
  warningFill: masterColors.warningFill,
  primary: "rgb(249, 172, 25)", // #F9AC19
  success: "rgb(0, 133, 60)", // #00853C
  successFill: masterColors.successFill,
  fieldInputBackground: "rgb(102,102,102)", //#666
  fieldInputBorder: "rgb(255,255,255)", // #FFF
  header: masterColors.white,
  activeHeader: masterColors.black,
  assistiveText: "rgb(244,244,244)", // #F4F4F4

  //! TEMPORARY UNTIL WE FIGURE OUT A FINAL THEME SHAPE
  //! should remove
  formFieldLabel: masterColors.white,
  formFieldBorder: masterColors.white,
  formFieldBackground: masterColors.darkGrey,
  formFieldColor: masterColors.white,
  formFieldDisabledBackground: masterColors.lightGrey,
  formFieldErrorBackground: masterColors.errorFill,
  radioCircleBorder: masterColors.white,

  focusIndicatorOutline: masterColors.yellow,

  iconFill: masterColors.black,
  backIcon: "rgb(255,255,255)", // #fff
  actionIcon: "rgb(255,255,255)", // #FFF
  dropShadow: "rgba(0, 0, 0, 0.1294)",

  // LIST ITEMS
  listItemsActiveText: masterColors.black,
  listItemsInActiveText: masterColors.black,

  listItemsActiveSubText: masterColors.black,
  listItemsInActiveSubText: masterColors.black,
};

export interface ThemeTypes {
  animations: typeof animations;
  colors: typeof lightTheme | typeof darkTheme;
  masterColors: typeof masterColors;
}

export default {
  masterColors,
  lightTheme,
  darkTheme,
};
