import { SignatureType } from "components/clientAdmin/formBuilder/types";

const typedSigTypes: SignatureType[] = [
  "TYPED_NAME",
  "TYPED_EMAIL",
  "TYPED_ANYTHING",
];

export const allowsTypedSignatures = (allowedTypes: SignatureType[] = []) => {
  return typedSigTypes.some((sigType) => allowedTypes.includes(sigType));

};
