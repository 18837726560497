import React from "react";

import styles from "../../styles";
import { css } from "aphrodite/no-important";
import { TextButton } from "components/common/Button";

export type MissingWidgetType = "DEFENSES" | "OPERATIONAL_EXPERIENCES";

function getWidgetName(widgetType: MissingWidgetType) {
  switch(widgetType) {
    case "OPERATIONAL_EXPERIENCES":
      return "OE";
    case "DEFENSES":
      return "DEFENSES";
    default:
      return "WIDGET";
  }
}

interface Props {
  type: MissingWidgetType;
  onAddMissing: () => void;
}

const MissingWidgetBanner = ({ type, onAddMissing }: Props) => {
  const s = styles();

  return (
    <div className={css(s.alert)}>
      <p className={css(s.alertTitle)}>REQUIRED WIDGETS</p>
      <p className={css(s.alertBody)}>
        Some of the options added above have related connections that require
         specific sections to display. Would you like to add the corresponding 
         widgets to your form?
      </p>
      <TextButton
        className={css(s.alertButton)}
        variant="link"
        onClick={onAddMissing}
      >
        + Add {getWidgetName(type)} Section
      </TextButton>
    </div>
  );
};

export default MissingWidgetBanner;
