import { PaginationParams } from "store/common/types";
import {
  TagLibraryFiltersParams,
  TAG_LIBRARY_FILTERS,
  SetTagLibraryFiltersAction,
  SetTagLibraryPaginationAction,
} from "./types";

export const setTagLibraryFilters = (
  filters: TagLibraryFiltersParams
): SetTagLibraryFiltersAction => ({
  response: filters,
  type: TAG_LIBRARY_FILTERS.SET,
});

export const setTagLibraryPagination = (
  pagination: PaginationParams
): SetTagLibraryPaginationAction => ({
  response: pagination,
  type: TAG_LIBRARY_FILTERS.PARAMS_SET,
});
