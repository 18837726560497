import React from "react";

import { createMarkup } from "util/index";
import { OperationalExperience } from "store/resources/types";

import * as S from "./styles";
import useGroupTerm from "util/hooks/whiteLabel/useGroupTerm";

interface Props {
  oes: Array<OperationalExperience>;
}

export function ReadOnlyOEs({ oes }: Props) {
  const oesWithDesc = oes.filter((oe) => typeof oe.description !== "undefined");

  // Group config terms
  const operationExperiencesTerm = useGroupTerm(
    "operationalExperiences",
    "noun",
    "plural",
    "Operational Experiences"
  );

  if (oesWithDesc.length > 0) {
    return (
      <S.ViewOEsWrapper>
        <S.OEsLabel>{operationExperiencesTerm}</S.OEsLabel>

        {oes.map(
          (oe) =>
            oe.description && (
              <S.RenderedOE key={oe.id}>
                <S.RenderedOELeft>
                  <S.ResponseLabel>Included</S.ResponseLabel>
                </S.RenderedOELeft>
                <S.RenderedOERight>
                  <S.RenderedOETitle>{oe.incident}</S.RenderedOETitle>
                  <S.RenderedOEDescription
                    dangerouslySetInnerHTML={createMarkup(oe.description)}
                  />
                </S.RenderedOERight>
              </S.RenderedOE>
            )
        )}
      </S.ViewOEsWrapper>
    );
  }
  return null;
}
