import React, { useState } from "react";

import styles from "./styles";
import { css } from "aphrodite/no-important";
import Loader from "components/common/Loader";

type FormItemProps = {
  disabled?: boolean;
  icon: string;
  name: string;
  onClick: () => Promise<void>;
}

const FormItem = ({ disabled = false, icon, name, onClick }: FormItemProps) => {
  const [hover, setHover] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const s = styles({ formItemDisabled: disabled });
  const addableItem = hover && !disabled;

  async function handleClick() {
    setLoading(true);
    await onClick();
    setLoading(false);
  }
  
  return (
    <div
      className={css(s.FormItem)}
      onMouseEnter={() => setHover(true)} 
      onMouseLeave={() => setHover(false)} 
      onClick={handleClick}
    >
      <div className={css(s.FormItemIcon)}>
        <i style={{ fontSize: "1.25rem" }} className={`icon icon-icons8-${icon}`} />
      </div>
      <span>{name}</span>
      {loading ? (
        <Loader className={css(s.formItemLoader)} loading spinnerProps={{ size: 16 }} />
      ) : addableItem && (
        <i className={`${css(s.AddIcon)} icon icon-icons8-plus_math`} />
      )}
    </div>
  );
};

export default FormItem;
