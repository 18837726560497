import React, { useState, useMemo } from "react";
import styled from "styled-components";

// Global
import RadioButton from "components/common/form/RadioButton";
import { ClientGroup } from "store/clientGroups/types";
import { Label } from "components/common/form/Label/futureUiKit";
import { Option } from "components/common/form/Select/Select";

// Local
import { GroupPicker } from "./groups/drawers/GroupPicker";
import { GroupsContainerPerRole } from "./groups/GroupsContainerPerRole";
import { roles } from "../constants";
import { useFormikContext } from "formik";
import { UserFormFields } from "../types";


type WrapperType = {
    hideBorder?: boolean;
    noPaddingTop?: boolean;
}

const Wrapper = styled.div<WrapperType>`
  border-top: ${({ hideBorder, theme }) => (!hideBorder && `1px solid ${theme.colors.lightGrey}`) || ""};
  margin-top: 23px;
  padding-left: 0;
  padding-right: 0;
  padding-top: ${({ noPaddingTop }) => (!noPaddingTop ? "23px" : "")};
`;

interface Props {
  authorities: {string?: number[]};
  groups?: ClientGroup[];
  updateAuthorities: (role: string, groups: number[]) => void;
}


export const RolesAndGroups = ({ authorities, groups, updateAuthorities }: Props) => {

  const { values, setValues } = useFormikContext<UserFormFields>();

  // Drawer state
  const [groupPickerIsOpen, setGroupPickerIsOpen] = useState<boolean>(false);
  const [selectedRole, setSelectedRole] = useState<Partial<Option>>();

  // Enhance authorities' groups with data from groups
  const _authorities = useMemo(() => {
    const result = {};
    for (const [key] of Object.entries(authorities)) {
      const enhancedGroupsArray: ClientGroup[] = [];
      authorities[key]?.forEach((groupId) => {
        const foundGroupObject = groups?.find((group) => group.id === groupId);
        if (foundGroupObject) enhancedGroupsArray.push(foundGroupObject);
      });
      result[key] = enhancedGroupsArray;
    }
    return result;
  }, [authorities, groups]);

  // Sidedrawer's used to add groups, not edit a group list. This filters
  // groups out that were previously added -- GK
  const _groups = useMemo(() => {
    const selectedGroups = authorities[selectedRole?.id] || [];
    return groups?.filter((group) => !selectedGroups.includes(group.id));
  }, [groups, selectedRole]);

  // Number of total groups
  const totalGroups = useMemo(() => groups?.filter((group) => group.name)?.length || 0, [groups]);

  return <Wrapper>

    {/* Drawer */}
    <GroupPicker
      groups={_groups}
      handleClose={(role?: string, groups?: number[]) => {
        if (role && groups) updateAuthorities(role, groups);
        setGroupPickerIsOpen(false);
      }}
      isOpen={groupPickerIsOpen}
      role={selectedRole}
    />
    {/* Reviewer */}
    <Label content="Client Reviewer"/>
    <RadioButton
      checked={values.isClientReviewer}
      label="Yes"
      onChange={() => {
        setValues({
          ...values,
          isClientReviewer: true,
        });
      }}
    />
    <RadioButton
      checked={!values.isClientReviewer}
      label="No"
      onChange={() => {
        setValues({
          ...values,
          isClientReviewer: false,
        });
      }}
    />
    {/* Roles with their groups */}
    {/* Reviewer is filtered out and handled differently as a flag -- either all groups or no groups -- GK */}
    {roles.filter((role) => role.id !== "ROLE_CLIENT_REVIEWER").map((role, i) => ( 
      <GroupsContainerPerRole
        action={() => {
          setSelectedRole(role);
          setGroupPickerIsOpen(true);
        }}
        groups={_authorities[role.id]}
        key={i}
        role={role}
        totalGroups={totalGroups}
        updateAuthorities={updateAuthorities}
      />
    ))}
   
  </Wrapper>;
};
