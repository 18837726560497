import { Property } from "../../types";
import { PROPERTIES_HELPER_TEXT } from "../../language";

function buildOEDropdownOptions(numToRender: number) {
  const options: { value: number; id: number }[] = [];

  for (let i = 0; i <= numToRender; i++) {
    options.push({ value: i, id: i });
  }

  return options;
}

const detailedSearchPath = "answerSource.properties.detailedSearch";

/** Properties for items with data sources */
const detailedSearchProps: { [key: string]: Property } = {
  /* drawer title */
  title: {
    name: `${detailedSearchPath}.title`,
    label: "Drawer title",
    type: "TEXT_LINE",
    propertyType: "PROPERTY",
  },
  /* drawer placeholder text */
  searchBy: {
    name: `${detailedSearchPath}.searchBy`,
    label: "Search placeholder text",
    type: "TEXT_LINE",
    propertyType: "PROPERTY",
  },
  /* drawer subtitle */
  subtitle: {
    name: `${detailedSearchPath}.subtitle`,
    label: "Drawer subtitle",
    type: "TEXT_LINE",
    propertyType: "PROPERTY",
  },
  /* data source property for which to sort infinite list results by */
  infiniteListSortBy: {
    name: `${detailedSearchPath}.infiniteListSortBy`,
    label: "Sort by",
    type: "DROP_DOWN",
    propertyType: "PROPERTY",
  },
  /* title for the infinite list */
  infiniteListTitle: {
    name: `${detailedSearchPath}.infiniteListTitle`,
    label: "List title",
    type: "TEXT_LINE",
    propertyType: "PROPERTY",
  },
  /* assistive link title */
  linkName: {
    name: `${detailedSearchPath}.linkName`,
    label: "Assistive link text",
    type: "TEXT_LINE",
    propertyType: "PROPERTY",
  },
};

export const commonProps: { [key: string]: Property } = {
  title: {
    name: "title",
    label: "Title",
    type: "TEXT_LINE",
    propertyType: "PROPERTY",
  },
  isRequired: {
    name: "formProperties.isRequired",
    label: "Required",
    type: "CHECKBOX",
    propertyType: "RULE",
  },
  isSearchable: {
    name: "formProperties.isSearchable",
    label: "Searchable",
    type: "CHECKBOX",
    propertyType: "RULE",
  },
  isCloneable: {
    name: "formProperties.isCloneable",
    label: "Clone answer to new Related Documents",
    type: "CHECKBOX",
    propertyType: "RULE",
  },
  answerSource: {
    name: "answerSource",
    label: "Answer Source",
    type: "DATA_SOURCE",
    propertyType: "PROPERTY",
  },
  allowMultipleAnswers: {
    name: "properties.allowMultipleAnswers",
    label: "Allow users to select multiple answers",
    type: "CHECKBOX",
    propertyType: "RULE",
  },
  prefillDocumentCreator: {
    name: "properties.prefillDocumentCreator",
    label: "Prefill Document Creator",
    type: "CHECKBOX",
    propertyType: "RULE",
  },
  voiceToTextEnabled: {
    name: "properties.voiceToTextEnabled",
    label: "Enable Voice-to-Text",
    type: "CHECKBOX",
    propertyType: "RULE",
  },
  assistiveText: {
    name: "properties.assistiveText",
    label: "Assistive Text",
    type: "TEXT_LINE",
    propertyType: "PROPERTY",
  },
  displayConditions: {
    name: "displayConditions",
    label: "Add conditional logic based on this question's response",
    type: "DISPLAY_CONDITIONS",
    propertyType: "RULE",
  },
  placeHolderText: {
    name: "properties.placeHolderText",
    label: "Placeholder Text",
    type: "TEXT_LINE",
    propertyType: "PROPERTY",
  },
  enableComments: {
    name: "properties.enableComments",
    label: "Allow commenting",
    type: "CHECKBOX",
    propertyType: "RULE",
  },
  selections: {
    name: "selections",
    label: "Custom Answer Options",
    type: "SELECTIONS",
    propertyType: "SELECTIONS",
  },
  prefillFromWorkOrder: {
    name: "prefillFromWorkOrder",
    label: "Prefill field from work order",
    type: "PREFILL_FROM_WORK_ORDER",
    propertyType: "RULE",
  },
};

export const fieldProperties: { [key: string]: Property[] } = {
  section: [{ ...commonProps.title, label: "Section Title" }],
  block: [
    {
      name: "content",
      label: "Content Block",
      type: "CONTENT_BLOCK",
      propertyType: "PROPERTY",
    },
  ],
  lineBreak: [],
  bannerMessage: [
    {
      name: "content",
      label: "Banner Message",
      type: "CONTENT_BLOCK",
      propertyType: "PROPERTY",
    },
    {
      name: "backgroundColor",
      label: "Background Color",
      type: "COLOR_PICKER",
      propertyType: "PROPERTY",
    },
    {
      name: "textColor",
      label: "Text Color",
      type: "COLOR_PICKER",
      propertyType: "PROPERTY",
    },
  ],
  participant: [
    commonProps.title,
    commonProps.placeHolderText,
    commonProps.assistiveText,
    commonProps.isRequired,
    commonProps.isSearchable,
    commonProps.allowMultipleAnswers,
    commonProps.isCloneable,
    commonProps.prefillDocumentCreator,
    {
      name: "participantRole",
      label: "Participant Role",
      type: "DROP_DOWN",
      options: [
        { value: "Please select a participant role", id: -1 },
        { value: "ATTENDANT", id: "ATTENDANT" },
        { value: "SUPERVISOR", id: "SUPERVISOR" },
      ],
      propertyType: "PROPERTY",
    },
    commonProps.prefillFromWorkOrder,
  ],
  multiSelect: [
    commonProps.title,
    commonProps.assistiveText,
    commonProps.isRequired,
    commonProps.isSearchable,
    commonProps.enableComments,
    commonProps.isCloneable,
    commonProps.displayConditions,
    {
      ...commonProps.selections,
      helperText:
        "Add 1 or more custom choices and their related conditional logic.",
      detailedSearchProps: [detailedSearchProps.infiniteListSortBy],
    },
  ],
  radioButtons: [
    commonProps.title,
    commonProps.assistiveText,
    commonProps.isRequired,
    commonProps.isSearchable,
    commonProps.enableComments,
    commonProps.isCloneable,
    {
      ...commonProps.selections,
      detailedSearchProps: [detailedSearchProps.infiniteListSortBy],
    },
    commonProps.displayConditions,
  ],
  yesOrNo: [
    commonProps.title,
    commonProps.assistiveText,
    commonProps.isRequired,
    commonProps.enableComments,
    commonProps.isCloneable,
    commonProps.displayConditions,
  ],
  dropdown: [
    commonProps.title,
    commonProps.placeHolderText,
    commonProps.assistiveText,
    commonProps.isRequired,
    commonProps.isSearchable,
    commonProps.isCloneable,
    {
      ...commonProps.selections,
      detailedSearchProps: [detailedSearchProps.infiniteListSortBy],
    },
    commonProps.displayConditions,
  ],
  textLine: [
    commonProps.title,
    commonProps.placeHolderText,
    commonProps.assistiveText,
    commonProps.isRequired,
    commonProps.isSearchable,
    commonProps.isCloneable,
    commonProps.voiceToTextEnabled,
    commonProps.prefillFromWorkOrder,
    {
      ...commonProps.answerSource,
      label: "Auto-Complete Suggestions",
      detailedSearchProps: [
        detailedSearchProps.infiniteListSortBy,
        // detailedSearchProps.infiniteListTitle,
        // detailedSearchProps.linkName,
        // detailedSearchProps.searchBy,
        // detailedSearchProps.subtitle,
        // detailedSearchProps.title,
      ],
    },
  ],
  textArea: [
    commonProps.title,
    commonProps.placeHolderText,
    commonProps.assistiveText,
    commonProps.isRequired,
    commonProps.isSearchable,
    commonProps.isCloneable,
    commonProps.voiceToTextEnabled,
  ],
  date: [
    commonProps.title,
    commonProps.assistiveText,
    {
      name: "properties.defaultToday",
      label: "Default to Today's Date?",
      type: "CHECKBOX",
      propertyType: "RULE",
    },
    commonProps.isRequired,
    commonProps.isSearchable,
    commonProps.isCloneable,
  ],
  defenses: [
    {
      ...commonProps.enableComments,
      name: "autoAppendComment",
    },
  ],
  signature: [
    {
      ...commonProps.isRequired,
      name: "signatureRequired",
    },
    {
      name: "allowedTypes",
      label: "Allowed Typed Signatures",
      type: "ALLOWED_SIGNATURE_TYPES",
      propertyType: "PROPERTY",
    },
  ],
  documentCreator: [commonProps.title, commonProps.isSearchable],
  safetyRating: [
    {
      ...commonProps.isRequired,
      forParent: true,
    },
    // {
    //   name: "displayDescription",
    //   label: "Include safety scale rating explanation chart property",
    //   type: "CHECKBOX",
    //   propertyType: "RULE"
    // },
    {
      ...commonProps.enableComments,
      name: "allowComments",
    },
    {
      name: "requireCommentsFor",
      label: "Require comments for:",
      type: "COMMENTS_REQUIRED_FOR",
      displayCondition: "allowComments",
      propertyType: "RULE",
    },
    {
      name: "subordinateTargetQuestionRootId",
      label: "Notifications",
      helperText:
        "Select a Participants question of who is being observed so their supervisor can be notified about the behavior requiring comments. The supervisor of the document creator will also be notified.",
      type: "NOTIFICATIONS",
      propertyType: "RULE",
      displayCondition: "allowComments",
    },
    {
      name: "questions",
      label: "Areas to Review",
      type: "SELECTIONS",
      helperText:
        "Add 1 or more areas to review and their related subcategories.",
      propertyType: "SELECTIONS",
    },
  ],
  map: [
    {
      name: "includeMap",
      label: "Include Map",
      type: "CHECKBOX",
      helperText: "",
      propertyType: "RULE",
    },
    {
      name: "questions",
      label: "",
      type: "LOCATION",
      helperText: "",
      propertyType: "RULE",
      detailedSearchProps: [
        detailedSearchProps.infiniteListSortBy,
        detailedSearchProps.infiniteListTitle,
        detailedSearchProps.linkName,
        detailedSearchProps.searchBy,
        detailedSearchProps.subtitle,
        detailedSearchProps.title,
      ],
    },
  ],
  operationalExperiences: [
    {
      name: "numberRequired",
      label: "OEs required to submit document",
      type: "DROP_DOWN",
      options: buildOEDropdownOptions(10),
      appendLabel: true,
      helperText: "",
      propertyType: "RULE",
    },
    {
      name: "questions",
      type: "SELECTIONS",
      label: "Tags",
      propertyType: "SELECTIONS",
      helperText: PROPERTIES_HELPER_TEXT.oeTags,
    },
  ],
  time: [
    commonProps.title,
    commonProps.assistiveText,
    {
      name: "properties.defaultNow",
      label: "Default to Current Time?",
      type: "CHECKBOX",
      propertyType: "RULE" as const,
    },
    commonProps.isRequired,
    commonProps.isCloneable,
  ],
  supervisor: [
    commonProps.title,
    commonProps.assistiveText,
    commonProps.isSearchable,
    commonProps.isCloneable,
  ],
};
