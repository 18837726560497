import { FilterParams, PaginationParams } from "store/common/types";

export const TAG_LIBRARY_FILTERS = {
  SET: "TAG_LIBRARY_FILTERS_SET",
  PARAMS_SET: "TAG_LIBRARY_FILTERS_PARAMS_SET",
};

export interface TagLibraryFiltersParams extends FilterParams {
  query: string;
}

export interface TagLibraryFiltersArgs {
  filterParams: TagLibraryFiltersParams;
  paginationParams: PaginationParams;
}

export interface SetTagLibraryFiltersAction {
  type: typeof TAG_LIBRARY_FILTERS.SET;
  response: TagLibraryFiltersParams;
}

export interface SetTagLibraryPaginationAction {
  type: typeof TAG_LIBRARY_FILTERS.PARAMS_SET;
  response: PaginationParams;
}

export type FiltersActions = SetTagLibraryFiltersAction;
