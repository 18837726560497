import {
  FiltersActions,
  TagLibraryFiltersArgs,
  TAG_LIBRARY_FILTERS,
} from "./types";

const initialFilters = {
  query: "",
};

const initialPaginationParams = {
  page: 0,
  size: 10,
  sort: "name,asc",
};

const initialState: TagLibraryFiltersArgs = {
  filterParams: initialFilters,
  paginationParams: initialPaginationParams,
};

export function tagLibraryReducer(
  state = initialState,
  action: FiltersActions
): TagLibraryFiltersArgs {
  const { response, type } = action;

  switch (type) {
    case TAG_LIBRARY_FILTERS.SET:
      return { ...state, filterParams: response };

    case TAG_LIBRARY_FILTERS.PARAMS_SET:
      return { ...state, paginationParams: response };

    default:
      return state;
  }
}
