import { get } from "lodash";

import { CALL_API } from "middleware/api";

import { GET_USER, USER_FORM_STATS, UpdateUserConfig, UPDATE_USER_INFO } from "./types";

export const getUser = () => (dispatch, getState) => {
  const { system } = getState();

  if (!system.authToken) {
    return null;
  }

  return dispatch({
    [CALL_API]: {
      endpoint: "users/me",
      options: {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${system.authToken}`,
        },
      },
      types: [GET_USER.REQUEST, GET_USER.SUCCESS, GET_USER.FAILURE],
    },
  });
};

export const getUserFormStats = () => (dispatch, getState) => {
  const {
    system: { authToken },
    user,
  } = getState();
  const userParticipantID = get(user, "data.participantId");

  return dispatch({
    [CALL_API]: {
      endpoint: `reports/participants/${userParticipantID}/form-type-stats`,
      options: {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${authToken}`,
        },
      },
      types: [USER_FORM_STATS.REQUEST, USER_FORM_STATS.SUCCESS, USER_FORM_STATS.FAILURE],
    },
  });
};

export const updateUserInfo = (user: UpdateUserConfig) => (dispatch, getState) => {
  const {
    system: { authToken },
  } = getState();

  const body: any = { ...user };
  if (body.workLocationId <= 0) {
    body.workLocationId = undefined;
  }

  return dispatch({
    [CALL_API]: {
      endpoint: "users/me",
      options: {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(body),
      },
      types: [UPDATE_USER_INFO.REQUEST, UPDATE_USER_INFO.SUCCESS, UPDATE_USER_INFO.FAILURE],
    },
  })
    .then((res) => res)
    .catch((err) => {
      throw new Error(err);
    });
};
