import React from "react";
import styled from "styled-components";
import TextFieldBase from "@material-ui/core/TextField";
import InputLabelBase from "@material-ui/core/InputLabel";
import ChipBase from "@material-ui/core/Chip";
import InputAdornmentBase from "@material-ui/core/InputAdornment";
import MenuItemBase from "@material-ui/core/MenuItem";
import MenuListBase from "@material-ui/core/MenuList";
import PopperBase from "@material-ui/core/Popper";

import COLORS from "themes/colors";
import mediaQueries from "themes/mediaQueries";
import { remCalc } from "themes/helpers";
import { ReactComponent as ErrorIcon } from "assets/svg/error.svg";

import { InternalVariantType } from "./TextInput";
import { TextInput as TextInputProps } from "../types";

const TextInput = styled.div<Partial<TextInputProps>>`
  display: flex;
  flex-direction: column;
  // opacity: ${({ disabled }) => (disabled ? "10%" : "100%")};
  width: 100%;
`;

const TextField = styled(
  ({
    internalVariant,
    isActive,
    rounded,
    error,
    renderedStartAdornment,
    ...otherProps
  }) => (
    <TextFieldBase
      {...otherProps}
      error={!!error}
      classes={{
        root: "root",
      }}
      InputLabelProps={{
        classes: {
          outlined: "label-outlined",
          error: "label-error",
          focused: "label-focused",
        },
      }}
      InputProps={{
        ...otherProps.InputProps,
        classes: {
          root: "input-root",
          input: "input",
          notchedOutline: "notchedOutline",
          adornedStart: "adornedStart",
          adornedEnd: "adornedEnd",
          focused: "focused",
          error: "error",
          disabled: "disabled",
        },
      }}
    />
  )
)`
  
&& .input-root {
  background-color: ${({ theme }) => theme.colors.fieldInputBackground};
}

  && .focused .notchedOutline {
    outline: 0;
    border-width: 1px;

    ${({ isActive, theme }) => {
      if (isActive) {
        return `
          border-color: ${theme.colors.primary};
          box-shadow: 0 0 0 1px ${theme.colors.primary};
        `;
      } else {
        return `
          border-color: ${theme.colors.focusIndicatorOutline};
          box-shadow: 0 0 0 ${remCalc(3)} ${theme.colors.focusIndicatorOutline};
        `;
      }
    }}
  }

  && .error {
    background-color: ${({ theme }) => theme.colors.formFieldErrorBackground};
  }

  &&.error:not(.focused) .notchedOutline {
    border-color: ${({ theme }) => theme.colors.error};
  }

  && .label-error {
    color: ${({ theme }) => theme.colors.error};
  }

  && .label-focused {
    color: ${({ theme }) => theme.colors.primary};
  }

  && .input {
    color: ${({ theme }) => theme.colors.formFieldColor};
    font-size: ${remCalc(16)};
    padding: padding: 1rem;

    &::placeholder {
      color: ${({ theme }) => theme.masterColors.darkGreyBlue};
      opacity: 1;
    }
    
    // remove left padding for TextArea
   
    ${({ internalVariant }) =>
      internalVariant === "TextArea" &&
      `
      padding: 0px;
    `}
  }

  && .notchedOutline {
    ${({ theme }) => {
    return `
        border-color: ${theme.colors.formFieldBorder};
        color: ${theme.colors.formFieldColor};
      `;
    }}

    ${({ rounded }) => rounded && "border-radius: 18px;"}
  }

  && .adornedStart {
    padding-left: ${({ renderedStartAdornment }) =>
      renderedStartAdornment ? "14px" : "0px"};
  }

  && .disabled .input {
    color: ${({ theme }) => theme.masterColors.darkGreyBlue};
  }

  && .disabled.input-root {
    background-color: ${({ theme }) => theme.colors.lightGrey};
  }

  && .disabled .notchedOutline {
    border-color: ${({ theme }) => theme.colors.mediumGrey};
  }
`;

// FOR TEXT FIELD SUB COMPONENTS

const StartAdornmentIcon = styled.span<{ darkMode?: boolean }>`
  color: ${({ darkMode }) =>
    darkMode ? COLORS.masterColors.white : COLORS.masterColors.darkGrey};
  font-size: ${remCalc(17)};

  margin-right: 0px;
`;

const EndAdornmentButton = styled.button`
  border: none;
  color: red;
  text-decoration: underline;
  background: none;

  &::hover {
    cursor: pointer;
  }
`;

const InputAdornment = styled(({ ...otherProps }) => (
  <InputAdornmentBase {...otherProps} />
))``;

const EndAdornmentIcon = styled.span<{ darkMode?: boolean }>`
  font-size: ${remCalc(24)};
  color: ${({ theme }) => theme.masterColors.darkGrey};
`;

const InputLabel = styled(({ smallLabel, ...otherProps }) => (
  <InputLabelBase {...otherProps} classes={{ root: "root" }} />
))`
  &&.root {
    color: ${({ theme }) => theme.colors.formFieldLabel};
    margin-bottom: ${remCalc(8)};
    font-size: ${remCalc(20)};
    font-weight: 500;

    ${({ smallLabel }) =>
      smallLabel &&
      `
      color: ${COLORS.masterColors.darkGrey};  
      font-size: ${remCalc(13)};
      margin-bottom: ${remCalc(5)};
      `}
  }
`;

const ErrorSpan = styled.span`
  display: block;
  color: ${({ theme }) => theme.colors.error};
  font-size: ${remCalc(16)};
  margin: ${remCalc(3)} ${remCalc(5)} 0 ${remCalc(16)};
  font-family: "Roboto";
`;

const ErrorIconContainer = styled.span`
  display: inline-block;
  height: ${remCalc(16)};
  padding-right: ${remCalc(8)};
  vertical-align: baseline;
  width: ${remCalc(16)};

  && .MuiSvgIcon-root {
    height: 100%;
    width: 100%;
  }
`;

const StyledErrorIcon = styled(ErrorIcon)`
  .error-fill {
    ${({ theme }) => {
      return `
        fill: ${theme.colors.error};
        stroke: ${theme.colors.error};
      `;
    }}
  }
`;

const ErrorText: React.FC = ({ children }) => {
  return (
    <ErrorSpan>
      {/*
      Removed for UAT push on 5/20/2020 due to looking inconsistent with other
      error messages. Replace after the push. —Andrew Dawson

      <ErrorIconContainer>
        <StyledErrorIcon fillClassName="error-fill" />
      </ErrorIconContainer>
      */}
      {children}
    </ErrorSpan>
  );
};

const HelperText = styled.span<{ hasValue?: boolean }>`
  display: block;
  color: ${({ theme }) => theme.masterColors.darkGrey};
  font-size: ${remCalc(16)};
  margin: ${remCalc(3)} ${remCalc(5)} 0 ${remCalc(16)};
  font-family: "Roboto";
`;

const AssistiveIcon = styled.i`
  color: ${({ theme }) => theme.colors.primary};
  text-decoration: none;
  display: none;

  @media (${mediaQueries.minTablet}) {
    display: inline-block;
    font-size: ${remCalc(34)};
  }
`;

const AssistiveLink = styled.a<{
  endAdornment?: boolean;
  multiline?: boolean;
  internalVariant?: InternalVariantType;
}>`
  font-family: "Roboto", sans-serif;
  color: ${({ theme }) => theme.colors.primary};
  font-size: ${remCalc(14)};

  @media (${mediaQueries.minTablet}) {
  }
`;

// @TODO update to AssistiveLink styles
type AssistiveLinkButtonType = {
  endAdornment?: boolean;
  internalVariant?: InternalVariantType;
  isTablet?: boolean;
  multiline?: boolean;
};
const AssistiveLinkButton = styled.button<AssistiveLinkButtonType>`
  font-family: "Roboto", sans-serif;
  color: ${({ theme }) => theme.colors.primary};
  font-size: ${remCalc(14)};
  border: none;
  background: none;

  &:hover {
    cursor: pointer;
  }

  @media (${mediaQueries.minTablet}) {
  }
`;

const LabelRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Chip = styled(({ ...otherProps }) => <ChipBase {...otherProps} />);

const SuggestionsPopper = styled(({ positionOffset, ...props }) => <PopperBase {...props} classes={{ root: "root" }} />)`
  z-index: 1200; // 1200 to get it over the Material UI Drawer
  position: relative !important;
  transform: none !important;
  top: ${({ positionOffset }) => positionOffset || "1.3rem important"}

  && .root {
    background: ${({ theme }) => theme.colors.white};
  }
`;

const SuggestionsOptions = styled(MenuListBase).attrs({
  classes: {
    root: "root",
  }
})`
  &&.root {
    position: relative;
    top: ${remCalc(-20)};
    width: 100%;
    box-shadow: 0px 1px 2px ${({ theme }) => theme.masterColors.mediumGrey};
    border-radius: ${remCalc(4)};
    max-height: 250px;
    overflow-y: scroll;
    overflow-x: none;
  }
`;

const SuggestionsOption = styled(({ ...props }) => (
  <MenuItemBase
    {...props}
    classes={{
      root: "root",
      selected: "selected",
    }}
  />
))`
&&.root {
    &:hover {
      background: ${({ theme }) => theme.colors.activeFill};
    }
  }
`;

export {
  LabelRow,
  AssistiveLink,
  TextInput,
  TextField,
  InputLabel,
  ErrorText,
  Chip,
  EndAdornmentButton,
  EndAdornmentIcon,
  StartAdornmentIcon,
  InputAdornment,
  HelperText,
  AssistiveLinkButton,
  AssistiveIcon,
  SuggestionsPopper,
  SuggestionsOption,
  SuggestionsOptions
};
