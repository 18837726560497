import React from "react";
import { css } from "aphrodite/no-important";

import Button, { TextButton } from "components/common/Button";
import Modal from "components/common/Modal";

import baseStyles from "../../../../styles"; // Form Builder base styles

interface Props {
  show: boolean;
  modalCallbackValues?: unknown;
  onAccept: (modalCallbackValues?: unknown) => void;
  onDecline: () => void;
  modalType: "ds" | "options" | "replaceDs" | "removeOption";
}

const SwitchOptionsTypeModal = ({ modalCallbackValues, show, onAccept, modalType, onDecline }: Props) => {
  const bs = baseStyles();
  const dataSetMessage = "Adding a data set will remove any current selections and display conditions.";
  const selectionsMessage = "Switching to custom selections will remove any existing display conditions.";
  const dataSetReplaceMessage = "Replacing your data set will remove any existing display conditions.";
  const removeOptionMessage = "This selection is associated with at least one display condition. Removing it will also remove any associated display conditions.";

  function renderMessage() {
    switch(modalType) {
      case "ds":
        return dataSetMessage;
      case "options":
        return selectionsMessage;
      case "replaceDs":
        return dataSetReplaceMessage;
      case "removeOption":
        return removeOptionMessage;
    }
  }

  return (
    <Modal
      border="none"
      height="auto"
      width="456px"
      padding="24px"
      open={show}
      handleClose={onDecline}
      content={
        <div>
          <div>
            <p>{renderMessage()}</p>
            <p>Would you like to continue?</p>
          </div>
          <div className={css(bs.row, bs.justifyEnd)}>
            <TextButton onClick={onDecline} variant="cancel">
              CANCEL
            </TextButton>
            <Button onClick={() => onAccept(modalCallbackValues)}>
              CONTINUE
            </Button>
          </div>
        </div>
      }
    />
  );
};

export default SwitchOptionsTypeModal;
