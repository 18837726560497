import Breadcrumbs from "components/common/Breadcrumbs";
import { ContentWrapper } from "components/common/Wrappers";
import { get } from "lodash";
import qs from "qs";
import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import { Action, bindActionCreators, Dispatch } from "redux";
import { AppState } from "store";
import { ClientGroup } from "store/clientGroups/types";
import * as formsActions from "store/forms/actions";
import { FormTypeDTO } from "store/forms/types";
import {
  GetAllFormStats,
  getAllFormStats,
} from "store/reporting/forms/actions";
import { userClientAdminGroupIds } from "store/user/selectors";
import { SortDirection } from "../../../store/common/types";
import { AllFormStats } from "../../../store/reporting/forms/types";
import Loader from "../../common/Loader";
import withClientGroups, { Groups } from "../dataHelpers/withClientGroups";
import { GroupsListDrawer } from "../groups/GroupsListDrawer";
import { generateGroupOptions, getStatsForFormType } from "../helpers";
import { H1 } from "../styles";
import { ReportsFilters } from "./ReportsFilters";
import { ReportsTable } from "./ReportsTable";
import FormTypeSummary from "./statistics/FormTypeSummary";
import * as S from "./styles";
import { DateRangeValue } from "components/common/DateRange";
import {
  getMinMaxSubmissionDatesFromTimeFilter,
  getPastDays,
  TimeFilterType,
} from "components/helpers/filters";
import {
  getAllFormTypeStats,
  GetAllFormTypeStats,
} from "../../../store/reporting/formTypes/actions";
import { FormTypeStats } from "../../../store/reporting/formTypes/types";
import { reportsFiltersSelector } from "store/filters/reports/selectors";
import { ThunkDispatch } from "redux-thunk";
import {
  setReportsFilters,
  setReportsPagination,
} from "store/filters/reports/actions";

interface Props extends RouteComponentProps {
  groups: Groups;
  actions: {
    forms: {
      getAllForms: (clientGroupIds?: number[]) => void;
      getAllFormTypes: () => void;
    };
    getAllFormStats: GetAllFormStats;
    getAllFormTypeStats: GetAllFormTypeStats;
  };
  // FIXME any
  match: any;
  store: {
    ownClientGroups: ClientGroup[];
    formsPagination: {
      currentPage: number;
      totalPages: number;
      pageSize: number;
    };
    formTypeStats?: Array<FormTypeStats>;
    formTypeLoadingStats?: boolean;
    formTypeErrorLoadingStats?: boolean;
    formStats?: Array<AllFormStats>;
    loadingStats?: boolean;
    errorLoadingStats?: boolean;
    caGroupIds: Array<number>;
  };
}

const Reports = ({
  actions: { getAllFormStats, getAllFormTypeStats },
  // groups,
  location,
  store: {
    ownClientGroups,
    formsPagination,
    formStats,
    formTypeStats,
    formTypeLoadingStats,
    formTypeErrorLoadingStats,
    loadingStats,
  },
}: Props) => {
  const filtersSelect = useSelector(reportsFiltersSelector);
  const dispatch = useDispatch<ThunkDispatch<AppState, void, Action>>();
  const { filterParams, paginationParams } = filtersSelect;
  const params = qs.parse(location.search, { ignoreQueryPrefix: true }) || {};
  const defaultGroupId = params.group || filterParams.groupId;
  const groupFilterOptions = generateGroupOptions(ownClientGroups);

  const [documentType, setDocumentType] = useState<number>(
    filterParams.documentType
  );

  const [subordinatesFilter, setSubordinatesFilter] = useState<string>(
    filterParams.subordinatesFilter
  );
  const [clientGroupId, setClientGroupId] = useState<number>(defaultGroupId);
  const [dateRange, setDateRange] = useState<DateRangeValue | null>(
    filterParams.dateRange as any
  );
  const [timeFilterType, setTimeFilterType] = useState<TimeFilterType>(
    filterParams.timeFilterType as TimeFilterType
  );
  const [query, setQuery] = useState<string>(filterParams.query);
  const [newPageNum, setNewPageNum] = useState<number>(
    paginationParams.page || 0
  );
  const [newPageSize, setNewPageSize] = useState<number>(
    paginationParams.size || 10
  );
  const sorting: string[] = paginationParams.sort
    ? (paginationParams.sort as string[])
    : ["name", "asc"];
  const [sortingType, setSortingType] = useState<string>(sorting[0]);
  const [sortingDirection, setSortingDirection] = useState<SortDirection>(
    sorting[1] as SortDirection
  );
  const [showGroupsDrawer, setShowGroupsDrawer] = useState<boolean>(false);
  const [selectedFormType, setSelectedFormType] = useState<FormTypeDTO | null>(
    null
  );
  const [queryString, setQueryString] = useState<string>("");
  const [
    selectedFormTypeData,
    setSelectedFormTypeData,
  ] = useState<FormTypeStats | null>(null);

  const setGroupIdValue = (groupId: number) => {
    dispatch(setReportsFilters({ ...filterParams, groupId }));
    setClientGroupId(groupId);
  };
  const setFormTypeValue = (formType: number) => {
    dispatch(setReportsFilters({ ...filterParams, documentType: formType }));
    setDocumentType(formType);
  };
  const setTimeFilterTypeValue = (value: TimeFilterType) => {
    setTimeFilterType(value);
    dispatch(setReportsFilters({ ...filterParams, timeFilterType: value }));
  };
  const setDateRangeValue = (value: DateRangeValue | null) => {
    setDateRange(value);
    dispatch(setReportsFilters({ ...filterParams, dateRange: value as any }));
  };
  const setSubordinatesValue = (value: string) => {
    setSubordinatesFilter(value);
    dispatch(setReportsFilters({ ...filterParams, subordinatesFilter: value }));
  };
  const setQueryValue = (q: string) => {
    dispatch(setReportsFilters({ ...filterParams, query: q }));
    setQuery(q);
  };
  const setNewPageNumValue = (page: number) => {
    dispatch(setReportsPagination({ ...paginationParams, page }));
    setNewPageNum(page);
  };
  const setNewPageSizeValue = (size: number) => {
    dispatch(setReportsPagination({ ...paginationParams, size }));
    setNewPageSize(size);
  };
  const setSortTypeValue = (sortType: string) => {
    dispatch(
      setReportsPagination({
        ...paginationParams,
        sort: [sortType, sortingDirection === "asc" ? "desc" : "asc"],
      })
    );
    setSortingType(sortType);
  };
  const setSortDirectionValue = (direction: SortDirection) => {
    dispatch(
      setReportsPagination({
        ...paginationParams,
        sort: [sortingType, direction],
      })
    );
    setSortingDirection(direction);
  };

  const handleClear = () => {
    dispatch(
      setReportsFilters({
        ...filterParams,
        timeFilterType: "ALL_TIME",
        dateRange: null,
      })
    );
    setTimeFilterType("ALL_TIME");
    setDateRange(null);
  };

  // get form type data
  useEffect(() => {
    const {
      minSubmissionDate,
      maxSubmissionDate,
    } = getMinMaxSubmissionDatesFromTimeFilter(timeFilterType, dateRange);
    getAllFormTypeStats({
      minSubmissionDate,
      maxSubmissionDate,
      clientGroupIds:
        clientGroupId && clientGroupId !== -1 ? clientGroupId.toString() : "",
      onlySubordinates: false,
      participantTreeFilter: subordinatesFilter,
    });
  }, [
    getAllFormTypeStats,
    dateRange,
    timeFilterType,
    clientGroupId,
    subordinatesFilter,
  ]);

  // get form list
  useEffect(() => {
    const {
      minSubmissionDate,
      maxSubmissionDate,
    } = getMinMaxSubmissionDatesFromTimeFilter(timeFilterType, dateRange);
    getAllFormStats({
      minSubmissionDate,
      maxSubmissionDate,
      clientGroupIds:
        clientGroupId && clientGroupId !== -1 ? clientGroupId.toString() : "",
      onlySubordinates: false,
      participantTreeFilter: subordinatesFilter,
      page: newPageNum,
      size: newPageSize,
      formTypeId: documentType > -1 ? documentType : undefined,
      query: query,
      sort: [sortingType, sortingDirection],
    });
    // if (pastDays > -1 || clientGroupId > -1) {
    // build and store a query string for persisting filters to FormReport
    if (timeFilterType === "CUSTOM_RANGE" && !dateRange) {
      setQueryString("");
    } else {
      const pastDays = getPastDays(timeFilterType);
      setQueryString(
        "?" +
          `${pastDays > -1 ? `pastDays=${pastDays}&` : ""}` +
          `${clientGroupId > -1 ? `clientGroupId=${clientGroupId}` : ""}`
      );
    }
    // }
  }, [
    getAllFormStats,
    dateRange,
    timeFilterType,
    clientGroupId,
    documentType,
    query,
    newPageNum,
    newPageSize,
    sortingType,
    sortingDirection,
    subordinatesFilter,
  ]);

  useEffect(() => {
    if (formTypeStats && selectedFormType && selectedFormType.id) {
      const data = formTypeStats.find(
        (d) => d.formType.id === selectedFormType.id
      );
      if (data) {
        setSelectedFormTypeData(data);
        return;
      }
    }
    setSelectedFormTypeData(null);
  }, [formTypeStats, selectedFormType]);

  /**
   * Set current form type in state and open the groups drawer
   * @param formType  - formType selected from summary
   */
  const openGroupsDrawer = (formType: FormTypeDTO): void => {
    setSelectedFormType(formType);
    setNewPageNum(0);
    setFormTypeValue(formType.id);
    setShowGroupsDrawer(true);
  };

  // @NOTE: ID IS USED FOR "SCROLL TO" / "BACK TO TOP" LOGIC
  // @TODO: UPDATE with a better solution - Trevor Kirpaul
  return (
    <ContentWrapper id="mainContent">
      {selectedFormType && selectedFormTypeData && (
        <GroupsListDrawer
          closeDrawer={() => setShowGroupsDrawer(false)}
          formStats={getStatsForFormType(selectedFormTypeData)}
          formType={selectedFormType}
          onGroupClick={(groupId: number): void => {
            setNewPageNum(0);
            setGroupIdValue(groupId);
            setFormTypeValue(selectedFormType.id);
            setShowGroupsDrawer(false);
          }}
          show={showGroupsDrawer}
          viewingGroupId={clientGroupId}
        />
      )}
      <Breadcrumbs paths={[{ pathName: "Reports" }]} />
      <H1>Reports</H1>
      <Loader
        loading={
          (formTypeStats && formTypeLoadingStats) || formTypeErrorLoadingStats
        }
      >
        <S.SummaryWrapper>
          {(formTypeStats &&
            formTypeStats.map((d) => (
              <FormTypeSummary
                key={d.formType.id}
                formType={d.formType}
                formStats={getStatsForFormType(d)}
                loadingStats={formTypeLoadingStats}
                onGroupClick={(id: number) => {
                  setFormTypeValue(d.formType.id);
                  setGroupIdValue(id);
                }}
                onAllGroupsClick={() => openGroupsDrawer(d.formType)}
                viewingAllGroups={false}
              />
            ))) || <p>something went wrong</p>}
        </S.SummaryWrapper>
      </Loader>
      <div>
        <ReportsFilters
          idPrefix="reportsFilters"
          setNewPageNum={setNewPageNum}
          setClientGroupId={setGroupIdValue}
          groupFilterOptions={groupFilterOptions}
          setDocumentType={setFormTypeValue}
          setSubordinatesFilter={setSubordinatesValue}
          formTypes={
            (formTypeStats && formTypeStats.map((d) => d.formType)) || []
          }
          handleChangeDateRange={setDateRangeValue}
          setQuery={setQueryValue}
          onClear={handleClear}
          setTimeFilterType={setTimeFilterTypeValue}
        />
        <ReportsTable
          sortingType={sortingType}
          setSortingType={setSortTypeValue}
          sortingDirection={sortingDirection}
          setSortingDirection={setSortDirectionValue}
          loadingStats={loadingStats}
          formStats={formStats}
          formsPagination={formsPagination}
          setNewPageNum={setNewPageNumValue}
          setNewPageSize={setNewPageSizeValue}
          queryString={queryString}
        />
      </div>
    </ContentWrapper>
  );
};

const mapStateToProps = (state: AppState) => {
  return {
    store: {
      ownClientGroups: get(state.user, "data.clientGroups", []),
      formTypeStats: state.allFormTypeStats.content,
      formTypeLoadingStats: state.allFormTypeStats.loading.GET,
      formTypeErrorLoadingStats: state.allFormTypeStats.errors.GET,
      formStats: state.allFormStats.content,
      loadingStats: state.allFormStats.loading.GET,
      errorLoadingStats: state.allFormStats.errors.GET,
      formsPagination: {
        currentPage: state.allFormStats.number || 0,
        totalPages: state.allFormStats.totalPages || 0,
        pageSize: state.allFormStats.size || 10,
      },
      caGroupIds: userClientAdminGroupIds(state.user),
    },
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  actions: {
    forms: bindActionCreators(formsActions, dispatch),
    getAllFormStats: bindActionCreators(getAllFormStats, dispatch),
    getAllFormTypeStats: bindActionCreators(getAllFormTypeStats, dispatch),
  },
});

export default withClientGroups(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(Reports))
);
