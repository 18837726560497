import React from "react";
import { css } from "aphrodite/no-important";

import FormItem from "./FormItem";
import styles from "./styles";
import { getItemIcon } from "./iconMap";
import { GeneratorFn, ItemParams } from "../Create";

export const widgets: ItemParams[] = [
  {
    name: "Map",
    type: "WIDGET",
    subType: "MAP",
    initialProperties: {
      includeMap: true,
      questions: [],
    },
  },
  {
    name: "Defenses",
    type: "WIDGET",
    subType: "DEFENSES",
    initialProperties: {
      autoAppendComment: false,
    },
  },
  {
    name: "Operational Experiences",
    type: "WIDGET",
    subType: "OPERATIONAL_EXPERIENCES",
    initialProperties: {
      numberRequired: 0,
      filterOutTags: [],
    },
  },
  {
    name: "Signatures",
    type: "WIDGET",
    subType: "SIGNATURE",
    initialProperties: {
      allowedTypes: ["DRAWN"],
    },
  },
  {
    name: "Safety Rating",
    type: "WIDGET",
    subType: "SAFETY_RATING",
  },
  {
    name: "Document Creator",
    type: "WIDGET",
    subType: "DOCUMENT_CREATOR",
  },
];

interface Props {
  add: GeneratorFn;
  itemSelected: boolean;
  disableAddMap?: boolean;
}

const Widgets = ({ add, itemSelected, disableAddMap }: Props) => {
  const S = styles({});

  return (
    <div className={css(S.ItemsContainer)}>
      <span className={css(S.Label)}>Widgets</span>
      {widgets.map((widget) => (
        <FormItem
          disabled={
            !itemSelected || (widget.subType === "MAP" && disableAddMap)
          }
          key={widget.name}
          name={widget.name}
          icon={getItemIcon(widget.subType)}
          onClick={() => add(widget)}
        />
      ))}
    </div>
  );
};

export default Widgets;
