export const PROPERTIES_HELPER_TEXT = {
  oeTags: `The following tags have been added under Global 
    or Custom Fields that include Options. These tags generate related OEs on the 
    form that team members can choose to include.`
};

export const PROPERTIES_HEADER_TEXT = {
  SECTION: "Customize the selected Section here.",
  CONTENT_BLOCK: "Customize and set basic rules for the selected Content Block here.",
  LINE_BREAK: "Line breaks allow you to add spaces between content " +
    "pieces on the form. There are no properties to be configured.",
  MULTI_SELECT: "Customize and set basic rules for the selected Custom Multi-Select field here.",
  RADIO_BUTTON: "Customize and set basic rules for the selected Radio Button field here.",
  YES_OR_NO: "Customize and set basic rules for the selected Yes/No Answer field here.",
  DROP_DOWN: "Customize and set basic rules for the selected Custom Dropdown field here.",
  TEXT_LINE: "Customize and set basic rules for the selected Single Line Text field here.",
  TEXT_AREA: "Customize and set basic rules for the selected Paragraph field here.",
  PARTICIPANT: "Customize and set basic rules for the selected Participant field here.",
  TIME: "Customize and set basic rules for the selected Time field here. " +
    "(The Default to Current Time rule will cause the field to default to the current " +
    "time when the form is loaded, but it can be modified by the end user.)",
  DATE: "Customize and set basic rules for the selected Date field here. " +
    "(The Default to Today rule will cause the field to default to the current " +
    "date, but it can be modified by the end user.)",
  MAP: "Configure the selected Map widget here.",
  DEFENSES: "Configure the selected Defense widget here.",
  OPERATIONAL_EXPERIENCES: "Configure the selected Operational Experiences widget here.",
  SIGNATURE: "Configure the selected Signatures widget here.",
  SAFETY_RATING: "Configure the selected Safety Rating widget here.",
  DOCUMENT_CREATOR: "Configure the selected Document Creator widget here.",
  SUPERVISOR: "Configure the selected Supervisor widget here.",
};

export const CONTENT_COPY = {
  oeWidgetDescription: `The content displayed here is an OE that has been triggered 
    by one of the tags connected to a field selection above. The person reviewing the 
    pre-job brief can choose to discuss the OE with their team, selecting the “Include” 
    button above. The amount of OEs that must be included is determined in the Rules 
    section of the OE Widget Properties. An OE can also be starred, which causes it to 
    appear more frequently.`
};
