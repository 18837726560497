import { useMemo, useState, useEffect } from "react";
import { QuestionDTO, QuestionSelectionsDTO } from "store/forms/types";
import { getMinMaxSubmissionDatesFromDate } from "components/helpers/filters";
import isEqual from "lodash/isEqual";

import { SafetyResponse } from "./FormReport";

export const useFetchComments = (
  fetchComments,
  responses: SafetyResponse[]
) => {
  const [selectionCommentsMap, setSelectionCommentsMap] = useState<null | {
    [key: number]: any;
  }>(null);
  const [priorSelectionIds, setPriorSelectionIds] = useState<number[]>([]);
  useEffect(() => {
    const selectionIds = responses.map(({ selectionId }) => selectionId);
    if (isEqual(selectionIds.sort(), priorSelectionIds.sort())) {
      return;
    }
    setPriorSelectionIds(selectionIds);
    const promises = selectionIds.map((selectionId) =>
      fetchComments({ selectionId })
    );
    Promise.all(promises)
      .then((value) => {
        const response = {};
        selectionIds.forEach((id, i) => (response[id] = value[i]));
        setSelectionCommentsMap(response);
      })
      .catch((e) => console.log(e));
  }, [responses]);
  return useMemo(
    () =>
      selectionCommentsMap
        ? responses.map((response) => {
            return {
              comments: selectionCommentsMap[response.selectionId].content,
              id: response.selectionId,
              title: response.question.title,
            };
          })
        : [],
    [selectionCommentsMap]
  );
};

type Response = {
  area: string;
  question: QuestionDTO;
  topResponses: Array<{ response: string; responseCount: number }>;
  totalResponses: number;
};

/**
 * Compile a list of question responses that are of type "RATING"
 * @param formStatsData - data from stats endpoint
 */
const getRatingQuestions = (formStatsData): Array<Response> =>
  // for each question in form stat
  formStatsData.questions.reduce((result, statQuestion) => {
    if (statQuestion.question.subType === "RATING") {
      result.push({
        ...statQuestion,
        area: statQuestion.sectionTitle, // Maps to `area` key to communicate what it's used for
      });
    }
    return result;
  }, []);

export type MappedResponses = {
  [key: string]: Array<SafetyResponse>;
};

function mapRiskResponses(
  ratingQuestions: Array<Response>,
  responses: MappedResponses
): MappedResponses {
  let selection: QuestionSelectionsDTO | null = null;
  // for each response in each rating question
  return ratingQuestions.reduce((result, question) => {
    question.topResponses.forEach((response) => {
      // if the risk type is a key in responses (declared above)
      if (response.response in responses) {
        if (question && question.question && question.question.selections) {
          // find that selection
          selection =
            question.question.selections.find(
              (sel) => sel.title === response.response
            ) || null;
          // if found, add risk response to mapped responses
          if (selection?.id) {
            responses[response.response].push({
              question: question.question,
              responseCount: response.responseCount,
              area: question.area,
              selectionId: selection.id,
            });
          }
        }
      }
    });
    return result;
  }, responses);
}

/**
 * Sum response counts for all risk question responses
 * @param responses
 */
const responseCount = (responses: MappedResponses): number =>
  Object.keys(responses).reduce((count, responseKey) => {
    responses[responseKey].forEach(
      (response) => (count += response.responseCount)
    );
    return count;
  }, 0);

/**
 * Generate statistics and options for rating filter based on form questions and stats
 * @param formStats - form statistics
 */
export const useRatingQuestions = ({ formStats }) => {
  const baseResponses = {
    Risk: [],
    "Coaching Moment": [],
    Satisfactory: [],
    "Best Practice": [],
  };
  let ratingQuestions: Array<Response> = [];
  let mappedResponses: MappedResponses = baseResponses;
  let totalResponses = 0;

  // When useRatingQuestions() runs a second time, these 3 values above are reset and the block below
  // doesn't run anymore, until you pull data or change the arrays. useMemo() could probably be outside
  // in parent, but then all the hooks inside have to be moved outside.
  // useMemo(() => {
  //   if (formStats.data && form.data) {
  //     ratingQuestions = getRatingQuestions(formStats.data, form.data);
  //     mappedResponses = mapRiskResponses(ratingQuestions, baseResponses);
  //     totalResponses = responseCount(mappedResponses);
  //   }
  // }, [formStats.data, form.data]);

  if (formStats?.data) {
    ratingQuestions = getRatingQuestions(formStats.data);
    mappedResponses = mapRiskResponses(ratingQuestions, baseResponses);
    totalResponses = responseCount(mappedResponses);
  }
  return {
    mappedResponses,
    totalResponses,
    SO_FLAG: ratingQuestions.length > 0,
  };
};

export const useFilters = ({ onFilterChange, form, riskResponses }) => {
  const [pastDays, setPastDays] = useState(-1);
  const [groupId, setGroupId] = useState(-1);
  const [query, setQuery] = useState("");
  const [ratingsFilter, setRatingsFilter] = useState<string[]>([]);
  const ratingOptions = Object.keys(riskResponses).reverse();

  const [groupFilterOptions, setGroupFilterOptions] = useState([
    {
      id: -1,
      value: "All Groups",
    },
  ]);
  useEffect(() => {
    const searchDocuments = () => {
      const {
        minSubmissionDate,
        maxSubmissionDate,
      } = getMinMaxSubmissionDatesFromDate(pastDays);

      onFilterChange({
        query: query,
        clientGroupIds: groupId,
        sortName: "submissionDate",
        sortOrder: "desc",
        submissionTypes: "SUBMIT",
        minSubmissionDate,
        maxSubmissionDate,
      });
    };
    searchDocuments();
  }, [pastDays, groupId, query]);
  useEffect(() => {
    if (form.data.clientGroups) {
      setGroupFilterOptions((prevState) =>
        prevState.concat(
          form.data.clientGroups.map((group) => ({
            id: group.id,
            value: group.name,
          }))
        )
      );
    }
  }, [form.data.clientGroups]);
  return {
    timeFilter: { pastDays, setPastDays },
    groupFilter: { groupId, setGroupId, groupFilterOptions },
    ratingsFilter: { ratingsFilter, setRatingsFilter, ratingOptions },
    setQuery,
  };
};
