import NewDocumentCard from "./NewDocumentCard";
import {
  NewDocumentCardStyle as OriginalNewDocumentCardStyle
} from "./NewDocumentCard";
import {
  StartNewDocumentButtonType as OriginalStartNewDocumentButtonType
} from "./styles";

export default NewDocumentCard;
export type NewDocumentCardStyle = OriginalNewDocumentCardStyle;
export type StartNewDocumentButtonType = OriginalStartNewDocumentButtonType;
