import React, { useContext } from "react";
import styled, { ThemeContext } from "styled-components";

import { DateRange as BaseDateRange } from "components/common/DateRange";
import { toRgba } from "themes/helpers";
import { Text, TextProps } from "components/clientAdmin/styles";

const DataItemEmail: React.FC<TextProps> = (props) => {
  const theme = useContext(ThemeContext);
  return (
    <Text
      color={theme.masterColors.darkGrey}
      fontSize="0.875rem"
      fontWeight={400}
      letterSpacing="0.24px"
      {...props}
    />
  );
};

const DataItemLabel: React.FC<TextProps> = (props) => {
  const theme = useContext(ThemeContext);
  return (
    <Text
      color={theme.masterColors.darkGrey}
      fontSize="0.75rem"
      fontWeight={500}
      letterSpacing="1.88px"
      margin="0 0 8px 0"
      {...props}
    />
  );
};

const DataItemText: React.FC<TextProps> = (props) => {
  const theme = useContext(ThemeContext);
  return (
    <Text
      color={theme.colors.black}
      fontSize="0.875rem"
      fontWeight={400}
      letterSpacing="0.24px"
      {...props}
    />
  );
};

const DateRange = styled(BaseDateRange)`
  margin-left: 15px;
`;

const DateRangeRow = styled.div`
  display: flex;
  padding-top: 8px;
`;

const EmailText: React.FC<TextProps> = (props) => {
  const theme = useContext(ThemeContext);
  return (
    <Text
      color={theme.masterColors.darkGrey}
      fontSize="0.875rem"
      fontWeight={400}
      letterSpacing="0.24px"
      {...props}
    />
  );
};

const PageHeader = styled.div.attrs({
  className: "col-12",
})`
  display: flex;
  justify-content: space-between;
`;

const PageTitle = styled.div`
  color: ${({ theme }) => theme.colors.black};
  font-size: 2.25rem;
  letter-spacing: 0.63px;
`;

const PageActionButton = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.primary};
  border-radius: 4px;
  color: ${({ theme }) => theme.colors.white};
  cursor: pointer;
  display: flex;
  font-size: 0.875rem;
  height: 36px;
  justif-content: center;
  letter-spacing: 1.25px
  padding: 0 16px;
  text-transform: uppercase;
`;

const PopperWrapper = styled.div`
  // width: 320px;
  // height: 161px;
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px 12px;
  position: relative;

  &:before {
    content: "";
    position: absolute;
    right: -24px;
    top: 40%;
    // z-index: -1;
    width: 15px;
    height: 15px;
    background-color: ${({ theme }) => theme.colors.white};
    border-right: 1px solid ${({ theme }) => toRgba(theme.colors.black, 0.1)};
    border-bottom: 1px solid ${({ theme }) => toRgba(theme.colors.black, 0.1)};
    transform: rotate(-45deg) translate(-14px, -7px);
  }
`;

type PopperRowType = {
  disabled?: boolean;
  error?: boolean;
  marginBottom?: boolean;
};

const PopperRow = styled.div<PopperRowType>`
  align-items: center;
  color: ${({ error, theme }) =>
    error ? theme.colors.error : theme.masterColors.darkGrey};
  cursor: ${({ disabled }) => !disabled && "pointer"};
  display: flex;
  font-size: 0.875rem;
  justify-content: space-between;
  margin-bottom: 15px;
  opacity: ${({ disabled }) => (!disabled ? 1 : 0.5)};
  width: 100%;
  &:last-of-type {
    margin-bottom: 0;
  }
`;

const PopperChevron = styled.i.attrs({
  className: "icon icon-icons8-chevron-left",
})<{
  color?: string;
}>`
  color: ${({ color, theme }) =>
    color || theme.masterColors.darkGrey} !important;
  display: inline-block;
  transform: rotate(180deg);
  font-size: 25px;
  margin-left: 30px;
`;

// Generic modal content
const ModalPromptWrapper = styled.div``;
const ModalPromptTitle = styled.div`
  color: ${({ theme }) => toRgba(theme.colors.black, 0.87)};
  font-size: 1.25rem;
  font-weight: 500;
  letter-spacing: 0.26px;
`;
const ModalPromptSubtitle = styled.div`
  color: ${({ theme }) => toRgba(theme.colors.black, 0.87)};
  font-size: 1rem;
  font-weight: 400;
  letter-spacing: 0.28px;
  margin-bottom: 24px;
  margin-top: 8px;
`;
const ModalPromptCloseIcon = styled.i.attrs({
  className: "icon-icons8-delete_sign",
})`
  border-radius: 50%;
  color: ${(props) => props.theme.masterColors.darkGrey};
  cursor: pointer;
  display: inline-block;
  font-size: 1.2rem;
  font-weight: 600;
  position: absolute;
  top: 24px;
  right: 23px;
`;

// Disable user modal content
const DisableUserDataItem = styled.div<{ width?: string }>`
  display: flex;
  flex-direction: column;
  margin-right: 70px;
  width: ${({ width }) => width};
`;
const DisableUserWarningLabel = styled.div<{
  margin?: string;
}>`
  background: ${({ theme }) => theme.masterColors.warningFill};
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.masterColors.warning};
  color: ${({ theme }) => toRgba(theme.masterColors.darkGrey, 0.87)};
  font-size: 0.875rem;
  margin: ${({ margin }) => margin};
  padding: 16px;
  width: 100%;
`;
const DisableUserButtonsWrapper = styled.div<{ margin?: string }>`
  bottom: 24px;
  display: flex;
  justify-content: flex-end;
  margin: ${({ margin }) => margin};
  right: 23px;
  width: 100%;
`;

const DeleteLinkedDocumentWarning = styled.span`
  font-weight: 700;
`;

export {
  DataItemEmail,
  DataItemLabel,
  DataItemText,
  DateRange,
  DateRangeRow,
  DeleteLinkedDocumentWarning,
  DisableUserButtonsWrapper,
  DisableUserDataItem,
  DisableUserWarningLabel,
  EmailText,
  ModalPromptCloseIcon,
  ModalPromptSubtitle,
  ModalPromptTitle,
  ModalPromptWrapper,
  PageActionButton,
  PageHeader,
  PageTitle,
  PopperChevron,
  PopperRow,
  PopperWrapper,
};
