import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { useMediaQuery } from "react-responsive";

import { getUser } from "store/user/actions";
import EditProfileForm from "components/EditProfile/Form";
import TitleBar from "components/navigation/TitleBar";
import { getAuthToken } from "store/user/selectors";
import { devices } from "themes/mediaQueries";

import * as S from "./styles";

const EditProfile = () => {
  const dispatch = useDispatch();
  const { replace } = useHistory();

  const isTablet = useMediaQuery({
    maxWidth: devices.maxTablet,
  });

  const authToken = useSelector(getAuthToken);

  /**
   * If user widens viewport to desktop view
   * we'll replace the route with the desktop
   * profile route. '/profile' is used for
   * Edit Profile and View Profile on Desktop
   */
  useEffect(() => {
    if (!isTablet) {
      replace("/profile");
    }
  }, [isTablet]);

  // fetch new User data
  // when we leave the Edit Profile
  // component. This will enure that updates
  // made will be in redux
  useEffect(() => {
    return () => {
      if (authToken) {
        dispatch(getUser());
      }
    };
  }, [dispatch, authToken]);

  // @TODO: REMOVE WHEN AUTH PROTECTION IS HANDLED
  if (!authToken) {
    replace("/login");

    return null;
  }

  return (
    <S.EditProfile>
      <TitleBar title="Edit Profile" />
      <EditProfileForm />
    </S.EditProfile>
  );
};

export default EditProfile;
