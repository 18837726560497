import React, { useMemo } from "react";
import { ThemeContext } from "styled-components";
import { css, StyleSheet } from "aphrodite/no-important";
import { Field, useFormikContext } from "formik";

import Switch from "components/common/form/Switch";
import { remCalc } from "themes/helpers";
import { FBForm, FBItem, FBQuestion } from "../../types";
import { getAllFormItems, isFormPublished } from "../../helpers";
import { DefensesWidgetDTO } from "store/forms/types";
import { get } from "lodash";

interface Props {
  itemPath: string;
  item: DefensesWidgetDTO;
}
/**
 * Defense question settings - allows setting defense visibility status for each question with associated defenses
 * @constructor
 */
export function DefensesProperties({ itemPath, item }: Props) {
  const { values, setFieldValue } = useFormikContext<FBForm>();
  const theme = React.useContext(ThemeContext);
  const s = styles(theme);

  const defenseQuestions = useMemo(
    () =>
      getAllFormItems(values).reduce(
        (itemsWithDefenses: FBQuestion[], item: FBItem) => {
          if (item.type === "QUESTION" && item.hasDefenses) {
            itemsWithDefenses.push(item);
          }
          return itemsWithDefenses;
        },
        []
      ),
    [values]
  );

  return (
    <div>
      <span className={css(s.title)}>Show &amp; Hide Defenses by Field</span>
      <span className={css(s.subTitle)}>
        The following fields have related Defenses that can be displayed in the
        Defenses section of the form:
      </span>
      {defenseQuestions.length > 0 ? (
        defenseQuestions.map((dq) => {
          const questions = item.questions || [];
          const dqIndex = questions.findIndex(
            (question) => question.questionRootId === dq.rootId
          );
          const dqPath = `${itemPath}.questions[${dqIndex}]`;
          const hide = get(values, `${dqPath}.hideDefenses`, false);

          return (
            <div key={`${dq.rootId}`}>
              <div className={css(s.option)}>
                <span className={css(s.optionTitle)}>{dq.title}</span>
                <Field
                  as={Switch}
                  name={`${dq.id}_hideDefenses`}
                  value={hide ? "hide" : "show"}
                  onChange={(e) => {
                    const value = {
                      hideDefenses: e.target.value === "hide",
                      questionRootId: dq.rootId,
                    };
                    if (dqIndex > -1) {
                      // replace the value in the questions array if it already exists
                      setFieldValue(dqPath, value);
                    } else {
                      // else append the value to the questions array
                      setFieldValue(`${itemPath}.questions`, [
                        ...questions,
                        value,
                      ]);
                    }
                  }}
                  options={[
                    { label: "SHOW", value: "show" },
                    { label: "HIDE", value: "hide" },
                  ]}
                  disabled={isFormPublished(values.workflowType)}
                />
              </div>
            </div>
          );
        })
      ) : (
        <span className={css(s.subTitle)}>
          Add questions with defenses to the form to configure them here
        </span>
      )}
    </div>
  );
}

// TODO temporary location until styled-components removed from styles file -JA
const styles = (theme) =>
  StyleSheet.create({
    title: {
      display: "block",
      letterSpacing: remCalc(0.15),
      lineHeight: remCalc(21),
      margin: `${remCalc(25)} 0 ${remCalc(4)}`,
      fontWeight: 500,
    },
    subTitle: {
      display: "block",
      fontSize: remCalc(12),
      letterSpacing: remCalc(0.4),
      lineHeight: remCalc(16),
      marginBottom: remCalc(4),
    },
    option: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      padding: `${remCalc(16)} 0`,
      borderBottom: `1px solid ${theme.masterColors.lightGrey}`,
    },
    optionTitle: {
      display: "block",
      fontSize: remCalc(14),
      letterSpacing: remCalc(0.13),
      lineHeight: remCalc(19),
      marginRight: remCalc(16),
    },
  });
