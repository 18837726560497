import { DocumentParticipant } from "store/documents/types";

import {
  formatAddress,
  geoCode,
  getCurrentPosition,
  reverseGeolocation,
} from "./geo";

export const getDocTypeFromURLParam = (urlParam?: string | number) => {
  if (!urlParam) return -1;
  if (typeof urlParam === "number") {
    return urlParam;
  }
  return parseInt(urlParam);
};

/**
 * Used with React's dangerouslySetInnerHTML prop
 * @param content string
 */
export const createMarkup = (content?: string) => {
  if (content) {
    return {
      __html: content,
    };
  }

  return {
    __html: "<div />",
  };
};

/**
 * Return a value which matches mockups for participant labels with the nickname value in parenthesis
 * @param participant
 */
export const createLabel = (participant: DocumentParticipant) => {
  const nickname = participant.nickname ? ` (${participant.nickname})` : "";
  return participant.name + nickname;
};

/**
 * Prompt the user to download a file
 * @param blob  - Blob with file contents
 * @param title - title (with extension) of file to be downloaded
 */
function downloadFile(blob: Blob, title: string) {
  if (navigator.msSaveBlob) {
    navigator.msSaveBlob(blob, title);
  } else {
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.setAttribute("download", title);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
}

/**
 * Convert a string to titlecase
 * @param str
 */
function toTitleCase(str?: string): string {
  if (str) {
    return str
      .split(" ")
      .map(
        (word) =>
          `${word[0].toUpperCase()}${word.slice(1, word.length).toLowerCase()}`
      )
      .join(" ");
  }
  return "";
}

/**
 * Convert a string to kebab case. Kebab case is lowercase-with-hypens.
 * @param value
 */
function toKebabCase(value?: string): string {
  return value
    ? value
        .toLowerCase()
        .split(" ")
        .join("-")
    : "";
}

export {
  downloadFile,
  formatAddress,
  geoCode,
  getCurrentPosition,
  reverseGeolocation,
  toKebabCase,
  toTitleCase,
};
