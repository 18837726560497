import React, { ReactNode, useState } from "react";
import S from "./styles";

export interface ActionButtonProps {
  className?: string;
  IconComponent?: ReactNode;
  onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  label: string;
  shouldShowTooltip?: boolean;
  ariaControls?: string;
  ariaExpanded?: boolean;
}

const ActionButton = ({
  className,
  IconComponent,
  onClick,
  label,
  shouldShowTooltip = true,
  ariaControls,
  ariaExpanded
}: ActionButtonProps) => {
  const [isMouseFocused, setIsMouseFocused] = useState(false);

  const handleBlur = () => {
    setIsMouseFocused(false);
  };

  const handleMouseDown = () => {
    setIsMouseFocused(true);
  };

  return (
    <>
      {shouldShowTooltip ? (
        <S.ActionTooltip
          className={className}
          title={label}
          placement="top"
          arrow
        >
          <S.ActionButtonWrapper>
            <S.ActionButton
              aria-label={label}
              aria-controls={ariaControls}
              aria-expanded={ariaExpanded} 
              className={isMouseFocused && "mouseFocus"}
              onClick={onClick}
              disableRipple
              onBlur={handleBlur}
              onMouseDown={handleMouseDown}
            >
              {IconComponent || <S.ActionIcon />}
            </S.ActionButton>
          </S.ActionButtonWrapper>
        </S.ActionTooltip>
      ) : (
        <S.ActionButtonWrapper>
          <S.ActionButton
            aria-label={label}
            className={isMouseFocused && "mouseFocus"}
            onClick={onClick}
            disableRipple
            onBlur={handleBlur}
            onMouseDown={handleMouseDown}
          >
            {IconComponent || <S.ActionIcon />}
          </S.ActionButton>
        </S.ActionButtonWrapper>
      )}
    </>
  );
};

export default ActionButton;
