import { OperationalExperience } from "../resources/types";

export interface OperationalExperiencesActionTypes {
  type: string;
  payload?: any;
  response?: any; // used with CALL_API
}

export const NAME = "OPERATIONAL_EXPERIENCE";

// --------------------------------------------------------------------------
// ? ACTION TYPES
// The following types are used with action creators
// --------------------------------------------------------------------------

/**
 * Used to fetch OEs matching the given document
 * `POST` `/api/document-oe/find`
 */
export const FETCH_DOCUMENT_OES = {
  REQUEST: `${NAME}/FETCH_DOCUMENT_OES/REQUEST`,
  SUCCESS: `${NAME}/FETCH_DOCUMENT_OES/SUCCESS`,
  FAILURE: `${NAME}/FETCH_DOCUMENT_OES/FAILURE`,
};

/** Action constants for fetching OEs when they should not be saved to the store */
export const FETCH_DOCUMENT_OES_NOSTORE = {
  SUCCESS: `${NAME}/FETCH_DOCUMENT_OES_NOSTORE/SUCCESS`,
  FAILURE: `${NAME}/FETCH_DOCUMENT_OES_NOSTORE/FAILURE`,
};

/**
 * Includes the given OE on the current Document.
 */
export const INCLUDE_OPERATIONAL_EXPERIENCE = {
  REQUEST: `${NAME}/INCLUDE_OPERATIONAL_EXPERIENCE/REQUEST`,
  SUCCESS: `${NAME}/INCLUDE_OPERATIONAL_EXPERIENCE/SUCCESS`,
  FAILURE: `${NAME}/INCLUDE_OPERATIONAL_EXPERIENCE/FAILURE`,
};

/**
 * Removes the given OE from the current Document
 */
export const REMOVE_OPERATIONAL_EXPERIENCE = {
  REQUEST: `${NAME}/REMOVE_OPERATIONAL_EXPERIENCE/REQUEST`,
  SUCCESS: `${NAME}/REMOVE_OPERATIONAL_EXPERIENCE/SUCCESS`,
  FAILURE: `${NAME}/REMOVE_OPERATIONAL_EXPERIENCE/FAILURE`,
};

/**
 * Favorites the given Operational Experience
 */
export const MARK_OPERATIONAL_EXPERIENCE_AS_FAVORITE = {
  REQUEST: `${NAME}/MARK_OPERATIONAL_EXPERIENCE_AS_FAVORITE/REQUEST`,
  SUCCESS: `${NAME}/MARK_OPERATIONAL_EXPERIENCE_AS_FAVORITE/SUCCESS`,
  FAILURE: `${NAME}/MARK_OPERATIONAL_EXPERIENCE_AS_FAVORITE/FAILURE`,
};

/**
 * Used with actions related to API call made to fetch all operational experiences
 * `GET` `/api/operational-experiences`
 */
export const FETCH_OPERATIONAL_EXPERIENCES = {
  REQUEST: `${NAME}/FETCH_OPERATIONAL_EXPERIENCES/REQUEST`,
  SUCCESS: `${NAME}/FETCH_OPERATIONAL_EXPERIENCES/SUCCESS`,
  FAILURE: `${NAME}/FETCH_OPERATIONAL_EXPERIENCES/FAILURE`,
};

/**
 * used when searching for OEs from the Document rendered OEs
 */
export const SEARCH_OPERATIONAL_EXPERIENCES = {
  REQUEST: `${NAME}/SEARCH_OPERATIONAL_EXPERIENCES/REQUEST`,
  SUCCESS: `${NAME}/SEARCH_OPERATIONAL_EXPERIENCES/SUCCESS`,
  FAILURE: `${NAME}/SEARCH_OPERATIONAL_EXPERIENCES/FAILURE`,
};

/**
 * Used with actions related to API call made to
 * fetching a single operational experience
 * `GET` `/api/operational-experiences/{id}`
 */
export const FETCH_SINGLE_OPERATIONAL_EXPERIENCE = {
  REQUEST: `${NAME}/FETCH_SINGLE_OPERATIONAL_EXPERIENCE/REQUEST`,
  SUCCESS: `${NAME}/FETCH_SINGLE_OPERATIONAL_EXPERIENCE/SUCCESS`,
  FAILURE: `${NAME}/FETCH_SINGLE_OPERATIONAL_EXPERIENCE/FAILURE`,
};

/**
 * Used with actions related to creating an operational experience
 * `POST` `/api/operational-experience`
 */
export const CREATE_OPERATIONAL_EXPERIENCE = {
  REQUEST: `${NAME}/CREATE_OPERATIONAL_EXPERIENCE/REQUEST`,
  SUCCESS: `${NAME}/CREATE_OPERATIONAL_EXPERIENCE/SUCCESS`,
  FAILURE: `${NAME}/CREATE_OPERATIONAL_EXPERIENCE/FAILURE`,
};

/**
 * Used with actions related to updating an operational experience
 * `PUT` `/api/operational-experience`
 */
export const UPDATE_OPERATIONAL_EXPERIENCES = {
  REQUEST: `${NAME}/UPDATE_OPERATIONAL_EXPERIENCES/REQUEST`,
  SUCCESS: `${NAME}/UPDATE_OPERATIONAL_EXPERIENCES/SUCCESS`,
  FAILURE: `${NAME}/UPDATE_OPERATIONAL_EXPERIENCES/FAILURE`,
};

/**
 * Used with actions related to deleting an operational experience
 * `DELETE` `/api/operational-experience`
 */
export const DELETE_OPERATIONAL_EXPERIENCE = {
  REQUEST: `${NAME}/DELETE_OPERATIONAL_EXPERIENCES/REQUEST`,
  SUCCESS: `${NAME}/DELETE_OPERATIONAL_EXPERIENCES/SUCCESS`,
  FAILURE: `${NAME}/DELETE_OPERATIONAL_EXPERIENCES/FAILURE`,
};

/**
 * Returns the status flags for the API calls used to determine
 * if Include/Remove has failed or succeeded. This is used
 * by component state since we don't get a return value and
 * need to explicitly track success/failure in the component
 */
export const RESET_API_STATUS_OE = "RESET_API_STATUS_OE";

// --------------------------------------------------------------------------
// ? TYPE DEFINITIONS FOR ACTION ARGUMENTS
// The following types describe args which might be given to action creators
// --------------------------------------------------------------------------

/**
 * args used for the `fetchDocumentOEs` action creator
 */
export interface FetchOperationalExperiencesForDocument {
  numberOfOEsToGenerate: number;
  skipCount?: number;
  documentSubmissionId?: number | null;
}

export interface IncludeOperationalExperience {
  operationalExperience: OperationalExperience;
}

export interface RemoveOperationalExperience {
  operationalExperience: OperationalExperience;
}

export interface MarkOperationalExperienceAsFavorite {
  operationalExperience: OperationalExperience;
  removeFromFavorites?: boolean;
}
export interface ComputeNewFavoriteCount {
  operationalExperience: OperationalExperience;
  removeFromFavorites?: boolean;
}

export interface OEActions {
  fetchDocumentOEs: (Args?: FetchOperationalExperiencesForDocument) => void;
  includeOperationalExperience: (Args: IncludeOperationalExperience) => void;
  removeOperationalExperience: (Args: RemoveOperationalExperience) => void;
  markOperationalExperienceAsFavorite: (Args: MarkOperationalExperienceAsFavorite) => void;
}
