import { CSSProperties, useContext } from "react";
import { StyleSheet } from "aphrodite";
import { ThemeContext } from "styled-components";
import { remCalc } from "themes/helpers";

export default function(): { [key: string]: CSSProperties } {
  const theme = useContext(ThemeContext);

  return StyleSheet.create({
    multiInput: {
      ":nth-child(1n) .MuiInput-root": {
        display: "none"
      },
      ":nth-child(1n) ul": {
        border: "none",
        padding: 0,
        display: "flex",
        alignItems: "center",
        flexWrap: "wrap",
      }
    },
    multiInputItem: {
      padding: "0.25rem 0.5rem 0 0 !important",
      ":nth-child(1n) .MuiListItem-root": {
        borderColor: theme.masterColors.darkGrey,
      },
      ":nth-child(1n) .MuiListItemSecondaryAction-root": {
        position: "relative",
        transform: "none",
        top: remCalc(2),
        left: remCalc(8),
      }
    },
    excludedItem: {
      ":nth-child(1n) .MuiListItem-root": {
        borderColor: theme.masterColors.mediumGrey,
      },
      ":nth-child(1n) .primary": {
        color: theme.masterColors.mediumGrey,
      },
      ":nth-child(1n) .icon": {
        color: theme.masterColors.mediumGrey,
      }
    },
    tagsSection: {
      marginBottom: "0.75rem",
    },
    tagsCount: {
      marginBottom: remCalc(4),
    },
    tagsCountText: {
      fontSize: remCalc(12),
      marginLeft: remCalc(4)
    }
  });
};
