import React, {
  useMemo,
  PointerEventHandler,
  useState,
  PropsWithChildren,
  useRef,
} from "react";
import { get } from "lodash";
import { useHistory } from "react-router";
import { useSelector } from "react-redux";
import moment from "moment";

import { getSearchDocuments } from "store/documents/selectors";
import { DocumentSummary } from "store/documents/types";
import { MapContent } from "components/common/Card";
import { oldDisplayStatus, displayWhen } from "components/helpers";
import { WidgetHeader } from "components/common/styled/WidgetHeader";
import Loader from "components/common/Loader";
import { Carousel } from "./Carousel";

import * as S from "./styles";
import useGroupTerm from "util/hooks/whiteLabel/useGroupTerm";
import { formatSubmitDeadline } from "components/document/helpers";

interface Props {
  displayDocumentsFromSearch?: boolean;
  isSearchLoading?: boolean;
  openContextMenu: (d: DocumentSummary) => void;
  query?: string | null;
  searchDocumentsAPIError?: boolean;
}

const RecentDocuments = ({
  searchDocumentsAPIError,
  isSearchLoading,
  openContextMenu,
}: Props) => {
  const { push } = useHistory();
  const searchDocuments = useSelector(getSearchDocuments);

  const recentDocuments = useMemo(
    () =>
      searchDocuments.filter((x) => moment().diff(x.submissionDate, "h") <= 24),
    [searchDocuments]
  );

  // Group config terms
  const documentsTerm =
    useGroupTerm("document", "noun", "plural") || "Documents";

  if (searchDocumentsAPIError || recentDocuments?.length === 0) {
    return null;
  }

  if (isSearchLoading) {
    return <Loader loading={true} error={false} />;
  }

  return (
    <>
      <WidgetHeader>Recent {documentsTerm} (24 hours)</WidgetHeader>
      <Carousel>
        {recentDocuments.map((document) => {
          const location = get(document, "summary.location", null);
          const iconType = get(document, "formSummary.type.iconName", "");
          const iconColor = get(document, "formSummary.type.iconColor", "");
          const workOrderId = get(document, "workOrder.workOrderId", "N/A");
          const deadline = get(document, "deadline", undefined);
          const subHeader = `ID: ${workOrderId}`;

          const submitString = formatSubmitDeadline(deadline);

          return (
            <S.StyledCard
              key={document.id}
              status={oldDisplayStatus(document.submissionType)}
              title={document.title}
              subHeader={subHeader}
              tertiaryHeader={displayWhen(document.submissionDate)}
              onClick={() => openContextMenu(document)}
              cardOnClick={() => push(`/document/${document.id}`)}
              submitDeadline={
                document.submissionType !== "SUBMIT" ? submitString : null
              }
            >
              {iconType && iconColor && (
                <MapContent
                  location={location}
                  icon={{
                    type: iconType,
                    color: iconColor,
                  }}
                />
              )}
            </S.StyledCard>
          );
        })}
      </Carousel>
    </>
  );
};

export default RecentDocuments;
