import MenuItemBase from "@material-ui/core/MenuItem";
import OutlinedInputBase from "@material-ui/core/OutlinedInput";
import React from "react";
import SelectBase from "@material-ui/core/Select";
import styled from "styled-components";

import { remCalc } from "themes/helpers";

export const Wrapper = styled.div`
  padding-top: 21px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const Label = styled.div`
  color: ${({ theme }) => theme.masterColors.darkGrey};
  font-size: ${remCalc(12)};
  font-weight: 500;
  margin-right: 10px;
`;

const MenuItem = styled(({ ...otherProps }) => <MenuItemBase {...otherProps} />)``;

const OutlinedInput = styled(({ ...otherProps }) => <OutlinedInputBase classes={{ input: "input" }} {...otherProps} />)`
  && .input {
    font-size: ${remCalc(12)};
    padding: 10px;
  }
`;

export const SelectInput = styled(({ ...otherProps }) => {
  return (
    <SelectBase
      {...otherProps}
      classes={{
        select: "select",
      }}
      input={<OutlinedInput labelWidth={0} />}
    >
      {otherProps.options &&
        otherProps.options.map((option) => (
          <MenuItem key={option.id} value={option.id}>
            {option.value}
          </MenuItem>
        ))}
    </SelectBase>
  );
})`
  && .select {
    color: ${({ theme }) => theme.colors.formFieldColor};
    width: 40px;
  }
`;
