import { DocumentVM } from "../documents/types";
import { FormSummaryVm, FormTypeDTO } from "../forms/types";
import { WorkOrderType } from "../workOrders/types";

// --------------------------------------------------------------------------
// ? TYPE DEFINITIONS FOR API VALUES
// The following types describe API response values which are
// directly related to the "Document" feature
// --------------------------------------------------------------------------

export interface NewDocumentActionTypes {
  type: string;
  response?: any;
  payload?: any;
  clearStatusPayload: ClearStatusPayloadTypes;
}

export type Response = {
  answer: string;
  associatedId?: number | null;
  comments?: string | null;
  questionId: number;
  timeAnswered: string | Date;
};

export interface HasIdOrNameOrRoles {
  participantId?: number | null;
  name?: string;
  role?: "SUPERVISOR" | "ATTENDANT";
}

export interface ParticipantSignature extends HasIdOrNameOrRoles {
  signatureData: any;
}

export interface ParticipantURL {
  participant: HasIdOrNameOrRoles;
  writableUrl: string | null;
  readableUrl: string | null;
}

// --------------------------------------------------------------------------
// ? TYPE DEFINITIONS REDUX/LOCAL/APP
// The following types describe the reducer and actions
// --------------------------------------------------------------------------

export interface NewDocumentState {
  loading: {
    /**
     * `GET` to `/api/documents/${id}` when continuing a Document
     */
    continueDocument: boolean;
    /**
     * first step when creating/submitting a new document
     * from the "Start New Document" feature. We'll make a
     * `POST` to the `/api/documents` endpoint which responds with
     * data that we append to our current document.
     * Then we make a `PUSH` to `/api/documents` with the actual
     * document data and the appended response from the `POST`
     */
    createDocument: boolean;
    fetchSummarizedForms: boolean;
    getSignatureUrlForParticipant: boolean;
    /**
     * `PUT` to `/api/document` when submitting the filled
     * document values after "Start New Document" feature
     */
    submitDocument: boolean;
    uploadSignatureImageToS3: boolean;
    workOrders: boolean;
  };
  error: {
    continueDocument: boolean;
    createDocument: boolean;
    fetchSummarizedForms: boolean;
    getSignatureUrlForParticipant: boolean;
    submitDocument: boolean;
    uploadSignatureImageToS3: boolean;
    workOrders: boolean;
  };
  /**
   * `boolean` values which indicate when a feature
   * has completed successfully.
   */
  success: {
    fetchSummarizedForms: boolean;
    workOrders: boolean;
    submitDocument: boolean;
    continueDocument: boolean;
    uploadSignatureImageToS3: boolean;
    getSignatureUrlForParticipant: boolean;
  };
  // first step of Start New Form
  formTypes: Array<FormTypeDTO>;
  // second step of Start New Form
  forms: Array<FormSummaryVm>;
  // third optional step of Start New Form
  // @NOTE: STILL A WIP
  workOrders: Array<WorkOrderType>;

  // stored config options
  // used when rendering FormController
  selectedFormTypeId: number | null;
  selectedFormTemplateId: number | null;
  selectedWorkOrderId: number | null;

  /**
   * **signatureURLs** will store the writable and readable
   * url values which we'll use for the signature image upload process.
   *
   * When we save a signature image, we'll make a `PUT` request
   * to the `writable` url. Then, when we submit a document, we'll
   * store the `readable` url for each signature with each corresponding
   * participant.
   *
   * This will be replaced with an array of matching sets of Urls for
   * each participant within a document
   */
  signatureURLs: {
    writable: string | null;
    readable: string | null;
  };

  documentParticipantSignatureUrls: Array<ParticipantURL>;

  storedSignatureImages: Array<ParticipantSignature>;

  /**
   * received within response from `POST` & `PUSH` to `/api/documents` and will
   * be the source value for "New Document" and "Edit Document" features.
   * When the user in within those features, this value will contain the necessary
   * values to drive that feature
   */
  currentDocument: DocumentVM | null;

  isRehuddleStarted: boolean;
}

export const NAME = "NEW_DOCUMENT";

/**
 * Used with step 1 of Start New Document
 * This dispatches an API call to: `forms/all?summary=true`
 * In return, we'll get a summarized list of the available
 * Forms which we'll render as the first set of options
 * within the bottom drawer for the feature
 */
export const FETCH_SUMMARIZED_FORMS = {
  REQUEST: `${NAME}/FETCH_SUMMARIZED_FORMS/REQUEST`,
  SUCCESS: `${NAME}/FETCH_SUMMARIZED_FORMS/SUCCESS`,
  FAILURE: `${NAME}/FETCH_SUMMARIZED_FORMS/FAILURE`,
};

/**
 * // Used with step 2 of Start New Document
 * // This dispatches an API call to: `forms/all`
 * // This gives us the full list of Forms.
 *
 * //  @NOTE: Should we consolidate the API calls from step 1 and 2
 * // and use client side logic to create the "summarized" list?
 * // If that's a good option, we should handle it during the refactor process.
 * ! DEPRECATED
 * We are no longer using `FETCH_FORMS`
 * @TODO ensure all associated logic is removed
 */

export const FETCH_FORMS = {
  REQUEST: `${NAME}/FETCH_FORMS/REQUEST`,
  SUCCESS: `${NAME}/FETCH_FORMS/SUCCESS`,
  FAILURE: `${NAME}/FETCH_FORMS/FAILURE`,
};

export const FIND_WORK_ORDERS_FOR_NEW_DOCUMENT = {
  REQUEST: `${NAME}/FIND_WORK_ORDERS_FOR_NEW_DOCUMENT/REQUEST`,
  SUCCESS: `${NAME}/FIND_WORK_ORDERS_FOR_NEW_DOCUMENT/SUCCESS`,
  FAILURE: `${NAME}/FIND_WORK_ORDERS_FOR_NEW_DOCUMENT/FAILURE`,
};

export const CREATE_DOCUMENT = {
  REQUEST: `${NAME}/CREATE_DOCUMENT/REQUEST`,
  SUCCESS: `${NAME}/CREATE_DOCUMENT/SUCCESS`,
  FAILURE: `${NAME}/CREATE_DOCUMENT/FAILURE`,
};

export const PRE_FILL_DOCUMENT = {
  REQUEST: `${NAME}/PRE_FILL_DOCUMENT/REQUEST`,
  SUCCESS: `${NAME}/PRE_FILL_DOCUMENT/SUCCESS`,
  FAILURE: `${NAME}/PRE_FILL_DOCUMENT/FAILURE`,
  HYDRATE_WITH_PREFILLED: `${NAME}/PRE_FILL_DOCUMENT/HYDRATE_WITH_PREFILLED`,
  NO_RESPONSES: `${NAME}/PRE_FILL_DOCUMENT/NO_RESPONSES`,
};

export const SUBMIT_DOCUMENT = {
  REQUEST: `${NAME}/SUBMIT_DOCUMENT/REQUEST`,
  SUCCESS: `${NAME}/SUBMIT_DOCUMENT/SUCCESS`,
  FAILURE: `${NAME}/SUBMIT_DOCUMENT/FAILURE`,
  HYDRATE: `${NAME}/SUBMIT_DOCUMENT/HYDRATE`,
};

/**
 * Once the user has selected options and clicked
 * on the submit button to start a new form, we'll
 * dispatch this action which will
 * store their selected options
 */
export const START_NEW_FORM = `${NAME}/START_NEW_FORM`;

/**
 * Used when the user has started a rehuddle and the document screen should
 * show a rehuddle toast.
 */
export const UPDATE_REHUDDLE_STARTED = `${NAME}/UPDATE_REHUDDLE_STARTED`;

/**
 * Combine responses/form values from `Document.tsx` (from `FormController`)
 * with the `currentDocument` value in **New Document**'s reducer then make
 * the API call: `PUT` to `/api/documents`
 */
export const UPLOAD_NEW_DOCUMENT = {
  REQUEST: `${NAME}/UPLOAD_NEW_DOCUMENT/REQUEST`,
  SUCCESS: `${NAME}/UPLOAD_NEW_DOCUMENT/SUCCESS`,
  FAILURE: `${NAME}/UPLOAD_NEW_DOCUMENT/FAILURE`,
};

/**
 * Used when user selects "Start Safety Observation"
 * We'll make an API call to get the document data
 * then we'll enter the "Start New Document" flow
 * but with pre-filled values if available
 */
export const CONTINUE_DOCUMENT = {
  REQUEST: `${NAME}/CONTINUE_DOCUMENT/REQUEST`,
  SUCCESS: `${NAME}/CONTINUE_DOCUMENT/SUCCESS`,
  FAILURE: `${NAME}/CONTINUE_DOCUMENT/FAILURE`,
};

export const CLEAR_STATUS = {
  SUCCESS: `${NAME}/CLEAR_STATUS/SUCCESS`,
  ERROR: `${NAME}/CLEAR_STATUS/ERROR`,
  LOADING: `${NAME}/CLEAR_STATUS/LOADING`,
};

export const GET_SIGNATURE_URL = {
  REQUEST: `${NAME}/GET_SIGNATURE_URL/REQUEST`,
  SUCCESS: `${NAME}/GET_SIGNATURE_URL/SUCCESS`,
  FAILURE: `${NAME}/GET_SIGNATURE_URL/FAILURE`,
};

export const GET_SIGNATURE_URL_FOR_PARTICIPANT = {
  REQUEST: `${NAME}/GET_SIGNATURE_URL_FOR_PARTICIPANT/REQUEST`,
  SUCCESS: `${NAME}/GET_SIGNATURE_URL_FOR_PARTICIPANT/SUCCESS`,
  FAILURE: `${NAME}/GET_SIGNATURE_URL_FOR_PARTICIPANT/FAILURE`,
};

export const UPLOAD_SIGNATURE_TO_S3 = {
  REQUEST: `${NAME}/UPLOAD_SIGNATURE_TO_S3/FAILURE`,
  SUCCESS: `${NAME}/UPLOAD_SIGNATURE_TO_S3/FAILURE`,
  FAILURE: `${NAME}/UPLOAD_SIGNATURE_TO_S3/FAILURE`,
};

export type ClearStatusActionTypes = "loading" | "error" | "success";

export type ClearStatusPayloadTypes = Array<
  | "formTypes"
  | "forms"
  | "workOrders"
  | "createDocument"
  | "submitDocument"
  | "ALL"
>;

export const CLEAR_SUCCESS = `${NAME}/CLEAR_SUCCESS`;

export const RESET_CURRENT_DOCUMENT = `${NAME}/RESET_CURRENT_DOCUMENT`;

export const STORE_SIGNATURE_DATA = `${NAME}/STORE_SIGNATURE_DATA`;

export const CLEAR_SIGNATURE_DATA = `${NAME}/CLEAR_SIGNATURE_DATA`;

export const CLEAR_WORK_ORDERS = `${NAME}/CLEAR_WORK_ORDERS`;
