import * as React from "react";
import styled from "styled-components";

import { RouteComponentProps, withRouter } from "react-router";
import Collapse from "@material-ui/core/Collapse";

export interface NavigationListProps extends RouteComponentProps {
  menuData: NavigationMenuLink[];
}

export interface NavigationMenuLink {
  disabled?: boolean;
  expansion?: NavigationMenuLink[];
  icon: string;
  id?: string | number;
  link: any;
  linkTo?: string;
}

export interface NavigationItemProps {
  active?: boolean;
  disabled?: boolean;
  id?: string | number;
}

const NavigationItem = styled.div<NavigationItemProps>`
  background-color: ${({ active, theme }) => (active && theme.colors.activeFill) || theme.colors.white};
  border-radius: ${(props) => (props.active && "0px 4px 4px 0px") || "0"};
  color: ${({ active, theme }) => (active ? theme.colors.black : theme.masterColors.darkGrey)};
  cursor: ${({ disabled }) => (disabled ? "standard" : "pointer")};
  margin-left: -15px;
  opacity: ${({ disabled }) => disabled && "0.5"};
  pointer-events: ${({ disabled }) => disabled && "none"};
`;

const NavigationItemIcon = styled.i.attrs({})`
  font-size: 1.25rem !important;
  margin: 18px 30px 18px 15px;
`;

const NavigationItemLink = styled.div.attrs({})`
  font-size: 0.875rem !important;
`;

// Builds an object that drives what's collapsed
// If a navigation item has an expansion property, then that property has a sub item with
// a `linkTo` of exact or partial `location.pathname`, then the sub item's parent ID is added
// to an object that is then used for initial `expanded` value.
const getDefaultExpandedItems = (items, pathName) => {
  const result = {};
  if (items) {
    for (const property in items) {
      const expansionArray = items[property]["expansion"] || [];
      if (expansionArray.length > 0) {
        if (expansionArray.some((expansionItem) => pathName.includes(expansionItem.linkTo))) {
          result[items[property]["id"]] = true;
        }
      }
    }
  }
  return result;
};

const NavigationList = ({ menuData, history, location }: NavigationListProps) => {
  const { pathname } = location;
  const defaultExpanded = getDefaultExpandedItems(menuData, location.pathname);
  const [expanded, setExpanded] = React.useState(defaultExpanded);

  const setOpen = (key, value) => {
    setExpanded({
      ...expanded,
      [key]: value,
    });
  };

  return (
    <div>
      {menuData.map((menuItem: NavigationMenuLink, i: number) => {
        let active = menuItem.linkTo === location.pathname;
        if (menuItem.linkTo === "/reports") {
          active = location.pathname.includes(menuItem.linkTo);
        }
        return (
          <React.Fragment key={i}>
            <NavigationItem
              className="d-flex flex-row align-items-center"
              active={active}
              disabled={menuItem.disabled}
              onClick={() => {
                if (menuItem.expansion && menuItem.id) {
                  setOpen(menuItem.id, !expanded[menuItem.id]);
                }
                if (menuItem.linkTo) {
                  history.push(menuItem.linkTo);
                }
              }}
            >
              <NavigationItemIcon className={menuItem.icon} />
              <NavigationItemLink>{menuItem.link}</NavigationItemLink>
            </NavigationItem>
            {menuItem.expansion &&
              menuItem.expansion.map((s: NavigationMenuLink, index: number) => {
                return (
                  <Collapse key={index} in={expanded[menuItem.id || ""]} timeout="auto" unmountOnExit>
                    <NavigationItem
                      key={s.id}
                      disabled={s.disabled}
                      className="d-flex flex-row align-items-center ml-3"
                      active={s.linkTo === pathname}
                      onClick={() => s.linkTo && history.push(s.linkTo)}
                    >
                      <NavigationItemIcon className={s.icon} />
                      <NavigationItemLink onClick={() => undefined}>{s.link}</NavigationItemLink>
                    </NavigationItem>
                  </Collapse>
                );
              })}
          </React.Fragment>
        );
      })}
    </div>
  );
};

export default withRouter(NavigationList);
