import React, { CSSProperties } from "react";
import { ThemeContext } from "styled-components";
import { StyleSheet } from "aphrodite";

export default function (selected): { [key: string]: CSSProperties } {
  const theme = React.useContext(ThemeContext);

  return StyleSheet.create({
    possibleOesLabel: {
      color: selected ? theme.colors.primary : theme.masterColors.darkGrey,
      textTransform: "uppercase",
    },
    possibleOesNumber: {
      fontSize: "4rem"
    },
    oeCard: {
      background: `${theme.colors.background} !important`
    }
  });
};
