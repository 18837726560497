// This is a copy from fe-common v3.169.0 since we can't upgrade from v3.123.0 due
// to other upgradable libraries that interact here
import { omit } from "lodash";
import moment from "moment";
import {
  DisplayConditionDTO,
  DocumentQuestionResponseVm,
  FormSectionVm,
  Functions,
  isQuestionDTO,
  ParticipantUserVm,
  PrefillAlternate,
  QuestionDTO,
} from "@rtslabs/field1st-fe-common";
import { parseTime } from "util/dateUtilities";

const updateParticipantResponse = (
  question: QuestionDTO,
  owner: ParticipantUserVm,
  prefillAlternates: PrefillAlternate[]
) => {
  const prefillAnswerField =
    question.answerSource?.properties?.prefillAnswerField ||
    question.answerSource?.properties?.answerField ||
    "fullName";
  const prefillAssociatedIdField =
    question.answerSource?.properties?.prefillAssociatedIdField ||
    question.answerSource?.properties?.associatedIdField ||
    "id";
  return Functions.parseResponses(
    owner,
    {
      prefillAnswerField,
      prefillAssociatedIdField,
    },
    question,
    prefillAlternates
  );
};

// these should probably be somewhere else in common
function formatDateAnswer(date: Date): string {
  return parseTime(date.toISOString()).format(moment.HTML5_FMT.DATE);
}

function formatTimeAnswer(date: Date): string {
  return parseTime(date.toISOString()).format("HH:mmZ");
}

function generateResponsesForQuestion(
  question: QuestionDTO,
  owner: ParticipantUserVm,
  prefillAlternates: PrefillAlternate[]
): Functions.ResponseWithParent[] {
  // I hope these properties go away in favor of CURRENT_DATE below
  if (question.properties?.defaultToday) {
    return [
      {
        questionId: question.id,
        timeAnswered: new Date().toISOString(),
        answer: formatDateAnswer(new Date()),
      },
    ];
  }
  if (question.properties?.defaultNow) {
    return [
      {
        questionId: question.id,
        timeAnswered: new Date().toISOString(),
        answer:
          question.subType === "TIME"
            ? formatTimeAnswer(new Date())
            : formatDateAnswer(new Date()),
      },
    ];
  }

  // @ts-ignore - intentional because we can't update new version of fe-common due to other dependencies
  if (question.properties?.prefillDocumentCreator) {
    return updateParticipantResponse(question, owner, prefillAlternates);
  }

  switch (question.answerSource?.type) {
    case "CURRENT_PARTICIPANT":
      return updateParticipantResponse(question, owner, prefillAlternates);
    case "CURRENT_DATE":
      return [
        {
          questionId: question.id,
          timeAnswered: new Date().toISOString(),
          answer:
            question.subType === "TIME"
              ? formatTimeAnswer(new Date())
              : formatDateAnswer(new Date()),
        },
      ];
  }

  return [];
}

/**
 * Generates the default responses for questions with property specified
 * See generateResponsesForQuestion for more info
 */
export function generateQuestionDefaultResponses({
  sections,
  owner,
  displayConditions,
  existingResponses,
  prefillAlternates = [],
}: {
  sections: FormSectionVm[];
  owner: ParticipantUserVm;
  displayConditions: DisplayConditionDTO[];
  existingResponses: DocumentQuestionResponseVm[];
  prefillAlternates?: PrefillAlternate[];
}): DocumentQuestionResponseVm[] {
  let responses: Functions.ResponseWithParent[] = [...existingResponses];

  for (const section of sections) {
    for (const item of section.items) {
      if (!isQuestionDTO(item)) {
        continue;
      }

      // if prefilled by another question, skip it
      if (responses.some((r) => r.questionId === item.id)) {
        continue;
      }

      const questionResponses = generateResponsesForQuestion(
        item,
        owner,
        prefillAlternates
      );

      responses = responses.concat(questionResponses);
      for (const response of questionResponses) {
        if (response.parentContent) {
          responses = Functions.prefillFromQuestionContent({
            question: item,
            content: response.parentContent,
            displayConditions,
            sections,
            existingResponses: responses,
            prefillAlternates,
          });
        }
      }
    }
  }

  return responses.map((r) => omit(r, "parentContent"));
}
