import React from "react";

import * as S from "../styles";

interface Props {
  collapsed: boolean;
  onClick: () => void;
}

function ShowMoreButton({ collapsed, onClick }: Props) {
  return (
    <S.ShowMoreButton onClick={onClick}>
      {collapsed ? "SHOW MORE" : "SHOW LESS"}{" "}
      <S.Icon variant="show" collapsed={collapsed} className="icon icon-icons8-expand_arrow" />
    </S.ShowMoreButton>
  );
}

export default ShowMoreButton;
