import { callApi as simpleCallAPI, CALL_API } from "middleware/api";
import moment from "moment";

import {
  CreateDocumentVM,
  DocumentQuestionResponseVm as OldDocumentQuestionResponseVm,
  DocumentVM,
  DocumentParticipant,
  DocumentFormActionRequestsVm,
  GetDocumentResponse,
  DocumentSubmissionType,
} from "../documents/types";
import { UserState } from "../user/types";

import {
  CLEAR_SIGNATURE_DATA,
  CLEAR_STATUS,
  CLEAR_WORK_ORDERS,
  ClearStatusActionTypes,
  ClearStatusPayloadTypes,
  CONTINUE_DOCUMENT,
  CREATE_DOCUMENT,
  FETCH_SUMMARIZED_FORMS,
  FIND_WORK_ORDERS_FOR_NEW_DOCUMENT,
  GET_SIGNATURE_URL_FOR_PARTICIPANT,
  GET_SIGNATURE_URL,
  NewDocumentState,
  PRE_FILL_DOCUMENT,
  START_NEW_FORM,
  SUBMIT_DOCUMENT,
  RESET_CURRENT_DOCUMENT,
  STORE_SIGNATURE_DATA,
  UPDATE_REHUDDLE_STARTED,
  UPLOAD_NEW_DOCUMENT,
  UPLOAD_SIGNATURE_TO_S3,
  ParticipantSignature,
  HasIdOrNameOrRoles,
} from "./types";
import { getWorkOrderById } from "store/workOrders/actions";
import { areParticipantsEqual } from "components/FormController/helpers";
import { OperationalExperience } from "../resources/types";
import {
  DocumentQuestionResponseVm,
  DocumentVm,
  Functions,
  WorkOrderDTO,
} from "@rtslabs/field1st-fe-common";
import { isEmpty } from "lodash";
import {
  ParticipantUserVm,
  PrefillAlternate,
} from "@rtslabs/field1st-fe-common/dist/main/types";
import { generateQuestionDefaultResponses } from "fecommon/nonDisplayConditionPrefill";
import { generateParticipants } from "fecommon/generateParticipants";

// convert encoded image to blog for s3 upload
const convertDataToBlobForS3 = (dataURI: any) => {
  const binary = atob(dataURI.split(",")[1]);
  const array: Array<number> = [];
  for (let i = 0, len = binary.length; i < len; i++) {
    array.push(binary.charCodeAt(i));
  }
  return new Blob([new Uint8Array(array)], { type: "image/png" });
};

/**
 * Returns a summarized version of `Forms`.
 *
 * We'll use that response for the first 2 steps of __Start New Document__.
 * We're storing a unique set of the results as `store.newDocuments.formTypes`
 * and the total list as `store.newDocuments.forms`
 */
export const fetchSummarizedForms = () => (dispatch, getState) => {
  const { system } = getState();

  return dispatch({
    [CALL_API]: {
      endpoint: "forms/all&format=SUMMARY",
      options: {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${system.authToken}`,
        },
      },
      types: [
        FETCH_SUMMARIZED_FORMS.REQUEST,
        FETCH_SUMMARIZED_FORMS.SUCCESS,
        FETCH_SUMMARIZED_FORMS.FAILURE,
      ],
    },
  });
};

/**
 * search for work orders
 */
export const startSearchForWorkOrders = (workOrderId: string) => (
  dispatch,
  getState
) => {
  const { system } = getState();

  return dispatch({
    [CALL_API]: {
      // endpoint: `work-orders/?query=${workOrderId}`,
      endpoint: `work-orders?partialWorkOrderId=${workOrderId}`, // using query param for partial matches
      options: {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${system.authToken}`,
        },
      },
      types: [
        FIND_WORK_ORDERS_FOR_NEW_DOCUMENT.REQUEST,
        FIND_WORK_ORDERS_FOR_NEW_DOCUMENT.SUCCESS,
        FIND_WORK_ORDERS_FOR_NEW_DOCUMENT.FAILURE,
      ],
    },
  }).catch((err) => console.log("ERR", err));
};

/**
 * Used to initiate a new document with
 * the Start New Document feature
 */
export const startNewForm = ({
  selectedFormTypeId,
  selectedFormTemplateId,
  selectedWorkOrderId,
}: {
  selectedFormTypeId: number | null;
  selectedFormTemplateId: number | null;
  selectedWorkOrderId: number | null;
}) => ({
  type: START_NEW_FORM,
  payload: {
    selectedFormTypeId,
    selectedFormTemplateId,
    selectedWorkOrderId,
  },
});

export const updateRehuddleStarted = (isRehuddleStarted: boolean) => ({
  type: UPDATE_REHUDDLE_STARTED,
  payload: {
    isRehuddleStarted,
  },
});

/**
 * First step when creating/submitting a new document
 * from the "Start New Document" feature. We'll make a
 * `POST` to `/api/documents` endpoint which responds with
 * data which we'll use as a base for the new Document
 */
export const createDocument = ({
  formId,
  isRehuddle = false,
  parentId,
  submissionDate,
  workOrderId,
}: {
  formId: number;
  isRehuddle?: boolean;
  parentId?: number;
  submissionDate: string;
  workOrderId?: number;
}) => (dispatch, getState) => {
  const { authToken } = getState().system;
  const { data }: UserState = getState().user;

  const ownerId = data?.participantId;
  const clientGroupId = data?.primaryGroupId || data?.clientGroups[0]?.id;

  const endpoint = "documents";

  if (!ownerId || !clientGroupId) {
    return dispatch({
      type: CREATE_DOCUMENT.FAILURE,
    });
  }

  const types = [
    PRE_FILL_DOCUMENT.REQUEST,
    PRE_FILL_DOCUMENT.SUCCESS,
    PRE_FILL_DOCUMENT.FAILURE,
  ];

  const requestBody: CreateDocumentVM = {
    clientGroupId,
    formId,
    isRehuddle,
    ownerId,
    parentId,
    submissionDate,
    workOrderId,
    createdOffline: false,
    documentDevice: "WEB",
  };

  return dispatch({
    [CALL_API]: {
      endpoint,
      options: {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${authToken}`,
        },
        body: JSON.stringify(requestBody),
      },
      types,
    },
    payload: {
      isRehuddle,
    },
  }).then((res) => res);
};

/**
 * This action in similar to `createDocument` where it creates a new document
 * with the given form type and template.
 * The main difference is that this handles pre-filling responses from a work order.
 * This action will fetch the form data then transform it based
 * on the work order.
 *
 * This is where we look at the display conditions and create
 * responses for any matching questions. We'll than add those responses
 * to the form data and finally store in redux under `store.newDocument.currentDocument`.
 *
 * The "Edit Document" view will hold in a loading state until that value exists.
 */
export const createAndPrefillDocument = ({
  formId,
  isRehuddle = false,
  submissionDate,
  workOrderId,
}: {
  formId: number;
  isRehuddle?: boolean;
  submissionDate: string;
  workOrderId: number | undefined;
}) => (dispatch, getState) => {
  const { workOrders }: NewDocumentState = getState().newDocument;
  const { data: appConfigs } = getState().appConfigs;
  const { data: userData }: UserState = getState().user;
  const owner = (userData as unknown) as ParticipantUserVm;

  const prefillAlternates = (appConfigs.clientOverrides?.properties.form
    ?.prefillAlternates as unknown) as PrefillAlternate[] | undefined;

  // create the initial document
  return dispatch(
    createDocument({
      formId,
      isRehuddle,
      submissionDate,
      workOrderId,
    })
  )
    .then(async ({ response: oldModelDocument }: { response: DocumentVM }) => {
      dispatch({ type: PRE_FILL_DOCUMENT.REQUEST });
      // handle WORK ORDER PREFILL conditions

      // get work order associated to this document

      const document = (oldModelDocument as unknown) as DocumentVm;

      const { displayConditions, sections } = document.form;

      let responses: DocumentQuestionResponseVm[] = [...document.responses];

      responses = generateQuestionDefaultResponses({
        owner,
        sections,
        displayConditions: displayConditions!,
        existingResponses: responses,
        prefillAlternates,
      });

      if (workOrderId) {
        let associatedWorkOrder = workOrders.find(
          (workOrder) => workOrder.id === workOrderId
        );
        if (!associatedWorkOrder) {
          const res = await dispatch(getWorkOrderById(workOrderId));
          if (res.response) {
            associatedWorkOrder = res.response;
          }
        }

        const workOrderPrefillDisplayConditions = displayConditions?.filter(
          (dc) => dc.action === "WORK_ORDER_PREFILL"
        );

        // handle WORK ORDER PREFILL conditions
        // get work order associated to this document
        if (
          associatedWorkOrder &&
          !isEmpty(workOrderPrefillDisplayConditions)
        ) {
          try {
            const workOrder = (associatedWorkOrder as unknown) as WorkOrderDTO;
            responses = Functions.prefillFromWorkOrder({
              workOrder,
              sections,
              displayConditions: workOrderPrefillDisplayConditions || [],
              existingResponses: responses,
              prefillAlternates,
            });
          } catch (ex) {
            console.error(
              `Failed to fetch work order of id ${workOrderId}. Skipping prefill.`
            );
          }
        }
      }

      // if nothing was generated, just return document instead of resubmitting
      if (isEmpty(responses)) {
        dispatch({ type: PRE_FILL_DOCUMENT.SUCCESS });
        return dispatch({
          type: PRE_FILL_DOCUMENT.NO_RESPONSES,
          payload: document,
        });
      }

      const participants = generateParticipants([], responses, sections);

      //******** END Handle PREFILL responses based on this condition ********//

      const prefillRes = await dispatch(
        submitDocument(
          {
            ...((document as unknown) as DocumentVM),
            responses: (responses as unknown) as OldDocumentQuestionResponseVm[],
            participants: (participants as unknown) as DocumentParticipant[],
          },
          "NEW"
        )
      );

      if (prefillRes.response) {
        dispatch({ type: PRE_FILL_DOCUMENT.SUCCESS });
        return dispatch({
          type: PRE_FILL_DOCUMENT.HYDRATE_WITH_PREFILLED,
          payload: prefillRes.response,
        });
      }
    })
    .catch((err) => {
      dispatch({
        type: PRE_FILL_DOCUMENT.FAILURE,
        payload: err,
      });
    });
};

/**
 * Used when user selects "Start Safety Observation"
 * We'll make an API call to get the document data
 * then we'll enter the "Start New Document" flow
 * but with pre-filled values if available
 */
export const continueDocument = (documentId) => (dispatch, getState) => {
  const { authToken } = getState().system;

  const endpoint = `documents/${documentId}`;

  return dispatch({
    [CALL_API]: {
      endpoint,
      options: {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${authToken}`,
        },
      },
      types: [
        CONTINUE_DOCUMENT.REQUEST,
        CONTINUE_DOCUMENT.SUCCESS,
        CONTINUE_DOCUMENT.FAILURE,
      ],
    },
  }).then((res: GetDocumentResponse) => res);
};

export type SubmissionType =
  | "NEW"
  | "AUTO_SYNC"
  | "SAVE_DRAFT"
  | "SUBMIT"
  | "ADD_PARTICIPANT";

/**
 * dispatched when submitting a document
 * used when updating documents as well
 * This is where the logic is which combines incoming
 * values into the final shape used for the API request
 * for submitting the document
 */
export const uploadNewDocument = ({
  id,
  responses,
  documentParticipants,
  submissionType,
  operationalExperiences,
  formActionRequests = [],
}: {
  id?: number;
  responses: Array<OldDocumentQuestionResponseVm>;
  documentParticipants: Array<DocumentParticipant>;
  submissionType: DocumentSubmissionType;
  operationalExperiences?: Array<OperationalExperience>;
  formActionRequests?: Array<DocumentFormActionRequestsVm>;
}) => (dispatch, getState) => {
  const { authToken } = getState().system;
  const {
    currentDocument,
    documentParticipantSignatureUrls,
    loading,
    storedSignatureImages,
  }: NewDocumentState = getState().newDocument;

  // If submitting, don't attempt to submit
  if (loading?.submitDocument || !currentDocument?.id) return;

  let participants;

  if (documentParticipants) {
    participants = documentParticipants.map((p) => {
      if (p.signatureType === "DRAWN") {
        return {
          name: p.name,
          participantId: p.participantId,
          role: p.role,
          timeAdded: p.timeAdded,
          signatureDate: p.signatureDate,
          signatureType: p.signatureType,
          signatureUrl: p.signatureUrl,
        };
      } else if (p.signatureType) {
        return {
          name: p.name,
          participantId: p.participantId,
          role: p.role,
          timeAdded: p.timeAdded,
          signatureDate: p.signatureDate,
          signatureType: p.signatureType,
          signatureTextValue: p.signatureTextValue,
        };
      }

      return {
        ...p,
        participantId: p.participantId,
      };
    });
  } else {
    participants = "";
  }

  // type commented until this is complete
  const requestBody /* DocumentUpdateVM */ = {
    formActionRequests,
    id: currentDocument?.id || id,
    participants: participants,
    responses,
    sectionComments: [],
    submissionDate: moment.utc().format(),
    submissionType,
    operationalExperiences,
  };

  // upload all signatures to s3
  storedSignatureImages.map((storedSignatureImage) => {
    const image = convertDataToBlobForS3(storedSignatureImage.signatureData);
    const docSig = documentParticipantSignatureUrls.find((s3Urls) =>
      areParticipantsEqual(s3Urls.participant, storedSignatureImage)
    );

    if (image && docSig && docSig.writableUrl) {
      // @TODO add dispatches for success & failures
      return fetch(docSig.writableUrl, {
        method: "PUT",
        headers: {
          "Content-Type": "image/png",
        },
        body: image,
      })
        .then(() => {
          return null;
        })
        .catch(() => {
          return null;
        });
    }

    return null;
  });

  const endpoint = "documents";

  return dispatch({
    [CALL_API]: {
      endpoint,
      options: {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${authToken}`,
        },
        body: JSON.stringify(requestBody),
      },
      types: [
        UPLOAD_NEW_DOCUMENT.REQUEST,
        UPLOAD_NEW_DOCUMENT.SUCCESS,
        UPLOAD_NEW_DOCUMENT.FAILURE,
      ],
    },
  });
};

export const submitDocument = (
  document: DocumentVM,
  submissionType: SubmissionType
) => (dispatch, getState) => {
  const { authToken } = getState().system;

  // type commented until this is complete
  const requestBody /*: DocumentUpdateVM */ = {
    formActionRequests: document.formActionRequests,
    id: document && document.id,
    participants: document.participants,
    responses: document.responses,
    sectionComments: [],
    submissionDate: moment.utc().format(),
    submissionType,
  };

  const endpoint = "documents";

  return dispatch({
    [CALL_API]: {
      endpoint,
      options: {
        method: "PUT",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBody),
      },
      types: [
        SUBMIT_DOCUMENT.REQUEST,
        SUBMIT_DOCUMENT.SUCCESS,
        SUBMIT_DOCUMENT.FAILURE,
      ],
    },
  })
    .then((res) => res)
    .catch((err) => {
      throw new Error(err);
    });
};

const clearStatusTypes = {
  loading: CLEAR_STATUS.LOADING,
  error: CLEAR_STATUS.ERROR,
  success: CLEAR_STATUS.SUCCESS,
};

/**
 * This action helps us clear a boolean value for
 * the specified feature within the specified status type
 *
 * ie: you can clear the error flag, success flag, or loading flag
 * for the feature passed in as the payload
 */
export const clearStatus = ({
  actionType,
  payload,
}: {
  actionType: ClearStatusActionTypes;
  payload: ClearStatusPayloadTypes;
}) => ({
  type: clearStatusTypes[actionType],
  clearStatusPayload: payload,
});

/**
 * clears `currentDocument` values from New Document reducer
 * this is used when `Dashboard.tsx` mounts, since we
 * want to rest that data in preparation for future actions
 */
export const resetCurrentDocument = () => ({
  type: RESET_CURRENT_DOCUMENT,
});

/**
 * Retrieve writable and readable image URLs for a given document ID
 * @param documentID
 */
export const getSignatureUrls = (documentID: number) => (
  dispatch,
  getState
) => {
  const { authToken } = getState().system;
  return dispatch({
    [CALL_API]: {
      endpoint: `documents/${documentID}/writable-signature-url`,
      options: {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${authToken}`,
        },
      },
      types: [
        GET_SIGNATURE_URL.REQUEST,
        GET_SIGNATURE_URL.SUCCESS,
        GET_SIGNATURE_URL.FAILURE,
      ],
    },
  });
};

/**
 * dispatched for each document participant added to a document
 * we'll make the API call to get the writable and readable URLS
 * and store them along with the associated document participant
 *
 */
export const getSignatureUrlForParticipant = (
  participant: HasIdOrNameOrRoles,
  documentId: number
) => (dispatch, getState) => {
  const { authToken } = getState().system;

  dispatch({
    type: GET_SIGNATURE_URL_FOR_PARTICIPANT.REQUEST,
  });

  const endpoint = `documents/${documentId}/writable-signature-url`;

  const options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${authToken}`,
    },
  };

  return simpleCallAPI(endpoint, options)
    .then((response) => {
      const readableUrl = response.readableUrl;
      const writableUrl = response.writableUrl;

      return dispatch({
        type: GET_SIGNATURE_URL_FOR_PARTICIPANT.SUCCESS,
        payload: {
          readableUrl,
          writableUrl,
          participant,
        },
      });
    })
    .catch((err) =>
      dispatch({
        type: GET_SIGNATURE_URL_FOR_PARTICIPANT.FAILURE,
        payload: err,
      })
    );
};

/**
 * Dispatched from within __FormController__
 * This action will signify when the upload fetch
 * has started when uploading a signature image to s3
 */
export const startUploadSignatureToS3 = () => ({
  type: UPLOAD_SIGNATURE_TO_S3.REQUEST,
});

/**
 * Upon a successful upload, this action will
 * be dispatched from within __FormController__
 */
export const succeedUploadSignatureToS3 = () => ({
  type: UPLOAD_SIGNATURE_TO_S3.SUCCESS,
});

/**
 * If the request made to upload a signature to s3 fails
 * we'll dispatch this action from within __FormController__
 */
export const failureUploadSignatureToS3 = (payload: string) => ({
  type: UPLOAD_SIGNATURE_TO_S3.FAILURE,
  payload,
});

/**
 * action which will store the given signature data
 * and associated participant within an array
 * to be used when we submit the document. At that time,
 * we'll submit the entire array to s3
 */
export const storeSignatureData = (signature: ParticipantSignature) => ({
  type: STORE_SIGNATURE_DATA,
  payload: signature,
});

export const clearSignatureData = (HasIdOrNameOrRoles: HasIdOrNameOrRoles) => ({
  type: CLEAR_SIGNATURE_DATA,
  payload: HasIdOrNameOrRoles,
});

export const clearWorkOrders = () => ({ type: CLEAR_WORK_ORDERS });

// TEMPORARY UNTIL REFACTOR
export const updateCurrentDocumentValues = (updates: Partial<DocumentVM>) => ({
  type: "UPDATE_CURRENT_DOCUMENT_VALUES",
  response: updates,
});
