import React from "react";
import { css } from "aphrodite/no-important";

import styles from "./styles";
import { H5 } from "components/clientAdmin/styles";

interface Props {
  active?: boolean;
  direction: "left" | "right";
  children?: React.ReactNode;
  label?: string;
  detail?: string;
}

const SidebarIntro = ({ active, direction, label, detail }: Props) => {
  const s = styles();
  return (
    <div className={
      css([
        s.sidebarIntro,
        active && s.introActive,
        direction === "left" ? s.introLeft : s.introRight
      ])}
    >
      <H5 className={css(s.sidebarIntroLabel)}>{label}</H5>
      <span>
        {detail}
      </span>
    </div>
  );
};

export default SidebarIntro;
