import { uniq, omit } from "lodash";

import { buildFilteredEndpoint } from "../common/apiUtilities";
import { CALL_API } from "../../middleware/api";
import { FBForm, FBSection } from "components/clientAdmin/formBuilder/types";
import { FilterParams } from "../common/types";
import { FormDTO, QuestionDTO, FormRelationshipTypeDTO, WorkflowType } from "store/forms/types";
import {
  GetBuilderFormsResponse,
  NewFormSection,
  NewFormContent,
  NewFormWidget,
  NewFormQuestion,
  NewFBForm, 
  GetDefensesResponse,
  UpdateDefenseVM,
  GetFormRelationshipTypesResponse,
  UpdateFormResponse,
  UpdateFormRelationshipTypeResponse,
  GetPossibleOesResponse,
} from "./types";
import { Defense } from "store/documents/types";

/** Get form summaries with stats */
export const getFBSummaries = (params: FilterParams) => (dispatch, getState) => {
  const authToken = getState().system.authToken;
  const endpoint = buildFilteredEndpoint("builder/forms", params);
  return dispatch({
    [CALL_API]: {
      endpoint,
      options: {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${authToken}`,
        },
      },
      types: ["GET_BUILDER_FORMS_REQUEST","GET_BUILDER_FORMS_SUCCESS","GET_BUILDER_FORMS_FAILURE"],
    },
  })
    .then((res: GetBuilderFormsResponse) => res);
};

/**
 * Get the list of form types available to the Form Builder
 */
export const getFormTypes = () => (dispatch, getState) => {
  const { authToken } = getState().system;

  return dispatch({
    [CALL_API]: {
      endpoint: "form-types/all",
      options: {
        method: "GET",
        headers: {
          Authorization: `Bearer ${authToken}`,
          Accept: "application/json",
        }
      },
      types: ["GET_FORM_TYPES_REQUEST","GET_FORM_TYPES_SUCCESS","GET_FORM_TYPES_FAILURE",]
    }
  }).then((res) => res)
    .catch((err) => {
      throw new Error(err);
    });
};

/**
 * get a form for form builder
 * @param formId Form to GET
 */
export const getBuilderForm = (formId: number | string) => (dispatch, getState) => {
  const { authToken } = getState().system;

  return dispatch({
    [CALL_API]: {
      endpoint: `builder/forms/${formId}`,
      options: {
        method: "GET",
        headers: {
          Authorization: `Bearer ${authToken}`,
          Accept: "application/json",
        },
      },
      types: ["GET_BUILDER_FORM_REQUEST", "GET_BUILDER_FORM_SUCCESS", "GET_BUILDER_FORM_FAILURE"]
    }
  })
    .then((res: UpdateFormResponse) => res)
    .catch((err) => {
      throw new Error(err);
    });
};

export const PUT_BUILDER_FORM = {
  REQUEST: "PUT_BUILDER_FORM_REQUEST",
  SUCCESS: "PUT_BUILDER_FORM_SUCCESS",
  FAILURE: "PUT_BUILDER_FORM_FAILURE",
};

/**
 * Add or update a form for form builder
 * @param form Form to PUT
 */
export const putBuilderForm = (form: FBForm | NewFBForm | FormDTO) => (dispatch, getState) => {
  const { authToken } = getState().system;

  return dispatch({
    [CALL_API]: {
      endpoint: "builder/forms",
      options: {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${authToken}`,
          Accept: "application/json",
          "Content-Type": "application/json"
        },
        body: JSON.stringify(form)
      },
      types: [PUT_BUILDER_FORM.REQUEST, PUT_BUILDER_FORM.SUCCESS, PUT_BUILDER_FORM.FAILURE]
    }
  })
    .then((res: UpdateFormResponse) => res)
    .catch((err) => {
      throw new Error(err);
    });
};

export const deleteBuilderForm = (formId: number) => (dispatch, getState) => {
  const { authToken } = getState().system;

  return dispatch({
    [CALL_API]: {
      endpoint: `builder/forms/${formId}`,
      options: {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${authToken}`,
          // Accept: "application/json",
          // "Content-Type": "application/json"
        },
      },
      types: ["DELETE_BUILDER_FORM_REQUEST", "DELETE_BUILDER_FORM_SUCCESS", "DELETE_BUILDER_FORM_FAILURE"]
    }
  });
};

/**
 * Add or update a section in a given form
 * @param formId Form ID to PUT the section to
 * @param section Section to add/update
 * @param index optional: Index at which to insert the section
 */
export const putFormSection = (
  formId: number,
  section: NewFormSection | FBSection,
  index?: number
) => (dispatch, getState): Promise<UpdateFormResponse> => {
  const { authToken } = getState().system;

  const body = { ...section, workflowType: "DRAFT" };

  return dispatch({
    [CALL_API]: {
      endpoint: `builder/forms/${formId}/sections${index !== undefined ? `?index=${index}` : ""}`,
      options: {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${authToken}`,
        },
        body: JSON.stringify(body),
      },
      types: ["PUT_FORM_SECTION_REQUEST", "PUT_FORM_SECTION_SUCCESS", "PUT_FORM_SECTION_FAILURE"],
    },
  });
};

/**
 * Add or update an item in a given form section
 * @param formId Form to PUT the item to
 * @param sectionId Section to PUT the item to
 * @param item Item to add/update
 * @param index optional: Index at which to insert the item
 */
export const putFormItem = (
  formId: number, 
  sectionId: number, 
  item: NewFormQuestion | NewFormWidget | NewFormContent | QuestionDTO,
  index?: number,
) => (dispatch, getState): Promise<UpdateFormResponse> => {
  const { authToken } = getState().system;

  const body = { ...item, workflowType: "DRAFT" };

  return dispatch({
    [CALL_API]: {
      endpoint: `builder/forms/${formId}/sections/${sectionId}/items${index !== undefined ? `?index=${index}` : ""}`,
      options: {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${authToken}`,
        },
        body: JSON.stringify(body),
      },
      types: ["PUT_FORM_ITEM_REQUEST", "PUT_FORM_ITEM_SUCCESS", "PUT_FORM_ITEM_FAILURE"],
    },
  })
    .then((res: UpdateFormResponse) => res)
    .catch((err) => {
      throw new Error(err);
    });
};

/**
 * Delete an item from a section
 * @param formId Form from which to delete the item
 * @param sectionId Section from which to delete the item
 * @param itemIndex Index of the item in the section
 * @param removeWidgetChildren optional - if an item is a widget, delete its children. Defaults to true
 */
export const deleteFormItem = (
  formId: number, 
  sectionId: number, 
  itemIndex: number, 
  removeWidgetChildren = true
) => (dispatch, getState) => {
  const { authToken } = getState().system;

  return dispatch({
    [CALL_API]: {
      endpoint: `builder/forms/${formId}/sections/${sectionId}/` +
        `items?index=${itemIndex}&removeWidgetChildren=${removeWidgetChildren}`,
      options: {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${authToken}`,
          Accept: "application/json",
        }
      },
      types: ["DELETE_FORM_ITEM_REQUEST", "DELETE_FORM_ITEM_SUCCESS", "DELETE_FORM_ITEM_FAILURE"]
    }
  })
    .then((res: UpdateFormResponse) => res)
    .catch((err) => {
      throw new Error(err);
    });
};

/**
 * Delete a section from a form
 * @param formId Form from which to delete the section
 * @param sectionId Section to delete
 * @param removeChildren Remove the section's children. Defaults to true
 */
export const deleteFormSection = (formId: number, sectionId: number, removeChildren = true) => (dispatch, getState) => {
  const { authToken } = getState().system;

  return dispatch({
    [CALL_API]: {
      endpoint: `builder/forms/${formId}/sections/${sectionId}?removeWidgetChildren=${removeChildren}`,
      options: {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${authToken}`,
          Accept: "application/json",
        }
      },
      types: ["DELETE_FORM_SECTION_REQUEST", "DELETE_FORM_SECTION_SUCCESS", "DELETE_FORM_SECTION_FAILURE"]
    }
  })
    .then((res: UpdateFormResponse) => res)
    .catch((err) => {
      throw new Error(err);
    });
};

/**
 * Create a shallow copy of a form
 * @param id - id of form to be copied
 */
export const cloneForm = (id: number) => (dispatch, getState) => {
  const authToken = getState().system.authToken;
  return dispatch({
    [CALL_API]: {
      endpoint: `builder/forms/${id}/clone`,
      options: {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${authToken}`,
        },
      },
      types: [
        "CLONE_FORM_REQUEST",
        "CLONE_FORM_SUCCESS",
        "CLONE_FORM_FAILURE"
      ],
    },
  })
    .then((res: UpdateFormResponse) => res)
    .catch((err) => {
      throw new Error(err);
    });
};

export const getMapWidgetQuestions = () => (dispatch, getState) => {
  // /api/application-configs/BASE_MAP_WIDGET_QUESTIONS
  const { authToken } = getState().system;
  return dispatch({
    [CALL_API]: {
      endpoint: "application-configs/BASE_MAP_WIDGET_QUESTIONS",
      options: {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${authToken}`,
        },
      },
      types: [
        "GET_MAP_WIDGET_QUESTIONS_REQUEST",
        "GET_MAP_WIDGET_QUESTIONS_SUCCESS",
        "GET_MAP_WIDGET_QUESTIONS_FAILURE"
      ],
    },
  })
    .then((res) => res)
    .catch((err) => {
      throw new Error(err);
    });
};

export const GET_DEFENSES = {
  REQUEST: "GET_DEFENSES_REQUEST",
  SUCCESS: "GET_DEFENSES_SUCCESS",
  FAILURE: "GET_DEFENSES_FAILURE",
};

export const getDefenses = (params?: FilterParams) => (dispatch, getState) => {
  const { authToken } = getState().system;

  const endpoint = buildFilteredEndpoint("defenses", params);

  return dispatch({
    [CALL_API]: {
      endpoint,
      options: {
        method: "GET",
        headers: {
          Authorization: `Bearer ${authToken}`,
          Accept: "application/json"
        },
      },
      types: [GET_DEFENSES.REQUEST, GET_DEFENSES.SUCCESS, GET_DEFENSES.FAILURE]
    }
  })
    .then((res: GetDefensesResponse) => res)
    .catch((err) => {
      throw new Error(err);
    });
};

export const GET_DEFENSE_BY_ID = {
  REQUEST: "GET_DEFENSE_BY_ID_REQUEST",
  SUCCESS: "GET_DEFENSE_BY_ID_SUCCESS",
  FAILURE: "GET_DEFENSE_BY_ID_FAILURE",
};

/**
 * Get a defense by ID
 * @param id defense ID
 */
export const getDefenseById = (id: number) => (dispatch, getState) => {
  const { authToken } = getState().system;

  return dispatch({
    [CALL_API]: {
      endpoint: `defenses/${id}`,
      options: {
        method: "GET",
        headers: {
          Authorization: `Bearer ${authToken}`,
          Accept: "application/json"
        },
      },
      types: [GET_DEFENSE_BY_ID.REQUEST, GET_DEFENSE_BY_ID.SUCCESS, GET_DEFENSE_BY_ID.FAILURE]
    }
  })
    .then((res: Defense) => res)
    .catch((err) => {
      throw new Error(err);
    });
};

/**
 * Update a defense, or add a new one if defense.id is null
 * @param defense
 */
export const updateDefense = (defense: UpdateDefenseVM) => (dispatch, getState) => {
  const { authToken } = getState().system;

  return dispatch({
    [CALL_API]: {
      endpoint: "defenses",
      options: {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${authToken}`,
          Accept: "application/json",
          "Content-Type": "application/json"
        },
        body: JSON.stringify(defense)
      },
      types: [
        "UPDATE_DEFENSE_REQUEST",
        "UPDATE_DEFENSE_SUCCESS",
        "UPDATE_DEFENSE_FAILURE"
      ]
    }
  })
    .then((res: Defense) => res)
    .catch((err) => {
      throw new Error(err);
    });
};

/**
 * Add defense to a question selection by adding the question selection id to the defense questionSelectionIds
 * @param defense Defense to add the question selection to
 * @param questionSelectionId ID of question selection
 */
export const addDefenseToQuestionSelection = (defense: Defense, questionSelectionId: number) => (dispatch) => {
  const existingSelections = defense.questionSelections?.map((qs) => qs.id) || [];
  const questionSelectionIds = uniq([...existingSelections, questionSelectionId]);
  const body = { ...defense, questionSelectionIds };

  return dispatch(updateDefense(body));
};

/**
 * Remove a defense from a question selection by remove the question selection from the defense questionSelections
 * @param defense Defense to add the question selection to
 * @param questionSelectionId ID of question selection
 */
export const removeDefenseFromQuestionSelection = (defense: Defense, questionSelectionId: number) => (dispatch) => {
  const questionSelections = defense.questionSelections?.filter((selection) => selection.id !== questionSelectionId);
  const body = { ...omit(defense, "questionSelectionIds"), questionSelections };

  return dispatch(updateDefense(body));
};

export const GET_POSSIBLE_FORM_OES_COUNT = {
  REQUEST: "GET_POSSIBLE_FORM_OES_COUNT_REQUEST",
  SUCCESS: "GET_POSSIBLE_FORM_OES_COUNT_SUCCESS",
  FAILURE: "GET_POSSIBLE_FORM_OES_COUNT_FAILURE",
};

/**
 * Get the total count of OEs that could possibly be rendered by a form
 * @param formId
 */
export const getPossibleFormOesCount = (formId: number) => (dispatch, getState) => {
  const { authToken } = getState().system;

  return dispatch({
    [CALL_API]: {
      endpoint: `operational-experiences/possible-matches-form/${formId}`,
      options: {
        method: "GET",
        headers: {
          Authorization: `Bearer ${authToken}`,
          Accept: "application/json"
        },
      },
      types: [
        GET_POSSIBLE_FORM_OES_COUNT.REQUEST,
        GET_POSSIBLE_FORM_OES_COUNT.SUCCESS,
        GET_POSSIBLE_FORM_OES_COUNT.FAILURE
      ]
    }
  }).then((res: GetPossibleOesResponse) => res)
    .catch((err) => {
      throw new Error(err);
    });
};

export const GET_POSSIBLE_QUESTION_OES_COUNT = {
  REQUEST: "GET_POSSIBLE_QUESTION_OES_COUNT_REQUEST",
  SUCCESS: "GET_POSSIBLE_QUESTION_OES_COUNT_SUCCESS",
  FAILURE: "GET_POSSIBLE_QUESTION_OES_COUNT_FAILURE",
};

/**
 * Get the total count of OEs that could possibly be rendered by a question
 * @param questionId
 */
export const getPossibleQuestionOesCount = (questionId: number) => (dispatch, getState) => {
  const { authToken } = getState().system;

  return dispatch({
    [CALL_API]: {
      endpoint: `operational-experiences/possible-matches-question/${questionId}`,
      options: {
        method: "GET",
        headers: {
          Authorization: `Bearer ${authToken}`,
          Accept: "application/json"
        },
      },
      types: [
        GET_POSSIBLE_QUESTION_OES_COUNT.REQUEST,
        GET_POSSIBLE_QUESTION_OES_COUNT.SUCCESS,
        GET_POSSIBLE_QUESTION_OES_COUNT.FAILURE
      ]
    }
  }).then((res: GetPossibleOesResponse) => res)
    .catch((err) => {
      throw new Error(err);
    });
};

export const putWorkflowType = (formId: number, workflowType: WorkflowType) => (dispatch, getState) => {
  const { authToken } = getState().system;

  return dispatch({
    [CALL_API]: {
      endpoint: `builder/forms/${formId}/workflow-type/${workflowType}`,
      options: {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${authToken}`,
          Accept: "application/json"
        },
      },
      types: [
        "CHANGE_WORKFLOW_TYPE_REQUEST",
        "CHANGE_WORKFLOW_TYPE_SUCCESS",
        "CHANGE_WORKFLOW_TYPE_FAILURE",
      ]
    }
  }).then((res: UpdateFormResponse) => res)
    .catch((err) => {
      throw new Error(err);
    });
};
