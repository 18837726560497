import React from "react";

import TextInput from "components/common/form/TextInput";

import { ProfileFieldTextField } from "../../../types";

import DesktopLabel from "../../DesktopLabel";

import * as S from "./styles";

interface Props extends ProfileFieldTextField {
  label: string;
  locked?: boolean;
}

const TextField = (props: Props) => {
  return (
    <>
      <DesktopLabel label={props.label} name={props.name} locked={props.locked} />

      {props.locked ? <S.LockedInput {...props} disabled /> : <TextInput {...props} label="" variant="outlined" />}
    </>
  );
};

export default TextField;
