import React, { useState, useEffect, useMemo } from "react";
import { Action } from "redux";
import { css } from "aphrodite/no-important";
import { ThunkDispatch } from "redux-thunk";
import { useDispatch } from "react-redux";
import { useFormikContext } from "formik";

import { AppState } from "store";
import { CONTENT_COPY } from "components/clientAdmin/formBuilder/language";
import { FBForm, FBOEWidget } from "components/clientAdmin/formBuilder/types";
import {
  getPossibleFormOesCount,
  GET_POSSIBLE_FORM_OES_COUNT,
} from "store/builder/actions";
import { getQuestionsWithOeTags } from "../../../properties/customFields/Tags/helpers";
import OECard from "components/OperationalExperiences/components/OECard";
import usePrevious from "util/hooks/usePrevious";

import styles from "./styles";

interface Props {
  item: FBOEWidget;
  selected?: boolean;
}

const OEWidget = ({ selected }: Props) => {
  const [included, setIncluded] = useState<boolean>(false);
  const [possibleOes, setPossibleOes] = useState<number>(0);
  const [error, setError] = useState<string>("");

  const { values } = useFormikContext<FBForm>();
  const dispatch = useDispatch<ThunkDispatch<AppState, void, Action>>();
  const s = styles(selected);

  // track the question selections with tags added so we can update the possible OEs count
  const questionsWithTags = useMemo(() => getQuestionsWithOeTags(values), [
    values,
  ]);
  const tagsCount = useMemo(
    () =>
      questionsWithTags
        .flatMap((question) => question.selections)
        .reduce((count, selection) => {
          return count + (selection?.tags.length || 0);
        }, 0),
    [questionsWithTags]
  );
  const previousTagsCount = usePrevious(tagsCount);

  useEffect(() => {
    // if any selections with tags have been added/removed, refetch the possible OEs count
    if (previousTagsCount !== tagsCount) {
      (async () => {
        const res = await dispatch(getPossibleFormOesCount(values.id));
        if (res.type === GET_POSSIBLE_FORM_OES_COUNT.SUCCESS) {
          setPossibleOes(res.response);
        } else if (res.type === GET_POSSIBLE_FORM_OES_COUNT.FAILURE) {
          setError(res.error);
        }
      })();
    }
  }, [tagsCount]);

  function toggleIncluded() {
    setIncluded((prevIncluded) => !prevIncluded);
  }

  return (
    <div>
      <div className={css(s.possibleOesLabel)}>
        {!error && (
          <>
            <span className={css(s.possibleOesNumber)}>{possibleOes}</span>
            <span> possible operational experiences</span>
          </>
        )}
      </div>
      <OECard
        className={css(s.oeCard)}
        handleIncludeExcludeOE={toggleIncluded}
        included={included}
        loading={false}
        oe={{
          categories: [],
          id: 10000,
          incident: "Operational Experience",
          description: CONTENT_COPY.oeWidgetDescription,
          status: "ACTIVE",
          clientGroups: [],
        }}
      />
    </div>
  );
};

export default OEWidget;
