import React, { MouseEventHandler } from "react";
import { useMouseFocus } from "util/hooks/useMouseFocus";
import styles from "./clearButtonStyles.module.scss";
import { joinClassNames } from "themes/helpers";

interface ClearButtonProps {
  handleClick?: MouseEventHandler<HTMLButtonElement>;
}

export const ClearButton = ({ handleClick }: ClearButtonProps) => {
  const { eventHandlers, isMouseFocused } = useMouseFocus();

  return (
    <button
      className={joinClassNames(
        styles.button,
        !isMouseFocused && styles.buttonKeyboardFocused
      )}
      onClick={handleClick}
      type="button"
      {...eventHandlers}
    >
      Clear
    </button>
  );
};
