import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { sortBy } from "lodash";
import { useMediaQuery } from "react-responsive";

import { devices } from "themes/mediaQueries";
import { getDataSourceValues } from "store/dataSource/actions";
import { getUserFormStats } from "store/user/actions";
import { getUserProfile, getUserFormStats as selectUserFormStats } from "store/user/selectors";
import Logout from "components/Settings/Logout";
import ProfileCard, { UserForm } from "components/Profile/ProfileCard";
import ProfileMenu from "components/Profile/ProfileMenu";

import EditProfile from "../EditProfile";

const Profile = () => {
  const isTablet = useMediaQuery({
    maxWidth: devices.maxTablet,
  });

  const dispatch = useDispatch();
  const userProfile = useSelector(getUserProfile);
  const userFormStats = useSelector(selectUserFormStats);

  // !ASYNC
  // fetch work locations
  useEffect(() => {
    dispatch(getDataSourceValues({ keyName: "WORK_LOCATION" }));
  }, []);

  // ! ASYNC
  // fetch User Form Stats if they don't exist && !error
  useEffect(() => {
    if (!userFormStats.data && !userFormStats.loading && !userFormStats.error) {
      dispatch(getUserFormStats());
    }
  }, [dispatch, userFormStats.loading, userFormStats.error, userFormStats.data]);

  const userForms: Array<UserForm> | undefined = useMemo(() => {
    return (
      userFormStats.data &&
      sortBy(
        userFormStats.data.map((ufs) => ({
          id: ufs.formTypeId,
          count: ufs.totalDocuments,
          icon: ufs.formTypeIconName,
          iconColor: ufs.formTypeIconColor,
          name: ufs.formTypeName,
        })),
        "name",
      )
    );
  }, [userFormStats.data]);

  const groupLabel = useMemo(() => {
    const { primaryGroupId, groups } = userProfile;

    const primaryGroup = groups.find((x) => x.id === primaryGroupId);

    if (primaryGroup && primaryGroup.name) {
      const count = groups.length - 1;
      const other = count === 1 ? "other" : "others";
      const countLabel = count > 0 ? ` + ${count} ${other}` : "";
      const otherGroups = groups.filter((x) => x.id !== primaryGroupId).map((y) => y.name);
      const otherGroupsLabel = otherGroups.length > 0 ? `, ${otherGroups.join(", ")}` : undefined;

      return {
        mobile: `${primaryGroup.name}` + countLabel,
        desktop: otherGroupsLabel ? `${primaryGroup.name}` + otherGroupsLabel : `${primaryGroup.name}`
      };
    }

    return {
      mobile: "",
      desktop: ""
    };
  }, [userProfile.groups, userProfile.primaryGroupId]);

  return (
    <>
      <ProfileCard
        name={userProfile.name}
        email={userProfile.email}
        groupLabel={groupLabel}
        userForms={userForms}
      />
      {isTablet && <ProfileMenu />}
      {!isTablet && <EditProfile />}
      {isTablet && <Logout marginTop="100px" />}
    </>
  );
};

export default Profile;
