import React, { useState, useMemo } from "react";

import { createMarkup } from "util/index";
import { DocumentVM, DocumentQuestionResponseVm, Defense } from "store/documents/types";
import { getResponsesAssociatedWithDefenses } from "components/FormController/helpers";

import * as S from "./styles";

interface GetComment {
  responses: {
    [key: number]: DocumentQuestionResponseVm;
  };
  id: number;
}

/**
 * Gets the comment string value for the given id
 * from the given responses. The id should be the
 * Defense questionSelectionIds[0].
 * @return string
 */
const getComment = ({ responses, id }: GetComment) => {
  const response = responses[id];
  if (response && typeof response.comments === "string") {
    return response.comments;
  }

  return "--";
};

interface Props {
  document?: DocumentVM | null;
  documentId?: number;
  responseIds: Array<number | null | undefined>;
  inDrawer?: boolean;
}

/**
 * Renders Defenses for a Document within
 * the Document Drawer from View User Profile
 */
const ViewDefenses = ({ responseIds, document, inDrawer }: Props) => {
  const [cachedDefenses, setCachedDefenses] = useState<Array<Defense>>([]);

  /** filter defenses by response ids and set in state on mount */
  React.useEffect(() => {
    if (document?.form?.defenses) {
      setCachedDefenses(document.form.defenses.filter(
        (def) => def.questionSelectionIds?.[0] && responseIds.includes(def.questionSelectionIds?.[0])
      ));
    }
  }, [document]);

  /**
   * Object with key being associatedID and value being response.
   * Only contains values which are associated with a Defense
   * attached / answered within this DocumentVM
   * {
   *  1234: DocumentQuestionResponse
   * }
   */
  const responsesAssociatedWithDefenses = useMemo(() => {
    if (cachedDefenses.length > 0) {
      return getResponsesAssociatedWithDefenses({
        defenses: cachedDefenses,
        responses: document?.responses || []
      });
    }
    return {};
  }, [cachedDefenses]);

  if (cachedDefenses.length === 0) {
    return null;
  }

  return (
    <S.ViewDefenses inDrawer={inDrawer}>
      <S.Title as="h3">Defenses</S.Title>
      <S.DefenseContent>
        {
          cachedDefenses.map((cd) => (
            <S.DefenseItem key={cd.id}>
              <S.Row>
                <S.Left
                  inDrawer={inDrawer}
                >
                  <S.DefenseItemLabel>Defense</S.DefenseItemLabel>
                </S.Left>
                <S.Right
                  inDrawer={inDrawer}
                >
                  {cd.title && <S.SelectionTitle>{cd.title}</S.SelectionTitle>}
                  <S.DefenseDescription 
                    dangerouslySetInnerHTML={createMarkup(cd.description)}
                    inDrawer={inDrawer}
                  />
                </S.Right>
              </S.Row>

              {cd.questionSelectionIds && cd.questionSelectionIds[0] &&
                <S.Row>
                  <S.Left
                    inDrawer={inDrawer}
                  >
                    <S.DefenseItemLabel>Comment</S.DefenseItemLabel>
                  </S.Left>
                  <S.Right
                    inDrawer={inDrawer}
                  >
                    <S.DefenseContentText
                      inDrawer={inDrawer}
                    >
                      {getComment({
                        responses: responsesAssociatedWithDefenses,
                        id: cd.questionSelectionIds[0]
                      })}
                    </S.DefenseContentText>
                  </S.Right>
                </S.Row>
              }
            </S.DefenseItem>
          ))
        }
      </S.DefenseContent>
    </S.ViewDefenses>
  );
};

export default ViewDefenses;
