import { GET_WORK_LOCATIONS, WorkLocationsResponse } from "./types";
import { CALL_API } from "../../middleware/api";

type ParamTypes = {
  page: number;
  query?: string;
  size?: number;
  sort?: string;
};

// Get work locations
export const getWorkLocations = (args: ParamTypes) => (dispatch, getState): Promise<WorkLocationsResponse> => {
  const authToken = getState().system.authToken;
  let endpoint = `work-locations?page=${args.page}`;

  // Build url params
  const params = Object.keys(args);
  params
    .filter((e) => e !== "page") // Page should always be there
    .forEach((param: string) => {
      if (args[param] !== undefined && args[param] !== -1 && args[param] !== "") {
        // If param has a value, add it to the url
        endpoint = `${endpoint}&${param}=${encodeURIComponent(args[param])}`;
      }
    });

  return dispatch({
    [CALL_API]: {
      endpoint,
      options: {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${authToken}`,
        },
      },
      types: [GET_WORK_LOCATIONS.REQUEST, GET_WORK_LOCATIONS.SUCCESS, GET_WORK_LOCATIONS.FAILURE],
    },
  });
};

// Resets participantsSummary and participantsAggregateSummary
export const resetParticipantsSummary = () => ({
  type: "RESET_WORK_LOCATIONS",
});
