import styled from "styled-components";

import Text from "components/common/Text";
import { remCalc } from "themes/helpers";
import { ThemeTypes } from "themes/colors";

const handleIsActive = ({
  isActive,
  theme
}: {
  isActive?: boolean;
  theme: ThemeTypes;
}) =>
  isActive &&
  `
  background-color: ${theme.colors.activeFill};
`;

const disabledStyles = (
  theme: ThemeTypes,
  disabled?: boolean,
) => {
  if (disabled) {
    return `
      background-color: ${theme.masterColors.lightGrey};
    `;
  }
};

export const WorkOrderListItem = styled.li<{disabled?: boolean}>`
  list-style: none;
  height: 100%;
  width: 100%;

  ${({ disabled, theme }) => disabledStyles(theme, disabled)}
`;

export const Clickable = styled.button<{ isActive?: boolean }>`
  border: none;
  background: none;
  height: 100%;
  width: 100%;

  border: 1px solid ${({ theme }) => theme.masterColors.mediumGrey};
  border-radius: 4px;
  padding: 11px 17px 7px 8px;
  margin: 0px 0px 16px 0px;

  ${({ isActive, theme }) => handleIsActive({ isActive, theme })}

  display: flex;
  justify-content: space-between;

  text-align: left;
`;

const commonTextStyles = `
  margin: 0px 0px 2px 0px;
  padding: 0px;
`;

export const Id = styled(Text)`
  ${commonTextStyles}
  color: ${({ theme }) => theme.masterColors.darkGrey};
  font-size: ${remCalc(14)};

`;
export const Date = styled(Text)`
  ${commonTextStyles}
  color: ${({ theme }) => theme.masterColors.darkGrey};
  font-size: ${remCalc(14)};

`;

export const Location = styled(Text)`
  ${commonTextStyles}
  color: ${({ theme }) => theme.masterColors.darkGrey};
  font-size: ${remCalc(14)};

`;

export const Description = styled(Text)`
  ${commonTextStyles}
  font-size: ${remCalc(16)};
`;

export const Left = styled.div`
  flex: 4;
  height: 100%;
`;
export const Right = styled.div`
  flex: 2;
  height: 100%;
  margin-top: auto;
  display: flex;
  flex-direction: column-reverse;
  text-align: right;
`;
