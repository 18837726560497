import React from "react";

import * as S from "./styles";

type MultiInputItemOptionProps = {
  className?: string;
  label: string;
  subLabel?: string;
  onRemove: () => void;
  onClick?: () => void;
  icon?: string;
  startAdornment?: React.ReactNode;
  disabled?: boolean;
};

const MultiInputItemOption = ({
  icon = "delete_sign",
  className,
  subLabel,
  label,
  onRemove,
  onClick,
  startAdornment,
  disabled,
}: MultiInputItemOptionProps) => {
  return (
    <S.ListItemOption
      className={className}
      renderLabelsAsRow
      label={label}
      onClick={onClick}
      subLabel={subLabel}
      startAdornment={startAdornment}
      endAdornment={{
        hideTextDecoration: true,
        visible: !disabled,
        type: "button",
        onClick: (e) => {
          // LastPass causes the form to reset when clicking this sometimes. These e methods block it from wacking out
          e.preventDefault();
          e.stopPropagation();
          onRemove();
        },
        label: <S.IconForEndAdornment className={`icon icon-icons8-${icon}`} />,
      }}
    />
  );
};

export default MultiInputItemOption;
