import React, { useContext } from "react";
import { ThemeContext } from "styled-components";

import Button, { ButtonProps } from "components/common/Button/Button";
import Icon from "components/common/Icon";
import ReadOnlyContent from "components/common/permissions/ReadOnlyContent";
import { NoResultsContentContainer, EmptyRow, NoResultsTitle, NoResultsBody } from "./styles";
import { TableRow } from "components/common/styled/Table";

type NoResultsProps = {
  body: string;
  button: ButtonProps;
  header: string;
  icon: string;
};

const NoResults = ({ icon, header, body, button }: NoResultsProps) => {
  const theme = useContext(ThemeContext);

  return (
    <TableRow>
      <EmptyRow colSpan={100}>
        <NoResultsContentContainer>
          <Icon type={icon} color={theme.colors.activeFill} size="100px" framed={false} />
          <NoResultsTitle>{header}</NoResultsTitle>
          <ReadOnlyContent>
            <>
              <NoResultsBody>{body}</NoResultsBody>
              <Button {...button} />
            </>
          </ReadOnlyContent>
        </NoResultsContentContainer>
      </EmptyRow>
    </TableRow>
  );
};

export default NoResults;
