import { DefaultGroupConfig } from "store/clientConfigs/types";

export const parseDefaultGroupTerms = (terms: DefaultGroupConfig[]) => {
  if (terms) {
    const result = terms.map((term: DefaultGroupConfig) => {
      return {
        id: term.visibleId,
        label: term.label,
        value: term.val,
      };
    });

    return result;
  }
  return [];
};  
