import { QuestionDTO } from "store/forms/types";

export const GET_QUESTION_BY_ID = {
  REQUEST: "GET_QUESTION_BY_ID_REQUEST",
  SUCCESS: "GET_QUESTION_BY_ID_SUCCESS",
  FAILURE: "GET_QUESTION_BY_ID_FAILURE",
};

export interface questionsState {
  loading: {
    getQuestionById: boolean;
  };
  errors: {
    getQuestionById: boolean;
  };
  data: {
    questionById: QuestionDTO | {};
  };
}

export interface questionsActionTypes {
  type: typeof GET_QUESTION_BY_ID.REQUEST | typeof GET_QUESTION_BY_ID.SUCCESS | typeof GET_QUESTION_BY_ID.FAILURE;
  response?: any;
}
