import React from "react";
import { get } from "lodash";

import { WorkOrderType } from "store/workOrders/types";

import { HandleSetFormValuesArgs } from "../NewForm";

import WorkOrderListItem from "./WorkOrderListItem";

export interface WorkOrderProps extends WorkOrderType {
  iconUrl?: string;
  iconColor?: string;
  isActive?: boolean;
  handleSelectWorkOrder: (value: number) => void;
}

export interface RenderWorkOrderProps {
  useMockAPI?: boolean;
  workOrders?: Array<WorkOrderType>;
  mockWorkOrders?: Array<WorkOrderType>;
  workOrderFilter: string;
  handleSetFormValues: (args: HandleSetFormValuesArgs) => void;
  selectedWorkOrderId: number | null;
  hasSearched?: boolean;
}

interface RenderNoResultsMessageProps {
  hasSearched?: boolean;
  workOrders?: Array<WorkOrderType>;
}
const RenderNoResultsMessage = ({ hasSearched, workOrders = [] }: RenderNoResultsMessageProps) => {
  if (hasSearched && workOrders.length === 0) {
    return (
      <WorkOrderListItem 
        description="No work orders found"
      />
    );
  }
  return null;
};

/**
 * Used to render the "Work Orders" returned from the API
 */
const RenderWorkOrders = ({
  workOrders,
  selectedWorkOrderId,
  handleSetFormValues,
  hasSearched
}: RenderWorkOrderProps) => {
  return (
    <>
      <RenderNoResultsMessage 
        hasSearched={hasSearched}
        workOrders={workOrders}
      />
      {workOrders &&
        workOrders.map((workOrder) => (
          <WorkOrderListItem
            key={workOrder.id}
            {...workOrder}
            location={get(workOrder, "workLocation.name")}
            isActive={selectedWorkOrderId === workOrder.id}
            handleSelectWorkOrder={(value) =>
              handleSetFormValues({
                valueType: "selectedWorkOrderId",
                value
              })
            }
          />
        ))}
    </>
  );
};

RenderWorkOrders.defaultProps = {};

export default RenderWorkOrders;
