import moment from "moment";
import { ResourceDTO, Resource, ResourceValues } from "store/resources/types";
import { SortParams } from "../../../store/common/types";

/**
 * Retrieve the sorting direction from a supplied comma-delimited string or tuple
 * 
 * Changed logic to check if `id` exists in sort before returning direction, otherwise
 * a default `asc` direction is returned. Mirrors sort behavior of MUI -- GK
 * 
 * @param id
 * @param sort
 */
export const getSortDirection = (id: string, sort?: string | SortParams) => {
  const isActiveSort: boolean = [id].some((el) => sort?.includes(el));
  if (isActiveSort) {
    return sort && sort.includes("asc") ? "asc" : "desc";
  }
  return "asc";
};

/**
 * Map the resourceType enum to the displayable string
 * @param resourceType - the type of the returned resource
 */
type ResourceTerms = {
  operationalExperience: string;
  defense: string;
}
export const getResourceTypeString = (resourceType: string, terms?: ResourceTerms) => {
  switch (resourceType) {
    case "OPERATIONAL_EXPERIENCE":
      return terms?.operationalExperience || "OE";
    case "DEFENSE":
      return terms?.defense || "Defense";
    default:
      return "NA";
  }
};

/**
 * Get the title field key based on the resource type
 * @param resource resource for which to get the title key
 */
export const getResourceTitleKey = (resource: ResourceDTO | Resource) => {
  switch (resource.resourceType) {
    case "OPERATIONAL_EXPERIENCE":
      return "incident";
    case "DEFENSE":
      return "title";
    default:
      return "title";
  }
};

/**
 * Build a date by subtracting the supplied days from today's date. Returns the start of the day.
 * Passing -1 will return -1.
 *
 * @param days Days before
 */
export const getBeforeDateFilter = (days: number) => {
  switch (days) {
    case -1:
      return -1;
    case 2: // "YESTERDAY" (before 12:00am on this date)
      return moment()
        .startOf("day")
        .utc()
        .format();
    default:
      // all other options will get results up to the end of today
      return moment()
        .endOf("day")
        .utc()
        .format();
  }
};

/**
 * Build a date by adding the supplied days (minus 1 to account for today) from today's date. Returns the start of the day.
 * Passing -1 will return -1.
 *
 * @param days Days after
 */
export const getAfterDateFilter = (days: number) => {
  switch (days) {
    case -1:
      return -1;
    case 2: // "YESTERDAY" (after 12:00am on yesterday's date)
      return moment()
        .subtract(1, "days")
        .startOf("day")
        .utc()
        .format();
    default:
      // all other dates will get results from the beginning of the day, x days ago
      // -1 is to account for including "today"
      return moment()
        .subtract(days - 1, "days")
        .startOf("day")
        .utc()
        .format();
  }
};

/** Get the display string for the resource status */
export const getResourceStatusString = (status: string) => {
  switch (status) {
    case "ACTIVE":
      return "Active";
    case "ARCHIVED":
      return "Archived";
    case "DRAFT":
      return "Draft";
    default:
      return "";
  }
};

/**
 * Build a full name string for a resource author
 * @param resource the resource values to use
 * @param type the type of author values to use, lastModified or createdBy
 */
export const buildResourceAuthorName = (resource: Resource, type: "lastModified" | "createdBy") => {
  const firstName = resource[`${type}FirstName`];
  const lastName = resource[`${type}LastName`];
  const nickname = resource[`${type}Nickname`];
  return `${firstName}${nickname ? ` "${nickname}"` : ""} ${lastName}`;
};

export const mapResourceValues = (values: ResourceValues) => {
  switch (values.resourceType) {
    case "OPERATIONAL_EXPERIENCE":
      return {
        description: values.description,
        incident: values.title,
        status: values.status,
        tags: values.tags,
        id: values.id,
      };
    case "DEFENSE":
      return {
        defenseDocuments: values.defenseDocuments,
        status: values.status,
        title: values.title,
        description: values.description,
        id: values.id,
      };
    default:
      return;
  }
};

export const mapInitialResourceValues = (initialValues?: ResourceDTO): ResourceValues => {
  if (initialValues) {
    if (initialValues.resourceType === "OPERATIONAL_EXPERIENCE") {
      return {
        categories: initialValues.categories || [],
        clientGroups: initialValues.clientGroups,
        description: initialValues.description,
        title: initialValues.incident,
        status: initialValues.status,
        tags: initialValues.tags,
        resourceType: initialValues.resourceType,
        id: initialValues.id,
        notes: initialValues.notes || null,
      };
    } else if (initialValues.resourceType === "DEFENSE") {
      return {
        ...initialValues,
        defenseDocuments: initialValues.defenseDocuments || [],
        title: initialValues.title || "",
        status: "ACTIVE",
      };
    }
  }
  return {
    categories: [],
    description: "",
    title: "",
    tags: [],
    defenseDocuments: [],
    resourceType: -1,
    status: "ACTIVE",
  };
};

export const truncateTags = (tag: string) => {
  return tag.length > 48 ? `${tag.slice(0,45).trim()}...` : tag;
};
