import React, { useCallback } from "react";
import KeyboardArrowUp from "@material-ui/icons/KeyboardArrowUp";

import * as S from "./styles";


const BackToTop = () => {
  const wrapperDiv = document.getElementById("mainContent");

  /**
   * Within the rendered view, you'll need an element
   * with the ID of 'mainContent' in order for this to work.
   */
  const onClick = useCallback(() => {
    if (wrapperDiv) {
      return wrapperDiv.scrollIntoView({
        behavior: "smooth"
      });
    }
  }, [wrapperDiv]);

  return (
    <S.Wrapper onClick={onClick}>
      <KeyboardArrowUp />
      <div>Back to top</div>
    </S.Wrapper>
  );
};

export default BackToTop;
