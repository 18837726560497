import { CALL_API } from "../../middleware/api";

import { GET_ALL_FORMS, GET_FORM, SEARCH_FORMS, GET_ALL_FORM_TYPES } from "./types";
import { FilterParams } from "../common/types";
import { buildFilteredEndpoint } from "../common/apiUtilities";

/**
 * Get all forms, filtered by an optional list of client group IDs
 * @param clientGroupIds
 */
export const getAllForms = (clientGroupIds: Array<number> = []) => (dispatch, getState) => {
  const authToken = getState().system.authToken;
  let endpoint = "forms/all";
  if (clientGroupIds.length > 0) {
    endpoint += "?clientGroupIds=";
    for (let i = 0, len = clientGroupIds.length; i < len; i++) {
      endpoint = `${endpoint}${i === 0 ? "" : ","}${clientGroupIds[i]}`;
    }
  }
  return dispatch({
    [CALL_API]: {
      endpoint,
      options: {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${authToken}`,
        },
      },
      types: [GET_ALL_FORMS.REQUEST, GET_ALL_FORMS.SUCCESS, GET_ALL_FORMS.FAILURE],
    },
  });
};

/**
 * Get all form types
 */
export const getAllFormTypes = (params?: FilterParams) => (dispatch, getState) => {
  const authToken = getState().system.authToken;
  
  return dispatch({
    [CALL_API]: {
      endpoint: buildFilteredEndpoint("form-types/all", params),
      options: {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${authToken}`,
        },
      },
      types: [GET_ALL_FORM_TYPES.REQUEST, GET_ALL_FORM_TYPES.SUCCESS, GET_ALL_FORM_TYPES.FAILURE],
    },
  });
};

// Get single form
export const getForm = (formId) => (dispatch, getState) => {
  const authToken = getState().system.authToken;
  return dispatch({
    [CALL_API]: {
      endpoint: `forms/${formId}`,
      options: {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${authToken}`,
        },
      },
      types: [GET_FORM.REQUEST, GET_FORM.SUCCESS, GET_FORM.FAILURE],
    },
  });
};

// Search Forms
export const searchForms = (params: FilterParams) => (dispatch, getState) => {
  const authToken = getState().system.authToken;
  return dispatch({
    loaderKey: "searchForms",
    [CALL_API]: {
      endpoint: buildFilteredEndpoint("forms", params),
      options: {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${authToken}`,
        },
      },
      types: [SEARCH_FORMS.REQUEST, SEARCH_FORMS.SUCCESS, SEARCH_FORMS.FAILURE],
    },
  });
};
export type SearchForms = typeof searchForms;
