import * as R from "ramda";
import React, { useContext } from "react";
import styled from "styled-components";

import { dateToString, prettifyWorkorderID } from "components/helpers";
import { DocumentSummary } from "store/documents/types";

import { ListProps } from "../List";
import * as S from "../styles";
import { ThemeContext } from "styled-components";
import useGroupTerm from "util/hooks/whiteLabel/useGroupTerm";

const OwnerInitialsWrapper = styled.span`
  width: 230px !important;
  display: inline-block;
  // Ellipsis
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export interface DocumentsListProps extends ListProps {
  /**
   * search results from documents endpoint
   */
  searchDocuments: Array<DocumentSummary>;
  handleOnClick: (documentId: number) => void;
  openContextMenu: (document: any) => void;
  toggleDashboardDrawerVisibility: () => void;
}

type StartNewDocButton = {
  iconName: string;
  avatarColor: string;
  label: string;
  startNewDocButton: boolean;
};

const DocumentsList = ({
  handleOnClick,
  openContextMenu,
  searchDocuments,
  toggleDashboardDrawerVisibility,
}: DocumentsListProps) => {
  const theme = useContext(ThemeContext);

  // Group config terms
  const documentTerm = useGroupTerm("document", "noun", undefined, "Document");
  const rehuddleTerm = useGroupTerm("rehuddle", "noun", undefined, "Rehuddle");
  const rehuddleTermUpper = rehuddleTerm.toUpperCase();

  // add extra button to create new doc
  const withCreateNewDocButton: Array<DocumentSummary | StartNewDocButton> = [
    {
      iconName: "icon-icons8-add_file",
      avatarColor: "primary",
      label: `Create New ${documentTerm}`,
      startNewDocButton: true,
    },
    ...searchDocuments,
  ];

  // used to determine which type we're rendering
  // in order to return the right elements/styles
  function isCreateNewDocButton(
    item: DocumentSummary | StartNewDocButton
  ): item is StartNewDocButton {
    return (item as StartNewDocButton).startNewDocButton === true;
  }

  return (
    <S.DocumentList>
      {withCreateNewDocButton.map((document) => {
        const iconName = R.pathOr(
          "null",
          ["formSummary", "type", "iconName"],
          document
        );
        const iconColor = R.pathOr(
          theme.masterColors.darkGrey,
          ["formSummary", "type", "iconColor"],
          document
        );
        const workOrderIdLabel = prettifyWorkorderID(
          R.pathOr("N/A", ["workOrder", "workOrderId"], document)
        );
        const ownerFirstName = R.pathOr(null, ["owner", "firstName"], document);
        const ownerLastName = R.pathOr(null, ["owner", "lastName"], document);

        // Document ID
        const documentId = `ID: ${workOrderIdLabel}`;

        // Initials of document owner
        const ownerInitials =
          (ownerFirstName &&
            ownerLastName &&
            `${ownerFirstName[0]}. ${ownerLastName}`) ||
          undefined;

        if (isCreateNewDocButton(document)) {
          return (
            <S.DocumentListItem
              key={document.label}
              onClick={toggleDashboardDrawerVisibility}
            >
              <S.Left variant="AVATAR">
                <S.NewDocumentIcon className="icon-icons8-add_file" />
              </S.Left>
              <S.Middle>
                <S.PrimaryText>{document.label}</S.PrimaryText>
              </S.Middle>
            </S.DocumentListItem>
          );
        }

        return (
          <S.DocumentListItem
            key={document.id}
            onClick={() => handleOnClick(document.id)}
          >
            <S.Left variant="AVATAR">
              <S.Avatar>
                <S.DocumentIcon className={iconName} iconColor={iconColor} />
              </S.Avatar>
            </S.Left>
            <S.Middle>
              <S.PrimaryText>{`${
                document.parentId && document.isRehuddle
                  ? `[${rehuddleTermUpper}]`
                  : ""
              } ${document.title}`}</S.PrimaryText>
              <S.SecondaryTextWrapper>
                <S.SecondaryText>
                  <OwnerInitialsWrapper>{ownerInitials}</OwnerInitialsWrapper>
                  <span>{documentId}</span>
                </S.SecondaryText>
                {document.submissionDate && (
                  <S.SecondaryText>
                    {dateToString(document.submissionDate)}
                  </S.SecondaryText>
                )}
              </S.SecondaryTextWrapper>
            </S.Middle>
            <S.Right variant="MORE-ICON">
              <S.IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  openContextMenu(document);
                }}
              >
                <S.MoreVert />
              </S.IconButton>
            </S.Right>
          </S.DocumentListItem>
        );
      })}
    </S.DocumentList>
  );
};

DocumentsList.defaultProps = {
  bottomBorder: true,
  searchDocuments: [],
};

export default DocumentsList;
