import React from "react";
// import { withStyles } from '@material-ui/core/styles';
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";

export type ListSimpleItem = {
  primary: any;
  rightSideItem?: any;
  onClick?: () => void;
  disabled?: boolean;
};

interface Props {
  bottomDivider?: boolean;
  listItems: any[];
  topDivider?: boolean;
}

const ListSimple = (props: Props) => {
  // const { classes } = props;
  return (
    <List component="nav">
      {props.topDivider && <Divider />}
      {props.listItems &&
          props.listItems.map((e: ListSimpleItem, i: number) => {
            return (
              <ListItem button key={i} onClick={e.onClick} disabled={e.disabled}>
                <ListItemText primary={e.primary} />
                <ListItemText primary={e.rightSideItem} className="d-flex flex-row justify-content-end mr-0" />
              </ListItem>
            );
          })}
      {props.bottomDivider && <Divider />}
    </List>
  );
};

export default ListSimple;
