import { CALL_API } from "middleware/api";
import { buildFilteredEndpoint } from "store/common/apiUtilities";

import {
  ADD_CLIENT_GROUP,
  GET_ALL_DEFAULT_TERM_DEFINITIONS,
  GET_ALL_GROUP_STATS,
  GET_CLIENT_GROUP,
  GET_CLIENT_GROUPS,
  GetClientGroupsResponse,
} from "./types";
import { FilterParams } from "store/common/types";

export const getClientGroups = (params?: FilterParams) => (dispatch, getState): Promise<GetClientGroupsResponse> => {
  const { system } = getState();

  const endpoint = buildFilteredEndpoint("client-groups", params);

  return dispatch({
    [CALL_API]: {
      endpoint,
      options: {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${system.authToken}`,
        },
      },
      types: [GET_CLIENT_GROUPS.REQUEST, GET_CLIENT_GROUPS.SUCCESS, GET_CLIENT_GROUPS.FAILURE],
    },
  });
};

// Add group
export const addClientGroup = (body) => (dispatch, getState) => {
  const { system } = getState();

  const method = body && body.id ? "PUT" : "POST";

  return dispatch({
    [CALL_API]: {
      endpoint: "client-groups",
      options: {
        method,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${system.authToken}`,
        },
        body: JSON.stringify(body),
      },
      types: [ADD_CLIENT_GROUP.REQUEST, ADD_CLIENT_GROUP.SUCCESS, ADD_CLIENT_GROUP.FAILURE],
    },
  });
};

// Get client group
export const getClientGroup = (id) => (dispatch, getState) => {
  const { system } = getState();

  return dispatch({
    [CALL_API]: {
      endpoint: `client-groups/${id}`,
      options: {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${system.authToken}`,
        },
      },
      types: [GET_CLIENT_GROUP.REQUEST, GET_CLIENT_GROUP.SUCCESS, GET_CLIENT_GROUP.FAILURE],
    },
  });
};

// Get all group stats
export const getAllGroupStats = (params) => (dispatch, getState) => {
  const { system } = getState();

  const endpoint = buildFilteredEndpoint("client-groups/all-stats", params);

  return dispatch({
    [CALL_API]: {
      endpoint,
      options: {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${system.authToken}`,
        },
      },
      types: [GET_ALL_GROUP_STATS.REQUEST, GET_ALL_GROUP_STATS.SUCCESS, GET_ALL_GROUP_STATS.FAILURE],
    },
  });
};

// Get client group
export const getAllDefaultTermDefinitions = () => (dispatch, getState) => {
  const { system } = getState();

  return dispatch({
    [CALL_API]: {
      endpoint: "default-term-definitions/all",
      options: {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${system.authToken}`,
        },
      },
      types: [
        GET_ALL_DEFAULT_TERM_DEFINITIONS.REQUEST,
        GET_ALL_DEFAULT_TERM_DEFINITIONS.SUCCESS,
        GET_ALL_DEFAULT_TERM_DEFINITIONS.FAILURE,
      ],
    },
  });
};
