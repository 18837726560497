import React, { useState } from "react";
import { SearchBar } from "../common/SearchBar";

/**
 * Base component for search bar filters. Handles blur/focus/change/reset functions.
 *
 * TODO Should this be folded into SearchBar?
 *
 * @param autoFocus - should the search bar autofocus? default true
 * @param onChange - called when the query changes. Passes new query.
 * @param onSearch - called when a search is performed. Passes query. Should dispatch an action to perform a search.
 * @param initialQuery
 * @param placeholder
 */
const BaseSearchBar = ({
  autoFocus = true,
  onChange,
  onSearch,
  initialQuery = "",
  placeholder,
  ...props
}: {
  autoFocus?: boolean;
  onChange?: (value: string) => void;
  onSearch: (value: string) => void;
  initialQuery?: string;
  placeholder?: string;
}) => {
  const [searchFocus, setSearchFocus] = useState(false);
  const [query, setQuery] = useState(initialQuery);

  const search = (value, force = false) => {
    if (onChange) onChange(value);
    setQuery(value);
    if (
      force ||
      value.length === 0 || // backspaced through search
      (value && value.length > 2)
    ) {
      onSearch(value);
    }
  };

  const handleChange = (e: React.FormEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;

    search(value);
  };

  const handleBlur = () => {
    setSearchFocus(false);
  };

  const handleFocus = () => {
    setSearchFocus(true);
  };

  const resetSearch = () => {
    search("", true);
  };

  return (
    <SearchBar
      autoFocus={autoFocus}
      disableUnderline={!searchFocus}
      handleBlur={handleBlur}
      handleChange={handleChange}
      handleFocus={handleFocus}
      hideFilters
      name=""
      placeholder={placeholder || "Search"}
      resetSearch={resetSearch}
      value={query}
      {...props}
    />
  );
};

export default BaseSearchBar;
