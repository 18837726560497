import React from "react";
import styled from "styled-components";

import { ReactComponent as ErrorIcon } from "assets/svg/error.svg";
import { remCalc } from "themes/helpers";

const ErrorSpan = styled.span`
  display: block;
  color: ${({ theme }) => theme.colors.error};
  font-size: ${remCalc(16)};
  margin: 0 ${remCalc(5)} 0 ${remCalc(16)};
  font-family: "Roboto";
`;

const ErrorIconContainer = styled.span`
  display: inline-block;
  height: ${remCalc(16)};
  padding-right: ${remCalc(8)};
  vertical-align: baseline;
  width: ${remCalc(16)};
  && .MuiSvgIcon-root {
    height: 100%;
    width: 100%;
  }
`;

const StyledErrorIcon = styled(ErrorIcon)`
  .error-fill {
    ${({ theme }) => {
      return `
        fill: ${theme.colors.error};
        stroke: ${theme.colors.error};
      `;
    }}
  }
`;

export const ErrorText: React.FC = ({ children }) => {
  return (
    <ErrorSpan>
      {/*
        Removed for UAT push on 5/20/2020 due to looking inconsistent with other
        error messages. Replace after the push. —Andrew Dawson

        <ErrorIconContainer>
          <StyledErrorIcon fillClassName="error-fill" />
        </ErrorIconContainer>
      */}
      {children}
    </ErrorSpan>
  );
};
