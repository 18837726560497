import { PaginationParams } from "store/common/types";
import {
  DataSetsFiltersParams,
  DATA_SETS_FILTERS,
  SetDataSetsFiltersAction,
  SetDataSetsPaginationAction,
} from "./types";

export const setDataSetsFilters = (
  filters: DataSetsFiltersParams
): SetDataSetsFiltersAction => ({
  response: filters,
  type: DATA_SETS_FILTERS.SET,
});

export const setDataSetsPagination = (
  pagination: PaginationParams
): SetDataSetsPaginationAction => ({
  response: pagination,
  type: DATA_SETS_FILTERS.PARAMS_SET,
});
