// Describing the shape of the system's slice of state

export interface SystemState {
  accountLocked: boolean;
  authToken: string | null;
  decodedAuthToken: any; // @TODO update with type when finalized
  error: string | null;
  isLoading: boolean;
  loggedIn: boolean;
  recaptchaNeeded: boolean;
  resetPassword?: boolean;
  resetRequestStatus: "success" | "failure" | "request" | null;
  resetStatus: "success" | "failure" | "request" | null;
  session: string;
  userName: string;
}

// Describing the different ACTION NAMES available
export const CLEAR_ERROR = "CLEAR_ERROR";
export const CLEAR_TOKEN = "CLEAR_TOKEN";
export const CLEAR_STATUS = "CLEAR_STATUS";
export const LOGIN = {
  FAILURE: "LOGIN_FAILURE",
  REQUEST: "LOGIN_REQUEST",
  SUCCESS: "LOGIN_SUCCESS",
};
export const PASSWORD_RESET = {
  FAILURE: "PASSWORD_RESET_FAILURE",
  REQUEST: "PASSWORD_RESET_REQUEST",
  SUCCESS: "PASSWORD_RESET_SUCCESS",
};
export const REQUEST_RESET = {
  FAILURE: "REQUEST_RESET_FAILURE",
  REQUEST: "REQUEST_RESET_REQUEST",
  SUCCESS: "REQUEST_RESET_SUCCESS",
};

// Used for validation password reset key (if expired, etc)
export const VALIDATE_PASSWORD_RESET_KEY = {
  FAILURE: "VALIDATE_PASSWORD_RESET_KEY_FAILURE",
  REQUEST: "VALIDATE_PASSWORD_RESET_KEY_REQUEST",
  SUCCESS: "VALIDATE_PASSWORD_RESET_KEY_SUCCESS",
};

// Used for user activation (initial password)
export const ACTIVATE_USER = {
  FAILURE: "ACTIVATE_USER_FAILURE",
  REQUEST: "ACTIVATE_USER_REQUEST",
  SUCCESS: "ACTIVATE_USER_SUCCESS",
};

// Used to validate user activation key
export const VALIDATE_USER_KEY = {
  FAILURE: "VALIDATE_USER_KEY_FAILURE",
  REQUEST: "VALIDATE_USER_KEY_REQUEST",
  SUCCESS: "VALIDATE_USER_KEY_SUCCESS",
};

export type ActivateUserAction = {
  type: typeof ACTIVATE_USER.REQUEST | typeof ACTIVATE_USER.SUCCESS | typeof ACTIVATE_USER.FAILURE;
}

export type ValidateUserKeyAction = {
  response: {
    email: string;
  };
  type: typeof VALIDATE_USER_KEY.REQUEST | typeof VALIDATE_USER_KEY.SUCCESS | typeof VALIDATE_USER_KEY.FAILURE;
}

export const UPDATE_SESSION = "UPDATE_SESSION";

interface UpdateSessionAction {
  type: typeof UPDATE_SESSION;
  payload: SystemState;
}

interface LoginSessionAction {
  response: {
    id_token: string;
    resetPassword?: boolean;
  };
  error: string;
  fullError: {
    accountLocked: boolean;
    message: string;
    recaptchaNeeded: boolean;
    resetPassword?: boolean;
    status: number;
  };
  status: number;
  type: typeof LOGIN.REQUEST | typeof LOGIN.SUCCESS | typeof LOGIN.FAILURE;
}

interface ClearErrorAction {
  type: typeof CLEAR_ERROR;
}

interface ClearTokenAction {
  type: typeof CLEAR_TOKEN;
}

interface ClearStatus {
  type: typeof CLEAR_STATUS;
}

type OAuth = {
  type: string;
  authToken: string;
}

export type SystemActionTypes = UpdateSessionAction &
  LoginSessionAction &
  ClearErrorAction &
  ClearTokenAction &
  ClearStatus & 
  OAuth;
