export const allRoles = [
  "ROLE_CLIENT_ADMIN",
  "ROLE_CLIENT_REVIEWER",
  "ROLE_CONTENT_EDITOR",
  "ROLE_FORM_ARCHITECT",
  "ROLE_GROUP_MANAGER",
  "ROLE_SUPER_ADMIN",
  "ROLE_USER",
];

export const peopleRoles = [
  "ROLE_CLIENT_ADMIN",
  "ROLE_CLIENT_REVIEWER",
  "ROLE_GROUP_MANAGER",
];

export const formsRoles = [
  "ROLE_CLIENT_ADMIN",
  "ROLE_CLIENT_REVIEWER",
  "ROLE_FORM_ARCHITECT",
];

export const contentRoles = [
  "ROLE_CLIENT_ADMIN",
  "ROLE_CLIENT_REVIEWER",
  "ROLE_CONTENT_EDITOR",
  "ROLE_FORM_ARCHITECT",
];

export const feedbackRoles = [
  "ROLE_CLIENT_ADMIN",
  "ROLE_CLIENT_REVIEWER",
];
