import {
  AppConfigsState,
  GET_APP_CONFIG,
  GET_PRESIGNED_APP_CONFIG,
  AppConfigsActionTypes,
  GET_IMAGE_FROM_PRESIGNED_APP_CONFIG
} from "./types";

import moment from "moment";

const initialState: AppConfigsState = {
  data: {
    clientConfig: {
      properties: {
        clientName: "",
        adfsEnabled: false,
      }
    },
    clientOverrides: undefined,
    logoConfigs: {
      properties: {
        logo2x: {
          signedUrlTimestamp: "",
          privateUrl:
            "https://s3.console.aws.amazon.com/powerfields-app-dev-config/b1e1ff3c-7f8b-4da5-9f81-23ebad814290",
          writableUrl: "",
          readableUrl: ""
        },
        logo2x2: {
          signedUrlTimestamp: "",
          privateUrl:
            "https://s3.console.aws.amazon.com/powerfields-app-dev-config/ee0ef3a1-cde4-40be-9172-d67ad53caed3",
          writableUrl: "",
          readableUrl: ""
        },
        logo2x3: {
          signedUrlTimestamp: "",
          privateUrl:
            "https://s3.console.aws.amazon.com/powerfields-app-dev-config/754c93e8-4e59-46cf-bba0-718d90b888e8",
          writableUrl: "",
          readableUrl: ""
        }
      }
    },
    // Main Navigation
    mainNavigation: {
      properties: {
        activeColor: "#0172CE",
        copy: {
          documents: "Documents3",
          menu: "Menu",
          profile: "Profile",
          reports: "Reports"
        }
      }
    },
    // Dashboard Sidebar
    dashboardSidebar: {
      properties: {
        copy: {
          refocus: "Refocus / Rehuddle",
          form: "Form"
        }
      }
    }
  }
};

export function appConfigsReducer(state = initialState, action: AppConfigsActionTypes): AppConfigsState {
  switch (action.type) {
    // This sets privateUrl props
    case GET_APP_CONFIG.SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          [action.keyName]: action.response
        }
      };
    case GET_PRESIGNED_APP_CONFIG.SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          [action.keyName]: {
            ...state.data[action.keyName],
            properties: {
              ...state.data[action.keyName].properties,
              [action.property]: {
                ...state.data[action.keyName].properties[action.property],
                readableUrl: action.response.readableUrl,
                signedUrlTimestamp: moment().toISOString()
              }
            }
          }
        }
      };
    case GET_IMAGE_FROM_PRESIGNED_APP_CONFIG.SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          [action.keyName]: {
            ...state.data[action.keyName],
            properties: {
              ...state.data[action.keyName].properties,
              [action.property]: {
                ...state.data[action.keyName].properties[action.property],
                image: action.response
              }
            }
          }
        }
      };
    default:
      return state;
  }
}
