import React, { ChangeEvent, FC } from "react";
import styled from "styled-components";

import {
  getSubOrdinatesOptions,
  timeFilterOptions,
} from "../../../helpers/filters";
import {
  FilterSelect,
  FilterSelectMulti,
} from "components/common/FilterSelect";
import SearchBar from "components/filters/BaseSearchBar";
import { DateRange } from "components/common/DateRange";
import { FiltersProps } from "./Filters.types";
import { useSelector } from "react-redux";
import { participantTreeOptionsSelector } from "store/participantTreeFilter/selectors";

const DateRangeWrapper = styled.div`
  margin-bottom: 24px;
`;

const Filters: FC<FiltersProps> = ({
  dateRange,
  groupFilter: { groupId, setGroupId, groupFilterOptions },
  handleChangeDateRange,
  ratingsFilter: { ratingsFilter, setRatingsFilter, ratingOptions },
  subordinatesFilter: { subordinatesFilter, setSubordinatesFilter },
  safetyFlag,
  setQuery,
  timeFilter: { timeFilterType, setTimeFilterType },
}) => {
  const participantTreeOptions = useSelector(participantTreeOptionsSelector);
  const subordinatesOptions = getSubOrdinatesOptions(participantTreeOptions);
  const onClear = () => {
    setTimeFilterType("ALL_TIME");
    handleChangeDateRange(null);
  };

  const handleChangeSubordinatesFilter = (
    e: ChangeEvent<HTMLSelectElement>
  ) => {
    setSubordinatesFilter(e.target.value);
  };

  return (
    <>
      <div className="d-flex align-items-center mb-2 mt-2">
        <div className="mr-4">
          <FilterSelect
            name="timeFilter"
            value={timeFilterType}
            label=""
            options={timeFilterOptions}
            handleChange={({ target: { value } }) => {
              setTimeFilterType(value);
            }}
          />
        </div>
        <div className="mr-3">
          {/* TODO real group filter */}
          <FilterSelect
            name="groups"
            value={groupId}
            label=""
            options={groupFilterOptions}
            handleChange={(e) => setGroupId(e.target.value)}
          />
        </div>
        {safetyFlag && (
          <div className="mr-3">
            <FilterSelectMulti
              name="rating"
              value={ratingsFilter}
              label=""
              options={ratingOptions}
              handleChange={(e) => {
                setRatingsFilter(e.target.value);
              }}
            />
          </div>
        )}
        {!participantTreeOptions.hideFilter && (
          <div className="mr-3">
            <FilterSelect
              name="subordinatesFilter"
              value={subordinatesFilter}
              label=""
              options={subordinatesOptions}
              handleChange={handleChangeSubordinatesFilter}
            />
          </div>
        )}
        <SearchBar onSearch={setQuery} />
      </div>
      {timeFilterType === "CUSTOM_RANGE" && (
        <DateRangeWrapper>
          <DateRange
            handleChange={handleChangeDateRange}
            labelId="customRangeId"
            name="customRange"
            initialValues={{
              from: dateRange?.startDate,
              to: dateRange?.endDate,
            }}
            onClear={onClear}
          />
        </DateRangeWrapper>
      )}
    </>
  );
};

export default Filters;
