import { get } from "lodash";

import { CALL_API } from "../../middleware/api";
import { GET_PARTICIPANT_CONFIG, POST_PARTICIPANT_CONFIG } from "./types";

// Get Participant config
export const getParticipantConfig = (keyName: string) => (dispatch, getState) => {
  const authToken = getState().system.authToken;
  const participantId = get(getState().user, ["data", "participant", "id"], null);

  if (participantId) {
    return dispatch({
      keyName: keyName,
      [CALL_API]: {
        endpoint: `participants/${participantId}/configs/${keyName}`,
        options: {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        },
        types: [GET_PARTICIPANT_CONFIG.REQUEST, GET_PARTICIPANT_CONFIG.SUCCESS, GET_PARTICIPANT_CONFIG.FAILURE],
      },
    });
  }
};

// Set Participant config
export const setParticipantConfig = (
  active: boolean,
  keyName: string,
  properties: { [k: string]: boolean }
) => (dispatch, getState) => {
  const authToken = getState().system.authToken;
  const participantId = get(getState().user, ["data", "participant", "id"], null);

  const body = {
    active,
    keyName,
    participantId,
    properties,
  };
  return dispatch({
    active,
    keyName: keyName,
    participantId,
    properties,
    [CALL_API]: {
      endpoint: "participant-configs",
      options: {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${authToken}`,
        },
        body: JSON.stringify(body),
      },
      types: [POST_PARTICIPANT_CONFIG.REQUEST, POST_PARTICIPANT_CONFIG.SUCCESS, POST_PARTICIPANT_CONFIG.FAILURE],
    },
  });
};

// Temp show tutorial
export const showTempTutorial = () => ({
  type: "SHOW_TEMP_TUTORIAL",
});
export const hideTempTutorial = () => ({
  type: "HIDE_TEMP_TUTORIAL",
});
