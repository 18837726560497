import * as R from "ramda";
import React, { useEffect, useState } from "react";
import { Dispatch, bindActionCreators } from "redux";
import { connect } from "react-redux";

import { 
  clearSignatureData, 
  getSignatureUrlForParticipant, 
  storeSignatureData 
} from "store/newDocument/actions";
import { Label } from "components/common/form/Label/futureUiKit";
import { AppState } from "store";
import { NewDocumentState } from "store/newDocument/types";

import * as S from "./styles";
import SignaturePad from "./SignaturePad";
import { TextInput } from "../TextInput";
import { SignatureType } from "components/clientAdmin/formBuilder/types";
import { allowsTypedSignatures } from "util/signatures";
import { areParticipantsEqual } from "components/FormController/helpers";
import { DocumentParticipant } from "store/documents/types";

/**
 * Used with helper functions related to the "typed"
 * work flow.
 * When we are rendering a pre-filled "typed" signature,
 * methods which use this interface are used to compute
 * values for assistive links, helper text, ect
 */
interface AssistiveLinkComputeArgs {
  loading: boolean;
  alreadySubmitted?: boolean;
}

type Props = {
  validationError?: string | undefined;
  label: string;
  participant: DocumentParticipant;
  placeholder?: string;
  signatureUrl?: string | null;
  typedSignature?: string;
  handleOnChangeTypedSignature: (
    value: string,
    participant: DocumentParticipant
  ) => void;
  attachURL: (participant: DocumentParticipant) => void;
  clearSignature: (participant: DocumentParticipant) => void;
  // redux
  store: {
    newDocument: NewDocumentState;
  };

  actions: {
    newDocument: {
      getSignatureUrlForParticipant: typeof getSignatureUrlForParticipant;
      storeSignatureData: typeof storeSignatureData;
      clearSignatureData: typeof clearSignatureData;
    };
  };
  allowedTypes: Array<SignatureType>;
};

/**
 * renders 1 of 2 versions based on whether the user is typing in
 * their signature or "drawing" it on the screen.
 * During "edit" mode, whiles the user is "drawing" their signature,
 * we'll use __SignaturePad.tsx__ to handle those responsibilities.
 */
const Signature = ({
  actions,
  attachURL,
  clearSignature,
  handleOnChangeTypedSignature,
  validationError,
  typedSignature,
  label,
  participant,
  placeholder,
  signatureUrl,
  store,
  allowedTypes,
}: Props) => {
  // STATE
  const [editMode, setEditMode] = useState(true);
  const [refreshSignaturePad, _refreshEditMode] = useState(false);
  const [refreshedSignaturePad, _refreshedEditMode] = useState(false);
  const [submitted, toggleSubmitted] = useState(!!signatureUrl);
  const [loading, setLoading] = useState(false);
  const [confirmedSignature, toggleConfirmedSignature] = React.useState(false);
  // used when another sig field updates the img
  const [sigImgFromFocus, setSigImgFromFocus] = useState<any>(null);
  const [touchedField, setTouchedField] = React.useState(false);

  const {
    newDocument: { documentParticipantSignatureUrls, storedSignatureImages },
  } = store;

  const urlsForThisParticipant = documentParticipantSignatureUrls.find(
    (urls) => areParticipantsEqual(urls.participant, participant)
  );

  const storedSignatureImageForThisParticipant = storedSignatureImages.find(
    (_storedImage) => areParticipantsEqual(_storedImage, participant)
  );

  /**
   * Handle when the user adds and confirms
   * signature drawing locally
   */
  useEffect(() => {
    /**
     * This should fire after the user has clicked
     * to add a signature and is entering the edit mode
     */
    if (loading && urlsForThisParticipant) {
      setLoading(false);
      setEditMode(true);
    }
  }, [urlsForThisParticipant, loading]);

  /**
   * update component if another signature field has updated
   * or added an image for this signature's participant
   */
  useEffect(() => {
    if (signatureUrl !== sigImgFromFocus && !editMode) {
      setSigImgFromFocus(signatureUrl);
      setEditMode(false);
    }
  }, [signatureUrl, editMode, submitted, sigImgFromFocus]);

  /**
   * Refresh the signature pad, while on the signature pad
   * i.e. If a user starts to sign, then wants to clear that signature, the following happens:
   * This is a two step process:
   * 1. Use state with no signature editing data. Set toggle mode to false
   * 2. Use state with toggle mode set to true
   *
   * Part 1: Use state with no signature editing data.
   */
  useEffect(() => {
    /**
     * This should fire after the user has clicked
     * to add a signature and is entering the edit mode
     */
    if (refreshSignaturePad === true) {
      setEditMode(false);
      _refreshEditMode(false);

      _refreshedEditMode(true);
    }
  }, [editMode, refreshSignaturePad, refreshedSignaturePad]);

  /**
   * Part 2: Use state with toggle edit mode set to true
   */
  useEffect(() => {
    if (refreshedSignaturePad === true) {
      setEditMode(true);

      _refreshEditMode(false);
      _refreshedEditMode(false);
    }
  }, [editMode, refreshSignaturePad, refreshedSignaturePad]);

  useEffect(() => {
    handleStartAddSignature(participant)
  }, []);

  /**
   * **handleStartAddSignature** is invoked when the user clicks on
   * the "Add Signature" assistive link on the __Signature__ field.
   *
   * Handles getting S3 URLs if not avail as well as setting
   * focus to the current Participant
   */
  const handleStartAddSignature = async (participant: DocumentParticipant) => {
    if (!editMode) {
      setEditMode(true);
    }
    const documentId = R.pathOr(
      null,
      ["id"],
      store.newDocument.currentDocument
    );

    if (!documentId) {
      return null;
    }

    // get s3 if needed
    if (!urlsForThisParticipant && !loading) {
      setLoading(true);
      await actions.newDocument.getSignatureUrlForParticipant(
        participant,
        documentId
      );
      setLoading(false);
    }
  };

  /**
   * local method used to dispatch action to store signature data
   * until we submit the document which is when it will be uploaded to s3
   * This is invoked when the user clicks on the "CONFIRM SIGNATURE" button
   * within __Signature_Pad__
   */
  const handleConfirm = (
    signatureData: any,
    participant: DocumentParticipant
  ) => {
    actions.newDocument.storeSignatureData({
      participantId: participant.participantId,
      name: participant.name,
      signatureData,
    });
    // toggleSubmitted(true);
    toggleConfirmedSignature(true);
    attachURL(participant);
  };

  /**
   * dispatches action to clear stored image from store
   */
  const clearSignatureLocally = (participant: DocumentParticipant) => {
    const { clearSignatureData } = actions.newDocument;

    clearSignature(participant);
    clearSignatureData(participant);
    handleOnChangeTypedSignature("", participant);
    toggleSubmitted(false);
    toggleConfirmedSignature(false);
  };

  // RENDER SIGNATURE IMAGE

  if (submitted) {
    return (
      <div>
        <S.LabelRow>
          <S.MaxWidth>
            <Label content={label} htmlFor={`${participant.participantId ? participant.participantId : participant.name}`} />
          </S.MaxWidth>
            <S.AssistiveLinkButton
              type="reset"
              variant="clear"
              onClick={() => {
                clearSignatureLocally(participant);
              }}
            >
              Clear Signature
            </S.AssistiveLinkButton>
        </S.LabelRow>
        {storedSignatureImageForThisParticipant ? (
          <S.SubmittedImageWrap>
            <S.SignatureImage
              src={storedSignatureImageForThisParticipant.signatureData}
            />
          </S.SubmittedImageWrap>
        ) : (
          <S.SubmittedImageWrap>
            <S.SignatureImage src={sigImgFromFocus || signatureUrl} />
          </S.SubmittedImageWrap>
        )}
      </div>
    );
  }

  // RENDER INITIAL MODE / PRE-FILLED TYPED MODE
  if (typedSignature || (!editMode && allowsTypedSignatures(allowedTypes))) {
    const computeAssistiveLinkHelperText = (): string => {
      let helperTextChunk;
      if (allowedTypes.includes("TYPED_NAME")) {
        helperTextChunk = "name";
      }
      if (allowedTypes.includes("TYPED_EMAIL")) {
        helperTextChunk = "email";
      }
      if (
        allowedTypes.includes("TYPED_ANYTHING") ||
        (allowedTypes.includes("TYPED_EMAIL") &&
          allowedTypes.includes("TYPED_NAME"))
      ) {
        helperTextChunk = "name or email";
      }
      return `Enter the participant's ${helperTextChunk} or select “Add Signature” to digitally sign in the field`;
    };
    const computeAssistiveLinkLabel = ({
      loading
    }: AssistiveLinkComputeArgs): string => {
      if (loading) {
        return "...loading";
      }
      return "Add Signature";
    };

    const computeAssistiveLinkOnClick = ({
      loading
    }: AssistiveLinkComputeArgs) => {
      if (!loading) {
        return () => {
          clearSignatureLocally(participant);
          handleStartAddSignature(participant);
        };
      }
    };

    return (
      <TextInput
        autoComplete="off"
        fullWidth
        value={typedSignature}
        height={56}
        helperText={computeAssistiveLinkHelperText()}
        label={label}
        mobileHeight={56}
        onChange={(e) => {
          handleOnChangeTypedSignature(
            e.target.value,
            participant,
          );
        }}
        onBlur={(e) => {
          handleOnChangeTypedSignature(
            e.target.value,
            participant
          );

          if (!touchedField) {
            setTouchedField(true);
          }
        }}
        name={"signature"}
        placeholder={placeholder}
        variant="outlined"
        assistiveLink={{
          label: computeAssistiveLinkLabel({ loading }),
          onClick: computeAssistiveLinkOnClick({ loading }),
        }}
        error={touchedField ? validationError : undefined}
      />
    );
  }

  // RENDER DRAWING MODE

  return (
    <>
      <S.SignatureContainer>
        <S.LabelWrapper>
          <Label
            content={label}
            htmlFor={`${participant.participantId ? participant.participantId : participant.name}`}
            assistiveLink={
              allowsTypedSignatures(allowedTypes)
                ? {
                    label: "Type Signature",
                    onClick: () => {
                      clearSignatureLocally(participant);
                      setEditMode(false);
                    },
                  }
                : undefined
            }
          />
        </S.LabelWrapper>
        <SignaturePad
          desktopMode
          clearOnResize={true}
          height={250}
          participant={participant}
          handleConfirm={handleConfirm}
          confirmation={confirmedSignature}
          width={600}
          clearSignatureLocally={clearSignatureLocally}
          setRefreshEditMode={_refreshEditMode}
        />
      </S.SignatureContainer>
    </>
  );
};

const mapStateToProps = (state: AppState) => ({
  store: {
    newDocument: state.newDocument,
  },
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  actions: {
    newDocument: {
      getSignatureUrlForParticipant: bindActionCreators(
        getSignatureUrlForParticipant,
        dispatch
      ),
      storeSignatureData: bindActionCreators(storeSignatureData, dispatch),
      clearSignatureData: bindActionCreators(clearSignatureData, dispatch),
    },
  },
});


export default connect(mapStateToProps, mapDispatchToProps)(Signature);
