import React, { useContext, useEffect, useState } from "react";
import { css } from "aphrodite/no-important";
import { get } from "lodash";
import { ThemeContext } from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";

import { ActionButton } from "components/common/ActionButton";
import { ActionMenu, ActionMenuItem } from "components/common/ActionMenu";
import { AppState } from "store";
import { DataSourceState, DataSourceValueDTO } from "store/dataSource/types";
import {
  getDataSourceValues,
  GetDataSourceValuesArgs,
} from "store/dataSource/actions";
import { ItemSelection } from "./ItemField";
import CollapsibleDiv from "components/common/CollapsibleDiv";
import Icon from "components/common/Icon";
import Loader from "components/common/Loader";
import Modal from "components/common/Modal/futureUiKit/Modal";

import { elements } from "../items/Elements";
import { FBItem, FBQuestion } from "../../types";
import { fields } from "../items/Fields";
import { ItemField } from "./ItemField";
import { ItemsMutator } from "../Create";
import { widgets } from "../items/Widgets";
import styles from "./styles";
import useValidation from "../validation/useValidation";
import ValidationStatus from "../validation/ValidationStatus";

/** Array of objects containing item names, types and subtypes */
const itemNames = [...elements, ...fields, ...widgets];

/**
 * Generate selections for an item from its data source (if existing) or from its explicitly defined "selections"
 * property. If neither exist, return a single selection prompting user to add selections.
 * @param item
 * @param dataSource
 */
function getSelections(
  item: FBQuestion,
  dataSource: DataSourceState
): Array<ItemSelection> {
  const placeholder = item.properties?.["placeHolderText"];
  const placeholderSelection: ItemSelection = {
    id: -1,
    rootId: -1,
    title: placeholder || "Please add options",
    disabled: true,
    optionPlaceholder: true,
  };

  let selections: Array<ItemSelection> = [];

  /* item has an answer source - use it to populate selections */
  if (item.answerSource && item.answerSource.dataSourceKey) {
    const values: DataSourceValueDTO[] = get(
      dataSource.data,
      item.answerSource.dataSourceKey,
      []
    );
    selections = values.map((as: DataSourceValueDTO) => ({
      id: as.id,
      rootId: as.id,
      title: as.content[item.answerSource?.properties?.answerField || 0],
    }));
  } else if (item.selections?.length) {
    /* item has defined selections */
    selections = item.selections.map((sel) => ({
      id: sel.id,
      rootId: sel.rootId || null,
      title: sel.title,
    }));
  }

  /* prepend placeholder selection if no selections available or placeholder is defined */
  if (selections.length === 0 || placeholder) {
    return [placeholderSelection, ...selections];
  }
  return selections;
}

/**
 * Build the item's name based on its type and subtype
 * @param type    - parent type of the item
 * @param subType - subType of the item
 */
export function getItemName(type: string, subType: string): string {
  const item = itemNames.find((i) => i.type === type && i.subType === subType);
  return item?.name || "";
}

interface Props {
  incrementItemIndex: (item: FBItem, pos: number, arrLen: number) => void;
  decrementItemIndex: (item: FBItem, pos: number) => void;
  item: FBItem;
  position: number;
  removeItem: ItemsMutator;
  setCurrentItem: (item: FBItem) => void;
  selected: boolean;
  siblings: Array<FBItem>;
  itemPath: string;
}

function Item({
  incrementItemIndex,
  decrementItemIndex,
  item,
  position,
  removeItem,
  setCurrentItem,
  selected,
  siblings,
  itemPath,
}: Props) {
  const theme = useContext(ThemeContext);
  const dispatch = useDispatch<ThunkDispatch<AppState, {}, Action>>();

  const [collapsed, setCollapsed] = useState<boolean>(false);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [itemLoading, setItemLoading] = useState<boolean>(false);
  const [apiStatus, setApiStatus] = useState<"initial" | "loading" | "error">(
    "initial"
  );

  const { getErrorsByPath, alertLevel } = useValidation();
  const itemErrors = getErrorsByPath(itemPath);

  const dataSource = useSelector(({ dataSource }: AppState) => dataSource);
  const firstItem = position === 0;
  const lastItem = position === siblings.length - 1;

  useEffect(() => {
    async function fetchDSValues(params: GetDataSourceValuesArgs) {
      setItemLoading(true);
      try {
        await dispatch(getDataSourceValues(params));
      } finally {
        setItemLoading(false);
      }
    }

    if (
      item.answerSource?.dataSourceKey &&
      item.subType !== "PARTICIPANT" &&
      item.subType !== "SUPERVISOR"
    ) {
      fetchDSValues({
        keyName: item.answerSource.dataSourceKey,
        size: 20,
        sortBy: item.answerSource.properties.detailedSearch?.infiniteListSortBy,
      });
    }
  }, [item.answerSource?.dataSourceKey]);

  async function handleDeleteItem() {
    setApiStatus("loading");
    const success = await removeItem(item);
    if (!success) {
      setApiStatus("error");
    } else {
      setApiStatus("initial");
    }
  }

  function handleCloseModal() {
    setModalOpen(false);
    setApiStatus("initial");
  }

  const s = styles();

  if (item.parentWidgetRootId) {
    // filter out the items which are auto-created by a widget
    return null;
  }

  const selections =
    item.type === "QUESTION" ? getSelections(item, dataSource) : [];

  const error = apiStatus === "error";
  const alertCount = itemErrors?.length;

  return (
    <>
      <Modal
        action={{
          text: error ? "okay" : "yes, delete",
          callback: error ? handleCloseModal : handleDeleteItem,
          loading: apiStatus === "loading",
        }}
        alert={{
          variant: error ? "error" : "warning",
          title: error
            ? "An error occurred"
            : "Are you sure you want to delete this question?",
          message: error
            ? "The question could not be deleted. If the issue persists, please contact your system administrator."
            : "This action cannot be undone.",
          isVisible: true,
        }}
        cancellable={apiStatus === "initial"}
        handleClose={handleCloseModal}
        open={modalOpen}
        title="Are you sure?"
      />
      <div
        className={css([
          s.item,
          selected && s.selected,
          alertCount && s[`${alertLevel}Item`],
          alertCount && selected && s[`${alertLevel}ItemSelected`],
        ])}
        id={item?.id?.toString()}
        onClick={(event) => {
          event.stopPropagation();
          setCurrentItem(item);
        }}
      >
        <div className={css(s.itemContent)}>
          <div
            className={css([
              s.itemNameWrapper,
              firstItem && lastItem ? s.padding : "",
            ])}
          >
            <div className={css(s.row)}>
              <Icon
                type="drag_reorder"
                color={theme.masterColors.darkGrey}
                size="20px"
              />
              <div className={css(s.itemName)}>
                {getItemName(item.type, item.subType)}
              </div>
            </div>
            <div className={css(s.row)}>
              {!!alertCount && (
                <ValidationStatus
                  alertLevel={alertLevel}
                  errorCount={alertCount}
                />
              )}
              {!firstItem && (
                <ActionButton
                  onClick={() => decrementItemIndex(item, position)}
                  label="move item up"
                  IconComponent={
                    <i className="icon-icons8-sort_up" style={{ height: 20 }} />
                  }
                />
              )}
              {!lastItem && (
                <ActionButton
                  onClick={() =>
                    incrementItemIndex(item, position, siblings.length)
                  }
                  label="move item down"
                  IconComponent={
                    <i
                      className="icon-icons8-sort_down"
                      style={{ height: 20 }}
                    />
                  }
                />
              )}
              <ActionMenu closeOnClick>
                <ActionMenuItem
                  onClick={() => setCollapsed((currState) => !currState)}
                  label={`${collapsed ? "Expand" : "Collapse"} question`}
                />
                <ActionMenuItem
                  danger
                  onClick={() => setModalOpen(true)}
                  label="Delete question"
                />
              </ActionMenu>
            </div>
          </div>
          <CollapsibleDiv collapsed={collapsed}>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <Loader loading={itemLoading} overlay>
                <ItemField
                  item={item}
                  selected={selected}
                  selections={selections}
                  siblings={siblings}
                />
              </Loader>
            </div>
          </CollapsibleDiv>
        </div>
      </div>
    </>
  );
}

export default Item;
