import styled from "styled-components";

import IconBase from "components/common/Icon";
import Text from "components/common/Text";
import MQ from "themes/mediaQueries";
import colors from "themes/colors";

const commonInputWrapperStyles = `
  padding: 16px 20px;
  border-bottom: 1px solid ${colors.masterColors.mediumGrey};
`;

// --
// ? ProfileField
// --

export const ProfileField = styled.div<{ isDesktop?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;

  ${({ isDesktop, theme }) => {
    if (!isDesktop) {
      return `
      border-bottom: 1px solid ${theme.masterColors.mediumGrey};
      padding: 16px 20px;
      margin-bottom: 19px;
      `;
    }
  }}
`;

// --
// ? Locked
// --

export const Locked = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid ${({ theme }) => theme.masterColors.mediumGrey};
  align-items: center;
  padding: 16px 20px;
  margin-bottom: 19px;
`;

export const LockedLabel = styled.div`
  display: flex;
  align-items: center;
`;

export const Left = styled.div`
  display: flex;
  flex-basis: 1;
  flex-grow: 1;
  flex-shrink: 1;
`;

export const Right = styled.div`
  flex-basis: 1;
  flex-grow: 1.5;
  flex-shrink: 1;
  /* 
    @NOTE: pTop helps align 
    but doesn't work in both cases
    where the locked icon is present
    or not. I'll fix this in the future
    - Trevor Kirpaul
  */
  /* padding-top: 3.5px; */
`;

export const ValueWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const commonTextStyles = `
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0;
`;

export const Label = styled(Text)<{ locked?: boolean }>`
  ${commonTextStyles}
  ${({ locked }) => {
    if (locked) {
      return `
        color: ${({ theme }) => theme.masterColors.mediumGrey};
      `;
    }
  }}
  margin: 0;
`;

export const UnlockedLabel = styled(Label)`
  color: ${({ theme }) => theme.masterColors.black};
`;

export const Value = styled(Text)`
  ${commonTextStyles}

  color: ${({ theme }) => theme.masterColors.mediumGrey};

  margin: 0px 0px 8px 0px;
  align-self: flex-end;

  @media (${MQ.maxPhone}) {
    max-width: 170px;
  }
`;

// --
// ? LockedWarning
// --

export const LockedWarning = styled.div`
  display: flex;
  background-color: ${({ theme }) => theme.masterColors.warningFill};
  @media (${MQ.maxTablet}) {
    padding: 16px 12px;
    margin: 26px;
  }

  @media (${MQ.minDesktop}) {
    width: 100%;
    padding: 17px 24px;
    margin-bottom: 25px;
  }
`;

export const LockIcon = styled(IconBase)`
  margin-right: 12px;
`;

export const LockedWarningText = styled(Text)`
  color: ${({ theme }) => theme.masterColors.darkGrey};
  letter-spacing: 0.51px;
  @media (${MQ.minDesktop}) {
    margin-bottom: 0px;
  }
`;

// --
// ? ProfileSelectField
// --

export const GroupList = styled.ul`
  display: flex;
  flex-direction: column;
  text-align: right;
  padding: 0;
`;
export const GroupLabel = styled(Text)<{ locked?: boolean }>`
  color: ${({ locked, theme }) =>
    locked ? theme.masterColors.mediumGrey : theme.masterColors.black};
  text-align: left;
`;

// --
// ? RenderGroups
// --
/* eslint-disable @typescript-eslint/indent */
export const RenderGroups = styled.div<{ isDesktop?: boolean; isLocked?: boolean; }>`
  ${commonInputWrapperStyles}
  cursor: default;
  display: flex;
  justify-content: space-between;
  padding-top: 10px;

  ${({ isDesktop }) => {
    if (isDesktop) {
      return `
        border-bottom: none;
        padding: 0px 0px 0px 15px;
      `;
    }
  }}

  ${({ isLocked }) => {
    if (!isLocked) {
      return `cursor: pointer;`;
    }
  }}
`;
