import { ReactComponent as Cross } from "assets/svg/cross.svg";
import React, { useEffect, useRef } from "react";

import { ActionButton } from "components/common/ActionButton";
import { Alert } from "components/common/Alert";
import { Button, TertiaryButton } from "components/common/Button/futureUiKit";

import styles from "./styles.module.scss";

export interface ModalProps {
  action: {
    text: string;
    callback: (args: unknown) => unknown;
    loading: boolean;
  };
  alert?: {
    variant: "warning" | "error";
    title: string;
    message: string;
    isVisible: boolean;
  };
  content?: React.ReactNode;
  description?: string;
  handleClose: () => void;
  open: boolean;
  title: string;
  cancellable?: boolean;
}

/**
 * Basic Modal component
 *
 * **Implementation Note -** *In the handleClose event handler,
 * you will need to restore focus to the element that opened this
 * modal. This can be done using a ref.*
 * 
 * @param action
 * @param alert
 * @param content
 * @param description
 * @param handleClose
 * @param open
 * @param title
 * @param cancellable
 * @constructor
 */
const Modal = ({
  action,
  alert,
  content,
  description,
  handleClose,
  open,
  title,
  cancellable = true,
}: ModalProps) => {
  const modalRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (modalRef.current) {
      modalRef.current.focus();
    }

    return () => {
      if (modalRef.current) {
        modalRef.current.blur();
      }
    };
  }, [modalRef.current]);

  const handleKeyUp = (e: globalThis.KeyboardEvent) => {
    if (e.keyCode === 27) {
      handleClose();
    }
  };

  useEffect(() => {
    if (open) {
      window.addEventListener("keyup", handleKeyUp);
    }

    // Clear the event listener on unmount
    return () => {
      window.removeEventListener("keyup", handleKeyUp);
    };
  }, [open]);

  if (open) {
    return (
      <div 
        className={styles.backdrop} 
        onClick={handleClose}
      >
        <div 
          ref={modalRef}
          className={styles.modalContainer} 
          onClick={(e) => e.stopPropagation()}
          role="dialog"
          aria-labelledby="dialog_label"
          aria-describedby="dialog_desc"
          tabIndex={0}
        >

          <div className={styles.header}>
            <h3 id="dialog_label" className={styles.headerText}>{title}</h3>
            <ActionButton
              onClick={handleClose}
              label="Close"
              IconComponent={<Cross className="icon-black-24" />}
            />
          </div>

          <div id="dialog_desc">
            {description && (
              <p id="dialog_desc" className={styles.description}>
                {description}
              </p>
            )}
          </div>

          {content && (
            <div className={styles.content}>
              {content}
            </div>
          )}

          {alert && (
            <Alert
              className={styles.alert}
              variant={alert.variant}
              title={alert.title}
              message={alert.message}
              isVisible={alert.isVisible}
            />
          )}

          <div className={styles.footer}>
            {cancellable && (
              <TertiaryButton className={styles.cancelButton} onClick={handleClose}>Cancel</TertiaryButton>
            )}
            <Button onClick={action.callback} loading={action.loading}>{action.text}</Button>
          </div>

        </div>
      </div>
    );
  }

  return null;
};

export default Modal;
