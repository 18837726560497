import React from "react";
import { Switch } from "react-router-dom";

// Profile routes
import Profile from "components/screens/Profile";
import Settings from "components/screens/Settings";
import EditProfile from "components/screens/EditProfile";

// Tags routes
import { AddTag } from "components/clientAdmin/tags/screens/AddTag";
import { TagLibrary } from "components/clientAdmin/tags/screens/TagLibrary";

// Remaining routes
import AddGroup from "components/clientAdmin/addGroup";
import AddUser from "components/clientAdmin/addUser";
import AdvancedDS from "components/clientAdmin/dataSets/AdvancedDS";
import AppNavWrapper from "components/navigation/AppNavWrapper";
import BasicDS from "components/clientAdmin/dataSets/BasicDS";
import ComingSoon from "components/comingSoon/ComingSoon";
import Dashboard from "components/dashboard/Dashboard";
import DataSets from "components/clientAdmin/dataSets/DataSets";
import Document from "components/document";
import Documents from "components/screens/documents";
import Feedback from "components/feedback";
import FormBuilder from "components/clientAdmin/formBuilder/FormBuilder";
import FormList from "components/clientAdmin/formList/FormList";
import FormReport from "components/clientAdmin/reports/FormReport/FormReport";
import Groups from "components/clientAdmin/groups";
import Header from "components/navigation/Header";
import HelpDesk from "components/helpDesk/screens/HelpDesk";
import MaintainResources from "components/clientAdmin/resources/MaintainResources";
import Privacy from "components/privacy/Privacy";
import Reports from "components/clientAdmin/reports";
import Resource from "components/clientAdmin/resources/Resource";
import Users from "components/clientAdmin/users";
import ViewAdvancedDS from "components/clientAdmin/dataSets/ViewAdvancedDS";
import ViewGroup from "components/clientAdmin/viewGroup";
import ViewUserProfile from "components/clientAdmin/viewUserProfile";

import { AuthenticatedRoute } from "routes/AuthenticatedRoute";
import {
  allRoles,
  peopleRoles,
  formsRoles,
  contentRoles,
  feedbackRoles,
} from "./constants/permissionSets";

// Not found
import { RouteNotFound } from "./RouteNotFound";
import { NoPermissions } from "./NoPermissions";
import ResourceCategories from "components/clientAdmin/resourceCategories/ResourceCategories";
import ResourceCategory from "components/clientAdmin/resourceCategories/ResourceCategory";

const AuthenticatedRoutes = () => {
  return (
    <Switch>
      <AuthenticatedRoute
        path="/document/:documentId"
        Component={Document}
        accessRoles={allRoles}
      />
      <AuthenticatedRoute
        path="/new-document"
        Component={Document}
        accessRoles={allRoles}
      />

      {/* Form Builder */}
      <AuthenticatedRoute
        exact
        path="/forms/form/:id"
        Component={FormBuilder}
        accessRoles={formsRoles}
      />

      <>
        <Header />
        <AppNavWrapper>
          <Switch>
            <AuthenticatedRoute
              exact
              path="/"
              Component={Dashboard}
              accessRoles={allRoles}
            />
            {/* Documents */}
            <AuthenticatedRoute
              exact
              path="/documents/:formType"
              Component={Documents}
              accessRoles={allRoles}
            />
            <AuthenticatedRoute
              exact
              path="/documents"
              Component={Documents}
              accessRoles={allRoles}
            />
            {/* Coming Soon placeholder */}
            <AuthenticatedRoute
              exact
              path="/coming-soon"
              Component={ComingSoon}
              accessRoles={allRoles}
            />
            {/* Reports  */}
            <AuthenticatedRoute
              exact
              path="/reports"
              Component={Reports}
              accessRoles={allRoles}
            />
            <AuthenticatedRoute
              exact
              path="/reports/:id"
              Component={FormReport}
              accessRoles={allRoles}
            />
            {/* Users  */}
            <AuthenticatedRoute
              exact
              path="/people/users"
              Component={Users}
              accessRoles={peopleRoles}
            />
            <AuthenticatedRoute
              exact
              path="/people/users/view/:id"
              Component={ViewUserProfile}
              accessRoles={peopleRoles}
            />
            <AuthenticatedRoute
              exact
              path="/people/users/add-user"
              Component={AddUser}
              accessRoles={peopleRoles}
            />
            <AuthenticatedRoute
              exact
              path="/people/users/edit-user/:id"
              Component={AddUser}
              accessRoles={feedbackRoles}
            />
            {/* Form List */}
            <AuthenticatedRoute
              exact
              path="/forms"
              Component={FormList}
              accessRoles={formsRoles}
            />
            {/* Groups  */}
            <AuthenticatedRoute
              exact
              path="/people/groups"
              Component={Groups}
              accessRoles={peopleRoles}
            />
            <AuthenticatedRoute
              exact
              path="/people/groups/add-group"
              Component={AddGroup}
              accessRoles={peopleRoles}
            />
            <AuthenticatedRoute
              exact
              path="/people/groups/edit-group/:id"
              Component={AddGroup}
              accessRoles={peopleRoles}
            />
            <AuthenticatedRoute
              exact
              path="/people/groups/view/:id"
              Component={ViewGroup}
              accessRoles={peopleRoles}
            />
            {/* Data Sets */}
            <AuthenticatedRoute
              exact
              path="/forms/data-sets"
              Component={DataSets}
              accessRoles={formsRoles}
            />
            <AuthenticatedRoute
              exact
              path="/forms/data-sets/basic"
              Component={BasicDS}
              accessRoles={formsRoles}
            />
            <AuthenticatedRoute
              exact
              path="/forms/data-sets/basic/:id"
              Component={BasicDS}
              accessRoles={formsRoles}
            />
            <AuthenticatedRoute
              exact
              path="/forms/data-sets/advanced"
              Component={AdvancedDS}
              accessRoles={formsRoles}
            />
            <AuthenticatedRoute
              exact
              path="/forms/data-sets/advanced/:id"
              Component={AdvancedDS}
              accessRoles={formsRoles}
            />
            <AuthenticatedRoute
              exact
              path="/forms/data-sets/advanced/:id/view"
              Component={ViewAdvancedDS}
              accessRoles={formsRoles}
            />
            {/* Resources */}
            <AuthenticatedRoute
              exact
              path="/content/resources"
              Component={MaintainResources}
              accessRoles={contentRoles}
            />
            <AuthenticatedRoute
              exact
              path="/content/resources/new"
              Component={Resource}
              accessRoles={contentRoles}
            />
            <AuthenticatedRoute
              exact
              path="/content/resources/:id"
              Component={Resource}
              accessRoles={contentRoles}
            />
            {/* Tags Library */}
            <AuthenticatedRoute
              exact
              path="/content/tag-library"
              Component={TagLibrary}
              accessRoles={contentRoles}
            />
            <AuthenticatedRoute
              exact
              path="/content/tag-library/add-tag"
              Component={AddTag}
              accessRoles={contentRoles}
            />
            {/* Resource Tags */}
            <AuthenticatedRoute
              exact
              path="/content/resource-categories"
              Component={ResourceCategories}
              accessRoles={contentRoles}
            />
            <AuthenticatedRoute
              exact
              path="/content/resource-categories/new"
              Component={ResourceCategory}
              accessRoles={contentRoles}
            />
            <AuthenticatedRoute
              exact
              path="/content/resource-categories/:id"
              Component={ResourceCategory}
              accessRoles={contentRoles}
            />
            {/* Profile */}
            <AuthenticatedRoute
              exact
              path="/profile/settings"
              Component={Settings}
              accessRoles={allRoles}
            />
            <AuthenticatedRoute
              exact
              path="/profile/edit"
              Component={EditProfile}
              accessRoles={allRoles}
            />
            <AuthenticatedRoute
              exact
              path="/profile"
              Component={Profile}
              accessRoles={allRoles}
            />

            <AuthenticatedRoute
              exact
              path="/help-desk"
              Component={HelpDesk}
              accessRoles={allRoles}
            />
            {/* Feedback */}
            <AuthenticatedRoute
              exact
              path="/feedback"
              Component={Feedback}
              accessRoles={allRoles}
            />
            {/* Privacy Policy */}
            <AuthenticatedRoute
              exact
              path="/privacy"
              Component={Privacy}
              accessRoles={allRoles}
            />

            {/* No Permissions */}
            <AuthenticatedRoute
              exact
              path="/no-permissions"
              Component={NoPermissions}
              accessRoles={allRoles}
            />

            <AuthenticatedRoute
              exact
              path="*"
              Component={RouteNotFound}
              accessRoles={allRoles}
            />
          </Switch>
        </AppNavWrapper>
      </>
    </Switch>
  );
};

export default AuthenticatedRoutes;
