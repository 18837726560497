import React from "react";
import { connect } from "react-redux";
import { Formik, FormikHelpers } from "formik";
import { RouteComponentProps, withRouter } from "react-router";

import { AppState } from "store";
import { feedbackTypeAPI } from "store/feedback/types";
import { Option } from "components/common/form/Select/Select";
import { submitFeedback } from "store/feedback/actions";
import { UserState } from "store/user/types";
import * as S from "./styles";
import Select from "components/common/form/Select";

// Types
type FeedbackOptions = Array<Option>;

interface FormikValues {
  selectFeedbackType: number;
  commentInput: string;
}

interface FormSubmitPayload {
  clientGroupId: number;
  feedback: string;
  feedbackType: feedbackTypeAPI;
  userId: number;
}

// Formik State Initial Values
const initialValues: FormikValues = {
  selectFeedbackType: -1,
  commentInput: "",
};

// Formik Validation
type ErrorsType = {
  selectFeedbackType?: string;
  commentInput?: string;
};

const validate = (values) => {
  const errors: ErrorsType = {};

  // Check if selection has not been made
  if (values.selectFeedbackType < 0) {
    errors.selectFeedbackType = "Please select a feedback type";
  }
  // Check if comment is less than 3 characters
  if (values.commentInput.length < 3) {
    errors.commentInput = "Please provide a meaningful comment";
  }

  return errors;
};

export interface ReportFeedbackFormProps extends RouteComponentProps {
  dispatch: any;
  submitFeedbackVisible: boolean;
  toggleSubmitFeedbackVisibility: () => void;

  // ? REDUX
  user: UserState;
}

export interface ReportFeedbackFormState {
  feedbackOptions: FeedbackOptions;
  submitLoading: boolean;
  submitStatus: "success" | "failure" | null;
}

class ReportFeedbackForm extends React.Component<ReportFeedbackFormProps, ReportFeedbackFormState> {
  constructor(props) {
    super(props);
    this.state = {
      feedbackOptions: [
        { id: -1, value: "Select a feedback type", disabled: true, optionPlaceholder: true },
        { id: 0, value: "New feature request" },
        { id: 1, value: "Report an issue" },
        { id: 2, value: "Other" },
      ],
      submitLoading: false,
      submitStatus: null,
    };
  }

  handleSelectedValue = (selectedValue: number) => {
    //Find the feedback option whose id matches the selected value in the form
    const matchingOption = this.state.feedbackOptions.find((option) => option.id === selectedValue);
    //And if there is a match, return the associated API friendly value
    if (matchingOption) {
      switch (matchingOption.id) {
        case 0:
          return "FEATURE_REQUEST";
        case 1:
          return "BUG";
        case 2:
          return "GENERAL";
      }
    }
  };

  _handleSubmit = (values: FormikValues, resetForm: FormikHelpers<FormikValues>["resetForm"]) => {
    const { selectFeedbackType, commentInput } = values;
    const { user } = this.props;

    if (user.data !== null && user.data.authorities) {
      const payload: FormSubmitPayload = {
        clientGroupId:
          user.data.authorities[0].groups[0] /*/FIXME: Need to eventually replace with user primaryGroupId /*/,
        feedback: commentInput,
        feedbackType: this.handleSelectedValue(selectFeedbackType),
        userId: user.data.user.id,
      };

      this.setState({ submitLoading: true, submitStatus: null });
      this.props.dispatch(submitFeedback(payload)).then((e) => {
        if (e.response) {
          // Stop loading if we get a response
          this.setState({ submitLoading: false });
          if (e.type === "SUBMIT_FEEDBACK_SUCCESS") {
            // Change status to success
            this.setState({ submitStatus: "success" });
            // And reset the form
            resetForm();
          } else {
            // Change status to failure
            this.setState({ submitStatus: "failure" });
          }
        } else {
          console.log("FAILURE: No response"); //FIXME: Do we need to handle if there is no response?
        }
      });
    }
  };

  handleSubmitStatus = (status: string | null, loading: boolean, defaultValue: string) => {
    //First, check if loading.
    switch (loading) {
      case true:
        // If so, break out of the switch
        break;
      default:
        // If not loading, check response status
        switch (status) {
          case "success":
            return (
                  <>
                    <S.Icon className="icon icon-icons8-checkmark" />
                FEEDBACK SUBMITTED
                  </>
            );
          case "failure":
            return "FAILED TO SUBMIT";
          default:
            // If not loading and do not have a response status, show default value
            return defaultValue.toUpperCase();
        }
    }
  };

  // Toggles the placeholder hint for the comment input field depending on selected feedback type
  handleCommentPlaceholder = (feedbackTypeId: number) => {
    switch (feedbackTypeId) {
      case 0:
        return "Describe the feature you'd like to see";
      case 2:
        return "Enter a detailed comment here";
      default:
        return "Help us understand what went wrong";
    }
  };

  render() {
    const { feedbackOptions } = this.state;
    return (
      <>
        <Formik
          initialValues={initialValues}
          onSubmit={(values, { setFieldTouched, resetForm }) => {
            //Set fields to touched
            setFieldTouched("selectFeedbackType", true, true);
            setFieldTouched("commentInput", true, true);

            this._handleSubmit(values, resetForm);
          }}
          validate={validate}
          validateOnChange={false}
        >
          {(props) => {
            const { errors, handleSubmit, setValues, touched, values } = props;
            const { selectFeedbackType, commentInput } = values;
            return (
              <>
                <form onSubmit={handleSubmit}>
                  <Select
                    error={errors["selectFeedbackType"]}
                    label={"Type of Feedback"}
                    name={"selectFeedbackType"}
                    noMargin
                    onChange={(e) => setValues({ ...values, selectFeedbackType: e.target.value })}
                    options={feedbackOptions}
                    required={true}
                    touched={touched["selectFeedbackType"]}
                    value={selectFeedbackType}
                  />
                  <S.TextArea
                    error={errors["commentInput"]}
                    initialRowsVisible={5}
                    internalVariant={"TextArea"}
                    label={"Comment"}
                    maxRowsVisible={5}
                    multiline={true}
                    name={"commentInput"}
                    onChange={(e) => setValues({ ...values, commentInput: e.target.value })}
                    placeholder={this.handleCommentPlaceholder(selectFeedbackType)}
                    touched={touched["commentInput"]}
                    value={commentInput}
                    variant={"outlined"}
                  />
                  <S.ButtonsWrapper>
                    <S.SubmitButton
                      type="submit"
                      fullWidth={true}
                      height={2.5}
                      loading={this.state.submitLoading}
                      status={this.state.submitStatus}
                      disabled={this.state.submitLoading}
                    >
                      {this.handleSubmitStatus(this.state.submitStatus, this.state.submitLoading, "Submit Feedback")}
                    </S.SubmitButton>
                    <S.CancelButton
                      onClick={this.props.toggleSubmitFeedbackVisibility}
                      variant={"unstyled-danger"}
                      fullWidth={true}
                      height={2.5}
                      disabled={this.state.submitLoading}
                    >
                      CANCEL
                    </S.CancelButton>
                  </S.ButtonsWrapper>
                </form>
              </>
            );
          }}
        </Formik>
      </>
    );
  }
}

const mapStateToProps = (state: AppState) => {
  return {
    user: state.user,
  };
};

export default withRouter(connect(mapStateToProps)(ReportFeedbackForm));
