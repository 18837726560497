import React from "react";

import { IconProps } from "../Icon";

import * as S from "./styles";

const Star = (props: Partial<IconProps>) => {
  return (
    <S.Star 
      {...props}
      type="icon-icons8-star-filled"
    />
  );
};

export default Star;
