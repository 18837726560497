import { CALL_API } from "middleware/api";

import {
  ADD_USERS_PARTICIPANT,
  CHANGE_ACTIVATION_STATUS,
  GET_USERS_PARTICIPANT,
  RESEND_VALIDATION_EMAIL,
  UPDATE_USERS_PARTICIPANT,
} from "./types";
import { UserStatus } from "store/participantsSummary/types";

// Add user
export const addUserParticipant = (body) => (dispatch, getState) => {
  const { system } = getState();
  const endpoint = "users/participants";
  return dispatch({
    [CALL_API]: {
      endpoint,
      options: {
        method: "POST",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${system.authToken}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      },
      types: [ADD_USERS_PARTICIPANT.REQUEST, ADD_USERS_PARTICIPANT.SUCCESS, ADD_USERS_PARTICIPANT.FAILURE],
    },
  });
};

// Edit user
export const updateUserParticipant = (body) => (dispatch, getState) => {
  const { system } = getState();
  const endpoint = "users/participants";
  return dispatch({
    [CALL_API]: {
      endpoint,
      options: {
        method: "PUT",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${system.authToken}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      },
      types: [UPDATE_USERS_PARTICIPANT.REQUEST, UPDATE_USERS_PARTICIPANT.SUCCESS, UPDATE_USERS_PARTICIPANT.FAILURE],
    },
  });
};

// Get user
export const getUserParticipant = (id) => (dispatch, getState) => {
  const { system } = getState();
  const endpoint = `users/participants/${id}`;
  return dispatch({
    [CALL_API]: {
      endpoint,
      options: {
        method: "GET",
        headers: {
          // Accept: "application/json",
          Authorization: `Bearer ${system.authToken}`,
        },
      },
      types: [GET_USERS_PARTICIPANT.REQUEST, GET_USERS_PARTICIPANT.SUCCESS, GET_USERS_PARTICIPANT.FAILURE],
    },
  });
};

// Re-send validation email
type ResendValidationEmailPayload = {
  email: string;
  status: UserStatus;
}
export const resendValidationEmail = ({
  email,
  status,
}: ResendValidationEmailPayload) => (dispatch, getState) => {
  const { system } = getState();
  let endpoint = `users/sendActivationEmail/${email}`;
  // Based on status, change endpoint
  if (status === "NOT_ACTIVATED") {
    endpoint = `/users/resend-validation-email/${email}`;
  }
  return dispatch({
    [CALL_API]: {
      endpoint,
      options: {
        method: "GET",
        headers: {
          // Accept: "application/json",
          Authorization: `Bearer ${system.authToken}`,
        },
      },
      types: [RESEND_VALIDATION_EMAIL.REQUEST, RESEND_VALIDATION_EMAIL.SUCCESS, RESEND_VALIDATION_EMAIL.FAILURE],
    },
  });
};

// Change activation status
export const changeActivationStatus = (body) => (dispatch, getState) => {
  const { system } = getState();
  const endpoint = "users/activation-status";
  return dispatch({
    id: body && body.participantId,
    [CALL_API]: {
      endpoint,
      options: {
        method: "POST",
        headers: {
          Authorization: `Bearer ${system.authToken}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      },
      types: [CHANGE_ACTIVATION_STATUS.REQUEST, CHANGE_ACTIVATION_STATUS.SUCCESS, CHANGE_ACTIVATION_STATUS.FAILURE],
    },
  });
};

// Disable participant (locally)
export const disableUserParticipant = (id) => (dispatch) =>
  dispatch({
    payload: id,
    type: "DISABLE_USER_PARTICIPANT",
  });
