import React from "react";

import { Pagination as PaginationType, PaginationParams } from "store/common/types";
import BackToTop from "components/common/TableUI/BackToTop";
import Pagination, { PaginationWrapper } from "components/common/TableUI/Pagination";
import RowsPerPage from "components/common/TableUI/RowsPerPage";

interface Props {
  params: PaginationParams;
  paginationValues: PaginationType;
  onUpdateParams: (param: Partial<PaginationParams>) => void;
};

const TagLibraryTablePagination = ({ params, paginationValues, onUpdateParams }: Props) => {
  return (
    <PaginationWrapper>
      <RowsPerPage pageSize={params.size} onClick={(size) => onUpdateParams({ size })} />
      <Pagination
        currentPage={params.page}
        totalPages={paginationValues.totalPages}
        onClick={(page) => onUpdateParams({ page })}
      />
      <BackToTop />
    </PaginationWrapper>
  );
};

export default TagLibraryTablePagination;
