import {
  DocumentsFiltersParams,
  DOCUMENTS_FILTERS,
  SetDocumentsFiltersAction,
} from "./types";

export const setDocumentsFilters = (
  filters: DocumentsFiltersParams
): SetDocumentsFiltersAction => ({
  response: filters,
  type: DOCUMENTS_FILTERS.SET,
});
