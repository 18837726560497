import React from "react";
import styled from "styled-components";
import AppBarBase from "@material-ui/core/AppBar";
import IconButtonBase from "@material-ui/core/IconButton";
import ToolbarBase from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import MoreVirtIcon from "@material-ui/icons/MoreVert";
import MenuBase from "@material-ui/core/Menu";
import MenuItemBase from "@material-ui/core/MenuItem";
import LinearProgressBase from "@material-ui/core/LinearProgress";

import { remCalc } from "themes/helpers";
import mediaQueries from "themes/mediaQueries";

const FormHeader = styled.div``;

const LinearProgress = styled(({ ...props }) => (
  <LinearProgressBase
    {...props}
    classes={{
      root: "root",
      bar1Determinate: "bar1Determinate",
      determinate: "determinate",
    }}
  />
))`
  && .bar1Determinate {
    background: ${({ theme }) => theme.colors.active};
  }
  &&.determinate {
    background: ${({ theme }) => theme.colors.activeFill};
  }
`;

const AppBar = styled(({ ...props }) => (
  <AppBarBase {...props} classes={{ root: "root", positionAbsolute: "positionAbsolute" }} />
))`
  &&.root {
    background: ${({ theme }) => theme.colors.white};
    box-shadow: none;
    min-height: ${remCalc(56)};
  }

  && .positionAbsolute {
    width: 100%;
  }
`;

const IconButton = styled(({ ...props }) => <IconButtonBase {...props} />)``;

const Icon = styled.i`
  color: ${({ theme }) => theme.colors.darkGrey};
  font-size: ${remCalc(30)};
`;

const Toolbar = styled(({ ...props }) => <ToolbarBase {...props} classes={{ root: "root" }} />)`
  display: flex;
  justify-content: space-between;
`;

const Title = styled(({ variant, ...props }) => <Typography {...props} classes={{ root: "root" }} />)`
  &&.root {
    color: ${({ theme }) => theme.colors.black};
    font-family: "Roboto";
    font-size: ${remCalc(20)};
    font-weight: 500;
    letter-spacing: ${remCalc(0.39)};

    @media (${mediaQueries.maxPhone}) {
      text-align: center;
    }
  }
`;

const MoreVirt = styled(({ ...props }) => <MoreVirtIcon {...props} classes={{ root: "root" }} />)`
  &&.root {
    color: ${({ theme }) => theme.colors.black};
  }
`;

const Menu = styled(({ ...props }) => <MenuBase {...props} />)``;

const MenuItem = styled(({ ...props }) => <MenuItemBase {...props} />)``;

export { AppBar, FormHeader, IconButton, Icon, Toolbar, Title, MoreVirt, Menu, MenuItem, LinearProgress };
