import { StyleSheet } from "aphrodite";

export default StyleSheet.create({
  container: {
    alignItems: "stretch",
    display: "flex",
    flexDirection: "column",
    position: "relative",
  },
  commentField: {
    marginTop: "0.375rem"
  }
});
