import React from "react";
import { css } from "aphrodite/no-important";

import Select from "components/common/form/Select";

import propertiesStyles from "../../styles";

interface ShowHideFieldProps {
  value: number | string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
}

const ShowHideField = ({ value, onChange, disabled }: ShowHideFieldProps) => {
  const ps = propertiesStyles();

  return (
    <Select
      wrapperClassName={css([ps.dcSelect, ps.ruleTypeSelect])}
      name="showhide"
      label=""
      value={value}
      options={[
        // { value: "Select rule type", id: -1 },
        { value: "Show/Hide", id: "showhide" },
        { value: "Prefill", id: "prefill" },
      ]}
      onChange={onChange}
      disabled={disabled}
    />
  );
};

export default ShowHideField;
