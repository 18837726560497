import React from "react";

import S from "./styles";

interface SplashScreenProps {
  status: string;
}

const SplashScreen = ({ status }: SplashScreenProps) => {
  return (
    <S.Wrapper>
      <S.ContentWrapper>
        <S.Logo>
          <S.LogoIcon />
        </S.Logo>
        <S.LogoBrand>Safety Tools</S.LogoBrand>
        <S.Status>{status}</S.Status>
      </S.ContentWrapper>
    </S.Wrapper>
  );
};

export default SplashScreen;
