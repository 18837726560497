import * as React from "react";
import { Column } from "../styles";
import { css, StyleSheet } from "aphrodite/no-important";

const styles = StyleSheet.create({
  wrapper: {
    display: "flex",
    flexWrap: "wrap",
  },
  title: {
    flex: "0 0 25%",
    maxWidth: "25%",
  },
  createdBy: {
    flex: "0 0 33.333333%",
    maxWidth: "33.333333%",
  },
  date: {
    flex: "0 0 41.666667%",
    maxWidth: "41.666667%",
    textAlign: "right",
  }
});

const RelatedDocumentsTableHeader = () => {
  return (
    <div className={css(styles.wrapper)}>
      <Column className={css(styles.title)}>Documents</Column>
      <Column className={css(styles.createdBy)}>Created By</Column>
      <Column className={css(styles.date)}>Date</Column>
    </div>
  );
};

export { RelatedDocumentsTableHeader };
