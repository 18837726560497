import styled from "styled-components";
import { toRgba } from "themes/helpers";

const SVG = styled.svg`
  position: relative;
  bottom: -1px;
  fill: ${({ theme }) => theme.colors.white};
  filter: ${({ theme }) => `drop-shadow(0px 0px 1px ${toRgba(theme.colors.black, 0.65)})`};
`;

const StartNewFormButton = styled.button`
  position: absolute;
  top: -30px;
  z-index: 10000;

  border-radius: 50%;
  width: 56px;
  height: 56px;

  background-color: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.white};

  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 26px;
`;

// @TODO: MOVE TO SHARED LOCATION
// THIS IS A COPY OF THE COMPONENT IN `DashboardBottomNavigation.tsx`
// @UPDATE: slight modification due to TS error
const StyledIcon = styled.i`
  font-size: 1.5rem;
`;

export { StartNewFormButton, StyledIcon, SVG };
