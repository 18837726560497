import React from "react";

import { MapContainer } from "components/document/styles";
import Map from "components/map";

import { StaticMap } from "../types";

export function ReadOnlyMap ({ viewport, mapMarkers }: StaticMap) {
  return (
    <MapContainer>
      <Map
        initialViewport={viewport}
        markers={mapMarkers}
        disableControls
      />
    </MapContainer>
  );
}
