import React from "react";

import * as S from "./styles";
import { ListItemText } from "@material-ui/core";
export interface CommonFilterProps {
  error?: string;
  label?: string;
  name: string;
  handleChange(e: React.ChangeEvent<any>): void;
  handleChange<T = string | React.ChangeEvent<any>>(
    field: T,
  ): T extends React.ChangeEvent<any> ? void : (e: string | React.ChangeEvent<any>) => void;
}
export interface CloseButtonProps {
  onClick: (e: React.MouseEvent) => void;
  className?: string;
  iconSize?: number;
}

const CloseButton = ({ iconSize, onClick, className }: CloseButtonProps) => {
  return (
    <S.CloseButton className={className} onClick={onClick}>
      <S.ButtonIcon iconSize={iconSize} className="icon-icons8-delete_sign" />
    </S.CloseButton>
  );
};
export interface FilterSelectProps extends CommonFilterProps {
  options: Array<{
    id: string | number;
    value: string;
  }>;
  value: string | number;
}
export interface MultiSelectProps extends CommonFilterProps {
  options: string[];
  value: string[];
}

const FilterSelect = ({ name, value, error, label, options, handleChange }: FilterSelectProps) => {
  const hasInitialValue = typeof value === "string" ? value === options[0].id : value === -1;
  return (
    <S.SelectInput>
      {label && <S.InputLabel>{label}</S.InputLabel>}
      <S.FormControl>
        <S.Select
          customColor={!hasInitialValue ? ["colors", "primary"] : ["masterColors", "darkGrey"]}
          disableUnderline
          displayEmpty
          hasInitialValue={hasInitialValue}
          name={name}
          onChange={handleChange}
          value={value}
          MenuProps={{
            getContentAnchorEl: null,
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
          }}
        >
          {options.map((option) => (
            <S.MenuItem key={option.id} value={option.id}>
              {option.value}
            </S.MenuItem>
          ))}
        </S.Select>
      </S.FormControl>
      {error && <S.ErrorLabel>{error}</S.ErrorLabel>}
    </S.SelectInput>
  );
};

const FilterSelectMulti = ({ name, value, error, label, options, handleChange }: MultiSelectProps) => {
  const renderButton = (selected, val) => {
    const value = selected.filter((s) => s !== val);
    return (
      <S.Button key={val}>
        {val}
        <CloseButton
          iconSize={14}
          onClick={(e) => {
            e.stopPropagation();
            handleChange({ target: { value } });
          }}
        />
      </S.Button>
    );
  };
  const renderValue = (selected) => {
    if (!selected.length) {
      return "All Ratings";
    }
    return (
      <>
        <div className="d-flex">{selected.map((val, i) => i < 2 && renderButton(selected, val))}</div>
        <div className="d-flex">{selected.map((val, i) => i > 1 && renderButton(selected, val))}</div>
      </>
    );
  };
  return (
    <S.SelectInput>
      {label && <S.InputLabel>{label}</S.InputLabel>}
      <S.FormControl>
        <S.Select
          customColor={value.length ? ["colors", "primary"] : ["masterColors", "darkGrey"]}
          disableUnderline
          displayEmpty
          // hasValue={value.length ? value.length : -2} // Deprecated
          name={name}
          onChange={handleChange}
          value={value}
          multiple
          renderValue={renderValue}
          MenuProps={{
            getContentAnchorEl: null,
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
          }}
        >
          {options.map((option) => (
            <S.MenuItemMulti key={option} value={option}>
              <S.Checkbox checked={value.indexOf(option) > -1} />
              <ListItemText primary={option} />
            </S.MenuItemMulti>
          ))}
        </S.Select>
      </S.FormControl>
      {error && <S.ErrorLabel>{error}</S.ErrorLabel>}
    </S.SelectInput>
  );
};

export { FilterSelect, FilterSelectMulti };
