import React, { useCallback, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import MUIMenu from "@material-ui/core/Menu";

import { getUserProfile } from "store/user/selectors";
import { logout } from "store/system/actions";
import colors from "themes/colors";
import Icon from "components/common/Icon";

import * as S from "./styles";
import MenuHeader from "./Header";
import { ThemeContext } from "styled-components";

const useStyles = () => {
  const theme = useContext(ThemeContext);
  return makeStyles({
    root: {
      outline: 0,

      "&:focus": {
        outline: 0,
        backgroundColor: theme.colors.white,
      }
    }
  })();
};

const menuItemStyles = () => {
  return makeStyles({
    root: {
      minWidth: 220
    }
  })();
};

/**
 * Renders the User Menu component in the top right of the Desktop view
 */
export default function UserMenu() {
  const dispatch = useDispatch();
  const { push } = useHistory();
  const { nameWithoutNick: name, userInitials } = useSelector(getUserProfile);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const classes = useStyles();

  function handleClick(event: React.MouseEvent<HTMLButtonElement>) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  const handleNavigateToUserProfile = useCallback(() => {
    push("/profile");
    handleClose();
  }, [push, handleClose]);

  const handleLogout = useCallback(() => {
    dispatch(logout());
    handleClose();
  }, [dispatch, handleClose]);

  const styles = menuItemStyles();

  return (
    <S.UserMenu>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        classes={classes}
        onClick={handleClick}
      >
        <S.UserMenuInitial>{userInitials}</S.UserMenuInitial>
        <Icon type="expand_arrow" color={colors.masterColors.darkGrey} />
      </Button>

      <MUIMenu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuHeader
          userInitials={userInitials}
          name={name}
          handleNavigateToUserProfile={handleNavigateToUserProfile}
        />
        <MenuItem onClick={handleLogout} classes={styles}>
          <S.Label>
            <S.SignOutText as="p">Sign Out</S.SignOutText>
            <Icon color="red" type="chevron-right" />
          </S.Label>
        </MenuItem>
      </MUIMenu>
    </S.UserMenu>
  );
}
