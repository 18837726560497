import React, { useCallback, useMemo } from "react";
import { isNumber, isNil } from "lodash";
import { useFormik } from "formik";
import { useMediaQuery } from "react-responsive";
import { useSelector, useDispatch } from "react-redux";

import { devices } from "themes/mediaQueries";
import {
  getUserProfile,
  getOfficeLocations,
  getUpdateUserStatus,
  getUserRoles,
} from "store/user/selectors";
import { UpdateUserConfig } from "store/user/types";
import { updateUserInfo, getUser } from "store/user/actions";
import Logout from "components/Settings/Logout";

import LockedWarning from "../LockedWarning";
import ProfileField from "../ProfileField";
import ProfileOptionField from "../ProfileOptionField";

import ButtonRow from "./ButtonRow";
import * as S from "./styles";

/**
 * Contains all form logic for Edit Profile
 * as well as form config (labels, names, ect)
 */
const EditProfileForm = () => {
  const isDesktop = useMediaQuery({
    minWidth: devices.minDesktop,
  });

  const {
    email,
    firstName,
    groups,
    imageUrl,
    langKey,
    lastName,
    nickname,
    primaryGroupId,
    workLocationId,
  } = useSelector(getUserProfile);
  const officeLocations = useSelector(getOfficeLocations);
  const updateUserStatus = useSelector(getUpdateUserStatus);
  const roles = useSelector(getUserRoles);
  const dispatch = useDispatch();

  const canEditGroups = roles.some(
    (role) => role === "ROLE_CLIENT_ADMIN" || role === "ROLE_FORM_ARCHITECT"
  );

  const groupNames: Array<string> = useMemo(() => {
    return groups.map((x) => x.name || "").filter((x) => !!x);
  }, [groups]);

  const options = useMemo(
    () =>
      officeLocations.map((x) => ({
        id: x.id,
        value: x.label,
      })),
    [officeLocations]
  );

  const primaryGroupOptions: Array<{
    id: number;
    value: string;
  }> = useMemo(
    () =>
      groups
        .filter((x) => x.id && x.name)
        .map((x) => ({
          id: x.id || 0,
          value: x.name || "",
        })),
    [groups]
  );

  const getLockedSelectFieldValueFor = useCallback(
    (id: number, field: "group" | "office") => {
      if (field === "group") {
        const group = groups.find((x) => x.id === id);
        if (group) {
          return group.name;
        }
      }

      if (field === "office") {
        const office = officeLocations.find((x) => x.id === id);
        if (office) {
          return office.label;
        }
      }

      return id.toString();
    },
    [groups, officeLocations]
  );

  // Requirements don't mention an ADFS flag, so disabling everything for now -- GK
  const locked = true; // PPP-351 -- GK

  const formik = useFormik<UpdateUserConfig>({
    initialValues: {
      email,
      firstName,
      imageUrl,
      langKey,
      lastName,
      nickname,
      primaryGroupId,
      workLocationId: !isNil(workLocationId) ? workLocationId : -1,
    },
    onSubmit: async (values) => {
      try {
        await dispatch(updateUserInfo(values));
        /**
         * We'll only need to fetch when viewing on desktop.
         * On Desktop there are elements which reflect the cached profile data.
         * On Mobile we don't worry any user data except for the form
         * which will always be up to date. (or ahead)
         */
        if (isDesktop) {
          dispatch(getUser());
        }
      } catch (error) {
        throw error;
      }
    },
  });

  const handleOnBlur = useCallback(async () => {
    if (isDesktop) return null;

    try {
      await formik.submitForm();
    } catch (error) {
      throw Error(error);
    }
  }, [formik.submitForm, isDesktop]);

  return (
    <>
      {locked && isDesktop && <LockedWarning />}
      <S.Form onSubmit={formik.handleSubmit}>
        <ProfileField
          isDesktop={isDesktop}
          fieldProps={{
            label: "First Name",
            name: "firstName",
            type: "text",
            onChange: formik.handleChange,
            value: formik.values.firstName,
            onBlur: handleOnBlur,
          }}
          locked={locked}
          label="First Name"
          lockedValue={formik.values.firstName}
        />

        <ProfileField
          isDesktop={isDesktop}
          fieldProps={{
            label: "Last Name",
            name: "lastName",
            type: "text",
            onChange: formik.handleChange,
            value: formik.values.lastName,
            onBlur: handleOnBlur,
          }}
          locked={locked}
          label="Last Name"
          lockedValue={formik.values.lastName}
        />

        <ProfileField
          isDesktop={isDesktop}
          fieldProps={{
            label: "Nickname",
            name: "nickname",
            type: "text",
            onChange: formik.handleChange,
            value: formik.values.nickname,
            onBlur: handleOnBlur,
          }}
          locked={locked}
          label="Nickname"
          lockedValue={formik.values.nickname}
        />

        <ProfileField
          isDesktop={isDesktop}
          fieldProps={{
            label: "Email",
            name: "email",
            type: "text",
            onChange: formik.handleChange,
            value: formik.values.email,
            onBlur: handleOnBlur,
          }}
          locked={locked}
          label="Email address"
          lockedValue={formik.values.email}
        />

        <ProfileOptionField
          isDesktop={isDesktop}
          value={groupNames}
          label="Group"
          locked={true}
        />

        {!isNil(formik.values.primaryGroupId) && (
          <ProfileField
            isDesktop={isDesktop}
            selectProps={{
              label: "Primary Group",
              name: "primaryGroupId",
              // @NOTE: See comment in workLocationId field below
              onChange: (value: number | string) => {
                const safeValue = isNumber(value) ? value : parseInt(value);

                formik.setFieldValue("primaryGroupId", safeValue);
              },
              value: formik.values.primaryGroupId,
              onBlur: handleOnBlur,
              options: primaryGroupOptions,
            }}
            label="PrimaryGroup"
            locked={!canEditGroups}
            lockedValue={getLockedSelectFieldValueFor(
              formik.values.primaryGroupId,
              "group"
            )}
          />
        )}

        {!isNil(formik.values.workLocationId) && (
          <ProfileField
            isDesktop={isDesktop}
            selectProps={{
              label: "Office",
              name: "workLocationId",
              // @NOTE: Formik was setting this value to a string
              // despite our need for a number. We'll use `setFieldValue`
              // and ensure the value is the correct type before
              // updating form values. - Trevor Kirpaul
              onChange: (value: number | string) => {
                const safeValue = isNumber(value) ? value : parseInt(value);

                formik.setFieldValue("workLocationId", safeValue);
              },
              value: formik.values.workLocationId,
              onBlur: handleOnBlur,
              options,
            }}
            locked={locked}
            label="Office"
            lockedValue={getLockedSelectFieldValueFor(
              formik.values.workLocationId,
              "office"
            )}
          />
        )}

        {locked && !isDesktop && <LockedWarning />}

        {isDesktop ? (
          <ButtonRow
            loading={updateUserStatus.loading}
            onSubmit={formik.submitForm}
            onCancel={formik.resetForm}
          />
        ) : (
          <Logout />
        )}
      </S.Form>
    </>
  );
};

export default EditProfileForm;
