import React, {
  forwardRef,
  KeyboardEventHandler,
  MouseEventHandler,
  PropsWithChildren,
} from "react";
import { joinClassNames } from "themes/helpers";
import { useMouseFocus } from "util/hooks/useMouseFocus";
import styles from "./styles.module.scss";

export interface TabClassNames {
  tab?: string;
  tabKeyboardFocused?: string;
  tabSelected?: string;
}

export interface TabProps {
  className?: string;
  variantClassNames?: TabClassNames;
  handleClick: MouseEventHandler<HTMLButtonElement>;
  handleKeyDown: KeyboardEventHandler<HTMLButtonElement>;
  id: string;
  isSelected: boolean;
  tabPanelId: string;
}

export const primaryTabClassNames: TabClassNames = {
  tab: styles.tab,
  tabKeyboardFocused: styles.tabKeyboardFocused,
  tabSelected: styles.tabSelected,
};

export const whiteTabClassNames: TabClassNames = {
  tab: styles.whiteTab,
  tabKeyboardFocused: styles.whiteTabKeyboardFocused,
  tabSelected: styles.whiteTabSelected,
};

export const Tab = forwardRef<HTMLButtonElement, PropsWithChildren<TabProps>>(
  (
    {
      children,
      className,
      handleClick,
      handleKeyDown,
      id,
      isSelected,
      tabPanelId,
      variantClassNames = primaryTabClassNames,
    },
    ref
  ) => {
    const { eventHandlers, isMouseFocused } = useMouseFocus();

    return (
      <button
        aria-controls={tabPanelId}
        aria-selected={isSelected}
        className={joinClassNames(
          variantClassNames.tab,
          !isMouseFocused && variantClassNames.tabKeyboardFocused,
          isSelected && variantClassNames.tabSelected,
          className
        )}
        id={id}
        onClick={handleClick}
        onKeyDown={handleKeyDown}
        ref={ref}
        role="tab"
        tabIndex={isSelected ? undefined : -1}
        type="button"
        {...eventHandlers}
      >
        {children}
      </button>
    );
  }
);
