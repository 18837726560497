import { createSelector } from "reselect";

import { AppState } from "..";
import { OperationalExperience } from "../resources/types";

/** Select OE redux store */
const oeSelector = (store: AppState) => store.operationalExperiences;

/** Select OEs from redux store */
export const selectStoredOEs = createSelector<
  AppState,
  AppState["operationalExperiences"],
  Array<OperationalExperience>
>(oeSelector, (state) => state.oes);

/** Select OE loading status */
export const selectOELoading = createSelector<
  AppState,
  AppState["operationalExperiences"],
  boolean
>(oeSelector, (state) => state.loading);
