import moment from "moment";
import { get } from "lodash";
import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";

import { CreateCloneDocument } from "./types";
import {
  DocumentSummary,
  DocumentVM,
  DisplayConditionDTO,
} from "store/documents/types";
import { createDocument, submitDocument } from "store/newDocument/actions";
import { getDocument } from "store/documents/actions";
import { ThunkDispatch } from "redux-thunk";
import { AppState } from "store";
import { Action } from "redux";
import { DocumentVm, DocumentUpdateVm } from "@rtslabs/field1st-fe-common";
import { SUBMIT_DOCUMENT } from "../../../store/newDocument/types";
import { cloneRelatedDocumentResponses } from "components/helpers/startRelated";
import { generateParticipants } from "fecommon/generateParticipants";

const getDocumentIDs = (selectedDocument?: DocumentSummary | DocumentVM) => ({
  formId: get(selectedDocument, "formSummary.id"),
  parentId: get(selectedDocument, ["id"]),
  workOrderId: get(selectedDocument, ["workOrder", "id"]),
});

interface HandleStartCloneDoc {
  displayConditions: DisplayConditionDTO[];
  formId?: number;
  onSuccess: () => void;
  selectedDocument: DocumentSummary | DocumentVM;
  onError: (error: string) => void;
  isRehuddle: boolean;
}

interface Return {
  handleStartCloneDoc: (args: HandleStartCloneDoc) => void;
}

/**
 * example:
 * const { handleStartCloneDoc } = useStartCloneDoc()
 *
 * handleStartCloneDoc(parentDocument)
 *
 * `handleStartCloneDoc` will create and clone responses to a
 * new Document.
 */
const useStartCloneDoc = (): Return => {
  const dispatch = useDispatch<ThunkDispatch<AppState, void, Action>>();
  const history = useHistory();

  // Creates and returns a new (cloned) document based on the given values
  const createCloneDocument = useCallback(
    async (documentIds: CreateCloneDocument) => {
      const submissionDate = moment()
        .utc()
        .format();
      return await dispatch(
        createDocument({
          ...documentIds,
          submissionDate,
        })
      );
    },
    []
  );

  const handleStartCloneDoc = useCallback(
    async ({
      selectedDocument,
      formId: relatedFormId,
      onSuccess,
      onError,
      isRehuddle,
    }: HandleStartCloneDoc) => {
      const { parentId, formId: rehuddleFormId, workOrderId } = getDocumentIDs(
        selectedDocument
      );
      const formId = isRehuddle ? rehuddleFormId : relatedFormId;

      if (parentId && formId) {
        // Request parent document
        const parentDocumentResponse = await dispatch(getDocument(parentId));

        if (parentDocumentResponse.error) {
          return onError(parentDocumentResponse.error);
        }

        // Create clone document
        const createdCloneDocumentResponse = await createCloneDocument({
          formId,
          parentId,
          workOrderId,
          isRehuddle,
        });

        if (createdCloneDocumentResponse.error) {
          return onError(createdCloneDocumentResponse.error);
        }

        // Responses from 2 API requests above
        const parentDocument: DocumentVm = get(
          parentDocumentResponse,
          "response"
        );
        const newDocument = get(
          createdCloneDocumentResponse,
          "response"
        ) as DocumentVm;

        // Clone cloneable questions
        const responses = cloneRelatedDocumentResponses({
          parentSections: parentDocument.form.sections,
          parentResponses: parentDocument.responses,
          childSections: newDocument.form.sections,
        }).responses;

        // create participants on new clone document
        const participants = generateParticipants(
          [],
          responses,
          newDocument.form.sections
        );

        const documentUpdate: DocumentUpdateVm = {
          ...newDocument,
          responses,
          participants,
        };

        // Submit updated clone document
        const updateRes = await dispatch(
          submitDocument((documentUpdate as unknown) as DocumentVM, "AUTO_SYNC")
        );
        // await dispatch(
        // );

        if (updateRes.response) {
          await dispatch({
            type: SUBMIT_DOCUMENT.HYDRATE,
            payload: updateRes.response,
          });
        }

        // Do this after document submission
        // I kind of feel this is better than going to the actual document, but this is how
        // rehuddle works and requirements aren't clear, so replacing it with `history` change for now -- GK
        // return onSuccess();
        if (newDocument) {
          onSuccess();
          if (history.location.pathname.includes("/document/")) {
            history.replace(`/document/${newDocument.id}`);
          } else {
            history.push(`/document/${newDocument.id}`);
          }
        }
      }
    },
    []
  );

  return {
    handleStartCloneDoc,
  };
};

export default useStartCloneDoc;
