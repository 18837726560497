import React, { Dispatch, SetStateAction } from "react";
import styled from "styled-components";

import DocumentHistory, { DocumentHistoryType } from "./DocumentHistory";
import Drawer from "components/common/Drawer";
import { DocumentVM, DocumentSummary, RelatedDocumentVm } from "store/documents/types";

import StickyHeader from "components/common/Drawer/components/StickyHeader";
import DrawerContentWrapper from "components/common/Drawer/components/DrawerContentWrapper";

const Body = styled.div`
  margin-top: -0.8125rem;
  text-align: left;
`;

export type RelatedDocuments = {
  data: RelatedDocumentVm[];
  isLoading: boolean;
  hasError: boolean;
}

interface Props {
  document?: DocumentVM | DocumentSummary | null;
  isDesktop?: boolean;
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  title?: string;
  historyType?: DocumentHistoryType;
  relatedDocuments?: RelatedDocuments;
}

const DocumentHistorySidebar = ({ 
  document, 
  historyType = "history",
  isDesktop, 
  isOpen, 
  setIsOpen, 
  title = "Document History", 
  relatedDocuments
}: Props) => {
  const handleClose = () => setIsOpen(false);
  return (
    <Drawer
      content={
        <>
          {/* Header */}
          <StickyHeader
            title={title}
            onClick={handleClose}
            chevronRight={false}
          />
          {/* Content */}
          <DrawerContentWrapper>
            {document && (
              <Body>
                <DocumentHistory 
                  document={document} 
                  type={historyType} 
                  relatedDocuments={relatedDocuments}
                />
              </Body>)}
          </DrawerContentWrapper>
        </>
      }
      anchor="right"
      disableBackdrop
      paperProps={{ elevation: 0 }}
      id="DocumentHistory"
      onClose={handleClose}
      open={isOpen}
      overrideClasses={{
        container: "drawerContainerNoBackdrop",
        content: isDesktop ? "myDocumentsDrawerDesktop" : "",
      }}
      showCloseIcon={false}
    />
  );
};

export default DocumentHistorySidebar;
