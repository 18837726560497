import styled from "styled-components";

import TextBase from "components/common/Text";
import { remCalc } from "themes/helpers";
import { Pin } from "components/map";
import mediaQueries from "themes/mediaQueries";

export const LocationContainer = styled.div`
  width: 100%;
`;

export const ClearAssistiveLink = styled.button`
  border: none;
  background: none;
`;

export const ClearLabel = styled(TextBase)`
  color: ${({ theme }) => theme.masterColors.error};
  text-decoration: underline;
  font-size: ${remCalc(14)};

  @media (${mediaQueries.maxTablet}) {
    font-size: ${remCalc(21)};
  }

  @media (${mediaQueries.maxPhone}) {
    font-size: ${remCalc(14)};
  }
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
`;

export type ColumnVariant = "default" | "map-pin";

export const Column = styled.div<{ variant: ColumnVariant }>`
  ${({ variant }) => {
    if (variant === "map-pin") {
      return `
        flex: 5%;
        display: flex;
        justify-content: center;
        align-items: center;

        @media (${mediaQueries.maxTablet}) {
          padding-top: ${remCalc(10)};
        }

        @media (${mediaQueries.maxPhone}) {
          padding-top: 0;
        }
      `;
    }

    if (variant === "default") {
      return `
        width: 100%;
      `;
    }
  }}
`;

Column.defaultProps = {
  variant: "default",
};

export const PinIcon = styled.i<{ variant: "primary" | "emergency" }>`
  color: ${({ theme, variant }) => {
    if (variant === "emergency") {
      return theme.masterColors.error;
    }
    return theme.colors.primary;
  }};
  font-size: ${remCalc(38)};

  @media (${mediaQueries.maxTablet}) {
    font-size: ${remCalc(50)};
  }

  @media (${mediaQueries.maxPhone}) {
    font-size: ${remCalc(38)};
  }
`;

export const EmergencyPin = styled(Pin)`
  height: ${remCalc(45)};
  width: ${remCalc(45)};
  transform: none;
  cursor: initial;
  padding-right: ${remCalc(5)};
`;
