import React from "react";

import Label from "components/common/form/Label";
import * as S from "./styles";
import { AssistiveLink } from "../types";
import { FormikErrors } from "formik";

export type InternalVariantType = "base" | "MultiInput" | "TextArea" | "Select";

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  assistiveLink?: AssistiveLink;
  error?: string | FormikErrors<unknown>;
  helperText?: string;
  inlineLabel?: boolean;
  internalVariant?: InternalVariantType;
  label?: string;
  multiline?: boolean;
  rounded?: boolean;
  rows?: number;
  rowsMax?: number;
  smallLabel?: boolean;
  touched?: boolean;
  variant?: string;
}

function TextInput({
  assistiveLink,
  autoComplete = "off",
  autoFocus,
  className,
  disabled,
  error,
  helperText,
  id,
  inlineLabel,
  internalVariant = "base",
  label,
  multiline,
  name,
  onBlur,
  onChange,
  onFocus,
  onKeyDown,
  placeholder,
  required,
  rounded,
  rows,
  rowsMax,
  type,
  value,
  variant = "outlined",
}: Props) {
  const [isActive, setIsActive] = React.useState(false);

  const handleMouseDown = () => {
    setIsActive(true);
  };

  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    onBlur && onBlur(event);
    setIsActive(false);
  };

  return (
    <S.TextInput name={name} className={className} disabled={disabled}>
      {label && !inlineLabel && (
        <Label
          htmlFor={typeof id === "string" ? id : `${id}`}
          assistiveLink={assistiveLink}
          required={required}
        >
          {label}
        </Label>
      )}

      <S.TextField
        autoComplete={autoComplete}
        autoFocus={autoFocus}
        disabled={disabled}
        error={error}
        internalVariant={internalVariant}
        isActive={isActive}
        label={inlineLabel && null}
        multiline={multiline}
        name={name}
        onBlur={handleBlur}
        onChange={onChange}
        onFocus={onFocus}
        onKeyDown={onKeyDown}
        onMouseDown={handleMouseDown}
        placeholder={placeholder}
        rounded={rounded}
        rows={rows}
        rowsMax={rowsMax}
        type={type}
        value={value}
        variant={variant}
      />
      {helperText && <S.HelperText>{helperText}</S.HelperText>}
      {error && <S.ErrorText>{error}</S.ErrorText>}
    </S.TextInput>
  );
}

export default TextInput;
