import React from "react";

import { FieldErrorType } from "components/FormController/components/Question";
import Label from "components/forms/Label";

import { BaseTextInputProps } from "../TextInput/TextInput";

import * as S from "./styles";
import moment from "moment";
import { SingleResponseQuestionProps } from "../types";

export interface TextAreaProps
  extends Partial<BaseTextInputProps>,
    SingleResponseQuestionProps {
  defaultValue?: string;
  error?: FieldErrorType;
  handleBlur: () => void;
  handleUpdateAnsweredQuestions?: (id: any, values: any) => void;
  id: string | number;
  initialRowsVisible: number;
  maxCharacters?: number;
  maxRowsVisible: number;
  name: string | number;
  onChange?: (e: any) => void;
  required?: boolean; // Used to be boolean | string
}

const TextArea = ({
  error,
  initialRowsVisible,
  maxRowsVisible,
  name,
  placeholder,
  question,
  response,
  setQuestionResponse,
  ...props
}: TextAreaProps) => {
  const isRequired = question.formProperties?.isRequired;

  // @FIXME: need to control state in order for input to actual update value
  // not sure why, this also happens with TextInput
  const [value, setValue] = React.useState((response && response.answer) || "");
  React.useEffect(() => setValue((response && response.answer) || ""), [
    response,
  ]);

  const localHandleOnBlur = (e) => {
    // pass in user defined `onBlur` if avail
    // onBlur && onBlur(e);
    const { value } = e.target;
    setQuestionResponse(
      value && {
        answer: value,
        questionId: question.id,
        questionRootId: question.rootId,
        timeAnswered: moment.utc().format(),
      }
    );
  };
  
  // Helper text
  const helperText = React.useMemo(() => 
    question.properties?.assistiveText || undefined, 
  [question.properties?.assistiveText]);

  return (
    <S.TextArea>
      <Label htmlFor={typeof props.id === "string" ? props.id : `${props.id}`} required={isRequired}>
        {props.label}
      </Label>
      <S.TextInput
        {...props}
        error={error}
        helperText={helperText}
        initialRowsVisible={initialRowsVisible}
        internalVariant="TextArea"
        label="" // hardcode label to "" since we're using Label above
        maxRowsVisible={maxRowsVisible}
        multiline={true}
        name={`${name}`}
        onChange={({ target: { value } }) => setValue(value)}
        onBlur={localHandleOnBlur}
        placeholder={placeholder}
        type="text"
        value={value}
        variant="outlined"
      />
    </S.TextArea>
  );
};

TextArea.defaultProps = {
  maxRows: 4,
  visibleRows: 4,
};

export default TextArea;
