import React, { useState, useEffect } from "react";
import { Action } from "redux";
import { css } from "aphrodite/no-important";
import { ThunkDispatch } from "redux-thunk";
import { useDispatch } from "react-redux";

import { AppState } from "store";
import {
  DataSourceVM,
  DataSource,
  DataSourceResponse,
} from "store/dataSource/types";
import {
  getDataSourcesPage,
  getDataSourceById,
} from "store/dataSource/actions";
import { Select } from "components/common/form/Select";
import Label from "components/common/form/Label";
import TextInputWithSuggestions, {
  TextInputSuggestion,
} from "components/common/form/TextInput/TextInputWithSuggestions";

import propertiesStyles from "../styles";
import { QuestionAnswerSourceDTO } from "store/forms/types";
import { Property as PropertyType } from "components/clientAdmin/formBuilder/types";
import Property from "../Property";
import useDebouncedValue from "util/hooks/useDebouncedValue";
import Loader from "components/common/Loader";

export function mapSourceColumns(dataSource: DataSource) {
  return dataSource.csvMappings.map((map) => ({
    id: map.columnName,
    value: map.columnName,
  }));
}

interface Props {
  onUpdate: (
    dataSet?: DataSource,
    sourceColumn?: string,
    sortBy?: string
  ) => void;
  disabled?: boolean;
  label?: string;
  helperText?: string;
  initialValues?: QuestionAnswerSourceDTO | null;
  detailedSearchProps?: PropertyType[];
  itemPath?: string;
}

const DataSetFields = ({
  initialValues,
  onUpdate,
  disabled,
  label,
  helperText,
  detailedSearchProps,
  itemPath,
}: Props) => {
  const [selectedDataSet, setSelectedDataSet] = useState<
    DataSource | undefined
  >(undefined);
  const [selectedSourceColumn, setSelectedSourceColumn] = useState<
    string | undefined
  >(undefined);
  const [selectedSortBy, setSelectedSortBy] = useState<string | undefined>();
  const [dataSets, setDataSets] = useState<DataSourceVM[]>([]);
  const [dataSetInputValue, setDataSetInputValue] = useState<string>("");
  const [sourceColumns, setSourceColumns] = useState<
    { id: string | number; value: string }[]
  >([]);
  const [loading, setLoading] = useState<boolean>(false);

  const ps = propertiesStyles();

  const dispatch = useDispatch<ThunkDispatch<AppState, void, Action>>();

  const getSourceColumns = async (dataSet: DataSource) => {
    const res: DataSourceResponse = await dispatch(
      getDataSourceById(String(dataSet.id))
    );
    if (res.response) {
      // columnName is always unique
      return mapSourceColumns(res.response);
    }
  };

  const searchDataSets = async (query) => { 
    const res = await dispatch(getDataSourcesPage({ query }));
    if (res.response) {
      const activeSets = res.response.content.filter((ds: DataSourceVM) => ds.status === "PUBLISHED");
      setDataSets(activeSets);
    }
  };

  // fetch the data source on mount to prefill (or clear) the fields
  // @TODO how can we make this not fetch every time
  useEffect(() => {
    if (
      initialValues?.dataSourceKey &&
      initialValues.dataSourceKey !== selectedDataSet?.dataSourceKey
    ) {
      (async () => {
        setLoading(true);
        const res: DataSourceResponse = await dispatch(
          getDataSourceById(String(initialValues.properties?.dataSourceId))
        );
        if (res.response) {
          setSelectedDataSet(res.response);
          setDataSetInputValue(res.response.title);
          const sourceColumns = mapSourceColumns(res.response);
          setSourceColumns(sourceColumns);
          setSelectedSourceColumn(initialValues.properties?.answerField);
          setSelectedSortBy(
            initialValues.properties.detailedSearch?.infiniteListSortBy
          );
        }
        setLoading(false);
      })();
    }
  }, [initialValues]);

  function clearDataSetValues() {
    setSelectedDataSet(undefined);
    setSelectedSourceColumn(undefined);
    setSelectedSortBy(undefined);
    onUpdate(undefined, undefined, undefined);
  }

  const debouncedSearchQuery = useDebouncedValue(dataSetInputValue, 600);

  useEffect(() => {
    if (debouncedSearchQuery.length < 3) {
      if (dataSets.length) {
        setDataSets([]);
      }
    } else if (!selectedDataSet) {
      searchDataSets(debouncedSearchQuery);
    }
  }, [debouncedSearchQuery]);

  async function onSelectSuggestion(dataSet: TextInputSuggestion) {
    setSourceColumns([]);
    setDataSets([]);

    setSelectedDataSet(dataSet as DataSource);
    setDataSetInputValue(dataSet.title);

    // get the selected data source's column mappings
    const dataSourceColumns = await getSourceColumns(dataSet as DataSource);

    let sourceColumn: string | undefined;
    let sortBy: string | undefined;

    if (dataSourceColumns?.length) {
      // if the data source has column mappings, display the source column dropdown
      setSourceColumns(dataSourceColumns);
    } else {
      // if it doesn't, it's a simple data source and we can default the sourceColumn to "value"
      sourceColumn = "value";
      sortBy = "displayOrder,value";
    }
    setSelectedSourceColumn(sourceColumn);
    onUpdate(dataSet as DataSource, sourceColumn, sortBy);
  }

  const showProperties =
    selectedDataSet && sourceColumns.length > 0 && itemPath;

  return (
    <>
      {label && (
        <Label className={css(ps.label)} htmlFor="answerSource">
          {label}
        </Label>
      )}
      <Loader loading={loading}>
        {/* Search data sets */}
        <TextInputWithSuggestions
          autoComplete="off"
          className={css(ps.textInputWithSuggestions)}
          disabled={disabled}
          suggestions={dataSets}
          name="dataSet"
          label="Data set"
          helperText={helperText}
          placeholder="Search data sets by ID or source key"
          onInputChange={(value) => {
            if (selectedDataSet) clearDataSetValues();
            setDataSetInputValue(value);
          }}
          value={dataSetInputValue}
          onSelectSuggestion={onSelectSuggestion}
          labelField="title"
          idField="id"
        />
        {/* Source Column */}
        {selectedDataSet && sourceColumns.length ? (
          <Select
            disabled={disabled}
            options={sourceColumns}
            name="sourceColumn"
            label="Source Column in Data Set"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setSelectedSourceColumn(e.target.value);
              onUpdate(selectedDataSet, e.target.value, selectedSortBy);
            }}
            value={selectedSourceColumn || ""}
          />
        ) : null}
        {/* if a data source is selected, show options to configure the data source drawer */}
        {showProperties &&
          detailedSearchProps?.map((dsp) => {
            if (dsp.name.includes("SortBy")) {
              return (
                <Select
                  disabled={disabled}
                  options={sourceColumns}
                  name="sortBy"
                  label="Sort by"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setSelectedSortBy(e.target.value);
                    onUpdate(
                      selectedDataSet,
                      selectedSourceColumn,
                      e.target.value
                    );
                  }}
                  value={selectedSortBy || ""}
                />
              );
            }
            return (
              <Property
                // @ts-ignore ts thinks itemPath wasn't already checked for -JA
                itemPath={itemPath}
                answerSource={selectedDataSet}
                key={`${itemPath}.${dsp.name}`}
                property={dsp}
              />
            );
          })}
      </Loader>
    </>
  );
};

export default DataSetFields;
