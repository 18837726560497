import styled from "styled-components";

import MQ from "themes/mediaQueries";

export const Form = styled.form`
max-width: 400px;

@media(${MQ.maxTablet}) {
  max-width: 100%;
}

`;
