import React from "react";

import * as S from "components/screens/documents/styles";
import SubmitFeedbackForm from "components/screens/documents/components/SubmitFeedbackSidebar/SubmitFeedbackForm";

export interface SubmitFeedbackSidebarProps {
  submitFeedbackVisible: boolean;
  toggleSubmitFeedbackVisibility: () => void;
}

const SubmitFeedbackSidebar = ({
  submitFeedbackVisible,
  toggleSubmitFeedbackVisibility,
}: SubmitFeedbackSidebarProps) => {
  return (
    <>
      <S.StickySidebarDrawerWrapper>
        <S.CloseDrawerIconWrapper>
          <S.ChevronIcon
            right={false}
            fontSize={"1.5rem"}
            onClick={toggleSubmitFeedbackVisibility}
          />
        </S.CloseDrawerIconWrapper>
        <S.SidebarDrawerHeader>Report Feedback</S.SidebarDrawerHeader>
      </S.StickySidebarDrawerWrapper>
      <S.SidebarDrawerContent>
        <S.SidebarDrawerHeader style={{ marginTop: "-32px" }}>
          Report Feedback
        </S.SidebarDrawerHeader>
        <S.SidebarDrawerBody>
          Your input is important for improving the app. Select a feedback type,
          enter a comment, and our support team will address it.
        </S.SidebarDrawerBody>
        <SubmitFeedbackForm
          submitFeedbackVisible={submitFeedbackVisible}
          toggleSubmitFeedbackVisibility={toggleSubmitFeedbackVisibility}
        />
      </S.SidebarDrawerContent>
    </>
  );
};

export default SubmitFeedbackSidebar;
