import React, { useMemo, useState, useEffect, useCallback } from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { connect, useSelector } from "react-redux";

import * as CAStyles from "components/clientAdmin/styles";
import * as UsersStyles from "components/clientAdmin/users/styles";
import BackToTop from "components/common/TableUI/BackToTop";
import Breadcrumbs from "components/common/Breadcrumbs";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import DefaultCell from "components/common/TableUI/DefaultCell";
import Loader from "components/common/Loader";
import Pagination, {
  PaginationWrapper,
} from "components/common/TableUI/Pagination";
import Popper from "components/common/Popper";
import RowsPerPage from "components/common/TableUI/RowsPerPage";
import TableSummary from "components/common/TableUI/TableSummary";
import UsersSearchBar from "components/filters/UsersSearchBar";
// import { AppState } from "store";
import { ClientGroupStats } from "store/clientGroups/types";
import { Text } from "components/clientAdmin/styles";
import { MoreVertIcon } from "components/common/Card/styles";
import {
  // Pagination as PaginationType,
  SortDirection,
} from "store/common/types";
import { getAllGroupStats } from "store/clientGroups/actions";
import { getExportUrl } from "../helpers";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "components/common/styled/Table";

import * as S from "./styles";
import { contentRoles } from "routes/constants/permissionSets";
import { useRoleAccess } from "util/hooks/useRoleAccess";
import ReadOnlyContent from "components/common/permissions/ReadOnlyContent";
import Link from "components/common/Link";
import { groupsFiltersSelector } from "store/filters/groups/selectors";
import {
  setGroupsFilters,
  setGroupsPagination,
} from "store/filters/groups/actions";

interface GroupsProps extends RouteComponentProps {
  dispatch: any;
}

const Groups = (props: GroupsProps) => {
  const filtersSelect = useSelector(groupsFiltersSelector);
  const { dispatch, history } = props;
  const { filterParams, paginationParams } = filtersSelect;

  // Does user have access to `content`
  const hasAccessToContent: boolean = useRoleAccess(contentRoles);

  // Current page of group stats
  const [groupStats, setGroupStats] = useState<ClientGroupStats[]>([]);

  // Pagination
  const [page, setPage] = useState<number>(paginationParams.page || 0);
  const [size, setSize] = useState<number>(paginationParams.size || 10);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [totalElements, setTotalElements] = useState<number>(0);

  // Group stats table is loading
  const [groupStatsLoading, setGroupStatsLoading] = useState(true);

  // Filter
  const [query, setQuery] = useState(filterParams.query);
  const [searchQuery, setSearchQuery] = useState(filterParams.query);

  const setSearchQueryValue = (value: string) => {
    setSearchQuery(value);
    dispatch(setGroupsFilters({ ...filterParams, query: value }));
  };

  const sortQuery = (paginationParams.sort as string) || "groupName,asc";
  const sorting = sortQuery.split(",");

  const sortBy = (type, direction) => {
    dispatch(
      setGroupsPagination({ ...paginationParams, sort: `${type},${direction}` })
    );
  };

  // Popper context menu
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [anchorGroup, setAnchorGroup] = useState<ClientGroupStats>();
  const [popperIsOpen, setPopperIsOpen] = useState(false);

  // Request groups
  const requestGroups = useCallback(
    (page) => {
      setGroupStatsLoading(true);
      dispatch(
        getAllGroupStats({
          page,
          query: searchQuery,
          size,
          sort: sortQuery,
        })
      ).then((e) => {
        setGroupStatsLoading(false);
        if (e.type === "GET_ALL_GROUP_STATS_SUCCESS" && e.response) {
          const { number, totalElements, totalPages } = e.response;
          setGroupStats(e.response.content);
          setTotalPages(totalPages);
          setTotalElements(totalElements);
          setPage(number);
        }
      });
    },
    [page, searchQuery, sortQuery, size]
  );

  // On filter or size change
  useEffect(() => {
    requestGroups(0);
  }, [size, searchQuery, sortQuery]);

  return (
    <>
      <Popper
        anchorEl={anchorEl}
        onClose={() => {
          // setPopperIsOpen(false);
        }}
        open={popperIsOpen}
        placement="left"
      >
        <UsersStyles.PopperWrapper>
          <ReadOnlyContent>
            <UsersStyles.PopperRow
              onClick={() =>
                history.push(
                  `/people/groups/view/${anchorGroup &&
                    anchorGroup.clientGroupId}`
                )
              }
            >
              <span>View/edit group profile</span>
              <UsersStyles.PopperChevron />
            </UsersStyles.PopperRow>
          </ReadOnlyContent>
          <UsersStyles.PopperRow
            onClick={() =>
              history.push(
                `/reports?group=${anchorGroup && anchorGroup.clientGroupId}`
              )
            }
          >
            <span>View detailed reports</span>
            <UsersStyles.PopperChevron />
          </UsersStyles.PopperRow>

          {hasAccessToContent && (
            <UsersStyles.PopperRow onClick={() => history.push("/forms")}>
              <span>View active forms & templates</span>
              <UsersStyles.PopperChevron />
            </UsersStyles.PopperRow>
          )}
        </UsersStyles.PopperWrapper>
      </Popper>

      <CAStyles.PageContent>
        <CAStyles.TableWrapper>
          {/* 
            @NOTE: ID IS USED FOR "SCROLL TO" / "BACK TO TOP" LOGIC
            @TODO: Update with a better solution - Trevor Kirpaul
          */}
          <div id="mainContent" className="col-12">
            <Breadcrumbs
              paths={[
                {
                  pathName: "People",
                },
                {
                  pathName: "Groups",
                  href: "/people/groups",
                },
              ]}
            />
          </div>
          <UsersStyles.PageHeader>
            <UsersStyles.PageTitle>Groups</UsersStyles.PageTitle>
            <ReadOnlyContent>
              <UsersStyles.PageActionButton
                onClick={() => history.push("/people/groups/add-group")}
              >
                Add group
              </UsersStyles.PageActionButton>
            </ReadOnlyContent>
          </UsersStyles.PageHeader>
          {/* Filters */}
          <div
            style={{
              width: "398px",
              marginTop: "10px",
              marginBottom: "15px",
              padding: "15px",
            }}
          >
            <UsersSearchBar
              onChange={setQuery}
              onSearch={setSearchQueryValue}
              query={query}
            />
          </div>
          <div className="col-12">
            <Loader loading={groupStatsLoading}>
              <>
                <TableSummary
                  pageSize={size}
                  currentPage={page}
                  totalElements={totalElements}
                  ofWhat="groups"
                  exports={["print", "xls", "csv"]}
                  exportUrl={getExportUrl(
                    "client-groups/all-stats",
                    query,
                    `sort=${sortQuery}`
                  )}
                />
                <Table>
                  <TableHead>
                    <TableRow noBottomBorder>
                      <TableCell
                        active={sorting[0] === "groupName"}
                        align="left"
                        minWidth="200px"
                        onClick={(dir) => sortBy("groupName", dir)}
                        sortDirection={
                          sorting[0] === "groupName"
                            ? (sorting[1] as SortDirection)
                            : "asc"
                        }
                      >
                        Group Name
                      </TableCell>
                      <TableCell
                        active={sorting[0] === "teamMembers"}
                        align="center"
                        onClick={(dir) => sortBy("teamMembers", dir)}
                        sortDirection={
                          sorting[0] === "teamMembers"
                            ? (sorting[1] as SortDirection)
                            : "asc"
                        }
                        width="210px"
                      >
                        Team Members
                      </TableCell>
                      <TableCell
                        active={sorting[0] === "feedback"}
                        align="center"
                        onClick={(dir) => sortBy("feedback", dir)}
                        sortDirection={
                          sorting[0] === "feedback"
                            ? (sorting[1] as SortDirection)
                            : "asc"
                        }
                        width="200px"
                      >
                        Feedback
                      </TableCell>
                      <TableCell
                        active={sorting[0] === "submissions"}
                        align="center"
                        onClick={(dir) => sortBy("submissions", dir)}
                        sortDirection={
                          sorting[0] === "submissions"
                            ? (sorting[1] as SortDirection)
                            : "asc"
                        }
                        width="200px"
                      >
                        Submissions
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {groupStats &&
                      groupStats.map((group, i) => (
                        <TableRow key={i}>
                          <TableCell>
                            <ReadOnlyContent
                              component={
                                <Text fontSize={"0.875rem"} fontWeight={400}>
                                  {group.groupName}
                                </Text>
                              }
                            >
                              <Link
                                to={`/people/groups/view/${group.clientGroupId}`}
                              >
                                {group.groupName}
                              </Link>
                            </ReadOnlyContent>
                            <S.GroupId>
                              {`ID: ${group.externalGroupId}` || "-"}
                            </S.GroupId>
                          </TableCell>
                          <TableCell align="center">
                            <DefaultCell
                              color="primary"
                              onClick={() =>
                                history.push(
                                  `/people/users?group=${group.clientGroupId}`
                                )
                              }
                              value={group.teamMembers}
                            />
                          </TableCell>
                          <TableCell align="center">
                            <div className="d-flex flex-row justify-content-center align-items-center">
                              <DefaultCell
                                // Removed link per POW-2269 -- GK
                                // color="primary"
                                // onClick={() => history.push("/feedback")}
                                value={group.feedback}
                              />
                              <i
                                className="icon-icons8-flag"
                                style={{ marginLeft: "10px" }}
                              />
                            </div>
                          </TableCell>
                          <TableCell align="center">
                            <DefaultCell
                              color="primary"
                              onClick={() =>
                                history.push(
                                  `/reports?group=${group.clientGroupId}`
                                )
                              }
                              value={group.submissions}
                            />
                          </TableCell>

                          <TableCell align="center">
                            <ClickAwayListener
                              onClickAway={() => {
                                if (popperIsOpen) {
                                  setPopperIsOpen(false);
                                }
                              }}
                            >
                              <div className="d-flex flex-row justify-content-end">
                                <MoreVertIcon
                                  onClick={(e) => {
                                    setAnchorEl(e.currentTarget);
                                    setAnchorGroup(group);
                                    setTimeout(() => {
                                      setPopperIsOpen(true);
                                    }, 100);
                                  }}
                                />
                              </div>
                            </ClickAwayListener>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>

                {/* // Pagination */}
                <PaginationWrapper>
                  <RowsPerPage
                    pageSize={size}
                    onClick={(size) => {
                      setSize(size);
                    }}
                  />
                  <Pagination
                    currentPage={page}
                    totalPages={totalPages}
                    onClick={(page) => {
                      setPage(page);
                      requestGroups(page);
                    }}
                  />
                  <BackToTop />
                </PaginationWrapper>
              </>
            </Loader>
          </div>
        </CAStyles.TableWrapper>
      </CAStyles.PageContent>
    </>
  );
};

const mapStateToProps = () => {
  return {};
};

export default withRouter(connect(mapStateToProps)(Groups));
