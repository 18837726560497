import * as React from "react";
import moment from "moment";

import useGroupTerm from "util/hooks/whiteLabel/useGroupTerm";
import { FormStats } from "store/reporting/singleFormStats/types";
import { Row } from "../../styles";
import { StatsCard } from "components/common/StatsCard";

interface StatisticsProps {
  formStats: FormStats;
}

const Statistics = ({ formStats }: StatisticsProps) => {
  const {
    averageCrewSize,
    averageOEsViewed,
    averageStartTime,
    averageTimeToComplete,
    submissionsWithWorkOrders,
    totalSubmissions,
  } = formStats;

  // averageTimeToComplete: seconds since start of day
  // averageStartTime: average seconds between start and submission

  // @isDST() was necessary because time was off by an hour. At this time, time is off again so BE is requesting
  // that the DST check is removed. If this continues to be an issue and further changes are required in the future,
  // BE values would need to be re-checked for consistency and FE formulas sync'ed and documented in requirements -- GK
  // const _averageStartTime = averageStartTime
  //  && moment().startOf("day").subtract(moment().isDST() ? 1 : 0, "hours").add(averageStartTime, "seconds")
  //   .add(moment().utcOffset(), "minutes") || 0;

  const _averageStartTime =
    (averageStartTime &&
      moment()
        .startOf("day")
        .add(averageStartTime, "seconds")
        .add(moment().utcOffset(), "minutes")) ||
    0; // See above
  const startTime =
    (_averageStartTime && _averageStartTime.format("HH:mm")) || "NA";
  const startTimeName =
    (_averageStartTime && _averageStartTime.format("a")) || "";

  // Group config terms
  const workOrderTerm = useGroupTerm(
    "workOrder",
    "noun",
    undefined,
    "Work Order"
  );

  return (
    <>
      <Row>
        <StatsCard
          header="Total Submissions"
          height="101px"
          iconName={formStats.form.type.iconName}
          iconColor={formStats.form.type.iconColor}
          stretch
          value={totalSubmissions}
        />
        <StatsCard
          header="Average Time To Complete"
          height="101px"
          stretch
          value={averageTimeToComplete}
          type="time"
        />
        <StatsCard
          header="Start Time"
          height="101px"
          stretch
          value={startTime}
          valueName={startTimeName}
        />
      </Row>
      <Row>
        <StatsCard
          header="Crew Size"
          height="101px"
          stretch
          value={Math.round(averageCrewSize)}
          valueName="Average"
        />
        <StatsCard
          header="Operational Experiences Viewed"
          height="101px"
          stretch
          value={Math.round(averageOEsViewed)}
          valueName="Average"
        />
        <StatsCard
          header={`${workOrderTerm} vs Blank`}
          height="101px"
          stretch
          value={submissionsWithWorkOrders}
          valueName={`out of ${totalSubmissions}`}
        />
      </Row>
    </>
  );
};

export default Statistics;
