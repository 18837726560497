import React from "react";
import { css } from "aphrodite/no-important";
import { FastField, Field, useFormikContext } from "formik";
import { get } from "lodash";

import { DataSource } from "store/dataSource/types";
import { QuestionAnswerSourceDTO } from "store/forms/types";
import ColorPicker from "components/common/form/ColorPicker";
import RichTextEditor from "components/common/RichTextEditor";
import Checkbox from "components/common/form/Checkbox";
import MultiInput from "components/common/form/MultiInput";
import TextInput from "components/common/form/TextInput";
import Select from "components/common/form/Select";

import { FBForm, Property as PropertyType, FBItem } from "../../types";
import AnswerSource from "./customFields/AnswerSource";
import CommentsRequiredFor from "./customFields/CommentsRequiredFor";
import DisplayConditions from "./customFields/DisplayConditions/DisplayConditions";
import LocationProperties from "./customFields/LocationProperties";
import PrefillFromWorkOrder from "./customFields/PrefillFromWorkOrder/PrefillFromWorkOrder";
import styles from "./styles";
import { NewAnswerSource } from "store/builder/types";
import useDebounce from "util/hooks/useDebounce";
import AllowedSignatureTypes from "./customFields/AllowedSignatureTypes";
import { Notifications } from "./customFields/Notifications";
import { isFormPublished } from "../../helpers";

interface Props {
  itemPath: string;
  property: PropertyType;
  dataSource?: DataSource;
  item: FBItem;
}

const Property = ({ item, itemPath, property, dataSource }: Props) => {
  const { values, setFieldValue, errors, touched } = useFormikContext<FBForm>();
  const propertyPath = `${itemPath}.${property.name}`;
  const workflowPath = `${itemPath}.workflowType`;
  // local state for text inputs, prevents rerender on every keystroke
  const [localStringVal, setLocalStringVal] = React.useState<string>(
    property.type === "TEXT_LINE" ? get(values, propertyPath, "") : ""
  );
  const isPublished = isFormPublished(values.workflowType);

  const s = styles();

  const debouncedSetValue = useDebounce({
    method(path: string, value: unknown): void {
      setFieldValue(path, value, false);
      setFieldValue(workflowPath, "DRAFT", false);
    },
    delayAmount: 1500,
  });

  const limitLengthSetValue = (value: string, limit?: number) => {
    const _limit = limit || 255;
    if (value?.length > _limit) {
      // Check for value still
      return value.substring(0, _limit);
    }
    return value;
  };
  switch (property.type) {
    case "CHECKBOX":
      return (
        <div>
          <Field
            as={Checkbox}
            containerClassName={css(s.checkboxContainer)}
            className={css(s.checkbox)}
            name={propertyPath}
            helperText={property.helperText}
            label={property.label}
            checked={get(values, propertyPath, false)}
            error={get(errors, propertyPath)}
            touched={get(touched, propertyPath)}
            onChange={(e) => {
              setFieldValue(
                propertyPath,
                !get(values, propertyPath, false),
                false
              );
            }}
            disabled={isPublished}
          />
        </div>
      );
    case "DROP_DOWN":
      return (
        <FastField
          as={Select}
          wrapperClassName={css([
            s.dropdown,
            property.appendLabel ? s.dropdownLabelAppended : "",
          ])}
          onChange={(e) => {
            let value = e.target.value;
            if (value === -1) {
              value = null;
            }
            setFieldValue(propertyPath, value, false);
            setFieldValue(workflowPath, "DRAFT", false);
          }}
          value={get(values, propertyPath) || -1}
          name={propertyPath}
          helperText={property.helperText}
          label={property.label}
          options={property.options}
          error={get(errors, propertyPath)}
          touched={get(touched, propertyPath)}
          disabled={isPublished}
        />
      );
    case "COLOR_PICKER":
      return (
        <FastField
          as={ColorPicker}
          id={propertyPath}
          withText
          label={property.label}
          name={propertyPath}
          placeholder={property.label}
          colorValue={get(values, propertyPath, "")}
          onColorChange={(value: string) =>
            debouncedSetValue(propertyPath, value)
          }
          disabled={isPublished}
        />
      );
    case "CONTENT_BLOCK":
      return (
        <FastField
          as={RichTextEditor}
          className={css(s.richTextEditor)}
          initialContent={get(values, propertyPath, "")}
          label={property.label}
          name={propertyPath}
          value={get(values, propertyPath, "")}
          onChangeContent={(value: string) => {
            setFieldValue(propertyPath, value, false);
            setFieldValue(workflowPath, "DRAFT", false);
          }}
          placeholder={property.label}
          disabled={isPublished}
        />
      );
    case "DATA_SOURCE":
      return (
        <FastField
          label={property.label}
          as={AnswerSource}
          name={`${itemPath}.answerSource`}
          onChange={(
            answerSource: QuestionAnswerSourceDTO | NewAnswerSource | null
          ) => {
            setFieldValue(`${itemPath}.answerSource`, answerSource, false);
            setFieldValue(workflowPath, "DRAFT", false);
          }}
          property={property}
          itemPath={itemPath}
          initialValues={item.answerSource}
          disabled={isPublished}
        />
      );
    case "LOCATION":
      return (
        <LocationProperties
          property={property}
          itemPath={itemPath}
          disabled={isPublished}
        />
      );
    case "MULTI_INPUT":
      return (
        <FastField
          as={MultiInput}
          className={css(s.multiInput)}
          name={propertyPath}
          helperText={property.helperText}
          label={property.label}
          placeholder={property.label}
          error={get(errors, propertyPath)}
          touched={get(touched, propertyPath)}
          disabled={isPublished}
        />
      );
    case "PREFILL_FROM_WORK_ORDER": // @TODO display errors
      return (
        <PrefillFromWorkOrder
          property={property}
          item={item as FBItem}
          itemPath={itemPath}
        />
      );
    case "COMMENTS_REQUIRED_FOR": // @TODO display errors
      const isSafetyWidget =
        item.type === "WIDGET" && item.subType === "SAFETY_RATING";
      if (
        !isSafetyWidget &&
        get(values, `${itemPath}.${property.displayCondition}`)
      ) {
        return <CommentsRequiredFor property={property} itemPath={itemPath} />;
      }
      return null;
    case "NOTIFICATIONS":
      return <Notifications property={property} itemPath={itemPath} />;
    case "ALLOWED_SIGNATURE_TYPES": // @TODO display errors
      return <AllowedSignatureTypes property={property} itemPath={itemPath} />;
    case "TEXT_LINE":
      return (
        <Field
          as={TextInput}
          error={get(errors, propertyPath)}
          touched={get(touched, propertyPath)}
          className={css(s.textInput)}
          name={propertyPath}
          value={localStringVal}
          helperText={property.helperText}
          label={property.label}
          placeholder={property.label}
          onChange={(e) => {
            const value = limitLengthSetValue(e.target.value);
            setLocalStringVal(value);
          }}
          onBlur={(e) => {
            setFieldValue(propertyPath, localStringVal, false);
            setFieldValue(workflowPath, "DRAFT", false);
          }}
          disabled={isPublished}
        />
      );
    case "DISPLAY_CONDITIONS": // @TODO display errors
      if (item.type === "QUESTION") {
        return (
          <DisplayConditions
            property={property}
            itemPath={itemPath}
            item={item}
            dataSource={dataSource}
          />
        );
      }
      return null;
    default:
      return (
        <div style={{ marginTop: "10px" }}>
          Field type {property.type} not found
        </div>
      );
  }
};

export default Property;
