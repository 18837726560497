import React from "react";

import Select from "components/common/form/Select";
import DesktopLabel from "../../DesktopLabel";

import { DesktopSelectField as Props } from "../../../types";

const SelectField = (props: Props) => {
  return (
    <>
      <DesktopLabel label={props.label} name={props.name} />

      <Select {...props} label="" onChange={(e) => props.onChange(e.target.value)} />
    </>
  );
};

export default SelectField;
