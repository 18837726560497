import { FilterParams, PaginationParams } from "store/common/types";

export const REPORTS_FILTERS = {
  SET: "REPORTS_FILTERS_SET",
  PARAMS_SET: "REPORTS_FILTERS_PARAMS_SET",
};

export interface ReportsFiltersParams extends FilterParams {
  groupId: number;
  documentType: number;
  timeFilterType: string;
  dateRange: string | null;
  subordinatesFilter: string;
  query: string;
}

export interface ReportsFiltersArgs {
  filterParams: ReportsFiltersParams;
  paginationParams: PaginationParams;
}

export interface SetReportsFiltersAction {
  type: typeof REPORTS_FILTERS.SET;
  response: ReportsFiltersParams;
}

export interface SetReportsPaginationAction {
  type: typeof REPORTS_FILTERS.PARAMS_SET;
  response: PaginationParams;
}

export type FiltersActions = SetReportsFiltersAction;
