import React, { useMemo } from "react";
import { css } from "aphrodite/no-important";

import Label from "components/common/form/Label";

import styles from "./styles";
import { Input } from "../types";
import { HelperText } from "../TextInput/styles";
import { useMouseFocus } from "util/hooks/useMouseFocus";
import { QuestionDTO } from "store/forms/types";

interface Props extends Input {
  question?: QuestionDTO;
  type: "date" | "datetime-local" | "time";
}

/** Date picker input element */
export function DateTime({
  required,
  error,
  helperText,
  label,
  name,
  value,
  type,
  question,
}: Props) {
  const _required = question?.formProperties?.isRequired || required;
  const { eventHandlers, isMouseFocused } = useMouseFocus();
  const s = styles(isMouseFocused);

  // Helper text
  const _helperText = useMemo(
    () => helperText || question?.properties?.assistiveText || undefined,
    [helperText, question?.properties?.assistiveText]
  );

  return (
    <div className={css(s.wrapper)}>
      {label && (
        <Label htmlFor={name || ""} required={_required}>
          {label}
        </Label>
      )}
      {_helperText && (
        <div style={{ paddingBottom: 10, marginLeft: -15 }}>
          <HelperText>{_helperText}</HelperText>
        </div>
      )}
      <input
        className={css(s.input)}
        type={type}
        name={name}
        value={value}
        {...eventHandlers}
      />
      {error && <span className={css(s.errorText)}>{error}</span>}
    </div>
  );
}
