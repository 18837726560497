import React from "react";
import styled from "styled-components";
import { debounce } from "lodash";

import BaseSearchBar from "components/filters/BaseSearchBar";
import { useDispatch, useSelector } from "react-redux";
import { tagLibraryFiltersSelector } from "store/filters/tagLibrary/selectors";
import { ThunkDispatch } from "redux-thunk";
import { AppState } from "store";
import { Action } from "redux";
import { setTagLibraryFilters } from "store/filters/tagLibrary/actions";

const FiltersContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  margin: 1rem 0;
  width: "398px";
`;

const initialFilters = {
  query: "",
};

type Props = {
  onUpdateFilters: (filters: typeof initialFilters) => void;
};

const TagFilters = ({ onUpdateFilters }: Props) => {
  const filtersSelect = useSelector(tagLibraryFiltersSelector);
  const dispatch = useDispatch<ThunkDispatch<AppState, void, Action>>();
  const { filterParams } = filtersSelect;

  const updateFilters = (filter: string, value: string) => {
    const newFilters = {
      ...filterParams,
      [filter]: value,
    };
    dispatch(setTagLibraryFilters(newFilters));
    onUpdateFilters(newFilters);
  };

  const handleSearch = debounce(
    (query: string) => updateFilters("query", query),
    500
  );

  return (
    <FiltersContainer>
      <BaseSearchBar
        onSearch={handleSearch}
        initialQuery={filterParams.query}
      />
    </FiltersContainer>
  );
};

export default TagFilters;
