import * as React from "react";
import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { useDispatch } from "react-redux";

import { FormStats } from "store/reporting/singleFormStats/types";
import { DocumentSummary } from "store/documents/types";

import { Tabs, Tab, TabPanel, tabA11yProps } from "components/common/Tabs";

import DocumentsTable, { DocumentsPopperProps, DocumentsSortingProps, DocumentsPaginationProps } from "./DocumentsTable";
import FormReportSummary from "./ResponseSummaries";
import SafetyReport from "./SafetyObservationReport/SafetyReport";

import { MappedResponses } from "./helpers";
import VersionHistoryTable from "./VersionHistoryTable";
import { AppState } from "store";
import { FormVersionResponse, GetFormVersionActionType, FormVersionDTO } from "store/versionHistory/types";
import { getFormVersion } from "store/versionHistory/actions";

interface FormTabsProps {
  minMaxDate: {
    minSubmissionDate: string;
    maxSubmissionDate: string;
  };
  formId: number;
  formStats: {
    data: FormStats | null;
    loading: boolean;
    error: boolean;
  };
  documents: DocumentSummary[];
  handleTabChange: (event: any, newValue: number) => void;
  onPaginate: (pageNum: number, numPerPage: number) => void;
  pagination: DocumentsPaginationProps;
  popper: DocumentsPopperProps;
  safetyReportProps: {
    totalResponses: number;
    ratingsFilter: string[];
    mappedRiskResponses: MappedResponses;
  };
  shouldRenderSafetyTab: boolean;
  sorting: DocumentsSortingProps;
  value: number;
}
const FormTabs = ({
  documents,
  formId,
  formStats,
  handleTabChange,
  minMaxDate,
  onPaginate,
  pagination,
  popper,
  safetyReportProps,
  shouldRenderSafetyTab,
  sorting,
  value,
}: FormTabsProps) => {
  const [formVersionLoading, setFormVersionLoading] = React.useState<boolean>(false);
  const [formVersionContent, setFormVersionContent] = React.useState<FormVersionDTO[]>([]);

  const dispatch = useDispatch<ThunkDispatch<AppState, void, Action>>();

  // Fetch version history for current form
  const fetchVersionHistory = React.useCallback(async () => {
    setFormVersionLoading(true);
    const res: {
    type: GetFormVersionActionType;
    response: FormVersionResponse;
  } = await dispatch(getFormVersion(formId));
    if (res?.type === GetFormVersionActionType.success) {
    // success
      setFormVersionContent(res.response);
    } else {
    // error
      setFormVersionContent([]);
    }
  
    setFormVersionLoading(false);
  }, [formId]);

  // On mount
  React.useEffect(() => {
    fetchVersionHistory();
  }, [fetchVersionHistory]);



  // This is set up weird, will merge if there's time -- GK
  if (shouldRenderSafetyTab) {
    return (
      <>
        <Tabs indicatorColor="primary" value={value} onChange={handleTabChange}>
          <Tab label="Safety Report" {...tabA11yProps(0)}></Tab>
          <Tab label="Summary" {...tabA11yProps(1)}></Tab>
          <Tab label="Details" {...tabA11yProps(2)}></Tab>
          {/* <Tab label="Version History" {...tabA11yProps(3)}></Tab> */}
        </Tabs>
        <TabPanel value={value} index={0}>
          <SafetyReport formId={formId} minMaxDate={minMaxDate} {...safetyReportProps} />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <FormReportSummary 
            formId={formId} 
            minMaxDate={minMaxDate} 
            formStats={formStats} 
            visible={value === 0} 
            switchToHistory={() => handleTabChange(undefined, 3)}
          />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <DocumentsTable documents={documents} onPaginate={onPaginate} pagination={pagination} popper={popper} sorting={sorting}/>
        </TabPanel>
        {/* <TabPanel value={value} index={3}>
          <VersionHistoryTable
            content={formVersionContent}
            loading={formVersionLoading}
          />
        </TabPanel> */}
      </>
    );
  }
  return (
    <>
      <Tabs value={value} onChange={handleTabChange}>
        <Tab label="Summary" {...tabA11yProps(0)} />
        <Tab label="Details" {...tabA11yProps(1)} />
        {/* <Tab label="Version History" {...tabA11yProps(2)}></Tab> */}
      </Tabs>
      <TabPanel value={value} index={0}>
        <FormReportSummary 
          formId={formId} 
          formStats={formStats} 
          switchToHistory={() => handleTabChange(undefined, 2)}
          visible={value === 0} 
        />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <DocumentsTable documents={documents} onPaginate={onPaginate} pagination={pagination} popper={popper} sorting={sorting} />
      </TabPanel>
      {/* <TabPanel value={value} index={2}>
        <VersionHistoryTable 
          content={formVersionContent} 
          loading={formVersionLoading}
        />
      </TabPanel> */}
    </>
  );
};

export default FormTabs;
