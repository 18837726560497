import React, { useState, useMemo, useEffect } from "react";
import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";

import { AppState } from "store";
import { BuilderFormSummaryVM } from "store/builder/types";
import { cloneForm, putWorkflowType } from "store/builder/actions";
import { ContentWrapper } from "components/common/Wrappers";
import { FilterSelect } from "components/common/FilterSelect";
import { FormTypeDTO, WorkflowType } from "store/forms/types";
import { getFBSummaries, getFormTypes } from "store/builder/actions";
import { getUserGroups } from "store/user/selectors";
import { H1 } from "components/clientAdmin/styles";
import { SortParams } from "store/common/types";
import BackToTop from "components/common/TableUI/BackToTop";
import BaseSearchBar from "components/filters/BaseSearchBar";
import Breadcrumbs from "components/common/Breadcrumbs";
import Button from "components/common/Button";
import Loader from "components/common/Loader";
import Pagination, {
  PaginationWrapper,
} from "components/common/TableUI/Pagination";
import RowsPerPage from "components/common/TableUI/RowsPerPage";
import TableSummary from "components/common/TableUI/TableSummary";
import useDebounce from "util/hooks/useDebounce";

import { FormListModal } from "./FormListModal";
import { FormListTable } from "./FormListTable";
import S from "./styles";
import { ConfirmationModal } from "./ConfirmationModal";
import ReadOnlyContent from "components/common/permissions/ReadOnlyContent";
import Toast from "components/common/Toast";
import { formTemplatesFiltersSelector } from "store/filters/formTemplates/selectors";
import { setFormTemplatesFilters } from "store/filters/formTemplates/actions";

interface Option {
  value: string;
  id: number;
}

const allGroupsOption = { value: "All Groups", id: -1 };
const allTypesOption = { value: "All Types", id: -1 };

function FormList() {
  const history = useHistory();
  const filterSelect = useSelector(formTemplatesFiltersSelector);
  const dispatch = useDispatch<ThunkDispatch<AppState, void, Action>>();
  const accessibleGroups = useSelector(getUserGroups);
  const { filterParams, paginationParams } = filterSelect;

  /* loading statuses */
  const [loading, setLoading] = useState<boolean>(false);
  const [workflowTypeLoading, setWorkflowTypeLoading] = useState<boolean>(
    false
  );

  /* modals */
  const [createModalOpen, setCreateModalOpen] = useState<boolean>(false);
  const [modalDesiredWorkflowType, setModalDesiredWorkflowType] = useState<
    WorkflowType | undefined
  >(undefined);

  /* form and sort data */
  const [forms, setForms] = useState<BuilderFormSummaryVM[]>([]);
  const [currSummary, setCurrSummary] = useState<
    BuilderFormSummaryVM | undefined
  >();
  const [sort, setSort] = useState<SortParams>(["name", "asc"]);
  const [groupOptions, setGroupOptions] = useState<Option[]>([
    { value: "All Groups", id: -1 },
  ]);
  const [formTypes, setFormTypes] = useState<FormTypeDTO[]>([]);
  const [page, setPage] = useState<number>(0);
  const [size, setSize] = useState<number>(10);
  const [totalEl, setTotalEl] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [query, setQuery] = useState<string>(filterParams.query);
  const [searched, setSearched] = useState<boolean>(false);

  const [error, setError] = useState<boolean>(false);
  const [toastText, setToastText] = useState<string>("");

  /** Map form types to options for filters + FormListModal */
  const formTypeOptions = formTypes.map((type) => ({
    value: type.name,
    id: type.id,
  }));

  /** Format group filter options on mount */
  useMemo(() => {
    const formattedGroups = accessibleGroups.map((g) => ({
      value: g.name || "",
      id: g.id || -1,
    }));
    setGroupOptions([allGroupsOption, ...formattedGroups]);
  }, []);

  const setGroupId = (groupId: number) => {
    dispatch(setFormTemplatesFilters({ ...filterParams, groupId }));
  };

  const setFormType = (formType: number) => {
    dispatch(setFormTemplatesFilters({ ...filterParams, formType }));
  };

  const setQueryValue = (q: string) => {
    dispatch(setFormTemplatesFilters({ ...filterParams, query: q }));
    setQuery(q);
  };

  // TODO getAndSetForms is always one state mutation behind, can be improved -jA
  /** Search for forms using sort state and possible supplied query */
  const getAndSetForms = async (q?: string) => {
    setLoading(true);
    const res = await dispatch(
      getFBSummaries({
        sort: `${sort[0]},${sort[1]}`,
        clientGroupIds:
          filterParams.groupId && filterParams.groupId !== -1
            ? filterParams.groupId.toString()
            : "",
        page,
        size,
        formTypeId:
          filterParams.formType > -1 ? filterParams.formType : undefined,
        query: q || (query && query.length > 2 ? query : undefined),
      })
    );
    if (res.response) {
      setTotalEl(res.response.totalElements);
      setTotalPages(res.response.totalPages);
      setForms(res.response.content);
      setLoading(false);
    }
  };

  /**
   * Get a list of all available form types for creating a new form
   */
  const getAndSetFormTypes = async () => {
    const res = await dispatch(getFormTypes());
    if (res.response) {
      setFormTypes(res.response);
    }
  };

  /** Fetch forms on mount and on sort change */
  useEffect(() => {
    getAndSetForms();
  }, [page, size, sort, filterParams.groupId, filterParams.formType]);

  /** Get available form types on mount */
  useEffect(() => {
    getAndSetFormTypes();
  }, []);

  const debouncedSearch = useDebounce({
    async method(q: string) {
      dispatch(setFormTemplatesFilters({ ...filterParams, query: q }));
      await getAndSetForms(q);
      setSearched(true);
    },
    delayAmount: 1000,
  });

  useEffect(() => {
    if (query && query.length > 2) {
      debouncedSearch(query);
    } else if (searched) {
      (async () => {
        await getAndSetForms();
        setSearched(false);
      })();
    }
  }, [query]);

  /**
   * Clone a form with given id
   * @param id
   */
  const clone = async (id: number) => {
    setToastText("");
    setError(false);
    const res = await dispatch(cloneForm(id));
    if (res.error) {
      setToastText(`Form cannot be cloned: ${res.error}`);
      setError(true);
    } else if (res.response?.id) {
      history.push(`/forms/form/${res.response.id}`);
    }
  };

  /** Deactivate a form corresponding with formId in state */
  async function setWorkflowType(workflowType: WorkflowType) {
    const formId = currSummary?.id || -1;
    if (formId > -1) {
      setWorkflowTypeLoading(true);
      const res = await dispatch(putWorkflowType(formId, workflowType));
      if (res.response) {
        getAndSetForms();
      }
    }
    setWorkflowTypeLoading(false);
    setModalDesiredWorkflowType(undefined);
    return false;
  }

  return (
    <ContentWrapper id="mainContent">
      <FormListModal
        modalOpen={createModalOpen}
        closeModal={() => setCreateModalOpen(false)}
        formTypeOptions={formTypeOptions}
        formTypes={formTypes}
        history={history}
      />
      <ConfirmationModal
        modalOpen={!!modalDesiredWorkflowType}
        desiredWorkflowType={
          modalDesiredWorkflowType || currSummary?.workflowType || "DRAFT"
        }
        closeModal={() => setModalDesiredWorkflowType(undefined)}
        setWorkflowType={setWorkflowType}
        loading={workflowTypeLoading}
      />
      <Breadcrumbs paths={[{ pathName: "Forms" }]} />
      <S.TitleWrapper>
        <H1>Forms</H1>
        <ReadOnlyContent>
          <Button onClick={() => setCreateModalOpen(true)}>
            CREATE NEW FORM
          </Button>
        </ReadOnlyContent>
      </S.TitleWrapper>
      <S.FilterWrapper>
        <S.SelectWrapper>
          <FilterSelect
            name="groupId"
            value={filterParams.groupId || ""}
            label=""
            options={groupOptions}
            handleChange={({ target: { value } }) => {
              setPage(0);
              setGroupId(value);
            }}
          />
        </S.SelectWrapper>
        <S.SelectWrapper>
          <FilterSelect
            name="formType"
            value={filterParams.formType || ""}
            label=""
            options={[allTypesOption, ...formTypeOptions]}
            handleChange={(e) => {
              setPage(0);
              setFormType(e.target.value);
            }}
          />
        </S.SelectWrapper>
        <BaseSearchBar onSearch={setQueryValue} initialQuery={query} />
      </S.FilterWrapper>
      {/* TODO exports */}
      <TableSummary
        pageSize={size}
        currentPage={page}
        totalElements={totalEl}
        ofWhat="forms"
        exports={["print", "xls", "csv"]}
        exportUrl="builder/forms"
      />
      <Loader loading={loading}>
        <FormListTable
          sort={sort}
          setSort={setSort}
          forms={forms}
          onCreateNew={setCreateModalOpen}
          setDesiredWorkflowType={setModalDesiredWorkflowType}
          setCurrSummary={setCurrSummary}
          cloneForm={clone}
          history={history}
        />
      </Loader>
      {!loading && (
        <PaginationWrapper>
          <RowsPerPage
            pageSize={size || 10}
            onClick={(size) => {
              setPage(0);
              setSize(size);
            }}
          />
          <Pagination
            currentPage={page}
            totalPages={totalPages}
            onClick={(page) => setPage(page)}
          />
          <BackToTop />
        </PaginationWrapper>
      )}
      <Toast
        variant="error"
        visible={error}
        onClick={() => {
          setError(false);
          setToastText("");
        }}
      >
        {toastText}
      </Toast>
    </ContentWrapper>
  );
}

export default FormList;
