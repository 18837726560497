import React, { PropsWithChildren } from "react";
import { Link as BaseLink } from "react-router-dom";

import { joinClassNames } from "themes/helpers";

interface LinkClassNames {
  link?: string;
  linkDisabled?: string;
}

export interface LinkProps {
  className?: string;
  classNames?: LinkClassNames;
  disabled?: boolean;
  to: string;
}

export const Link = ({
  children,
  className,
  classNames = {
    link: "Link",
    linkDisabled: "Link--disabled",
  },
  disabled,
  to,
}: PropsWithChildren<LinkProps>) => {
  return disabled ? (
    <span className={joinClassNames(classNames.linkDisabled, className)}>
      {children}
    </span>
  ) : (
    <BaseLink className={joinClassNames(classNames.link, className)} to={to}>
      {children}
    </BaseLink>
  );
};

export const TextLink: React.FC<LinkProps> = (props) => {
  return (
    <Link
      classNames={{ link: "TextLink", linkDisabled: "TextLink--disabled" }}
      {...props}
    />
  );
};
