import * as R from "ramda";
import React, { useEffect, useState } from "react";
import moment from "moment";
import { withRouter } from "react-router-dom";
import { bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";
import { get } from "lodash";

import { AppState } from "store";
import { DataSourceState } from "store/dataSource/types";
import {
  DocumentQuestionResponseVm,
  GetDocumentResponse,
  DocumentFormActionRequestsVm,
  DocumentVM,
  DocumentParticipant,
} from "store/documents/types";
import {
  ClearStatusActionTypes,
  ClearStatusPayloadTypes,
  NewDocumentState,
} from "store/newDocument/types";
import { ParticipantState } from "store/participants/types";
import * as dataSourceActions from "store/dataSource/actions";
import * as newDocumentActions from "store/newDocument/actions";
import * as documentsActions from "store/documents/actions";
import * as operationalExperienceActions from "store/operationalExperiences/actions";
import FormHeader from "components/forms/FormHeader";
import Loader from "components/common/Loader";
import Toast from "components/common/Toast";
import DocumentSidebarDrawer from "components/DocumentSidebarDrawer";

import * as S from "./styles";
import FormController from "../FormController";

import { getUniqueAnswerSources, shouldRenderLocked } from "./helpers";
import ReadOnlyDoc from "./ReadOnly";
import { OperationalExperience } from "../../store/resources/types";
import { FormAction, QuestionDTO } from "store/forms/types";
import { QuestionAnswerSourceDTO } from "store/forms/types";
import { ClientGroupConfig } from "store/clientConfigs/types";
import { handleNoun } from "util/hooks/whiteLabel/useGroupTerm";
import { RehuddleInfo } from "components/FormController/components/RehuddleInfo";
import { getAppConfig } from "store/appConfigs/actions";
import { ClientOverridesConfig } from "store/appConfigs/types";
import { SubmissionType } from "store/newDocument/actions";
import { currentUser } from "store/user/selectors";
import { UsersMe } from "store/user/types";
import { remCalc } from "../../themes/helpers";
import { useHistory, useLocation } from "react-router";
import { usePutDocument } from "./usePutDocument";
import { useAsyncEffect } from "@rtslabs/field1st-fe-common";

const redirectDuration = 4000;

export type DocumentFormValuesType = {
  responses: Array<DocumentQuestionResponseVm>;
  operationalExperiences?: Array<OperationalExperience>;
};

export interface DocumentProps {
  match: {
    params: {
      documentId?: number;
    };
  };
  withMap?: boolean;
  store: {
    newDocument: NewDocumentState;
    participants: ParticipantState;
    dataSource: DataSourceState;
    clientOverrides: ClientOverridesConfig;
    currentUser: UsersMe;
  };
  actions: {
    getAppConfig: (keyName: string) => Promise<void>;
    operationalExperiences: typeof operationalExperienceActions;
    newDocument: {
      uploadNewDocument: ({
        id,
        responses,
        documentParticipants,
        submissionType,
        operationalExperiences,
        formActionRequests,
      }: {
        id?: number;
        responses: Array<DocumentQuestionResponseVm>;
        documentParticipants: Array<DocumentParticipant>;
        submissionType: newDocumentActions.SubmissionType;
        operationalExperiences: Array<OperationalExperience>;
        formActionRequests?: Array<DocumentFormActionRequestsVm>;
      }) => Promise<void>;
      /**
       * This action helps us clear a boolean value for
       * the specified feature within the specified status type
       *
       * ie: you can clear the error flag, success flag, or loading flag
       * for the feature passed in as the payload
       */
      clearStatus: ({
        actionType,
        payload,
      }: {
        actionType: ClearStatusActionTypes;
        payload: ClearStatusPayloadTypes;
      }) => void;
      resetCurrentDocument: () => void;
      getSignatureUrls: (id: number) => void;
      getSignatureUrlForParticipant: (
        participantId: number,
        documentId: number
      ) => void;
      continueDocument: (documentId: number) => Promise<GetDocumentResponse>;
      updateRehuddleStarted: (isRehuddleStarted: boolean) => void;
      updateCurrentDocumentValues: (
        updates: Partial<DocumentVM>
      ) => Promise<void>;
      submitDocument: (
        document: DocumentVM,
        submissionType: SubmissionType
      ) => Promise<void>;
    };

    dataSource: {
      getDataSourceValues: (args: {
        keyName: string;
        sortBy?: string | string[];
        page?: number;
        size?: number;
      }) => void;
    };
  };
  // White label
  terms: {
    document?: string;
  };
}

const Document = ({ match, store, actions, terms }: DocumentProps) => {
  const [successfulSubmit, setSuccessfulSubmit] = useState<boolean>(false);
  const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false);
  const [documentInfoDrawerOpen, setDocumentInfoDrawerOpen] = useState<boolean>(
    false
  );
  const [formProgress, setFormProgress] = useState<number>(0);
  const [redirectCountdownInterval, setRedirectCountdownInterval] = useState<
    number
  >(0);
  const [redirectSecondsLeft, setRedirectSecondsLeft] = useState<number>(0);
  const [submissionTypeIsSaving, setSubmissionTypeIsSaving] = useState<boolean>(
    false
  );
  const [submissionTypeIsSubmitting, setSubmissionTypeIsSubmitting] = useState<
    boolean
  >(false);
  const [autoSyncInProgress, setAutoSyncInProgress] = useState<boolean>(false);
  const [startSubmit, setStartSubmit] = useState<boolean>(false);
  const [syncOEs, setSyncOEs] = useState<boolean | undefined>();
  const [formValuesLoading, setFormValuesLoading] = useState<boolean>(false);
  const [documentLoading, setDocumentLoading] = useState<boolean>(true);
  const [initialResponses, setInitialResponses] = useState<
    DocumentQuestionResponseVm[] | undefined
  >();

  const history = useHistory();
  const location = useLocation();

  const submitDocumentLoading = store.newDocument.loading.submitDocument;
  const submitDocumentError = store.newDocument.error.submitDocument;

  const isSubmitOrSave = submissionTypeIsSaving || submissionTypeIsSubmitting;

  const { handlePutDocument } = usePutDocument();

  const isDocExpired =
    store.newDocument.currentDocument?.deadline &&
    moment(store.newDocument.currentDocument.deadline).diff(moment.now()) < 0;

  useAsyncEffect(async () => {
    if (
      !!match.params.documentId &&
      store.newDocument.currentDocument?.submissionType !== "NEW"
    ) {
      await actions.newDocument.continueDocument(match.params.documentId);
    }
  }, [match.params.documentId]);

  const handleRedirectCountdown = () => {
    setRedirectSecondsLeft((prevCount) => {
      if (prevCount > 0) {
        return prevCount - 1;
      } else return 0;
    });
  };

  const startRedirectCountdown = () => {
    const millisecondsPerSecond = 1000;
    setRedirectCountdownInterval(
      setInterval(handleRedirectCountdown, millisecondsPerSecond)
    );
    setRedirectSecondsLeft(redirectDuration / millisecondsPerSecond);
  };

  const getInitialDataSourceValues = () => {
    // const {
    //   actions,
    //   store: {
    //     newDocument: { currentDocument },
    //     dataSource: {
    //       loading: { getDataSourceValues: getDataSourceLoading },
    //       success: { getDataSourceValues: getDataSourceSuccess },
    //     },
    //   },
    // } = this.props;

    if (!store.newDocument.currentDocument) {
      return;
    }

    const uniqueAnswerSources: Array<QuestionAnswerSourceDTO> = store
      .newDocument.currentDocument.form
      ? getUniqueAnswerSources(store.newDocument.currentDocument.form)
      : [];

    if (uniqueAnswerSources.length) {
      const promises = uniqueAnswerSources.map(async (answerSource) => {
        // If the uniqueKey's loading and success flags are false/non-existent
        // then we'll fire the API call. This will prevent the call from firing
        // if we already have the data in the store

        // The above comment produced a bug -> ST-466
        // The solution is that we need to fire the API call for any updated values
        // if the dataset has been changed so it doesn't keep the old ones in the store
        // thus removing the success flag out. Alternative is to remove datasource from
        // redux but this is just a temp fix until we start using Core version

        if (
          answerSource.dataSourceKey &&
          !store.dataSource.loading.getDataSourceValues[
            answerSource.dataSourceKey
          ]
        ) {
          await actions.dataSource.getDataSourceValues({
            keyName: answerSource.dataSourceKey,
            sortBy: get(
              answerSource,
              "properties.detailedSearch.infiniteListSortBy"
            ),
            page: 0,
            size: 100,
          });
        }
      });
      return Promise.all(promises);
    }
  };

  useAsyncEffect(async () => {
    if (!store.newDocument.currentDocument?.id) return null;

    if (!store.clientOverrides) {
      actions.getAppConfig("clientOverrides");
    }
    setDocumentLoading(true);
    if (store.newDocument.currentDocument.status === "DELETED") {
      setDocumentLoading(false);
      return history.push({
        pathname: "/documents",
        state: { error: "DELETED" },
      });
    }

    // prefill CURRENT_PARTICIPANT fields
    // @TODO this functionality should be handled by Formik in FormController after the refactor
    const formItems =
      store.newDocument.currentDocument?.form?.sections?.flatMap(
        (section) => section.items as QuestionDTO[]
      ) || [];
    const currentParticipantItems = formItems?.filter(
      (item) =>
        item.type === "QUESTION" &&
        item.answerSource?.type === "CURRENT_PARTICIPANT"
    );

    if (store.newDocument.currentDocument && currentParticipantItems) {
      const newResponses = [
        ...(store.newDocument.currentDocument.responses || []),
      ];
      const newParticipants = [
        ...(store.newDocument.currentDocument.participants || []),
      ];
      !store.newDocument.isRehuddleStarted &&
        currentParticipantItems.forEach((cpi) => {
          // if there's not already a response
          if (
            !newResponses.find((r) => r.questionId === cpi.id) &&
            cpi.answerSource
          ) {
            const { properties } = cpi.answerSource;
            const answerField =
              properties.prefillAnswerField || properties.answerField;
            const idField =
              properties.prefillAssociatedIdField ||
              properties.associatedIdField ||
              "participantId";
            if (answerField) {
              const answer = get(store.currentUser, answerField);
              const associatedId = idField
                ? get(store.currentUser, idField)
                : null;
              if (!!answer) {
                newResponses.push({
                  answer,
                  associatedId,
                  associatedRootId: associatedId,
                  questionId: cpi.id,
                  questionRootId: cpi.rootId,
                  timeAnswered: moment.utc().format(),
                });
                if (
                  !cpi.properties?.excludeFromSignatures &&
                  !newParticipants.find((p) => p.participantId === associatedId)
                ) {
                  newParticipants.push({
                    email: store.currentUser.email,
                    firstName: store.currentUser.firstName,
                    fullName: store.currentUser.fullName,
                    lastName: store.currentUser.lastName,
                    name: store.currentUser.name,
                    participantId: associatedId,
                    role: cpi.participantRole || "ATTENDANT",
                    timeAdded: moment()
                      .utc()
                      .format(),
                  });
                }
              }
            }
          }
        });
      setInitialResponses(newResponses);
      await actions.newDocument.updateCurrentDocumentValues({
        ...store.newDocument.currentDocument,
        responses: newResponses,
        participants: newParticipants,
      });
      if (
        store.newDocument.currentDocument.submissionType !== "SUBMIT" &&
        !isDocExpired &&
        !store.newDocument.currentDocument?.readOnly
      ) {
        const submissionObject: DocumentFormValuesType = {
          responses: newResponses,
          operationalExperiences:
            store.newDocument.currentDocument.operationalExperiences,
        };
        await handlePutDocument(
          submissionObject,
          newParticipants,
          "AUTO_SYNC",
          store.newDocument.currentDocument?.id,
          store.newDocument.currentDocument?.form.actions
        );
      }
    }
    setFormValuesLoading(true);
    await getInitialDataSourceValues();
    setFormValuesLoading(false);
    setDocumentLoading(false);
  }, [store.newDocument.currentDocument?.id]);

  useEffect(() => {
    // ? HANDLE DOCUMENT SUBMISSION LOADING
    if (submitDocumentLoading && !loadingSubmit && isSubmitOrSave) {
      setLoadingSubmit(true);
    }
    // ? HANDLE DOCUMENT AUTO_SYNC SUCCESS
    if (
      store.newDocument.success.submitDocument &&
      !successfulSubmit &&
      !submitDocumentLoading &&
      autoSyncInProgress
    ) {
      setLoadingSubmit(false);
      setAutoSyncInProgress(false);
    }
    // ? HANDLE DOCUMENT SUBMISSION SUCCESS
    if (
      startSubmit &&
      store.newDocument.success.submitDocument &&
      !successfulSubmit &&
      !submitDocumentLoading
    ) {
      setSuccessfulSubmit(true);
      setLoadingSubmit(false);
    }

    // ? HANDLE DOCUMENT SUBMISSION ERROR
    if (
      store.newDocument.error.submitDocument &&
      loadingSubmit &&
      !submitDocumentLoading
    ) {
      setLoadingSubmit(false);
    }
  }, [
    store.newDocument.success,
    submitDocumentLoading,
    successfulSubmit,
    autoSyncInProgress,
    isSubmitOrSave,
  ]);

  useEffect(() => {
    return () => {
      if (redirectCountdownInterval) {
        clearInterval(redirectCountdownInterval);
      }
    };
  }, [redirectCountdownInterval]);

  /**
   * Map through the array of unique `dataSourceKeys` and fire an API
   * call to get the first 100 data source values for each key
   */

  /**
   * Submit a document to the API
   * @param values
   * @param documentParticipants
   * @param submissionType
   */
  const handleSubmit = async (
    values: DocumentFormValuesType,
    documentParticipants: Array<DocumentParticipant>,
    submissionType: newDocumentActions.SubmissionType,
    formActions?: Array<FormAction>
  ) => {
    setLoadingSubmit(true);
    setSubmissionTypeIsSaving(submissionType === "SAVE_DRAFT");
    setSubmissionTypeIsSubmitting(submissionType === "SUBMIT");
    setStartSubmit(true);
    actions.newDocument.updateCurrentDocumentValues({
      responses: values.responses,
      participants: documentParticipants,
    });
    await handlePutDocument(
      values,
      documentParticipants,
      submissionType,
      store.newDocument.currentDocument?.id,
      formActions
    );
    const currentDocument = store.newDocument.currentDocument!;
    if (submissionType === "SUBMIT")
      documentsActions.updateOneDocument({
        ...currentDocument,
        status: "SUBMITTED",
      });
    startRedirectCountdown();
  };

  const handleAutoSync = async (
    values: DocumentFormValuesType,
    documentParticipants: Array<DocumentParticipant>,
    submissionType: newDocumentActions.SubmissionType,
    formActions?: Array<FormAction>
  ) => {
    setLoadingSubmit(true);
    setAutoSyncInProgress(true);
    actions.newDocument.updateCurrentDocumentValues({
      responses: values.responses,
      participants: documentParticipants,
      submissionType: "AUTO_SYNC",
    });
    await handlePutDocument(
      values,
      documentParticipants,
      submissionType,
      store.newDocument.currentDocument?.id,
      formActions
    ).then(() => {
      setAutoSyncInProgress(false);
    });
  };

  const handleClearError = () => {
    actions.newDocument.clearStatus({
      actionType: "error",
      payload: ["submitDocument"],
    });
  };

  const handleToggleDocumentInfoDrawer = () => {
    setDocumentInfoDrawerOpen((prevState) => !prevState);
  };

  /**
   * update the progress bar at the top
   * @param formProgress Number between 0 and 100
   */
  const handleUpdateFormProgress = (formProgress: number) => {
    setFormProgress((prevProgress) => formProgress);
  };

  /**
   * Handles post successful submission/save logic.
   * This method should be auto invoked when passed
   * to __Toast__.
   * Is responsible for cleaning up data and redirecting.
   *
   */
  const handleCompleteAndLeave = () => {
    // clear success flag for submit document
    actions.newDocument.clearStatus({
      actionType: "success",
      payload: ["submitDocument"],
    });

    // clear `currentDocument` value
    // which is now stale/no longer necessary
    actions.newDocument.resetCurrentDocument();

    // return user to Dashboard
    return history.push("/documents");
  };

  const handleDismissRehuddle = () => {
    actions.newDocument.updateRehuddleStarted(false);
  };

  /**
   * Set OE sync state based on form contents
   * @param shouldSync
   */
  const setShouldSyncOEs = (shouldSync: boolean) =>
    setSyncOEs((prevSyncOEs) => {
      /* existence of OE widget has been determined, set sync state accordingly */
      if (prevSyncOEs === undefined) {
        return shouldSync;
        /* syncOEs has not yet been set */
      } else return prevSyncOEs;
    });

  /**
   * render loading screen
   */

  if (
    documentLoading ||
    !store.newDocument.currentDocument ||
    !store.newDocument.currentDocument.form
  ) {
    return <Loader loading />;
  }

  const signatureRequired = R.pathOr(
    false,
    ["form", "signatureRequired"],
    store.newDocument.currentDocument
  );

  const queryString: string | null | undefined = location.search;
  const readOnly = R.pathOr(
    false,
    ["readOnly"],
    store.newDocument.currentDocument
  );

  const renderLocked = shouldRenderLocked({ queryString, readOnly });
  const handleClickOnBackArrow = () => {
    const { length, goBack, push } = history;
    if (length > 2) return goBack();
    return push("/");
  };

  // White label
  const documentTerm = terms?.document || "Document";
  const documentTermLower = documentTerm.toLowerCase();

  // choose toast
  let toast: React.ReactNode = null;

  if (successfulSubmit && !submissionTypeIsSaving) {
    {
      /* RENDER SUCCESS TOAST ON SUBMIT */
    }
    toast = (
      <Toast
        visible={true}
        onClick={handleCompleteAndLeave}
        onDelay={handleCompleteAndLeave}
        variant="success"
      >
        Submitted! Return to DOCS({redirectSecondsLeft})
      </Toast>
    );
  } else if (successfulSubmit && submissionTypeIsSaving) {
    {
      /* SUCCESS TOAST FOR SAVE */
    }
    toast = (
      <Toast
        visible={true}
        onClick={handleCompleteAndLeave}
        onDelay={handleCompleteAndLeave}
        variant="save"
      >
        Saved! Return to DOCS({redirectSecondsLeft})
      </Toast>
    );
  } else if (store.newDocument.currentDocument.submissionType === "SUBMIT") {
    {
      /* STICKY SUBMISSION BANNER */
    }
    toast = (
      <Toast variant="success" visible={true}>
        Submitted {store.newDocument.currentDocument.title} by{" "}
        {store.newDocument.currentDocument.owner.name} at{" "}
        {moment(store.newDocument.currentDocument.submissionDate).format(
          "HH:mm M/DD/YYYY"
        )}
      </Toast>
    );
  } else if (
    store.newDocument.currentDocument.submissionType === "SAVE_DRAFT"
  ) {
    {
      /* STICKY DRAFT BANNER */
    }
    toast = (
      <Toast visible={true} variant="save">
        Saved (draft) {store.newDocument.currentDocument.title} by{" "}
        {store.newDocument.currentDocument.owner.name} at{" "}
        {moment(store.newDocument.currentDocument.submissionDate).format(
          "HH:mm M/DD/YYYY"
        )}
      </Toast>
    );
  } else if (submitDocumentError) {
    {
      /* RENDER ERROR TOAST ON ERROR */
    }
    toast = (
      <Toast variant="error" onClick={() => handleClearError()} visible={true}>
        Recent changes to your {documentTermLower} may not have saved to the
        server. Please verify intended information is complete and re-submit.
      </Toast>
    );
  } else if (store.newDocument.isRehuddleStarted) {
    {
      /* REHUDDLE NOTIFICATION */
    }
    toast = (
      <Toast
        onClick={handleDismissRehuddle}
        onDelay={handleDismissRehuddle}
        variant="new"
        visible={true}
      >
        Rehuddle started at {moment().format("HH:mm M/DD/YYYY")}
      </Toast>
    );
  }

  return (
    <S.DocumentContainer>
      <FormHeader
        formProgress={formProgress}
        handleClickOnBackArrow={handleClickOnBackArrow}
        handleOnClickMenu={() => handleToggleDocumentInfoDrawer()}
        position="fixed"
        title={store.newDocument.currentDocument.form.name}
      />
      <DocumentSidebarDrawer
        onClose={() => handleToggleDocumentInfoDrawer()}
        onOpen={() => handleToggleDocumentInfoDrawer()}
        open={documentInfoDrawerOpen}
        selectedDocument={store.newDocument.currentDocument}
      />
      {renderLocked ? (
        // Render view only document
        <ReadOnlyDoc document={store.newDocument.currentDocument} />
      ) : (
        // Render editable document
        <>
          {/* render toast with document offset if one is being presented */}
          {toast ? (
            <>
              {" "}
              <div style={{ height: remCalc(75) }} /> {toast}{" "}
            </>
          ) : null}

          <Loader loading={documentLoading || formValuesLoading}>
            <>
              <RehuddleInfo
                id={store.newDocument.currentDocument.id}
                isRehuddle={store.newDocument.currentDocument.isRehuddle}
                parentId={store.newDocument.currentDocument.parentId}
              />
              {initialResponses && (
                <FormController
                  // @ts-ignore
                  autoSyncInProgress={autoSyncInProgress}
                  displayConditions={
                    store.newDocument.currentDocument.form.displayConditions
                  }
                  defenses={
                    store.newDocument.currentDocument.form.defenses || []
                  }
                  documentId={store.newDocument.currentDocument.id}
                  documentParticipants={
                    store.newDocument.currentDocument.participants || []
                  }
                  handleSaveDocument={handleSubmit}
                  handleAutoSync={handleAutoSync}
                  handleUpdateFormProgress={handleUpdateFormProgress}
                  initialResponses={initialResponses}
                  initialOperationalExperiences={
                    store.newDocument.currentDocument.operationalExperiences
                  }
                  isRehuddle={store.newDocument.currentDocument.isRehuddle}
                  loadingSubmit={loadingSubmit}
                  participants={store.participants.participants}
                  form={store.newDocument.currentDocument.form}
                  sections={store.newDocument.currentDocument.form.sections}
                  setSyncOEs={setShouldSyncOEs}
                  signatureRequired={signatureRequired}
                  submitDocumentError={submitDocumentError}
                  submitDocumentLoading={submitDocumentLoading}
                  submitStatus={store.newDocument.currentDocument.status}
                  successfulSubmit={successfulSubmit}
                />
              )}
            </>
          </Loader>
        </>
      )}
    </S.DocumentContainer>
  );
};

const mapStateToProps = (state: AppState) => {
  let _documentTerm =
    get(state.clientGroupConfigs, ["data", "terms"], []).find(
      (term: ClientGroupConfig) => term.visibleId === "document"
    )?.val || "";
  _documentTerm = handleNoun(_documentTerm, undefined);
  return {
    store: {
      newDocument: state.newDocument,
      documents: state.documents,
      participants: state.participants,
      dataSource: state.dataSource,
      clientOverrides: state.appConfigs.data.clientOverrides,
      currentUser: currentUser(state.user),
      // White label
      terms: {
        document: _documentTerm,
      },
    },
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  actions: {
    newDocument: bindActionCreators(newDocumentActions, dispatch),
    documents: bindActionCreators(documentsActions, dispatch),
    dataSource: bindActionCreators(dataSourceActions, dispatch),
    operationalExperiences: bindActionCreators(
      operationalExperienceActions,
      dispatch
    ),
    getAppConfig: bindActionCreators(getAppConfig, dispatch),
  },
});

// TODO typing our actions is getting in the way of fixing a prod bug -JA
export default withRouter(
  // @ts-ignore
  connect(mapStateToProps, mapDispatchToProps)(Document)
);
