import React, { useState, useEffect } from "react";
import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { omit } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";

// Common
import Breadcrumbs from "components/common/Breadcrumbs";
import TagFilters from "../components/TagFilters";
import { ContentWrapper } from "components/common/Wrappers";
import {
  GetTagLibrariesAction,
  GET_TAG_LIBRARIES,
} from "store/tagLibrary/types";
import {
  PageHeader,
  PageTitle,
  PageActionButton,
} from "components/clientAdmin/styles";
import {
  Pagination as PaginationType,
  PaginationParams,
  FilterParams,
} from "store/common/types";
import { AppState } from "store";
import { getTags } from "store/tagLibrary/actions";

// Styles from resources
import {
  LoadingWrapper,
  PageLoader,
} from "components/clientAdmin/resources/styles";

// Local
import { TagDTO } from "store/tagLibrary/types";
import { TagLibraryTable } from "../components/TagLibraryTable";
import usePrevious from "util/hooks/usePrevious";
import TagLibraryTablePagination from "../components/TagLibraryTablePagination";
import ReadOnlyContent from "components/common/permissions/ReadOnlyContent";
import { tagLibraryFiltersSelector } from "store/filters/tagLibrary/selectors";
import { setTagLibraryPagination } from "store/filters/tagLibrary/actions";

export const TagLibrary = () => {
  const history = useHistory();

  const filtersSelect = useSelector(tagLibraryFiltersSelector);
  const dispatch = useDispatch<ThunkDispatch<AppState, void, Action>>();
  const { filterParams, paginationParams } = filtersSelect;

  const [filters, setFilters] = useState<FilterParams>(filterParams);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [paginationValues, setPaginationValues] = useState<PaginationType>({});
  const [tags, setTags] = useState<TagDTO[]>([]);

  const handleUpdateFilters = (filters) => {
    setFilters(filters);
    dispatch(setTagLibraryPagination({ ...paginationParams, page: 0 }));
  };

  const handleSort = (sort: string) =>
    dispatch(setTagLibraryPagination({ ...paginationParams, sort }));

  const fetchTags = async () => {
    setIsLoading(true);
    const res: GetTagLibrariesAction = await dispatch(
      getTags(paginationParams, filters)
    );
    if (res?.type === GET_TAG_LIBRARIES.SUCCESS) {
      setTags(res.response.content);
      setPaginationValues(omit(res.response, ["content"]));
    }
    setIsLoading(false);
  };

  const prevParamsSize = usePrevious(paginationParams.size);

  const handleUpdatePaginationParams = (param: PaginationParams) =>
    dispatch(setTagLibraryPagination({ ...paginationParams, ...param }));

  // get the tags on mount and when search/pagination params change
  useEffect(() => {
    // When we change the page size, reset page to 0
    if (prevParamsSize !== paginationParams.size) {
      dispatch(setTagLibraryPagination({ ...paginationParams, page: 0 }));
    } else {
      fetchTags();
    }
  }, [paginationParams, filters]);

  return (
    <ContentWrapper id="mainContent">
      <Breadcrumbs
        paths={[
          {
            pathName: "Content",
          },
          {
            pathName: "Tag Library",
            href: "/content/tag-library",
          },
        ]}
      />
      <PageHeader>
        <PageTitle>Tag Library</PageTitle>
        <ReadOnlyContent>
          <PageActionButton
            onClick={() => history.push("/content/tag-library/add-tag")}
          >
            add tag
          </PageActionButton>
        </ReadOnlyContent>
      </PageHeader>

      <TagFilters onUpdateFilters={handleUpdateFilters} />

      <LoadingWrapper>
        <PageLoader loading={isLoading} />
        <TagLibraryTable
          onFetch={fetchTags}
          onSort={handleSort}
          params={paginationParams}
          tags={tags}
          totalElements={paginationValues.totalElements || 0}
        />
      </LoadingWrapper>
      <TagLibraryTablePagination
        params={paginationParams}
        paginationValues={paginationValues}
        onUpdateParams={handleUpdatePaginationParams}
      />
    </ContentWrapper>
  );
};
