import { PaginationParams } from "store/common/types";
import {
  GroupsFiltersParams,
  GROUPS_FILTERS,
  SetGroupsFiltersAction,
  SetGroupsPaginationAction,
} from "./types";

export const setGroupsFilters = (
  filters: GroupsFiltersParams
): SetGroupsFiltersAction => ({
  response: filters,
  type: GROUPS_FILTERS.SET,
});

export const setGroupsPagination = (
  pagination: PaginationParams
): SetGroupsPaginationAction => ({
  response: pagination,
  type: GROUPS_FILTERS.PARAMS_SET,
});
