import { useContext, CSSProperties, useEffect, useState } from "react";
import { StyleSheet } from "aphrodite";
import { ThemeContext } from "styled-components";

import { remCalc } from "themes/helpers";

export const CONTENT_WIDTH = "41rem";

export default function(headerId?: string): { [key: string]: CSSProperties } {
  const theme = useContext(ThemeContext);
  const [headerHeight, setHeaderHeight] = useState(0);
  useEffect(() => {
    if (headerId) {
      const newHeight: number = document.getElementById(headerId)
        ?.clientHeight!;
      if (newHeight !== headerHeight) {
        return setHeaderHeight(newHeight);
      } else {
        return;
      }
    }
  });

  return StyleSheet.create({
    row: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },

    justifyEnd: {
      justifyContent: "flex-end",
    },

    spaceBetween: {
      justifyContent: "space-between",
    },

    centerFlex: {
      display: "flex",
      alignItems: "center",
    },

    formBuilder: {
      height: "100%",
      display: "flex",
      flexDirection: "column",
    },

    form: {
      height: "100%",
      display: "flex",
      flexDirection: "column",
    },

    // Layout
    contentWrapper: {
      display: "flex",
      flexDirection: "row",
      flex: 1,
      height: `calc(100% - ${headerHeight})`,
      overflow: "hidden",
    },

    content: {
      display: "flex",
      flexDirection: "column",
      overflowY: "scroll",
      flexGrow: 1,
      flexShrink: 1,
      padding: `1rem ${remCalc(40)}`,
      /* Hide scrollbar */
      "-ms-overflow-style": "none" /* IE and Edge */,
      scrollbarWidth: "none" /* Firefox */,
      "::-webkit-scrollbar": {
        /* for Chrome, Safari and Opera */
        display: "none",
      },
    },

    contentContainer: {
      // display: "flex",
      // flexDirection: "column",
      maxWidth: CONTENT_WIDTH,
      flexShrink: 1,
    },

    contentContinerPublished: {
      marginTop: remCalc(8),
    },

    headerWrapper: {
      position: "fixed",
      width: "100%",
      backgroundColor: theme.masterColors.lightWhite,
      zIndex: 3,
    },

    tabPanelWrapper: {
      position: "fixed",
      overflowY: "auto",
      top: `${headerHeight}px`,
      display: "flex",
      // full height minus header wrapper height
      height: `calc(100% - ${headerHeight}px)`,
      width: "100%",
      background: theme.masterColors.lightWhite,
    },

    /* styles are used in conjunction with sidebar styles */
    propertiesSidebar: {
      paddingTop: "1rem",
      width: remCalc(378),
      // position set to fixed with top: 160 in sidebar styles
      right: 0,
    },

    alert: {
      background: theme.colors.warningFill,
      border: `1px solid ${theme.colors.warning}`,
      borderRadius: "4px",
      padding: "16px 12px",
      margin: "16px 0",
    },

    alertTitle: {
      fontSize: "0.75rem",
      fontWeight: 500,
      color: theme.masterColors.black,
      letterSpacing: remCalc(1.88),
      marginBottom: "0.5rem",
    },

    alertBody: {
      fontSize: "0.875rem",
    },

    alertButton: {
      textTransform: "uppercase",
      padding: "0 16px",
      display: "block",
    },

    lowercase: {
      textTransform: "initial",
    },

    publishedBannerWrapper: {
      position: "relative",
      marginBottom: 85,
    },

    publishedBanner: {
      position: "fixed",
      background: "rgb(0, 133, 60, 1)",
      border: `1px solid ${theme.colors.success}`,
      color: "white",
      padding: remCalc(14),
      borderRadius: remCalc(4),
      marginBottom: remCalc(8),
      left: remCalc(385),
      right: remCalc(395),
      zIndex: 100,
    },

    deactivatedBanner: {
      background: theme.colors.error,
      border: `1px solid ${theme.colors.error}`,
      color: "white",
      padding: remCalc(14),
      borderRadius: remCalc(4),
      marginBottom: remCalc(8),
    },
  });
}
