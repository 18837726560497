import React, { useContext } from "react";
import styled, { ThemeContext } from "styled-components";
import { StyleSheet } from "aphrodite";

import { devices } from "themes/mediaQueries";
import { remCalc, toRgba } from "themes/helpers";
import { ThemeTypes } from "themes/colors";
import { Text, TextProps } from "components/clientAdmin/styles";
import { StyledDiv } from "util/styleWrappers";

const Table = styled.div`
  display: table;
  height: 100%;
  left: 0;
  padding: 0 28px;
  position: absolute;
  top: 0;
  width: 100%;
`;

const TableCell = styled.div`
  display: table-cell;
  vertical-align: middle;
  max-width: 20.75em;
`;

const TableContent = styled.div`
  margin-left: auto;
  margin-right: auto;
  max-width: 415px;
`;

const ResetContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 2.25em auto;
  max-width: 415px;
`;

const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: ${remCalc(30)};
`;

const Title = styled.span`
  display: block;
  font-size: 1.25em;
  font-weight: 500;
  margin-bottom: 8px;
  margin-top: 46px;

  @media (min-width: ${devices.minDesktop}px) {
    text-align: center;
  }

  @media (max-width: ${devices.maxTablet}px) {
    text-align: left;
  }
`;

// Can this be named better? -- GK
const SpanWithMargin = styled.span`
  display: block;
  font-size: 0.875rem;
  margin-bottom: 25px;
`;

const ErrorSpan = styled.span`
  color: ${({ theme }) => theme.colors.error};
  display: block;
  margin-bottom: 1em;
`;

const Version = styled.span`
  display: block;
  text-align: center;
  letter-spacing: 0.1em;
  color: ${({ theme }) => theme.masterColors.darkGrey};
  margin-top: 12px;
  margin-bottom: 48px;
`;

const Welcome = styled.span`
  display: block;
  text-align: center;
  font-size: 1.8em;
  margin-bottom: 2rem;
`;

const RecaptchaContainer = styled.div`
  margin-top: 1em;
  display: flex;
  justify-content: center;
`;

const UnderlinedSpan = styled.span`
  text-decoration: underline;
  cursor: pointer;
`;

type PasswordReqProps = {
  fulfilled: boolean;
  theme: ThemeTypes;
};

const PasswordRequirement = styled.span`
  display: block;
  color: ${({ fulfilled, theme }: PasswordReqProps) =>
    fulfilled ? `${theme.colors.success}` : `${theme.masterColors.mediumGrey}`};
  font-size: 1em;
`;

const ButtonSuccessTempState = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.primary};
  border-radius: 4px;
  color: ${({ theme }) => theme.colors.white};
  display: flex;
  font-size: 0.875rem;
  height: 56px;
  justify-content: center;
  letter-spacing: 1.25px;

  @media (min-width: ${devices.minDesktop}px) {
    margin-top: 2.125rem;
  }

  @media (max-width: ${devices.maxTablet}px) {
    margin-top: 1rem;
  }

  padding: 20px 0;
  position: relative;
  text-transform: uppercase;
  width: 100%;
`;

// Temporary checked icon container that shows before ButtonSuccessState shows
const ButtonSuccessTempIconContainer = styled.i`
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.success};
  border-radius: 50%;
  border: 3px solid ${({ theme }) => theme.colors.white};
  display: flex;
  height: 35px;
  justify-content: center;
  width: 35px;
`;

// Temporary checked icon that shows before ButtonSuccessState shows
const ButtonSuccessTempIcon = styled.i.attrs({
  className: "icon-icons8-checkmark",
})`
  color: ${({ theme }) => theme.colors.white};
  font-size: 22px;
  font-weight: 600;
`;

const ButtonSuccessState = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.success};
  border-radius: 4px;
  color: ${({ theme }) => theme.colors.white};
  display: flex;
  font-size: 0.875rem;
  height: 56px;
  justify-content: center;
  letter-spacing: 1.25px;

  @media (min-width: ${devices.minDesktop}px) {
    margin-top: 2.125rem;
  }

  @media (max-width: ${devices.maxTablet}px) {
    margin-top: 1rem;
  }

  padding: 20px 0;
  position: relative;
  text-transform: uppercase;
  width: 100%;
`;

const ButtonSuccessStateIcon = styled.i.attrs({
  className: "icon-icons8-checkmark",
})`
  font-size: 1.875rem;
  left: 16px;
  position: absolute;
  top: 13px;
`;

const ButtonFailureState = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.error};
  border-radius: 4px;
  color: ${({ theme }) => theme.colors.white};
  display: flex;
  font-size: 0.875rem;
  height: 56px;
  justify-content: center;
  letter-spacing: 1.25px;

  @media (min-width: ${devices.minDesktop}px) {
    margin-top: 2.125rem;
  }

  @media (max-width: ${devices.maxTablet}px) {
    margin-top: 1rem;
  }

  padding: 20px 0;
  position: relative;
  text-transform: uppercase;
  width: 100%;
`;

const ButtonFailureStateIcon = styled.i.attrs({
  className: "icon-icons8-delete_sign",
})`
  font-size: 1.875rem;
  left: 16px;
  position: absolute;
  top: 13px;
`;

const ChooseAPasswordHeading: React.FC<TextProps> = (props) => {
  const theme = useContext(ThemeContext);
  return (
    <Text
      color={theme.colors.black}
      fontSize="2.125rem"
      fontWeight={400}
      margin="0 0 37px 0"
      textAlign="center"
      {...props}
    />
  );
};

const MobileHeader = styled.div`
  align-items: center;
  box-shadow: ${({ theme }) => toRgba(theme.colors.black, 0.2)} 0 1px 3px;
  color: ${({ theme }) => theme.colors.black};
  display: flex;
  font-size: 1.25rem;
  font-weight: 500;
  height: 56px;
  justify-content: center;
  letter-spacing: 0.26px;
  position: relative;
  // Removing parent's padding for this component only, not sure if there's a better way
  // (instead of removing from parent and adding to everything below MobileHeader)
  margin-left: -15px;
  margin-right: -15px;
  margin-top: -32px;
`;

const MobileHeaderChevron = styled.i.attrs({
  className: "icon-icons8-chevron-left",
})`
  color: ${({ theme }) => theme.masterColors.darkGrey};
  font-size: 1.5rem;
  left: 15px;
  position: absolute;
  top: 28%;
`;

const ResetPasswordBubble = styled.div`
  background-color: ${({ theme }) => theme.colors.errorFill};
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.colors.error};
  color: ${({ theme }) => theme.masterColors.darkGrey};
  font-size: 0.875rem;
  letter-spacing: 0.44px;
  line-height: 1rem;
  margin-bottom: 1rem;
  padding: 15px 35px;
  position: relative;
  text-align: left;
`;

const ResetPasswordBubbleIcon = styled.div.attrs({
  className: "icon-icons8-error",
})`
  font-size: 1rem;
  left: 12px;
  position: absolute;
  top: 15px;
`;

const ResetPasswordBubbleActionLink = styled.a`
  color: ${({ theme }) => theme.colors.primary} !important;
  cursor: pointer;
  display: block;
  text-decoration: underline !important;
`;

const TryAgainSubmitButton = styled.button.attrs({
  type: "submit",
})`
  background: none;
  border: 0;
  color: ${({ theme }) => theme.colors.primary};
  cursor: pointer;
  display: block;
  font-size: 0.875rem;
  font-weight: 500;
  letter-spacing: 1.25px;
  margin: 20px auto 0 auto;
  padding: 20px 19px;
  position: relative;
  text-align: center;
  text-transform: uppercase;

  &:focus {
    outline: 0;
  }
`;

// Activation
const ActivationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto auto;
  max-width: 415px;
  min-width: 359px;
`;

// Validation key error
const ValidationError = styled.span`
  display: flex;
  flex-direction: row;
  justify-content: center;
  text-align: center;
`;

export const styles = StyleSheet.create({
  additionalButtonWrapper:{
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
  },
  additionalLink: {
    marginTop: remCalc(24),
    padding: `0 ${remCalc(8)}`,
  },
  logo: {
    cursor: "pointer",
    display: "flex",
    justifyContent: "center",
  },
});

const AdditionalButtonWrapper = StyledDiv(styles.additionalButtonWrapper);

export {
  ActivationWrapper,
  AdditionalButtonWrapper,
  ButtonFailureState,
  ButtonFailureStateIcon,
  ButtonSuccessState,
  ButtonSuccessStateIcon,
  ButtonSuccessTempIcon,
  ButtonSuccessTempIconContainer,
  ButtonSuccessTempState,
  ChooseAPasswordHeading,
  ErrorSpan,
  LogoContainer,
  MobileHeader,
  MobileHeaderChevron,
  PasswordRequirement,
  RecaptchaContainer,
  ResetContainer,
  ResetPasswordBubble,
  ResetPasswordBubbleActionLink,
  ResetPasswordBubbleIcon,
  SpanWithMargin,
  Table,
  TableCell,
  TableContent,
  Title,
  TryAgainSubmitButton,
  UnderlinedSpan,
  ValidationError,
  Version,
  Welcome,
};
