import React, { Dispatch, SetStateAction, useMemo } from "react";

import Drawer from "components/common/Drawer";
import SubmitFeedbackSidebar from "components/screens/documents/components/SubmitFeedbackSidebar/SubmitFeedbackSidebar";

interface Props {
  isDesktop?: boolean;
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}

const SubmitFeedbackDrawer = ({ isOpen, setIsOpen, isDesktop }: Props) => {
  const toggleSubmitFeedbackVisibility = () => setIsOpen(!isOpen);

  const computedOverrideClasses = useMemo(
    () =>
      isDesktop
        ? {
          content: "myDocumentsDrawerDesktop",
        }
        : {
          container: "",
          content: "myDocumentsDrawer",
        },
    [isDesktop],
  );

  return (
    <Drawer
      content={
        <SubmitFeedbackSidebar
          submitFeedbackVisible={isOpen}
          toggleSubmitFeedbackVisibility={toggleSubmitFeedbackVisibility}
        />
      }
      anchor="right"
      disableBackdrop
      paperProps={{ elevation: 0 }}
      id="submitFeedbackDrawer"
      onClose={() => setIsOpen(false)}
      onOpen={() => {}}
      open={isOpen}
      overrideClasses={computedOverrideClasses}
      showCloseIcon={false}
    />
  );
};

export default SubmitFeedbackDrawer;
