import React from "react";
import { css } from "aphrodite/no-important";

import Icon from "components/common/Icon";
import { TextButton } from "components/common/Button/styles";

import propertiesStyles from "../../styles";

interface AddRuleButtonProps {
  onAdd: () => void;
}

const AddRuleButton = ({ onAdd }: AddRuleButtonProps) => {
  const ps = propertiesStyles();

  return (
    <TextButton type="button" className={css(ps.newRuleButton)} onClick={onAdd}>
      <div className={css(ps.newRuleText)}>
        <div>Add another display rule</div>
        <div><Icon className={css(ps.addIcon)} type="add" /></div>
      </div>
    </TextButton>
  );
};

export default AddRuleButton;
