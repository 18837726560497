import React, { useState, useEffect } from "react";
import {  useDispatch } from "react-redux";
import { Action } from "redux";
import { useHistory, useLocation } from "react-router";
import { css } from "aphrodite/no-important";

import * as S from "./styles";
import * as f from "./fields";
import Form from "../forms/Form";
import Loader from "components/common/Loader";
import { ACTIVATE_USER, VALIDATE_USER_KEY, ValidateUserKeyAction, ActivateUserAction } from "store/system/types";
import { ActivationWrapper as Wrapper, SpanWithMargin, styles, ValidationError, Version } from "./styles";
import { AppState } from "store";
import { PasswordResetValues } from "./types";
import { ThunkDispatch } from "redux-thunk";
import { activateUser, validateUserKey } from "store/system/actions";
import { Link } from "components/common/Link/futureUiKit";

// Type to track API request status
type APIState = "request" | "success" | "failure" | undefined;

const Activation = () => {

  // Logo
  const logo2x2Fallback = "/assets/logo/Dominion-EnergyLogo@2x.png";

  // Key validation
  const [keyValidationStatus, setKeyValidationStatus] = useState<APIState>();

  // Form
  const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [tooltipIsShowing, setTooltipIsShowing] = useState<boolean>(false);
  const [showTempSuccessButton, setShowTempSuccessButton] = useState<boolean>(false);

  // Activation
  const [activationStatus, setActivationStatus] = useState<APIState>();

  // User data
  const [username, setUsername] = useState<string | null>(null);

  // 3rd Party Hooks
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch<ThunkDispatch<AppState, void, Action>>();

  // Activate user
  const activate = async (key: string, newPassword: string) => {
    setActivationStatus("request");
    const res: ActivateUserAction = await dispatch(activateUser(key, newPassword));

    if (res.type === ACTIVATE_USER.SUCCESS) {
      setActivationStatus("success");
      setShowTempSuccessButton(true);
      setTimeout(() => {
        setShowTempSuccessButton(false);
      }, 1500);


    } else {
      setActivationStatus("failure");
    }
  };

  // Validate user activation key and store user email
  const validateKey = async (key: string, type: "activationKey") => {
    setKeyValidationStatus("request");
    const res: ValidateUserKeyAction = await dispatch(validateUserKey(key, type));

    if (res.type === VALIDATE_USER_KEY.SUCCESS) {
      setKeyValidationStatus("success");
      setUsername(res.response && res.response.email || null);
    } else {
      setKeyValidationStatus("failure");
    }
  };

  const handleSubmit = (values: PasswordResetValues): void => {
    const search = new URLSearchParams(location.search);
    const key = search.get("key");
    if (key !== null) {
      activate(key, values.newPassword);
    }
  };

  const toggleShowConfirmPassword = () => setShowConfirmPassword((prevShowConfirmPassword) => !prevShowConfirmPassword);
  const toggleShowPassword = () => setShowPassword((prevShowPassword) => !prevShowPassword);

  const navigate = (endpoint: string): void => {
    history.push(endpoint);
  };

  // Key validation hook
  useEffect(() => {
    const search = new URLSearchParams(location.search);
    const key = search.get("key");
    if (key) validateKey(key, "activationKey");
  }, [location.search]);

  // Redirect on successful activation
  useEffect(() => {
    if (activationStatus === "success") {
      // Set timer
      const redirectTimer = setTimeout(() => history.push("/login"), 3000);

      // Clear timer
      return () => {
        clearTimeout(redirectTimer);
      };
    }
  }, [activationStatus]);
  
  return <Wrapper>
    <Link className={css(styles.logo)} to="/login">
      <img src={logo2x2Fallback} height="68" alt="Dominion Energy" />
    </Link>
    <Version>VERSION 0.1</Version>

    <Loader
      loading={keyValidationStatus === "request"}
      error={
        keyValidationStatus === "failure" && (
          <ValidationError>
            Your key has expired. Please contact your system administrator for support.
          </ValidationError>
        )
      }
    >
      <S.ChooseAPasswordHeading>
        Choose a Password
      </S.ChooseAPasswordHeading>
      {username && 
        <SpanWithMargin>
        Choose a new password for username: <b>{username}</b>
        </SpanWithMargin>
      }
      
      {/* Form */}
      <Form
        fields={f.activationFields({
        // Buttons
          buttonSuccessState: showTempSuccessButton ? (
            <S.ButtonSuccessTempState>
              <S.ButtonSuccessTempIconContainer>
                <S.ButtonSuccessTempIcon />
              </S.ButtonSuccessTempIconContainer>
            </S.ButtonSuccessTempState>
          ) : (
            <S.ButtonSuccessState>
              <S.ButtonSuccessStateIcon />
                  New Password Saved
            </S.ButtonSuccessState>
          ),
          buttonFailureState: (
            <>
              <S.ButtonFailureState>
                <S.ButtonFailureStateIcon />
                    Unable to Save Password
              </S.ButtonFailureState>
              {/* <S.TryAgainSubmitButton>try again</S.TryAgainSubmitButton> */}
            </>
          ),
          // Tooltip focused
          setTooltipShowing: setTooltipIsShowing,
          tooltipIsShowing: tooltipIsShowing,
          // Other
          showConfirmPassword: showConfirmPassword,
          showPassword: showPassword,
          toggleShowConfirmPassword: toggleShowConfirmPassword,
          toggleShowPassword: toggleShowPassword,
        })}
        accountLocked={false}
        error={""}
        externalLoading={activationStatus === "request"}
        handleSubmit={handleSubmit}
        navigate={navigate}
        recaptchaNeeded={false}
        status={activationStatus}
        validationSchema={f.resetSchema}
      />
    </Loader>
  </Wrapper>;
};

export default Activation;
