import React from "react";

import { Button } from "components/common/Button/futureUiKit";

import * as S from "../styles";

interface Props {
  loading: boolean;
  minimumIncluded: boolean;
  requiredText: string;
  reshuffle: () => void;
  disabled: boolean;
}

function Reshuffle({ loading, minimumIncluded, requiredText, reshuffle, disabled }: Props) {
  return (
    <S.Reshuffle>
      <S.RequiredText as="p" success={minimumIncluded}>
        {requiredText}
      </S.RequiredText>

      <Button loading={loading} onClick={reshuffle} disabled={disabled}>
        RESHUFFLE
      </Button>

      { disabled
        ? <S.ReshuffleText as="p">No more operational experiences are available.</S.ReshuffleText>
        : <S.ReshuffleText as="p">Reshuffle the deck for more scenarios.</S.ReshuffleText>
      }
    </S.Reshuffle>
  );
}

export default Reshuffle;
