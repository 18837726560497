import styled from "styled-components";
import mediaQueries from "themes/mediaQueries";
import Loader from "components/common/Loader";

import { remCalc } from "themes/helpers";

// -------------------
// FORM CONTROLLER ---
// -------------------

export const FormController = styled.div`
  padding: ${remCalc(8)};
  position: relative;
`;

// custom styled Loader so
// we can preserve spacing
export const MapLoader = styled(Loader)`
  width: 100%;
  height: 18em;
  margin-bottom: 1.875em;
`;

export const FormActions = styled.div<{ hasErrors?: boolean }>`
  background-color: ${({ theme }) => theme.colors.white};
  display: flex;
  flex-direction: column;
  padding-bottom: 2.125rem;
  margin-bottom: 2.125rem;
  ${({ hasErrors }) => {
    if (hasErrors) {
      return `
        position: sticky;
        bottom: 0;
        z-index: 2;
      `;
    }
  }}
`;

// -------------------
// SECTION -----------
// -------------------

export const Section = styled.div`
  margin-bottom: ${remCalc(50)};
`;
export const SectionTitle = styled.h3`
  color: ${({ theme }) => theme.colors.black};

  font-family: "Roboto", sans-serif;
  font-weight: 400;

  font-size: ${remCalc(30)};
  margin-bottom: ${remCalc(21)};
`;
export const SectionSeparator = styled.div`
  height: ${remCalc(1)};
  width: 100%;
  background-color: ${({ theme }) => theme.masterColors.mediumGrey};
  margin-top: ${remCalc(40)};
`;

export const SectionProperties = styled.div`
  margin-bottom: ${remCalc(50)};
  background: ${({ theme }) => theme.masterColors.lightWhite};
  border-radius: ${remCalc(10)};
  padding: ${remCalc(10)} ${remCalc(10)} ${remCalc(10)} ${remCalc(10)};

  @media (${mediaQueries.minDesktop}) {
    padding: ${remCalc(10)} ${remCalc(0)};
    border-radius: ${remCalc(16)};

    display: flex;
    width: 100%;
    justify-content: space-between;

    background: ${({ theme }) => theme.masterColors.lightWhite};
    padding: ${remCalc(10)} ${remCalc(10)} ${remCalc(10)} ${remCalc(10)};
  }

  @media (${mediaQueries.minTablet}) {
    display: flex;
    width: 100%;
  }

  @media (${mediaQueries.maxPhone}) {
    margin-bottom: ${remCalc(14)};
  }
`;

export const SectionPropertiesTitle = styled.div`
  margin-bottom: ${remCalc(9)};
  font-size: ${remCalc(20)};
  font-weight: 500;

  @media (${mediaQueries.minTablet}) {
    max-width: 20%;
  }
`;
export const SectionPropertiesHeaderDescription = styled.div`
  font-size: ${remCalc(14)};

  @media (${mediaQueries.minTablet}) {
    margin-top: ${remCalc(5)};
  }
`;

// -------------------
// CONTROLLER --------
// -------------------

export const Question = styled.div`
  margin-bottom: ${remCalc(16)};

  @media (${mediaQueries.minTablet}) {
    margin-bottom: ${remCalc(21)};
  }
`;
