import { StyleSheet, StyleDeclaration } from "aphrodite";

import { remCalc } from "themes/helpers";
import { CreateStyleSheetWithProps } from "util/hooks/useThemedStyleSheet";

interface TextInputStyle {
  beforeContainer: StyleDeclaration;
  column: StyleDeclaration;
  errorText: StyleDeclaration;
  warningText: StyleDeclaration;
  helperText: StyleDeclaration;
  input: StyleDeclaration;
  inputMouseBlurred: StyleDeclaration;
  inputMouseFocused: StyleDeclaration;
  row: StyleDeclaration;
}

// TODO theme -JA
interface Props {
  disabled?: boolean;
  rounded?: boolean;
}

const styles: CreateStyleSheetWithProps<TextInputStyle, Props> = (
  theme,
  { disabled, rounded }
) => {
  const { focusIndicatorAppear } = theme.animations;

  return StyleSheet.create({
    beforeContainer: {
      paddingTop: remCalc(10),
      marginRight: remCalc(5),
    },

    column: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
    },

    errorText: {
      display: "block",
      color: theme.colors.error,
      fontSize: remCalc(16),
      margin: `${remCalc(2)} ${remCalc(5)} 0 1rem`,
    },
    warningText: {
      display: "block",
      color: theme.colors.warning,
      fontSize: remCalc(16),
      margin: `${remCalc(2)} ${remCalc(5)} 0 1rem`,
    },

    helperText: {
      display: "block",
      color: theme.colors.assistiveText,
      fontSize: remCalc(16),
      margin: `${remCalc(4)} ${remCalc(5)} 0 0`,
      paddingBottom: 10,
    },

    input: {
      backgroundColor: disabled
        ? theme.masterColors.lightGrey
        : theme.colors.fieldInputBackground,
      border: `1px solid ${theme.masterColors.darkGrey}`,
      borderRadius: rounded ? remCalc(18) : remCalc(4),
      boxShadow: `0 0 0 ${remCalc(6)} transparent`,
      color: theme.colors.formFieldColor,
      transition: `border-color ${focusIndicatorAppear.duration} ${focusIndicatorAppear.timingFunction}, box-shadow ${focusIndicatorAppear.duration} ${focusIndicatorAppear.timingFunction}`,
      ":hover": {
        border: `1px solid ${theme.colors.primary}`,
      },
      ":focus": {
        border: `1px solid ${theme.colors.focusIndicatorOutline}`,
        boxShadow: `0 0 0 ${remCalc(3)} ${theme.colors.focusIndicatorOutline}`,
        outline: "none",
      },
      padding: remCalc(16),
      "::placeholder": {
        color: theme.masterColors.darkGreyBlue,
      },
      ".error": {
        border: `1px solid ${theme.colors.error}`,
        backgroundColor: theme.colors.errorFill,
      },
      width: "100%",
    },

    inputMouseBlurred: {
      transition: "none",
    },

    inputMouseFocused: {
      transition: "none",
      ":focus": {
        border: `1px solid ${theme.colors.primary}`,
        boxShadow: `0 0 0 1px ${theme.colors.primary}`,
      },
    },

    row: {
      display: "flex",
      flexDirection: "row",
    },
  });
};

export default styles;
