import React from "react";

import colors from "themes/colors";

import * as S from "../styles";

const LockedWarning = () => {
  return (
    <S.LockedWarning>
      <div>
        <S.LockIcon type="lock" framed={false} color={colors.masterColors.darkGrey} />
      </div>
      <div>
        <S.LockedWarningText as="p">
          Locked items cannot be manually edited. Contact your system administrator to make changes.
        </S.LockedWarningText>
      </div>
    </S.LockedWarning>
  );
};

export default LockedWarning;
