import React, { useState, useEffect } from "react";
import moment from "moment";
import { css } from "aphrodite/no-important";
import { useFormikContext } from "formik";
import { useHistory } from "react-router";

import { TextButton } from "components/common/Button";
import Icon from "components/common/Icon";
import usePrevious from "util/hooks/usePrevious";

import { FB_TABS } from "../tabs/FormBuilderTabs";
import { FBForm } from "../../types";
import { FormDTO } from "store/forms/types";
import { scrollToElement } from "../../helpers";
import Status from "./Status";
import useValidation from "../../create/validation/useValidation";
import ValidationStatus from "../../create/validation/ValidationStatus";

import baseStyles from "../../styles";
import styles from "./styles";

type EditLinkProps = {
  handleNavigate: FormBuilderHeaderProps["handleNavigate"];
};

const EditLink = ({ handleNavigate }: EditLinkProps) => (
  <span onClick={() => handleNavigate(1) /* navigate to Settings tab*/}>
    <Icon type="pencil" size="12" color="black" />
  </span>
);

type FormBuilderHeaderProps = {
  handleNavigate: (tabIndex: number) => void;
  onSave: (values: FBForm) => Promise<FormDTO | undefined>;
  error: string;
};

const FormBuilderHeader: React.FC<FormBuilderHeaderProps> = ({
  handleNavigate,
  onSave,
  error,
}) => {
  const {
    values,
    errors,
    handleSubmit,
    isSubmitting,
    setSubmitting,
    setFieldValue,
  } = useFormikContext<FBForm>();
  const [lastSaved, setLastSaved] = useState<string>("");
  const [isPublishing, setIsPublishing] = useState<boolean>(false);
  const { alertLevel, getValidationErrors, errorTypes } = useValidation();

  const history = useHistory();

  const errorCount = getValidationErrors(errors).length;

  const s = styles();
  const bs = baseStyles();

  const wasSubmitting = usePrevious(isSubmitting);
  useEffect(() => {
    if (!isSubmitting && wasSubmitting) {
      setLastSaved(moment().format("MM/DD/YYYY [at] HH:mm"));
    }
  }, [isSubmitting]);

  useEffect(() => {
    if (values.lastModifiedDate) {
      setLastSaved(
        moment(values.lastModifiedDate).format("MM/DD/YYYY [at] HH:mm")
      );
    }
  }, [values.lastModifiedDate]);

  async function handleSave(): Promise<FormDTO | undefined> {
    setSubmitting(true);
    const response = await onSave(values);
    setSubmitting(false);
    return response;
  }

  function handlePublish(): void {
    setIsPublishing(true);
    handleSubmit();
  }

  async function handleGoBack(): Promise<void> {
    if (values.workflowType !== "FINAL") {
      await handleSave();
    }
    history.push("/forms");
  }
  async function handleUnpublish(): Promise<void> {
    const response = await handleSave();

    // update the form id
    if (response && response.id !== values.id) {
      setFieldValue("id", response.id);
    }
  }

  // unpublishing a form creates a new form id, update it in the URL
  useEffect(() => {
    if (values.id) {
      history.replace(`${values.id}`);
    }
  }, [values.id]);

  useEffect(() => {
    if (!isSubmitting && isPublishing) {
      setIsPublishing(false);
    }
  }, [isSubmitting]);

  function scrollToValidationBanner() {
    const errorTab = errorTypes?.includes("create") ? "create" : "settings";
    const tabIndex = FB_TABS.indexOf(errorTab);

    handleNavigate(tabIndex);
    scrollToElement(`fb-validation-${errorTab}`);
  }

  const notFinalOrDeactivated =
    values.workflowType !== "FINAL" && values.workflowType !== "DEACTIVATED";

  return (
    <div>
      <div
        className={css([
          s.header,
          bs.centerFlex,
          !!error ? s.errorPadding : null,
        ])}
      >
        <div className={css(s.headerLeft)}>
          <span className={css(s.headerLink)} onClick={() => handleGoBack()}>
            <Icon type="chevron-left" color="" /> Back To Forms
          </span>
        </div>

        <div className={css(s.headerCenter)}>
          <div className={css(bs.centerFlex)}>
            <h1 className={css(s.headerTitle)}>{values.name || "Untitled"}</h1>
            {notFinalOrDeactivated && (
              <EditLink handleNavigate={handleNavigate} />
            )}
          </div>
          <div className={css(s.headerSavedAt)}>
            {lastSaved && <span>Last Saved: {lastSaved}</span>}
            {notFinalOrDeactivated && (
              <TextButton
                disabled={isSubmitting || !values.name}
                className={css(s.saveButton)}
                onClick={handleSave}
              >
                {isSubmitting
                  ? isPublishing
                    ? "Publishing..."
                    : "Saving..."
                  : "Save"}
              </TextButton>
            )}
          </div>
        </div>

        <div className={css(s.headerRight)}>
          <ValidationStatus
            errorCount={errorCount}
            onClick={scrollToValidationBanner}
            alertLevel={alertLevel}
          />
          <Status
            onPublish={handlePublish}
            onSubmit={handleUnpublish}
            status={values.workflowType}
            isPublishing={isPublishing}
          />
        </div>
      </div>
    </div>
  );
};

export default FormBuilderHeader;
