import moment, { Moment } from "moment";
import { FormTypeDTO } from "store/forms/types";
import { DateRangeValue } from "components/common/DateRange";
import { ParticipantTreeOptionsArgs } from "store/participantTreeFilter/types";
import { FilterSelectProps } from "components/common/FilterSelect";

interface SelectOption {
  id: number | string;
  value: any;
}

export const mapFormTypesToFilter = (formTypes?: Array<FormTypeDTO>) => {
  const result: SelectOption[] = [
    {
      id: -1,
      value: "All Documents",
    },
  ];
  if (formTypes) {
    formTypes.forEach((formType: FormTypeDTO) =>
      result.push({
        id: formType.id,
        value: formType.name,
      })
    );
  }
  return result || [];
};

export const statusFilterOptions: SelectOption[] = [
  {
    id: -1,
    value: "All Statuses",
  },
  {
    id: "ACTIVE",
    value: "Active",
  },
  {
    id: "ARCHIVED",
    value: "Archived",
  },
];

// TODO are these consistent across filters? If not, remove from here and put wherever needed..
// Global until a different set is needed (then it'll be added in the relevant component) -- GK
export const daysFilterOptions: SelectOption[] = [
  {
    id: -1,
    value: "All Time",
  },
  {
    id: 1,
    value: "Today",
  },
  {
    id: 2,
    value: "Yesterday",
  },
  {
    id: 7,
    value: "7 Days",
  },
  {
    id: 15,
    value: "15 Days",
  },
  {
    id: 30,
    value: "30 Days",
  },
];

export type TimeFilterType =
  | "ALL_TIME"
  | "CUSTOM_RANGE"
  | "FIFTEEN_DAYS"
  | "SEVEN_DAYS"
  | "THIRTY_DAYS"
  | "TODAY"
  | "YESTERDAY";

export const timeFilterOptions = [
  {
    id: "ALL_TIME",
    value: "All Time",
  },
  {
    id: "TODAY",
    value: "Today",
  },
  {
    id: "YESTERDAY",
    value: "Yesterday",
  },
  {
    id: "SEVEN_DAYS",
    value: "7 Days",
  },
  {
    id: "FIFTEEN_DAYS",
    value: "15 Days",
  },
  {
    id: "THIRTY_DAYS",
    value: "30 Days",
  },
  {
    id: "CUSTOM_RANGE",
    value: "Custom Range",
  },
];

/**
 * Calculate minimum and maximum submission dates for an API querystring
 * @param pastDays - number of days before today to use in filter
 */
export const getMinMaxSubmissionDatesFromDate = (pastDays: number) => {
  let minSubmissionDate = "";
  let maxSubmissionDate = "";

  switch (pastDays) {
    case 1: // Since Today
      maxSubmissionDate = moment().toISOString();
      minSubmissionDate = moment()
        .startOf("day")
        .toISOString();
      break;
    case 2: // JUST Yesterday
      maxSubmissionDate = moment()
        .subtract(1, "d")
        .endOf("day")
        .toISOString();
      minSubmissionDate = moment()
        .subtract(1, "d")
        .startOf("day")
        .toISOString();
      break;
    case 7:
      maxSubmissionDate = moment().toISOString();
      minSubmissionDate = moment()
        .subtract(7, "d")
        .startOf("day")
        .toISOString();
      break;
    case 15:
      maxSubmissionDate = moment().toISOString();
      minSubmissionDate = moment()
        .subtract(15, "d")
        .startOf("day")
        .toISOString();
      break;
    case 30:
      maxSubmissionDate = moment().toISOString();
      minSubmissionDate = moment()
        .subtract(30, "d")
        .startOf("day")
        .toISOString();
      break;
    default:
      break;
  }

  return { minSubmissionDate, maxSubmissionDate };
};

export const getMinMaxSubmissionDatesFromTimeFilter = (
  timeFilterType: TimeFilterType,
  dateRange: DateRangeValue | null
) => {
  let maxSubmissionDate = "";
  let minSubmissionDate = "";

  switch (timeFilterType) {
    case "CUSTOM_RANGE":
      if (!dateRange) {
        break;
      }
      const clampedEndDate = dateRange.endDate
        ? moment.min([dateRange.endDate, moment()])
        : undefined;
      minSubmissionDate = moment(dateRange.startDate).toISOString();
      maxSubmissionDate = moment(clampedEndDate).toISOString();
      break;

    case "FIFTEEN_DAYS":
      maxSubmissionDate = moment().toISOString();
      minSubmissionDate = moment()
        .subtract(15, "d")
        .startOf("day")
        .toISOString();
      break;

    case "SEVEN_DAYS":
      maxSubmissionDate = moment().toISOString();
      minSubmissionDate = moment()
        .subtract(7, "d")
        .startOf("day")
        .toISOString();
      break;

    case "THIRTY_DAYS":
      maxSubmissionDate = moment().toISOString();
      minSubmissionDate = moment()
        .subtract(30, "d")
        .startOf("day")
        .toISOString();
      break;

    case "TODAY":
      maxSubmissionDate = moment().toISOString();
      minSubmissionDate = moment()
        .startOf("day")
        .toISOString();
      break;

    case "YESTERDAY":
      maxSubmissionDate = moment()
        .subtract(1, "d")
        .endOf("day")
        .toISOString();
      minSubmissionDate = moment()
        .subtract(1, "d")
        .startOf("day")
        .toISOString();
      break;

    case "ALL_TIME":
    default:
      break;
  }

  return { minSubmissionDate, maxSubmissionDate };
};

export const getPastDays = (timeFilterType: TimeFilterType): number => {
  switch (timeFilterType) {
    case "FIFTEEN_DAYS":
      return 15;
    case "SEVEN_DAYS":
      return 7;
    case "THIRTY_DAYS":
      return 30;
    case "TODAY":
      return 1;
    case "YESTERDAY":
      return 2;
    default:
    case "ALL_TIME":
    case "CUSTOM_RANGE":
      return -1;
  }
};

export const clampDate = (date: Moment, minDate?: Moment, maxDate?: Moment) => {
  const clampedLow = maxDate ? moment.min([date, maxDate]) : date;
  return minDate ? moment.max([clampedLow, minDate]) : clampedLow;
};

export const getMonthYear = (date: Moment) => {
  return date.format("MMMM YYYY");
};

export const getStartOfToday = () => moment().startOf("day");

export const isAfterDate = (
  date: Moment,
  dateToCompare?: Moment | string | null
) => {
  return dateToCompare && date.isAfter(dateToCompare);
};

export const isBeforeDate = (
  date: Moment,
  dateToCompare?: Moment | string | null
) => {
  return dateToCompare && date.isBefore(dateToCompare);
};

export const getSubOrdinatesOptions = (
  participantTreeOptions: ParticipantTreeOptionsArgs
): SelectOption[] => {
  const mappedOptions: FilterSelectProps["options"] = participantTreeOptions.options.map(
    (option) => ({
      id: option.participantTreeFilter,
      value: option.title,
    })
  );
  return mappedOptions;
};
