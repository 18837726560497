import styled from "styled-components";

import { remCalc } from "themes/helpers";
import mediaQueries from "themes/mediaQueries";
import Text from "components/common/Text";

// -------------------------------------------
// ? MOBILE
// -------------------------------------------

const handleIfAdfsEnabled = (adfsEnabled?: boolean) =>
  !adfsEnabled &&
  `
  margin-bottom: 25.5px;
`;

export const ProfileCard = styled.div<{ bg?: string; adfsEnabled?: boolean }>`
  background-color: ${({ theme }) => theme.colors.primary};
  width: 100%;
  min-height: 181px;
  padding: 36.5px 24px;

  /* add margin bottom if adfsEnabled */
  ${({ adfsEnabled }) => handleIfAdfsEnabled(adfsEnabled)}

  @media (${mediaQueries.minDesktop}) {
    border-radius: 4px;
    background-image: ${({ bg }) => `url(${bg})`};
  }

  @media (${mediaQueries.maxTablet}) {
    border-bottom: 8px solid ${({ theme }) => theme.masterColors.lightWhite};
  }
`;

export const Card = styled.div`
  max-width: 1280px;

`;

export const Username = styled(Text)<{ desktop?: boolean }>`
  font-size: ${remCalc(20)};
  color: ${({ theme }) => theme.colors.white};
  margin-bottom: ${({ desktop }) => (desktop ? "12px" : "5px")};
  letter-spacing: 0.63px;

  @media (${mediaQueries.minDesktop}) {
    font-size: ${remCalc(36)};
    margin-right: 10px;
  }
`;

export const Email = styled(Text)`
  color: ${({ theme }) => theme.colors.white};
  font-size: 1rem;
  font-weight: 400 !important;
  letter-spacing: 0.28px;
  margin-bottom: 0;
`;

export const Group = styled(Text)`
  color: ${({ theme }) => theme.colors.white};
  font-size: 1rem;
  font-weight: 400 !important;
  letter-spacing: 0.28px;
  margin-bottom: 24px;
`;

export const FormCountsLabel = styled(Text)`
  color: ${({ theme }) => theme.colors.white};
  font-size: ${remCalc(12)};
  margin-bottom: 10px;
`;

export const FormCountLink = styled.a``;

export const FormCounts = styled.div<{ desktop?: boolean }>`
  display: flex;

  ${({ desktop }) => {
    if (desktop) {
      return `
        flex-direction: column;
      `;
    }
  }}
`;

export const FormCount = styled.div<{ desktop?: boolean }>`
  display: flex;
  align-items: center;
  margin-right: 10px;

  ${({ desktop }) => {
    if (desktop) {
      return `
        margin-bottom: 16px;
      `;
    }
  }}
`;

export const Icon = styled.span<{ color: string }>`
  color: ${({ color }) => color};
  margin-right: 8px;
  font-size: 1.35rem;
  ${({ theme }) =>
    `
    background-color: ${theme.masterColors.white};
    width: 30px;
    height: 30px;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
  `}
`;

export const GroupRow = styled.div`
  display: flex;
  flex-shrink: 1;
  flex-grow: 100%;
`;

export const Star = styled.span.attrs({
  className: "icon-icons8-star-filled"
})`
  color: ${({ theme }) => theme.masterColors.warning};
  margin-right: 5px;
  padding-top: 2.5px;
`;

export const Count = styled(Text)<{ desktop?: boolean }>`
  color: ${({ theme }) => theme.colors.white};
  font-size: ${remCalc(20)};

  ${({ desktop }) => {
    if (desktop) {
      return `
        text-decoration: underline;
      `;
    }
  }}
`;

export const Clickable = styled.button`
  border: none;
  background: none;
`;

// -------------------------------------------
// ? DESKTOP
// -------------------------------------------

export const Row = styled.div`
  display: flex;
`;

export const LeftTop = styled.div`
  display: flex;
  margin-right: 150px;
`;

export const GroupCol = styled.div`
  margin-right: 48px;
  max-width: 100%;
`;

export const Col = styled.div``;

export const LeftCol = styled.div``;

export const RightCol = styled.div``;

export const SectionLabel = styled(Text)`
  color: ${({ theme }) => theme.colors.white};
  font-size: ${remCalc(12)};
  font-weight: 500;
  letter-spacing: 1.88px;
  margin-bottom: 10px;
`;

export const Supervisor = styled(Text)`
  color: ${({ theme }) => theme.colors.white};
  font-size: 1rem;
  font-weight: 400 !important;
  letter-spacing: 0.28px;
`;
