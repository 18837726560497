import { useContext, CSSProperties } from "react";
import { ThemeContext } from "styled-components";
import { StyleSheet } from "aphrodite";

export default function(): { [key: string]: CSSProperties } {
  const theme = useContext(ThemeContext);

  return StyleSheet.create({
    publishButton: {
      marginTop: "0.5rem",
      marginBottom: 0,
    },

    statusContainer: {
      justifyContent: "flex-end",
      display: "flex",
      alignItems: "center",

      // Select
      ":nth-child(1n) .MuiOutlinedInput-root": {
        width: "8rem",
        marginLeft: "0.75rem",
      },
    },

    status: {
      marginRight: "0.75rem",
    },

    // Header
    header: {
      color: theme.masterColors.darkGrey,
      justifyContent: "space-between",
      fontSize: "1rem",
      padding: "1rem 1.5rem",
    },
    errorPadding: {
      paddingTop: ".15em",
      paddingBottom: "4.5rem",
    },

    headerLink: {
      color: theme.masterColors.darkGrey,
      display: "flex",
      flexDirection: "row",
      textDecoration: "none",
      alignItems: "center",
      cursor: "pointer",
      ":hover": {
        textDecoration: "underline",
      },
    },

    headerTitle: {
      fontSize: "1.5rem",
      color: theme.colors.black,
      fontWeight: 400,
      marginRight: "0.5rem",
    },

    headerLeft: {},

    headerRight: {
      textAlign: "right",
      display: "flex",
      flexDirection: "row",
    },

    headerRightPublished: {
      backgroundColor: theme.colors.success,
      alignItems: "flex-end",
      padding: 10,
      marginRight: -25,
      paddingRight: 25,
      color: theme.colors.white,
      borderTopLeftRadius: 20,
      borderBottomLeftRadius: 20,
    },

    headerCenter: {
      flexDirection: "column",
      display: "flex",
      alignItems: "center",
    },

    headerSavedAt: {
      display: "flex",
      alignItems: "center",
    },

    saveButton: {
      padding: 0,
      margin: 0,
      marginLeft: "1rem",
    },

    editLink: {
      color: theme.colors.lightGrey,
      fontWeight: 700,
    },
  });
}
