import {
  GET_PARTICIPANT_TREE_OPTIONS,
  ParticipantTreeOptionsActions,
  ParticipantTreeOptionsState,
} from "./types";

const initialState: ParticipantTreeOptionsState = {
  data: {
    hideFilter: true,
    hideFilterReason: "Participant Tree Options API is not called",
    options: [],
  },
  loading: false,
  error: false,
};

export function participantTreeOptionsReducer(
  state = initialState,
  action: ParticipantTreeOptionsActions
): ParticipantTreeOptionsState {
  switch (action.type) {
    case GET_PARTICIPANT_TREE_OPTIONS.FAILURE:
      return {
        ...state,
        data: {
          ...state.data,
          hideFilter: true,
          hideFilterReason: "Participant Tree Options API Failed",
        },
        error: true,
        loading: false,
      };
    case GET_PARTICIPANT_TREE_OPTIONS.REQUEST:
      return {
        ...state,
        data: {
          ...state.data,
          hideFilter: true,
          hideFilterReason: "Participant Tree Options API Loading",
        },
        loading: true,
      };
    case GET_PARTICIPANT_TREE_OPTIONS.SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.response,
      };
    default:
      return state;
  }
}
