import React from "react";
import { css } from "aphrodite/no-important";

import baseStyles from "../../styles";
import styles from "./styles";
import { TextButton } from "components/common/Button";

interface Props {
  onClick: () => void;
  title: string;
  error: string;
}

const ValidationLink = ({ onClick, title, error }: Props) => {
  const s = styles();
  const bs = baseStyles();

  let link: string  | React.ReactNode = error;

  if (title) {
    link = <><b>{title}:</b> {error}</>;
  }
  
  return (
    <TextButton
      className={css([bs.alertButton, bs.lowercase, s.validationLink])}
      variant="link"
      onClick={onClick}
    >
      {link}
    </TextButton>
  );
};

export default ValidationLink;
