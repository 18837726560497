import { get } from "lodash";
import { CALL_API } from "middleware/api";

import {
  CREATE_OPERATIONAL_EXPERIENCE,
  DELETE_OPERATIONAL_EXPERIENCE,
  FETCH_OPERATIONAL_EXPERIENCES,
  FETCH_SINGLE_OPERATIONAL_EXPERIENCE,
  SEARCH_OPERATIONAL_EXPERIENCES,
  UPDATE_OPERATIONAL_EXPERIENCES,
  FETCH_DOCUMENT_OES,
  FETCH_DOCUMENT_OES_NOSTORE,
  IncludeOperationalExperience,
  INCLUDE_OPERATIONAL_EXPERIENCE,
  REMOVE_OPERATIONAL_EXPERIENCE,
  RemoveOperationalExperience,
  MarkOperationalExperienceAsFavorite,
  MARK_OPERATIONAL_EXPERIENCE_AS_FAVORITE,
  RESET_API_STATUS_OE,
  ComputeNewFavoriteCount,
} from "./types";
import { OperationalExperience } from "../resources/types";

/**
 * Fetches OEs for the given Document and Participant.
 * Returns and array of OEs
 */
export const fetchDocumentOEs = (
  numberOfOEsToGenerate = 20,
  skipCount = 0,
  store = true,
) => (
  dispatch,
  getState
) => {
  try {
    const { system, user, newDocument } = getState();
    const participantId = get(user, ["data", "participant", "id"], null);
    const documentSubmissionId = get(newDocument, ["currentDocument", "submissionId"], null);

    const requestBody = {
      documentSubmissionId,
      numberOfOEsToGenerate,
      participantId,
      skipCount,
    };

    return dispatch({
      [CALL_API]: {
        endpoint: "document-oe/find",
        options: {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${system.authToken}`,
          },
          body: JSON.stringify(requestBody),
        },
        types: store ? [
          FETCH_DOCUMENT_OES.REQUEST,
          FETCH_DOCUMENT_OES.SUCCESS,
          FETCH_DOCUMENT_OES.FAILURE,
        ] : [
          "",
          FETCH_DOCUMENT_OES_NOSTORE.SUCCESS,
          FETCH_DOCUMENT_OES_NOSTORE.FAILURE,
        ],
      },
    });
  } catch (error) {
    throw new Error(error);
  }
};

/**
 * Includes the given OE on the current Document.
 * It should be noted that the current Document's ID
 * is found in the Redux Store.
 */
export const includeOperationalExperience = ({ operationalExperience }: IncludeOperationalExperience) => (
  dispatch,
  getState,
) => {
  const { system, newDocument } = getState();
  const documentId = get(newDocument, ["currentDocument", "id"], null);

  return dispatch({
    [CALL_API]: {
      endpoint: `document-oe/include/${documentId}`,
      options: {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${system.authToken}`,
        },
        body: JSON.stringify(operationalExperience),
      },
      types: [
        INCLUDE_OPERATIONAL_EXPERIENCE.REQUEST,
        INCLUDE_OPERATIONAL_EXPERIENCE.SUCCESS,
        INCLUDE_OPERATIONAL_EXPERIENCE.FAILURE,
      ],
    },
  }).catch((err) => console.log("ERR", err));
};

/**
 * Removes the given OE from the current Document.
 * It should be noted that the ID for the current Document
 * is found from the Redux Store.
 */
export const removeOperationalExperience = ({ operationalExperience }: RemoveOperationalExperience) => (
  dispatch,
  getState,
) => {
  const { system, newDocument } = getState();
  const documentId = get(newDocument, ["currentDocument", "id"], null);

  return dispatch({
    [CALL_API]: {
      endpoint: `document-oe/include/${documentId}`,
      options: {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${system.authToken}`,
        },
        body: JSON.stringify(operationalExperience),
      },
      types: [
        REMOVE_OPERATIONAL_EXPERIENCE.REQUEST,
        REMOVE_OPERATIONAL_EXPERIENCE.SUCCESS,
        REMOVE_OPERATIONAL_EXPERIENCE.FAILURE,
      ],
    },
  });
};

const computeNewFavoriteCount = ({ operationalExperience, removeFromFavorites }: ComputeNewFavoriteCount) => {
  const currentFavoriteCount = operationalExperience.favoriteCount || 0;

  if (removeFromFavorites) {
    return currentFavoriteCount > 0 ? currentFavoriteCount - 1 : 0;
  }

  return currentFavoriteCount + 1;
};

/**
 * Favorites the given Operational Experience
 */
export const markOperationalExperienceAsFavorite = ({
  operationalExperience,
  removeFromFavorites,
}: MarkOperationalExperienceAsFavorite) => (dispatch, getState) => {
  const { system } = getState();

  const favoriteCount = computeNewFavoriteCount({
    operationalExperience,
    removeFromFavorites,
  });

  const updatedOperationalExperience = {
    ...operationalExperience,
    favoriteCount,
  };

  return dispatch({
    [CALL_API]: {
      endpoint: "document-oe/favorite-count",
      options: {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${system.authToken}`,
        },
        body: JSON.stringify(updatedOperationalExperience),
      },
      types: [
        MARK_OPERATIONAL_EXPERIENCE_AS_FAVORITE.REQUEST,
        MARK_OPERATIONAL_EXPERIENCE_AS_FAVORITE.SUCCESS,
        MARK_OPERATIONAL_EXPERIENCE_AS_FAVORITE.FAILURE,
      ],
    },
  });
};

/**
 * DEPRECATED
 */
export const fetchOperationalExperiences = () => (dispatch, getState) => {
  const { system } = getState();

  return dispatch({
    [CALL_API]: {
      endpoint: "operational-experiences",
      options: {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${system.authToken}`,
        },
      },
      types: [
        FETCH_OPERATIONAL_EXPERIENCES.REQUEST,
        FETCH_OPERATIONAL_EXPERIENCES.SUCCESS,
        FETCH_OPERATIONAL_EXPERIENCES.FAILURE,
      ],
    },
  });
};

/**
 * action dispatched when searching for Operational Experiences.
 *
 * @searchTerm should be a keyword and is currently a
 * full word match. Will not return partials.
 *
 * @searchType is an optional enum arg which dictates which property
 * we are searching within. This will default to "incident"
 *
 *
 * @param searchTerm string
 * @param searchType "incident" | "description"
 */
export const searchOperationalExperiences = (searchTerm: string, searchType?: "incident" | "description") => (
  dispatch,
  getState,
) => {
  const { system } = getState();

  const _searchType = searchType ? searchType : "incident";

  const searchQuery = `query=${_searchType}:${searchTerm}`;

  return dispatch({
    [CALL_API]: {
      endpoint: `operational-experiences?${searchQuery}`,
      options: {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${system.authToken}`,
        },
      },
      types: [
        SEARCH_OPERATIONAL_EXPERIENCES.REQUEST,
        SEARCH_OPERATIONAL_EXPERIENCES.SUCCESS,
        SEARCH_OPERATIONAL_EXPERIENCES.FAILURE,
      ],
    },
  });
};

export const fetchSingleOperationalExperience = (operationalExperienceId: number) => (dispatch, getState) => {
  const { system } = getState();

  return dispatch({
    [CALL_API]: {
      endpoint: `operational-experiences/${operationalExperienceId}`,
      options: {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${system.authToken}`,
        },
      },
      types: [
        FETCH_SINGLE_OPERATIONAL_EXPERIENCE.REQUEST,
        FETCH_SINGLE_OPERATIONAL_EXPERIENCE.SUCCESS,
        FETCH_SINGLE_OPERATIONAL_EXPERIENCE.FAILURE,
      ],
    },
  });
};

export const createOperationalExperience = (operationalExperience: OperationalExperience) => (
  dispatch,
  getState,
) => {
  const { system } = getState();

  return dispatch({
    [CALL_API]: {
      endpoint: "operational-experiences",
      options: {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${system.authToken}`,
        },
        body: JSON.stringify(operationalExperience),
      },
      types: [
        CREATE_OPERATIONAL_EXPERIENCE.REQUEST,
        CREATE_OPERATIONAL_EXPERIENCE.SUCCESS,
        CREATE_OPERATIONAL_EXPERIENCE.FAILURE,
      ],
    },
  });
};

export const updateOperationalExperience = (operationalExperience: OperationalExperience) => (
  dispatch,
  getState,
) => {
  const { system } = getState();

  return dispatch({
    [CALL_API]: {
      endpoint: "operational-experiences",
      options: {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${system.authToken}`,
        },
        body: JSON.stringify(operationalExperience),
      },
      types: [
        UPDATE_OPERATIONAL_EXPERIENCES.REQUEST,
        UPDATE_OPERATIONAL_EXPERIENCES.SUCCESS,
        UPDATE_OPERATIONAL_EXPERIENCES.FAILURE,
      ],
    },
  });
};

export const deleteOperationalExperience = (operationalExperienceId: number) => (dispatch, getState) => {
  const { system } = getState();

  return dispatch({
    [CALL_API]: {
      endpoint: `operational-experiences/${operationalExperienceId}`,
      options: {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${system.authToken}`,
        },
      },
      types: [
        DELETE_OPERATIONAL_EXPERIENCE.REQUEST,
        DELETE_OPERATIONAL_EXPERIENCE.SUCCESS,
        DELETE_OPERATIONAL_EXPERIENCE.FAILURE,
      ],
    },
  });
};

export const resetAPIStatusOE = () => ({
  type: RESET_API_STATUS_OE,
});
