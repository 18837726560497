import styled from "styled-components";
import { SingleLineTextInputProps } from "./SingleLineTextInput";
import { remCalc } from "themes/helpers";
import mediaQueries from "themes/mediaQueries";

const SingleLineTextInput = styled.div<Partial<SingleLineTextInputProps>>``;

export { SingleLineTextInput };

export const DisabledTextFieldValue = styled.span`
  font-size: ${remCalc(14)};
  margin: 0.5rem 1rem 0 1rem;
  @media (${mediaQueries.maxPhone}) {
    font-size: ${remCalc(16)};
  }
`;
