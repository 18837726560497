import React from "react";
import ReactDOM from "react-dom";

import * as S from "./styles";

export interface PortalProps {
  children?: React.ReactNode;
  onClick?: (e?: any) => void;
  variant: "default" | "backdrop";
}

const Portal = ({ children, variant, onClick }: PortalProps) => {
  const el = document.getElementById("backdrop-root");

  if (!el) {
    return null;
  }

  if (variant === "backdrop") {
    return ReactDOM.createPortal(<S.Backdrop onClick={onClick ? onClick : () => {}} />, el);
  }

  return ReactDOM.createPortal(React.cloneElement(<>{children}</>), el);
};

export default Portal;
