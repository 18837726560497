import React, { useContext } from "react";
import styled, { ThemeContext } from "styled-components";

import Icon from "components/common/Icon";

import * as S from "./styles";

const DayFilter = styled.span.attrs({
  className: "d-block",
})`
  color: ${({ theme }) => theme.masterColors.darkGrey};
  font-size: 0.75rem;
`;

const ConstructionIcon = () => {
  const theme = useContext(ThemeContext);
  return (
    <Icon
      color={theme.masterColors.warning}
      framed={true}
      type="icon-icons8-under_construction"
    />
  );
};

const ChartHeader = ({ dayFilterValue, totalResponseCount }) => (
  <div className="d-flex w-100 justify-content-between">
    <div>
      <span style={{ fontSize: "0.875rem" }} className="d-block">
        Safety Scale Responses
      </span>
      <DayFilter>
        {dayFilterValue}
      </DayFilter>
      <span style={{ fontSize: "3rem" }}>{totalResponseCount}</span>
    </div>
    <S.IconWrapper>
      <ConstructionIcon />
    </S.IconWrapper>
  </div>
);

export default ChartHeader;
