import { GeoPoint } from "../common/types";

export const GET_WORK_LOCATION = {
  REQUEST: "GET_WORK_LOCATION_REQUEST",
  SUCCESS: "GET_WORK_LOCATION_SUCCESS",
  FAILURE: "GET_WORK_LOCATION_FAILURE",
};

export type WorkLocationContent = {
  id: number;
  locationId: string;
  locationType: string;
  name: string;
  nickname: string;
  locationInformationUrl: string;
  preferUsersLocation: boolean;
  geolocation: GeoPoint | null;
  physicalAddress?: {
    id: number;
    line1: string;
    line2: string;
    line3: string;
    city: string;
    state: string;
    zip: string;
    addressString: string;
  };
  emergencyAddress?: {
    id: number;
    line1: string;
    line2: string;
    line3: string;
    city: string;
    state: string;
    zip: string;
    addressString: string;
  };
  supervisor: string | null;
  isCompletedData: boolean;
};

export type WorkLocation = {
  active: boolean;
  content: WorkLocationContent;
  dataSourceId: null;
  id: number;
  source: string;
  uniqueId: string;
};

export type FlattenedWorkLocation = {
  id: number;
  name: string;
  address?: string;
  geolocation?: GeoPoint | null;
  type: string;
};

export type WorkLocationState = {
  loading: boolean;
  error: any;
  locations: Array<WorkLocation>;
  flattenedLocations: Array<FlattenedWorkLocation>;
};

// @TODO FINALIZE THIS TYPE
export interface WorkLocationDTO {
  content: WorkLocationContent;
  dataSourceId: null;
  id: number;
  source: string;
  uniqueId: string;
}
