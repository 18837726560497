import React, {
  FocusEventHandler,
  useState,
  MouseEventHandler,
  useRef,
  useMemo,
  forwardRef,
  useImperativeHandle,
} from "react";
import { css } from "aphrodite";

import { Label } from "../../Label/futureUiKit/Label";
import styles from "./styles";
import { Adornment, AssistiveLink, ClickableAdornment } from "../../types";
import { FormikErrors, FormikTouched } from "formik";
import { joinClassNames } from "themes/helpers";
import { useThemedStyleSheetWithProps } from "util/hooks/useThemedStyleSheet";

export interface TextInputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  hasNativeAutocomplete?: boolean;
  assistiveLink?: AssistiveLink;
  elementBefore?: React.ReactNode;
  endAdornment?: ClickableAdornment;
  error?: FormikErrors<unknown>;
  helperText?: string;
  required?: boolean;
  label?: string;
  labelClass?: string;
  rounded?: boolean;
  smallLabel?: boolean;
  startAdornment?: Adornment;
  touched?: FormikTouched<unknown>;
  warning?: string;
}

interface TextInputRef {
  focus: () => void;
}

// TODO start and end adornments if they are necessary, if not remove from props -JA
/** Basic html text input with optional label */
export const TextInput = forwardRef<TextInputRef, TextInputProps>(
  function TextInput(
    {
      assistiveLink,
      autoFocus,
      className = "TextInput",
      disabled,
      elementBefore,
      error,
      hasNativeAutocomplete = true,
      helperText,
      id,
      required,
      label,
      labelClass,
      name,
      onBlur,
      onChange,
      onFocus,
      onKeyDown,
      placeholder,
      rounded,
      smallLabel,
      touched,
      value,
      warning,
    },
    ref
  ) {
    const input = useRef<HTMLInputElement | null>(null);
    const [isMouseFocused, setIsMouseFocused] = useState(false);
    const [isMouseBlur, setIsMouseBlur] = useState(false);
    const styleProps = useMemo(() => {
      return { disabled, rounded };
    }, [disabled, rounded]);
    const s = useThemedStyleSheetWithProps(styles, styleProps);

    const handleBlur: FocusEventHandler<HTMLInputElement> = (event) => {
      setIsMouseBlur(isMouseFocused);
      setIsMouseFocused(false);
      if (onBlur) {
        onBlur(event);
      }
    };

    const handleFocus: FocusEventHandler<HTMLInputElement> = (event) => {
      setIsMouseBlur(false);
      if (onFocus) {
        onFocus(event);
      }
    };

    const handleMouseDown: MouseEventHandler = () => {
      setIsMouseFocused(true);
    };

    useImperativeHandle(ref, () => ({
      focus: () => {
        input.current?.focus();
      },
    }));

    return (
      <div className={css(s.column)}>
        {label && (
          <Label
            assistiveLink={assistiveLink}
            className={labelClass}
            content={label}
            htmlFor={name}
            required={required}
            smallLabel={smallLabel}
          />
        )}
        <div className={css(s.row)}>
          {elementBefore && (
            <div className={css(s.beforeContainer)}>{elementBefore}</div>
          )}
          <div className={css(s.column)}>
            <input
              autoComplete={hasNativeAutocomplete ? "on" : "off"}
              autoFocus={autoFocus}
              className={joinClassNames(
                css(
                  s.input,
                  isMouseFocused && s.inputMouseFocused,
                  isMouseBlur && s.inputMouseBlurred
                ),
                className
              )}
              disabled={disabled}
              id={id}
              name={name}
              onBlur={handleBlur}
              onChange={onChange}
              onFocus={handleFocus}
              onKeyDown={onKeyDown}
              onMouseDown={handleMouseDown}
              placeholder={placeholder}
              ref={input}
              type="text"
              value={value}
            />
            {helperText && (
              <span className={css(s.helperText)}>{helperText}</span>
            )}

            {error && <span className={css(s.errorText)}>{error}</span>}
            {warning && <span className={css(s.warningText)}>{warning}</span>}
          </div>
        </div>
      </div>
    );
  }
);
