import React, { useState } from "react";
import { css } from "aphrodite/no-important";
import { useDispatch } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { AppState } from "store";
import { Action } from "redux";
import { useFormikContext } from "formik";

import {
  addDefenseToQuestionSelection,
  removeDefenseFromQuestionSelection,
  getDefenseById,
} from "store/builder/actions";
import { Defense } from "store/documents/types";
import { FBForm } from "components/clientAdmin/formBuilder/types";
import { Input } from "components/common/form/types";
import { NewSelection, NewFormItem } from "store/builder/types";
import { QuestionSelectionsDTO } from "store/forms/types";
import { SuggestionType } from "components/common/form/MultiInput/MultiInput";
import { TextButton } from "components/common/Button";
import MultiInput from "components/common/form/MultiInput";

import CustomAnswerOptionsDrawer from "../../drawers/CustomAnswerOptionsDrawer";
import propertiesStyles from "../../styles";
import ReorderButtons from "./ReorderButtons";
import selectionStyles from "../Selections/styles";
import { isFormPublished } from "components/clientAdmin/formBuilder/helpers";

function pluralize(array: unknown[]): string {
  return array.length === 1 ? "" : "s";
}

function renderSelectionSublabel(selection: NewSelection): string {
  const { defenseIds, tags } = selection;
  return `Related: ${defenseIds.length} Defense${pluralize(defenseIds)}, ${
    tags.length
  } OE tag${pluralize(tags)}`;
}

/**
 * Add or remove a defense to/from a question selection value
 * @param options current option values
 * @param optionId option to update
 * @param defenseId defense to add/remove from the option
 * @param remove optional - should remove or not
 */
function updateOptionDefenses(
  options: NewSelection[],
  optionId: number,
  defenseId: number,
  remove?: boolean
): NewSelection[] {
  return options.map((option) => {
    if (option.id === optionId) {
      // we only allow 1 defense per option right now, so just replace it
      const defenseIds = remove ? [] : [defenseId];
      return { ...option, defenseIds };
    }
    return option;
  });
}

interface Props extends Input {
  name: string;
  onUpdate: (values: NewSelection[]) => Promise<QuestionSelectionsDTO[]>;
  selectedOptions: NewSelection[];
  itemPath?: string;
}

const emptySelection: NewSelection = {
  id: null,
  rootId: null,
  defenseIds: [],
  title: "",
  tags: [],
};

const CustomAnswerOptions: React.FC<Props> = ({
  selectedOptions,
  name,
  onUpdate,
  itemPath,
}: Props) => {
  const [showDrawer, setShowDrawer] = useState<boolean>(false);
  const [activeItem, setActiveItem] = useState<NewSelection>(emptySelection);

  const { values, setFieldValue } = useFormikContext<FBForm>();

  const ps = propertiesStyles();

  const selectionsWithDetails = selectedOptions.map((option) => ({
    ...option,
    subLabel: renderSelectionSublabel(option),
  }));

  const ss = selectionStyles();
  const dispatch = useDispatch<ThunkDispatch<AppState, void, Action>>();

  async function onRemoveItem(
    updatedOptions: SuggestionType[],
    removedValueId?: number | string
  ): Promise<void> {
    if (removedValueId) {
      const updatedValues = (updatedOptions as unknown) as NewFormItem<
        QuestionSelectionsDTO
      >[];
      await onUpdate(updatedValues);
    }
  }

  /**
   * Add a defense to a selection by adding the selection ID to the defense's questionSelectionIds
   * @param optionId option to add to the defense
   * @param defense defense to update
   * @param options current option values
   */
  async function onAddDefense(
    optionId: number,
    defense: Defense,
    options: NewSelection[]
  ): Promise<void> {
    // add the question selection to the defense
    await dispatch(addDefenseToQuestionSelection(defense, optionId));
    // update the question selection defenseIds in the form values
    const finalOptions = updateOptionDefenses(options, optionId, defense.id);
    setFieldValue(name, finalOptions, false);
  }

  /**
   * Remove a defense from a selection by removing the selection from the defense's questionSelections
   * @param optionId option from which to remove the defense
   * @param defense defense to update
   * @param options current option values
   */
  async function onRemoveDefense(
    optionId: number,
    defense: Defense,
    options: NewSelection[]
  ): Promise<void> {
    // remove the question selection from the defense
    await dispatch(removeDefenseFromQuestionSelection(defense, optionId));
    // update the question selection defenseIds in the form values
    const finalOptions = updateOptionDefenses(
      options,
      optionId,
      defense.id,
      true
    );
    setFieldValue(name, finalOptions, false);
  }

  async function updateDefenses(
    option: NewSelection,
    defense: Defense,
    updatedOptions: QuestionSelectionsDTO[]
  ): Promise<void> {
    const addedDefense = option.defenseIds.includes(defense.id)
      ? null
      : defense;
    const defenseOption = option.id
      ? (option as QuestionSelectionsDTO)
      : updatedOptions[updatedOptions.length - 1];
    if (addedDefense && defenseOption) {
      // remove any existing defenses before adding the new one
      // we're only allowed to have 1 defense per selection, but
      // some old forms and Form Builder bugs have more than 1 and
      // defenseIds is an array, so cycling through just in case
      if (option.defenseIds.length > 0) {
        option.defenseIds.forEach(async (dId) => {
          const res = await dispatch(getDefenseById(dId));
          if (res.response) {
            await dispatch(
              removeDefenseFromQuestionSelection(res.response, defenseOption.id)
            );
          }
        });
      }
      await onAddDefense(defenseOption.id, defense, updatedOptions);
    }
  }

  async function onSaveOption(
    option: NewSelection,
    defense?: Defense,
    removeDefense?: boolean
  ): Promise<void> {
    let options: NewSelection[];
    // if the option already exists, replace it
    if (option.id) {
      options = selectedOptions.map((sOption) => {
        if (sOption.rootId === option.rootId) return option;
        return sOption;
      });
    } else {
      // if the option is new, add it to the array
      options = [...selectedOptions, option];
    }
    if (option.id && defense && removeDefense) {
      await onRemoveDefense(option.id, defense, options);
    }
    // PUT the options to the form
    const updatedOptions = await onUpdate(options);

    // need to get updated option in order to pass in most recent id
    const updatedOption =
      updatedOptions.find((uo) => uo.rootId === option.rootId) || option;

    // Once the option has been added to the form, add/update the defense
    // await so that onSave doesn't complete until the defenses have been updated
    if (defense && !removeDefense) {
      await updateDefenses(updatedOption, defense, updatedOptions);
    }
  }

  const isPublished = isFormPublished(values.workflowType);

  return (
    <>
      <MultiInput
        className={css([ps.multiInput, ss.multiInput])}
        labelField="title"
        idField="id"
        subLabelField="subLabel"
        selectedValues={(selectionsWithDetails as unknown) as SuggestionType[]}
        onUpdateValues={onRemoveItem}
        itemClassName={css(ss.multiInputItem)}
        disabled={isPublished}
        onClickItem={(_, index: number) => {
          setActiveItem(
            (selectionsWithDetails[index] as unknown) as NewSelection
          );
          setShowDrawer(true);
        }}
        itemStartAdornment={(item: SuggestionType) => {
          const itemIndex = selectedOptions.findIndex(
            (sel) => sel.id?.toString() === item.id?.toString()
          );

          return isPublished ? null : (
            <ReorderButtons
              hide={{
                top: itemIndex === 0,
                bottom: itemIndex === selectedOptions.length - 1,
              }}
              onReorder={(index: number) => {
                const finalOptions = [...selectedOptions];
                const option = finalOptions.splice(itemIndex, 1)[0];
                finalOptions.splice(itemIndex + index, 0, option);
                setFieldValue(name, finalOptions, false);
                if (itemPath) {
                  setFieldValue(`${itemPath}.workflowType`, "DRAFT", false);
                }
              }}
            />
          );
        }}
      />
      {showDrawer && (
        <CustomAnswerOptionsDrawer
          initialValues={activeItem}
          onSaveOption={onSaveOption}
          show={showDrawer}
          disabled={isPublished}
          closeDrawer={() => {
            setActiveItem(emptySelection);
            setShowDrawer(false);
          }}
        />
      )}
      {!isPublished && (
        <TextButton
          className={css(ps.textButton)}
          onClick={() => setShowDrawer(true)}
        >
          + Add Option
        </TextButton>
      )}
    </>
  );
};

export default CustomAnswerOptions;
