import styled from "styled-components";

import TextInput from "components/forms/TextInput";
import { Button } from "components/forms/Button";
import { devices } from "themes/mediaQueries";
import { remCalc } from "themes/helpers";

export const TextArea = styled(TextInput)`
  margin-top: 1.05rem;
`;

export const FeedbackTypeWrapper = styled.div`
  width: 50%;
  @media (max-width: ${devices.maxPhone}px) {
    width: 100%;
  }
`;

export const ButtonsWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-bottom: 1.05rem;
  margin-top: 1.05rem;

  @media (max-width: ${devices.maxPhone}px) {
    flex-direction: column;
    justify-content: center;
  }  
`;

export const SubmitButton = styled(Button)`
  && {
    border-radius: 4px;
    color: ${({ disabled, theme }) => disabled && theme.colors.primary};
    width: 188px !important;
    @media (max-width: ${devices.maxPhone}px) {
      font-size: ${remCalc(14)};
      width: 100% !important;
    }
  }

  &&.root {
    background-color: ${({ disabled, theme }) => (disabled ? theme.colors.primary : null)};
  }
`;

export const Icon = styled.i`
  font-size: ${remCalc(24)};
  left: 16px;
  position: absolute;
`;

export const CancelButton = styled(Button)`
  && {
    background-color: ${({ theme }) => theme.colors.white};
    border-radius: 4px;
    display: ${({ disabled }) => disabled && "none"};
    width: 60px !important;

    @media (max-width: ${devices.maxPhone}px) {
      font-size: ${remCalc(14)};
    }
  }
`;
