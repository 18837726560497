import React, { useState } from "react";
import { connect } from "react-redux";

import downloadFileFromBlob from "downloadjs";

import { callApi } from "middleware/api";
import { AppState } from "store";
import Loader from "components/common/Loader";
import { SearchDocumentsParams } from "../../store/documents/types";

interface DownloadResultsButtonProps {
  url: string;
  params?: SearchDocumentsParams;
  type: string;
  fileName: string;
  authToken: string | null;
  className?: string;
  children: JSX.Element | string;
}

/**
 * DownloadResultsButton wraps the passed children with a clickable span.
 * When clicked, the resulting URL is fetched and downloaded as a file.
 *
 * @param url Relative URL from which to download
 * @param params Object of query string parameters
 * @param fileName Desired name of downloaded file
 * @param type File type (text/csv, etc)
 * @param authToken User's Bearer token for Authorization header
 * @param className
 * @param children
 */
const DownloadResultsButton = ({
  url,
  params,
  type,
  fileName,
  authToken,
  className,
  children,
}: DownloadResultsButtonProps) => {
  const [loading, setLoading] = useState(false);

  if (params) {
    const queryStr = Object.entries(params).map(([key, val]) => `${key}=${val}`);
    url += url.includes("?") ? "&" : "?";
    url += queryStr.join("&");
  }

  const doExport = () => {
    setLoading(true);
    callApi(url, {
      headers: {
        "Content-Type": type,
        Accept: type,
        Authorization: `Bearer ${authToken}`,
      },
    }).then((blob) => {
      setLoading(false);
      downloadFileFromBlob(blob, fileName, type);
    });
  };

  return (
    <div className={className}>
      <Loader loading={loading} className="p-0" spinnerProps={{ size: 24, style: { lineHeight: 0 } }}>
        <span onClick={doExport}>{children}</span>
      </Loader>
    </div>
  );
};

export default connect((state: AppState) => ({
  authToken: state.system.authToken,
}))(DownloadResultsButton);
