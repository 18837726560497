import React, { useEffect, useState } from "react";
import moment from "moment";
import { connect } from "react-redux";
// import { useLocation } from "react-router";

import Loader from "components/common/Loader";
import { Data, DataLink } from "../styles";
import { DocumentHistory, DocumentSummary } from "store/documents/types";
import { DocumentHistoryTableHeader } from "./DocumentHistoryTableHeader";
import { DocumentHistoryType } from "components/DocumentSidebarDrawer/DocumentHistorySidebar/DocumentHistory";
import { DocumentVM } from "store/documents/types";
import { getDocumentHistory } from "store/documents/actions";

// Instead of padding an addition prop down 2 components, show links if location matches these routes
// const routesWithHistoryLinks = ["/", "/documents"];

// Prettified document status constants
const documentStatuses = {
  created: "Created",
  original: "Original",
  rehuddled: "Rehuddled",
  related: "Related",
  autoSync: "Auto saved",
};

const getLinkTo = (type: string, id: number | null) => {
  if (
    id &&
    [
      documentStatuses.original,
      documentStatuses.rehuddled,
      documentStatuses.related,
    ].includes(type)
  ) {
    return `/document/${id}`;
  }
  return "";
};

const dataRow = (
  column1: string,
  column2: string,
  column3: string,
  linkTo: string,
  i: number
) => {
  return (
    <React.Fragment key={i}>
      {linkTo ? (
        <DataLink className="col-4" href={linkTo} target="_blank">
          {column1}
        </DataLink>
      ) : (
        <Data className="col-4">{column1}</Data>
      )}
      <Data className="col-3 ellipsis">{column2}</Data>
      <Data className="col-5 text-right">{column3}</Data>
    </React.Fragment>
  );
};

// API enum CREATED, SAVED_AS_DRAFT, SUBMITTED, REHUDDLE, DELETE, AUTO_SYNC
const prettifyHistoryStatus = (
  statuses: string,
  documentId: number,
  historyDocumentId: number | null,
  isRehuddle: DocumentHistory["isRehuddle"]
) => {
  /**
   *
   * @param statuses          - historyType in history
   * @param documentId        - id of the original document
   * @param historyDocumentId - documentId in history
   * @param isRehuddle        - isRehuddle in history
   */

  const isOriginal = documentId !== historyDocumentId;
  const isCreated = documentId === historyDocumentId;

  if (statuses) {
    switch (statuses) {
      case "CREATED":
        if (isOriginal) return documentStatuses.original;
        return documentStatuses.created;
      case "SUBMITTED":
        if (isOriginal) return documentStatuses.original;
        return "Submitted";
      case "REHUDDLE":
        if (isCreated) return documentStatuses.created;
        if (isRehuddle) return documentStatuses.rehuddled;
        return documentStatuses.related;
      case "DELETE":
        if (isOriginal) return documentStatuses.original;
        return "Deleted";
      case "SAVED_AS_DRAFT":
        if (isOriginal) return documentStatuses.original;
        return "Saved as Draft";
      case "AUTO_SYNC":
        if (isOriginal) return documentStatuses.original;
        return documentStatuses.autoSync;
      default:
        return "-";
    }
  } else {
    return "-";
  }
};

const mapDispatch = {
  getDocumentHistory: getDocumentHistory,
};
const connector = connect(null, mapDispatch);
type Props = {
  document: DocumentVM | DocumentSummary;
  getDocumentHistory: (
    id: string | number
  ) => Promise<{ type: string; response: DocumentHistory[] }>;
  type: DocumentHistoryType;
};

const DocumentHistoryTable = ({
  getDocumentHistory,
  document,
  type = "history",
}: Props) => {
  // Store document history from API
  const [documentHistory, setDocumentHistory] = useState<DocumentHistory[]>([]);
  // If fetching history
  const [documentHistoryLoading, setDocumentHistoryLoading] = useState<boolean>(
    true
  );
  // If there was an error fetching history
  const [documentHistoryError, setDocumentHistoryError] = useState<boolean>(
    false
  );

  // // Get location
  // const location = useLocation();

  // Get document history from API
  useEffect(() => {
    setDocumentHistoryLoading(true);
    setDocumentHistoryError(false);
    getDocumentHistory(document.id).then((e) => {
      setDocumentHistoryLoading(false);
      if (e.type === "GET_DOCUMENT_HISTORY_SUCCESS") {
        // On success, store document history locally
        if (type === "history") {
          setDocumentHistory(e.response);
        } else {
          setDocumentHistory(
            e.response?.filter((e) => e.parentDocumentId === document.id)
          );
        }
      } else {
        setDocumentHistoryError(true);
      }
    });
  }, [document.id]);

  // const isUserDocumentsDrawer = useMemo(() => {
  //   return routesWithHistoryLinks.includes(location.pathname);
  // },[location]);
  return (
    <div className="pt-3 pb-3">
      {/* <DocumentHistoryTableHeader /> */}
      <Loader loading={documentHistoryLoading} error={documentHistoryError}>
        {documentHistory?.length > 0 ? (
          <>
            <DocumentHistoryTableHeader />
            <div className="row no-gutters">
              {documentHistory.map((e: DocumentHistory, i) => {
                const historyType = prettifyHistoryStatus(
                  e.historyType,
                  document.id,
                  e.documentId,
                  e.isRehuddle
                );
                return dataRow(
                  historyType,
                  (e.participant && e.participant.name) || "-",
                  (e.historyDate &&
                    moment(e.historyDate).format("MM/DD/YY - HH:mm")) ||
                    "-",
                  getLinkTo(historyType, e.documentId),
                  i
                );
              })}
            </div>
          </>
        ) : (
          "No data available"
        )}
      </Loader>
    </div>
  );
};

export default connector(DocumentHistoryTable);
