import React from "react";

import * as S from "../styles";

export interface HeaderProps {
  children: string;
}

const Header = ({ children }: HeaderProps) => {
  return <S.Header>{children}</S.Header>;
};

Header.defaultProps = {
  children: "Header",
};

export default Header;
