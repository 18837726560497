import { PaginationParams } from "store/common/types";
import {
  ResourcesFiltersParams,
  RESOURCES_FILTERS,
  SetResourcesFiltersAction,
  SetResourcesPaginationAction,
} from "./types";

export const setResourcesFilters = (
  filters: ResourcesFiltersParams
): SetResourcesFiltersAction => ({
  response: filters,
  type: RESOURCES_FILTERS.SET,
});

export const setResourcesPagination = (
  pagination: PaginationParams
): SetResourcesPaginationAction => ({
  response: pagination,
  type: RESOURCES_FILTERS.PARAMS_SET,
});
