import React, { useRef, useCallback } from "react";
import { TextInput, TextInputProps } from "./TextInput";

import * as S from "../styles";
import { FieldErrorType } from "components/FormController/components/Question";

export interface TextInputSuggestion {
  [keyName: string]: any;
}

interface TextInputWithSuggestionsProps extends TextInputProps {
  error?: FieldErrorType;
  idField: string;
  labelField: string;
  onInputChange: (value: string) => void;
  onSelectSuggestion: (suggestion: TextInputSuggestion) => void;
  suggestions: TextInputSuggestion[];
  value: string;
  customItemRef?: React.RefObject<HTMLDivElement>;
}

export const TextInputWithSuggestions = ({
  idField,
  labelField,
  onInputChange,
  onSelectSuggestion,
  suggestions = [],
  value,
  customItemRef,
  ...textInputProps
}: TextInputWithSuggestionsProps) => {
  // const MENU_ANCHOR = useRef(null);

  // Ref should work but doesn't, position is same regardless of what component
  // it's attached to. When it's fixed to work, it should be passed to the input
  // in TextInput directly, to not include assistive text and errors in calculations -- GK

  // Temporary solution because we need to go fast -- GK
  const { helperText, error } = textInputProps;
  const positionOffset = useCallback(() => {
    let result = 25;
    if (helperText || error) result = 3;
    if (helperText && error) result = -20;
    return `${result}px !important`;
  }, [helperText, error]);

  return (
    // <div ref={MENU_ANCHOR}>
    <div ref={customItemRef}>
      <TextInput
        {...textInputProps}
        hasNativeAutocomplete={false}
        value={value}
        onChange={(e) => onInputChange(e.target.value)}
      />
      <S.SuggestionsPopper
        open={suggestions.length > 0}
        // anchorEl={MENU_ANCHOR.current}
        disablePortal
        placement="bottom"
        positionOffset={positionOffset}
      >
        <S.SuggestionsOptions>
          {suggestions.map((suggestion) => {
            return (
              <S.SuggestionsOption
                onMouseDown={(e: React.MouseEvent) => e.preventDefault()}
                onClick={() => onSelectSuggestion(suggestion)}
                key={suggestion[idField]}
              >
                {suggestion[labelField]}
              </S.SuggestionsOption>
            );
          })}
        </S.SuggestionsOptions>
      </S.SuggestionsPopper>
    </div>
  );
};
