import React, { useState, FC } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { useFormik } from "formik";
import * as Yup from "yup";
import styled from "styled-components";

import TextInput from "components/common/form/TextInput";
import Toast from "components/common/Toast";
import Button, { TextButton } from "components/common/Button";
import {
  saveResourceCategory,
  CategorySaveVM,
} from "store/resourceCategories/actions";
import { ThunkDispatch } from "redux-thunk";
import { AppState } from "store";
import { Action } from "redux";
import { SAVE_RESOURCE_CATEGORY } from "store/resourceCategories/types";

interface Props {
  initialValues: CategorySaveVM;
}

const validationSchema = () =>
  Yup.object().shape({
    title: Yup.string().required("Title is required"),
  });

const Wrapper = styled.div`
  width: 398px;
`;

const ResourceCategoryForm: FC<Props> = ({ initialValues }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [errorToastVisible, setErrorToastVisible] = useState<boolean>(false);
  const [error, setError] = useState<string>("");

  const history = useHistory();

  const dispatch = useDispatch<ThunkDispatch<AppState, void, Action>>();

  const handleSubmit = async (values: CategorySaveVM) => {
    setLoading(true);
    setError("");
    setErrorToastVisible(false);
    let _error = false;
    const res = await dispatch(saveResourceCategory(values));
    if (res.type === SAVE_RESOURCE_CATEGORY.SUCCESS) {
      setLoading(false);
    }
    if (res.type === SAVE_RESOURCE_CATEGORY.FAILURE) {
      _error = true;
      setLoading(false);
      setErrorToastVisible(true);
      setError(res.error);
    }
    if (!_error) {
      history.push("/content/resource-categories");
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    onSubmit: (values) => {
      handleSubmit(values);
    },
    validationSchema,
  });

  return (
    <Wrapper>
      <form onSubmit={formik.handleSubmit}>
        <TextInput
          error={formik.errors["title"]}
          label="Category"
          name="title"
          onChange={formik.handleChange}
          placeholder="Category"
          touched={formik.touched["title"]}
          value={formik.values["title"]}
          variant="outlined"
        />
        <Toast
          variant="error"
          onClick={() => setErrorToastVisible(false)}
          visible={errorToastVisible}
        >
          {error}
        </Toast>
        <br />
        <Button type="submit" loading={loading}>
          Save
        </Button>
        <TextButton variant="cancel" onClick={() => history.goBack()}>
          Cancel
        </TextButton>
      </form>
    </Wrapper>
  );
};

export default ResourceCategoryForm;
