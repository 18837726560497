import React, { useContext } from "react";
import styled, { ThemeContext } from "styled-components";

import { remCalc, toRgba } from "../../../themes/helpers";
import { TextButton } from "components/common/Button";
import { H1 } from "../styles";
import { devices } from "../../../themes/mediaQueries";
import TextInput from "components/common/form/TextInput";
import { SubmitButton } from "../../forms/Button";
import { Select } from "../../common/form/Select";
import { CommonStyles } from "components/common/styles";
import Icon, { IconProps } from "components/common/Icon";

export default {
  ColumnDiv: CommonStyles.ColumnDiv,
  RowDiv: CommonStyles.RowDiv,
  BlockSpan: CommonStyles.BlockSpan,
  DivWithBottomMargin: CommonStyles.DivWithBottomMargin,

  PointerDiv: styled.div`
    cursor: pointer;
    height: 17px;
  `,

  TitleWrapper: styled(CommonStyles.RowDiv)`
    justify-content: space-between;
  `,

  Title: styled(H1)`
    margin-bottom: ${remCalc(24)};
  `,

  Subtitle: styled(CommonStyles.BlockSpan)`
    font-size: ${remCalc(16)};
    color: ${({ theme }) => theme.masterColors.darkGrey};
    letter-spacing: ${remCalc(0.15)};
    line-height: ${remCalc(24)};
    margin-bottom: ${remCalc(24)};
  `,

  Link: styled.span`
    color: ${({ theme }) => theme.colors.primary};
    text-decoration: underline;
    cursor: pointer;
  `,

  Medium: styled.span`
    font-weight: 500;
  `,

  NoResultsWrapper: styled.div`
    display: flex;
    border-radius: 4px;
    width: 400px;
    height: 50px;
    font-size: 18px;
    background-color: ${({ theme }) =>
      toRgba(theme.masterColors.darkGrey, 0.1)};
  `,

  NoResultsText: styled.span`
    color: ${({ theme }) => theme.colors.primary};
    margin: auto;
  `,

  Input: styled(TextInput)`
    width: ${remCalc(398)};
  `,

  NoteContainer: styled(CommonStyles.ColumnDiv)`
    margin-top: ${remCalc(44)};
  `,

  Note: styled.div`
    height: max-content;
    display: flex;
    flex-direction: column;
    width: ${remCalc(469)};
    background-color: ${({ theme }) => theme.colors.activeFill};
    border-radius: ${remCalc(4)};
    color: ${({ theme }) => theme.masterColors.darkGrey};
    font-size: ${remCalc(14)};
    letter-spacing: ${remCalc(0.13)};
    line-height: ${remCalc(21)};
    padding: ${remCalc(8)};
    margin-bottom: ${remCalc(16)};
  `,

  InputWithNote: styled(CommonStyles.RowDiv)`
    justify-content: space-between;
  `,

  Select: styled(Select)`
    width: ${remCalc(398)};
  `,

  CancelButton: styled(TextButton)`
    && {
      height: ${remCalc(36)};
      background-color: ${({ theme }) => theme.colors.white};
      vertical-align: middle;
      padding: 0 ${remCalc(35)};
      border-radius: 4px;
      display: ${({ disabled }) => disabled && "none"};
      margin: ${remCalc(34)} 0 0;

      @media (max-width: ${devices.maxPhone}px) {
        font-size: ${remCalc(14)};
      }
    }
  `,

  ModalHeader: styled(CommonStyles.BlockSpan)`
    font-size: ${remCalc(20)};
    letter-spacing: ${remCalc(0.26)};
    line-height: ${remCalc(26)};
    font-weight: 500;
    margin-bottom: ${remCalc(8)};
    margin-left: ${remCalc(10)};
  `,

  ModalText: styled(CommonStyles.BlockSpan)`
    font-size: ${remCalc(16)};
    letter-spacing: ${remCalc(0.28)};
    line-height: ${remCalc(21)};
    margin-bottom: ${remCalc(24)};
    margin-left: ${remCalc(10)};
    color: ${({ theme }) => theme.masterColors.darkGrey};
  `,

  ToggleContainer: styled(CommonStyles.RowDiv)`
    justify-content: center;
    width: 100%;
  `,

  ToggleButton: styled.div<{ selected: boolean }>`
    display: flex;
    justify-content: center;
    height: ${remCalc(100)};
    width: ${remCalc(120)};
    border-radius: ${remCalc(4)};
    ${({ selected, theme }) =>
      selected ? `background-color: ${theme.colors.activeFill};` : ""}
    border: 1px solid ${({ selected, theme }) =>
      selected ? theme.colors.primary : theme.colors.lightGrey};
    margin: 0 ${remCalc(24)};
    font-size: ${remCalc(14)};
    letter-spacing: ${remCalc(0.24)};
    color: ${({ theme }) => theme.masterColors.darkGrey};
    :first-of-type {
      margin-left: 0;
    }
    :last-of-type {
      margin-right: 0;
    }
    &:hover {
      background-color: ${({ theme }) => theme.colors.activeFill};
      cursor: pointer;
    }
  `,

  ButtonContainer: styled(CommonStyles.RowDiv)`
    justify-content: right;
  `,

  ToggleContent: styled(CommonStyles.ColumnDiv)`
    align-content: center;
    justify-content: center;
    text-align: center;
  `,

  MetricColumn: styled(CommonStyles.ColumnDiv)`
    margin-right: ${remCalc(60)};
  `,

  Metric: styled(CommonStyles.ColumnDiv)`
    margin-bottom: ${remCalc(16)};
  `,

  SmallTitle: styled(CommonStyles.BlockSpan)`
    font-size: ${remCalc(12)};
    letter-spacing: ${remCalc(1.88)};
    line-height: ${remCalc(16)};
    font-weight: 500;
    color: ${({ theme }) => theme.masterColors.darkGrey};
    margin-bottom: ${remCalc(8)};
  `,

  SmallText: styled(CommonStyles.BlockSpan)`
    font-size: ${remCalc(14)};
  `,

  DataItem: styled(CommonStyles.BlockSpan)`
    font-size: ${remCalc(16)};
    letter-spacing: ${remCalc(0.15)};
    line-height: ${remCalc(24)};
  `,

  PreviewHeader: styled(CommonStyles.BlockSpan)`
    font-size: ${remCalc(20)};
    letter-spacing: ${remCalc(0.19)};
    line-height: ${remCalc(24)};
    font-weight: 500;
    margin-bottom: ${remCalc(16)};
  `,

  SectionHeader: styled(CommonStyles.BlockSpan)<{ disabled: boolean }>`
    font-size: ${remCalc(36)};
    color: ${({ theme, disabled }) =>
      disabled ? theme.colors.lightGrey : theme.colors.primary};
    letter-spacing: ${remCalc(0.63)};
    line-height: ${remCalc(48)};
    margin-bottom: ${remCalc(24)};
  `,

  FormSection: styled.div`
    margin-left: ${remCalc(39)};
  `,

  TableButtons: styled(CommonStyles.RowDiv)`
    justify-content: space-between;
  `,

  EditButton: styled(TextButton)`
    display: flex;
    height: ${remCalc(48)};
    width: ${remCalc(76)};
    margin: 0;
    padding: 0;
    text-align: center;
    justify-content: center;
  `,

  ImportButton: styled(TextButton)`
    display: initial;
    height: ${remCalc(36)};
    width: ${remCalc(248)};
    margin: 0 0 ${remCalc(24)};
    padding: ${remCalc(8)} 0;
    text-align: right;
  `,

  DownloadButton: styled(SubmitButton)`
    margin: 0 0 ${remCalc(24)} !important;
  `,

  PNG: styled.img`
    margin-top: ${remCalc(16)};
  `,

  TableWrapper: styled.div`
    border: 1px solid ${({ theme }) => theme.colors.lightGrey};
    border-radius: ${remCalc(4)};
    width: 100%;
    padding: ${remCalc(16)};
    background-color: ${({ theme }) => theme.masterColors.lightWhite};
    margin-bottom: ${remCalc(24)};
    overflow-x: scroll;
    overflow-y: hidden;
  `,

  HeaderRow: styled(CommonStyles.RowDiv)`
    margin-bottom: ${remCalc(16)};
  `,

  DataRow: styled(CommonStyles.RowDiv)`
    margin-bottom: ${remCalc(17)};
  `,

  TH: styled.div`
    width: ${remCalc(219)};
    min-width: ${remCalc(219)};
    margin-right: ${remCalc(43)};
  `,

  TD: styled.div`
    font-size: ${remCalc(14)};
    color: ${({ theme }) => theme.masterColors.darkGrey};
    width: ${remCalc(262)};
    min-width: ${remCalc(262)};
    padding-right: ${remCalc(43)};
    border-bottom: 1px solid ${({ theme }) => theme.colors.lightGrey};
    height: ${remCalc(46)};
  `,

  MockFieldLabel: styled(CommonStyles.BlockSpan)`
    font-size: ${remCalc(20)};
    font-weight: 500;
    letter-spacing: ${remCalc(0.19)};
    line-height: ${remCalc(26)};
  `,

  MockFieldValue: styled(CommonStyles.BlockSpan)`
    padding: ${remCalc(8)} ${remCalc(16)} 0;
  `,

  DarkIcon: styled<React.FC<IconProps>>(({ type, ...otherProps }) => {
    const theme = useContext(ThemeContext);
    return (
      <Icon color={theme.masterColors.darkGrey} type={type} {...otherProps} />
    );
  })``,
};
