import React from "react";
import { ErrorMessage } from "formik";
import { css } from "aphrodite/no-important";

import styles from "./styles";

const FieldErrorMessage = ({ fieldName }: { fieldName: string }) => {
  const s = styles();

  return (
    <ErrorMessage
      component="span"
      className={css(s.fieldError)}
      name={fieldName}
    />
  );
};

export default FieldErrorMessage;
