import * as R from "ramda";
import React, { useContext, useState, useEffect } from "react";
import { Action } from "redux";
import { ThemeContext } from "styled-components";
import { ThunkDispatch } from "redux-thunk";
import { useDispatch } from "react-redux";

import { FormTypeDTO } from "store/forms/types";
import NewDocumentCard, { NewDocumentCardStyle } from "components/common/NewDocumentCard";
import { StartNewDocumentButton, StartNewDocumentButtonType } from "components/common/NewDocumentCard/styles";
import Loader from "components/common/Loader";

import { FormType } from "./Dashboard"; 

import { ThemeTypes } from "themes/colors";
import { getAllFormTypes } from "store/forms/actions";


/**
 * helps us ensure we're rendering an expected form type
 * checks if the form name is one of our `FormTypes`
 */
function isFormType(val: string): val is FormType {
  return (val as string) === "pre job brief" || (val as string) === "safety observation";
}

interface Props {
  mobile?: boolean;
  selectFormTypeAndOpenNewDocumentDrawerFor: (formType: FormType) => void;
}

/**
 * Used to render Actionable elements to "quick start" a new document.
 * Depending on the viewport width, we'll either return Cards or Buttons.
 * This is used in `Dashboard.tsx`
 */
const RenderQuickStartButtons: React.FC<Props> = ({
  mobile,
  selectFormTypeAndOpenNewDocumentDrawerFor,
}: Props) => {
  const theme = useContext(ThemeContext);

  // State
  const [loading, setLoading] = useState<boolean>(false);
  const [formTypes, setFormTypes] = useState<Array<FormTypeDTO>>([]);

  // Dispatch
  const dispatch = useDispatch<ThunkDispatch<null, void, Action>>();

  // Fetch and store form types
  const fetchFormTypes = async () => {
    setLoading(true);
    const res = await dispatch(getAllFormTypes({ sort: "name,asc" }));
    if (res?.response) setFormTypes(res.response);
    setLoading(false);
  };

  // On mount
  useEffect(() => {
    (async () => {
      await fetchFormTypes();
    })();
  }, []);


  if (loading) {
    return <Loader loading />;
  }

  if (mobile) {
    return (
      <>
        {formTypes.filter((formType) => formType.active).map((formType) => {
          const formTypeName: string = formType.name.toLowerCase();
          if (!isFormType(formTypeName)) {
            return null;
          }
          const buttonType = getStartNewDocumentButtonType(theme, formTypeName);
          return (
            <StartNewDocumentButton
              key={formType.id}
              onClick={() => selectFormTypeAndOpenNewDocumentDrawerFor(formTypeName)}
              {...buttonType}
            >
              {formTypeName}
            </StartNewDocumentButton>
          );
        })}
      </>
    );
  } else {
    return (
      <>
        {formTypes?.filter((formType) => formType.active).map((formType) => {
          const formTypeName: string = formType.name.toLowerCase();
          const iconColor = R.pathOr(theme.colors.primary, ["iconColor"], formType);
          const iconName = R.pathOr("", ["iconName"], formType);

          if (!isFormType(formTypeName)) {
            return null;
          }

          return (
            <NewDocumentCard
              key={formType.id}
              buttonText="start"
              icon={<i className={iconName} />}
              onClick={() => selectFormTypeAndOpenNewDocumentDrawerFor(formTypeName)}
              style={getNewDocumentCardStyle(theme, formTypeName, iconColor)}
              text={formTypeName.toUpperCase()}
            />
          );
        })}
      </>
    );
  }
};

const getNewDocumentCardStyle = (
  theme: ThemeTypes,
  formTypeName: string,
  iconColor: string
): NewDocumentCardStyle => {
  const style: NewDocumentCardStyle = {
    button: {},
    icon: {
      color: iconColor,
    },
    text: {},
    wrapper: {},
  };

  switch (formTypeName) {
    // @TODO these form types shouldn't be hardcoded
    case "pre job brief":
      style.text.color = theme.colors.white;
      style.wrapper.background = theme.colors.primary;
      break;

    case "safety observation":
      style.text.color = theme.colors.primary;
      style.wrapper.background = theme.colors.white;
      style.wrapper.border = theme.colors.lightGrey;
      break;
  }

  return style;
};

const getStartNewDocumentButtonType = (
  theme: ThemeTypes,
  formTypeName: string
): StartNewDocumentButtonType => {
  switch (formTypeName) {
    // @TODO these form types shouldn't be hardcoded
    case "pre job brief":
      return {
        background: theme.colors.primary,
        textColor: theme.colors.white,
      };

    case "safety observation":
      return {
        background: theme.colors.white,
        borderColor: theme.colors.lightGrey,
        textColor: theme.colors.primary,
      };

    default:
      return {};
  }
};

export default RenderQuickStartButtons;
