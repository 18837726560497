import styled from "styled-components";

import TextInput from "components/forms/TextInput";
import { Button } from "components/forms/Button";
import { devices } from "themes/mediaQueries";
import { remCalc } from "themes/helpers";

export const TextArea = styled(TextInput)`
  margin-top: 1.05rem;
`;

export const ButtonsWrapper = styled.div`
  margin-top: 1.05rem;
  margin-bottom: 1.05rem;
`;

export const SubmitButton = styled(Button)`
  && {
    border-radius: 4px;
    color: ${({ disabled, theme }) => disabled && theme.colors.primary};
    @media (max-width: ${devices.maxPhone}px) {
      font-size: ${remCalc(14)};
    }
  }

  &&.root {
    background-color: ${({ disabled, theme }) => (disabled ? theme.colors.primary : null)};
  }
`;

export const Icon = styled.i`
  font-size: ${remCalc(24)};
  left: 16px;
  position: absolute;
`;

export const CancelButton = styled(Button)`
  && {
    background-color: #fff;
    border-radius: 4px;
    display: ${({ disabled }) => disabled && "none"};
    margin-top: 10px !important;

    @media (max-width: ${devices.maxPhone}px) {
      font-size: ${remCalc(14)};
    }
  }
`;
