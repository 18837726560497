import styled from "styled-components";
import { toRgba } from "themes/helpers";

export const PopperWrapper = styled.div`
  width: 320px;
  // height: 161px;
  // align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px 12px;
  position: relative;

  &:before {
    content: "";
    position: absolute;
    right: -24px;
    top: calc(50% - 15px);
    // z-index: -1;
    width: 15px;
    height: 15px;
    background-color: ${({ theme }) => theme.colors.white};
    border-right: 1px solid ${({ theme }) => toRgba(theme.colors.black, 0.1)};
    border-bottom: 1px solid ${({ theme }) => toRgba(theme.colors.black, 0.1)};
    transform: rotate(-45deg) translate(-14px, -7px);
  }
`;

type PopperRowType = {
  color?: string;
  marginBottom?: boolean;
};

export const PopperRow = styled.div<PopperRowType>`
  align-items: center;
  color: ${({ color, theme }) => (color ? color : theme.masterColors.darkGrey)};
  cursor: pointer;
  display: flex;
  font-size: 0.875rem;
  justify-content: space-between;
  margin-bottom: 15px;
  width: 100%;
  &:last-of-type {
    margin-bottom: 0;
  }
`;

export const PopperChevron = styled.i.attrs({
  className: "icon icon-icons8-chevron-left",
})<{
  color?: string;
}>`
  color: ${({ color, theme }) => color || theme.masterColors.darkGrey} !important;
  display: inline-block;
  transform: rotate(180deg);
  font-size: 25px;
  margin-left: 30px;
`;

export const Option = styled.span`
  display: block;
`;

export const DangerOption = styled(Option)`
  color: ${({ theme }) => theme.colors.error};
`;
