import React from "react";
import { NavLink } from "react-router-dom";

import * as S from "./styles";

type LinkProps = {
  to: string;
  children: React.ReactNode;
  className?: string;
};

const Link = ({ to, className, children }: LinkProps) => {
  return (
    <NavLink to={to}>
      <S.Link className={className}>{children}</S.Link>
    </NavLink>
  );
};

export default Link;
