import styled from "styled-components";
import { toRgba } from "themes/helpers";

export const Wrapper = styled.div`
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.colors.lightGrey};
  padding: 24px 16px;
  width: 300px;
`;

export const Title = styled.div`
  color: #${({ theme }) => toRgba(theme.colors.black, 0.87)};
  font-size: 0.875rem;
  text-align: left;
`;

export const Total = styled.div`
  color: ${({ theme }) => theme.colors.black};
  font-size: 3rem;
  text-align: left;
`;

export const Row = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
`;

export const RowNameWrapper = styled.div`
  align-items: center;
  color: ${({ theme }) => theme.colors.black};
  display: flex;
  flex-direction: row;
  font-size: 0.875rem;
  width: 75%;
`;

export const RowValueWrapper = styled.div`
  color: ${({ theme }) => theme.colors.black};
  display: flex;
  flex-direction: row;
  font-size: 0.875rem;
  justify-content: space-between;
  width: 25%;
  @media (max-width: 1300px) {
    justify-content: flex-end;
  }
`;

type RowNameIconType = {
  color: string;
};
export const RowNameIcon = styled.div<RowNameIconType>`
  align-items: center;
  border-radius: 20px;
  border: 0.5px solid ${({ theme }) => theme.colors.white};
  background-color: ${({ color }) => color};
  display: flex;
  height: 16px;
  margin-right: 8px;
  width: 16px;
`;

export const RowNameContent = styled.div``;

export const RowValuePercentage = styled.div`
  @media (max-width: 1300px) {
    display: none;
  }
`;

export const RowValueAmount = styled.div``;

export const Bar = styled.div`
  border-radius: 4px;
  border: 0.5px solid ${({ theme }) => theme.colors.white};
  display: flex;
  flex-direction: row;
  height: 18px;
  margin-bottom: 14px;
  overflow: hidden;
  width: 100%;
`;

type BarItemType = {
  color: string;
  width: number;
};
export const BarItem = styled.div<BarItemType>`
  background-color: ${({ color }) => color};
  height: 16px;
  width: ${({ width }) => `${width}%`};
  background-image: ${({ theme }) =>
    `linear-gradient(180deg, ${toRgba(theme.colors.white, 0.35)}, ${toRgba(
      theme.masterColors.darkGrey,
      0.03
    )})`};
`;
