import React, { Dispatch, SetStateAction, useState } from "react";

import Drawer from "components/common/Drawer";
import InterstitialDrawerContent from "./components/InterstitialDrawerContent";
import useStartCloneDoc from "./useStartCloneDoc";
import { DisplayConditionDTO } from "store/documents/types";
import { DocumentSummary, DocumentVM } from "store/documents/types";

interface Props {
  displayConditions?: DisplayConditionDTO[];
  submissionTimeError?: string;
  formId?: number;
  formType?: string;
  formTypeId?: number;
  isDesktop: boolean;
  isOpen: boolean;
  onSuccess: () => void;
  selectedDocument: DocumentSummary | DocumentVM | null;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  title?: string;
}

export const InterstitialDrawer = ({
  displayConditions = [],
  formId,
  formType,
  submissionTimeError,
  isDesktop,
  isOpen,
  onSuccess,
  selectedDocument,
  setIsOpen,
  title = "",
}: Props) => {
  const [error, setError] = useState<string>("");
  const { handleStartCloneDoc } = useStartCloneDoc();

  const handleSubmit = () => {
    if (selectedDocument !== null) {
      handleStartCloneDoc({
        selectedDocument,
        formId,
        displayConditions,
        onSuccess,
        onError: setError,
        isRehuddle: false,
      });
    }
  };

  const handleClose = () => {
    setIsOpen(false);
    setError("");
  };

  return (
    <Drawer
      content={
        <InterstitialDrawerContent
          formType={formType}
          title={title}
          onClose={handleClose}
          onSubmit={handleSubmit}
          error={error}
          submissionTimeError={submissionTimeError}
        />
      }
      anchor="right"
      disableBackdrop
      paperProps={{ elevation: 0 }}
      id="FormTemplatesDrawer"
      onClose={handleClose}
      onOpen={() => undefined}
      open={isOpen}
      overrideClasses={{
        container: "drawerContainerNoBackdrop",
        content: isDesktop ? "myDocumentsDrawerDesktop" : "",
      }}
      showCloseIcon={false}
    />
  );
};
