import React, { useEffect } from "react";
import { Action } from "redux";
import { get } from "lodash";
import { ThunkDispatch } from "redux-thunk";
import { useDispatch, useSelector } from "react-redux";

import { AppState } from "store";
import {
  DataSourceItem,
  SubmitButtonLabel,
} from "./ItemSelectorDrawer/ItemSelectorForm/ItemSelectorForm";
import {
  DataSourceValueDTO,
  PaginatedDataSourceResponse,
  ParticipantProps,
} from "store/dataSource/types";
import { QuestionAnswerSourceDTO } from "store/forms/types";
import {
  getDataSourceValues,
  searchDataSourceValues,
} from "store/dataSource/actions";
import useDebouncedValue from "util/hooks/useDebouncedValue";
import { ItemSelectorDrawer } from "./ItemSelectorDrawer";

// TODO remove unused props when component is finalized -JA
export interface DataSourceDrawerProps {
  allowMultipleAnswers?: boolean;
  allowWriteIn?: boolean;
  answerSource: QuestionAnswerSourceDTO;
  isFinalPage?: boolean;
  isLoadingMore?: boolean;
  isOpen: boolean;
  onChangeOpen: (isOpen: boolean) => void;
  onLoadMore?: () => void;
  onSubmit: (values: DataSourceItem[]) => void;
  selected?: DataSourceItem[];
  submitButtonLabel: SubmitButtonLabel;
}

/**
 * Format data source values into selections
 * @param dataSourceValues  - values of a data source matching a specific key
 * @param answerSource      - contains metadata and properties for a data source
 */
function buildItems(
  dataSourceValues: DataSourceValueDTO[],
  answerSource: QuestionAnswerSourceDTO
): DataSourceItem[] {
  const { properties, dataSourceKey } = answerSource;

  return dataSourceValues.map((dsv) => {
    const title = get(dsv.content, properties.answerField, "name not found");
    const id = properties.associatedIdField
      ? get(dsv.content, properties.associatedIdField)
      : dsv.content.id.toString();
    const value = get(dsv.content, properties.displayField || "", title);
    const associatedLocation = get(
      dsv.content,
      properties.associatedLocationField || ""
    );

    let subtitle: string | undefined;
    let participant: ParticipantProps | undefined;
    if (dataSourceKey === "PARTICIPANT") {
      participant = dsv.content as ParticipantProps;
      const { clientGroups, primaryGroupId } = dsv.content;
      subtitle = clientGroups?.find((group) => group.id === primaryGroupId)
        ?.name;
    }

    if (dataSourceKey === "WORK_LOCATION") {
      const { physicalAddress } = dsv.content;
      if (physicalAddress) {
        subtitle = physicalAddress.addressString;
      }
    }

    return {
      id,
      title,
      value,
      subtitle,
      associatedLocation,
      participant,
    };
  });
}

const DataSourceDrawer = ({
  allowMultipleAnswers,
  allowWriteIn,
  answerSource,
  isOpen,
  onChangeOpen,
  onSubmit,
  selected,
  submitButtonLabel,
}: DataSourceDrawerProps) => {
  const dataSourceKey = answerSource.dataSourceKey;

  const [error, setError] = React.useState<string>();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [loadingMore, setLoadingMore] = React.useState<boolean>(false);
  const [options, setOptions] = React.useState<DataSourceItem[]>([]);
  const [query, setQuery] = React.useState<string>();
  const [page, setPage] = React.useState<number>(0);
  const [last, setLast] = React.useState<boolean>(false);

  const dispatch = useDispatch<ThunkDispatch<AppState, void, Action>>();

  const detailedSearch = answerSource.properties.detailedSearch;
  const title = detailedSearch?.title || "...";
  const subTitle = detailedSearch?.subtitle || "...";
  const searchPlaceholder = detailedSearch?.searchBy || "...";
  const listTitle = detailedSearch?.infiniteListTitle || "";
  const sortBy = detailedSearch?.infiniteListSortBy;
  const size = 100;

  const loadedDSVIsLastPage = useSelector(
    ({ dataSource }: AppState) => dataSource.isLastPage[dataSourceKey!]
  );

  function clearState() {
    setLast(loadedDSVIsLastPage);
    setPage(0);
    !!dataSourceKey && init(dataSourceKey);
  }

  /**
   * Update stateful values depending on API response
   * @param apiRes
   * @param append - append new results to existing options?
   */
  function updateState(apiRes: PaginatedDataSourceResponse, append = false) {
    if (apiRes.response?.content) {
      if (apiRes.response.last) {
        setLast(true);
      }
      setPage((currPage) => currPage + 1);
      setOptions((currOptions) => {
        const withPrev = append ? currOptions : [];
        return [
          ...withPrev,
          ...buildItems(apiRes.response?.content || [], answerSource),
        ];
      });
    } else if (apiRes.error) {
      setError(apiRes.error);
    }
  }

  async function init(keyName: string) {
    setLoading(true);
    setPage(0);
    const getDSVs = await dispatch(
      getDataSourceValues({
        keyName,
        sortBy,
        page: 0,
        size,
      })
    );
    if (getDSVs) {
      updateState(getDSVs);
    }
    setLoading(false);
  }
  /* get first page of data sources on load */
  useEffect(() => {
    if (!last && options.length === 0 && !loading && dataSourceKey) {
      init(dataSourceKey);
    }
  }, []);

  /** Load more options and append them to existing options */
  async function onLoadMore() {
    if (dataSourceKey) {
      let getDSVs: PaginatedDataSourceResponse | undefined;
      /* query has been set from previous searches */
      if (query && query.length > 2) {
        setLoadingMore(true);
        getDSVs = await dispatch(
          searchDataSourceValues({
            keyName: dataSourceKey,
            sortBy,
            page,
            query,
            size,
          })
        );
        /* query has not been set - get options based on data source key */
      } else {
        setLoadingMore(true);
        getDSVs = await dispatch(
          getDataSourceValues({
            keyName: dataSourceKey,
            sortBy,
            page,
            size,
          })
        );
      }
      if (getDSVs) {
        updateState(getDSVs, true);
      }
      setLoadingMore(false);
    }
  }

  const debouncedQuery = useDebouncedValue(query, 500);

  /* search dsv on query change */
  useEffect(() => {
    if (query && query.length > 2 && dataSourceKey) {
      (async () => {
        setLoading(true);
        setPage(0);
        const search = await dispatch(
          searchDataSourceValues({
            keyName: dataSourceKey,
            query,
            sortBy,
            page: 0,
            size: 100,
          })
        );
        if (search) {
          updateState(search);
        }
        setLoading(false);
      })();
    }
  }, [debouncedQuery]);

  // reset results if query is cleared, checking type so we dont run on load
  useEffect(() => {
    if (typeof query === "string" && query.length === 0) {
      clearState();
    }
  }, [debouncedQuery]);

  return (
    <ItemSelectorDrawer
      allowWriteIn={allowWriteIn}
      error={error}
      handleChangeOpen={onChangeOpen}
      handleChangeSearch={setQuery}
      handleSubmit={onSubmit}
      hasMultiSelect={allowMultipleAnswers}
      isLastPage={last}
      isLoadingMore={loadingMore}
      isLoadingSearchResults={loading}
      isOpen={isOpen}
      listTitle={listTitle}
      name={`${title}_${answerSource.id}`}
      noResultsMessage="No Results Found"
      onLoadMore={onLoadMore}
      searchLabel={searchPlaceholder || "Search"}
      searchPlaceholder={
        searchPlaceholder ||
        answerSource?.properties.detailedSearch?.searchBy ||
        "Search..."
      }
      options={options}
      selected={selected || []}
      submitButtonLabel={submitButtonLabel}
      subtitle={subTitle}
      title={title}
      titleId={`${title}_${answerSource.id}`} // @todo is this unique or is answerSource.id shared between questions?
    />
  );
};

export default DataSourceDrawer;
