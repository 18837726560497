import styled from "styled-components";

import { Chevron } from "components/common/TableUI/Pagination/styles";
import { remCalc, toRgba } from "themes/helpers";

// --------------------------------------------------------------------------
// ? Documents Component
// --------------------------------------------------------------------------

export const Documents = styled.div`
  margin: 10px;
`;

export const DesktopDocuments = styled.div`
  margin-left: 15px;
`;

export const DesktopMaxWidth = styled.div`
  max-width: 960px;
`;

export const OverFlow = styled.div``;

// --------------------------------------------------------------------------
// ? DocumentSidebar Drawers
// --------------------------------------------------------------------------

export const CloseDrawerIconWrapper = styled.div`
  cursor: pointer;
  left: 15px;
  position: absolute;
  top: 17px;
`;

export const ChevronIcon = styled(Chevron)`
  :hover {
    cursor: pointer;
  }
`;

// Try to not use this, there's a StickyHeader and Header in Drawer/componets -- GK
export const SidebarDrawerWrapper = styled.div`
  box-shadow: 0px 1px 3px ${({ theme }) => theme.masterColors.lightGrey};
  line-height: 1.5rem;
  margin-bottom: 16px;
  padding-bottom: 1.05rem;
  padding-top: 1.05rem;
  position: absolute;
  text-align: center;
  width: 100%;
  height: 56px;
  left: 0;
  top: 0;
`;

// Try to not use this, there's a StickyHeader and Header in Drawer/componets -- GK
export const StickySidebarDrawerWrapper = styled(SidebarDrawerWrapper)`
  backgroundcolor: ${({ theme }) => theme.colors.white};
  padding-left: 18px;
  padding-right: 18px;
  position: sticky;
  width: auto;
`;

export const SidebarDrawerHeader = styled.h6`
  color: ${({ theme }) => theme.masterColors.black};
  font-weight: 500;
  font-size: 1.25rem;
  letter-spacing: 0.26px;
`;

export const SidebarDrawerBody = styled.p`
  color: ${({ theme }) => theme.masterColors.black};
  font-size: ${remCalc(14)};
  letter-spacing: 0.24px;
`;

export const SidebarDrawerContent = styled.div`
  margin-top: 56px;
  padding-left: 18px;
  padding-right: 18px;
  text-align: left;
`;

// --------------------------------------------------------------------------
// ? DocumentSidebarMenu
// --------------------------------------------------------------------------

export const DocumentSidebarMenu = styled.div``;

// --------------------------------------------------------------------------
// ? DocumentSidebar
// --------------------------------------------------------------------------

export const DocumentSidebar = styled.div``;

// --------------------------------------------------------------------------
// ? MobileSearchFilterSidebar
// --------------------------------------------------------------------------

export const MobileSearchFilterSidebar = styled.div``;

export const WrapperForNegMargin = styled.div`
  margin-right: -16px;
  margin-top: -8px;
`;

export const MobileFiltersHeader = styled.div.attrs({})`
  color: ${({ theme }) => toRgba(theme.colors.black, 0.87)};
  font-size: ${remCalc(20)};
  font-weight: 700;
  letter-spacing: 0.26px;
  margin-top: 5px;
  text-align: left;
`;

export const DividerNegMargin = styled.hr`
  margin-left: -15px;
  margin-right: -15px;
`;

export const FilterSectionHeader = styled.div`
  color: ${({ theme }) => theme.colors.black};
  font-size: 0.875rem;
  text-align: left;
`;

export const FilterOption = styled.div.attrs({
  className: "col-12 pl-0",
})``;

export const ApplyButtonWrapper = styled.div`
  padding-right: 16px;
  text-align: center;
`;

// Greys aren't changing with theme, it seems like
export const ApplyButton = styled.div`
  border-radius: 4px;
  border: 1px solid ${({ theme }) => toRgba(theme.colors.black, 0.1)};
  color: ${(props) => props.theme.colors.primary};
  font-size: 0.875rem;
  font-weight: 500;
  letter-spacing: 1.25;
  margin: 16px 22px 16px 0;
  padding: 8px 0;
  text-transform: uppercase;
  width: 100%;
`;

// --------------------------------------------------------------------------
// ? DesktopSearchFilters
// --------------------------------------------------------------------------

export const DesktopSearchFilters = styled.div``;

// --------------------------------------------------------------------------
// ? RehuddleSidebar
// --------------------------------------------------------------------------

export const ButtonsWrapper = styled.div``;
export const SubmitButton = styled.button``;
