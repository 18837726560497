import React from "react";
import * as S from "./styles";

interface endAdornment {
  type: "button" | "label";
  buttonType?: "reset" | "change";
  onClick?: (e: any) => void;
  /**
   * can be a `string` or a React Component
   */
  label: string | React.ReactNode;
  visible: boolean;
  hideTextDecoration?: boolean;
}

const RenderEndAdornment = ({
  endAdornment: {
    type,
    onClick,
    label,
    visible,
    buttonType,
    hideTextDecoration,
  },
  active,
}: {
  endAdornment: endAdornment;
  active: boolean;
  tertiary?: string;
}) => {
  if (!visible) return null;

  if (type === "label") {
    return (
      <S.ListItemSecondaryAction flexEnd={true}>
        <S.ListItemText active={active} secondary={label} />
      </S.ListItemSecondaryAction>
    );
  }

  if (type === "button") {
    return (
      <S.ListItemSecondaryAction>
        <S.ListItemButton
          hideTextDecoration={hideTextDecoration}
          buttonType={buttonType ? buttonType : "change"}
          type="reset"
          onClick={onClick}
        >
          {label}
        </S.ListItemButton>
      </S.ListItemSecondaryAction>
    );
  }
  return null;
};

export type VariantType =
  | "ItemSelectorDrawer"
  | "Base"
  | "participant-selected";
export type ListItemColumnVariant =
  | "default"
  | "auto-width"
  | "max-width"
  | "participant-list-item"
  | "form-list-item";

export interface ListItemProps {
  active: boolean;
  address?: string;
  className?: string;
  ellipses?: boolean;
  endAdornment?: endAdornment;
  icon?: {
    type?: string;
    color?: string;
    framed: boolean;
  };
  label: string;
  listItemColumnVariant: ListItemColumnVariant;
  onClick?: () => void;
  rounded?: boolean;
  row?: boolean;
  startAdornment?: React.ReactNode;
  subLabel?: string;
  tabIndex?: number;
  tertiaryLabel?: string;
  /**
   * type which dictates behavior. Default is "Base", if you
   * need to extend the behavior of ListItem then you can add
   * a new variant which can be leveraged when conditionally
   * setting styles or rendering
   */
  variant: VariantType;
  wrapSubLabel?: boolean;
}

const ListItem = ({
  active,
  onClick,
  rounded,
  label,
  listItemColumnVariant,
  icon,
  subLabel,
  address,
  endAdornment,
  startAdornment,
  className,
  row,
  variant,
  wrapSubLabel,
  tabIndex,
  tertiaryLabel,
}: ListItemProps) => {
  return (
    <S.ListItem className={`${className} listItem`}>
      <S.MUIListItem
        variant={variant}
        active={active}
        rounded={rounded}
        onClick={onClick}
        tabIndex={tabIndex}
      >
        {startAdornment}
        {/* 
          listItemColumnVariant hard-coded to "auto-width"
          since we'll mainly use this Column for a
          conditionally rendered element
          @NOTE: you can conditionally set the listItemColumnVariant
          if needed by using `icon`
        */}
        {icon && icon.type && icon.color && (
          <S.Column listItemColumnVariant="auto-width">
            <S.ListItemIcon>
              <S.Icon
                framed={icon.framed}
                className={
                  icon.type.includes("icon")
                    ? icon.type
                    : `icon icon-icons8-${icon.type}`
                }
                color={icon.color}
              />
            </S.ListItemIcon>
          </S.Column>
        )}

        <S.Column listItemColumnVariant={listItemColumnVariant}>
          <S.DynamicRow renderAsColumn={!row}>
            <S.Row wrapSubLabel={wrapSubLabel} column={!!tertiaryLabel}>
              <S.ListItemText
                active={active}
                primary={label}
                secondary={address || subLabel}
                variant={variant}
              />
              {tertiaryLabel && (
                <S.TertiaryLabel as="p">{tertiaryLabel}</S.TertiaryLabel>
              )}
            </S.Row>

            {subLabel && row && (
              <S.Row variant={variant}>
                <S.ListItemText
                  active={active}
                  secondary={subLabel}
                  variant={variant}
                />
              </S.Row>
            )}

            {tertiaryLabel && row && (
              <S.Row variant={variant}>
                <S.ListItemText
                  active={active}
                  secondary={subLabel}
                  variant={variant}
                />
              </S.Row>
            )}
          </S.DynamicRow>
        </S.Column>

        {endAdornment && endAdornment.visible && (
          <RenderEndAdornment active={active} endAdornment={endAdornment} />
        )}
      </S.MUIListItem>
    </S.ListItem>
  );
};

ListItem.defaultProps = {
  label: "label",
  ellipses: false,
  rounded: true,
  active: false,
  renderLabelsAsRow: false,
  variant: "Base",
  listItemColumnVariant: "default",
};

export default ListItem;
