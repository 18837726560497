export enum GetFormVersionActionType {
  request = "GET_FORM_VERSION_REQUEST",
  success = "GET_FORM_VERSION_SUCCESS",
  failure = "GET_FORM_VERSION_FAILURE",
}

export type FormVersionDTO = {
  formId: number;
  id: number;
  lastModifiedBy?: string;
  lastModifiedDate: string;
  notes?: string;
  version: string;
}

export type FormVersionResponse = FormVersionDTO[];
