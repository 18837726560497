import React from "react";
import { css } from "aphrodite/no-important";
import { get } from "lodash";
import { useFormikContext } from "formik";

import { FBForm, FBItem, FBSection } from "../../types";
import baseStyles from "../../styles";
import styles from "./styles";
import useValidation, { FBValidationLevel, FBValidationType } from "./useValidation";
import ValidationLink from "./ValidationLink";

const getCopy = (alertLevel: FBValidationLevel): { title: string; description: string} => {
  if (alertLevel === "error") {
    return {
      title: "We’re sorry, we couldn’t save your form.",
      description: "You’re missing some required fields. Please correct the errors below and submit your form again."
    };
  }
  return {
    title: "You’ll need to correct the issues below before publishing",
    description: "You’re missing some required fields. Please correct the fields below before publishing your form."
  };
};

interface Props {
  onJumpToError: (errorKey: string, errorItem?: FBItem | FBSection) => void;
  errorType: FBValidationType;
}

const ValidationBanner = ({ onJumpToError, errorType }: Props) => {
  const { values } = useFormikContext<FBForm>();
  
  const { alertLevel, getErrorsByType } = useValidation();

  const validationErrors = getErrorsByType(errorType);

  const bs = baseStyles();
  const s = styles();

  const copy = getCopy(alertLevel);

  if (!validationErrors.length) return null;
  
  return (
    <div id={`fb-validation-${errorType}`} className={css(bs.alert, s.validationBanner, s.s16)}>
      <p className={css(bs.alertTitle, s.s16)}>{copy.title}</p>
      <p className={css(bs.alertBody, s.s16)}>
        <p>{copy.description}</p>

        {validationErrors?.map((valError) => {
          const { itemPath, errorPath, errorKey, error } = valError;
          const item = get(values, itemPath);

          return (
            <ValidationLink
              key={`${itemPath}-${errorKey}-error`}
              onClick={() => onJumpToError(errorKey, item)}
              title={errorPath}
              error={error}
            />
          );
        })}
      </p>
    </div>
  );
};

export default ValidationBanner;
