import React from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";

import * as documentsActions from "store/documents/actions";
import { SearchDocumentsParams } from "store/documents/types";

import BaseSearchBar from "./BaseSearchBar";

interface Props {
  mobile?: boolean;
  onChange: (e?: any) => void;
  openMobileFilters?: () => void;
  params: () => SearchDocumentsParams;
  placeholder?: string;
  query?: string;
  autoFocus?: boolean;

  // redux
  actions: {
    documents: {
      searchDocuments: (props: SearchDocumentsParams) => void;
    };
  };
}

const DocumentSearchBar = ({
  onChange,
  params,
  actions,
  query,
  ...props
}: Props) => {
  const search = (value) => {
    // Search Documents
    actions.documents.searchDocuments({ ...params(), query: value });

    // Search Work Orders
    // actions.workOrders.searchWorkOrders(value); // Disabled for now
  };

  return (
    <BaseSearchBar
      onChange={onChange}
      onSearch={search}
      initialQuery={query}
      {...props}
    />
  );
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  actions: {
    documents: bindActionCreators(documentsActions, dispatch),
  },
});

export default connect(null, mapDispatchToProps)(DocumentSearchBar);
