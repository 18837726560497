import React from "react";

import { DisabledTextFieldValue } from "components/forms/SingleLineTextInput/styles";
import * as S from "components/common/form/Label/styles";

interface Props {
  label?: string;
  value?: string;
}

const DisabledTextField = ({ label, value }: Props) => {
  return (
    <>
      <S.Label>
        <S.FieldLabel>{label}</S.FieldLabel>
      </S.Label>
      <DisabledTextFieldValue>{value}</DisabledTextFieldValue>
    </>
  );
};

export default DisabledTextField;
