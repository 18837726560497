import React, { useState } from "react";
import { css } from "aphrodite/no-important";

import { DataSource } from "store/dataSource/types";
import Button from "components/common/Button";
import DrawerHeader from "components/common/Drawer/components/Header";
import Label from "components/common/form/Label";
import Link from "components/common/Link";

import propertiesStyles from "../styles";
import DataSetFields from "../customFields/DataSetFields";
import Drawer from "./Drawer";
import { QuestionAnswerSourceDTO } from "store/forms/types";
import { Property } from "components/clientAdmin/formBuilder/types";

interface Props {
  initialValues?: QuestionAnswerSourceDTO | null;
  onAddDataSet: (dataSet: DataSource, sourceColumn: string, sortBy: string) => void;
  closeDrawer: () => void;
  show: boolean;
  detailedSearchProps?: Property[];
  itemPath?: string;
}

const DataSetDrawer = ({ initialValues, onAddDataSet, closeDrawer, show, detailedSearchProps, itemPath }: Props) => {
  const [dataSet, setDataSet] = useState<DataSource | null>(null);
  const [sourceColumn, setSourceColumn] = useState<string | null>(null);
  const [sortBy, setSortBy] = useState<string | null>(null);

  const ps = propertiesStyles();

  const handleAddDataSet = async () => {
    if (dataSet && sourceColumn && sortBy) {
      onAddDataSet(dataSet, sourceColumn, sortBy);
      closeDrawer();
    }
  };

  const setDataSetValues = (dataSet?: DataSource, sourceColumn?: string, sortBy?: string) => {
    if (dataSet) setDataSet(dataSet);
    if (sourceColumn) setSourceColumn(sourceColumn);
    if (sortBy) setSortBy(sortBy);
  };

  return (
    <Drawer
      anchor="right"
      content={
        <Drawer.Content>
          <DrawerHeader closeDrawer={closeDrawer} text="Add Data Sets" />
          <Drawer.Section>
            <Label className={css(ps.label)} htmlFor="">Add a Data Set as an Answer Source</Label>
            <Drawer.Description>
              Custom dropdown questions can utilize pre-built data sets for their answer options.{" "}
              Each record in the selected data set will display as a selectable answer option in{" "}
              a dropdown menu. In order to include a specific data set,{" "}
              <Link to="/forms/data-sets">build it here</Link>.
            </Drawer.Description>
            <DataSetFields
              initialValues={initialValues}
              onUpdate={setDataSetValues}
              detailedSearchProps={detailedSearchProps}
              itemPath={itemPath}
            />
          </Drawer.Section>
          <Drawer.ButtonContainer>
            <Button 
              type="submit" 
              onClick={handleAddDataSet} 
              disabled={!dataSet || !sourceColumn || !sortBy}
            >
              {initialValues ? "Edit" : "Add"} Data Set
            </Button>
          </Drawer.ButtonContainer>
        </Drawer.Content>
      }
      id="dataSetDrawer"
      onClose={closeDrawer}
      onOpen={() => undefined}
      open={show}
      showCloseIcon={false}
      variant="persistent"
    />
  );
};

export default DataSetDrawer;
