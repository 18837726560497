import { FilterParams, PaginationParams } from "store/common/types";

export const DATA_SETS_FILTERS = {
  SET: "DATA_SETS_FILTERS_SET",
  PARAMS_SET: "DATA_SETS_FILTERS_PARAMS_SET",
};

export interface DataSetsFiltersParams extends FilterParams {
  query: string;
}

export interface DataSetsFiltersArgs {
  filterParams: DataSetsFiltersParams;
  paginationParams: PaginationParams;
}

export interface SetDataSetsFiltersAction {
  type: typeof DATA_SETS_FILTERS.SET;
  response: DataSetsFiltersParams;
}

export interface SetDataSetsPaginationAction {
  type: typeof DATA_SETS_FILTERS.PARAMS_SET;
  response: PaginationParams;
}

export type FiltersActions = SetDataSetsFiltersAction;
