// import { bindActionCreators, Dispatch } from "redux";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import React from "react";
import moment from "moment";
import { connect } from "react-redux";

import { prettifyDocumentStatus } from "components/helpers";
import { SubText } from "../styles";
import { FadedText } from "../../styles";
import { Text } from "components/clientAdmin/styles";

import BackToTop from "components/common/TableUI/BackToTop";
import DefaultCell from "components/common/TableUI/DefaultCell";
import Pagination, {
  PaginationWrapper,
} from "components/common/TableUI/Pagination";
import RowsPerPage from "components/common/TableUI/RowsPerPage";
import { DocumentSummary } from "store/documents/types";
import { MoreVertIcon } from "components/common/Card/styles";
import {
  PageSize,
  CurrentPage,
} from "components/common/TableUI/Pagination/types";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "components/common/styled/Table";

import Loader from "components/common/Loader";
import { AppState } from "store";
import { DocumentDrawerSections } from "components/clientAdmin/documents/DocumentDrawer";
import { SortDirection } from "store/common/types";
import * as S from "./styles";

export const DateAndTime = ({ dateTime }: { dateTime: string | undefined }) => {
  if (!dateTime) {
    return null;
  }

  const date = moment(dateTime).format("MM/DD/YY");
  const time = moment(dateTime).format("HH:mm");
  return (
    <>
      <SubText>{date}</SubText>
      <FadedText>{time}</FadedText>
    </>
  );
};

export type DocumentsPopperProps = {
  popperIsOpen: boolean;
  setAnchorDocument: (document: DocumentSummary) => void;
  setAnchorEl: (e: string) => void;
  setPopperIsOpen: (flag: boolean) => void;
  setShowDocumentDrawer: (
    tab:
      | typeof DocumentDrawerSections.Document
      | typeof DocumentDrawerSections.History
  ) => void;
};

type ReportsDocumentsSort = {
  direction: SortDirection;
  type: string;
};

type ReportsDocumentsPrimarySort = {
  direction: SortDirection;
};

export type ReportsDocumentsSorting = {
  dateCreated: ReportsDocumentsPrimarySort;
  ["clientGroup.name"]: ReportsDocumentsPrimarySort;
  ["owner.lastName"]: ReportsDocumentsPrimarySort;
  primarySort: ReportsDocumentsSort;
  submissionDate: ReportsDocumentsPrimarySort;
  title: ReportsDocumentsPrimarySort;
};

export type DocumentsSortingProps = {
  sorting: ReportsDocumentsSorting;
  setSorting: (sorting: ReportsDocumentsSorting) => void;
};

export type DocumentsPaginationProps = {
  currentPage: CurrentPage;
  pageSize: PageSize;
};

interface DocumentsTableProps {
  documentPagination: { totalPages: number };
  documents: DocumentSummary[];
  loading: boolean; // From store
  onPaginate: (pageNum: number, numPerPage: number) => void;
  pagination: DocumentsPaginationProps;
  popper: DocumentsPopperProps;
  sorting: DocumentsSortingProps;
}

const DocumentsTable = ({
  documents,
  documentPagination: { totalPages },
  loading,
  onPaginate,
  pagination: { currentPage, pageSize },
  popper: {
    popperIsOpen,
    setAnchorDocument,
    setAnchorEl,
    setPopperIsOpen,
    setShowDocumentDrawer,
  },
  sorting: { setSorting, sorting },
}: DocumentsTableProps) => {
  const sortBy = (type: string, direction: SortDirection) => {
    setSorting({
      ...sorting,
      [type]: {
        direction,
      },
      primarySort: {
        direction,
        type,
      },
    });
  };

  // Open document in sidebar
  const viewDocument = (document: DocumentSummary) => {
    setAnchorDocument(document);
    setShowDocumentDrawer(DocumentDrawerSections.Document); // View document
  };

  const formatTitle = (title: string, isRehuddle: boolean): string => {
    if (isRehuddle) {
      return `[REHUDDLE] ${title}`;
    } else return title;
  };

  return (
    <Loader loading={loading}>
      <Table>
        <TableHead>
          <TableRow noBottomBorder>
            <TableCell
              active={sorting.primarySort.type === "title"}
              onClick={(dir) => sortBy("title", dir)}
              sortDirection={sorting.title.direction}
              minWidth="170px"
            >
              Title & Work ID
            </TableCell>
            <TableCell
              active={sorting.primarySort.type === "clientGroup.name"}
              onClick={(dir) => sortBy("clientGroup.name", dir)}
              sortDirection={sorting["clientGroup.name"].direction}
              width="150px"
            >
              Group
            </TableCell>
            <TableCell
              active={sorting.primarySort.type === "owner.lastName"}
              onClick={(dir) => sortBy("owner.lastName", dir)}
              sortDirection={sorting["owner.lastName"].direction}
              width="100px"
            >
              Owner
            </TableCell>
            <TableCell
              active={sorting.primarySort.type === "dateCreated"}
              onClick={(dir) => sortBy("dateCreated", dir)}
              sortDirection={sorting.dateCreated.direction}
              width="120px"
            >
              Created
            </TableCell>
            <TableCell
              active={sorting.primarySort.type === "submissionDate"}
              onClick={(dir) => sortBy("submissionDate", dir)}
              sortDirection={sorting.submissionDate.direction}
              width="135px"
            >
              Submitted
            </TableCell>
            <TableCell>Status</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {documents.map((document) => (
            <TableRow key={document.id}>
              <TableCell>
                <Text
                  fontSize={"0.875rem"}
                  fontWeight={400}
                  lineClamp={2}
                  onClick={() => viewDocument(document)}
                  textDecoration="underline"
                >
                  {formatTitle(document.title, document.isRehuddle)}
                </Text>
                <S.IdLabel>{`ID: ${document.id || "-"}`}</S.IdLabel>
              </TableCell>
              <TableCell>
                {document.clientGroup && document.clientGroup.name}
              </TableCell>
              <TableCell>{document.owner && document.owner.name}</TableCell>
              <TableCell>
                <DateAndTime dateTime={document.startDate} />
              </TableCell>
              <TableCell>
                <DateAndTime dateTime={document.submissionDate} />
              </TableCell>
              {/* <TableCell>{document.status && prettifyDocumentStatus(document.status)}</TableCell> */}
              <TableCell>
                {/* <DocumentActionMenu document={document} /> */}
                <div className="d-flex flex-row justify-content-between">
                  <DefaultCell
                    value={prettifyDocumentStatus(document.status)}
                  />
                  {/* Context menu and sidebar */}
                  <ClickAwayListener
                    onClickAway={() => {
                      if (popperIsOpen) {
                        setPopperIsOpen(false);
                      }
                    }}
                  >
                    <div className="d-flex flex-row justify-content-end">
                      <MoreVertIcon
                        onClick={(e) => {
                          setAnchorEl(e.currentTarget);
                          setAnchorDocument(document);
                          setTimeout(() => {
                            setPopperIsOpen(true);
                          }, 100);
                        }}
                      />
                    </div>
                  </ClickAwayListener>
                </div>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {/* // Pagination */}
      {documents && (
        <PaginationWrapper>
          <RowsPerPage
            pageSize={pageSize}
            onClick={(size) => onPaginate(0, size)}
          />
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onClick={(page) => onPaginate(page, pageSize)}
          />
          <BackToTop />
        </PaginationWrapper>
      )}
    </Loader>
  );
};

export default connect((state: AppState) => {
  let documents, loading, totalPages;
  if (state.documents.data.searchDocuments) {
    documents = state.documents.data.searchDocuments;
    loading = state.documents.loading.searchDocuments.GET;
    totalPages = documents.totalPages;
  }

  return {
    documentPagination: {
      totalPages,
    },
    loading,
  };
})(DocumentsTable);
