import { FBItem } from "components/clientAdmin/formBuilder/types";
import { NewDisplayCondition } from "store/builder/types";
import { Option } from "components/common/form/Select/Select";

// extension of Option with association fields
// note that `id` is the `prefillAnswerField` of a display condition
export interface WorkOrderPrefillOption extends Option {
  prefillAssociatedIdField?: string;
  prefillAssociatedLocation?: string;
}

export const WORK_ORDER_PREFILL_OPTIONS: WorkOrderPrefillOption[] = [
  {
    value: "Crew",
    id: "participants[*].nameAndTitle",
    prefillAssociatedIdField: "participants[*].id",
  },
  {
    value: "Description",
    id: "description",
  },
  {
    value: "Geolocation",
    id: "geolocation.gpsString",
    prefillAssociatedLocation: "geolocation",
  },
  {
    value: "Work Order Address",
    id: "address",
    prefillAssociatedLocation: "geolocation",
  },
  {
    value: "Supervisor",
    id: "supervisor.nameAndTitle",
    prefillAssociatedIdField: "supervisor.id",
  },
  {
    value: "Work Location",
    id: "workLocation.name",
    prefillAssociatedIdField: "workLocation.id",
    prefillAssociatedLocation: "workLocation.geolocation",
  },
  {
    value: "Work Location Address",
    id: "workLocation.physicalAddress.addressString",
  },
  {
    value: "Work Order Creator",
    id: "participantCreatedBy.nameAndTitle",
    prefillAssociatedIdField: "participantCreatedBy.id",
  },
  {
    value: "Work Order Number",
    id: "workOrderId",
    prefillAssociatedIdField: "id",
  },
];

export function buildWODisplayConditions(
  item: FBItem,
  prefillAnswerField: string | undefined,
  currentConditions: NewDisplayCondition[] = []
) {
  const conditions: NewDisplayCondition[] = [...(currentConditions || [])];

  const option = WORK_ORDER_PREFILL_OPTIONS.find(
    (o) => o.id === prefillAnswerField
  );

  const condition = {
    action: "WORK_ORDER_PREFILL",
    prefillAnswerField: prefillAnswerField || "",
    prefillAssociatedIdField: option?.prefillAssociatedIdField,
    prefillAssociatedLocation: option?.prefillAssociatedLocation,
    targetRootId: item.rootId,
    targetType: "QUESTION",
  };
  const conditionIndex = conditions.findIndex(
    (cond) =>
      cond.targetRootId === condition.targetRootId &&
      cond.action === "WORK_ORDER_PREFILL"
  );
  if (conditionIndex > -1) {
    conditions[conditionIndex] = condition;
  } else {
    conditions.push(condition);
  }
  return conditions;
}
