import * as React from "react";
import { Column } from "../styles";

const DocumentHistoryTableHeader = () => {
  return (
    <div className="row no-gutters">
      <Column className="col-4">Action</Column>
      <Column className="col-3">Changed By</Column>
      <Column className="col-5 text-right">Date</Column>
    </div>
  );
};

export { DocumentHistoryTableHeader };
