import { useContext, CSSProperties } from "react";
import { ThemeContext } from "styled-components";
import { StyleSheet } from "aphrodite";

import { remCalc } from "themes/helpers";

const FIELD_FONT_SIZE = remCalc(14);

const baseInputStyles = {
  padding: "0.5rem",
  fontSize: FIELD_FONT_SIZE,
  height: "auto",
};

export default function(): { [key: string]: CSSProperties } {
  const theme = useContext(ThemeContext);

  return StyleSheet.create({
    text: {
      fontSize: FIELD_FONT_SIZE,
    },

    label: {
      ":nth-child(1n) button": {
        fontSize: remCalc(12),
        textDecoration: "underline",
      },
    },

    textInput: {
      ":nth-child(1n) ${EndAdornmentButtonClass}": {
        textDecoration: "none",
      },
    },

    textInputWithSuggestions: {
      "${EndAdornmentButtonClass}": {
        textDecoration: "none",
      },
    },

    checkbox: {
      display: "flex",
      margin: 0,
      alignItems: "flex-start",

      ":nth-child(1n) .MuiButtonBase-root": {
        padding: "0.25rem",
        paddingLeft: 0,
      },

      ":nth-child(1n) .MuiFormControlLabel-label": {
        paddingTop: "calc(0.25rem + 2px)",
      },
    },

    noVertPadding: {
      paddingTop: 0,
      paddingBottom: 0,
    },

    checkboxContainer: {
      marginBottom: 0,

      ":nth-child(1n) .MuiFormControlLabel-label": {
        marginTop: 0,
      },
    },

    dropdown: {
      ":nth-child(n) .MuiInputBase-root": {
        minWidth: "4rem", // make space for the icon
      },
    },

    dropdownLabelAppended: {
      ":nth-child(n) .MuiInputBase-root": {
        order: 1,
        marginRight: remCalc(8),
      },
      flexDirection: "row",
      ":nth-child(n) .fieldLabel": {
        order: 2,
        ":nth-child(n) label": {
          fontWeight: 400,
        },
      },
    },

    radioButtonsGroup: {},

    multiInput: {
      ":nth-child(1n) .MuiListItem-root": {
        fontSize: FIELD_FONT_SIZE,
      },

      ":nth-child(1n) ul": {
        padding: 0,
      },

      ":nth-child(1n) .listItem": {
        padding: "0.25rem 0.25rem 0",
        marginBottom: 0,

        ":nth-child(1n) .MuiListItem-root": {
          marginBottom: 0,
        },

        ":nth-child(1n) .MuiTypography-body1": {
          fontSize: FIELD_FONT_SIZE,
        },
      },
    },

    properties: {
      padding: "0 1rem 1rem",
      display: "flex",
      overflowY: "auto",
      flexDirection: "column",

      ":nth-child(1n) hr": {
        width: "100%",
      },

      // global properties field styles
      ":nth-child(1n) .MuiFormControlLabel-label, :nth-child(1n) .fieldLabel": {
        fontSize: FIELD_FONT_SIZE,
        color: theme.masterColors.darkGrey,
      },

      ":nth-child(1n) .input, :nth-child(1n) .MuiInputBase-input, :nth-child(1n) .TextInput": {
        ...baseInputStyles,
      },

      ":nth-child(1n) .MuiTextField-root, :nth-child(1n) .select, :nth-child(1n) .multiInput": {
        ".input, .MuiInputBase-input": {
          ...baseInputStyles,
        },
      },

      ":nth-child(1n) .MuiInputLabel-root": {
        fontSize: FIELD_FONT_SIZE,
        marginBottom: "0.05rem",
        marginTop: "0.75rem",
      },

      ":nth-child(1n) .selectInput": {
        marginLeft: "1rem",
      },
      ":nth-child(1n) .select": {
        height: "2.5rem",
      },
    },

    requiredForContainer: {
      marginLeft: "1.8rem",
    },

    requiredForLabel: {
      fontSize: FIELD_FONT_SIZE,
      color: theme.colors.darkGrey,
      marginBottom: "0.5rem",
    },

    richTextEditor: {
      ":nth-child(1n) #wysiwyg-editor": {
        fontSize: FIELD_FONT_SIZE,
        padding: "0.5rem",
        color: theme.masterColors.black,
      },
      ":nth-child(1n) .wysiwyg-style-buttons": {
        display: "flex",
        padding: "0.25rem 0.5rem",
        justifyContent: "space-between",
      },
      ":nth-child(1n) .wysiwyg-style-button": {
        fontSize: "1rem",
        width: "auto",
        flexBasis: "10%",
      },
    },

    participantDs: {
      margin: "0.5rem 0",
      paddingLeft: "0.5rem",

      ":nth-child(1n) .MuiCheckbox-root": {
        paddingTop: 0,
        paddingBottom: 0,
      },
      ":nth-child(1n) .MuiFormControlLabel-label, :nth-child(1n) .fieldLabel": {
        marginTop: 0,
      },

      ":nth-child(1n) .MuiFormControlLabel-root ": {
        marginBottom: 0,
      },
    },

    textButton: {
      padding: 0,
      marginBottom: 0,
    },

    addIcon: {
      fontSize: remCalc(24),
    },

    dcRow: {
      paddingBottom: "0.5rem",
      textAlign: "right",
    },

    dcRule: {
      paddingBottom: "0.5rem",
      borderBottom: `1px solid ${theme.masterColors.mediumGrey}`,
      margin: "1rem 0",
    },

    ruleTypeSelect: {
      marginRight: "0.5rem",
    },

    dcSelect: {
      flexGrow: 1,
      marginLeft: "0.5rem",
      minWidth: "90px",
    },

    actionSelect: {
      width: remCalc(72),
    },

    prefillSelect: {
      width: remCalc(196),
    },

    showHideSelect: {
      width: remCalc(148),
    },

    conditionRules: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-end",
    },

    link: {
      textDecoration: "underline",
      fontSize: remCalc(14),
      letterSpacing: "0.15px",
    },

    newRuleButton: {
      color: theme.colors.black,
      textTransform: "none",
      letterSpacing: "normal",
      width: "100%",
      padding: 0,
      margin: 0,
    },

    newRuleText: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      width: "100%",
    },

    workLocationNotice: {
      display: "block",
      marginTop: 10,
      marginBottom: 10,
      fontSize: remCalc(14),
      fontStyle: "italic",
    },
    notificationsLabel: {
      color: theme.colors.black,
      fontSize: remCalc(14),
      fontWeight: 500,
      letterSpacing: remCalc(0.15),
      marginBottom: 0,
    },
    notificationsHelperText: {
      display: "block",
      color: theme.colors.assistiveText,
      fontSize: remCalc(14),
    },

    ratingOptionsWrapper: {
      display: "flex",
      flexDirection: "row",
      paddingTop: 10,
    },
  });
}
