import styled from "styled-components";

import { remCalc, toRgba } from "themes/helpers";
import mediaQueries from "themes/mediaQueries";

export const Circle = styled.div`
  display: flex;
  background-color: ${({ theme }) => theme.colors.white};
  border: none;
  width: ${remCalc(63)};
  height: ${remCalc(63)};
  border-radius: 50%;
  box-shadow: 1px 2px 2px ${({ theme }) => toRgba(theme.colors.primary, 0.2)};
  justify-content: center;
`;

type PlaceMarkerProps = {
  disabled: boolean;
  color?: string;
  className: string;
};

export const PlaceMarker = styled.i<PlaceMarkerProps>`
  font-size: 1.5em;
  margin: auto;
  color: ${({ theme, disabled, color }) => disabled ? theme.colors.lightGrey : color};
`;

type MarkerContainerProps = {
  idx: number;
  mapDims: { width: number; height: number };
  visible: boolean;
  dragging: boolean;
};

export const MarkerContainer = styled.div.attrs((props: MarkerContainerProps) => ({
  idx: props.idx,
  mapDims: props.mapDims,
  visible: props.visible,
  dragging: props.dragging,
}))`
  position: relative;
  ${({ idx, mapDims, visible }) =>
    !visible &&
    `
  position: absolute;
  left: calc(${-mapDims.width / 2}px + ${3 + 5 * idx}em);
  bottom: calc(${mapDims.height / 2}px + 2em);
  `}
  z-index: ${({ visible, dragging }) => !visible || dragging ? 11 : 9};
`;

// ${({ offset, idx }) => offset && `position: absolute; left: calc(${offset.x}px); bottom: calc(${offset.y}px);`}
export const MarkerControlContainer = styled.div`
  position: absolute;
  left: 1em;
  bottom: 1em;
  display: flex;
  width: 8.875em;
  justify-content: space-between;
  z-index: 10;
`;

// to change size of pin (where n = desired size in px):
// height: (n)px;
// transform: translate((-n / 2)px,(-n)px);
export const Pin = styled.svg<{ color?: string }>`
  height: 30px;
  cursor: pointer;
  fill: ${({ color }) => color};
  stroke: none;
`;

export const ZoomContainer = styled.div`
  display: flex;
  position: absolute;
  right: ${remCalc(16)};
  bottom: ${remCalc(16)};
  background-color: ${({ theme }) => theme.colors.background};
  width: ${remCalc(180)};
  height: ${remCalc(60)};
  box-shadow: 1px 2px 2px ${({ theme }) => toRgba(theme.colors.primary, 0.2)};
  border-radius: 4px;
  z-index: 10;

  button:last-child {
    border-right: none;
  }
  button:not(:last-child) {
    border-right: 2px solid ${({ theme }) => theme.colors.lightGrey};
  }

  @media (${mediaQueries.maxPhone}) {
    top: ${remCalc(16)};
  }
`;

export const ControlButton = styled.button`
  display: inline-block;
  align-self: center;
  background: ${({ theme }) => theme.colors.background};
  font-size: ${remCalc(20)};
  width: 3.75rem; //60px
  border-top: none;
  border-left: none;
  border-bottom: none;
  height: ${remCalc(45)};
`;

export const SatelliteImage = styled.img`
  border-radius: 4px;
  width: 1.5em;
`;
