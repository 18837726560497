import {
  FiltersActions,
  ResourcesFiltersArgs,
  RESOURCES_FILTERS,
} from "./types";

const initialFilters = {
  lastModifiedByEmail: -1,
  pastDays: -1,
  categoryIds: -1,
  resourceType: -1,
  lastUpdated: "",
  timeFilterType: "ALL_TIME",
  dateRange: null,
  status: -1,
  query: "",
};

const initialPaginationParams = {
  page: 0,
  size: 10,
  sort: "lastModifiedDate,desc",
};

const initialState: ResourcesFiltersArgs = {
  filterParams: initialFilters,
  paginationParams: initialPaginationParams,
};

export function resourcesFiltersReducer(
  state = initialState,
  action: FiltersActions
): ResourcesFiltersArgs {
  const { response, type } = action;

  switch (type) {
    case RESOURCES_FILTERS.SET:
      return { ...state, filterParams: response };

    case RESOURCES_FILTERS.PARAMS_SET:
      return { ...state, paginationParams: response };

    default:
      return state;
  }
}
