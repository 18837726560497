import styled from "styled-components";
import mediaQueries from "themes/mediaQueries";

import { remCalc, toRgba } from "../../../themes/helpers";
import { Button } from "../Button";

/* eslint-disable */
export const Signatures = styled.div<{
  error?: boolean;
}>`
  // handle error styling
  ${({ error, theme }) => {
    if (error) {
      return `
      border: 2px solid ${theme.masterColors.error};
      padding: 20px;
    `;
    }
  }}
`;
/* eslint-enable */

export const LabelRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (${mediaQueries.maxPhone}) {
    flex-direction: column;
    width: 100%;
    max-width: 100%;
    justify-content: flex-start;
    align-items: flex-start;
  }
`;

export const LabelWrapper = styled.div`
  padding-bottom: ${remCalc(12)};
  padding-right: ${remCalc(11)};
`;

export const AssistiveLinkRow = styled.div`
  box-sizing: border-box;
  display: flex;
  justify-content: flex-end;
  padding-bottom: ${remCalc(16)};
  padding-right: ${remCalc(13)};
  padding-top: ${remCalc(16)};
  width: 100%;
`;

export const MaxWidth = styled.div<{ width?: number }>`
  @media (${mediaQueries.maxTablet}) {
    max-width: ${remCalc(500)};
  }

  @media (${mediaQueries.maxPhone}) {
    max-width: ${remCalc(400)};
  }

  ${({ width }) => {
    if (width) {
      return `
      max-width: ${({ width }) => width};
    `;
    }
  }}
`;

export const AssistiveLinkButton = styled.button<{ variant: "default" | "clear" }>`
  font-family: "Roboto", sans-serif;
  color: ${({ theme }) => theme.colors.primary};
  font-size: ${remCalc(14)};

  text-decoration: underline;

  border: none;
  background: none;

  @media (${mediaQueries.maxTablet}) {
    font-size: ${remCalc(21)};
  }

  @media (${mediaQueries.maxPhone}) {
    font-size: ${remCalc(14)};
  }

  /* Handle Variants */
  ${({ variant, theme }) => {
    if (variant === "clear") {
      return `color: ${theme.masterColors.error}`;
    }
    return null;
  }}
`;

type Props = {
  height: number;
  width: number;
};

export const BottomLine = styled.div`
  ${(props: Props) => `
    width: ${props.width - 48}px;
    height: ${props.height - 36}px;`}
  position: absolute;
  left: 0;
  top: 0;
  zindex: 1;
  border-bottom: 1px solid ${({ theme }) => theme.masterColors.darkGrey};
  margin-left: 24px;
  margin-right: 24px;
`;

export const PadContainer = styled.div<{ width: number; height: number; confirmation: boolean }>`
  ${(props: Props) => `
    width: ${props.width}px;
    height: ${props.height}px;`}
  border: 1px solid ${({ theme }) => theme.masterColors.darkGrey};
  border-radius: ${remCalc(4)};
  position: relative;

  /* HANDLE STYLING FOR CONFIRMED SIGNATURE */
  ${({ confirmation, theme }) =>
    confirmation ? `
      background-color: ${theme.masterColors.successFill};
      border: ${remCalc(1)} solid ${theme.masterColors.success};
      box-shadow: 0 0 0 1px ${theme.masterColors.success};
    ` : `
      &:focus {
        border-color: ${theme.colors.primary};
        box-shadow: 0 0 0 1px ${theme.colors.primary};
        outline: none;
      }
  
      &:hover {
        background-color: ${theme.colors.activeFill};
      }
  `}

  @media (${mediaQueries.maxPhone}) {
    width: 80vw;
  }
`;

export const ClearButton = styled(Button)`
  position: absolute !important;
  width: auto;
  font-size: 0.75rem;
  font-weight: 700;
  z-index: 3;
`;

export const ConfirmButton = styled(Button)`
  position: absolute !important;
  right: 16px;
  width: auto;
  font-size: 0.75rem;
  z-index: 4;
`;

export const Circle = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  display: flex;
  border: none;
  width: ${remCalc(63)};
  height: ${remCalc(68)};
  border-radius: 50%;
  box-shadow: 1px 2px 2px ${({ theme }) => toRgba(theme.colors.primary, 0.2)};
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;
  margin: ${remCalc(24)};
`;

export const Icon = styled.i`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.masterColors.success};
  font-size: ${remCalc(25)};
`;

export const ClearButtonLabel = styled.span`
  color: ${({ theme }) => theme.masterColors.error};
`;

export const Canvas = styled.canvas`
  ${(props: Props) => `
    width: ${props.width}px;
    height: ${props.height}px;`}
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
`;

export const SignatureImage = styled.img`
  object-fit: contain;
  height: 100%;
  width: auto;
`;

export const SubmittedImageWrap = styled.div`
  height: ${remCalc(56)};
  border: ${remCalc(1)} solid ${({ theme }) => theme.colors.fieldInputBorder};
  border-radius: ${remCalc(4)};

  @media (${mediaQueries.maxTablet}) {
    height: ${remCalc(84)};
  }

  @media (${mediaQueries.maxPhone}) {
    height: ${remCalc(56)};
  }
`;

export const SignatureWrapper = styled.div`
  margin-bottom: ${remCalc(14)};
`;

export const PadIcon = styled.i`
  font-size: ${remCalc(50)};

  color: ${({ theme }) => theme.masterColors.black};
`;

export const PadIconLabel = styled.span`
  color: ${({ theme }) => theme.masterColors.darkGrey};  
  font-size: ${remCalc(16)};
  font-weight: 500;
  letter-spacing: ${remCalc(2)};
  text-transform: uppercase;
`;

export const CenterPadElement = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const SignatureContainer = styled.div`
  width: ${remCalc(600)};

  max-width: 100%;
`;
