import { WorkOrdersState, workOrdersActionTypes, SEARCH_WORK_ORDERS } from "./types";
import { returnLoadingStatus } from "components/helpers";

const initialState: WorkOrdersState = {
  loading: {
    searchWorkOrders: {
      GET: false,
    },
  },
  data: {
    searchWorkOrders: [],
  },
};

export function workOrdersReducer(state = initialState, action: workOrdersActionTypes): WorkOrdersState {
  switch (action.type) {
    case SEARCH_WORK_ORDERS.SUCCESS:
      return {
        ...state,
        loading: returnLoadingStatus(false, "GET", "searchWorkOrders", state),
        data: {
          ...state.data,
          searchWorkOrders: action.response,
        },
      };
    case SEARCH_WORK_ORDERS.FAILURE:
      return {
        ...state,
        loading: returnLoadingStatus(false, "GET", "searchWorkOrders", state),
      };
    case SEARCH_WORK_ORDERS.REQUEST:
      return {
        ...state,
        loading: returnLoadingStatus(true, "GET", "searchWorkOrders", state),
      };
    default:
      return state;
  }
}
