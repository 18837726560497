import * as React from "react";
import { Route, Router, Switch } from "react-router-dom";
import { connect } from "react-redux";
import { get, isArray } from "lodash";

import AuthenticatedRoutes from "routes/AuthenticatedRoutes";
import { AppState } from "store";
import { getAllDefaultTermDefinitions } from "store/clientGroups/actions";
import { getAppConfig } from "./store/appConfigs/actions";
import { getClientGroupConfig } from "store/clientConfigs/actions";

// Login route components
import Activation from "components/auth/Activation";
import { Login, PasswordHelp, PasswordReset } from "./components/auth/index";
import { OAuth2Login } from "./components/auth/OAuth2Redirect";
import PasswordLogin from "components/auth/PasswordLogin";
import { getParticipantTreeOptions } from "store/participantTreeFilter/actions";

const history = require("history").createBrowserHistory({});

export interface AppProps {
  dispatch?: any;
  primaryGroupId: number;
}

class App extends React.Component<AppProps> {
  componentDidMount() {
    // Destructure
    const { primaryGroupId, dispatch } = this.props;
    // Get logo configs
    dispatch(getAppConfig("logoConfigs"));
    // Get client config
    dispatch(getAppConfig("clientConfig"));
    // Get client group terms (for some reason primaryGroupId is an array if invalid)
    if (primaryGroupId && !isArray(primaryGroupId)) {
      dispatch(getClientGroupConfig(primaryGroupId, "terms")).then(
        (response) => {
          const _terms = get(
            response,
            ["response", "properties", "terms"],
            undefined
          );
          if (!_terms || _terms.length === 0) {
            dispatch(getAllDefaultTermDefinitions());
          }
        }
      );
    }

    // Get participant tree options
    dispatch(getParticipantTreeOptions());
  }

  render() {
    return (
      <Router history={history}>
        <Switch>
          {/* Unprotected */}
          <Route exact path="/login" component={Login} />
          <Route exact path="/password-login" component={PasswordLogin} />
          {/* <-- /oauth2/login is temp, should remove when ADFS stable */}
          <Route exact path="/oauth2/login" component={OAuth2Login} />
          <Route exact path="/help" component={PasswordHelp} />
          <Route exact path="/password-reset" component={PasswordReset} />
          <Route exact path="/activate" component={Activation} />

          {/* Protected */}
          <AuthenticatedRoutes />
        </Switch>
      </Router>
    );
  }
}

function mapStateToProps(state: AppState) {
  return {
    primaryGroupId: get(state, ["user", "data", "primaryGroupId"], []),
  };
}

export default connect(mapStateToProps)(App);
