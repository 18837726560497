import { get } from "lodash";

import { AppState } from "../index";
import { ClientGroup } from "../clientGroups/types";
import { Participant } from "../participants/types";
import { WorkLocationDTO } from "../workLocation/types";

import { UsersMe, UserState, UserRole } from "./types";

/**
 * returns the currently signed in user's participant ID
 * @param userState
 */
export const userIdSelector = (userState: UserState): number | null => {
  return get(userState, ["data", "participant", "id"], null);
};

/**
 * returns the currently signed in user's data
 * @param userState
 */
export const currentUser = (
  userState: UserState
): UsersMe | null => {
  return userState.data;
};

export const getUserProfile = ({ appConfigs, user }: AppState) => {
  const firstName = get(user, "data.participant.firstName");
  const lastName = get(user, "data.participant.lastName");
  const nickname = get(user, "data.participant.nickname") || "";
  const nicknameWithQuotes = nickname && `"${nickname}"`;

  const userInitials =
    firstName &&
    lastName &&
    `${firstName[0].toUpperCase()}${lastName[0].toUpperCase()}`;

  const supervisorFirstName = get(
    user,
    "data.participant.supervisor.firstName"
  );
  const supervisorLastName = get(user, "data.participant.supervisor.lastName");

  const supervisor =
    supervisorFirstName &&
    supervisorLastName &&
    `${supervisorFirstName} ${supervisorLastName}`;

  const groups: Array<ClientGroup> = get(user, "data.clientGroups");
  const primaryGroupId: number | undefined = get(
    user,
    "data.primaryGroupId",
    undefined
  );

  const adfsEnabled: boolean = get(appConfigs, "data.clientConfig.properties.adfsEnabled");

  const langKey: string | undefined = get(user, "data.langKey", undefined);

  const imageUrl: string | undefined = get(user, "data.imageUrl", undefined);
  const workLocationId: number | undefined = get(
    user,
    "data.workLocation.id",
    undefined
  );

  return {
    adfsEnabled,
    email: get(user, "data.participant.email"),
    firstName,
    groups,
    imageUrl,
    langKey,
    lastName,
    name: `${firstName} ${nicknameWithQuotes} ${lastName}`,
    nameWithoutNick: `${firstName} ${lastName}`,
    nickname,
    primaryGroupId,
    supervisor,
    userInitials,
    workLocationId
  };
};

export const getUserFormStats = ({ user }: AppState) => ({
  data: user.userFormStats,
  loading: user.loading.getUserFormStats,
  error: user.error.getUserFormStats
});

// Selected DataSource WorkLocations and transforms
// for use with Edit Profile
export const getOfficeLocations = ({ dataSource }: AppState) => {
  const workLocations: Array<WorkLocationDTO> = get(
    dataSource,
    "data.WORK_LOCATION"
  );

  if (!workLocations) return [];

  return workLocations.map((location) => ({
    label: location.content.name,
    value: location.content.id,
    id: location.id
  }));
};

/**
 * Get the groups that the user has access to
 * @param user
 */
export const getUserGroups = ({ user }: AppState): Array<ClientGroup> => get(user, "data.clientGroups", []);

export function userClientAdminGroupIds(userState: UserState): Array<number> {
  const userAuthorities = get(userState, ["data", "authorities"]);
  const idSet = userAuthorities.reduce((groupIds, role) => {
    if (role.authority === "ROLE_CLIENT_ADMIN") {
      role.groups.forEach((groupId) => (groupIds = groupIds.add(groupId)));
    }
    return groupIds;
  }, new Set<number>());
  return Array.from(idSet);
}

export const currentUserFullName = (userState: UserState): string | undefined =>
  get(userState, "data.fullName");

export const getUpdateUserStatus = ({ user }: AppState) => {
  const loading: boolean = get(user, "loading.updateUserInfo", false);
  const error: boolean = get(user, "error.updateUserInfo", false);
  const success: boolean = get(user, "success.updateUserInfo", false);

  return {
    loading,
    error,
    success
  };
};

// returns null || string
export const getAuthToken = ({ system }: AppState) => system.authToken;

// Get roles
export const getUserRoles = ({ user }: AppState): UserRole[] => {
  return user.data && user.data.authorities && user.data.authorities.map((e) => e.authority) || [];
};

// User is admin?
export const isAdmin = ({ user }: AppState): boolean => {
  const roles = user.data && user.data.authorities && user.data.authorities.map((e) => e.authority) || [];
  if (roles.length > 0 && roles.some((role: UserRole) => role === "ROLE_CLIENT_ADMIN")) return true;
  return false;
};

