import React from "react";

import * as S from "./styles";
import { CurrentPage, TotalElements } from "./types";

interface Pagination {
  currentPage?: CurrentPage;
  totalPages?: TotalElements;
  onClick: (page: number) => void;
}

const getPrecedingNumbers = (startAt: number, howMany: number) => {
  for (var i = 1, res: number[] = []; i < 3 && startAt - i > 0; i++) {
    res.unshift(startAt - i);
  }
  return res;
};

const getFollowingNumbers = (startAt, max, howMany) => {
  for (var i = 1, res: number[] = []; i < howMany && max >= startAt + i; i++) {
    res.push(startAt + i);
  }
  return res;
};

const getRemainingNumbersAfter = (startAt, max, onClick) => {
  if (startAt < max) {
    return <S.PageNumber onClick={() => onClick(max - 1)}>{max}</S.PageNumber>;
  }
};

const Pagination = ({ currentPage = 0, totalPages = 0, onClick }: Pagination) => {
  const previous = getPrecedingNumbers(currentPage + 1, 2);
  const next = getFollowingNumbers(currentPage + 1, totalPages, 5 - previous.length);
  const moreThanTwoPagesRemaining = next[next.length - 1] < totalPages; // Show dots and last page if more than 2 remaining
  const nextLinkIsActive = totalPages && currentPage !== totalPages - 1;
  const previousLinkIsActive = currentPage !== 0;
  const moreThanTwoPagesToStart = currentPage > 2;
  return (
    <S.Wrapper>
      {/* Chevron Prev */}
      {(previousLinkIsActive && (
        <S.PageNumber onClick={() => onClick(currentPage - 1)}>
          <S.Chevron />
          PREV
        </S.PageNumber>
      )) || (
        <S.PageNumber>
          <S.Chevron />
          PREV
        </S.PageNumber>
      )}
      {/* Dots */}
      {moreThanTwoPagesToStart && (
        <>
          <S.PageNumber onClick={() => onClick(0)}>1</S.PageNumber>
          {previous && previous[0] !== 2 && <S.PageNumber>...</S.PageNumber>}
        </>
      )}
      {/* // Prev Pages */}
      {previous.map((e) => (
        <S.PageNumber key={e} onClick={() => onClick(e - 1)}>
          {e}
        </S.PageNumber>
      ))}
      {/* Current page */}
      <S.CurrentPage>{currentPage + 1}</S.CurrentPage>
      {/* Next pages */}
      {next.map((e) => (
        <S.PageNumber key={e} onClick={() => onClick(e - 1)}>
          {e}
        </S.PageNumber>
      ))}
      {/* Dots */}
      {moreThanTwoPagesRemaining && (
        <>
          {next && next[next.length - 1] !== totalPages - 1 && <S.PageNumber>...</S.PageNumber>}
          {getRemainingNumbersAfter(currentPage + 3, totalPages, onClick)}
        </>
      )}
      {/* Chevron Next */}
      {(nextLinkIsActive && (
        <S.PageNumber onClick={() => onClick(currentPage + 1)}>
          NEXT
          <S.Chevron right />
        </S.PageNumber>
      )) || (
        <S.PageNumber>
          NEXT
          <S.Chevron right />
        </S.PageNumber>
      )}
    </S.Wrapper>
  );
};

export default Pagination;
