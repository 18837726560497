import React, { useContext } from "react";
import styled, { ThemeContext } from "styled-components";

import { Text, TextProps } from "components/clientAdmin/styles";

export const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.masterColors.lightWhite};
  border-radius: 4px;
  height: 93px;
  line-height: 25px;
  position: fixed;
  top: 80px;
  width: 90%;
  z-index: 1000;
`;

export const ContentWrapper = styled.div`
  max-width: 975px;
  padding: 20px 24px;
`;

export const Name: React.FC<TextProps> = (props) => {
  const theme = useContext(ThemeContext);
  return (
    <Text
      color={theme.colors.black}  
      fontSize="1.5rem"
      letterSpacing="0.42px"
      {...props}
    />
  );
};
