import React, { useState } from "react";
import moment from "moment";

import * as S from "../styles";
import Loader from "components/common/Loader";
import { CancelButton, SubmitButton } from "components/clientAdmin/styles";
import { LoaderWrapper } from "../../addUser/styles";
import useGroupTerm from "util/hooks/whiteLabel/useGroupTerm";
import { useDispatch } from "react-redux";
import { getRelatedDocuments } from "store/documents/actions";
import { ThunkDispatch } from "redux-thunk";
import { AppState } from "store";
import { Action } from "redux";
import { useAsyncEffect } from "@rtslabs/field1st-fe-common";

interface Props {
  // document data
  created?: string;
  group?: string;
  title?: string;
  id?: number;

  loading?: boolean;
  onCancel: () => void;
  onSubmit: () => void;
}

// Content only
export const DeleteDocumentConfirmation = ({
  // Document data
  created,
  group,
  title,
  id,

  loading,
  onCancel,
  onSubmit,
}: Props) => {
  const [hasRelatedDocuments, setHasRelatedDocuments] = useState(false);
  const dispatch = useDispatch<ThunkDispatch<AppState, void, Action>>();

  useAsyncEffect(async () => {
    if (id) {
      const relatedDocs = await dispatch(getRelatedDocuments(id));
      relatedDocs.response.length && setHasRelatedDocuments(true);
    }
  }, [id]);
  // Group config terms
  const documentTerm = useGroupTerm("document", "noun", undefined, "Document");
  const documentTermLower = documentTerm.toLowerCase();
  const documentTermUpper = documentTerm.toUpperCase();
  return (
    <S.ModalPromptWrapper>
      <S.ModalPromptTitle>
        Would you like to delete this {documentTermLower}?
      </S.ModalPromptTitle>
      <S.ModalPromptSubtitle>
        You are about to delete this {documentTermLower}:
      </S.ModalPromptSubtitle>
      <div className="d-flex flex-row">
        <S.DisableUserDataItem>
          <S.DataItemLabel>{documentTermUpper}</S.DataItemLabel>
          <S.DataItemText>{title || "-"}</S.DataItemText>
        </S.DisableUserDataItem>
        <S.DisableUserDataItem>
          <S.DataItemLabel>GROUP</S.DataItemLabel>
          <S.DataItemText>{group || "-"}</S.DataItemText>
        </S.DisableUserDataItem>
        <S.DisableUserDataItem>
          <S.DataItemLabel>CREATED</S.DataItemLabel>
          <S.DataItemText>
            {(created && moment(created).format("MM/DD/YY - HH:mm")) || "-"}
          </S.DataItemText>
        </S.DisableUserDataItem>
      </div>
      <S.DisableUserWarningLabel margin="32.5px 0 0 0">
        Deleting a {documentTermLower} is a permanent action and cannot be
        undone. The deleted {documentTermLower} will be removed from the search
        database and will no longer impact relevant reporting.{" "}
        {hasRelatedDocuments && (
          <S.DeleteLinkedDocumentWarning>
            This {documentTermLower} has relations/links to other{" "}
            {documentTermLower}s that will be severed when deleted. Do not
            proceed if you wish to preserve a record of these relationships
          </S.DeleteLinkedDocumentWarning>
        )}
      </S.DisableUserWarningLabel>
      <S.DisableUserButtonsWrapper margin="24px 0 0 0">
        <CancelButton disabled={false} onClick={onCancel}>
          cancel
        </CancelButton>
        <SubmitButton disabled={false} onClick={onSubmit}>
          <LoaderWrapper>
            <Loader
              loading={loading}
              className="p-0 mr-3"
              spinnerProps={{ size: 14 }}
            />
          </LoaderWrapper>
          <span>delete {documentTermLower} </span>
        </SubmitButton>
      </S.DisableUserButtonsWrapper>
      <S.ModalPromptCloseIcon onClick={onCancel} />
    </S.ModalPromptWrapper>
  );
};
