import React from "react";

import { DocumentQuestionResponseVm } from "store/documents/types";
import Comment from "components/forms/Comment";
import RadioInput from "components/forms/RadioInput";
import { FieldErrorType } from "components/FormController/components/Question";

import * as S from "./styles";
import { SingleResponseQuestionProps } from "../types";
import { SafetyRatingWidgetDTO } from "store/forms/types";
import useDebounce from "util/hooks/useDebounce";
import { isEqual } from "lodash";

export interface Props extends SingleResponseQuestionProps {
  error?: FieldErrorType;
  parentWidget?: SafetyRatingWidgetDTO;
}

export default function Rating({
  question,
  setQuestionResponse,
  response: formResponse,
  error,
  parentWidget,
}: Props) {
  const [showButton, setShowButton] = React.useState<boolean>(true);
  const [showField, setShowField] = React.useState<boolean>(false);
  const [response, setResponse] = React.useState<typeof formResponse>(
    () => formResponse
  );
  const [comment, setComment] = React.useState<string | null | undefined>(
    response?.comments
  );
  const currentSelection = question.selections?.find(
    (selection) => selection.title === response?.answer
  );

  const hasCommentRequired =
    currentSelection?.properties?.commentRequired !== undefined;

  // This logic checks if the current selection has a commentRequired prop, use
  // it as an "override", otherise use the existing "global" requireCommentsFor.
  // This should cover existing ratings widgets before this new change in ST-626
  const commentRequired = hasCommentRequired
    ? currentSelection?.properties?.commentRequired
    : response?.answer
    ? (parentWidget?.requireCommentsFor || []).includes(response?.answer)
    : false;

  React.useEffect(() => {
    if (commentRequired || comment) {
      setShowField(true);
      setShowButton(false);
    } else {
      setShowField(false);
      setShowButton(true);
    }
  }, [commentRequired, comment]);

  const debouncedSetComment = useDebounce({
    method: (comments?: string) =>
      setResponse((currRes) => {
        if (currRes) {
          return {
            ...currRes,
            comments,
            timeAnswered: new Date().toISOString(),
          };
        }
        return currRes;
      }),
  });

  React.useEffect(() => {
    if (comment !== response?.comments) {
      debouncedSetComment(comment);
    }
  }, [comment]);

  /* set question response on local response change */
  React.useEffect(() => {
    if (isEqual(response, formResponse)) {
      return;
    }
    if (response && response.answer) {
      setQuestionResponse(response);
    } else if (response?.answer === "") {
      setQuestionResponse(undefined);
    }
  }, [response]);

  /**
   * Update the answer in the local version of the response
   * @param radioResponse
   */
  const setAnswer = (radioResponse?: DocumentQuestionResponseVm) =>
    setResponse((currRes) => {
      if (radioResponse) {
        if (currRes) {
          return {
            ...radioResponse,
            comments: currRes.comments,
          };
        }
        return radioResponse;
      }
    });

  return (
    <S.Rating>
      <S.Column flex="1" variant="LEFT">
        {/* LABEL COLUMN */}
        <S.RatingLabel variant={error ? "error" : "default"}>
          {question.title}
        </S.RatingLabel>
        {showButton && !showField && (
          <S.AddCommentBubble type="button" onClick={() => setShowField(true)}>
            <S.AddCommentIcon className="icon icon-icons8-comments" />
            <S.AddCommentText>Add Comment</S.AddCommentText>
          </S.AddCommentBubble>
        )}
      </S.Column>
      <S.Column flex="3">
        {/* ANSWER & COMMENT COLUMN */}
        <S.Row>
          {question.selections && (
            <RadioInput
              error={error}
              options={question.selections.map(({ id, rootId, title }) => ({
                rootId,
                id,
                value: title,
                label: title,
              }))}
              handleBlur={() => undefined}
              name={`${question.id}`}
              question={question}
              response={response}
              setQuestionResponse={(
                questionResponse?: DocumentQuestionResponseVm
              ) => {
                setAnswer(questionResponse);
              }}
              variant="RATING"
              unselectable={true}
            />
          )}
        </S.Row>

        <S.Row flexEnd>
          <Comment
            commentValue={comment}
            enabled={showField}
            handleClearComment={() => setComment("")}
            handleUpdateComment={setComment}
            name={`${question.id}`}
            shouldRenderClearCommentButton={!!response?.comments}
            variant="RATING"
            required={commentRequired}
          />
        </S.Row>
      </S.Column>
    </S.Rating>
  );
}
