import { PaginationParams } from "store/common/types";
import { FiltersActions, GroupsFiltersArgs, GROUPS_FILTERS } from "./types";

const initialFilters = {
  query: "",
};

const initialPaginationParams: PaginationParams = {
  page: 0,
  size: 10,
  sort: "groupName,asc",
};

const initialState: GroupsFiltersArgs = {
  filterParams: initialFilters,
  paginationParams: initialPaginationParams,
};

export function groupsReducer(
  state = initialState,
  action: FiltersActions
): GroupsFiltersArgs {
  const { response, type } = action;

  switch (type) {
    case GROUPS_FILTERS.SET:
      return { ...state, filterParams: response };

    case GROUPS_FILTERS.PARAMS_SET:
      return { ...state, paginationParams: response };

    default:
      return state;
  }
}
