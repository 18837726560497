import React, { ReactType } from "react";
import { RouteComponentProps, Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import { AppState } from "store";
import { UserRole } from "store/user/types";
import { useLocation } from "react-router";

interface Props {
  Component: ReactType;
  exact?: boolean;
  path: string;
  accessRoles: string[];
}

export const AuthenticatedRoute = ({
  Component,
  exact = false,
  path,
  accessRoles,
}: Props): JSX.Element => {
  // Get location
  const location = useLocation();

  // Get user roles
  const userRoles: UserRole[] = useSelector(
    (state: AppState) =>
      state.user?.data?.authorities.map((e) => e.authority) || []
  );

  // Is user authenticated
  const isAuthenticated = useSelector((state: AppState) => {
    const authToken = state.system.authToken;
    const loggedIn = state.system.loggedIn;
    const userId = state.user?.data?.participant.id;
    return !!(authToken && loggedIn && userId);
  });

  // Compare user roles to required roles
  const hasRequiredRole = userRoles.some((r) => accessRoles.includes(r));

  return (
    <Route
      exact={exact}
      path={path}
      render={(props: RouteComponentProps) => {
        // If authenticated
        if (isAuthenticated) {
          if (hasRequiredRole) {
            return <Component {...props} />;
          }
          return (
            <Redirect
              to={{
                pathname: "/no-permissions",
              }}
            />
          );
        }

        // If not authenticated
        const redirectUrl = location?.pathname;
        return (
          <Redirect
            to={{
              pathname: "/login",
              state: {
                redirect: redirectUrl,
              },
            }}
          />
        );
      }}
    />
  );
};
