export const SAVE_RESOURCE_CATEGORY = {
  REQUEST: "SAVE_RESOURCE_CATEGORY_REQUEST",
  SUCCESS: "SAVE_RESOURCE_CATEGORY_SUCCESS",
  FAILURE: "SAVE_RESOURCE_CATEGORY_FAILURE",
};

export const GET_RESOURCE_CATEGORIES = {
  REQUEST: "GET_RESOURCE_CATEGORIES_REQUEST",
  SUCCESS: "GET_RESOURCE_CATEGORIES_SUCCESS",
  FAILURE: "GET_RESOURCE_CATEGORIES_FAILURE",
};
export const GET_RESOURCE_CATEGORY = {
  REQUEST: "GET_RESOURCE_CATEGORY_REQUEST",
  SUCCESS: "GET_RESOURCE_CATEGORY_SUCCESS",
  FAILURE: "GET_RESOURCE_CATEGORY_FAILURE",
};
export const GET_RESOURCE_CATEGORY_BY_ID = {
  REQUEST: "GET_RESOURCE_CATEGORY_BY_ID_REQUEST",
  SUCCESS: "GET_RESOURCE_CATEGORY_BY_ID_SUCCESS",
  FAILURE: "GET_RESOURCE_CATEGORY_BY_ID_FAILURE",
};
