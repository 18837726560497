import React from "react";
import { css } from "aphrodite/no-important";

import { TextButton } from "components/common/Button";
import Icon from "components/common/Icon";
import styles from "./styles";

interface ReorderButtonProps {
  onReorder: () => void;
  icon: string;
}

const ReorderButton = ({ onReorder, icon }: ReorderButtonProps) => (
  <TextButton
    className={css(styles().reorderButton)}
    onClick={(e) => {
      e.stopPropagation();
      onReorder();
    }}
  >
    <Icon type={icon} framed={false} size="12" />
  </TextButton>
);

interface ReorderButtonsProps {
  hide?: {
    top: boolean;
    bottom: boolean;
  };
  onReorder: (index: number) => void;
}

const ReorderButtons = ({ hide, onReorder }: ReorderButtonsProps) => (
  <div className={css(styles().buttonsContainer)}>
    {!hide?.top && (
      <ReorderButton onReorder={() => onReorder(-1)} icon="sort_up" />
    )}
    {!hide?.bottom && (
      <ReorderButton onReorder={() => onReorder(1)} icon="sort_down" />
    )}
  </div>
);

export default ReorderButtons;
