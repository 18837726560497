import { isArray, chain } from "lodash";
import { DataSourceValueDTO } from "./types";
import { buildFilteredEndpoint } from "store/common/apiUtilities";

interface GetDataSourceEndpoint {
  lastModifiedDate?: string;
  keyName: string;
  page?: number;
  size?: number;
  sort?: string | string[];
  query?: string;
}

/**
 * Returns API endpoint used to fetch Data Source Values
 * @param lastModifiedDate - date string
 * @param keyName - string
 */
export const createDataSourceEndpoint = ({
  query,
  lastModifiedDate,
  keyName,
  page,
  size,
  sort,
}: GetDataSourceEndpoint) => {
  let endpoint = buildFilteredEndpoint("data-source-values", {
    query,
    dataSourceKey: keyName,
    page,
    size,
    sort,
  });

  if (lastModifiedDate) {
    endpoint = `${endpoint}&lastModifiedDate=${lastModifiedDate}`;
  }

  return endpoint;
};

interface CombineDataSourceResponse {
  dataSourceResponse?: Array<DataSourceValueDTO> | null;
  keyName: string;
  previousDataSourceData: {
    [key: string]: Array<DataSourceValueDTO>;
  };
}

/**
 * Takes the given API response (dataSourceResponse)
 * and combines into Data Source Reducer
 * @param dataSourceResponse - Array<DataSourceValueDTO>
 * @param keyName - string
 * @param previousDataSourceData - Store.dataSources.data[keyName]
 */
export const combineDataSourceResponse = ({
  dataSourceResponse,
  keyName,
  previousDataSourceData,
}: CombineDataSourceResponse) => {
  if (isArray(dataSourceResponse)) {
    const updatedData = chain(dataSourceResponse)
      .uniqBy("id")
      .filter((x) => !x.softDeleted)
      .value();

    return {
      ...previousDataSourceData,
      [keyName]: updatedData,
    };
  }

  return previousDataSourceData;
};
