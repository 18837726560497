import React, { ChangeEvent } from "react";
import { FormTypeDTO } from "../../../store/forms/types";
import { FilterSelect } from "../../common/FilterSelect";
import BaseSearchBar from "../../filters/BaseSearchBar";
import {
  mapFormTypesToFilter,
  TimeFilterType,
  timeFilterOptions,
  getSubOrdinatesOptions,
} from "../../helpers/filters";
import * as S from "./styles";
import { DateRangeValue } from "components/common/DateRange";
import { debounce } from "lodash";
import { useSelector } from "react-redux";
import { reportsFiltersSelector } from "store/filters/reports/selectors";
import moment from "moment";
import { participantTreeOptionsSelector } from "store/participantTreeFilter/selectors";

type FilterOption = {
  id: number;
  value: string;
};

type Props = {
  idPrefix: string;
  setNewPageNum: (n: number) => void;
  setClientGroupId: (i: number) => void;
  groupFilterOptions: Array<FilterOption>;
  setDocumentType: (t: number) => void;
  setSubordinatesFilter: (t: string) => void;
  formTypes: Array<FormTypeDTO>;
  handleChangeDateRange: (dateRange: DateRangeValue | null) => void;
  setQuery: (q: string) => void;
  onClear: () => void;
  setTimeFilterType: (type: TimeFilterType) => void;
};

/** Filter bar for reports */
export const ReportsFilters = ({
  idPrefix,
  setNewPageNum,
  setClientGroupId,
  groupFilterOptions,
  setDocumentType,
  setSubordinatesFilter,
  formTypes,
  handleChangeDateRange,
  setQuery,
  onClear,
  setTimeFilterType,
}: Props) => {
  const participantTreeOptions = useSelector(participantTreeOptionsSelector);
  const subordinatesOptions = getSubOrdinatesOptions(participantTreeOptions);
  const filtersSelect = useSelector(reportsFiltersSelector);
  const { filterParams } = filtersSelect;
  const customRangeId = `${idPrefix}-customRange`;

  const handleQuery = (query) => {
    setNewPageNum(0);
    setQuery(query);
  };

  const handleChangeSubordinatesFilter = (
    e: ChangeEvent<HTMLSelectElement>
  ) => {
    setNewPageNum(0);
    setSubordinatesFilter(e.target.value);
  };

  const debouncedHandleSearch = debounce(handleQuery, 300);

  const initialCustomDates = filterParams.dateRange
    ? {
        // @ts-ignore
        from: moment(filterParams.dateRange?.startDate),
        // @ts-ignore
        to: moment(filterParams.dateRange?.endDate),
      }
    : {};

  return (
    <>
      <S.FilterWrapper>
        <S.SelectWrapper>
          <FilterSelect
            name="groups"
            value={filterParams.groupId}
            label=""
            options={groupFilterOptions}
            handleChange={(e) => {
              setNewPageNum(0);
              setClientGroupId(e.target.value);
            }}
          />
        </S.SelectWrapper>

        <S.SelectWrapper>
          <FilterSelect
            name="documentType"
            value={filterParams.documentType}
            label=""
            options={mapFormTypesToFilter(formTypes) || []}
            handleChange={({ target: { value } }) => {
              setNewPageNum(0);
              setDocumentType(value);
            }}
          />
        </S.SelectWrapper>

        <S.SelectWrapper>
          <FilterSelect
            name="timeFilter"
            value={filterParams.timeFilterType}
            label=""
            options={timeFilterOptions}
            handleChange={({ target: { value } }) => {
              setNewPageNum(0);
              setTimeFilterType(value);
            }}
          />
        </S.SelectWrapper>

        {!participantTreeOptions.hideFilter && (
          <S.SelectWrapper>
            <FilterSelect
              name="subordinatesFilter"
              value={filterParams.subordinatesFilter}
              label=""
              options={subordinatesOptions}
              handleChange={handleChangeSubordinatesFilter}
            />
          </S.SelectWrapper>
        )}

        <BaseSearchBar
          onSearch={(query) => {
            debouncedHandleSearch(query);
          }}
          initialQuery={filterParams.query}
        />
      </S.FilterWrapper>
      {filterParams.timeFilterType === "CUSTOM_RANGE" && (
        <S.DateRangeRow>
          <S.DateRange
            handleChange={handleChangeDateRange}
            labelId={customRangeId}
            name={`${idPrefix}-customRange`}
            onClear={onClear}
            initialValues={initialCustomDates}
          />
        </S.DateRangeRow>
      )}
    </>
  );
};
