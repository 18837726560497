import React from "react";

import { DocumentParticipant } from "store/documents/types";
import { createLabel } from "util/index";

import * as S from "./styles";
import RenderDrawnSignature from "./RenderDrawnSignatures";
import RenderTypedSignature from "./RenderTypedSignature";

type RenderSignatureProps = Pick<
  DocumentParticipant,
  "signatureType" |
  "signatureTextValue" |
  "email" |
  "signatureUrl"
>

/**
 * Based on the signatureType, determines wether we
 * render typed or drawn
 */
const RenderSignature = (props: RenderSignatureProps) => {
  if (props.signatureType === "DRAWN") {
    return (
      <RenderDrawnSignature 
        signatureUrl={props.signatureUrl}
      />
    );
  } else if (props.signatureType) {
    return (
      <RenderTypedSignature
        signatureTextValue={props.signatureTextValue || props.email}
      />
    );
  }
  
  return null;
};

interface Props {
  participants: Array<DocumentParticipant>;
}

/**
 * Renders elements for Participant Signatures.
 * Only renders Participants which have have signed.
 */
const ViewSignatures = ({ participants }: Props) => {
  const signedParticipants = participants.filter((x) => x.signatureType);

  if (signedParticipants.length === 0) return null;

  return (
    <S.ViewSignaturesWrapper>
      <S.SignatureTitle as="h3">Signatures</S.SignatureTitle>
      {
        signedParticipants.map((participant) => (
          <S.SignatureItem key={participant.participantId}>
            <S.SignatureLabel as="p">{createLabel(participant)}</S.SignatureLabel>
            <RenderSignature {...participant} />
          </S.SignatureItem>
        ))
      }
    </S.ViewSignaturesWrapper>
  );

};

export default ViewSignatures;
