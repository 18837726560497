import React from "react";
import { css } from "aphrodite/no-important";

import styles from "../styles";

const PublishedBanner: React.FC<{ visible: boolean }> = ({ visible }) => {
  if (!visible) return null;

  const s = styles();
  return (
    <div className={css(s.publishedBannerWrapper)}>
      <div className={css(s.publishedBanner)}>
        This form is published. To make changes, click Edit and convert the form
        to a draft.
      </div>{" "}
    </div>
  );
};

export default PublishedBanner;
