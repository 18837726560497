import React, { useMemo } from "react";
import { connect, useDispatch } from "react-redux";
import { get } from "lodash";
import { ThemeContext } from "styled-components";
import moment from "moment";

import { AppState } from "store";
import { DocumentQuestionResponseVm } from "store/documents/types";
import { FieldErrorType } from "components/FormController/components/Question";
import { FlattenedWorkLocation, WorkLocation } from "store/workLocation/types";
import { flattenWorkLocations } from "store/workLocation/util";
import { GeoPoint, QuestionAnswer } from "store/common/types";
import {
  getCurrentPosition,
  reverseGeolocation,
  geoCode,
  formatGeolocation,
} from "util/geo";
import { MapWidgetQuestionDTO, QuestionDTO } from "store/forms/types";
import useDebounce from "util/hooks/useDebounce";

import { AssistiveLink } from "../../common/form/types";
import { DataSourceItem } from "../ItemSelectorDrawer/ItemSelectorForm/ItemSelectorForm";
import { Viewport } from "../../map/types";
import * as S from "./styles";
import DataSourceDrawer from "../DataSourceDrawer";
import { searchDataSourceValues } from "store/dataSource/actions";
import { ThunkDispatch } from "redux-thunk";
import {
  DataSourceValueDTO,
  GetDataSourceValuesAction,
} from "store/dataSource/types";
import { TextInputWithSuggestions } from "../../common/form/TextInput/futureUiKit/TextInputWithSuggestions";
import { TextInputSuggestion } from "../../common/form/TextInput/TextInputWithSuggestions";

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  assistiveLink?: AssistiveLink;
  elementBefore?: React.ReactNode;
  error?: FieldErrorType;
  isFinalPage?: boolean;
  isLoadingMore?: boolean;
  lastPageLoaded: number;
  onLoadMore?: () => void;
  question: QuestionDTO | (QuestionDTO & MapWidgetQuestionDTO);
  response?: DocumentQuestionResponseVm;
  setQuestionResponse: (questionResponse?: DocumentQuestionResponseVm) => void;
  setViewport?: (viewport: Viewport) => void;
  workLocations: Array<FlattenedWorkLocation>;
  handleBlur?: () => void;
  customItemRef?: React.RefObject<HTMLDivElement>;
  enableWorkLocationAutoComplete?: boolean;
}

// helper method to choose error message
function getLocalError(
  geoCodeStatus: string,
  error?: FieldErrorType
): FieldErrorType | undefined {
  if (error) {
    return error;
  }
  if (geoCodeStatus === "blocked") {
    return "Unable to find address: browser location permissions blocked";
  }
  if (geoCodeStatus === "failed") {
    return "Error while finding location of address";
  }
  return;
}

function getLocalWarning(response?: DocumentQuestionResponseVm) {
  if (response && !response.associatedLocation) {
    return "Answer accepted, but cannot drop pin for this address";
  }
  return;
}

function Location({
  disabled,
  elementBefore,
  error,
  isFinalPage,
  isLoadingMore,
  onLoadMore,
  question,
  response,
  setQuestionResponse,
  handleBlur,
  customItemRef,
  enableWorkLocationAutoComplete,
}: Props) {
  const [locationStatus, setLocationStatus] = React.useState<
    "initial" | "loading" | "failed" | "success" | "invalid" | "blocked"
  >("initial");
  const [searchDrawerOpen, setSearchDrawer] = React.useState<boolean>(false);
  const responseAnswer: string = response?.answer || "";
  const [value, setValue] = React.useState(responseAnswer);
  const [suggestions, setSuggestions] = React.useState<
    Array<TextInputSuggestion>
  >([]);

  // Is question required
  const isRequired = question.formProperties?.isRequired;

  const theme = React.useContext(ThemeContext);

  const dispatch = useDispatch<
    ThunkDispatch<AppState, void, GetDataSourceValuesAction>
  >();

  /* set this field's value to the current response on mount if it exists */
  React.useEffect(() => {
    setValue(response?.answer || "");
  }, [response]);

  /** Denotes whether this field is GPS enabled */
  const gpsField = get(question.answerSource, ["type"], "") === "CURRENT_GPS";

  /** Pin to use for field depending on type */
  const pinVariant = gpsField ? "emergency" : "primary";

  /** Update question response and reset status */
  const clearResponse = () => {
    setQuestionResponse();
    setLocationStatus("initial");
    setValue("");
  };

  /**
   * Set a location response when user selects an option in the location drawer or finds their current geolocation
   * @param answer
   */
  function setLocationResponse(answer: QuestionAnswer) {
    if (!answer.answer) {
      return setQuestionResponse();
    }
    // set answer locally and in form responses
    setQuestionResponse({
      ...answer,
      questionId: question.id,
      questionRootId: question.rootId,
      timeAnswered: moment.utc().format(),
    });
  }

  async function getGeolocation(value?: string) {
    if (!value) {
      return null;
    }

    setLocationStatus("loading");
    try {
      const geocode = await geoCode(value);
      setLocationStatus(geocode ? "success" : "invalid");
      return geocode;
    } catch (err) {
      err.code === 1
        ? setLocationStatus("blocked")
        : setLocationStatus("failed");
    }
  }

  /**
   * Attempt to retrieve a geolocation using the value that the user has entered into the field
   * @param value
   */
  async function updateGeolocation(value?: string) {
    setLocationStatus("initial");

    // prevent action when user hasn't changed value
    // or if we already have a successful geolocation value
    if (!value || value.length < 3 || value === (response && response.answer)) {
      return null;
    }

    return await getGeolocation(value);
  }

  /** Get the coordinates of the user and attempt to get the nearest address - then set either as the response value */
  async function getCurrentLocation() {
    if (locationStatus !== "loading") {
      setLocationStatus("loading");

      let geolocation: GeoPoint | undefined;

      const position = await getCurrentPosition();
      // if coordinates were found for the user's location
      if ("coords" in position && position.coords) {
        geolocation = {
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        };
        // use the user's coordinates to get the nearest address
        const reverseGeo = await reverseGeolocation(geolocation);
        const answer =
          reverseGeo?.display_name ||
          (geolocation && formatGeolocation(geolocation)) ||
          "";
        // set value in state
        // set the response to the nearest address to the user or coordinates if none found
        setLocationResponse({
          answer,
          associatedLocation: geolocation,
        });
        setLocationStatus("success");
        // coordinates were not found for the user's location
      } else {
        setLocationStatus("failed");
      }
    }
  }

  /** Retrieve an address and set it in form responses when field is blurred */
  async function setResponse(
    event: React.FocusEvent<HTMLInputElement>
  ): Promise<void> {
    // get geolocation and set response if field is not empty and does not match current response
    if (event.target.value !== response?.answer) {
      const { value } = event.target;
      if (!value) return setQuestionResponse();
      let response: DocumentQuestionResponseVm = {
        answer: value,
        questionId: question.id,
        questionRootId: question.rootId,
        timeAnswered: new Date().toISOString(),
      };
      const geolocation = await updateGeolocation(value);
      if (geolocation) {
        response = {
          ...response,
          answer: geolocation.address,
          associatedLocation: geolocation.geolocation,
        };
      }
      setQuestionResponse(response);
    }
  }

  async function setResponseFromDrawer(item: DataSourceItem): Promise<void> {
    setLocationStatus("initial");
    if (!item.associatedLocation) {
      const geolocation = await updateGeolocation(item.subtitle);
      if (geolocation) {
        item.associatedLocation = geolocation.geolocation;
      }
    }
    setLocationResponse({
      answer: item.value || item.title,
      associatedId: Number(item.id),
      associatedLocation: item.associatedLocation,
    });
  }

  /** Calculate the assistive link based on current status */
  function configureAssistiveLink(): AssistiveLink {
    const label = get(
      question.answerSource,
      "properties.detailedSearch.linkName",
      ""
    );
    // const workLocationFromStore = response && response.associatedId;

    if (locationStatus === "loading") {
      return {
        label: "...loading",
        color: theme.colors.darkGrey,
      };
    }

    /* Allow clearing the field when location has been entered */
    if (gpsField) {
      if (response?.answer) {
        return {
          label: "Clear",
          color: theme.colors.error,
          onClick: clearResponse,
        };
      } else {
        return {
          label: "",
          onClick: getCurrentLocation,
        };
      }
    }

    return {
      label,
      onClick: () => setSearchDrawer(true),
    };
  }

  const handleClose = () => {
    setSearchDrawer(false);
  };

  const getSelected = (): DataSourceItem[] => {
    if (
      response &&
      response.associatedId &&
      response.questionId === question.id
    ) {
      const item = {
        id: response.associatedId.toString(),
        title: response.answer,
        value: response.answer,
        associatedLocation: response.associatedLocation,
      };

      return [item];
    }

    return [];
  };

  // Helper text
  const helperText = useMemo(
    () => question.properties?.assistiveText || undefined,
    [question.properties?.assistiveText]
  );

  /** Debounced call to get data source values and set suggestions from response */
  const setDSSuggestions = useDebounce({
    async method(query: string) {
      if (!query) return setSuggestions([]);
      const res = await dispatch(
        searchDataSourceValues({
          keyName: "WORK_LOCATION",
          query,
          size: 40,
          sortBy: "name",
        })
      );
      const dsValues = res?.response?.content;
      if (dsValues) {
        return setSuggestions(
          dsValues.map((dsv: DataSourceValueDTO) => ({
            title: dsv.content.name,
            geolocation: dsv.content.geolocation,
            id: dsv.id,
          }))
        );
      }
      return setSuggestions([]);
    },
  });

  /* Get and set suggestions on user input */
  React.useEffect(() => {
    if (enableWorkLocationAutoComplete) {
      if (value.length < 3 || responseAnswer === value) {
        setDSSuggestions("");
      } else {
        setDSSuggestions(value);
      }
    }
  }, [responseAnswer, value, dispatch, searchDataSourceValues]);

  // Geocode the answer if it's prefilled from a work order.
  React.useEffect(() => {
    (async () => {
      if (!response?.associatedLocation && response?.answer) {
        const geolocation = await getGeolocation(response?.answer);
        if (geolocation) {
          setLocationResponse({
            answer: response?.answer,
            associatedLocation: geolocation.geolocation,
          });
        }
      }
    })();
  }, [response]);

  return (
    <>
      {question.answerSource && searchDrawerOpen && (
        <DataSourceDrawer
          isOpen={searchDrawerOpen}
          answerSource={question.answerSource}
          onChangeOpen={handleClose}
          onSubmit={(values: DataSourceItem[]) => {
            values.forEach((val) => {
              setResponseFromDrawer(val);
            });
            handleClose();
          }}
          allowMultipleAnswers={question.properties?.allowMultipleAnswers}
          selected={getSelected()}
          submitButtonLabel={{
            prefix: "Add",
            label: "Work Location",
          }}
          onLoadMore={onLoadMore}
          isFinalPage={isFinalPage}
          isLoadingMore={isLoadingMore}
        />
      )}

      <TextInputWithSuggestions
        idField="id"
        labelField="title"
        assistiveLink={configureAssistiveLink()}
        disabled={disabled || locationStatus === "loading"}
        elementBefore={
          elementBefore || (
            <S.PinIcon
              variant={pinVariant}
              className="icon icon-icons8-marker"
            />
          )
        }
        error={getLocalError(locationStatus, error)}
        helperText={helperText}
        required={isRequired}
        label={question.title}
        name={question.id.toString()}
        placeholder={get(
          question.properties,
          "placeHolderText",
          question.title
        )}
        onBlur={(e) => {
          if (suggestions.length > 0) {
            setSuggestions([]);
          }
          setResponse(e);
          handleBlur?.();
        }}
        onInputChange={(value) => setValue(value)}
        suggestions={suggestions}
        onSelectSuggestion={(suggestion) => {
          setSuggestions([]);
          setValue(suggestion.title);
          setLocationResponse({
            answer: suggestion.title,
            associatedId: suggestion.id,
            associatedLocation: suggestion.geolocation,
          });
        }}
        value={value}
        customItemRef={customItemRef}
        warning={getLocalWarning(response)}
      />
    </>
  );
}

const mapStateToProps = (state: AppState) => {
  const workLocations =
    ((state.dataSource.data["WORK_LOCATION"] as unknown) as WorkLocation[]) ||
    [];
  const lastPageLoaded =
    state.dataSource.lastPageLoaded?.["WORK_LOCATION"] || 0;
  const flattenedLocations = flattenWorkLocations(workLocations);
  return {
    workLocations: flattenedLocations,
    lastPageLoaded,
  };
};

export default connect(mapStateToProps)(Location);
