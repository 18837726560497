import React, { useEffect, useState } from "react";
import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";

import Breadcrumbs from "components/common/Breadcrumbs";
import { AppState } from "store";
import { ContentWrapper } from "components/common/Wrappers";
import { GET_TAG_BY_ID } from "store/tagLibrary/types";
import { LoadingWrapper, PageLoader } from "components/clientAdmin/resources/styles";
import { TagDTO } from "store/tagLibrary/types";
import { getTagById } from "store/tagLibrary/actions";
import TagForm from "../components/TagForm";


export const AddTag = () => {
  const [tag, setTag] = useState<TagDTO>({
    createdDate: "",
    id: 0,
    name: "",
    useCount: 0,
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);

  // get tag id from url params
  const { id: tagId } = useParams();

  const dispatch = useDispatch<ThunkDispatch<AppState, void, Action>>();

  const fetchTag = async (id: string) => {
    setIsLoading(true);
    const res = await dispatch(getTagById(id));
    if (res.type === GET_TAG_BY_ID.SUCCESS) {
      setTag(res.response);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (tagId) {
      fetchTag(tagId);
    }
  }, []);

  const currentPathName = tagId ? "Edit Tag" : "Add Tag";

  return (
    <ContentWrapper>
      <Breadcrumbs
        paths={[
          {
            pathName: "Content",
          },
          {
            pathName: "Tag Library",
            href: "/content/tag-libraries",
          },
          {
            pathName: currentPathName,
            // href: `/content/tag-libraries/tag/${tagId}`,
          },
        ]}
      />
      <LoadingWrapper>
        <PageLoader loading={isLoading} />
        <TagForm
          initialValues={tag}
        />
      </LoadingWrapper>
    </ContentWrapper>
  );
};
