import React from "react";
import { css } from "aphrodite/no-important";
import { useFormikContext, Field } from "formik";

import { DataSource } from "store/dataSource/types";
import { Option } from "components/common/form/Select/Select";
import { FBForm, FBSection, FBItem } from "components/clientAdmin/formBuilder/types";
import Banner from "components/common/Banner/Banner";
import Select from "components/common/form/Select";

import propertiesStyles from "../../styles";
import baseStyles from "../../../../styles";

interface PrefillConditionProps {
  conditionIndex: number;
  dataSet?: DataSource;
  targetOptions: Option[];
  targetItems: (FBItem | FBSection)[];
  sourceColumns: {id: string | number; value: string}[];
}

// @TODO what question types can be prefilled?
const PrefillCondition = ({ 
  conditionIndex, 
  dataSet, 
  targetOptions, 
  targetItems,
  sourceColumns,
}: PrefillConditionProps) => {
  const { setFieldValue } = useFormikContext<FBForm>();

  const ps = propertiesStyles();
  const bs = baseStyles();

  if (!dataSet) {
    return <Banner type="warning">You must add a data set to set a Prefill rule</Banner>;
  }

  return (
    <div className={css(ps.conditionRules)}>
      <div className={css([bs.row, ps.dcRow])}>
        <span className={css(ps.text)}>Use</span>
        {/* @TODO what if we need a prefillAssociatedIdField? */}
        <Field
          as={Select}
          wrapperClassName={css([ps.dcSelect, ps.prefillSelect])}
          name={`displayConditions[${conditionIndex}].prefillAnswerField`}
          options={sourceColumns}
        />
      </div>
      <span className={css([ps.text, ps.dcRow])}>
        from this question&apos;s data set
      </span>
      <div className={css([bs.row, ps.dcRow])}>
        <span className={css(ps.text)}>to prefill</span>
        <Field
          as={Select}
          wrapperClassName={css([ps.dcSelect, ps.prefillSelect])}
          name={`displayConditions[${conditionIndex}].targetRootId`}
          options={targetOptions}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            const targetItem = targetItems.find((tItem) => tItem.id === Number(e.target.value));
            setFieldValue(`displayConditions[${conditionIndex}].targetRootId`, e.target.value);
            setFieldValue(`displayConditions[${conditionIndex}].targetType`, targetItem?.type);
            setFieldValue(`displayConditions[${conditionIndex}].action`, "PREFILL");
          }}
        />
      </div>
    </div>
  );
};

export default PrefillCondition;
