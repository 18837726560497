import { connect } from "react-redux";
import { Formik, FormikHelpers } from "formik";
import { RouteComponentProps, withRouter } from "react-router";
import React from "react";
import { delay } from "lodash";

// import { ErrorText } from "components/common/form/TextInput/styles";
import * as S from "./styles";
import ConditionalWrapper from "components/forms/ShareForm/components/ConditionalWrapper";
import Label from "components/forms/Label";
import { AppState } from "store";
import { DataSourceState } from "store/dataSource/types";
import { DocumentSummary, DocumentVM } from "store/documents/types";
import { clearDataSourceValues } from "store/dataSource/actions";
import { shareDocument } from "store/shareDocument/actions";

import { ErrorsType, FormSubmitPayload, ShareFormValues } from "./types";

import AddMemberDropdown from "./components/AddMemberDropdown";
import DataSourceDrawer from "../DataSourceDrawer";
import { DataSourceItem } from "../ItemSelectorDrawer/ItemSelectorForm/ItemSelectorForm";
import { ShareFormButtons } from "./components/ShareFormButtons";

// Types

const initialValues: ShareFormValues = {
  members: [],
};

// Sync validation function for Formik
const validate = (values) => {
  const errors: ErrorsType = {};

  if (values.members.length < 1) {
    errors.members = "Please select a member";
  }

  return errors;
};

interface ShareFormState {
  itemSelectorVisible: boolean;
  memberInput?: string;
  participants: DataSourceItem[] | [];
  submitLoading: boolean;
  submitStatus: "success" | "failure" | "request" | null;
  // Infinite scroll
  isLastPage: boolean;
  isLoadingMore: boolean;
}

interface ShareFormProps extends RouteComponentProps {
  assistiveText?: boolean;
  dispatch: any;
  selectedDocument: DocumentSummary | DocumentVM | null;
  shareDocumentVisible?: boolean; // Is this even needed? -- GK
  toggleShareDocumentVisibility: () => void;

  // ? REDUX
  selectors: {
    authToken: string | null;
  };
  store: {
    dataSource: DataSourceState;
    lastPageLoaded?: number;
    participantsSourceIsLoading?: boolean;
  };
}

class ShareForm extends React.Component<ShareFormProps, ShareFormState> {
  constructor(props) {
    super(props);
    this.state = {
      itemSelectorVisible: false,
      memberInput: "",
      participants: [],
      submitLoading: false,
      submitStatus: null,
      // Infinite scroll
      isLastPage: false,
      isLoadingMore: false,
    };
  }

  componentDidUpdate() {
    const { submitStatus } = this.state;

    if (submitStatus === "success") {
      delay(() => this.props.toggleShareDocumentVisibility(), 1000);
    }
  }

  toggleItemSelectorVisibility = () => {
    return this.setState((prevState: any) => ({
      itemSelectorVisible: !prevState.itemSelectorVisible,
    }));
  };

  _handleFormSubmit = async (
    values: ShareFormValues,
    resetForm: FormikHelpers<ShareFormValues>["resetForm"],
  ) => {
    const { members } = values;
    const { selectedDocument } = this.props;

    if (members && selectedDocument) {
      //Convert the members data into an array with just their ids
      const participantIds = members.map((member) => member.id);

      //Build an object with data to pass to API call
      const payload: FormSubmitPayload = {
        documentId: selectedDocument.id,
        participantIds,
      };

      //API call to submit
      this.setState(() => ({ submitLoading: true, submitStatus: null }));

      try {
        const { type } = await this.props.dispatch(shareDocument(payload));

        if (type === "SHARE_DOCUMENT_SUCCESS") {
          this.setState(() => ({
            submitStatus: "success",
            submitLoading: false,
          }));
          resetForm();
        } else {
          this.setState(() => ({
            submitStatus: "failure",
            submitLoading: false,
          }));

          throw Error("Failed to Share Document");
        }
      } catch (error) {
        throw Error(error);
      }
    }
  };

  // handleResponse = ({
  //   content,
  //   members
  // }: {
  //   members: DataSourceItem[];
  //   content: ParticipantProps[];
  // }) => {
  //   if (members.length > 0) {
  //     //Build an array of member ids in form state
  //     //to compare against response array
  //     const memberIds = members.map((member) => member.id);
  //     //Filter the response to exclude values already in the above array
  //     const filteredResponseArray = content.filter(
  //       (item) => !memberIds.includes(item.id)
  //     );
  //     //And update component state
  //     this.setState({ participants: filteredResponseArray });
  //   } else {
  //     //Otherwise, store response in state for first time
  //     this.setState({ participants: content });
  //   }
  // };

  render() {
    const {
      dispatch,
    } = this.props;

    const { submitStatus } = this.state;

    return (
      <Formik
        initialValues={initialValues}
        onSubmit={(values, { setFieldTouched, resetForm }) => {
          //Set field to touched
          setFieldTouched("members", true, true);

          this._handleFormSubmit(values, resetForm);
        }}
        validate={validate}
        validateOnChange={false}
      >
        {(props) => {
          const {
            errors,
            handleSubmit,
            setValues,
            touched,
            values,
          } = props;
          const { members } = values;
          return (
            <>
              <DataSourceDrawer
                isOpen={this.state.itemSelectorVisible}
                answerSource={{
                  dataSourceKey: "PARTICIPANT",
                  id: 0,
                  properties: {
                    autoComplete: true,
                    detailedSearch: {
                      infiniteListTitle: "Add Another Member",
                      recentAnswersEnabled: false,
                      linkName: "Add Another Member",
                      title: "Add Another Member",
                      searchBy: "Search by name",
                      infiniteListEnabled: true,
                      subtitle: "Who would you like to add?",
                      customAnswerEnabled: false,
                      enabled: true,
                      infiniteListSortBy: "fullName",
                    },
                    answerField: "fullName",
                  },
                  type: "DATA_SOURCE",
                }}
                onChangeOpen={() => {
                  // clear any searched data source values
                  dispatch(clearDataSourceValues({ keyName: "PARTICIPANT" }));
                  // open/close the drawer
                  this.toggleItemSelectorVisibility();
                }}
                onSubmit={(vals: DataSourceItem[]) => {

                  // Get data
                  const _participantsToAdd = new Set();
                  vals.forEach((val: DataSourceItem) => _participantsToAdd.add(val));
                  const __participantsToAddArray: DataSourceItem[] = Array.from(_participantsToAdd) as DataSourceItem[];

                  // Update form values
                  setValues({
                    ...values,
                    members: __participantsToAddArray,
                  });
                  // Close drawer
                  this.toggleItemSelectorVisibility();
                }}
                allowMultipleAnswers={true}
                selected={values.members.map((member) => {
                  const item = {
                    id: member.id.toString(),
                    title: member.title,
                    value: member.value,
                  };

                  return item;
                })}
                submitButtonLabel={{
                  prefix: "Add",
                  label: "Member",
                }}
                // Infinite scroll @TODO
                // isLastPage={this.state.isLastPage}
                // isLoadingMore={this.state.isLoadingMore}
                // onLoadMore={this.loadMore}
              />
              <form onSubmit={handleSubmit}>
                <Label
                  htmlFor={"members"}
                  assistiveLink={{
                    label: "Add Another Member",
                    onClick: this.toggleItemSelectorVisibility,
                  }}
                >
                  Add Member
                </Label>
                {/* Will wrap around input and list items once first selection is made */}
                <ConditionalWrapper
                  condition={members.length > 0}
                  wrapper={(children) => (
                    <S.ListItems>{children}</S.ListItems>
                  )}
                >
                  {members.map((participant) => (
                    <S.ListItemOption
                      key={participant.id}
                      variant="participant-selected"
                      renderLabelsAsRow
                      label={participant.title}
                      endAdornment={{
                        hideTextDecoration: true,
                        visible: true,
                        type: "button",
                        onClick: () => {
                          // method which removes participant from
                          // form state
                          const filteredArray = members.filter(
                            (member) => member.id !== participant.id,
                          );
                          setValues({ ...values, members: filteredArray });
                        },
                        label: (
                          <S.IconForEndAdornment className="icon icon-icons8-delete_sign"/>
                        ),
                      }}
                    />
                  ))}

                  <AddMemberDropdown
                    touched={touched}
                    selectMember={(id: number, fullName: string) => {
                      this.setState({ submitStatus: null }); // IDK if we need this yet / still
                      const member: DataSourceItem = {
                        id: id.toString(),
                        title: fullName,
                        value: fullName,
                      };
                      setValues({
                        ...values,
                        members: [...members, member],
                      });
                    }}
                    authToken={this.props.selectors.authToken}
                  />

                  {this.props.assistiveText && (
                    <S.SubLabel>{this.props.assistiveText}</S.SubLabel>
                  )}
                </ConditionalWrapper>

                <ShareFormButtons submitStatus={submitStatus} onCancel={this.props.toggleShareDocumentVisibility}/>

              </form>
            </>
          );
        }}
      </Formik>
    );
  }
}

const mapStateToProps = (state: AppState) => {
  return {
    selectors: {
      authToken: state.system.authToken,
    },
    store: {
      dataSource: state.dataSource,
      lastPageLoaded: state.dataSource.lastPageLoaded?.["PARTICIPANT"] || 0,
      participantsSourceIsLoading: state.dataSource.loading.getDataSourceValues.PARTICIPANT,
    },
  };
};

export default withRouter(connect(mapStateToProps)(ShareForm));
