import React from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { withTheme } from "styled-components";
import CheckboxBase from "@material-ui/core/Checkbox";

import { ThemeTypes } from "themes/colors";
import ErrorText from "components/common/form/ErrorText";
import { CheckBoxTwoTone } from "components/svg/CheckBoxTwoTone";
import { CheckBoxTwoToneBlank } from "components/svg/CheckBoxTwoToneBlank";

import {
  CheckboxTheme,
  useCheckboxStyles,
  LabelTheme,
  useLabelStyles,
} from "./styles";
import { CheckBoxFilled } from "components/svg/CheckBoxFilled";
import { CheckBoxFilledBlank } from "components/svg/CheckBoxFilledBlank";

export interface CheckboxProps {
  checked?: boolean;
  className?: string;
  containerClassName?: string;
  color?: string;
  disabled?: boolean;
  disabledColor?: string;
  error?: string;
  onChange?: (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => void;
  label?: React.ReactNode;
  name?: string;
  theme: ThemeTypes;
  uncheckedColor?: string;
  value?: number | string;
}

function Checkbox({
  checked = false,
  className,
  containerClassName,
  color,
  disabled,
  disabledColor,
  error,
  onChange,
  label,
  name,
  theme,
  uncheckedColor,
  value = "",
}: CheckboxProps) {
  const _theme: CheckboxTheme = {
    color: color || theme.colors.primary,
    disabledColor: disabledColor || theme.masterColors.darkGreyBlue,
    disabledFillColor: theme.masterColors.greyBlue,
    errorColor: theme.colors.error,
    errorFillColor: theme.colors.errorFill,
    focusColor: theme.colors.focusIndicatorOutline,
    hoverColor: theme.colors.activeFill,
    uncheckedColor: uncheckedColor || theme.masterColors.darkGrey,
  };

  const classes = useCheckboxStyles({ theme: _theme, disabled, error });

  const labelTheme: LabelTheme = {
    color: theme.colors.black,
    disabledColor: theme.masterColors.darkGreyBlue,
    errorColor: theme.colors.error,
    hoverColor: theme.colors.activeFill,
  };

  const labelClasses = useLabelStyles({ theme: labelTheme, disabled, error });

  let checkedIcon: React.ReactNode;
  let uncheckedIcon: React.ReactNode;
  if (disabled) {
    checkedIcon = <CheckBoxTwoTone />;
    uncheckedIcon = <CheckBoxTwoToneBlank />;
  } else if (error) {
    checkedIcon = <CheckBoxTwoTone />;
    uncheckedIcon = <CheckBoxTwoToneBlank />;
  } else {
    checkedIcon = <CheckBoxFilled />;
    uncheckedIcon = <CheckBoxFilledBlank />;
  }

  // If there's a label
  if (label) {
    return (
      <>
        <FormControlLabel
          classes={labelClasses}
          className={containerClassName}
          control={
            <CheckboxBase
              className={className}
              classes={classes}
              checked={checked}
              checkedIcon={checkedIcon}
              disableRipple
              disabled={disabled}
              icon={uncheckedIcon}
              name={name}
              onChange={onChange}
              value={value}
            />
          }
          label={label}
        />
        {error && <ErrorText>{error}</ErrorText>}
      </>
    );
  }
  // If there's no label
  return (
    <>
      <CheckboxBase
        classes={classes}
        checked={checked}
        checkedIcon={checkedIcon}
        disableRipple
        disabled={disabled}
        icon={uncheckedIcon}
        onChange={onChange}
        value={value}
      />
      {error && <ErrorText>{error}</ErrorText>}
    </>
  );
};

export default withTheme(Checkbox);
