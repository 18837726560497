import styled from "styled-components";

const Wrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  position: relative;
  width: 100%;
  height: 100vh;
  // Background
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url(${require("./splash_141227807.png")});
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: -200px;
`;

const Logo = styled.div`
  align-items: center;
  background-image: ${({ theme }) => `linear-gradient(180deg, ${theme.colors.primary}, ${theme.masterColors.secondary})`};
  border-radius: 143px;
  display: flex;
  height: 96px;
  justify-content: center;
  width: 96px;
`;

const LogoIcon = styled.i.attrs({
  className: "icon-icons8-lightning_bolt"
})`
  color: ${({ theme }) => theme.colors.white};
  font-size: 50px;
`;

const LogoBrand = styled.span`
  color: ${({ theme }) => theme.colors.white};
  font-size: 2.25rem;
  font-weight: 700;
  letter-spacing: 3.21px;
  margin-top: 15px;
`;

const Status = styled.i`
  color: ${({ theme }) => theme.colors.white};
  font-size: 1rem;
  font-weight: 400;
  letter-spacing: 0.15px;
  margin-top: 10px;
`;

export default {
  ContentWrapper,
  Logo,
  LogoBrand,
  LogoIcon,
  Status,
  Wrapper,
};
