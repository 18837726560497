import {
  APIResponse,
  Group,
  MethodTypes,
  Pagination,
  SortParams,
} from "store/common/types";
import { FormDTO, FormProperties } from "store/forms/types";
import { ClientGroup } from "store/clientGroups/types";

export const GET_ALL_FORM_STATS = {
  REQUEST: "GET_ALL_FORM_STATS_REQUEST",
  SUCCESS: "GET_ALL_FORM_STATS_SUCCESS",
  FAILURE: "GET_ALL_FORM_STATS_FAILURE",
};

export const GET_PARTICIPANT_SUBMISSION_REPORTS = {
  REQUEST: "GET_PARTICIPANT_SUBMISSION_REPORTS_REQUEST",
  SUCCESS: "GET_PARTICIPANT_SUBMISSION_REPORTS_SUCCESS",
  FAILURE: "GET_PARTICIPANT_SUBMISSION_REPORTS_FAILURE",
};

// For /form-type-stats
export const GET_FORM_TYPE_STATS = {
  REQUEST: "GET_FORM_TYPE_STATS_REQUEST",
  SUCCESS: "GET_FORM_TYPE_STATS_SUCCESS",
  FAILURE: "GET_FORM_TYPE_STATS_FAILURE",
};

// Response for /form-type-stats
export type FormTypeStatsType = {
  formTypeIconColor: string;
  formTypeIconName: string;
  formTypeId: number;
  formTypeName: string;
  percentageOfDocuments: number;
  totalDocuments: number;
};

export interface AllFormStatsState extends Pagination {
  loading: Partial<MethodTypes>;
  errors: Partial<MethodTypes>;
  content?: AllFormStats[];
}

export interface AllFormStatsActionTypes {
  type:
    | typeof GET_ALL_FORM_STATS.REQUEST
    | typeof GET_ALL_FORM_STATS.SUCCESS
    | typeof GET_ALL_FORM_STATS.FAILURE;
  response?: any;
  formId: number | string;
}

export interface GetAllFormStatsProps {
  clientGroupIds?: string;
  maxSubmissionDate?: string;
  minSubmissionDate?: string;
  onlySubordinates?: boolean;
  participantTreeFilter: string;
  page?: number;
  size?: number;
  sort?: SortParams;
  statuses?: string;
  formTypeId?: number;
  query?: string;
}

export interface GroupCount {
  count: number;
  group: Group;
}

export interface FormStats {
  form: {
    id: number;
    name: string;
    properties: FormProperties;
    type: {
      active: boolean;
      iconColor: string;
      iconName: string;
      id: number;
      name: string;
      properties: {
        iconName: string;
        iconColor: string;
      };
    };
  };
  groupCounts: GroupCount[];
  totalCount: number;
}

interface PaginatedFormStats extends Pagination {
  content: FormStats[];
}

export interface GetAllFormStatsResponse extends APIResponse {
  response?: PaginatedFormStats;
}

export interface GetParticipantSubmissionReportsProps {
  clientGroupIds?: string | number; // Until we use an array
  formIds?: string | number; // Until we use an array
  formTypeIds?: string | number;
  maxSubmissionDate?: string;
  minSubmissionDate?: string;
  participantId: number;
  query?: string;
  statuses?: string | number; // Until we use an array
}

export type AllFormStats = {
  totalCount: number;
  form: FormDTO;
  groupCounts: [
    {
      count: number;
      group: ClientGroup;
    }
  ];
};
