import React, { useState, FC } from "react";
import styled from "styled-components";
import { debounce } from "lodash";

import BaseSearchBar from "components/filters/BaseSearchBar";
import { useDispatch, useSelector } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { AppState } from "store";
import { Action } from "redux";
import { resourceCategoriesFiltersSelector } from "store/filters/resourceCategories/selectors";
import { setResourceCategoriesFilters } from "store/filters/resourceCategories/actions";

const FiltersContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  margin: 1rem 0;
  width: "398px";
`;

const initialFilters = {
  query: "",
};

type Props = {
  onUpdateFilters: (filters: typeof initialFilters) => void;
};

const ResourceCategoryFilters: FC<Props> = ({ onUpdateFilters }) => {
  const filtersSelect = useSelector(resourceCategoriesFiltersSelector);
  const dispatch = useDispatch<ThunkDispatch<AppState, void, Action>>();

  const updateFilters = (filter: string, value: string) => {
    const newFilters = {
      ...filtersSelect.filterParams,
      [filter]: value,
    };
    dispatch(setResourceCategoriesFilters(newFilters));
    onUpdateFilters(newFilters);
  };

  const handleSearch = debounce(
    (query: string) => updateFilters("query", query),
    500
  );

  return (
    <FiltersContainer>
      <BaseSearchBar
        onSearch={handleSearch}
        initialQuery={filtersSelect.filterParams.query}
      />
    </FiltersContainer>
  );
};

export default ResourceCategoryFilters;
