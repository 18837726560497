import { CategoryDTO } from "store/categories/types";
import { CategorySaveVM } from "store/resourceCategories/actions";

export const mapInitialCategoryValues = (
  initialValues?: CategoryDTO
): CategorySaveVM => {
  if (initialValues) {
    return initialValues;
  } else
    return {
      title: "",
      archived: false,
    };
};
