import { Participant } from "../participants/types";
import { Group, Person, APIResponse } from "../common/types";
import { ClientGroup } from "../clientGroups/types";
import { WorkLocationDTO } from "../workLocation/types";
import { UserStatus } from "store/participantsSummary/types";

export const NAME = "USER";

// reducer state
export interface UserState {
  loading: {
    getUser: boolean;
    getUserFormStats: boolean;
    updateUserInfo: boolean;
  };
  error: {
    getUser: boolean;
    getUserFormStats: boolean;
    updateUserInfo: boolean;
  };
  success: {
    getUser: boolean;
    getUserFormStats: boolean;
    updateUserInfo: boolean;
  };
  data: UsersMe | null;
  userFormStats?: Array<UserFormStatsDTO>;
}

export const GET_USER = {
  REQUEST: `${NAME}/GET_USER/REQUEST`,
  SUCCESS: `${NAME}/GET_USER/SUCCESS`,
  FAILURE: `${NAME}/GET_USER/FAILURE`,
};

export const USER_FORM_STATS = {
  REQUEST: `${NAME}/USER_FORM_STATS/REQUEST`,
  SUCCESS: `${NAME}/USER_FORM_STATS/SUCCESS`,
  FAILURE: `${NAME}/USER_FORM_STATS/FAILURE`,
};

export const UPDATE_USER_INFO = {
  REQUEST: `${NAME}/UPDATE_USER_INFO/REQUEST`,
  SUCCESS: `${NAME}/UPDATE_USER_INFO/SUCCESS`,
  FAILURE: `${NAME}/UPDATE_USER_INFO/FAILURE`,
};

export interface UserActionTypes {
  type: typeof GET_USER.REQUEST | typeof GET_USER.SUCCESS | typeof GET_USER.FAILURE | typeof UPDATE_USER_INFO;
  response?: any;
}

// API VALUES

export interface UserDTO extends Person {
  accountLocked?: boolean;
  activated: boolean;
  activationKey?: string;
  authProviderId?: string;
  authProviderType: string;
  authorities?: Array<string>;
  createdBy?: string;
  createdDate?: string;
  id: number;
  imageUrl?: string;
  langKey?: string;
  lastModifiedBy?: string;
  lastModifiedByDate?: string;
  resetDate?: string;
  resetKey?: string;
}

export interface Authority {
  authority: UserRole;
  groups: Array<number>;
}

type AuthProviderType = "local" | "facebook" | "google" | "github" | "azure";
type ParticipantType = "EMPLOYEE" | "CONTRACTOR" | "EMPLOYEE_SUPERVISOR";
/**
 * response from `/api/users/me`
 */
export type UsersMe = {
  accountLocked: boolean;
  activated: boolean;
  authProviderId: number | null;
  authProviderType: AuthProviderType;
  authorities: Array<Authority>;
  clientGroups: Array<ClientGroup>;
  contractingCompany: null; // TODO find potential response
  email: string;
  firstName: string;
  fullName: string;
  imageUrl: string | null;
  isActive: boolean | null;
  isCompletedData: boolean | null;
  langKey: "en";
  lastLogin: string;
  lastName: string;
  name: string;
  nickname: string;
  participant: Participant;
  participantId: number;
  participantType: ParticipantType;
  primaryGroup: Group;
  primaryGroupId: number;
  status: UserStatus;
  supervisor: Participant;
  user: UserDTO;
  userId: number;
  workLocation: WorkLocationDTO;
};

export interface GetUserResponse extends APIResponse {
  response?: UsersMe;
}

export type UserRole =
  | "ROLE_ANONYMOUS"
  | "ROLE_CLIENT_ADMIN"
  | "ROLE_CLIENT_REVIEWER"
  | "ROLE_CONTENT_EDITOR"
  | "ROLE_FORM_ARCHITECT"
  | "ROLE_GROUP_MANAGER"
  | "ROLE_SUPER_ADMIN"
  | "ROLE_USER";

/**
 * response from `/reports/participants/${userParticipantID}/form-type-stats`
 */
export interface UserFormStatsDTO {
  formTypeIconColor?: string | null;
  formTypeIconName?: string | null;
  formTypeId: number;
  formTypeName: string;
  percentageOfDocuments: number;
  totalDocuments: number;
}

export interface UpdateUserConfig {
  email: string;
  firstName?: string;
  imageUrl?: string;
  langKey?: string;
  lastName?: string;
  nickname?: string;
  primaryGroupId?: number;
  workLocationId: number;
}
