import React from "react";
import styled, {
  css,
  StyledComponentPropsWithRef,
  ThemedStyledProps,
} from "styled-components";
import CircularProgressBase from "@material-ui/core/CircularProgress";

import { toRgba } from "themes/helpers";

export interface LoaderProps
  extends ThemedStyledProps<StyledComponentPropsWithRef<"div">, any> {
  loading: boolean;
  overlay?: boolean;
  height?: string;
}

const Loader = styled<React.FC<LoaderProps>>(
  ({ loading, overlay, height, ...otherProps }) => <div {...otherProps} />
)`
  ${({ loading, height }) =>
    loading &&
    css`
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding-top: 25px;
      padding-bottom: 25px;
      ${height ? `height: ${height}` : ""};
    `}
  ${({ overlay }) =>
    overlay &&
    `
    position: absolute;
    background: ${({ theme }) => toRgba(theme.colors.white, 0.95)};
    z-index: 1;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  `}
  width: 100%;
`;

const CircularProgress = styled(({ color, ...otherProps }) => (
  <CircularProgressBase {...otherProps} classes={{ root: "root" }} />
))`
  &&.root {
    color: ${({ color, theme }) => color || theme.colors.primary};
  }
`;

export { Loader, CircularProgress };
