import { CALL_API } from "middleware/api";
import { GetFormVersionActionType, FormVersionResponse } from "./types";

// Get all version history
export const getFormVersion = (id: number | string) => (
  dispatch,
  getState,
) => {
  const { authToken } = getState().system;

  const endpoint = `form-version/${id}`;

  return dispatch({
    [CALL_API]: {
      endpoint,
      options: {
        method: "GET",
        headers: {
          Authorization: `Bearer ${authToken}`,
          Accept: "application/json",
        },
      },
      types: [
        GetFormVersionActionType.request,
        GetFormVersionActionType.success,
        GetFormVersionActionType.failure
      ],
    },
  })
    .then((res: FormVersionResponse) => res)
    .catch((err) => {
      throw new Error(err);
    });
};
