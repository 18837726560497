import { AllFormTypeStatsActionTypes, AllFormTypeStatsState, GET_ALL_FORM_TYPE_STATS } from "./types";

const initialState: AllFormTypeStatsState = {
  loading: {},
  errors: {},
  content: [],
};

export function allFormTypeStatsReducer(
  state = initialState,
  action: AllFormTypeStatsActionTypes,
): AllFormTypeStatsState {
  const { response, type } = action;
  switch (type) {
    case GET_ALL_FORM_TYPE_STATS.REQUEST:
      return {
        ...state,
        errors: { GET: false },
        loading: { GET: true },
        content: [],
      };

    case GET_ALL_FORM_TYPE_STATS.SUCCESS:
      return {
        ...state,
        content: response,
        loading: { GET: false },
      };

    case GET_ALL_FORM_TYPE_STATS.FAILURE:
      return {
        ...state,
        errors: { GET: true },
        loading: { GET: false },
        content: [],
      };

    default:
      return state;
  }
}
