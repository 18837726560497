import React from "react";
import { SvgIcon, SvgIconProps } from "@material-ui/core";

interface ClassNames {
  background?: string;
  fill?: string;
}

export interface CheckBoxTwoToneBlankProps extends SvgIconProps {
  classNames?: ClassNames;
}

export const CheckBoxTwoToneBlank = ({
  classNames = {
    background: "background",
    fill: "fill",
  },
  ...otherProps
}: CheckBoxTwoToneBlankProps) => {
  return (
    <SvgIcon {...otherProps}>
      <path className={classNames.background} d="M 5,19 H 19 V 5 H 5 Z" />
      <path
        className={classNames.fill}
        d="M19 5v14H5V5h14m0-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2z"
      />
    </SvgIcon>
  );
};
