import React from "react";
import { css } from "aphrodite/no-important";
import Tabs from "@material-ui/core/Tabs";

import styles from "./styles";
import baseStyles from "../../styles";
import useValidation from "../../create/validation/useValidation";
import ValidationStatusIndicator from "../../create/validation/ValidationStatusIndicator";
import FormBuilderTab from "./FormBuilderTab";

export type FBTab = "create" | "settings";
export const FB_TABS: FBTab[] = ["create", "settings" /*, "preview"*/];

interface Props {
  currentTab: number;
  onChangeTab: (tabIndex: number) => void;
}

const FormBuilderTabs = ({ currentTab, onChangeTab }: Props) => {
  const s = styles();
  const bs = baseStyles();

  const { errorTypes } = useValidation();

  return (
    <Tabs
      className={css(s.tabBarWrapper)}
      centered
      indicatorColor="primary"
      classes={{
        indicator: css(s.indicator)
      }}
      onChange={(_, value) => onChangeTab(value)}
      value={currentTab}
    >
      {FB_TABS.map((tab: FBTab, tabIndex) => (
        <FormBuilderTab
          key={`${tab}_${tabIndex}`}
          tabIndex={tabIndex}
          onChangeTab={onChangeTab}
          currentTab={currentTab}
        >
          <div className={css(bs.row)}>
            {errorTypes.includes(tab) && <ValidationStatusIndicator />}
            {tab}
          </div>
        </FormBuilderTab>
      ))}
    </Tabs>
  );
};

export default FormBuilderTabs;
