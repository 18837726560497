import React from "react";
import styled from "styled-components";

import { CommonStyles } from "components/common/styles";
import { remCalc } from "themes/helpers";
import { Select } from "components/common/form/Select";
import Button, { TextButton } from "components/common/Button";
import Icon, { IconProps } from "components/common/Icon";

export default {
  RowDiv: CommonStyles.RowDiv,
  ColumnDiv: CommonStyles.ColumnDiv,

  TitleWrapper: styled(CommonStyles.RowDiv)`
    width: 100%;
    justify-content: space-between;
    align-items: center;
  `,

  ModalHeader: styled(CommonStyles.BlockSpan)`
    font-size: ${remCalc(20)};
    font-weight: 500;
    letter-spacing: ${remCalc(0.26)};
    line-height: ${remCalc(26)};
    margin-bottom: ${remCalc(8)};
  `,

  ModalText: styled(CommonStyles.BlockSpan)`
    color: ${({ theme }) => theme.masterColors.darkGrey};
    letter-spacing: ${remCalc(0.28)};
    line-height: ${remCalc(21)};
    margin-bottom: ${remCalc(24)};
  `,

  PointerDiv: styled.div`
    cursor: pointer;
  `,

  FilterWrapper: styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0;
    margin-bottom: ${remCalc(26)};
  `,

  Select: styled(Select)`
    width: 100%;
    height: ${remCalc(56)};
  `,

  ButtonGroup: styled(CommonStyles.RowDiv)`
    width: 100%;
    justify-content: right;
  `,

  CancelButton: styled(TextButton)`
    height: ${remCalc(36)};
    margin: ${remCalc(34)} 0 0;
  `,

  SubmitButton: styled(Button)`
    width: ${remCalc(140)};
    height: ${remCalc(36)};
    padding: ${remCalc(8)} ${remCalc(14)};
    margin: 2.125rem 0;
  `,

  SelectWrapper: styled.div`
    margin-left: 12px;
    margin-right: 12px;
    :first-of-type: {
      margin-left: 0px;
    }
    :last-of-type: {
      margin-right: 0px;
    }
  `,

  FormLink: styled(TextButton)`
    text-align: left;
  `,
};
