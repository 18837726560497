import styled from "styled-components";

import { toRgba } from "themes/helpers";

export interface BottomNavigationSpacerProps {
  right?: boolean;
}

export const BottomNavigationSpacer = styled.div<BottomNavigationSpacerProps>`
  flex: 1;
  height: 100%;
  background: ${({ theme }) => theme.colors.white};
  z-index: ${({ right }) => (right ? "1000001" : "1000001")};
  border-top: 1px solid ${({ theme }) => toRgba(theme.colors.black, 0.2)};
`;

BottomNavigationSpacer.defaultProps = {
  right: false,
};

export const BottomNavigation = styled.div`
  bottom: 0px;
  position: fixed;
  width: 100%;
  box-shadow: 0px 1px 3px ${({ theme }) => toRgba(theme.colors.black, 0.2)};
  left: 0px;
`;
