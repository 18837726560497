import { GET_ALL_FORM_STATS, AllFormStatsState, AllFormStatsActionTypes } from "./types";

const initialState: AllFormStatsState = {
  loading: {},
  errors: {},
  content: [],
  empty: false,
  first: true,
  last: true,
  number: 0,
  numberOfElements: 0,
  pageable: {
    offset: 0,
    pageNumber: 0,
    pageSize: 20,
    paged: true,
    sort: { empty: true, sorted: false, unsorted: true },
    unpaged: false,
  },
  size: 10,
  sort: { empty: true, sorted: false, unsorted: true },
  totalElements: 6,
  totalPages: 1,
};

export function allFormStatsReducer(state = initialState, action: AllFormStatsActionTypes): AllFormStatsState {
  const { response, type } = action;
  switch (type) {
    case GET_ALL_FORM_STATS.REQUEST:
      return {
        ...state,
        errors: { GET: false },
        loading: { GET: true },
      };

    case GET_ALL_FORM_STATS.SUCCESS:
      return {
        ...state,
        ...response,
        loading: { GET: false },
      };

    case GET_ALL_FORM_STATS.FAILURE:
      return {
        ...state,
        errors: { GET: true },
        loading: { GET: false },
        content: [],
      };

    default:
      return state;
  }
}
