import React, { useContext } from "react";
import { Action } from "redux";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { History } from "history";
import * as yup from "yup";

import Modal from "components/common/Modal";
import { AppState } from "store";
import { FormTypeDTO } from "store/forms/types";
import { NewFBForm } from "store/builder/types";
import { putBuilderForm } from "store/builder/actions";
import TextInput from "components/common/form/TextInput";

import S from "./styles";
import { ThemeContext } from "styled-components";
import Icon from "components/common/Icon";

const DeleteIcon = () => {
  const theme = useContext(ThemeContext);
  return (
    <Icon type="delete_sign" size="17px" color={theme.masterColors.darkGrey} />
  );
};

interface Option {
  value: string;
  id: number;
}

interface Values {
  formName: string;
  formType: number;
}

const formatRequestBody = (
  values: Values,
  formType: FormTypeDTO
): NewFBForm => ({
  id: null,
  name: values.formName,
  sections: [],
  type: formType,
  allowedDocumentEditor: "DOCUMENT_CREATOR",
  workflowType: "DRAFT",
  sharingEnabled: true,
  startFromWorkOrderEnabled: true,
  isRehuddleEligible: true,
  operationalExperiences: [],
});

interface Props {
  modalOpen: boolean;
  closeModal: () => void;
  formTypes: FormTypeDTO[];
  formTypeOptions: Option[];
  history: History;
}

const validation = yup.object().shape<Values>({
  formName: yup.string().required("Please enter a form name"),
  formType: yup.number().required("Please select a form type"),
});

export const FormListModal = ({
  modalOpen,
  closeModal,
  formTypes,
  formTypeOptions,
  history,
}: Props) => {
  const dispatch = useDispatch<ThunkDispatch<AppState, void, Action>>();

  const formik = useFormik<Values>({
    initialValues: {
      formName: "",
      formType: -1,
    },
    onSubmit: (values: Values) => {
      try {
        const chosenFormType = formTypes.find(
          (formType) => formType.id === values.formType
        );
        chosenFormType &&
          dispatch(putBuilderForm(formatRequestBody(values, chosenFormType)))
            .then((res) => {
              if (res?.response?.id) {
                history.push(`forms/form/${res.response.id}`);
              }
            })
            .catch((err) => {
              throw new Error(err);
            });
      } catch (err) {
        throw new Error(err);
      }
    },
    validationSchema: validation,
  });

  return (
    <Modal
      border="none"
      height="419px"
      width="456px"
      padding="24px"
      open={modalOpen}
      handleClose={closeModal}
      content={
        <div>
          <S.RowDiv>
            <S.ColumnDiv>
              <S.ModalHeader>Create a New Form</S.ModalHeader>
              <S.ModalText>
                To begin, add a form name and select the form type.
              </S.ModalText>
            </S.ColumnDiv>
            <S.PointerDiv onClick={closeModal}>
              <DeleteIcon />
            </S.PointerDiv>
          </S.RowDiv>
          <form onSubmit={formik.handleSubmit}>
            <TextInput
              name="formName"
              label="Form Name"
              placeholder="Form Name"
              helperText={!formik.errors.formName ? "Assistive text" : ""}
              onChange={formik.handleChange}
              touched={formik.touched.formName}
              error={formik.errors.formName}
            />
            <S.Select
              name="formType"
              label="Form Type"
              value={formik.values.formType}
              options={formTypeOptions}
              onChange={formik.handleChange}
              touched={formik.touched.formType}
              error={formik.errors.formType}
            />
            <S.ButtonGroup>
              <S.CancelButton onClick={closeModal} variant="cancel">
                CANCEL
              </S.CancelButton>
              <S.SubmitButton type="submit" loading={formik.isSubmitting}>
                {formik.isSubmitting ? "" : "CREATE FORM"}
              </S.SubmitButton>
            </S.ButtonGroup>
          </form>
        </div>
      }
    />
  );
};
