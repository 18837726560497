import React, { ReactNode } from "react";
import { connect } from "react-redux";
import { withRouter, Redirect } from "react-router";
import { css } from "aphrodite/no-important";
import { get } from "lodash";

import * as S from "./styles";
import * as f from "./fields";
import { Button } from "components/common/Button/futureUiKit";
import Form from "../forms/Form";
import SplashScreen from "./SplashScreen";
import { AppState } from "../../store";
import { Error } from "components/forms/Form/styles";
import { LoginValues } from "./types";
import { SystemState } from "../../store/system/types";
import { afterLogin, oauthLogin } from "store/system/actions";
import { getAppConfig } from "store/appConfigs/actions";
import { getSplashStatus } from "./helpers";
import { login, clearError, clearStatus } from "../../store/system/actions";
import { oauthRedirect, extractURLParam } from "./adfs/helpers";
import { userIdSelector } from "store/user/selectors";
import { Link, TextLink } from "components/common/Link/futureUiKit";

interface Props {
  adfsEnabled?: boolean;
  afterLogin: (authToken: string) => void;
  authenticated: boolean;
  clearError: () => void;
  clearStatus: () => void;
  getAppConfig: (config: string) => void;
  history: any;
  loadingFromUserReducer: boolean;
  location: any;
  login: (email: string, password: string, recaptchaKey: string | null) => void;
  logo2x2: string | null;
  match: any;
  oauthLogin: (authToken: string) => void;
  system: SystemState;
}

interface State {
  recaptchaResponse: string | null;
  showPassword: boolean;
  // ADFS
  errorToast: boolean;
  forcedSplash: boolean;
}

/**
 * Utilize a <table> to center the login form
 * @param props
 * @returns JSX
 */
const CenteredLogin = (props: { children: ReactNode }) => (
  <S.Table>
    <S.TableCell>
      <S.TableContent>{props.children}</S.TableContent>
    </S.TableCell>
  </S.Table>
);

/** Login class component */
class Login extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      recaptchaResponse: null,
      showPassword: false,
      // ADFS
      errorToast: false,
      forcedSplash: true,
    };
  }

  componentDidMount(): void {
    const { afterLogin, location, oauthLogin } = this.props;
    this.props.clearError();
    this.props.clearStatus();
    this.props.getAppConfig("clientConfig"); // Get ADFS login flag

    const authToken = extractURLParam(location, "id_token", "#");
    if (authToken) {
      afterLogin(authToken);
      oauthLogin(authToken);
    } else if (!!location.state?.redirect && location.state.redirect !== "/") {
      oauthRedirect("azure", location.state.redirect);
    }

    setTimeout(() => this.setState({ forcedSplash: false }), 2000); // Hide splash screen after some time
  }

  /** Determine logo based on if and how the user is authenticated */
  determineLogo = () => {
    // @TODO implementation of ADFS/SSO
    // @TODO implementation of whitelabeled logo
    // @NOTE: Disabled for now, should we move this logic to a separate Login component?
    const logo2x2Fallback = "/assets/logo/Dominion-EnergyLogo@2x.png";
    return (
      <img
        src={this.props.logo2x2 || logo2x2Fallback}
        height="68"
        alt="Dominion Energy"
      />
    );
    // return <PowerFieldsLogo />;
  };

  /**
   * Store the generated ReCAPTCHA token in state
   * @param response - ReCAPTCHA response token
   */
  verifyRecaptcha = (response) => {
    this.setState({ recaptchaResponse: response });
  };

  /**
   * Navigate to a specific endpoint
   * @param endpoint
   */
  navigate = (endpoint: string): void => {
    this.props.history.push(endpoint);
  };

  render() {
    const {
      adfsEnabled,
      authenticated,
      history,
      loadingFromUserReducer,
      location,
      system,
    } = this.props;

    const loading = system.isLoading || loadingFromUserReducer;

    // Get overrideSSO flag from URL
    const overrideSSO = extractURLParam(location, "overrideSSO");
    // Get token from URL
    const authToken = extractURLParam(location, "id_token", "#");
    // Get (ADFS) error from URL
    const adfsError = extractURLParam(location, "error");

    // redirectAfterLogin is provided after being authenticated
    // username/password flow:
    //  1. user tries to go to ff.com/document/123
    //  2. user is directed to /login with location.state?.redirect=/document/123
    //  3. user logs in and this component reloads
    //  4. the redirect is activated below
    // adfs flow:
    //  1. user tries to go to ff.com/document/123
    //  2. user is directed to /login with location.state?.redirect=/document/123
    //  3. user clicks login through adfs
    //  4. user is directed to backend with query redirect_uri=ff.com/login?redirectAfterLogin=/document/123
    //      note redirect_uri and redirectAfterLogin within in are url encoded
    //  5. user logs in through adfs and are directed to above redirect_uri but with token query param
    //  6. the login page picks up token and redirectAfterLogin
    const redirectAfterLogin =
      extractURLParam(location, "redirectAfterLogin") ||
      location.state?.redirect ||
      "/";

    // Render splash
    if (this.state.forcedSplash) {
      return <SplashScreen status={getSplashStatus(authToken)} />;
    }

    // If adfs token is in URL, store in store/user and get users/me info
    // useEffect(() => {
    //   if (authToken) {
    //     afterLogin(authToken);
    //     // oauthLogin(authToken);
    //   };
    // }, [authToken]);

    // If on `/login` and you login successfully, move to `/` or specified route
    if (authenticated) {
      const safeRedirect = redirectAfterLogin?.startsWith("/login")
        ? "/"
        : redirectAfterLogin;
      return <Redirect to={safeRedirect} />;
    }

    return (
      <CenteredLogin>
        <Link className={css(S.styles.logo)} to="/login">
          {this.determineLogo()}
        </Link>

        <S.Version>VERSION {process.env.REACT_APP_VERSION || "0.1"}</S.Version>
        <S.Welcome>Welcome</S.Welcome>

        {/* disabled for uat testing -JA 5.7.2020 */}

        {/* Different forms if ADFS is enabled */}
        {/*{adfsEnabled && !overrideSSO ? (*/}
        {/*  <S.ADFSLoginButtonWrapper>*/}
        {/*    /!* ADFS Error *!/*/}
        {/*    {adfsError && <Error>*/}
        {/*      {adfsError}*/}
        {/*    </Error>*/}
        {/*    }*/}
        {/*    <Button onClick={oauthRedirect("azure")}>SIGN IN</Button>*/}
        {/*  </S.ADFSLoginButtonWrapper>*/}
        {/*) : (*/}
        {/*  <>*/}
        {/*    {resetPasswordBubbleIsShowing && (*/}
        {/*      <S.ResetPasswordBubble>*/}
        {/*        <S.ResetPasswordBubbleIcon />*/}
        {/*    For security, continued failed attempts will result in your account being locked.*/}
        {/*        <b />*/}
        {/*        <S.ResetPasswordBubbleActionLink onClick={() => this.navigate("/help")}>*/}
        {/*      Reset your password.*/}
        {/*        </S.ResetPasswordBubbleActionLink>*/}
        {/*      </S.ResetPasswordBubble>*/}
        {/*    )}*/}
        {/*    <Form*/}
        {/*      fields={f.loginFields(this.verifyRecaptcha, {*/}
        {/*        showPassword: this.state.showPassword,*/}
        {/*        toggleShowPassword: this.toggleShowPassword,*/}
        {/*      })}*/}
        {/*      accountLocked={system.accountLocked}*/}
        {/*      error={system.error}*/}
        {/*      externalLoading={loading}*/}
        {/*      formName="login"*/}
        {/*      handleSubmit={this.handleSubmit}*/}
        {/*      navigate={this.navigate}*/}
        {/*      recaptchaNeeded={false} // Temp disable recaptcha for dominion*/}
        {/*      resetPassword={system.resetPassword}*/}
        {/*      validationSchema={f.loginSchema}*/}
        {/*    />*/}

        {/*    <S.HelpLink onClick={() => this.navigate("/help")}>Need help logging in?</S.HelpLink>*/}
        {/*  </>*/}
        {/*)}*/}

        {/*
          start temporary uat code
          both login options will always render, with the ADFS login button beneath the traditional login button
          requested by Shantanu for Dominion SSO testing
        */}
        <S.AdditionalButtonWrapper>
          {/* ADFS Error */}
          {adfsError && <Error>{adfsError}</Error>}
          <Button onClick={() => oauthRedirect("azure", redirectAfterLogin)}>
            SIGN IN
          </Button>
        </S.AdditionalButtonWrapper>

        {/* end temporary uat code */}
      </CenteredLogin>
    );
  }
}

const mapStateToProps = (state: AppState) => {
  const authenticated = !!(
    state.system.authToken &&
    state.system.loggedIn &&
    userIdSelector(state.user)
  );
  const logo2x2 = get(
    state,
    [
      "appConfigs",
      "data",
      "logoConfigs",
      "properties",
      "logo2x2",
      "readableUrl",
    ],
    null
  );

  return {
    adfsEnabled: state.appConfigs.data.clientConfig.properties.adfsEnabled, // ADFS
    authenticated,
    loadingFromUserReducer: state.user.loading?.getUser,
    system: state.system,
    logo2x2,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    afterLogin,
    getAppConfig,
    login,
    clearError,
    clearStatus,
    oauthLogin,
  })(Login)
);
