import React, { ChangeEvent, useState } from "react";
import { css } from "aphrodite/no-important";

import Label from "components/common/form/Label";
import {
  RadioButton,
  RadioButtonProps,
  RatingRadioButton,
} from "components/common/form/RadioButton/futureUiKit";
import {
  CreateStyleSheet,
  useThemedStyleSheet,
} from "util/hooks/useThemedStyleSheet";

import { defaultStyle, RadioGroupStyle, ratingGroupStyle } from "./styles";
import { useId } from "util/hooks/useId";
import { HelperText } from "../../TextInput/styles";

export interface RadioButtonOption {
  disabled?: boolean;
  label: string;
  value: string;
}

type BaseRadioButtonsGroupProps = {
  RadioButtonComponent?: React.ComponentType<RadioButtonProps>;
  className?: string;
  name: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  options: RadioButtonOption[];
  required?: boolean;
  row?: boolean;
  style?: CreateStyleSheet<RadioGroupStyle>;
  value: string;
  helperText?: string;
};

interface LabelProps {
  label: string;
  labelId?: string;
}

interface LabelIdProps {
  label?: string | null;
  labelId: string;
}

export type RadioButtonsGroupProps = BaseRadioButtonsGroupProps &
  (LabelProps | LabelIdProps);

export const RadioButtonsGroup = ({
  className,
  label,
  labelId,
  name,
  onChange,
  options,
  RadioButtonComponent = RadioButton,
  row,
  style = defaultStyle,
  value,
  required,
  helperText,
}: RadioButtonsGroupProps) => {
  const [selection, setSelection] = useState<string>(value);

  const styles = useThemedStyleSheet(style);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSelection(event.target.value);
    if (onChange) {
      onChange(event);
    }
  };

  const radioGroupId = useId(name);

  return (
    <div className={className}>
      {label && (
        <Label htmlFor={radioGroupId} required={required}>
          {label}
        </Label>
      )}
      {helperText && (
        <div style={{ paddingBottom: 10, marginLeft: -15 }}>
          <HelperText>{helperText}</HelperText>
        </div>
      )}
      <div
        aria-labelledby={labelId}
        className={css(styles.radioGroup, row ? styles.row : styles.column)}
        id={label ? radioGroupId : undefined}
        role="radiogroup"
      >
        {options?.map((option) => (
          <RadioButtonComponent
            checked={selection === option.value}
            className={css(row ? styles.rowItem : styles.columnItem)}
            disabled={option.disabled}
            handleChange={handleChange}
            key={option.value}
            label={option.label}
            name={name}
            value={option.value}
          />
        ))}
      </div>
    </div>
  );
};

export const Rating = (props: RadioButtonsGroupProps) => {
  return (
    <RadioButtonsGroup
      RadioButtonComponent={RatingRadioButton}
      row={true}
      style={ratingGroupStyle}
      {...props}
    />
  );
};
