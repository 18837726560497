import {
  FiltersActions,
  ResourceCategoriesFiltersArgs,
  RESOURCE_CATEGORIES_FILTERS,
} from "./types";

const initialFilters = {
  query: "",
};

const initialPaginationParams = {
  page: 0,
  size: 10,
  sort: "title,asc",
};

const initialState: ResourceCategoriesFiltersArgs = {
  filterParams: initialFilters,
  paginationParams: initialPaginationParams,
};

export function resourceCategoriesReducer(
  state = initialState,
  action: FiltersActions
): ResourceCategoriesFiltersArgs {
  const { response, type } = action;

  switch (type) {
    case RESOURCE_CATEGORIES_FILTERS.SET:
      return { ...state, filterParams: response };

    case RESOURCE_CATEGORIES_FILTERS.PARAMS_SET:
      return { ...state, paginationParams: response };

    default:
      return state;
  }
}
