import Divider from "@material-ui/core/Divider";
import React, { SetStateAction } from "react";
import styled from "styled-components";

import AppNavigation from "components/navigation/AppNavigation";
import { PrivacyTerms } from "components/navigation/SidebarNavigation";

// This drawer still uses padding which we then remove
const Wrapper = styled.div`
  margin: -10px;
`;

const Header = styled.div`
  font-weight: 600;
  margin-left: 15px;
  position: relative;
  text-align: left;
  top: 20px;
`;

const Footer = styled.div`
  align-items: center;
  border-top: 1px solid #ebebeb;
  bottom: 0;
  display: flex;
  justify-content: center;
  padding: 10px;
  position: absolute;
  width: 100%;
`;

export interface NavigationExpansionDrawerContentProps {
  togglePrivacySidebarVisibility: (value: SetStateAction<boolean>) => void;
}

export const NavigationExpansionDrawerContent = ({
  togglePrivacySidebarVisibility,
}: NavigationExpansionDrawerContentProps) => {
  return (
    <Wrapper>
      {/* Header */}
      <Header>Menu</Header>
      <AppNavigation inMobileExpansion />
      <Footer>
        <Divider />
        <PrivacyTerms onClick={() => togglePrivacySidebarVisibility(true)}>
          Privacy
        </PrivacyTerms>
      </Footer>
    </Wrapper>
  );
};
