// import React from "react";
import { bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";

import { AppState } from "../../../store";
import { deleteDocument } from "../../../store/documents/actions";
import { DocumentSummary } from "store/documents/types";
import { documentStatusSelector } from "store/documents/selectors";

interface DeleteDocumentButtonProps {
  document: DocumentSummary;
  selectors: {
    documentStatusSelector: {
      deleting: boolean;
      error: boolean;
    };
  };
  deleteDocument: (id) => void;
  children: (deleting: boolean, error: boolean, handleDelete: () => void) => JSX.Element;
}

const DeleteDocumentButton = ({
  // document,
  // deleteDocument,
  children,
  selectors: {
    documentStatusSelector: { deleting, error },
  },
}: DeleteDocumentButtonProps) => {
  const handleDelete = () => {
    // TODO Real confirmation popup?
    // if (window.confirm(`Are you sure you want to delete ${document.title} (${document.id})?`)) {
    //   deleteDocument(document.id);
    // }
  };
  return children(deleting, error, handleDelete);
};

const mapStateToProps = (state: AppState, props) => ({
  selectors: {
    documentStatusSelector: {
      deleting: documentStatusSelector({
        documentState: state.documents,
        documentId: props.documentId,
        statusType: "loading",
        checkForDeletionStatus: true,
      }),
      error: documentStatusSelector({
        documentState: state.documents,
        documentId: props.documentId,
        statusType: "error",
        checkForDeletionStatus: true,
      }),
    },
  },
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  deleteDocument: bindActionCreators(deleteDocument, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(DeleteDocumentButton);
