import React from "react";
import styled from "styled-components";
import InputLabelBase from "@material-ui/core/InputLabel";

import mediaQueries from "themes/mediaQueries";
import { remCalc } from "themes/helpers";

import { LabelVariantTypes } from "./Label";

const Label = styled.div<{
  assistiveLink?: boolean;
  variant: LabelVariantTypes;
}>`
  display: flex;
  align-items: baseline;
  justify-content: ${({ assistiveLink }) =>
    assistiveLink ? "space-between" : "flex-start"};

  margin-bottom: ${remCalc(5)};

  /* HANDLE VARIANTS */
  /* STACKED */
  ${({ variant }) => {
    if (variant === "stacked") {
      return `
        flex-direction: column;
        justify-content: flex-start;
      `;
    }
  }}
`;

const FieldLabel = styled(({ smallLabel, assistiveLink, ...otherProps }) => (
  <InputLabelBase {...otherProps} classes={{ root: "root" }} />
))`
  &&.root {
    color: ${({ theme }) => theme.colors.formFieldLabel};

    font-size: ${remCalc(20)};
    font-weight: 500;

    margin-bottom: 0;

    @media (${mediaQueries.maxTablet}) {
      font-size: ${remCalc(20)};
      max-width: ${({ assistiveLink }) => (assistiveLink ? "70%" : "100%")};
    }

    @media (${mediaQueries.maxPhone}) {
      font-size: ${remCalc(20)};
      max-width: ${({ assistiveLink }) => (assistiveLink ? "70%" : "100%")};
    }

    ${({ smallLabel, theme }) =>
      smallLabel &&
      `
      color: ${theme.masterColors.darkGrey};  
      margin-bottom: ${remCalc(5)};
      font-size: ${remCalc(13)};

      @media (${mediaQueries.minTablet}) {
        font-size: ${remCalc(13)};
      }
      `}
  }
`;

const AssistiveLink = styled.a<{
  endAdornment?: boolean;
  isMouseFocused?: boolean;
  labelVariant: LabelVariantTypes;
}>`
  color: ${({ theme }) => theme.colors.primary};
  font-size: ${remCalc(14)};
  text-decoration: underline;

  margin-right: ${remCalc(0)};

  ${({ isMouseFocused, theme }) => {
    if (!isMouseFocused) {
      return `
        &:focus {
          outline: 0;
          border-radius: ${remCalc(4)};
          box-shadow: 0 0 0 ${remCalc(4)} ${theme.colors.focusIndicatorOutline};
        }
      `;
    }
  }}

  /* HANDLE VARIANTS */
  ${({ labelVariant }) => {
    if (labelVariant === "stacked") {
      return `
        padding-left: 2px;
        margin-bottom: ${remCalc(5)};
      `;
    }
  }}

  @media (${mediaQueries.maxTablet}) {
    font-size: ${remCalc(21)};
    display: flex;
    align-items: center;
  }

  @media (${mediaQueries.maxPhone}) {
    font-size: ${remCalc(14)};
  }
`;

const AssistiveLinkButton = styled.button<{
  isMouseFocused?: boolean;
  isTablet?: boolean;
  labelVariant: LabelVariantTypes;
}>`
  color: ${({ theme }) => theme.colors.primary};
  font-size: ${remCalc(14)};
  text-decoration: underline;

  margin-right: ${remCalc(0)};

  border: none;
  background: none;

  ${({ isMouseFocused, theme }) => {
    if (!isMouseFocused) {
      return `
        &:focus {
          outline: 0;
          border-radius: ${remCalc(4)};
          box-shadow: 0 0 0 ${remCalc(4)} ${theme.colors.focusIndicatorOutline};
        }
      `;
    }
  }}

  /* HANDLE VARIANTS */
  ${({ labelVariant }) => {
    if (labelVariant === "stacked") {
      return `
        padding-left: 2px;
        margin-bottom: ${remCalc(5)};
      `;
    }
  }}

  ${({ isTablet, theme }) =>
    isTablet &&
    `
      background-color: ${theme.colors.activeFill};
      border-radius: 24px;
  `}

  @media (${mediaQueries.maxTablet}) {
    font-size: ${remCalc(21)};
    display: flex;
    align-items: center;
  }

  @media (${mediaQueries.maxPhone}) {
    font-size: ${remCalc(14)};
  }
`;

const AssistiveIcon = styled.i`
  color: ${({ theme }) => theme.colors.primary};
  text-decoration: none;
  display: none;

  @media (${mediaQueries.minTablet}) {
    display: inline-block;
    font-size: ${remCalc(34)};
  }
`;

export { Label, FieldLabel, AssistiveLink, AssistiveLinkButton, AssistiveIcon };
