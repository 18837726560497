import { useMemo, useCallback } from "react";

import { debounce, DebounceSettings } from "lodash";

interface Props {
  method: (...args: any) => void;
  delayAmount?: number;
  options?: DebounceSettings;
}

/**
 * Hook which helps to debounce and cancel the given method
 */
function useDebounce({ method, delayAmount = 500, options }: Props) {
  const methodToDebounce = useMemo(() => debounce(method, delayAmount, options), []);

  return useCallback(
    (...args: any) => {
      methodToDebounce.cancel();

      methodToDebounce(...args);
    },
    [methodToDebounce],
  );
}

export default useDebounce;
