import React, { useState, useCallback, FC } from "react";
import moment from "moment";
import { useHistory } from "react-router";
import { useSelector } from "react-redux";

import * as S from "./styles";
import ActionBar from "./ActionBar";
import Drawer from "components/common/Drawer";
import Header from "components/common/Drawer/components/Header";
import Loader from "components/common/Loader";
import { doExport } from "components/clientAdmin/helpers";
import { getAuthToken } from "store/user/selectors";
import { isAdmin } from "store/user/selectors";

interface QuestionExpansionPanelProps {
  comments: any;
  expanded: boolean;
  handleExpansionChange: (event: React.ChangeEvent<{}>) => void;
  id: number;
  title: string;
  onUserNameClick?: (id: number) => void;
}

interface QuestionExpansionPanelDetailsProps {
  name: string;
  date: string;
  comment: string;
  onUserNameClick?: () => void;
}

const QuestionExpansionPanel: FC<QuestionExpansionPanelProps> = ({
  comments,
  expanded,
  handleExpansionChange,
  id,
  title,
  onUserNameClick,
}) => (
  <S.ExpansionPanel
    disabled={!comments.length}
    expanded={expanded}
    elevation={0}
    onChange={handleExpansionChange}
  >
    <S.ExpansionPanelSummary
      expandIcon={<S.SortDown />}
      aria-controls={`panel-${id}-content`}
      id={`panel-${id}-header`}
    >
      <S.TitleText>{title}</S.TitleText>
      <S.DetailsText>
        <S.Message /> {comments.length} Comments
      </S.DetailsText>
    </S.ExpansionPanelSummary>
    <S.ExpansionPanelDetails>
      {comments.map(
        ({
          comments,
          firstName,
          lastName,
          lastModifiedDate,
          participantId,
        }) => (
          <QuestionExpansionPanelDetails
            comment={comments}
            date={lastModifiedDate}
            key={lastModifiedDate}
            name={`${firstName} ${lastName}`}
            onUserNameClick={() =>
              onUserNameClick && onUserNameClick(participantId)
            }
          />
        )
      )}
    </S.ExpansionPanelDetails>
  </S.ExpansionPanel>
);

const QuestionExpansionPanelDetails: FC<QuestionExpansionPanelDetailsProps> = ({
  name,
  date,
  comment,
  onUserNameClick,
}) => (
  <S.PanelDetailsContainer>
    <S.LinkText onClick={onUserNameClick}>{name}</S.LinkText>
    <S.DateText>{moment(date).format("MM/DD/YYYY")}</S.DateText>
    <S.CommentText>{comment}</S.CommentText>
  </S.PanelDetailsContainer>
);

type DrawerProps = {
  closeDrawer: () => void;
  handleExpansionChange: (id: number) => (event: React.ChangeEvent<{}>) => void;
  drawerRiskLevel: string;
  headerText: JSX.Element;
  responsesLoaded: boolean;
  responseComments: {
    comments: any;
    id: number;
    title: string;
  }[];
  selectedQuestionIds: number[];
  show: boolean;
};

const SafetyResponseCommentsDrawer = ({
  closeDrawer,
  drawerRiskLevel,
  handleExpansionChange,
  headerText,
  responseComments,
  responsesLoaded,
  selectedQuestionIds,
  show,
}: DrawerProps) => {
  // Get JWT
  const authToken = useSelector(getAuthToken);

  // Get history object
  const history = useHistory();

  // is admin?
  const userIsAdmin = useSelector(isAdmin);

  // On user name click
  const onUserNameClick = useCallback(
    (id) => {
      if (id && userIsAdmin) {
        history.push(`/people/users/view/${id}`);
      }
    },
    [isAdmin]
  );

  const [exportLoading, setExportLoading] = useState<boolean>(false);

  const handleExport = () => {
    const url = `reports/comments/selection?selectionQuestionIds=${selectedQuestionIds}`;
    const type = "text/csv";
    doExport(
      authToken,
      `Export ${drawerRiskLevel}.csv`,
      undefined,
      setExportLoading,
      type,
      url
    );
  };
  return (
    <Drawer
      showCloseIcon={false}
      content={
        <div style={{ width: "30vw" }}>
          <Loader loading={!responsesLoaded} />
          <Header
            className="d-flex justify-content-between px-4 pt-3 pb-3"
            closeDrawer={closeDrawer}
            text={headerText}
          />
          <ActionBar
            handleExport={handleExport}
            handlePrint={() => window.print()}
            handleShare={() => undefined}
            loading={{ export: exportLoading }}
          />
          {responseComments.map((r) => (
            <QuestionExpansionPanel
              comments={r.comments}
              expanded={selectedQuestionIds.includes(r.id)}
              handleExpansionChange={handleExpansionChange(r.id)}
              id={r.id}
              key={r.id}
              onUserNameClick={!userIsAdmin ? onUserNameClick : undefined}
              title={r.title}
            />
          ))}
        </div>
      }
      anchor="right"
      id="documentDrawer"
      onClose={closeDrawer}
      onOpen={() => undefined}
      open={show}
      overrideClasses={{ content: "commentDrawerStyles" }}
    />
  );
};

export default SafetyResponseCommentsDrawer;
