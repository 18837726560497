import * as React from "react";
import { ResponsivePie } from "@nivo/pie";
import { useCommonChartProps } from "../common";
import { ThemeContext } from "styled-components";

const PieChart = ({ data, ...chartProps }) => {
  const theme = React.useContext(ThemeContext);
  const commonChartProps = useCommonChartProps();
  return (
    <ResponsivePie
      data={data}
      innerRadius={0.5}
      margin={{ bottom: 80, right: 200 }}
      borderWidth={1}
      enableRadialLabels={false}
      slicesLabelsTextColor={theme.colors.white}
      legends={[
        {
          anchor: "right",
          direction: "column",
          translateX: 150,
          translateY: 0,
          itemWidth: 50,
          itemHeight: 22,
          symbolShape: "circle",
          effects: [
            {
              on: "hover",
              style: {
                itemTextColor: theme.colors.black,
              },
            },
          ],
        },
      ]}
      {...commonChartProps}
      {...chartProps}
    />
  );
};

export default PieChart;
