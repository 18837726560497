import styled from "styled-components";
import { remCalc } from "../../../../themes/helpers";

type CheckboxContainerProps = {
  outlined?: boolean;
};

export default {
  CheckboxContainer: styled.div<CheckboxContainerProps>`
    margin-bottom: ${remCalc(12)};
    border: ${({ outlined }) => outlined && "1px solid"}
    border-radius: 0.25rem;
    padding: ${({ outlined }) => outlined ? `${remCalc(6)} ${remCalc(8)} 0` : 0};
    background: ${({ outlined, theme }) => outlined && theme.colors.background}
  `,
};
