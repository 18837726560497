import React, { useMemo } from "react";
import { css } from "aphrodite/no-important";

import styles from "../../styles";
import { TextButton } from "components/common/Button";
import { FBForm, FBItem } from "../../types";
import { DisplayConditionDTO } from "store/documents/types";
import { getFormItemByRootId, scrollToElement } from "../../helpers";
import { useFormikContext } from "formik";

interface Props {
  sourceDisplayConditions: DisplayConditionDTO[];
  onSelectItem: (item: FBItem) => void;
}

const DisplayConditionsBanner = ({ sourceDisplayConditions, onSelectItem }: Props) => {
  const { values } = useFormikContext<FBForm>();
  const s = styles();

  /**
   * Select and scroll to the source item of a display condition
   * @param item Source item for the display condition
   */
  function onJumpToDisplayConditionItem(item: FBItem) {
    // select the item
    onSelectItem(item);
    // scroll to the item
    scrollToElement(item.id.toString());
  }

  const sourceQuestions = useMemo(() => 
    sourceDisplayConditions.map((cond) => {
      const item = getFormItemByRootId(values, cond.sourceQuestionRootId);
      if (item?.id) {
        return (
          <TextButton
            key={item.id}
            className={css([s.alertButton, s.lowercase])}
            variant="link"
            onClick={() => onJumpToDisplayConditionItem(item)}
          >
            {item.title || `Untitled ${item.subType.toLowerCase()} question`}
          </TextButton>
        );
      }
      return null;
    }), 
  [sourceDisplayConditions]
  );

  return (
    <div className={css(s.alert)}>
      <p className={css(s.alertTitle)}>CONDITIONAL LOGIC</p>
      <p className={css(s.alertBody)}>
        The display of this question is affected by conditional logic rules on these questions:
      </p>

      {sourceQuestions}
    </div>
  );
};

export default DisplayConditionsBanner;
