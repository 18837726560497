import React from "react";
import { AppState } from "store";
import { bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";
import { RouteChildrenProps } from "react-router";
import * as R from "ramda";
import MediaQuery from "react-responsive";

import { AppConfigsState } from "store/appConfigs/types";
import { devices } from "themes/mediaQueries";
import { FormTypeDTO } from "store/forms/types";
import { NewDocumentState, ClearStatusActionTypes, ClearStatusPayloadTypes } from "store/newDocument/types";
import { SystemState } from "store/system/types";
import * as formsActions from "store/forms/actions";
import * as newDocumentActions from "store/newDocument/actions";
import * as participantsActions from "store/participants/actions";
import * as systemActions from "store/system/actions";
import * as userActions from "store/user/actions";
import DashboardDrawer from "components/dashboard/DashboardDrawer";

import * as S from "./styles";

export interface ComingSoonProps extends RouteChildrenProps {
  store: {
    appConfigs: AppConfigsState;
    formTypes?: Array<FormTypeDTO>;
    newDocument: NewDocumentState;
    system: SystemState;
    workLocationIDFromUserReducer: number | null;
  };

  actions: {
    system: {
      logout: () => void;
    };
    forms: {
      getAllForms: () => void;
    };
    newDocument: {
      clearStatus: ({
        actionType,
        payload,
      }: {
        actionType: ClearStatusActionTypes;
        payload: ClearStatusPayloadTypes;
      }) => void;
      continueDocument: (documentId: number) => void;
      resetCurrentDocument: () => void;
      fetchSummarizedForms: () => void;
    };
    participants: {
      getParticipants: (workLocationIDFromUserReducer: number) => void;
    };
    user: {
      getUser: () => void;
    };
  };
}

export interface ComingSoonState {
  /**
   * when clicking on a Document, this value will be populated
   * with that Document's data
   */
  query: string;
  // searchFocus: boolean;
  showContextMenu: boolean;
  /**
   * when `true` renders the "Start New Document" feature
   * which can either be rendered within the bottom drawer
   * or in a modal anchored to the button opening it
   */
  dashboardDrawerVisible: boolean;

  // NEW

  // formType: boolean;

  contextMenuDocument: {
    title: string;
    id: string | number;
    submissionDate: string;
  };

  /**
   * used to track window height in order
   * to update anchor for Start New Document modal/drawer
   */
  windowWidth: number;
}

class ComingSoon extends React.Component<ComingSoonProps, ComingSoonState> {
  constructor(props: ComingSoonProps) {
    super(props);
    this.state = {
      dashboardDrawerVisible: false,
      query: "",
      showContextMenu: false,
      // new
      // formType: false,
      contextMenuDocument: {
        title: "",
        id: "",
        submissionDate: "",
      },
      windowWidth: window.innerWidth,
    };
  }
  componentDidMount() {
    const {
      actions,
      store: { workLocationIDFromUserReducer },
    } = this.props;

    // get participants based on `workOrderLocationId`
    workLocationIDFromUserReducer && actions.participants.getParticipants(workLocationIDFromUserReducer);

    actions.forms.getAllForms();

    // returns `newDocument` to initial state
    // @NOTE: be sure to call before `fetchSummarizedForms`
    // @NOTE: should we change this process so those values are not reset and
    // we don't always re-call `fetchSummarizedForms`?
    actions.newDocument.resetCurrentDocument();

    // dispatch API call to get data for Start New Document
    actions.newDocument.fetchSummarizedForms();

    /// remove if already exists, add event listener to get
    // current window width for setting anchor for Start New Form
    window.removeEventListener("resize", this.handleListenToWindowWidth);
    window.addEventListener("resize", this.handleListenToWindowWidth);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleListenToWindowWidth);
  }

  handleListenToWindowWidth = () => {
    const { windowWidth } = this.state;

    if (windowWidth !== window.innerWidth) {
      return this.setState({
        windowWidth: window.innerWidth,
      });
    }
  };

  toggleDashboardDrawerVisibility = () => {
    return this.setState((prevState: any) => ({
      dashboardDrawerVisible: !prevState.dashboardDrawerVisible,
    }));
  };

  // refs used to set an anchor point for
  // the StartNewDoc modal/drawer element
  startNewDocMobileAnchor = React.createRef<HTMLAnchorElement>();
  startNewDocTabletAnchor = React.createRef<HTMLAnchorElement>();
  startNewDocDesktopAnchor = React.createRef<HTMLAnchorElement>();

  render() {
    return (
      <>
        {/* START NEW FORM */}

        <DashboardDrawer />

        {/* Used for desktop views */}

        <MediaQuery minWidth={devices.minDesktop}>
          <div className="d-flex flex-row">
            <div className="col overflow-hidden">
              <S.ContentWrapper>
                <S.ComingSoonText>Coming Soon</S.ComingSoonText>
              </S.ContentWrapper>
            </div>
          </div>
        </MediaQuery>

        {/* Mobile and Tablet View will render the components below */}

        <MediaQuery maxWidth={devices.maxTablet}>
          <S.ContentWrapper>
            <S.ComingSoonText>Coming Soon</S.ComingSoonText>
            <S.LogoutText onClick={() => this.props.actions.system.logout()}>Log out</S.LogoutText>
          </S.ContentWrapper>
        </MediaQuery>
      </>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  store: {
    appConfigs: state.appConfigs,
    formTypes: state.forms.data.formTypes || [],
    newDocument: state.newDocument,
    ownerIds: (state.documents.data && state.documents.data.ownerIds) || [],
    system: state.system,
    workLocationIDFromUserReducer: R.pathOr(null, ["participant", "workLocation", "id"], state.user.data),
  },
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  actions: {
    forms: bindActionCreators(formsActions, dispatch),
    system: bindActionCreators(systemActions, dispatch),
    newDocument: bindActionCreators(newDocumentActions, dispatch),
    participants: bindActionCreators(participantsActions, dispatch),
    user: bindActionCreators(userActions, dispatch),
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ComingSoon);
