import {
  FiltersActions,
  DocumentsFiltersArgs,
  DOCUMENTS_FILTERS,
} from "./types";

const initialFilters = {
  documentType: -1,
  timeFilterType: "ALL_TIME",
  dateRange: null,
  subordinatesFilter: "ALL_USERS",
  documentOwner: null,
  query: "",
};

const initialState: DocumentsFiltersArgs = {
  filterParams: initialFilters,
};

export function documentsFiltersReducer(
  state = initialState,
  action: FiltersActions
): DocumentsFiltersArgs {
  const { response, type } = action;

  switch (type) {
    case DOCUMENTS_FILTERS.SET:
      return { ...state, filterParams: response };

    default:
      return state;
  }
}
