import React from "react";
import { FieldInputProps } from "formik";

import TextInput from "components/common/form/TextInput";

const ResourceTitleField = (fieldProps: FieldInputProps<string>) => {
  return (
    <TextInput
      label="Title"
      placeholder="Resource title"
      {...fieldProps}
    />
  );
};

export default ResourceTitleField;
