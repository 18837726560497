import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { ThunkDispatch } from "redux-thunk";
import { Action } from "redux";
import withTheme from "@material-ui/core/styles/withTheme";

import {
  DataSourceVM,
  GetDataSourceTemplateResponse,
} from "store/dataSource/types";
import {
  getDataSourceCSV,
  getDataSourcesPage,
  putDataSourceStatus,
} from "store/dataSource/actions";
import { ContentWrapper } from "components/common/Wrappers";
import Breadcrumbs from "components/common/Breadcrumbs";
import SearchBar from "components/filters/BaseSearchBar";
import Button from "components/common/Button";
import { FilterParams, PaginationParams, SortParams } from "store/common/types";
import TableSummary from "components/common/TableUI/TableSummary";
import Loader from "components/common/Loader";
import { H1 } from "../styles";
import { downloadFile } from "../../../util";
import useDebounce from "../../../util/hooks/useDebounce";

import { DSTable } from "./DSTable";
import S from "./styles";
import { CreateModal, ConfirmModal } from "./DSModals";
import ReadOnlyContent from "components/common/permissions/ReadOnlyContent";
import { dataSetsFiltersSelector } from "store/filters/dataSets/selectors";
import {
  setDataSetsFilters,
  setDataSetsPagination,
} from "store/filters/dataSets/actions";

/** Data sets main view. Note that "data set" (business) is synonymous with "data source" (API) */
function DataSets() {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [dataSources, setDataSources] = React.useState<Array<DataSourceVM>>([]);
  const [totals, setTotals] = React.useState<{
    elements: number;
    pages: number;
  }>({ elements: 0, pages: 0 });
  const [createModalOpen, setCreateModalOpen] = React.useState<boolean>(false);
  const [confirmModalOpen, setConfirmModalOpen] = React.useState<boolean>(
    false
  );
  const [typeSelected, setTypeSelected] = React.useState<
    "basic" | "advanced" | null
  >(null);
  const [
    sourceSelected,
    setSourceSelected,
  ] = React.useState<DataSourceVM | null>(null);

  const filtersSelect = useSelector(dataSetsFiltersSelector);
  const dispatch = useDispatch<ThunkDispatch<null, void, Action>>();
  const { filterParams, paginationParams } = filtersSelect;
  const [query, setQuery] = React.useState<string>(filterParams.query);
  const history = useHistory();

  const getAndSetDataSources = async (params: FilterParams) => {
    setLoading(true);
    const res = await dispatch(getDataSourcesPage(params));
    if (res?.response?.totalPages && res?.response?.totalElements) {
      setTotals({
        pages: res.response.totalPages,
        elements: res.response.totalElements,
      });
    }
    if (res?.response?.content) {
      setDataSources(res.response.content);
    }
    setLoading(false);
  };

  const setPaginationPage = (page: number) => {
    dispatch(setDataSetsPagination({ ...paginationParams, page }));
  };
  const setPaginationSize = (size: number) => {
    dispatch(setDataSetsPagination({ ...paginationParams, size }));
  };
  const setPaginationSort = (sort: SortParams) => {
    dispatch(setDataSetsPagination({ ...paginationParams, sort }));
  };

  const sort = paginationParams.sort
    ? `${paginationParams.sort[0]},${paginationParams.sort[1]}`
    : "title,asc";

  /** get all data sources on mount */
  React.useEffect(() => {
    (async () => {
      await getAndSetDataSources({
        page: paginationParams.page,
        size: paginationParams.size,
        sort,
        query,
      });
    })();
  }, [paginationParams]);

  /** delayed search for user input */
  const debouncedGet = useDebounce({
    method: async (q) => {
      setQuery(q);
      dispatch(setDataSetsFilters({ query: q }));
      await getAndSetDataSources({
        page: paginationParams.page,
        size: paginationParams.size,
        sort,
        query: q,
      });
    },
    delayAmount: 1000,
  });

  const closeCreateModal = () => {
    setCreateModalOpen(false);
    setTypeSelected(null);
  };

  const closeConfirmModal = () => {
    setConfirmModalOpen(false);
  };

  const downloadCSV = (id: number, title: string) =>
    dispatch(getDataSourceCSV(id.toString()))
      .then((res: GetDataSourceTemplateResponse) => {
        if (res.response) {
          downloadFile(res.response, title + ".csv");
        }
      })
      .catch((err) => {
        throw new Error(err);
      });

  const updateSource = async (
    ds: DataSourceVM,
    status?: "DISABLED" | "DRAFT" | "PUBLISHED"
  ) => {
    status && (await dispatch(putDataSourceStatus(ds.id, status)));
    closeConfirmModal();
    await getAndSetDataSources({
      page: paginationParams.page,
      size: paginationParams.size,
      sort,
      query,
    });
  };

  return (
    <ContentWrapper id="mainContent">
      <CreateModal
        open={createModalOpen}
        closeModal={closeCreateModal}
        typeSelected={typeSelected}
        setTypeSelected={setTypeSelected}
        history={history}
      />
      <ConfirmModal
        open={confirmModalOpen}
        closeModal={closeConfirmModal}
        updateSourceStatus={updateSource}
        sourceSelected={sourceSelected}
      />
      <Breadcrumbs
        paths={[
          { href: "/forms", pathName: "Forms" },
          { pathName: "Data Sets" },
        ]}
      />
      <S.TitleWrapper>
        <H1>Data Sets</H1>
        <ReadOnlyContent>
          <Button onClick={() => setCreateModalOpen(true)}>ADD DATA SET</Button>
        </ReadOnlyContent>
      </S.TitleWrapper>
      <S.DivWithBottomMargin margin={26}>
        <SearchBar
          onSearch={(q) => debouncedGet(q)}
          initialQuery={filterParams.query}
        />
      </S.DivWithBottomMargin>
      <TableSummary
        pageSize={paginationParams.size}
        currentPage={paginationParams.page}
        totalElements={totals.elements}
        ofWhat="data sets"
      />
      <Loader loading={loading}>
        <DSTable
          dataSources={dataSources}
          sort={paginationParams.sort as SortParams}
          setSort={setPaginationSort}
          page={paginationParams.page || 0}
          setPage={setPaginationPage}
          totalPages={totals.pages}
          size={paginationParams.size || 10}
          setSize={setPaginationSize}
          downloadCSV={downloadCSV}
          setCreateModalOpen={setCreateModalOpen}
          setConfirmModalOpen={setConfirmModalOpen}
          setSourceSelected={setSourceSelected}
          history={history}
        />
      </Loader>
    </ContentWrapper>
  );
}

export default withTheme(DataSets);
