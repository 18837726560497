import { ParticipantsSummaryState, GET_PARTICIPANTS_SUMMARY, ParticipantsActions } from "./types";
import { CHANGE_ACTIVATION_STATUS } from "store/usersParticipants/types";

const locallyChangeActivationStatus = (id, data) => {
  const _data = data;
  _data.forEach((e, index) => {
    if (e.id === id) {
      // Not sure if easy to read, switch might be better
      if (_data[index]["status"] === "ACTIVE") {
        _data[index]["status"] = "DISABLED";
      } else if (_data[index]["status"] === "DISABLED") {
        _data[index]["status"] = "ACTIVE";
      }
    }
  });
  return _data;
};

const initialState: ParticipantsSummaryState = {
  loading: false,
  error: false,
  errorMessage: "",
  data: {
    content: [],
  },
};

export function participantsSummaryReducer(
  state = initialState,
  action: ParticipantsActions,
): ParticipantsSummaryState {
  switch (action.type) {
    case GET_PARTICIPANTS_SUMMARY.FAILURE:
      return {
        ...state,
        error: true,
        loading: false,
      };
    case GET_PARTICIPANTS_SUMMARY.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_PARTICIPANTS_SUMMARY.SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.response,
      };
    case CHANGE_ACTIVATION_STATUS.SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          content: locallyChangeActivationStatus(action.id, state.data.content),
        },
      };
    case "RESET_PARTICIPANTS_SUMMARY":
      return {
        ...state,
        loading: false,
        data: {},
      };
    default:
      return state;
  }
}
