import React from "react";

interface State {
  updateTouchedQuestions: (e?: any) => void;
  touchedQuestions: Set<number>;
}

/**
 * **TouchedQuestionsContext** is used to track "touched" or
 * manipulated questions in order to determine if we
 * need to render and error state
 */
const TouchedQuestionsContext = React.createContext<State>({
  updateTouchedQuestions: () => {},
  touchedQuestions: new Set(),
});

TouchedQuestionsContext.displayName = "TouchedQuestionsContext";

export { TouchedQuestionsContext as default };
