import React, { useEffect, useState } from "react";
import moment from "moment";
import { StyleSheet, css } from "aphrodite/no-important";

import Loader from "components/common/Loader";
import Tooltip from "components/common/Tooltip";
import { Data, DataLink } from "../styles";
import { DocumentSummary, RelatedDocumentVm } from "store/documents/types";
import { DocumentVM } from "store/documents/types";
import { RelatedDocuments } from "../index";
import { RelatedDocumentsTableHeader } from "./RelatedDocumentsTableHeader";

const styles = StyleSheet.create({
  col1: {
    // Document Title
    flex: "0 0 25%",
    maxWidth: "25%",
  },
  col2: {
    // Created By
    flex: "0 0 33.333333%",
    maxWidth: "33.333333%",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  col3: {
    // Created Date
    flex: "0 0 41.666667%",
    maxWidth: "41.666667%",
    textAlign: "right",
  },
  wrapper: {
    paddingBottom: "1rem !important",
    paddingTop: "1rem !important",
  },
  documentsContent: {
    // Document rows under header
    ["-ms-flex-wrap"]: "wrap",
    display: "flex",
    flexWrap: "wrap",
    marginLeft: 0,
    marginRight: 0,
  },
});

const dataRow = (
  column1: string,
  column2: string,
  column3: string,
  linkTo: string,
  i: number
) => {
  return (
    <React.Fragment key={i}>
      {linkTo ? (
        <DataLink className={css(styles.col1)} href={linkTo} target="_blank">
          {column1}
        </DataLink>
      ) : (
        <Data className={css(styles.col1)}>{column1}</Data>
      )}
      <Tooltip
        title={column2}
        placement={"top"}
        styles={{
          top: 15,
        }}
      >
        <Data className={css(styles.col2)}>{column2}</Data>
      </Tooltip>

      <Data className={css(styles.col3)}>{column3}</Data>
    </React.Fragment>
  );
};

const getDocumentTitle = (isRehuddle?: boolean, title?: string) => {
  if (isRehuddle) {
    return "Rehuddle";
  }
  return title || "-";
};

type Props = {
  document: DocumentVM | DocumentSummary;
  relatedDocuments: RelatedDocuments;
};

const RelatedDocumentsTable = ({ document, relatedDocuments }: Props) => {
  const { data, isLoading, hasError } = relatedDocuments;

  return (
    <div className={css(styles.wrapper)}>
      <Loader loading={isLoading} error={hasError}>
        {data.length > 0 ? (
          <>
            <RelatedDocumentsTableHeader />
            <div className={css(styles.documentsContent)}>
              {data.map((doc, i) => {
                return dataRow(
                  getDocumentTitle(doc.isRehuddle, doc.formName),
                  doc.createdBy || "-",
                  (doc.createdDate &&
                    moment(doc.createdDate).format("MM/DD/YY - HH:mm")) ||
                    "-",
                  `/document/${doc.id}`,
                  i
                );
              })}
            </div>
          </>
        ) : (
          "No data available"
        )}
      </Loader>
    </div>
  );
};

export default RelatedDocumentsTable;
