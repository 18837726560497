import React, { FC } from "react";
import RadioButtonsGroup from "../RadioButtons";

interface YesOrNoProps {
  value?: boolean;
  name: string;
  label: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
}

const YesOrNo: FC<YesOrNoProps> = ({
  value,
  name,
  label,
  onChange,
  disabled,
}) => {
  return (
    <RadioButtonsGroup
      name={name}
      variant="pills"
      row
      label={label}
      options={[
        { label: "Yes", value: "true" },
        { label: "No", value: "false" },
      ]}
      value={value?.toString() || ""}
      onChange={onChange}
      disabled={disabled}
    />
  );
};

export default YesOrNo;
