import React from "react";
import { SvgIcon, SvgIconProps } from "@material-ui/core";

interface ClassNames {
  background?: string;
  fill?: string;
}

export interface CheckBoxFilledProps extends SvgIconProps {
  classNames?: ClassNames;
}

export const CheckBoxFilled = ({
  classNames = {
    background: "background",
    fill: "fill",
  },
  ...otherProps
}: CheckBoxFilledProps) => {
  return (
    <SvgIcon {...otherProps}>
      <path className={classNames.background} d="M 5,5 H 19 V 19 H 5 Z" />
      <path
        className={classNames.fill}
        d="M19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-8.29 13.29c-.39.39-1.02.39-1.41 0L5.71 12.7c-.39-.39-.39-1.02 0-1.41.39-.39 1.02-.39 1.41 0L10 14.17l6.88-6.88c.39-.39 1.02-.39 1.41 0 .39.39.39 1.02 0 1.41l-7.58 7.59z"
      />
    </SvgIcon>
  );
};
