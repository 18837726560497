import React from "react";
import { PopperChevron, PopperRow, Option, DangerOption } from "./styles";

type Props = {
  onClick: () => unknown;
  label: string;
  danger?: boolean;
};

export const ActionMenuItem = ({ onClick, label, danger }: Props) => (
  <PopperRow onClick={onClick}>
    {danger ? (
      <DangerOption>{label}</DangerOption>
    ) : (
      <Option>{label}</Option>
    )}
    <PopperChevron />
  </PopperRow>
);
