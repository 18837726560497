import React from "react";
import { FieldInputProps } from "formik";

import TextInput from "components/common/form/TextInput";

const ResourceOECommentField = (fieldProps: FieldInputProps<string>) => {
  return <TextInput label="Comment" placeholder="OE Comment" {...fieldProps} />;
};

export default ResourceOECommentField;
