import React, { useMemo, useEffect } from "react";
import { range } from "lodash";

import useWindowResize from "util/hooks/useWindowResize";
import { createMarkup } from "util/index";
import { OperationalExperience } from "store/resources/types";

import IncludeButton from "./IncludeButton";
import ShowMoreButton from "./ShowMoreButton";
import * as S from "../styles";

interface Props {
  oe: OperationalExperience;
  handleIncludeExcludeOE: () => void;
  included: boolean;
  loading: boolean;
  className?: string;
}

function OECard({ oe, handleIncludeExcludeOE, included, loading, className }: Props) {
  const { width = 0 } = useWindowResize();
  const [collapsed, setCollapse] = React.useState(true);
  const isDesktopWidth = useMemo(() => width >= 1024, [width]);

  // On desktop, we will not collapse cards
  // and we'll hide the show more/less buttons
  useEffect(() => {
    if (isDesktopWidth && collapsed) {
      setCollapse(false);
    }
  }, [width, collapsed, isDesktopWidth]);

  const descriptionCharCount = useMemo(
    () => (oe.description ? oe.description.length : 0),
    [oe.description]
  );

  const showCardActionRow = useMemo(() => {
    if (isDesktopWidth) {
      return false;
    }

    if (range(width, 768, 835) && descriptionCharCount < 370) {
      return false;
    }

    if (width < 768 && descriptionCharCount < 150) {
      return false;
    }

    return true;
  }, [descriptionCharCount, isDesktopWidth, width]);

  return (
    <S.OperationalExperienceCard included={included} className={className}>
      <S.OECardActionRow variant="top">
        <IncludeButton
          onClick={handleIncludeExcludeOE}
          included={included}
          loading={loading}
        >
          {included ? "Included" : "Include"}
        </IncludeButton>
      </S.OECardActionRow>
      <S.OECardTitleRow>
        <S.OECardTitle as="p">{oe.incident}</S.OECardTitle>
        <S.OECardIDLabel as="p">#{oe.id}</S.OECardIDLabel>
      </S.OECardTitleRow>
      <S.OECardCollapsibleBody
        descriptionCharCount={descriptionCharCount}
        collapsed={collapsed}
        included={included}
      >
        <S.OECardHTML dangerouslySetInnerHTML={createMarkup(oe.description)} />
      </S.OECardCollapsibleBody>
      {showCardActionRow && (
        <S.OECardActionRow
          variant="bottom"
          descriptionCharCount={descriptionCharCount}
        >
          <ShowMoreButton
            collapsed={collapsed}
            onClick={() => setCollapse(!collapsed)}
          />
        </S.OECardActionRow>
      )}
    </S.OperationalExperienceCard>
  );
}

export default OECard;
