import React, { FC, useEffect } from "react";
import { Field, useFormikContext } from "formik";
import { css } from "aphrodite/no-important";

import TextInput from "components/common/form/TextInput";

import { getSecondsFromMidnight, getTimeFromSeconds } from "../../helpers";
import styles from "../styles";
import { FBForm } from "../../types";

interface Props {
  disabled?: boolean;
}

const TimeToOption: FC<Props> = ({ disabled }) => {
  const { values, setFieldValue } = useFormikContext<FBForm>();
  const s = styles();

  useEffect(() => {
    if (values.formSubmissionConstraint?.type === "TIME") {
      if (!values.formSubmissionConstraint?.timeLimit) {
        const timeLimit = getSecondsFromMidnight("08:00");
        setFieldValue("formSubmissionConstraint.timeLimit", timeLimit);
      }
    }
  }, [values.formSubmissionConstraint?.type]);

  // reshape form value to time input string
  const value =
    values.formSubmissionConstraint?.timeLimit &&
    getTimeFromSeconds(values.formSubmissionConstraint.timeLimit);

  return (
    <div className={css(s.editTimeframeOption)}>
      At
      <Field
        as={TextInput}
        className={css(s.timeToInput)}
        name="formSubmissionConstraint.timeLimit"
        variant="standard"
        type="time"
        onChange={(e) => {
          const timeLimit = getSecondsFromMidnight(e.target.value);
          setFieldValue("formSubmissionConstraint.timeLimit", timeLimit);
        }}
        value={value || "08:00"}
        disabled={disabled}
      />
    </div>
  );
};

export default TimeToOption;
