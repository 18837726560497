import React, { ChangeEvent } from "react";
import { bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";
import { RouteChildrenProps } from "react-router";
import * as R from "ramda";
import { get } from "lodash";
import MediaQuery from "react-responsive";

import { getDocTypeFromURLParam } from "util/index";
import {
  DocumentOwner,
  DocumentSummary,
  Owners,
  SearchDocumentsParams,
  DocumentQuestionResponseVm,
  DocumentParticipant,
} from "store/documents/types";
import { AppConfigsState } from "store/appConfigs/types";
import { AppState } from "store";
import {
  ClearStatusActionTypes,
  ClearStatusPayloadTypes,
  NewDocumentState,
} from "store/newDocument/types";
import { DataSourceValueDTO } from "store/dataSource/types";
import { devices } from "themes/mediaQueries";
import { FormTypeDTO, WorkflowType } from "store/forms/types";
import {
  TimeFilterType,
  getMinMaxSubmissionDatesFromTimeFilter,
} from "components/helpers/filters";
import { SubmissionType } from "store/newDocument/actions";
import { SystemState } from "store/system/types";
import * as documentsActions from "store/documents/actions";
import * as filtersActions from "store/filters/documents/actions";
import * as formsActions from "store/forms/actions";
import * as newDocumentActions from "store/newDocument/actions";
import * as participantsActions from "store/participants/actions";
import * as userActions from "store/user/actions";
import DashboardDrawer from "components/dashboard/DashboardDrawer";
import Drawer from "components/common/Drawer";
import MyDocuments from "components/dashboard/MyDocuments";
import RecentDocuments from "components/dashboard/RecentDocuments";
import ViewTitle from "components/common/styled/ViewTitle";
import DocumentSidebarDrawer from "components/DocumentSidebarDrawer";
import DocumentSearchBar from "components/filters/DocumentSearchBar";

import DesktopSearchFilters from "./components/DesktopSearchFilters";
import MobileSearchFilterSidebar from "./components/MobileSearchFilterSidebar";
import * as S from "./styles";
import { AutocompleteSuggestion } from "components/forms/Autocomplete";
import { getGroupConfigTerm } from "util/getGroupConfigTerm-temp";
import { DateRangeValue } from "components/common/DateRange";
import Toast from "components/common/Toast";
import { remCalc } from "themes/helpers";
import {
  DocumentsFiltersArgs,
  DocumentsFiltersParams,
} from "store/filters/documents/types";

export interface DashboardProps
  extends RouteChildrenProps<any, { error: WorkflowType }> {
  store: {
    appConfigs: AppConfigsState;
    filtersSelect: DocumentsFiltersArgs;
    formTypes: Array<FormTypeDTO>;
    newDocument: NewDocumentState;
    ownerIds: Owners;
    system: SystemState;
    tempFilterDocumentsIsLoading?: boolean;
    tempFilterDocumentsLength?: number;
    workLocationIDFromUserReducer: number | null;
  };

  actions: {
    documents: {
      getOwnerIds: any; // This should be coming from the doc types
      resetOwnerIds: () => void;
      searchDocuments: (props: SearchDocumentsParams) => void;
      searchTempFilterDocuments: (props: SearchDocumentsParams) => void;
    };
    filters: {
      setDocumentsFilters: (props: DocumentsFiltersParams) => void;
    };
    forms: {
      getAllForms: () => void;
      getAllFormTypes: () => void;
    };
    newDocument: {
      clearStatus: ({
        actionType,
        payload,
      }: {
        actionType: ClearStatusActionTypes;
        payload: ClearStatusPayloadTypes;
      }) => void;
      createDocument: ({
        submissionDate,
        formId,
        workOrderId,
        parentId,
      }: {
        submissionDate: string;
        formId: number;
        workOrderId?: number;
        parentId?: number;
      }) => void;
      uploadNewDocument: ({
        responses,
        documentParticipants,
        submissionType,
      }: {
        responses: Array<DocumentQuestionResponseVm>;
        documentParticipants: Array<DocumentParticipant>;
        submissionType: SubmissionType;
      }) => void;

      continueDocument: (documentId: number) => void;
      resetCurrentDocument: () => void;
      // fetchSummarizedForms: () => void;
      clearWorkOrders: () => void;
    };
    participants: {
      getParticipants: (workLocationIDFromUserReducer: number) => void;
    };
    user: {
      getUser: () => void;
    };
  };

  // White label
  terms: {
    document: string;
    documents: string;
  };
}

export interface DashboardState {
  contextMenuDocument: {
    title: string;
    id: string | number;
    submissionDate: string;
  };
  dashboardDrawerVisible: boolean;
  dateRange: DateRangeValue | null; // Date range filter type
  documentOwner: number | null;
  documentType: number;
  filtersDrawerVisible: boolean;
  participants: Array<DataSourceValueDTO>;
  query: string;
  selectedDocument: DocumentSummary | null;
  showContextMenu: boolean;
  subordinatesFilter: string;
  tempDateRange: DateRangeValue | null; // Date range filter type
  tempDocumentOwner: number | null;
  tempDocumentType: number;
  tempSubordinatesFilter: string;
  tempTimeFilterType: TimeFilterType; // Date range filter type
  timeFilterType: TimeFilterType; // Date range filter type
  windowWidth: number;
}

class Documents extends React.Component<DashboardProps, DashboardState> {
  constructor(props: DashboardProps) {
    super(props);
    const documentTypeFromURLParam =
      get(props.match, "params.formType") ||
      props.store.filtersSelect.filterParams.documentType;
    const initialCustomDates = props.store.filtersSelect.filterParams.dateRange
      ? {
          startDate:
            // @ts-ignore
            props.store.filtersSelect.filterParams.dateRange?.startDate,
          // @ts-ignore
          endDate: props.store.filtersSelect.filterParams.dateRange?.endDate,
        }
      : {};

    this.state = {
      contextMenuDocument: {
        title: "",
        id: "",
        submissionDate: "",
      },
      dashboardDrawerVisible: false,
      dateRange: initialCustomDates as any, // Date range filter value
      documentOwner: props.store.filtersSelect.filterParams.documentOwner,
      documentType: getDocTypeFromURLParam(documentTypeFromURLParam),
      filtersDrawerVisible: false,
      participants: [],
      query: props.store.filtersSelect.filterParams.query,
      selectedDocument: null,
      showContextMenu: false,
      subordinatesFilter:
        props.store.filtersSelect.filterParams.subordinatesFilter,
      tempDateRange: initialCustomDates as any, // Date range filter value
      tempDocumentOwner: props.store.filtersSelect.filterParams.documentOwner,
      tempDocumentType: getDocTypeFromURLParam(documentTypeFromURLParam),
      tempTimeFilterType:
        (props.store.filtersSelect.filterParams
          .timeFilterType as TimeFilterType) || "ALL_TIME", // Date range filter value
      timeFilterType:
        (props.store.filtersSelect.filterParams
          .timeFilterType as TimeFilterType) || "ALL_TIME", // Date range filter value
      tempSubordinatesFilter:
        props.store.filtersSelect.filterParams.subordinatesFilter,
      windowWidth: window.innerWidth,
    };
  }
  componentDidMount() {
    const {
      actions,
      store: { filtersSelect, workLocationIDFromUserReducer },
      match,
    } = this.props;

    // If URL Param for formId exists, compute
    // value for API call
    const documentTypeFromURLParam =
      get(match, "params.formType") || filtersSelect.filterParams.documentType;
    const formTypeIds: number =
      getDocTypeFromURLParam(documentTypeFromURLParam) !== -1
        ? getDocTypeFromURLParam(documentTypeFromURLParam)
        : -1;

    // get participants based on `workOrderLocationId`
    workLocationIDFromUserReducer &&
      actions.participants.getParticipants(workLocationIDFromUserReducer);

    this.setFiltersAndSearchDocuments({
      query: this.state.query,
      documentOwner: this.state.documentOwner,
      documentType: formTypeIds,
    });

    actions.forms.getAllForms();
    actions.forms.getAllFormTypes();

    // returns `newDocument` to initial state
    // @NOTE: be sure to call before `fetchSummarizedForms`
    // @NOTE: should we change this process so those values are not reset and
    // we don't always re-call `fetchSummarizedForms`?
    actions.newDocument.resetCurrentDocument();

    // dispatch API call to get data for Start New Document
    // actions.newDocument.fetchSummarizedForms();

    /// remove if already exists, add event listener to get
    // current window width for setting anchor for Start New Form
    window.removeEventListener("resize", this.handleListenToWindowWidth);
    window.addEventListener("resize", this.handleListenToWindowWidth);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleListenToWindowWidth);
  }

  mapSuggestions = (
    suggestions: Owners
  ): AutocompleteSuggestion[] | undefined =>
    suggestions.content &&
    suggestions.content.map((e: DocumentOwner) => {
      return {
        id: e.participantId,
        value: `${e.lastName}, ${e.firstName}`,
      };
    });

  handleListenToWindowWidth = () => {
    const { windowWidth } = this.state;

    if (windowWidth !== window.innerWidth) {
      return this.setState({
        windowWidth: window.innerWidth,
      });
    }
  };

  toggleDashboardDrawerVisibility = () =>
    this.setState((prevState) => ({
      dashboardDrawerVisible: !prevState.dashboardDrawerVisible,
    }));

  openContextMenu = (selectedDocument: DocumentSummary) => {
    if (document) {
      this.setState({
        selectedDocument,
        showContextMenu: true,
      });
    }
  };

  handleChange = (value: string) => {
    this.setFiltersAndSearchDocuments({ query: value });
  };

  handleFilterChange = (e: any) => {
    const {
      target: { name, value },
    } = e;

    if (name === "timeFilter" && value === "CUSTOM_RANGE") {
      this.setFiltersAndSearchDocuments({ timeFilterType: value });
      return;
    }

    if (name && value) {
      let newFilters = {};

      switch (name) {
        case "timeFilter":
          newFilters = { timeFilterType: value };
          break;
        case "documentType":
          newFilters = { documentType: value };
          break;
        default:
          break;
      }

      this.setFiltersAndSearchDocuments(newFilters);
    }
  };

  // Handle date range change
  onDateRangeChange = (value: DateRangeValue) => {
    this.setFiltersAndSearchDocuments({
      dateRange: value as any,
      timeFilterType: "CUSTOM_RANGE",
    });
  };

  // Handle date range clear
  onDateRangeClear = () => {
    this.setFiltersAndSearchDocuments({
      dateRange: null,
      timeFilterType: "ALL_TIME",
    });
  };

  // Handles onChange for Mobile's Date Range and Document Type
  handleTempFilterChange = (name: string, value: string) => {
    let newFilters = {};

    switch (name) {
      case "tempTimeFilterType":
        newFilters = { tempTimeFilterType: value };
        break;
      case "customRange":
        newFilters = { tempDateRange: value };
        break;
      case "tempDocumentType":
        newFilters = { tempDocumentType: value };
        break;
      case "tempSubordinatesFilter":
        newFilters = { tempSubordinatesFilter: value };
        break;
      default:
        break;
    }

    this.setFiltersAndSearchDocuments(newFilters, true);
  };

  setFiltersAndSearchDocuments = (filters, isTemp = false) => {
    this.setState(filters, () => {
      const {
        timeFilterType,
        dateRange,
        documentType,
        documentOwner,
        subordinatesFilter,
        tempTimeFilterType,
        tempDateRange,
        tempDocumentType,
        tempDocumentOwner,
        tempSubordinatesFilter,
        query,
      } = this.state;

      // set the temp value in case the browser resize or tablet/phone view change
      if (!isTemp) {
        this.setState({
          tempDocumentOwner: documentOwner,
          tempDocumentType: documentType,
          tempSubordinatesFilter: subordinatesFilter,
          tempTimeFilterType: timeFilterType,
          tempDateRange: dateRange,
        });
      }

      const {
        maxSubmissionDate,
        minSubmissionDate,
      } = getMinMaxSubmissionDatesFromTimeFilter(
        isTemp ? tempTimeFilterType : timeFilterType,
        isTemp ? tempDateRange : dateRange
      );

      this.props.actions.filters.setDocumentsFilters({
        ...this.props.store.filtersSelect.filterParams,
        ...filters,
      });

      const searchDocumentsAction = isTemp
        ? this.props.actions.documents.searchTempFilterDocuments
        : this.props.actions.documents.searchDocuments;

      const participantTreeFilter = isTemp
        ? tempSubordinatesFilter
        : subordinatesFilter;
      const formTypeIds = isTemp ? tempDocumentType : documentType;
      const ownerIds = isTemp ? tempDocumentOwner : documentOwner;

      searchDocumentsAction({
        query,
        value: 0,
        minSubmissionDate,
        maxSubmissionDate,
        participantTreeFilter,
        ownerIds,
        formTypeIds,
        sortName: "submissionDate",
        sortOrder: "desc",
      });
    });
  };

  handleSubordinatesFilterChange = (e: ChangeEvent<HTMLSelectElement>) => {
    this.setFiltersAndSearchDocuments({ subordinatesFilter: e.target.value });
  };

  handleOwnerIdChange = (ownerId: number | null, isTemp = false) => {
    if (isTemp) {
      const {
        tempTimeFilterType,
        tempDateRange,
        tempDocumentType,
        tempSubordinatesFilter,
      } = this.state;
      const {
        maxSubmissionDate,
        minSubmissionDate,
      } = getMinMaxSubmissionDatesFromTimeFilter(
        tempTimeFilterType,
        tempDateRange
      );

      this.setState({
        tempDocumentOwner: ownerId,
      });

      this.props.actions.documents.searchTempFilterDocuments({
        query: this.state.query,
        value: 0,
        minSubmissionDate,
        maxSubmissionDate,
        ownerIds: ownerId,
        participantTreeFilter: tempSubordinatesFilter,
        formTypeIds: tempDocumentType,
        sortName: "submissionDate",
        sortOrder: "desc",
      });
    } else {
      this.setFiltersAndSearchDocuments({ documentOwner: ownerId });
    }
  };

  getSearchParams = () => {
    const {
      timeFilterType,
      dateRange,
      documentType,
      documentOwner,
      subordinatesFilter,
    } = this.state;

    const {
      maxSubmissionDate,
      minSubmissionDate,
    } = getMinMaxSubmissionDatesFromTimeFilter(timeFilterType, dateRange);

    return {
      query: this.state.query,
      value: 0,
      minSubmissionDate,
      maxSubmissionDate,
      ownerIds: documentOwner,
      participantTreeFilter: subordinatesFilter,
      formTypeIds: documentType,
      sortName: "submissionDate",
      sortOrder: "desc",
    };
  };

  handleApplySearchFilters = () => {
    const {
      query,
      tempDateRange,
      tempDocumentOwner,
      tempDocumentType,
      tempSubordinatesFilter,
      tempTimeFilterType,
    } = this.state;

    // Apply the temp filters to main ones
    const filters = {
      query,
      dateRange: tempDateRange,
      documentOwner: tempDocumentOwner,
      documentType: tempDocumentType,
      subordinatesFilter: tempSubordinatesFilter,
      timeFilterType: tempTimeFilterType,
    };

    this.setFiltersAndSearchDocuments(filters);
    this.setState({ filtersDrawerVisible: false });
  };

  startNewDocMobileAnchor = React.createRef<HTMLAnchorElement>();
  startNewDocTabletAnchor = React.createRef<HTMLAnchorElement>();
  startNewDocDesktopAnchor = React.createRef<HTMLAnchorElement>();

  render() {
    const {
      store: { formTypes },
    } = this.props;

    const { selectedDocument } = this.state;

    // White label
    // const documentTerm = this.props.terms?.document || "Document";
    // const documentTermLower = documentTerm.toLowerCase();
    const documentsTerm = this.props.terms?.documents || "Documents";
    const documentsTermLower = documentsTerm.toLowerCase();

    const isDeleted = this.props.location?.state?.error === "DELETED";

    return (
      <>
        {/* deleted document redirect toast */}
        <div style={{ height: remCalc(35) }} hidden={!isDeleted}>
          <Toast
            variant="error"
            onClick={() => {
              this.props.history.replace({
                ...this.props.location,
                state: { error: "" },
              });
            }}
            visible={isDeleted}
          >
            The document you are trying to view has been deleted.
          </Toast>
        </div>
        <DocumentSidebarDrawer
          selectedDocument={selectedDocument}
          open={this.state.showContextMenu}
          onClose={() => this.setState({ showContextMenu: false })}
          onOpen={() => this.setState({ showContextMenu: true })}
        />

        {/* START NEW FORM */}
        <DashboardDrawer />

        {/* Used for desktop views */}
        <MediaQuery minWidth={devices.minDesktop}>
          <S.DesktopDocuments>
            <S.DesktopMaxWidth>
              <ViewTitle>{documentsTerm}</ViewTitle>
              {/* Filters  */}
              <DesktopSearchFilters
                formTypes={formTypes || []}
                getOwnerIds={this.props.actions.documents.getOwnerIds}
                getSearchParams={this.getSearchParams}
                handleChange={this.handleChange}
                handleFilterChange={this.handleFilterChange}
                handleOwnerIdChange={this.handleOwnerIdChange}
                handleSubordinatesFilterChange={
                  this.handleSubordinatesFilterChange
                }
                mapSuggestions={this.mapSuggestions}
                onDateRangeChange={this.onDateRangeChange}
                onDateRangeClear={this.onDateRangeClear}
                ownerIds={this.props.store.ownerIds}
                resetOwnerIds={this.props.actions.documents.resetOwnerIds}
              />
            </S.DesktopMaxWidth>

            <RecentDocuments
              openContextMenu={this.openContextMenu}
              query={this.state.query}
            />
            <S.DesktopMaxWidth>
              <MyDocuments openContextMenu={this.openContextMenu} />
            </S.DesktopMaxWidth>
          </S.DesktopDocuments>
        </MediaQuery>

        {/* Tablet View will render the components below */}
        <MediaQuery maxWidth={devices.maxTablet} minWidth={devices.minTablet}>
          {/* Mobile filter drawer */}
          <Drawer
            content={
              <MobileSearchFilterSidebar
                documentType={this.state.documentType}
                formTypes={formTypes || []}
                getOwnerIds={this.props.actions.documents.getOwnerIds}
                handleApplySearchFilters={this.handleApplySearchFilters}
                handleTempFilterChange={this.handleTempFilterChange}
                handleTempOwnerIdChange={(id: number | null) =>
                  this.handleOwnerIdChange(id, true)
                }
                mapSuggestions={this.mapSuggestions}
                ownerIds={this.props.store.ownerIds}
                query={this.state.query}
                resetOwnerIds={this.props.actions.documents.resetOwnerIds}
                searchDocuments={this.props.actions.documents.searchDocuments}
                tempDocumentOwner={this.state.tempDocumentOwner}
                tempDocumentType={this.state.tempDocumentType}
                tempFilterDocumentsLength={
                  this.props.store.tempFilterDocumentsLength
                }
                tempSubordinatesFilter={this.state.tempSubordinatesFilter}
                // Date range props
                tempTimeFilterType={this.state.tempTimeFilterType}
              />
            }
            anchor="right"
            id="mobileContextMenu"
            onClose={() => this.setState({ filtersDrawerVisible: false })}
            onOpen={() => false}
            open={this.state.filtersDrawerVisible}
            overrideClasses={{
              container: "",
              content: "myDocumentsDrawer",
            }}
          />

          <S.Documents>
            <DocumentSearchBar
              mobile
              autoFocus={false}
              onChange={this.handleChange}
              params={() => this.getSearchParams()}
              placeholder="Search documents"
              query={this.state.query}
              openMobileFilters={() => {
                const filters = {};
                this.setFiltersAndSearchDocuments(filters, true);
                this.setState({ filtersDrawerVisible: true });
              }}
            />
            <S.OverFlow>
              <RecentDocuments
                openContextMenu={this.openContextMenu}
                query={this.state.query}
              />
              <MyDocuments openContextMenu={this.openContextMenu} />
            </S.OverFlow>
          </S.Documents>
        </MediaQuery>

        {/* Mobile only */}
        <MediaQuery maxWidth={devices.maxPhone}>
          {/* Mobile filter drawer */}
          <Drawer
            content={
              <MobileSearchFilterSidebar
                documentType={this.state.documentType}
                formTypes={formTypes || []}
                getOwnerIds={this.props.actions.documents.getOwnerIds}
                handleApplySearchFilters={this.handleApplySearchFilters}
                handleTempFilterChange={this.handleTempFilterChange}
                handleTempOwnerIdChange={(id: number | null) =>
                  this.handleOwnerIdChange(id, true)
                }
                mapSuggestions={this.mapSuggestions}
                ownerIds={this.props.store.ownerIds}
                query={this.state.query}
                resetOwnerIds={this.props.actions.documents.resetOwnerIds}
                searchDocuments={this.props.actions.documents.searchDocuments}
                tempDocumentOwner={this.state.tempDocumentOwner}
                tempDocumentType={this.state.tempDocumentType}
                tempFilterDocumentsLength={
                  this.props.store.tempFilterDocumentsLength
                }
                tempSubordinatesFilter={this.state.tempSubordinatesFilter}
                tempTimeFilterType={this.state.tempTimeFilterType}
              />
            }
            anchor="right"
            id="mobileContextMenu"
            onClose={() => this.setState({ filtersDrawerVisible: false })}
            onOpen={() => false}
            open={this.state.filtersDrawerVisible}
            overrideClasses={{
              container: "",
              content: "myDocumentsDrawer",
            }}
          />

          <S.Documents>
            <DocumentSearchBar
              autoFocus={false}
              mobile
              onChange={this.handleChange}
              params={() => this.getSearchParams()}
              placeholder={`Search ${documentsTermLower}`}
              query={this.state.query}
              openMobileFilters={() => {
                const filters = {};
                this.setFiltersAndSearchDocuments(filters, true);
                this.setState({ filtersDrawerVisible: true });
              }}
            />
            <S.OverFlow>
              <RecentDocuments
                openContextMenu={this.openContextMenu}
                query={this.state.query}
              />
              <MyDocuments openContextMenu={this.openContextMenu} />
            </S.OverFlow>
          </S.Documents>
        </MediaQuery>
      </>
    );
  }
}

const mapStateToProps = (state: AppState) => {
  const documentTerm = getGroupConfigTerm(state, "document", "noun", undefined);
  const documentsTerm = getGroupConfigTerm(state, "document", "noun", "plural");
  return {
    store: {
      appConfigs: state.appConfigs,
      filtersSelect: state.filters.documents,
      formTypes: state.forms.data.formTypes || [],
      newDocument: state.newDocument,
      ownerIds: (state.documents.data && state.documents.data.ownerIds) || [],
      system: state.system,
      tempFilterDocumentsIsLoading: R.pathOr(
        false,
        ["tempFilterDocuments", "loading", "GET"],
        state.documents.data
      ),
      tempFilterDocumentsLength: R.pathOr(
        0,
        ["tempFilterDocuments", "totalElements"],
        state.documents.data
      ),
      workLocationIDFromUserReducer: R.pathOr(
        null,
        ["participant", "workLocation", "id"],
        state.user.data
      ),
    },
    // White label
    terms: {
      document: documentTerm,
      documents: documentsTerm,
    },
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  actions: {
    documents: bindActionCreators(documentsActions, dispatch),
    filters: bindActionCreators(filtersActions, dispatch),
    forms: bindActionCreators(formsActions, dispatch),
    newDocument: bindActionCreators(newDocumentActions, dispatch),
    participants: bindActionCreators(participantsActions, dispatch),
    user: bindActionCreators(userActions, dispatch),
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Documents);
