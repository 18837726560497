import styled from "styled-components";

import { Chevron } from "components/common/TableUI/Pagination/styles";
import TextBase from "components/common/Text";
import { remCalc } from "themes/helpers";

// --------------------------------------------------------------------------
// ? DocumentSidebar Drawers
// --------------------------------------------------------------------------

export const CloseDrawerIconWrapper = styled.div`
  position: absolute;
  top: 17px;
  left: 15px;
`;

export const ChevronIcon = styled(Chevron)`
  :hover {
    cursor: pointer;
  }
`;

export const SidebarDrawerWrapper = styled.div`
  box-shadow: 0px 1px 3px ${({ theme }) => theme.masterColors.lightGrey};
  line-height: 1.5rem;
  margin-bottom: 16px;
  padding-bottom: 1.05rem;
  padding-top: 1.05rem;
  position: absolute;
  text-align: center;
  width: 100%;
  height: 56px;
  left: 0;
  top: 0;
`;

export const SidebarDrawerHeader = styled.h6`
  color: ${({ theme }) => theme.masterColors.black};
  font-weight: 500;
  font-size: 1.25rem;
  letter-spacing: 0.26px;
`;

export const SidebarDrawerBody = styled.p`
  color: ${({ theme }) => theme.masterColors.black};
  font-size: ${remCalc(14)};
  letter-spacing: 0.24px;
`;

export const SidebarDrawerContent = styled.div`
  margin-top: 56px;
  text-align: left;
  padding-left: 4px;
  padding-right: 4px;
`;

// --------------------------------------------------------------------------
// ? DocumentSidebarMenu
// --------------------------------------------------------------------------

export const DocumentSidebarMenu = styled.div``;

// --------------------------------------------------------------------------
// ? DocumentSidebar
// --------------------------------------------------------------------------

export const DocumentSidebar = styled.div``;

export const NavLabel = styled(TextBase)`
  font-size: ${remCalc(14)};
  color: ${({ theme }) => theme.masterColors.darkGrey};
  font-weight: 400;
  letter-spacing: ${remCalc(0.24)};
`;
