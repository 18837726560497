import React, { useMemo } from "react";
import moment from "moment";
import { useFormikContext } from "formik";

import { FieldErrorType } from "components/FormController/components/Question";
import { QuestionSuggestion } from "components/FormController/types";

import { SingleResponseQuestionProps } from "../types";
import * as S from "./styles";
import { LazyLoadSelect } from "components/common/form/Select";
import { AssistiveLink } from "components/common/form/types";
import { Option } from "components/common/form/Select/Select";
import { fmtSelections } from "components/helpers";
import { FormDTO } from "store/forms/types";

export interface SelectInputProps extends SingleResponseQuestionProps {
  assistiveLink?: AssistiveLink;
  disabled?: boolean;
  error?: FieldErrorType;
  handleBlur?: () => void;
  helperText?: string;
  selections: QuestionSuggestion[];
  label?: string;
  name: string | number;
  onChange?: (e: any) => void;
  onLoadMore?: () => Promise<void> | undefined;
  isFinalPage?: boolean;
  isLoadingMore?: boolean;
  value?: string | null;
}

const SelectInput = ({
  assistiveLink,
  disabled,
  error,
  handleBlur,
  label,
  name,
  onChange,
  question,
  selections,
  response,
  setQuestionResponse,
  onLoadMore,
  isLoadingMore,
  isFinalPage,
}: SelectInputProps) => {
  const [options, setOptions] = React.useState<Array<Option>>([]);
  const { errors } = useFormikContext<FormDTO>();
  const isRequired = question.formProperties?.isRequired;
  const enableComments = question.properties?.enableComments;
  const commentRequired = question.selections?.find(
    (selection) => selection.id === response?.associatedId
  )?.properties?.commentRequired;

  /* Format incoming QuestionSuggestions into Select Options */
  React.useEffect(() => {
    setOptions(() => fmtSelections(selections));
  }, [selections]);

  const localOnChange = (
    e: React.ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    const {
      target: { value },
    } = e;

    const selection = selections.find((x) => x.id === value);

    if (!selection) {
      return;
    }

    setQuestionResponse(
      value && {
        answer: selection.value?.toString() || selection.title,
        associatedId: selection.id,
        associatedRootId: question.answerSource
          ? selection.id
          : selection.rootId,
        associatedLocation: selection.associatedLocation,
        questionId: question.id,
        questionRootId: question.rootId,
        timeAnswered: moment.utc().format(),
      }
    );

    if (onChange) {
      onChange(e);
    }
  };

  const setComment = (comments?: string) =>
    setQuestionResponse({
      ...response,
      answer: response?.answer || "",
      questionId: question.id,
      questionRootId: question.rootId,
      comments,
      timeAnswered: new Date().toISOString(),
    });

  // Helper text
  const helperText = useMemo(
    () => question.properties?.assistiveText || undefined,
    [question.properties?.assistiveText]
  );

  // This should update the selection that matches the existing options, usually from dataset
  // after the document was cloned from the option that was customized or other dataset.
  // fallback is to use existing associatedId
  const currentSelection =
    options.find(
      (o) => response?.answer === o.value && response?.associatedId !== o.id
    )?.id || response?.associatedId;

  return (
    <S.SelectInput onBlur={handleBlur}>
      <LazyLoadSelect
        assistiveLink={assistiveLink}
        disabled={disabled}
        error={error}
        helperText={helperText}
        isFinalPage={isFinalPage}
        isLoadingMore={isLoadingMore}
        label={label}
        name={`${name}`}
        onBlur={handleBlur}
        onChange={localOnChange}
        onLoadMore={onLoadMore}
        options={options}
        required={isRequired}
        value={currentSelection}
      />
      {enableComments && response && response.associatedId && (
        <S.Comment
          commentValue={response.comments}
          error={errors[`${question.id}_${response.associatedId}_comment`]}
          enabled={true}
          name={`${question.id}_${response.associatedId}_comment`}
          handleUpdateComment={setComment}
          variant="DEFENSE"
          required={commentRequired}
        />
      )}
    </S.SelectInput>
  );
};

export { SelectInput };
