import "bootstrap/dist/css/bootstrap.css";
import * as React from "react";
import * as Sentry from "@sentry/browser";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";
import { ThemeProvider } from "styled-components";
import { hotjar } from "react-hotjar";
import { render } from "react-dom";
import { BottomNavigationContextProvider } from "components/dashboard/DashboardBottomNavigation/Context";

import "assets/icomoon/style.css";
import App from "./App";
import { animations } from "themes/animations";
import COLORS from "themes/colors";
import GlobalStyle from "./themes/GlobalStyle";
import Loader from "components/common/Loader";
import ThemeContext, { ThemeContextProvider } from "themes/ThemeContext";
import configureStore from "./store";
import * as FullStory from "@fullstory/browser";
import "sass/main.scss";
import ErrorBoundary from "components/common/ErrorBoundary";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";

// Analytics
const fsOrgId = "V7CZE";
FullStory.init({ orgId: fsOrgId });
if (
  process.env.NODE_ENV !== "development" &&
  process.env.REACT_APP_CURRENTENV
) {
  let sentryUrl: string | undefined;
  let hjid: number | undefined;
  const fsOrgId = "V7CZE";

  switch (process.env.REACT_APP_CURRENTENV.toUpperCase()) {
    case "DEV":
      console.log("Not tracking this ENV");
      break;
    case "QA":
      hjid = 1494582;
      sentryUrl = "https://93a2b5d9b38948819f7aaf8fa8f9f0a9@sentry.io/1762785";
      // fsOrgId = "V7CZE";
      break;
    case "UAT":
      hjid = 1494582;
      sentryUrl = "https://d475831ff9424490bf0c31815c60c0d7@sentry.io/1758454";
      // fsOrgId = "V7CZE";
      break;
    case "PROD":
      hotjar.initialize(1494582, 6);
      Sentry.init({
        dsn:
          "https://28e0f6473e4b4239a2f0d499334c2ff5@o304379.ingest.sentry.io/5303874",
      });
      break;
    default:
      console.log("Not tracking this ENV");
  }

  if (hjid) hotjar.initialize(1494582, 6);
  if (sentryUrl) Sentry.init({ dsn: sentryUrl });
  // if (fsOrgId) FullStory.init({ orgId: fsOrgId });
}

const { store, persistor } = configureStore(); // Store

const MUItheme = createMuiTheme({
  palette: {
    primary: {
      main: COLORS.masterColors.primary,
    },
  },
}); // MUI Theme

const Root = () => {
  const { darkMode } = React.useContext(ThemeContext);

  // New app version available
  const [needUpdate, setNeedUpdate] = React.useState<boolean | undefined>();

  // Check for new version
  // Check if app needs an update
  // const checkAsset = React.useCallback(() => {
  //   if (process.env.NODE_ENV === "production") {
  //     const currentBuildHash = localStorage.getItem("buildHash");
  //     fetch("/asset-manifest.json")
  //       .then((response) => response.json())
  //       .then((json) => {
  //         if (needUpdate === undefined && json["main.js"] !== currentBuildHash) {
  //           setNeedUpdate(true);
  //         }
  //       });
  //   }
  // }, []);

  // On mount
  // React.useEffect(() => {
  //   // Fetch hash for JS asset and set timer to check for changes
  //   if (process.env.NODE_ENV === "production") {
  //     fetch("/asset-manifest.json")
  //       .then((response) => response.json())
  //       .then((json) => {
  //         const assets: {
  //           "main.js": string;
  //         } = json;
  //         // If current build hash isn't stored, set it
  //         if (!localStorage?.buildHash) {
  //           localStorage.setItem("buildHash", assets["main.js"]);
  //         }
  //         // If current build is stored, but is different than server's
  //         else if (localStorage.buildHash !== assets["main.js"]) {
  //           setNeedUpdate(true);
  //         }
  //       });
  //     setInterval(() => checkAsset(), 180000);
  //   }
  // }, []);

  const theme = {
    animations,
    colors: darkMode ? COLORS.darkTheme : COLORS.lightTheme,
    masterColors: COLORS.masterColors,
  };

  const updateModalIsOpen = needUpdate === true;

  return (
    <ThemeProvider theme={theme}>
      <MuiThemeProvider theme={MUItheme}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <Provider store={store}>
            <ErrorBoundary>
              <PersistGate
                loading={<Loader loading={true} />}
                persistor={persistor}
              >
                {/* <ApplicationUpdateModal isOpen={needUpdate} onClose={() => setNeedUpdate(false)}/> */}
                <GlobalStyle />
                <BottomNavigationContextProvider>
                  <App />
                </BottomNavigationContextProvider>
              </PersistGate>
            </ErrorBoundary>
          </Provider>
        </MuiPickersUtilsProvider>
      </MuiThemeProvider>
    </ThemeProvider>
  );
};

const RootWithThemeContext = () => (
  <ThemeContextProvider>
    <Root />
  </ThemeContextProvider>
);

render(<RootWithThemeContext />, document.getElementById("root"));
