import React from "react";
import styled from "styled-components";
import WarningIcon from "@material-ui/icons/Warning";

import { remCalc } from "themes/helpers";
import mediaQueries from "themes/mediaQueries";

import { ToastVariant } from "./Toast";

/**
 * In charge of rendering its children in the correct
 * area but is not intended to be used for styles such as
 * color, fonts, spacing, ect
 *
 * Use `ToastChildren` and the `variant` prop from __Toast__
 * to handle styling for different use cases and for
 * styling the elements within the __Toast__ component
 */
export const Toast = styled.div<{ shouldScroll?: boolean }>`
  position: ${({ shouldScroll }) => (shouldScroll ? "absolute" : "fixed")};
  top: 0;
  left: 0;
  width: 100%; // @TODO: FIX ISSUE WITH APP STYLING -- THIS IS TEMPORARY

  margin-top: ${remCalc(75)};

  z-index: 1031;

  @media (${mediaQueries.maxPhone}) {
    margin-top: ${remCalc(75)};
  }
`;

export const ToastChildren = styled.button<{ variant: ToastVariant }>`
  margin: ${remCalc(9)} ${remCalc(8)};
  padding: ${remCalc(16)};
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  border-radius: ${remCalc(4)};
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;

  /* DETERMINE COLOR BASED STYLING USING VARIANT */

  ${({ variant, theme }) => {
    switch (variant) {
      case "read only":
      case "success":
        return `
          background-color: ${theme.colors.success};
          color: ${theme.colors.white};
          font-size: ${remCalc(14)};
        `;

      case "error":
        return `
            background-color: ${theme.colors.error};
            color: ${theme.colors.white};
            font-size: ${remCalc(14)};
          `;

      case "save":
        return `
          background-color: ${theme.masterColors.warning};
          color: ${theme.colors.black};
          font-size: ${remCalc(14)};
          `;

      case "new":
        return `
          background-color: ${theme.colors.primary};
          color: ${theme.colors.white};
          font-size: ${remCalc(14)};
          `;

      default:
        return `
        background-color: ${theme.colors.darkGrey};
        color: ${theme.colors.white};
      `;
    }
  }}
`;

export const ToastIcon = styled.i`
  font-size: ${remCalc(24)};
  margin-right: ${remCalc(19.41)};
`;

export const ErrorIcon = styled(({ ...props }) => (
  <WarningIcon {...props} classes={{ root: "root" }} />
))`
  &&.root {
    margin-right: ${remCalc(19.41)};
  }
`;
