import React from "react";
import ReportFeedbackForm from "components/forms/ReportFeedbackForm-Unused";

export interface SubmitFeedbackFormProps {
  submitFeedbackVisible: boolean;
  toggleSubmitFeedbackVisibility: () => void;
}

const SubmitFeedbackForm = ({ submitFeedbackVisible, toggleSubmitFeedbackVisibility }: SubmitFeedbackFormProps) => {
  return (
    <>
      <ReportFeedbackForm
        submitFeedbackVisible={submitFeedbackVisible}
        toggleSubmitFeedbackVisibility={toggleSubmitFeedbackVisibility}
      />
    </>
  );
};

export default SubmitFeedbackForm;
