import React from "react";

import { tabA11yProps } from "components/common/Tabs";

import { VerticalTab, VerticalTabs } from "./VerticalTabs";
import SidebarIntro from "../baseUi/sidebar/SidebarIntro";

type SettingsTabsProps = {
  currentTab: number;
  onChangeTab: (tabIndex: number) => void;
}

const SettingsTabs = ({ currentTab, onChangeTab }: SettingsTabsProps) => (
  <VerticalTabs value={currentTab} onChange={(e, value) => onChangeTab(value)}>
    <VerticalTab
      {...tabA11yProps(0)}
    >
      <SidebarIntro 
        active={currentTab === 0}
        direction="left"
        label="Form Settings"
        detail="Form properties and group access"
      />
    </VerticalTab>
    <VerticalTab 
      {...tabA11yProps(1)}
    >
      <SidebarIntro 
        active={currentTab === 1}
        direction="left"
        label="Additional Actions"
        detail="Customize form actions within the app"
      />
    </VerticalTab>
  </VerticalTabs>
);

export default SettingsTabs;
