import { useSelector } from "react-redux";

import { UserRole } from "store/user/types";
import { AppState } from "store";

/**
 * Wrap content with this.
 * Initial goal was to make this more intelligent and still allow write access
 * if you're client reviewer but also client admin, architect, etc. Keeping it dumb
 * until requested -- GK
 * 
 * @component -> If user is a client reviewer, render this if available
 *
 */


interface Props {
  children: JSX.Element;
  component?: JSX.Element;
}

const ReadOnlyContent = ({ component, children }: Props) => {
  // Get user roles
  const userRoles: UserRole[] = useSelector((state: AppState) => 
    state.user?.data?.authorities.map((e) => e.authority) || []);
  
  // Is user a Client Reviewer
  const isClientReviewer = userRoles.includes("ROLE_CLIENT_REVIEWER");

  if (isClientReviewer) { // Reverse this check to test Client Reviewer -- GK
    if (component) {
      return component;
    }
    return null;
  }
  return children;
};

export default ReadOnlyContent;
