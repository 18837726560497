import React from "react";

import * as S from "components/screens/documents/styles";
import DrawerContentWrapper from "components/common/Drawer/components/DrawerContentWrapper";
import ShareForm from "components/forms/ShareForm";
import StickyHeader from "components/common/Drawer/components/StickyHeader";
import { DocumentSummary, DocumentVM } from "store/documents/types";

export interface Props {
  selectedDocument: DocumentSummary | DocumentVM | null;
  shareDocumentVisible: boolean;
  toggleShareDocumentVisibility: () => void;
}

const ShareDocumentSidebar = ({ selectedDocument, shareDocumentVisible, toggleShareDocumentVisibility }: Props) => {
  return (
    <>
      {/* Header */}
      <StickyHeader
        title="Share"
        onClick={toggleShareDocumentVisibility}
        chevronRight={false}
      />
      {/* Content */}
      <DrawerContentWrapper>
        <S.SidebarDrawerHeader>Share</S.SidebarDrawerHeader>
        <S.SidebarDrawerBody>
          Do you want to invite a coworker or manager from your company? When sharing, they’ll be allowed to view the
          document and all of your selections.
        </S.SidebarDrawerBody>
        <ShareForm
          selectedDocument={selectedDocument}
          shareDocumentVisible={shareDocumentVisible}
          toggleShareDocumentVisibility={toggleShareDocumentVisibility}
        />
      </DrawerContentWrapper>
    </>
  );
};

export default ShareDocumentSidebar;
