import styled from "styled-components";

import { remCalc } from "themes/helpers";

import Text from "../Text";

// ---
// ? Menu
// ---

export const Menu = styled.div`
  width: 100%;
`;

// ---
// ? MenuItem
// ---
export const MenuItem = styled.button`
  background: none;
  border: none;

  display: flex;
  padding: 19px 53px 19px 16px;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${({ theme }) => theme.masterColors.lightGrey};

  &:hover {
    cursor: pointer;
  }
`;
export const Label = styled(Text)`
  font-size: ${remCalc(14)};
`;
export const ChevronRight = styled.span.attrs({
  className: "icon-icons8-chevron-left",
})`
  transform: rotate(180deg);
  font-size: 1.35rem;
`;

export const Pencil = styled.span.attrs({
  className: "icon-icons8-pencil_fill",
})`
  font-size: 1.25rem;
`;

export const PhoneSettings = styled.span.attrs({
  className: "icon-icons8-phone_settings",
})`
  font-size: 1.35rem;
`;

export const Icon = styled.div`
  display: inline-block;
  margin-right: 20px;
`;

export const Left = styled.div`
  display: flex;
`;

// ---
// ? MenuDivider
// ---
export const MenuDivider = styled.div`
  background-color: ${({ theme }) => theme.masterColors.lightWhite};
`;
export const DividerTitle = styled(Text)`
  font-size: ${remCalc(12)};
  padding: 24px 16px;
  margin: 0;
  text-transform: uppercase;
  letter-spacing: ${remCalc(1.88)};
`;
