import React, { FC } from "react";
import {
  PaginationParams,
  Pagination as PaginationType,
} from "store/common/types";
import Pagination, {
  PaginationWrapper,
} from "components/common/TableUI/Pagination";
import RowsPerPage from "components/common/TableUI/RowsPerPage";
import BackToTop from "components/common/TableUI/BackToTop";

interface Props {
  params: PaginationParams;
  paginationValues: PaginationType;
  onUpdateParams: (param: Partial<PaginationParams>) => void;
}

const ResourceCategoryTablePagination: FC<Props> = ({
  params,
  paginationValues,
  onUpdateParams,
}) => {
  return (
    <PaginationWrapper>
      <RowsPerPage
        pageSize={params.size}
        onClick={(size) => onUpdateParams({ size })}
      />
      <Pagination
        currentPage={params.page}
        totalPages={paginationValues.totalPages}
        onClick={(page) => onUpdateParams({ page })}
      />
      <BackToTop />
    </PaginationWrapper>
  );
};

export default ResourceCategoryTablePagination;
