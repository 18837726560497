import React, { ChangeEvent, useState, useRef } from "react";
import { css } from "aphrodite";

import Label from "../Label";
import RadioButton from "../RadioButton/RadioButton";
import { styles } from "./styles";
import { default as textInputStyles } from "components/common/form/TextInput/futureUiKit/styles";
import { useThemedStyleSheetWithProps } from "util/hooks/useThemedStyleSheet";
import { Input } from "../types";

export interface RadioButtonOption {
  disabled?: boolean;
  label: string;
  value: string;
}

interface RadioButtonsGroupProps extends Input {
  helperText?: string;
  label: string;
  name: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  options: RadioButtonOption[];
  row?: boolean;
  value: string;
  variant?: "standard" | "pills";
  disabled?: boolean;
}

let uniqueId = 0;
const getUniqueId = (): number => {
  return uniqueId++;
};

const useId = (prefix: string): string => {
  const idRef = useRef<number | null>(null);
  if (idRef.current === null) {
    idRef.current = getUniqueId();
  }
  return `${prefix}-${idRef.current}`;
};

const RadioButtonsGroup = ({
  helperText,
  label,
  name,
  onChange,
  options,
  required,
  row,
  value,
  variant,
  disabled,
}: RadioButtonsGroupProps) => {
  // const [selection, setSelection] = useState<string>(value);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    // setSelection(event.target.value);
    if (onChange) {
      onChange(event);
    }
  };

  const radioGroupId = useId(name);

  const textInputStylesThemed = useThemedStyleSheetWithProps(
    textInputStyles,
    {}
  );

  return (
    <div>
      {label && (
        <Label htmlFor={radioGroupId} required={required}>
          {label}
        </Label>
      )}
      {helperText && (
        <span className={css(textInputStylesThemed.helperText)}>
          {helperText}
        </span>
      )}
      <div
        aria-labelledby={label}
        className={css(styles.radioGroup, row ? styles.row : styles.column)}
        id={radioGroupId}
        role="radiogroup"
      >
        {options?.map((option) => (
          <div
            className={css(row ? styles.rowItem : styles.columnItem)}
            key={option.value}
          >
            <RadioButton
              checked={value === option.value}
              disabled={option.disabled}
              formDisabled={disabled}
              onChange={handleChange}
              label={option.label}
              name={name}
              value={option.value}
              variant={variant}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default RadioButtonsGroup;
