import styled from "styled-components";

import { remCalc } from "themes/helpers";
import Text from "components/common/Text";

export const ButtonRow = styled.div`
  display: flex;
  align-items: center;
`;

export const Label = styled(Text)<{ cancel?: boolean }>`
  color: ${({ theme, cancel }) => (cancel ? theme.colors.error : theme.masterColors.white)};
  font-size: ${remCalc(14)};
`;

export const CancelButton = styled.button`
  background: none;
  border-radius: 4px;
  border: none;
  font-size: 0.875rem;
  font-weight: 500;
  height: 36px;
  letter-spacing: 1.25px;
  margin-left: 24px;
  width: 106px;
  /* Matching value from src/components/forms/Button */
  margin-top: 2.125rem;

  &:hover {
    cursor: pointer;
  }
`;
