import React, { FC, useEffect } from "react";
import { Field, useFormikContext } from "formik";
import { css } from "aphrodite/no-important";

import Select from "components/common/form/Select";
import TextInput from "components/common/form/TextInput";

import styles from "../styles";
import { FBForm } from "../../types";

interface Props {
  disabled?: boolean;
}

const TimeLimitOption: FC<Props> = ({ disabled }) => {
  const { values, setFieldValue } = useFormikContext<FBForm>();
  const s = styles();

  useEffect(() => {
    if (values.formSubmissionConstraint?.type === "HOUR") {
      if (!values.formSubmissionConstraint?.hourLimit) {
        setFieldValue("formSubmissionConstraint.hourLimit", "24");
      }
    }
  }, [values.formSubmissionConstraint?.type]);

  return (
    <div className={css(s.editTimeframeOption)}>
      Time limit of
      <Field
        name="formSubmissionConstraint.hourLimit"
        variant="standard"
        as={TextInput}
        value={values.formSubmissionConstraint?.hourLimit || "24"}
        className={css(s.timeLimitInput)}
        disabled={disabled}
      />
      <Field
        as={Select}
        className={css(s.timeLimitUnit)}
        name="formSubmissionConstraint.hourType"
        value={values.formSubmissionConstraint?.hourType || "hours"}
        options={[
          { id: "hours", value: "hours" },
          { id: "days", value: "days" },
        ]}
        disabled={disabled}
      />
    </div>
  );
};

export default TimeLimitOption;
