import React from "react";
import { CSSProperties } from "react";
import { StyleSheet } from "aphrodite";

import { remCalc } from "../../../../../themes/helpers";
import { ThemeContext } from "styled-components";

interface Props {
  disabled?: boolean;
  rounded?: boolean;
}

export default function({ disabled }: Props): { [key: string]: CSSProperties } {
  const theme = React.useContext(ThemeContext);
  return StyleSheet.create({

    errorText: {
      display: "block",
      color: theme.colors.error,
      fontSize: remCalc(12),
      margin: `${remCalc(3)} ${remCalc(5)} 0`,
    },

    helperText: {
      display: "block",
      color: "#666666",
      fontSize: remCalc(12),
      margin: `${remCalc(3)} ${remCalc(5)} 0`,
    },

    textarea: {
      backgroundColor: theme.colors.fieldInputBackground,
      border: `1px solid ${disabled ? theme.masterColors.mediumGrey : theme.masterColors.black}`,
      borderRadius: remCalc(4),
      color: theme.colors.formFieldColor,
      margin: remCalc(1), // prevents "shifting" on focus
      ":hover": {
        border: `1px solid ${theme.colors.primary}`,
      },
      ":focus": {
        border: `2px solid ${theme.colors.primary}`,
        margin: 0,
      },
      padding: remCalc(16),
      "::placeholder": {
        color: theme.masterColors.mediumGrey,
      },
      resize: "none",
      ".error": {
        border: `2px solid ${theme.colors.error}`,
      },
      width: "100%",
    },

    wrapper: {
      display: "flex",
      flexDirection: "column",
    },
  });
}
