import styled from "styled-components";

import LabelBase from "components/forms/Label";

export const Label = styled(LabelBase)<{ locked?: boolean }>`
  ${({ locked, theme }) => {
    if (locked) {
      return `
        .Label__Text {
          color: ${theme.masterColors.mediumGrey} !important;
          margin: 0;
          padding: 6.5px 0px 0px 0px;
        }
      `;
    }
  }}
`;

export const LabelRow = styled.div`
  display: flex;
  margin-bottom: 11.5px;
`;
