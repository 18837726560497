import React from "react";
import { Link } from "react-router-dom";

import * as S from "../styles";

interface Props {
  userInitials: string;
  name: string;
  handleNavigateToUserProfile: () => void;
}

const MenuHeader = React.forwardRef<HTMLDivElement, Props>((
  { userInitials, name, handleNavigateToUserProfile },
  ref
) => {
  return (
    <S.MenuHeader ref={ref}>
      <S.Col>
        <S.UserMenuInitial inMenu>{userInitials}</S.UserMenuInitial>
      </S.Col>
      <S.Col>
        <S.Username as="p">{name}</S.Username>
        <Link to="#" onClick={handleNavigateToUserProfile}>
          <S.LinkText as="p">View Profile</S.LinkText>
        </Link>
      </S.Col>
    </S.MenuHeader>
  );
});

export default MenuHeader;
