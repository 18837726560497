import React, { ReactNode } from "react";
import styled from "styled-components";
import { connect } from "react-redux";

import { logout } from "store/system/actions";

const Wrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  width: 100vw;
  height: 100vh;
`;

const LogoutLink = styled.span`
  color: steelblue; // Don't theme this yet -- GK
  cursor: pointer;
`;

interface StateType {
  error: string | null;
  errorInfo: string | null;
}

class ErrorBoundary extends React.Component<
  { logout: any; children?: ReactNode },
  StateType
> {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  componentDidCatch(error, errorInfo) {
    // Catch errors in any components below and re-render with error message
    this.setState({
      error: error,
      errorInfo: errorInfo,
    });
  }

  render() {
    if (this.state.errorInfo) {
      // Error path
      return (
        <Wrapper>
          <h2>
            Something went wrong. Click{" "}
            <LogoutLink onClick={() => this.props.logout()}>
              here&nbsp;
            </LogoutLink>
            to logout and refresh.
          </h2>
        </Wrapper>
      );
    }
    // Normally, just render children
    return this.props.children;
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    logout: () => dispatch(logout()),
  };
};

export default connect(null, mapDispatchToProps)(ErrorBoundary);
