import React, { ButtonHTMLAttributes } from "react";
import styled from "styled-components";

import { toRgba } from "themes/helpers";

import BaseLoader, { LoaderProps as BaseLoaderProps } from "../Loader";
import { CircularProgress } from "../Loader/styles";

type ButtonType = {
  disabled?: boolean;
  loading?: boolean;
};

export const Button = styled<React.FC<ButtonHTMLAttributes<HTMLButtonElement> & ButtonType>>(({
  disabled,
  loading,
  ...otherProps
}) => {
  return <button {...otherProps} />;
})`
  align-items: center;
  background-color: ${({ disabled, theme }) => (disabled ? toRgba(theme.colors.black, 0.12) : theme.colors.primary)};
  border-radius: 4px;
  border: none;
  color: ${({ disabled, theme }) => (disabled ? toRgba(theme.colors.black, 0.26) : theme.colors.white)};
  display: inline-flex;
  flex-direction: row;
  font-size: 0.875rem;
  font-weight: 500;
  letter-spacing: 1.25px;
  margin-bottom: 1rem;
  margin-top: 1rem;
  padding: ${({ loading }) => (loading ? "8px 35px 8px 14px" : "8px 35px")};
  position: relative;
  text-transform: uppercase;
`;

export const ButtonText = styled.div`
  flex-shrink: 0;
`;

type TextButtonType = ButtonType & {
  variant?: "cancel" | "link";
  disabled?: boolean;
};

export const TextButton = styled(Button)<TextButtonType>`
  background-color: transparent;
  color: ${({ variant, disabled, theme }) =>
    disabled ? theme.colors.mediumGray : variant === "cancel" ? theme.colors.error : theme.colors.primary};
  
  ${({ variant }) => variant === "link" && `
    text-decoration: underline;
    font-weight: 400;
    text-transform: none;
    padding: 0;
    margin: 0;
    font-size: inherit;
  `}
`;

export const Loader = styled<React.FC<BaseLoaderProps>>(({ ...otherProps }) =>
  <BaseLoader
    spinnerProps={{ size: 14 }}
    {...otherProps}
  />
)`
  margin-right: 0.6rem;
  padding: 0;

  ${CircularProgress} {
    color: ${({ theme }) => theme.colors.white}
  }
`;
