import React from "react";
import { FormDTO, isQuestionDTO, OEWidgetDTO } from "../../store/forms/types";
import { TagDTO } from "../../store/tagLibrary/types";
import { DocumentQuestionResponseVm } from "../../store/documents/types";

// since Map shouldn't be used in state, use restricted object
type FormTagsMapType = { [key: number]: string[] };

// logic for creating a map between question/selection ids and their tags
function calculateFormTags(form: FormDTO, widget: OEWidgetDTO): FormTagsMapType {
  const m = new Map<number, Set<string>>();

  function addToMap(id: number, tags: TagDTO[] | undefined, suppressedTags: Set<string>) {
    const filteredTags = (tags || []).map((t) => t.tagIdentifier || "")
      .filter((t) => t && !suppressedTags.has(t));
    m.set(id, new Set<string>(filteredTags));
  }

  for (const section of form.sections) {
    for (const item of section.items) {

      if (isQuestionDTO(item)) {
        // collect suppressed tags
        const suppressedTags = (widget.questions || []).filter((q) => q.questionRootId === item.rootId)
          .reduce((prev, curr) => prev.concat(curr.suppressedTags), [] as string[])
          .reduce((prev, curr) => prev.add(curr), new Set<string>());

        // add question tags
        addToMap(item.id, item.tags, suppressedTags);

        // add selection tags
        for (const selection of (item.selections || [])) {
          addToMap(selection.id, selection.tags, suppressedTags);
        }
      }
    }
  }
  const o: FormTagsMapType = {};
  for (const entry of m.entries()) {
    o[entry[0]] = Array.from(entry[1]);
  }
  return o;
}

// helper method to check if two string sets are the same
function equalSets(a: Set<string>, b: Set<string>): boolean {
  if (a.size !== b.size) {
    return false;
  }
  for (const v of a) {
    if (!b.has(v)) {
      return false;
    }
  }
  for (const v of b) {
    if (!a.has(v)) {
      return false;
    }
  }
  return true;
}

// logic for getting the tags from the responses
function calculateSelectedTags(
  responses: Array<DocumentQuestionResponseVm>,
  formTags: FormTagsMapType,
): Set<string> {
  const s = new Set<string>();
  for (const response of responses) {
    formTags[response.questionId]?.forEach((t) => s.add(t));
    if (response.associatedId) {
      formTags[response.associatedId]?.forEach((t) => s.add(t));
    }
  }
  return s;
}

/**
 * Hook for getting tags from questions answered by the user
 */
export function useSelectedTags(
  form: FormDTO,
  widget: OEWidgetDTO,
  responses: Array<DocumentQuestionResponseVm>,
): string[] {
  const formTags = React.useMemo(() => calculateFormTags(form, widget), [form, widget]);
  const [selectedTags, setSelectedTags] = React.useState<string[]>(() =>
    Array.from(calculateSelectedTags(responses, formTags))
  );

  React.useEffect(() => {
    const newTags = calculateSelectedTags(responses, formTags);
    if (!equalSets(new Set<string>(selectedTags), newTags)) {
      setSelectedTags(Array.from(newTags));
    }
  }, [formTags, responses]);
  return selectedTags;
}

/**
 * Hook for get tags that are only updated after the document has been submitted (auto-synced)
 * Stupid complex, but basically the tags move from selectedTags to submittingTags to submittedTags
 */
export function useSubmittedSelectedTags(
  selectedTags: string[],
  isSubmitting: boolean,
  submitCount: number
): string [] {
  const [submittingTags, setSubmittingTags] = React.useState<string[]>(selectedTags);
  const [submittedTags, setSubmittedTags] = React.useState<string[]>(selectedTags);
  const [lastSubmitCount, setLastSubmitCount] = React.useState<number>(0);

  React.useEffect(() => {
    if (isSubmitting && submitCount !== lastSubmitCount) {
      setSubmittingTags(selectedTags);
      setLastSubmitCount(submitCount);
      return;
    }
    if (isSubmitting && submitCount === lastSubmitCount) {
      return;
    }
    setSubmittedTags(submittingTags);
  }, [
    selectedTags,
    isSubmitting,
    submitCount,
    lastSubmitCount,
    submittingTags,
    setSubmittingTags,
    setSubmittedTags,
    setLastSubmitCount
  ]);

  return submittedTags;
}
