import React from "react";
import * as R from "ramda";

import styled from "styled-components";
import IconButtonBase from "@material-ui/core/IconButton";
import MoreVertBase from "@material-ui/icons/MoreVert";
import AvatarBase from "@material-ui/core/Avatar";
import ListItemBase from "@material-ui/core/ListItem";

import { remCalc } from "themes/helpers";
import mediaQueries from "themes/mediaQueries";

// --------------------------------------------------------------------------
// ? List.tsx
// --------------------------------------------------------------------------

export const Avatar = styled(({ color, ...rest }) => <AvatarBase {...rest} classes={{ root: "root" }} />)`
  &.root {
    background-color: ${(props) =>
    props.color && props.color.length > 0
      ? R.pathOr("red", props.color, props.theme)
      : props.theme.masterColors.lightGrey};
    border-radius: 4px;
    font-size: 2rem;
  }
`;

export const BorderList = styled.div<{ bottomBorder?: boolean }>`
  margin-right: -15px;
  padding-right: 5px;
  border-bottom: ${({ theme, bottomBorder }) => bottomBorder && `1px solid ${theme.masterColors.lightGrey}`};

  &:hover {
    cursor: pointer;
  }
`;

export const ListItem = styled(({ ...props }) => <ListItemBase {...props} />)``;

export const List = styled.div`
  width: 100%;
`;

// --------------------------------------------------------------------------
// ? DocumentsList.tsx
// --------------------------------------------------------------------------
export const NewDocumentIcon = styled("i")<{ iconColor?: string | undefined }>`
  color: ${({ theme }) => {
    return theme.colors.white;
  }};

  background-color: ${({ theme }) => theme.colors.primary};
  width: ${remCalc(40)};
  height: ${remCalc(40)};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: ${remCalc(4)};
  font-size: 2rem;
`;

export const DocumentList = styled.ul`
  list-style: none;
  width: 100%;
  padding: 0px;
`;
export const DocumentListItem = styled.li`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  height: ${remCalc(64)};
  padding: ${remCalc(8)};
  padding-left: 0px;

  border-bottom: ${({ theme }) => `1px solid ${theme.masterColors.lightGrey}`};

  &:last-of-type {
    border-bottom: none;
  }

  &:hover {
    cursor: pointer;
    background-color: ${({ theme }) => theme.colors.lightGrey};
  }
`;

export const PrimaryText = styled.span`
  font-family: "Roboto", sans-serif;
  color: ${({ theme }) => theme.masterColors.black};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 200px; /* MOBILE DEFAULT */

  @media (${mediaQueries.minTablet}) {
    max-width: 350px;
  }

  @media (${mediaQueries.minDesktop}) {
    max-width: 550px;
  }
`;
export const SecondaryText = styled.span`
  font-family: "Roboto", sans-serif;
  color: ${({ theme }) => theme.masterColors.darkGrey};
  font-size: ${remCalc(14)};

  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 90px; /* MOBILE DEFAULT */

  @media (${mediaQueries.minTablet}) {
    max-width: 350px;
  }

  @media (${mediaQueries.minDesktop}) {
    max-width: 550px;
  }
`;

export type LeftVariants = "DEFAULT" | "AVATAR";

export const Left = styled.div<{ variant: LeftVariants }>`
  ${({ variant }) => {
    if (variant === "AVATAR") {
      return `
      display: flex;
      justify-content: center;
      align-items: center;
      flex-grow: ${remCalc(63)};
      flex-shrink: 0;
      `;
    }
  }}
`;
export const Middle = styled.div`
  flex-basis: 100%;
  flex-shrink: 100%;
  flex-grow: 100%;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  align-content: flex-end;

  margin-left: ${remCalc(13)};
`;

export type RightVariants = "DEFAULT" | "MORE-ICON";

export const Right = styled.div<{ variant: RightVariants }>`
  ${({ variant }) => {
    if (variant === "MORE-ICON") {
      return `
        display: flex;
        justify-content: center;
        align-items: center;
      `;
    }
  }}
`;

export const IconButton = styled(({ ...props }) => <IconButtonBase {...props} />)`
  :focus {
    outline: 0;
  }
`;

export const MoreVert = styled(({ ...props }) => <MoreVertBase {...props} />)``;

export const DocumentIcon = styled("i")<{ iconColor?: string | undefined }>`
  color: ${(props) => {
    return props.iconColor;
  }};
`;

export const SecondaryTextWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-right: ${remCalc(26)};
`;

export const PrimaryTextWrapper = styled.div`
  max-width: 60vw;
`;
