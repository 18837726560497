import React from "react";

import * as S from "./styles";

export type StyleButtonProps = {
  active?: boolean;
  disabled?: boolean;
  label: string | React.ReactNode;
  onToggle: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
};

const StyleButton = ({ active, disabled, label, onToggle }: StyleButtonProps) => {
  const handleToggle = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    e.stopPropagation();
    onToggle(e);
  };

  const handleMouseDown = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    // prevent the button from stealing focus
    e.preventDefault();
  };

  return (
    <S.StyleButton
      className="wysiwyg-style-button"
      disabled={disabled}
      type="button"
      active={active}
      onMouseDown={handleMouseDown}
      onClick={handleToggle}
    >
      {label}
    </S.StyleButton>
  );
};

export default StyleButton;
