import { CALL_API } from "middleware/api";

import { SUBMIT_FEEDBACK } from "./types";

//Submit Feedback
export const submitFeedback = (body) => (dispatch, getState) => {
  const { system } = getState();
  const endpoint = "admin/feedback";
  return dispatch({
    [CALL_API]: {
      endpoint,
      options: {
        method: "POST",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${system.authToken}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      },
      types: [SUBMIT_FEEDBACK.REQUEST, SUBMIT_FEEDBACK.SUCCESS, SUBMIT_FEEDBACK.FAILURE],
    },
  });
};
