import styled from "styled-components";

import { remCalc } from "themes/helpers";
import TextBase from "components/common/Text";

const commonTextStyles = `
  margin: 0;
  padding: 0;
`;

export const Title = styled.div`
  margin: 14px 0px 16px 0px;
`;

export const Heading = styled(TextBase)`
  ${commonTextStyles}
  font-size: ${remCalc(20)};
  font-weight: 500;
  margin-bottom: 2px;
  letter-spacing: 0.26px;
`;

export const SubHeading = styled(TextBase)`
  ${commonTextStyles}
  font-size: ${remCalc(14)};
  font-weight: 400;
`;
