import {
  UPDATE_SESSION,
  LOGIN,
  CLEAR_ERROR,
  CLEAR_TOKEN,
  CLEAR_STATUS,
  REQUEST_RESET,
  PASSWORD_RESET,
  SystemState,
  SystemActionTypes,
} from "./types";

import { GET_USER } from "../user/types";

const jwtDecode = require("jwt-decode");

const initialState: SystemState = {
  accountLocked: false,
  authToken: null,
  decodedAuthToken: null,
  error: null,
  isLoading: false,
  loggedIn: false,
  recaptchaNeeded: false,
  resetPassword: false,
  resetRequestStatus: null,
  resetStatus: null,
  session: "",
  userName: "",
};

function errorMessage(status: number, accountLocked: boolean): string {
  if (status === 401 || status === 404) {
    if (accountLocked) {
      return "Your account has been locked.";
    }
    return "Wrong username or password.";
  }
  return "An error occurred.";
}

export function systemReducer(state = initialState, action: SystemActionTypes): SystemState {
  switch (action.type) {
    case UPDATE_SESSION: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case LOGIN.REQUEST:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case LOGIN.SUCCESS:
      return {
        ...state,
        accountLocked: false,
        authToken: action.response.id_token,
        decodedAuthToken: jwtDecode(action.response.id_token),
        error: null,
        recaptchaNeeded: false,
        resetPassword: false,
      };
    case LOGIN.FAILURE:
      return {
        ...state,
        accountLocked: action.fullError.accountLocked,
        error: errorMessage(action.status, action.fullError.accountLocked),
        isLoading: false,
        loggedIn: false,
        recaptchaNeeded: action.fullError.recaptchaNeeded,
        resetPassword: action.fullError.resetPassword,
      };
    case GET_USER.SUCCESS:
      return {
        ...state,
        loggedIn: true,
        isLoading: false,
        error: null,
      };
    case GET_USER.FAILURE:
      return {
        ...state,
        isLoading: false,
        loggedIn: false,
        error: "An error occurred while fetching user profile.",
      };
    case REQUEST_RESET.REQUEST:
      return {
        ...state,
        resetRequestStatus: "request",
      };
    case REQUEST_RESET.SUCCESS:
      return {
        ...state,
        resetRequestStatus: "success",
      };
    case REQUEST_RESET.FAILURE:
      return {
        ...state,
        resetRequestStatus: "failure",
      };
    case PASSWORD_RESET.REQUEST:
      return {
        ...state,
        resetStatus: "request",
      };
    case PASSWORD_RESET.SUCCESS:
      return {
        ...state,
        resetStatus: "success",
      };
    case PASSWORD_RESET.FAILURE:
      return {
        ...state,
        resetStatus: "failure",
      };
    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
      };
    case CLEAR_TOKEN:
      return {
        ...state,
        loggedIn: false,
        authToken: null,
      };
    case CLEAR_STATUS:
      return {
        ...state,
        resetRequestStatus: null,
        resetStatus: null,
      };
    case "OAUTH_LOGIN":
      return {
        ...state,
        authToken: action.authToken,
      };
    default:
      return state;
  }
}
