import React, { useState } from "react";

import * as S from "./styles";
import { AssistiveLink } from "../types";
import { StyledAsterisk } from "components/forms/Label/Label";

export interface LabelProps {
  htmlFor: string;
  children: React.ReactNode;
  assistiveLink?: AssistiveLink | null;
  noMargin?: boolean;
}

// used to help conditionally render the correct element
// if onclick is provided, Assistive Link element will be a button
// if url is provided, it will be an anchor element
function RenderAssistiveLink({ assistiveLink }: { assistiveLink: AssistiveLink }) {
  const [isMouseFocused, setIsMouseFocused] = useState(false);

  const handleBlur = () => {
    setIsMouseFocused(false);
  };

  const handleClick = () => {
    if (assistiveLink.onClick) {
      assistiveLink.onClick(isMouseFocused);
    }
  };

  const handleMouseDown = () => {
    setIsMouseFocused(true);
  };

  if (assistiveLink.onClick) {
    return (
      <S.AssistiveLinkButton
        isMouseFocused={isMouseFocused}
        onBlur={handleBlur}
        onClick={handleClick}
        onMouseDown={handleMouseDown}
        type="button"
      >
        {assistiveLink.icon && (
          <S.AssistiveIcon
            className={`icon icon-icons8-${assistiveLink.icon}`}
          />
        )}
        {assistiveLink.label}
      </S.AssistiveLinkButton>
    );
  }

  if (assistiveLink.url) {
    return (
      <S.AssistiveLink href={assistiveLink.url}>
        {assistiveLink.icon && <S.AssistiveIcon className={`icon icon-icons8-${assistiveLink.icon}`} />}
        {assistiveLink.label}
      </S.AssistiveLink>
    );
  }

  return null;
};

interface Props {
  assistiveLink?: AssistiveLink;
  children: React.ReactNode;
  className?: string;
  htmlFor: string;
  noMargin?: boolean;
  required?: boolean;
}

const Label = ({ htmlFor, children, assistiveLink, noMargin, className, required }: Props) => {
  // If question is required, add a styled asterisk
  const requiredAsterisk = required ? <StyledAsterisk> *</StyledAsterisk>: "";
  return (
    <S.Label assistiveLink={!!assistiveLink} className={`fieldLabel${className ? ` ${className}` : ""}`}>
      <S.FieldLabel htmlFor={htmlFor} noMargin={noMargin}>
        {children}{requiredAsterisk}
      </S.FieldLabel>
      {assistiveLink && <RenderAssistiveLink assistiveLink={assistiveLink} />}
    </S.Label>
  );
};

export default Label;
