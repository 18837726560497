import DisabledTextField from "components/common/form/DisabledTextField";
import { InputDescription } from "components/common/form/InputDescription";
import { FieldErrorType } from "components/FormController/components/Question";
import Label from "components/forms/Label";
import { get } from "lodash";
import moment from "moment";
import React, { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { AppState } from "store";
import { getDataSourceValueByIdKey } from "store/dataSource/actions";
import { valuesForDataSourceKey } from "store/dataSource/selectors";
import {
  DataSourceValueDTO,
  ParticipantProps as ParticipantDTO,
} from "store/dataSource/types";
import { DocumentQuestionResponseVm } from "store/documents/types";
import { currentUser, currentUserFullName } from "store/user/selectors";
import { UsersMe } from "store/user/types";
import { WorkLocationsState } from "store/workLocations/types";
import useDebouncedValue from "../../../util/hooks/useDebouncedValue";
import DataSourceDrawer from "../DataSourceDrawer";
import {
  DataSourceItem,
  WRITE_IN_ID,
} from "../ItemSelectorDrawer/ItemSelectorForm/ItemSelectorForm";
import { MultiResponseQuestionProps } from "../types";
import * as S from "./styles";

const renderParticipantLabel = (participant: DataSourceValueDTO) => {
  const name = get(participant, "content.name", "");
  return get(participant, "content.nameAndTitle", name);
};

export interface ParticipantProps extends MultiResponseQuestionProps {
  error?: FieldErrorType;
  label: string;
  selectors: {
    valuesForDataSourceKey: Array<DataSourceValueDTO>;
    currentUser: UsersMe | null;
    currentUserFullName?: string;
  };
  handleBlur?: () => void;
  onLoadMore?: (query?: string) => void;
  isLoadingMore?: boolean;
  isFinalPage?: boolean;
  removeParticipant: (id: number) => void;
}

/**
 * Fetch a participant's data source and return the full name of the participant
 * @param participantId number
 * @param dispatch
 */
const getParticipantLabel = async (
  participantId: number,
  dispatch: ThunkDispatch<null, void, Action>
): Promise<string> => {
  const participantRes =
    participantId && dispatch
      ? await dispatch(getDataSourceValueByIdKey(participantId, "PARTICIPANT"))
      : null;

  return participantRes?.response?.content?.nameAndTitle || "";
};

/**
 * **filterSelectedParticipantOptions** handles filtering and sorting
 * participants which have already been selected for this field
 * then filters to match the @filterValue from the text input
 * when searching using auto-complete
 */
export const filterSelectedParticipantOptions = ({
  participants,
  selectedParticipants,
}: {
  participants: Array<DataSourceValueDTO>;
  selectedParticipants: Array<number>;
}): Array<DataSourceValueDTO> => {
  if (!participants || participants.length === 0) {
    return [];
  }
  return participants.filter((x) => !selectedParticipants.includes(x.id));
};

const ParticipantField = ({
  error,
  label,
  question,
  responses,
  selectors,
  setQuestionResponses,
  onLoadMore,
  isLoadingMore,
  isFinalPage,
  removeParticipant,
}: ParticipantProps) => {
  const isRequired = question.formProperties?.isRequired;
  const questionProperties = question.properties;
  const { answerSource } = question;

  const [currentValue, setCurrentValue] = React.useState("");
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const [popperOpen, setPopperOpen] = React.useState(false);

  const debouncedQuery = useDebouncedValue(currentValue, 500);
  /* search dsv on query change */
  useEffect(() => {
    if (onLoadMore && debouncedQuery && debouncedQuery.length > 2) {
      (async () => {
        onLoadMore(debouncedQuery);
      })();
    }
  }, [debouncedQuery]);

  useEffect(() => {
    setPopperOpen(!!currentValue.length);
  }, [currentValue]);

  const assistiveLinkLabel = get(
    answerSource,
    "properties.detailedSearch.linkName",
    "add more"
  );

  const textInputPlaceholder = get(
    questionProperties,
    "placeHolderText",
    question.title
  );
  const textInputAssistiveText = get(questionProperties, "assistiveText", "");

  // refs for __AUTO_COMPLETE__ UI element
  const MENU_ANCHOR = React.useRef();
  const TEXT_INPUT = React.useRef();

  const dispatch = useDispatch<
    ThunkDispatch<WorkLocationsState, void, Action>
  >();

  /**
   * When user selects a Participant from the AutoComplete Menu
   */
  const handleSelectParticipantFromAutoComplete = async (
    participant: DataSourceValueDTO
  ) => {
    const answer = await getParticipantLabel(participant.id, dispatch);
    let updatedResponses: DocumentQuestionResponseVm[];
    if (question.properties?.allowMultipleAnswers) {
      updatedResponses = [...responses];
    } else {
      updatedResponses = [];
    }
    setQuestionResponses(
      updatedResponses.concat({
        answer,
        associatedId: participant.id,
        associatedRootId: participant.id,
        questionId: question.id,
        questionRootId: question.rootId,
        timeAnswered: moment.utc().format(),
      }),
      [
        {
          participant: participant.content as ParticipantDTO,
          isRemoved: false,
        },
      ]
    );

    return setCurrentValue("");
  };

  const handleWriteInParticipant = () => {
    /**
     * Write-in participants don't have associated IDs so we can't allow
     * duplicate answers. Check if participant already exists in the list of
     * responses before allowing a new response to be added.
     */
    if (
      currentValue &&
      !responses.find((r) => !r.associatedId && r.answer === currentValue)
    ) {
      let updatedResponses: DocumentQuestionResponseVm[];
      if (question.properties?.allowMultipleAnswers) {
        updatedResponses = [...responses];
      } else {
        updatedResponses = [];
      }
      setQuestionResponses(
        updatedResponses.concat({
          answer: currentValue,
          questionId: question.id,
          questionRootId: question.rootId,
          timeAnswered: moment.utc().format(),
        })
      );
    }

    setCurrentValue("");
    setPopperOpen(false);
  };

  /**
   * When user clicks Submit on the ItemSelectorDrawer we'll invoke
   * this function to create the Form values
   * @param participantIds
   */
  const itemSelectorDrawerHandleSubmit = async (
    participants: Array<DataSourceItem>
  ) => {
    /**
     * IF multiple answers allowed
     * THEN Filter out any existing responses for this question except for write-ins.
     * Participants array will have all non write-in responses plus new write-in
     * responses.
     * ELSE Filter all existing responses
     */
    const filteredResponses = responses.filter(
      (res) =>
        res.questionId !== question.id ||
        (!res.associatedId && question.properties?.allowMultipleAnswers)
    );

    const updatedResponses = participants.reduce(
      (allResponses, participant) => {
        if (participant.id === WRITE_IN_ID) {
          if (
            !allResponses.find(
              (existingResponse) =>
                !existingResponse.associatedId &&
                existingResponse.answer === participant.title
            )
          ) {
            // New write-in response received from dataSourceDrawer
            allResponses.push({
              answer: participant.title,
              questionId: question.id,
              questionRootId: question.rootId,
              timeAnswered: moment.utc().format(),
            });
          }
        } else {
          // All non-write in responses will be included
          allResponses.push({
            answer: participant.value || participant.title,
            associatedId: Number(participant.id),
            associatedRootId: Number(participant.id),
            questionId: question.id,
            questionRootId: question.rootId,
            timeAnswered: moment.utc().format(),
          });
        }
        return allResponses;
      },
      filteredResponses
    );

    const updatedParticipants = participants.map((p) => ({
      participant: (p.participant
        ? p.participant
        : (p as unknown)) as ParticipantDTO,
      isRemoved: false,
    }));

    setQuestionResponses(updatedResponses, updatedParticipants);

    setDrawerOpen(false);
  };

  /**
   * When clicking on the :clear: button on a rendered List Item for a
   * Participant, we'll remove the associated Field Value response and
   * update the __FormController__ state
   */
  const handleRemoveParticipant = (
    responseToRemove: DocumentQuestionResponseVm
  ) => {
    responseToRemove.associatedId &&
      removeParticipant(responseToRemove.associatedId);
    setQuestionResponses(
      responses.filter((r) =>
        responseToRemove.associatedId
          ? r.associatedId !== responseToRemove.associatedId
          : r.answer !== responseToRemove.answer
      )
    );
  };

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    if ((e.relatedTarget as HTMLElement)?.getAttribute("tabindex") === "-1") {
      /**
       * If user has clicked a participant from the dropdown list, then don't
       * execute the handleWriteInParticipant function. This event handler will
       * fire before the onClick handler of the participant, so we check the
       * `tabindex` here to do this.
       */
      return;
    }
    handleWriteInParticipant();
  };

  const inputDescriptionId = `description-${question.id}`;

  /* We won't need this if rendering Participant field for the signed in user */
  if (answerSource?.properties.readOnly) {
    return <DisabledTextField label={label} value={responses?.[0]?.answer} />;
  }

  return (
    <S.MultiInput ref={TEXT_INPUT}>
      {question.answerSource && drawerOpen && (
        <DataSourceDrawer
          isOpen={drawerOpen}
          allowWriteIn={true}
          answerSource={question.answerSource}
          onChangeOpen={setDrawerOpen}
          onSubmit={itemSelectorDrawerHandleSubmit}
          allowMultipleAnswers={question.properties?.allowMultipleAnswers}
          selected={responses.reduce((selectedItems, response) => {
            if (response.questionId === question.id && response.associatedId) {
              const selectedItem: DataSourceItem = {
                id: response.associatedId.toString(),
                title: response.answer,
                value: response.answer,
                associatedLocation: response.associatedLocation,
              };

              selectedItems.push(selectedItem);
            }
            return selectedItems;
          }, [] as DataSourceItem[])}
          submitButtonLabel={{
            prefix: "Add",
            label: "Participant",
          }}
          onLoadMore={onLoadMore}
          isFinalPage={isFinalPage}
          isLoadingMore={isLoadingMore}
        />
      )}
      <S.Popper
        open={popperOpen}
        anchorEl={MENU_ANCHOR.current}
        disablePortal={false}
        placement="bottom"
        style={{ zIndex: 7 }}
      >
        <S.Options parentWidth={get(TEXT_INPUT, "current.offsetWidth", 0)}>
          {currentValue && (
            <S.Option onClick={handleWriteInParticipant}>
              Add new participant: {currentValue}
            </S.Option>
          )}
          {currentValue.length > 2 &&
            selectors.valuesForDataSourceKey.map((participant) => {
              return (
                <S.Option
                  onClick={() =>
                    handleSelectParticipantFromAutoComplete(participant)
                  }
                  key={participant.id}
                >
                  {renderParticipantLabel(participant)}
                </S.Option>
              );
            })}
        </S.Options>
      </S.Popper>
      <Label
        htmlFor={`${question.id}`}
        assistiveLink={
          !answerSource?.properties.readOnly
            ? {
                label: assistiveLinkLabel,
                onClick: () => setDrawerOpen(true),
              }
            : null
        }
        required={isRequired}
      >
        {label}
      </Label>
      {responses.length > 0 && (
        <>
          <S.ListItems>
            {responses.map((response) => {
              const { answer } = response;

              return (
                <S.ListItemOption
                  key={answer}
                  variant="participant-selected"
                  renderLabelsAsRow
                  label={answer}
                  tabIndex={-1} // Allow click handler to fire before TextInput blur handler
                  endAdornment={{
                    hideTextDecoration: true,
                    visible: true,
                    type: "button",
                    onClick: () => {
                      handleRemoveParticipant(response);
                    },
                    label: (
                      <S.IconForEndAdornment className="icon icon-icons8-delete_sign" />
                    ),
                  }}
                />
              );
            })}

            <S.TextInput
              name={question.id}
              label="" // hard code label to "" since we're spreading props above
              internalVariant="Participant-Add-Another"
              variant="outlined"
              value={currentValue}
              onBlur={handleBlur}
              autoComplete={!!question.answerSource ? "off" : "on"}
              onChange={({
                currentTarget: { value },
              }: React.FormEvent<HTMLInputElement>) => setCurrentValue(value)}
              placeholder={
                typeof textInputPlaceholder === "string"
                  ? textInputPlaceholder
                  : ""
              }
              error={error}
              hideErrorText
            />
          </S.ListItems>
          {typeof textInputAssistiveText === "string" && (
            <InputDescription id={inputDescriptionId}>
              {textInputAssistiveText}
            </InputDescription>
          )}
        </>
      )}

      {responses.length === 0 && (
        <S.TextInput
          name={question.id}
          label="" // hard code label to "" since we're spreading props above
          internalVariant="MultiInput"
          variant="outlined"
          autoComplete={!!question.answerSource ? "off" : "on"}
          value={currentValue}
          onBlur={handleBlur}
          onChange={({
            currentTarget: { value },
          }: React.FormEvent<HTMLInputElement>) => setCurrentValue(value)}
          placeholder={
            typeof textInputPlaceholder === "string" ? textInputPlaceholder : ""
          }
          error={error}
          helperText={
            typeof textInputAssistiveText === "string"
              ? textInputAssistiveText
              : ""
          }
          hideErrorText
        />
      )}

      <S.MenuAnchor ref={MENU_ANCHOR} />

      {error && <S.ErrorText>{error}</S.ErrorText>}
    </S.MultiInput>
  );
};

const mapStateToProps = (
  state: AppState,
  props: Partial<ParticipantProps>
) => ({
  selectors: {
    valuesForDataSourceKey: valuesForDataSourceKey({
      dataSourceKey: props.question?.answerSource?.dataSourceKey,
      state: state.dataSource,
    }),
    currentUser: currentUser(state.user),
    currentUserFullName: currentUserFullName(state.user),
  },
});

export default connect(mapStateToProps)(ParticipantField);
