import { useForkRef } from "@material-ui/core";
import Loader from "components/common/Loader";
import React, { forwardRef, HTMLAttributes, useRef } from "react";
import VisibilitySensor from "react-visibility-sensor";
import styles from "./Listbox.module.scss";

export interface ListboxProps extends HTMLAttributes<HTMLElement> {
  isFinalPage?: boolean;
  isLoadingMore?: boolean;
  onLoadMore?: () => void;
  onChangeVisibility?: (isVisible: boolean) => void;
}

export const Listbox = forwardRef<HTMLUListElement, ListboxProps>(
  (
    {
      children,
      isLoadingMore,
      isFinalPage,
      onChangeVisibility,
      onLoadMore,
      ...props
    },
    ref
  ) => {
    const listbox = useRef<HTMLUListElement | null>(null);
    const handleRef = useForkRef(listbox, ref);

    return (
      <ul ref={handleRef} role="listbox" {...props}>
        {children}
        <VisibilitySensor
          active={!isFinalPage}
          delayedCall={true}
          intervalDelay={500}
          onChange={(isVisible) => {
            if (onChangeVisibility) {
              onChangeVisibility(isVisible);
            }
            if (isVisible && onLoadMore) {
              onLoadMore();
            }
          }}
        >
          <div className={styles.sensor}></div>
        </VisibilitySensor>
        <Loader className={styles.loader} loading={isLoadingMore} />
      </ul>
    );
  }
);
