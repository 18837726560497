import React from "react";
import { MoreVertIcon } from "../Card/styles";
import Popper from "../Popper";
import { PopperWrapper } from "./styles";
import { ActionMenuItem } from "./ActionMenuItem";

type ActionMenuChild = React.ReactElement<typeof ActionMenuItem>;

type Children = Array<ActionMenuChild | undefined> | ActionMenuChild;

type Props = {
  children?: Children;
  closeOnClick?: boolean;
};

/** Popup display with action items or informative content */
export function ActionMenu({ children, closeOnClick }: Props) {
  const [open, setOpen] = React.useState<boolean>(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClose = () => open && setOpen(false);

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
    setTimeout(() => setOpen(true));
  };

  // Hide context menu if no links -- GK
  if (!children) return null;

  return (
    <>
      <MoreVertIcon onClick={handleOpen} />
      <Popper anchorEl={anchorEl} onClose={handleClose} open={open} placement="left">
        <PopperWrapper onClick={closeOnClick ? handleClose : undefined}>
          {children}
        </PopperWrapper>
      </Popper>
    </>
  );
}
