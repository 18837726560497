import styled from "styled-components";

export const SelectField = styled.select`
  text-align-last: right;
  padding: 0;
  border: none;
  background: none;
  width: 100%;
`;

export const Option = styled.option``;

export const Row = styled.div`
  display: flex;
`;
