import { StyleSheet, StyleDeclaration } from "aphrodite";
import { CreateStyleSheet } from "util/hooks/useThemedStyleSheet";
import { remCalc } from "themes/helpers";

interface InputDescriptionStyle {
  inputDescription: StyleDeclaration;
}

export const style: CreateStyleSheet<InputDescriptionStyle> = (theme) => {
  return StyleSheet.create({
    inputDescription: {
      color: theme.masterColors.darkGreyBlue,
      marginLeft: remCalc(16),
      marginTop: remCalc(4),
    },
  });
};
