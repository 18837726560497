import React, { useContext } from "react";
import { ResponsiveBar } from "@nivo/bar";

import * as S from "./styles";
import ChartHeader from "./ChartHeader";
import CustomBarElement from "./CustomBar";
import Loader from "components/common/Loader";
import { MappedResponses } from "../../helpers";
import { useCommonChartProps } from "components/common/Charts/common";
import { ThemeContext } from "styled-components";

type SafetyScaleProps = {
  dayFilterValue: string;
  loading: boolean;
  ratingsFilter: string[];
  responses: MappedResponses;
  totalResponseCount: number;
};

type linesAcc = {
  lines: string[];
  currLine: string;
};

const getTspanGroups = (value: string, maxLineLength = 12, maxLines = 2) => {
  if (typeof value !== "string") return;
  const words = value.split(" ");

  //reduces the words into lines of maxLineLength
  const assembleLines: linesAcc = words.reduce(
    (acc: linesAcc, word: string) => {
      /* if the current line isn't empty and the word + current line is larger than the allowed line size, create a new
       * line and update current line */
      if ((word + acc.currLine).length > maxLineLength && acc.currLine !== "") {
        return {
          lines: acc.lines.concat([acc.currLine]),
          currLine: word,
        };
      }
      //otherwise add the word to the current line
      return {
        ...acc,
        currLine: acc.currLine + " " + word,
      };
    },
    { lines: [], currLine: "" },
  );

  //add the ending state of current line (the last line) to lines
  const allLines = assembleLines.lines.concat([assembleLines.currLine]);

  //for now, only take first 2 lines due to tick spacing and possible overflow
  const lines = allLines.slice(0, maxLines);
  const children: JSX.Element[] = [];
  let dy = 0;

  lines.forEach((lineText, i) => {
    children.push(
      <tspan style={{ marginRight: "1rem" }} x={0} dy={dy} key={i}>
        {// if on the second line, and that line's length is within 3 of the max length, add ellipsis
          1 === i && allLines.length > 2 ? lineText.slice(0, maxLineLength - 3) + "..." : lineText}
      </tspan>,
    );
    //increment dy to render next line text below
    dy += 15;
  });

  return children;
};

const SafetyScale = ({ dayFilterValue, loading, ratingsFilter, responses, totalResponseCount }: SafetyScaleProps) => {
  const theme = useContext(ThemeContext);
  const commonChartProps = useCommonChartProps();
  const data = Object.keys(responses).map((k, i) => {
    const responseCountArr = responses[k].map((r) => r.responseCount);
    const value = responseCountArr.length ? responseCountArr.reduce((acc, cur) => acc + cur) : 0;
    return {
      id: k,
      value,
      label: k,
      totalResponseCount,
    };
  });
  data.reverse();
  const labelFormat = (d) => `${d} (${((d / totalResponseCount) * 100).toFixed(1)}%)`;
  const alteredCommonProps = { ...commonChartProps, colorBy: "index", labelFormat };
  const renderTick = ({ opacity, textAnchor, textBaseline, textX, textY, value, x, y }) => {
    const textStyle = {
      fontSize: "12px",
      fill: theme.colors.primary,
      textDecoration: "underline",
      textDecorationColor: theme.colors.primary,
    };
    if (!ratingsFilter.includes(value) && ratingsFilter.length) {
      delete textStyle.fill;
      delete textStyle.textDecoration;
      delete textStyle.textDecorationColor;
    }
    return (
      <g transform={`translate(${x},${y})`} style={{ opacity }}>
        <text
          alignmentBaseline={textBaseline}
          style={textStyle}
          textAnchor={textAnchor}
          transform={`translate(${textX},${textY})`}
        >
          {getTspanGroups(value, 12, 2)}
        </text>
      </g>
    );
  };
  return (
    <Loader loading={loading} overlay>
      <S.WidgetContainer>
        <ChartHeader totalResponseCount={totalResponseCount} dayFilterValue={dayFilterValue} />
        <S.ChartContainer>
          <ResponsiveBar
            {...alteredCommonProps}
            data={data}
            enableGridY={false}
            enableLabel={true}
            borderWidth={1}
            margin={{ bottom: 100, left: 0, right: 0, top: 15 }}
            padding={0.3}
            barComponent={CustomBarElement}
            layout="vertical"
            indexBy="label"
            axisLeft={null}
            axisBottom={{
              tickSize: 0,
              tickPadding: 25,
              tickRotation: 0,
              renderTick,
            }}
          />
        </S.ChartContainer>
      </S.WidgetContainer>
    </Loader>
  );
};

export default SafetyScale;
