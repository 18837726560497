import React from "react";

import * as S from "./styles";

interface ButtonStyle {
  border?: string;
}

interface IconStyle {
  color: string;
}

interface TextStyle {
  color?: string;
}

interface WrapperStyle {
  background?: string;
  border?: string;
}

export interface NewDocumentCardStyle {
  button: ButtonStyle;
  icon: IconStyle;
  text: TextStyle;
  wrapper: WrapperStyle;
}

export interface NewDocumentCardProps {
  buttonText: string;
  icon?: any;
  style: NewDocumentCardStyle;
  text: string;
  onClick?: () => void;
}

const NewDocumentCard = ({
  buttonText,
  icon,
  onClick,
  style,
  text,
}: NewDocumentCardProps) => {
  return (
    <>
      <S.Wrapper
        onClick={onClick}
        background={style.wrapper.background}
        border={style.wrapper.border}
      >
        <S.IconWrapper color={style.icon.color}>{icon}</S.IconWrapper>
        <S.TextWrapper color={style.text.color}>{text}</S.TextWrapper>
        <S.ButtonWrapper border={style.button.border}>
          {buttonText}
        </S.ButtonWrapper>
      </S.Wrapper>
    </>
  );
};

export default NewDocumentCard;
