import { MethodTypes } from "store/common/types";
import { FormDTO, QuestionDTO } from "store/forms/types";
import { ClientGroup } from "store/clientGroups/types";

export const GET_FORM_STATS = {
  REQUEST: "GET_FORM_STATS_REQUEST",
  SUCCESS: "GET_FORM_STATS_SUCCESS",
  FAILURE: "GET_FORM_STATS_FAILURE",
};

export interface formStatsState {
  loading: {
    [key: number]: Partial<MethodTypes>;
  };
  errors: {
    [key: number]: Partial<MethodTypes>;
  };
  data: {
    [key: number]: FormStats;
  };
}

export interface formStatsActionTypes {
  type: typeof GET_FORM_STATS.REQUEST | typeof GET_FORM_STATS.SUCCESS | typeof GET_FORM_STATS.FAILURE;
  response?: any;
  formId: number | string;
}

export type FormStats = {
  averageCrewSize: number;
  averageOEsViewed: number;
  averageStartTime: number;
  averageTimeToComplete: number;
  clientGroups: ClientGroup[];
  form: FormDTO;
  operationalExperienceViewCounts: [
    {
      documentCount: number;
      viewCount: number;
    },
  ];
  questions: [
    {
      question: QuestionDTO;
      topResponses: [
        {
          response: string;
          responseCount: number;
        },
      ];
      totalResponses: number;
    },
  ];
  submissionsWithWorkOrders: number;
  totalOutstanding: number;
  totalSubmissions: number;
};
