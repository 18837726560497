import React from "react";
import { css } from "aphrodite/no-important";

import { Chevron } from "components/common/TableUI/Pagination/styles";
import { createStyles } from "./styles";
import { useThemedStyleSheet } from "util/hooks/useThemedStyleSheet";


interface Props {
  chevronRight?: boolean;
  hideChevron?: boolean;
  onClick?: () => void;
  title?: string;
}

const StickyHeader = ({ chevronRight, hideChevron, onClick, title }: Props) => {
  const styles = useThemedStyleSheet(createStyles);
  return <div className={css(styles.wrapper)}>
    {/* Chevron */}
    {!hideChevron && <div className={css(styles.chevronWrapper)}>
      <Chevron right={chevronRight} fontSize={"1.5rem"} onClick={onClick} />
    </div>
    }
    
    {/* Title */}
    <h6 className={css(styles.title)}>
      {title}
    </h6>
  </div>;
};

export default StickyHeader;
