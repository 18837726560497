import { CALL_API } from "middleware/api";

import { SHARE_DOCUMENT } from "./types";

//Share document
export const shareDocument = (body) => (dispatch, getState) => {
  const { system } = getState();
  const endpoint = "documents/share";
  return dispatch({
    [CALL_API]: {
      endpoint,
      options: {
        method: "POST",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${system.authToken}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      },
      types: [SHARE_DOCUMENT.REQUEST, SHARE_DOCUMENT.SUCCESS, SHARE_DOCUMENT.FAILURE],
    },
  });
};
