import { StyleDeclaration, StyleSheet } from "aphrodite";
import { remCalc } from "themes/helpers";
import { bodyMedium } from "themes/mixins";
import { CreateStyleSheet } from "util/hooks/useThemedStyleSheet";

interface DatePickerStyle {
  datePicker: StyleDeclaration;
  header: StyleDeclaration;
  heading: StyleDeclaration;
}

export const styles: CreateStyleSheet<DatePickerStyle> = (theme) => {
  return StyleSheet.create({
    datePicker: {
      display: "flex",
      flexDirection: "column",
      padding: remCalc(16),
    },
    header: {
      display: "flex",
      paddingBottom: remCalc(16),
    },
    heading: {
      ...bodyMedium,
      color: theme.colors.black,
      flexGrow: 1,
      margin: 0,
      textAlign: "center",
    },
  });
};
