import { StyleSheet } from "aphrodite";
import { useContext } from "react";
import { ThemeContext } from "styled-components";
import useValidation from "./useValidation";

export default () => {
  const theme = useContext(ThemeContext);
  const { alertLevel } = useValidation();
  const alertColor = alertLevel === "error" ? "error" : "warning";

  return StyleSheet.create({
    s16: {
      fontSize: 16
    },
    statusIndicator: {
      width: "0.525rem",
      height: "0.525rem",
      borderRadius: "0.25rem",
      marginRight: "0.5rem",
      background: theme.colors[alertColor]
    },
    validationBanner: {
      background: theme.colors[`${alertColor}Fill`],
      border: `1px solid ${theme.colors[alertColor]}`,
    },
    validationStatus: {
      display: "flex",
      alignItems: "center",
      marginRight: "1rem",
      marginBottom: "0.5rem",
      color: alertColor === "error" && theme.colors.error
    },
    validationLink: {
      textAlign: "left",
      color: alertColor === "error" && theme.colors.error
    },

    fieldErrorWrapper: {
      border: `2px solid ${theme.colors.error}`,
      background: theme.colors.errorFill,
      borderRadius: "0.25rem",
      padding: "0.5rem",
      marginTop: "0.5rem"
    },
    
    fieldError: {
      color: theme.colors.error,
      fontSize: "0.75rem",
      marginTop: "0.25rem"
    }
  });
};
