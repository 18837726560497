import React from "react";
import { connect } from "react-redux";

import { AppState } from "store";
import { Text } from "components/clientAdmin/styles";

import * as S from "./styles";
import { StarIcon } from "../../styles";
import useGroupTerm from "util/hooks/whiteLabel/useGroupTerm";
import ReadOnlyContent from "components/common/permissions/ReadOnlyContent";

interface StickyProfileHeaderProps {
  email?: string;
  firstName?: string;
  icon?: JSX.Element;
  lastName?: string;
  primaryGroup?: string;
  supervisor?: string;
}

const StickyProfileHeader = (props: StickyProfileHeaderProps) => {
  const { email, firstName, icon, lastName, primaryGroup, supervisor } = props;

  // Group config terms
  const supervisorTerm = useGroupTerm("supervisor", "noun", undefined, "Supervisor");

  return (
    <S.Wrapper>
      <S.ContentWrapper className="d-flex flex-row justify-content-between">
        <div className="d-flex flex-column">
          <S.Name>
            {firstName} {lastName}
            {lastName && icon && <ReadOnlyContent>
              {icon}
            </ReadOnlyContent>}
          </S.Name>
          <Text fontSize={"1rem"} letterSpacing="0.28px" textDecoration="underline">
            <a href={`mailto: ${email}`}>{email}</a>
          </Text>
        </div>
        <div className="d-flex flex-column">
          <div className="d-flex flex-row">
            <Text fontSize={"0.875rem"} fontWeight={600} letterSpacing="0.24px">
              Primary Group:&nbsp;
            </Text>
            <Text fontSize={"0.875rem"} fontWeight={400} letterSpacing="0.24px">
              {primaryGroup ? (
                <>
                  <StarIcon />{" "}
                  {primaryGroup}
                </>
              ) : (
                "-"
              )}
            </Text>
          </div>
          <div className="d-flex flex-row">
            <Text fontSize={"0.875rem"} fontWeight={600} letterSpacing="0.24px">
              {supervisorTerm}:&nbsp;
            </Text>
            <Text fontSize={"0.875rem"} fontWeight={400} letterSpacing="0.24px">
              {supervisor || "-"}
            </Text>
          </div>
        </div>
      </S.ContentWrapper>
    </S.Wrapper>
  );
};

export default connect((state: AppState) => ({}))(StickyProfileHeader);
