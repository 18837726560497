import React, { useState } from "react";
import { Action } from "redux";
import { css } from "aphrodite/no-important";
import { useFormikContext } from "formik";
import { omit } from "lodash";
import { ThunkDispatch } from "redux-thunk";
import { useDispatch } from "react-redux";

import { AppState } from "store";
import {
  NewAnswerSource,
  NewFormItem,
  NewFormQuestion,
  NewFormWidget,
  UpdateFormResponse,
} from "store/builder/types";
import {
  putFormSection,
  putFormItem,
  deleteFormItem,
  deleteFormSection,
  putBuilderForm,
} from "store/builder/actions";
import {
  ContentDTO,
  FormDTO,
  QuestionDTO,
  SafetyRatingWidgetDTO,
} from "store/forms/types";

import Content from "./content/Content";
import ItemsPanel from "./items/ItemsPanel";
import Properties from "./properties/Properties";
import baseStyles from "../styles";
import sidebarStyles from "../baseUi/sidebar/styles";
import {
  FBForm,
  FBItem,
  FBSection,
  ItemType,
  EntityProperties,
} from "../types";
import {
  unmarshallForm,
  getFormItemById,
  scrollToElement,
  getSelectedSectionIndex,
  getAddedItemIndex,
  getAllFormItems,
  marshallForm,
  marshallSection,
  marshallItem,
} from "../helpers";

export const initialParticipantAnswerSource: NewAnswerSource = {
  id: null,
  dataSourceKey: "PARTICIPANT",
  properties: {
    answerField: "nameAndTitle",
    detailedSearch: {
      title: "Add Participant",
      enabled: true,
      linkName: "Add Participant",
      searchBy: "Search by name or member ID",
      subtitle: "Who would you like to add?",
      infiniteListTitle: "Participants by Alphabetical Order",
      infiniteListSortBy: "nameAndTitle",
      recentAnswersTitle: "Recently Added Participants",
      customAnswerEnabled: true,
      infiniteListEnabled: true,
      recentAnswersEnabled: true,
    },
  },
  type: "DATA_SOURCE",
};

export interface ItemParams {
  name: string;
  type: ItemType;
  subType: string;
  initialProperties?: EntityProperties;
}

export type GeneratorFn = (item: ItemParams | QuestionDTO) => Promise<void>;
export type ItemsMutator = (
  item: FBItem,
  removeWidgetChildren?: boolean
) => Promise<boolean>;
export type SectionsMutator = (
  section: FBSection,
  removeChildren?: boolean
) => Promise<boolean>;

interface Props {
  onSaveError?: (error: string) => void;
}

/** Tab contents for the "Create" Form Builder tab */
const Create: React.FC<Props> = ({ onSaveError }) => {
  const [currentItem, setCurrentItem] = useState<FBItem | FBSection | null>(
    null
  );
  const { values, setValues } = useFormikContext<FBForm>();

  const dispatch = useDispatch<ThunkDispatch<AppState, void, Action>>();

  const bs = baseStyles();
  const ss = sidebarStyles();

  /**
   * Construct a content item DTO
   * @param item
   */
  function buildContent(item: ItemParams): NewFormItem<ContentDTO> {
    const content: NewFormItem<ContentDTO> = {
      ...item,
      type: "CONTENT",
      content: "",
      id: null,
    };

    if (item.subType === "BANNER_MESSAGE") {
      return { ...content, backgroundColor: "#FFFFFF" };
    }

    return content;
  }

  /**
   * Construct a question item DTO
   * @param item
   */
  function buildQuestion(item: ItemParams): NewFormQuestion {
    const question: NewFormQuestion = {
      ...item,
      id: null,
      properties: {},
      type: "QUESTION",
      title: "",
      ...item.initialProperties,
    };

    if (["RADIO_BUTTONS", "MULTI_SELECT"].includes(item.subType)) {
      return { ...question, selections: [] };
    }

    if (item.subType === "PARTICIPANT") {
      return {
        ...question,
        answerSource: initialParticipantAnswerSource,
      };
    }

    return question;
  }

  /**
   * Construct a widget item DTO and handle any specific actions necessary for individual widgets
   * @param item
   * @param sectionId
   * @param sectionIndex
   */
  async function buildWidget(
    item: ItemParams,
    sectionId: number,
    sectionIndex: number
  ): Promise<NewFormWidget | NewFormQuestion> {
    const widget: NewFormWidget = {
      ...omit(item, "initialProperties"),
      ...item.initialProperties,
      type: "WIDGET",
      id: null,
    };

    /* The safety rating widget requires creation of a multi-select parent field */
    if (item.subType === "SAFETY_RATING") {
      // const currentSectionIndex = values.sections.findIndex((section) => section.id === currentItem.id);
      // build the MULTI_SELECT field
      const multiSelectField = buildQuestion({
        type: "QUESTION",
        subType: "MULTI_SELECT",
        name: "",
      });
      multiSelectField.title = "Which area do you want to review?";
      multiSelectField.formProperties = {
        isCloneable: false,
        isRequired: false,
        isSearchable: false,
      };
      // add the MULTI_SELECT field to the form
      const res = await dispatch(
        putFormItem(values.id, sectionId, multiSelectField as NewFormQuestion)
      );
      if (res.response) {
        const newValues = unmarshallForm(res.response);
        const newItemIndex =
          newValues.sections[sectionIndex]?.items?.length - 1;
        const parentQuestionRootId =
          newValues.sections[sectionIndex]?.items?.[newItemIndex]?.id;
        if (parentQuestionRootId) {
          // build + return the SAFETY_RATING widget
          return {
            ...widget,
            parentQuestionRootId,
            parentID: newValues.sections[sectionIndex].id,
            parentRootID: newValues.sections[sectionIndex].rootId,
            questions: [],
            allowComments: true,
            displayDescription: false,
            requireCommentsFor: [],
          } as SafetyRatingWidgetDTO;
        }
      }
    } else if (item.subType === "DOCUMENT_CREATOR") {
      return {
        ...widget,
        title: "",
        type: "QUESTION",
        subType: "PARTICIPANT",
        answerSource: {
          // id: null,
          dataSourceKey: null,
          properties: {
            answerField: "nameAndTitle",
            associatedIdField: "participantId",
            autoComplete: true,
            detailedSearch: undefined,
            readOnly: true,
          },
          type: "CURRENT_PARTICIPANT",
        },
      } as NewFormQuestion;
    } else if (item.subType === "SUPERVISOR") {
      return {
        ...widget,
        title: "",
        type: "QUESTION",
        subType: "PARTICIPANT",
        participantRole: "SUPERVISOR",
        properties: {
          ...widget.properties,
          allowMultipleAnswers: true,
          excludeFromSignatures: true,
        },
        answerSource: {
          dataSourceKey: "PARTICIPANT",
          properties: {
            ...initialParticipantAnswerSource.properties,
            prefillAnswerField: "supervisor.fullName",
            prefillAssociatedIdField: "supervisor.id",
          },
          type: "CURRENT_PARTICIPANT",
        },
      } as NewFormQuestion;
    }
    return widget;
  }

  /**
   * Construct a question, widget or content item DTO
   * @param item
   * @param currentItemId
   * @param sectionId
   * @param currentSectionIndex
   */
  async function buildItem(
    item: ItemParams,
    currentItemId: number,
    sectionId: number,
    currentSectionIndex: number
  ): Promise<
    NewFormQuestion | NewFormItem<ContentDTO> | NewFormWidget | undefined
  > {
    // build new item
    if (item.type === "QUESTION") {
      return buildQuestion(item);
    }
    if (item.type === "CONTENT") {
      return buildContent(item);
    }
    if (item.type === "WIDGET" && currentItemId) {
      return await buildWidget(item, sectionId, currentSectionIndex);
    }
  }

  function selectAddedItem(
    itemIndex: number,
    itemType: ItemType,
    currentSectionIndex: number,
    sections: FBSection[]
  ): void {
    if (currentSectionIndex > -1 && itemIndex > -1) {
      const selectedItem =
        itemType == "SECTION"
          ? sections[itemIndex]
          : sections[currentSectionIndex].items[itemIndex];
      setCurrentItem(selectedItem);
      // scroll to the newly added item
      scrollToElement(selectedItem.id.toString());
    }
  }

  /**
   * Remove a non-section item from the form via API and update the local form state
   * @param item
   * @param removeChildren
   */
  async function removeItem(
    item: FBItem,
    removeChildren?: boolean
  ): Promise<boolean> {
    if (item.position > -1) {
      const currForm = marshallForm(values);
      await dispatch(putBuilderForm(currForm));
      const parentSection = values.sections.find(
        (section) => section.id === item.parentID
      );
      setCurrentItem(parentSection || null);
      const res = await dispatch(
        deleteFormItem(values.id, item.parentID, item.position, removeChildren)
      );
      if (res?.response) {
        setValues(unmarshallForm(res.response));
        return true;
      }
    }
    return false;
  }

  /**
   * Remove a section item from the form via API and update the local form state
   * @param section
   * @param removeChildren
   */
  async function removeSection(
    section: FBSection,
    removeChildren?: boolean
  ): Promise<boolean> {
    const currForm = marshallForm(values);
    await dispatch(putBuilderForm(currForm));
    const res = await dispatch(
      deleteFormSection(values.id, section.id, removeChildren)
    );
    if (res?.response) {
      setValues(unmarshallForm(res.response));
      setCurrentItem(null);
      return true;
    }
    return false;
  }

  /**
   * Update a form item and then unmarshall the response and update the current item
   * @param item
   * @param index - target index to insert item in items array
   */
  async function updateItem(
    item: FBItem | FBSection,
    index?: number
  ): Promise<FBForm | undefined> {
    // @TODO this is a hack find a better way to prevent updates on Published form
    if (values.workflowType === "FINAL") return;
    // end hack
    // const currForm = marshallForm(values);
    // await dispatch(putBuilderForm(currForm));

    // ensure the item is in DRAFT mode
    let draftItem: FBItem | FBSection = { ...item, workflowType: "DRAFT" };

    if (draftItem.type === "SECTION") {
      const res = await dispatch(
        putFormSection(values.id, marshallSection(draftItem), index)
      );
      return processResult(res, draftItem.id);
    } else {
      if (["SUPERVISOR", "DOCUMENT_CREATOR"].includes(draftItem.subType)) {
        draftItem = {
          ...draftItem,
          type: "QUESTION",
          subType: "PARTICIPANT",
        } as FBItem;
      }
      const parentSection = values.sections.find(
        (s) => s.rootId === (draftItem as FBItem).parentRootID
      );
      const res = await dispatch(
        putFormItem(
          values.id,
          parentSection?.id || draftItem.parentID,
          marshallItem(draftItem),
          index
        )
      );
      return processResult(res, draftItem.id);
    }
  }

  async function processResult(
    res: UpdateFormResponse,
    currentId: number
  ): Promise<FBForm | undefined> {
    if (res.response) {
      const updatedValues = unmarshallForm(res.response);
      setValues(updatedValues);
      const updatedCurrentItem = getFormItemById(updatedValues, currentId);
      if (updatedCurrentItem) {
        setCurrentItem(updatedCurrentItem);
      }
      return updatedValues;
    }
  }

  /**
   * Add a new section to the form
   * @param section
   * @param index - optional, the index at which to insert the section
   */
  async function addSection(
    section: ItemParams,
    index?: number
  ): Promise<UpdateFormResponse> {
    const newSection = {
      items: [],
      id: null,
      title: "",
      properties: section.initialProperties || {},
    };

    return await dispatch(putFormSection(values.id, newSection, index));
  }

  /**
   * Add an item to the form via API and update local form state with response
   * @param item
   */
  async function add(item: ItemParams | QuestionDTO): Promise<void> {
    // @TODO this is a hack find a better way to prevent updates on Published form
    if (values.workflowType === "FINAL") return;
    // end hack

    const currForm = marshallForm(values);
    await dispatch(putBuilderForm(currForm));
    let newItem;
    let updatedForm: FormDTO | undefined;
    const currentSectionIndex = getSelectedSectionIndex(
      currentItem,
      values.sections
    );
    const itemIndex = getAddedItemIndex(
      item.type,
      currentSectionIndex,
      currentItem,
      values.sections
    );

    /* user is adding a section */
    if (item.type === "SECTION") {
      const res = await addSection(item, itemIndex);
      if (res?.response) updatedForm = res.response;
    } else if (currentItem) {
      /* user is adding a child item to a section */
      const sectionId = values.sections[currentSectionIndex].id;
      /* user is adding a global item */
      if ("id" in item) {
        const res = await dispatch(
          putFormItem(values.id, sectionId, item, itemIndex)
        );
        if (res?.response) updatedForm = res.response;
      } else {
        /* user is adding a new item */
        newItem = await buildItem(
          item,
          currentItem.id,
          sectionId,
          currentSectionIndex
        );
        if (newItem) {
          const res = await dispatch(
            putFormItem(
              values.id,
              newItem.parentID || sectionId,
              newItem,
              itemIndex
            )
          );
          if (res?.response) updatedForm = res.response;
        }
      }
    }

    /* form was successfully updated */
    if (updatedForm) {
      // update the form values
      const form = unmarshallForm(updatedForm);
      setValues(form);
      // select the newly added item
      typeof itemIndex === "number" &&
        selectAddedItem(
          itemIndex,
          item.type,
          currentSectionIndex,
          form.sections
        );
    }
  }

  const mapWidget = getAllFormItems(values).find(
    (item) => item.subType === "MAP"
  );

  return (
    <div className={css(bs.contentWrapper)}>
      {/* Left sidebar */}
      <div className={css(ss.sidebar)}>
        <ItemsPanel
          add={add}
          itemSelected={!!currentItem}
          disableAddMap={!!mapWidget}
        />
      </div>
      {/* Layout */}
      <Content
        currentItem={currentItem}
        removeItem={removeItem}
        removeSection={removeSection}
        setCurrentItem={setCurrentItem}
        onUpdateItem={updateItem}
      />
      {/* Right sidebar */}
      <div className={css(ss.sidebarContainer)}>
        {currentItem && (
          <div className={css([ss.sidebar, bs.propertiesSidebar])}>
            <Properties
              currentItem={currentItem}
              setCurrentItem={setCurrentItem}
              onUpdateItem={updateItem}
              onSaveError={onSaveError}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default Create;
