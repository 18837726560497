import React from "react";

import {
  TableCell,
  TableCellProps,
  TableHead,
  TableRow,
} from "components/common/styled/Table";
import { getSortDirection } from "./helpers";
import { SortParams } from "../../../store/common/types";

interface TableHeaderCellProps extends TableCellProps {
  id: string;
  label?: string;
  currentSort?: string | SortParams;
  notSortable?: boolean;
  onSort: (sort: string) => void;
}

export const TableHeaderCell = ({
  id,
  label,
  currentSort,
  notSortable,
  onSort,
  ...rest
}: TableHeaderCellProps) => {
  const sortDirection = notSortable
    ? undefined
    : getSortDirection(id, currentSort);
  let activeSort = "";
  if (typeof currentSort === "string") {
    activeSort = currentSort?.split(",")[0];
  } else if (Array.isArray(currentSort)) {
    activeSort = currentSort[0];
  }

  return (
    <TableCell
      active={activeSort === id}
      onClick={(dir) => onSort(`${id},${dir}`)}
      sortDirection={sortDirection}
      {...rest}
    >
      {label || id}
    </TableCell>
  );
};

const TableHeader = ({
  columns,
  onSort,
  currentSort,
}: {
  columns: { id: string; label?: string; notSortable?: boolean }[];
  onSort: (sort: string) => void;
  currentSort?: string | SortParams;
}) => (
  <TableHead>
    <TableRow noBottomBorder>
      {columns.map((col) => (
        <TableHeaderCell
          key={col.id}
          id={col.id}
          label={col.label}
          onSort={onSort}
          currentSort={currentSort}
          notSortable={col.notSortable}
        />
      ))}
    </TableRow>
  </TableHead>
);

export default TableHeader;
