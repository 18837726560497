import { CALL_API } from "../../middleware/api";
import {
  GET_PARTICIPANTS_SUMMARY,
  UserStatus,
  GetParticipantsSummaryResponse,
} from "./types";

export type GetParticipantsSummaryParams = {
  clientGroupId?: number;
  maxLastLogin?: string | number;
  minLastLogin?: string | number;
  page: number;
  participantTreeFilter?: string;
  participantType?: string | number;
  searchText?: string;
  size?: number;
  sort?: string;
  status?: UserStatus;
};

// Get participants summary
export const getParticipantsSummary = (args: GetParticipantsSummaryParams) => (
  dispatch,
  getState
): Promise<GetParticipantsSummaryResponse> => {
  const authToken = getState().system.authToken;
  let endpoint = `participants/summary?page=${args.page}`;

  // Trying to make url query building more dry
  const params = Object.keys(args);
  params
    .filter((e) => e !== "page") // Page should always be there
    .forEach((param: string) => {
      if (
        args[param] !== undefined &&
        args[param] !== -1 &&
        args[param] !== ""
      ) {
        // If param has a value, add it to the url
        endpoint = `${endpoint}&${param}=${encodeURIComponent(args[param])}`;
      }
    });

  return dispatch({
    [CALL_API]: {
      endpoint,
      options: {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${authToken}`,
        },
      },
      types: [
        GET_PARTICIPANTS_SUMMARY.REQUEST,
        GET_PARTICIPANTS_SUMMARY.SUCCESS,
        GET_PARTICIPANTS_SUMMARY.FAILURE,
      ],
    },
  });
};

// Resets participantsSummary and participantsAggregateSummary
export const resetParticipantsSummary = () => ({
  type: "RESET_PARTICIPANTS_SUMMARY",
});
