import React, { useCallback, useMemo, useRef, forwardRef } from "react";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { useMediaQuery } from "react-responsive";
import Slider from "react-slick";

import { devices } from "themes/mediaQueries";
import Modal from "components/common/Modal";

import * as S from "./styles";

import image1 from "./images/1.png";
import image2 from "./images/2.png";
import image3 from "./images/3.png";
import image4 from "./images/4.png";
import image5 from "./images/5.png";
import { Slide } from ".";
import useGroupTerm from "util/hooks/whiteLabel/useGroupTerm";

/**
 * Represents the ref to the React-Slick element
 * used to control the carousel. There are other
 * props but I've only added the methods
 */
interface ReactSlickControl {
  slickPrev: () => void;
  slickNext: () => void;
  slickGoTo: () => void;
  slickPause: () => void;
  slickPlay: () => void;
  innerSlider: {
    state: {
      currentSlide: number;
      slideCount: number;
    };
  };
}

// props for Tutorial component
interface Props {
  endTutorial: () => void;
  open?: boolean;
}

export interface CopyType {
  content: string;
  header: string;
  id: number;
  image: string;
}

const getCopy = ({ terms: { document, documents, supervisor, employee } }) => {
  return [
    {
      content:
        "Thank you for participating in the early version pilot for the new Dominion Safety Tools app.  We will be collecting your feedback to make improvements",
      header: "Welcome to the new Dominion Safety Tools App",
      image: image1,
      id: 0,
    },
    {
      content:
        `Kickoff a new ${document} by selecting the form type. You can initiate it by locating a work order or start fresh with a blank one.`,
      header: "Start a Form",
      image: image2,
      id: 1,
    },
    {
      content:
        `Dominion Safety Tools forms include new features such as an interactive map with draggable pins, ${employee} directories with one-tap removal, and more commenting options.`,
      header: "Many New Features",
      image: image3,
      id: 2,
    },
    {
      content:
        `Once you're finished, you can submit a ${document} to your ${supervisor} or save as draft at any point and return to complete it later.`,
      header: "Save or Submit",
      image: image4,
      id: 3,
    },
    {
      content:
        `The ${documents} list shows all of your forms - both Pre-Job Briefs and Safety Observations. It includes both submitted and in-progress forms.`,
      header: "Review Your History",
      image: image5,
      id: 4,
    },
  ];
};

interface TutorialSettings {
  dots: boolean;
  infinite: boolean;
  slidesToScroll: number;
  slidesToShow: number;
  speed: number;
}

interface TutorialProps {
  endTutorial: () => void;
  goToNextSlide: () => void;
  settings: TutorialSettings;
  isDesktop: boolean;
}

/**
 * Component used to render the mobile version of the
 * Tutorial feature
 */
const MobileTutorial = forwardRef<ReactSlickControl, TutorialProps>(
  ({ endTutorial, goToNextSlide, isDesktop, settings }: TutorialProps, ref) => {
    // Group config terms
    const documentTerm = useGroupTerm("document", "noun", undefined, "Document");
    // const documentTermLower = documentTerm.toLowerCase();
    const documentsTerm = useGroupTerm("document", "noun", "plural", "Documents");
    const supervisorTerm = useGroupTerm("supervisor", "noun", undefined, "Supervisor");
    const supervisorTermLower = supervisorTerm.toLowerCase();
    const employeeTerm = useGroupTerm("employee", "noun", undefined, "Employee");
    const employeeTermLower = employeeTerm.toLowerCase();


    // Generate copy
    const _copy = getCopy({ terms: {
      document: documentTerm,
      documents: documentsTerm,
      supervisor: supervisorTermLower,
      employee: employeeTermLower,
    } });

    const logo2x2Fallback = "/assets/logo/Dominion-EnergyLogo@2x.png";
    return (
      <S.TutorialWrapper mobile>
        <S.Logo>
          <img src={logo2x2Fallback} height="48" alt="logo" />
        </S.Logo>
        <Slider {...settings} ref={ref}>
          {_copy.map((e) => (
            <Slide key={e.id} contents={e} />
          ))}
        </Slider>
        <S.ButtonsWrapper mobile={!isDesktop}>
          <S.Next
            onClick={goToNextSlide}
          >
            next
          </S.Next>
          <S.Skip
            onClick={endTutorial}
          >
            skip
          </S.Skip>
        </S.ButtonsWrapper>
      </S.TutorialWrapper>
    );
  },
);

MobileTutorial.displayName = "MobileTutorial";

/**
 * Component used to render the Desktop version
 * of the Tutorial
 */
const DesktopTutorial = forwardRef<ReactSlickControl, TutorialProps>(
  ({ endTutorial, goToNextSlide, isDesktop, settings }: TutorialProps, sliderRef) => {
    // Group config terms
    const documentTerm = useGroupTerm("document", "noun", undefined, "Document");
    // const documentTermLower = documentTerm.toLowerCase();
    const documentsTerm = useGroupTerm("document", "noun", "plural", "Documents");
    const supervisorTerm = useGroupTerm("supervisor", "noun", undefined, "Supervisor");
    const supervisorTermLower = supervisorTerm.toLowerCase();
    const employeeTerm = useGroupTerm("employee", "noun", undefined, "Employee");
    const employeeTermLower = employeeTerm.toLowerCase();
  
  
    // Generate copy
    const _copy = getCopy({ terms: {
      document: documentTerm,
      documents: documentsTerm,
      supervisor: supervisorTermLower,
      employee: employeeTermLower,
    } });
    return (
      <S.TutorialWrapper>
        <div>
          <Slider {...settings} ref={sliderRef}>
            {_copy.map((e) => (
              <Slide key={e.id} contents={e} />
            ))}
          </Slider>
        </div>

        <S.ButtonsWrapper mobile={!isDesktop}>
          <S.Next
            onClick={goToNextSlide}
          >
            next
          </S.Next>
          <S.Skip onClick={endTutorial}>skip</S.Skip>
        </S.ButtonsWrapper>
      </S.TutorialWrapper>
    );
  },
);

DesktopTutorial.displayName = "DesktopTutorial";

/**
 * Component which holds some methods/state and
 * contains logic which determines which version
 * of the Tutorial component we'll be rendering.
 *
 * @NOTE: Styles for dots are in GlobalStyle.ts
 */
const Tutorial = ({ endTutorial, open = false }: Props) => {
  const isDesktop = useMediaQuery({
    minWidth: devices.minDesktop,
  });

  const sliderRef = useRef<null | ReactSlickControl>(null);

  const settings = useMemo(
    () => ({
      dots: true,
      infinite: false,
      slidesToScroll: 1,
      slidesToShow: 1,
      speed: 500,
    }),
    [],
  );

  /**
   * Uses a ref from "react-slick" to invoke a method
   * used to progress the carousel to the next page
   */
  const goToNextSlide = useCallback(() => {
    const currentSlide = sliderRef?.current?.innerSlider.state.currentSlide || null;
    const slideCount = sliderRef?.current?.innerSlider.state.slideCount || 0;
    const atLastSlide = currentSlide === slideCount - 1;
    if (atLastSlide) {
      endTutorial();
    } else {
      if (sliderRef.current && sliderRef.current.slickNext) {
        return sliderRef.current.slickNext();
      }
    }
  }, [sliderRef.current]);

  const content = useMemo(() => {
    if (isDesktop) {
      return (
        <DesktopTutorial
          endTutorial={endTutorial}
          goToNextSlide={goToNextSlide}
          isDesktop={isDesktop}
          ref={sliderRef}
          settings={settings}
        />
      );
    }

    return (
      <MobileTutorial
        endTutorial={endTutorial}
        goToNextSlide={goToNextSlide}
        isDesktop={isDesktop}
        ref={sliderRef}
        settings={settings}
      />
    );
  }, [isDesktop, sliderRef, settings, endTutorial, goToNextSlide]);

  return <Modal open={open} handleClose={endTutorial} mobile={!isDesktop} content={content} width="485px"/>;
};

export default Tutorial;
