import { DateRange as BaseDateRange } from "components/common/DateRange";
import { Link } from "react-router-dom";
import styled from "styled-components";

export const DateRange = styled(BaseDateRange)`
  // margin-left: 240px;
`;

export const DateRangeRow = styled.div`
  display: flex;
  padding-top: 8px;
`;

export const Wrapper = styled.div`
  max-width: 960px;
`;

export const SummaryWrapper = styled.div`
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  margin-top: 24px;
`;

export const FilterWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0;
`;

export const SelectWrapper = styled.div`
  margin-left: 12px;
  margin-right: 12px;
  :first-of-type: {
    margin-left: 0px;
  }
  :last-of-type: {
    margin-right: 0px;
  }
`;

export const SubText = styled.span`
  display: block;
  color: ${({ theme }) => theme.masterColors.darkGrey};
`;

export const ReportLink = styled(Link)`
  display: flex;
  align-items: center;
  font-weight: 500;
  .icon {
    margin: 0;
    font-size: 1.2rem;
  }
  &:hover {
    text-decoration: none;
  }
`;
