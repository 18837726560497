import React from "react";

import styles from "./styles.module.scss";
import { joinClassNames } from "themes/helpers";

export interface AlertProps {
  variant: "warning" | "error";
  title: string;
  message: string;
  isVisible: boolean;
  className?: string;
}

const Alert = ({
  variant,
  title,
  message,
  isVisible,
  className
}: AlertProps) => {
  if (!isVisible) {
    return null;
  }

  const getAlertVariantClassName = () => {
    switch(variant) {
      case "error":
        return styles.error;
      default:
        return styles.warning;
    }
  };

  const shouldHaveAlertRole = variant === "error";

  return (
    <div
      className={joinClassNames(
        styles.alert,
        getAlertVariantClassName(),
        className
      )}
      role={shouldHaveAlertRole ? "alert" : undefined}
    >
      <h3 className={styles.title}>{title}</h3>
      <p className={styles.message}>{message}</p>
    </div>
  );
};

export default Alert;
