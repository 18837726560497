import React, { useState, useMemo, FC } from "react";
import styled from "styled-components";
import { useHistory } from "react-router";

import Popper from "components/common/Popper";
import { MoreVertIcon } from "components/common/Card/styles";

// Resources
import {
  PopperChevron,
  PopperRow,
  PopperWrapper,
} from "components/clientAdmin/resources/styles";
import { CategoryDTO } from "store/categories/types";

const ChangeTagStatusItem = styled.span`
  color: ${({ theme }) => theme.colors.error};
`;

const prettifyCategoryStatusMenuItem = (status?: CategoryDTO["archived"]) => {
  if (!status) return "Archive";
  return "Activate";
};

interface Props {
  onCategoryStatusUpdate: (tag: CategoryDTO) => void;
  category: CategoryDTO;
}

const ResourceCategoryActionMenu: FC<Props> = ({
  onCategoryStatusUpdate,
  category,
}) => {
  const [popperIsOpen, setPopperIsOpen] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const history = useHistory();

  const handleClose = () => {
    if (popperIsOpen) {
      setPopperIsOpen(false);
    }
  };

  const handleOpen = (e) => {
    setAnchorEl(e.currentTarget);
    setTimeout(() => setPopperIsOpen(true));
  };

  // Archive or Activate tag menu item label
  const statusMenuLabel = useMemo(
    () => prettifyCategoryStatusMenuItem(category.archived),
    [category.archived]
  );

  return (
    <>
      <MoreVertIcon onClick={handleOpen} />
      <Popper
        anchorEl={anchorEl}
        onClose={handleClose}
        open={popperIsOpen}
        placement="left"
      >
        <PopperWrapper>
          <PopperRow
            onClick={() =>
              history.push(`/content/resource-categories/${category.id}`)
            }
          >
            <span>Edit Category</span>
            <PopperChevron />
          </PopperRow>
          <PopperRow onClick={() => onCategoryStatusUpdate(category)}>
            <ChangeTagStatusItem>{statusMenuLabel}</ChangeTagStatusItem>
            <PopperChevron />
          </PopperRow>
        </PopperWrapper>
      </Popper>
    </>
  );
};

export default ResourceCategoryActionMenu;
