import React, { useState, memo } from "react";
import { css } from "aphrodite/no-important";

import { TabPanel } from "components/common/Tabs";
import { ClientGroupDTO } from "store/participants/types";
import { FormTypeDTO } from "store/forms/types";

import AdditionalActionsForm from "./AdditionalActionsForm";
import SettingsForm from "./SettingsForm";
import SettingsTabs from "./SettingsTabs";
import styles from "../styles";
import sidebarStyles from "../baseUi/sidebar/styles";
import ValidationBanner from "../create/validation/ValidationBanner";
import { scrollToElement } from "../helpers";

interface Props {
clientGroups: ClientGroupDTO[];
formTypes: FormTypeDTO[];
}

const FormBuilderSettings: React.FC<Props> = ({ clientGroups, formTypes }) => {
  const [selectedTab, setSelectedTab] = useState<number>(0);

  const s = styles();
  const ss = sidebarStyles();

  return (
    <div className={css(s.contentWrapper)}>
      {/* Left sidebar */}
      <div className={css(ss.sidebar)}>
        <SettingsTabs currentTab={selectedTab} onChangeTab={setSelectedTab} />
      </div>
      {/* Center content */}
      <div className={css(s.content)}>
        <ValidationBanner
          errorType="settings"
          onJumpToError={(errorKey) => {
            scrollToElement(errorKey);
          }}
        />
        <TabPanel value={selectedTab} index={0}>
          <SettingsForm clientGroups={clientGroups} formTypes={formTypes} />
        </TabPanel>
        <TabPanel value={selectedTab} index={1}>
          <AdditionalActionsForm formTypes={formTypes} />
        </TabPanel>
      </div>
      {/* placeholder so width matches Create tab */}
      <div className={css(ss.sidebarContainer)} />
    </div>
  );
};

export default memo(FormBuilderSettings);
