import React, { useEffect, useState } from "react";
import ReactMapGL, { Marker, ViewState } from "react-map-gl";
import { GeoPoint } from "../../store/common/types";
import { reverseGeolocation } from "../../util";
import { LocationMarker } from "../FormController/types";
import { MarkerControl } from "./MarkerControl";
import Pin from "./Pin";
import * as S from "./styles";
import { ZoomControl } from "./ZoomControl";

type Props = {
  initialViewport: {
    width: number | string;
    height: number | string;
    zoom: number;
    center: GeoPoint;
  };
  markers: Array<LocationMarker>;
  disableControls?: boolean;
};

/**
 * Extension of LocationMarker that stores local state
 */
interface MapMarker extends LocationMarker {
  dragging: boolean;
  visible: () => boolean;
}

/**
 * Map from LocationMarkers to MapMarkers
 * @param markers Markers from props
 */
function mapMarkers(markers: Array<LocationMarker>): Array<MapMarker> {
  return (markers || []).map((m) => ({
    ...m,
    dragging: false,
    visible() {
      return Boolean(m.geolocation) || this.dragging;
    },
  }));
}

/** Map component */
export function Map(props: Props) {
  const [tileStyle, setTileStyle] = useState("streets-v11");

  const [viewport, setViewport] = useState({
    width: props.initialViewport.width,
    height: props.initialViewport.height,
    zoom: props.initialViewport.zoom,
    center: props.initialViewport.center,
  });

  // local versions of props stored in state, only modified when props are updated or marker dragged locally
  const [markers, setMarkers] = useState<Array<MapMarker>>([]);
  const setMarker = (marker, idx) => {
    const updatedMarkers = [...markers];
    updatedMarkers[idx] = marker;
    setMarkers(updatedMarkers);
  };

  // reset the markers when props change
  useEffect(() => {
    setMarkers(mapMarkers(props.markers));
  }, [props.markers]);

  useEffect(() => {
    setViewport({
      width: props.initialViewport.width,
      height: props.initialViewport.height,
      zoom: props.initialViewport.zoom,
      center: props.initialViewport.center,
    });
  }, [props.initialViewport]);

  // store these to calculate offset to hide pin behind control
  const [mapDimensions, setMapDimensions] = useState({
    width: 100,
    height: 100,
  });

  /**
   * Set Marker attributes - lat/lng and whether it has been placed or is being dragged
   * @param event Drag event
   * @param idx Index of marker
   */
  const updateMarker = async (event, idx: number): Promise<void> => {
    if (!markers[idx]) {
      return;
    }
    const marker = {
      ...markers[idx],
      geolocation: {
        latitude: event.lngLat[1],
        longitude: event.lngLat[0],
      },
    };
    setMarker(marker, idx);

    const reverseGeo = await reverseGeolocation(marker.geolocation);

    if (reverseGeo?.display_name) {
      marker.locationName = reverseGeo.display_name;
    } else {
      delete marker.locationName;
    }

    marker.onUpdate();
  };

  const clearMarker = (idx: number): void => {
    if (!markers[idx]) {
      return;
    }
    const marker = {
      ...markers[idx],
      geolocation: undefined,
    };
    setMarker(marker, idx);
    marker.onUpdate();
  };

  /**
   * Set of map when dragged or zoomed
   * @param newViewport
   */
  const handleSetViewport = (newViewport: ViewState): void => {
    !props.disableControls &&
      setViewport({
        ...viewport,
        zoom: newViewport.zoom,
        center: {
          latitude: newViewport.latitude,
          longitude: newViewport.longitude,
        },
      });
  };

  /** Toggle the style of map shown */
  const toggleTileStyle = () => {
    if (!props.disableControls) {
      if (tileStyle === "streets-v11") {
        setTileStyle("satellite-v9"); // satellite
      } else {
        setTileStyle("streets-v11"); // street
      }
    }
  };

  const onViewportChange = props.disableControls
    ? () => undefined
    : handleSetViewport;

  return (
    <ReactMapGL
      {...viewport}
      latitude={viewport.center.latitude}
      longitude={viewport.center.longitude}
      mapStyle={`mapbox://styles/mapbox/${tileStyle}`}
      onViewportChange={onViewportChange}
      scrollZoom={!props.disableControls}
      reuseMaps
      onResize={setMapDimensions}
      mapboxApiAccessToken="pk.eyJ1IjoibWF0dHdvb2RydHMiLCJhIjoiY2t1NG1rYTJpMXplODJ2bzdqOGh4M2tzdCJ9.a82666CBkBxYheD7Pkhpwg"
    >
      <ZoomControl
        handleSetViewport={handleSetViewport}
        toggleTileStyle={toggleTileStyle}
      />
      <S.MarkerControlContainer>
        {markers.map((marker, idx) => (
          <MarkerControl
            key={idx}
            disabled={marker.visible()}
            color={marker.color}
            onMouseUp={() => clearMarker(idx)}
          />
        ))}
      </S.MarkerControlContainer>
      {markers.map((marker, idx) => (
        <S.MarkerContainer
          key={idx}
          mapDims={mapDimensions}
          visible={marker.visible()}
          idx={idx}
          dragging={marker.dragging}
        >
          <Marker
            latitude={
              (marker.geolocation && marker.geolocation.latitude) ||
              viewport.center.latitude
            }
            longitude={
              (marker.geolocation && marker.geolocation.longitude) ||
              viewport.center.longitude
            }
            offsetLeft={-15}
            offsetTop={-30}
            draggable={!props.disableControls}
            onDragStart={() => {
              if (props.disableControls) return null;
              setMarker({ ...marker, dragging: true }, idx);
            }}
            onDragEnd={(event) => {
              if (props.disableControls) return null;
              updateMarker(event, idx);
            }}
          >
            <Pin color={marker.color} visible={marker.visible()} />
          </Marker>
        </S.MarkerContainer>
      ))}
    </ReactMapGL>
  );
}
