import React, { FC, useState } from "react";
import { css } from "aphrodite/no-important";

import { SuggestionType } from "components/common/form/MultiInput/MultiInput";
import CollapsibleDiv, {
  CollapseCaret,
} from "components/common/CollapsibleDiv";
import Label from "components/common/form/Label";
import MultiInput from "components/common/form/MultiInput";

import propertiesStyles from "../../styles";
import tagsStyles from "./styles";
import baseStyles from "../../../../styles";
import TagsCount from "./TagsCount";
import { Tag } from "./types";

interface Props {
  title: string;
  id: number;
  tags: Tag[];
  toggleTag: (tag: SuggestionType) => void;
  disabled?: boolean;
}

const TagsSection: FC<Props> = ({ title, id, tags, toggleTag, disabled }) => {
  const [collapsed, setCollapsed] = useState<boolean>(false);

  const ps = propertiesStyles();
  const ts = tagsStyles();
  const bs = baseStyles();

  const toggleCollapse = () => setCollapsed((currState) => !currState);

  return (
    <div className={css(ts.tagsSection)}>
      <div className={css([bs.row, bs.spaceBetween])}>
        <Label className={css(ps.label)} htmlFor={String(id)}>
          {title}
        </Label>
        <CollapseCaret collapsed={collapsed} toggleCollapse={toggleCollapse} />
      </div>
      <TagsCount count={tags.length} />
      <CollapsibleDiv collapsed={collapsed}>
        <MultiInput
          className={css([ps.multiInput, ts.multiInput])}
          labelField="title"
          idField="id"
          name={String(id)}
          itemClassName={css(ts.multiInputItem)}
          itemAction={toggleTag}
          selectedValues={(tags as unknown) as SuggestionType[]}
          disabled={disabled}
        />
      </CollapsibleDiv>
    </div>
  );
};

export default TagsSection;
