import React from "react";
// import styled from "styled-components";

// import COLORS from "themes/colors";

//@NOTE: this is temporary, mainly for dev purposes
// We're disabled rendering the theme button - Trevor
// const ThemeButton = styled.button<{ darkMode: boolean }>`
//   position: absolute;
//   top: 15%;
//   right: 0;
//   z-index: 1000000;
//   padding: 15px;
//   color: #fd7e14;
//   border: ${({ darkMode }) => darkMode && "none"};
//   background-color: ${({ darkMode }) => (darkMode ? COLORS.masterColors.darkGrey : COLORS.masterColors.white)};
// `;

export interface ThemeContextTypes {
  darkMode: boolean;
  toggleDarkMode: () => void;
}
/**
 * **ThemeContext** is used to manage values which impact the team.
 * This can be values like `darkMode`
 */
const ThemeContext = React.createContext<ThemeContextTypes>({
  darkMode: false,
  toggleDarkMode: () => {},
});

export interface ThemeContextProviderProps {
  children?: React.ReactNode;
}

/**
 * **ThemeContextProvider** lets us house the state for __ThemeContext__
 * in an area of the app which will always be rendered.
 *
 * And example can be to invoke this component in `App.tsx`
 */
const ThemeContextProvider = ({ children }: ThemeContextProviderProps) => {
  const [darkMode, toggleDarkMode] = React.useState(false);

  return (
    <ThemeContext.Provider
      value={{
        darkMode,
        toggleDarkMode: () => toggleDarkMode(!darkMode),
      }}
    >
      {/* <ThemeButton darkMode={darkMode} onClick={() => toggleDarkMode(!darkMode)}>
        {darkMode ? <span className="icon icon-icons8-moon_symbol" /> : <span className="icon icon-icons8-sun" />}
      </ThemeButton> */}
      {children}
    </ThemeContext.Provider>
  );
};

export { ThemeContextProvider, ThemeContext as default };
