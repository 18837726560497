import React, { useEffect, useState } from "react";
import {  useDispatch } from "react-redux";
import { Action } from "redux";
import { Formik } from "formik";
import { RouteComponentProps, withRouter } from "react-router";
import { ThunkDispatch } from "redux-thunk";

import * as US from "../addUser/styles";
import Breadcrumbs from "components/common/Breadcrumbs";
import Loader from "components/common/Loader";
import TextInput from "components/common/form/TextInput";
import Toast from "components/common/Toast";
import { AppState } from "store";
import { ClientGroup } from "store/clientGroups/types";
import { PageContent } from "../styles";
import { addClientGroup, getClientGroup } from "store/clientGroups/actions";

// Constants

type ErrorType = {
  externalGroupId?: string;
  name?: string;
};
const validate = (values) => {
  // Validates Formik form
  const errors: ErrorType = {};
  if (values) {
    if (!values.name) {
      errors.name = "Group Name is required";
    }
    if (!values.externalGroupId) {
      errors.externalGroupId = "Group ID is required";
    }
  }
  return errors;
};

type AddGroupProps = RouteComponentProps

const AddGroup = (props: AddGroupProps) => {
  const { history } = props;

  // Dispatch
  const dispatch = useDispatch<ThunkDispatch<AppState, void, Action>>();

  // Get group id from url
  const params: { id?: number } = props.match.params;
  const groupId = params.id;

  // Current group
  const [currentGroup, setCurrentGroup] = useState<ClientGroup>({
    name: "",
    externalGroupId: "",
  });
  const [currentGroupLoading, setCurrentGroupLoading] = useState(false);

  // Error toast visible
  const [errorToastVisible, setErrorToastVisible] = useState<boolean>(false);

  // Async form submit loading (API)
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);

  // On mount
  useEffect(() => {
    if (groupId) {
      setCurrentGroupLoading(true);
      dispatch(getClientGroup(groupId)).then((e) => {
        if (e.type === "GET_CLIENT_GROUP_SUCCESS") {
          setCurrentGroup(e.response);
        }
        setCurrentGroupLoading(false);
      });
    }
  }, [groupId]);

  // Submits the form
  const _handleSubmit = (values) => {
    const { externalGroupId, name } = values;
    const body = {
      detail: "",
      externalGroupId,
      id: groupId || null,
      isCompletedData: true,
      name,
      source: "API",
    };
    setSubmitLoading(true);
    dispatch(addClientGroup(body)).then((e) => {
      setSubmitLoading(false);
      if (e.type === "ADD_CLIENT_GROUP_SUCCESS") {
        history.goBack();
      } else {
        setErrorToastVisible(true);
      }
    });
  };

  const addEditGroupPathName = groupId ?
    `Edit ${currentGroup.name} Group` :
    "Add New Group";

  const addEditGroupHref = groupId ?
    `/people/groups/view/${groupId}` :
    "/people/groups/add-group"; 

  return (
    <PageContent>
      <div className="col-12">
        <Breadcrumbs
          paths={[
            {
              pathName: "People",
            },
            {
              pathName: "Groups",
              href: "/people/groups",
            },
            {
              pathName: addEditGroupPathName,
              href: addEditGroupHref,
            },
          ]}
        />
      </div>
      <Loader loading={currentGroupLoading}>
        <div className="col-12 mt-3 mb-3">
          <Formik
            initialValues={currentGroup}
            onSubmit={(values, { setFieldTouched }) => {
              // Set all fields to touched
              setFieldTouched("name", true, true);
              _handleSubmit(values);
            }}
            enableReinitialize
            validate={validate}
            validateOnChange={false}
            // validateOnBlur={false}
          >
            {(props) => {
              const { errors, handleSubmit, setValues, touched, values } = props;
              return (
                <form onSubmit={handleSubmit}>
                  <div className="pl-0 pr-0">
                    <div style={{ width: "398px" }}>
                      <TextInput
                        error={errors["name"]}
                        label="Group Name"
                        name="name"
                        onChange={(e) => {
                          setValues({
                            ...values,
                            // externalGroupId: getExternalGroupId(e.target.value),
                            name: e.target.value,
                          });
                        }}
                        placeholder="Group Name"
                        touched={touched["name"]}
                        value={values["name"]}
                        variant="outlined"
                      />
                    </div>
                    <div style={{ width: "398px" }}>
                      <TextInput
                        error={errors["externalGroupId"]}
                        label="Group ID"
                        name="externalGroupId"
                        onChange={(e) => {
                          setValues({
                            ...values,
                            externalGroupId: e.target.value,
                          });
                        }}
                        placeholder="Group ID"
                        touched={touched["externalGroupId"]}
                        value={values["externalGroupId"]}
                        variant="outlined"
                      />
                    </div>
                  </div>

                  <Toast variant="error" onClick={() => setErrorToastVisible(false)} visible={errorToastVisible}>
                    Failed to {groupId ? "Edit" : "Add"} Group
                  </Toast>
                  <br />
                  <US.ButtonsWrapper>
                    <US.AddUserButton disabled={submitLoading}>
                      <US.LoaderWrapper>
                        <Loader loading={submitLoading} className="p-0 mr-3" spinnerProps={{ size: 14 }} />
                      </US.LoaderWrapper>
                      <span>{`${groupId ? "edit" : "add"}`} group </span>
                    </US.AddUserButton>
                    <US.CancelButton disabled={submitLoading} onClick={() => history.goBack()}>
                      cancel
                    </US.CancelButton>
                  </US.ButtonsWrapper>
                </form>
              );
            }}
          </Formik>
        </div>
      </Loader>
    </PageContent>
  );
};


export default withRouter(AddGroup);
