import React, { useContext } from "react";

import Loader from "components/common/Loader";
import Icon from "components/common/Icon";
import { ThemeContext } from "styled-components";
import { TextButton } from "components/common/Button";

interface Props {
  onLoadMore: () => void;
  isLoadingMore?: boolean;
  isFinalPage?: boolean;
}

const MoreOptionsLoader = ({ onLoadMore, isLoadingMore, isFinalPage }: Props) => {
  const theme = useContext(ThemeContext);

  if (isFinalPage) return null;

  return (
    <div className="moreOptionsLoader">
      <div className="moreOptionsButton">
        <Loader loading={isLoadingMore}>
          <div>
            <Icon type="add" size="16px" color={theme.colors.primary} />
            <TextButton
              onClick={() => {
                if (!isLoadingMore && onLoadMore) {
                  onLoadMore();
                }
              }}
            >
              Load more options
            </TextButton>
          </div>
        </Loader>
      </div>
      <div className="borderLine" />
    </div>
  );
};

export default MoreOptionsLoader;
