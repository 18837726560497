import React, { Dispatch, SetStateAction } from "react";
import { useHistory } from "react-router";
import { useSelector } from "react-redux";

import RehuddleSidebarContent from "components/screens/documents/components/RehuddleSidebar/RehuddleSidebar";
import Drawer from "components/common/Drawer";
import { DocumentSummary, DocumentVM } from "store/documents/types";
import { getCurrentDocumentId } from "store/newDocument/selectors";

interface Props {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  isDesktop?: boolean;
  onSuccess: () => void;
  selectedDocument: DocumentSummary | DocumentVM | null;
}

const RehuddleSidebar = ({ isOpen, setIsOpen, isDesktop, onSuccess, selectedDocument }: Props) => {
  const history = useHistory();
  const currentDocumentId = useSelector(getCurrentDocumentId);
  return (
    <Drawer
      content={
        <RehuddleSidebarContent
          onSuccess={onSuccess}
          toggleRehuddleSidebarVisibility={() => setIsOpen(false)}
          selectedDocument={selectedDocument}
          history={history}
          currentDocumentId={currentDocumentId}
        />
      }
      anchor="right"
      disableBackdrop
      paperProps={{ elevation: 0 }}
      id="RehuddleSidebar"
      open={isOpen}
      onClose={() => setIsOpen(false)}
      overrideClasses={{
        container: "drawerContainerNoBackdrop",
        content: isDesktop ? "myDocumentsDrawerDesktop" : "",
      }}
      showCloseIcon={false}
    />
  );
};

export default RehuddleSidebar;
