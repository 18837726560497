import React, { useState } from "react";
import { Field, useFormikContext } from "formik";

import { WorkflowType } from "store/forms/types";
import Button, { TextButton } from "components/common/Button";
import Select from "components/common/form/Select";

import { getFormStatusString, isFormPublished } from "../../helpers";
import style from "./styles";
import { css } from "aphrodite/no-important";
import { FBForm } from "../../types";

const workflowTypeOptions = [
  { id: "DRAFT", value: "Draft" },
  { id: "FINAL", value: "Published" },
];

type StatusUpdateButtonsProps = {
  onCancel: () => void;
  onSubmit: () => void;
};

const StatusUpdateButtons = ({
  onCancel,
  onSubmit,
}: StatusUpdateButtonsProps) => {
  const bs = style();
  return (
    <div className={css(bs.centerFlex)}>
      <TextButton variant="cancel" onClick={onCancel}>
        Cancel
      </TextButton>
      <Button onClick={onSubmit}>Update</Button>
    </div>
  );
};

type StatusProps = {
  status?: WorkflowType;
  onSubmit: (workflowType?: WorkflowType) => void;
  isPublishing?: boolean;
  onPublish: () => void;
};

const Status = ({ status, onSubmit, isPublishing, onPublish }: StatusProps) => {
  // Formik
  const { values, setFieldValue } = useFormikContext<FBForm>();
  const [displayStatusField, setDisplayStatusField] = useState<boolean>(false);
  const s = style();
  const isPublished = isFormPublished(values.workflowType);

  return (
    <div
      className={css(
        isPublished && !displayStatusField && s.headerRightPublished
      )}
    >
      <div className={css(s.statusContainer)}>
        <span>Status:&nbsp;</span>
        {/* Status + Edit Link or Status Dropdown */}
        {displayStatusField ? (
          <Field
            name="workflowType"
            as={Select}
            options={workflowTypeOptions}
          />
        ) : (
          <>
            <span className={css(s.status)}>{getFormStatusString(status)}</span>
            {status === "FINAL" && (
              <TextButton
                className={css(s.editLink)}
                variant="link"
                onClick={() => setDisplayStatusField(true)}
              >
                Edit
              </TextButton>
            )}
          </>
        )}
      </div>

      {/* Publish or Update/Cancel buttons */}
      {displayStatusField ? (
        <StatusUpdateButtons
          onCancel={() => {
            setFieldValue("workflowType", "FINAL"); // Set type back to "FINAL"
            setDisplayStatusField(false);
          }}
          onSubmit={() => {
            onSubmit();
            setDisplayStatusField(false);
          }}
        />
      ) : (
        status !== "FINAL" &&
        status !== "DEACTIVATED" && (
          <Button
            loading={isPublishing}
            className={css(s.publishButton)}
            onClick={onPublish}
          >
            Publish
          </Button>
        )
      )}
    </div>
  );
};

export default Status;
