import { PaginationParams } from "store/common/types";

import {
  FiltersActions,
  FormTemplatesFiltersArgs,
  FORM_TEMPLATES_FILTERS,
} from "./types";

const initialFilters = {
  groupId: -1,
  formType: -1,
  query: "",
};

const initialPaginationParams: PaginationParams = {
  page: 0,
  size: 10,
  sort: ["title", "asc"],
};

const initialState: FormTemplatesFiltersArgs = {
  filterParams: initialFilters,
  paginationParams: initialPaginationParams,
};

export function formTemplatesReducer(
  state = initialState,
  action: FiltersActions
): FormTemplatesFiltersArgs {
  const { response, type } = action;

  switch (type) {
    case FORM_TEMPLATES_FILTERS.SET:
      return { ...state, filterParams: response };

    case FORM_TEMPLATES_FILTERS.PARAMS_SET:
      return { ...state, paginationParams: response };

    default:
      return state;
  }
}
