import React, { useState } from "react";

import * as S from "./styles";

export type CommentVariant = "DEFAULT" | "RATING" | "DEFENSE";

export type CommentRefType = HTMLInputElement;

export interface CommentProps {
  className?: string;
  commentValue?: string | null;
  error?: string;
  /**
   * boolean which determines if user has elected to
   * add a comment/use this feature
   */
  enabled?: boolean; // To support undefined
  handleClearComment?: () => void;
  handleUpdateComment: (value: string) => void;
  inputRef?: React.Ref<CommentRefType>;
  name: string;
  passedRef?: React.RefObject<HTMLInputElement>;
  required?: boolean;
  shouldRenderClearCommentButton?: boolean;
  variant?: CommentVariant;
}

const Comment = ({
  className,
  commentValue,
  error,
  enabled,
  handleClearComment,
  handleUpdateComment,
  inputRef,
  name,
  passedRef,
  required,
  shouldRenderClearCommentButton,
  variant = "DEFAULT",
}: CommentProps) => {
  const [isMouseFocused, setIsMouseFocused] = useState<boolean>(false);

  const handleBlur = () => {
    setIsMouseFocused(false);
  };

  const handleMouseDown = () => {
    setIsMouseFocused(true);
  };

  if (!enabled) {
    return null;
  }

  return (
    <S.Comment className={className} commentVariant={variant}>
      <S.CommentTextField
        InputProps={{
          endAdornment: shouldRenderClearCommentButton ? (
            <S.ClearCommentButton onClick={handleClearComment}>
              Clear
            </S.ClearCommentButton>
          ) : required &&
            <S.Required>* required</S.Required>,
          ref: passedRef,
        }}
        error={!!error}
        required={required}
        inputRef={inputRef}
        isMouseFocused={isMouseFocused}
        commentVariant={variant}
        name={name}
        value={commentValue}
        placeholder="Add comment"
        onBlur={handleBlur}
        onChange={({ target: { value } }) => handleUpdateComment(value)}
        onMouseDown={handleMouseDown}
        fullWidth
        /**
         * for MUI, enables text row/area
         */
        multiline
        rows={variant === "RATING" ? 4 : 1}
        rowsMax={variant === "RATING" ? 4 : 2}
        variant="outlined"
      />
      {error && <S.ErrorText variant="error">{error}</S.ErrorText>}
    </S.Comment>
  );
};

Comment.defaultProps = {
  variant: "DEFAULT",
};

export default Comment;
