import { StyleDeclarationValue, StyleSheet } from "aphrodite";
import { CreateStyleSheet } from "util/hooks/useThemedStyleSheet";
import mediaQueries from "themes/mediaQueries";

export interface DrawerStyles {
  disableSwipe?: StyleDeclarationValue;
}

export const drawerStyles: CreateStyleSheet<DrawerStyles> = () => {
  return StyleSheet.create({
    disableSwipe: {
      [`@media (${mediaQueries.minDesktop})`]: {
        display: "none"
      }
    }
  });
};
