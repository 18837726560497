import * as R from "ramda";
import { get } from "lodash";

import { AppState } from "../index";

import { DocumentsState, DocumentSummary, DocumentVM } from "./types";

/**
 * **documentStatusSelector** will return a flag which indicates
 * whether or not the __Documents__ feature is loading
 * @documentState reducer/store for Document feature
 * @documentId target document which we'll return the status for
 * @statusType "loading" or "error" to return the specified property
 * @defaultReturn = `false` (optional) if the path resolves to undefined then we'll default
 * to returning this value.
 * @checkForDeletionState = `false` (optional) when true, will check for the value within the `deleteDocument` property rather than the `singleDocument` property
 *
 * @returns `boolean`
 */
export const documentStatusSelector = ({
  documentState,
  documentId,
  statusType,
  defaultReturn = false,
  checkForDeletionStatus = false,
}: {
  documentState: DocumentsState;
  documentId: number;
  statusType: "loading" | "error";
  defaultReturn?: boolean;
  checkForDeletionStatus?: boolean;
}): boolean => {
  return R.pathOr(
    defaultReturn,
    [statusType, checkForDeletionStatus ? "deleteDocument" : "singleDocument", documentId],
    documentState,
  );
};

/**
 * returns the direct results of the Document API call content
 * an array of Documents with the type __DocumentVM__
 *
 * @param documentState
 */
export const participantDocumentsSelector = (documentState: DocumentsState) => {
  if (documentState.data.participantDocuments && documentState.data.participantDocuments.content) {
    return documentState.data.participantDocuments.content;
  }

  return [];
};

/**
 * If the document exists _with its responses_, returns it. Otherwise, returns null.
 * @param documentId
 * @param documentState
 */
export const getDocumentWithResponses = (documentId: number, documentState: DocumentsState) => {
  const document = participantDocumentsSelector(documentState).find((doc) => doc.id === documentId) as DocumentVM;
  if (document && document.responses) return document;
  return null;
};

/**
 * Finds the response for the passed question ID.
 * @param document
 * @param questionId
 */
export const responseFor = (document: DocumentVM, questionId?: number) => {
  const answerIfNotFound = { answer: null, comments: null };
  if (!document.responses || !questionId) {
    return answerIfNotFound;
  }

  const answer = document.responses.find((response) => response.questionId === questionId) || answerIfNotFound;

  // Customize answers for display here
  if (answer.answer === "true") {
    answer.answer = "Yes";
  }

  return answer;
};

/**
 * returns an array of the `content` of the results for the API call
 * when searching for Documents
 * @param documentState
 */
export const searchDocumentsSelector = (documentState: DocumentsState) => {
  if (documentState.data.searchDocuments && documentState.data.searchDocuments.content) {
    return documentState.data.searchDocuments.content;
  }
  return [];
};

// searchDocumentsSelector but usable with useSelector
export const getSearchDocuments = (store: AppState) =>
  searchDocumentsSelector(store.documents);

// @NOTE: Same as `searchDocumentsSelector` but this will work off of the Store
// We need that to happen if we want this to work with useSelector
export const selectSearchDocuments = (store: AppState) => get(store, "documents.data.searchDocuments.content", []);

export type SearchDocumentsForFormReportSignature = {
  searchResults: Array<DocumentSummary>;
  totalElements?: number;
};

/**
 * used for __FormReport.tsx__ to return the data specific to that component
 * - Documents from search endpoint
 * - `totalElements` property from search endpoint
 * @param documentState
 */
export const searchDocumentsForFormReport = (documentState: DocumentsState): SearchDocumentsForFormReportSignature => {
  if (documentState.data.searchDocuments && documentState.data.searchDocuments.content) {
    return {
      searchResults: documentState.data.searchDocuments.content,
      totalElements: documentState.data.searchDocuments.totalElements,
    };
  }

  return {
    searchResults: [],
    totalElements: 0,
  };
};

/**
 * **documentStoreParticipantDocumentsContentSelector** returns
 * `documentReducer.data.participantDocuments.content` and will
 * default to returning an empty array.
 */
export const documentStoreParticipantDocumentsContentSelector = (
  documentState: DocumentsState,
): Array<DocumentSummary> => {
  if (documentState.data && documentState.data.participantDocuments) {
    return documentState.data.participantDocuments.content;
  }

  return [];
};

/**
 * Gets values used in `MyDocuments.tsx`
 */
export const selectMyDocumentsProps = ({ documents }: AppState) => ({
  isLoadingSearchInfinite: get(documents, "loading.searchDocumentsInfinite.GET"),
  isSearchLoading: get(documents, "loading.searchDocuments.GET"),
  lastPageSearch: get(documents, "data.searchDocuments.last", false),
  searchDocumentsError: get(documents, "error.searchDocuments"),
});
