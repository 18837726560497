import { css } from "aphrodite/no-important";
import { ReactComponent as Back } from "assets/svg/back.svg";
import React, { MouseEventHandler } from "react";
import { useMouseFocus } from "util/hooks/useMouseFocus";
import {
  CreateStyleSheet,
  useThemedStyleSheet
} from "util/hooks/useThemedStyleSheet";
import { BackIconStyle, backIconStyles, styles } from "./navButtonStyles";

interface NavButtonProps {
  handleClick: MouseEventHandler<HTMLButtonElement>;
  label: string;
  iconStyle?: CreateStyleSheet<BackIconStyle>;
}

export const NavButton = ({
  handleClick,
  label,
  iconStyle = backIconStyles,
}: NavButtonProps) => {
  const { eventHandlers, isMouseFocused } = useMouseFocus();
  const themedStyles = useThemedStyleSheet(styles);
  const iconThemedStyles = useThemedStyleSheet(iconStyle);

  return (
    <button
      aria-label={label}
      className={css(
        themedStyles.navButton,
        !isMouseFocused && themedStyles.navButtonKeyboardFocused
      )}
      onClick={handleClick}
      type="button"
      {...eventHandlers}
    >
      <Back className={css(iconThemedStyles.icon)} />
    </button>
  );
};
