import { css } from "aphrodite/no-important";
import { Moment } from "moment";
import React, { FocusEventHandler, useEffect, useRef } from "react";
import usePrevious from "util/hooks/usePrevious";
import { useThemedStyleSheet } from "util/hooks/useThemedStyleSheet";
import { styles } from "./calendarStyles";
import { Cell, CellRef } from "./Cell";

interface CalendarProps {
  focusedDate: Moment;
  handleBlur: FocusEventHandler<HTMLButtonElement>;
  handleFocus: FocusEventHandler<HTMLButtonElement>;
  handleFocusChange: (date: Moment) => void;
  handleSelectionChange: (date: Moment) => void;
  labelId: string;
  maxDate?: Moment;
  minDate?: Moment;
  selectedEndDate?: Moment | null;
  selectedStartDate: Moment | null;
  shouldHideAutoFocus?: boolean;
  shouldAutoFocus?: boolean;
}

interface ColumnHeader {
  abbreviation: string;
  fullName: string;
}

interface DayRecord {
  date: Moment;
  dayOfMonth: number;
  id: string;
}

const getDayByWeek = (monthStart: Moment): DayRecord[][] => {
  const dayOfWeekStart = monthStart.day();
  const calendarStart = monthStart.clone().subtract(dayOfWeekStart, "days");

  const maxRows = 6;
  const daysPerWeek = 7;

  let daysByWeek: DayRecord[][] = [];
  for (let i = 0; i < maxRows; i++) {
    let week: DayRecord[] = [];
    for (let j = 0; j < daysPerWeek; j++) {
      const date = calendarStart.clone().add(daysPerWeek * i + j, "days");
      const day: DayRecord = {
        date,
        dayOfMonth: date.date(),
        id: date.toISOString(),
      };
      week.push(day);
    }
    daysByWeek.push(week);
  }

  return daysByWeek;
};

const columnHeaders: ColumnHeader[] = [
  { abbreviation: "S", fullName: "Sunday" },
  { abbreviation: "M", fullName: "Monday" },
  { abbreviation: "T", fullName: "Tuesday" },
  { abbreviation: "W", fullName: "Wednesday" },
  { abbreviation: "T", fullName: "Thursday" },
  { abbreviation: "F", fullName: "Friday" },
  { abbreviation: "S", fullName: "Saturday" },
];

export const Calendar = ({
  focusedDate,
  handleBlur,
  handleFocus,
  handleFocusChange,
  handleSelectionChange,
  labelId,
  maxDate,
  minDate,
  selectedEndDate,
  selectedStartDate,
  shouldAutoFocus,
  shouldHideAutoFocus,
}: CalendarProps) => {
  const monthStart = focusedDate.clone();
  monthStart.startOf("month");
  const previousMonthStart = usePrevious(monthStart.clone());
  const daysByWeek = getDayByWeek(monthStart);

  const cellRefsById = useRef(new Map<string, CellRef>());
  const themedStyles = useThemedStyleSheet(styles);

  const handleCellRef = (cell: CellRef, id: string) => {
    cellRefsById.current.set(id, cell);
  };

  useEffect(() => {
    if (shouldAutoFocus) {
      const cell = cellRefsById.current.get(focusedDate.toISOString());
      cell?.focus();
    }
  }, [focusedDate, shouldAutoFocus]);

  useEffect(() => {
    if (!previousMonthStart?.isSame(monthStart)) {
      cellRefsById.current.clear();
    }
  }, [previousMonthStart, monthStart]);

  return (
    <table
      aria-labelledby={labelId}
      className={css(themedStyles.calendar)}
      role="grid"
    >
      <thead>
        <tr>
          {columnHeaders.map(({ abbreviation, fullName }) => (
            <th
              className={css(themedStyles.columnHeader)}
              key={fullName}
              scope="col"
            >
              <span aria-hidden="true">{abbreviation}</span>
              <span className="sr-only">{fullName}</span>
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {daysByWeek.map((week) => (
          <tr key={week[0].id}>
            {week.map(({ date, dayOfMonth, id }) => {
              const handleRef = (cell: CellRef) => {
                handleCellRef(cell, id);
              };
              return (
                <Cell
                  date={date}
                  dayOfMonth={dayOfMonth}
                  handleBlur={handleBlur}
                  handleFocus={handleFocus}
                  handleFocusChange={handleFocusChange}
                  handleSelectionChange={handleSelectionChange}
                  isDisabled={
                    date.month() != monthStart.month() ||
                    (!!maxDate && date.isAfter(maxDate)) ||
                    (!!minDate && date.isBefore(minDate))
                  }
                  isFocusVisible={!shouldHideAutoFocus}
                  isFocused={date.isSame(focusedDate)}
                  isHidden={date.month() != monthStart.month()}
                  isSelected={
                    (!!selectedStartDate && date.isSame(selectedStartDate, "day")) ||
                    (!!selectedEndDate && date.isSame(selectedEndDate, "day"))
                  }
                  isWithinRange={
                    !!selectedStartDate &&
                    !!selectedEndDate &&
                    date.isBetween(
                      selectedStartDate,
                      selectedEndDate,
                      undefined,
                      "[]"
                    )
                  }
                  key={id}
                  ref={handleRef}
                />
              );
            })}
          </tr>
        ))}
      </tbody>
    </table>
  );
};
