import { useContext } from "react";
import { CSSProperties } from "react";
import { StyleSheet } from "aphrodite";
import { ThemeContext } from "styled-components";
import { remCalc } from "../../../../themes/helpers";

export default function(): { [key: string]: CSSProperties } {
  const theme = useContext(ThemeContext);
  return StyleSheet.create({

    label: {
      fontSize: remCalc(14),
      color: theme.colors.mediumGrey,
      marginTop: 0,
    },

    picker: {
      marginRight: remCalc(8),
    },

    row: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },

    col: {
      display: "flex",
      flexDirection: "column",
    },

  });
};
