import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "../../common/styled/Table";
import { SortDirection } from "../../../store/common/types";
import * as S from "./styles";
import Loader from "../../common/Loader";
import Icon from "../../common/Icon";
import { StyledLinkCaretRight } from "../../common/styled/StyledLinkCaretRight";
import RowsPerPage from "../../common/TableUI/RowsPerPage";
import Pagination, { PaginationWrapper } from "../../common/TableUI/Pagination";
import BackToTop from "../../common/TableUI/BackToTop";
import { AllFormStats } from "../../../store/reporting/forms/types";

type Props = {
  loadingStats?: boolean;
  formStats?: Array<AllFormStats>;
  sortingType: string;
  setSortingType: (t: string) => void;
  sortingDirection: SortDirection;
  setSortingDirection: (d: SortDirection) => void;
  formsPagination: {
    currentPage: number;
    totalPages: number;
    pageSize: number;
  };
  setNewPageNum: (n: number) => void;
  setNewPageSize: (s: number) => void;
  queryString: string;
};

export const ReportsTable = ({
  loadingStats,
  formStats,
  sortingType,
  setSortingType,
  sortingDirection,
  setSortingDirection,
  formsPagination,
  setNewPageNum,
  setNewPageSize,
  queryString,
}: Props) => {
  /**
   * Update sorting parameters of parent
   * @param type      - sort by
   * @param direction - direction
   */
  const sortBy = (type: string, direction: SortDirection) => {
    setSortingDirection(direction);
    setSortingType(type);
  };

  return (
    // Was requested that loader is here -- GK
    <Loader loading={loadingStats}>
      <Table>
        <TableHead>
          <TableRow noBottomBorder>
            <TableCell
              sortDirection={sortingDirection}
              active={sortingType === "type"}
              onClick={(dir) => sortBy("type", dir)}
              width="80px"
            >
              Type
            </TableCell>
            <TableCell
              sortDirection={sortingDirection}
              active={sortingType === "name"}
              onClick={(dir) => sortBy("name", dir)}
            >
              Name
            </TableCell>
            <TableCell
              sortDirection={sortingDirection}
              active={sortingType === "submissions"}
              onClick={(dir) => sortBy("submissions", dir)}
              align="center" width="150px"
            >
              Submissions
            </TableCell>
            <TableCell
              sortDirection={sortingDirection}
              active={sortingType === "groupUsage"}
              onClick={(dir) => sortBy("groupUsage", dir)}
              align="center" width="150px"
            >
              Group Usage
            </TableCell>
            <TableCell align="center" width="125px"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {formStats &&
          formStats.map((stats) => (
            <TableRow key={stats.form.id}>
              <TableCell>
                <Icon type={stats.form.type.iconName || ""} color={stats.form.type.iconColor || ""} framed={true} />
              </TableCell>
              <TableCell>{stats.form.name}</TableCell>
              <TableCell align="center">{stats.totalCount}</TableCell>
              <TableCell align="center">{stats.groupCounts.length}</TableCell>
              <TableCell>
                <S.ReportLink to={`/reports/${stats.form.id}${queryString}`}>
                        View Report
                  <StyledLinkCaretRight style={{ color: "inherit" }} />
                </S.ReportLink>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <PaginationWrapper>
        <RowsPerPage
          pageSize={formsPagination.pageSize || 10}
          onClick={(size) => {
            setNewPageNum(0);
            setNewPageSize(size);
          }}
        />
        <Pagination
          currentPage={formsPagination.currentPage}
          totalPages={formsPagination.totalPages}
          onClick={(page) => setNewPageNum(page)}
        />
        <BackToTop />
      </PaginationWrapper>
    </Loader>
  );
};
