import { StyleSheet } from "aphrodite";
import { remCalc } from "themes/helpers";
import { ThemeTypes } from "themes/colors";

export const createStyles = (theme: ThemeTypes) => {
  return StyleSheet.create({
    input: {
      appearance: "none",
      borderColor: theme.masterColors.darkGreyBlue,
      backgroundColor: theme.colors.white,
      borderRadius: "50%",
      borderStyle: "solid",
      borderWidth: remCalc(2),
      display: "inline-block",
      height: remCalc(24),
      margin: 0,
      width: remCalc(24),
      ":after": {
        borderRadius: "50%",
        content: "''",
        display: "block",
        height: remCalc(16),
        left: remCalc(2),
        position: "relative",
        transform: "scale(0)",
        transition: "transform .15s",
        top: remCalc(2),
        width: remCalc(16),
      },
      ":focus": {
        outline: "none",
      },
    },
    inputChecked: {
      backgroundColor: "transparent",
      borderColor: theme.colors.primary,
      ":after": {
        backgroundColor: theme.colors.primary,
        transform: "scale(1)",
        transition: "transform .15s",
      },
    },
    inputDisabled: {
      backgroundColor: theme.masterColors.greyBlue,
      borderColor: theme.masterColors.darkGreyBlue,
      ":after": {
        backgroundColor: theme.masterColors.darkGreyBlue,
      },
      ":checked": {
        ":after": {
          backgroundColor: theme.masterColors.darkGreyBlue,
        },
      },
    },
    inputEnabled: {
      ":hover": {
        backgroundColor: theme.masterColors.lightBlue,
      },
    },
    inputError: {
      backgroundColor: theme.colors.errorFill,
      borderColor: theme.colors.error,
      ":after": {
        backgroundColor: theme.colors.error,
      },
      ":checked": {
        ":after": {
          backgroundColor: theme.colors.error,
        },
      },
    },
    inputPill: {
      height: remCalc(36),
      width: remCalc(36),
      ":after": {
        height: remCalc(28),
        width: remCalc(28),
      },
    },
    inputStandard: {
      ":focus": {
        boxShadow: `0 0 0 ${remCalc(4)} ${theme.colors.focusIndicatorOutline}`,
      },
    },
    label: {
      alignItems: "center",
      borderRadius: remCalc(24),
      display: "inline-flex",
      flexDirection: "row",
      margin: 0,
      padding: remCalc(2),
    },
    labelCheckedPill: {
      backgroundColor: theme.masterColors.lightBlue,
    },
    labelCheckedDisabledPill: {
      backgroundColor: theme.masterColors.greyBlue,
    },
    labelCheckedErrorPill: {
      backgroundColor: theme.colors.errorFill,
    },
    labelPill: {
      padding: remCalc(6),
      ":focus-within": {
        boxShadow: `0 0 0 ${remCalc(4)} ${theme.colors.focusIndicatorOutline}`,
      },
    },
    labelText: {
      padding: `0 ${remCalc(24)} 0 ${remCalc(16)}`,
    },
    labelTextDisabled: {
      color: theme.masterColors.darkGreyBlue,
      display: "none",
    },
    labelTextError: {
      color: theme.colors.error,
    },
  });
};
