import { ClientGroup } from "store/clientGroups/types";
import { ClientGroupDTO } from "store/participants/types";
import { ErrorsType, AuthorityDTO, UserFormFields } from "./types";
import { groupRoleRegexPattern, emailValidationPattern, nameValidationPattern, RequiredMessage } from "./constants";
import { ParticipantSummary } from "store/participantsSummary/types";
import { UsersParticipant } from "store/usersParticipants/types";
import { WorkLocationContent } from "store/workLocation/types";

// User is a Client Reviewer
const userIsClientReviewer = (authorities: AuthorityDTO[]) => {
  const reviewerAuthority: AuthorityDTO | undefined = authorities
    ?.find((authority) => authority.authority === "ROLE_CLIENT_REVIEWER");
  if (reviewerAuthority && reviewerAuthority.groups?.length > 0) {
    return true;
  }
  return false;
};


// Check values for at least one role
export const atLeastOneRole = (values) => {
  let result = false;
  if (values) {
    for (const prop in values) {
      const matches = prop.match(groupRoleRegexPattern);
      if (matches && matches.length > 0) {
        if (values[matches[0]]) {
          result = true;
        }
      }
    }
  }

  return result;
};

// Build group options
export const generateGroupOptions = (options) => {
  const res = options.map((e) => ({
    id: e.id,
    value: e.name,
  }));
  return res || [];
};

// Sync validation function for Formik
export const validate = (values) => {
  const errors: ErrorsType = {};

  if (!values.email) {
    errors.email = RequiredMessage;
  } else {
    if (!emailValidationPattern.test(values.email)) {
      errors.email = "Please enter a valid email";
    }
  }
  if (!values.firstName) {
    errors.firstName = RequiredMessage;
  } else {
    if (!nameValidationPattern.test(values.firstName)) {
      errors.firstName = "Letters, numbers and dash only";
    }
  }
  if (!values.lastName) {
    errors.lastName = RequiredMessage;
  } else {
    if (!nameValidationPattern.test(values.lastName)) {
      errors.lastName = "Letters, numbers and dash only";
    }
  }

  // Primary group field error if creating a participant (createUser is false) -- GK
  if (!values.primaryGroupId) {
    errors.primaryGroupId = RequiredMessage;
  }


  // workLocation not required -- GK
  // if (!values.workLocationId || values.workLocationId === -1) errors.workLocationId = RequiredMessage;

  return errors;
};

// Form's initial values
export const getInitialValues = (user) => {
  let result = {
    // Default, for Add User
    createUser: true,
    email: "",
    firstName: "",
    isClientReviewer: false,
    lastName: "",
    nickname: "",
    primaryGroupId: 0, // No default primary group
    supervisorId: -1,
    workLocationId: -1,
  };
  if (user?.email) {
    // For Edit user
    const _createUser = !!(user.authorities?.length > 0); // user.createUser is always `null` here -- GK
    const _isClientReviewer = userIsClientReviewer(user.authorities);
    result = {
      createUser: _createUser,
      email: user.email,
      firstName: user.firstName,
      isClientReviewer: _isClientReviewer,
      lastName: user.lastName,
      nickname: user.nickname,
      primaryGroupId: user.primaryGroupId,
      supervisorId: user.supervisor && user.supervisor.id,
      workLocationId: user.workLocation && user.workLocation.id,
    };
  }
  return result;
};

// Check if a non-user role exists for this group
export const atLeastOneNonUserRoleInGroup = (groupId, values) => {
  let result = false;
  const pattern = RegExp(
    `^g-${groupId}-(ROLE_CLIENT_REVIEWER|ROLE_FORM_ARCHITECT|ROLE_CONTENT_EDITOR|ROLE_GROUP_MANAGER)$`,
  );
  if (values) {
    for (const prop in values) {
      const matches = prop.match(pattern);
      if (matches && matches.length > 0) {
        if (values[matches[0]]) {
          result = true;
        }
      }
    }
  }
  return result;
};

// Check if a non-user role tally is checked
export const atLeastOneNonUserTallyRole = (values) => {
  let result = false;
  const pattern = RegExp(
    "^all-(ROLE_CLIENT_REVIEWER|ROLE_FORM_ARCHITECT|ROLE_CONTENT_EDITOR|ROLE_GROUP_MANAGER)$",
  );
  if (values) {
    for (const prop in values) {
      const matches = prop.match(pattern);
      if (matches && matches.length > 0) {
        if (values[matches[0]]) {
          result = true;
        }
      }
    }
  }
  return result;
};

// Update authorities
// BE's model has `authority` as string, ideally we'd have actual roles here -- GK
export const updateAuthorities = (
  authorities = {}, 
  authorityToUpdate: string, 
  updatedGroups: number[], 
  method?: "replace" | "add",
) => {

  // Condition flags
  const authoritiesNotEmpty = Object.keys(authorities)?.length > 0;
  const updatedGroupsNotEmpty = updatedGroups?.length > 0;
  const existingGroupsInAuthority = !!(authorities[authorityToUpdate]);

  // Set updated groups array to update an authority with
  const updatedArray = method === "replace" ? updatedGroups : [
    ...(authoritiesNotEmpty && existingGroupsInAuthority? authorities[authorityToUpdate]: []),
    ...(updatedGroupsNotEmpty ? updatedGroups : []),
  ];

  return {
    ...authorities,
    [authorityToUpdate]: updatedArray,
  };
};

// Get an array of authorities, transform into an object with authority keys and
// value of an array of group IDs -- GK
export const flattenAuthorities = (authorities: AuthorityDTO[]) => {
  const result: {string?: number[]} = {};
  authorities?.forEach((authority: AuthorityDTO) => {
    result[authority.authority] = authority.groups;
  });
  return result;
};

// If creating a user, there needs to be at least one role that has at least one group -- GK
export const atLeastOneRoleHasGroup = (authorities: {string?: number[]}) => {
  let result = false;
  for (const [key] of Object.entries(authorities)) {
    if (authorities[key]?.length > 0) {
      result = true;
      break;
    }
  }
  return result;
};

// Build authorities array for Add/Edit User payload
export const getAuthorities = (authorities: {string?: number[]}, groups: ClientGroupDTO[], values: UserFormFields) => {
  const _authorities: AuthorityDTO[] = [];
  for (const [key, value] of Object.entries(authorities)) {
    if (value?.length) {
      if (key !== "ROLE_CLIENT_REVIEWER") {
        _authorities.push({
          authority: key,
          groups: value,
        });
      } else {
        // add client reviewer role if user set the option in form
        if (values.isClientReviewer) {
          const flattenedGroupIds = groups?.map((group) => group.id || 0) || [];
          _authorities.push({
            authority: "ROLE_CLIENT_REVIEWER",
            groups: flattenedGroupIds,
          });
        }
      }
    }
  }

  return _authorities;
};

/**
 * Map work location content to id/label options
 * @param workLocations
 */
export function mapWorkLocations(workLocations: WorkLocationContent[]) {
  let result: { id?: number | null; label: string }[] = [];
  result = workLocations.map((workLocation) => {
    return {
      id: workLocation.id,
      label: workLocation.name,
    };
  });
  return result;
};

/**
 * Map supervisors to id/label options
 * @param supervisors
 * @param currentUser
 */
export function mapSupervisorOptions(
  supervisors: ParticipantSummary[],
  currentUser: Partial<UsersParticipant>
) {
  const result: { id?: number | null; label: string }[] = supervisors.map((supervisor) => ({
    id: supervisor.id,
    label: supervisor.name,
  }));
  // Add supervisor option to list of initial supervisors if not there already
  if (currentUser.supervisor && supervisors) {
    // getting TS error without this check below
    const _supervisorId = currentUser.supervisor?.id;
    if (!supervisors.find((e) => e.id === _supervisorId)) {
      result.unshift({
        id: currentUser.supervisor.id,
        label: currentUser.supervisor.name,
      });
    }
  }
  return result;
}

/**
 * Map client groups to id/label options
 * @param clientGroups
 */
export function mapClientGroupsOptions(clientGroups: ClientGroup[]) {
  let result: { id?: number | null; label: string }[] = [];
  result = clientGroups.map((group: ClientGroupDTO) => {
    return {
      id: group.id || undefined,
      label: group.name || "Group name missing",
    };
  });
  return result;
}

export function buildBreadcrumbs(
  participantId?: number,
  currentUser?: Partial<UsersParticipant>
) {
  return [
    {
      pathName: "People",
      href: "/people",
    },
    {
      pathName: "Users",
      href: "/people/users",
    },
    {
      pathName: `${
        participantId
          ? `Edit User Profile${(currentUser?.fullName ? `: ${currentUser?.fullName}` : "")}`
          : "Add New User"
      }`,
      href: "",
    },
  ];
}
