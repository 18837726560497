import React, { useMemo } from "react";
import MediaQuery, { useMediaQuery } from "react-responsive";

import { devices } from "themes/mediaQueries";
import * as S from "../styles";


const handleButtonStatus = (
  status: string | null,
  loading: boolean,
  isDesktop?: boolean,
) => {
  if (loading) {
    return (
      <S.Spinner
        loading={`${loading}`}
        className="p-0 mr-3"
        spinnerProps={{ size: 14 }}
      />
    );
  }

  if (status === "success") {
    return (
      <>
        <S.Icon className="icon icon-icons8-checkmark" />
        DOCUMENT SHARED
      </>
    );
  }

  if (status === "failure") {
    return "FAILED TO SHARE";
  }

  return isDesktop ? "SHARE WITH TEAM MEMBER" : "SHARE";
};

interface Props {
  onCancel: () => void;
  submitStatus: "success" | "failure" | "request" | null;
}

export const ShareFormButtons = ({ submitStatus, onCancel }: Props) => {

  const submitButtonType = useMemo(() => {
    if (submitStatus !== "success") {
      return "submit";
    }
    return "button";
  }, [submitStatus]);

  const loading = useMemo(() => submitStatus === "request", [submitStatus]);

  const disabled = useMemo(() => {
    if (submitStatus) {
      return ["request, success"].includes(submitStatus);
    }
    return false;
  }, [submitStatus]);

  const isDesktop = useMediaQuery({
    query: `(min-device-width: ${devices.minDesktop})`,
  });

  const buttonStatus = useMemo(() => {

    return handleButtonStatus(submitStatus, loading, isDesktop); 
  }, [isDesktop, submitStatus, loading]);

  return <>
    
    {/* Buttons Desktop */}
    <MediaQuery minWidth={devices.minTablet}>
      <S.SubmitButton
        disabled={disabled}
        fullWidth={true}
        height={2.5}
        status={submitStatus}
        type={submitButtonType}
      >
        {buttonStatus}
      </S.SubmitButton>
      {submitStatus !== "success" && (
        <S.CancelButton
          disabled={disabled}
          fullWidth={true}
          height={2.5}
          onClick={onCancel}
          variant="unstyled-danger"
        >
          CANCEL
        </S.CancelButton>
      )}
    </MediaQuery>
                    
    {/* Buttons Mobile */}
    <MediaQuery maxWidth={devices.maxPhone}>
      <S.SubmitButton
        type="submit"
        fullWidth={true}
        height={3.5}
        status={submitStatus}
        disabled={disabled}
      >
        {buttonStatus}
      </S.SubmitButton>
      {submitStatus !== "success" && (
        <S.CancelButton
          disabled={disabled}
          onClick={onCancel}
          variant="unstyled-danger"
          fullWidth={true}
          height={3.5}
        >
          CANCEL
        </S.CancelButton>
      )}
    </MediaQuery>
  </>;
};
