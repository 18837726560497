import React from "react";
import { sortBy } from "lodash";

import Loader from "components/common/Loader";
import { FormTypeDTO, FormDTO } from "store/forms/types";

import { HandleSetFormValuesArgs } from "../NewForm";

import * as S from "../styles";

export interface SelectFormTypeProps {
  selectedFormTypeId: number | null;
  selectedFormTemplateId?: number;
  handleSelectFormTemplate: (formTemplate: FormDTO | null) => void;
  loading: boolean;
  handleSetFormValues: (args: HandleSetFormValuesArgs) => void;
  formTypes: Array<FormTypeDTO>;
}

// SELECT FORM TYPE
const SelectFormType = ({
  formTypes,
  handleSetFormValues,
  loading,
  selectedFormTypeId,
  selectedFormTemplateId,
  handleSelectFormTemplate,
}: SelectFormTypeProps) => {
  if (loading) {
    return <Loader loading />;
  }
  return (
    <>
      {sortBy(formTypes, "name").filter((formType) => formType.active).map((formType) => {
        if ((selectedFormTypeId && selectedFormTypeId !== formType.id) || !formType.active) {
          return null;
        }
        return (
          <S.ListItem
            key={formType.id}
            onClick={() => {
              selectedFormTemplateId && handleSelectFormTemplate(null);
              handleSetFormValues({
                valueType: "selectedFormTypeId",
                value: formType.id,
              });
            }}
            active={selectedFormTypeId === formType.id}
            icon={{ type: formType.iconName, color: formType.iconColor, framed: false }}
            label={formType.name}
            endAdornment={{
              type: "button",
              visible: selectedFormTypeId === formType.id,
              label: "Change",
              buttonType: "change",
            }}
            listItemColumnVariant="form-list-item"
          />
        );
      })}
    </>
  );
};

SelectFormType.defaultProps = {};

export default SelectFormType;
