import React, { useState, useEffect } from "react";
import { css } from "aphrodite/no-important";
import { get } from "lodash";
import { useFormikContext, Field } from "formik";
import { useSelector } from "react-redux";

import { AppState } from "store";
import {
  DataSource,
  DataSourceState,
  DataSourceValueDTO,
} from "store/dataSource/types";
import { DisplayConditionDTO } from "store/documents/types";
import { Option } from "components/common/form/Select/Select";
import {
  FBForm,
  Property,
  FBItem,
} from "components/clientAdmin/formBuilder/types";
import { QuestionAnswerSourceDTO } from "store/forms/types";
import Checkbox from "components/common/form/Checkbox";

import { getTargetItems, buildTargetOptions } from "./helpers";
import { ItemSelection } from "../../../content/ItemField";
import AddRuleButton from "./AddRuleButton";
import Condition from "./Condition";
import propertiesStyles from "../../styles";
import { NewDisplayCondition } from "store/builder/types";
import { isFormPublished } from "components/clientAdmin/formBuilder/helpers";

function buildSelectionResponses(
  values: FBForm,
  itemPath: string,
  itemSubType: string
) {
  const selections = get(values, `${itemPath}.selections`, []);

  if (itemSubType === "YES_OR_NO") {
    return [
      { id: true, value: "Yes" },
      { id: false, value: "No" },
    ];
  }

  return selections.map((option: ItemSelection) => ({
    id: option.rootId,
    value: option.title,
  }));
}

function buildAnswerSourceSelections(
  answerSource: QuestionAnswerSourceDTO,
  dataSource: DataSourceState
) {
  let selections: Option[] = [{ id: -1, value: "Please add options" }];
  if (answerSource.dataSourceKey) {
    const values: DataSourceValueDTO[] = get(
      dataSource.data,
      answerSource.dataSourceKey,
      []
    );
    selections = values.map((as: DataSourceValueDTO) => ({
      id: as.id,
      value: as.content[answerSource?.properties?.answerField || 0],
    }));
  }
  return selections;
}

function buildNewCondition(itemId: number): NewDisplayCondition {
  return {
    sourceQuestionRootId: itemId,
    targetRootId: 0,
    action: "",
    sourceConditionRootId: undefined,
    targetType: "",
    prefillAnswerField: "",
    prefillAssociatedIdField: "",
  };
}

interface Props {
  property: Property;
  itemPath: string;
  item: FBItem;
  dataSource?: DataSource;
}

const DisplayConditions = ({ property, itemPath, item, dataSource }: Props) => {
  const { values, setFieldValue } = useFormikContext<FBForm>();
  const [checked, setChecked] = useState<boolean>(false);

  const ps = propertiesStyles();
  const dataSources = useSelector(({ dataSource }: AppState) => dataSource);

  // form display conditions
  const formDisplayConditions: DisplayConditionDTO[] =
    values.displayConditions || [];
  // item display conditions
  const itemDisplayConditions: NewDisplayCondition[] = formDisplayConditions.filter(
    (fdc: DisplayConditionDTO) => fdc.sourceQuestionRootId === item.rootId
  );

  useEffect(() => {
    if (itemDisplayConditions.length > 0) {
      setChecked(true);
    } else {
      setChecked(false);
    }
  }, [item]);

  // answer source options
  let sourceColumns;
  if (dataSource?.type === "BASIC") {
    sourceColumns = [{ id: "value", value: "Basic Value" }];
  } else {
    sourceColumns =
      dataSource?.csvMappings.map((map) => ({
        id: map.columnName,
        value: map.columnName,
      })) || [];
  }

  // build response options from answerSource or item selections
  const responseOptions = item.answerSource
    ? buildAnswerSourceSelections(item.answerSource, dataSources)
    : buildSelectionResponses(values, itemPath, item.subType);

  // build target options
  const targetItems = getTargetItems(values.sections);
  const targetOptions = buildTargetOptions(targetItems, item);

  function addCondition() {
    const newCondition: NewDisplayCondition = buildNewCondition(item.rootId);
    setFieldValue("displayConditions", [
      ...formDisplayConditions,
      newCondition,
    ]);
  }

  function removeCondition(conditionIndex: number) {
    const updatedConditions = [...formDisplayConditions];
    updatedConditions.splice(conditionIndex, 1);
    setFieldValue("displayConditions", updatedConditions);
  }

  function updateCondition(
    conditionIndex: number,
    updates: Partial<NewDisplayCondition>
  ) {
    const updatedConditions = formDisplayConditions.map((fdc, index) => {
      if (index === conditionIndex) {
        return { ...fdc, ...updates };
      }
      return fdc;
    });
    setFieldValue("displayConditions", updatedConditions);
  }

  function updateConditionType(
    conditionIndex: number,
    type: DisplayConditionDTO["action"]
  ) {
    updateCondition(conditionIndex, {
      action: type,
      targetRootId: undefined,
      targetType: undefined,
      sourceConditionRootId: undefined,
      booleanCondition: undefined,
    });
  }

  function handleCheck(e: React.ChangeEvent<HTMLInputElement>) {
    if (e.target.checked) {
      addCondition();
      setChecked(true);
    } else {
      setChecked(false);
      // @TODO do we want to preserve the display conditions if they uncheck the rule?
      // if so we should keep a copy in a reducer/state
      setFieldValue(
        "displayConditions",
        formDisplayConditions.filter(
          (dc) => dc.sourceQuestionRootId !== item.rootId
        )
      );
    }
  }

  const isPublished = isFormPublished(values.workflowType);

  return (
    <div className="displayConditions">
      <Field
        as={Checkbox}
        className={css(ps.checkbox)}
        name={`${itemPath}.${property.name}.enabled`}
        label={property.label}
        checked={checked}
        onChange={handleCheck}
        disabled={isPublished}
      />
      {checked && (
        <div style={{ marginLeft: "1.8rem", marginTop: "1rem" }}>
          {itemDisplayConditions.map((condition, index) => {
            // display conditions may not have an ID :(
            const conditionIndex = formDisplayConditions.findIndex(
              (fdc) =>
                (fdc.id && fdc.id === condition.id) ||
                (!fdc.id &&
                  !condition.id &&
                  fdc.sourceQuestionRootId === condition.sourceQuestionRootId &&
                  fdc.targetRootId === condition.targetRootId &&
                  fdc.sourceConditionRootId ===
                    condition.sourceConditionRootId &&
                  fdc.booleanCondition === condition.booleanCondition)
            );
            return (
              <Condition
                key={index}
                ruleType={condition.action}
                conditionIndex={conditionIndex}
                itemIndex={index}
                itemSubType={item.subType}
                onRemove={removeCondition}
                onUpdateType={updateConditionType}
                responseOptions={responseOptions}
                targetOptions={targetOptions}
                targetItems={targetItems}
                dataSource={dataSource}
                sourceColumns={sourceColumns}
              />
            );
          })}
          {!isPublished && <AddRuleButton onAdd={addCondition} />}
        </div>
      )}
    </div>
  );
};

export default DisplayConditions;
