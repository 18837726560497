import React from "react";
import { css } from "aphrodite/no-important";

import tagsStyles from "./styles";
import baseStyles from "../../../../styles";

interface Props {
  count: number;
}

const TagsCount = ({ count }: Props) => {
  const ts = tagsStyles();
  const bs = baseStyles();
  return (
    <div className={css([bs.row, ts.tagsCount])}>
      <i className="icon icon-icons8-tags" />
      <span className={css(ts.tagsCountText)}>{count} tags</span>
    </div>
  );
};

export default TagsCount;
