import React from "react";

import colors from "themes/colors";
import Icon from "components/common/Icon";

import { LockedProps } from "../types";
import * as S from "../styles";

const Locked = ({ label, lockedValue, variant = "DEFAULT" }: LockedProps) => {
  return (
    <S.Locked>
      <S.Left>
        <Icon type="lock" framed={false} color={colors.masterColors.mediumGrey} />
        <S.Label as="p">{label || "..."}</S.Label>
      </S.Left>
      {typeof lockedValue === "string" ? (
        <S.ValueWrapper>
          <S.Value>{lockedValue}</S.Value>
          {variant === "SELECT" && <Icon type="sort_down" color={colors.masterColors.mediumGrey} />}
        </S.ValueWrapper>
      ) : (
        <S.GroupList>
          {lockedValue &&
            lockedValue.map((x) => (
              <S.GroupLabel locked key={x}>
                {x}
              </S.GroupLabel>
            ))}
        </S.GroupList>
      )}
    </S.Locked>
  );
};

export default Locked;
