import Downshift from "downshift";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import React, { useState, useContext, FC } from "react";
import TextField from "@material-ui/core/TextField";
import deburr from "lodash/deburr";
import matchSorter from "match-sorter";
import styled, { ThemeContext } from "styled-components";
import useDebounce from "util/hooks/useDebounce";
import { IconForEndAdornment } from "components/forms/MultiInput/styles";
import { CircularProgress } from "components/common/Loader/styles";

const childElement: React.CSSProperties = {
  position: "absolute",
  zIndex: 1,
};

const parentElement: React.CSSProperties = {
  zIndex: 0,
};

const StyledTextField = styled(
  ({ InputProps, fullWidth, notchedOutline, ...other }) => (
    <TextField fullWidth={true} InputProps={InputProps} {...other} />
  )
)`
  && .input {
    color: ${({ theme }) => theme.colors.primary};
    border: ${({ mobile, theme }) =>
      mobile && `1px solid ${theme.colors.black} !important`};
    border-radius: ${({ mobile }) => mobile && "4px"};
    font-weight: ${(props) =>
      (props.inputProps && props.inputProps.value && 500) || 400};
    ::placeholder {
      opacity: 1;
      color: ${({ theme }) => theme.masterColors.darkGrey};
    }
    padding: ${({ mobile }) => (mobile && "16px") || "4.5px 2px"};
  }
  && .underline {
    &:after {
      border-bottom: ${({ theme }) =>
        `1px solid ${theme.masterColors.lightGrey}`};
    }
  }
`;

function renderInput(inputProps) {
  const {
    InputProps,
    ref,
    customAutocomplete: CustomAutocomplete,
    ...other
  } = inputProps;
  if (CustomAutocomplete) {
    return (
      <CustomAutocomplete
        InputProps={{
          ...InputProps,
          // classes: {
          //   input: "input",
          //   underline: "underline",
          // },
          inputRef: ref,
        }}
        {...other}
      />
    );
  }
  return (
    <StyledTextField
      InputProps={{
        ...InputProps,
        classes: {
          input: "input",
          underline: "underline",
        },
        inputRef: ref,
      }}
      {...other}
    />
  );
}

export interface AutocompleteSuggestion {
  id: number;
  value: string;
}

const getSuggestions = (
  allSuggestions: Array<AutocompleteSuggestion> | undefined,
  value: string | null,
  { showEmpty = false } = {}
): AutocompleteSuggestion[] => {
  const inputValue = deburr(value?.trim()).toLowerCase();
  const inputLength = inputValue.length;

  const result =
    inputLength === 0 && !showEmpty
      ? []
      : matchSorter(allSuggestions, inputValue, { keys: ["value"] });

  if (result && result.length > 0) {
    return result.slice(0, 6);
  }
  return [];
};

interface SuggestionProps {
  highlightedIndex: number | null;
  index: number;
  itemProps: any;
  selectedItem: any;
  suggestion: AutocompleteSuggestion;
  suggestionMinWidth?: number;
}

const Suggestion: React.FC<SuggestionProps> = (suggestionProps) => {
  const {
    suggestion,
    index,
    itemProps,
    highlightedIndex,
    selectedItem,
    suggestionMinWidth = "100%",
  } = suggestionProps;
  const isHighlighted = highlightedIndex === index;
  const isSelected =
    ((selectedItem && selectedItem.id) || "") === suggestion.id;
  const theme = useContext(ThemeContext);
  return (
    <MenuItem
      {...itemProps}
      selected={isHighlighted}
      component="div"
      style={{
        background: isSelected ? theme.colors.activeFill : "",
        fontWeight: isSelected ? 500 : 400,
        minWidth: suggestionMinWidth,
      }}
    >
      {suggestion.value}
    </MenuItem>
  );
};

export interface AutocompleteProps {
  className?: string;
  customAutocomplete?: React.ReactNode /*/Temporarily work around not being able to extend component styles /*/;
  handleOwnerIdChange?: (ownerId: number | null) => void;
  mobile?: boolean;
  requestOptions?: (query?: string) => void;
  resetSuggestions?: () => void;
  suggestions?: AutocompleteSuggestion[];
  documentOwner: number | null;
  suggestionMinWidth?: number;
}

const Autocomplete: FC<AutocompleteProps> = ({
  customAutocomplete,
  handleOwnerIdChange,
  mobile,
  requestOptions,
  resetSuggestions,
  suggestions,
  documentOwner,
  suggestionMinWidth,
}) => {
  const [focused, setFocus] = useState(false);

  const debouncedRequestOpts = useDebounce({
    method(query: string) {
      requestOptions && requestOptions(query);
    },
  });

  const selectedOwner =
    !!documentOwner &&
    suggestions &&
    suggestions.find((suggestion) => suggestion.id === documentOwner);

  return (
    <Downshift
      onChange={(selection) => {
        handleOwnerIdChange &&
          handleOwnerIdChange((selection && selection.id) || null);
      }}
      onInputValueChange={(value) => {
        (value && value.length > 2 && debouncedRequestOpts(value)) ||
          (resetSuggestions && resetSuggestions());
      }}
      itemToString={(item) => (item ? item.value : "")}
      initialSelectedItem={selectedOwner}
    >
      {({
        clearSelection,
        getInputProps,
        getItemProps,
        getMenuProps,
        highlightedIndex,
        inputValue,
        isOpen,
        selectedItem,
      }) => {
        const { onBlur, onFocus, ...inputProps } = getInputProps({
          placeholder: "Search Document Owner",
          onFocus: () => !mobile && setFocus(true),
          onBlur: () => !mobile && setFocus(false),
          onChange: (e) => {
            if (e.target.value === "") {
              clearSelection();
            }
          },
        });
        const loadingSuggestions =
          suggestions?.length === 0 && inputValue && inputValue.length >= 3;
        const suggestionsMapped = getSuggestions(suggestions, inputValue);

        return (
          <div style={{ position: "relative" }}>
            {renderInput({
              customAutocomplete,
              fullWidth: true,
              mobile,
              InputProps: { onBlur, onFocus, disableUnderline: !focused },
              inputProps,
            })}

            <div {...getMenuProps()} style={parentElement}>
              {!!inputValue && (
                <div
                  style={{
                    position: "absolute",
                    top: 0,
                    right: 0,
                    paddingTop: mobile ? 18 : 5,
                    paddingRight: 10,
                  }}
                  onClick={() => clearSelection()}
                >
                  <IconForEndAdornment className="icon icon-icons8-delete_sign" />
                </div>
              )}
              {isOpen ? (
                <Paper square style={childElement}>
                  {loadingSuggestions && (
                    <div
                      style={{
                        minWidth: suggestionMinWidth,
                        textAlign: "center",
                        padding: 5,
                      }}
                    >
                      <CircularProgress size={20} />
                    </div>
                  )}
                  {suggestionsMapped.length === 0 &&
                    !loadingSuggestions &&
                    inputValue &&
                    inputValue.length >= 3 && (
                      <div
                        style={{
                          minWidth: 182,
                          padding: 8,
                          cursor: "not-allowed",
                        }}
                      >
                        No results found
                      </div>
                    )}
                  {suggestionsMapped.map((suggestion, index) => (
                    <Suggestion
                      highlightedIndex={highlightedIndex}
                      index={index}
                      itemProps={getItemProps({ item: suggestion })}
                      key={suggestion.id}
                      selectedItem={selectedItem}
                      suggestion={suggestion}
                      suggestionMinWidth={suggestionMinWidth}
                    />
                  ))}
                </Paper>
              ) : null}
            </div>
          </div>
        );
      }}
    </Downshift>
  );
};

Autocomplete.defaultProps = {};

export { Autocomplete };
