import React, { FC } from "react";

import {
  DisableUserButtonsWrapper,
  ModalPromptCloseIcon,
  ModalPromptSubtitle,
  ModalPromptTitle,
  ModalPromptWrapper,
} from "components/clientAdmin/users/styles";
import { SubmitButton } from "components/clientAdmin/styles";
import {
  CategoryStatusModalProps,
  actionPerformed,
} from "./CategoryStatusModal";
import { CategoryDTO } from "store/categories/types";

interface Props {
  archived: CategoryDTO["archived"];
  onClose: () => void;
  status: CategoryStatusModalProps["status"];
}

export const CategoryStatusOutcomeModal: FC<Props> = ({
  archived,
  status,
  onClose,
}) => {
  const error = status === "failure";

  return (
    <>
      <ModalPromptWrapper>
        <ModalPromptTitle>
          {error
            ? `Error - Unable to ${actionPerformed(
                archived,
                "present"
              )} category`
            : `Category has been ${actionPerformed(archived, "past")}`}
        </ModalPromptTitle>
        <ModalPromptSubtitle>
          {error
            ? `The system encountered an error while trying to ${actionPerformed(
                archived,
                "present"
              )} the selected category`
            : `The selected category has successfully been ${actionPerformed(
                archived,
                "past"
              )}`}
        </ModalPromptSubtitle>
        <DisableUserButtonsWrapper>
          <SubmitButton onClick={onClose}>
            <span>close </span>
          </SubmitButton>
        </DisableUserButtonsWrapper>
        <ModalPromptCloseIcon onClick={onClose} />
      </ModalPromptWrapper>
    </>
  );
};
