import * as y from "yup";

import { FieldProps } from "../forms/Form/types";

export const loginFields = (
  onVerify: (response: string) => void,
  options?: {
    showPassword?: boolean;
    toggleShowPassword?: () => void;
  },
): FieldProps => ({
  input: [
    {
      type: "text",
      variant: "outlined",
      height: 56,
      name: "email",
      placeholder: "Email Address",
      initialValue: "",
    },
    {
      type: options && options.showPassword ? "text" : "password",
      variant: "outlined",
      height: 56,
      name: "password",
      placeholder: "Password",
      initialValue: "",
      endAdornment: {
        // icon: options && options.showPassword ? "Visibility" : "VisibilityOff",
        button: true,
        color: "colors.primary",
        handleClick: options && options.toggleShowPassword,
        label: options && options.showPassword ? "Hide" : "Show",
        visible: true,
      },
    },
    {
      type: "recaptcha",
      name: "loginRecaptcha",
      onRecaptchaVerify: onVerify,
    },
  ],
  buttonStates: {
    primary: {
      initial: "SIGN IN",
    },
  },
});

export const loginSchema = y.object().shape({
  email: y
    .string()
    .trim()
    .email("Invalid email address")
    .required("Email is required"),
  password: y.string().required("Password is required"),
});

// export const helpFields: FieldProps = {
//   input: [
//     {
//       type: "text",
//       variant: "outlined",
//       height: 56,
//       name: "email",
//       placeholder: "Email Address",
//       initialValue: "",
//     },
//   ],
//   buttonStates: {
//     primary: {
//       initial: "SEND EMAIL",
//       success: "Recovery Email Sent",
//       failure: "EMAIL COULD NOT BE SENT",
//     },
//   },
// };

export const helpSchema = y.object().shape({
  email: y
    .string()
    .email("Invalid email address")
    .required("Email is required"),
});

export const helpFields = (options?: {
  buttonLoadingState?: any;
  buttonSuccessState?: any;
  buttonFailureState?: any;
}): FieldProps => ({
  input: [
    {
      height: 56,
      helperText: "Your account email is the address you used to sign up initially",
      initialValue: "",
      label: "Email Address",
      name: "email",
      placeholder: "Email Address",
      type: "text",
      variant: "outlined",
    },
  ],
  buttonStates: {
    primary: {
      failure: (options && options.buttonFailureState) || "EMAIL COULD NOT BE SENT",
      initial: "SEND RECOVERY EMAIL",
      success: (options && options.buttonSuccessState) || "Recovery Email Sent",
    },
  },
});

export const resetFields = (options?: {
  buttonFailureState?: any;
  buttonSuccessState?: any;
  setTooltipShowing?: (value?: boolean) => void;
  showConfirmPassword?: boolean;
  showPassword?: boolean;
  toggleShowConfirmPassword?: () => void;
  toggleShowPassword?: () => void;
  tooltipIsShowing?: boolean;
}): FieldProps => ({
  input: [
    {
      type: "tooltipPassword",
      variant: "outlined",
      label: "New Password",
      smallLabel: true,
      height: 56,
      name: "newPassword",
      placeholder: "New Password",
      initialValue: "",
      hideErrorText: options && options.tooltipIsShowing,
      endAdornment: {
        // icon: options && options.showPassword ? "Visibility" : "VisibilityOff",
        button: true,
        color: "colors.primary",
        handleClick: options && options.toggleShowPassword,
        label: options && options.showPassword ? "Hide" : "Show",
        visible: true,
      },
      onBlur: () => options && options.setTooltipShowing && options.setTooltipShowing(false),
      onFocus: () => options && options.setTooltipShowing && options.setTooltipShowing(true),
    },
    {
      type: "password",
      variant: "outlined",
      label: "Confirm New Password",
      smallLabel: true,
      height: 56,
      name: "confirmPassword",
      placeholder: "Re-enter Your New Password",
      initialValue: "",
      endAdornment: {
        // icon: options && options.showConfirmPassword ? "Visibility" : "VisibilityOff",
        button: true,
        color: "colors.primary",
        handleClick: options && options.toggleShowConfirmPassword,
        label: options && options.showConfirmPassword ? "Hide" : "Show",
        visible: true,
      },
    },
  ],
  buttonStates: {
    primary: {
      initial: "SAVE NEW PASSWORD",
      success: (options && options.buttonSuccessState) || "SUCCESSFULLY RESET",
      failure: (options && options.buttonFailureState) || "PASSWORD RESET FAILED",
    },
  },
});

// Activation
export const activationFields = (options?: {
  buttonFailureState?: any;
  buttonSuccessState?: any;
  setTooltipShowing?: (boolean) => void;
  showConfirmPassword?: boolean;
  showPassword?: boolean;
  toggleShowConfirmPassword?: () => void;
  toggleShowPassword?: () => void;
  tooltipIsShowing?: boolean;
}): FieldProps => ({
  input: [
    {
      type: "tooltipPassword",
      variant: "outlined",
      label: "New Password",
      smallLabel: true,
      height: 56,
      name: "newPassword",
      placeholder: "New Password",
      initialValue: "",
      hideErrorText: options && options.tooltipIsShowing,
      endAdornment: {
        button: true,
        color: "colors.primary",
        handleClick: options && options.toggleShowPassword,
        label: options && options.showPassword ? "Hide" : "Show",
        visible: true,
      },
      onBlur: () => options && options.setTooltipShowing && options.setTooltipShowing(false),
      onFocus: () => options && options.setTooltipShowing && options.setTooltipShowing(true),
    },
    {
      type: "password",
      variant: "outlined",
      label: "Confirm New Password",
      smallLabel: true,
      height: 56,
      name: "confirmPassword",
      placeholder: "Re-enter Your New Password",
      initialValue: "",
      endAdornment: {
        button: true,
        color: "colors.primary",
        handleClick: options && options.toggleShowConfirmPassword,
        label: options && options.showConfirmPassword ? "Hide" : "Show",
        visible: true,
      },
    },
  ],
  buttonStates: {
    primary: {
      initial: "ACTIVATE YOUR ACCOUNT",
      success: (options && options.buttonSuccessState) || "NEW PASSWORD SAVED",
      failure: (options && options.buttonFailureState) || "UNABLE TO SAVE NEW PASSWORD",
    },
  },
});

/**
 * Password standard regex
 * (?=.*[a-z])        : This matches the presence of at least one lowercase letter.
 * (?=.*d)            : This matches the presence of at least one digit i.e. 0-9.
 * (?=.*[!@#$%^&*()]) : This matches the presence of at least one special character.
 * ((?=.*[A-Z])       : This matches the presence of at least one capital letter.
 * {8,100}            : This limits the length of password from minimum 8 letters to maximum 100 letters.
 */
export const PASSWORD_REGEX = {
  DIGIT: /(\d)/,
  FULL: /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()]).{8,100}$/,
  LOWERCASE: /(?=.*[a-z])/,
  SPECIAL: /([!@#$%^&*()])/,
  UPPERCASE: /(?=.*[A-Z])/,
};

export const resetSchema = y.object().shape({
  newPassword: y
    .string()
    .matches(PASSWORD_REGEX.FULL, "Password does not meet standard requirements")
    .required("Password is required"),
  confirmPassword: y
    .string()
    .required("Password is required")
    .oneOf([y.ref("newPassword"), ""], "Passwords do not match"),
});
