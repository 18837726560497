import React from "react";
import { css } from "aphrodite/no-important";
import { useFormikContext } from "formik";

import styles from "./styles";
import { TextInput } from "components/common/form/TextInput/futureUiKit/TextInput";
import Icon from "components/common/Icon";
import { FBForm, FBDefensesWidget } from "../../../../types";

interface Props {
  item: FBDefensesWidget;
}

export function DefensesWidget({ item }: Props) {
  const { values } = useFormikContext<FBForm>();
  const s = styles();
  return (
    <div className={css(s.container)}>
      <div className={css(s.possibleDefenses)}>
        <span className={css(s.numDefenses)}>{values.defenses?.length}</span>
        <span className={css(s.possibleDefenses)}>POSSIBLE DEFENSES</span>
      </div>
      <div className={css(s.detailsContainer)}>
        <div className={css(s.selectedField)}>
          <Icon type="icon-icons8-checkmark" />
          <span>Selected Field</span>
        </div>
        <span className={css(s.detail)}>
          The text that displays in this section is the related defense attached
          to its selected field above.&nbsp; These defenses display in the order
          of their selection on the form and are grouped together here to&nbsp;
          help facilitate the discussion between team members.
        </span>
        {item.autoAppendComment && (
          <>
            <div className={css(s.triangle)} />
            <div className={css(s.relatedCommentContainer)}>
              <TextInput
                assistiveLink={{
                  label: "Voice to Text",
                  onClick: () => undefined,
                }}
                className={css(s.textField)}
                label="Related Comment"
                labelClass={css(s.relatedCommentLabel)}
                placeholder="Add Comment"
                rounded
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
}
