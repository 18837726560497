// import CancelIcon from "@material-ui/icons/Cancel";
// import NoSsr from "@material-ui/core/NoSsr";
// import clsx from "clsx";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import PropTypes from "prop-types";
import React, { useContext } from "react";
import Select from "react-select";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { ThemeContext, withTheme } from "styled-components";

import * as SS from "components/common/form/Select/styles"; // Select's styles
import { Label } from "components/forms/MultiInput/styles";

const useStyles = (props) => {
  const { error, theme } = props;
  const borderStyles = error ? `2px solid ${theme.colors.error}` : `1px solid ${theme.colors.black}`;
  return makeStyles({
    root: {
      flexGrow: 1,
      minWidth: 290,
    },
    input: {
      border: borderStyles,
      borderRadius: "4px",
      display: "flex",
      padding: "1.125rem 0.875rem",
    },
    noOptionsMessage: {
      padding: "8px 16px",
    },
    paper: {
      left: 0,
      marginTop: "8px",
      position: "absolute",
      right: 0,
      zIndex: 1,
    },
    placeholder: {
      alignItems: "center",
      color: theme.masterColors.mediumGrey,
      display: "flex",
      fontSize: 16,
      position: "absolute",
    },
    value: {
      fontSize: 16,
    },
    valueContainer: {
      alignItems: "center",
      display: "flex",
      flex: 1,
      flexWrap: "wrap",
      overflow: "hidden",
    },
  })(props);
};

function NoOptionsMessage(props) {
  return (
    <Typography color="textSecondary" className={props.selectProps.classes.noOptionsMessage} {...props.innerProps}>
      {props.children}
    </Typography>
  );
}

NoOptionsMessage.propTypes = {
  /**
   * The children to be rendered.
   */
  children: PropTypes.node,
  /**
   * Props to be passed on to the wrapper.
   */
  innerProps: PropTypes.object.isRequired,
  selectProps: PropTypes.object.isRequired,
};

function inputComponent({ inputRef, ...props }) {
  return <div ref={inputRef} {...props} />;
}

inputComponent.propTypes = {
  inputRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({
      current: PropTypes.any.isRequired,
    }),
  ]),
};

function Control(props) {
  const {
    children,
    innerProps,
    innerRef,
    selectProps: { classes, TextFieldProps },
  } = props;
  return (
    <TextField
      fullWidth
      variant="outlined"
      InputProps={{
        inputComponent,
        inputProps: {
          className: classes.input,
          ref: innerRef,
          children,
          ...innerProps,
        },
      }}
      {...TextFieldProps}
    />
  );
}

Control.propTypes = {
  /**
   * Children to render.
   */
  children: PropTypes.node,
  /**
   * The mouse down event and the innerRef to pass down to the controller element.
   */
  innerProps: PropTypes.shape({
    onMouseDown: PropTypes.func.isRequired,
  }).isRequired,
  innerRef: PropTypes.oneOfType([
    PropTypes.oneOf([null]),
    PropTypes.func,
    PropTypes.shape({
      current: PropTypes.any.isRequired,
    }),
  ]).isRequired,
  selectProps: PropTypes.object.isRequired,
};

function Option(props) {
  const theme = useContext(ThemeContext);
  return (
    <MenuItem
      ref={props.innerRef}
      selected={props.isFocused}
      component="div"
      style={{
        fontWeight: props.isSelected ? 500 : 400,
        backgroundColor: props.isFocused || props.isSelected ? theme.colors.activeFill : "", // Can pass in props if static needs to be dynamic
      }}
      {...props.innerProps}
    >
      {props.children}
    </MenuItem>
  );
}

Option.propTypes = {
  /**
   * The children to be rendered.
   */
  children: PropTypes.node,
  /**
   * props passed to the wrapping element for the group.
   */
  innerProps: PropTypes.shape({
    id: PropTypes.string.isRequired,
    key: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    onMouseMove: PropTypes.func.isRequired,
    onMouseOver: PropTypes.func.isRequired,
    tabIndex: PropTypes.number.isRequired,
  }).isRequired,
  /**
   * Inner ref to DOM Node
   */
  innerRef: PropTypes.oneOfType([
    PropTypes.oneOf([null]),
    PropTypes.func,
    PropTypes.shape({
      current: PropTypes.any.isRequired,
    }),
  ]).isRequired,
  /**
   * Whether the option is focused.
   */
  isFocused: PropTypes.bool.isRequired,
  /**
   * Whether the option is selected.
   */
  isSelected: PropTypes.bool.isRequired,
};

function Placeholder(props) {
  const { selectProps, innerProps = {}, children } = props;
  return (
    <Typography color="textSecondary" className={selectProps.classes.placeholder} {...innerProps}>
      {children}
    </Typography>
  );
}

Placeholder.propTypes = {
  /**
   * The children to be rendered.
   */
  children: PropTypes.node,
  /**
   * props passed to the wrapping element for the group.
   */
  innerProps: PropTypes.object,
  selectProps: PropTypes.object.isRequired,
};

function Value(props) {
  return (
    <Typography className={props.selectProps.classes.singleValue} {...props.innerProps}>
      {props.children}
    </Typography>
  );
}

Value.propTypes = {
  /**
   * The children to be rendered.
   */
  children: PropTypes.node,
  /**
   * Props passed to the wrapping element for the group.
   */
  innerProps: PropTypes.any.isRequired,
  selectProps: PropTypes.object.isRequired,
};

function DropdownIndicator() {
  return <i className="icon-icons8-sort_down"/>;
}

function ValueContainer(props) {
  return <div className={props.selectProps.classes.valueContainer}>{props.children}</div>;
}

ValueContainer.propTypes = {
  /**
   * The children to be rendered.
   */
  children: PropTypes.node,
  selectProps: PropTypes.object.isRequired,
};

function Menu(props) {
  return (
    <Paper square className={props.selectProps.classes.paper} {...props.innerProps}>
      {props.children}
    </Paper>
  );
}

Menu.propTypes = {
  /**
   * The children to be rendered.
   */
  children: PropTypes.element.isRequired,
  /**
   * Props to be passed to the menu wrapper.
   */
  innerProps: PropTypes.object.isRequired,
  selectProps: PropTypes.object.isRequired,
};

const components = {
  Control,
  DropdownIndicator,
  Menu,
  NoOptionsMessage,
  Option,
  Placeholder,
  Value,
  ValueContainer,
};

function SearchableSelect(props) {
  const {
    assistiveLink,
    error,
    label,
    name,
    noMargins,
    onBlur,
    onChange,
    onFocus,
    onInputChange,
    placeholder,
    selectedItems,
    suggestions,
    theme,
    touched,
    value,
    onMenuScrollToBottom,
    isLoading,
    filterOption,
  } = props;
  const classes = useStyles({ error, theme });

  return (
    <div className={noMargins ? undefined : "mt-3 mb-3"}>
      {label && (
        <Label htmlFor={name} assistiveLink={assistiveLink}>
          {label}
        </Label>
      )}
      {selectedItems}
      <Select
        TextFieldProps={{
          onChange: (e) => {
            if (!e.target.value || e.target.value.length > 2) {
              // This resets suggestions if you:
              // 1) Type a value and backspace it
              // 2) Type a value and it's longer than 2 characters
              onInputChange(e.target.value);
            }
          },
          onFocus: (e) => {
            // This resets suggestions to the default list if you click on the dropdown
            onInputChange(e.target.value);
          },
        }}
        classes={classes}
        components={components}
        error={error}
        inputId="react-select-single"
        name={name}
        onBlur={onBlur}
        onChange={(e) => {
          onChange(e.value, e.label);
        }}
        onFocus={onFocus}
        options={suggestions}
        placeholder={placeholder}
        value={value}
        isLoading={isLoading}
        onMenuScrollToBottom={onMenuScrollToBottom}
        filterOption={filterOption}
      />
      {touched && error && <SS.ErrorText>{error}</SS.ErrorText>}
    </div>
  );
}

export default withTheme(SearchableSelect);
