import { CircularProgress } from "@material-ui/core";
import { css } from "aphrodite";
import React from "react";
import {
  CreateStyleSheet,
  useThemedStyleSheet,
} from "util/hooks/useThemedStyleSheet";
import { ProgressStyle } from "./styles";

interface ProgressProps {
  createStyles: CreateStyleSheet<ProgressStyle>;
}

export const Progress = ({ createStyles }: ProgressProps) => {
  const styles = useThemedStyleSheet(createStyles);
  return (
    <CircularProgress
      className={css(styles.progress)}
      classes={{ root: "root" }}
      size={20}
    />
  );
};
