import React from "react";

import { MenuItem as MenuItemType, MenuItemIcon } from "../types";
import * as S from "../styles";

/**
 * Renders an Icon based on the given enum icon
 * ie: If you Menu config has:
 * `icon: MenuItemIcon.PENCIL`
 * Then it'll meet the requirements to render
 * the pencil icon. You'll need to import
 * and use the enum. (Exported from Menu)
 */
const renderIcon = (icon: MenuItemIcon) => {
  const iconToRender = () => {
    switch (icon) {
      case MenuItemIcon.PENCIL:
        return <S.Pencil />;
      case MenuItemIcon.PHONE_SETTINGS:
        return <S.PhoneSettings />;
      default:
        return null;
    }
  };

  return <S.Icon>{iconToRender()}</S.Icon>;
};

interface Props {
  menuItem: MenuItemType;
}

/**
 * Renders a Menu Item element and contains logic to
 * render an Icon, label, and a hard coded Chevron at the end.
 */
const MenuItem = ({ menuItem: { label, icon, onClick } }: Props) => {
  return (
    <S.MenuItem onClick={onClick}>
      <S.Left>
        {icon && renderIcon(icon)} <S.Label>{label}</S.Label>
      </S.Left>
      <S.ChevronRight />
    </S.MenuItem>
  );
};

export default MenuItem;
