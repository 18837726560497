import React, { useEffect, useState, useMemo } from "react";
import { Action } from "redux";
import { AppState } from "store";
import { css } from "aphrodite/no-important";
import { get } from "lodash";
import { ThunkDispatch } from "redux-thunk";
import { useDispatch, useSelector } from "react-redux";
import { useFormikContext } from "formik";

import { DataSourceResponse, DataSource } from "store/dataSource/types";
import {
  getDataSourceById,
  getDataSourceValues,
} from "store/dataSource/actions";
import { NewFormSection } from "store/builder/types";
import { putFormSection } from "store/builder/actions";

import { DefensesProperties } from "./DefensesProperties";
import { FBForm, FBSection, FBItem, FBQuestion } from "../../types";
import { getItemProperties } from "./helpers";
import { PROPERTIES_HEADER_TEXT } from "../../language";
import { unmarshallForm, getMissingWidgets } from "../../helpers";
import DisplayConditionsBanner from "./DisplayConditionsBanner";
import MissingWidgetBanner, { MissingWidgetType } from "./MissingWidgetBanner";
import Property from "./Property";
import Selections from "./customFields/Selections/Selections";
import SidebarIntro from "../../baseUi/sidebar/SidebarIntro";
import SidebarLabel from "../../baseUi/sidebar/SidebarLabel";
import styles from "./styles";
import AutoSaveItem from "../../autoSave/AutoSaveItem";
import { widgets } from "../items/Widgets";
import { elements } from "../items/Elements";
import { fields } from "../items/Fields";
import { getItemInSection } from "./customFields/AreasToReview/AreasToReview";

const itemNames = [...widgets, ...elements, ...fields];

function buildMissingWidget(type: MissingWidgetType) {
  if (type === "DEFENSES") {
    return {
      name: "Defenses",
      type: "WIDGET" as const,
      subType: "DEFENSES" as const,
      autoAppendComment: false,
      questions: [],
      id: null,
    };
  } else if (type === "OPERATIONAL_EXPERIENCES") {
    return {
      name: "Operational Experiences",
      type: "WIDGET" as const,
      subType: "OPERATIONAL_EXPERIENCES" as const,
      numberRequired: 0,
      filterOutTags: [],
      id: null,
    };
  }
}

export const getItemPath = (
  sections: FBSection[],
  item: FBItem | FBSection
) => {
  if (item.type === "SECTION") {
    const sectionIndex = sections.findIndex(
      (section) => section.rootId === item.rootId
    );
    return `sections[${sectionIndex}]`;
  } else {
    const sectionIndex = sections.findIndex(
      (section) => section.rootId === item.parentRootID
    );
    if (sectionIndex > -1) {
      // I can't seem to get item position to update when new items are added BEFORE
      // existing items. Reverting to findIndex for now, sorry :( -- ED
      const itemIndex = sections[sectionIndex].items?.findIndex(
        (sItem) => sItem.rootId === item.rootId
      );
      return `sections[${sectionIndex}].items[${itemIndex}]`;
    }
    return "";
  }
};

/**
 * Build the item's name based on its subtype
 * @param subType - subType of the item
 */
export function getHeaderLabel(subType: string): string {
  const item = itemNames.find((i) => i.subType === subType);
  return item?.name || "";
}

interface Props {
  currentItem: FBItem | FBSection;
  setCurrentItem: (item: FBItem | FBSection) => void;
  onUpdateItem: (item: FBItem | FBSection) => Promise<FBForm | undefined>;
  onSaveError?: (error: string) => void;
}

const FormItemProperties: React.FC<Props> = ({
  currentItem,
  setCurrentItem,
  onUpdateItem,
  onSaveError,
}) => {
  const [dataSource, setDataSource] = useState<DataSource>();
  const { values, setValues } = useFormikContext<FBForm>();
  const s = styles();

  const dispatch = useDispatch<ThunkDispatch<AppState, void, Action>>();

  const itemPath = useMemo(() => getItemPath(values.sections, currentItem), [
    currentItem,
    values,
  ]);
  const itemType =
    currentItem.type === "SECTION" ? "SECTION" : currentItem.subType;
  const headerLabel = useMemo(() => getHeaderLabel(itemType), [itemType]);

  const itemProps = useMemo(
    () => (itemType ? getItemProperties(itemType) : []),
    [currentItem]
  );
  const rules = useMemo(
    () => itemProps.filter((prop) => prop.propertyType === "RULE"),
    [itemProps]
  );
  const properties = useMemo(
    () => itemProps.filter((prop) => prop.propertyType === "PROPERTY"),
    [itemProps]
  );
  const selections = useMemo(
    () => itemProps.filter((prop) => prop.propertyType === "SELECTIONS"),
    [itemProps]
  );
  const dataSources = useSelector(({ dataSource }: AppState) => dataSource);

  const sourceDisplayConditions =
    (currentItem.type === "QUESTION" &&
      values.displayConditions?.filter(
        (dc) =>
          dc.action !== "WORK_ORDER_PREFILL" &&
          dc.targetRootId === currentItem.rootId
      )) ||
    [];
  const missingWidgets =
    currentItem.type === "QUESTION"
      ? getMissingWidgets(values, currentItem)
      : [];

  const item: FBItem = useMemo(() => get(values, itemPath, {}), [
    itemPath,
    values,
  ]);

  // get parent if exists
  const parentRootId = useMemo(() => get(item, "parentQuestionRootId"), [item]);
  const parentItem = useMemo(
    () =>
      parentRootId &&
      (getItemInSection(values, item.parentRootID, parentRootId) as FBQuestion),
    [item, parentRootId, values]
  );
  const parentItemPath = useMemo(
    () => parentItem && getItemPath(values.sections, parentItem),
    [parentItem, values]
  );

  useEffect(() => {
    (async () => {
      // get the data source object
      if (
        currentItem.type === "QUESTION" &&
        item.answerSource?.dataSourceKey &&
        item.answerSource.properties?.dataSourceId
      ) {
        const res: DataSourceResponse = await dispatch(
          getDataSourceById(String(item.answerSource.properties.dataSourceId))
        );
        if (res.response) {
          setDataSource(res.response);
          // get the data source values to populate options/display conditions
          if (
            res.response.dataSourceKey &&
            !dataSources.data[res.response.dataSourceKey]
          ) {
            dispatch(
              getDataSourceValues({
                keyName: res.response.dataSourceKey,
                size: 20,
                sortBy:
                  item.answerSource.properties.detailedSearch
                    ?.infiniteListSortBy,
              })
            );
          }
        }
      }
    })();
  }, [item.answerSource, currentItem]);

  async function addMissingWidget(type: MissingWidgetType) {
    // @TODO this is a hack find a better way to prevent updates on Published form
    if (values.workflowType === "FINAL") return;
    // end hack

    const widget = buildMissingWidget(type);
    const items = widget ? [widget] : [];
    const res = await dispatch(
      putFormSection(values.id, {
        items,
        id: null,
        title: "",
        properties: {},
      } as NewFormSection)
    );
    if (res.response) {
      setValues(unmarshallForm(res.response));
    }
  }

  return (
    <>
      <AutoSaveItem
        values={get(values, itemPath)}
        itemPath={itemPath}
        onError={onSaveError}
      />
      {!!parentItemPath && (
        <AutoSaveItem
          values={get(values, parentItemPath)}
          itemPath={parentItemPath}
          onError={onSaveError}
        />
      )}
      <SidebarIntro
        active
        direction="right"
        label={`${headerLabel} Properties`}
        detail={PROPERTIES_HEADER_TEXT[itemType]}
      />
      <div className={css(s.properties)}>
        {sourceDisplayConditions.length > 0 && (
          <>
            <DisplayConditionsBanner
              sourceDisplayConditions={sourceDisplayConditions}
              onSelectItem={setCurrentItem}
            />
            <hr />
          </>
        )}
        {properties.length > 0 &&
          properties.map((prop) => (
            <Property
              itemPath={itemPath}
              dataSource={dataSource}
              key={`${itemPath}.${prop.name}`}
              property={prop}
              item={item}
            />
          ))}
        {selections.length > 0 && (
          <>
            <hr />
            {selections.map((prop) => (
              <Selections
                itemPath={itemPath}
                itemType={itemType}
                key={`${itemPath}.${prop.name}`}
                onUpdateItem={onUpdateItem}
                property={prop}
                item={item}
                dataSource={dataSource}
              />
            ))}
          </>
        )}
        {rules.length > 0 && (
          <>
            <hr />
            <SidebarLabel>Rules</SidebarLabel>
            {rules.map((prop) =>
              prop.forParent && parentItem && parentItemPath ? (
                <Property
                  dataSource={dataSource}
                  itemPath={parentItemPath}
                  key={`${parentItemPath}.${prop.name}`}
                  property={prop}
                  item={parentItem}
                />
              ) : (
                <Property
                  dataSource={dataSource}
                  itemPath={itemPath}
                  key={`${itemPath}.${prop.name}`}
                  property={prop}
                  item={item}
                />
              )
            )}
          </>
        )}
        {item.type === "WIDGET" && currentItem.subType === "DEFENSES" && (
          <>
            <hr />
            <DefensesProperties item={item} itemPath={itemPath} />
          </>
        )}
        {missingWidgets.length > 0 &&
          missingWidgets.map((missingWidget) => (
            <MissingWidgetBanner
              key={missingWidget}
              type={missingWidget}
              onAddMissing={() => addMissingWidget(missingWidget)}
            />
          ))}
      </div>
    </>
  );
};

export default FormItemProperties;
