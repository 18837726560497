import { FilterParams } from "store/common/types";

export const DOCUMENTS_FILTERS = {
  SET: "DOCUMENTS_FILTERS_SET",
};

export interface DocumentsFiltersParams extends FilterParams {
  documentType: number;
  timeFilterType: string;
  dateRange: string | null;
  subordinatesFilter: string;
  documentOwner: number | null;
  query: string;
}

export interface DocumentsFiltersArgs {
  filterParams: DocumentsFiltersParams;
}

export interface SetDocumentsFiltersAction {
  type: typeof DOCUMENTS_FILTERS.SET;
  response: DocumentsFiltersParams;
}

export type FiltersActions = SetDocumentsFiltersAction;
