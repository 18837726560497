import React from "react";
import styled from "styled-components";
import { ButtonBase, CircularProgress } from "@material-ui/core";

import { ThemeTypes } from "themes/colors";
import { devices } from "themes/mediaQueries";
import { remCalc } from "themes/helpers";

import { ButtonProps, ButtonVariants } from "./Button";

const LoadingAdornment = styled(({ ...otherProps }) => {
  return (
    <CircularProgress
      {...otherProps}
      classes={{
        root: "root",
      }}
      size={16}
      color="inherit"
    />
  );
})`
  &&.root {
    margin-left: ${({ after }) => after && "0.6rem"};
    margin-right: 0.6em;
  }
`;

const determineBgColor = ({
  status,
  variant,
  theme,
  disabled,
}: {
  status?: "success" | "failure" | "request" | null;
  variant?: ButtonVariants;
  theme: ThemeTypes;
  disabled?: boolean;
}) => {
  //@NOTE ensure there's no regression

  if (disabled) {
    return theme.masterColors.lightGrey;
  }
  if (!variant || variant === "primary") {
    if (status === null || typeof status === "undefined") {
      return theme.colors.primary;
    }
    if (status === "failure") {
      return theme.colors.error;
    }
    if (status === "success") {
      return theme.colors.success;
    }
  }

  if (variant === "secondary" || variant === "unstyled") {
    return "";
  }

  if (variant === "primary") {
    return "none";
  }
};

const determineColor = ({
  disabled, loading, variant, theme
}: {
  disabled?: boolean;
  loading?: boolean;
  variant?: ButtonVariants;
  theme: ThemeTypes;
}) => {
  if (disabled && !loading) {
    return theme.masterColors.darkGreyBlue;
  }

  if (!variant || variant === "primary") {
    return theme.masterColors.white;
  }

  if (variant === "secondary") {
    return theme.colors.primary;
  }

  if (variant === "unstyled") {
    return theme.masterColors.black;
  }

  if (variant === "unstyled-danger") {
    return theme.masterColors.error;
  }

  return theme.colors.white;
};

const Button = styled(({ width, fullWidth, documentButton, loading, ...otherProps }: ButtonProps) => {
  return (
    <ButtonBase
      {...otherProps}
      disableRipple={otherProps.status === "success"}
      classes={{
        root: "root",
        disabled: "disabled",
      }}
    />
  );
})`
  &&.root {
    cursor: ${({ status }) => status !== "success" && "pointer" || "default"};
    font-size: 0.875rem;
    font-weight: 500;
    letter-spacing: 1.25px;
    border-radius: 0.25em;
    min-width: ${({ width }) => (width ? "0px" : "16em")};
    
    @media (min-width: ${devices.minDesktop}px) {
      margin-top: 2.125rem;
    }
  
    @media (max-width: ${devices.maxTablet}px) {
      margin-top: 1rem;
    }

    background-color: ${({ status, variant, theme, disabled }) =>
    determineBgColor({ status, variant, theme, disabled })};
    color: ${({ disabled, loading, variant, theme }) => determineColor({ disabled, loading, variant, theme })};

    width: ${({ fullWidth, width }) => (width ? remCalc(width) : fullWidth ? "100%" : "auto")};
    height: ${({ height }) => (height ? `${height}rem` : remCalc(56))};

    // extra rules not covered above

    ${({ variant, theme }) => {
    if (variant === "secondary") {
      return `border: 1px solid ${theme.masterColors.lightGrey}`;
    }
    return null;
  }}

    ${({ type, theme }) =>
    type === "submit" &&
    `
      border: 1px solid ${theme.masterColors.primary};
      `}

    // HANDLE DOCUMENT BUTTON VARIANT
    // CURRENTLY USING A BOOLEAN PROP
    // @FIXME: CHANGE TO VARIANT (in Button.tsx)
    ${({ documentButton, theme }) => {
    if (documentButton) {
      return `
          height: ${remCalc(56)};
          border: 1px solid ${theme.masterColors.lightGrey};
        `;
    }
  }}
  }
`;

export { Button, LoadingAdornment };
