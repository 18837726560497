// RESOURCE CATEGORY METHODS

import { PaginationParams, FilterParams } from "store/common/types";
import { CALL_API } from "middleware/api";
import { buildFilteredEndpoint } from "store/common/apiUtilities";
import {
  SAVE_RESOURCE_CATEGORY,
  GET_RESOURCE_CATEGORIES,
  GET_RESOURCE_CATEGORY_BY_ID,
} from "./types";

export type CategorySaveVM = {
  title: string;
  id?: number;
  archived?: boolean;
  createdDate?: string;
};

export const getResourceCategories = (
  paginationParams?: PaginationParams,
  filterParams?: FilterParams
) => (dispatch, getState) => {
  const { authToken } = getState().system;

  const endpoint = buildFilteredEndpoint("resource-categories", {
    ...paginationParams,
    ...filterParams,
  });

  return dispatch({
    [CALL_API]: {
      endpoint,
      options: {
        method: "GET",
        headers: {
          Authorization: `Bearer ${authToken}`,
          Accept: "application/json",
        },
      },
      types: [
        GET_RESOURCE_CATEGORIES.REQUEST,
        GET_RESOURCE_CATEGORIES.SUCCESS,
        GET_RESOURCE_CATEGORIES.FAILURE,
      ],
    },
  })
    .then((res) => res)
    .catch((err) => {
      throw new Error(err);
    });
};

export const getUnarchivedResourceCategories = (
  paginationParams?: PaginationParams,
  filterParams?: FilterParams
) => (dispatch, getState) => {
  const { authToken } = getState().system;

  const endpoint = buildFilteredEndpoint("resource-categories/unarchived", {
    ...paginationParams,
    ...filterParams,
  });

  return dispatch({
    [CALL_API]: {
      endpoint,
      options: {
        method: "GET",
        headers: {
          Authorization: `Bearer ${authToken}`,
          Accept: "application/json",
        },
      },
      types: [
        GET_RESOURCE_CATEGORIES.REQUEST,
        GET_RESOURCE_CATEGORIES.SUCCESS,
        GET_RESOURCE_CATEGORIES.FAILURE,
      ],
    },
  })
    .then((res) => res)
    .catch((err) => {
      throw new Error(err);
    });
};

export const saveResourceCategory = (values: CategorySaveVM) => (
  dispatch,
  getState
) => {
  const { authToken } = getState().system;
  return dispatch({
    [CALL_API]: {
      endpoint: "resource-categories",
      options: {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${authToken}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(values),
      },
      types: [
        SAVE_RESOURCE_CATEGORY.REQUEST,
        SAVE_RESOURCE_CATEGORY.SUCCESS,
        SAVE_RESOURCE_CATEGORY.FAILURE,
      ],
    },
  })
    .then((res) => res)
    .catch((err) => {
      throw new Error(err);
    });
};

export const getResourceCategoryById = (id: string | number) => (
  dispatch,
  getState
) => {
  const { authToken } = getState().system;

  return dispatch({
    [CALL_API]: {
      endpoint: `resource-categories/${id}`,
      options: {
        method: "GET",
        headers: {
          Authorization: `Bearer ${authToken}`,
          Accept: "application/json",
        },
      },
      types: [
        GET_RESOURCE_CATEGORY_BY_ID.REQUEST,
        GET_RESOURCE_CATEGORY_BY_ID.SUCCESS,
        GET_RESOURCE_CATEGORY_BY_ID.FAILURE,
      ],
    },
  })
    .then((res) => res)
    .catch((err) => {
      throw new Error(err);
    });
};
