import React from "react";
import {
  _useMapControl,
  NavigationControlProps,
  ViewState,
} from "react-map-gl";
import * as S from "./styles";

type Props = NavigationControlProps & {
  handleSetViewport: (viewport: ViewState) => void;
  toggleTileStyle: () => void;
};

/** Custom ZoomControl class - replacement for react-map-gl NavigationControl */

export const ZoomControl = (props: Props) => {
  const { context, containerRef } = _useMapControl({
    onDragStart: (evt) => {
      evt.stopPropagation();
    },
    onClick: (evt) => {
      evt.stopPropagation();
    },
    onDoubleClick: (evt) => {
      evt.stopPropagation();
    },
  });

  const onZoomIn = () => {
    if (context.viewport) {
      props.handleSetViewport({
        ...context.viewport,
        zoom: context.viewport.zoom + 1,
      });
    }
  };

  const onZoomOut = () => {
    if (context.viewport) {
      props.handleSetViewport({
        ...context.viewport,
        zoom: context.viewport.zoom - 1,
      });
    }
  };

  return (
    <S.ZoomContainer ref={containerRef}>
      <S.ControlButton
        type="button"
        onClick={() => {
          props.toggleTileStyle();
        }}
      >
        {/* @TODO integrate an actual satellite map tile here */}
        <S.SatelliteImage src={require("./satellitemap.png")} alt="satellite" />
      </S.ControlButton>
      <S.ControlButton
        type="button"
        onClick={() => {
          onZoomOut();
        }}
      >
        <i className="icon-icons8-minus2" />
      </S.ControlButton>
      <S.ControlButton
        type="button"
        onClick={() => {
          onZoomIn();
        }}
      >
        <i className="icon-icons8-plus_math" />
      </S.ControlButton>
    </S.ZoomContainer>
  );
};
