import React from "react";

import Button from "components/forms/Button";

import * as S from "./styles";

interface Props {
  loading?: boolean;
  onSubmit: () => void;
  onCancel: () => void;
}

const ButtonRow = ({ onSubmit, loading, onCancel }: Props) => {
  return (
    <S.ButtonRow>
      <Button onClick={onSubmit} width={106} height={2.25} loading={loading} disabled={loading}>
        <S.Label>SAVE</S.Label>
      </Button>

      <S.CancelButton onClick={onCancel} type="reset">
        <S.Label cancel>CANCEL</S.Label>
      </S.CancelButton>
    </S.ButtonRow>
  );
};

export default ButtonRow;
