import React from "react";
import { css } from "aphrodite/no-important";

import styles from "./styles";

const SidebarLabel = ({ children }: {children: React.ReactNode}) => {
  const s = styles();
  return (
    <span className={css(s.label)}>{children}</span>
  );
};

export default SidebarLabel;
