// Form
export const RequiredMessage = "Required";
export const emailValidationPattern = RegExp(
  "^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$",
);
export const groupRoleRegexPattern = new RegExp("^g-(.*)-(.*)");
export const nameValidationPattern = RegExp("^(?! )[0-9a-zA-Z-]*$");

// Roles
export const roles = [
  // {id: "ROLE_CLIENT_ADMIN", value: "Client Admin"},
  { id: "ROLE_CLIENT_REVIEWER", value: "Client Reviewer" },
  { id: "ROLE_FORM_ARCHITECT", value: "Form Architect" },
  { id: "ROLE_CONTENT_EDITOR", value: "Content Editor" },
  { id: "ROLE_GROUP_MANAGER", value: "Group Manager" },
  { id: "ROLE_USER", value: "User" },
];
