import { ResourceStatus } from "@rtslabs/field1st-fe-common";
import { CategoryDTO } from "store/categories/types";
import { ClientGroup } from "store/clientGroups/types";
import { Pagination } from "store/common/types";
import { Defense, DefenseDocument } from "store/documents/types";
import { TagVm, TagDTO } from "store/tagLibrary/types";

export type OperationalExperience = {
  categories: CategoryDTO[];
  clientGroups: ClientGroup[];
  description?: string;
  favoriteCount?: number;
  id?: number;
  importance?: string;
  incident?: string;
  incidentDate?: string | null;
  includedCount?: number;
  notes?: string | null;
  score?: number;
  status: ResourceStatus;
  tags?: TagDTO[];
};

export type ResourceTypes = ResourceType | -1;

export interface ResourceValues {
  categories: CategoryDTO[];
  clientGroups?: ClientGroup[];
  resourceType: ResourceTypes;
  description?: string;
  title?: string;
  tags?: TagDTO[];
  defenseDocuments?: Array<DefenseDocument | File>;
  id?: number;
  status: ResourceStatus;
  notes?: string | null;
}

export interface DefenseValues extends Defense {
  resourceType: "DEFENSE";
}

export interface OperationalExperienceValues extends OperationalExperience {
  resourceType: "OPERATIONAL_EXPERIENCE";
}

export type ResourceDTO = OperationalExperienceValues | DefenseValues;

export const SAVE_RESOURCE = {
  REQUEST: "SAVE_RESOURCE_REQUEST",
  SUCCESS: "SAVE_RESOURCE_SUCCESS",
  FAILURE: "SAVE_RESOURCE_FAILURE",
};

export const ADD_RESOURCE = {
  REQUEST: "ADD_RESOURCE_REQUEST",
  SUCCESS: "ADD_RESOURCE_SUCCESS",
  FAILURE: "ADD_RESOURCE_FAILURE",
};

export interface SubmitResourceAction {
  response?: Defense | OperationalExperience;
  type: string;
  error?: string;
}

export interface DuplicateResourceAction {
  response?: Defense | OperationalExperience;
  type: string;
  error?: string;
}

export type ResourceType = "OPERATIONAL_EXPERIENCE" | "DEFENSE";

export type Resource = {
  categories: CategoryDTO[];
  createdByEmail: string;
  createdByFirstName: string;
  createdByLastName: string;
  createdByNickname: string;
  createdDate: string;
  id: number;
  lastModifiedByEmail: string;
  lastModifiedDate: string;
  lastModifiedFirstName: string;
  lastModifiedLastName: string;
  lastModifiedNickname?: string;
  notes?: string | null;
  resourceType: ResourceType;
  status: ResourceStatus;
  tags: TagVm[];
  title: string;
  uses: string;
};

export interface Resources extends Pagination {
  content: Resource[];
}

export const GET_RESOURCES = {
  REQUEST: "GET_RESOURCES_REQUEST",
  SUCCESS: "GET_RESOURCES_SUCCESS",
  FAILURE: "GET_RESOURCES_FAILURE",
};

export interface GetResourcesAction {
  response: Resources;
  type:
    | typeof GET_RESOURCES.REQUEST
    | typeof GET_RESOURCES.SUCCESS
    | typeof GET_RESOURCES.FAILURE;
}

export const GET_RESOURCE_BY_ID = {
  REQUEST: "GET_RESOURCE_BY_ID_REQUEST",
  SUCCESS: "GET_RESOURCE_BY_ID_SUCCESS",
  FAILURE: "GET_RESOURCE_BY_ID_FAILURE",
};

export interface GetResourceByIdAction {
  response: Resource;
  type:
    | typeof GET_RESOURCE_BY_ID.REQUEST
    | typeof GET_RESOURCE_BY_ID.SUCCESS
    | typeof GET_RESOURCE_BY_ID.FAILURE;
}

export const GET_RESOURCE_BY_ID_AND_TYPE = {
  REQUEST: "GET_RESOURCE_BY_ID_AND_TYPE_REQUEST",
  SUCCESS: "GET_RESOURCE_BY_ID_AND_TYPE_SUCCESS",
  FAILURE: "GET_RESOURCE_BY_ID_AND_TYPE_FAILURE",
};

type GetResourceByIdAndTypeType =
  | typeof GET_RESOURCE_BY_ID_AND_TYPE.REQUEST
  | typeof GET_RESOURCE_BY_ID_AND_TYPE.SUCCESS
  | typeof GET_RESOURCE_BY_ID_AND_TYPE.FAILURE;

export interface GetResourceByIdAndTypeAction {
  response: Defense | OperationalExperience;
  type: GetResourceByIdAndTypeType;
}

export const GET_RESOURCE_AUTHORS = {
  REQUEST: "GET_RESOURCE_AUTHORS_REQUEST",
  SUCCESS: "GET_RESOURCE_AUTHORS_SUCCESS",
  FAILURE: "GET_RESOURCE_AUTHORS_FAILURE",
};

export interface ResourceAuthors extends Pagination {
  content: string[];
}

export interface GetResourceAuthorsAction {
  response: ResourceAuthors;
  type:
    | typeof GET_RESOURCE_AUTHORS.REQUEST
    | typeof GET_RESOURCE_AUTHORS.SUCCESS
    | typeof GET_RESOURCE_AUTHORS.FAILURE;
}

export const GET_DEFENSE_WRITEABLE_URL = {
  REQUEST: "GET_DEFENSE_WRITEABLE_URL_REQUEST",
  SUCCESS: "GET_DEFENSE_WRITEABLE_URL_SUCCESS",
  FAILURE: "GET_DEFENSE_WRITEABLE_URL_FAILURE",
};
export const ADD_TAG_TO_OE = {
  REQUEST: "ADD_TAG_TO_OE_REQUEST",
  SUCCESS: "ADD_TAG_TO_OE_SUCCESS",
  FAILURE: "ADD_TAG_TO_OE_FAILURE",
};
export const REMOVE_TAG_FROM_OE = {
  REQUEST: "REMOVE_TAG_FROM_OE_REQUEST",
  SUCCESS: "REMOVE_TAG_FROM_OE_SUCCESS",
  FAILURE: "REMOVE_TAG_FROM_OE_FAILURE",
};
