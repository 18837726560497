import React, { Dispatch, SetStateAction, useState } from "react";
import { useHistory } from "react-router";
import { useDispatch } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { Action } from "redux";

import { AppState } from "store";
import { MoreVertIcon } from "components/common/Card/styles";
import { Resource, SAVE_RESOURCE, ADD_RESOURCE } from "store/resources/types";
import {
  duplicateResource,
  updateResourceStatus,
} from "store/resources/actions";
import Popper from "components/common/Popper";

import * as S from "./styles";
import { ResourceStatus } from "@rtslabs/field1st-fe-common";

interface ResourceActionMenuProps {
  resource: Resource;
  onToggleResourceStatus: (resourceId: number, status: ResourceStatus) => void;
  setConfirmDeleteResource: Dispatch<SetStateAction<Resource | undefined>>;
}

const ResourceActionMenu = ({
  resource,
  onToggleResourceStatus,
  setConfirmDeleteResource,
}: ResourceActionMenuProps) => {
  const [popperIsOpen, setPopperIsOpen] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const history = useHistory();
  const dispatch = useDispatch<ThunkDispatch<AppState, void, Action>>();

  const handleClose = () => {
    if (popperIsOpen) {
      setPopperIsOpen(false);
    }
  };

  const handleOpen = (e) => {
    setAnchorEl(e.currentTarget);
    setTimeout(() => setPopperIsOpen(true));
  };

  const handleUpdateResourceStatus = async () => {
    // "DRAFT" is another possible status but we're not handling it yet (and there's no way for a user to create a draft at this time)
    const status = resource.status === "ARCHIVED" ? "ACTIVE" : "ARCHIVED";
    const response = await dispatch(updateResourceStatus(resource, status));
    if (response.type === SAVE_RESOURCE.SUCCESS) {
      setPopperIsOpen(false);
      onToggleResourceStatus(resource.id, status);
    }
  };

  const handleDuplicateResource = async () => {
    const response = await dispatch(duplicateResource(resource));
    if (response.type === ADD_RESOURCE.SUCCESS) {
      setPopperIsOpen(false);
      history.push(`/content/resources/${response.response.id}`);
    }
  };

  return (
    <>
      <MoreVertIcon onClick={handleOpen} />
      <Popper
        anchorEl={anchorEl}
        onClose={handleClose}
        open={popperIsOpen}
        placement="left"
      >
        <S.PopperWrapper>
          <S.PopperRow
            onClick={() => history.push(`/content/resources/${resource.id}`)}
          >
            <span>Edit Resource</span>
            <S.PopperChevron />
          </S.PopperRow>
          <S.PopperRow onClick={handleDuplicateResource}>
            <span>Duplicate</span>
            <S.PopperChevron />
          </S.PopperRow>
          <S.PopperRow error={true} onClick={handleUpdateResourceStatus}>
            <span>
              {resource.status === "ARCHIVED" ? "Activate" : "Archive"} Resource
            </span>
            <S.PopperChevron />
          </S.PopperRow>
          <S.PopperRow
            error={true}
            onClick={() => setConfirmDeleteResource(resource)}
          >
            <span>
              {resource.status === "ARCHIVED" ? "Delete" : "Delete"} Resource
            </span>
            <S.PopperChevron />
          </S.PopperRow>
        </S.PopperWrapper>
      </Popper>
    </>
  );
};

export default ResourceActionMenu;
