import React from "react";
import { History } from "history";

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "components/common/styled/Table";
import Icon from "components/common/Icon";
import { ActionMenu, ActionMenuItem } from "components/common/ActionMenu";
import { SortParams } from "store/common/types";
import NoResults from "components/common/TableUI/NoResults";
import S from "./styles";
import { BuilderFormSummaryVM } from "store/builder/types";
import ReadOnlyContent from "components/common/permissions/ReadOnlyContent";
import { WorkflowType } from "store/forms/types";
import Link from "components/common/Link";

interface Props {
  sort: SortParams;
  setSort: (sp: SortParams) => void;
  forms: BuilderFormSummaryVM[];
  onCreateNew: (mo: boolean) => void;
  setDesiredWorkflowType: (workflowType: WorkflowType) => void;
  setCurrSummary: (s: BuilderFormSummaryVM) => void;
  cloneForm: (id: number) => void;
  history: History;
}

// helper method to get the desired workflowType for the activation toggle
function getActivationToggle(currentWorkflowType: WorkflowType): WorkflowType {
  switch (currentWorkflowType) {
    // draft/final to deactivated
    case "DRAFT":
      return "DEACTIVATED_DRAFT";
    case "FINAL":
      return "DEACTIVATED_FINAL";

    // deactivated to draft/final
    case "DEACTIVATED_DRAFT":
      return "DRAFT";
    case "DEACTIVATED_FINAL":
    case "DEACTIVATED":
      return "FINAL";
    default:
      // shouldn't be able to get here
      return currentWorkflowType;
  }
}

export function FormListTable({
  sort,
  setSort,
  forms,
  onCreateNew,
  setDesiredWorkflowType,
  setCurrSummary,
  cloneForm,
  history,
}: Props) {
  return (
    <Table>
      <TableHead>
        <TableRow noBottomBorder>
          <TableCell
            active={sort[0] === "type"}
            onClick={(dir) => setSort(["type", dir])}
            sortDirection={sort[1]}
          >
            Type
          </TableCell>
          <TableCell
            active={sort[0] === "name"}
            onClick={(dir) => setSort(["name", dir])}
            sortDirection={sort[1]}
          >
            Name
          </TableCell>
          <TableCell
            active={sort[0] === "submissions"}
            onClick={(dir) => setSort(["submissions", dir])}
            sortDirection={sort[1]}
          >
            Submissions
          </TableCell>
          <TableCell>Group Usage</TableCell>
          <TableCell
            active={sort[0] === "status"}
            onClick={(dir) => setSort(["workflowType", dir])}
            sortDirection={sort[1]}
          >
            Status
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {forms.length > 0 ? (
          forms.map((form, idx) => (
            <TableRow key={`${form.name}_${idx}`}>
              <TableCell>
                {form.type.iconName ? (
                  <Icon
                    type={form.type.iconName}
                    color={form.type.iconColor || ""}
                    framed={true}
                  />
                ) : (
                  <span>{form.name}</span>
                )}
              </TableCell>
              <TableCell>
                <ReadOnlyContent component={<>{form.name}</>}>
                  <Link to={`/forms/form/${form.id}`}>{form.name}</Link>
                </ReadOnlyContent>
              </TableCell>
              <TableCell>{form.submissions}</TableCell>
              <TableCell>{form.groupUsage}</TableCell>
              <TableCell>
                {form.workflowType.startsWith("DEACTIVATED")
                  ? "DEACTIVATED"
                  : form.workflowType}
              </TableCell>
              <TableCell>
                <ActionMenu>
                  {/* Form builder link */}
                  <ReadOnlyContent>
                    <ActionMenuItem
                      onClick={() => history.push(`/forms/form/${form.id}`)}
                      label="View/Edit form"
                    />
                  </ReadOnlyContent>

                  {/* Clone form */}
                  <ReadOnlyContent>
                    <ActionMenuItem
                      onClick={() => cloneForm(form.id)}
                      label="Clone form"
                    />
                  </ReadOnlyContent>

                  {/* Reports link */}
                  <ActionMenuItem
                    onClick={() => history.push(`/reports/${form.id}`)}
                    label="View detailed form report"
                  />

                  {/* Toggle Deactivated */}
                  <ReadOnlyContent>
                    <ActionMenuItem
                      danger
                      onClick={() => {
                        setCurrSummary(form);
                        setDesiredWorkflowType(
                          getActivationToggle(form.workflowType)
                        );
                      }}
                      label={
                        (form.workflowType.startsWith("DEACTIVATED")
                          ? "Enable"
                          : "Disable") + " form (permissions required)"
                      }
                    />
                  </ReadOnlyContent>

                  {/* Delete - todo also compare outstanding count once BE has added it */}
                  {form.submissions + form.outstanding > 0 ? null : (
                    <ReadOnlyContent>
                      <ActionMenuItem
                        danger
                        onClick={() => {
                          setCurrSummary(form);
                          setDesiredWorkflowType("DELETED");
                        }}
                        label={"Delete form (permissions required)"}
                      />
                    </ReadOnlyContent>
                  )}

                  {/* Discard Draft */}
                  {form.workflowType != "DRAFT" ? null : (
                    <ReadOnlyContent>
                      <ActionMenuItem
                        danger
                        onClick={() => {
                          setCurrSummary(form);
                          setDesiredWorkflowType("DISCARDED_DRAFT");
                        }}
                        label={"Discard changes (permissions required)"}
                      />
                    </ReadOnlyContent>
                  )}
                </ActionMenu>
              </TableCell>
            </TableRow>
          ))
        ) : (
          <NoResults
            icon="document"
            header="No forms found"
            body="Create a new form"
            button={{
              children: "Create a form",
              onClick: () => onCreateNew(true),
            }}
          />
        )}
      </TableBody>
    </Table>
  );
}
