import React from "react";
import { connect } from "react-redux";
import { Formik, FormikHelpers } from "formik";
import { RouteComponentProps, withRouter } from "react-router";

import * as S from "./styles";
import Select from "components/common/form/Select";
import { AppState } from "store";
import { FeedbackDTO } from "../types";
import { Option } from "components/common/form/Select/Select";
import { UserState } from "store/user/types";
import { submitFeedback } from "store/feedback/actions";
import { getAllFeedbackTypes } from "store/feedbackTypes/actions";
import { FeedbackTypeVm } from "store/feedbackTypes/types";
import Text from "components/common/Text";
import Loader from "components/common/Loader";

// Types
type FeedbackOptions = Array<Option>;

interface FormikValues {
  selectFeedbackType: number;
  commentInput: string;
}

// Formik State Initial Values
const initialValues: FormikValues = {
  selectFeedbackType: -1,
  commentInput: "",
};

// Formik Validation
type ErrorsType = {
  selectFeedbackType?: string;
  commentInput?: string;
};

const validate = (values) => {
  const errors: ErrorsType = {};

  // Check if selection has not been made
  if (values.selectFeedbackType < 0) {
    errors.selectFeedbackType = "Please select a feedback type";
  }
  // Check if comment is less than 3 characters
  if (values.commentInput.length < 3) {
    errors.commentInput = "Please provide a meaningful comment";
  }

  return errors;
};

export interface ReportFeedbackFormProps extends RouteComponentProps {
  dispatch: any;
  submitFeedbackVisible: boolean;
  toggleSubmitFeedbackVisibility: () => void;

  // ? REDUX
  user: UserState;
}

export interface ReportFeedbackFormState {
  feedbackTypes: FeedbackTypeVm[];
  feedbackOptions: FeedbackOptions;
  isLoadingFeedback: boolean;
  submitLoading: boolean;
  submitStatus: "success" | "failure" | null;
  errorStatus: string | null;
}

class ReportFeedbackForm extends React.Component<
  ReportFeedbackFormProps,
  ReportFeedbackFormState
> {
  constructor(props) {
    super(props);
    this.state = {
      feedbackOptions: [
        {
          id: -1,
          value: "Select a feedback type",
          disabled: true,
          optionPlaceholder: true,
        },
      ],
      feedbackTypes: [],
      isLoadingFeedback: false,
      submitLoading: false,
      submitStatus: null,
      errorStatus: null,
    };
  }

  async componentDidMount() {
    this.setState({ isLoadingFeedback: true });
    try {
      const feedbackTypes: FeedbackTypeVm[] = await this.props.dispatch(
        getAllFeedbackTypes()
      );

      this.setState((state) => ({
        feedbackTypes: feedbackTypes,
        feedbackOptions: [
          ...state.feedbackOptions,
          ...feedbackTypes.map((ft) => ({
            id: ft.id,
            value: ft.selectionText,
          })),
        ],
        errorStatus: null,
        isLoadingFeedback: false,
      }));
    } catch (err) {
      this.setState({
        isLoadingFeedback: false,
        errorStatus: "Error fetching feedback types.",
      });
    }
  }

  handlePlaceholderText = (selectedId: number) => {
    const { feedbackTypes } = this.state;
    const matchedFeedbackType = feedbackTypes.find((ft) => ft.id === selectedId);
    return matchedFeedbackType?.placeholderText ? matchedFeedbackType.placeholderText : "Enter a detailed comment here";
  };

  _handleSubmit = (
    values: FormikValues,
    resetForm: FormikHelpers<FormikValues>["resetForm"]
  ) => {
    const { selectFeedbackType, commentInput } = values;
    const { user } = this.props;

    if (user.data !== null && user.data.authorities) {
      // Get browserInfo from navigator object
      let browserInfo = "";
      for (const obj in navigator) {
        browserInfo += obj + ":" + JSON.stringify(navigator[obj]) + "\n";
      }
      const payload: FeedbackDTO = {
        browserInfo,
        clientGroupId: user.data.primaryGroupId,
        feedback: commentInput,
        feedbackTypeId: selectFeedbackType,
        platform: "WEB",
        uiGitCommit: process.env.REACT_APP_LATEST_COMMIT,
        uiVersion: process.env.REACT_APP_VERSION,
        userId: user.data.user.id,
      };

      this.setState({ submitLoading: true, submitStatus: null });
      this.props.dispatch(submitFeedback(payload)).then((e) => {
        if (e.response) {
          // Stop loading if we get a response
          this.setState({ submitLoading: false });
          if (e.type === "SUBMIT_FEEDBACK_SUCCESS") {
            // Change status to success
            this.setState({ submitStatus: "success" });
            // And reset the form
            resetForm();
            // Redirect to /help-desk
            setTimeout(() => this.props.history.push("/help-desk"), 2000);
          } else {
            // Change status to failure
            this.setState({ submitStatus: "failure" });
          }
        } else {
          console.log("FAILURE: No response"); //FIXME: Do we need to handle if there is no response?
        }
      });
    }
  };

  handleSubmitStatus = (
    status: string | null,
    loading: boolean,
    defaultValue: string
  ) => {
    //First, check if loading.
    switch (loading) {
      case true:
        // If so, break out of the switch
        break;
      default:
        // If not loading, check response status
        switch (status) {
          case "success":
            return (
              <>
                <S.Icon className="icon icon-icons8-checkmark" />
                FEEDBACK SENT
              </>
            );
          case "failure":
            return "FAILED TO SUBMIT";
          default:
            // If not loading and do not have a response status, show default value
            return defaultValue.toUpperCase();
        }
    }
  };

  render() {
    const {
      history: { push },
    } = this.props;
    const { feedbackOptions, errorStatus, isLoadingFeedback } = this.state;
    return (
      <>
        <Formik
          initialValues={initialValues}
          onSubmit={(values, { setFieldTouched, resetForm }) => {
            if (this.state.submitStatus === "success") return;
            //Set fields to touched
            setFieldTouched("selectFeedbackType", true, true);
            setFieldTouched("commentInput", true, true);

            this._handleSubmit(values, resetForm);
          }}
          validate={validate}
          validateOnChange={false}
        >
          {(props) => {
            const { errors, handleSubmit, setValues, touched, values } = props;
            const { selectFeedbackType, commentInput } = values;
            return (
              <>
                <Loader loading={isLoadingFeedback}>
                  <form onSubmit={handleSubmit}>
                    <S.FeedbackTypeWrapper>
                      <Select
                        error={errors["selectFeedbackType"]}
                        label={"Type of Feedback"}
                        name={"selectFeedbackType"}
                        noMargin
                        onChange={(e) =>
                          setValues({
                            ...values,
                            selectFeedbackType: e.target.value,
                          })
                        }
                        options={feedbackOptions}
                        required={true}
                        touched={touched["selectFeedbackType"]}
                        value={selectFeedbackType}
                      />
                    </S.FeedbackTypeWrapper>
                    {errorStatus && <Text variant="error">{errorStatus}</Text>}
                    <S.TextArea
                      error={errors["commentInput"]}
                      initialRowsVisible={5}
                      internalVariant={"TextArea"}
                      label={"Comment"}
                      maxRowsVisible={5}
                      multiline={true}
                      name={"commentInput"}
                      onChange={(e) =>
                        setValues({ ...values, commentInput: e.target.value })
                      }
                      placeholder={this.handlePlaceholderText(selectFeedbackType)}
                      touched={touched["commentInput"]}
                      value={commentInput}
                      variant={"outlined"}
                    />
                    <S.ButtonsWrapper>
                      <S.SubmitButton
                        type={
                          this.state.submitStatus !== "success"
                            ? "submit"
                            : undefined
                        }
                        fullWidth={true}
                        height={2.5}
                        loading={this.state.submitLoading}
                        status={this.state.submitStatus}
                        disabled={this.state.submitLoading}
                      >
                        {this.handleSubmitStatus(
                          this.state.submitStatus,
                          this.state.submitLoading,
                          "Submit Feedback"
                        )}
                      </S.SubmitButton>
                      {this.state.submitStatus !== "success" && (
                        <S.CancelButton
                          onClick={() => push("/help-desk")}
                          variant={"unstyled-danger"}
                          fullWidth={true}
                          height={2.5}
                          disabled={this.state.submitLoading}
                        >
                          CANCEL
                        </S.CancelButton>
                      )}
                    </S.ButtonsWrapper>
                  </form>
                </Loader>
              </>
            );
          }}
        </Formik>
      </>
    );
  }
}

const mapStateToProps = (state: AppState) => {
  return {
    user: state.user,
  };
};

export default withRouter(connect(mapStateToProps)(ReportFeedbackForm));
