import React, { useMemo } from "react";

import NavigationList, {
  NavigationMenuLink,
} from "components/navigation/NavigationList";
import SidebarNavigation from "components/navigation/SidebarNavigation";
import styled from "styled-components";
import { AppState } from "store";
import { UserRole } from "store/user/types";
import { WidgetHeader } from "components/common/styled/WidgetHeader";
import {
  peopleRoles,
  formsRoles,
  contentRoles,
} from "routes/constants/permissionSets";
import { useSelector } from "react-redux";
import useGroupTerm from "util/hooks/whiteLabel/useGroupTerm";

const NavHeader = styled(WidgetHeader)`
  margin: 1rem 0 0.5rem;
  display: block;
`;

const peopleMenu = [
  {
    id: "101",
    icon: "icon-icons8-business_conference_female_speaker",
    link: "People",
    // linkTo: "",
    expansion: [
      {
        id: "101-1",
        icon: "",
        link: "Groups",
        linkTo: "/people/groups",
      },
      {
        id: "101-2",
        icon: "",
        link: "Users",
        linkTo: "/people/users",
      },
    ],
  },
];

const formsMenu = [
  {
    id: "003",
    icon: "icon-icons8-multi_edit",
    link: "Forms",
    // linkTo: "/forms",
    expansion: [
      {
        id: "003-1",
        icon: "",
        link: "Form Templates",
        linkTo: "/forms",
      },
      {
        id: "003-2",
        icon: "",
        link: "Data Sets",
        linkTo: "/forms/data-sets",
      },
      // {
      //   id: "003-3",
      //   icon: "",
      //   link: "Content",
      //   linkTo: "/forms/content",
      // },
      {
        id: "003-4",
        icon: "",
        link: "Widgets",
        linkTo: "/forms/widgets",
        disabled: true,
      },
    ],
  },
];

const contentMenu = [
  {
    id: "004",
    icon: "icon-icons8-overview_pages_4",
    link: "Content",
    // linkTo: "/content",
    expansion: [
      {
        id: "004-1",
        icon: "",
        link: "Resources",
        linkTo: "/content/resources",
      },
      {
        id: "004-2",
        icon: "",
        link: "Resource Categories",
        linkTo: "/content/resource-categories",
      },
      {
        id: "004-3",
        icon: "",
        link: "Tag Library",
        linkTo: "/content/tag-library",
      },
    ],
  },
];

interface Props {
  inMobileExpansion?: boolean;
}

const AppNavigation = ({ inMobileExpansion = false }: Props) => {
  // Group config terms
  const documentsTerm =
    useGroupTerm("document", "noun", "plural") || "Documents";

  // Home, Documents, Reports menu
  const homeDocumentsReportsMenu = useMemo(
    () => [
      {
        id: "001",
        icon: "icon-icons8-a_home",
        link: "Home",
        linkTo: "/",
      },
      {
        id: "002",
        icon: "icon-icons8-document-2",
        link: documentsTerm,
        linkTo: "/documents",
      },
      {
        icon: "icon-icons8-combo_chart",
        link: "Reports",
        linkTo: "/reports",
      },
      {
        icon: "icon-icons8-help",
        link: "Help Desk",
        linkTo: "/help-desk",
      },
    ],
    [documentsTerm]
  );

  // Get user roles
  const userRoles: UserRole[] = useSelector(
    (state: AppState) =>
      state.user?.data?.authorities.map((e) => e.authority) || []
  );

  const showContent = useMemo(
    () => userRoles.some((r) => contentRoles.includes(r)),
    [userRoles]
  );
  const showForms = useMemo(
    () => userRoles.some((r) => formsRoles.includes(r)),
    [userRoles]
  );
  const showPeople = useMemo(
    () => userRoles.some((r) => peopleRoles.includes(r)),
    [userRoles]
  );

  // Flag's used to show `SYSTEM ADMIN` menu header
  const showAdminMenuHeader = useMemo(
    () => showForms || showPeople || showContent,
    [showForms, showPeople, showContent]
  );

  // Array of options for admin menu sections
  const adminMenuData = useMemo(() => {
    const data: NavigationMenuLink[] = [
      ...(showPeople ? peopleMenu : []),
      ...(showForms ? formsMenu : []),
      ...(showContent ? contentMenu : []),
    ];
    return data;
  }, [showPeople, showForms, showContent]);

  // Has fewer links because bottom navigation already has them -- GK
  const _homeDocumentsReportsMenu = useMemo(() => {
    if (inMobileExpansion) {
      return homeDocumentsReportsMenu.filter(
        (menuItem) => !["/", "/documents"].includes(menuItem.linkTo)
      );
    }
    return homeDocumentsReportsMenu;
  }, [inMobileExpansion]);

  return (
    <SidebarNavigation hidePrivacyLinks={inMobileExpansion}>
      <NavigationList menuData={_homeDocumentsReportsMenu} />

      {showAdminMenuHeader && <NavHeader>System Admin</NavHeader>}

      {adminMenuData?.length > 0 && <NavigationList menuData={adminMenuData} />}
    </SidebarNavigation>
  );
};

export default AppNavigation;
