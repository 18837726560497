import React from "react";
import styled from "styled-components";

import FormControlBase, {
  FormControlProps,
} from "@material-ui/core/FormControl";
import MenuItemBase from "@material-ui/core/MenuItem";
import OutlinedInputBase, {
  OutlinedInputProps as OutlinedInputPropsBase,
} from "@material-ui/core/OutlinedInput";
import SelectBase, {
  SelectProps as SelectPropsBase,
} from "@material-ui/core/Select";

import mediaQueries from "themes/mediaQueries";
import { remCalc } from "themes/helpers";
import { selectHasValue } from "components/common/FilterSelect/styles";

const SelectInput = styled.div<{ width?: number }>`
  display: flex;
  flex-direction: column;
  ${({ width }) => (width ? `width: ${remCalc(width)};` : "")}
`;

const FormControl = styled<React.FC<FormControlProps>>(({ ...otherProps }) => (
  <FormControlBase
    {...otherProps}
    classes={{
      root: "root",
    }}
  />
))`
  &&.root {
  }
`;

const MenuItem = styled((props) => (
  <MenuItemBase
    {...props}
    classes={{
      root: "root",
      selected: "selected",
    }}
  />
))`
  &&.root {
    display: ${({ placeholder }) => placeholder && "none"};
    color: ${({ theme }) => theme.colors.darkGrey};
  }
  &&.root:hover,
  &&.Mui-focusVisible {
    ${({ theme }) => {
      return `
        background-color: ${theme.colors.activeFill};
        color: ${theme.colors.black};
      `;
    }}
  }
`;

interface SelectProps extends SelectPropsBase {
  active?: boolean;
}

const Select = styled<React.FC<SelectProps>>(({ ...otherProps }) => (
  <SelectBase
    {...otherProps}
    classes={{
      select: "select",
      icon: "icon",
      outlined: "outlined",
    }}
  />
))`
  && .select {
    background-color: ${({ theme }) => theme.colors.fieldInputBackground};
    color: ${({ active, value, theme }) =>
      active
        ? theme.colors.primary
        : selectHasValue(
            value,
            theme.colors.formFieldColor,
            theme.masterColors.darkGrey
          )};
    height: ${remCalc(56)};
    font-size: ${remCalc(16)};
    padding: 0;
    padding-left: ${remCalc(16)};
    padding-right: ${remCalc(16)};
    display: flex;
    justify-content: flex-start;
    align-items: center;
    @media (${mediaQueries.minTablet}) {
      height: ${remCalc(56)};
      font-size: ${remCalc(16)};
    }
  }
  && .select:focus {
    background-color: transparent;
  }
  && .select.disabled {
    background-color: ${({ theme }) => theme.colors.lightGrey};
  }
  && .icon {
    color: ${({ theme }) => theme.colors.formFieldColor};
  }
  &.error {
    .notchedOutline {
      border-width: 1px;
    }
  }
`;

interface OutlinedInputProps extends OutlinedInputPropsBase {
  isFocusedByMouse: boolean;
}

const OutlinedInput = styled<React.FC<OutlinedInputProps>>(
  ({ isFocusedByMouse, ...otherProps }) => (
    <OutlinedInputBase
      {...otherProps}
      classes={{
        disabled: "disabled",
        root: "root",
        notchedOutline: "notchedOutline",
        focused: "focused",
        error: "error",
      }}
    />
  )
)`
  &&.disabled {
    background-color: ${({ theme }) => theme.colors.lightGrey};
  }
  &&.disabled .notchedOutline {
    border-color: ${({ theme }) => theme.colors.darkGrey};
  }
  &&.focused .notchedOutline {
    ${({ isFocusedByMouse, theme }) => {
      if (isFocusedByMouse) {
        return `
          border-color: ${theme.colors.primary};
          box-shadow: 0 0 0 ${remCalc(1)} ${theme.colors.primary};
        `;
      } else {
        return `
          border-color: ${theme.colors.focusIndicatorOutline};
          box-shadow: 0 0 0 ${remCalc(3)} ${theme.colors.focusIndicatorOutline};
        `;
      }
    }}
  }
  &&.notchedOutline {
    border-color: ${({ theme }) => theme.colors.darkGrey};
    border-width: 1px;
  }
  &&.error {
    background-color: ${({ theme }) => theme.colors.errorFill};
  }
  &&.error:not(.focused) .notchedOutline {
    border-color: ${({ theme }) => theme.colors.error};
  }
`;

const HelperText = styled.span<{ hasValue?: boolean }>`
  display: block;
  color: ${({ theme, hasValue }) =>
    hasValue ? theme.masterColors.darkGrey : theme.colors.assitiveText};
  font-size: ${remCalc(16)};
  margin: ${remCalc(3)} ${remCalc(5)} 0rem;
  font-family: "Roboto";
  @media (${mediaQueries.maxTablet}) {
    font-size: ${remCalc(18)};
  }
`;

const ErrorText = styled.span`
  display: block;
  color: ${({ theme }) => theme.colors.error};
  font-size: ${remCalc(16)};
  margin: ${remCalc(3)} ${remCalc(5)} 0rem;
  font-family: "Roboto";
`;

export {
  FormControl,
  MenuItem,
  OutlinedInput,
  Select,
  SelectInput,
  ErrorText,
  HelperText,
};
