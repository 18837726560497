import ListItemBase from "@material-ui/core/ListItem";
import ListItemIconBase from "@material-ui/core/ListItemIcon";
import ListItemSecondaryActionBase from "@material-ui/core/ListItemSecondaryAction";
import ListItemTextBase from "@material-ui/core/ListItemText";
import React from "react";
import styled from "styled-components";
import { ThemeTypes } from "themes/colors";
import { remCalc } from "themes/helpers";
import mediaQueries from "themes/mediaQueries";
import Text from "components/common/Text";

import { ListItemColumnVariant, VariantType } from "./ListItem";

const ListItem = styled.div``;

const MUIListItem = styled(({ variant, active, rounded, ...otherProps }) => (
  <ListItemBase {...otherProps} classes={{ root: "root" }} />
))`
  &&.root {
    padding: ${remCalc(5)} ${remCalc(10)};
  }
  display: flex;
  flex-direction: row;

  border: 1px solid
    ${({ theme, variant }) =>
    variant === "participant-selected"
      ? theme.masterColors.darkGrey
      : theme.masterColors.lightGrey};

  border-radius: ${({ rounded }) => (rounded ? "4px" : "0px")};
  background: ${({ theme, active }) =>
    active ? theme.colors.activeFill : theme.colors.fieldInputBackground};
  min-height: ${remCalc(32)};
  margin-bottom: ${remCalc(8)};

  &:hover {
    cursor: pointer;
  }

  // HANDLE RESPONSIVE STYLES

  @media (${mediaQueries.maxTablet}) {
    min-height: ${remCalc(32)};
  }

  @media (${mediaQueries.maxPhone}) {
    min-height: ${remCalc(32)};
  }
`;

const TextColorActive = (theme: ThemeTypes) => ({
  Base: theme.colors.activeHeader,
  ItemSelectorDrawer: theme.masterColors.darkGrey
});

const TextColor = (theme: ThemeTypes) => ({
  Base: theme.colors.header,
  ItemSelectorDrawer: theme.masterColors.darkGrey
});

const ListItemText = styled(({ active, variant, ...otherProps }) => (
  <ListItemTextBase
    {...otherProps}
    classes={{ primary: "primary", secondary: "secondary" }}
  />
))`
  && .primary {
    color: ${({ theme, active, variant }) =>
    active ? TextColorActive(theme)[variant] : TextColor(theme)[variant]};

    @media (${mediaQueries.maxTablet}) {
      font-size: ${remCalc(16)};
    }

    @media (${mediaQueries.maxPhone}) {
      font-size: ${remCalc(16)};
    }
  }

  && .secondary {
    color: ${({ theme, active, variant }) =>
    active ? TextColorActive(theme)[variant] : TextColor(theme)[variant]};
    font-size: 16px;

    @media (${mediaQueries.maxTablet}) {
    }
  }
`;

const ListItemSecondaryAction = styled(({ flexEnd, ...otherProps }) => (
  <ListItemSecondaryActionBase {...otherProps} />
))`
  display: flex;
  align-items: ${({ flexEnd }) => (flexEnd ? "flex-end" : "center")};

  height: 100%;
  padding-bottom: ${({ flexEnd }) => (flexEnd ? "10px" : "0px")};

  margin: 0;
`;
const ListItemButton = styled.button<{
  hideTextDecoration?: boolean;
  buttonType: "reset" | "change";
}>`
  border: none;
  background: none;
  font-size: 16px;

  @media (${mediaQueries.maxTablet}) {
    /* font-size: ${remCalc(24)}; */
  }

  ${({ buttonType, theme, hideTextDecoration }) => {
    switch (buttonType) {
      case "change":
        return `
        text-decoration: ${hideTextDecoration ? "none" : "underline"};
        color: ${theme.colors.primary};
        `;
      default:
        return "";
    }
  }}
`;

const ListItemIcon = styled(({ ...otherProps }) => (
  <ListItemIconBase {...otherProps} />
))``;

const Icon = styled.span<{ color: string; framed?: boolean }>`
  color: ${({ color }) => color};
  font-size: 1.25rem;
  ${({ framed, theme }) =>
    framed &&
    `
    background-color: ${theme.masterColors.lightGrey};
    width: 34px;
    height: 34px;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
  `}
`;

const DynamicRow = styled.div<{
  renderAsColumn?: boolean;
  wrapSubLabel?: boolean;
}>`
  display: flex;
  flex-direction: ${({ renderAsColumn }) =>
    renderAsColumn ? "column" : "row"};
  /* justify-content: ${({ renderAsColumn }) =>
    renderAsColumn ? "space-between" : "flex-start"}; */
  justify-content: space-between;

`;

const Row = styled.div<{
  variant?: VariantType;
  wrapSubLabel?: boolean;
  column?: boolean;
}>`
  display: flex;
  flex-direction: row;
  width: 100%;

    justify-content: flex-end;

  ${({ column }) => {
    if (column) {
      return `
        flex-direction: column;
      `;
    }
  }}

  ${({ variant }) =>
    variant === "ItemSelectorDrawer" &&
    `
    justify-content: flex-end;
    text-align: right;
  `}

  ${({ wrapSubLabel }) => {
    if (wrapSubLabel) {
      return `
      width: 70%;
      `;
    }
  }}
`;

const Column = styled.div<{ listItemColumnVariant: ListItemColumnVariant }>`
  display: flex;
  flex-direction: column;
  height: 100%;

  /* HANDLE VARIANTS */
  ${({ listItemColumnVariant }) => {
    switch (listItemColumnVariant) {
      case "auto-width":
        return `
          width: auto;
        `;
      case "participant-list-item":
        return `
          width: 100%;
        `;
      case "form-list-item":
        return `
          width: 75%;
        `;
      default:
        return `
          width: 100%;
        `;
    }
  }}
`;

const TertiaryLabel = styled(Text)``;

export {
  ListItem,
  MUIListItem,
  ListItemText,
  ListItemSecondaryAction,
  ListItemIcon,
  Row,
  Column,
  Icon,
  ListItemButton,
  DynamicRow,
  TertiaryLabel
};
