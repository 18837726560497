import { css } from "aphrodite/no-important";
import { Moment } from "moment";
import React, { forwardRef, MouseEventHandler } from "react";
import { useThemedStyleSheet } from "util/hooks/useThemedStyleSheet";
import { styles } from "./dateButtonStyles";
import { ReactComponent as Calendar } from "assets/svg/calendar.svg";
import { useKeyboardFocus } from "util/hooks/useKeyboardFocus";

interface DateButtonProps {
  handleClick: (
    anchor: HTMLButtonElement | null,
    isKeyboardFocused: boolean
  ) => void;
  isActive: boolean;
  label: string;
  labelId: string;
  name: string;
  selectedDate: Moment | null;
}

export const DateButton = forwardRef<HTMLButtonElement, DateButtonProps>(
  ({ handleClick, isActive, label, labelId, name, selectedDate }, ref) => {
    const { isKeyboardFocused, ...eventHandlers } = useKeyboardFocus<
      HTMLButtonElement
    >();
    const themedStyles = useThemedStyleSheet(styles);
    const buttonLabelId = `${name}-label`;
    const valueId = `${name}-value`;

    const localHandleClick: MouseEventHandler<HTMLButtonElement> = (event) => {
      handleClick(event.currentTarget, isKeyboardFocused);
    };

    return (
      <>
        <label className="sr-only" id={buttonLabelId}>
          {label}
        </label>
        <button
          aria-labelledby={[valueId, buttonLabelId, labelId].join(" ")}
          className={css(
            themedStyles.dateButton,
            isActive && themedStyles.dateButtonActive,
            isKeyboardFocused
              ? themedStyles.dateButtonKeyboardFocused
              : themedStyles.dateButtonMouseFocused
          )}
          onClick={localHandleClick}
          ref={ref}
          type="button"
          {...eventHandlers}
        >
          <Calendar
            className={css(
              themedStyles.calendarIcon,
              isActive && themedStyles.calendarIconActive
            )}
          />
          <span id={valueId}>
            {selectedDate ? selectedDate.format("l") : "mm/dd/yyyy"}
          </span>
        </button>
      </>
    );
  }
);
