import { FormDTO, FormSummaryVm, QuestionDTO } from "../forms/types";
import { APIResponse, GeoPoint, Pagination, Person } from "store/common/types";
import { ResourceStatus } from "@rtslabs/field1st-fe-common";
import { OperationalExperience } from "../resources/types";
import { CategoryDTO } from "store/categories/types";
// import { ClientGroup } from "store/clientGroups/types";
// import { ActionSettingsBrightness } from "material-ui/svg-icons";

// Describing the shape of the system's slice of state
export interface DocumentsState {
  loading: {
    participantDocuments: {
      DELETE?: boolean;
      GET?: boolean;
      POST?: boolean;
      PUT?: boolean;
    };
    participantDocumentsInfinite: {
      GET?: boolean;
    };
    searchDocuments: {
      GET?: boolean;
    };
    searchDocumentsInfinite: {
      GET?: boolean;
    };
    tempFilterDocuments: {
      GET?: boolean;
    };
    ownerIds: {
      GET?: boolean;
    };
    singleDocument: {
      [key: number]: boolean;
    };
    deleteDocument: {
      [key: number]: boolean;
    };
  };
  /**
   * booleans which represent if the associated
   * feature's API call has failed.
   * Generally, these values should always be
   * associated with an API call
   */
  error: {
    deleteDocument: { [key: number]: boolean };
    ownerIds: boolean;
    participantDocuments: boolean;
    searchDocuments: boolean;
    singleDocument: { [key: number]: boolean };
  };
  data: DocumentsData;
  queryStrings?: {
    searchDocuments?: string;
  };
}

interface DocumentsData {
  ownerIds: Owners;
  participantDocuments?: PageOfDocumentSummaryVM;
  searchDocuments?: ParticipantDocuments;
  tempFilterDocuments?: PageOfDocumentSummaryVM;
}

export interface Owners extends Pagination {
  content?: DocumentOwner[];
}

export interface DocumentOwner extends Person {
  participantId: number;
}

export interface DocumentClientGroup {
  id: number;
  name: string;
}

// created a new type with the same name as swagger
// so I'm doing this to prevent needing to update the rest of the app
// for now
// @TODO: update use cases of this type name to use the new name
type ParticipantDocuments = PageOfDocumentSummaryVM;
// interface ParticipantDocuments {
//   content?: ParticipantDocumentsContent[];
//   empty: boolean;
//   first: boolean;
//   last: boolean;
//   number: number;
//   numberOfElements: number;
// pageable?: {
//   sort: {
//     sorted: boolean;
//     unsorted: boolean;
//     empty: boolean;
//   };
//   offset: number;
//   pageSize: number;
//   pageNumber: number;
//   paged: boolean;
//   unpaged: false;
// };
//   size: number;
//   sort: ParticipantDocumentsSort;
//   totalElements: number;
//   totalPages: number;
// }

export const GET_PARTICIPANT_DOCUMENTS = {
  FAILURE: "GET_PARTICIPANT_DOCUMENTS_FAILURE",
  REQUEST: "GET_PARTICIPANT_DOCUMENTS_REQUEST",
  SUCCESS: "GET_PARTICIPANT_DOCUMENTS_SUCCESS",
};

export const SEARCH_DOCUMENTS = {
  FAILURE: "SEARCH_DOCUMENTS_FAILURE",
  REQUEST: "SEARCH_DOCUMENTS_REQUEST",
  SUCCESS: "SEARCH_DOCUMENTS_SUCCESS",
};

export const GET_DOCUMENT = {
  FAILURE: "GET_DOCUMENT_FAILURE",
  REQUEST: "GET_DOCUMENT_REQUEST",
  SUCCESS: "GET_DOCUMENT_SUCCESS",
};

// Temp mobile filters
export const GET_TEMP_FILTER_DOCUMENTS = {
  FAILURE: "GET_TEMP_FILTER_DOCUMENTS_FAILURE",
  REQUEST: "GET_TEMP_FILTER_DOCUMENTS_REQUEST",
  SUCCESS: "GET_TEMP_FILTER_DOCUMENTS_SUCCESS",
};

export const GET_OWNER_IDS = {
  FAILURE: "GET_OWNER_IDS_FAILURE",
  REQUEST: "GET_OWNER_IDS_REQUEST",
  SUCCESS: "GET_OWNER_IDS_SUCCESS",
};

export const DELETE_DOCUMENT = {
  FAILURE: "DELETE_DOCUMENT_FAILURE",
  REQUEST: "DELETE_DOCUMENT_REQUEST",
  SUCCESS: "DELETE_DOCUMENT_SUCCESS",
};

// For `documents/:id/history`
export const GET_DOCUMENT_HISTORY = {
  FAILURE: "GET_DOCUMENT_HISTORY_FAILURE",
  REQUEST: "GET_DOCUMENT_HISTORY_REQUEST",
  SUCCESS: "GET_DOCUMENT_HISTORY_SUCCESS",
};

// For `documents/:id/related`
export const GET_RELATED_DOCUMENTS = {
  FAILURE: "GET_RELATED_DOCUMENTS_FAILURE",
  REQUEST: "GET_RELATED_DOCUMENTS_REQUEST",
  SUCCESS: "GET_RELATED_DOCUMENTS_SUCCESS",
};

interface GetParticipantDocuments {
  // page?: string | number;
  loaderKey: string;
  infinite?: boolean;
  queryString?: string;
  response?: any;
  type:
    | typeof GET_PARTICIPANT_DOCUMENTS.FAILURE
    | typeof GET_PARTICIPANT_DOCUMENTS.REQUEST
    | typeof GET_PARTICIPANT_DOCUMENTS.SUCCESS;
}

export interface SearchDocumentsParams {
  clientGroupIds?: string | number;
  formIds?: string | number;
  formTypeIds?: string | number;
  infinite?: boolean;
  maxSubmissionDate?: string;
  minSubmissionDate?: string;
  ownerIds?: number | null;
  page?: string | number | null;
  participantId?: number;
  participantTreeFilter?: string;
  query: string;
  size?: number;
  sortName?: string;
  sortOrder?: string;
  statuses?: string | number;
  submissionTypes?: string;
  value?: number;
}

export type DocumentsActionTypes = GetParticipantDocuments;

export type ParticipantDocumentsType = ParticipantDocuments;

// --------------------------------------------------------------------------
// ? TYPE DEFINITIONS FOR API VALUES
// The following types describe API response values which are
// directly related to the "Document" feature
// --------------------------------------------------------------------------

export type DisplayConditionDTO = {
  id?: number; // will not have id if widget-generated
  action: string; // "SHOW" | "HIDE" | "PREFILL"
  booleanCondition?: boolean;
  prefillAnswerField?: string;
  prefillAssociatedIdField?: string;
  prefillAssociatedLocation?: string;
  sourceConditionRootId?: number;
  sourceQuestionRootId: number;
  targetRootId: number;
  targetType: string; // "SECTION" | "QUESTION" | "ANSWER"
};

export interface PageOfDocumentSummaryVM extends Pagination {
  content: Array<DocumentSummary>;
}
export type DocumentSubmissionType =
  | "NEW"
  | "AUTO_SYNC"
  | "SAVE_DRAFT"
  | "SUBMIT"
  | "ADD_PARTICIPANT"
  | "DELETE";

// used in DocumentVM
export type DocumentSummary = {
  clientGroup?: DocumentClientGroup;
  deadline?: string;
  dateCreated?: string;
  formSummary: FormSummaryVm;
  id: number;
  isRehuddle: boolean;
  owner: DocumentOwner;
  parentId?: number;
  readOnly?: boolean;
  startDate?: string;
  status?: "NEW" | "IN_PROGRESS" | "SUBMITTED" | "DELETED";
  submissionDate?: string;
  submissionType: DocumentSubmissionType;
  summary?: {
    [key: string]: string;
  };
  title: string;
  // workOrderId?: string;
  workOrder?: {
    workOrderId?: string;
    id?: number;
  };
};

// used in DocumentVM
export type DocumentSectionComment = {
  comments: string;
  formSectionId: number;
};

/**
 * used in Document responses, this is a value
 * created by the Signature Field and populates
 * `Participants Field` when we render a pre-filled Document
 */
export type DocumentParticipant = {
  email?: string;
  firstName?: string;
  fullName?: string;
  lastName?: string;
  name?: string;
  nickname?: string;
  participantId?: number;
  role: "SUPERVISOR" | "ATTENDANT";
  signatureDate?: string;
  signatureTextValue?: string;
  signatureType?: "DRAWN" | "TYPED_NAME" | "TYPED_EMAIL" | "TYPED_ANYTHING";
  signatureUrl?: string;
  timeAdded: string;
  /**
   * @deprecated DB id, did you mean to use participantId?
   */
  id?: number; // Not really deprecated, I just want the warning to show up
};

// used in DocumentVm and DocumentUpdateVm
export type DocumentFormActionRequestsVm = {
  actionId: number;
  requestBody: string;
};

// used in DocumentUpdateVM
export type DocumentQuestionResponseVm = {
  answer: string;
  associatedId?: number | null;
  associatedRootId?: number | null;
  associatedLocation?: GeoPoint | null;
  comments?: string | null;
  questionId: number;
  questionRootId: number;
  timeAnswered: string;
};

export type DocumentDevice = "WEB" | "MOBILE" | "API";

/**
 * API request body user to `POST` to `/api/documents` endpoint.
 * This is used during the "Start New Document" feature when the
 * user is in the process of submitting a new Document.
 * We first send these values then
 * get __NewDocumentFoundation__ as a response
 */
export type CreateDocumentVM = {
  clientGroupId: number;
  formId: number;
  isRehuddle: boolean;
  ownerId: number;
  parentId?: number;
  submissionDate: string;
  workOrderId?: number;
  createdOffline?: boolean;
  documentDevice?: DocumentDevice;
};

/**
 * Response from `POST` to `/api/documents`
 * We'll take this value and use it as
 * the base/foundation for the new "Document"
 * then, after the user has filled out the form,
 * we'll use the `PUSH` endpoint to
 * create the document
 *
 * This is also the response from `PUT` to `/api/documents`
 */
export type DocumentVM = {
  clientGroup?: DocumentClientGroup;
  deadline?: string;
  form: FormDTO;
  formActionRequests: Array<DocumentFormActionRequestsVm>;
  formSummary: FormSummaryVm;
  id: number;
  isRehuddle: boolean;
  operationalExperiences?: Array<OperationalExperience>;
  owner: DocumentOwner;
  parentId?: number | null;
  participants: Array<DocumentParticipant>;
  readOnly?: boolean;
  responses?: Array<DocumentQuestionResponseVm>;
  resubmissions?: Array<DocumentSummary>;
  sectionComments: Array<DocumentSectionComment>;
  startDate?: string;
  status?: "NEW" | "IN_PROGRESS" | "SUBMITTED" | "DELETED";
  submissionDate?: string;
  submissionType?: DocumentSubmissionType;
  summary?: {
    [key: string]: string;
  };
  title: string;
  workOrderId?: string;
  workOrder?: {
    workOrderId?: string;
  };
};

export interface GetDocumentResponse extends APIResponse {
  response?: DocumentVM;
}

// This is for `/documents/:id/history`
export type DocumentHistory = {
  documentId: number | null;
  isRehuddle: boolean;
  historyDate: string;
  historyType: string;
  parentDocumentId?: number;
  participant: {
    email: string;
    firstName?: string;
    fullName?: string;
    id: 0;
    lastName?: string;
    name: string;
    nickname?: string;
  };
};

// For `/documents/:id/related` response
export type RelatedDocumentVm = {
  createdBy?: string;
  createdDate?: string;
  formName?: string;
  id?: number;
  isRehuddle?: boolean;
};

/** A document associated with a defense */
export interface DefenseDocument {
  contentLength: number;
  contentType: string;
  description: string;
  fileSize: string;
  fileType: string;
  id: number;
  lastUploadedDate: string;
  title: string | null;
  url: string;
}

interface QuestionSelection {
  id: number;
  properties?: {
    [key: string]: string | number | boolean | null | undefined;
  };
  title: string;
}

/** Defense with associated documents */
export interface Defense {
  categories: CategoryDTO[];
  defenseDocuments: Array<DefenseDocument>;
  description: string;
  id: number;
  questionSelectionIds: Array<number> | null;
  questionSelections: Array<QuestionSelection>;
  status: ResourceStatus;
  title?: string;
}

export interface ResponseWithAssociatedQuestion {
  defense?: Defense;
  question?: QuestionDTO;
  response: DocumentQuestionResponseVm;
}
