export const GET_RISK_LEVEL_COMMENTS = {
  REQUEST: "GET_RISK_LEVEL_COMMENTS_REQUEST",
  SUCCESS: "GET_RISK_LEVEL_COMMENTS_SUCCESS",
  FAILURE: "GET_RISK_LEVEL_COMMENTS_FAILURE",
};

export interface AnswerSelectionCommentVM {
  comments: string;
  firstName?: string;
  lastName?: string;
  lastModifiedDate?: string;
  participantId: number;
}
