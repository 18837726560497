import styled from "styled-components";

import { remCalc } from "themes/helpers";

export const Wrapper = styled.div`
  align-items: center;
  color: ${({ theme }) => theme.colors.primary};
  cursor: pointer;
  display: flex;
  flex-direction: row;
  font-size: ${remCalc(12)};
  justify-content: center;
  letter-spacing: 0.21px;
  padding-top: 21px;
  text-decoration: underline;
`;
