import { PaginationParams } from "store/common/types";
import {
  UsersFiltersParams,
  USERS_FILTERS,
  SetUsersFiltersAction,
  SetUsersPaginationAction,
} from "./types";

export const setUsersFilters = (
  filters: UsersFiltersParams
): SetUsersFiltersAction => ({
  response: filters,
  type: USERS_FILTERS.SET,
});

export const setUsersPagination = (
  pagination: PaginationParams
): SetUsersPaginationAction => ({
  response: pagination,
  type: USERS_FILTERS.PARAMS_SET,
});
