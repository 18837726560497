import { ClientConfigsState, GET_CLIENT_CONFIG, ClientConfigsActionTypes } from "./types";

const initialState: ClientConfigsState = {
  data: {
    mainNavigation: {
      properties: {
        activeColor: "#0172CE",
        copy: {
          documents: "Documents",
          menu: "Menu",
          profile: "Profile",
          reports: "Reports",
        },
      },
    },
  },
};

export function clientConfigsReducer(state = initialState, action: ClientConfigsActionTypes): ClientConfigsState {
  switch (action.type) {
    case GET_CLIENT_CONFIG.SUCCESS:
      return {
        ...state,
        [action.keyName]: action.response,
      };
    default:
      return state;
  }
}
