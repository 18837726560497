export type feedbackTypeAPI = "BUG" | "FEATURE_REQUEST" | "GENERAL" | undefined;

export type Data = {
  clientGroupId: number;
  feedback: string;
  feedbackType: feedbackTypeAPI;
  userId: number;
};

export const SUBMIT_FEEDBACK = {
  REQUEST: "SUBMIT_FEEDBACK_REQUEST",
  SUCCESS: "SUBMIT_FEEDBACK_SUCCESS",
  FAILURE: "SUBMIT_FEEDBACK_FAILURE",
};

export interface SubmitFeedbackState {
  loading: boolean;
  error: boolean;
  errorMessage: string;
  data?: Data;
}

export interface SubmitFeedbackActions {
  payload?: any;
  response?: any;
  type: string;
}
