import React, { FC } from "react";
import { css } from "aphrodite/no-important";
import { useFormikContext, Field } from "formik";
import { get } from "lodash";

import Checkbox from "components/common/form/Checkbox";

import propertiesStyles from "../styles";
import { FBForm, Property } from "components/clientAdmin/formBuilder/types";
import {
  ratingSelections,
  RatingSelection,
} from "../../content/widgets/RatingsWidget/RatingsWidget";

interface Props {
  property: Property;
  itemPath: string;
}

const CommentsRequiredFor: FC<Props> = ({ property, itemPath }) => {
  const { values, setFieldValue } = useFormikContext<FBForm>();
  const requiredFor = get(values, `${itemPath}.${property.name}`) || [];
  const ps = propertiesStyles();

  function onChange(
    e: React.ChangeEvent<HTMLInputElement>,
    sel: RatingSelection
  ) {
    const val = e.target.checked
      ? [...requiredFor, sel.title]
      : requiredFor.filter((reqF: string) => reqF !== sel.title);
    setFieldValue(`${itemPath}.${property.name}`, val);
  }

  return (
    <div className={css(ps.requiredForContainer)}>
      <p className={css(ps.requiredForLabel)}>{property.label}</p>
      {ratingSelections.map((sel) => (
        <div key={sel.title}>
          <Field
            as={Checkbox}
            className={css([ps.checkbox, ps.noVertPadding])}
            name={`${itemPath}.${property.name}.${sel.title}`}
            label={sel.title}
            checked={requiredFor.includes(sel.title)}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              onChange(e, sel)
            }
          />
        </div>
      ))}
    </div>
  );
};

export default CommentsRequiredFor;
