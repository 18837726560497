import React, { useState } from "react";
import { useHistory } from "react-router";

import Tutorial from "components/dashboard/Tutorial";
import { Text } from "components/clientAdmin/styles";

import { 
  About, 
  ChevronLink, 
  GotQuestionsCTA, 
  GotQuestionsIcon, 
  GotQuestionsWrapper, 
  HelpDesk as Wrapper,
  HelpDeskRow as Row, 
  HelpDeskTitle as Title,
  RowTitle,
  GotQuestionsHeading,
  VersionNumber,
} from "../styles";

const HelpDesk = () => {
  const [showingTutorial, setShowingTutorial] = useState<boolean>(false);

  const { push } = useHistory();

  return <Wrapper>

    {/* Tutorial */}
    <Tutorial open={showingTutorial} endTutorial={() => setShowingTutorial(false)} />

    {/* Title  */}
    <Title>
        Help Desk
    </Title>
    <About>About PowerFields</About>

    {/* Version row */}
    <Row>
      <RowTitle>Version</RowTitle>
      <VersionNumber>{process.env.REACT_APP_VERSION || "0.1"}</VersionNumber>
    </Row>

    {/* Tutorial row */}
    <Row>
      <RowTitle onClick={() => setShowingTutorial(true)}>
        Run Tutorial Walkthrough
      </RowTitle>
      <ChevronLink onClick={() => setShowingTutorial(true)}/>
    </Row>

    {/* Got questions */}
    <GotQuestionsWrapper>
      <GotQuestionsIcon/>
      <GotQuestionsCTA>
        <GotQuestionsHeading>have questions?</GotQuestionsHeading>
        <Text
          fontSize="1rem"
          fontWeight={400}
          letterSpacing="0.28px"
          onClick={() => push("/feedback")}
          textDecoration="underline"
        >
          Send us a message with your feedback
        </Text>
      </GotQuestionsCTA>
    </GotQuestionsWrapper>
    
  </Wrapper>;
};

export default HelpDesk;
