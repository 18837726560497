import { PaginationParams } from "store/common/types";
import {
  FormTemplatesFiltersParams,
  FORM_TEMPLATES_FILTERS,
  SetFormTemplatesFiltersAction,
  SetFormTemplatesPaginationAction,
} from "./types";

export const setFormTemplatesFilters = (
  filters: FormTemplatesFiltersParams
): SetFormTemplatesFiltersAction => ({
  response: filters,
  type: FORM_TEMPLATES_FILTERS.SET,
});

export const setFormTemplatesPagination = (
  pagination: PaginationParams
): SetFormTemplatesPaginationAction => ({
  response: pagination,
  type: FORM_TEMPLATES_FILTERS.PARAMS_SET,
});
