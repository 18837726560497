import React, { RefObject, useState } from "react";
import { isEqual, orderBy } from "lodash";
import { ThemeContext } from "styled-components";
import { Viewport } from "../../map/types";
import {
  MapWidgetDTO,
  MapWidgetQuestionDTO,
  QuestionDTO,
  SectionItem,
} from "../../../store/forms/types";
import { DocumentQuestionResponseVm } from "../../../store/documents/types";
import { LocationMarker } from "../types";
import { remCalc } from "../../../themes/helpers";
import { Map as MapComponent } from "../../map/Map";
import Location from "../../forms/Location";
import {
  calculateZoom,
  formatGeolocation,
  getDistanceBetweenGeoLocations,
  getMidPoint,
} from "../../../util/geo";
import { LocationResponseLngLat } from "../helpers";
import { css, StyleSheet } from "aphrodite/no-important";
import { FormErrorShape } from "../FormController";

const styles = StyleSheet.create({
  wrapper: {
    marginBottom: remCalc(21),
  },
  map: {
    height: remCalc(300),
    width: "100%",
  },
});

/**
 * Sort widget questions by their answer source type (descending), placing "WORK_LOCATION" before "CURRENT_GPS"
 * @param questions
 */
function sortQuestions(questions: Array<QuestionDTO & MapWidgetQuestionDTO>) {
  return orderBy(questions, (question) => question.answerSource?.type, [
    "desc",
  ]);
}

/**
 * Get a calculated viewport that encompasses any pins currently placed on the map
 * @param locations - geo coordinates of all location responses in the widget
 */
function getViewport(locations: Array<LocationResponseLngLat>): Viewport {
  const { lat, lng } = getMidPoint(locations);
  return {
    width: "100%",
    height: "100%",
    center: { latitude: lat || 0, longitude: lng || 0 },
    zoom: calculateZoom(getDistanceBetweenGeoLocations(locations)),
  };
}

interface Props {
  errors: FormErrorShape;
  item: MapWidgetDTO;
  responses: Array<DocumentQuestionResponseVm>;
  sectionItems: Array<SectionItem>;
  setQuestionResponse: (
    item: QuestionDTO,
    response?: DocumentQuestionResponseVm,
    skipPrefills?: boolean
  ) => void;
  itemRefs?: { [key: string]: RefObject<HTMLDivElement> };
}

/**
 * Map widget with optional map and questions
 * @param errors
 * @param item                - the form item to render
 * @param sectionItems        - all questions in the current section
 * @param setQuestionResponse
 * @param responses           - all responses in the form
 * @param itemRefs
 * @constructor
 */
export function MapWidget({
  errors,
  item,
  responses,
  sectionItems,
  setQuestionResponse,
  itemRefs,
}: Props) {
  const [viewport, setViewport] = useState<Viewport>({
    width: "100%",
    height: "100%",
    center: {
      latitude: 37.54129,
      longitude: -77.43476,
    },
    zoom: 5,
  });
  const theme = React.useContext(ThemeContext);

  /** QuestionDTOs for updating responses */
  const questionDTOs: Map<number, QuestionDTO> = new Map();

  /** Responses for each question */
  const mapResponses: Map<number, DocumentQuestionResponseVm> = new Map();

  /* SectionItem matching a map widget's nested question (used for updating form responses) */
  let fullQuestion: SectionItem | undefined;
  /* response matching a map widget's nested question (used for passing text input value) */
  let mapResponse: DocumentQuestionResponseVm | undefined;

  /** QuestionDTOs with added iconColor for pins */
  const mapQuestions: Array<QuestionDTO & MapWidgetQuestionDTO> = item.questions
    ?.length
    ? /* widget has questions - combine them with matching form questions and sort by answerSource type */
      sortQuestions(
        item.questions.reduce(
          (result: Array<QuestionDTO & MapWidgetQuestionDTO>, question) => {
            fullQuestion = sectionItems.find(
              (item) =>
                item.type === "QUESTION" &&
                question.questionRootId === item.rootId
            );
            if (fullQuestion && "title" in fullQuestion) {
              // update questionDTOs with the form question
              questionDTOs.set(fullQuestion.rootId, fullQuestion);
              // if a response for this question exists, add it to mapResponses
              mapResponse = responses.find(
                (res) => fullQuestion?.rootId === res.questionRootId
              );
              if (mapResponse) {
                mapResponses.set(fullQuestion.rootId, mapResponse);
              }

              // add the combined parameters to result array
              result.push({
                ...fullQuestion,
                ...question,
                // favor the widgetQuestion's answer source (set in FB) over fullQuestion
                answerSource:
                  question.answerSource || fullQuestion.answerSource,
                id: fullQuestion.id,
                rootId: fullQuestion.rootId,
              });
            }
            return result;
          },
          []
        )
      )
    : /* widget does not have questions - set to empty array */
      [];

  /** Array of geo coordinates for calculating viewport */
  const locations: Array<LocationResponseLngLat> = [];

  /** Markers representing location responses */
  let markers: Array<LocationMarker> = [];

  if (item.includeMap) {
    let response: DocumentQuestionResponseVm | undefined;

    /* Map marker configuration */
    markers = mapQuestions.reduce((result: Array<LocationMarker>, question) => {
      response = mapResponses.get(question.rootId);

      /* if response found, add it to locations */
      if (response?.associatedLocation) {
        locations.push({
          lat: response.associatedLocation.latitude,
          lng: response.associatedLocation.longitude,
        });
      }

      /* create the marker and push to array */
      const marker: LocationMarker = {
        geolocation: response?.associatedLocation || undefined,
        locationName: response?.answer,
        color: question.iconColor,
        onUpdate() {
          const questionDTO = questionDTOs.get(question.rootId);
          if (questionDTO) {
            // geolocation is defined (marker was placed on map)
            if (this.geolocation) {
              setQuestionResponse(
                questionDTO,
                {
                  questionId: questionDTO.id,
                  questionRootId: questionDTO.rootId,
                  answer:
                    this.locationName || formatGeolocation(this.geolocation),
                  timeAnswered: new Date().toISOString(),
                  associatedLocation: this.geolocation,
                },
                true
              );
              // geolocation is undefined (marker was removed from  map)
            } else {
              setQuestionResponse(questionDTO, undefined, true);
            }
          }
        },
      };
      result.push(marker);

      return result;
    }, []);

    // calculate and set viewport based on marker locations
    if (locations.length > 0) {
      const newViewport = getViewport(locations);
      if (!isEqual(viewport, newViewport)) {
        setViewport(newViewport);
      }
    }
  }

  return (
    <div className={css(styles.wrapper)}>
      {item.includeMap && (
        <>
          <div className={css(styles.map)}>
            <MapComponent initialViewport={viewport} markers={markers} />
          </div>
        </>
      )}
      {mapQuestions.map((widgetQuestion, idx) => {
        const _error = errors[widgetQuestion.questionRootId] || false;

        return (
          <div
            key={`${widgetQuestion.id}`}
            ref={itemRefs && itemRefs[widgetQuestion.id]}
          >
            <Location
              error={_error}
              elementBefore={
                <i
                  className="icon icon-icons8-marker"
                  style={{
                    fontSize: remCalc(40),
                    color:
                      "iconColor" in widgetQuestion
                        ? widgetQuestion.iconColor
                        : theme.colors.primary,
                  }}
                />
              }
              question={widgetQuestion}
              response={mapResponses.get(widgetQuestion.rootId)}
              setQuestionResponse={(response?: DocumentQuestionResponseVm) => {
                const questionDTO = questionDTOs.get(
                  widgetQuestion.questionRootId
                );
                if (questionDTO) {
                  setQuestionResponse(questionDTO, response);
                }
              }}
              setViewport={() => undefined}
              enableWorkLocationAutoComplete={idx === 0}
            />
          </div>
        );
      })}
    </div>
  );
}
