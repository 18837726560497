// import LinkBase from "@material-ui/core/Link";
import styled from "styled-components";

import { remCalc, toRgba } from "themes/helpers";

export const Wrapper = styled.div.attrs({
  className: "mt-3 mb-4",
})`
  border-bottom: 1px solid ${({ theme }) => theme.colors.lightGrey};
  padding-bottom: 13px;
`;

export const Crumb = styled.div<{ currentPath?: boolean }>`
  color: ${({ theme }) => toRgba(theme.masterColors.black, 0.87)};
  font-size: ${remCalc(12)};
  font-weight: 700;

  ${({ currentPath }) => {
    if (!currentPath) {
      return `
        color: ${({ theme }) => theme.masterColors.darkGrey};
      `;
    }
  }}
`;

export const Link = styled.span`
  color: ${({ theme }) => theme.masterColors.darkGrey};
  cursor: pointer;
  display: block;
  font-size: ${remCalc(12)};
  font-weight: 500;
`;
