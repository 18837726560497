import React from "react";

import SettingsMenu from "components/Settings/Menu";
import Logout from "components/Settings/Logout";
import TitleBar from "components/navigation/TitleBar";

const Settings = () => {
  return (
    <>
      <TitleBar title="Settings" />
      <SettingsMenu />
      <Logout spacing />
    </>
  );
};

export default Settings;
