import React from "react";

import RichTextEditor from "components/common/RichTextEditor";
import { FieldInputProps } from "formik";
import { RichTextEditorProps } from "components/common/RichTextEditor/RichTextEditor";

type ResourceDescriptionField = RichTextEditorProps & FieldInputProps<string>

const ResourceDescriptionField = ({ value, onChangeContent, ...rest }: ResourceDescriptionField) => (
  <RichTextEditor
    initialContent={value}
    label="Description"
    onChangeContent={onChangeContent}
    placeholder="Resource description"
    {...rest}
  />
);

export default ResourceDescriptionField;
