import styled from "styled-components";

import { toRgba } from "themes/helpers";

const Portal = styled.div``;

const Backdrop = styled.div`
  z-index: 10000;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: ${({ theme }) => toRgba(theme.colors.black, 0.3)};
`;

export { Portal, Backdrop };
