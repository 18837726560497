import React, { useState, useEffect } from "react";
import { Action } from "redux";
import { css } from "aphrodite/no-important";
import { Formik, Form, FormikHelpers } from "formik";
import { omit } from "lodash";
import { ThunkDispatch } from "redux-thunk";
import { useDispatch } from "react-redux";
import { useRouteMatch } from "react-router";

import { AppState } from "store";
import { FormDTO, FormTypeDTO } from "store/forms/types";
import {
  getBuilderForm,
  getFormTypes,
  PUT_BUILDER_FORM,
} from "store/builder/actions";
import { putBuilderForm } from "store/builder/actions";
import { TabPanel } from "components/common/Tabs";
import Loader from "components/common/Loader";

import { FBSection, FBItem, FBForm } from "./types";
import { unmarshallForm, marshallForm } from "./helpers";
import AutoSaveForm from "./autoSave/AutoSaveForm";
import Create from "./create";
import FormBuilderHeader from "./baseUi/header/FormBuilderHeader";
import FormBuilderSettings from "./settings/FormBuilderSettings";
import FormBuilderTabs from "./baseUi/tabs/FormBuilderTabs";
import styles from "./styles";
import Toast from "components/common/Toast";
import formValidationSchema from "./validationSchema";
import { ClientGroupDTO } from "store/participants/types";
import { getClientGroups } from "store/clientGroups/actions";

const emptyInitialValues: FBForm = {
  actions: [],
  allowedDocumentEditor: "DOCUMENT_CREATOR",
  clientGroups: [],
  defenses: [],
  displayConditions: [],
  documentSummaryEntries: [],
  formSubmissionConstraint: undefined,
  id: 0,
  rootId: 0,
  name: "",
  properties: undefined,
  sections: [],
  sharingEnabled: true,
  signatureRequired: false,
  startFromWorkOrderEnabled: true,
  isRehuddleEligible: false,
  type: {
    active: true,
    iconColor: undefined,
    iconName: undefined,
    id: -1,
    name: "",
    properties: undefined,
  },
  workflowType: "DRAFT",
  operationalExperiences: [],
};

interface RouteParams {
  id: string;
}

const FormBuilder: React.FC<{}> = () => {
  const match = useRouteMatch<RouteParams>();
  const dispatch = useDispatch<ThunkDispatch<AppState, void, Action>>();

  const [selectedTab, setSelectedTab] = useState<number>(0);
  const [form, setForm] = useState<FBForm>(emptyInitialValues);
  const [clientGroups, setClientGroups] = useState<ClientGroupDTO[]>([]);
  const [formTypes, setFormTypes] = useState<FormTypeDTO[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");

  const s = styles("form-builder-header");

  async function fetchForm(): Promise<void> {
    const res = await dispatch(getBuilderForm(match.params.id));
    if (res?.response) {
      const form = unmarshallForm(res.response);
      setForm(form);
    }
  }

  async function fetchClientGroups(): Promise<void> {
    const res = await dispatch(
      getClientGroups({ size: 2000, sort: "name,asc" })
    );
    if (res.response) {
      setClientGroups(res.response.content);
    }
  }

  async function fetchFormTypes(): Promise<void> {
    const res = await dispatch(getFormTypes());
    if (res.response) {
      setFormTypes(res.response);
    }
  }

  /** Fetch the form on load using form ID from URL params */
  useEffect(() => {
    (async () => {
      setLoading(true);
      await fetchForm();
      await fetchClientGroups();
      await fetchFormTypes();
      setLoading(false);
    })();
  }, []);

  /**
   * Save the form
   * @param values form values
   */
  async function handleSubmitForm(
    values: FBForm
  ): Promise<FormDTO | undefined> {
    const form = marshallForm(values);

    const response = await dispatch(putBuilderForm(form));

    if (response.type === PUT_BUILDER_FORM.FAILURE && response.error) {
      setError(response.error);
    } else if (response.type === PUT_BUILDER_FORM.SUCCESS) {
      setError("");
    }

    return response.response;
  }

  /**
   * Submit form as PUBLISHED (workflowType: FINAL)
   * @param values form values
   * @param formikBag
   */
  async function publishForm(
    values: FBForm,
    { setValues }: FormikHelpers<FBForm>
  ): Promise<FBForm | undefined> {
    const response = await handleSubmitForm({
      ...values,
      workflowType: "FINAL",
    });

    if (response) {
      const form = unmarshallForm(response);
      setValues(form);
      return form;
    }
  }

  /**
   * Save the form as a draft, without validation
   * (except for values.name)
   * @param values form values
   */
  async function saveForm(values: FBForm): Promise<FormDTO | undefined> {
    if (!values.name) {
      return;
    }
    return await handleSubmitForm(values);
  }

  return (
    <Loader loading={loading} overlay>
      <Formik
        initialValues={form}
        onSubmit={async (values, setValues) => {
          await publishForm(values, setValues);
        }}
        validationSchema={formValidationSchema}
        validateOnMount
      >
        {({ values }) => (
          <Form translate="yes">
            {/* AutoSave all value changes except for sections, items, id, and workflowType */}
            <AutoSaveForm
              onSave={saveForm}
              listeners={omit(values, [
                "sections",
                "id",
                "workflowType",
                "lastModifiedDate",
              ])}
            />
            <div className={css(s.formBuilder)}>
              {/* Error toast */}
              <Toast
                visible={!!error}
                onClick={() => setError("")}
                variant="error"
                onDelay={() => setError("")}
                onDelayTime={6000}
              >
                {error}
              </Toast>
              {/* Settings form, which wraps header so it can get the title from the current form state */}
              <div id="form-builder-header" className={css(s.headerWrapper)}>
                <FormBuilderHeader
                  handleNavigate={(tabIndex) => setSelectedTab(tabIndex)}
                  onSave={saveForm}
                  error={error}
                />
                <FormBuilderTabs
                  currentTab={selectedTab}
                  onChangeTab={setSelectedTab}
                />
              </div>

              {/* Create */}
              <TabPanel index={0} value={selectedTab}>
                <div className={css(s.tabPanelWrapper)}>
                  <Create onSaveError={setError} />
                </div>
              </TabPanel>

              {/* Settings */}
              <TabPanel index={1} value={selectedTab}>
                <div className={css(s.tabPanelWrapper)}>
                  <FormBuilderSettings
                    formTypes={formTypes}
                    clientGroups={clientGroups}
                  />
                </div>
              </TabPanel>
              {/* Preview */}
              <TabPanel index={2} value={selectedTab}>
                <div />
              </TabPanel>
            </div>
          </Form>
        )}
      </Formik>
    </Loader>
  );
};

export default FormBuilder;
