import React from "react";
import styled from "styled-components";
import AppBarBase from "@material-ui/core/AppBar";
import Text from "components/common/Text";

import { remCalc } from "themes/helpers";

/**
 * @NOTE: I had some issues with the center element
 * not being correctly positioned in the center of the
 * AppBarBase component. This is why I had to add the
 * margin-right to Title (using margin) - Trevor Kirpaul
 */
export const Header = styled(({ ...props }) => (
  <AppBarBase
    {...props}
    classes={{
      root: "root",
    }}
  />
))`
  &&.root {
    background-color: ${({ theme }) => theme.colors.white};
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: ${remCalc(15)} ${remCalc(16)};
    width: 100%;
  }
`;

export const Title = styled(Text)`
  display: inline-block;
  font-size: ${remCalc(20)};
  font-weight: 500;
  margin: 0px 50px 0px 0px;
  text-align: center;
  padding: 0px;
  color: ${({ theme }) => theme.colors.black};
`;

export const BackButton = styled.button`
  width: 56px;
  height: 100%;
  border: none;
  background: none;
  margin: 0;
  padding-top: 5px;
`;

export const Placeholder = styled.div`
  width: 5px;
  user-select: none;
  height: 5px;
`;
