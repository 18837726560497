import React, { useState } from "react";
import { Action } from "redux";
import { AppState } from "store";
import { css } from "aphrodite/no-important";
import { groupBy } from "lodash";
import { ThunkDispatch } from "redux-thunk";
import { useDispatch } from "react-redux";
import { useFormikContext } from "formik";

import {
  FBItem,
  FBRatingsWidget,
  FBSection,
  FBQuestion,
  AreaToReview,
  FBForm,
} from "components/clientAdmin/formBuilder/types";
import { Input } from "components/common/form/types";
import { NewFormQuestion, NewFormSection } from "store/builder/types";
import {
  putFormSection,
  putFormItem,
  deleteFormSection,
} from "store/builder/actions";
import {
  SafetyRatingQuestionsDTO,
  SectionDTO,
  FormDTO,
} from "store/forms/types";
import { TextButton } from "components/common/Button";
import {
  isFormPublished,
  marshallSection,
  unmarshallForm,
} from "components/clientAdmin/formBuilder/helpers";
import { SuggestionType } from "components/common/form/MultiInput/MultiInput";
import MultiInputItemOption from "components/common/form/MultiInput/MultiInputItemOption";
import Label from "components/common/form/Label";

import { RatingSelection } from "../../../content/widgets/RatingsWidget/RatingsWidget";
import AreasToReviewDrawer from "../../drawers/AreasToReviewDrawer";
import propertiesStyles from "../../styles";
import validationStyles from "../../../validation/styles";
import selectionStyles from "../../styles";
import FieldErrorMessage from "../../../validation/FieldErrorMessage";
import ReorderButtons from "../CustomAnswerOptions/ReorderButtons";

// @TODO move this to helpers? will it be used elsewhere?
export function getItemInSection(
  form: FBForm,
  sectionRootId: number,
  itemRootId: number
) {
  const sectionIndex = form.sections.findIndex(
    (section: FBSection) => section.rootId === sectionRootId
  );
  const item = form.sections[sectionIndex]?.items?.find(
    (pItem: FBItem) => pItem.rootId === itemRootId
  );
  return item;
}

interface Props extends Input {
  areas: SafetyRatingQuestionsDTO[];
  name: string;
  item: FBRatingsWidget;
}

const AreasToReview = ({
  name,
  label,
  item: widget,
  helperText,
  areas,
  error,
  touched,
}: Props) => {
  const [showDrawer, setShowDrawer] = useState<boolean>(false);
  const [activeItem, setActiveItem] = useState<AreaToReview | undefined>(
    undefined
  );
  const { values, setValues } = useFormikContext<FBForm>();
  const isPublished = isFormPublished(values.workflowType);

  const ps = propertiesStyles();
  const ss = selectionStyles();
  const vs = validationStyles();

  const dispatch = useDispatch<ThunkDispatch<AppState, void, Action>>();

  function updateFormValues(newForm: FormDTO) {
    const updatedValues = unmarshallForm(newForm);
    setValues(updatedValues);
  }
  // get the parent MULTI_SELECT item
  const parentItem = getItemInSection(
    values,
    widget.parentRootID,
    widget.parentQuestionRootId
  ) as FBQuestion;

  // group the widget's questions by section
  const groupedAreas = groupBy(areas, "sectionRootId");
  // build the added areas to review with necessary values to update/remove them
  const selectedValues = Object.keys(groupedAreas).map((key) => {
    const area = groupedAreas[key];
    const parentSelectionRootId = area[0].parentSelectionRootId; // this will be the same for all items in this area
    const section: FBSection | undefined = values.sections.find(
      (section) => String(section.rootId) === key
    );
    if (section) {
      return ({
        id: section.id, // same as sectionId
        parentSelectionRootId,
        label: section.title,
        subLabel: `Related: ${section.items?.length} subcategories`,
        items: section.items,
      } as unknown) as SuggestionType;
    } else {
      return {};
    }
  });
  const orderedSelectedValues = (parentItem?.selections || []).map((pis) =>
    selectedValues.find(
      (selectedValue) => selectedValue.parentSelectionRootId === pis.rootId
    )
  );

  /**
   * Build the RATING question items for an area
   * @param area area that is being added/updated
   * @param parentWidgetSelectionRootId the id of the parent MULTI_SELECT item's selection
   * that will trigger this RATING question
   */
  function buildRatingsItems(
    area: AreaToReview,
    parentWidgetSelectionRootId: number | null
  ) {
    return area.items.map((item) => {
      if (item.id) {
        // allows to update custom ratings by setting workflowType to DRAFT
        return { ...item, workflowType: "DRAFT" };
      } else {
        const selections: RatingSelection[] = [];
        area.ratingsSelections.forEach(({ title, commentRequired }) =>
          selections.push({
            title,
            id: null,
            properties: { commentRequired },
          })
        );

        return {
          id: null,
          properties: {},
          title: item.title,
          type: "QUESTION" as const,
          subType: "RATING",
          parentWidgetRootId: widget.rootId,
          parentWidgetSelectionRootId,
          selections,
          formProperties: {
            isCloneable: false,
          },
        };
      }
    });
  }

  /**
   * Add or update the parent MULTI_SELECT item's selection values
   * @param area area that is being added/updated
   */
  function updateSelections(area: AreaToReview) {
    let itemSelections = parentItem?.selections || [];
    if (area.id) {
      itemSelections = itemSelections.map((selection) => {
        if (selection.rootId === area.parentSelectionRootId) {
          return { ...selection, title: area.label };
        }
        return selection;
      });
    } else {
      itemSelections = [
        ...itemSelections,
        { title: area.label, id: null, tags: [], defenseIds: [] },
      ];
    }
    return itemSelections;
  }

  /**
   * Update the parent MULTI_SELECT item's selections based on the areas to review
   * @param area area that is being added/updated
   */
  async function updateParentQuestion(
    area: AreaToReview
  ): Promise<FBForm | undefined> {
    if (parentItem) {
      const itemSelections = updateSelections(area);
      const updateMultiSelect = await dispatch(
        putFormItem(values.id, parentItem.parentID, {
          ...parentItem,
          selections: itemSelections,
        } as NewFormQuestion)
      );
      if (updateMultiSelect.response) {
        return unmarshallForm(updateMultiSelect.response);
      }
    }
  }

  async function reorderParentQuestionSelections(
    newIds,
    area: AreaToReview,
    idx: number
  ) {
    if (parentItem) {
      const itemSelections = newIds.map((id) => {
        return parentItem.selections?.find(
          (selection) => selection.rootId === id
        );
      });
      const updateMultiSelect = await dispatch(
        putFormItem(values.id, parentItem.parentID, {
          ...parentItem,
          selections: itemSelections,
        } as NewFormQuestion)
      );
      if (updateMultiSelect.response) {
        const updatedValues = unmarshallForm(updateMultiSelect.response);
        const ratingsSection = updatedValues.sections.find(
          (section: FBSection) => section.id === area.id
        );
        const ratingsSectionIndex = updatedValues.sections.findIndex(
          (section: FBSection) => section.id === area.id
        );
        if (ratingsSection) {
          const draftItem: FBSection = {
            ...ratingsSection,
            workflowType: "DRAFT",
          };
          const res = await dispatch(
            putFormSection(
              updatedValues.id,
              marshallSection(draftItem),
              ratingsSectionIndex + idx
            )
          );
          if (res.response) {
            const updatedValues = unmarshallForm(res.response);
            setValues(updatedValues);
          }
        } else {
          setValues(updatedValues);
        }
      }
    }
  }

  /**
   * Add or update the sections containing the RATING questions (including adding/removing RATING items)
   * @param area area that is being added/updated
   * @param ratingsSection ratings section that is being added/removed
   * @param parentWidgetSelectionRootId the id of the parent MULTI_SELECT item's
   * selection that will trigger this RATING question
   */
  async function updateRatingsSection(
    area: AreaToReview,
    ratingsSection: NewFormSection | SectionDTO,
    parentWidgetSelectionRootId?: number | null
  ) {
    const ratingsItems = buildRatingsItems(
      area,
      parentWidgetSelectionRootId || area.parentSelectionRootId || null
    );

    const updateRatingsSection = await dispatch(
      putFormSection(values.id, {
        ...ratingsSection,
        title: area.label,
        // @ts-ignore
        items: ratingsItems,
      })
    );

    return updateRatingsSection?.response;
  }

  /**
   * Add an area to review:
   *  1. Updates the parent MULTI_SELECT item with the new selection
   *  2. Add a section with RATING items for each area item (subcategory)
   * @param area area that is being added
   */
  async function addArea(area: AreaToReview) {
    // @TODO this is a hack find a better way to prevent updates on Published form
    if (values.workflowType === "FINAL") return;
    // end hack

    // update the area in the parent MULTI_SELECT item
    const updateMultiSelectResponse = await updateParentQuestion(area);
    if (updateMultiSelectResponse) {
      // get the updated parent MULTI_SELECT item from the response
      // we can't use parentItem here, because it hasn't been updated to include the selection ids
      const multiSelect = getItemInSection(
        updateMultiSelectResponse,
        widget.parentRootID,
        widget.parentQuestionRootId
      ) as FBQuestion;
      if (multiSelect && multiSelect.selections) {
        // get the most recently added selection's id so we can add it to all the RATING questions
        const { rootId: parentWidgetSelectionRootId } = multiSelect.selections[
          multiSelect.selections.length - 1
        ];
        // add a new section w/ RATINGS question for each selection
        const ratingsSection = {
          items: [],
          id: null,
          title: area.label,
          properties: {},
          parentWidgetRootId: widget.rootId,
        };
        const addRatingsSectionsResponse = await updateRatingsSection(
          area,
          ratingsSection,
          parentWidgetSelectionRootId
        );
        // update the form state
        if (addRatingsSectionsResponse) {
          updateFormValues(addRatingsSectionsResponse);
        }
      }
    }
  }

  /**
   * Update an area to review:
   *  1. Updates the parent MULTI_SELECT item's associated selection label
   *  2. Updates the associated section's title and RATING items
   * @param area area that is being updated
   */
  async function updateArea(area: AreaToReview) {
    // @TODO this is a hack find a better way to prevent updates on Published form
    if (values.workflowType === "FINAL") return;
    // end hack

    // update the selection label in the parent MULTI_SELECT item
    const updateMultiSelectResponse = await updateParentQuestion(area);
    if (updateMultiSelectResponse) {
      // update the corresponding ratings section's title + items (using the updated form response)
      const ratingsSection = updateMultiSelectResponse.sections.find(
        (section: FBSection) => section.id === area.id
      )!;

      const updateRatingSelections: any = [];
      // @ts-ignore
      ratingsSection.items[0].selections.forEach((item, index) => {
        updateRatingSelections.push({
          ...item,
          title: area.ratingsSelections[index].title,
          properties: {
            commentRequired: area.ratingsSelections[index].commentRequired,
          },
        });
      });

      // @ts-ignore
      ratingsSection.items[0].selections = updateRatingSelections;

      if (ratingsSection) {
        const updateRatingsSectionResponse = await updateRatingsSection(
          area,
          ratingsSection
        );

        // update the form state
        if (updateRatingsSectionResponse) {
          updateFormValues(updateRatingsSectionResponse);
        }
      }
    }
  }

  /**
   * Remove an area to review from the widget
   *  - Removes the associated section containing RATING questions
   * @param areaId ID of area to be removed
   */
  async function onRemoveArea(areaId: number) {
    const updateResponse = await dispatch(deleteFormSection(values.id, areaId));
    // update the form state
    if (updateResponse.response) {
      updateFormValues(updateResponse.response);
    }
  }
  return (
    <>
      <div className={error && touched ? css(vs.fieldErrorWrapper) : ""}>
        <Label className={css(ps.label)} htmlFor={name}>
          {label}
        </Label>
        <p className={css(ps.text)}>{helperText}</p>
        <div>
          {orderedSelectedValues
            .filter((value) => value !== undefined)
            .map((value, index) => (
              <MultiInputItemOption
                icon={value?.icon}
                onClick={() => {
                  setActiveItem(
                    (orderedSelectedValues[index] as unknown) as AreaToReview
                  );
                  setShowDrawer(true);
                }}
                className={css(ss.multiInputItem)}
                key={value?.id}
                subLabel={value?.subLabel}
                label={value?.label || ""}
                onRemove={() => onRemoveArea(Number(value?.id))}
                disabled={isPublished}
                startAdornment={
                  <ReorderButtons
                    hide={{
                      top: index === 0,
                      bottom: index === orderedSelectedValues.length - 1,
                    }}
                    onReorder={async (idx: number) => {
                      const finalOptions = [
                        ...orderedSelectedValues.map(
                          (sv) => sv?.parentSelectionRootId
                        ),
                      ];
                      const option = finalOptions.splice(index, 1)[0];
                      finalOptions.splice(index + idx, 0, option);
                      await reorderParentQuestionSelections(
                        finalOptions,
                        (orderedSelectedValues[
                          index
                        ] as unknown) as AreaToReview,
                        idx
                      );
                    }}
                  />
                }
              />
            ))}
        </div>
        {!isPublished && (
          <TextButton
            className={css(ps.textButton)}
            onClick={() => setShowDrawer(true)}
          >
            + Add Area
          </TextButton>
        )}
      </div>

      <FieldErrorMessage fieldName={name} />

      {showDrawer && (
        <AreasToReviewDrawer
          areaName={name}
          initialValues={activeItem}
          onAddArea={addArea}
          onUpdateArea={updateArea}
          onDeleteArea={onRemoveArea}
          show={showDrawer}
          closeDrawer={() => {
            setActiveItem(undefined);
            setShowDrawer(false);
          }}
          widget={widget}
        />
      )}
    </>
  );
};

export default AreasToReview;
