import React, { FC } from "react";
import * as S from "./styles";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import DefaultCell from "components/common/TableUI/DefaultCell";
import { DocumentDrawerSections } from "components/clientAdmin/documents/DocumentDrawer";
import moment from "moment";
import { MoreVertIcon } from "components/common/Card/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "components/common/styled/Table";
import { Text } from "components/clientAdmin/styles";
import { prettifyDocumentStatus } from "components/helpers";
import { ViewUserTableProps } from "./types";
import useGroupTerm from "util/hooks/whiteLabel/useGroupTerm";

export const ViewUserTable: FC<ViewUserTableProps> = ({
  documents,
  sorting,
  sortBy,
  setAnchorDocument,
  setShowDocumentDrawer,
  popperIsOpen,
  setPopperIsOpen,
  setAnchorEl,
}) => {
  const documentTerm = useGroupTerm("document", "noun", undefined, "Document");

  return (
    <Table>
      <TableHead>
        <TableRow noBottomBorder>
          <TableCell
            active={sorting.primarySort.type === "title"}
            onClick={(dir) => sortBy("title", dir)}
            sortDirection={sorting.title.direction}
            minWidth="125px"
            maxWidth="300px"
          >
            <b>{documentTerm}</b>
          </TableCell>
          <TableCell
            active={sorting.primarySort.type === "clientGroup.name"}
            minWidth="150px"
            onClick={(dir) => sortBy("clientGroup.name", dir)}
            sortDirection={sorting["clientGroup.name"].direction}
            width="175px"
          >
            <b>Group</b>
          </TableCell>
          <TableCell
            active={sorting.primarySort.type === "formTypeIds"}
            minWidth="155px"
            onClick={(dir) => sortBy("formTypeIds", dir)}
            sortDirection={sorting["formTypeIds"].direction}
            width="155px"
          >
            <b>Type</b>
          </TableCell>
          <TableCell
            active={sorting.primarySort.type === "startDate"}
            minWidth="110px"
            onClick={(dir) => sortBy("startDate", dir)}
            sortDirection={sorting.startDate.direction}
            width="110px"
          >
            <b>Created</b>
          </TableCell>
          <TableCell
            active={sorting.primarySort.type === "submissionDate"}
            minWidth="130px"
            onClick={(dir) => sortBy("submissionDate", dir)}
            sortDirection={sorting.submissionDate.direction}
            width="130px"
          >
            <b>Submitted</b>
          </TableCell>
          <TableCell
            active={sorting.primarySort.type === "status"}
            minWidth="100px"
            onClick={(dir) => sortBy("status", dir)}
            sortDirection={sorting.status.direction}
            width="100px" // `In Progress` is on 2 lines unfortunately
          >
            <b>Status</b>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {documents &&
          documents.map((document, i) => (
            <TableRow key={i}>
              <TableCell>
                <Text
                  fontSize={"0.875rem"}
                  fontWeight={400}
                  lineClamp={2}
                  textDecoration="underline"
                  onClick={() => {
                    setAnchorDocument(document);
                    setShowDocumentDrawer(DocumentDrawerSections.Document); // View document
                  }}
                  style={{
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    maxWidth: 278,
                  }}
                  title={document.title}
                >
                  {`${document.title}`}
                </Text>
                <S.IdLabel
                  style={{
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    maxWidth: 278,
                  }}
                  title={document.formSummary.name}
                >
                  {document.formSummary.name}
                </S.IdLabel>
                <S.IdLabel>{`ID: ${document.id || "-"}`}</S.IdLabel>
              </TableCell>
              <TableCell>
                <S.TableData>
                  {(document.clientGroup && document.clientGroup.name) || "-"}
                </S.TableData>
              </TableCell>
              <TableCell>
                <S.TableData>
                  {document.formSummary &&
                    document.formSummary.type &&
                    document.formSummary.type.name}
                </S.TableData>
              </TableCell>
              <TableCell>
                <S.TableData>
                  {`${moment(document.startDate).format("MM/DD/YY")}`}
                </S.TableData>
                <S.Time>
                  {`${moment(document.startDate).format("HH:mma")}`}
                </S.Time>
              </TableCell>
              <TableCell>
                <S.TableData>
                  {`${moment(document.submissionDate).format("MM/DD/YY")}`}
                </S.TableData>
                <S.Time>
                  {`${moment(document.submissionDate).format("HH:mma")}`}
                </S.Time>
              </TableCell>
              <TableCell>
                <div className="d-flex flex-row justify-content-between">
                  <DefaultCell
                    value={prettifyDocumentStatus(document.status)}
                  />
                  {/* Context menu and sidebar */}
                  <ClickAwayListener
                    onClickAway={() => {
                      if (popperIsOpen) {
                        setPopperIsOpen(false);
                      }
                    }}
                  >
                    <div className="d-flex flex-row justify-content-end">
                      <MoreVertIcon
                        onClick={(e) => {
                          setAnchorEl(e.currentTarget);
                          setAnchorDocument(document);
                          setTimeout(() => {
                            setPopperIsOpen(true);
                          }, 100);
                        }}
                      />
                    </div>
                  </ClickAwayListener>
                </div>
              </TableCell>
            </TableRow>
          ))}
      </TableBody>
    </Table>
  );
};
