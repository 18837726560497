import React from "react";

import * as S from "./styles";
import { InputProps } from "@material-ui/core";

export interface AdornmentProps {
  handleClick?: () => void;
  position: string;
  visible?: boolean;
  label?: string;
  userDefined?: boolean;
  icon?: string;
}

const Adornment = ({ icon, position, handleClick, userDefined, label, visible }: AdornmentProps) => {
  // intended to render the Search icon
  if (position === "start") {
    if (!visible) {
      return null;
    }
    return (
      <S.InputAdornment position={position}>
        <S.SearchIcon className={icon} noPaddingLeft />
      </S.InputAdornment>
    );
  }

  // Used if we want to pass in a defined onClick and label
  // whiles also supporting conditional rendering
  if (userDefined) {
    if (!visible) {
      return null;
    }

    return (
      <S.InputAdornment position={position}>
        <S.AdornmentButton type="reset" onClick={handleClick}>
          {label}
        </S.AdornmentButton>
      </S.InputAdornment>
    );
  }

  // generally, this should be position === 'end'
  // for the SearchInput, this should be the "chip" icon
  // which will trigger a dropdown for search options
  return (
    <S.InputAdornment position={position}>
      <S.FilterButton onClick={handleClick}>
        <S.FilterIcon />
      </S.FilterButton>
    </S.InputAdornment>
  );
};

Adornment.defaultProps = {
  position: "start",
  icon: "icon icon-icons8-search",
  visible: true,
};

interface endAdornmentItem extends Partial<AdornmentProps> {
  handleClick: () => void;
  label: any; // What is this element?
  visible: boolean;
}

export interface SearchInputProps {
  autoFocus?: boolean;
  disableUnderline?: boolean;
  error?: string;
  /**
   * `object` which defines the behavior of the end adornment, which
   * can be a button with an onClick behavior.
   */
  endAdornment?: endAdornmentItem[];
  mobile?: boolean;

  className?: string;
  /**
   * `object` which defines the behavior for the "start adornment".
   * Usually, within **SearchInput**, this will be a "Search Icon" which
   * can be conditionally rendered using `visible`.
   *
   * Note: `visible` also affects the padding for the `S.TextField` input class.
   * By default, when `visible` is `true`, padding left and right will be `0px`.
   * When `visible` is false, padding left and right will be `15px`. This is necessary
   * for ensuring the value text has enough padding when the `startAdornment` is *not* rendered.
   */
  startAdornment?: {
    visible: boolean;
    label?: string;
    handleClick?: () => void;
    icon?: string;
  };
  fullWidth?: boolean;
  helperText?: string;
  label?: string;
  name: string;
  placeholder?: string;
  /**
   * `boolean`, when `true` will add a border radius to
   * the input
   */
  rounded?: boolean;
  value: string;
  /**
   * based on MUI functionality, this `string` defaults
   * to **outlined** which is the value we'll be using the
   * majority of the time.
   */
  variant?: string;
  handleBlur(e: React.FocusEvent<any>): void;
  handleBlur<T = string | any>(fieldOrEvent: T): T extends string ? ((e: any) => void) : void;
  handleChange(e: React.ChangeEvent<any>): void;
  handleChange<T = string | React.ChangeEvent<any>>(
    field: T,
  ): T extends React.ChangeEvent<any> ? void : ((e: string | React.ChangeEvent<any>) => void);
  handleFocus?: (e: React.FocusEvent<any>) => void;
}

const DashboardSearch = ({
  autoFocus,
  className,
  disableUnderline,
  endAdornment,
  error,
  fullWidth,
  handleBlur,
  handleChange,
  handleFocus,
  helperText,
  label,
  mobile,
  name,
  placeholder,
  rounded,
  startAdornment,
  value,
  variant,
}: SearchInputProps) => {
  const InputProps: InputProps = {
    startAdornment: <Adornment position="start" {...startAdornment} />,
    endAdornment: endAdornment ? (
      endAdornment.map((e: endAdornmentItem, index: number) => {
        return <Adornment key={index} position="end" userDefined {...e} />;
      })
    ) : (
      <Adornment position="end" />
    ),
  };

  if (variant === "filled" || variant === "standard" || !variant) {
    InputProps.disableUnderline = disableUnderline;
  }

  return (
    <S.SearchInput className={className}>
      {label && <S.InputLabel>{label}</S.InputLabel>}
      <S.DashboardSearch
        autoFocus={autoFocus}
        fullWidth={fullWidth}
        helperText={helperText}
        mobile={mobile}
        name={name}
        onBlur={handleBlur}
        onChange={handleChange}
        onFocus={handleFocus}
        placeholder={placeholder}
        rounded={rounded}
        startAdornmentVisible={startAdornment ? startAdornment.visible : true}
        value={value}
        variant={variant}
        InputProps={InputProps}
      />
      {error && <S.ErrorLabel>{error}</S.ErrorLabel>}
    </S.SearchInput>
  );
};

DashboardSearch.defaultProps = {
  variant: "outlined",
  handleBlur: () => {},
};

export { DashboardSearch };
