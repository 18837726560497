import React from "react";

import * as S from "../styles";

export type variantType = "base" | "detached";

export interface HandleBarProps {
  onClose?: (e: any) => void;
  variant: variantType;
  hideCloseButton: boolean;
  /**
   * boolean which hides the handle element but
   * will still render the close button
   * unless the `hideCloseButton` prop is used
   */
  hideHandle?: boolean;
}

const HandleBar = ({ onClose, variant, hideCloseButton, hideHandle }: HandleBarProps) => {
  return (
    <S.HandleBar variant={variant}>
      {!hideHandle && <S.Handle variant={variant} />}
      {!hideCloseButton && (
        <S.CloseButton onClick={onClose} variant={variant}>
          <S.CloseButtonIcon className="icon-icons8-delete_sign" />
        </S.CloseButton>
      )}
    </S.HandleBar>
  );
};

HandleBar.defaultProps = {
  variant: "base",
  hideCloseButton: false,
};

export default HandleBar;
