import React from "react";
import { useHistory } from "react-router";
import { useMediaQuery } from "react-responsive";

import colors from "themes/colors";
import Icon from "components/common/Icon";
import { devices } from "themes/mediaQueries";

import * as S from "./styles";

interface Props {
  title: string;
  onPressBack?: () => void;
}

const SettingsHeader = ({ title, onPressBack }: Props) => {
  const isDesktop = useMediaQuery({
    minWidth: devices.minDesktop,
  });

  const { goBack } = useHistory();

  if (isDesktop) return null;

  return (
    <S.Header>
      <S.BackButton onClick={onPressBack || goBack}>
        <Icon type="chevron-left" color={colors.masterColors.darkGrey} />
      </S.BackButton>
      <S.Title as="p">{title}</S.Title>
      <S.Placeholder />
    </S.Header>
  );
};

export default SettingsHeader;
