import React from "react";
import useGroupTerm from "util/hooks/whiteLabel/useGroupTerm";
import * as S from "../styles";

interface StartBlankDocumentProps {
  createDocumentLoading: boolean;
  selectedFormTypeId: number | null;
  selectedFormTemplateId: number | null;
  startNewDocument: (prefill?: boolean) => void;
}

const StartBlankDocument: React.FC<StartBlankDocumentProps> = ({
  createDocumentLoading,
  selectedFormTemplateId,
  selectedFormTypeId,
  startNewDocument,
}) => {
  const documentTerm = useGroupTerm("document", "noun", undefined, "Document");
  const documentTermUpper = documentTerm.toUpperCase();
  return selectedFormTemplateId && selectedFormTypeId ? (
    <S.GhostButton
      fullWidth
      isLoading
      onClick={() => startNewDocument(false)}
      disabled={createDocumentLoading}
      selectedWorkOrderId
      variant="outlined"
    >
      {createDocumentLoading && <S.LoadingAdornment />}
      START BLANK {documentTermUpper}
    </S.GhostButton>
  ) : null;
};

export default StartBlankDocument;
