export interface ParticipantsAggregateSummaryState {
  data: ParticipantsAggregateSummary;
  error: boolean;
  errorMessage: string;
  loading: boolean;
}

export interface ParticipantsAggregateSummary {
  numberOfActiveUsers: number;
  numberOfAllUsers: number;
  numberOfGroups: number;
}

export const GET_PARTICIPANTS_AGGREGATE_SUMMARY = {
  FAILURE: "GET_PARTICIPANTS_AGGREGATE_SUMMARYFAILURE",
  REQUEST: "GET_PARTICIPANTS_AGGREGATE_SUMMARY_REQUEST",
  SUCCESS: "GET_PARTICIPANTS_AGGREGATE_SUMMARY_SUCCESS",
};

export interface ParticipantsAggregateSummaryActions {
  type:
    | typeof GET_PARTICIPANTS_AGGREGATE_SUMMARY.REQUEST
    | typeof GET_PARTICIPANTS_AGGREGATE_SUMMARY.SUCCESS
    | typeof GET_PARTICIPANTS_AGGREGATE_SUMMARY.FAILURE;
  payload?: any;
  response?: any;
}
