import styled from "styled-components";

import { remCalc, toRgba } from "themes/helpers";

type ChevronType = {
  right?: boolean;
  fontSize?: string;
};

export const PaginationWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Wrapper = styled.div`
  display: flex;
  padding-top: 21px;
  align-items: center;
`;

export const PageNumber = styled.div`
  color: ${({ onClick, theme }) => (onClick ? theme.colors.primary : toRgba(theme.colors.black, 0.2))};
  display: flex;
  align-items: center;
  letter-spacing: 1.87px;
  font-size: ${remCalc(12)};
  font-weight: 600;
  margin: 7px;
  &:hover {
    cursor: ${(props) => props.onClick && "pointer"};
  }
`;

export const CurrentPage = styled.div`
  color: ${({ theme }) => theme.colors.white};
  margin-right: 7px;
  margin-left: 7px;
  font-size: ${remCalc(12)};
  font-weight: 600;
  display: flex;
  align-items: center;

  border-radius: 5px;
  background: ${({ theme }) => theme.colors.primary};
  padding: 0 7px;
  height: 22px;
`;

export const Chevron = styled.i.attrs({ className: "icon-icons8-chevron-left" })<ChevronType>`
  display: inline-block;
  font-size: ${({ fontSize }) => fontSize || "1.125rem"};
  left: ${({ right }) => right && "-6px"};
  position: relative;
  right: ${({ right }) => !right && "-4px"};
  transform: rotate(${({ right }) => right && "180deg"});
`;
