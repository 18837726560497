import React from "react";

import * as S from "../styles";

interface endAdornment {
  buttonType?: "reset" | "change";
  hideTextDecoration?: boolean;
  /**
   * can be a `string` or a React Component
   */
  label: string | React.ReactNode;
  onClick?: () => void;
  type: "button" | "label";
  visible: boolean;
}

const RenderEndAdornment = ({
  endAdornment: { type, onClick, label, visible, buttonType, hideTextDecoration },
  active,
}: {
  endAdornment: endAdornment;
  active: boolean;
}) => {
  if (!visible) return null;

  if (type === "label") {
    return (
      <S.ListItemSecondaryAction flexEnd={true}>
        <S.ListItemText active={active} secondary={label} />
      </S.ListItemSecondaryAction>
    );
  }

  if (type === "button") {
    return (
      <S.ListItemSecondaryAction>
        <S.ListItemButton
          hideTextDecoration={hideTextDecoration}
          buttonType={buttonType ? buttonType : "change"}
          type="reset"
          onClick={onClick}
        >
          {label}
        </S.ListItemButton>
      </S.ListItemSecondaryAction>
    );
  }
  return null;
};

export type VariantType = "ItemSelectorDrawer" | "Base" | "participant-selected";

export interface ListItemProps {
  active: boolean;
  className?: string;
  ellipses?: boolean;
  endAdornment?: endAdornment;
  icon?: {
    type?: string;
    color?: string;
    framed: boolean;
  };
  label: string;
  onClick?: () => void;
  renderLabelsAsRow?: boolean;
  rounded?: boolean;
  subLabel?: string | null;
  /**
   * type which dictates behavior. Default is "Base", if you
   * need to extend the behavior of ListItem then you can add
   * a new variant which can be leveraged when conditionally
   * setting styles or rendering
   */
  variant: VariantType;
}

const ListItem = ({
  active,
  className,
  endAdornment,
  icon,
  label,
  onClick,
  renderLabelsAsRow,
  rounded,
  subLabel,
  variant,
}: ListItemProps) => {
  return (
    <S.ListItem className={className}>
      <S.MUIListItem variant={variant} active={active} rounded={rounded} onClick={onClick}>
        {icon && icon.type && icon.color && (
          <S.Column listItemColumnVariant="auto-width">
            <S.ListItemIcon>
              <S.Icon
                framed={icon.framed}
                className={icon.type.includes("icon") ? icon.type : `icon icon-icons8-${icon.type}`}
                color={icon.color}
              />
            </S.ListItemIcon>
          </S.Column>
        )}

        <S.Column listItemColumnVariant="participant-list-item">
          <S.DynamicRow renderAsColumn={!renderLabelsAsRow}>
            <S.Row>
              <S.ListItemText active={active} primary={label} variant={variant} />
            </S.Row>

            {subLabel && (
              <S.Row variant={variant}>
                <S.ListItemText active={active} secondary={subLabel} variant={variant} />
              </S.Row>
            )}
          </S.DynamicRow>
        </S.Column>

        {endAdornment && endAdornment.visible && <RenderEndAdornment active={active} endAdornment={endAdornment} />}
      </S.MUIListItem>
    </S.ListItem>
  );
};

ListItem.defaultProps = {
  active: false,
  ellipses: false,
  label: "label",
  renderLabelsAsRow: false,
  rounded: true,
  variant: "Base",
};

export default ListItem;
