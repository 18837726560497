import { Property } from "../../types";
import { fieldProperties } from "./fieldProperties";

export const getItemProperties = (itemType: string): Property[] => {
  switch(itemType) {
    case "BANNER_MESSAGE":
      return fieldProperties.bannerMessage;
    case "BLOCK":
      return fieldProperties.block;
    case "DATE":
    case "DATE_TIME":
      return fieldProperties.date;
    case "DEFENSES":
      return fieldProperties.defenses;
    case "DOCUMENT_CREATOR":
      return fieldProperties.documentCreator;
    case "DROP_DOWN":
      return fieldProperties.dropdown;
    case "LINE_BREAK":
      return fieldProperties.lineBreak;
    case "MAP":
      return fieldProperties.map;
    case "MULTI_SELECT":
      return fieldProperties.multiSelect;
    case "OPERATIONAL_EXPERIENCES":
      return fieldProperties.operationalExperiences;
    case "PARTICIPANT":
      return fieldProperties.participant;
    case "RADIO_BUTTONS":
      return fieldProperties.radioButtons;
    case "SAFETY_RATING": 
      return fieldProperties.safetyRating;
    case "SECTION":
      return fieldProperties.section;
    case "SIGNATURE":
      return fieldProperties.signature;
    case "SUPERVISOR":
      return fieldProperties.supervisor;
    case "TEXT_AREA":
      return fieldProperties.textArea;
    case "TEXT_LINE":
      return fieldProperties.textLine;
    case "TIME":
      return fieldProperties.time;
    case "YES_OR_NO":
      return fieldProperties.yesOrNo;
    
    default:
      return [];
  }
};
