import { CALL_API } from "middleware/api";
import { GET_RISK_LEVEL_COMMENTS } from "./types";
// /api/reports/comments/selection/${selectionId}

export const getSafetyResponseComments = ({ selectionId }) => (dispatch, getState) => {
  const { system } = getState();
  // Build endpoint url
  const endpoint = `reports/comments/selection/${selectionId}`;
  return dispatch({
    [CALL_API]: {
      endpoint: endpoint,
      options: {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${system.authToken}`,
        },
      },
      types: [GET_RISK_LEVEL_COMMENTS.REQUEST, GET_RISK_LEVEL_COMMENTS.SUCCESS, GET_RISK_LEVEL_COMMENTS.FAILURE],
    },
  }).then(({ response }) => response);
};
