import React, { memo } from "react";

import { getItemIcon } from "./iconMap";
import FormItem from "./FormItem";
import styles from "./styles";
// import SearchGlobalFields from "./SearchGlobalFields";
import { GeneratorFn, ItemParams } from "../Create";
import { H5 } from "components/clientAdmin/styles";
import { css } from "aphrodite/no-important";

export const fields: ItemParams[] = [
  { name: "Add Participants", type: "QUESTION", subType: "PARTICIPANT" },
  { name: "Multi-Select", type: "QUESTION", subType: "MULTI_SELECT" },
  { name: "Radio Button", type: "QUESTION", subType: "RADIO_BUTTONS" },
  { name: "Yes/No Answer", type: "QUESTION", subType: "YES_OR_NO" },
  { name: "Dropdown", type: "QUESTION", subType: "DROP_DOWN" },
  { name: "Single Line", type: "QUESTION", subType: "TEXT_LINE" },
  { name: "Paragraph", type: "QUESTION", subType: "TEXT_AREA" },
  { name: "Date", type: "QUESTION", subType: "DATE" },
  { name: "Time", type: "QUESTION", subType: "TIME" },
];

interface Props {
  add: GeneratorFn;
  itemSelected: boolean;
}

const Fields = ({ add, itemSelected }: Props) => {
  const S = styles({});
  return (
    <>
      {/* Hide Global Fields until further requirements approved -- ED */}
      {/* <div className={css(S.ItemsContainer)}>
        <span className={css(S.Label)}>Global Fields</span>
        <SearchGlobalFields addGlobal={add} />
      </div> */}
      <div className={css(S.ItemsContainer)}>
        <H5 className={css(S.Label)}>Fields</H5>
        {fields.map((field) => (
          <FormItem
            disabled={!itemSelected}
            key={field.name}
            name={field.name}
            icon={getItemIcon(field.subType)}
            onClick={() => add(field)}
          />
        ))}
      </div>
    </>
  );
};

export default memo(Fields);
