import { css } from "aphrodite/no-important";
import { ReactComponent as Checkmark } from "assets/svg/checkmark.svg";
import React, { ChangeEventHandler } from "react";
import { joinClassNames } from "themes/helpers";
import { useKeyboardFocus } from "util/hooks/useKeyboardFocus";
import {
  CreateStyleSheet,
  useThemedStyleSheet,
} from "util/hooks/useThemedStyleSheet";
import {
  checkedIconStyle,
  pillStyle,
  RadioButtonStyle,
  ratingStyle,
  standardStyle,
} from "./styles";

export interface RadioButtonProps {
  checked?: boolean;
  checkedIcon?: React.ReactNode;
  className?: string;
  disabled?: boolean;
  error?: string;
  handleChange?: ChangeEventHandler<HTMLInputElement>;
  label?: string;
  name?: string | undefined;
  style?: CreateStyleSheet<RadioButtonStyle>;
  value?: string | number;
}

export const RadioButton = ({
  checked,
  checkedIcon,
  className,
  disabled,
  error,
  handleChange,
  label,
  name,
  style = standardStyle,
  value,
}: RadioButtonProps) => {
  const { isKeyboardFocused, ...eventHandlers } = useKeyboardFocus();
  const styles = useThemedStyleSheet(style);

  return (
    <label
      className={joinClassNames(
        css(
          styles.label,
          checked && styles.labelChecked,
          !disabled && isKeyboardFocused && styles.labelKeyboardFocused
        ),
        className
      )}
    >
      <span className={css(styles.inputContainer)}>
        {checked && checkedIcon}
        <input
          checked={checked}
          className={css(
            styles.input,
            checked && styles.inputChecked,
            !!error && styles.inputError,
            disabled && styles.inputDisabled,
            !disabled && !error && styles.inputEnabled,
            !disabled && isKeyboardFocused && styles.inputKeyboardFocused
          )}
          disabled={disabled}
          name={name}
          onChange={handleChange}
          type="radio"
          value={value}
          {...eventHandlers}
        />
      </span>
      <span
        className={css(
          styles.labelText,
          !!error && styles.labelTextError,
          disabled && styles.labelTextDisabled
        )}
      >
        {label}
      </span>
    </label>
  );
};

export const PillRadioButton = (props: RadioButtonProps) => {
  return <RadioButton style={pillStyle} {...props} />;
};

export const RatingRadioButton = (props: RadioButtonProps) => {
  const style = useThemedStyleSheet(checkedIconStyle);
  return (
    <RadioButton
      checkedIcon={<Checkmark className={css(style.icon)} />}
      style={ratingStyle}
      {...props}
    />
  );
};
