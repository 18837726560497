import { FormikErrors, useFormikContext } from "formik";
import { FBForm, FBSection, FBItem } from "../../types";
import { pick } from "lodash";
import { FBTab } from "../../baseUi/tabs/FormBuilderTabs";
import { getAllFormItems, getPathIndices } from "../../helpers";
import { getItemPath } from "../properties/Properties";
import { DisplayConditionDTO } from "store/documents/types";

export type FBValidationError = {
  error: string;
  errorKey: string;
  itemPath: string;
  errorPath: string;
};

export type FBValidationLevel = "alert" | "error";
export type FBValidationType = FBTab;

function useValidation() {
  const { values, errors, submitCount } = useFormikContext<FBForm>();

  const alertLevel: FBValidationLevel = submitCount > 0 ? "error" : "alert";

  /**
   * Get errors related to section items
   */
  function getItemErrors(sectionErrors, sectionIndex) {
    const items = sectionErrors["items"] as FormikErrors<FBItem | FBSection>[];
    const errorsArray: FBValidationError[] = [];

    items.forEach((itemErrors, itemIndex) => {
      let errorPath;
      if (itemIndex > -1) {
        errorPath = `Section ${sectionIndex + 1}, Item ${itemIndex + 1}`;
      }
      const itemPath = `sections[${sectionIndex}].items[${itemIndex}]`;
      if (itemErrors) {
        Object.keys(itemErrors).forEach((itemErrorKey) => {
          errorsArray.push({
            itemPath,
            error: itemErrors[itemErrorKey],
            errorKey: itemErrorKey,
            errorPath
          });
        });
      }
    });

    
    return errorsArray;
  }

  /**
   * Get errors related to form sections/items
   */
  function getSectionErrors(sectionErrors) {
    if (!sectionErrors) {
      return [];
    }

    return sectionErrors.reduce((
      errorsArray: FBValidationError[],
      errors: FormikErrors<FBSection>,
      sectionIndex: number
    ): FBValidationError[] => {
      if (errors) {
        const errorSectionPath = `Section ${sectionIndex + 1}`;
        const sectionPath = `sections[${sectionIndex}]`;
            
        Object.keys(errors).forEach((sectionErrorKey) => {
          // handle item errors
          if (sectionErrorKey === "items" && errors["items"]) {
            errorsArray.push(...getItemErrors(errors, sectionIndex));
          } else {
            // handle section errors
            errorsArray.push({
              itemPath: sectionPath,
              error: errors[sectionErrorKey],
              errorKey: sectionErrorKey,
              errorPath: errorSectionPath
            });
          }
        });
      }
      return errorsArray;
    }, []);
  }

  /**
   * Get flattened validation error array from the supplied error object
   * @param errorObj Object of validation errors
   */
  function getFlattenedErrors(errorObj): FBValidationError[] {
    const flattenedErrors: FBValidationError[] = [];
    if (errorObj) Object.keys(errorObj).map((errorKey) => {
      if (errorKey === "sections") {
        flattenedErrors.push(...getSectionErrors(errorObj[errorKey]));
      }
      else {
        flattenedErrors.push({
          itemPath: "",
          error: errorObj[errorKey],
          errorKey,
          errorPath: ""
        });
      }
    });
    return flattenedErrors;
  }

  function getSettingsErrors() {
    const settingsErrors = pick(errors, "clientGroups");
    return getFlattenedErrors(settingsErrors);
  }

  function getCreateErrors() {
    const createErrors = pick(errors, "sections");
    return getFlattenedErrors(createErrors);
  }

  /**
   * Get the flattened list of errors by type
   * @param type Form Builder grouping - "create" | "settings"
   */
  function getErrorsByType(type: FBValidationType): FBValidationError[] {
    switch(type) {
      case "create":
        return getCreateErrors();
      case "settings":
        return getSettingsErrors();
      default:
        return [];
    }
  }

  /**
   * Get an array of validation error types
   */
  function getErrorTypes(): FBValidationType[] {
    const errorTypes: FBValidationType[] = [];
    if (getSettingsErrors().length) {
      errorTypes.push("settings");
    }
    if (getCreateErrors().length) {
      errorTypes.push("create");
    }

    return errorTypes;
  }

  function getErrorsByPath(errorPath) {
    const allErrors = getFlattenedErrors(errors);
    return allErrors.filter((err) => err.itemPath === errorPath);
  }
  
  return {
    getValidationErrors: getFlattenedErrors,
    errorTypes: getErrorTypes(),
    getErrorsByType,
    getErrorsByPath,
    alertLevel
  };
}

export default useValidation;
