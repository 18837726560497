import { CALL_API } from "middleware/api";
import {
  GET_PARTICIPANT_TREE_OPTIONS,
  ParticipantTreeOptionsArgs,
} from "./types";

export const getParticipantTreeOptions = () => (
  dispatch,
  getState
): ParticipantTreeOptionsArgs => {
  const { system } = getState();

  return dispatch({
    [CALL_API]: {
      endpoint: "request-filters/participant-tree-options",
      options: {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${system.authToken}`,
        },
      },
      types: [
        GET_PARTICIPANT_TREE_OPTIONS.REQUEST,
        GET_PARTICIPANT_TREE_OPTIONS.SUCCESS,
        GET_PARTICIPANT_TREE_OPTIONS.FAILURE,
      ],
    },
  });
};
