import React, { useMemo } from "react";
import { useHistory } from "react-router";

import Menu, { MenuItemIcon, MenuItems } from "components/common/Menu";

import * as S from "./styles";

/**
 * Uses a hook to determine which component
 * to render based on the view port
 */
const ProfileMenu = () => {
  const { push } = useHistory();

  const menu: MenuItems = useMemo(() => {
    return [
      {
        icon: MenuItemIcon.PENCIL,
        label: "Edit Profile",
        onClick: () => push("/profile/edit"),
      },
      {
        icon: MenuItemIcon.PHONE_SETTINGS,
        label: "Settings",
        onClick: () => push("/profile/settings"),
      },
    ];
  }, [push]);
  return (
    <S.ProfileMenu>
      <Menu menuItems={menu} />
    </S.ProfileMenu>
  );
};

export default ProfileMenu;
