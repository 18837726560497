import React, { useMemo } from "react";

import { DocumentQuestionResponseVm, Defense } from "store/documents/types";
import { QuestionDTO } from "store/forms/types";

import { DefenseDetails } from "./DefenseDetails";
import * as S from "./styles";
import useGroupTerm from "util/hooks/whiteLabel/useGroupTerm";
import { DefensesWidgetDTO } from "@rtslabs/field1st-fe-common";

type DefensesProps = {
  defenses: Defense[];
  flattenedQuestions: Array<QuestionDTO>;
  item: DefensesWidgetDTO;
  responses: DocumentQuestionResponseVm[];
  setDocumentResponses: (
    question: QuestionDTO,
    questionResponses: DocumentQuestionResponseVm[]
  ) => void;
};

const Defenses = ({
  defenses,
  flattenedQuestions,
  item,
  responses,
  setDocumentResponses,
}: DefensesProps) => {
  const visibleDefenses = defenses.filter((defense) =>
    responses.find((res) =>
      defense.questionSelections.find((sel) =>
        res.associatedId
          ? sel.id === res.associatedId
          : sel.id === res.questionId
      )
    )
  );

  const defensesTerm = useGroupTerm("defenses", "noun", "plural", "Defenses");
  const defensesTermLower = defensesTerm.toLowerCase();

  const subTitle = useMemo(() => {
    if (visibleDefenses.length) {
      return (
        `The following ${defensesTerm} are recommended based on the selections made above. ` +
        "Review with your team and make comments to each section as necessary."
      );
    }
    return `Please make your task selections first and then we’ll provide ${defensesTermLower} to talk 
    through with your team.`;
  }, [defensesTerm]);

  return (
    <S.Section>
      <S.SectionTitle>{defensesTerm}</S.SectionTitle>
      <DefenseDetails
        autoAppendComment={!!item.autoAppendComment}
        defenses={visibleDefenses}
        responses={responses}
        questions={flattenedQuestions}
        setQuestionResponses={setDocumentResponses}
        subTitle={subTitle}
      />
    </S.Section>
  );
};

export default Defenses;
