import React from "react";
import { css } from "aphrodite/no-important";

import styles from "../styles";

const DeactivatedBanner: React.FC<{visible: boolean}> = function ({ visible }) {
  if (!visible) return null;
  
  const s = styles();
  return (
    <div className={css(s.deactivatedBanner)}>
      This form is deactivated. To make changes, enable the form in the form list before attempting to edit.
    </div>
  );
};

DeactivatedBanner.displayName = "DeactivatedBanner";

export default DeactivatedBanner;
