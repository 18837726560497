import React from "react";

import DesktopLabel from "../../DesktopLabel";

import * as S from "./styles";

interface Props {
  label: string;
  name: string;
  lockedValue?: string;
}

const LockedDesktopSelectField = ({ label, name, lockedValue }: Props) => {
  return (
    <>
      <DesktopLabel locked label={label} name={name} />
      <S.Value>{lockedValue}</S.Value>
    </>
  );
};

export default LockedDesktopSelectField;
