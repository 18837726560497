import * as Yup from "yup";
import React, { useState } from "react";
import { Action } from "redux";
import { useFormik } from "formik";
import { ThunkDispatch } from "redux-thunk";
import { useDispatch } from "react-redux";
import { withRouter, RouteComponentProps } from "react-router";

import TextInput from "components/common/form/TextInput";
import Toast from "components/common/Toast";
import { AppState } from "store";
import { TagDTO } from "store/tagLibrary/types";
import Button, { TextButton } from "components/common/Button";
import styled from "styled-components";
import { addTag } from "store/tagLibrary/actions";
import { ADD_TAG } from "store/tagLibrary/types";


const validationSchema = () => Yup.object().shape({
  name: Yup.string().required("Name is required"),
});

const Wrapper = styled.div`
  width: 398px;
`;

interface Props extends RouteComponentProps {
  initialValues: TagDTO;
}

const TagForm = ({ history, initialValues, }: Props) => {

  const [loading, setLoading] = useState(false);
  const [errorToastVisible, setErrorToastVisible] = useState(false);

  const dispatch = useDispatch<ThunkDispatch<AppState, void, Action>>();

  // const _updateTag = async (values: TagDTO) => {
  //   const res = await dispatch(updateTag(values));
  //   setLoading(false);
  //   if (res.type === UPDATE_TAG.SUCCESS) {
  //     history.replace("/content/tag-library");
  //   } else {
  //     setErrorToastVisible(true);
  //   }
  // };

  const _addTag = async (values: TagDTO) => {
    const res = await dispatch(addTag(values));
    setLoading(false);
    if (res?.type === ADD_TAG.SUCCESS) {
      history.replace("/content/tag-library");
    } else {
      setErrorToastVisible(true);
    }
  };

  const handleSubmit = (values: TagDTO) => {
    setLoading(true);
    // if (values?.id) {
    //   _updateTag(values);
    // } else {
    //   _addTag(values);
    // }
    _addTag(values);
  };


  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    onSubmit: (values) => {
      handleSubmit(values);
    },
    validationSchema,
  });

  return (
    <Wrapper>
      <form onSubmit={formik.handleSubmit}>
        <TextInput
          error={formik.errors["name"]}
          label="Tag"
          name="name"
          onChange={formik.handleChange}
          placeholder="Tag"
          touched={formik.touched["name"]}
          value={formik.values["name"]}
          variant="outlined"
        />

        <Toast variant="error" onClick={() => setErrorToastVisible(false)} visible={errorToastVisible}>
          Tag with that name already exists
        </Toast>
        <br />
        <Button type="submit" loading={loading}>
          Save
        </Button>
        <TextButton variant="cancel" onClick={() => history.goBack()}>
          Cancel
        </TextButton>
      </form>

    </Wrapper>
  );
};

export default withRouter(TagForm);
