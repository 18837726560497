import { ClientGroup } from "store/clientGroups/types";
import { ContractingCompany, ParticipantTeam, WorkLocation } from "store/participants/types";
import { Person } from "store/common/types";
import { UserDTO } from "store/user/types";
import { UserStatus } from "store/participantsSummary/types";

export type Authority = {
  authority: string;
  groups: number[];
};

export interface UsersParticipant extends Person {
  authorities?: Array<Authority>;
  clientGroups: ClientGroup[];
  contactingCompany: ContractingCompany;
  id: number;
  isCompletedData?: boolean;
  participant: UsersParticipant;
  participantId?: number;
  participantType?: "EMPLOYEE" | "CONTRACTOR" | string;
  source?: "API" | "USER_GENERATED" | "API_INCOMPLETE" | string;
  status?: UserStatus;
  supervisor?: UsersParticipant;
  teams?: Array<ParticipantTeam>;
  userId?: number;
  workLocation?: WorkLocation;
}

export const ADD_USERS_PARTICIPANT = {
  REQUEST: "ADD_USERS_PARTICIPANT_REQUEST",
  SUCCESS: "ADD_USERS_PARTICIPANT_SUCCESS",
  FAILURE: "ADD_USERS_PARTICIPANT_FAILURE",
};

export const GET_USERS_PARTICIPANT = {
  REQUEST: "GET_USERS_PARTICIPANT_REQUEST",
  SUCCESS: "GET_USERS_PARTICIPANT_SUCCESS",
  FAILURE: "GET_USERS_PARTICIPANT_FAILURE",
};

export const UPDATE_USERS_PARTICIPANT = {
  REQUEST: "UPDATE_USERS_PARTICIPANT_REQUEST",
  SUCCESS: "UPDATE_USERS_PARTICIPANT_SUCCESS",
  FAILURE: "UPDATE_USERS_PARTICIPANT_FAILURE",
};

export const RESEND_VALIDATION_EMAIL = {
  REQUEST: "RESEND_VALIDATION_EMAIL_REQUEST",
  SUCCESS: "RESEND_VALIDATION_EMAIL_SUCCESS",
  FAILURE: "RESEND_VALIDATION_EMAIL_FAILURE",
};

export const CHANGE_ACTIVATION_STATUS = {
  REQUEST: "CHANGE_ACTIVATION_STATUS_REQUEST",
  SUCCESS: "CHANGE_ACTIVATION_STATUS_SUCCESS",
  FAILURE: "CHANGE_ACTIVATION_STATUS_FAILURE",
};

export type Data = {
  authorities: Authority[];
  participant: UsersParticipant;
  user: UserDTO;
};

export interface UsersParticipantsState {
  loading: boolean;
  error: boolean;
  errorMessage: string;
  data?: Data;
}

export interface UsersParticipantsActions {
  payload?: any;
  response?: any;
  type: string;
}
