import React, { useState } from "react";

import styles from "./styles";
import { Tabs, Tab, TabPanel, tabA11yProps } from "components/common/Tabs";
import Elements from "./Elements";
import Fields from "./Fields";
import Widgets from "./Widgets";
import { GeneratorFn } from "../Create";
import { css } from "aphrodite/no-important";

interface Props {
  add: GeneratorFn;
  itemSelected: boolean;
  disableAddMap?: boolean;
}

const ItemsPanel = ({ add, itemSelected, disableAddMap }: Props) => {
  const [selectedTab, setSelectedTab] = useState<number>(0);
  const S = styles({});

  return (
    <div>
      <Tabs fullWidth indicatorColor="white" value={selectedTab} onChange={(e, value) => setSelectedTab(value)}>
        <Tab className={css(S.ItemsTab)} disableRipple label="Elements" {...tabA11yProps(0)} />
        <Tab className={css(S.ItemsTab)} disableRipple label="Fields" {...tabA11yProps(1)} />
        <Tab className={css(S.ItemsTab)} disableRipple label="Widgets" {...tabA11yProps(2)} />
      </Tabs>
      {/* Elements */}
      <TabPanel value={selectedTab} index={0}>
        <Elements add={add} itemSelected={itemSelected} />
      </TabPanel>
      {/* Fields */}
      <TabPanel value={selectedTab} index={1}>
        <Fields add={add} itemSelected={itemSelected} />
      </TabPanel>
      {/* Widgets */}
      <TabPanel value={selectedTab} index={2}>
        <Widgets add={add} itemSelected={itemSelected} disableAddMap={disableAddMap} />
      </TabPanel>
    </div>
  );
};

export default ItemsPanel;
