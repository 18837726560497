import React from "react";

import * as S from "./styles";

export interface IconProps {
  type: string;
  color?: string;
  size?: string; // @TODO: figure out why this doesn't work
  /**
   * `boolean` when true will
   * render a small "lightGrey"
   * background behind the icon.
   */
  framed?: boolean;
  listItem?: boolean;
  className?: string;
}

const Icon = ({ className, size, type, color = "", framed = false, listItem }: IconProps) => {
  if (listItem) {
    return (
      <div className={className}>
        <S.ListItemIcon>
          <S.IconElement
            className={type.includes("icon") ? type : `icon icon-icons8-${type}`}
            color={color}
            framed={framed}
            size={size}
          />
        </S.ListItemIcon>
      </div>
    );
  }

  return (
    // <div>
    <S.IconElement
      className={`${type.includes("icon") ? type : `icon icon-icons8-${type}`} ${className}`}
      color={color}
      framed={framed}
      size={size}
    />
    // </div>
  );
};

export default Icon;
