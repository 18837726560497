import React, { Dispatch, SetStateAction } from "react";

import Drawer from "components/common/Drawer";
import FormTemplatesDrawerContent from "./components/FormTemplatesDrawerContent";
import { FormDTO, FormTypeDTO } from "store/forms/types";

interface Props {
  isDesktop?: boolean;
  isOpen: boolean;
  onSelectFormTemplate: (template: FormDTO) => void;
  selectedFormType: FormTypeDTO | null;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  title: string;
}

const FormTemplatesDrawer = ({ 
  isOpen, 
  setIsOpen, 
  isDesktop, 
  onSelectFormTemplate, 
  selectedFormType, 
  title,
}: Props) => {
  return (
    <Drawer
      content={
        <FormTemplatesDrawerContent
          selectedFormType={selectedFormType}
          title={title}
          onClose={() => setIsOpen(false)}
          onSelectFormTemplate={onSelectFormTemplate}
        />
      }
      anchor="right"
      disableBackdrop
      paperProps={{ elevation: 0 }}
      id="FormTemplatesDrawer"
      onClose={() => setIsOpen(false)}
      onOpen={() => undefined}
      open={isOpen}
      overrideClasses={{
        container: "drawerContainerNoBackdrop",
        content: isDesktop ? "myDocumentsDrawerDesktop" : "",
      }}
      showCloseIcon={false}
    />
  );
};

export default FormTemplatesDrawer;
