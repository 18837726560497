import React, { useState, FC } from "react";
import { useHistory } from "react-router";
import moment from "moment";

import Modal from "components/common/Modal";
import TableSummary from "components/common/TableUI/TableSummary";
import { PaginationParams } from "store/common/types";
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "components/common/styled/Table";
import NoResults from "components/common/TableUI/NoResults";
import TableHeader from "../resources/TableHeader";
import { CategoryDTO } from "store/categories/types";
import Link from "components/common/Link";
import ReadOnlyContent from "components/common/permissions/ReadOnlyContent";
import ResourceCategoryActionMenu from "./ResourceCategoryActionMenu";
import {
  CategoryStatusModal,
  CategoryStatusModalProps,
} from "./CategoryStatusModal";
import { useDispatch } from "react-redux";
import {
  CategorySaveVM,
  saveResourceCategory,
} from "store/resourceCategories/actions";
import { SAVE_RESOURCE_CATEGORY } from "store/resourceCategories/types";
import { ThunkDispatch } from "redux-thunk";
import { AppState } from "store";
import { Action } from "redux";

const emptyCategoryStatusModalState = {
  status: null,
  category: null,
  visible: false,
};
interface Props {
  onFetch: () => void;
  onSort: (sort: string) => void;
  params: PaginationParams;
  categories: CategoryDTO[];
  totalElements: number;
}

interface StatusModalState {
  status: CategoryStatusModalProps["status"];
  category: CategoryStatusModalProps["category"];
  visible: boolean;
}

const ResourceCategoryTable: FC<Props> = ({
  onFetch,
  onSort,
  params,
  categories,
  totalElements,
}) => {
  const [categoryStatusModal, setCategoryStatusModal] = useState<
    StatusModalState
  >({
    status: null,
    category: null,
    visible: false,
  });

  const history = useHistory();
  const dispatch = useDispatch<ThunkDispatch<AppState, void, Action>>();

  const renderStatusLabel = (archived: boolean) =>
    archived ? "Archived" : "Active";

  const onCategoryStatusUpdate = async () => {
    if (categoryStatusModal.category) {
      setCategoryStatusModal({
        ...categoryStatusModal,
        status: "request",
      });
      const payload: CategorySaveVM = {
        ...categoryStatusModal.category,
        archived: !categoryStatusModal.category?.archived,
      };
      const res = await dispatch(saveResourceCategory(payload));
      if (res.type === SAVE_RESOURCE_CATEGORY.SUCCESS) {
        setCategoryStatusModal({
          ...categoryStatusModal,
          status: "success",
        });
        onFetch();
      } else {
        setCategoryStatusModal({
          ...categoryStatusModal,
          status: "failure",
        });
      }
    }
  };

  return (
    <>
      <Modal
        border="none"
        open={categoryStatusModal.visible}
        handleClose={() => {
          setCategoryStatusModal({
            ...emptyCategoryStatusModalState,
            visible: false,
          });
        }}
        height="auto"
        width="652px"
        padding="24px 23px"
        content={
          <CategoryStatusModal
            onCancel={() => {
              setCategoryStatusModal({
                ...emptyCategoryStatusModalState,
                visible: false,
              });
            }}
            onSubmit={onCategoryStatusUpdate}
            status={categoryStatusModal.status}
            category={categoryStatusModal.category}
          />
        }
      />
      <TableSummary
        pageSize={params.size}
        currentPage={params.page}
        totalElements={totalElements}
        ofWhat="categories"
      />
      <Table>
        <TableHeader
          onSort={onSort}
          currentSort={params.sort}
          columns={[
            { id: "title", label: "category" },
            { id: "createdDate", label: "Created Date" },
            { id: "archived", label: "status" },
          ]}
        />
        <TableBody>
          {categories.length > 0 ? (
            categories.map((category) => (
              <TableRow key={category.id}>
                <TableCell width="350px>">
                  <ReadOnlyContent component={<>{category.id}</>}>
                    <Link to={`/content/resource-categories/${category.id}`}>
                      {category.title}
                    </Link>
                  </ReadOnlyContent>
                </TableCell>
                <TableCell width="150px>">
                  {moment(category.createdDate).format("MM/DD/YYYY")}
                </TableCell>
                <TableCell width="200px>">
                  {renderStatusLabel(!!category.archived)}
                </TableCell>
                <TableCell width="22px">
                  {/* Leaving cell to keep table consistent. 
                      None of the menu links apply for Client Reviewer -- GK 
                  */}
                  <ReadOnlyContent>
                    <ResourceCategoryActionMenu
                      onCategoryStatusUpdate={(category) => {
                        setCategoryStatusModal({
                          ...categoryStatusModal,
                          category,
                          visible: true,
                        });
                      }}
                      category={category}
                    />
                  </ReadOnlyContent>
                </TableCell>
              </TableRow>
            ))
          ) : (
            <NoResults
              icon="document"
              header="No categories were found"
              body="Create a new category to match your needs"
              button={{
                children: "Create a category",
                onClick: () => history.push("/content/resource-categories/new"),
              }}
            />
          )}
        </TableBody>
      </Table>
    </>
  );
};

export default ResourceCategoryTable;
