import styled from "styled-components";

import Text from "components/common/Text";
import { remCalc } from "themes/helpers";

export const ViewSignaturesWrapper = styled.ul`
  list-style: none;
  text-align: left;
  padding: 24px 0px;
`;
export const SignatureItem = styled.li`
  margin-bottom: 16px;
`;

export const SignatureLabel = styled(Text)`
  font-size: ${remCalc(14)};
  margin: 0px 0px 7px 0px;
  padding: 0px;
`;

export const SignatureTitle = styled(Text)`
  font-size: ${remCalc(16)};
  font-weight: 500;
  margin: 0px 0px 16px 0px;
`;

// ----
// ? RENDER TYPED
// ----

export const SignatureValue = styled(Text)`
  font-size: ${remCalc(16)};
`;


// ----
// ? RENDER TYPED & RENDER DRAWN
// ----

export const SignatureWrap = styled.div<{drawn?: boolean}>`
  border: 1px solid ${({ theme }) => theme.masterColors.darkGrey};
  border-radius: 4px;
  height: 56px;

  ${({ drawn }) => {
    if (!drawn) {
      return `
        padding: 16px;
      `;
    }
  }}
  
`;

// ----
// ? RENDER DRAWN
// ----

export const SignatureImage = styled.img`
  object-fit: contain;
  height: 100%;
  width: auto;
`;
