import React, { CSSProperties } from "react";
import { StyleSheet } from "aphrodite";
import { ThemeContext } from "styled-components";
import { remCalc } from "themes/helpers";

export default function(): { [key: string]: CSSProperties } {
  const theme = React.useContext(ThemeContext);

  return StyleSheet.create({
    multiInput: {
      ":nth-child(1n) .MuiInput-root": {
        display: "none"
      },
      ":nth-child(1n) ul": {
        border: "none",
      }
    },
    multiInputItem: {
      padding: "0.25rem 0 0",
      ":nth-child(1n) .secondary": {
        fontSize: remCalc(12),
        color: theme.masterColors.darkGrey,
        marginTop: "0.15rem"
      },
      ":nth-child(1n) .MuiListItemSecondaryAction-root": {
        right: "0.15rem"
      }
    }
  });
};
