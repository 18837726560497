import styled from "styled-components";
import { remCalc, toRgba } from "themes/helpers";

export const Dot = styled.div<{ color: string }>`
  background: ${({ color, theme }) => `${color} linear-gradient(0deg, ${toRgba(theme.colors.black, 0.25)}, transparent)`};
  width: 1.2em;
  height: 1.2em;
  border-radius: 100%;
`;

export const Statistic = styled.div`
  text-align: right;
  width: 4ch;
`;

export const GroupWrapper = styled.div<{ active?: boolean }>`
  font-size: ${remCalc(14)};
  ${({ active, theme }) => active && `background: ${theme.colors.activeFill}; padding: 0 -16px;`}
`;

const handleTruncate = (truncate = false) => truncate && `
  max-width: 150px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

export const LinkButton = styled.button<{ bold?: boolean; truncate?: boolean }>`
  background: transparent;
  border: 0;
  color: ${({ theme }) => theme.colors.primary};

  ${({ truncate }) => handleTruncate(truncate)}

  ${({ bold }) => (bold ? "font-weight: 500;" : "")}
  font-size: 0.875rem;
  outline: 0;
  text-decoration: underline;
  :focus {
    outline: 0;
  }
`;
