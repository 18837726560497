import { createElement, HTMLAttributes } from "react";
import { css, StyleDeclarationValue } from "aphrodite";
import { joinClassNames } from "themes/helpers";

type CSSInputTypes = StyleDeclarationValue | false | null | void;

/**
 * Create a div component with a given style.
 * 
 * The purpose of this is mainly to give divs readable names rather than having
 * to look for their class name.
 * 
 *    <ButtonContainer>
 * 
 * instead of
 * 
 *    <div className={css(styles.buttonContainer)}>
 */
export const StyledDiv = (
  ...styles: CSSInputTypes[]
): React.FC<HTMLAttributes<HTMLDivElement>> => {
  return ({ children, className, ...otherProps }) =>
    createElement(
      "div",
      {
        className: joinClassNames(css(styles), className),
        ...otherProps,
      },
      children
    );
};
