import React, {
  KeyboardEventHandler,
  forwardRef,
  useImperativeHandle,
  useRef,
  FocusEventHandler,
} from "react";
import { css } from "aphrodite/no-important";
import { useThemedStyleSheet } from "util/hooks/useThemedStyleSheet";
import { styles } from "./cellStyles";
import { Moment } from "moment";

interface CellProps {
  date: Moment;
  dayOfMonth: number;
  handleBlur: FocusEventHandler<HTMLButtonElement>;
  handleFocus: FocusEventHandler<HTMLButtonElement>;
  handleFocusChange: (date: Moment) => void;
  handleSelectionChange: (date: Moment) => void;
  isDisabled: boolean;
  isFocusVisible: boolean;
  isFocused: boolean;
  isHidden: boolean;
  isSelected: boolean;
  isWithinRange: boolean;
}

export interface CellRef {
  focus: () => void;
}

export const Cell = forwardRef<CellRef, CellProps>(
  (
    {
      date,
      dayOfMonth,
      handleBlur,
      handleFocus,
      handleFocusChange,
      handleSelectionChange,
      isDisabled,
      isFocusVisible,
      isFocused,
      isHidden,
      isSelected,
      isWithinRange,
    }: CellProps,
    ref
  ) => {
    const button = useRef<HTMLButtonElement | null>(null);
    const themedStyles = useThemedStyleSheet(styles);

    const handleClick = () => {
      handleSelectionChange(date);
    };

    const handleKeyDown: KeyboardEventHandler<HTMLButtonElement> = (event) => {
      const nextDate = date.clone();

      const handleKey = (date: Moment) => {
        event.preventDefault();
        handleFocusChange(date);
      };

      switch (event.key) {
        case "ArrowDown":
          nextDate.add(7, "day");
          handleKey(nextDate);
          break;

        case "ArrowLeft":
          nextDate.subtract(1, "day");
          handleKey(nextDate);
          break;

        case "ArrowRight":
          nextDate.add(1, "day");
          handleKey(nextDate);
          break;

        case "ArrowUp":
          nextDate.subtract(7, "day");
          handleKey(nextDate);
          break;

        case "End":
          nextDate.add(6 - date.day(), "day");
          handleKey(nextDate);
          break;

        case "Home":
          nextDate.subtract(date.day(), "day");
          handleKey(nextDate);
          break;
      }
    };

    useImperativeHandle(ref, () => ({
      focus: () => {
        button.current?.focus();
      },
    }));

    return (
      <td
        className={css(
          themedStyles.cell,
          isWithinRange && !isDisabled && themedStyles.cellWithinRange
        )}
      >
        <button
          aria-selected={isSelected ? true : undefined}
          className={css(
            themedStyles.button,
            isSelected && themedStyles.buttonSelected,
            isFocused && isFocusVisible && themedStyles.buttonFocused,
            isHidden && themedStyles.buttonHidden
          )}
          disabled={isDisabled}
          onBlur={handleBlur}
          onClick={handleClick}
          onFocus={handleFocus}
          onKeyDown={isFocused ? handleKeyDown : undefined}
          ref={button}
          tabIndex={isFocused ? 0 : -1}
          type="button"
        >
          {dayOfMonth}
        </button>
      </td>
    );
  }
);
