import React, { useRef, useState, useMemo } from "react";
import _ from "lodash";

import { Input } from "../types";
import * as S from "./styles";
import { ErrorText, HelperText } from "../TextInput/styles";
import Label from "../Label";
import MultiInputItemOption from "./MultiInputItemOption";

/**
 * value that populates the **ItemSelectorDrawer** and
 * is the value for the **MultiInput** field
 */
export type SuggestionType = {
  [fieldKey: string]: string | undefined;
  className?: string;
  icon?: string;
};

interface Props extends Input {
  autoCompleteSuggestions?: SuggestionType[];
  canUseCustomValues?: boolean;
  idField: string;
  inlineLabel?: boolean;
  subLabelField?: string;
  labelField: string;
  onChangeInput?: (value: string) => void;
  onUpdateValues?: (
    values: SuggestionType[],
    removedValueId?: number | string
  ) => void;
  selectedValues?: SuggestionType[];
  itemClassName?: string;
  onClickItem?: (value: SuggestionType, index: number) => void;
  itemAction?: (value: SuggestionType, index: number) => void;
  itemStartAdornment?: (item: SuggestionType) => React.ReactNode;
  showSuggestions?: boolean;
}

const MultiInput = ({
  assistiveLink,
  autoCompleteSuggestions,
  canUseCustomValues = true,
  className,
  error,
  helperText,
  idField,
  inlineLabel,
  inputProps,
  itemAction, // @todo replace handleRemoveValue with this
  itemClassName,
  label,
  labelField,
  name,
  onBlur,
  onChangeInput,
  onClickItem,
  onUpdateValues,
  placeholder,
  required,
  selectedValues = [],
  subLabelField,
  touched,
  itemStartAdornment,
  showSuggestions = false,
  disabled,
}: Props) => {
  // refs for __AUTO_COMPLETE__ UI element
  const MENU_ANCHOR = useRef();
  const TEXT_INPUT = useRef();

  const [localError, setLocalError] = useState<string>("");
  const [renderAutoComplete, setRenderAutoComplete] = useState(false);
  const [textFieldValue, setTextFieldValue] = useState("");

  /**
   * Method used when user types into the participant input field.
   * Also handles opening the "auto-complete" menu
   */
  const handleOnChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const {
      currentTarget: { value },
    } = e;

    // if the field is cleared, close the autocomplete, else show it
    if (value === "") {
      setLocalError("");
      setRenderAutoComplete(false);
    } else {
      setRenderAutoComplete(true);
    }
    setTextFieldValue(value);
    onChangeInput && onChangeInput(value);
  };

  const updateValues = (
    values: SuggestionType[],
    removedValueId?: string | number
  ) => {
    // run the external update handler
    onUpdateValues && onUpdateValues(values, removedValueId);
  };

  const handleAddValue = (value: SuggestionType) => {
    // clear the text input field & any errors
    setTextFieldValue("");
    setLocalError("");
    // close the __AutoComplete Menu__
    setRenderAutoComplete(false);
    // update the values
    const newValues = [...selectedValues, value];
    updateValues(newValues);
  };

  const handleRemoveValue = (value: SuggestionType) => {
    const newValues = selectedValues.filter(
      (sVal) => sVal[idField] !== value[idField]
    );
    updateValues(newValues, value.id);
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    // if the user can add custom (just text) values, and they press "return", add the value
    if (canUseCustomValues && e.keyCode === 13) {
      e.preventDefault();
      e.stopPropagation();
      handleAddValue({
        [labelField]: textFieldValue,
        [idField]: textFieldValue,
      });
    }
  };

  const handleBlur = (e) => {
    // Close auto complete menu on blur
    setRenderAutoComplete(false);

    // display an error if the text field has not cleared (value hasn't been added) and the field has been blurred
    if (!canUseCustomValues && textFieldValue !== "") {
      setLocalError("Please select a valid item");
    }
    // use the text field value on blur, if custom values can be used
    if (canUseCustomValues && textFieldValue !== "") {
      handleAddValue({
        [labelField]: textFieldValue,
        [idField]: textFieldValue,
      });
    }

    onBlur && onBlur(e);
  };

  const handleFocus = () => {
    if (textFieldValue.length || showSuggestions) {
      setRenderAutoComplete(true);
    }
  };

  // filter suggestions by typed value
  const filteredSuggestions = useMemo(
    () =>
      autoCompleteSuggestions?.filter((suggestion) => {
        const alreadySelected =
          selectedValues.findIndex(
            (val) => val[idField] === suggestion[idField]
          ) > -1;
        const suggestionValue = suggestion[labelField]?.toLowerCase() || "";
        const matchesInput =
          suggestionValue.indexOf(textFieldValue.toLowerCase()) > -1;
        return !alreadySelected && matchesInput;
      }),
    [textFieldValue, autoCompleteSuggestions]
  );

  const hasError = localError || (error && touched);

  return (
    <S.MultiInput id={name} className={`${className} multiInput`}>
      {label && !inlineLabel && (
        <Label
          htmlFor={name || ""}
          assistiveLink={assistiveLink}
          required={required}
        >
          {label}
        </Label>
      )}
      {helperText && !error && (
        <div style={{ paddingBottom: 10, marginLeft: -15 }}>
          <HelperText>{helperText}</HelperText>
        </div>
      )}
      <S.MultiInputContent
        error={!!hasError}
        ref={MENU_ANCHOR}
        disabled={disabled}
      >
        <S.ListItems>
          {selectedValues.map((value, index) => (
            <MultiInputItemOption
              startAdornment={itemStartAdornment?.(value)}
              icon={value.icon}
              onClick={() => onClickItem && onClickItem(value, index)}
              className={`${itemClassName} ${value.className}`}
              key={index}
              subLabel={subLabelField ? value[subLabelField] : undefined}
              label={value[labelField] || ""}
              disabled={disabled}
              onRemove={() =>
                itemAction ? itemAction(value, index) : handleRemoveValue(value)
              }
            />
          ))}
          <S.Input
            name={name}
            inputProps={inputProps}
            error={!!hasError}
            ref={TEXT_INPUT}
            placeholder={placeholder}
            fullWidth
            disableUnderline
            value={textFieldValue}
            onChange={handleOnChange}
            onKeyDown={handleKeyPress}
            onBlur={handleBlur}
            onFocus={handleFocus}
            autoComplete="off"
            disabled={disabled}
          />
        </S.ListItems>
      </S.MultiInputContent>
      {hasError && <ErrorText>{localError || error}</ErrorText>}

      {/* AUTO COMPLETE MENU */}
      {filteredSuggestions && filteredSuggestions.length > 0 && (
        <S.Popper
          disablePortal
          open={renderAutoComplete}
          anchorEl={MENU_ANCHOR.current}
          placement="bottom"
          modifiers={{
            flip: {
              enabled: false, // Prevent Popper.js from flipping anchor to top of anchorEl
            },
            offset: {
              enabled: true,
              offset: "0px 5px", // Shift popper down 5px
            },
          }}
        >
          <S.Options
            parentWidth={_.get(TEXT_INPUT, ["current", "offsetWidth"])}
          >
            {filteredSuggestions.map((suggestion) => {
              return (
                <S.Option
                  onClick={() => handleAddValue(suggestion)}
                  onMouseDown={(e) => e.preventDefault()} // don't trigger handleBlur on the input field
                  key={suggestion[idField]}
                >
                  {suggestion[labelField]}
                </S.Option>
              );
            })}
          </S.Options>
        </S.Popper>
      )}
    </S.MultiInput>
  );
};

export default MultiInput;
