import React from "react";
import { withStyles } from "@material-ui/core";

import { MUIProps } from "themes/materialUI";
import { styles } from "themes/materialUI";

import * as S from "./styles";
import useGroupTerm from "util/hooks/whiteLabel/useGroupTerm";
import { formatSubmitDeadline } from "components/document/helpers";

// This is all worded like it's generic but it's not, it's a document. We should
// get rid of `subtitle` and name them for what they are -- GK

interface OtherProps {
  isRehuddle?: boolean;
  parentId?: number | null;
  submissionType: string;
  subtitle1?: string;
  subtitle2?: string;
  subtitle3?: string;
  title: string;
  ownerName?: string;
  formType?: string;
  deadline?: string;
}

type Props = OtherProps & MUIProps;

const DocumentHeader = withStyles(styles)((props: Props) => {
  const {
    formType,
    isRehuddle,
    ownerName,
    parentId,
    submissionType,
    subtitle1,
    subtitle2,
    subtitle3,
    title,
    deadline,
  } = props;

  // Group config terms
  const rehuddleTerm = useGroupTerm("rehuddle", "noun", undefined, "Rehuddle");
  const rehuddleTermUpper = rehuddleTerm.toUpperCase();

  const deadlineString = formatSubmitDeadline(deadline);
  return (
    <S.DocumentHeader>
      <S.TitleRow>
        <S.Title as="h1">{title}</S.Title>
        <S.SubmissionType
          isSubmitted={submissionType === "Submitted"}
          as="span"
        >
          {submissionType}
          {submissionType !== "Submitted" ? deadlineString : null}
        </S.SubmissionType>
      </S.TitleRow>
      <S.Subtitle1 as="p">{`${
        parentId && isRehuddle ? `[${rehuddleTermUpper}]` : ""
      } ${subtitle1}`}</S.Subtitle1>
      {formType && <S.Subtitle2 as="p">{formType}</S.Subtitle2>}
      <S.Subtitle2 as="p">{subtitle2}</S.Subtitle2>
      <S.Subtitle2 as="p">{subtitle3}</S.Subtitle2>
      {ownerName && <S.Subtitle2 as="p">{ownerName}</S.Subtitle2>}
    </S.DocumentHeader>
  );
});

export { DocumentHeader };
