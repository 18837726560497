import React from "react";
import { FastField, Field, useFormikContext } from "formik";
import { get, orderBy } from "lodash";
import { Action } from "redux";
import { useDispatch } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { css } from "aphrodite/no-important";

import {
  deleteFormItem,
  getMapWidgetQuestions,
  putFormItem,
} from "store/builder/actions";
import Checkbox from "components/common/form/Checkbox";
import ColorPicker from "components/common/form/ColorPicker";
import useDebounce from "util/hooks/useDebounce";

import {
  FBForm,
  FBQuestion,
  Property,
  isFBMapWidget,
  FBItem,
} from "../../../types";
import {
  getFormItemByRootId,
  getPathIndices,
  unmarshallForm,
} from "../../../helpers";
import { getItemPath } from "../Properties";
import AutoSaveItem from "components/clientAdmin/formBuilder/autoSave/AutoSaveItem";
import styles from "../styles";
import PrefillFromWorkOrder from "./PrefillFromWorkOrder/PrefillFromWorkOrder";
import { commonProps } from "../fieldProperties";

/**
 * Check that a question corresponding to a map widget question exists in the form
 * @param questionRootId  - root id of question to search for
 * @param values          - current values of the form
 * @param sectionIndex    - section within the form to search
 */
function isIncluded(
  questionRootId: number,
  values: FBForm,
  sectionIndex?: number
): boolean {
  if (typeof sectionIndex === "undefined") {
    return false;
  }
  return (
    values.sections[sectionIndex]?.items.findIndex(
      (i) => i.rootId === questionRootId
    ) > -1
  );
}

/**
 * Sort global questions by their answer source type (descending), placing "DATA_SOURCE" before "CURRENT_GPS"
 * @param questions
 */
function sortQuestions(questions: Array<FBQuestion>): Array<FBQuestion> {
  return orderBy(questions, (question) => question.answerSource?.type, [
    "desc",
  ]);
}

interface Props {
  itemPath: string;
  property: Property;
  disabled?: boolean;
}

export default function LocationProperties({
  itemPath,
  property,
  disabled,
}: Props) {
  const { values, setValues, setFieldValue } = useFormikContext<FBForm>();
  const dispatch = useDispatch<ThunkDispatch<null, void, Action>>();
  const [globalMapQuestions, setGlobalMapQuestions] = React.useState<
    Array<FBQuestion>
  >([]);

  const [sectionIndex, wqIndex] = React.useMemo(
    () => getPathIndices(itemPath),
    [itemPath]
  );
  const s = styles();

  const propertyPath = `${itemPath}.${property.name}`;

  /* fetch global map questions on mount */
  React.useEffect(() => {
    (async () => {
      const res = await dispatch(getMapWidgetQuestions());
      if (res?.response) {
        setGlobalMapQuestions(
          sortQuestions(Object.values(res.response.properties))
        );
      }
    })();
  }, [dispatch, getMapWidgetQuestions, setGlobalMapQuestions]);

  const debouncedSetColor = useDebounce({
    method(index: number, color: string) {
      if (color.match(/^#[A-Fa-f0-9]{6}$/)) {
        setFieldValue(`${propertyPath}[${index}].iconColor`, color);
      }
    },
  });

  /**
   * Add a map question to the form and map widget
   * @param question - a global question being added
   */
  async function addMapQuestion(question: FBQuestion): Promise<void> {
    // @TODO this is a hack find a better way to prevent updates on Published form
    if (values.workflowType === "FINAL") return;
    // end hack

    if (typeof sectionIndex !== "undefined" && typeof wqIndex !== "undefined") {
      const widget = values.sections[sectionIndex].items[wqIndex];
      if (widget && isFBMapWidget(widget) && question.answerSource) {
        // this is some weird and possibly unnecessary logic to add the map widget questions in order
        // (work location first, 911 second)
        const newItemIndex =
          question.answerSource.type === "CURRENT_GPS" &&
          widget.questions.length
            ? 2
            : 1;

        // 1. add the new question under the widget
        // including the parentWidgetId automatically updates the map widget questions
        const newQuestion = {
          ...question,
          parentWidgetId: widget.id,
          // parentWidgetRootId: widget.rootId
        };
        const qRes = await dispatch(
          putFormItem(
            values.id,
            widget.parentID,
            newQuestion,
            wqIndex + newItemIndex
          )
        );

        // 2. update the form values
        if (qRes.response) {
          const updatedForm = unmarshallForm(qRes.response);
          setValues(updatedForm);
        }
      }
    }
  }

  /**
   * Remove a map question from the form and map widget (if existing)
   * @param question - the form question to be removed
   */
  async function deleteMapQuestion(question: FBItem) {
    const res = await dispatch(
      deleteFormItem(values.id, question.parentID, question.position)
    );
    if (res.response) {
      setValues(unmarshallForm(res.response));
    }
  }

  const widget = get(values, itemPath);

  return (
    <>
      {globalMapQuestions.map((question) => {
        const checked = isIncluded(question.rootId, values, sectionIndex);
        const formQuestion = getFormItemByRootId(values, question.rootId);
        const wqIndex = widget.questions?.findIndex(
          (wq) => wq.questionRootId === question.rootId
        );
        const questionPath =
          formQuestion && getItemPath(values.sections, formQuestion);

        return (
          <>
            <Field
              key={`mapQuestion[${wqIndex}]`}
              as={Checkbox}
              className={css(s.checkbox)}
              name={`mapQuestion[${wqIndex}]`}
              label={`Include ${question.title} field`}
              checked={checked}
              onChange={(e) => {
                if (e.target.checked) {
                  addMapQuestion(question);
                } else if (formQuestion) {
                  deleteMapQuestion(formQuestion);
                }
              }}
              disabled={disabled}
            />
            {checked && (
              <div style={{ marginLeft: 28 }}>
                {questionPath && (
                  <AutoSaveItem
                    values={get(values, questionPath)}
                    itemPath={questionPath}
                  />
                )}
                <FastField
                  as={Checkbox}
                  containerClassName={css(s.checkboxContainer)}
                  className={css(s.checkbox)}
                  name={`${questionPath}.formProperties.isRequired`}
                  label="Required"
                  checked={get(
                    values,
                    `${questionPath}.formProperties.isRequired`
                  )}
                  disabled={disabled}
                />
                <FastField
                  as={Checkbox}
                  containerClassName={css(s.checkboxContainer)}
                  className={css(s.checkbox)}
                  name={`${questionPath}.formProperties.isCloneable`}
                  label="Clone answer to new Related Documents"
                  checked={get(
                    values,
                    `${questionPath}.formProperties.isCloneable`
                  )}
                  disabled={disabled}
                />
                <FastField
                  as={ColorPicker}
                  id={question.id?.toString() || `pin_color_${wqIndex}`}
                  withText
                  label="Icon Color"
                  onColorChange={(value: string) =>
                    debouncedSetColor(wqIndex, value)
                  }
                  colorValue={get(
                    values,
                    `${propertyPath}[${wqIndex}].iconColor`,
                    ""
                  )}
                  disabled={disabled}
                />
                {question.answerSource?.type === "DATA_SOURCE" ? (
                  <span className={css(s.workLocationNotice)}>
                    {question.answerSource.dataSourceKey
                      ? `Work location responses are configured in the 
                      ${question.answerSource.dataSourceKey ||
                        "work location"} data set`
                      : "The work location data set for this field has not been set. Please contact the system administrator."}
                  </span>
                ) : (
                  <FastField
                    as={Checkbox}
                    name={`${questionPath}.answerSource.properties.detailedSearch.enabled`}
                    checked={get(
                      values,
                      `${questionPath}.answerSource.properties.detailedSearch.enabled`,
                      false
                    )}
                    label="Enable Geo Location finder"
                    disabled={disabled}
                  />
                )}
                <FastField
                  as={PrefillFromWorkOrder}
                  item={question}
                  property={commonProps.prefillFromWorkOrder}
                  itemPath={questionPath}
                  disabled={disabled}
                />
              </div>
            )}
          </>
        );
      })}
    </>
  );
}
