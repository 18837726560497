import { CALL_API } from "../../middleware/api";
import { GET_PARTICIPANTS_AGGREGATE_SUMMARY } from "./types";
import { UserStatus } from "store/participantsSummary/types";

export type GetParticipantsAggregateSummaryParams = {
  clientGroupId?: number;
  maxLastLogin?: string | number;
  minLastLogin?: string | number;
  page?: number;
  participantTreeFilter?: string;
  participantType?: string | number;
  searchText?: string;
  size?: number;
  sort?: string;
  status?: UserStatus;
};

// Get participants summary
export const getParticipantsAggregateSummary = (
  args: GetParticipantsAggregateSummaryParams
) => (dispatch, getState) => {
  const authToken = getState().system.authToken;
  let endpoint = "participants/aggregate-summary?";

  // Add query params
  const params = Object.keys(args);
  params.forEach((param: string) => {
    if (args[param] !== undefined && args[param] !== -1 && args[param] !== "") {
      // If param has a value, add it to the url
      endpoint = `${endpoint}&${param}=${encodeURIComponent(args[param])}`;
    }
  });

  return dispatch({
    [CALL_API]: {
      endpoint,
      options: {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${authToken}`,
        },
      },
      types: [
        GET_PARTICIPANTS_AGGREGATE_SUMMARY.REQUEST,
        GET_PARTICIPANTS_AGGREGATE_SUMMARY.SUCCESS,
        GET_PARTICIPANTS_AGGREGATE_SUMMARY.FAILURE,
      ],
    },
  });
};
