import { StyleDeclaration } from "aphrodite";
import { ThemeTypes } from "./colors";
import { remCalc } from "./helpers";

export type ThemedMixin = (theme: ThemeTypes) => StyleDeclaration;

export const activeBorderOverlapAppear: ThemedMixin = (theme) => {
  return {
    boxShadow: `0 0 0 1px ${theme.colors.primary}`,
    borderColor: theme.colors.primary,
  };
};

export const bodyMedium: StyleDeclaration = {
  fontFamily: "Roboto",
  fontSize: remCalc(16),
  fontWeight: "500",
  lineHeight: 1.5,
};

export const bodyRegular: StyleDeclaration = {
  fontFamily: "Roboto",
  fontSize: "1rem",
  fontWeight: "400",
  lineHeight: 1.5,
};

export const bodyItalic: StyleDeclaration = {
  fontFamily: "Roboto",
  fontSize: "1rem",
  fontStyle: "italic",
  fontWeight: "400",
  lineHeight: 1.5,
};

export const buttonReset: StyleDeclaration = {
  backgroundColor: "transparent",
  border: "none",
  margin: 0,
  padding: 0,
};

export const focusIndicatorDisappear = (
  theme: ThemeTypes,
  extraTransitionProps?: string[],
  borderWidth = 0
): StyleDeclaration => {
  const { duration, timingFunction } = theme.animations.focusIndicatorAppear;
  const transitionProps = extraTransitionProps
    ? ["box-shadow"].concat(extraTransitionProps)
    : ["box-shadow"];
  return {
    boxShadow: `0 0 0 ${remCalc(6 - borderWidth)} transparent`,
    transitionDuration: duration,
    transitionProperty: transitionProps.join(", "),
    transitionTimingFunction: timingFunction,
  };
};

export const focusIndicatorAppear = (
  theme: ThemeTypes,
  borderWidth = 0
): StyleDeclaration => {
  return {
    borderColor: borderWidth ? theme.colors.focusIndicatorOutline : undefined,
    boxShadow: `0 0 0 ${remCalc(4 - borderWidth)} ${
      theme.colors.focusIndicatorOutline
    }`,
  };
};

export const focusIndicatorOverlapAppear: ThemedMixin = (theme) => {
  return {
    borderColor: theme.colors.focusIndicatorOutline,
    boxShadow: `0 0 0 ${remCalc(3)} ${theme.colors.focusIndicatorOutline}`,
  };
};

export const inputReset: StyleDeclaration = {
  appearance: "none",
  border: "none",
  display: "inline-block",
  margin: 0,
};

export const inlineRow: StyleDeclaration = {
  alignItems: "center",
  display: "inline-flex",
  flexDirection: "row",
};

export const smallRegular: StyleDeclaration = {
  fontFamily: "Roboto",
  fontSize: remCalc(14),
  fontWeight: "400",
  letterSpacing: remCalc(0.43),
  lineHeight: remCalc(16),
};

export const uppercaseMedium: StyleDeclaration = {
  fontFamily: "Roboto",
  fontSize: remCalc(16),
  fontWeight: "500",
  letterSpacing: remCalc(1.44),
  lineHeight: 1.5,
  textTransform: "uppercase",
};

/** Duplicate of the global sr-only CSS class. */
export const screenReaderOnly: StyleDeclaration = {
  border: 0,
  clip: "rect(0 0 0 0)",
  height: "1px",
  margin: "-1px",
  overflow: "hidden",
  padding: 0,
  position: "absolute",
  width: "1px",
};
