import React from "react";

import * as S from "./styles";

export type ButtonVariants = "primary" | "secondary" | "unstyled" | "unstyled-danger";
export interface ButtonProps {
  children?: any;
  className?: string;
  disabled?: boolean;
  fullWidth?: boolean;
  height?: number;
  loading?: boolean;
  onClick?: () => void;
  status?: "success" | "failure" | "request" | null;
  type?: "submit" | "reset" | "button";
  variant?: ButtonVariants;
  renderLoadingAfterLabel?: boolean;
  zIndex?: number;
  // @FIXME temporary, add variant in future to handle this
  documentButton?: boolean;
  /**
   * temporary until we figure out a better way
   * MUI makes it difficult to correctly pass
   * extended styles down
   */
  width?: number;
}

/**
 * Button used with:
 * 
 * - View/Edit Document feature
 * - OE Reshuffle
 * - Login / OAuth
 * - Offline Warning Panel
 * - Submit Button
 * - Item Selector Drawer
 * - Generic Form Component
 */
const Button = ({
  children,
  type,
  className,
  onClick,
  loading,
  status,
  fullWidth,
  height,
  variant,
  renderLoadingAfterLabel,
  disabled,
  width,
  documentButton,
}: ButtonProps): any => (
  <S.Button
    className={className}
    disabled={disabled}
    documentButton={documentButton}
    fullWidth={fullWidth}
    height={height}
    loading={loading}
    onClick={onClick}
    status={status}
    type={type}
    variant={variant}
    width={width}
  >
    {loading && !renderLoadingAfterLabel && <S.LoadingAdornment />}
    {children && children}
    {loading && renderLoadingAfterLabel && <S.LoadingAdornment after />}
  </S.Button>
);

Button.defaultProps = {
  onClick: () => {},
};

export default Button;
