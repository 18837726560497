import { TagDTO } from "store/tagLibrary/types";

// Takes tag.archived from TagDTO and renders a context menu label
export const prettifyTagStatusMenuItem = (status?: TagDTO["archived"]) => {
  if (!status) return "Archive";
  return "Activate";
};

// Helps with copy verbage for activate/archive terms
export const actionPerformed = (archived: TagDTO["archived"], tense: "past" | "present") => {
  const activate =  {
    present: "activate",
    past: "activated",
  };
  const archive = {
    present: "archive",
    past: "archived",
  };
  switch(archived) {
    case true:
      return activate[tense];
    case false:
      return archive[tense];
    default:
      return archive[tense];
  }
};
