import { CALL_API } from "middleware/api";

import { buildFilteredEndpoint } from "../common/apiUtilities";
import { FilterParams } from "../common/types";
import { AppState } from "../index";

import { createDataSourceEndpoint } from "./helpers";
import {
  DataSource,
  DataSourceResponse,
  DataSourceValueDTO,
  GET_DATA_SOURCE_VALUES,
  GetDataSourceTemplateResponse,
  PaginatedDataSourceResponse,
  PostDataSourceCSVResponse, DataSourceVM, SearchDataSourceValuesAction, GetDataSourceValuesAction,
} from "./types";

/**
 * Get a single data source by its ID
 * @param id
 */
export const getDataSourceById = (id: string) => (dispatch, getState) => {
  const { authToken } = getState().system;
  return dispatch({
    [CALL_API]: {
      endpoint: `data-sources/${id}`,
      options: {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${authToken}`,
        },
      },
      types: [
        "GET_DATA_SOURCE_REQUEST",
        "GET_DATA_SOURCE_SUCCESS",
        "GET_DATA_SOURCE_FAILURE",
      ],
    },
  })
    .then((res: DataSourceResponse) => res)
    .catch((err) => {
      throw new Error(err);
    });
};

/** Get a page of data sources, filtered by params */
export const getDataSourcesPage = (params: FilterParams) => (dispatch, getState) => {
  const { authToken } = getState().system;
  const endpoint = buildFilteredEndpoint("data-sources", params);

  return dispatch({
    [CALL_API]: {
      endpoint,
      options: {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${authToken}`,
        },
      },
      types: [
        "GET_PAGE_OF_DATA_SOURCES_REQUEST",
        "GET_PAGE_OF_DATA_SOURCES_SUCCESS",
        "GET_PAGE_OF_DATA_SOURCES_FAILURE",
      ]
    },
  })
    .then((res: PaginatedDataSourceResponse) => res)
    .catch((err) => {
      throw new Error(err);
    });
};

/**
 * Fetch a CSV template (representing a Data Source's headers) for a given ID
 * @param id
 */
export const getDataSourceValuesTemplate = (id: number) => (dispatch, getState) => {
  const { authToken } = getState().system;
  return dispatch({
    [CALL_API]: {
      endpoint: `data-sources/${id}/upload-template`,
      options: {
        method: "GET",
        headers: {
          Accept: "text/csv",
          Authorization: `Bearer ${authToken}`,
        },
      },
      types: [
        "GET_DATA_SOURCE_VALUES_TEMPLATE_REQUEST",
        "GET_DATA_SOURCE_VALUES_TEMPLATE_SUCCESS",
        "GET_DATA_SOURCE_VALUES_TEMPLATE_FAILURE",
      ],
    },
  })
    .then((res: GetDataSourceTemplateResponse) => res)
    .catch((err) => {
      throw new Error(err);
    });
};

/**
 * Fetch a CSV representing a data source's records
 * @param id
 */
export const getDataSourceCSV = (id: string) => (dispatch, getState) => {
  const { authToken } = getState().system;
  return dispatch({
    [CALL_API]: {
      endpoint: `data-sources/${id}`,
      options: {
        method: "GET",
        headers: {
          Accept: "text/csv",
          Authorization: `Bearer ${authToken}`,
        },
      },
      types: [
        "GET_DATA_SOURCE_CSV_REQUEST",
        "GET_DATA_SOURCE_CSV_SUCCESS",
        "GET_DATA_SOURCE_CSV_FAILURE",
      ],
    },
  })
    .then((res: GetDataSourceTemplateResponse) => res)
    .catch((err) => {
      throw new Error(err);
    });
};

/**
 * Submit data source information for an advanced data source
 * @param ds
 */
export const postDataSource = (ds: DataSource) => (dispatch, getState) => {
  const { authToken } = getState().system;
  return dispatch({
    [CALL_API]: {
      endpoint: "data-sources",
      options: {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${authToken}`,
        },
        body: JSON.stringify(ds),
      },
      types: [
        "POST_DATA_SOURCE_REQUEST",
        "POST_DATA_SOURCE_SUCCESS",
        "POST_DATA_SOURCE_FAILURE",
      ],
    },
  })
    .then((res: DataSourceResponse) => res)
    .catch((err) => {
      throw new Error(err);
    });
};

/**
 * Update an existing data source
 * @param ds
 */
export const putDataSource = (ds: DataSource | Partial<DataSourceVM>) => (dispatch, getState) => {
  const { authToken } = getState().system;
  return dispatch({
    [CALL_API]: {
      endpoint: "data-sources",
      options: {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${authToken}`,
        },
        body: JSON.stringify(ds),
      },
      types: [
        "PUT_DATA_SOURCE_REQUEST",
        "PUT_DATA_SOURCE_SUCCESS",
        "PUT_DATA_SOURCE_FAILURE",
      ],
    },
  })
    .then((res: DataSourceResponse) => res)
    .catch((err) => {
      throw new Error(err);
    });
};

/**
 * Update the status of an existing data source
 * @param dsid
 * @param status
 */
export const putDataSourceStatus = (dsid: number, status: string) => (dispatch, getState) => {
  const { authToken } = getState().system;
  return dispatch({
    [CALL_API]: {
      endpoint: `data-sources/${dsid}/status`,
      options: {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${authToken}`,
        },
        body: JSON.stringify(status),
      },
      types: [
        "PUT_DATA_SOURCE_REQUEST",
        "PUT_DATA_SOURCE_SUCCESS",
        "PUT_DATA_SOURCE_FAILURE",
      ],
    },
  })
    .then((res: DataSourceResponse) => res)
    .catch((err) => {
      throw new Error(err);
    });
};

/**
 * Submit a new data source CSV template
 * @param id - id of data source
 * @param fd - CSV data
 */
export const postDataSourceCSV = (id: number, fd: FormData) => (dispatch, getState) => {
  const { authToken } = getState().system;
  return dispatch({
    [CALL_API]: {
      endpoint: `data-sources/${id}/batch`,
      options: {
        method: "POST",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${authToken}`,
        },
        body: fd,
      },
      types: [
        "POST_DATA_SOURCE_CSV_REQUEST",
        "POST_DATA_SOURCE_CSV_SUCCESS",
        "POST_DATA_SOURCE_CSV_FAILURE",
      ],
    },
  })
    .then((res: PostDataSourceCSVResponse) => res)
    .catch((err) => {
      throw new Error(err);
    });
};

/**
 * Post data for an established basic Data Source
 * @param id
 * @param body
 */
export const postBasicDSValues = (id: number, body: { value: string; displayOrder: number }[]) => (dispatch, getState) => {
  const { authToken } = getState().system;
  return dispatch({
    [CALL_API]: {
      endpoint: `data-sources/${id}/batch`,
      options: {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${authToken}`,
        },
        body: JSON.stringify(body),
      },
      types: [
        "POST_DATA_SOURCE_JSON_REQUEST",
        "POST_DATA_SOURCE_JSON_SUCCESS",
        "POST_DATA_SOURCE_JSON_FAILURE",
      ],
    },
  })
    .then((res: PostDataSourceCSVResponse) => res)
    .catch((err) => {
      throw new Error(err);
    });
};

/**
 * Get page of values related to a particular data source
 * @param keyName - dataSourceKey of the desired data source
 * @param sortBy - sort string
 * @param page - page number
 * @param size - number of results to return
 */

export interface GetDataSourceValuesArgs {
  keyName: string;
  sortBy?: string | string[];
  page?: number;
  size?: number;
  lastModifiedDate?: string;
  query?: string;
}
export const getDataSourceValues = ({
  keyName,
  sortBy,
  page,
  size,
  lastModifiedDate,
  query
}: GetDataSourceValuesArgs) => 
  (dispatch, getState): Promise<GetDataSourceValuesAction> => {
    const { 
      system: { authToken },
    }: AppState = getState();

    const endpoint = createDataSourceEndpoint({
      lastModifiedDate,
      keyName,
      sort: sortBy,
      page,
      size,
      query,
    });

    return dispatch({
      keyName,
      [CALL_API]: {
        endpoint,
        options: {
          useMock: false,
          method: "GET",
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        },
        types: [
          GET_DATA_SOURCE_VALUES.REQUEST,
          GET_DATA_SOURCE_VALUES.SUCCESS,
          GET_DATA_SOURCE_VALUES.FAILURE,
        ],
      },
    });
  };

export const clearDataSourceValues = ({ keyName }: {
  keyName: string;
}) => (dispatch): Promise<GetDataSourceValuesAction> => {
  return dispatch({
    keyName,
    type: GET_DATA_SOURCE_VALUES.CLEAR
  });
};

/**
 * Get page of values related to a particular data source
 * @param keyName - dataSourceKey of the desired data source
 * @param query 
 * @param sortBy - sort string
 * @param page - page number
 * @param size - number of results to return
 */
export const searchDataSourceValues = ({ keyName, query, sortBy, page, size }: {
  keyName: string;
  query: string;
  sortBy?: string | string[];
  page?: number;
  size?: number;
}) => (dispatch, getState) => {
  const { 
    system: { authToken },
  }: AppState = getState();

  const endpoint = createDataSourceEndpoint({
    query,
    keyName,
    page,
    size,
    sort: sortBy
  });

  return dispatch({
    keyName,
    [CALL_API]: {
      endpoint,
      options: {
        useMock: false,
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${authToken}`,
        },
      },
      types: [
        "SEARCH_DATA_SOURCE_VALUES_REQUEST",
        "SEARCH_DATA_SOURCE_VALUES_SUCCESS",
        "SEARCH_DATA_SOURCE_VALUES_FAILURE",
      ],
    },
  }).then((res: SearchDataSourceValuesAction) => res)
    .catch((err) => {
      throw new Error(err);
    });
};

/**
 * Get values related to a particular data source
 * @param id - dataSourceKey of the desired data source
 */
export const getDataSourceValuesById = (id: string, sort?: string) => (dispatch, getState) => {
  const { authToken } = getState().system;

  const endpoint = buildFilteredEndpoint(`data-sources/${id}/values`, { sort });

  return dispatch({
    [CALL_API]: {
      endpoint,
      options: {
        useMock: false,
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${authToken}`,
        },
      },
      types: [
        "GET_DS_VALUES_BY_ID_REQUEST",
        "GET_DS_VALUES_BY_ID_SUCCESS",
        "GET_DS_VALUES_BY_ID_FAILURE"
      ],
    },
  }).catch((err) => {
    throw new Error(err);
  });
};

// Get specific data source value by key and id
export const getDataSourceValueByIdKey = (id: number, key: string) =>
  (dispatch, getState): Promise<{response: DataSourceValueDTO; type: string}> => {
    const { authToken } = getState().system;

    const endpoint = `data-source-values/${id}?dataSourceKey=${key}`;

    return dispatch({
      [CALL_API]: {
        endpoint,
        options: {
          useMock: false,
          method: "GET",
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        },
        types: [
          "GET_DS_VALUES_BY_ID_KEY_REQUEST",
          "GET_DS_VALUES_BY_ID_KEY_SUCCESS",
          "GET_DS_VALUES_BY_ID_KEY_FAILURE",
        ],
      },
    }).then((res) => res)
      .catch((err) => {
        throw new Error(err);
      });
  };

export const getAllDataSourceValuesById = (id: string) => (dispatch, getState) => {
  const { authToken } = getState().system;

  const endpoint = `data-sources/${id}/values/all`;
  return dispatch({
    [CALL_API]: {
      endpoint,
      options: {
        useMock: false,
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${authToken}`,
        },
      },
      types: [
        "GET_ALL_DATA_SOURCE_VALUES_BY_IS_REQUEST",
        "GET_ALL_DATA_SOURCE_VALUES_BY_IS_SUCCESS",
        "GET_ALL_DATA_SOURCE_VALUES_BY_IS_FAILURE",
      ],
    },
  }).then((res) => res)
    .catch((err) => {
      throw new Error(err);
    });
};
