export const GET_ALL_FEEDBACK_TYPES = {
  FAILURE: "GET_ALL_FEEDBACK_TYPES_FAILURE",
  REQUEST: "GET_ALL_FEEDBACK_TYPES_REQUEST",
  SUCCESS: "GET_ALL_FEEDBACK_TYPES_SUCCESS",
};

export interface FeedbackTypeVm {
  displayOrder: number;
  emailDestination?: string;
  id: number;
  placeholderText: string | null;
  selectionText: string;
  softDeleted: boolean;
  supportEmailOverride?: string;
};
