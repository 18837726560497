import { useContext, CSSProperties } from "react";
import { ThemeContext } from "styled-components";
import { StyleSheet } from "aphrodite";

import { remCalc } from "themes/helpers";

const SIDEBAR_WIDTH = remCalc(378);

export default function (): { [key: string]: CSSProperties } {
  const theme = useContext(ThemeContext);

  return StyleSheet.create({
  // Global sidebar
    sidebar: {
      overflowY: "scroll",
      overflow: "initial",
      width: SIDEBAR_WIDTH,
      // full height minus header wrapper height
      display: "flex",
      flexDirection: "column",
      height: "100%",
      borderStyle: "solid",
      borderWidth: "0 1px",
      backgroundColor: theme.masterColors.white,
      borderColor: theme.masterColors.mediumGrey,
      color: theme.masterColors.darkGrey,
    },

    sidebarContainer: {
      width: SIDEBAR_WIDTH,
      height: "100%",
    },

    sidebarIntro: {
      fontSize: "0.75rem",
      padding: "1rem",
      position: "relative",
      background: theme.colors.white,
      marginBottom: 10,
    },
    introLeft: {
      borderRadius: "0px 4px 4px 0px",
      borderLeft: 0,
    },
    introRight: {
      left: "-0.75rem",
      borderRadius: "4px 0px 0px 4px",
      borderRight: 0,
    },
    introActive: {
      width: "calc(100% + 0.75rem)",
      padding: "calc(1rem - 2px) 1rem", // prevent size changes from added border
      background: theme.colors.activeFill,
      borderColor: theme.colors.primary,
      borderWidth: "2px",
      borderStyle: "solid",
    },

    sidebarIntroLabel: {
      color: "black",
      fontSize: "0.75rem",
      letterSpacing: "1.88pt",
    },

    label: {
      color: theme.colors.black,
      fontSize: "1rem",
      fontWeight: 500,
      letterSpacing: remCalc(0.15),
    },

    section: {
      padding: "1rem 0",
      borderBottom: `1px solid ${theme.colors.lightGrey}`,
    },
  });
};
