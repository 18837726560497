import React from "react";
import { orderBy } from "lodash";

import { Drawer } from "../../common/Drawer/Drawer";
import { FormTypeDTO } from "../../../store/forms/types";
import { Value, Header, ContentWrapper, IconWrapper, ValueWrapper } from "../../common/StatsCard/styles";
import DrawerHeader from "../../common/Drawer/components/Header";
import Icon from "../../common/Icon";

import { Dot, GroupWrapper, LinkButton, Statistic } from "../reports/statistics/styles";
import { FormTypeStats } from "../helpers";
import PercentLineGraph from "../reports/statistics/PercentLineGraph";

type Props = {
  closeDrawer: () => void;
  formStats: FormTypeStats;
  formType: FormTypeDTO;
  onGroupClick: (g: number) => void;
  show: boolean;
  viewingGroupId: number;
};

export const GroupsListDrawer = ({ closeDrawer, formStats, formType, onGroupClick, show }: Props) => {
  const groupsSorted = orderBy(formStats.groupCounts, ["percent"], ["desc"]);

  return <Drawer
    anchor="right"
    content={
      <div style={{ width: "30vw" }}>
        <DrawerHeader closeDrawer={closeDrawer} text={formType.name} />

        <ContentWrapper>
          <div>
            <Header>{formType.name}</Header>
            <ValueWrapper>
              <Value>{formStats.totalCount}</Value>
            </ValueWrapper>
          </div>
          {formType.iconName && (
            <IconWrapper>
              <Icon type={formType.iconName} color={formType.iconColor || ""} framed={true} />
            </IconWrapper>
          )}
        </ContentWrapper>

        <PercentLineGraph total={formStats.totalCount} data={formStats.groupCounts} />

        {groupsSorted.map((group) => (
          <GroupWrapper key={group.id} className="d-flex w-100 justify-content-between my-2">
            <div className="d-flex align-items-center">
              <Dot color={group.color} className="mr-2" />
              <LinkButton onClick={() => onGroupClick(group.id)}>
                {group.name}
              </LinkButton>
            </div>
            <div className="d-flex align-items-center">
              <Statistic className="mr-3">{group.total}</Statistic>
              <Statistic>{group.percent}%</Statistic>
            </div>
          </GroupWrapper>
        ))}
      </div>
    }
    id="groupListDrawer"
    onClose={closeDrawer}
    onOpen={() => {}}
    open={show}
    showCloseIcon={false}
  />;
};
