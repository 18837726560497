import { CALL_API } from "middleware/api";

import { AllParticpantsActionType, GET_ALL_PARTICIPANTS, GET_PARTICIPANTS } from "./types";

/**
 * Dispatch API call to get all Participants by workLocationId
 */
export const getParticipants = (workLocationId?: number) => (dispatch, getState) => {
  const { authToken } = getState().system;

  const endpoint = `work-locations/${workLocationId}/participants`;

  return dispatch({
    [CALL_API]: {
      endpoint,
      options: {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${authToken}`,
        },
      },
      types: [GET_PARTICIPANTS.REQUEST, GET_PARTICIPANTS.SUCCESS, GET_PARTICIPANTS.FAILURE],
    },
  });
};

// Get all participants
export const getAllParticipants = (args: AllParticpantsActionType) => (dispatch, getState) => {
  const { authToken } = getState().system;

  let endpoint = "participants?";

  // Add query params
  const params = Object.keys(args);
  params.forEach((param: string) => {
    if (args[param] !== undefined && args[param] !== -1 && args[param] !== "") {
      // If param has a value, add it to the url
      endpoint = `${endpoint}&${param}=${encodeURIComponent(args[param])}`;
    }
  });

  return dispatch({
    [CALL_API]: {
      endpoint,
      options: {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${authToken}`,
        },
      },
      types: [GET_ALL_PARTICIPANTS.REQUEST, GET_ALL_PARTICIPANTS.SUCCESS, GET_ALL_PARTICIPANTS.FAILURE],
    },
  });
};
