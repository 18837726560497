import React, { Dispatch, SetStateAction } from "react";
import { FC } from "react";

import BaseSearchBar from "./BaseSearchBar";

interface Props {
  onChange: Dispatch<SetStateAction<string>>;
  onSearch: (value: string) => void;
  query: string;
}

const UsersSearchBar: FC<Props> = ({ onChange, onSearch, query }) => {
  const search = (value: string) => {
    onSearch(value);
  };

  return (
    <BaseSearchBar onChange={onChange} onSearch={search} initialQuery={query} />
  );
};

export default UsersSearchBar;
