import React from "react";

interface CollapsibleDivProps {
  children: React.ReactNode;
  collapsed: boolean;
}
const CollapsibleDiv = ({ children, collapsed }: CollapsibleDivProps) => (
  <div style={collapsed ? { display: "none" } : {}}>
    {children}
  </div>
);

export default CollapsibleDiv;
