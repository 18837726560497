import ChipBase from "@material-ui/core/Chip";
import InputAdornmentBase from "@material-ui/core/InputAdornment";
import InputLabelBase from "@material-ui/core/InputLabel";
import TextFieldBase, {
  OutlinedTextFieldProps,
} from "@material-ui/core/TextField";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { get } from "lodash";
import React from "react";
import styled from "styled-components";
import COLORS from "themes/colors";
import { remCalc } from "themes/helpers";
import mediaQueries from "themes/mediaQueries";
import { InternalVariantType, TextInputProps } from "./TextInput";
import { ReactComponent as ErrorIcon } from "assets/svg/error.svg";

export const TextInput = styled.div<Partial<TextInputProps>>`
  display: flex;
  flex-direction: column;
`;

/* eslint-disable */

interface TextFieldProps extends OutlinedTextFieldProps {
  height?: number;
  internalVariant: InternalVariantType;
  isActive?: boolean;
  renderedStartAdornment: boolean;
  rounded?: boolean;
  success?: boolean;
}

export const TextField = styled<React.FC<TextFieldProps>>(
  ({
    fullWidth,
    internalVariant,
    isActive,
    renderedStartAdornment,
    rounded,
    success,
    ...otherProps
  }) => {
    return (
      <TextFieldBase
        {...otherProps}
        classes={{
          root: "root",
        }}
        InputLabelProps={{
          classes: {
            outlined: "label-outlined",
            error: "label-error",
            focused: "label-focused",
          },
        }}
        InputProps={{
          ...otherProps.InputProps,
          classes: {
            root: "input-root",
            input: "input",
            notchedOutline: "notchedOutline",
            adornedStart: "adornedStart",
            adornedEnd: "adornedEnd",
            focused: "focused",
            error: "error",
            disabled: "disabled",
          },
        }}
      />
    );
  }
)`
  && .focused .notchedOutline {
    outline: 0;
    border-width: 1px;

    ${({ isActive, theme }) => {
      if (isActive) {
        return `
          border-color: ${theme.colors.primary};
          box-shadow: 0 0 0 1px ${theme.colors.primary};
        `;
      } else {
        return `
          border-color: ${theme.colors.focusIndicatorOutline};
          box-shadow: 0 0 0 ${remCalc(3)} ${theme.colors.focusIndicatorOutline};
        `;
      }
    }}
  }

  && .error {
    background-color: ${({ theme }) => theme.colors.formFieldErrorBackground};
  }

  && .error .notchedOutline {
    border-color: ${({ theme }) => theme.colors.error}
    border-width: 1px;
  }

  && .label-error {
    color: ${({ theme }) => theme.colors.error};
  }

  && .label-focused {
    color: ${({ theme }) => theme.colors.primary};
  }

  && .input {
    font-size: ${remCalc(16)};
    padding: 0px ${remCalc(15)} 0px ${remCalc(16)};
    color: ${({ theme }) => theme.colors.black};

    // HANDLE SUCCESS COLOR
    ${({ success, theme }) => {
      if (success) {
        return `
          color: ${theme.masterColors.success};
        `;
      }
    }}

    &::placeholder {
      color: ${({ theme }) => theme.masterColors.darkGreyBlue};
      opacity: 1;
    }
    
    // REMOVE LEFT PADDING FOR TEXT AREA
   
    ${({ internalVariant }) =>
      internalVariant === "TextArea" &&
      `
      padding: 0px;
    `}
    // HANDLE LINE HEIGHT FOR TEXT AREA

    ${({ multiline }) =>
      multiline &&
      `
      line-height: ${remCalc(30)};
    `}

    // HANDLE HEIGHT FOR GENERIC INPUT
    height: ${remCalc(56)};

    @media (${mediaQueries.maxTablet}) {
      height: ${remCalc(84)};
    }

    @media (${mediaQueries.maxPhone}) {
      height: ${remCalc(56)};    
    }

    ${({ height, internalVariant }) => {
      if (height) {
        return `height: ${remCalc(height)};`;
      }

      // HANDLE PARTICIPANT FIELD'S ADD MORE VARIANT
      // "Participant-Add-More"
      if (internalVariant === "Participant-Add-Another") {
        return `
          height: ${remCalc(32)};
          padding: ${remCalc(5)} ${remCalc(10)};
        `;
      }

      return `height: ${remCalc(56)};`;
    }}
  }

  && .notchedOutline {
    ${({ theme }) => {
      return `
        border-color: ${theme.colors.formFieldBorder};
        color: ${theme.colors.black};
      `;
    }}

    ${({ rounded }) => rounded && "border-radius: 18px;"}

    // HANDLE SUCCESS STATE
    ${({ success, theme }) => {
      if (success) {
        return `
          border-color: ${theme.masterColors.success};
        `;
      }
    }}

    // HANDLE VARIANT FOR PARTICIPANT ADD ANOTHER
    ${({ internalVariant }) => {
      if (internalVariant === "Participant-Add-Another") {
        return `
          border: none;
        `;
      }
    }}
  }

  && .adornedStart {
    padding-left: ${({ renderedStartAdornment }) =>
      renderedStartAdornment ? "14px" : "0px"};
  }

  && .disabled {
    color: ${({ theme }) => theme.masterColors.darkGreyBlue}
  }

  && .disabled.input-root {
    background-color: ${({ theme }) => theme.colors.lightGrey};
  }

  && .disabled .notchedOutline {
    border-color: ${({ theme }) => theme.colors.mediumGrey};
    color: ${({ theme }) => theme.colors.mediumGrey}
  }

  &&.root {
    width: 100%;
  }
`;

// FOR TEXT FIELD SUB COMPONENTS

export const StartAdornmentIcon = styled.span<{ darkMode?: boolean }>`
  color: ${({ darkMode }) =>
    darkMode ? COLORS.masterColors.white : COLORS.masterColors.darkGrey};
  font-size: ${remCalc(17)};

  margin-right: 0px;
`;

export const EndAdornmentButton = styled.button<{ isMouseFocused: boolean }>`
  background: none;
  border: none;
  border-radius: ${remCalc(4)};
  box-shadow: 0 0 0 ${remCalc(6)} transparent;
  color: ${({ color, theme }) =>
    color ? get(theme, color.split("."), "red") : "red"};
  font-size: 0.875rem;
  text-decoration: underline;

  ${({ theme }) => {
    const { duration, timingFunction } = theme.animations.focusIndicatorAppear;
    return `transition: box-shadow ${duration} ${timingFunction};`;
  }}

  &:focus {
    ${({ isMouseFocused, theme }) => {
      if (!isMouseFocused) {
        return `
          box-shadow: 0 0 0 ${remCalc(4)} ${theme.colors.focusIndicatorOutline};
        `;
      }
    }}
    outline: 0;
  }

  &::hover {
    cursor: pointer;
  }
`;
/* eslint-enable */

export const InputAdornment = styled(({ ...otherProps }) => (
  <InputAdornmentBase {...otherProps} />
))``;

export const EndAdornmentIcon = styled.span<{ darkMode?: boolean }>`
  font-size: ${remCalc(24)};
  color: ${({ theme }) => theme.masterColors.darkGrey};
`;

/* eslint-disable */
export const InputLabel = styled(({ smallLabel, ...otherProps }) => (
  <InputLabelBase {...otherProps} classes={{ root: "root" }} />
))`
  &&.root {
    color: ${({ theme }) => theme.colors.formFieldLabel};
    margin-bottom: ${remCalc(8)};
    font-size: ${remCalc(20)};
    font-weight: 500;

    ${({ smallLabel }) =>
      smallLabel &&
      `
      color: ${COLORS.masterColors.darkGrey};  
      margin-bottom: ${remCalc(5)};
      font-size: ${remCalc(13)};

      @media (${mediaQueries.minTablet}) {
        font-size: ${remCalc(13)};
      }
      `}
  }
`;
/* eslint-enable */

const ErrorSpan = styled.span`
  display: block;
  color: ${({ theme }) => theme.colors.error};
  font-size: ${remCalc(16)};
  margin: ${remCalc(3)} ${remCalc(5)} 0rem ${remCalc(16)};
  font-family: "Roboto";
`;

const ErrorIconContainer = styled.span`
  display: inline-block;
  height: ${remCalc(16)};
  padding-right: ${remCalc(8)};
  vertical-align: baseline;
  width: ${remCalc(16)};

  && .MuiSvgIcon-root {
    height: 100%;
    width: 100%;
  }
`;

const StyledErrorIcon = styled(ErrorIcon)`
  .error-fill {
    ${({ theme }) => {
      return `
        fill: ${theme.colors.error};
        stroke: ${theme.colors.error};
      `;
    }}
  }
`;

export const ErrorText: React.FC = ({ children }) => {
  return (
    <ErrorSpan>
      {/*
        Removed for UAT push on 5/20/2020 due to looking inconsistent with other
        error messages. Replace after the push. —Andrew Dawson
      
        <ErrorIconContainer>
          <StyledErrorIcon fillClassName="error-fill" />
        </ErrorIconContainer>
      */}
      {children}
    </ErrorSpan>
  );
};

export const HelperText = styled.span<{ hasValue?: boolean }>`
  display: block;
  color: ${({ hasValue, theme }) =>
    hasValue ? theme.colors.darkGrey : theme.colors.assitiveText};
  font-size: ${remCalc(16)};
  margin: ${remCalc(3)} ${remCalc(5)} 0 ${remCalc(16)};
  font-family: "Roboto";

  @media (${mediaQueries.maxTablet}) {
    font-size: ${remCalc(18)}; // @TODO: CONFIRM
  }

  @media (${mediaQueries.maxPhone}) {
    font-size: ${remCalc(12)};
  }
`;

export const AssistiveIcon = styled.i`
  color: ${({ theme }) => theme.colors.primary};
  text-decoration: none;
  display: none;

  @media (${mediaQueries.minTablet}) {
    display: inline-block;
    font-size: ${remCalc(34)};
  }
`;

type AssistiveLink = {
  endAdornment?: boolean;
  multiline?: boolean;
  internalVariant?: InternalVariantType;
};
export const AssistiveLink = styled.a<AssistiveLink>`
  font-family: "Roboto", sans-serif;
  color: ${({ theme }) => theme.colors.primary};
  font-size: ${remCalc(14)};

  @media (${mediaQueries.maxTablet}) {
    font-size: ${remCalc(21)};
  }

  @media (${mediaQueries.maxPhone}) {
    font-size: ${remCalc(14)};
  }
`;

// disabled bec eslint doesn't like how the interface is a new line
/* eslint-disable */
// @TODO update to AssistiveLink styles
const AssistiveLinkButton = styled.button<{
  endAdornment?: boolean;
  isTablet?: boolean;
  multiline?: boolean;
  internalVariant?: InternalVariantType;
}>`
  font-family: "Roboto", sans-serif;
  color: ${({ theme }) => theme.colors.primary};
  font-size: ${remCalc(14)};
  border: none;
  background: none;

  &:hover {
    cursor: pointer;
  }

  @media (${mediaQueries.minTablet}) {
  }
`;
/* eslint-enable */

export const LabelRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Chip = styled(({ ...otherProps }) => <ChipBase {...otherProps} />);

// @NOTE **Row** will have code related to rendering the Map Marker pins
// for the Location Field
/* eslint-disable */
export const Row = styled.div<{
  variant?: "default" | "map-pin" | "field";
}>`
  width: 100%;

  // HANDLE VARIANTS
  ${({ variant }) => {
    switch (variant) {
      case "default":
        return `
         display: flex;
         justify-content: space-between;
         width: 100%;
        `;
      case "map-pin":
        return `
          flex: 0.5;

          display: flex;
          justify-content: center;
          align-items: flex-start;
          padding-top: ${remCalc(10)};

          @media (${mediaQueries.maxTablet}) {
            padding-top: ${remCalc(20)};
          }

          @media (${mediaQueries.maxPhone}) {
            padding-top: ${remCalc(10)};
          }
        `;
      case "field":
        return `
          flex: 9.5;
          display: flex;
          flex-direction: column;
          text-align: left;
        `;
      default:
        return null;
    }
  }}
`;
/* eslint-enable */

export const VisibilityIcon = styled(({ ...props }) => (
  <Visibility
    classes={{
      root: "root",
    }}
    {...props}
  />
))`
  &&.root {
    color: ${({ theme }) => theme.colors.black};
  }
`;
export const VisibilityOffIcon = styled(({ ...props }) => (
  <VisibilityOff
    classes={{
      root: "root",
    }}
    {...props}
  />
))`
  &&.root {
    color: ${({ theme }) => theme.masterColors.mediumGrey};
  }
`;
