import React from "react";
import { useDispatch } from "react-redux";
import { useHistory, useRouteMatch } from "react-router";
import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";

import { ContentWrapper } from "components/common/Wrappers";
import Loader from "components/common/Loader";
import Breadcrumbs from "components/common/Breadcrumbs";

import S from "./styles";
import { DSForm } from "./DSForm";
import { RouteParams } from "./types";
import withTheme from "@material-ui/core/styles/withTheme";
import { DataSource } from "../../../store/dataSource/types";
import {
  getAllDataSourceValuesById,
  getDataSourceById,
} from "../../../store/dataSource/actions";
import { orderBy } from "lodash";

/* Component behavior is dictated by the existence of an "id" param in the URL:
 * 1. "id" exists: user is editing an existing source. fetch source and pre-fill form.
 * 2. "id" undefined: user is creating a new source. provide empty form. */

/** Parent view for adding, editing and viewing a basic Data Set */
function BasicDS() {
  const dispatch = useDispatch<ThunkDispatch<null, void, Action>>();
  const history = useHistory();
  const match = useRouteMatch<RouteParams>();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [dataSource, setDataSource] = React.useState<DataSource | null>(null);
  // TODO handle data source values for delimited data list -JA
  const [dataSourceValues, setDataSourceValues] = React.useState<string[]>([]);

  React.useEffect(() => {
    (async () => {
      if (match.params.id) {
        setLoading(true);
        const ds = await dispatch(getDataSourceById(match.params.id));
        if (ds.response) {
          const dsv = await dispatch(
            getAllDataSourceValuesById(match.params.id)
          );
          if (dsv.response) {
            setDataSource(ds.response);
            setDataSourceValues(
              orderBy(dsv.response, "content.displayOrder").map(
                (value) => value.content.value
              )
            );
          }
        }
        setLoading(false);
      }
    })();
  }, []);

  return (
    <ContentWrapper>
      <Loader loading={loading}>
        <Breadcrumbs
          paths={[
            { pathName: "Forms" },
            { pathName: "Data Sets", href: "/forms/data-sets" },
            {
              pathName: `Data Set${
                dataSource?.title ? `: ${dataSource.title}` : ""
              }`,
            },
          ]}
        />
        <S.Title>
          {dataSource?.title ? dataSource.title : "Create a Basic Data Set"}
        </S.Title>
        {!dataSource && (
          <S.Subtitle>
            Creating a basic data set is a quick way to include single item
            lists as answer options on forms. To create a basic data set, assign
            a title and description, then copy/paste or write out a list with
            each item on a new line. If you would like to create a data set with
            multiple properties per item or import by CSV,&nbsp;
            <S.Link onClick={() => history.push("/forms/data-sets/advanced")}>
              create a new advanced data set instead.
            </S.Link>
          </S.Subtitle>
        )}
        <DSForm
          dataSource={dataSource}
          dataSourceValues={dataSourceValues}
          setDataSource={setDataSource}
        />
      </Loader>
    </ContentWrapper>
  );
}

export default withTheme(BasicDS);
