import { createStore, combineReducers, applyMiddleware } from "redux";
import thunkMiddleware from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage

import { allFormStatsReducer } from "./reporting/forms/reducer";
import { api, unauthorized } from "../middleware";
import { appConfigsReducer } from "./appConfigs/reducer";
import { clientConfigsReducer } from "./clientConfigs/reducer";
import { clientGroupConfigsReducer } from "./clientGroupConfigs/reducer";
import { clientGroupsReducer } from "./clientGroups/reducer";
import { dataSourceReducer } from "./dataSource/reducer";
import { documentsReducer } from "./documents/reducer";
import { filtersReducer } from "./filters/reducer";
import { formStatsReducer } from "./reporting/singleFormStats/reducer";
import { formsReducer } from "./forms/reducer";
import { newDocumentReducer } from "./newDocument/reducer";
import { operationalExperiencesReducer } from "./operationalExperiences/reducer";
import { participantConfigsReducer } from "./participantConfigs/reducer";
import { participantsAggregateSummaryReducer } from "./participantsAggregateSummary/reducer";
import { participantsReducer } from "./participants/reducer";
import { participantsSummaryReducer } from "./participantsSummary/reducer";
import { questionsReducer } from "./questions/reducer";
import { systemReducer } from "./system/reducers";
import { userReducer } from "./user/reducer";
import { usersReducer } from "./users/reducer";
import { workLocationReducer } from "./workLocation/reducer";
import { workLocationsReducer } from "./workLocations/reducer";
import { workOrdersReducer } from "./workOrders/reducer";
import { allFormTypeStatsReducer } from "./reporting/formTypes/reducer";
import { participantTreeOptionsReducer } from "./participantTreeFilter/reducer";

export const RESET_APP = "RESET_APP";

export const appReducer = combineReducers({
  allFormStats: allFormStatsReducer,
  allFormTypeStats: allFormTypeStatsReducer,
  appConfigs: appConfigsReducer,
  clientConfigs: clientConfigsReducer,
  clientGroupConfigs: clientGroupConfigsReducer,
  clientGroups: clientGroupsReducer,
  dataSource: dataSourceReducer,
  documents: documentsReducer,
  filters: filtersReducer,
  formStats: formStatsReducer,
  forms: formsReducer,
  newDocument: newDocumentReducer,
  operationalExperiences: operationalExperiencesReducer,
  participantConfigs: participantConfigsReducer,
  participantTreeOptions: participantTreeOptionsReducer,
  participants: participantsReducer,
  participantsAggregateSummary: participantsAggregateSummaryReducer,
  participantsSummary: participantsSummaryReducer,
  questions: questionsReducer,
  system: systemReducer,
  user: userReducer,
  users: usersReducer,
  workLocation: workLocationReducer,
  workLocations: workLocationsReducer,
  workOrders: workOrdersReducer,
});

const rootReducer = (state, action) => {
  if (action.type === RESET_APP) {
    localStorage.clear(); // Clear localStorage -- GK
    state = undefined;
  }
  return appReducer(state, action);
};

const whitelist = ["system", "user"];

const persistConfig = {
  key: "root",
  storage,
  ...(process.env.NODE_ENV === "production" && { whitelist }),
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export type AppState = ReturnType<typeof rootReducer>;

export default function configureStore() {
  const middleware = [thunkMiddleware, api, unauthorized];
  // @ts-ignore FIXME - remove ts-ignore and fix type - JA
  const middleWareEnhancer = applyMiddleware(...middleware);

  /**
   * HANDLE PRODUCTION/HIGHER ENVS
   */

  // Removing this for now to persist `user`

  // if (process.env.NODE_ENV !== "development") {
  //   const productionStore = createStore(rootReducer, middleWareEnhancer);
  //   return {
  //     store: productionStore,
  //     persistor: null,
  //   };
  // }

  console.log("RUNNING IN DEVELOPMENT MODE");

  const store = createStore(
    persistedReducer,
    composeWithDevTools(middleWareEnhancer)
  );
  const persistor = persistStore(store);

  return { store, persistor };
}
