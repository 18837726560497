import downloadFileFromBlob from "downloadjs";
import { FormTypeStats as apiFormTypeStats } from "store/reporting/formTypes/types";
import { ClientGroup } from "store/clientGroups/types";
import { callApi } from "middleware/api";
import { identity, pickBy } from "lodash";

const buildQueryString = (
  params: Record<string, string | null | undefined>
) => {
  const definedParams = pickBy(params, identity) as Record<string, string>;
  const queryString = new URLSearchParams(definedParams).toString();
  return queryString;
};

/** Builds an export URL for CA tables */
export const getExportUrl = (
  base: string,
  query?: string,
  sort?: string,
  extraParams?: Record<string, string>
) => {
  const queryString = buildQueryString({ ...extraParams, sort, query });
  const result = queryString ? `${base}?${queryString}` : base;
  return result;
};

/**
 * Create an array of options for a groups select dropdown
 * @param groups
 */
export function generateGroupOptions(groups: Array<ClientGroup>) {
  const groupFilterOptions = [
    {
      id: -1,
      value: "All Groups",
    },
  ];
  if (groups && groups.length > 0) {
    groups
      .sort((a, b) => {
        if (a.name === undefined) return b.name === undefined ? 0 : 1;
        if (b.name === undefined) return -1;
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
      })
      .forEach((group) => {
        if (group.id && group.name) {
          groupFilterOptions.push({
            id: group.id,
            value: group.name,
          });
        }
      });
  }
  return groupFilterOptions;
}

/**
 * Compile a list of form types based on stored types and searched form types
 * @param storedFormTypes   - form types stored in reducer
 * @param searchedFormTypes - TODO not sure what this represents (brought over from refactor)
 * // TODO need type def in parameters
 */
export function generateFormTypes(storedFormTypes, searchedFormTypes) {
  if (searchedFormTypes) {
    const compiledFormTypes = storedFormTypes;
    searchedFormTypes.forEach((form) => {
      if (!storedFormTypes.find((ft) => ft.id === form.type.id)) {
        compiledFormTypes.push(form.type);
      }
      return compiledFormTypes;
    });
  }
  return storedFormTypes;
}

export type GroupStats = {
  [key: string]: {
    name: string;
    id: number;
    total: number;
    color: string;
    percent: number;
  };
};

export type FormTypeStats = {
  totalCount: number;
  groupCounts: GroupStats;
};

/**
 * Aggregate data for a form type
 * @param apiFormTypeStats
 */
export const getStatsForFormType = (
  apiFormTypeStats: apiFormTypeStats
): FormTypeStats => {
  // TODO colors from mocks - should be moved to global - JA
  const colors = [
    "#F91919",
    "#00853C",
    "#1165A8",
    "#5E35B1",
    "#EF6C00",
    "#00838F",
    "#AD1457",
    "#795548",
  ];

  const { totalCount } = apiFormTypeStats;

  const groupCounts = {};
  for (let i = 0; i < apiFormTypeStats.groupCounts.length; i++) {
    const { group, count } = apiFormTypeStats.groupCounts[i];
    groupCounts[group.name] = {
      name: group.name,
      id: group.id,
      total: count,
      color: colors[i % 8],
      percent: Math.round((count / totalCount) * 100),
    };
  }

  return {
    totalCount,
    groupCounts,
  };
};

/**
 * Exports to xls or csv and sets loading status
 * When clicked, the resulting URL is fetched and downloaded as a file.
 *
 * @param authToken User's Bearer token for Authorization header
 * @param fileName Desired name of downloaded file
 * @param params Object of query string parameters
 * @param type File type (text/csv, etc)
 * @param url Relative URL from which to download
 */
export const doExport = (
  authToken,
  fileName,
  params,
  setLoading,
  type,
  url
) => {
  // Add params to `url`
  if (params) {
    const queryStr = Object.entries(params).map(
      ([key, val]) => `${key}=${val}`
    );
    url += url.includes("?") ? "&" : "?";
    url += queryStr.join("&");
  }
  // Set (external) loading status
  setLoading(true);

  callApi(url, {
    headers: {
      "Content-Type": type,
      Accept: type,
      Authorization: `Bearer ${authToken}`,
    },
  }).then((blob) => {
    setLoading(false);
    downloadFileFromBlob(blob, fileName, type);
  });
};
