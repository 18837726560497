import React, { useCallback } from "react";
import Divider from "@material-ui/core/Divider";
import { useHistory, useLocation } from "react-router";

import ListSimple, { ListSimpleItem } from "components/common/ListSimple";
import Loader from "components/common/Loader";
import { AppConfigsState } from "store/appConfigs/types";
import { StyledLinkCaretRight } from "components/common/styled/StyledLinkCaretRight";
import { FormTypeDTO } from "store/forms/types";

import * as S from "../styles";
import styles from "./styles.module.scss"; // TODO: Refactor remaining elements to use SASS
import { DocumentVM, DocumentSummary } from "store/documents/types";
import useGroupTerm from "util/hooks/whiteLabel/useGroupTerm";
import { RelatedDocuments } from "../DocumentHistorySidebar";

interface Props {
  appConfigs: AppConfigsState;
  document: DocumentVM | DocumentSummary;
  formTypes: FormTypeDTO[];
  onSelectFormType: (type: FormTypeDTO) => void;
  relatedDocuments: RelatedDocuments;
  toggleDocumentHistory: () => void;
  toggleFormTypesVisibility: () => void;
  toggleRehuddleSidebarVisibility: () => void;
  toggleShareDocumentVisibility: () => void;
  toggleSubmitFeedbackVisibility: () => void;
  toggleRelatedDocumentsVisibility: () => void;
}

const DocumentSidebarMenu = ({
  // appConfigs, // @TODO: Connect in separate PR
  document,
  formTypes,
  onSelectFormType,
  relatedDocuments,
  toggleDocumentHistory,
  toggleFormTypesVisibility,
  toggleRehuddleSidebarVisibility,
  toggleRelatedDocumentsVisibility,
  toggleShareDocumentVisibility,
}: Props) => {
  const { push } = useHistory();
  const { pathname } = useLocation();

  // Group config terms
  const documentTerm = useGroupTerm("document", "noun", undefined, "Document");
  const documentsTerm = useGroupTerm("document", "noun", "plural", "Documents");
  const rehuddleTerm = useGroupTerm("refocus", "noun", undefined, "Rehuddle"); // not verb -- GK
  // const documentsTermLower = documentTerm.toLowerCase();

  const handleNavigateToDocument = useCallback(
    () => push(`/document/${document.id}`),
    [push, document.id]
  );

  /* Nav items */
  const viewDocument: ListSimpleItem = {
    primary: <S.NavLabel>View {documentTerm}</S.NavLabel>,
    rightSideItem: <StyledLinkCaretRight />,
    onClick: handleNavigateToDocument,
  };

  const documentHistory: ListSimpleItem = {
    primary: <S.NavLabel>{documentTerm} History</S.NavLabel>,
    rightSideItem: <StyledLinkCaretRight />,
    onClick: toggleDocumentHistory,
  };

  const shareDocument: ListSimpleItem = {
    primary: <S.NavLabel>Share</S.NavLabel>,
    rightSideItem: <StyledLinkCaretRight />,
    onClick: () => toggleShareDocumentVisibility(),
  };

  const rehuddle: ListSimpleItem = {
    primary: <S.NavLabel>{rehuddleTerm}</S.NavLabel>,
    rightSideItem: <StyledLinkCaretRight />,
    onClick: () => {
      toggleRehuddleSidebarVisibility();
    },
  };

  const startRelatedShortList: ListSimpleItem[] = formTypes.map(
    (type: FormTypeDTO) => ({
      primary: <S.NavLabel>Start related {type.name}</S.NavLabel>,
      rightSideItem: <StyledLinkCaretRight />,
      onClick: () => onSelectFormType(type),
    })
  );

  const viewMoreRelated: ListSimpleItem = {
    primary: (
      <S.NavLabel>
        Start more Related {documentsTerm} ({formTypes.length - 2})
      </S.NavLabel>
    ),
    rightSideItem: <StyledLinkCaretRight />,
    onClick: () => toggleFormTypesVisibility(),
  };

  const startRelated: ListSimpleItem[] = [...startRelatedShortList];
  if (formTypes.length > 2) {
    startRelated.push(viewMoreRelated);
  }

  const isViewRelatedDisabled: boolean =
    relatedDocuments.isLoading || !relatedDocuments?.data?.length;

  const viewRelated: ListSimpleItem = {
    primary: (
      <S.NavLabel>
        <div className={styles.viewRelated}>
          <span>View Related {documentsTerm}</span>
          <Loader
            loading={relatedDocuments.isLoading}
            className={styles.loader}
            spinnerProps={{ size: 20 }}
          >
            ({relatedDocuments.data.length})
          </Loader>
        </div>
      </S.NavLabel>
    ),
    rightSideItem: <StyledLinkCaretRight />,
    onClick: !isViewRelatedDisabled
      ? () => toggleRelatedDocumentsVisibility()
      : undefined,
    disabled: isViewRelatedDisabled,
  };

  /* Group nav items together */
  // top nav group
  const docDetailsGroup: ListSimpleItem[] = [documentHistory];
  if (!pathname.includes("/document/")) {
    docDetailsGroup.unshift(viewDocument);
  }
  if (document.formSummary.sharingEnabled) {
    docDetailsGroup.push(shareDocument);
  }

  // bottom ("related") nav group
  const relatedDocsGroup: ListSimpleItem[] = [viewRelated];
  if (startRelated.length > 0) {
    relatedDocsGroup.unshift(...startRelated);
  }

  return (
    <S.DocumentSidebarMenu>
      {/* FIXME remove external padding, remove negative margins, add consistent padding -- GK [POW-2362] */}
      <div style={{ marginLeft: "-16px", marginRight: "-16px", marginTop: 0 }}>
        <Divider />
        <ListSimple listItems={docDetailsGroup} />
        <Divider />
        {document.formSummary.isRehuddleEligible && (
          <>
            <ListSimple listItems={[rehuddle]} />
            <Divider />
          </>
        )}
        <ListSimple listItems={relatedDocsGroup} />
      </div>
    </S.DocumentSidebarMenu>
  );
};

export default DocumentSidebarMenu;
