import React, { useContext } from "react";
import { ThemeContext } from "styled-components";

import { Text, TextProps } from "components/clientAdmin/styles";

export const GroupId: React.FC<TextProps> = (props) => {
  const theme = useContext(ThemeContext);
  return (
    <Text
      color={theme.masterColors.darkGrey}
      fontSize="0.75rem"
      fontWeight={400}
      {...props}
    />
  );
};
