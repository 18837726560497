import * as React from "react";
import Label from "components/forms/Label";
import Text from "components/common/Text";
import { AssistiveLinkType } from "components/forms/Label/Label";
import { FieldErrorType } from "components/FormController/components/Question";
import { HelperText } from "../../TextInput/styles";

import { MultiResponseQuestionProps } from "../../types";

import * as S from "../styles";

import RenderCheckboxes from "./RenderCheckboxes";
import { QuestionSelectionsDTO } from "store/forms/types";

export type CheckBoxVariantType =
  | "CHECKBOX"
  | "MULTI_SELECT"
  | "CHECKBOX_FILTER";

export interface CheckboxGroupProps extends MultiResponseQuestionProps {
  assistiveLink?: AssistiveLinkType;
  error?: FieldErrorType;
  isRatingsParentQuestion: boolean;
  handleBlur?: () => void;
  variant: CheckBoxVariantType;
  selections: QuestionSelectionsDTO[];
  children?: React.ReactNode;
}

/**
 * This component is made to be used within `Question.tsx`
 * to render Form Questions for the following types:
 * - CHECKBOX
 * - MULTI_SELECT
 */
const CheckboxGroup = ({
  selections,
  question,
  error,
  setQuestionResponses,
  isRatingsParentQuestion = false,
  handleBlur,
  responses,
  variant = "CHECKBOX",
  assistiveLink,
  children,
}: CheckboxGroupProps) => {
  const isRequired = question?.formProperties?.isRequired;
  const enabledComments = question.properties?.enableComments;
  const helperText = question.properties?.assistiveText;

  if (question && selections) {
    return (
      <S.CheckboxGroup onBlur={handleBlur}>
        <S.CheckboxGroupLabelRow assistiveLink={!!assistiveLink}>
          <Label
            htmlFor={question.title}
            assistiveLink={assistiveLink}
            variant="stacked"
            required={isRequired}
          >
            {isRatingsParentQuestion && !question.title
              ? "Which areas do you want to review?"
              : question.title}
          </Label>
        </S.CheckboxGroupLabelRow>
        {helperText && (
          <div style={{ paddingBottom: 10, marginLeft: -15 }}>
            <HelperText>{helperText}</HelperText>
          </div>
        )}
        <RenderCheckboxes
          selections={selections}
          questionId={question.id}
          questionRootId={question.rootId}
          variant={variant}
          enableComments={enabledComments}
          responses={responses}
          setQuestionResponses={setQuestionResponses}
          error={error}
        />
        {children}
        {error && <Text variant="error">{error}</Text>}
      </S.CheckboxGroup>
    );
  }

  return null;
};

export default CheckboxGroup;
