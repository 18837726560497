import { css } from "aphrodite/no-important";
import React, { PropsWithChildren, useState } from "react";
import { joinClassNames } from "themes/helpers";
import {
  CreateStyleSheet,
  StyleSheetDefinitions,
  useThemedStyleSheet,
} from "util/hooks/useThemedStyleSheet";
import { Progress } from "./Progress";
import {
  alwaysShownLabelStyles,
  ButtonStyle,
  CircleCheckmarkStyle,
  genericLabelStyles,
  hiddenCircleCheckmarkStyles,
  hiddenProgressStyles,
  LabelStyle,
  linkButtonStyles,
  primaryButtonStyles,
  primaryCircleCheckmarkStyles,
  primaryProgressStyles,
  ProgressStyle,
  removeLinkButtonStyles,
  secondaryButtonStyles,
  secondaryCircleCheckmarkStyles,
  secondaryProgressStyles,
  tertiaryButtonStyles,
  tertiaryCircleCheckmarkStyles,
  tertiaryProgressStyles,
} from "./styles";

export type ButtonLoadingState = "Checked" | "Completed" | "Loading" | "None";

export interface ButtonProps {
  buttonStyle?: CreateStyleSheet<ButtonStyle>;
  children: string | React.ReactNode;
  className?: string;
  completedIconStyle?: CreateStyleSheet<CircleCheckmarkStyle>;
  disabled?: boolean;
  labelStyle?: CreateStyleSheet<LabelStyle>;
  loading?: boolean;
  loadingState?: ButtonLoadingState;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  progressStyle?: CreateStyleSheet<ProgressStyle>;
  buttonRef?: React.Ref<HTMLButtonElement>;
  type?: "submit" | "reset";
}

const getStateButtonStyles = (
  styles: StyleSheetDefinitions<ButtonStyle>,
  disabled?: boolean,
  loadingState?: ButtonLoadingState
) => {
  if (disabled) {
    return styles.buttonDisabled;
  } else if (loadingState) {
    switch (loadingState) {
      case "Checked":
        return styles.buttonChecked;
      case "Completed":
        return styles.buttonCompleted;
      case "Loading":
        return styles.buttonLoading;
      case "None":
      default:
        return styles.buttonEnabled;
    }
  } else {
    return styles.buttonEnabled;
  }
};

const getStateLabelStyles = (
  styles: StyleSheetDefinitions<LabelStyle>,
  disabled?: boolean,
  loadingState?: ButtonLoadingState
) => {
  if (disabled) {
    return styles.labelDisabled;
  } else if (loadingState) {
    switch (loadingState) {
      case "Checked":
        return styles.labelChecked;
      case "Completed":
        return styles.labelCompleted;
      case "Loading":
        return styles.labelLoading;
      case "None":
      default:
        return styles.labelEnabled;
    }
  } else {
    return styles.labelEnabled;
  }
};

/**
 * Common "Filled" Button component
 */
export const Button = ({
  children,
  className,
  buttonStyle = primaryButtonStyles,
  completedIconStyle = primaryCircleCheckmarkStyles,
  labelStyle = genericLabelStyles,
  progressStyle = primaryProgressStyles,
  onClick,
  disabled,
  loading,
  loadingState,
  buttonRef,
  type,
}: ButtonProps) => {
  const [isMouseFocused, setIsMouseFocused] = useState(false);
  const styles = useThemedStyleSheet(buttonStyle);

  const handleBlur = () => {
    setIsMouseFocused(false);
  };

  const handleMouseDown = () => {
    setIsMouseFocused(true);
  };

  if (loading) {
    loadingState = "Loading";
  }

  const styleClassName = css(
    styles.button,
    getStateButtonStyles(styles, disabled, loadingState),
    !isMouseFocused && styles.buttonWithoutMouseFocus
  );

  return (
    <button
      className={joinClassNames(styleClassName, className)}
      disabled={(!!loadingState && loadingState !== "None") || disabled}
      onBlur={handleBlur}
      onClick={onClick}
      onMouseDown={handleMouseDown}
      ref={buttonRef}
      type={type || "button"}
    >
      <div className={css(styles.retainer)}>
        <StartContent
          createCompletedIconStyle={completedIconStyle}
          createProgressStyle={progressStyle}
          disabled={disabled}
          loadingState={loadingState}
        />
        <Label
          createLabelStyle={labelStyle}
          disabled={disabled}
          loadingState={loadingState}
        >
          {children}
        </Label>
      </div>
    </button>
  );
};

interface LabelProps {
  createLabelStyle?: CreateStyleSheet<LabelStyle>;
  disabled?: boolean;
  loadingState?: ButtonLoadingState;
}

const Label = ({
  children,
  createLabelStyle = genericLabelStyles,
  disabled,
  loadingState,
}: PropsWithChildren<LabelProps>) => {
  const styles = useThemedStyleSheet(createLabelStyle);
  return (
    <span
      className={css(
        styles.label,
        getStateLabelStyles(styles, disabled, loadingState)
      )}
    >
      {children}
    </span>
  );
};

interface StartContentProps {
  createCompletedIconStyle: CreateStyleSheet<CircleCheckmarkStyle>;
  createProgressStyle: CreateStyleSheet<ProgressStyle>;
  disabled?: boolean;
  loadingState?: ButtonLoadingState;
}

const StartContent = ({
  createCompletedIconStyle,
  createProgressStyle,
  disabled,
  loadingState,
}: StartContentProps) => {
  if (disabled) {
    return null;
  }
  switch (loadingState) {
    case "Checked":
      return (
        <CircleCheckmark
          createCompletedIconStyle={createCompletedIconStyle}
          isBeforeLabel={false}
        />
      );
    case "Completed":
      return (
        <CircleCheckmark
          createCompletedIconStyle={createCompletedIconStyle}
          isBeforeLabel={true}
        />
      );
    case "Loading":
      return <Progress createStyles={createProgressStyle} />;
    case "None":
    default:
      return null;
  }
};

interface CircleCheckmarkProps {
  createCompletedIconStyle?: CreateStyleSheet<CircleCheckmarkStyle>;
  isBeforeLabel: boolean;
}

export const CircleCheckmark = ({
  createCompletedIconStyle = primaryCircleCheckmarkStyles,
  isBeforeLabel = false,
}: CircleCheckmarkProps) => {
  const styles = useThemedStyleSheet(createCompletedIconStyle);
  return (
    <div
      className={css(
        styles.iconCircle,
        isBeforeLabel && styles.iconCircleBeforeLabel
      )}
    >
      <span
        className={`icon icon-icons8-checkmark ${css(styles.circledIcon)}`}
      ></span>
    </div>
  );
};

/**
 * A button styled to look like a link.
 */
export const LinkButton: React.FC<ButtonProps> = (props) => (
  <Button
    buttonStyle={linkButtonStyles}
    completedIconStyle={hiddenCircleCheckmarkStyles}
    labelStyle={alwaysShownLabelStyles}
    progressStyle={hiddenProgressStyles}
    {...props}
  />
);

export const RemoveLinkButton: React.FC<ButtonProps> = (props) => (
  <Button
    buttonStyle={removeLinkButtonStyles}
    {...props}
  />
);

export const SecondaryButton: React.FC<ButtonProps> = (props) => (
  <Button
    buttonStyle={secondaryButtonStyles}
    completedIconStyle={secondaryCircleCheckmarkStyles}
    progressStyle={secondaryProgressStyles}
    {...props}
  />
);

export const TertiaryButton: React.FC<ButtonProps> = (props) => (
  <Button
    buttonStyle={tertiaryButtonStyles}
    completedIconStyle={tertiaryCircleCheckmarkStyles}
    progressStyle={tertiaryProgressStyles}
    {...props}
  />
);
