import { CSSProperties, useContext } from "react";
import { StyleSheet } from "aphrodite/no-important";
import { ThemeContext } from "styled-components";

import { remCalc } from "themes/helpers";

export default function(): { [key: string]: CSSProperties } {
  const theme = useContext(ThemeContext);
  return StyleSheet.create({
    switchWrapper: {
      display: "flex",
    },

    input: {
      position: "absolute",
      height: 1,
      width: 1,
      border: 0,
      clip: "rect(0, 0, 0, 0)",
      ":checked + label": {
        backgroundColor: theme.colors.activeFill,
        borderColor: theme.colors.primary,
        color: theme.colors.primary,
      },
    },

    label: {
      display: "flex",
      alignItems: "center",
      backgroundColor: theme.masterColors.white,
      color: theme.masterColors.darkGrey,
      fontSize: remCalc(12),
      letterSpacing: remCalc(1.07),
      lineHeight: remCalc(16),
      padding: `${remCalc(5)} ${remCalc(9)}`,
      border: `1px solid ${theme.masterColors.darkGrey}`,
      ":hover": {
        cursor: "pointer",
      },
      ":first-of-type": {
        borderRadius: `${remCalc(4)} 0 0 ${remCalc(4)}`,
        // TODO fix this for when more than two options are supplied -JA
        borderRight: 0,
      },
      ":last-of-type": {
        borderRadius: `0 ${remCalc(4)} ${remCalc(4)} 0`,
        // TODO fix this for when more than two options are supplied -JA
        borderLeftColor: theme.colors.primary,
      },
    },

    disabled: {
      color: theme.colors.lightGrey,
    },
  });
}
