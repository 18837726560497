export const GET_PARTICIPANT_TREE_OPTIONS = {
  REQUEST: "GET_PARTICIPANT_TREE_OPTIONS_REQUEST",
  SUCCESS: "GET_PARTICIPANT_TREE_OPTIONS_SUCCESS",
  FAILURE: "GET_PARTICIPANT_TREE_OPTIONS_FAILURE",
};

interface TreeOptionsProps {
  description: string;
  participantCount: number;
  participantTreeFilter: string;
  title: string;
}

export interface ParticipantTreeOptionsArgs {
  hideFilter: boolean;
  hideFilterReason: string;
  options: TreeOptionsProps[];
}

export interface ParticipantTreeOptionsState {
  data: ParticipantTreeOptionsArgs;
  loading: boolean;
  error: boolean;
}

export interface ParticipantTreeOptionsActions {
  type:
    | typeof GET_PARTICIPANT_TREE_OPTIONS.REQUEST
    | typeof GET_PARTICIPANT_TREE_OPTIONS.SUCCESS
    | typeof GET_PARTICIPANT_TREE_OPTIONS.FAILURE;
  response: ParticipantTreeOptionsArgs;
}
