import React from "react";
import styled from "styled-components";

import FormControlBase, {
  FormControlProps,
} from "@material-ui/core/FormControl";
import InputLabelBase, { InputLabelProps } from "@material-ui/core/InputLabel";
import MenuItemBase from "@material-ui/core/MenuItem";
import OutlinedInputBase, {
  OutlinedInputProps as OutlinedInputPropsBase,
} from "@material-ui/core/OutlinedInput";
import SelectBase, { SelectProps } from "@material-ui/core/Select";
import CommentBase from "components/forms/Comment";

import TextBase from "components/common/Text";
import { remCalc } from "themes/helpers";
import mediaQueries from "themes/mediaQueries";

const SelectInput = styled.div`
  display: flex;
  flex-direction: column;
`;

const FormControl = styled<React.FC<FormControlProps>>(({ ...otherProps }) => (
  <FormControlBase
    {...otherProps}
    classes={{
      root: "root",
    }}
  />
))`
  &&.root {
  }
`;

const InputLabel = styled<React.FC<InputLabelProps>>(({ ...otherProps }) => (
  <InputLabelBase {...otherProps} classes={{ root: "root" }} />
))`
  &&.root {
    color: ${({ theme }) => theme.colors.formFieldLabel};
    margin-bottom: 8px;
    font-size: 1.25rem;
    font-weight: 400;
  }
`;

const MenuItem = styled((props) => (
  <MenuItemBase
    {...props}
    classes={{
      root: "root",
      selected: "selected",
    }}
  />
))`
  &&.root {
    color: ${({ theme }) => theme.colors.darkGrey};
  }

  &&.selected {
    background: transparent;
  }

  &&.root:hover,
  &&.Mui-focusVisible {
    ${({ theme }) => {
      return `
        background-color: ${theme.colors.activeFill};
        color: ${theme.colors.black};
      `;
    }}
  }
`;

const Select = styled<React.FC<SelectProps>>(({ ...otherProps }) => (
  <SelectBase
    {...otherProps}
    classes={{
      select: "select",
      icon: "icon",
    }}
  />
))`
  && .select {
    color: ${({ theme }) => theme.colors.formFieldColor};
    height: ${remCalc(56)};

    padding: 0;
    padding-left: ${remCalc(16)};
    display: flex;
    justify-content: flex-start;
    align-items: center;

    @media (${mediaQueries.maxTablet}) {
      height: ${remCalc(84)};
    }

    @media (${mediaQueries.maxPhone}) {
      height: ${remCalc(56)};
    }
  }

  && .select:focus {
    background-color: transparent;
  }

  && .notchedOutline {
    border-color: ${({ theme }) => theme.colors.formFieldBorder};
  }
  
  && .icon {
    color: ${({ theme }) => theme.colors.formFieldColor};
    margin-right: ${remCalc(16)};
  }
`;

interface SelectValueProps {
  disabled?: boolean;
  renderingPlaceholder: boolean;
}

export const SelectValue = styled(TextBase)<SelectValueProps>`
  font-size: ${remCalc(16)};

  ${({ disabled, renderingPlaceholder, theme }) => {
    if (disabled || renderingPlaceholder) {
      return `
        color: ${theme.masterColors.darkGreyBlue};
      `;
    }
  }}

  @media (${mediaQueries.maxTablet}) {
    font-size: ${remCalc(24)};
  }

  @media (${mediaQueries.maxPhone}) {
    font-size: ${remCalc(16)};
  }
`;

// @NOTE when changing the height of the input element used with the Select field,
// you'll need to change the height for both of the following selectors:
// 1. `.input`
// 2. `.notchedOutline`
// they are different elements but both are currently used

interface OutlinedInputProps extends OutlinedInputPropsBase {
  isFocusedByMouse: boolean;
}

const OutlinedInput = styled<React.FC<OutlinedInputProps>>(
  ({ isFocusedByMouse, ...otherProps }) => (
    <OutlinedInputBase
      {...otherProps}
      classes={{
        disabled: "disabled",
        root: "root",
        notchedOutline: "notchedOutline",
        focused: "focused",
        error: "error",
        input: "input",
      }}
    />
  )
)`
  &&.root {
    background: ${({ theme }) => theme.colors.formFieldBackground};
    color: ${({ theme }) => theme.colors.formFieldColor};
    display: border-box;
    padding: ${remCalc(0)} ${remCalc(16)} ${remCalc(0)} ${remCalc(0)};
  }

  && .input {
    height: ${remCalc(56)};

    @media (${mediaQueries.maxTablet}) {
      height: ${remCalc(84)};
    }

    @media (${mediaQueries.maxPhone}) {
      height: ${remCalc(56)};
    }
  }

  &&.disabled {
    background-color: ${({ theme }) => theme.colors.lightGrey};
  }

  &&.disabled .notchedOutline {
    border-color: ${({ theme }) => theme.colors.darkGrey};
  }

  &&.focused .notchedOutline {
    border-width: 1px;
    ${({ isFocusedByMouse, theme }) => {
      if (isFocusedByMouse) {
        return `
          border-color: ${theme.colors.primary};
          box-shadow: 0 0 0 ${remCalc(1)} ${theme.colors.primary};
        `;
      } else {
        return `
          border-color: ${theme.colors.focusIndicatorOutline};
          box-shadow: 0 0 0 ${remCalc(3)} ${theme.colors.focusIndicatorOutline};
        `;
      }
    }}
  }

  &&.notchedOutline {
    border-color: ${({ theme }) => theme.masterColors.darkGrey};
    border-width: 1px;
    height: ${remCalc(54)};

    @media (${mediaQueries.maxTablet}) {
      height: ${remCalc(84)};
    }

    @media (${mediaQueries.maxPhone}) {
      height: ${remCalc(54)};
    }
  }

  &&.error {
    background-color: ${({ theme }) => theme.colors.errorFill};
  }

  &&.error:not(.focused) .notchedOutline {
    border-color: ${({ theme }) => theme.colors.error};
  }
`;

const HelperText = styled.span<{ hasValue?: boolean }>`
  display: block;
  color: ${({ theme, hasValue }) =>
    hasValue ? theme.masterColors.darkGrey : theme.colors.assitiveText};
  font-size: ${remCalc(16)};
  margin: ${remCalc(3)} ${remCalc(5)} 0 ${remCalc(16)};
  font-family: "Roboto";
`;

const ErrorText = styled.span`
  display: block;
  color: ${({ theme }) => theme.colors.error};
  font-size: ${remCalc(16)};
  margin: ${remCalc(3)} ${remCalc(5)} 0 ${remCalc(16)};
  font-family: "Roboto";
`;

const Comment = styled(CommentBase)`
  margin-top: 0.375rem
`;

export {
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  SelectInput,
  ErrorText,
  HelperText,
  Comment
};
