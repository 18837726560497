import { FieldErrorType } from "components/FormController/components/Question";
import Label from "components/forms/Label";
import React, { ChangeEventHandler, FocusEventHandler, useState } from "react";
import * as S from "./styles";

// List of google material icons which is used
// when rendering the end adornment. If the icon
// is in this list, then we will render a MUI
// icon rather than an icomoon asset
const materialIcons = ["Visibility", "VisibilityOff"];

export type InternalVariantType =
  | "base"
  | "MultiInput"
  | "TextArea"
  | "Select"
  | "Participant-Add-Another";

export interface EndAdornmentTypes {
  button?: boolean;
  color?: string; // path to color in theme object (eg. colors.primary)
  handleClick?: () => void;
  icon?: string;
  label?: string | React.ReactNode;
  visible: boolean;
}
function RenderEndAdornment({
  visible,
  label,
  icon,
  button,
  handleClick,
  color,
}: EndAdornmentTypes) {
  const [isMouseFocused, setIsMouseFocused] = useState(false);

  const handleBlur = () => {
    setIsMouseFocused(false);
  };

  const handleMouseDown = () => {
    setIsMouseFocused(true);
  };

  if (visible) {
    if (button) {
      // if `icon` is a specific google material icon
      // we'll render a google material icon
      if (icon && materialIcons.includes(icon)) {
        return (
          <S.EndAdornmentButton
            type="button"
            isMouseFocused={isMouseFocused}
            onBlur={handleBlur}
            onClick={handleClick && handleClick}
            onMouseDown={handleMouseDown}
          >
            {icon === "Visibility" ? (
              <S.VisibilityIcon />
            ) : (
              <S.VisibilityOffIcon />
            )}
          </S.EndAdornmentButton>
        );
      }
      return (
        <S.EndAdornmentButton
          color={color}
          type="button"
          isMouseFocused={isMouseFocused}
          onBlur={handleBlur}
          onClick={handleClick && handleClick}
          onMouseDown={handleMouseDown}
        >
          {label}
        </S.EndAdornmentButton>
      );
    }
    return (
      <S.EndAdornmentIcon className={icon && `icon icon-icons8-${icon}`}>
        {label}
      </S.EndAdornmentIcon>
    );
  }
  return null;
}

interface StartAdornmentTypes {
  visible: boolean;
  label?: string;
  icon?: string;
}

const RenderStartAdornment = ({
  visible,
  label,
  icon,
}: StartAdornmentTypes) => {
  if (visible) {
    return (
      <S.StartAdornmentIcon className={icon && `icon icon-icons8-${icon}`}>
        {label}
      </S.StartAdornmentIcon>
    );
  }
  return null;
};

export interface AssistiveLinkType {
  label: string;
  /**
   * when passed, will render the Assistive Text
   * element as a button. Takes precedence over url
   * and rendering an anchor element
   */
  onClick?: () => void;
  /**
   * when passed, will render an anchor element for
   * Assistive Text. If an `onClick` is passed, we will
   * let the `onClick` and button element take precedence
   */
  url?: string;
  icon?: string;
}

interface TextInputEventHandlerProps {
  onBlur?: FocusEventHandler<HTMLInputElement>;
}

export interface BaseTextInputProps {
  autoComplete?: React.InputHTMLAttributes<HTMLInputElement>["autoComplete"];
  assistiveLink?: AssistiveLinkType | null;
  className?: string;
  disabled?: boolean;
  endAdornment?: EndAdornmentTypes | null;
  error?: FieldErrorType;
  fullWidth?: boolean;
  preElements?: React.ReactNode;
  /**
   * `number` used for height of input field
   */
  height?: number;
  /**
   * just like `height` but only used for mobile. If not present,
   * we'll use `height`
   */
  mobileHeight?: number;
  helperText?: string;
  id?: string | number;
  label?: string;
  name: string | number;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  onFocus?: FocusEventHandler<HTMLInputElement>;
  placeholder?: string;
  rounded?: boolean;
  smallLabel?: boolean;
  startAdornment?: StartAdornmentTypes | null;
  success?: boolean;
  touched?: boolean;
  type?: string;
  value?: string | number | null;
  variant?: string;
  /**
   * when `true`, renders label within MUI Input component
   * when `false`, uses separate element to render label, above Input component
   */
  inlineLabel?: boolean;
  hideErrorText?: boolean;

  // ? USED TO APPLY CONDITIONS BASED ON EXTENDED COMPONENTS
  // ?  ie: if we use TextInput in MultiInput then we want
  // ? certain things to happen
  /**
   * this should only be set by components which use **TextInput**
   */
  internalVariant: InternalVariantType;

  // ? USED WITH TEXT AREA
  multiline?: boolean;
  maxRowsVisible?: number;
  initialRowsVisible?: number;

  // ? USED WITH PASSWORD FIELDS
  showPassword?: {
    enabled: boolean;
    show: boolean;
  };
  required?: boolean;
}

export type TextInputProps = BaseTextInputProps & TextInputEventHandlerProps;

/**
 * This is the version of **TextInput** which uses
 * `styled-components` and is the most up to date
 * version of the component
 */
const TextInput = ({
  autoComplete,
  assistiveLink,
  className,
  disabled,
  endAdornment,
  error,
  fullWidth,
  helperText,
  hideErrorText,
  id,
  initialRowsVisible,
  inlineLabel,
  internalVariant,
  label,
  maxRowsVisible,
  multiline,
  name,
  onBlur,
  onChange,
  onFocus,
  placeholder,
  preElements,
  required,
  rounded,
  startAdornment,
  success,
  type,
  value,
}: TextInputProps) => {
  const [isActive, setIsActive] = useState(false);

  const handleMouseDown = () => {
    setIsActive(true);
  };

  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    if (onBlur) {
      onBlur(event);
    }
    setIsActive(false);
  };

  return (
    <>
      <S.TextInput
        className={className}
        disabled={disabled}
        fullWidth={fullWidth}
      >
        {label && (
          <Label
            htmlFor={typeof id === "string" ? id : `${id}`}
            assistiveLink={assistiveLink}
            required={required}
          >
            {label}
          </Label>
        )}
        {helperText && (
          <div style={{ paddingBottom: 10, marginLeft: -15 }}>
            <S.HelperText hasValue={!!value}>{helperText}</S.HelperText>
          </div>
        )}
        <S.Row variant="default">
          {preElements && <S.Row variant="map-pin">{preElements}</S.Row>}

          <S.Row variant="field">
            <S.TextField
              autoComplete={autoComplete} // This is camelCase for MUI -- GK
              disabled={disabled}
              error={!!error}
              fullWidth={fullWidth}
              id={id ? id.toString() : undefined}
              internalVariant={internalVariant}
              isActive={isActive}
              // @NOTE: Need to explicitly pass undefined
              // to handle a bug where mui still tries to
              // render a label in-line. If a boolean
              // is passed, it will render with a break
              // in the border. - Trevor Kirpaul
              label={inlineLabel ? label : undefined}
              multiline={multiline}
              name={`${name}`}
              onBlur={handleBlur}
              onChange={onChange}
              onFocus={onFocus}
              onMouseDown={handleMouseDown}
              placeholder={placeholder}
              renderedStartAdornment={
                !!startAdornment && startAdornment.visible
              }
              rounded={rounded}
              rows={initialRowsVisible}
              rowsMax={maxRowsVisible}
              success={success}
              type={type}
              value={value}
              variant="outlined"
              InputProps={{
                startAdornment: startAdornment && (
                  <S.InputAdornment position="start">
                    <RenderStartAdornment {...startAdornment} />
                  </S.InputAdornment>
                ),
                endAdornment: endAdornment && (
                  <S.InputAdornment position="end">
                    <RenderEndAdornment
                      button={endAdornment.button}
                      color={endAdornment.color}
                      handleClick={endAdornment.handleClick}
                      icon={endAdornment.icon}
                      label={endAdornment.label}
                      visible={endAdornment.visible}
                    />
                  </S.InputAdornment>
                ),
              }}
            />
            {error && !hideErrorText && <S.ErrorText>{error}</S.ErrorText>}
          </S.Row>
        </S.Row>
      </S.TextInput>
    </>
  );
};

TextInput.defaultProps = {
  variant: "standard",
  internalVariant: "base",
  fullWidth: false,
  disabled: false,
  mobileHeight: 56,
  height: 56,
  multiline: false,
};

export default TextInput;
