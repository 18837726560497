import * as FS from "@fullstory/browser";

import { getApiHost } from "../components/helpers";
import { sanitizeAPIError } from "store/helpers";

require("es6-promise").polyfill();
require("fetch-everywhere");


const apiRoot = `${getApiHost()}/api/`;

export const envApiRoot = apiRoot;

/* Used as a unique identifier for callAPI configuration contained in action */
export const CALL_API = Symbol("Call API");

/**
 * Configure and initialize API request (if specified), else pass action to next middleware
 * @returns {function(*=): Function}
 */
export default () => (next) => (action) => {
  // retrieve API call params from action
  const callAPI = action[CALL_API];

  // if no API call is specified, pass the action to the next middleware
  if (typeof callAPI === "undefined") {
    return next(action);
  }

  // else, configure call options
  if (callAPI && callAPI.options) {
    callAPI.options = { ...callAPI.options, mode: "cors" };
  }

  const { endpoint } = callAPI;
  const { types, options } = callAPI;

  // then check for proper configuration
  if (typeof endpoint !== "string") {
    throw new Error("Specify a string endpoint URL.");
  }
  if (!Array.isArray(types) || types.length !== 3) {
    throw new Error("Expected an array of three action types.");
  }
  if (!types.every((type) => typeof type === "string")) {
    throw new Error("Expected action types to be strings.");
  }

  /**
   * Attach response data to action and strip CALL_API config
   * @param data
   * @returns action
   */
  const actionWith = (data) => {
    const finalAction = { ...action, ...data };
    delete finalAction[CALL_API];
    return finalAction;
  };
  const [requestType, successType, failureType] = types;
  // pass action with request type to next middleware
  next(actionWith({ type: requestType }));

  // make an API call with configured options and handle result
  return callApi(endpoint, options).then(
    (response) =>
      // success: pass response to next middleware with success type
      next(
        actionWith({
          response,
          type: successType,
        }),
      ),
    (error) => {
      // failure: pass error to next middleware with failure type
      FS.event("API Error", sanitizeAPIError({
        body: options.body,
        env: process.env.NODE_ENV,
        headers: {
          "Accept": options.headers?.Accept,
          "Authorization": options.headers?.Authorization,
          "Content-Type": options.headers?.["Content-Type"],
        },
        method: options.method,
        mode: options.mode,
        state: error && JSON.stringify(error) || undefined,
      }));
      
      return next(
        actionWith({
          fullError: error,
          type: failureType,
          status: error.status,
          error: error.detail || error.message || "Uncaught",
        }),
      );
    }
    
      
      
  );
};

/**
 * Call API endpoint with configured options
 * @param endpoint
 * @param options
 * @returns {Promise<T>}
 */
export const callApi = (endpoint, options) => {
  let url = apiRoot + endpoint;
  if (endpoint.indexOf("http") === 0) url = endpoint;

  // determine if we'll use the mock API
  // if we, we'll use the unmodified endpoint
  // passed from the invoked method
  const URL = options.useMock ? endpoint : url;

  return fetch(URL, options).then((response) => {
    const blobTypes = ["text/csv", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"];

    // JSON response expected
    if (options.headers) {
      if (options.headers.Accept === "application/json") {
        return response.json().then(
          (json) => (response.ok ? json : Promise.reject({ ...json, ...{ status: response.status } })),
          (error) => Promise.reject({ message: error, status: response.status }),
        );
      } else if (blobTypes.indexOf(options.headers.Accept) >= 0) {
        // return blob
        return response.ok
          ? response.blob().then(
            (blob) => blob,
            (error) => response,
          )
          : response.blob().then(
            (blob) => Promise.reject(blob),
            (error) => Promise.reject(response),
          );
      }
    }

    return response.ok
      ? response.text().then(
        (text) => text,
        (error) => response,
      )
      : response.text().then(
        (text) => Promise.reject(text),
        (error) => Promise.reject(response),
      );
  });
};
