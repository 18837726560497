import { useFormikContext } from "formik";

import useDebouncedValue from "util/hooks/useDebouncedValue";
import usePrevious from "util/hooks/usePrevious";

import { FBForm } from "../types";
import { isEqual } from "lodash";
import { FormDTO } from "store/forms/types";
import { useAsyncEffect } from "@rtslabs/field1st-fe-common";

interface Props {
  onSave: (values: FBForm) => Promise<FormDTO | undefined>;
  /** The values for which we want to trigger an auto-save */
  listeners: Partial<FBForm>;
}

const AutoSaveForm = ({ onSave, listeners }: Props): null => {
  const { values, isSubmitting, setSubmitting } = useFormikContext<FBForm>();
  /**
   * AutoSave the form
   *
   * Note: we don't want to unmarshall the form on every autosave, so if a
   * response is ever meant to be updated by the backend, we should explicitly
   * call handleSubmit for that case
   *
   * @param form
   */
  async function autoSave(form: FBForm) {
    setSubmitting(true);
    await onSave(form);
    setSubmitting(false);
  }

  const debouncedValues = useDebouncedValue(listeners, 600);
  const previousValues = usePrevious(debouncedValues);

  useAsyncEffect(async () => {
    const hasDiff = !isEqual(debouncedValues, previousValues);
    // don't attempt submit if we're already submitting or on initial load
    if (
      values.workflowType !== "FINAL" &&
      values.workflowType !== "DEACTIVATED" &&
      previousValues &&
      hasDiff &&
      !isSubmitting
    ) {
      await autoSave(values);
    }
  }, [debouncedValues]);

  return null;
};

export default AutoSaveForm;
