import React from "react";

import FeedbackForm from "../components/FeedbackForm";
import { ResponsiveContentWrapper } from "components/common/Wrappers";

import * as S from "../styles";

const Feedback = () => {
  return (
    <ResponsiveContentWrapper>
      <S.Title>Send Feedback</S.Title>
      <S.Subtitle>
        Your input is important for improving the app. <br />
        <br />
        <b>Technical Issues:</b> Call the DE HelpDesk or submit a ServiceNow
        ticket. <br />
        <b>Safety Tool Feature Requests:</b> Contact your DE IT liaison to
        submit a CWR. <br />
        <b>Form Enhancements/New Form Requests:</b> Use options below to send
        feedback to the DE Business Form Architect group.
      </S.Subtitle>

      <FeedbackForm
        submitFeedbackVisible={true}
        toggleSubmitFeedbackVisibility={() => console.log("")}
      />
    </ResponsiveContentWrapper>
  );
};

export default Feedback;
