import styled from "styled-components";

import { remCalc } from "themes/helpers";

export const Wrapper = styled.div`
  align-items: center;
  background: ${({ theme }) => theme.masterColors.lightWhite};
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 5px 12px;
`;

export const SummaryText = styled.div`
  color: ${({ theme }) => theme.masterColors.darkGrey};
  font-size: ${remCalc(14)};
  font-style: italic;
  font-weight: 300;
  letter-spacing: 0.43px;
  padding: ${remCalc(14)} 0;
`;

export const SummaryExports = styled.div`
  align-items: center;
  color: ${({ theme }) => theme.colors.primary};
  cursor: pointer;
  display: flex;
  font-size: ${remCalc(25)};
`;
