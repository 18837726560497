import React, { useState, useCallback } from "react";
import moment from "moment";
import { Action } from "redux";
import { AppState } from "store";
import { ThunkDispatch } from "redux-thunk";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";

// Common
import Modal from "components/common/Modal";
import NoResults from "components/common/TableUI/NoResults";
import TableSummary from "components/common/TableUI/TableSummary";
import { PaginationParams } from "store/common/types";
import { Table, TableBody, TableCell, TableRow } from "components/common/styled/Table";
import { TagDTO, UpdateTagBody } from "store/tagLibrary/types";
import { UPDATE_TAG } from "store/tagLibrary/types";
import { getExportUrl } from "components/clientAdmin/helpers";
import { updateTag } from "store/tagLibrary/actions";

// Resources
import TableHeader from "components/clientAdmin/resources/TableHeader";

// Local
import TagActionMenu from "./TagActionMenu";
import { TagStatusModal, TagStatusModalProps } from "./TagStatusModal";
import ReadOnlyContent from "components/common/permissions/ReadOnlyContent";


const emptyTagStatusModalState = {
  status: null,
  tag: null,
  visible: false,
};


interface Props {
  onFetch: () => void;
  onSort: (sort: string) => void;
  params: PaginationParams;
  tags: TagDTO[];
  totalElements: number;
};

export const TagLibraryTable = ({ onFetch, onSort, params, tags = [], totalElements }: Props) => {

  const history = useHistory();

  const dispatch = useDispatch<ThunkDispatch<AppState, void, Action>>();

  const [tagStatusModal, setTagStatusModal] = useState<{
    status: TagStatusModalProps["status"];
    tag: TagStatusModalProps["tag"];
    visible: boolean;
  }>({
    status: null,
    tag: null,
    visible: false,
  });

  // Update tag's status, we aren't changing the name -- Gk
  const onTagStatusUpdate = async () => {

    // Set loading state
    setTagStatusModal({
      ...tagStatusModal,
      status: "request",
    });

    // API request
    const payload: UpdateTagBody = {
      archived: !tagStatusModal.tag?.archived,
      id: tagStatusModal.tag?.id || 0,
    };
    const res = await dispatch(updateTag(payload));

    // On success
    if (res.type === UPDATE_TAG.SUCCESS) {
      setTagStatusModal({
        ...tagStatusModal,
        status: "success"
      });
      onFetch(); // Fetch table with current filters
    }
    // On failure
    else {
      setTagStatusModal({
        ...tagStatusModal,
        status: "failure"
      });
    }

  };

  // Makes tag.archived label user-friendly
  const prettifyStatus = useCallback((archived?: boolean) => archived ? "Archived" : "Active", []);

  return <>
    <Modal
      border="none"
      open={tagStatusModal.visible}
      handleClose={() => {
        setTagStatusModal({
          ...emptyTagStatusModalState,
          visible: false,
        });
      }}
      height="auto"
      width="652px"
      padding="24px 23px"
      content={<TagStatusModal
        onCancel={() => {
          setTagStatusModal({
            ...emptyTagStatusModalState,
            visible: false,
          });
        }}
        onSubmit={onTagStatusUpdate}
        status={tagStatusModal.status}
        tag={tagStatusModal.tag}
      />}
    />
    <TableSummary
      pageSize={params.size}
      currentPage={params.page}
      totalElements={totalElements}
      ofWhat="tags"
      exports={[
        {
          type: "print",
        },
        {
          type: "xls",
          exportUrl: getExportUrl("tag-libraries", params.query, `sort=${params.sort}`),
        },
        {
          type: "csv",
          exportUrl: getExportUrl("tag-libraries", params.query, `sort=${params.sort}`),
        },
      ]}
    />
    <Table>
      <TableHeader
        onSort={onSort}
        currentSort={params.sort}
        columns={[
          { id: "name", label: "tag" },
          { id: "useCount", label: "uses" },
          { id: "createdDate", label: "date added" },
          { id: "archived", label: "Status" },
        ]}
      />
      <TableBody>
        {tags.length > 0 ? (
          tags.map((tag, i) => (
            <TableRow key={i}>
              <TableCell width="350px">{tag.tagIdentifier}</TableCell>
              <TableCell width="100px">{tag.useCount}</TableCell>
              <TableCell width="150px">{moment(tag.createdDate).format("MM/DD/YYYY")}</TableCell>
              <TableCell width="100px">{prettifyStatus(tag.archived)}</TableCell>
              <TableCell width="22px">
                {/* None of the menu items are (currently) visible to Client Reviewer -- GK */}
                <ReadOnlyContent>
                  <TagActionMenu
                    tag={tag}
                    onTagStatusUpdate={(tag) => {
                      setTagStatusModal({
                        ...tagStatusModal,
                        tag,
                        visible: true,
                      });
                    }}
                  />
                </ReadOnlyContent>

              </TableCell>
            </TableRow>
          ))
        ) : (
            <NoResults
              icon="document"
              header="No tags were found."
              body="Create a new tag to match your needs."
              button={{
                children: "Create a tag",
                onClick: () => history.push("/content/tag-library/add-tag"),
              }}
            />
          )}
      </TableBody>
    </Table>
  </>;

};
