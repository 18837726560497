import styled from "styled-components";

import Text from "components/common/Text";
import { remCalc } from "themes/helpers";

// --
// ? UserMenu
// --

export const UserMenu = styled.div`
  position: relative;
  color: ${(props) => props.theme.masterColors.mediumGrey};
  font-size: ${remCalc(14)};
  font-weight: 700;
`;

export const UserMenuInitial = styled(Text)<{ inMenu?: boolean }>`
  border-radius: 50%;
  width: ${remCalc(50)};
  height: ${remCalc(50)};
  border: 1px solid ${(props) => props.theme.masterColors.lightGrey};
  padding: 9px;
  margin-right: 8px;

  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.masterColors.darkGrey};

  font-size: 16px;
  font-weight: 600;

  /*   
    This element is used in 2 locations which each require
    a different font-size. We'll use this boolean prop
    to determine the font-size
  */
  ${({ inMenu }) => {
    if (inMenu) {
      return `
        font-size: 24px;
      `;
    }
  }}
`;

export const Label = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const MenuChildren = styled.div`
  /* padding: 16px 12px; */
  /* min-width: 220px; */
`;

export const LabelText = styled(Text)`
  padding: 0;
  margin: 0;
  font-size: ${remCalc(14)};
`;

export const SignOutText = styled(LabelText)`
  color: ${({ theme }) => theme.colors.error};
`;

// ---
// ? MenuHeader
// ---

export const MenuHeader = styled.div`
  display: flex;
  margin-bottom: 34px;
  padding: 16px 12px 0px 12px;
`;

export const Col = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Username = styled(Text)`
  margin: 0;
  padding: 0;
`;

export const LinkText = styled(Text)`
  color: ${({ theme }) => theme.colors.primary};
`;
