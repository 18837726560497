import React from "react";
import * as S from "./styles";

export interface Props {
  as: React.ElementType;
  children: React.ReactNode;
  className?: string;
  variant: "default" | "section-title";
}

/**
 * **Header** is a reusable text component meant to render Heading elements.
 * You can designate which HTML element is used via @as (defaults to `h1`).
 *
 * @variant can also be leveraged for common use cases
 */
const Header = ({ as, children, className, variant }: Props) => {
  return (
    <S.Header as={as} className={className} variant={variant}>
      {children}
    </S.Header>
  );
};

Header.defaultProps = {
  as: "h1",
  variant: "default",
};

export default Header;
