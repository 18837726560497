import React from "react";
import { css } from "aphrodite/no-important";
import { useFormikContext, Field } from "formik";
import { get } from "lodash";

import Checkbox from "components/common/form/Checkbox";

import propertiesStyles from "../styles";
import {
  FBForm,
  Property,
  SignatureType,
} from "components/clientAdmin/formBuilder/types";
import { isFormPublished } from "components/clientAdmin/formBuilder/helpers";

interface Props {
  property: Property;
  itemPath: string;
}

interface SignatureSelection {
  title: string;
  value: SignatureType;
}

const signatureOptions: SignatureSelection[] = [
  { title: "Anything", value: "TYPED_ANYTHING" },
  { title: "Names", value: "TYPED_NAME" },
  { title: "Emails", value: "TYPED_EMAIL" },
];

const AllowedSignatureTypes = ({ property, itemPath }: Props) => {
  const { values, setFieldValue } = useFormikContext<FBForm>();
  const allowedSigs = get(values, `${itemPath}.${property.name}`) || [];
  const ps = propertiesStyles();

  function onChange(
    e: React.ChangeEvent<HTMLInputElement>,
    sel: SignatureSelection
  ) {
    const val = e.target.checked
      ? [...allowedSigs, sel.value]
      : allowedSigs.filter((sig: string) => sig !== sel.value);
    setFieldValue(`${itemPath}.${property.name}`, val);
  }
  return (
    <div>
      <p className={css(ps.label)}>{property.label}</p>
      {signatureOptions.map((sel) => (
        <div key={sel.title}>
          <Field
            as={Checkbox}
            className={css([ps.checkbox, ps.noVertPadding])}
            name={`${itemPath}.${property.name}.${sel.title}`}
            label={sel.title}
            checked={allowedSigs.includes(sel.value)}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              onChange(e, sel)
            }
            disabled={isFormPublished(values.workflowType)}
          />
        </div>
      ))}
    </div>
  );
};

export default AllowedSignatureTypes;
