import { combineReducers } from "redux";
import { dataSetsReducer } from "./dataSets/reducer";
import { documentsFiltersReducer } from "./documents/reducer";
import { formTemplatesReducer } from "./formTemplates/reducer";
import { groupsReducer } from "./groups/reducer";
import { reportsFiltersReducer } from "./reports/reducer";
import { resourceCategoriesReducer } from "./resourceCategories/reducer";
import { resourcesFiltersReducer } from "./resources/reducer";
import { tagLibraryReducer } from "./tagLibrary/reducer";
import { usersReducer } from "./users/reducer";

export const filtersReducer = combineReducers({
  dataSets: dataSetsReducer,
  documents: documentsFiltersReducer,
  formTemplates: formTemplatesReducer,
  groups: groupsReducer,
  reports: reportsFiltersReducer,
  resourceCategories: resourceCategoriesReducer,
  resources: resourcesFiltersReducer,
  tagLibrary: tagLibraryReducer,
  users: usersReducer,
});
