import styled from "styled-components";

import { Button } from "components/forms/Button";
import { Error as ErrorBase } from "components/common/Error";
import { devices } from "themes/mediaQueries";
import { remCalc } from "themes/helpers";

export const ButtonsWrapper = styled.div`
  margin-top: 0;
  margin-bottom: 1.05rem;
`;

export const Error = styled(ErrorBase)`
  margin-bottom: 1rem;
`;

export const SubmitButton = styled(Button)`
  && {
    border-radius: 4px;
    color: ${({ disabled, theme }) => disabled && theme.colors.primary};
    @media (max-width: ${devices.maxPhone}px) {
      font-size: ${remCalc(14)};
    }
    text-transform: uppercase;
  }

  &&.root {
    background-color: ${({ disabled, theme }) => (disabled ? theme.colors.primary : null)};
    margin-top: 0 !important; // This specific instance in rehuddle has no margin
    height: 56px;
  }
`;

export const CancelButton = styled(Button)`
  && {
    background-color: ${({ theme }) => theme.colors.white};
    @media (max-width: ${devices.maxPhone}px) {
      font-size: ${remCalc(14)};
    }
    text-transform: uppercase;
  }

  &&.root {
    margin-top: 26px; // Rehuddle button has smaller margin
  }
`;
