import { GET_WORK_LOCATION, WorkLocationState } from "./types";
import { flattenWorkLocations } from "./util";

const initialState: WorkLocationState = {
  loading: false,
  error: null,
  locations: [],
  flattenedLocations: [],
};

export function workLocationReducer(state = initialState, action: any) {
  switch (action.type) {
    case GET_WORK_LOCATION.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_WORK_LOCATION.SUCCESS:
      return {
        ...state,
        loading: false,
        locations: action.response,
        flattenedLocations: flattenWorkLocations(action.response),
      };
    case GET_WORK_LOCATION.FAILURE:
      return {
        ...state,
        error: action.response,
      };
    default:
      return state;
  }
}
