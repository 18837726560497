import React from "react";
import styles from "./Error.module.scss";
import { joinClassNames } from "themes/helpers";

interface ErrorProps {
  ariaLive?: "assertive" | "off" | "polite";
  className?: string;
  message?: string;
}

export const Error = ({
  ariaLive = "assertive",
  className,
  message
}: ErrorProps) => {
  return (
    <div
      aria-live={ariaLive}
      className={joinClassNames(styles.error, className)}
      role="alert"
    >
      {message}
    </div>
  );
};
