import qs from "qs";

import { oauthBaseRoute } from "./constants";

export const extractURLParam = (
  location: Location,
  param: string,
  delimiter?: string
): string | undefined => {
  if (!location?.search && !location?.hash) {
    return;
  }
  let queries = {};
  if (delimiter === "#" && location.hash) {
    queries = qs.parse(location.hash, { delimiter: "#" });
  } else if (location.search) {
    queries = qs.parse(location.search, { ignoreQueryPrefix: true });
  }
  return queries?.[param];
};

// Use extractURLParam instead of this one
export function extractToken(location: Location): string | undefined {
  if (!location || !location.search) {
    return;
  }
  // const queries = extractQueryParameters(location.search);
  const queries = qs.parse(location.search, { ignoreQueryPrefix: true }) || {};
  return queries["id_token"];
}

// Use extractURLParam instead of this one
export function extractError(location: Location): string | undefined {
  if (!location || !location.search) {
    return;
  }
  const params = qs.parse(location.search, { ignoreQueryPrefix: true }) || {};
  const errorMessage = params["error"];
  if (errorMessage) {
    return decodeURIComponent(errorMessage);
  }
  return;
}

export const oauthRedirect = (
  provider: string,
  redirectAfterLogin?: string
) => {
  let uri = `${window.location.origin}${window.location.pathname}`;
  if (redirectAfterLogin && !redirectAfterLogin.startsWith("/login")) {
    uri += `?redirectAfterLogin=${encodeURIComponent(redirectAfterLogin)}`;
  }
  const redirectURI = encodeURIComponent(uri);
  window.location.href = `${oauthBaseRoute}/${provider}?redirect_uri=${redirectURI}`;
};
