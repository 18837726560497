import {
  QuestionAnswerSourceDTO,
  SafetyRatingQuestionsDTO,
  FormDTO,
  FormSubmissionConstraintDTO,
  WorkflowType,
} from "store/forms/types";
import { NewSelection, NewFormItem } from "store/builder/types";

export interface FBForm extends Omit<FormDTO, "sections"> {
  sections: FBSection[];
  formSubmissionConstraint?: FormSubmissionConstraintDTO & {
    hourType?: "hours" | "days";
  };
}

export type ItemType = "CONTENT" | "QUESTION" | "WIDGET" | "SECTION";

export type SignatureType =
  | "DRAWN"
  | "TYPED_NAME"
  | "TYPED_EMAIL"
  | "TYPED_ANYTHING";

/** Individual property describing a field or rule for an entity */
export interface Property {
  name: string;
  label: string;
  type: string;
  helperText?: string;
  displayCondition?: string;
  propertyType: "PROPERTY" | "RULE" | "SELECTIONS";
  detailedSearchProps?: Property[];
  options?: { value: string | number; id: string | number | string[] }[];
  appendLabel?: boolean;
  forParent?: boolean; // apply property to parentQuestionRootId (todo make generic)
}

export interface EntityProperties {
  allowEmailSignature?: boolean;
  allowMultipleAnswers?: boolean;
  allowedTypes?: Array<SignatureType>;
  answerField?: string;
  displayField?: string;
  associatedLocationField?: string;
  assistiveText?: string;
  autoComplete?: boolean;
  autoAppendComment?: boolean;
  dataSourceId?: number | null;
  defaultToday?: boolean;
  defaultNow?: boolean;
  detailedSearch?: {
    customAnswerEnabled?: boolean;
    enabled?: boolean;
    infiniteListEnabled?: boolean;
    infiniteListSortBy?: string;
    infiniteListTitle?: string;
    linkName?: string;
    recentAnswersEnabled?: boolean;
    recentAnswersTitle?: string;
    searchBy?: string;
    subtitle?: string;
    title?: string;
  };
  enableComments?: boolean;
  excludeFromSignatures?: boolean;
  filterOutTags?: string[];
  includeMap?: boolean;
  isCloneable?: boolean;
  isRequired?: boolean;
  isSearchable?: boolean;
  pinColor?: string;
  questions?: any[];
  placeHolderText?: string;
  prefillDocumentCreator?: boolean;
  readOnly?: boolean;
  numberRequired?: number;
  voiceToTextEnabled?: boolean;
  location?: {
    enabled: boolean;
    geoLocationEnabled: boolean;
    iconColor: string;
  };
  sourcedLocation?: {
    answerSource?: QuestionAnswerSourceDTO;
    enabled: boolean;
    iconColor: string;
  };
}

/** Base parameters for a builder entity */
interface FBEntity {
  id: number;
  rootId: number;
  // name: string;
  parentID?: number;
  parentWidgetId?: number;
  parentWidgetRootId?: number;
  position: number;
  properties?: EntityProperties;
  subType?: string; // This should probably be typed later -- GK
  title?: string;
  type: ItemType;
  workflowType?: WorkflowType;
}

export interface FBDefenseQuestion {
  questionRootId: number;
  title: string;
  visibility: "show" | "hide";
}

/** Child entity nested within a section or widget */
export interface FBBaseItem extends FBEntity {
  answerSource?: QuestionAnswerSourceDTO;
  formProperties?: {
    isRequired: boolean;
    isSearchable: boolean;
    isCloneable?: boolean;
  };
  // isRehuddleEditable?: boolean;
  parentID: number;
  parentRootID: number;
  type: "QUESTION" | "WIDGET" | "CONTENT";
  subType: string;
}

export interface FBQuestion extends FBBaseItem {
  type: "QUESTION";
  hasDefenses: boolean;
  participantRole?: "SUPERVISOR" | "ATTENDANT";
  selections?: NewSelection[];
  conditionFields?: string[];
}

export interface FBContent extends FBBaseItem {
  type: "CONTENT";
  content: string;
}

export interface FBBanner extends FBContent {
  backgroundColor: string;
  textColor: string;
}

export interface FBBaseWidget extends FBBaseItem {
  type: "WIDGET";
}

export interface RatingsOptions {
  title: string;
  commentRequired: boolean;
}

export interface AreaToReview {
  label: string;
  items: NewFormItem<FBQuestion>[];
  ratingsSelections: RatingsOptions[];
  id: number | null;
  parentSelectionRootId?: number;
}

export interface FBRatingsWidget extends FBBaseWidget {
  areasToReview?: AreaToReview[];
  questions: SafetyRatingQuestionsDTO[];
  subType: "SAFETY_RATING";
  parentQuestionRootId: number;
  displayDescription: boolean;
  requireCommentsFor?: string[];
}

export interface FBDefensesWidget extends FBBaseWidget {
  autoAppendComment: boolean;
  questions: Array<FBDefenseQuestion>;
  subType: "DEFENSES";
}

export interface FBMapWidgetQuestion {
  answerSource: QuestionAnswerSourceDTO;
  iconColor: string;
  id?: number;
  questionRootId: number;
}

export interface FBMapWidget extends FBBaseWidget {
  includeMap: boolean;
  questions: Array<FBMapWidgetQuestion>;
  subType: "MAP";
}

interface FBSignatureWidget extends FBBaseWidget {
  subType: "SIGNATURE";
}

interface FBDocumentCreatorWidget extends FBBaseWidget {
  subType: "DOCUMENT_CREATOR";
}

interface FBSupervisorWidget extends FBBaseWidget {
  subType: "SUPERVISOR";
}

export interface OperationalExperiencesWidgetQuestionDTO {
  answerSource?: QuestionAnswerSourceDTO;
  id?: number;
  questionRootId: number;
  suppressedTags: string[];
}

export interface FBOEWidget extends FBBaseWidget {
  questions: OperationalExperiencesWidgetQuestionDTO[];
  subType: "OPERATIONAL_EXPERIENCES";
  numberRequired: number;
}

export type FBWidget =
  | FBDocumentCreatorWidget
  | FBDefensesWidget
  | FBMapWidget
  | FBOEWidget
  | FBRatingsWidget
  | FBSignatureWidget
  | FBSupervisorWidget;

export type FBItem = FBWidget | FBQuestion | FBContent | FBBanner;

/** Top-level entity with nested items and widgets, sibling to other sections */
export interface FBSection extends FBEntity {
  items: FBItem[];
  type: "SECTION";
  rootId: number;
}

/**
 * Type-guard for Form Builder Map Widgets
 * @param widget
 */
export function isFBMapWidget(widget: FBItem): widget is FBMapWidget {
  return (
    (widget as FBMapWidget).type === "WIDGET" &&
    (widget as FBMapWidget).subType === "MAP"
  );
}
