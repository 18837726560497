import React, { useState } from "react";
import { css } from "aphrodite/no-important";
import { get } from "lodash";
import { useFormikContext } from "formik";

import { DataSource } from "store/dataSource/types";
import { DisplayConditionDTO } from "store/documents/types";
import { FBForm, Property } from "components/clientAdmin/formBuilder/types";
import { Input } from "components/common/form/types";
import {
  QuestionSelectionsDTO,
  QuestionAnswerSourceDTO,
} from "store/forms/types";
import Label from "components/common/form/Label";
import { SuggestionType } from "components/common/form/MultiInput/MultiInput";
import { NewSelection } from "store/builder/types";

import { buildAnswerSource } from "../AnswerSource";
import DataSetBanner from "./DataSetBanner";
import DataSetDrawer from "../../drawers/DataSetDrawer";
import SwitchOptionsTypeModal from "./SwitchOptionsTypeModal";

import propertiesStyles from "../../styles";
import CustomAnswerOptions from "../CustomAnswerOptions";
import FieldErrorMessage from "../../../validation/FieldErrorMessage";
import { isFormPublished } from "components/clientAdmin/formBuilder/helpers";

interface Props extends Input {
  detailedSearchProps?: Property[];
  label: string;
  helperText: string;
  selectedValues: QuestionSelectionsDTO[];
  name: string;
  itemPath: string;
  itemRootId: number;
  onOptionUpdate: (values: NewSelection[]) => Promise<QuestionSelectionsDTO[]>;
}

const AnswerOptions = ({
  label,
  helperText,
  selectedValues,
  name,
  itemPath,
  itemRootId,
  onOptionUpdate,
  detailedSearchProps,
  error,
  touched,
}: Props) => {
  const ps = propertiesStyles();

  const [showDrawer, setShowDrawer] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [modalType, setModalType] = useState<
    "ds" | "options" | "replaceDs" | "removeOption" | undefined
  >(undefined);
  const [modalCallbackValues, setModalCallbackValues] = useState<unknown>();

  const { values, setFieldValue } = useFormikContext<FBForm>();

  const existingSource: QuestionAnswerSourceDTO | null = get(
    values,
    `${itemPath}.answerSource`
  );
  const allDisplayConditions: DisplayConditionDTO[] = get(
    values,
    "displayConditions",
    []
  );
  const itemDisplayConditions = allDisplayConditions.filter(
    (dc) => dc.sourceQuestionRootId === itemRootId
  );

  function closeModal() {
    setShowModal(false);
    setModalType(undefined);
  }

  function removeDataSet() {
    setFieldValue(`${itemPath}.answerSource`, null);
  }

  function openDataSetDrawer() {
    setShowDrawer(true);
  }

  function clearAllSelectionValues() {
    setFieldValue(name, []);
  }

  function removeSelection(selectionIndex: number) {
    const updatedSelections = [
      ...selectedValues.slice(0, selectionIndex),
      ...selectedValues.slice(selectionIndex + 1),
    ];

    setFieldValue(name, updatedSelections);
  }

  function removeSelectionCondition(selectionRootId: string) {
    const updatedDisplayConditions = itemDisplayConditions.filter(
      (dc) => String(dc.sourceConditionRootId) !== selectionRootId
    );
    setFieldValue("displayConditions", updatedDisplayConditions);
  }

  function clearItemConditions() {
    const updatedDisplayConditions = allDisplayConditions?.filter(
      (dc) => dc.sourceQuestionRootId !== itemRootId
    );
    setFieldValue("displayConditions", updatedDisplayConditions);
  }

  function addDataSet(
    dataSet: DataSource,
    sourceColumn: string,
    sortBy: string
  ) {
    const updatedAnswerSource = buildAnswerSource(
      dataSet,
      sourceColumn,
      sortBy
    );
    setFieldValue(`${itemPath}.answerSource`, updatedAnswerSource);
  }

  // function handleRemoveCustomSelection(value: SuggestionType, index: number) {
  //   if (itemDisplayConditions.find((dc) => dc.sourceConditionRootId === value.rootId)) {
  //     setModalType("removeOption");
  //     setShowModal(true);
  //     setModalCallbackValues({ value, index });
  //   } else {
  //     removeSelection(index);
  //   }
  // }

  function handleRemoveDataSet() {
    if (itemDisplayConditions?.length) {
      setModalType("options");
      setShowModal(true);
    } else {
      removeDataSet();
    }
  }

  function handleAddDataSet() {
    // if we're replacing custom selections
    if (get(values, name)?.length) {
      setModalType("ds");
      setShowModal(true);
      // if we're replacing an existing answerSource and there are display conditions associated
    } else if (existingSource && itemDisplayConditions.length) {
      setModalType("replaceDs");
      setShowModal(true);
    } else {
      openDataSetDrawer();
    }
  }

  function acceptDsChange() {
    clearItemConditions();
    clearAllSelectionValues();
    openDataSetDrawer();
  }

  function acceptOptionsChange() {
    clearItemConditions();
    removeDataSet();
  }

  function acceptReplaceDsChange() {
    clearItemConditions();
    openDataSetDrawer();
  }

  function acceptRemoveOptionChange(callbackValues: {
    value: SuggestionType;
    index: number;
  }) {
    if (callbackValues) {
      const { value, index } = callbackValues;
      value.rootId && removeSelectionCondition(value.rootId);
      removeSelection(index);
    }
  }

  function acceptUpdate(callbackValues?: unknown) {
    closeModal();

    switch (modalType) {
      case "ds":
        return acceptDsChange();
      case "options":
        return acceptOptionsChange();
      case "replaceDs":
        return acceptReplaceDsChange();
      case "removeOption":
        return acceptRemoveOptionChange(
          callbackValues as { value: SuggestionType; index: number }
        );
    }
  }

  return (
    <>
      <div className={error && touched ? css(ps.errorWrapper) : ""}>
        {modalType && (
          // warning modal for switching selections source (custom vs data set)
          // or deleting a selection with associated display conditions
          <SwitchOptionsTypeModal
            show={showModal}
            modalType={modalType}
            modalCallbackValues={modalCallbackValues}
            onAccept={acceptUpdate}
            onDecline={closeModal}
          />
        )}

        {!isFormPublished(values.workflowType) && (
          <Label
            className={css(ps.label)}
            htmlFor={name}
            assistiveLink={{
              onClick: handleAddDataSet,
              label: `${existingSource ? "Edit" : "Add"} Data Set`,
            }}
          >
            {label}
          </Label>
        )}

        <span className={css(ps.text)}>{helperText}</span>

        {existingSource?.dataSourceKey ? (
          <DataSetBanner
            dataSourceKey={existingSource.dataSourceKey}
            onRemoveDataSet={handleRemoveDataSet}
            disabled={isFormPublished(values.workflowType)}
          />
        ) : (
          <CustomAnswerOptions
            name={name}
            itemPath={itemPath}
            onUpdate={onOptionUpdate}
            selectedOptions={selectedValues}
          />
        )}

        <DataSetDrawer
          show={showDrawer}
          onAddDataSet={addDataSet}
          closeDrawer={() => setShowDrawer(false)}
          detailedSearchProps={detailedSearchProps}
          initialValues={existingSource}
          itemPath={itemPath}
        />
      </div>

      <FieldErrorMessage fieldName={name} />
    </>
  );
};

export default AnswerOptions;
