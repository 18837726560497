import styled from "styled-components";

import { remCalc, toRgba } from "themes/helpers";
import Text from "components/common/Text";

export const DocumentHeader = styled.div`
  text-align: left;
  // border-bottom: 1px solid ${({ theme }) => theme.masterColors.lightGrey};
  width: 100%;
  padding-bottom: 18px;
  max-width: 270px;
`;

export const TitleRow = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 2px;
  flex-wrap: wrap;
`;

export const Title = styled(Text)`
  margin: 0;
  padding: 0;
  font-size: ${remCalc(20)};
  margin-right: 8px;
`;

interface SubmissionTypeProps {
  readonly isSubmitted: boolean;
}

export const SubmissionType = styled(Text)<SubmissionTypeProps>`
  padding: 1px 8px;
  background-color: ${(props) => props.isSubmitted ? props.theme.colors.successFill : props.theme.colors.activeFill};
  border: 1px solid ${(props) => props.isSubmitted ? props.theme.colors.success : props.theme.colors.primary};
  color: ${(props) => props.isSubmitted ? props.theme.colors.success : props.theme.colors.primary};
  font-size: ${remCalc(12)};
  min-width: 85px;
  width: max-contenxt;
  border-radius: 21px;
  text-align: center;
`;

const commonSubtitleStyles = `
  padding: 0px;
  margin: 0px;
`;

export const Subtitle1 = styled.span`
${commonSubtitleStyles}
  color: ${({ theme }) => theme.masterColors.black};
`;

export const Subtitle2 = styled.span`
  ${commonSubtitleStyles}
  color: ${({ theme }) => toRgba(theme.colors.black, 0.6)};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;
