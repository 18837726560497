import React, { useState } from "react";
import styled from "styled-components";

import * as S from "./styles";

export type LabelVariantTypes = "default" | "stacked";

export interface AssistiveLinkType {
  label: string;
  /**
   * when passed, will render the Assistive Text
   * element as a button. Takes precedence over url
   * and rendering an anchor element
   */
  onClick?: (isInitiatedByMouse: boolean) => void;
  /**
   * when passed, will render an anchor element for
   * Assistive Text. If an `onClick` is passed, we will
   * let the `onClick` and button element take precedence
   */
  url?: string;
  icon?: string;
}

export interface LabelProps {
  htmlFor: string;
  className?: string;
  children: React.ReactNode;
  assistiveLink?: AssistiveLinkType | null;
  /**
   * **variant** affects styling
   * - `default` will render the label as row
   * - `stacked` will render as a column, placing the
   * assistive text under the label
   */
  required?: boolean;
  variant: LabelVariantTypes;
}

// used to help conditionally render the correct element
// if onclick is provided, Assistive Link element will be a button
// if url is provided, it will be an anchor element
const RenderAssistiveLink = ({
  assistiveLink,
  endAdornment,
  labelVariant,
}: {
  assistiveLink: AssistiveLinkType;
  endAdornment?: boolean;
  labelVariant: LabelVariantTypes;
}) => {

  const [isMouseFocused, setIsMouseFocused] = useState(false);

  const handleBlur = () => {
    setIsMouseFocused(false);
  };

  const handleClick = () => {
    if (assistiveLink.onClick) {
      assistiveLink.onClick(isMouseFocused);
    }
  };

  const handleMouseDown = () => {
    setIsMouseFocused(true);
  };

  if (assistiveLink.onClick) {
    return (
      <S.AssistiveLinkButton
        type="button"
        isMouseFocused={isMouseFocused}
        labelVariant={labelVariant}
        onBlur={handleBlur}
        onClick={handleClick}
        onMouseDown={handleMouseDown}
      >
        {assistiveLink.icon && (
          <S.AssistiveIcon
            className={`icon icon-icons8-${assistiveLink.icon}`}
          />
        )}
        {assistiveLink.label}
      </S.AssistiveLinkButton>
    );
  }

  if (assistiveLink.url) {
    return (
      <S.AssistiveLink
        href={assistiveLink.url}
        endAdornment={endAdornment}
        isMouseFocused={isMouseFocused}
        labelVariant={labelVariant}
        onBlur={handleBlur}
        onMouseDown={handleMouseDown}
      >
        {assistiveLink.icon && (
          <S.AssistiveIcon
            className={`icon icon-icons8-${assistiveLink.icon}`}
          />
        )}
        {assistiveLink.label}
      </S.AssistiveLink>
    );
  }

  return null;
};

const RenderAssistiveLinkNode = ({ Element }: { Element: React.ReactNode }) => (
  <>{Element}</>
);

export const StyledAsterisk = styled.span`
  color: ${({ theme }) => theme.colors.error};
`;

const Label = ({
  assistiveLink,
  children,
  className,
  htmlFor,
  required,
  variant,
}: LabelProps) => {
  // If question is required, add a styled asterisk
  const requiredAsterisk = required ? <StyledAsterisk> *</StyledAsterisk>: "";
  return (
    <S.Label
      className={className}
      assistiveLink={!!assistiveLink}
      variant={variant}
    >
      <S.FieldLabel
        className="Label__Text"
        htmlFor={htmlFor}
        assistiveLink={!!assistiveLink}
      >
        {children}{requiredAsterisk}
      </S.FieldLabel>
      {assistiveLink &&
        (typeof assistiveLink.label === "string" ? (
          <RenderAssistiveLink
            assistiveLink={assistiveLink}
            labelVariant={variant}
          />
        ) : (
          <RenderAssistiveLinkNode Element={assistiveLink.label} />
        ))}
    </S.Label>
  );
};

Label.defaultProps = {
  variant: "default",
};

export default Label;
