export const setLoadingOrErrorFlag = (state: any, key: string | number, method: string, value: boolean) => {
  let newState = state;
  newState = {
    ...newState,
    [key]: {
      ...newState[key],
      [method]: value,
    },
  };
  return newState;
};
