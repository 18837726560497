import { RESET_CURRENT_DOCUMENT } from "../newDocument/types";

import { FETCH_DOCUMENT_OES, FETCH_DOCUMENT_OES_NOSTORE, OperationalExperiencesActionTypes } from "./types";
import { OperationalExperience } from "../resources/types";

export interface OEState {
  oes: Array<OperationalExperience>;
  loading: boolean;
}

const initialState: OEState = {
  oes: [],
  loading: false,
};

export function operationalExperiencesReducer(
  state = initialState,
  action: OperationalExperiencesActionTypes,
): OEState {
  const { type, response } = action;
  switch (type) {
    case FETCH_DOCUMENT_OES.REQUEST:
      return {
        ...state,
        loading: true,
      };

    case FETCH_DOCUMENT_OES.SUCCESS:
      return {
        ...state,
        oes: response || [],
        loading: false,
      };

    case FETCH_DOCUMENT_OES.FAILURE:
      return {
        ...state,
        oes: [],
        loading: false,
      };

    case FETCH_DOCUMENT_OES_NOSTORE.SUCCESS:
    case FETCH_DOCUMENT_OES_NOSTORE.FAILURE:
      return {
        ...state,
        loading: false,
      };

    case RESET_CURRENT_DOCUMENT:
      return initialState;

    default:
      return state;
  }
}
