import { RequestUsers } from "./types";
import { UserStatus } from "store/participantsSummary/types";
import { getMinMaxSubmissionDatesFromTimeFilter } from "components/helpers/filters";

// User status filter, transform options to BE-friendly values
export const mapStatus = (status) => {
  switch (status) {
    case 1:
      return "ACTIVE";
    case 2:
      return "DISABLED";
    case 3: // Not registered
      return "NOT_ACTIVATED";
    case 4: // N/A
      return "NOT_APPLICABLE";
    case -1:
    default:
      return undefined;
  }
};

export const requestUsers = ({
  dateRange,
  dispatch,
  getParticipantsAggregateSummary,
  getParticipantsSummary,
  group,
  noStats, // Don't do additional call for stats if number of records is same
  page,
  participantTreeFilter,
  searchQuery,
  size,
  sortQuery,
  status,
  timeFilterType,
}: RequestUsers) => {
  // Get min and max dates
  const {
    maxSubmissionDate: maxLastLogin,
    minSubmissionDate: minLastLogin,
  } = getMinMaxSubmissionDatesFromTimeFilter(timeFilterType, dateRange);
  // Request participants summary (table)
  dispatch(
    getParticipantsSummary({
      // participantType: role,
      clientGroupId: group,
      maxLastLogin,
      minLastLogin,
      page,
      participantTreeFilter,
      searchText: searchQuery,
      size: size || 10,
      sort: sortQuery,
      status: mapStatus(status),
    })
  );
  // Request participants aggregate summary (above table)
  !noStats &&
    dispatch(
      getParticipantsAggregateSummary({
        clientGroupId: group,
        status: mapStatus(status),
        maxLastLogin,
        minLastLogin,
        // page: 0,
        // participantType: role,
        participantTreeFilter,
        searchText: searchQuery,
        // size: size || 10,
        // sort: sortQuery,
      })
    );
};

// If this returns true, we display an Enable/Disable user menu item
export const showDisableEnableItem = (status) => {
  if (status && ["ACTIVE", "DISABLED"].includes(status)) {
    return true;
  }
  return false;
};

// Activation endpoint accepts a boolean, so this maps the status to disable/enable a user
export const getActivationStatusForPayload = (status?: UserStatus) => {
  switch (status) {
    case "ACTIVE":
      return false;
    case "DISABLED":
      return true;
    default:
      return null;
  }
};

// If this returns true, we display a "resend validation/activation email" menu item
export const showEmailValidationItem = (status) => {
  if (status === "DISABLED" || status === "NOT_ACTIVATED") {
    return true;
  }
  return false;
};

// Prettifies user status in Users table
export const getActivationStatusForTable = (status) => {
  switch (status) {
    case "ACTIVE":
      return "Active";
    case "DISABLED":
      return "Disabled";
    case "NOT_ACTIVATED":
      return "Not registered";
    case "NOT_APPLICABLE":
      return "N/A";
    default:
      return "";
  }
};

// Enable/disable user partial copy
export const getEnabledOrDisabledWording = (currentStatus) => {
  switch (currentStatus) {
    case "ACTIVE":
      return "disable";
    case "DISABLED":
      return "enable";
    default:
      return "";
  }
};

// Used for button inside modal, when enabling/disabling user
export const getActivationStatusButtonLabel = (status) => {
  switch (status) {
    case "ACTIVE":
      return "Disable user";
    case "DISABLED":
      return "Enable user";
    default:
      return "";
  }
};

// Build group options
export const generateGroupOptions = (options) => {
  const res = options.map((e) => ({
    id: e.id,
    value: e.name,
  }));
  res.unshift({
    id: -1,
    value: "All Groups",
  });
  return res;
};
