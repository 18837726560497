import React from "react";
import { css } from "aphrodite/no-important";
import { useFormikContext, Field } from "formik";

import { Option } from "components/common/form/Select/Select";
import {
  FBForm,
  FBSection,
  FBItem,
} from "components/clientAdmin/formBuilder/types";
import Select from "components/common/form/Select";

import propertiesStyles from "../../styles";
import baseStyles from "../../../../styles";
import { isFormPublished } from "components/clientAdmin/formBuilder/helpers";

interface ShowHideConditionProps {
  conditionIndex: number;
  responseOptions: Option[];
  targetOptions: Option[];
  targetItems: (FBItem | FBSection)[];
  itemSubType: string;
}

const ShowHideCondition = ({
  conditionIndex,
  responseOptions,
  targetOptions,
  targetItems,
  itemSubType,
}: ShowHideConditionProps) => {
  const { values, setFieldValue } = useFormikContext<FBForm>();

  const conditionSource =
    itemSubType === "YES_OR_NO" ? "booleanCondition" : "sourceConditionRootId";

  const ps = propertiesStyles();
  const bs = baseStyles();
  const isPublished = isFormPublished(values.workflowType);

  return (
    <div className={css(ps.conditionRules)}>
      <div className={css([bs.row, ps.dcRow])}>
        <span className={css(ps.text)}>If response is</span>
        <Field
          as={Select}
          wrapperClassName={css([ps.dcSelect, ps.showHideSelect])}
          name={`displayConditions[${conditionIndex}].${conditionSource}`}
          options={responseOptions}
          disabled={isPublished}
        />
      </div>
      <div className={css([bs.row, ps.dcRow])}>
        <span className={css([ps.text, ps.dcRow])}>then</span>
        <Field
          as={Select}
          wrapperClassName={css([ps.dcSelect, ps.actionSelect])}
          name={`displayConditions[${conditionIndex}].action`}
          options={[
            { value: "show", id: "SHOW" },
            { value: "hide", id: "HIDE" },
          ]}
          disabled={isPublished}
        />
        <Field
          as={Select}
          wrapperClassName={css([ps.dcSelect, ps.showHideSelect])}
          name={`displayConditions[${conditionIndex}].targetRootId`}
          options={targetOptions}
          disabled={isPublished}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            const targetItem = targetItems.find(
              (tItem) => tItem.id === Number(e.target.value)
            );
            setFieldValue(
              `displayConditions[${conditionIndex}].targetRootId`,
              e.target.value
            );
            setFieldValue(
              `displayConditions[${conditionIndex}].targetType`,
              targetItem?.type
            );
          }}
        />
      </div>
    </div>
  );
};

export default ShowHideCondition;
