import { GET_CLIENT_GROUPS, ClientGroupsState, ClientGroupsActionTypes } from "./types";
import { setLoadingOrErrorFlag } from "store/common/reducerUtilities";

const initialState: ClientGroupsState = {
  loading: {
    getClientGroups: {
      GET: false,
      POST: false,
      PUT: false,
    },
  },
  errors: {
    getClientGroups: {
      GET: false,
      POST: false,
      PUT: false,
    },
  },
  data: {
    clientGroups: { content: [] },
  },
};

export function clientGroupsReducer(state = initialState, action: ClientGroupsActionTypes): ClientGroupsState {
  const { response, type } = action;

  switch (type) {
    case GET_CLIENT_GROUPS.REQUEST:
      return {
        ...state,
        errors: setLoadingOrErrorFlag(state.errors, "getClientGroups", "GET", false),
        loading: setLoadingOrErrorFlag(state.loading, "getClientGroups", "GET", true),
      };
    case GET_CLIENT_GROUPS.FAILURE:
      return {
        ...state,
        errors: setLoadingOrErrorFlag(state.errors, "getClientGroups", "GET", true),
        loading: setLoadingOrErrorFlag(state.loading, "getClientGroups", "GET", false),
      };
    case GET_CLIENT_GROUPS.SUCCESS:
      return {
        ...state,
        data: {
          clientGroups: response,
        },
        errors: setLoadingOrErrorFlag(state.errors, "getClientGroups", "GET", false),
        loading: setLoadingOrErrorFlag(state.loading, "getClientGroups", "GET", false),
      };

    default:
      return state;
  }
}
