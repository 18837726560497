import { MethodTypes, Pagination, APIResponse } from "store/common/types";

export const GET_CLIENT_GROUPS = {
  REQUEST: "GET_CLIENT_GROUPS_REQUEST",
  SUCCESS: "GET_CLIENT_GROUPS_SUCCESS",
  FAILURE: "GET_CLIENT_GROUPS_FAILURE",
};

export const ADD_CLIENT_GROUP = {
  REQUEST: "ADD_CLIENT_GROUP_REQUEST",
  SUCCESS: "ADD_CLIENT_GROUP_SUCCESS",
  FAILURE: "ADD_CLIENT_GROUP_FAILURE",
};

export const GET_CLIENT_GROUP = {
  REQUEST: "GET_CLIENT_GROUP_REQUEST",
  SUCCESS: "GET_CLIENT_GROUP_SUCCESS",
  FAILURE: "GET_CLIENT_GROUP_FAILURE",
};

export const GET_ALL_GROUP_STATS = {
  REQUEST: "GET_ALL_GROUP_STATS_REQUEST",
  SUCCESS: "GET_ALL_GROUP_STATS_SUCCESS",
  FAILURE: "GET_ALL_GROUP_STATS_FAILURE",
};

export const GET_ALL_DEFAULT_TERM_DEFINITIONS = {
  REQUEST: "GET_ALL_DEFAULT_TERM_DEFINITIONS_REQUEST",
  SUCCESS: "GET_ALL_DEFAULT_TERM_DEFINITIONS_SUCCESS",
  FAILURE: "GET_ALL_DEFAULT_TERM_DEFINITIONS_FAILURE",
};

export interface ClientGroupsState {
  loading: {
    getClientGroups: Partial<MethodTypes>;
  };
  errors: {
    getClientGroups: Partial<MethodTypes>;
  };
  data: {
    clientGroups: ClientGroupsResponse;
  };
}

export interface ClientGroupsActionTypes {
  type: typeof GET_CLIENT_GROUPS.REQUEST | typeof GET_CLIENT_GROUPS.SUCCESS | typeof GET_CLIENT_GROUPS.FAILURE;
  response?: any;
}

export interface ClientGroupsResponse extends Pagination {
  content: ClientGroup[];
}

export interface GetClientGroupsResponse extends APIResponse {
  response?: ClientGroupsResponse;
}

export type ClientGroup = {
  detail?: string;
  externalGroupId?: string;
  id?: number;
  isCompletedData?: boolean;
  name?: string;
  source?: "API" | "USER_GENERATED" | "API_INCOMPLETE";
  softDeleted?: boolean;
};

export type ClientGroupStats = {
  clientGroupId?: number;
  externalGroupId?: string;
  feedback?: number;
  groupName?: string;
  submissions?: number;
  teamMembers?: number;
};
