import React, { useState, useEffect } from "react";

import { WorkOrderType } from "store/workOrders/types";
import Loader from "components/common/Loader";
import useDebounce from "util/hooks/useDebounce";

import { HandleSetFormValuesArgs } from "../NewForm";
import * as S from "../styles";

import RenderWorkOrders from "./RenderWorkOrders";

export interface WorkOrderProps {
  clearWorkOrders: () => void;
  handleSetFormValues: (args: HandleSetFormValuesArgs) => void;
  loading: boolean;
  selectedWorkOrderId: number | null;
  startSearchForWorkOrders: (workOrderId: string) => void;
  workOrderFilter: string;
  workOrders: Array<WorkOrderType>;
}

/**
 * Handles the part of the Add New Document feature which involves entering
 * a Work Order ID, hitting the API for matching Work Orders, allowing the
 * user to select a Work Order, ect
 */
const WorkOrder = ({
  handleSetFormValues,
  selectedWorkOrderId,
  startSearchForWorkOrders,
  workOrderFilter,
  workOrders,
  loading,
  clearWorkOrders,
}: WorkOrderProps) => {
  const [searchInputValue, setSearchInputValue] = useState("");
  const [hasSearched, setHasSearched] = useState(false);

  // clear state on unmount
  useEffect(() => {
    return setHasSearched(false);
  }, []);

  const debouncedSearchForWorkOrders = useDebounce({
    delayAmount: 500,
    method: (searchInputValue: string) => {
      !hasSearched && setHasSearched(true);
      return startSearchForWorkOrders(searchInputValue);
    },
  });

  const localHandleChange = (e: React.FormEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;

    setSearchInputValue(value);

    // clear selection on change
    if (selectedWorkOrderId) {
      handleSetFormValues({
        valueType: "selectedWorkOrderId",
        value: null,
      });
    }

    if (value.length >= 3) {
      debouncedSearchForWorkOrders(value);
    }
  };

  return (
    <S.SetFormValue>
      <S.FormValueLabel>Start with work order</S.FormValueLabel>
      <p>
        Please use the full work order number if
        you are unable to find a match
      </p>
      <S.SearchInput
        rounded={true}
        name="workOrder"
        value={searchInputValue}
        placeholder="Enter Work Order ID#"
        handleChange={localHandleChange}
        handleBlur={() => {}}
        variant="outlined"
        fullWidth
        startAdornment={{
          visible: searchInputValue.length === 0,
        }}
        endAdornment={{
          visible: searchInputValue.length > 0,
          label: "clear",
          handleClick: () => {
            setSearchInputValue("");
            setHasSearched(false);
            handleSetFormValues({
              valueType: "selectedWorkOrderId",
              value: "",
            });
            clearWorkOrders();
          },
        }}
      />
      <Loader loading={loading}>
        {hasSearched && (
          <S.WorkOrderWrapper>
            <RenderWorkOrders
              workOrders={workOrders}
              workOrderFilter={workOrderFilter}
              handleSetFormValues={handleSetFormValues}
              selectedWorkOrderId={selectedWorkOrderId}
              hasSearched={hasSearched}
            />
          </S.WorkOrderWrapper>
        )}
      </Loader>
    </S.SetFormValue>
  );
};

export default WorkOrder;
