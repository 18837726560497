import moment from "moment";

import { DataSourceState, DataSourceActionTypes, GET_DATA_SOURCE_VALUES } from "./types";
import {
  combineDataSourceResponse
} from "./helpers";

const initialState: DataSourceState = {
  lastModifiedDate: {},
  data: {},
  loading: {
    getDataSourceValues: {},
  },
  lastPageLoaded: {},
  isLastPage: {},
  totalCount: {},
  success: {
    getDataSourceValues: {},
  },
  error: {
    getDataSourceValues: {},
  },
};

/**
 * Determines whether a specified type is included in an array of defined types
 * @param type
 */
const needsActionKeyName = (type: string): boolean => [
  GET_DATA_SOURCE_VALUES.REQUEST,
  GET_DATA_SOURCE_VALUES.SUCCESS,
  GET_DATA_SOURCE_VALUES.FAILURE,
].includes(type);

export const dataSourceReducer = (state = initialState, action: DataSourceActionTypes): DataSourceState => {
  if (needsActionKeyName(action.type) && !action.keyName) {
    throw new Error(
      "ERROR: Expected keyName with getDataSourceValues action for dataSource feature, this should not be empty!",
    );
  }

  switch (action.type) {
    /**
     * using the dynamic `keyName` passed in through the action,
     * we'll add a property with the same name to the `loading`
     * object to let the app know that we've fired the API call.
     * This action is dispatched within `Document.tsx`'s `componentDidUpdate`
     * lifecycle method so we want to be sure it is not called more
     * than it needs to be called.
     */
    case GET_DATA_SOURCE_VALUES.REQUEST:
      return {
        ...state,
        loading: {
          ...state.loading,
          getDataSourceValues: {
            ...state.loading.getDataSourceValues,
            [action.keyName]: true,
          },
        },
        error: {
          ...state.error,
          getDataSourceValues: {
            ...state.error.getDataSourceValues,
            [action.keyName]: "",
          },
        },
      };

    case GET_DATA_SOURCE_VALUES.FAILURE:
      return {
        ...state,
        loading: {
          ...state.loading,
          getDataSourceValues: {
            ...state.loading.getDataSourceValues,
            [action.keyName]: false,
          },
        },
        error: {
          ...state.error,
          getDataSourceValues: {
            ...state.error.getDataSourceValues,
            [action.keyName]: action.error,
          },
        },
        // Not clearing values here because we combine data source values when we load more,
        // and don't want to clear existing ones on a failure

        // data: {
        //   ...state.data,
        //   [action.keyName]: [], // CLEARING VALUES WHEN NEW REQUEST IS MADE WITH SAME KEYNAME
        //   // THIS SHOULD NEVER HAPPEN SINCE WE ONLY FETCH UNIQUE KEYNAMES
        // },
      };

    case GET_DATA_SOURCE_VALUES.SUCCESS:
      return {
        ...state,
        loading: {
          ...state.loading,
          getDataSourceValues: {
            ...state.loading.getDataSourceValues,
            [action.keyName]: false,
          },
        },
        success: {
          ...state.success,
          getDataSourceValues: {
            ...state.success.getDataSourceValues,
            [action.keyName]: true,
          },
        },
        lastModifiedDate: {
          ...state.lastModifiedDate,
          [action.keyName]: action.response.last ? moment().toISOString() : ""
        },
        lastPageLoaded: {
          ...state.lastPageLoaded,
          [action.keyName]: action.response.number,
        },
        isLastPage: {
          ...state.isLastPage,
          [action.keyName]: action.response.last || false
        },
        totalCount: {
          ...state.totalCount,
          [action.keyName]: action.response.totalElements,
        },
        data: combineDataSourceResponse({
          dataSourceResponse: action.response.content,
          keyName: action.keyName,
          previousDataSourceData: state.data,
        })
      };
    case GET_DATA_SOURCE_VALUES.CLEAR:
      return {
        ...state,
        lastPageLoaded: {
          ...state.lastPageLoaded,
          [action.keyName]: -1,
        },
        isLastPage: {
          ...state.isLastPage,
          [action.keyName]: false
        },
        data: {
          ...state.data,
          [action.keyName]: [],
        },
      };
    default:
      return state;
  }
};
