import React from "react";

import s from "../styles";
import { css } from "aphrodite/no-important";

const AlwaysOption = () => (
  <div className={css(s().AlwaysOption)}>
      Submissions can always be edited
  </div>
);

export default AlwaysOption;
