import * as React from "react";
import MediaQuery from "react-responsive";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { css } from "aphrodite/no-important";

import { Link, TextLink } from "components/common/Link/futureUiKit";

import * as S from "./styles";
import * as f from "./fields";
import Form from "../forms/Form";
import { AppState } from "../../store";
import { PasswordHelpValues } from "./types";
import { ResetContainer, Title, SpanWithMargin } from "./styles";
import { SystemState } from "../../store/system/types";
import { devices } from "themes/mediaQueries";
import { requestReset, clearError, clearStatus } from "../../store/system/actions";

interface PasswordHelpProps {
  clearError: () => void;
  clearStatus: () => void;
  history: any;
  requestReset: (email: string) => void;
  system: SystemState;
  location: any;
  match: any;
}

class PasswordHelp extends React.Component<PasswordHelpProps> {
  state = {
    showTempSuccessButton: false,
  };
  componentDidMount(): void {
    if (this.props.system.error !== null) {
      this.props.clearError();
    }
    if (this.props.system.resetRequestStatus !== null) {
      this.props.clearStatus();
    }
  }

  componentWillReceiveProps(nextProps) {
    // Show temp success button state briefly
    if (this.props.system.resetRequestStatus === "request" && nextProps.system.resetRequestStatus === "success") {
      this.setState({
        showTempSuccessButton: true,
      });
      setTimeout(() => {
        this.setState({ showTempSuccessButton: false });
      }, 1500);
    }
  }

  handleSubmit = (values: PasswordHelpValues): void => {
    this.props.requestReset(values.email);
  };

  navigate = (endpoint: string): void => {
    this.props.history.push(endpoint);
  };

  render() {
    const { history, system } = this.props;
    const logo2x2Fallback = "/assets/logo/Dominion-EnergyLogo@2x.png";
    return (
      <ResetContainer>
        <MediaQuery minWidth={devices.minTablet}>
          <Link className={css(S.styles.logo)} to="/login">
            <img src={logo2x2Fallback} height="68" alt="Dominion Energy" />
          </Link>
        </MediaQuery>
        <MediaQuery maxWidth={devices.maxPhone}>
          <S.MobileHeader>
            <S.MobileHeaderChevron onClick={() => history.push("/login")} />
            <span>Help</span>
          </S.MobileHeader>
        </MediaQuery>
        <Title>Need Help Logging In?</Title>
        {/* // This isn't in requirements, commenting out for now -- GK */}
        {/* {system.accountLocked && (
          <ErrorSpan>Your account has been locked. Please submit your email to proceed.</ErrorSpan>
        )} */}
        <SpanWithMargin>
          We're here for you. Enter your email and we'll send instructions on how to reset your password. If you don't
          know your account email or don't have access, contact your administrator.
        </SpanWithMargin>

        <Form
          fields={f.helpFields({
            buttonSuccessState: this.state.showTempSuccessButton ? (
              <S.ButtonSuccessTempState>
                <S.ButtonSuccessTempIconContainer>
                  <S.ButtonSuccessTempIcon />
                </S.ButtonSuccessTempIconContainer>
              </S.ButtonSuccessTempState>
            ) : (
              <S.ButtonSuccessState>
                <S.ButtonSuccessStateIcon />
                Recovery Email Sent
              </S.ButtonSuccessState>
            ),
            buttonFailureState: (
              <S.ButtonFailureState>
                <S.ButtonFailureStateIcon />
                Unable to Send Email
              </S.ButtonFailureState>
            ),
          })}
          accountLocked={false}
          error={system.error}
          externalLoading={system.resetRequestStatus === "request"}
          handleSubmit={this.handleSubmit}
          navigate={this.navigate}
          recaptchaNeeded={false}
          status={system.resetRequestStatus}
          validationSchema={f.helpSchema}
        />
        <S.AdditionalButtonWrapper>
          <TextLink className={css(S.styles.additionalLink)} to="/login">
            Return to login
          </TextLink>
        </S.AdditionalButtonWrapper>
      </ResetContainer>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  system: state.system,
});

export default withRouter(connect(mapStateToProps, { requestReset, clearError, clearStatus })(PasswordHelp));
