import { ThemeContext } from "styled-components";

import { remCalc } from "themes/helpers";
import { CSSProperties, useContext } from "react";
import { StyleSheet } from "aphrodite/no-important";

interface Props {
  formItemDisabled?: boolean;
}

export default function({ formItemDisabled }: Props): {[key: string]: CSSProperties} {
  const theme = useContext(ThemeContext);
  return StyleSheet.create({

    AddIcon: {
      color: theme.colors.primary,
      fontSize: remCalc(28),
      fontWeight: 600,
      position: "absolute",
      right: remCalc(4),
    },

    Row: {
      alignItems: "center",
    },

    FormItem: {
      alignItems: "center",
      border: `1px solid ${theme?.colors.lightGrey}`,
      borderRadius: "4px",
      cursor: formItemDisabled ? "not-allowed" : "pointer",
      display: "flex",
      fontSize: remCalc(14),
      ":hover": {
        background: theme.colors.activeFill,
      },
      marginBottom: "0.5rem",
      opacity: 1,
      padding: `${remCalc(6)} 1rem`,
      position: "relative" as const,
    },

    FormItemIcon: {
      marginRight: "0.25rem",
    },

    ItemsTab: {
      padding: remCalc(12),
      flexBasis: "33.3%",
      minWidth: 0,
    },

    ItemsContainer: {
      padding: "1rem 1.5rem 0",
    },

    Label: {
      color: theme.colors.black,
      fontSize: remCalc(12),
      letterSpacing: 1.88,
      textTransform: "uppercase",
      fontWeight: 500,
      marginBottom: remCalc(8),
    },

    QuestionsResults: {
      margin: "0.5rem 0",
      maxHeight: remCalc(50 * 3), // update if FormItem size changes
      overflowY: "scroll" as const,

      // display the scrollbar if there's overflow on macOS
      "::-webkit-scrollbar": {
        "-webkit-appearance": "none",
        width: "14px",
      },
      "::-webkit-scrollbar-thumb": {
        border: "4px solid rgba(0, 0, 0, 0)",
        borderRadius: "7px",
        backgroundClip: "padding-box",
        backgroundColor: "rgba(0,0,0,.5)",
        "-webkit-box-shadow": "0 0 1px rgba(255,255,255,.5)",
      }
    },

    VisibilityLoader: {
      padding: `${remCalc(8)} 0`,
    },

    formItemLoader: {
      padding: 0,
      width: "auto",
      marginLeft: "auto"
    }

  });
}
