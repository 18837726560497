import styled from "styled-components";

import Text from "components/common/Text";
import { remCalc } from "themes/helpers";

interface PillProps {
  readonly isSubmitted: boolean;
}

export const Pill = styled.div<PillProps>`
  min-width: 78px;
  height: 22px;
  background-color: ${(props) => props.isSubmitted ? props.theme.colors.successFill : props.theme.colors.activeFill};
  border: 1px solid ${(props) => props.isSubmitted ? props.theme.colors.success : props.theme.colors.primary};
  border-radius: 16px;

  display: flex;
  justify-content: center;
  align-items: center;

  padding: 4px 11px 4px 11px;
`;

export const Label = styled(Text)<PillProps>`
  margin: 0;
  padding: 0;
  font-size: ${remCalc(12)};
  color: ${(props) => props.isSubmitted ? props.theme.colors.success : props.theme.colors.primary};
`;
