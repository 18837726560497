/**
 * This component will be used in the future UI kit redesign. Added 2020-14-12.
 */
import React, { PropsWithChildren, MouseEventHandler, forwardRef } from "react";
import { joinClassNames } from "themes/helpers";
import styles from "./styles.module.scss";
import { useMouseFocus } from "util/hooks/useMouseFocus";
import { ReactComponent as Cross } from "assets/svg/cross.svg";

interface SelectionChipProps {
  className?: string;
  handleClick: MouseEventHandler<HTMLButtonElement>;
  isFocusDisabled?: boolean;
}

export const SelectionChip = forwardRef<
  HTMLLIElement,
  PropsWithChildren<SelectionChipProps>
>(({ children, className, handleClick, isFocusDisabled }, ref) => {
  const { eventHandlers, isMouseFocused } = useMouseFocus();

  return (
    <li className={className} ref={ref}>
      <button
        className={joinClassNames(
          styles.selectionChip,
          !isMouseFocused && styles.selectionChipKeyboardFocused
        )}
        onClick={handleClick}
        tabIndex={isFocusDisabled ? -1 : undefined}
        type="button"
        {...eventHandlers}
      >
        <span className={styles.label}>{children}</span>
        <Cross className={styles.icon} />
      </button>
    </li>
  );
});
