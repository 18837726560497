import React, { useCallback, useMemo } from "react";
import styled from "styled-components";

import { Label } from "components/common/form/Label/futureUiKit";
import { ClientGroup } from "store/clientGroups/types";
import { ListItemOption, IconForEndAdornment } from "components/forms/MultiInput/styles";


const Wrapper = styled.div`
  border-radius: 4px;
  border: 1px solid rgb(24,24,24);
  padding: 1rem 0.875rem; // This is rem to match other inputs, not what I think it should be -- GK
`;

const LabelWrapper = styled.div`
  align-items: flex-end;
  display: flex;
  justify-content: space-between;
`;

const ActionLink = styled.div`
  color: ${({ theme }) => theme.colors.primary};
  cursor: pointer;
  margin: 0 5px 5px 0;
  text-decoration: underline;
`;

const AssistiveText = styled.div`
  color: ${({ theme }) => theme.masterColors.mediumGrey};
`;

interface Props {
  action: () => void;
  groups: ClientGroup[];
  role: {
    id: string | null;
    value: string;
  };
  totalGroups: number;
  updateAuthorities: (role: string, groups: number[], method?: "replace" | "add") => void;
}


export const GroupsContainerPerRole = ({ 
  action, 
  groups = [], 
  role= { id: null, value: "N/A" }, 
  totalGroups = 0,
  updateAuthorities,
}: Props) => {

  const removeGroup = useCallback((groupId, role) => {
    const _groups = groups.filter((group) => group.id !== groupId).map((group) => group.id || 0);
    updateAuthorities(role.id, _groups, "replace");
  }, [groups, role]);

  const allGroupsSelected = useMemo(() => totalGroups !== 0 && totalGroups === groups?.length, [totalGroups, groups]);

  return <>
    <LabelWrapper>
      <Label content={role.value}/>
      <ActionLink onClick={action}>Add groups</ActionLink>
    </LabelWrapper>
    
    <Wrapper>
      {allGroupsSelected ? <ListItemOption
        key={"all-groups-selected"}
        variant="participant-selected"
        renderLabelsAsRow
        label={"All groups"}
        endAdornment={{
          hideTextDecoration: true,
          visible: true,
          type: "button",
          onClick: () => {
            updateAuthorities(role.id || "", [], "replace");
          },
          label: <IconForEndAdornment className="icon icon-icons8-delete_sign" />,
        }}
      /> :groups?.map((group) => (
        <ListItemOption
          key={group.id}
          variant="participant-selected"
          renderLabelsAsRow
          label={group.name}
          endAdornment={{
            hideTextDecoration: true,
            visible: true,
            type: "button",
            onClick: () => {
              removeGroup(group.id, role);
            },
            label: <IconForEndAdornment className="icon icon-icons8-delete_sign" />,
          }}
        />
      ))}
      <AssistiveText>Select groups for which this user is a {role.value}</AssistiveText>
    </Wrapper>
  </>;
};
