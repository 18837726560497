import { FilterParams, PaginationParams } from "store/common/types";

export const USERS_FILTERS = {
  SET: "USERS_FILTERS_SET",
  PARAMS_SET: "USERS_FILTERS_PARAMS_SET",
};

export interface UsersFiltersParams extends FilterParams {
  groupId: number;
  status: number;
  timeFilterType: string;
  subordinatesFilter: string;
  dateRange: string | null;
  query: string;
}

export interface UsersFiltersArgs {
  filterParams: UsersFiltersParams;
  paginationParams: PaginationParams;
}

export interface SetUsersFiltersAction {
  type: typeof USERS_FILTERS.SET;
  response: UsersFiltersParams;
}

export interface SetUsersPaginationAction {
  type: typeof USERS_FILTERS.PARAMS_SET;
  response: PaginationParams;
}

export type FiltersActions = SetUsersFiltersAction;
