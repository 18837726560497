/**
 * This component will be used in the future UI kit redesign. Added 2020-14-12.
 */
import { ReactComponent as Search } from "assets/svg/search.svg";
import React, {
  ChangeEventHandler,
  forwardRef,
  MouseEventHandler,
  useImperativeHandle,
  useRef,
} from "react";
import { joinClassNames } from "themes/helpers";
import { RequiresLabel } from "util/accessibility";
import { useMouseFocusAndBlur } from "util/hooks/useMouseFocus";
import { ClearButton } from "./ClearButton";
import styles from "./styles.module.scss";

interface ClassNames {
  container?: string;
  containerKeyboardFocused?: string;
  containerMouseBlurred?: string;
  containerMouseFocused?: string;
  input?: string;
  searchIcon?: string;
  underlineContainer?: string;
  underlineContainerFocused?: string;
  underlineContainerMouseFocused?: string;
}

const underlinedClassNames: ClassNames = {
  container: styles.underlinedContainer,
  containerKeyboardFocused: styles.underlinedContainerKeyboardFocused,
  input: styles.input,
  searchIcon: styles.searchIcon,
  underlineContainer: styles.underlineContainer,
  underlineContainerFocused: styles.underlineContainerFocused,
  underlineContainerMouseFocused: styles.underlineContainerMouseFocused,
};

const outlinedClassNames: ClassNames = {
  container: styles.outlinedContainer,
  containerKeyboardFocused: styles.outlinedContainerKeyboardFocused,
  containerMouseBlurred: styles.outlinedContainerMouseBlurred,
  containerMouseFocused: styles.outlinedContainerMouseFocused,
  input: styles.input,
  searchIcon: styles.searchIcon,
  underlineContainer: styles.outlinedUnderlineContainer,
};

interface BaseSearchFieldProps {
  autoComplete?: string;
  className?: string;
  classNames?: ClassNames;
  handleChange?: ChangeEventHandler<HTMLInputElement>;
  handleClickClearButton?: MouseEventHandler<HTMLButtonElement>;
  name: string;
  placeholder?: string;
  value: string;
}

type SearchFieldProps = BaseSearchFieldProps & RequiresLabel;

interface SearchFieldRef {
  focus: () => void;
}

export const SearchField = forwardRef<SearchFieldRef, SearchFieldProps>(
  (
    {
      autoComplete = "off",
      className,
      classNames = underlinedClassNames,
      handleChange,
      handleClickClearButton,
      label,
      labelId,
      name,
      placeholder,
      value,
    },
    ref
  ) => {
    const inputRef = useRef<HTMLInputElement | null>(null);
    const {
      eventHandlers,
      isFocused,
      isMouseFocused,
      wasMouseBlurred,
    } = useMouseFocusAndBlur();

    useImperativeHandle(ref, () => ({
      focus: () => {
        inputRef.current?.focus();
      },
    }));

    return (
      <div
        className={joinClassNames(
          classNames.container,
          isFocused && !isMouseFocused && classNames.containerKeyboardFocused,
          isMouseFocused && classNames.containerMouseFocused,
          wasMouseBlurred && classNames.containerMouseBlurred,
          className
        )}
      >
        <Search className={classNames.searchIcon} />
        <div
          className={joinClassNames(
            classNames.underlineContainer,
            isFocused && classNames.underlineContainerFocused,
            isMouseFocused && classNames.underlineContainerMouseFocused
          )}
        >
          <input
            aria-label={label}
            aria-labelledby={labelId}
            autoComplete={autoComplete}
            className={classNames.input}
            name={name}
            onChange={handleChange}
            placeholder={placeholder}
            ref={inputRef}
            type="text"
            value={value}
            {...eventHandlers}
          />
          {!!value && <ClearButton handleClick={handleClickClearButton} />}
        </div>
      </div>
    );
  }
);

export const OutlinedSearchField = forwardRef<HTMLDivElement, SearchFieldProps>(
  (props: SearchFieldProps, ref) => {
    return <SearchField classNames={outlinedClassNames} ref={ref} {...props} />;
  }
);
