import React from "react";
import { bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";
import { History } from "history";
import * as R from "ramda";
import moment from "moment";

import { AppState } from "store";
import { FormDTO, FormTypeDTO } from "store/forms/types";
import { NewDocumentState } from "store/newDocument/types";
import * as newDocumentActions from "store/newDocument/actions";
import * as workOrderActions from "store/workOrders/actions";
import Text from "components/common/Text";

import * as S from "./styles";
import PrefillFromWorkOrder from "./components/PrefillFromWorkOrder";
import Header from "./components/Header";
import SelectForm from "./components/SelectForm";
import SelectFormType from "./components/SelectFormType";
import WorkOrder from "./components/WorkOrder";
import StartBlankDocument from "./components/StartBlankDocument";

/**
 * Renders an error message based on a boolean
 * value from the "newDocument" reducer.
 *
 * Can render multiple error messages if there are multiple
 * errors.
 */
const RenderErrorMessage = ({
  fetchSummarizedFormsError,
  createDocumentError,
}: {
  fetchSummarizedFormsError: boolean;
  createDocumentError: boolean;
}) => {
  return (
    <div>
      {fetchSummarizedFormsError && (
        <Text variant="error">Error: Failed to get Forms</Text>
      )}
      {createDocumentError && (
        <Text variant="error">Error: Failed to create a new Document</Text>
      )}
    </div>
  );
};

/**
 * **clearChildValues** helps us clear state values for
 * sibling form options, an example being that if the user
 * clears the value for `selectedFormTypeId` then we want to
 * clear all of the other form options, thus resetting the form
 *
 * @param valueType "selectedFormTypeId" | "selectedFormTemplateId" | "selectedWorkOrderId" | "workOrderFilter"
 */
const clearChildValues = (valueType: valueType) => {
  if (valueType === "selectedFormTypeId") {
    return {
      selectedFormTemplateId: null,
      selectedWorkOrderId: null,
      workOrderFilter: "",
    };
  }

  if (valueType === "selectedFormTemplateId") {
    return {
      selectedWorkOrderId: null,
      workOrderFilter: "",
    };
  }
};

type valueType =
  | "selectedFormTypeId"
  | "selectedFormTemplateId"
  | "selectedWorkOrderId"
  | "workOrderFilter";
export interface HandleSetFormValuesArgs {
  valueType: valueType;
  value: number | string | null;
}

export type NewFormVariant = "desktop" | "tablet" | "mobile";

export interface NewFormProps {
  store: {
    newDocument: NewDocumentState;
    forms: {
      data: {
        formTypes?: FormTypeDTO[];
      };
      loading: {
        formTypes: boolean;
      };
    };
  };
  actions: {
    newDocument: typeof newDocumentActions;
    workOrder: typeof workOrderActions;
  };
  title: string;
  /**
   * used when rendering the Desktop Variant
   * of the Drawer component which houses
   * New Form. We're rendering a Close button
   * in that situation so we'll invoke this prop
   * on click
   */
  handleClose: () => void;
  handleCloseDrawer?: () => void;
  /**
   * when `true`, tells components to use default props
   * rather than data from redux
   */
  useMockAPI?: boolean;
  history: History;
  variant: NewFormVariant;
}

export interface NewFormState {
  selectedFormTypeId: number | null;
  selectedFormTemplate: FormDTO | null;
  selectedWorkOrderId: number | null;
  workOrderFilter: string;
}

class NewForm extends React.Component<NewFormProps> {
  static defaultProps = {
    title: "New Form",
  };

  state: NewFormState = {
    selectedFormTypeId: this.props.store.newDocument.selectedFormTypeId || null,
    selectedFormTemplate: null,
    selectedWorkOrderId: null,
    workOrderFilter: "",
  };

  componentDidUpdate(prevProps: NewFormProps) {
    const {
      store: { newDocument },
    } = this.props;

    // document was created, navigate to /documents/:documentId
    if (
      !prevProps.store.newDocument.currentDocument &&
      this.props.store.newDocument.currentDocument?.id
    ) {
      return this.props.history.push(
        `/document/${this.props.store.newDocument.currentDocument.id}`
      );
    }

    const urlPath = R.pathOr(
      null,
      ["location", "pathname"],
      this.props.history
    );

    // user clicking on __Dashboard__ buttons will update value in redux
    // We'll make sure that selected value is correctly stored in local state
    if (
      urlPath !== "/documents" &&
      newDocument.selectedFormTemplateId !== null &&
      newDocument.selectedFormTypeId !== this.state.selectedFormTypeId
    ) {
      return this.setState(() => ({
        selectedFormTypeId: newDocument.selectedFormTypeId,
      }));
    }
  }

  componentWillUnmount() {
    const { newDocument } = this.props.actions;

    // clear work order search results
    newDocument.clearWorkOrders();
  }

  handleSetFormValues = ({ valueType, value }: HandleSetFormValuesArgs) => {
    // handle resetting value
    // `clearChildValues` helps clear sibling values
    if (this.state[valueType] === value) {
      const resetValue = valueType === "workOrderFilter" ? "" : null;
      valueType !== "selectedWorkOrderId" &&
        this.props.actions.newDocument.clearWorkOrders();
      return this.setState((prevState) => ({
        ...prevState,
        [valueType]: resetValue,
        ...clearChildValues(valueType),
      }));
    }

    return this.setState((prevState) => ({
      ...prevState,
      [valueType]: value,
    }));
  };

  handleSelectFormTemplate = (formTemplate: FormDTO | null) => {
    this.setState({ selectedFormTemplate: formTemplate });
  };

  handleSearchWorkOrders = (workOrderId: string) => {
    this.props.actions.workOrder.searchWorkOrders(workOrderId, true);
  };

  startNewDocument = async (prefill?: boolean) => {
    const { handleClose, actions } = this.props;
    const {
      selectedFormTemplate,
      selectedFormTypeId,
      selectedWorkOrderId,
    } = this.state;

    if (!selectedFormTypeId) {
      return null;
    }

    // only stores the data in the feature's reducer
    // @TODO why are we storing this in a reducer? -- ED
    actions.newDocument.startNewForm({
      selectedFormTypeId,
      selectedFormTemplateId: selectedFormTemplate?.id || null,
      selectedWorkOrderId: prefill ? selectedWorkOrderId : null,
    });

    /**
     * dispatch action which makes a `POST` to `/api/documents`
     * then we'll take that response, use it as the foundation
     * for the new document value and we'll push navigation
     * to "/new-document" where the user with complete the form
     */
    const formId = selectedFormTemplate?.id;

    if (!formId) {
      return null;
    }

    const submissionDate = moment()
      .utc()
      .format();

    try {
      await actions.newDocument.createAndPrefillDocument({
        formId,
        workOrderId:
          selectedWorkOrderId && prefill
            ? Number(selectedWorkOrderId)
            : undefined,
        submissionDate,
      });

      return handleClose();
    } catch (e) {
      throw e;
    }
  };

  render() {
    const { title, store, variant, handleClose } = this.props;
    const {
      selectedFormTypeId,
      selectedFormTemplate,
      workOrderFilter,
      selectedWorkOrderId,
    } = this.state;

    const {
      newDocument: {
        loading: { createDocument: createDocumentLoading },
        error: {
          fetchSummarizedForms: fetchSummarizedFormsError,
          createDocument: createDocumentError,
        },
      },
      forms: {
        data: { formTypes = [] },
        loading: { formTypes: formTypesLoading },
      },
    } = store;

    const displayWorkOrderSelector =
      !!selectedFormTypeId &&
      !!selectedFormTemplate &&
      selectedFormTemplate?.startFromWorkOrderEnabled;

    return (
      <S.NewForm>
        <S.HeaderRow>
          <Header>{title}</Header>
          {/* 
            handleClose has its logic in `Dashboard.tsx`
            That's where we clear reducer values and handle
            any other necessary side effects which may need
            to happen when the Drawer is closed
          */}
          {variant === "desktop" && (
            <S.HeaderCloseButton onClick={handleClose}>
              <S.HeaderCloseIcon className="icon icon-icons8-delete_sign" />
            </S.HeaderCloseButton>
          )}
        </S.HeaderRow>
        <RenderErrorMessage
          fetchSummarizedFormsError={fetchSummarizedFormsError}
          createDocumentError={createDocumentError}
        />
        <SelectFormType
          handleSetFormValues={this.handleSetFormValues}
          formTypes={formTypes}
          loading={formTypesLoading}
          selectedFormTypeId={selectedFormTypeId}
          selectedFormTemplateId={selectedFormTemplate?.id}
          handleSelectFormTemplate={this.handleSelectFormTemplate}
        />
        <SelectForm
          loading={store.newDocument.loading.fetchSummarizedForms}
          formTemplates={store.newDocument.forms}
          handleSelectFormTemplate={this.handleSelectFormTemplate}
          selectedFormTypeId={selectedFormTypeId}
          selectedFormTemplateId={selectedFormTemplate?.id || null}
        />
        <StartBlankDocument
          createDocumentLoading={createDocumentLoading}
          selectedFormTypeId={selectedFormTypeId}
          selectedFormTemplateId={selectedFormTemplate?.id || null}
          startNewDocument={this.startNewDocument}
        />
        {displayWorkOrderSelector && (
          <WorkOrder
            workOrderFilter={workOrderFilter}
            handleSetFormValues={this.handleSetFormValues}
            selectedWorkOrderId={selectedWorkOrderId}
            workOrders={store.newDocument.workOrders}
            loading={store.newDocument.loading.workOrders}
            startSearchForWorkOrders={
              this.props.actions.newDocument.startSearchForWorkOrders
            }
            clearWorkOrders={this.props.actions.newDocument.clearWorkOrders}
          />
        )}
        <PrefillFromWorkOrder
          selectedWorkOrderId={selectedWorkOrderId}
          startNewDocument={this.startNewDocument}
          createDocumentLoading={createDocumentLoading}
        />
      </S.NewForm>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  store: {
    newDocument: state.newDocument,
    forms: state.forms,
  },
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  actions: {
    newDocument: bindActionCreators(newDocumentActions, dispatch),
    workOrder: bindActionCreators(workOrderActions, dispatch),
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(NewForm);
