import styled from "styled-components";

import { remCalc } from "themes/helpers";
import Text from "components/common/Text";

export const Logout = styled.div<{ spacing?: boolean; marginTop?: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: ${({ spacing }) => (spacing ? "218px" : "0px")};

  ${({ marginTop }) => {
    if (marginTop) {
      return `
        margin-top: ${marginTop};
      `;
    }
  }}
`;

export const Button = styled.button`
  border: none;
  background: none;
  padding: 15px;
`;

export const Label = styled(Text)`
  color: ${({ theme }) => theme.masterColors.error};
  font-size: ${remCalc(18)};
  font-weight: 500;
`;
