import React, { useEffect, useState } from "react";
import { css } from "aphrodite/no-important";
import { FastField, useFormikContext, Field } from "formik";

import { ClientGroupDTO } from "store/participants/types";
import { FormTypeDTO } from "store/forms/types";
import { SuggestionType } from "components/common/form/MultiInput/MultiInput";
import MultiInput from "components/common/form/MultiInput";
import RadioButtonsGroup from "components/common/form/RadioButtons";
import Select from "components/common/form/Select";
import TextInput from "components/common/form/TextInput";
import YesOrNo from "components/common/form/YesOrNo/YesOrNo";

import styles from "./styles";
import AlwaysOption from "./fields/AlwaysOption";
import TimeLimitOption from "./fields/TimeLimitOption";
import TimeToOption from "./fields/TimeToOption";
import { FBForm, FBQuestion } from "../types";
import baseStyles from "../styles";
import { getAllFormItems, isFormPublished } from "../helpers";
import { Option } from "components/common/form/Select/Select";
import { FormAction } from "store/forms/types";

/** Search for an item in an array. If it exists, update it. If not, add it to the array
 * @param values - the value array to search
 * @param searchFor - key and value to search for
 * @param updatedValue - the new value
 */
function updateValueArray(
  values: { [key: string]: any }[],
  searchFor: { key: string; value: any },
  updatedValue: { [key: string]: any }
) {
  const updatedValues = values ? [...values] : [];
  const valueIndex = updatedValues.findIndex(
    (value) => value[searchFor.key] === searchFor.value
  );

  if (valueIndex === -1) {
    updatedValues.push(updatedValue);
  } else {
    updatedValues[valueIndex] = updatedValue;
  }

  return updatedValues;
}

/**
 * Build the title sources options from the current form values
 * @param form current form values
 */
function getTitleSources(form: FBForm): Option[] {
  const questions = getAllFormItems(form).filter(
    (item) => item.type === "QUESTION"
  ) as FBQuestion[];
  return questions.map((item) => ({ id: item.rootId, value: item.title }));
}

interface SettingsFormProps {
  formTypes: FormTypeDTO[];
  clientGroups: ClientGroupDTO[];
}

const SettingsForm = ({ formTypes, clientGroups }: SettingsFormProps) => {
  const [localTitle, setLocalTitle] = useState<string>("");
  const { values, errors, touched, setFieldValue } = useFormikContext<FBForm>();

  const handleEmailChange = (
    actionType: "EMAIL_SUPERVISOR" | "EMAIL_PARTICIPANTS"
  ) => (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const actions = values.actions ? [...values.actions] : [];

    let updatedActions: FormAction[];
    if (value === "true") {
      const currentAction = actions.find(
        (action) => action.actionType === actionType
      );

      const updatedAction: FormAction = {
        ...currentAction,
        actionType: actionType,
        doItByDefault: true,
        isRequired: currentAction?.isRequired || false,
      };

      updatedActions = updateValueArray(
        actions,
        { key: "actionType", value: actionType },
        updatedAction
      ) as FormAction[];
    } else {
      updatedActions = actions.filter(
        (action) => action.actionType !== actionType
      );
    }

    setFieldValue("actions", updatedActions);
  };

  useEffect(() => {
    if (values.name) {
      setLocalTitle(values.name);
    }
  }, []);

  const s = styles();
  const bs = baseStyles();

  const mappedFormTypes = formTypes?.map((type) => ({
    id: type.id,
    value: type.name,
  }));

  const titleSources = [
    { id: -1, value: "Choose field to define title" },
    ...getTitleSources(values),
  ];

  const titleSourceEntryIndex = values.documentSummaryEntries?.findIndex(
    (entry) => entry.type === "TITLE"
  );

  const isPublished = isFormPublished(values.workflowType);

  return (
    <div className={css(s.settingsForm, bs.contentContainer)}>
      {/* Form Title */}
      <Field
        name="name"
        label="Form Title"
        as={TextInput}
        placeholder="Form title"
        value={localTitle}
        onBlur={(e) => setFieldValue("name", localTitle, false)}
        onChange={(e) => {
          const { value } = e.target;
          setLocalTitle(value);
        }}
        error={errors.name}
        touched={touched.name}
        validate={() =>
          !values.name &&
          "Form title is required. No changes will be saved until a form title has been defined."
        }
        disabled={isPublished}
      />

      {/* Form Type */}
      <FastField
        name="type"
        label="Form Type"
        as={Select}
        options={mappedFormTypes}
        value={values.type.id}
        onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
          const typeId = Number(e.target.value);
          const type = formTypes.find((type) => type.id === typeId) || {
            id: -1,
          };
          setFieldValue("type", type);
        }}
        disabled={isPublished}
      />

      {/* Submission Title Source */}
      <Field
        name={`documentSummaryEntries[${titleSourceEntryIndex}]`}
        label="Submission Title Source"
        as={Select}
        options={titleSources}
        value={
          values.documentSummaryEntries?.find((entry) => entry.type === "TITLE")
            ?.sourceQuestionRootId || -1
        }
        onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
          const titleSourceId = Number(e.target.value);
          const entries = values.documentSummaryEntries || [];
          let updatedEntries = [...entries];

          if (titleSourceId === -1) {
            updatedEntries =
              entries.filter((entry) => entry.type !== "TITLE") || [];
          } else {
            const updatedEntry = {
              name: "title",
              sourceQuestionRootId: titleSourceId,
              type: "TITLE" as const,
            };

            updatedEntries = updateValueArray(
              entries,
              { key: "type", value: "TITLE" },
              updatedEntry
            );
          }

          setFieldValue("documentSummaryEntries", updatedEntries);
        }}
        disabled={isPublished}
      />

      {/* Email supervisor */}
      <Field
        name="emailSupervisor"
        as={YesOrNo}
        label="Email document creator’s supervisor automatically on form submission?"
        value={
          !!values.actions?.find((a) => a.actionType === "EMAIL_SUPERVISOR")
        }
        onChange={handleEmailChange("EMAIL_SUPERVISOR")}
        disabled={isPublished}
      />

      {/* Email participants */}
      <Field
        name="emailParticipants"
        as={YesOrNo}
        label="Email added participants automatically on form submission?"
        value={
          !!values.actions?.find((a) => a.actionType === "EMAIL_PARTICIPANTS")
        }
        onChange={handleEmailChange("EMAIL_PARTICIPANTS")}
        disabled={isPublished}
      />

      {/* Edit Period */}
      <Field
        name="formSubmissionConstraint.type"
        as={RadioButtonsGroup}
        value={values.formSubmissionConstraint?.type || ""}
        label="Edit Period"
        options={[
          { label: <TimeLimitOption disabled={isPublished} />, value: "HOUR" },
          { label: <TimeToOption disabled={isPublished} />, value: "TIME" },
          { label: <AlwaysOption />, value: "" },
        ]}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          setFieldValue("formSubmissionConstraint.type", e.target.value);
          setFieldValue(
            "formSubmissionConstraint.noSubmissionsUntilNextDay",
            e.target.value === "TIME"
          );
        }}
        disabled={isPublished}
      />

      {/* Edit Permissions */}
      <Field
        as={RadioButtonsGroup}
        name="allowedDocumentEditor"
        label="Edit Permissions"
        value={values.allowedDocumentEditor}
        options={[
          { label: "Creator Only", value: "DOCUMENT_CREATOR" },
          {
            label: "Creator and Supervisors",
            value: "DOCUMENT_CREATOR_AND_SUPERVISORS",
          },
          { label: "All Participants", value: "DOCUMENT_PARTICIPANTS" },
        ]}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          setFieldValue("allowedDocumentEditor", e.target.value);
        }}
        disabled={isPublished}
      />

      {/* Group Access */}
      <FastField
        touched={touched["clientGroups"]}
        error={errors["clientGroups"]}
        name="clientGroups"
        label="Group Access"
        placeholder="Add group"
        as={MultiInput}
        labelField="name"
        idField="id"
        selectedValues={values.clientGroups?.map((group) => ({
          ...group,
          name: group.name + (group.softDeleted ? " (deactivated)" : ""),
        }))}
        canUseCustomValues={false}
        autoCompleteSuggestions={clientGroups || []}
        onUpdateValues={(values: SuggestionType[]) =>
          setFieldValue(
            "clientGroups",
            values.map((val) => val)
          )
        }
        disabled={isPublished}
      />
    </div>
  );
};

export default SettingsForm;
