import React from "react";
import { css } from "aphrodite/no-important";

import styles from "./styles";
import { joinClassNames } from "themes/helpers";

interface Props {
  className?: string;
}

const ValidationStatusIndicator = ({ className }: Props) => {
  const s = styles();

  return (
    <div className={joinClassNames(css(s.statusIndicator), className)} />
  );
};

export default ValidationStatusIndicator;
