import React from "react";
import styled from "styled-components";
import FormControlBase, {
  FormControlProps as FormControlPropsBase,
} from "@material-ui/core/FormControl";
import FormControlLabelBase, {
  FormControlLabelProps as FormControlLabelPropsBase,
} from "@material-ui/core/FormControlLabel";
import InputLabelBase, { InputLabelProps } from "@material-ui/core/InputLabel";
import RadioBase, {
  RadioProps as RadioPropsBase,
} from "@material-ui/core/Radio";
import RadioGroupBase, {
  RadioGroupProps as RadioGroupPropsBase,
} from "@material-ui/core/RadioGroup";
import SvgIconBase, { SvgIconProps } from "@material-ui/core/SvgIcon";

import { remCalc } from "themes/helpers";
import mediaQueries from "themes/mediaQueries";

import { RadioInputVariant } from "./RadioInput";
import { FieldErrorType } from "components/FormController/components/Question";
import { ThemeTypes } from "themes/colors";

export const SvgIcon = styled<React.FC<SvgIconProps>>(({ ...otherProps }) => (
  <SvgIconBase {...otherProps} classes={{ root: "root" }} />
))`
  &&.root {
    font-size: ${remCalc(24)};

    @media (${mediaQueries.iPad}) {
      font-size: ${remCalc(24)};
    }
  }
`;

export const RadioInput = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
`;

interface OptionsProps {
  powerfieldsVariant: RadioInputVariant;
}

export const Options = styled<React.FC<OptionsProps>>(({
  powerfieldsVariant, ...otherProps
}) => {
  return <div {...otherProps} />;
})`
  ${({ powerfieldsVariant }) => {
    if (powerfieldsVariant === "RATING") {
      return `
        width: 100%;

        @media (${mediaQueries.maxTablet}) {
          max-width: ${remCalc(470)};
          align-self: flex-end;
        }

        @media (${mediaQueries.maxPhone}) {
          max-width: 100%;
        }
      `;
    }
  }}
`;

interface FormControlProps extends FormControlPropsBase {
  powerfieldsVariant: RadioInputVariant;
}

export const FormControl = styled<React.FC<FormControlProps>>(({
  powerfieldsVariant, ...otherProps
}) => (
  <FormControlBase {...otherProps} classes={{ root: "root" }} />
))`
  &&.root {
    &:hover {
      background-color: transparent;
    }

    ${({ powerfieldsVariant }) => {
      if (powerfieldsVariant === "RATING") {
        return `
          width: 100%;
        `;
      }
    }}
  }
`;

/* eslint-disable */

interface FormControlLabelProps extends FormControlLabelPropsBase {
  error?: FieldErrorType;
  isInputFocused: boolean;
  powerfieldsVariant: RadioInputVariant;
  theme: ThemeTypes;
}

export const FormControlLabel = styled<React.FC<FormControlLabelProps>>(
  ({ checked, isInputFocused, powerfieldsVariant, error, ...otherProps }) => (
    <FormControlLabelBase
      {...otherProps}
      checked={checked}
      classes={{ root: "root", label: "label", disabled: "disabled" }}
    />
  )
)`
  &&.root {
    border: 1px solid ${({ theme, checked }) =>
      checked ? theme.colors.primary : theme.masterColors.mediumGrey};
    ${({ error, isInputFocused, theme }) => {
      if (isInputFocused) {
        return `border-color: ${theme.colors.focusIndicatorOutline};`;
      }
      if (error) {
        return `border-color: ${theme.masterColors.error};`;
      }
    }}
    height: ${remCalc(32)};
    border-radius: ${remCalc(16)};
    min-width: ${remCalc(103)};
    background-color: ${({ checked, theme }) =>
      checked ? theme.colors.activeFill : theme.colors.formFieldBackground};
    margin-right: ${remCalc(36)};
    margin-left: 0px;
    margin-top: 0px;

    &&.disabled {
      border-color: ${({ theme }) => theme.masterColors.lightGrey};
    }

    ${({ isInputFocused, theme }) => {
      if (isInputFocused) {
        return `
          box-shadow: 0 0 0 ${remCalc(3)} ${theme.colors.focusIndicatorOutline};
          position: relative;
        `;
      }
    }}

    ${({ checked }) => {
      if (checked) {
        return `
          font-weight: 700;
        `;
      }
    }}

    /* HANDLE VARIANTS */
    /* - RATING */
    ${({ checked, isInputFocused, powerfieldsVariant, theme }) => {
      const nonHoverBackgroundColor = checked
        ? theme.colors.activeFill
        : isInputFocused
        ? theme.masterColors.lightBlue
        : theme.colors.formFieldBackground;

      if (powerfieldsVariant === "RATING") {
        // @NOTE padding is commented for all views atm, testing using flexbox and hopefully that works
        return `
          &&.root {
            min-height: ${remCalc(85)};
            border-radius: 0px;
            margin-left: 0px;
            margin-right: 0px;
            width: 100%;
            // padding: ${remCalc(0)} ${remCalc(20)} ${remCalc(4)} ${remCalc(
          20
        )};

            background-color: ${nonHoverBackgroundColor};

            &:hover {
              background-color: ${theme.masterColors.lightBlue};
            }

            @media (${mediaQueries.minTablet}) {
              align-items: center;
              display: flex;
              flex-direction: column;
              justify-content: center;
              margin-bottom: ${remCalc(24)};
              min-height: ${remCalc(95)};
              min-width: 25%;
              text-align: center;
            }
  
            @media (${mediaQueries.maxPhone}) {
              align-items: center;
              display: flex;
              flex-direction: column;
              justify-content: flex-start;
              margin-bottom: ${remCalc(26)};
              min-height: ${remCalc(95)};
              min-width: 25%;
              padding-top: ${remCalc(5)};
              padding-left: ${remCalc(10)};
              padding-right: ${remCalc(10)};
              text-align: center;
            }
          }

          &&.root:first-of-type {
            border-radius: ${remCalc(16)} 0px 0px ${remCalc(16)};
          }
          
          &&.root:last-of-type {
            border-radius:  0px ${remCalc(16)} ${remCalc(16)} 0px;
          }
        `;
      }
    }}


    @media (${mediaQueries.minTablet}) {
      ${({ powerfieldsVariant }) => {
        if (powerfieldsVariant !== "RATING") {
          return `
            height: ${remCalc(32)};
          `;
        }
      }}
      border-radius: ${remCalc(24)};
      min-width: ${remCalc(103)};
    }

   
    @media (${mediaQueries.iPhone5}) {
      // margin-bottom: ${remCalc(12)};
    }
  }

  && .label {
   color: ${({ theme, error }) =>
     error ? theme.masterColors.error : theme.colors.black};
   padding-right: 10px;
   width: 100%;
   text-align: center;

    ${({ checked, theme }) => {
      if (checked) {
        return `
          font-weight: 600;
          color: ${theme.masterColors.black};
        `;
      }
    }}

    // RATING VARIANT RESPONSIVE STYLES
    ${({ powerfieldsVariant }) => {
      if (powerfieldsVariant === "RATING") {
        return `
        @media (${mediaQueries.maxPhone}) {
          font-size: ${remCalc(12)};
        }
        `;
      }
    }}

  }
`;
/* eslint-enable */

export const InputLabel = styled<React.FC<InputLabelProps>>(
  ({ ...otherProps }) => (
    <InputLabelBase {...otherProps} classes={{ root: "root" }} />
  )
)`
  &&.root {
    color: ${({ theme }) => theme.colors.formFieldLabel};
    font-size: ${remCalc(20)};
    margin-bottom: ${remCalc(8)};
    font-weight: 500;

    @media (${mediaQueries.minTablet}) {
      font-size: ${remCalc(30)};
    }
  }
`;

export const ErrorLabel = styled<React.FC<InputLabelProps>>(
  ({ ...otherProps }) => <InputLabelBase {...otherProps} />
)``;

interface RadioProps extends RadioPropsBase {
  error?: FieldErrorType;
}

export const Radio = styled<React.FC<RadioProps>>((props) => (
  <RadioBase {...props} classes={{ root: "root" }} />
))`
  &&.root {
    color: ${({ theme, error }) =>
      error ? theme.masterColors.error : theme.colors.radioCircleBorder};
    // padding-left: ${remCalc(4)};
    width: auto;
    
    &:hover {
      background-color: transparent;
    }
  }
`;

interface RadioGroupProps extends RadioGroupPropsBase {
  powerfieldsVariant: RadioInputVariant;
  stacked?: boolean;
}

export const RadioGroup = styled<React.FC<RadioGroupProps>>(({
  powerfieldsVariant, stacked, ...otherProps
}) => (
  <RadioGroupBase
    {...otherProps}
    classes={{
      root: "root",
    }}
  />
))`
  &&.root {
    display: flex;
    flex-direction: ${({ stacked }) => (stacked ? "column" : "row")};

    &:hover {
      background-color: transparent;
    }

    ${({ powerfieldsVariant }) => {
      if (powerfieldsVariant === "RATING") {
        return `
          width: 100%;
          flex-wrap: nowrap;

          // MOBILE RESPONSIVE STYLES FOR RATING VARIANT
          @media (${mediaQueries.maxPhone}) {
            flex-direction: row;
          }
        `;
      }
    }}
  }
`;

export const CheckIcon = styled.span`
  width: 100%;
  height: 100%;
  color: ${({ theme }) => theme.colors.iconFill};
  background-color: ${({ theme }) => theme.colors.primary};
  border-radius: 50%;
  padding: ${remCalc(1)};

  @media (${mediaQueries.iPad}) {
    font-size: ${remCalc(24)};
  }
`;
