import * as Yup from "yup";

type ItemSchema = {
  title?: Yup.StringSchema<string>;
  participantRole?: Yup.StringSchema<string | null>;
  selections?: Yup.ArraySchema<any>; // @todo types
  answerSource?: Yup.StringSchema<string | null>;
  questions?: Yup.ArraySchema<any>; // @todo types
  conditionFields?: Yup.ArraySchema<string>;
}

type DisplayConditionSchema = {
  prefillAnswerField?: Yup.StringSchema<string>;
  action?: Yup.StringSchema<string>;
  sourceQuestionRootId?: Yup.NumberSchema<number>;
  targetRootId?: Yup.NumberSchema<number>;
  targetType?: Yup.StringSchema<string>;
}

const SELECTIONS_REQUIRED_FOR = ["DROP_DOWN", "MULTI_SELECT", "RADIO_BUTTONS"];

const requiredSchema = (fieldName: string) => Yup.string().required(`${fieldName} is required`);

const itemSchema = Yup.lazy(((item: any) => {
  const itemSchema: ItemSchema = {};

  if (item.conditionFields) {
    itemSchema.conditionFields = Yup.array().of(
      Yup.string().matches(/.*[^\.]$/, "Please select field")
    );
  }

  if (!item.parentWidgetRootId) { // don't validate widget children
    if (item.type === "QUESTION" || item.type === "SECTION") {
      itemSchema.title = requiredSchema("Title");
    }
    if ("selections" in item && !item.answerSource?.dataSourceKey) {
      if (SELECTIONS_REQUIRED_FOR.includes(item.subType)) {
        itemSchema.selections = Yup.array().min(1, "At least one selection is required");
      }
    }
    if (item.subType === "PARTICIPANT") {
      itemSchema.participantRole = requiredSchema("Participant Role").nullable();
      delete(itemSchema.selections);
      if (!item.answerSource?.dataSourceKey && item.answerSource?.type !== "CURRENT_PARTICIPANT") {
        itemSchema.answerSource = requiredSchema("Participant data source").nullable();
      }
    }
    if (["DOCUMENT_CREATOR", "SUPERVISOR"].includes(item.subType)) {
      delete(itemSchema.selections);
      itemSchema.title = requiredSchema("Title");
    }

    if (item.subType === "SAFETY_RATING") {
      itemSchema.questions = Yup.array().min(1, "At least one area to review is required");
    }
  }

  return Yup.object().shape(itemSchema);
}));

const FormValidationSchema = Yup.object().shape({
  clientGroups: Yup.array()
    .min(1, "Group Access: At least one client group is needed")
    .required("Group Access is required"),
  name: Yup.string().required("Form title is required"),
  sections: Yup.array().of(Yup.object().shape({
    // title: requiredSchema("Title"),
    items: Yup.array().of(itemSchema)
  })),
});

export default FormValidationSchema;
