import { Pagination } from "store/common/types";

export interface TagDTO {
  archived?: boolean;
  createdDate: string;
  id: number;
  name: string;
  tagIdentifier?: string;  // Required for GET, should not be passed for PUT/POST
  useCount: number;
}

export interface TagVm {
  id: number;
  name: string;
}

export interface TagLibrariesResponse extends Pagination {
  content: TagDTO[];
}

export const GET_TAG_LIBRARIES = {
  REQUEST: "GET_TAG_LIBRARIES_REQUEST",
  SUCCESS: "GET_TAG_LIBRARIES_SUCCESS",
  FAILURE: "GET_TAG_LIBRARIES_FAILURE",
};

export interface GetTagLibrariesAction {
  response: TagLibrariesResponse;
  type: typeof GET_TAG_LIBRARIES.REQUEST | typeof GET_TAG_LIBRARIES.SUCCESS | typeof GET_TAG_LIBRARIES.FAILURE;
}


export const GET_TAG_BY_ID = {
  REQUEST: "GET_TAG_BY_ID_REQUEST",
  SUCCESS: "GET_TAG_BY_ID_SUCCESS",
  FAILURE: "GET_TAG_BY_ID_FAILURE",
};

export interface GetTagByIdAction {
  response: TagDTO;
  type: typeof GET_TAG_BY_ID.REQUEST | typeof GET_TAG_BY_ID.SUCCESS | typeof GET_TAG_BY_ID.FAILURE;
}

export const ADD_TAG = {
  REQUEST: "ADD_TAG_REQUEST",
  SUCCESS: "ADD_TAG_SUCCESS",
  FAILURE: "ADD_TAG_FAILURE",
};

export const UPDATE_TAG = {
  REQUEST: "UPDATE_TAG_REQUEST",
  SUCCESS: "UPDATE_TAG_SUCCESS",
  FAILURE: "UPDATE_TAG_FAILURE",
};

export const DELETE_TAG = {
  REQUEST: "DELETE_TAG_REQUEST",
  SUCCESS: "DELETE_TAG_SUCCESS",
  FAILURE: "DELETE_TAG_FAILURE",
};

// For updateTag()
export type UpdateTagBody = {
  id: TagDTO["id"];
  archived: TagDTO["archived"];
}
