import React, { useMemo, useState } from "react";

import Menu, { MenuItems } from "components/common/Menu";
import Tutorial from "components/dashboard/Tutorial";

import * as S from "./styles";

const SettingsMenu = () => {
  const [tutorialIsVisible, setTutorialIsVisible] = useState(false);

  const menu: MenuItems = useMemo(() => {
    return [
      {
        label: "Run Tutorial Walkthrough",
        onClick: () => setTutorialIsVisible(true),
      },
    ];
  }, [setTutorialIsVisible]);
  return (
    <S.SettingsMenu>
      <Tutorial
        open={tutorialIsVisible}
        endTutorial={() => setTutorialIsVisible(false)}
      />
      <Menu menuItems={menu} />
    </S.SettingsMenu>
  );
};

export default SettingsMenu;
