import React, { useState } from "react";

import * as S from "components/screens/documents/styles";
import * as Styles from "./styles";
import { DocumentSummary, DocumentVM } from "store/documents/types";
import * as newDocumentActions from "store/newDocument/actions";
import { bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";
import { NewDocumentState } from "store/newDocument/types";
import { AppState } from "store";
import { getDocument } from "store/documents/actions";

import useStartCloneDoc from "components/DocumentSidebarDrawer/InterstitialDrawer/useStartCloneDoc";
import DrawerContentWrapper from "components/common/Drawer/components/DrawerContentWrapper";
import StickyHeader from "components/common/Drawer/components/StickyHeader";
import useGroupTerm from "util/hooks/whiteLabel/useGroupTerm";
import { getSubmissionTimeError } from "components/DocumentSidebarDrawer/helpers";

interface Props {
  onSuccess: () => void;
  toggleRehuddleSidebarVisibility: () => void;
  selectedDocument: DocumentSummary | DocumentVM | null;
  history: any;
  store: {
    newDocument: NewDocumentState;
  };
  actions: {
    newDocument: {
      createDocument: ({
        submissionDate,
        formId,
        workOrderId,
        parentId,
      }: {
        submissionDate: string;
        formId: number;
        workOrderId?: number;
        parentId: number;
        isRehuddle?: boolean;
      }) => any;
      submitDocument: (
        document: DocumentVM,
        submissionType: newDocumentActions.SubmissionType
      ) => any;
    };
    getDocument: (documentId: number) => any;
  };
  currentDocumentId?: number;
}

const RehuddleSidebar = ({
  onSuccess,
  toggleRehuddleSidebarVisibility,
  selectedDocument,
  store,
}: Props) => {
  const { handleStartCloneDoc: handleCloneFromHook } = useStartCloneDoc();
  const [rehuddleError, setRehuddleError] = useState("");

  // Group config terms
  const documentTerm = useGroupTerm("document", "noun", undefined, "Document");
  const documentTermLower = documentTerm.toLowerCase();
  const rehuddleTerm = useGroupTerm("refocus", "noun", undefined, "Rehuddle");
  const rehuddleTermLower = rehuddleTerm.toLowerCase();
  const rehuddleTermUpper = rehuddleTerm.toUpperCase();

  const { createDocument: createDocumentLoading } = store.newDocument.loading;

  const handleRehuddle = () => {
    selectedDocument &&
      handleCloneFromHook({
        displayConditions: [],
        selectedDocument,
        isRehuddle: true,
        onError: setRehuddleError,
        onSuccess,
      });
  };

  // is the current time past the form submission time constraint
  const submissionTimeError = getSubmissionTimeError(
    selectedDocument?.formSummary.formSubmissionConstraint
  );

  return (
    <>
      {/* Header */}
      <StickyHeader
        title={rehuddleTerm}
        onClick={toggleRehuddleSidebarVisibility}
        chevronRight={false}
      />
      {/* Content */}
      <DrawerContentWrapper>
        <S.SidebarDrawerHeader>{rehuddleTerm}</S.SidebarDrawerHeader>
        <S.SidebarDrawerBody>
          Kicking off a {rehuddleTermLower} will create a new{" "}
          {documentTermLower} that carries over only some key information from
          the original while providing cleared fields for the rest of the form.
        </S.SidebarDrawerBody>
        <Styles.Error message={rehuddleError} />
        {submissionTimeError && <Styles.Error message={submissionTimeError} />}
        <Styles.ButtonsWrapper>
          <Styles.SubmitButton
            onClick={handleRehuddle}
            fullWidth={true}
            height={2.25}
            disabled={createDocumentLoading || !!submissionTimeError}
            loading={createDocumentLoading}
          >
            START {rehuddleTermUpper}
          </Styles.SubmitButton>
          <Styles.CancelButton
            onClick={toggleRehuddleSidebarVisibility}
            variant={"unstyled-danger"}
            fullWidth={true}
            height={2.25}
            disabled={createDocumentLoading}
          >
            Cancel
          </Styles.CancelButton>
        </Styles.ButtonsWrapper>
      </DrawerContentWrapper>
    </>
  );
};

const mapStateToProps = (state: AppState) => ({
  store: {
    newDocument: state.newDocument,
  },
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  actions: {
    newDocument: bindActionCreators(newDocumentActions, dispatch),
    getDocument: bindActionCreators(getDocument, dispatch),
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(RehuddleSidebar);
