import React from "react";

import * as S from "./styles";

interface Props {
  visible: boolean;
  color?: string;
  className?: string;
}

/** SVG path for default pin */
const DEFAULT_PIN = `M256 0.006c-98.995 0-179.195 80.247-179.195 179.195 0 128.013 179.195 332.787 179.195
 332.787s179.195-204.798 179.195-332.787c0-98.996-80.247-179.195-179.195-179.195zM256 243.232c-35.347 
 0-64.007-28.66-64.007-64.006s28.66-64.007 64.007-64.007 64.007 28.659 64.007 64.007c0 35.347-28.659 
 64.007-64.007 64.007z`;

/** Custom Pin class */
export default function Pin({ visible, color, className }: Props) {
  return (
    <div style={!visible ? { opacity: 0 } : {}}>
      <S.Pin color={color} viewBox="0 0 550 550" className={className}>
        <path d={DEFAULT_PIN} />
      </S.Pin>
    </div>
  );
}
