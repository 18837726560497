import styled from "styled-components";

const ContentWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 65vh;
  justify-content: center;
`;

const ComingSoonText = styled.div`
  align-items: center;
  color: ${({ theme }) => theme.masterColors.mediumGrey};
  display: flex;
  font-size: 0.875rem;
  height: 50%;
  text-transform: uppercase;
`;

const LogoutText = styled.span`
  align-items: flex-end;
  bottom: 100px;
  color: ${({ theme }) => theme.colors.primary};
  cursor: pointer;
  display: flex;
  font-size: 0.875rem;
  position: absolute;
  text-transform: uppercase;
`;

export { ComingSoonText, ContentWrapper, LogoutText };
