/* This is a copy from fe-common that we need to modify without trying to upgrade the library that could
 ** potentionally mess up other areas of code that we don't know about. ST-33 (newboard)
 ** https://domsafetytools.atlassian.net/browse/ST-33 **/

import {
  DocumentQuestionResponseVm,
  FormSectionVm,
  Functions,
  QuestionDTO,
  isQuestionDTO,
} from "@rtslabs/field1st-fe-common";

export interface StartRelatedArgs {
  parentResponses: DocumentQuestionResponseVm[];
  parentSections: FormSectionVm[];
  childSections: FormSectionVm[];
}

export interface ClonedResponses {
  responses: DocumentQuestionResponseVm[];
}

function findMatchingQuestionInSection(
  childSections: FormSectionVm[],
  relatedSourceQuestion: QuestionDTO
): QuestionDTO | undefined {
  // try first by root id
  const byRootId = Functions.findQuestionByRootId(
    childSections,
    relatedSourceQuestion.rootId
  );
  if (byRootId) {
    return byRootId.question;
  }

  // then try by type & title
  for (const section of childSections) {
    for (const item of section.items) {
      if (
        isQuestionDTO(item) &&
        item.subType === relatedSourceQuestion.subType &&
        item.title.toLowerCase() === relatedSourceQuestion.title.toLowerCase()
      ) {
        return item;
      }
    }
  }

  // give up
  return undefined;
}

/**
 * Takes a raw response and makes sure 'answer' and associated ids line up with the target question appropriately
 */
export function alignWithSelectionOrBoolean(
  response: DocumentQuestionResponseVm,
  question: QuestionDTO
): DocumentQuestionResponseVm {
  // match question selections
  if (
    [
      "MULTI_SELECT",
      "CHECKBOX",
      "DROP_DOWN",
      "RADIO_BUTTONS",
      "RATING",
    ].includes(question.subType) &&
    question.selections &&
    question.selections.length > 0
  ) {
    const match = question.selections.filter((s) => {
      return s.title.toLowerCase() === response.answer.toLowerCase();
    })[0];
    return {
      ...response,
      answer: match?.title || "",
      associatedId: match?.id,
      associatedRootId: match?.rootId,
    };

    // match boolean
  } else if ("YES_OR_NO" === question.subType) {
    let answer = response.answer.toLowerCase();
    if (answer === "true" || answer === "yes") {
      answer = "true";
    } else if (answer === "false" || answer === "no") {
      answer = "false";
    } else {
      answer = "";
    }
    return { ...response, answer };

    // just return string
  } else {
    // decided not to match question selections
    // maybe something with date time?
    return response;
  }
}

/**
 * Standard start related document process to generate responses from another document (parent)
 * Throws an error if:
 *   - parentResponses has a response to a question not in parentSections
 * Edge cases not covered:
 *   - a target question is matched with multiple source questions (same id as one and title as other?)
 *   - multiple target questions with the same title (it will just fill first found)
 */
export function cloneRelatedDocumentResponses(
  args: StartRelatedArgs
): ClonedResponses {
  const { parentResponses, parentSections, childSections } = args;
  const responses: DocumentQuestionResponseVm[] = [];
  for (const parentResponse of parentResponses) {
    const { question } = Functions.findOrThrowQuestionById(
      parentSections,
      parentResponse.questionId
    );

    if (
      !question.formProperties?.isCloneable ||
      (question.type === "QUESTION" &&
        question.answerSource?.properties?.readOnly &&
        question.answerSource?.type === "CURRENT_PARTICIPANT")
    ) {
      continue;
    }

    const targetQuestion = findMatchingQuestionInSection(
      childSections,
      question
    );

    if (targetQuestion) {
      const childResponse = alignWithSelectionOrBoolean(
        {
          answer: parentResponse.answer,
          associatedId: parentResponse.associatedId,
          associatedRootId: parentResponse.associatedRootId,
          associatedLocation: parentResponse.associatedLocation,
          comments: parentResponse.comments,
          questionId: targetQuestion.id,
          questionRootId: targetQuestion.rootId,
          timeAnswered: new Date().toISOString(),
        },
        targetQuestion
      );

      if (childResponse.answer) {
        responses.push(childResponse);
      }
    }
  }

  return { responses };
}
