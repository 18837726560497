import React, { useState, useEffect, useCallback, useMemo } from "react";
import moment from "moment";
import qs from "qs";
import { Action } from "redux";
import { Dispatch, bindActionCreators } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { connect, useDispatch } from "react-redux";

import { AppState } from "store";
import { FormDTO, QuestionDTO } from "store/forms/types";
import {
  deleteDocument,
  searchDocuments,
  SearchDocuments,
} from "store/documents/actions";
import { getFormStats } from "store/reporting/singleFormStats/actions";
import { FormStats } from "store/reporting/singleFormStats/types";
import {
  searchDocumentsForFormReport,
  SearchDocumentsForFormReportSignature,
} from "store/documents/selectors";

import { pluralize } from "components/helpers";
import {
  TimeFilterType,
  getMinMaxSubmissionDatesFromTimeFilter,
  timeFilterOptions,
} from "components/helpers/filters";
import Breadcrumbs from "components/common/Breadcrumbs";
import TableSummary from "components/common/TableUI/TableSummary";
import Loader from "components/common/Loader";

import * as UserStyles from "components/clientAdmin/users/styles";
import DeleteDocumentButton from "components/clientAdmin/documents/DeleteDocumentButton";
import DocumentDrawer, {
  DocumentDrawerSections,
} from "components/clientAdmin/documents/DocumentDrawer";

import Filters from "./Filters";
import FormReportHeader from "./FormReportHeader";
import FormTabs from "./FormTabs";
import Modal from "components/common/Modal";
import Popper from "components/common/Popper";
import Statistics from "./Statistics";
import withClientGroups, { Groups } from "../../dataHelpers/withClientGroups";
import { ContentWrapper } from "components/common/Wrappers";
import { DeleteDocumentConfirmation } from "components/clientAdmin/users/modalContent/DeleteDocumentConfirmation";
import { DocumentSummary, DELETE_DOCUMENT } from "store/documents/types";
import {
  PopperChevron,
  PopperRow,
  PopperWrapper,
} from "components/clientAdmin/users/styles";
import { ReportsDocumentsSorting } from "./DocumentsTable";
import { RouteComponentProps } from "react-router";
import { SubmitButton } from "components/clientAdmin/styles";
import { Text } from "components/clientAdmin/styles";
import { default as CommonText } from "components/common/Text";
import { generateGroupOptions } from "../../helpers";
import { useRatingQuestions } from "./helpers";
import * as S from "./styles";
import useGroupTerm from "util/hooks/whiteLabel/useGroupTerm";
import { buildFilteredEndpoint } from "store/common/apiUtilities";
import { DateRangeValue } from "components/common/DateRange";
import useDebounce from "util/hooks/useDebounce";
import { Authority } from "store/usersParticipants/types";

const DeleteDocumentMenuItem = ({
  document,
  closeMenu,
  openConfirmationModal,
}) => {
  // Group config terms
  const documentTerm = useGroupTerm("document", "noun", undefined, "Document");
  const documentTermLower = documentTerm.toLowerCase();

  return (
    <DeleteDocumentButton document={document}>
      {(deleting, errorDeleting) => (
        <Loader loading={deleting}>
          <S.DeleteDocumentButton
            onClick={() => {
              openConfirmationModal();
              closeMenu();
            }}
          >
            Delete {documentTermLower} (permission required)
            {errorDeleting && (
              <CommonText className="ml-2" variant="error">
                An error occurred
              </CommonText>
            )}
          </S.DeleteDocumentButton>
        </Loader>
      )}
    </DeleteDocumentButton>
  );
};

interface FormProps extends RouteComponentProps<{ id: string }> {
  groups: Groups;
  form: {
    data: FormDTO | null;
    loading: boolean;
    error: boolean | { message: string; status: string };
  };
  formId: number;
  formStats: {
    data: FormStats | null;
    loading: boolean;
    error: boolean;
  };
  actions: {
    documents: {
      searchDocuments: SearchDocuments;
    };
    getFormStats: (id, params) => void;
  };
  searchDocumentsLoading?: boolean;
  selectors: {
    searchDocumentsForFormReport: SearchDocumentsForFormReportSignature;
  };
  userRoles?: Authority[];
}

export interface SafetyResponse {
  question: QuestionDTO;
  responseCount: number;
  area: string;
  selectionId: number;
}

const FormReport = ({
  groups,
  formId,
  selectors,
  actions,
  formStats,
  searchDocumentsLoading,
  location,
  userRoles,
}: FormProps) => {
  const params = qs.parse(location.search, { ignoreQueryPrefix: true });
  const [query, setQuery] = useState<string>("");
  const [clientGroupId, setClientGroupId] = useState<string | number>(
    params.clientGroupId || -1
  );
  const [timeFilterType, setTimeFilterType] = useState<TimeFilterType>(
    "ALL_TIME"
  );
  const [dateRange, setDateRange] = useState<DateRangeValue | null>(null);
  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(10);
  const [maxDate, setMaxDate] = useState<string>("");
  const [minDate, setMinDate] = useState<string>("");
  const [ratingsFilter, setRatingsFilter] = useState<Array<string>>([]);
  const [subordinatesFilter, setSubordinatesFilter] = useState<string>(
    "ALL_USERS"
  );
  const [tabIndex, setTabIndex] = useState(0);

  const minMaxDate = useMemo(() => {
    return getMinMaxSubmissionDatesFromTimeFilter(timeFilterType, dateRange);
  }, [timeFilterType, dateRange]);
  const groupFilterOptions = generateGroupOptions(groups.data);

  const dispatch = useDispatch<ThunkDispatch<AppState, void, Action>>();

  // Sorting
  const [sorting, setSorting] = useState<ReportsDocumentsSorting>({
    // Order is by appearance in table
    title: {
      direction: "asc",
    },
    ["clientGroup.name"]: {
      direction: "asc",
    },
    ["owner.lastName"]: {
      direction: "asc",
    },
    dateCreated: {
      direction: "asc",
    },
    submissionDate: {
      direction: "asc",
    },
    primarySort: {
      // Sort by this column
      direction: "desc",
      type: "submissionDate",
    },
  });

  const paginateDocuments = (pageNum, numPerPage) => {
    setPage(pageNum);
    setPageSize(numPerPage);
  };

  const requestDocuments = () => {
    // format and set dates for use in summary, empty string if not set
    setMaxDate(
      minMaxDate.maxSubmissionDate === ""
        ? ""
        : moment(minMaxDate.maxSubmissionDate).format("MM/DD/YYYY")
    );
    setMinDate(
      minMaxDate.minSubmissionDate === ""
        ? ""
        : moment(minMaxDate.minSubmissionDate).format("MM/DD/YYYY")
    );
    // get stats and documents
    actions.getFormStats(formId, {
      clientGroupIds: clientGroupId,
      participantTreeFilter: subordinatesFilter,
      maxSubmissionDate: minMaxDate.maxSubmissionDate,
      minSubmissionDate: minMaxDate.minSubmissionDate,
    });
    actions.documents.searchDocuments({
      clientGroupIds: clientGroupId,
      formIds: formId,
      infinite: false,
      participantTreeFilter: subordinatesFilter,
      maxSubmissionDate: minMaxDate.maxSubmissionDate,
      minSubmissionDate: minMaxDate.minSubmissionDate,
      page: page,
      query: query,
      size: pageSize,
      sortName: sorting.primarySort.type,
      sortOrder: sorting.primarySort.direction,
      submissionTypes: "SUBMIT",
    });
  };

  const debouncedSetQuery = useDebounce({
    method: (q) => setQuery(q),
  });

  useEffect(() => {
    requestDocuments();
  }, [
    clientGroupId,
    subordinatesFilter,
    minMaxDate,
    sorting,
    pageSize,
    page,
    query,
  ]);

  const { mappedResponses, totalResponses, SO_FLAG } = useRatingQuestions({
    formStats,
  });

  const subs = () =>
    pluralize("submission", formStats?.data?.totalSubmissions || 1, false);

  /** Generate summary text for summary line */
  const summaryText = () => {
    // @minDate and @maxDate are from component's state
    if (formStats?.data) {
      let text = `Showing responses for ${
        formStats.data.totalSubmissions
      } ${subs()}`;
      if (minDate && maxDate) {
        text = `${text} from ${minDate}`;
        if (maxDate !== minDate) {
          text = `${text} - ${maxDate}`;
        }
      }
      return text;
    }
    return null;
  };

  // Action menu
  // const [selectedDocumentId, setSelectedDocumentId] = useState<number | null>(null);
  const [showDocumentDrawer, setShowDocumentDrawer] = useState<
    string | boolean
  >(false);
  const [popperIsOpen, setPopperIsOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState("");

  // Delete document
  const [deleteDocumentError, setDeleteDocumentError] = useState(false);
  const [deleteDocumentIsLoading, setDeleteDocumentIsLoading] = useState(false);
  const [
    deleteDocumentConfirmationModalIsVisible,
    setDeleteDocumentConfirmationModalIsVisible,
  ] = useState(false);
  const [
    deleteDocumentOutcomeModalIsVisible,
    setDeleteDocumentOutcomeModalIsVisible,
  ] = useState(false);
  const [anchorDocument, setAnchorDocument] = useState<DocumentSummary>();

  const exportUrl = useMemo(
    () =>
      buildFilteredEndpoint(`reports/form-responses/${formId}`, {
        clientGroupIds: clientGroupId !== -1 ? clientGroupId : undefined,
        ...minMaxDate,
        includeRemovedQuestions: true,
      }),
    [formId, clientGroupId, minMaxDate]
  );

  const _deleteDocument = async () => {
    setDeleteDocumentIsLoading(true);
    const res: {
      type:
        | typeof DELETE_DOCUMENT.REQUEST
        | typeof DELETE_DOCUMENT.SUCCESS
        | typeof DELETE_DOCUMENT.FAILURE;
    } = await dispatch(
      deleteDocument((anchorDocument && anchorDocument.id) || 0)
    );

    setDeleteDocumentIsLoading(false);
    setDeleteDocumentConfirmationModalIsVisible(false);
    setDeleteDocumentOutcomeModalIsVisible(true);
    if (res.type === "DELETE_DOCUMENT_SUCCESS") {
      setDeleteDocumentError(false);
      setShowDocumentDrawer(false); // Close drawer if document is deleted
    } else {
      setDeleteDocumentError(true);
    }
  };

  const viewDocument = useCallback(() => {
    if (anchorDocument && anchorDocument.id) {
      setPopperIsOpen(false);
      setShowDocumentDrawer(DocumentDrawerSections.Document); // View doc
    }
  }, [anchorEl]);

  // Group config terms
  const documentTerm = useGroupTerm("document", "noun", undefined, "Document");
  const documentTermLower = documentTerm.toLowerCase();

  const datePeriod = useMemo(() => {
    // If custom range picked and range is selected
    if (timeFilterType === "CUSTOM_RANGE" && dateRange) {
      return `${moment(dateRange.startDate).format("MM/DD/YYYY")} - ${moment(
        dateRange?.endDate
      ).format("MM/DD/YYY")}`;
    }
    return (
      timeFilterOptions.find((option) => option.id === timeFilterType)?.value ||
      ""
    );
  }, [dateRange, timeFilterType]);

  return (
    <>
      <DocumentDrawer
        closeDrawer={() => {
          setShowDocumentDrawer(false);
          // setSelectedDocumentId(null);
        }}
        documentId={anchorDocument && anchorDocument.id}
        show={showDocumentDrawer}
        openDeleteModal={() => {
          setDeleteDocumentConfirmationModalIsVisible(true);
          // setShowDocumentDrawer(false);
          // setSelectedDocumentId(null);
        }}
        onClose={() => {
          setShowDocumentDrawer(false);
          // setSelectedDocumentId(null);
        }}
      />
      {/* Modal for deleting a user */}
      <Modal
        border="none"
        open={deleteDocumentConfirmationModalIsVisible}
        handleClose={() => {
          setDeleteDocumentConfirmationModalIsVisible(false);
        }}
        height="auto"
        width="652px"
        padding="24px 23px"
        content={
          <DeleteDocumentConfirmation
            // user data
            created={anchorDocument && anchorDocument.dateCreated}
            group={
              anchorDocument &&
              anchorDocument.clientGroup &&
              anchorDocument.clientGroup.name
            }
            title={anchorDocument && anchorDocument.title}
            id={anchorDocument && anchorDocument.id}
            // rest
            loading={deleteDocumentIsLoading}
            onCancel={() => setDeleteDocumentConfirmationModalIsVisible(false)}
            onSubmit={() => _deleteDocument()}
          />
        }
      />
      {/* Modal for the outcome when deleting a document */}
      <Modal
        border="none"
        open={deleteDocumentOutcomeModalIsVisible}
        handleClose={() => {
          !deleteDocumentError && requestDocuments();
          setDeleteDocumentError(false);
          setDeleteDocumentOutcomeModalIsVisible(false);
        }}
        height="auto"
        width="652px"
        padding="24px 23px"
        content={
          <UserStyles.ModalPromptWrapper>
            <UserStyles.ModalPromptTitle>
              {deleteDocumentError
                ? `Error: ${documentTerm} could not be deleted`
                : `${documentTerm} has been deleted`}
            </UserStyles.ModalPromptTitle>
            <UserStyles.ModalPromptSubtitle>
              {deleteDocumentError
                ? `A problem has occurred while attempting to delete the selected ${documentTermLower}. Try again later or contact your system administrator for support.`
                : `The selected ${documentTermLower} has been successfully deleted. ${documentTerm} will no longer impact relevant reporting.`}
            </UserStyles.ModalPromptSubtitle>
            <UserStyles.DisableUserButtonsWrapper>
              <SubmitButton
                onClick={() => {
                  !deleteDocumentError && requestDocuments();
                  setDeleteDocumentError(false);
                  setDeleteDocumentOutcomeModalIsVisible(false);
                }}
              >
                <span>close </span>
              </SubmitButton>
            </UserStyles.DisableUserButtonsWrapper>
            <UserStyles.ModalPromptCloseIcon
              onClick={() => {
                !deleteDocumentError && requestDocuments();
                setDeleteDocumentError(false);
                setDeleteDocumentOutcomeModalIsVisible(false);
              }}
            />
          </UserStyles.ModalPromptWrapper>
        }
      />
      <Popper
        anchorEl={anchorEl}
        onClose={() => {
          // setPopperIsOpen(false);
        }}
        open={popperIsOpen}
        placement="left"
      >
        <PopperWrapper>
          <PopperRow onClick={viewDocument}>
            <Text fontSize="0.9rem">View {documentTermLower}</Text>
            <PopperChevron />
          </PopperRow>
          <PopperRow
            onClick={() => {
              setPopperIsOpen(false);
              // setSelectedDocumentId(anchorEl && anchorEl.id);
              setShowDocumentDrawer(DocumentDrawerSections.History);
            }}
          >
            <Text fontSize="0.9rem">View {documentTermLower} history</Text>
            <PopperChevron />
          </PopperRow>
          {userRoles?.some((ur) => ur.authority === "ROLE_CLIENT_ADMIN") && (
            <PopperRow>
              <DeleteDocumentMenuItem
                document={document}
                closeMenu={() => setPopperIsOpen(false)}
                openConfirmationModal={() =>
                  setDeleteDocumentConfirmationModalIsVisible(true)
                }
              />
              <PopperChevron />
            </PopperRow>
          )}
        </PopperWrapper>
      </Popper>
      <ContentWrapper id="mainContent">
        <Breadcrumbs
          paths={[
            {
              pathName: "Reports",
              href: "/reports",
            },
            {
              pathName: `Form Submission Report: ${formStats?.data?.form.name}`,
              href: `/reports/${formId}`,
            },
          ]}
        />

        {formStats?.data && (
          <FormReportHeader
            formStats={formStats.data}
            datePeriod={datePeriod}
            ratingsFilter={ratingsFilter}
            safetyFlag={SO_FLAG}
            totalResponseCount={totalResponses}
            safetyResponses={mappedResponses}
            safetyResponsesLoading={searchDocumentsLoading}
          />
        )}

        <div id="caFormFiltersAndStats">
          <Filters
            dateRange={dateRange}
            groupFilter={{
              groupId: clientGroupId,
              setGroupId: setClientGroupId,
              groupFilterOptions,
            }}
            handleChangeDateRange={setDateRange}
            ratingsFilter={{
              ratingsFilter,
              setRatingsFilter,
              ratingOptions: Object.keys(mappedResponses),
            }}
            subordinatesFilter={{ subordinatesFilter, setSubordinatesFilter }}
            safetyFlag={SO_FLAG}
            setQuery={debouncedSetQuery}
            timeFilter={{ timeFilterType, setTimeFilterType }}
          />
          {formStats.data && <Statistics formStats={formStats.data} />}
        </div>

        <div id="caFormTableSummary" className="mt-3 mb-4">
          <TableSummary
            pageSize={pageSize}
            currentPage={page}
            totalElements={
              selectors.searchDocumentsForFormReport.totalElements || 0
            }
            ofWhat="submissions"
            exports={["print", "xls", "csv"]}
            exportUrl={exportUrl}
            summary={summaryText()}
          />
        </div>
        <Loader
          loading={formStats.loading}
          error={
            formStats.error
              ? "An error occurred when loading the form. Please try again"
              : false
          }
        >
          <FormTabs
            minMaxDate={minMaxDate} // Needed for safety report questions
            formId={formId}
            onPaginate={paginateDocuments}
            formStats={formStats}
            value={tabIndex}
            handleTabChange={(event, newIndex) => setTabIndex(newIndex)}
            pagination={{
              currentPage: page,
              pageSize,
            }}
            popper={{
              popperIsOpen,
              setAnchorDocument,
              setAnchorEl,
              setPopperIsOpen,
              setShowDocumentDrawer,
            }}
            shouldRenderSafetyTab={SO_FLAG}
            sorting={{
              setSorting,
              sorting,
            }}
            safetyReportProps={{
              totalResponses: totalResponses,
              ratingsFilter: ratingsFilter,
              mappedRiskResponses: mappedResponses,
            }}
            documents={selectors.searchDocumentsForFormReport.searchResults}
          />
        </Loader>
      </ContentWrapper>
    </>
  );
};

const mapStateToProps = (state: AppState, props: FormProps) => {
  const formId = parseInt(props.match.params.id);
  return {
    searchDocumentsLoading: state.documents.loading.searchDocuments.GET,
    formId: formId,
    formStats: {
      data: state.formStats.data[formId],
      loading:
        (state.formStats.loading[formId] &&
          state.formStats.loading[formId].GET) ||
        false,
      error:
        (state.formStats.errors[formId] &&
          state.formStats.errors[formId].GET) ||
        false,
    },
    selectors: {
      searchDocumentsForFormReport: searchDocumentsForFormReport(
        state.documents
      ),
    },
    userRoles: state.user.data?.authorities,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  actions: {
    documents: bindActionCreators(
      { deleteDocument: deleteDocument, searchDocuments: searchDocuments },
      dispatch
    ),
    getFormStats: bindActionCreators(getFormStats, dispatch),
  },
});

export default withClientGroups(
  connect(mapStateToProps, mapDispatchToProps)(FormReport)
);
