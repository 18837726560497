import * as React from "react";

import { FieldErrorType } from "components/FormController/components/Question";
import Comment, { CommentRefType } from "components/forms/Comment";

import { CheckBoxVariantType } from "./components/CheckboxGroup";
import * as S from "./styles";

export interface CheckboxProps {
  assistiveOnClick?: (e?: any) => void;
  checked: boolean;
  className?: string;
  commentError?: string;
  commentRef?: React.Ref<CommentRefType>;
  commentRequired?: boolean;
  commentValue?: string;
  enabledComment?: boolean;
  enabledComments?: boolean;
  error?: FieldErrorType;
  fullWidth?: boolean;
  handleBlur?: () => void;
  handleChange: () => void;
  handleClearComment: () => void;
  handleUpdateComment: (value?: string) => void;
  id: string | number;
  questionId: number;
  key?: number;
  label: string;
  name: string | number;
  onSetActive?: (id: number) => void;
  variant: CheckBoxVariantType;
}

const CheckboxInput = ({
  checked,
  className,
  commentError,
  commentRef,
  commentRequired,
  commentValue,
  enabledComment,
  error,
  fullWidth,
  handleChange,
  handleClearComment,
  handleUpdateComment,
  id,
  questionId,
  label,
  variant,
  name,
}: CheckboxProps) => {
  const [isCheckboxFocused, setIsCheckboxFocused] = React.useState(false);

  const classStyle = {
    paddingTop: ".5em",
    paddingBottom: ".5em",
    marginRight: "0px",
  };

  const handleBlurCheckbox = () => {
    setIsCheckboxFocused(false);
  };

  const handleFocusCheckbox = () => {
    setIsCheckboxFocused(true);
  };

  return (
    <>
      <S.CheckboxInput
        active={checked}
        enabledComment={enabledComment}
        error={error}
        fullWidth={fullWidth}
        isCheckboxFocused={isCheckboxFocused}
        variant={variant}
      >
        <S.FormControlLabel
          className={className}
          style={classStyle}
          fullWidth={fullWidth}
          error={error}
          control={
            <S.FormControl>
              <S.Checkbox
                checkedIcon={<S.CheckedIcon />}
                icon={<S.UnCheckedIcon />}
                name={`${name}`}
                onChange={handleChange}
                onKeyPress={(e) => e.key === "Enter" && handleChange()}
                onBlur={handleBlurCheckbox}
                onFocusVisible={handleFocusCheckbox}
                checked={checked}
                error={error}
              />
            </S.FormControl>
          }
          label={label}
        />

        {enabledComment && checked && (
          <S.CommentRow>
            <Comment
              error={commentError}
              name={`${questionId}_${id}_comment`}
              commentValue={commentValue}
              enabled={true}
              required={commentRequired}
              handleClearComment={handleClearComment}
              handleUpdateComment={handleUpdateComment}
              inputRef={commentRef}
            />
          </S.CommentRow>
        )}

        {/* When `checked`, we render the option to add a comment */}
        {enabledComment && commentValue && (
          <S.AssistiveText
            onClick={handleClearComment}
            enabledComment={enabledComment}
          >
            {enabledComment ? "Remove Comment" : ""}
          </S.AssistiveText>
        )}
      </S.CheckboxInput>
    </>
  );
};

CheckboxInput.defaultProps = {
  fullWidth: true,
  checked: false,
};

export { CheckboxInput };
