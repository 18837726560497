import styled from "styled-components";

import Card from "components/common/Card";

const StyledCard = styled(Card)`
  margin-right: 8px;
  height: 159px;
`;

const Cards = styled.div`
  display: flex;
  overflow-x: scroll;
  overflow-y: hidden;

  width: 100%;
  height: 170px;
  align-items: flex-start;
  user-select: none;

  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 0px;
    background: transparent;
    display: none;
  }
`;

export {
  StyledCard,
  Cards
};
