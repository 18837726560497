import styled from "styled-components";

import { devices } from "themes/mediaQueries";

type WrapperType = {
  background?: string;
  border?: string;
};

type ButtonWrapperType = {
  border?: string;
};

type TextWrapperType = {
  color?: string;
};

type IconWrapperType = {
  color: string;
};

export type StartNewDocumentButtonType = {
  background?: string;
  borderColor?: string;
  textColor?: string;
};

export const Wrapper = styled.div<WrapperType>`
  align-items: center;
  background: ${({ background, theme }) => background || theme.colors.white};
  border-radius: 4px;
  border: 1px solid ${({ border, theme }) => border || theme.colors.white};
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: 159px;
  margin-right: 36px;
  padding: 15px;
  width: 168px;
`;

export const IconWrapper = styled.div<IconWrapperType>`
  align-items: center;
  background: ${({ theme }) => theme.colors.lightGrey};
  border-radius: 4px;
  color: ${(props) => props.color};
  display: flex;
  font-size: 25px;
  justify-content: center;
  padding: 5px;
`;

export const TextWrapper = styled.div<TextWrapperType>`
  color: ${({ color, theme }) => color || theme.colors.white};
  font-size: 0.875rem;
  letter-spacing: 0.49px;
  padding: 8px;
  text-align: center;
  text-transform: uppercase;
  width: 100px;
`;

export const ButtonWrapper = styled.button<ButtonWrapperType>`
  background: ${({ theme }) => theme.colors.white};
  border-radius: 4px;
  border: 1px solid ${({ border, theme }) => border || theme.colors.lightGrey};
  color: ${({ theme }) => theme.colors.primary};
  font-size: 0.875rem;
  letter-spacing: 0.49px;
  padding: 5px 10px;
  text-align: center;
  text-transform: uppercase;
  width: 120px;
`;

export const StartNewDocumentButton = styled.button<StartNewDocumentButtonType>`
  background-color: ${({ background, theme }) => background || theme.colors.white};
  border-radius: 4px;
  border: 1px solid ${({ borderColor, theme }) => borderColor || theme.colors.white};
  color: ${({ textColor, theme }) => textColor || theme.colors.black};
  letter-spacing: 0.49px;
  margin-bottom: 5px;
  padding: 12px 0;
  text-align: center;
  text-transform: uppercase;

  @media (min-width: ${devices.iPhone5}px) and (max-width: ${devices.iPhone8Plus}px) {
    font-size: 0.875rem;
  }

  @media (min-width: ${devices.minTablet}px) and (max-width: ${devices.maxTablet}px) {
    font-size: 1.3125rem;
  }
`;
