import React, { FC } from "react";
import { css } from "aphrodite/no-important";

import Banner from "components/common/Banner/Banner";
import { TextButton } from "components/common/Button";

import propertiesStyles from "../../styles";

interface Props {
  dataSourceKey: string;
  onRemoveDataSet: () => void;
  disabled?: boolean;
}

const DataSetBanner: FC<Props> = ({
  dataSourceKey,
  onRemoveDataSet,
  disabled,
}) => {
  const ps = propertiesStyles();

  return (
    <Banner type="warning">
      <p>This question is using data set {dataSourceKey} for options</p>
      <p>
        The first 10 options will display as a sample in the form preview panel
      </p>
      {!disabled && (
        <TextButton
          className={css([ps.link])}
          variant="link"
          onClick={onRemoveDataSet}
        >
          Switch to custom options
        </TextButton>
      )}
    </Banner>
  );
};

export default DataSetBanner;
