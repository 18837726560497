import React, { useState } from "react";
import styled from "styled-components";

import Label from "../Label";
import { AssistiveLink } from "../types";
import { HelperText } from "../TextInput/styles";

import * as S from "./styles";
import { MenuProps } from "@material-ui/core/Menu";
import { FieldErrorType } from "components/FormController/components/Question";
import Text from "components/common/Text";

const OptionContent = styled.span`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export type Option = {
  id?: any;
  value?: any;
  disabled?: boolean;
  optionPlaceholder?: boolean;
};

export interface SelectInputProps {
  active?: boolean;
  assistiveLink?: AssistiveLink;
  className?: string;
  disabled?: boolean;
  error?: FieldErrorType;
  helperText?: string;
  id?: number | string;
  label?: string;
  name: string | number;
  noMargin?: boolean;
  onBlur?: (e: React.FormEvent<HTMLSelectElement>) => void;
  onChange(e: React.ChangeEvent<any>): void;
  onChange<T = string | React.ChangeEvent<any>>(
    field: T
  ): T extends React.ChangeEvent<any>
    ? void
    : (e: string | React.ChangeEvent<any>) => void;
  options?: Array<Option>;
  required?: boolean;
  touched?: boolean;
  value?: any;
  children?: React.ReactNode;
  MenuProps?: Partial<MenuProps>;
  wrapperClassName?: string;
}

const Select = ({
  active,
  assistiveLink,
  children,
  className,
  disabled,
  error,
  helperText,
  label,
  name,
  noMargin,
  onBlur,
  onChange,
  options,
  required,
  touched,
  value,
  wrapperClassName,
}: SelectInputProps) => {
  const [blurredWithNoValue] = React.useState(false);
  const [isFocusedByMouse, setIsFocusedByMouse] = useState(false);

  const handleBlur = (e) => {
    setIsFocusedByMouse(false);
    onBlur && onBlur(e);
  };

  const handleMouseDown = () => {
    setIsFocusedByMouse(true);
  };

  // const displayError = error && touched;
  const displayError = error;

  return (
    <S.SelectInput className={wrapperClassName}>
      {label && (
        <Label
          htmlFor={typeof name === "string" ? name : String(name)}
          assistiveLink={assistiveLink}
          noMargin={noMargin}
          required={required}
        >
          {label}
        </Label>
      )}
      {helperText && (
        <div style={{ paddingBottom: 10, marginLeft: -15 }}>
          <HelperText>{helperText}</HelperText>
        </div>
      )}
      <S.Select
        error={!!displayError}
        active={active}
        className={className}
        disabled={disabled}
        variant="outlined"
        MenuProps={{
          getContentAnchorEl: null,
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
        }}
        name={name ? name.toString() : undefined}
        onBlur={handleBlur}
        onChange={onChange}
        onMouseDown={handleMouseDown}
        value={value}
        input={
          <S.OutlinedInput
            isFocusedByMouse={isFocusedByMouse}
            notched={false}
          />
        }
      >
        {options &&
          options.map((option) => (
            <S.MenuItem
              key={option.id}
              value={option.id}
              disabled={option.disabled}
              placeholder={
                option.optionPlaceholder
                  ? option.optionPlaceholder.toString()
                  : undefined
              }
            >
              <OptionContent>{option.value}</OptionContent>
            </S.MenuItem>
          ))}
        {children}
      </S.Select>
      {displayError && (
        // <S.ErrorText>{error || (blurredWithNoValue && "Required")}</S.ErrorText>
        <Text variant="error">{error}</Text>
      )}
    </S.SelectInput>
  );
};

export { Select };
