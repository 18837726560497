import * as FS from "@fullstory/browser";

import {
  ACTIVATE_USER,
  CLEAR_ERROR,
  CLEAR_STATUS,
  CLEAR_TOKEN,
  LOGIN,
  PASSWORD_RESET,
  REQUEST_RESET,
  VALIDATE_PASSWORD_RESET_KEY,
  VALIDATE_USER_KEY,
} from "./types";

import { getParticipantConfig } from "store/participantConfigs/actions";
import { CALL_API } from "../../middleware/api";
import { GET_USER, GetUserResponse } from "../user/types";
import { RESET_APP } from "../index";
import { showTempTutorial } from "store/participantConfigs/actions";
import { getAppConfig } from "store/appConfigs/actions";
import { getParticipantTreeOptions } from "store/participantTreeFilter/actions";

// common functionality used after different login methods
export const afterLogin = (idToken: string) => (dispatch) => {
  return dispatch({
    keyName: "getUser",
    [CALL_API]: {
      endpoint: "users/me",
      options: {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${idToken}`,
        },
      },
      types: [GET_USER.REQUEST, GET_USER.SUCCESS, GET_USER.FAILURE],
    },
  }).then((res: GetUserResponse) => {
    // Identify user in FullStory analytics
    if (res.type === GET_USER.SUCCESS && res.response) {
      const { response: user } = res;
      // FullStory
      FS.identify(user.userId.toString(), {
        displayName:
          user.fullName ||
          `${user.lastName}, ${user.firstName}${
            user.nickname ? ` "${user.nickname}"` : ""
          }`,
        ...user,
      });
    }
    // Get participant configs
    dispatch(showTempTutorial()); // Default state is true anyway
    // Get appConfigs that are secured
    dispatch(getAppConfig("clientOverrides"));
    dispatch(getAppConfig("mainNavigation"));
    dispatch(getAppConfig("dashboardSidebar"));
    // Disabled, using the temp one until the pilot.
    // if (e.response && e.response.participant && e.response.participant.id) {
    dispatch(getParticipantConfig("tutorial"));
    // }

    // Get participant tree options
    dispatch(getParticipantTreeOptions());
  });
};

/**
 * Call API with user email and password for authentication
 * @param {string} email
 * @param {string} password
 * @param {string|null} recaptchaKey
 */
export const login = (
  email: string,
  password: string,
  recaptchaKey: string | null
) => (dispatch, getState) => {
  const endpoint = "authenticate";
  const body = JSON.stringify({
    username: email,
    password: password,
    recaptchaToken: recaptchaKey,
  });
  return dispatch({
    keyName: "login",
    [CALL_API]: {
      endpoint,
      options: {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: body,
      },
      types: [LOGIN.REQUEST, LOGIN.SUCCESS, LOGIN.FAILURE],
    },
  })
    .then((e) => {
      if (e.type === LOGIN.SUCCESS) {
        // login was successful, fetch user profile
        return afterLogin(getState().system.authToken)(dispatch);
      }
    })
    .catch((e) => {
      console.error("ERROR", e);
    });
};

/**
 * Directly logs in by setting the ID token
 * @param idToken
 */
export const setIdToken = (idToken: string) => (dispatch) => {
  dispatch({
    keyName: "tokenChange",
    response: {
      // eslint-disable-next-line @typescript-eslint/camelcase
      id_token: idToken,
    },
    type: LOGIN.SUCCESS,
  });
  return afterLogin(idToken)(dispatch);
};

/** Clear auth tokens and reducers with any user-specific data */
export const logout = () => (dispatch) => {
  localStorage.clear(); // Clear localStorage as well -- GK
  dispatch({
    type: RESET_APP,
  });
  window.location.reload(false);
};

/**
 * Call API with email for password reset
 * @param {string} email
 */
export const requestReset = (email: string) => (dispatch) => {
  const endpoint = "users/password-reset";
  const body = email;
  return dispatch({
    keyName: "requestReset",
    [CALL_API]: {
      endpoint,
      options: {
        method: "POST",
        headers: {
          "Content-Type": "text/html",
        },
        body: body,
      },
      types: [
        REQUEST_RESET.REQUEST,
        REQUEST_RESET.SUCCESS,
        REQUEST_RESET.FAILURE,
      ],
    },
  });
};

/**
 * Call API with reset key and new password to reset user's password
 * @param key {string}          - attached to URL query string from API
 * @param newPassword {string}
 */
export const resetPassword = (key: string | null, newPassword: string) => (
  dispatch
) => {
  if (key === null) return;

  const endpoint = "users/password-reset/new-password";

  const body = JSON.stringify({
    key: key,
    newPassword: newPassword,
  });

  return dispatch({
    keyName: "resetPassword",
    [CALL_API]: {
      endpoint,
      options: {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: body,
      },
      types: [
        PASSWORD_RESET.REQUEST,
        PASSWORD_RESET.SUCCESS,
        PASSWORD_RESET.FAILURE,
      ],
    },
  });
};

// Check if password reset key is valid
export const validatePasswordResetKey = (key: string) => (dispatch) => {
  if (!key) return;

  const endpoint = `users/password-reset/validate?key=${key}`;

  return dispatch({
    [CALL_API]: {
      endpoint,
      options: {
        method: "GET",
        headers: {
          Accept: "application/json",
        },
      },
      types: [
        VALIDATE_PASSWORD_RESET_KEY.REQUEST,
        VALIDATE_PASSWORD_RESET_KEY.SUCCESS,
        VALIDATE_PASSWORD_RESET_KEY.FAILURE,
      ],
    },
  });
};

// Activate user
export const activateUser = (key: string, newPassword: string) => (
  dispatch
) => {
  if (!key || !newPassword) return;

  const body = JSON.stringify({
    key,
    newPassword,
  });

  return dispatch({
    [CALL_API]: {
      endpoint: "users/activate",
      options: {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body,
      },
      types: [
        ACTIVATE_USER.REQUEST,
        ACTIVATE_USER.SUCCESS,
        ACTIVATE_USER.FAILURE,
      ],
    },
  });
};

// Validate user key
// `activationKey` url param is required on BE, which makes it not likely to be re-used for other keys -- GK
export const validateUserKey = (key: string, type: "activationKey") => (
  dispatch
) => {
  if (!key || !type) return;

  const endpoint = `users/validate?${type}=${key}`;

  return dispatch({
    [CALL_API]: {
      endpoint,
      options: {
        method: "GET",
        headers: {
          Accept: "application/json",
        },
      },
      types: [
        VALIDATE_USER_KEY.REQUEST,
        VALIDATE_USER_KEY.SUCCESS,
        VALIDATE_USER_KEY.FAILURE,
      ],
    },
  });
};

/**
 * Clear reducer error
 */
export const clearError = () => (dispatch) =>
  dispatch({
    type: CLEAR_ERROR,
  });

/**
 * Clear auth token
 */
export function clearToken() {
  return {
    type: CLEAR_TOKEN,
  };
}

/**
 * Clear system statuses
 */
export const clearStatus = () => (dispatch) =>
  dispatch({
    type: CLEAR_STATUS,
  });

// oAuth login
export const oauthLogin = (authToken: string) => {
  return {
    type: "OAUTH_LOGIN",
    authToken,
  };
};
