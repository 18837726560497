import React from "react";

import * as S from "../styles";

interface Props {
  signatureTextValue?: string;
}

const RenderTypedSignature = ({ signatureTextValue }: Props) => {
  if (!signatureTextValue) return null;
  
  return (
    <S.SignatureWrap>
      <S.SignatureValue>{signatureTextValue}</S.SignatureValue>
    </S.SignatureWrap>
  );
};
export default RenderTypedSignature;
