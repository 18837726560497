import { PaginationParams } from "store/common/types";
import {
  FiltersActions,
  DataSetsFiltersArgs,
  DATA_SETS_FILTERS,
} from "./types";

const initialFilters = {
  query: "",
};

const initialPaginationParams: PaginationParams = {
  page: 0,
  size: 10,
  sort: ["title", "asc"],
};

const initialState: DataSetsFiltersArgs = {
  filterParams: initialFilters,
  paginationParams: initialPaginationParams,
};

export function dataSetsReducer(
  state = initialState,
  action: FiltersActions
): DataSetsFiltersArgs {
  const { response, type } = action;

  switch (type) {
    case DATA_SETS_FILTERS.SET:
      return { ...state, filterParams: response };

    case DATA_SETS_FILTERS.PARAMS_SET:
      return { ...state, paginationParams: response };

    default:
      return state;
  }
}
