import { useSelector } from "react-redux";

import { UserRole } from "store/user/types";
import { AppState } from "store";


/** 
 * Component level authentication -- links, buttons, text, etc.
 * 
 * Hook logic is not expensive, but will trigger on every re-render wherever it's used. An
 * alternative is to have each component get the roles from the store then pass it to a function
 * that does a comparison, but then it's more code in each component and not as abstract, or clean.
 * 
 * We could store flags in `store/user/reducer` and calculate them once (only) when we
 * get user's data, but each component would still have to pull that data on every re-render, so
 * considering `.some()` is optimized and roles' length is tiny, I'm leaving it this way.
 * 
 * -- GK
 * 
 * */

export const useRoleAccess = (accessRoles: string[]) => {
  // Get user roles
  const userRoles: UserRole[] = useSelector((state: AppState) => 
  state.user?.data?.authorities.map((e) => e.authority) || []);

  // Compare user roles to required roles
  const hasRequiredRole = userRoles.some((r)=> accessRoles.includes(r));

  // Result
  return hasRequiredRole;
};
