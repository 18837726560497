import React, { FC } from "react";
import { css } from "aphrodite/no-important";
import { useFormikContext, FastField, useField } from "formik";

import { FormRelationshipTypeDTO, FormTypeDTO } from "store/forms/types";

import { Label } from "components/common/form/Label/futureUiKit/Label";
import Checkbox from "components/common/form/Checkbox";
import YesOrNo from "components/common/form/YesOrNo/YesOrNo";

import { FBForm } from "../types";
import styles from "./styles";
import baseStyles from "../styles";
import { isFormPublished } from "../helpers";

interface AdditionalActionsFormProps {
  formTypes: FormTypeDTO[];
}

const AdditionalActionsForm = ({ formTypes }: AdditionalActionsFormProps) => {
  const { values, setFieldValue } = useFormikContext<FBForm>();
  const s = styles();
  const bs = baseStyles();
  const relationshipTypes = values.relationshipTypes || [];

  async function handleChangeRelationshipTypes(
    checked: boolean,
    formType: FormTypeDTO,
    relationshipType?: FormRelationshipTypeDTO
  ) {
    let relTypes = relationshipTypes;
    // checking the box (adding/updating the relationship)
    if (checked) {
      relTypes = [
        ...relTypes,
        {
          id: relationshipType?.id || null,
          displayName: formType.name,
          formId: values.id,
          formTypeId: formType.id,
          softDeleted: false,
        },
      ];
    }
    // unchecking the box (removing the relationship type)
    else if (relationshipType) {
      relTypes = relTypes.filter(
        (relType) => relType.formTypeId !== relationshipType.formTypeId
      );
    }
    setFieldValue("relationshipTypes", relTypes);
  }

  const CheckBoxField: FC<{
    ft: FormTypeDTO;
    relationshipType: FormRelationshipTypeDTO | undefined;
    disabled?: boolean;
  }> = ({ ft, relationshipType, disabled }) => {
    const [field] = useField(`relationshipTypes_${ft.id}`);
    return (
      <Checkbox
        {...field}
        label={ft.name}
        checked={!!relationshipType}
        onChange={(
          event: React.ChangeEvent<HTMLInputElement>,
          isChecked: boolean
        ) => handleChangeRelationshipTypes(isChecked, ft, relationshipType)}
        disabled={disabled}
      />
    );
  };

  const isPublished = isFormPublished(values.workflowType);

  return (
    <div className={css(s.settingsForm, bs.contentContainer)}>
      <FastField
        name="isRehuddleEligible"
        as={YesOrNo}
        label="Enable Rehuddle?"
        disabled={isPublished}
      />
      <Label
        htmlFor="formRelationshipTypes"
        content="Allow users to start additional related documents:"
      />
      {formTypes.map((ft: FormTypeDTO) => {
        const relationshipType = values.relationshipTypes?.find(
          (rt) => ft.id === rt.formTypeId
        );

        return (
          <div key={ft.id}>
            <CheckBoxField
              ft={ft}
              relationshipType={relationshipType}
              disabled={isPublished}
            />
          </div>
        );
      })}
      <FastField
        name="sharingEnabled"
        as={YesOrNo}
        label="Enable sharing?"
        disabled={isPublished}
      />
      <FastField
        name="startFromWorkOrderEnabled"
        as={YesOrNo}
        label="Allow users to start form from work order?"
        disabled={isPublished}
      />
    </div>
  );
};

export default AdditionalActionsForm;
