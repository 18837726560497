import { PaginationParams } from "store/common/types";
import {
  ResourceCategoriesFiltersParams,
  RESOURCE_CATEGORIES_FILTERS,
  SetResourceCategoriesFiltersAction,
  SetResourceCategoriesPaginationAction,
} from "./types";

export const setResourceCategoriesFilters = (
  filters: ResourceCategoriesFiltersParams
): SetResourceCategoriesFiltersAction => ({
  response: filters,
  type: RESOURCE_CATEGORIES_FILTERS.SET,
});

export const setResourceCategoriesPagination = (
  pagination: PaginationParams
): SetResourceCategoriesPaginationAction => ({
  response: pagination,
  type: RESOURCE_CATEGORIES_FILTERS.PARAMS_SET,
});
