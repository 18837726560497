import React from "react";
import moment from "moment";

import Label from "components/forms/Label";
import Text from "components/common/Text";

import { FieldErrorType } from "components/FormController/components/Question";

import * as S from "./styles";
import { SingleResponseQuestionProps } from "../types";
import { HelperText } from "../TextInput/styles";
import {
  KeyboardDatePicker,
  KeyboardDateTimePicker,
  KeyboardTimePicker,
} from "@material-ui/pickers";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { TIME_FORMAT, parseTime } from "util/dateUtilities";

// get the format string to match the HTML5 input type
function html5Format(type: string): string {
  switch (type) {
    case "date":
      return moment.HTML5_FMT.DATE;
    case "time":
      return TIME_FORMAT;
    default:
    case "datetime-local":
      return moment.HTML5_FMT.DATETIME_LOCAL;
  }
}

interface Props extends SingleResponseQuestionProps {
  error?: FieldErrorType;
  handleBlur?: () => void;
  label?: string;
  name: string;
  type: string;
}

const DateInput = ({
  error,
  handleBlur,
  label,
  name,
  question,
  response,
  setQuestionResponse,
  type,
}: Props) => {
  const isRequired = question.formProperties?.isRequired;
  const helperText = question.properties?.assistiveText || undefined; // undefined intentional

  const [localVal, setLocalVal] = React.useState<string | null | undefined>(
    null
  );
  const [pickerDate, setPickerDate] = React.useState<MaterialUiPickersDate>();

  // Use the date from the picker when it's valid. Otherwise, attempt to parse the user's input.
  // Finally, set the response to empty.
  const setResponse = (date?: MaterialUiPickersDate, value?: string | null) => {
    const existingResponse = response?.answer;

    if (existingResponse === value) {
      return;
    }

    let answer = "";

    if (value) {
      const valueMoment = moment(value);
      answer =
        !!date && date?.isValid()
          ? date.utc().format(html5Format(type))
          : valueMoment?.isValid()
          ? valueMoment.utc().format(html5Format(type))
          : "";
      if (existingResponse === answer) {
        return;
      }
    }

    setTimeout(() => {
      setQuestionResponse(
        answer
          ? {
              answer,
              comments: null,
              questionId: question.id,
              questionRootId: question.rootId,
              timeAnswered: new Date().toISOString(),
            }
          : undefined
      );
    });
  };

  const localHandleBlur = ({
    target: { value },
  }: React.FocusEvent<HTMLInputElement>) => {
    handleBlur && handleBlur();
    setResponse(pickerDate, value);
  };

  const handleChangeTime = (
    date: MaterialUiPickersDate,
    value?: string | null
  ) => {
    setLocalVal(value);
    setPickerDate(date);

    // We usually want to update the form response on blur. However, if they
    // use the picker instead of typing, there is no blur event. So we also
    // update if the picker gives a valid date.
    if (date?.isValid()) {
      setResponse(date, value);
    }
  };

  React.useEffect(() => {
    if (response?.answer) {
      const answerMoment =
        type === "time" ? parseTime(response.answer) : moment(response.answer);
      if (answerMoment.isValid()) {
        setLocalVal(answerMoment.toISOString());
      }
    }
  }, [response?.answer]);

  return (
    <S.DateInput>
      {label && (
        <Label htmlFor={`${name}`} required={isRequired}>
          {label}
        </Label>
      )}
      {helperText && (
        <div style={{ paddingBottom: 10, marginLeft: -15 }}>
          <HelperText>{helperText}</HelperText>
        </div>
      )}
      <S.FormControl>
        {type === "date" && (
          <KeyboardDatePicker
            autoOk
            id={name}
            helperText="" // hide the built-in error text
            name={name}
            value={localVal}
            placeholder="mm/dd/yyyy"
            onChange={handleChangeTime}
            onBlur={localHandleBlur}
            format="MM/DD/yyyy"
            inputVariant="outlined"
            variant="inline"
            error={Boolean(error)}
          />
        )}
        {type === "time" && (
          <KeyboardTimePicker
            autoOk
            id={name}
            name={name}
            helperText="" // hide the built-in error text
            value={localVal}
            placeholder="--:--"
            ampm={false}
            onChange={handleChangeTime}
            onBlur={localHandleBlur}
            format="HH:mm"
            inputVariant="outlined"
            variant="inline"
            error={Boolean(error)}
          />
        )}
        {type === "datetime-local" && (
          <KeyboardDateTimePicker
            autoOk
            id={name}
            name={name}
            value={localVal}
            placeholder="mm/dd/yyyy --:--"
            ampm={false}
            onChange={handleChangeTime}
            onBlur={localHandleBlur}
            format="MM/DD/yyyy HH:mm"
            inputVariant="outlined"
            variant="inline"
            error={Boolean(error)}
          />
        )}
        {/* <S.DateInputField
          error={Boolean(error)}
          id={name}
          name={name}
          onBlur={handleBlur}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setLocalVal(e.target.value)
          }
          type={type}
          value={localVal}
          variant="outlined"
        /> */}
      </S.FormControl>
      {error && <Text variant="error">{error}</Text>}
    </S.DateInput>
  );
};

export default DateInput;
