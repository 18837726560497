import { Action } from "redux";
import { get } from "lodash";
import { ThunkDispatch } from "redux-thunk";
import { useDispatch } from "react-redux";
import { useFormikContext } from "formik";

import useDebouncedValue from "util/hooks/useDebouncedValue";
import usePrevious from "util/hooks/usePrevious";

import { FBForm, FBItem, FBSection } from "../types";
import { putFormItem, putFormSection } from "store/builder/actions";
import { AppState } from "store";
import {
  getPathIndices,
  marshallItem,
  marshallSection,
  unmarshallForm,
  unmarshallSection,
} from "../helpers";
import { useAsyncEffect } from "@rtslabs/field1st-fe-common";

interface Props {
  values: FBItem | FBSection;
  itemPath: string;
  onError?: (error: string) => void;
}

const AutoSaveItem = ({ onError, itemPath, values }: Props): null => {
  const {
    values: form,
    isSubmitting,
    setSubmitting,
    setFieldValue,
  } = useFormikContext<FBForm>();
  const dispatch = useDispatch<ThunkDispatch<AppState, void, Action>>();

  /**
   * PUT an section to the form section endpoint and handle ID changes
   * @param values Section values
   */
  async function saveSection(values: FBSection): Promise<string | undefined> {
    const section = marshallSection(values);
    const response = await dispatch(putFormSection(form.id, section));

    if (response.response) {
      const updatedItem = get(unmarshallForm(response.response), itemPath);
      const [sectionIndex] = getPathIndices(itemPath);

      if (sectionIndex !== undefined && updatedItem.id !== values.id) {
        const updatedSection = unmarshallSection(
          updatedItem,
          sectionIndex,
          form.displayConditions
        );
        setFieldValue(itemPath, updatedSection, false);
      }
    }

    if (response.error) {
      return response.error;
    }
  }

  /**
   * PUT an item to the form item endpoint and handle ID changes
   * @param values Item values
   */
  async function saveItem(values: FBItem): Promise<string | undefined> {
    const item = marshallItem(values);
    const response = await dispatch(
      putFormItem(form.id, values.parentID, item)
    );

    if (response.response) {
      const updatedItem = get(unmarshallForm(response.response), itemPath);
      const [sectionIndex, itemIndex] = getPathIndices(itemPath);

      // if the item id has changed (reverted from FINAL), update the item and reset the current item
      if (updatedItem.id !== values.id) {
        setFieldValue(itemPath, updatedItem, false);
      }
      // item the item's parent section id has changed, update the section id
      if (
        sectionIndex !== undefined &&
        values.parentID !== updatedItem.parentID
      ) {
        setFieldValue(
          `sections[${sectionIndex}].id`,
          updatedItem.parentID,
          false
        );
        // update the remaining section items with the new section id
        response.response.sections[sectionIndex].items.forEach(
          (item, index) => {
            if (index !== itemIndex) {
              setFieldValue(
                `sections[${sectionIndex}].items[${index}].parentID`,
                updatedItem.parentID,
                false
              );
              setFieldValue(
                `sections[${sectionIndex}].items[${index}].parentRootID`,
                updatedItem.parentRootID,
                false
              );
            }
          }
        );
      }
    }

    if (response.error) {
      return response.error;
    }
  }

  /**
   * AutoSave the item + handle errors
   * @param values Item or Section to be saved
   */
  async function autoSave(values: FBItem | FBSection) {
    setSubmitting(true);
    let error: string | undefined;

    switch (values.type) {
      case "SECTION":
        error = await saveSection(values);
        break;
      case "QUESTION":
      case "CONTENT":
      case "WIDGET":
        error = await saveItem(values);
        break;
      default:
        throw "item type is not handled in auto save";
    }

    if (error) {
      onError?.(error);
    }
    setSubmitting(false);
  }

  const debouncedValues = useDebouncedValue(values, 600);
  const previousValues = usePrevious(debouncedValues);

  useAsyncEffect(async () => {
    // don't attempt submit if we're already submitting or on initial load
    // don't submit if the item id has changed // @TODO instead, can we check if the ONLY change is the item id
    if (
      debouncedValues &&
      form.workflowType !== "FINAL" &&
      form.workflowType !== "DEACTIVATED" &&
      !isSubmitting &&
      previousValues?.id === debouncedValues.id
    ) {
      await autoSave(debouncedValues);
    }
  }, [debouncedValues]);

  return null;
};

export default AutoSaveItem;
