import React from "react";
import { orderBy } from "lodash";

import { AnswerSourceType } from "store/forms/types";
import { AssistiveLink } from "components/common/form/types";
import { FBItem, FBMapWidget } from "../../types";
import { LocationMarker } from "components/FormController/types";
import { TextInput } from "components/common/form/TextInput/futureUiKit/TextInput";
import Map from "components/map";

interface MapWidgetQuestion {
  answerSourceType?: AnswerSourceType;
  assistiveLink?: AssistiveLink;
  helperText?: string;
  iconColor: string;
  label?: string;
  name: string;
  placeholder?: string;
  questionRootId?: number;
}

/**
 * Sort widget questions by their answer source type (descending), placing "DATA_SOURCE" before "CURRENT_GPS"
 * @param questions
 */
function sortQuestions(questions: Array<MapWidgetQuestion>) {
  return orderBy(questions, (question) => question.answerSourceType, ["desc"]);
}

function generateQuestions(item: FBMapWidget, siblings: Array<FBItem>) {
  const questions = item.questions.map((question) => {
    /** global form question */
    const globalQuestion = siblings.find((s) => s.rootId === question.questionRootId);
    const globalProperties = globalQuestion?.properties;
    const globalAnswerSource = globalQuestion?.answerSource;
    const detailedSearch =
            question.answerSource?.properties?.detailedSearch || globalAnswerSource?.properties?.detailedSearch;
    const defaultLinkName = question.answerSource?.type === "CURRENT_GPS" ? "Find My Geo Location" 
      : question.answerSource?.type === "DATA_SOURCE" ? "Search Locations" 
        : "";

    const widgetQuestion: MapWidgetQuestion = {
      answerSourceType: globalAnswerSource?.type,
      iconColor: question.iconColor,
      name: `mapQuestion_${question.id}`,
      helperText: globalProperties?.assistiveText,
      label: globalQuestion?.title,
      placeholder: globalProperties?.placeHolderText,
      assistiveLink: detailedSearch && {
        label: detailedSearch.linkName || defaultLinkName
      }
    };

    return widgetQuestion;
  });
  return sortQuestions(
    questions
  );
}

interface Props {
  item: FBMapWidget;
  /** Items contained in the same section.items array as the widget */
  siblings: Array<FBItem>;
  disableControls?: boolean;
}

export function MapWidget({ item, siblings, disableControls }: Props) {
  const [mapQuestions, setMapQuestions] = React.useState<Array<MapWidgetQuestion>>([]);
  const [mapMarkers, setMapMarkers] = React.useState<Array<LocationMarker>>([]);

  /*
  * Configure mock location fields on item.questions.length prop change
  *
  * Icon color, answer source and GPS enablement are derived from each formik widget question.
  * All other properties are derived from the related global form question.
  * This strategy keeps mutable and immutable properties separate for form marshalling and submission.
  */
  React.useEffect(() => {
    const mapQuestions = generateQuestions(item, siblings);
    const mapMarkers = mapQuestions.map((q) => ({
      onUpdate: () => undefined,
      color: q.iconColor
    }));

    setMapQuestions(mapQuestions);
    setMapMarkers(mapMarkers);
  }, [item.questions, item.questions.length, generateQuestions]);

  return (
    <>
      {item.includeMap && (
        <Map
          disableControls={disableControls}
          initialViewport={{
            width: "100%",
            height: 281,
            zoom: 14,
            center: { latitude: 37.548083, longitude: -77.466722 }
          }}
          markers={mapMarkers}
        />
      )}
      {mapQuestions.map((question) => (
        <TextInput
          key={question.name}
          assistiveLink={question.assistiveLink}
          elementBefore={
            <i
              className="icon icon-icons8-marker"
              style={{
                fontSize: 38,
                color: question.iconColor
              }}
            />
          }
          helperText={question.helperText}
          label={question.label}
          name={question.name}
          placeholder={question.placeholder}
        />
      ))}
    </>
  );
}
