import React from "react";
import { SvgIcon, SvgIconProps } from "@material-ui/core";

interface ClassNames {
  background?: string;
  fill?: string;
}

export interface CheckBoxFilledBlankProps extends SvgIconProps {
  classNames?: ClassNames;
}

export const CheckBoxFilledBlank = ({
  classNames = {
    background: "background",
    fill: "fill",
  },
  ...otherProps
}: CheckBoxFilledBlankProps) => {
  return (
    <SvgIcon {...otherProps}>
      <path className={classNames.background} d="M 5,5 H 19 V 19 H 5 Z" />
      <path
        className={classNames.fill}
        d="M18 19H6c-.55 0-1-.45-1-1V6c0-.55.45-1 1-1h12c.55 0 1 .45 1 1v12c0 .55-.45 1-1 1zm1-16H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2z"
      />
    </SvgIcon>
  );
};
