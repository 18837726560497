import React from "react";

import * as S from "./styles";
import { CopyType } from "./Tutorial";

type SlideProps = {
  contents: CopyType;
};

const Slide = (props: SlideProps) => {
  const {
    contents: { image, header, content },
  } = props;
  return (
    <div>
      <S.Image>
        <img src={image} alt="Tutorial slide" height="100%" />
      </S.Image>
      <S.Header>{header}</S.Header>
      <S.Content>{content}</S.Content>
    </div>
  );
};

export default Slide;
