
import { CALL_API } from "middleware/api";
import { 
  ADD_TAG, 
  GET_TAG_BY_ID, 
  GET_TAG_LIBRARIES, 
  TagDTO, 
  TagLibrariesResponse, 
  UPDATE_TAG, 
  UpdateTagBody,
} from "./types";
import { PaginationParams, FilterParams } from "store/common/types";
import { buildFilteredEndpoint } from "store/common/apiUtilities";

/**
 * Get a page of tags
 * @param paginationParams - object of pagination options, {page, size, sort}
 * @param filterParams -  object of filter name/value pairs
 */
export const getTags = (paginationParams?: PaginationParams, filterParams?: FilterParams) => (
  dispatch,
  getState,
) => {
  const { authToken } = getState().system;

  const endpoint = buildFilteredEndpoint("tag-libraries", { ...paginationParams, ...filterParams });

  return dispatch({
    [CALL_API]: {
      endpoint,
      options: {
        method: "GET",
        headers: {
          Authorization: `Bearer ${authToken}`,
          Accept: "application/json",
        },
      },
      types: [GET_TAG_LIBRARIES.REQUEST, GET_TAG_LIBRARIES.SUCCESS, GET_TAG_LIBRARIES.FAILURE],
    },
  })
    .then((res: TagLibrariesResponse) => res)
    .catch((err) => {
      throw new Error(err);
    });
};

/**
 * Get a tag by id
 * @param id - id of the tag
 */
export const getTagById = (id: string) => (dispatch, getState) => {
  const { authToken } = getState().system;

  return dispatch({
    [CALL_API]: {
      endpoint: `tag-libraries/${id}`,
      options: {
        method: "GET",
        headers: {
          Authorization: `Bearer ${authToken}`,
          Accept: "application/json",
        },
      },
      types: [GET_TAG_BY_ID.REQUEST, GET_TAG_BY_ID.SUCCESS, GET_TAG_BY_ID.FAILURE],
    },
  })
    .then((res: TagDTO) => res)
    .catch((err) => {
      throw new Error(err);
    });
};

/**
 * Add tag
 * @param body - tag
 */
export const addTag = (body: TagDTO) => (dispatch, getState) => {
  const { authToken } = getState().system;

  return dispatch({
    [CALL_API]: {
      endpoint: "tag-libraries",
      options: {
        method: "POST",
        headers: {
          Authorization: `Bearer ${authToken}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      },
      types: [ADD_TAG.REQUEST, ADD_TAG.SUCCESS, ADD_TAG.FAILURE],
    },
  })
    .then((res: TagDTO) => res)
    .catch((err) => {
      throw new Error(err);
    });
};

/**
 * Update tag
 * @param body - tag
 */
export const updateTag = (body: UpdateTagBody) => (dispatch, getState) => {
  const { authToken } = getState().system;

  return dispatch({
    [CALL_API]: {
      endpoint: "tag-libraries",
      options: {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${authToken}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      },
      types: [UPDATE_TAG.REQUEST, UPDATE_TAG.SUCCESS, UPDATE_TAG.FAILURE],
    },
  })
    .then((res: TagDTO) => res)
    .catch((err) => {
      throw new Error(err);
    });
};

/**
 * Delete tag -- not needed currently -- GK
 * @param id
 */
// export const deleteTag = (id: string | number) => (dispatch, getState) => {
//   const { authToken } = getState().system;

//   return dispatch({
//     [CALL_API]: {
//       endpoint: "tag-libraries",
//       options: {
//         method: "DELETE",
//         headers: {
//           Authorization: `Bearer ${authToken}`,
//           Accept: "application/json",
//           "Content-Type": "application/json",
//         },
//       },
//       types: [DELETE_TAG.REQUEST, DELETE_TAG.SUCCESS, DELETE_TAG.FAILURE],
//     },
//   })
//     .then((res: TagDTO) => res)
//     .catch((err) => {
//       throw new Error(err);
//     });
// };
