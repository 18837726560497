import React from "react";

import Pill from "components/common/Pill";

import * as S from "./styles";

interface Props {
  title: string;
  subTitle1?: string;
  subTitle2?: string;
  submissionType: string;
  submitDeadline: string | null;
}

const renderPillString = (
  submitString: string,
  deadline: string | null
): string => {
  if (submitString !== "Submitted" && !!deadline) {
    return `${submitString} ${deadline}`;
  } else return submitString;
};

const DocumentInfo = ({
  title,
  subTitle1,
  subTitle2,
  submissionType,
  submitDeadline,
}: Props) => {
  const pillString = renderPillString(submissionType, submitDeadline);

  return (
    <S.DocumentInfo>
      <S.Row>
        <S.Title as="h2">{title}</S.Title>
        {!!pillString && (
          <Pill isSubmitted={submissionType === "Submitted"}>{pillString}</Pill>
        )}
      </S.Row>
      {subTitle1 && <S.SubTitle1 as="p">{subTitle1}</S.SubTitle1>}
      {subTitle2 && <S.SubTitle2 as="p">{subTitle2}</S.SubTitle2>}
    </S.DocumentInfo>
  );
};

export default DocumentInfo;
