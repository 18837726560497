import { CALL_API } from "middleware/api";

import { GET_FORM_STATS } from "./types";

interface QueryParams {
  clientGroupIds?: number;
  participantTreeFilter?: string;
  minSubmissionDate?: string;
  maxSubmissionDate?: string;
}

export const getFormStats = (formId: number, query: QueryParams) => (
  dispatch,
  getState
) => {
  const { system } = getState();

  let endpoint = `reports/forms/${formId}?onlySubordinates=false&includeRemovedQuestions=true`;

  if (query.participantTreeFilter) {
    endpoint += `&participantTreeFilter=${query.participantTreeFilter}`;
  }
  if (query.clientGroupIds && query.clientGroupIds > -1) {
    endpoint += `&clientGroupIds=${query.clientGroupIds}`;
  }

  if (query.minSubmissionDate)
    endpoint += `&minSubmissionDate=${query.minSubmissionDate}`;
  if (query.maxSubmissionDate)
    endpoint += `&maxSubmissionDate=${query.maxSubmissionDate}`;

  return dispatch({
    formId: formId,
    [CALL_API]: {
      endpoint: endpoint,
      options: {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${system.authToken}`,
        },
      },
      types: [
        GET_FORM_STATS.REQUEST,
        GET_FORM_STATS.SUCCESS,
        GET_FORM_STATS.FAILURE,
      ],
    },
  });
};
