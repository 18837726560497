import React from "react";

import * as S from "./styles";

export interface ButtonProps {
  children: string | React.ReactNode;
  disabled?: boolean;
  loading?: boolean;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  type?: "submit" | "reset";
  className?: string;
}

/** Filled button */
const Button = ({ children, onClick, disabled, loading, type, ...rest }: ButtonProps) => {
  // If type is "submit" it will still submit form when disabled,
  // changing it to a "disabled" "button" -- gk
  const _type = (loading || disabled) ? "button" : type || "button";
  return (
    <S.Button type={_type} onClick={onClick} loading={loading} disabled={loading || disabled} {...rest}>
      {loading && <S.Loader loading spinnerProps={{ color: "white", size: 14 }} />}
      <S.ButtonText>{children}</S.ButtonText>
    </S.Button>
  );
};

interface TextButtonProps extends ButtonProps {
  variant?: "cancel" | "link";
}

/** Text-only button */
export const TextButton = ({ variant, className, children, onClick, disabled, loading, ...rest }: TextButtonProps) => {
  return (
    <S.TextButton
      className={className}
      type="button"
      variant={variant}
      onClick={onClick}
      disabled={loading || disabled}
      {...rest}
    >
      {children}
    </S.TextButton>
  );
};

export default Button;
