// This is a copy from fe-common v3.181.0 since we can't upgrade from v3.123.0 due
// to other upgradable libraries that interact here
import {
  DocumentParticipantSaveVm,
  DocumentQuestionResponseVm,
  FormSectionVm,
  Functions,
} from "@rtslabs/field1st-fe-common";

function participantMatch(
  response: DocumentQuestionResponseVm,
  participant: DocumentParticipantSaveVm,
  role: DocumentParticipantSaveVm["role"]
): boolean {
  const isParticipantIdMatch =
    response.associatedId &&
    participant.participantId === response.associatedId;
  const isNameMatch =
    participant.name?.toLowerCase() === response.answer.toLowerCase();
  const isRoleMatch = participant.role === role;

  return isRoleMatch && (isParticipantIdMatch || isNameMatch);
}

/**
 *  this should probably be somewhere else, but it generates/combines participants based on responses
 */
export function generateParticipants(
  existingParticipants: DocumentParticipantSaveVm[],
  responses: DocumentQuestionResponseVm[],
  sections: FormSectionVm[]
): DocumentParticipantSaveVm[] {
  const participants: DocumentParticipantSaveVm[] = [];

  for (const response of responses) {
    const { question } = Functions.findOrThrowQuestionById(
      sections,
      response.questionId
    );
    if (question.subType !== "PARTICIPANT") {
      continue;
    }

    // set role to ATTENDANT if not set
    const participantRole = question.participantRole || "ATTENDANT";

    // check if participant already added
    if (
      participants.some((p) => participantMatch(response, p, participantRole))
    ) {
      continue;
    }

    // check if participant existed already
    const existing = existingParticipants.find((p) =>
      participantMatch(response, p, participantRole)
    );
    if (existing) {
      participants.push(existing);
      continue;
    }

    // create one if not
    participants.push({
      name: response.answer,
      participantId: response.associatedId,
      role: participantRole,
      timeAdded: new Date().toISOString(),
    });
  }
  return participants;
}
