import { Authority, UsersParticipant } from "store/usersParticipants/types";
import { Pagination, APIResponse } from "store/common/types";
import { UserDTO } from "store/user/types";

export interface ParticipantsSummaryState {
  data: ParticipantsSummaryData;
  error: boolean;
  errorMessage: string;
  loading: boolean;
}

export type UserStatus = "ACTIVE" | "DISABLED" | "NOT_ACTIVATED" | "NOT_APPLICABLE";

export interface ParticipantsSummaryData extends Pagination {
  content?: ParticipantSummary[];
}

export type ParticipantSummary = {
  authorities?: Array<Authority>;
  email?: string;
  firstName?: string;
  groupNames?: string[];
  id?: number;
  lastLogin?: string;
  lastName?: string;
  name: string;
  participant: UsersParticipant;
  participantId?: number;
  roleName?: string;
  status?: UserStatus;
  supervisor: UserDTO;
  user: UserDTO;
  userId: number;
};

export const GET_PARTICIPANTS_SUMMARY = {
  FAILURE: "GET_GET_PARTICIPANTS_SUMMARY_FAILURE",
  REQUEST: "GET_GET_PARTICIPANTS_SUMMARY_REQUEST",
  SUCCESS: "GET_GET_PARTICIPANTS_SUMMARY_SUCCESS",
};

export interface GetParticipantsSummaryResponse extends APIResponse {
  response?: ParticipantsSummaryData;
}

export interface ParticipantsActions {
  id?: string | number;
  type:
    | typeof GET_PARTICIPANTS_SUMMARY.REQUEST
    | typeof GET_PARTICIPANTS_SUMMARY.SUCCESS
    | typeof GET_PARTICIPANTS_SUMMARY.FAILURE;
  payload?: any;
  response?: any;
}
