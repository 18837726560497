import sassVariables from "sass/jsExports.module.scss";

const minWidth = (value: string) => `min-width: ${value}`;
const maxWidth = (value: string) => `max-width: ${value}`;

const minDeviceWidth = (value: number) => `(min-device-width: ${value}px)`;
const createMinWidthMQForHook = (value: number) => `(min-width: ${value}px)`;
const createMaxWidthMQForHook = (value: number) => `(max-width: ${value}px)`;

// ? EXAMPLE USE CASE

/**
 * ? Within a styled-component declaration:
 *
 * import mediaQueries from 'themes/mediaQueries'
 *
 * @media(${mediaQueries.iPad}) {
 *  ..styles go here..
 * }
 *
 * ? Within a component, using 'react-responsive' to
 * ? conditionally render elements based on viewport
 *
 * import MediaQuery from "react-responsive";
 * import { devices } from 'themes/mediaQueries'
 *
 * <MediaQuery maxWith={devices.iPad}>
 *  ..component which only renders if this viewport matches..
 * </MediaQuery>
 *
 */

// generics
const maxPhone = parseInt(sassVariables.maxPhone);
const maxTablet = parseInt(sassVariables.maxTablet);
const minTablet = parseInt(sassVariables.minTablet);
const minDesktop = parseInt(sassVariables.minDesktop);
const desktop = parseInt(sassVariables.desktop);

// tablets
const iPad = parseInt(sassVariables.iPad);
const iPadPro = parseInt(sassVariables.iPadPro);

// phones
const iPhone5 = parseInt(sassVariables.iPhone5);
const iPhone6 = parseInt(sassVariables.iPhone6);
const iPhoneX = parseInt(sassVariables.iPhoneX);
const iPhone6Plus = parseInt(sassVariables.iPhone6Plus);
const iPhone8Plus = parseInt(sassVariables.iPhone8Plus);

// ? computed media queries
const mediaQueries = {
  // generics
  maxPhone: maxWidth(`${maxPhone}px`),
  maxTablet: maxWidth(`${maxTablet}px`),
  minTablet: minWidth(`${minTablet}px`),

  // TABLET
  iPad: minWidth(`${iPad}px`),
  iPadPro: minWidth(`${iPadPro}px`),

  // PHONE
  iPhone5: minWidth(`${iPhone5}px`),
  iPhone6: minWidth(`${iPhone6}px`),
  iPhoneX: minWidth(`${iPhoneX}px`),
  iPhone6Plus: minWidth(`${iPhone6Plus}px`),
  iPhone8Plus: minWidth(`${iPhone8Plus}px`),

  // DESKTOP
  desktop: maxWidth(`${desktop}px`),
  minDesktop: minWidth(`${minDesktop}px`),
};

/**
 * intended for use with the `react-responsive` hook
 * and returns values like:
 * (min-device-width: 123px)
 * It should be noted that these media queries are using
 * the `device-width` keyword
 */
const deviceQueries = {
  minTablet: minDeviceWidth(minTablet),
  minDesktop: minDeviceWidth(minDesktop),
};

/**
 * intended for use with the `react-responsive` hook
 * and returns values like:
 * (min-width: ${value}px)
 * Same as `deviceQueries` but doesn't use the keyword device.
 */
const hookQueries = {
  minDesktop: createMinWidthMQForHook(minDesktop),
  maxTablet: createMaxWidthMQForHook(maxTablet),
};

const devices = {
  desktop,
  iPad,
  iPadPro,
  iPhone5,
  iPhone6,
  iPhone6Plus,
  iPhone8Plus,
  iPhoneX,
  maxPhone,
  maxTablet,
  minDesktop,
  minTablet,
};

export { devices, mediaQueries as default, deviceQueries, hookQueries };
