import React, { useState, useEffect } from "react";
import { css } from "aphrodite/no-important";
import { Field, useFormikContext } from "formik";
import { get } from "lodash";

import {
  AreaToReview,
  FBQuestion,
  FBForm,
  RatingsOptions,
  FBRatingsWidget,
} from "components/clientAdmin/formBuilder/types";
import { NewFormItem } from "store/builder/types";
import MultiInput from "components/common/form/MultiInput/MultiInput";
import Button, { TextButton } from "components/common/Button";
import DrawerHeader from "components/common/Drawer/components/Header";
import Label from "components/common/form/Label";
import TextInput from "components/common/form/TextInput";
import Text from "components/common/Text";
import Checkbox from "components/common/form/Checkbox";

import Drawer from "./Drawer";
import propertiesStyles from "../styles";
import drawerStyles from "./styles";
import { isFormPublished } from "components/clientAdmin/formBuilder/helpers";

const DeleteAreaButton = ({ onDelete }: { onDelete: () => void }) => {
  const ds = drawerStyles();

  return (
    <div className={css(ds.deleteAreaButton)}>
      <TextButton variant="cancel" onClick={onDelete}>
        Delete Area
      </TextButton>
    </div>
  );
};

interface Props {
  closeDrawer: () => void;
  show: boolean;
  onAddArea: (area: AreaToReview) => void;
  onUpdateArea: (area: AreaToReview) => void;
  initialValues?: AreaToReview;
  onDeleteArea: (areaId: number) => void;
  areaName: string;
  widget: FBRatingsWidget;
}

const AreasToReviewDrawer = ({
  onAddArea,
  closeDrawer,
  show,
  areaName,
  initialValues,
  onUpdateArea,
  onDeleteArea,
  widget,
}: Props) => {
  const [addedSubcats, setAddedSubcats] = useState<NewFormItem<FBQuestion>[]>(
    []
  );
  const [areaLabel, setAreaLabel] = useState<string>("");
  const [submitErrors, setSubmitErrors] = useState<boolean>(false);

  const { values, touched, setFieldTouched } = useFormikContext<FBForm>();
  const isPublished = isFormPublished(values.workflowType);

  const defaultRatingsSelections: RatingsOptions[] = [
    { title: "Risk", commentRequired: true },
    { title: "Coaching Moment", commentRequired: false },
    { title: "Satisfactory", commentRequired: false },
    { title: "Best Practice", commentRequired: false },
  ];

  const existingSelections: RatingsOptions[] = [];
  initialValues?.items[0].selections?.forEach((selection) => {
    existingSelections.push({
      title: selection.title,
      commentRequired:
        selection.properties?.commentRequired ??
        widget?.requireCommentsFor?.includes(selection.title) ??
        false,
    });
  });

  const [ratingsSelections, setRatingsSelections] = useState<RatingsOptions[]>(
    existingSelections.length ? existingSelections : defaultRatingsSelections
  );
  function clearValues() {
    setAddedSubcats([]);
    setAreaLabel("");
  }

  const handleRequireComment = (id: number) => {
    const selections = [...ratingsSelections];
    selections[id].commentRequired = !selections[id].commentRequired;
    setRatingsSelections(selections);
  };

  const handleOptions = (value: string, id: number) => {
    const selections = [...ratingsSelections];
    selections[id].title = value;
    setRatingsSelections(selections);
  };

  useEffect(() => {
    if (areaLabel && addedSubcats.length) {
      setSubmitErrors(false);
    }
  }, [areaLabel, addedSubcats]);

  async function addArea() {
    // clear previous submit error
    setSubmitErrors(false);

    // trigger validation
    setFieldTouched(`areaToReview.label_${areaName}`);
    setFieldTouched(`areaToReview.subcategories_${areaName}`);

    if (!areaLabel || !addedSubcats.length) {
      // local validation to prevent drawer submission
      setSubmitErrors(true);
      return;
    }

    // clear temp ids from subcategory items
    const items = addedSubcats.map((cat) => {
      if (typeof cat.id === "string") {
        return { ...cat, id: null };
      }
      return cat;
    });

    if (initialValues?.id) {
      const updateRatingsSelections: any = [];
      // @ts-ignore
      initialValues.items[0].selections.forEach((item, index) => {
        updateRatingsSelections.push({
          ...item,
          title: ratingsSelections[index].title,
          properties: {
            commentRequired: ratingsSelections[index].commentRequired,
          },
        });
      });

      for (let i = 0; i < initialValues.items.length; i++)
        initialValues.items[i].selections = updateRatingsSelections;

      onUpdateArea({
        ...initialValues,
        label: areaLabel,
        items,
        ratingsSelections,
      });
    } else {
      onAddArea({ label: areaLabel, items, ratingsSelections, id: null });
    }
    clearValues();
    closeDrawer();

    // un-touch the fields to clear validation errors
    setFieldTouched(`areaToReview.label_${areaName}`, false);
    setFieldTouched(`areaToReview.subcategories_${areaName}`, false);
  }

  function deleteArea() {
    if (initialValues?.id) {
      onDeleteArea(initialValues.id);
      clearValues();
      closeDrawer();
    }
  }

  useEffect(() => {
    if (initialValues) {
      setAddedSubcats(initialValues.items || []);
      setAreaLabel(initialValues.label || "");
    } else {
      clearValues();
    }
  }, [initialValues]);

  const ps = propertiesStyles();

  return (
    <Drawer
      anchor="right"
      content={
        <Drawer.Content>
          <DrawerHeader
            closeDrawer={closeDrawer}
            text="Add Area &amp; Subcategories"
          />
          <Drawer.Section>
            <Drawer.SectionHeader>Add an Area to Review</Drawer.SectionHeader>
            <Drawer.Description>
              Areas to review are displayed as a list of multi-select checkboxes
              that will guide the user on what to review. When an area is
              selected, the related subcategories will be displayed.
            </Drawer.Description>

            <Field
              as={TextInput}
              className={css(ps.textInput)}
              name={`areaToReview.label_${areaName}`}
              label="Area To Review Label"
              placeholder="Area To Review Label"
              onChange={(e) => setAreaLabel(e.target.value)}
              value={areaLabel}
              error={!areaLabel ? "Area label is required" : ""}
              touched={get(touched, `areaToReview.label_${areaName}`)}
              disabled={isPublished}
            />

            <Label
              className={css(ps.label)}
              htmlFor={`areaToReview.subcategories_${areaName}`}
            >
              Add Subcategories
            </Label>
            <Drawer.Description>
              Each area to review is required to have at least one subcategory.{" "}
              These subcategories are the specific topics related to the area
              that will be rated.
              <br />
              <i>
                EXAMPLE: A subcategory for Job Hazard Analysis is Hazard
                Mitigation
              </i>
            </Drawer.Description>
            <Field
              as={MultiInput}
              className={css(ps.multiInput)}
              name={`areaToReview.subcategories_${areaName}`}
              label=""
              helperText=""
              placeholder={`Add a${
                addedSubcats.length > 1 ? "nother" : ""
              } subcategory`}
              labelField="title"
              idField="id"
              selectedValues={addedSubcats}
              onUpdateValues={setAddedSubcats}
              error={
                !addedSubcats.length
                  ? "At least one subcategory is required"
                  : ""
              }
              touched={get(touched, `areaToReview.subcategories_${areaName}`)}
              disabled={isPublished}
            />
          </Drawer.Section>
          <Label
            className={css(ps.label)}
            htmlFor={`areaToReview.ratingsOptions_${areaName}`}
          >
            Ratings Options
          </Label>
          <Drawer.Description>
            Below are the default rating options. Click on each pane if you wish
            to rename the rating options. Apply checkmarks to each rating option
            that you want to require comments for.
          </Drawer.Description>

          {ratingsSelections.map((option, index) => {
            return (
              <div
                key={`option${index}`}
                className={css(ps.ratingOptionsWrapper)}
              >
                <Field
                  as={Checkbox}
                  className={css([ps.checkbox, ps.noVertPadding])}
                  name={`requiredCheckbox${index}.label_${areaName}`}
                  onChange={() => handleRequireComment(index)}
                  checked={option.commentRequired}
                  disabled={isPublished}
                />
                <Field
                  as={TextInput}
                  className={css(ps.textInput)}
                  name={`option${index}.label_${areaName}`}
                  placeholder={`Option ${index + 1}`}
                  onChange={(e) => handleOptions(e.target.value, index)}
                  value={option.title}
                  error={!option.title ? "Rating label is required" : ""}
                  disabled={isPublished}
                />
              </div>
            );
          })}

          {!isPublished && (
            <Drawer.ButtonContainer>
              {initialValues?.id && <DeleteAreaButton onDelete={deleteArea} />}
              <Button onClick={addArea}>
                {initialValues ? "Update" : "Add"} Area
              </Button>
            </Drawer.ButtonContainer>
          )}
          {submitErrors && (
            <Text variant="error">Please fix errors before continuing</Text>
          )}
        </Drawer.Content>
      }
      id="areasToReviewDrawer"
      onClose={closeDrawer}
      onOpen={() => {}}
      open={show}
      showCloseIcon={false}
      variant="persistent"
    />
  );
};

export default AreasToReviewDrawer;
