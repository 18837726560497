import React from "react";

import * as S from "../styles";

interface Props {
  signatureUrl?: string;
}

const RenderDrawnSignature = ({ signatureUrl }: Props) => {
  if (!signatureUrl) return null;
  return (
    <S.SignatureWrap drawn>
      <S.SignatureImage src={signatureUrl}/>
    </S.SignatureWrap>
  );
};

export default RenderDrawnSignature;
