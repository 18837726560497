import React from "react";
import { css } from "aphrodite/no-important";
import { RadioButtonUnchecked } from "@material-ui/icons";
import { useFormikContext } from "formik";

import {
  RadioGroup,
  Radio,
  SvgIcon,
  CheckIcon,
} from "components/forms/RadioInput/styles";
import CheckboxGroup from "components/common/form/CheckboxGroup";
import Label from "components/common/form/Label";

import { FBForm, FBRatingsWidget, FBQuestion } from "../../../../types";
import { getItemInSection } from "../../../properties/customFields/AreasToReview/AreasToReview";
import styles from "./styles";

export type RatingSelection = {
  title: string;
  id: number | null;
  properties?: { commentRequired: boolean };
};

export const ratingSelections: RatingSelection[] = [
  { title: "Risk", id: null },
  { title: "Coaching Moment", id: null },
  { title: "Satisfactory", id: null },
  { title: "Best Practice", id: null },
];

const RatingsWidget = ({ item }: { item: FBRatingsWidget }) => {
  const s = styles();
  const { values } = useFormikContext<FBForm>();

  // get the parent MULTI_SELECT item
  const parentItem = getItemInSection(
    values,
    item.parentRootID,
    item.parentQuestionRootId
  ) as FBQuestion;

  const initialOptions =
    parentItem?.selections && parentItem.selections.length > 0
      ? parentItem.selections.map((selection) => ({
          checked: false,
          disabled: false,
          label: selection.title,
          value: String(selection.id),
        }))
      : [
          {
            checked: false,
            disabled: false,
            label: "Area to review",
            value: "NewArea",
          },
        ];

  return (
    <div>
      <Label htmlFor={item.id.toString()}>
        Which areas do you want to review?
      </Label>
      <CheckboxGroup initialOptions={initialOptions} outlined />
      {/* {item.displayDescription && (
        <div className={css(s.scaleContainer)}>
          <div>
            <strong>How to use the safety scale</strong>
          </div>
          <div className={css([s.scaleBody, s.small])}>
            <p>
              <strong>Risk:</strong> A situation which, if not addressed
              immediately, would likely result in injury or equipment damage.
              This is a <strong>Stop Work</strong> moment.
            </p>
            <p>
              <strong>Coaching Moment:</strong> When additional reminders are
              needed to reinforce safety expectations
            </p>
            <p>
              <strong>Satisfactory:</strong> Meeting expectations for safety
              performance
            </p>
            <p>
              <strong>Best Practice:</strong> Most effective or above
              expectations
            </p>
          </div>
        </div>
      )} */}

      {/* @TODO make a common Radio Input component */}
      <Label htmlFor={`item_${item.id}`}>Area to Review</Label>
      <div className={css(s.radioSection)}>
        <p className={css([s.small, s.bold])}>Subcategory</p>
        <RadioGroup
          className={css(s.radioGroup)}
          stacked={false}
          aria-label={item.title}
          powerfieldsVariant="RATING"
          name={`item_${item.id}`}
          value={""}
          onChange={() => null}
        >
          {ratingSelections.map((option, i) => (
            <div className={css(s.radioOption)} key={`${option.title}_${i}`}>
              <Radio
                icon={
                  <SvgIcon>
                    <RadioButtonUnchecked />
                  </SvgIcon>
                }
                checkedIcon={
                  <CheckIcon className="icon icon-icons8-checkmark" />
                }
              />
              <span className={css([s.radioOptionLabel, s.small])}>
                {option.title}
              </span>
            </div>
          ))}
        </RadioGroup>
      </div>
    </div>
  );
};

export default RatingsWidget;
