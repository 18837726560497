import React, { useState, useEffect } from "react";
import { Formik } from "formik";
import { RouteComponentProps, withRouter } from "react-router";
import { connect } from "react-redux";
import { get } from "lodash";

import * as S from "./styles";
import * as US from "../addUser/styles";
import * as VUPS from "../viewUserProfile/styles";
import Breadcrumbs from "components/common/Breadcrumbs";
import Loader from "components/common/Loader";
import TextInput from "components/common/form/TextInput";
import Toast from "components/common/Toast";
import { AppState } from "store";
import { ClientGroup } from "store/clientGroups/types";
import { EditIcon, PageContent } from "../styles";
import { Tab, Tabs, TabPanel, tabA11yProps } from "components/common/Tabs";
import { getClientGroup, getAllDefaultTermDefinitions } from "store/clientGroups/actions";
import { getClientGroupConfig, updateClientGroupConfig } from "store/clientConfigs/actions";
import { ClientGroupConfig } from "store/clientConfigs/types";

interface ViewGroupProps extends RouteComponentProps {
  dispatch?: any;
}

// For the url params
type Params = {
  id?: number;
};

// Constants

const termsExistInConfig = (action) => {
  if (action?.type === "GET_CLIENT_GROUP_CONFIG_SUCCESS" && get(action, ["response", "properties", "terms"], null)) {
    return true;
  }
  return false;
};

const mapDefaultTerms = (terms) => {
  let result = [];
  if (terms && terms.length > 0) {
    result = terms.map((term) => ({ id: term.visibleId, label: term.label, value: term.val }));
  }
  return result;
};

const getInitialValues = (terms) => {
  // Accepts terms array and returns a cleaned up object
  const result = {};
  if (terms) {
    terms.forEach((term) => {
      result[term.id] = term.value;
    });
  }
  return result;
};

const ViewGroup = (props: ViewGroupProps) => {
  const { dispatch, history } = props;

  // Get group id from url
  const params: Params = props.match.params;
  const groupId = params.id;

  // Current tab
  const [tab, setTab] = useState(0);

  // Current group
  const [currentGroup, setCurrentGroup] = useState<ClientGroup>({});
  const [currentGroupLoading, setCurrentGroupLoading] = useState<boolean>(true);

  // Current group configs
  const [defaultConfigs, setDefaultConfigs] = useState<ClientGroupConfig[]>([]);
  const [defaultConfigsLoading, setDefaultConfigsLoading] = useState(false);
  const [currentGroupConfigs, setCurrentGroupConfigs] = useState<ClientGroupConfig[]>([]);
  const [currentGroupConfigsLoading, setCurrentGroupConfigsLoading] = useState(false);

  // Error toast visible
  const [errorToastVisible, setErrorToastVisible] = useState<boolean>(false);

  // Async form submit loading (API)
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);

  // On mount
  useEffect(() => {
    dispatch(getClientGroup(groupId)).then((e) => {
      if (e?.type === "GET_CLIENT_GROUP_SUCCESS") {
        setCurrentGroup(e.response);

        // Get current group configs
        setCurrentGroupConfigsLoading(true);
        setDefaultConfigsLoading(true);
        dispatch(getClientGroupConfig(e.response.id, "terms")).then((e) => {
          setCurrentGroupConfigsLoading(false);
          if (termsExistInConfig(e)) {
            // Terms exist in group's config
            setCurrentGroupConfigs(get(e, ["response", "properties", "terms"], null));
          }
        });
        // Get default terms to distinguish
        dispatch(getAllDefaultTermDefinitions()).then((s) => {
          if (s.type === "GET_ALL_DEFAULT_TERM_DEFINITIONS_SUCCESS") {
            if (s.response && s.response.length > 0) {
              setDefaultConfigs(mapDefaultTerms(s.response));
            }
          }
          setDefaultConfigsLoading(false);
        });
      }
      setCurrentGroupLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const _handleSubmit = (values) => {
    const properties = defaultConfigs.map((e) => {
      return {
        ...e,
        // value: values[e.id] || e.value,
        value: values[e.id],
      };
    });

    const payload = {
      active: true,
      clientGroupId: currentGroup.id,
      keyName: "terms",
      properties: {
        terms: properties,
      },
    };

    // Do API call
    setSubmitLoading(true);
    dispatch(updateClientGroupConfig(payload)).then((e) => {
      if (e.type === "UPDATE_CLIENT_GROUP_CONFIG_SUCCESS") {
        history.replace("/people/groups");
      } else {
        setErrorToastVisible(true);
      }
      setSubmitLoading(false);
    });
  };

  return (
    <PageContent>
      <div className="col-12">
        <Breadcrumbs
          paths={[
            {
              pathName: "People",
              href: "/people/groups",
            },
            {
              pathName: "Groups",
              href: "/people/groups",
            },
            {
              pathName: `${currentGroup.name} Profile`,
              href: `/people/groups/view/${currentGroup.id}`,
            },
          ]}
        />
      </div>
      <Loader loading={currentGroupLoading}>
        {/* Group information  */}
        <div className="col-lg-12">
          <VUPS.ProfileInformationWrapper>
            <VUPS.ProfileName>
              {currentGroup.name}
              <span className="ml-2">
                <EditIcon onClick={() => history.push(`/people/groups/edit-group/${groupId}`)} />
              </span>
            </VUPS.ProfileName>
            <VUPS.ProfileContentWrapper>
              <VUPS.ProfileContent>
                <VUPS.ProfileContentHeader>ID</VUPS.ProfileContentHeader>
                <VUPS.ProfileContentValue>{currentGroup.externalGroupId}</VUPS.ProfileContentValue>
              </VUPS.ProfileContent>
            </VUPS.ProfileContentWrapper>
          </VUPS.ProfileInformationWrapper>
        </div>

        <div className="col-12 mt-5">
          <Tabs value={tab} onChange={(event, newValue) => setTab(newValue)}>
            {/* <Tab label="settings" {...tabA11yProps(0)} /> */}
            <Tab label="group terms" {...tabA11yProps(0)} />
            {/* <Tab label="user permissions" {...tabA11yProps(2)} /> */}
          </Tabs>
          {/* <TabPanel value={tab} index={0}>
              <div />
            </TabPanel> */}
          <TabPanel value={tab} index={0}>
            <S.TabContent>
              <VUPS.ProfilePermissionsCopy>
                Specific terms and phrases within the Safety Tools app can be customized for each group. At Dominion, a
                term referred to as “Refocus” by one group may be called a “Rehuddle” by another. Using the terms and
                language your team members use will improve adoption and reduce confusion. <b>Note:</b> System defaults
                exist for all terms, so only specify those you want to vary.
              </VUPS.ProfilePermissionsCopy>
              <Loader loading={currentGroupConfigsLoading || defaultConfigsLoading}>
                {/* Terms  */}
                <div className="pl-0 pr-0">
                  <S.TermColumnsWrapper>
                    <S.TermColumnHeader width="199px">
                      <S.TermColumnHeaderContent>
                        company term
                      </S.TermColumnHeaderContent>
                    </S.TermColumnHeader>
                    <S.TermColumnHeader>
                      <S.TermColumnHeaderContent>
                        group term
                      </S.TermColumnHeaderContent>
                    </S.TermColumnHeader>
                  </S.TermColumnsWrapper>
                  <Formik
                    enableReinitialize
                    initialValues={getInitialValues(currentGroupConfigs)}
                    onSubmit={(values) => {
                      _handleSubmit(values);
                    }}
                  >
                    {(props) => {
                      const { handleChange, handleSubmit, values } = props;
                      return (
                        <form onSubmit={handleSubmit}>
                          {defaultConfigs.map((config, i) => {
                            return (
                              <S.TermColumnsWrapper key={i}>
                                <S.LabelWrapper width="199px">
                                  <S.Label>{config.label}</S.Label>
                                </S.LabelWrapper>

                                <S.FieldWrapper>
                                  <TextInput
                                    name={config.id}
                                    onChange={handleChange}
                                    placeholder={config.label}
                                    variant="outlined"
                                    value={values[config.id]}
                                  />
                                </S.FieldWrapper>
                              </S.TermColumnsWrapper>
                            );
                          })}

                          <Toast
                            variant="error"
                            onClick={() => setErrorToastVisible(false)}
                            visible={errorToastVisible}
                          >
                            Failed to save
                          </Toast>
                          <br />
                          <US.ButtonsWrapper>
                            <US.AddUserButton disabled={submitLoading}>
                              <US.LoaderWrapper>
                                <Loader loading={submitLoading} className="p-0 mr-3" spinnerProps={{ size: 14 }} />
                              </US.LoaderWrapper>
                              <span>save </span>
                            </US.AddUserButton>
                            {/* <US.CancelButton disabled={submitLoading} onClick={() => history.goBack()}>
                                cancel
                              </US.CancelButton> */}
                          </US.ButtonsWrapper>
                        </form>
                      );
                    }}
                  </Formik>
                </div>
              </Loader>
            </S.TabContent>
          </TabPanel>
          {/* <TabPanel value={tab} index={2}>
              <div />
            </TabPanel> */}
        </div>
      </Loader>
    </PageContent>
  );
};

const mapStateToProps = (state: AppState) => {
  return {};
};

export default withRouter(connect(mapStateToProps)(ViewGroup));
