import styled from "styled-components";

export const EmptyRow = styled.td`
  text-align: center;
  padding: 80px 0;
`;

export const NoResultsContentContainer = styled.div`
  max-width: 390px;
  margin: 0 auto;
`;

export const NoResultsTitle = styled.div`
  font-size: 1.5rem;
  line-height: 21px;
  margin-top: 1.5rem;
`;

export const NoResultsBody = styled.div`
  color: ${({ theme }) => theme.masterColors.darkGrey};
  margin: 0.75rem 0;
`;
