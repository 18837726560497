import React from "react";
import CircularProgressBase from "@material-ui/core/CircularProgress";
import styled from "styled-components";

import ListItemBase from "components/common/ListItem";
import { Button } from "components/forms/Button";
import { devices } from "themes/mediaQueries";
import { remCalc } from "themes/helpers";

export const HeaderRow = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 0;
`;

export const Header = styled.h1`
  color: ${({ theme }) => theme.masterColors.black};
  font-size: 1.25rem;
  font-weight: 500;
  margin-bottom: 16px;
`;

export const AddAnotherMember = styled.button`
  background: none;
  border: none;
  color: ${({ theme }) => theme.colors.primary};
  display: inline;
  font-size: 0.875rem;
  text-decoration: underline;

  &:focus {
    outline: 0;
  }
`;

////////////////////////////////////////////////////////
// List Items
////////////////////////////////////////////////////////

export const ListItems = styled.ul`
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.masterColors.darkGrey};
  margin: 0;
  padding: ${remCalc(12)};
  width: 100%;
`;

export const ListItemOption = styled(ListItemBase)`
  margin-bottom: 16px;
`;

export const IconForEndAdornment = styled.i`
  color: ${({ theme }) => theme.masterColors.darkGrey};
  &:hover {
    cursor: pointer;
  }
`;

////////////////////////////////////////////////////////

export const SubLabel = styled.span`
  color: ${({ theme }) => theme.masterColors.mediumGrey};
  font-size: ${remCalc(12)};
  line-height: 14px;
  line-spacing: ${remCalc(0.4)};
  margin-left: 16px;
  margin-top: 0;
`;

export const SubmitButton = styled(Button)`
  && {
    border-radius: 4px;
    color: ${({ disabled, theme }) => disabled && theme.colors.primary};
    @media (max-width: ${devices.maxPhone}px) {
      font-size: ${remCalc(14)};
    }
  }

  &&.root {
    background-color: ${({ disabled, theme }) => (disabled ? theme.colors.primary : null)};
  }
`;

export const Icon = styled.i`
  font-size: ${remCalc(24)};
  left: 16px;
  position: absolute;
`;

export const CancelButton = styled(Button)`
  && {
    background-color: ${({ theme }) => theme.colors.white};
    border-radius: 4px;
    display: ${({ disabled }) => disabled && "none"};
    margin-top: 10px !important;

    @media (max-width: ${devices.maxPhone}px) {
      font-size: ${remCalc(14)};
    }
  }
`;

export const Spinner = styled(({ ...otherProps }) => (
  <CircularProgressBase {...otherProps} size={20} classes={{ root: "root" }} />
))`
  &&.root {
    color: ${({ theme }) => theme.colors.white};
    font-weight: 500;
  }
`;
