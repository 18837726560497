import { APIResponse, MethodTypes } from "store/common/types";
import { FormTypeDTO } from "store/forms/types";

export const GET_ALL_FORM_TYPE_STATS = {
  REQUEST: "GET_ALL_FORM_TYPE_STATS_REQUEST",
  SUCCESS: "GET_ALL_FORM_TYPE_STATS_SUCCESS",
  FAILURE: "GET_ALL_FORM_TYPE_STATS_FAILURE",
};

export interface GetAllFormTypeStatsProps {
  clientGroupIds?: string;
  maxSubmissionDate?: string;
  minSubmissionDate?: string;
  onlySubordinates?: boolean;
  participantTreeFilter: string;
}

export interface FormTypeStatsGroupVm {
  group: {
    id: number;
    name: string;
  };
  count: number;
}

export interface FormTypeStats {
  formType: FormTypeDTO;
  groupCounts: FormTypeStatsGroupVm[];
  totalCount: number;
}

export interface GetAllFormTypeStatsResponse extends APIResponse {
  response?: FormTypeStats[];
}

export interface AllFormTypeStatsState {
  loading: Partial<MethodTypes>;
  errors: Partial<MethodTypes>;
  content?: FormTypeStats[];
}

export interface AllFormTypeStatsActionTypes {
  type:
    | typeof GET_ALL_FORM_TYPE_STATS.REQUEST
    | typeof GET_ALL_FORM_TYPE_STATS.SUCCESS
    | typeof GET_ALL_FORM_TYPE_STATS.FAILURE;
  response?: FormTypeStats[];
}
