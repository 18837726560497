import { PaginationParams } from "store/common/types";

import { FiltersActions, UsersFiltersArgs, USERS_FILTERS } from "./types";

const initialFilters = {
  groupId: -1,
  status: -1,
  timeFilterType: "ALL_TIME",
  subordinatesFilter: "ALL_USERS",
  dateRange: null,
  query: "",
};

const initialPaginationParams: PaginationParams = {
  page: 0,
  size: 10,
  sort: "firstName,asc",
};

const initialState: UsersFiltersArgs = {
  filterParams: initialFilters,
  paginationParams: initialPaginationParams,
};

export function usersReducer(
  state = initialState,
  action: FiltersActions
): UsersFiltersArgs {
  const { response, type } = action;

  switch (type) {
    case USERS_FILTERS.SET:
      return { ...state, filterParams: response };

    case USERS_FILTERS.PARAMS_SET:
      return { ...state, paginationParams: response };

    default:
      return state;
  }
}
