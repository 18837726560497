import React, { useMemo } from "react";
import { bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";
import { get } from "lodash";
import { RouteComponentProps, withRouter } from "react-router-dom";
import MediaQuery from "react-responsive";

import { devices } from "themes/mediaQueries";
import { NewDocumentState } from "store/newDocument/types";
import UserMenu from "components/UserMenu";

import { AppState } from "../../store";
import * as systemActions from "../../store/system/actions";

import * as S from "./styles";

interface Props {
  store: {
    clientName: string;
    logo2x2?: string | null;
    newDocument: NewDocumentState;
  };
  actions: {
    system: {
      logout: () => void;
    };
  };
}

const blacklistMobileHeader = ["/profile/settings", "/profile/edit"];

const Header = (props: RouteComponentProps<{}> & Props) => {
  const {
    store: {
      clientName,
      logo2x2,
      newDocument: { forms, selectedFormTemplateId },
    },
  } = props;

  const selectedForm = forms.find((form) => form.id === selectedFormTemplateId);
  const currentPath = get(props, "location.pathname");

  const renderMobileHeader = useMemo(() => !blacklistMobileHeader.includes(currentPath), [currentPath]);

  /**
   * IF user is on a view which is rendering a document or currently
   * editing a document then we will not render any header since the Document
   * component will render its own header
   */
  if (props.location.pathname === "/new-document" && selectedForm) {
    return null;
  }

  /**
   * also don't render when editing a document
   */
  if (props.location.pathname.includes("/document/")) {
    return null;
  }

  const logo2x2Fallback = "/assets/logo/Dominion-EnergyLogo@2x.png";

  return (
    <>
      <MediaQuery minWidth={devices.minDesktop}>
        <S.DesktopHeader>
          <img alt={clientName} src={logo2x2 || logo2x2Fallback} height="32" />
          <UserMenu />
        </S.DesktopHeader>
      </MediaQuery>
      {renderMobileHeader && (
        <MediaQuery maxWidth={devices.maxTablet}>
          <S.MobileHeader>
            <img alt={clientName} src={logo2x2 || logo2x2Fallback} height="32" />
          </S.MobileHeader>
        </MediaQuery>
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  actions: {
    system: bindActionCreators(systemActions, dispatch),
  },
});

const mapStateToProps = (state: AppState) => {
  const clientName = get(state, ["appConfigs", "data", "clientConfig", "properties", "clientName"], "PowerFields");
  const logo2x2 = get(state, ["appConfigs", "data", "logoConfigs", "properties", "logo2x2", "readableUrl"], null);

  return {
    store: {
      clientName,
      logo2x2,
      newDocument: state.newDocument,
    },
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));
