import React, { useCallback, useMemo } from "react";
import { sortBy, get } from "lodash";
import moment from "moment";

import {
  DocumentQuestionResponseVm,
  Defense,
  DefenseDocument,
  ResponseWithAssociatedQuestion,
} from "store/documents/types";
import { QuestionDTO } from "store/forms/types";

import * as S from "./styles";
import Comment from "../Comment";

const createMarkup = (x: string) => ({ __html: x });

const Doc = ({
  description,
  title,
  url,
  fileSize,
  fileType,
  lastUploadedDate,
}: DefenseDocument) => (
  <S.DocWrapper>
    <S.FileType>{fileType}</S.FileType>
    <S.TitleLink href={url}>{title}</S.TitleLink>
    <S.Description>{description}</S.Description>
    <S.IconAndSpecs>
      <S.StyledIcon type="pdf" framed={false} color={"black"} size="2.5rem" />
      <S.Specs>
        <S.Spec>{fileSize}</S.Spec>
        <S.Spec>{moment(lastUploadedDate).format("MM/DD/YYYY")}</S.Spec>
      </S.Specs>
    </S.IconAndSpecs>
  </S.DocWrapper>
);

type NotesProps = {
  name: number | string;
  id: number;
  commentValue: string | null | undefined;
  handleClearComment: () => void;
  handleUpdateComment: (comments?: string) => void;
};

const NotesBlock = ({
  id,
  name,
  commentValue,
  handleClearComment,
  handleUpdateComment,
}: NotesProps) => {
  return (
    <>
      <S.Caret />
      <S.NoteContainer>
        <S.SubTitle>Related Comment</S.SubTitle>
        <Comment
          commentValue={commentValue}
          enabled={true}
          handleClearComment={handleClearComment}
          handleUpdateComment={handleUpdateComment}
          name={typeof name === "string" ? name : `${name}`}
          variant="DEFENSE"
        />
        {commentValue && (
          <S.AssistiveText
            style={{ marginTop: "0.5rem" }}
            onClick={() => handleClearComment()}
            enabledComment
          >
            Remove Comment
          </S.AssistiveText>
        )}
      </S.NoteContainer>
    </>
  );
};

interface DetailsBlockProps {
  allowComments: boolean;
  handleUpdateComment: (comments?: string) => void;
  handleClearComment: () => void;
  response: DocumentQuestionResponseVm;
  defense: Defense;
}

const DetailsBlock = ({
  allowComments,
  response,
  defense,
  handleClearComment,
  handleUpdateComment,
}: DetailsBlockProps) => (
  <S.DetailsBlockContainer>
    <S.DetailsTitleContainer>
      <S.DetailsTitleIcon className="icon-icons8-checkmark" />
      <S.DetailsTitle>{response.answer}</S.DetailsTitle>
    </S.DetailsTitleContainer>
    <S.DetailsWrapper>
      <S.DetailsHTML
        dangerouslySetInnerHTML={createMarkup(defense.description)}
      />
      {defense?.defenseDocuments?.length > 0 &&
        defense.defenseDocuments.map((d) => (
          <Doc
            key={`defense_doc_${d.id}`}
            contentLength={d.contentLength}
            contentType={d.contentType}
            description={d.description}
            fileSize={d.fileSize}
            fileType={d.fileType.toUpperCase()}
            id={d.id}
            lastUploadedDate={d.lastUploadedDate}
            title={d.title}
            url={d.url}
          />
        ))}
    </S.DetailsWrapper>
    {allowComments && (
      <NotesBlock
        id={response.questionId}
        handleClearComment={handleClearComment}
        handleUpdateComment={(comments?: string) =>
          handleUpdateComment(comments)
        }
        commentValue={response.comments}
        name={response.associatedId || response.questionId}
      />
    )}
  </S.DetailsBlockContainer>
);

type Props = {
  autoAppendComment: boolean;
  defenses: Defense[];
  responses: DocumentQuestionResponseVm[];
  subTitle: string;
  questions: QuestionDTO[];
  setQuestionResponses: (
    question: QuestionDTO,
    questionResponses: DocumentQuestionResponseVm[]
  ) => void;
};

export const DefenseDetails = ({
  autoAppendComment,
  questions,
  defenses = [],
  responses = [],
  subTitle,
  setQuestionResponses,
}: Props) => {
  function handleUpdateComment(
    response: DocumentQuestionResponseVm,
    comment?: string
  ) {
    const question = questions.find((ques) => ques.id === response.questionId);
    if (!question) return;

    const questionResponses = responses.filter(
      (res) => res.questionId === response.questionId
    );

    const updatedQuestionResponses = questionResponses.map((qRes) => {
      if (qRes.associatedId === response.associatedId) {
        return {
          ...qRes,
          comments: comment || "",
          timeAnswered: new Date().toISOString(),
        };
      }
      return qRes;
    });
    setQuestionResponses(question, updatedQuestionResponses);
  }

  function getDefenseResponses(defense: Defense) {
    return responses
      .filter((res) =>
        defense.questionSelections.find((sel) =>
          res.associatedId
            ? sel.id === res.associatedId
            : sel.id === res.questionId
        )
      )
      .map((response) => {
        const question = questions.find(
          (question) => question.id === response.questionId
        );
        return { response, question };
      });
  }

  return (
    <S.DetailsContainer>
      <S.Label>{subTitle}</S.Label>
      {defenses.map((defense) => (
        <React.Fragment key={defense.id}>
          {getDefenseResponses(defense).map(({ response, question }) => (
            <DetailsBlock
              allowComments={
                autoAppendComment && !!question?.properties?.enableComments
              }
              key={`${response.questionId}${
                response.associatedId ? `_${response.associatedId}` : ""
              }`}
              handleUpdateComment={(comment?: string) =>
                handleUpdateComment(response, comment)
              }
              handleClearComment={() => {
                handleUpdateComment(response, "");
              }}
              defense={defense}
              response={response}
            />
          ))}
        </React.Fragment>
      ))}
    </S.DetailsContainer>
  );
};
