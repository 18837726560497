// Describing the shape of the system's slice of state
export interface AppConfigsState {
  data: {
    clientConfig: ClientConfig;
    dashboardSidebar: dashboardSidebar;
    logoConfigs: LogoConfigs;
    mainNavigation: mainNavigation;
    clientOverrides?: ClientOverridesConfig;
  };
}

export interface ClientConfig {
  properties: {
    clientName?: string;
    adfsEnabled?: boolean;
  };
}

export interface PrefillAlternates {
  targetField: string;
  alternateField: string;
}

export interface ClientOverridesConfig {
  properties: {
    form?: {
      prefillAlternates?: PrefillAlternates[];
    };
  };
}

export interface LogoConfigs {
  properties: {
    logo2x: {
      signedUrlTimestamp: string;
      privateUrl: string;
      writableUrl: string;
      readableUrl: string;
    };
    logo2x2: {
      signedUrlTimestamp: string;
      privateUrl: string;
      writableUrl: string;
      readableUrl: string;
    };
    logo2x3: {
      signedUrlTimestamp: string;
      privateUrl: string;
      writableUrl: string;
      readableUrl: string;
    };
  };
}
export interface mainNavigation {
  properties: {
    activeColor?: string;
    copy: {
      documents?: string;
      menu?: string;
      profile?: string;
      reports?: string;
    };
  };
}

export interface dashboardSidebar {
  properties: {
    copy: {
      refocus: string;
      form: string;
    };
  };
}

export const GET_APP_CONFIG = {
  FAILURE: "GET_APP_CONFIG_FAILURE",
  REQUEST: "GET_APP_CONFIG_REQUEST",
  SUCCESS: "GET_APP_CONFIG_SUCCESS",
};

export const GET_PRESIGNED_APP_CONFIG = {
  FAILURE: "GET_PRESIGNED_APP_CONFIG_FAILURE",
  REQUEST: "GET_PRESIGNED_APP_CONFIG_REQUEST",
  SUCCESS: "GET_PRESIGNED_APP_CONFIG_SUCCESS",
};

export const GET_IMAGE_FROM_PRESIGNED_APP_CONFIG = {
  FAILURE: "GET_IMAGE_FROM_PRESIGNED_APP_CONFIG_FAILURE",
  REQUEST: "GET_IMAGE_FROM_PRESIGNED_APP_CONFIG_REQUEST",
  SUCCESS: "GET_IMAGE_FROM_PRESIGNED_APP_CONFIG_SUCCESS",
};

interface getAppConfig {
  keyName: string;
  property: string;
  response?: any;
  type: string;
}

export type AppConfigsActionTypes = getAppConfig;
