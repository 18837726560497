import { FilterParams, PaginationParams } from "store/common/types";

export const RESOURCE_CATEGORIES_FILTERS = {
  SET: "RESOURCE_CATEGORIES_FILTERS_SET",
  PARAMS_SET: "RESOURCE_CATEGORIES_FILTERS_PARAMS_SET",
};

export interface ResourceCategoriesFiltersParams extends FilterParams {
  query: string;
}

export interface ResourceCategoriesFiltersArgs {
  filterParams: ResourceCategoriesFiltersParams;
  paginationParams: PaginationParams;
}

export interface SetResourceCategoriesFiltersAction {
  type: typeof RESOURCE_CATEGORIES_FILTERS.SET;
  response: ResourceCategoriesFiltersParams;
}

export interface SetResourceCategoriesPaginationAction {
  type: typeof RESOURCE_CATEGORIES_FILTERS.PARAMS_SET;
  response: PaginationParams;
}

export type FiltersActions = SetResourceCategoriesFiltersAction;
