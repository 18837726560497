export const iconMap = [
  { type: "PARTICIPANT", icon: "add_user_male" },
  { type: "MULTI_SELECT", icon: "todo_list" },
  { type: "RADIO_BUTTONS", icon: "radio_button" },
  { type: "YES_OR_NO", icon: "true_false" },
  { type: "DROP_DOWN", icon: "dropdown_field" },
  { type: "TEXT_LINE", icon: "edit_row" },
  { type: "TEXT_AREA", icon: "paragraph" },
  { type: "DATE", icon: "calendar" },
  { type: "TIME", icon: "clock" },
  { type: "LOCATION", icon: "map" },
  { type: "SAFETY_RATING", icon: "under_construction" },
  { type: "SECTION", icon: "overview_pages_4" },
  { type: "BLOCK", icon: "content" },
  { type: "LINE_BREAK", icon: "horizontal_line" },
  { type: "BANNER_MESSAGE", icon: "clapperboard" },
  { type: "MAP", icon: "map" },
  { type: "DEFENSES", icon: "defense" },
  { type: "OPERATIONAL_EXPERIENCES", icon: "development_skill" },
  { type: "SIGNATURE", icon: "autograph" },
  { type: "DOCUMENT_CREATOR", icon: "shared_document" },
  { type: "SUPERVISOR", icon: "person_male" },
];

export const getItemIcon = (itemType: string) => {
  const iconObject = iconMap.find((icon) => icon.type === itemType);

  return iconObject?.icon || "delete_sign";
};
