import React, { useMemo } from "react";
import styled from "styled-components";

import { StyledLinkCaretRight } from "components/common/styled/StyledLinkCaretRight";

import ListSimple from "components/common/ListSimple";
import { FormTypeDTO } from "store/forms/types";
import { NavLabel } from "components/DocumentSidebarDrawer/styles";
import StickyHeader from "components/common/Drawer/components/StickyHeader";
import DrawerContentWrapper from "components/common/Drawer/components/DrawerContentWrapper";

export interface Props {
  formTypes: FormTypeDTO[];
  onClose: () => void;
  onSelectFormType: (type: FormTypeDTO) => void;
  title?: string;
}

const Body = styled.div`
  text-align: left;
`;

const FormTypesDrawerContent = ({
  formTypes = [],
  onClose,
  onSelectFormType,
  title = "Start Related Document"
}: Props) => {

  // First 2 form types
  const firstTwoFormTypes = useMemo(() => {
    if (formTypes) {
      const firstTwoFormTypes: FormTypeDTO[] = formTypes.slice(0, 1);
      return firstTwoFormTypes.map((type: FormTypeDTO) => ({
        primary: <NavLabel>Start {type.name}</NavLabel>,
        rightSideItem: <StyledLinkCaretRight />,
        onClick: () => onSelectFormType(type),
      }));
    }
    return false;
  }, [formTypes.length]);

  // Remaining form types
  const remainingFormTypes = useMemo(() => {
    if (formTypes && formTypes.length > 2) {
      const remainingFormTypes: FormTypeDTO[] = formTypes.slice(2);
      return remainingFormTypes.map((type: FormTypeDTO) => ({
        primary: <NavLabel>Start {type.name}</NavLabel>,
        rightSideItem: <StyledLinkCaretRight />,
        onClick: () => onSelectFormType(type),
      }));
    }
    return false;
  }, [formTypes.length]);

  return (
    <>
      {/* Header */}
      <StickyHeader
        title={title}
        onClick={onClose}
        chevronRight={false}
      />
      {/* Content */}
      <DrawerContentWrapper>
        <Body>
          <ListSimple bottomDivider={!!(remainingFormTypes)} listItems={firstTwoFormTypes || []}/>
          <ListSimple listItems={remainingFormTypes || []}/>
        </Body>
      </DrawerContentWrapper>
      
    </>
  );
};

export default FormTypesDrawerContent;
