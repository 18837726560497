import styled from "styled-components";

import Icon from "../Icon";

export const Star = styled(Icon)`
  color: ${({ theme }) => theme.masterColors.warning};
  margin-right: 5px;
  padding-top: 2.5px;
  font-size: 16px;
`;
