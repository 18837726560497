import { FlattenedWorkLocation, WorkLocation } from "./types";
import * as R from "ramda";

export function flattenWorkLocations(locations: Array<WorkLocation>): Array<FlattenedWorkLocation> {
  return locations
    .map((loc) => ({
      id: loc.id,
      name: loc.content.name,
      address: R.pathOr("", ["physicalAddress", "addressString"], loc.content),
      emergencyAddress: R.pathOr("", ["emergencyAddress", "addressString"], loc.content),
      geolocation: loc.content.geolocation,
      type: "workLocation",
    }))
    .sort((a, b) => {
      const aName = a.name.toLowerCase();
      const bName = b.name.toLowerCase();
      if (aName < bName) {
        return -1;
      } else if (aName > bName) {
        return 1;
      } else {
        return 0;
      }
    });
}
