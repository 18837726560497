import { get } from "lodash";

import {
  DocumentQuestionResponseVm,
  DisplayConditionDTO,
  GetDocumentResponse,
  DocumentParticipant,
} from "store/documents/types";
import {
  SectionDTO,
  isQuestionDTO,
  isSafetyRatingWidgetDTO,
  isSignatureWidgetDTO,
} from "store/forms/types";
import { SubmissionType } from "store/newDocument/actions";
import {
  getRequiredQuestions,
  getFlattenedQuestions,
  getItemByRootId,
  getRequiredSelectionComments,
  filterParticipants,
} from "./helpers";
import {
  getVisibleQuestionIds,
  isQuestionVisible,
  isSectionVisible,
} from "../helpers";
import { OperationalExperience } from "store/resources/types";
import { DocumentFormValuesType } from "components/document/Document";
import { allowsTypedSignatures } from "util/signatures";
import { SignatureType } from "components/clientAdmin/formBuilder/types";
import moment from "moment";
import { parseTime } from "util/dateUtilities";

function isNonEmptyString(p?: string | null): boolean {
  return typeof p === "string" && p !== "";
}

/**
 * Check that a participant has provided either a drawn or typed signature
 * @param participant
 */
export function hasValidDrawnTypedSignature(
  participant: DocumentParticipant,
  allowedTypes: Array<SignatureType>
): boolean {
  return (
    // confirm participant has signed
    isNonEmptyString(participant.signatureDate) &&
    // confirm there is either a text signature of at least 3 characters or a drawn signature image
    (!typedSignatureValidationError({
      allowedTypes,
      name: participant.name || "",
      email: participant.email || "",
      fullName: participant.fullName || "",
      typedSig: participant.signatureTextValue || "",
    }) ||
      isNonEmptyString(participant.signatureUrl))
  );
}

/**
 * Check that a participant has provided a drawn signature
 * @param participant
 */
export function hasValidDrawnSignature(
  participant: DocumentParticipant
): boolean {
  return (
    isNonEmptyString(participant.signatureDate) &&
    isNonEmptyString(participant.signatureUrl)
  );
}

/**
 * Form validation handler for Formik
 *
 * @param values form values
 * @param sections form sections
 * @param getDocument
 * @param requiredOEs
 * @param storedOEs
 * @param currentParticipants
 * @param displayConditions used to apply rendering/conditional logic to form from API
 * @param submissionType Type of submission
 */
export const validate = async (
  values: DocumentFormValuesType,
  sections: Array<SectionDTO>,
  getDocument: () => Promise<GetDocumentResponse>,
  requiredOEs: number,
  storedOEs: Array<OperationalExperience>,
  currentParticipants: Array<DocumentParticipant>,
  submitCount: number,
  displayConditions?: Array<DisplayConditionDTO>,
  submissionType?: SubmissionType
) => {
  if (
    submissionType === "SAVE_DRAFT" ||
    (!submissionType && submitCount === 0) ||
    (submissionType === "AUTO_SYNC" && submitCount === 0)
  ) {
    return undefined;
  }
  const errors = {};

  /* only continue with validation if document is being submitted */

  const questions = getFlattenedQuestions(sections);
  const requiredQuestions = getRequiredQuestions(questions);
  const requiredComments = getRequiredSelectionComments(questions);
  const responses: Array<DocumentQuestionResponseVm> = values.responses;

  const visibleQuestionIds = getVisibleQuestionIds(
    sections,
    responses,
    displayConditions
  );
  const participantQuestionIds: Set<number> = new Set();
  sections.forEach((section) => {
    section.items
      .filter(
        (i) =>
          i.subType === "PARTICIPANT" &&
          !i.properties?.excludeFromSignatures &&
          // @ts-ignore
          i.participantRole !== "SUPERVISOR"
      )
      .forEach((q) => participantQuestionIds.add(q.id));
  });
  const visibleParticipants = filterParticipants(
    currentParticipants,
    responses.filter((r) => visibleQuestionIds.has(r.questionId)),
    participantQuestionIds
  );

  /*
   * Determine validity of the form submission using the following criteria:
   *  1.  all required questions have a response
   *  2.  all safety rating questions have comments where required
   *  3a. the number of selected OEs meets the number required by the form or...
   *  3b. no OEs have been made visible for selection based on user responses
   */
  for (const section of sections) {
    // skip sections with display conditions preventing their visibility
    if (!isSectionVisible(section.rootId, responses, displayConditions)) {
      continue;
    }

    for (const item of section.items) {
      // skip items with display conditions preventing their visibility
      if (!isQuestionVisible(item.rootId, responses, displayConditions)) {
        continue;
      }

      // ignore questions with "CURRENT_PARTICIPANT" answer sources
      if (
        get(item, ["answerSource", "type"], undefined) === "CURRENT_PARTICIPANT"
      ) {
        continue;
      }

      // handle rating questions
      if (
        item.subType === "RATING" &&
        isQuestionDTO(item) &&
        item.parentWidgetRootId
      ) {
        const response = responses.find((r) => r.questionId === item.id);
        // if the question has been answered, determine if a required comment was supplied
        if (response?.answer) {
          const ratingWidget = getItemByRootId(
            item.parentWidgetRootId,
            sections
          );

          const currentSelection = item.selections?.find(
            (selection) => selection.title === response.answer
          );

          const isCommentRequired = requiredComments.find(
            (item) =>
              item.associatedId === response.associatedId &&
              item.questionId === response.questionId
          );

          const hasCommentRequired =
            currentSelection?.properties?.commentRequired !== undefined;
          if (
            ratingWidget &&
            isSafetyRatingWidgetDTO(ratingWidget) && // item is a rating widget
            (hasCommentRequired
              ? !!isCommentRequired
              : ratingWidget?.requireCommentsFor?.includes(response.answer)) && // and comment is required for answer
            !response.comments // and no comment was supplied
          ) {
            errors[item.id] = "Requires Comment"; // set error
          }
        }
      }

      // handle operational experiences widget
      else if (
        item.type === "WIDGET" &&
        item.subType === "OPERATIONAL_EXPERIENCES"
      ) {
        if ((values.operationalExperiences?.length || 0) < requiredOEs) {
          errors[item.id] = "Required OEs not met";
        }
      }

      // handle signature widget
      else if (
        item &&
        isSignatureWidgetDTO(item) && // item is signature widget
        item.signatureRequired && // and is required
        visibleParticipants?.length // and form has participants
      ) {
        // determine validation function based on properties
        const hasValidSignature = allowsTypedSignatures(item.allowedTypes)
          ? hasValidDrawnTypedSignature
          : hasValidDrawnSignature;
        const filteredParticipants = visibleParticipants.filter(
          (participant) => participant.role !== "SUPERVISOR"
        );
        for (const participant of filteredParticipants) {
          if (!hasValidSignature(participant, item.allowedTypes)) {
            errors[item.id] = "Required: Please complete all signature fields";
            break;
          }
        }
      }

      // handle all other questions
      else {
        if (item.subType === "DATE") {
          const response = responses.find(
            (r) => r.questionId === item.id && !moment(r.answer).isValid()
          );
          if (response) {
            errors[item.id] = "Invalid Date";
          }
        }

        if (item.subType === "TIME") {
          const response = responses.find(
            (r) => r.questionId === item.id && !parseTime(r.answer).isValid()
          );
          if (response) {
            errors[item.id] = "Invalid Time";
          }
        }

        // required questions
        if (requiredQuestions.includes(item.id)) {
          const hasValidResponse = responses.some(
            (r) => r.questionId === item.id && Boolean(r.answer)
          );
          if (!hasValidResponse) {
            errors[item.id] = "Required Field";
          }
        }

        const questionRequiredComments = requiredComments.filter(
          (rc) => rc.questionId === item.id
        );
        // required comments
        if (questionRequiredComments.length) {
          questionRequiredComments.forEach((rc) => {
            const response = responses.find(
              (r) =>
                r.questionId === item.id && r.associatedId === rc.associatedId
            );
            if (response && !response.comments) {
              errors[`${item.id}_${rc.associatedId}_comment`] =
                "Required Comment";
            }
          });
        }
      }
    }
  }

  return errors;
};

interface ValidationArgs {
  allowedTypes: Array<SignatureType>;
  typedSig: string;
  name: string;
  email: string;
  fullName: string;
}

export function typedSignatureValidationError(
  args: ValidationArgs
): string | undefined {
  if (
    args.allowedTypes.includes("TYPED_ANYTHING") &&
    !didTypeAnything(args.typedSig)
  ) {
    return "Must be at least 3 characters";
  }

  if (
    !args.allowedTypes.includes("TYPED_ANYTHING") &&
    args.allowedTypes.includes("TYPED_EMAIL") &&
    args.allowedTypes.includes("TYPED_NAME") &&
    !didTypeEmail(args.typedSig, args.email) &&
    !didTypeName(args.typedSig, args.name, args.fullName)
  ) {
    return "Must match the participant's name or email";
  }

  if (
    !args.allowedTypes.includes("TYPED_ANYTHING") &&
    args.allowedTypes.includes("TYPED_EMAIL") &&
    !args.allowedTypes.includes("TYPED_NAME") &&
    !didTypeEmail(args.typedSig, args.email)
  ) {
    return "Must match the participant's email";
  }

  if (
    !args.allowedTypes.includes("TYPED_ANYTHING") &&
    args.allowedTypes.includes("TYPED_NAME") &&
    !args.allowedTypes.includes("TYPED_EMAIL") &&
    !didTypeName(args.typedSig, args.name, args.fullName)
  ) {
    return "Must match the participant's name";
  }
}

function didTypeName(
  typedSig: string,
  name: string,
  fullName: string
): boolean {
  return (
    typedSig.toLowerCase() === name.toLowerCase() ||
    typedSig.toLowerCase() === fullName.toLowerCase()
  );
}

function didTypeEmail(typedSig: string, email: string): boolean {
  return typedSig.toLowerCase() === email.toLowerCase();
}

function didTypeAnything(typedSig: string): boolean {
  return typedSig.length > 2;
}
