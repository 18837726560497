import React, { useContext } from "react";
import { ThemeContext } from "styled-components";

import { Text, TextProps } from "components/clientAdmin/styles";

export const Subtitle: React.FC<TextProps> = (props) => {
  const theme = useContext(ThemeContext);
  return (
    <Text
      color={theme.colors.black}
      fontSize="1rem"
      fontWeight={400}
      letterSpacing="0.15px"
      margin="12px 0 24px 0"
      {...props}
    />
  );
};

export const Title: React.FC<TextProps> = (props) => (
  <Text
    fontSize="1.25rem"
    fontWeight={500}
    letterSpacing="0.26px"
    margin="51px 0 0 0"
    {...props}
  />
);
