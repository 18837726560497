import React, { useContext } from "react";
import styled, { ThemeContext } from "styled-components";

import { Text, TextProps } from "components/clientAdmin/styles";

export const HelpDesk = styled.div`
    max-width: 990px;
    padding: 0 15px;
    width: 100%;
`;

export const HelpDeskTitle = styled.div`
    font-size: 1.25rem;
    font-weight: 500;
    letterSpacing: 0.26px;
    padding-top: 46.5px;
`;

export const About = styled.div`
    color: ${({ theme }) => theme.colors.black};
    font-size: 1rem;
    font-weight: 500;
    letter-spacing: 0.49px;
    margin-top: 34px;
`;

export const HelpDeskRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 13px;
`;

export const ChevronLink = styled.i.attrs({
  className: "icon-icons8-chevron-right",
})`
    color: ${({ theme }) => theme.masterColors.darkGrey};
    cursor: pointer;
    font-size: 1.5rem;
`;

export const GotQuestionsWrapper = styled.div`
    align-items: center;
    display: flex;
    justify-content: start;
    margin-left: 3px;
    margin-top: 48px;
`;

export const GotQuestionsIcon = styled.i.attrs({
  className: "icon-icons8-help",
})`
    color: ${({ theme }) => theme.colors.primary};
    font-size: 1.875rem;
`;

export const GotQuestionsCTA = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: 13px;
`;

export const GotQuestionsHeading: React.FC<TextProps> = (props) => {
  const theme = useContext(ThemeContext);
  return (
    <Text
      color={theme.masterColors.darkGrey}
      fontSize="0.865rem"
      fontWeight={500}
      letterSpacing="2.19px"
      textTransform="uppercase"
      {...props}
    />
  );
};

export const RowTitle: React.FC<TextProps> = (props) => {
  const theme = useContext(ThemeContext);
  return (
    <Text
      color={theme.colors.black}
      fontSize="1rem"
      fontWeight={400}
      letterSpacing="0.29px"
      {...props}
    />
  );
};

export const VersionNumber: React.FC<TextProps> = (props) => {
  const theme = useContext(ThemeContext);
  return (
    <Text
      color={theme.masterColors.darkGrey}
      fontSize="1rem"
      fontWeight={400}
      letterSpacing="0.29px"
      {...props}
    />
  );
};
