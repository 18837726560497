import React, { CSSProperties } from "react";
import { StyleSheet } from "aphrodite";
import { ThemeContext } from "styled-components";

export default function(): { [key: string]: CSSProperties } {
  const theme = React.useContext(ThemeContext);

  return StyleSheet.create({
    reorderButton: {
      margin: 0,
      padding: 0,
      marginRight: "10px",
      color: theme.masterColors.darkGrey
    },
    buttonsContainer: {
      display: "flex",
      flexDirection: "column"
    }
  });
};
