import _, { get } from "lodash";
import moment from "moment";

import {
  DocumentQuestionResponseVm,
  DisplayConditionDTO,
  DocumentParticipant,
} from "store/documents/types";
import {
  SectionDTO,
  QuestionDTO,
  BaseItemDTO,
  SectionItem,
} from "store/forms/types";
import { Defense } from "store/documents/types";
import { DataSourceValueDTO } from "store/dataSource/types";
import { WorkOrderType } from "store/workOrders/types";
import { PrefillAlternates } from "store/appConfigs/types";
import { ItemType } from "components/clientAdmin/formBuilder/types";
import { toString } from "ramda";
import { HasIdOrNameOrRoles } from "store/newDocument/types";
import { ParticipantResponse } from "components/forms/types";

/**
 * Extract all items of a form from each of its sections
 * @param sections
 * @param types
 */
export function getFlattenedQuestions(
  sections: Array<SectionDTO>,
  types?: Array<string>
): Array<QuestionDTO> {
  return sections.reduce((result: Array<QuestionDTO>, section) => {
    section.items?.forEach((item) => {
      if (item.type === "QUESTION") {
        if (!types || types.length === 0) {
          result.push(item as QuestionDTO);
        } else if (types && types.includes(item.subType)) {
          result.push(item as QuestionDTO);
        }
      }
    });
    return result;
  }, []);
}

/**
 * Extract all items of a form from each of its sections
 * @param sections
 * @param itemType - optional: only return items of a specific type
 * @param subTypes - optional: only return items matching specific subtypes
 */
export function getFlattenedItems(
  sections: Array<SectionDTO>,
  itemType?: ItemType,
  types?: Array<string>
): Array<BaseItemDTO> {
  return sections.reduce((result: Array<BaseItemDTO>, section) => {
    section.items?.forEach((item) => {
      if (!itemType) {
        // if no item type is specified, return all items
        result.push(item);
      } else if (item.type === itemType) {
        if (!types || types.length === 0) {
          result.push(item);
        } else if (types && types.includes(item.subType)) {
          result.push(item);
        }
      }
    });
    return result;
  }, []);
}

export type LocationResponseLngLat = {
  lat: number | null;
  lng: number | null;
};

/**
 * Find all existing location question responses and return their geocoordinates
 * @param sections          - sections of the form
 * @param initialResponses  - existing responses
 */
export function getLocationQuestionResponse(
  sections: Array<SectionDTO>,
  initialResponses?: Array<DocumentQuestionResponseVm>
): Array<LocationResponseLngLat> {
  if (!initialResponses) {
    return [];
  }

  return getFlattenedQuestions(sections)
    .filter((item) => item.subType === "LOCATION")
    .map((y) => initialResponses.find((z) => z.questionId === y.id))
    .map((res) => {
      const lat: number | null = _.get(
        res,
        ["associatedLocation", "latitude"],
        null
      );
      const lng: number | null = _.get(
        res,
        ["associatedLocation", "longitude"],
        null
      );

      return { lat, lng };
    });
}

/**
 * **getRequiredQuestions** returns as array of questions ids
 * which represents questions that are required. Required
 * questions will contains `true` for their `isRequired`
 * property, under `formProperties`
 */
export const getRequiredQuestions = (
  questions: Array<QuestionDTO>
): Array<number> => {
  return questions.reduce((result: Array<number>, question) => {
    if (question.formProperties?.isRequired) {
      result.push(question.id);
    }
    return result;
  }, []);
};

/**
 * returns as array of question selection ids which require
 * comments.
 */
export const getRequiredSelectionComments = (
  questions: Array<QuestionDTO>
): Array<{
  associatedId: number;
  questionId: number;
}> => {
  return questions.reduce(
    (
      result: Array<{
        associatedId: number;
        questionId: number;
      }>,
      question
    ) => {
      if (question.selections) {
        question.selections.forEach((selection) => {
          if (selection.properties?.commentRequired) {
            result.push({
              associatedId: selection.id,
              questionId: question.id,
            });
          }
        });
      }
      return result;
    },
    []
  );
};

interface GetCommentForDefense {
  defenses: Array<Defense>;
  responses: Array<DocumentQuestionResponseVm>;
}

/**
 * Returns an object with the key
 * being the associatedId and the value
 * being the response. Only returns values
 * which have associated Defenses
 */
export const getResponsesAssociatedWithDefenses = ({
  defenses,
  responses,
}: GetCommentForDefense) => {
  const responseIDsFromDefenses = defenses.flatMap((x) =>
    x.questionSelections.map((qs) => qs.id)
  );
  const filteredResponses = responses.filter(
    (r) => r.associatedId && responseIDsFromDefenses.includes(r.associatedId)
  );

  return _.zipObject(
    filteredResponses.map((x) => x.associatedId || x.questionId),
    filteredResponses
  );
};

// PREFILL Display Condition helpers

/**
 * Build a participant from a prefilled response
 * @param question Prefill target question
 * @param response Prefilled response
 */
export function buildPrefillParticipant(
  question: QuestionDTO,
  response: DocumentQuestionResponseVm
) {
  return {
    participantId: response.associatedId || 0,
    role: question.participantRole || ("ATTENDANT" as const),
    timeAdded: moment()
      .utc()
      .format(),
  };
}

/**
 * Build the participants array from responses after prefilling a question
 * @param targetQuestion
 * @param responses
 * @param participants
 */
export function buildPrefillParticipantArray(
  targetQuestion: QuestionDTO,
  responses: DocumentQuestionResponseVm[],
  participants: DocumentParticipant[]
) {
  const participantResponse = responses.find(
    (res) => res.questionId === targetQuestion.id
  );
  if (participantResponse) {
    return [
      ...participants,
      buildPrefillParticipant(targetQuestion, participantResponse),
    ];
  } else {
    return participants;
  }
}

/**
 * Find alternate fields base on the client's clientOverrides.form config
 * @param condition Display condition
 * @param prefillAlternates Alternate field config
 */
function getAlternateFields(
  condition: DisplayConditionDTO,
  prefillAlternates: PrefillAlternates[]
) {
  const alternate = prefillAlternates.find((alt: PrefillAlternates) =>
    condition.prefillAnswerField?.includes(alt.targetField)
  );
  if (alternate) {
    const alternateAnswerField = condition.prefillAnswerField?.replace(
      alternate.targetField,
      alternate.alternateField
    );
    const alternateAssociatedIdField = condition.prefillAssociatedIdField?.replace(
      alternate.targetField,
      alternate.alternateField
    );
    const alternatePrefillAssociatedLocation = condition.prefillAssociatedLocation?.replace(
      alternate.targetField,
      alternate.alternateField
    );

    return {
      prefillAnswerField: alternateAnswerField,
      prefillAssociatedIdField: alternateAssociatedIdField,
      prefillAssociatedLocation: alternatePrefillAssociatedLocation,
    };
  }
}

/**
 * Build a response from a display condition
 * @param condition Display condition
 * @param dataSourceValue Object to use for the display condition values
 * @param prefillAlternates
 */
export function buildPrefillResponse(
  condition: DisplayConditionDTO,
  dataSourceValue: DataSourceValueDTO | WorkOrderType | {},
  prefillAlternates?: PrefillAlternates[]
): DocumentQuestionResponseVm {
  let answer;
  if (condition.prefillAnswerField) {
    answer = get(dataSourceValue, condition.prefillAnswerField);

    if (typeof answer !== "string") {
      switch (condition.prefillAnswerField) {
        case "supervisor":
        case "participantCreatedBy":
          answer = get(
            dataSourceValue,
            condition.prefillAnswerField + ".fullName"
          );
          break;
        case "workLocation":
          answer = get(dataSourceValue, "workLocation.locationId");
          break;
        case "geolocation":
        case "gpsString":
          answer = get(dataSourceValue, "geolocation.gpsString");
          break;
        case "participants":
          const participants = get(dataSourceValue, "participants");
          if (Array.isArray(participants)) {
            answer = participants
              .map((p) => {
                return get(p, "name");
              })
              .join(", ");
          }
          break;
      }
      if (
        answer !== null &&
        answer !== undefined &&
        typeof answer !== "string"
      ) {
        answer = toString(answer);
      }
    }
  }
  let associatedId = condition.prefillAssociatedIdField
    ? get(dataSourceValue, condition.prefillAssociatedIdField)
    : null;
  let associatedLocation = condition.prefillAssociatedLocation
    ? get(dataSourceValue, condition.prefillAssociatedLocation)
    : null;

  if (!answer && prefillAlternates && prefillAlternates.length > 0) {
    const alternate = getAlternateFields(condition, prefillAlternates);
    if (alternate) {
      answer = alternate.prefillAnswerField
        ? get(dataSourceValue, alternate.prefillAnswerField)
        : answer;
      associatedId = alternate.prefillAssociatedIdField
        ? get(dataSourceValue, alternate.prefillAssociatedIdField)
        : associatedId;
      associatedLocation = alternate.prefillAssociatedLocation
        ? get(dataSourceValue, alternate.prefillAssociatedLocation)
        : associatedLocation;
    }
  }

  return {
    questionId: condition.targetRootId, // don't trust it
    questionRootId: condition.targetRootId,
    answer,
    associatedId,
    associatedRootId: associatedId,
    associatedLocation,
    timeAnswered: moment().toISOString(),
  };
}

/**
 * Find an item within a form's sections with a rootId that matches argument rootId
 * @param rootId
 * @param sections
 */
export function getItemByRootId(
  rootId: number,
  sections: Array<SectionDTO>
): SectionItem | undefined {
  for (const section of sections) {
    for (const item of section.items) {
      if (item.rootId === rootId) {
        return item;
      }
    }
  }
}

export function areParticipantsEqual(
  a: HasIdOrNameOrRoles,
  b: HasIdOrNameOrRoles
): boolean {
  return (
    (!!a.participantId &&
      !!b.participantId &&
      a.participantId === b.participantId) || // both have participantIds and participantIds are equal, or
    (!a.participantId &&
      !b.participantId &&
      !!a.name &&
      !!b.name &&
      a.name === b.name) // neither have participantIds and names are equal
  );
}

export function areParticipantsWithRolesEqual(
  a: HasIdOrNameOrRoles,
  b: HasIdOrNameOrRoles
): boolean {
  return (
    (!!a.participantId &&
      !!b.participantId &&
      a.participantId === b.participantId &&
      a.role === b.role) || // both have participantIds, participantIds and participantRole are equal, or
    (!a.participantId &&
      !b.participantId &&
      !!a.name &&
      !!b.name &&
      a.name === b.name &&
      a.role === b.role) // neither have participantIds and names/participantRoles are equal
  );
}

export function filterParticipants(
  participants: DocumentParticipant[],
  responses: DocumentQuestionResponseVm[],
  participantQuestionIds: Set<number>
): DocumentParticipant[] {
  return participants.filter((p) =>
    responses
      .filter((r) => participantQuestionIds.has(r.questionId))
      .find((r) =>
        areParticipantsEqual(p, {
          participantId: r.associatedId,
          name: r.answer,
        })
      )
  );
}

export function findParticipant(
  response: DocumentQuestionResponseVm,
  participantData?: ParticipantResponse[]
): ParticipantResponse | undefined {
  return participantData?.find((p) =>
    areParticipantsEqual(
      {
        participantId: p.participant.id,
        name: p.participant.name,
      },
      { participantId: response.associatedId, name: response.answer }
    )
  );
}
