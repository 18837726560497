import React, { PropsWithChildren } from "react";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import styles from "./styles.module.scss";
import { joinClassNames } from "themes/helpers";

interface DrawerProps {
  anchor?: "bottom" | "left" | "right" | "top";
  className?: string;
  labelId?: string;
  id?: string;
  onClose?: () => void;
  onOpen?: () => void;
  isOpen: boolean;
}

export const Drawer = ({
  anchor = "bottom",
  children,
  className,
  id,
  isOpen,
  labelId,
  onClose = () => {},
  onOpen = () => {},
}: PropsWithChildren<DrawerProps>) => {
  return (
    <SwipeableDrawer
      anchor={anchor}
      id={id}
      ModalProps={{
        BackdropProps: {
          className: styles.backdrop,
        },
      }}
      onClose={onClose}
      onOpen={onOpen}
      open={isOpen}
      PaperProps={{
        "aria-labelledby": labelId,
        className: joinClassNames(styles.paper, className),
        elevation: 0,
      }}
    >
      {children}
    </SwipeableDrawer>
  );
};
