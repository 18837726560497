import React from "react";

import colors from "themes/colors";
import Icon from "components/common/Icon";

import * as S from "./styles";

interface Props {
  label: string;
  locked?: boolean;
  name: string;
}

const DesktopLabel = ({ label, name, locked }: Props) => {
  return (
    <S.LabelRow>
      {locked && <Icon type="lock" framed={false} color={colors.masterColors.mediumGrey} />}
      <S.Label locked={locked} htmlFor={name}>
        {label}
      </S.Label>
    </S.LabelRow>
  );
};

export default DesktopLabel;
