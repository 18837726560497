import React, { CSSProperties } from "react";
import { StyleSheet } from "aphrodite";
import { ThemeContext } from "styled-components";

export default function (): { [key: string]: CSSProperties } {
  const theme = React.useContext(ThemeContext);

  return StyleSheet.create({
    tabBarWrapper: {
      boxShadow: "0px 3px 6px #00000029",
      background: theme.colors.primary,
    },
    indicator: {
      backgroundColor: "inherit"
    }
  });
};
