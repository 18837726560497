import { StyleSheet } from "aphrodite";

import { ThemeTypes } from "themes/colors";

const chevronWrapper = {
  cursor: "pointer",
  left: "15px",
  position: "absolute" as "absolute",
  top: "17px",
};

export const createStyles = (theme: ThemeTypes) => {
  return StyleSheet.create({
    chevronWrapper,
    title: {
      color: theme.masterColors.black,
      fontSize:"1.25rem",
      fontWeight: 500,
      letterSpacing: "0.26px",
      // Ellipsis
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      paddingLeft: "25px",
    },
    wrapper: {
      backgroundColor: `${theme.colors.white}`,
      boxShadow: `0px 1px 3px ${theme.masterColors.lightGrey}`,
      height: "56px",
      left: 0,
      lineHeight: "1.5rem",
      paddingBottom: "1.05rem",
      paddingLeft: "18px",
      paddingRight: "18px",
      paddingTop: "1.05rem",
      position: "sticky" as "sticky",
      textAlign: "center",
      top: 0,
      width: "auto",

    }
  });
};
