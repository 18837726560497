import React, { useState, useMemo } from "react";
import styled from "styled-components";

// Global
import * as S from "components/forms/ItemSelectorDrawer/styles";
import Drawer from "components/common/Drawer";
import SearchInput from "components/forms/SearchInput";
import { Button } from "components/forms/Button";
import { ClientGroup } from "store/clientGroups/types";
import { Option } from "components/common/form/Select/Select";


const addLocalGroup = (groupId: number, localGroups: number[]) => {
  return [
    ...localGroups,
    groupId,
  ];
};

const removeLocalGroup = (groupId: number, localGroups: number[]) => {
  return localGroups.filter((group) => group !== groupId);
};

const SelectOrDeselectAll = styled.span`
  color: ${({ theme }) => theme.colors.primary};
  cursor: pointer;
  font-weight: 500;
  margin-bottom: 1rem;
  text-decoration: underline;

`;

const ActionRow = styled.div`
  align-items: flex-end;
  display: flex;
  justify-content: space-between;
  margin-top: auto;
  padding: 1rem;
  width: 100%;
`;

const Circle = styled.span`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 10px;
  box-sizing: border-box;
  color: ${({ theme }) => theme.colors.primary};
  display: flex;
  flex-wrap: wrap;
  font-size: 1rem;
  font-weight: 500;
  margin-left: 4px;
  margin-right: 4px;
  padding-left: 6px;
  padding-right: 6px;
`;

interface Props {
  groups?: ClientGroup[];
  handleClose: (role?: string, groups?: number[]) => void;
  isOpen: boolean;
  role?: Partial<Option>;
}

export const GroupPicker = ({ groups = [], handleClose, isOpen, role }: Props) => {
  const _role = role?.value || "N/A";

  // Search input
  const [searchInputValue, setSearchInputValue] = useState("");

  // Added groups (local, not saved, by ID)
  const [localAddedGroups, setLocalAddedGroups] = useState<number[]>([]);

  // Filtered groups (local)
  const _groups = useMemo(() => {
    // `group.name` can be undefined, probably shouldn't be possible. It's possible it's an empty string,
    // so I added filters -- GK
    if (searchInputValue?.length > 3) {
      return groups.filter((group) => group.name).filter((group) => {
        if (group.name) {
          return group.name?.toLowerCase().indexOf(searchInputValue.toLowerCase()) > -1;
        }
      });
    }
    return groups.filter((group) => group.name);
  }, [groups, searchInputValue]);

  // Random UI variables
  const numberOfGroupsToAdd = useMemo(() => localAddedGroups.length, [localAddedGroups.length]);
  const pluralOrSingularButtonLabel = useMemo(() => {
    if (numberOfGroupsToAdd === 1) return "GROUP";
    return "GROUPS";
  }, [numberOfGroupsToAdd]);
  const selectOrDeselectCopy = useMemo(() => {
    if (numberOfGroupsToAdd === _groups?.length) {
      return "Deselect All";
    }
    return "Select All";
  }, [_groups, numberOfGroupsToAdd]);

  // Add or Remove all groups
  const addOrRemoveAllLocalGroups = () => {
    const action: "remove" | "add" = 
      _groups.length === localAddedGroups.length ? "remove" : "add";
    if (action === "remove") {
      setLocalAddedGroups([]);
    } else {
      const _flattenedGroups = _groups?.map((group) => group.id || 0) || [];
      setLocalAddedGroups(_flattenedGroups);
    }
  };

  return (<Drawer
    content={
      <>
        <S.ItemSelector responsiveWidth={true}>
          {/* HEADER AREA */}
          <S.TitleRow>
            <S.Row variant="header" withBottomBorder>
              <S.Row fullWidth>
                <S.Col>
                  <S.Title>{`Add group to ${_role} role`}</S.Title>
                  <S.SubTitle>Which groups would you like to add to this set of permissions?</S.SubTitle>
                </S.Col>

                <S.CloseButton onClick={() => {
                  setLocalAddedGroups([]);
                  handleClose();
                }}
                >
                  <S.Icon className="icon-icons8-delete_sign" />
                </S.CloseButton>
              </S.Row>
            </S.Row>

            <S.Row>
              <S.Col fullWidth>
                <SearchInput
                  fullWidth={false}
                  handleChange={(e) => setSearchInputValue(e.target.value)}
                  name="ItemSelectorDrawerSearch-GroupPicker`"
                  placeholder={"Search by name"}
                  rounded
                  value={searchInputValue}
                  endAdornment={{
                    visible: !!searchInputValue,
                    label: "clear",
                    handleClick: () => setSearchInputValue(""),
                  }}
                />
              </S.Col>
            </S.Row>
          </S.TitleRow>
          <S.Row padding="0 16px 0 28px" variant="title">
            <S.FormSectionTitle>
              GROUPS IN ALPHABETICAL ORDER
            </S.FormSectionTitle>
          </S.Row>
          <S.ItemRow>
            <S.Row padding="8px 16px 16px 16px">
              <S.List>
                {_groups?.map((group: ClientGroup) => {
                  const _id = group?.id || 0; // ID is not required so we make the check once -- GK
                  return <S.ParticipantListItem 
                    key={group.id}
                    renderLabelsAsRow
                    active={localAddedGroups.includes(_id)}
                    label={group.name}
                    onClick={() => {
                      localAddedGroups.includes(_id) ? 
                        setLocalAddedGroups(removeLocalGroup(_id, localAddedGroups))
                        :
                        setLocalAddedGroups(addLocalGroup(_id, localAddedGroups));
                    }}
                    variant="ItemSelectorDrawer"
                  />;
                }
                  )}
              </S.List>
            </S.Row>
          </S.ItemRow>

          <ActionRow>
            <SelectOrDeselectAll onClick={addOrRemoveAllLocalGroups}>
              {selectOrDeselectCopy}
            </SelectOrDeselectAll>
            <Button 
              onClick={() => {
                handleClose(role?.id, localAddedGroups);
                setLocalAddedGroups([]);
              }} 
              disabled={numberOfGroupsToAdd === 0}
            >
              {"ADD"} <Circle>{numberOfGroupsToAdd}</Circle>{pluralOrSingularButtonLabel}
            </Button>
          </ActionRow>
        </S.ItemSelector>
      </>
    }
    anchor="right"
    id="GroupPickerDrawer"
    onClose={() => {
      setLocalAddedGroups([]);
      handleClose();
    }}
    open={isOpen}
    paperProps={{ elevation: 0 }}
    overrideClasses={{
      content: "myDocumentsDrawerDesktop",
    }}
  />);
};
