import styled from "styled-components";

import { ErrorText as ErrorTextBase } from "components/forms/TextInput/styles";
import {
  MultiInput as MultiInputStyledContainer,
  ListItems as MultiInputStyledListItems,
} from "components/common/form/MultiInput/styles";
import LoaderBase from "components/common/Loader";
import MultiInput from "components/common/form/MultiInput";
import { toRgba } from "themes/helpers";

export const ShortFieldWrapper = styled.div`
  width: 100%;
  max-width: 465px;
`;

export const TagsInput = styled(MultiInput)`
  .MuiListItemText-root {
    margin-top: 0;
    margin-bottom: 0;
  }
  .MuiListItemSecondaryAction-root {
    button {
      padding: 0;
    }
  }
`;

export const PopperWrapper = styled.div`
  width: 320px;
  // height: 161px;
  // align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px 12px;
  position: relative;

  &:before {
    content: "";
    position: absolute;
    right: -24px;
    top: calc(50% - 15px);
    // z-index: -1;
    width: 15px;
    height: 15px;
    background-color: ${({ theme }) => theme.colors.white};
    border-right: 1px solid ${({ theme }) => toRgba(theme.colors.black, 0.1)};
    border-bottom: 1px solid ${({ theme }) => toRgba(theme.colors.black, 0.1)};
    transform: rotate(-45deg) translate(-14px, -7px);
  }
`;

type PopperRowType = {
  error?: boolean;
  marginBottom?: boolean;
};

export const PopperRow = styled.div<PopperRowType>`
  align-items: center;
  color: ${({ error, theme }) =>
    error ? theme.colors.error : theme.masterColors.darkGrey};
  cursor: pointer;
  display: flex;
  font-size: 0.875rem;
  justify-content: space-between;
  margin-bottom: 15px;
  width: 100%;
  &:last-of-type {
    margin-bottom: 0;
  }
`;

export const PopperChevron = styled.i.attrs({
  className: "icon icon-icons8-chevron-left",
})<{
  color?: string;
}>`
  color: ${({ color, theme }) =>
    color || theme.masterColors.darkGrey} !important;
  display: inline-block;
  transform: rotate(180deg);
  font-size: 25px;
  margin-left: 30px;
`;

export const LoadingWrapper = styled.div`
  position: relative;
  height: 100%;
`;

export const PageLoader = styled(LoaderBase)`
  position: absolute;
  background: ${({ theme }) => toRgba(theme.colors.white, 0.95)};
  z-index: 1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;

export const ResourceForm = styled.div``;

export const FileUploadFieldWrapper = styled.div`
  margin-bottom: 1rem;
`;

export const FileUploadItemsContainer = styled(MultiInputStyledContainer)``;
export const FileUploadItems = styled(MultiInputStyledListItems)``;

export const FileUploadFieldPlaceholder = styled.label`
  color: ${({ theme }) => theme.masterColors.mediumGrey};
  cursor: pointer;
  display: flex;
  margin: 0;
`;

export const AddedFile = styled.div`
  display: flex;
  padding: 0.3125rem 0.625rem;
  border: 1px solid ${({ theme }) => theme.colors.lightGrey};
  border-radius: 4px;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 4px;
`;

export const AddedFileLabel = styled.div`
  margin: 4px 0;
`;

export const UploadFileButton = styled.label`
  color: ${({ theme }) => theme.colors.primary};
  display: inline-flex;
  flex-direction: row;
  flex-shrink: 0;
  flex-wrap: no-wrap;
  font-size: 0.875rem;
  font-weight: 500;
  letter-spacing: 1.25px;
  margin-bottom: 0;
  margin-left: 1rem;
  padding: 16px 0;
  text-transform: uppercase;
  cursor: pointer;
`;

export const ResourceHistoryContainer = styled.div`
  margin: 2rem 0;
`;

export const ResourceHistoryItem = styled.p`
  margin-bottom: 0.5rem;
  color: ${({ theme }) => theme.masterColors.darkGrey};

  b {
    color: ${({ theme }) => theme.masterColors.black};
    padding-right: 4px;
  }
`;

export const ErrorText = styled(ErrorTextBase)`
  white-space: pre-line;
`;
