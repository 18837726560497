import React from "react";
import { Formik, Form, FormikErrors, FormikTouched } from "formik";
import { css } from "aphrodite/no-important";

import Tooltip from "components/common/Tooltip";
import { AuthFormProps, AuthFormValues } from "components/auth/types";
import { ButtonStates, FieldProps, InputProps } from "./types";
import { DocumentValues } from "components/document/types";
import { Button, SecondaryButton } from "components/common/Button/futureUiKit";

import * as S from "./styles";
import { PASSWORD_REGEX } from "components/auth/fields";
import { PasswordRequirements } from "components/forms/PasswordRequirements";

/**
 * Determine text for current state of form's submit button
 * @param status {"success"|"failure"|undefined}
 * @param text {string[]}
 */
function buttonText(
  text: ButtonStates["primary"] | ButtonStates["secondary"],
  status?: string | null
) {
  if (typeof text !== "undefined") {
    if (status === "success") {
      return text.success;
    } else if (status === "failure") {
      return text.failure;
    } else {
      return text.initial;
    }
  }
}

/**
 * Create field elements based on passed input parameters
 * @param values          - form values
 * @param errors          - form errors
 * @param touched         - touched form fields
 * @param fields          - object containing field parameters
 * @param handleChange
 * @param handleBlur
 * @param recaptchaNeeded - is ReCAPTCHA auth needed
 */
function generateFields(
  values: FormValueTypes,
  errors: FormikErrors<FormValueTypes>,
  touched: FormikTouched<FormValueTypes>,
  fields: FieldProps,
  handleChange,
  handleBlur,
  recaptchaNeeded?: boolean
) {
  try {
    return fields.input.map((field) => {
      //@TODO change to switch when more than one field type is supplied
      switch (field.type) {
        case "text":
          return (
            <S.TextInput
              key={field.name}
              type={field.type}
              variant={field.variant}
              height={field.height}
              onBlur={handleBlur}
              onChange={handleChange}
              name={field.name}
              label={field.label}
              smallLabel={field.smallLabel}
              placeholder={field.placeholder}
              value={field.value || values[field.name]}
              error={touched[field.name] && errors[field.name]}
              endAdornment={field.endAdornment}
              helperText={field.helperText}
            />
          );
        case "tooltipPassword":
          return (
            <>
              <Tooltip
                key={field.name}
                title={
                  <PasswordRequirements
                    newPassword={field.value || values[field.name]}
                    passwordRegex={{
                      DIGIT: PASSWORD_REGEX.DIGIT,
                      FULL: PASSWORD_REGEX.FULL,
                      LOWERCASE: PASSWORD_REGEX.LOWERCASE,
                      SPECIAL: PASSWORD_REGEX.SPECIAL,
                      UPPERCASE: PASSWORD_REGEX.UPPERCASE,
                    }}
                  />
                }
                styles={{
                  marginLeft: 20,
                  marginTop: 15,
                  maxWidth: 360,
                  paddingRight: 0,
                }}
              >
                <S.TextInput
                  endAdornment={field.endAdornment}
                  error={errors[field.name]}
                  height={field.height}
                  hideErrorText={field.hideErrorText}
                  label={field.label}
                  name={field.name}
                  onBlur={field.onBlur}
                  onChange={handleChange}
                  onFocus={field.onFocus}
                  placeholder={field.placeholder}
                  smallLabel={field.smallLabel}
                  type={
                    field.endAdornment && field.endAdornment.label === "Hide"
                      ? "text"
                      : "password"
                  }
                  value={field.value || values[field.name]}
                  variant={field.variant}
                />
              </Tooltip>
            </>
          );
        case "password":
          return (
            <S.TextInput
              key={field.name}
              type={
                field.endAdornment && field.endAdornment.label === "Hide"
                  ? "text"
                  : "password"
              }
              variant={field.variant}
              height={field.height}
              onBlur={handleBlur}
              onChange={handleChange}
              name={field.name}
              label={field.label}
              smallLabel={field.smallLabel}
              placeholder={field.placeholder}
              value={field.value || values[field.name]}
              error={touched[field.name] && errors[field.name]}
              endAdornment={field.endAdornment}
            />
          );
        // Temp disable recaptcha for dominion
        // case "recaptcha":
        //   if (recaptchaNeeded && field.onRecaptchaVerify) {
        //     return (
        //       <S.RecaptchaContainer>
        //         <Reaptcha sitekey="6LfQjKgUAAAAAD0rMqr6-VvPnDcCjyL3SkDQyKc4" onVerify={field.onRecaptchaVerify} />
        //       </S.RecaptchaContainer>
        //     );
        //   }
        //   return null;
        default:
          return null;
      }
    });
  } catch (error) {
    throw Error(error);
  }
}

/**
 * Generate initialValues object used by Formik
 * @param inputs {InputProps[]} - array of input fields
 * @returns {{[name: string]: string|boolean|undefined}|{}}
 */
function initialValues(inputs: InputProps[]): FormValueTypes {
  const values: AuthFormValues = {};
  for (let i = 0; i < inputs.length; i++) {
    if (
      inputs[i].initialValue !== null &&
      typeof inputs[i].initialValue !== "undefined"
    ) {
      values[inputs[i].name] = inputs[i].initialValue;
    }
  }
  return values;
}

function errorMessage(
  error: string,
  accountLocked: boolean,
  navigate: (endpoint: string) => void,
  resetPassword?: boolean,
  formName?: string
): React.ReactNode {
  if (error !== "An error occurred.") {
    // FIXME bandaid solution, need a more reliable way to check error type -JA

    const isLoginForm = formName === "login";
    const largerResetPasswordBubbleIsShowing = !!resetPassword;

    if (accountLocked) {
      return (
        <S.Error>
          {error}&nbsp;
          {isLoginForm && (
            <span
              style={{ textDecorationLine: "underline", cursor: "pointer" }}
              onClick={() => navigate("/help")}
            >
              Reset your password.
            </span>
          )}
        </S.Error>
      );
    }
    return (
      <S.Error>
        {error}&nbsp;
        {isLoginForm && !largerResetPasswordBubbleIsShowing && (
          <span
            style={{ textDecorationLine: "underline", cursor: "pointer" }}
            onClick={() => navigate("/help")}
          >
            Need help logging in?
          </span>
        )}
      </S.Error>
    );
  }
  return <S.Error>{error}</S.Error>;
}

// union global Form props and values here
// type FormPropTypes = AuthFormProps | DocumentProps;
// @NOTE IDK why but I get an error when I have
// `ControlledForm`'s props set to `FormPropTypes` rather
// than `AuthFormProps
type FormValueTypes = AuthFormValues | DocumentValues;

/** Extensible class for form implementation */
class ControlledForm extends React.Component<AuthFormProps> {
  render() {
    const {
      accountLocked,
      error,
      externalLoading,
      fields,
      formName,
      handleSubmit,
      navigate,
      recaptchaNeeded,
      resetPassword,
      status,
      validateOnBlur,
      validateOnChange,
      validationSchema,
    } = this.props;
    const primaryButtonText = buttonText(fields.buttonStates.primary, status);
    return (
      <Formik
        initialValues={initialValues(fields.input)}
        validationSchema={validationSchema}
        validateOnBlur={validateOnBlur}
        validateOnChange={validateOnChange}
        onSubmit={(values, { setSubmitting }) => {
          handleSubmit(values);
          setSubmitting && setSubmitting(false);
        }}
        render={({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          isSubmitting,
        }) => {
          const isButtonLoading = externalLoading || isSubmitting;
          return (
            <Form translate="yes">
              {generateFields(
                values,
                errors,
                touched,
                fields,
                handleChange,
                handleBlur,
                recaptchaNeeded
              )}
              {error &&
                errorMessage(
                  error,
                  accountLocked,
                  navigate,
                  resetPassword,
                  formName
                )}
              {/* 
                If button text value is string, render a button.
                If button text value is something else, render it instead. 
              */}
              {(typeof primaryButtonText === "string" && (
                <Button
                  className={css(S.styles.button)}
                  loading={isButtonLoading}
                  type="submit"
                >
                  {primaryButtonText}
                </Button>
              )) ||
                buttonText(fields.buttonStates.primary, status)}

              {fields.buttonStates.secondary && (
                <SecondaryButton>
                  {buttonText(fields.buttonStates.secondary, status)}
                </SecondaryButton>
              )}
            </Form>
          );
        }}
      />
    );
  }
}

export default ControlledForm;
