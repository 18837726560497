import React, { useEffect, useState, useMemo } from "react";
import styled from "styled-components";
import { ThunkDispatch } from "redux-thunk";
import { bindActionCreators, Dispatch, Action } from "redux";
import { connect, useDispatch } from "react-redux";
import { get, orderBy } from "lodash";

import { AppState } from "store";
import { DocumentVM } from "store/documents/types";
import { getDocument } from "store/documents/actions";
import { getDocumentWithResponses } from "store/documents/selectors";
import Drawer from "components/common/Drawer";
import Header from "components/common/Drawer/components/Header";
import Loader from "components/common/Loader";
import { documentStatusSelector } from "store/documents/selectors";

import Responses from "./drawer/ResponseList";
import ActionBar from "./drawer/ActionBar";
import SubmissionStatus from "./drawer/SubmissionStatus";
import DocumentHistory from "components/DocumentSidebarDrawer/DocumentHistorySidebar/DocumentHistory";

import ViewDefenses from "./ViewDefenses";
import ViewSignatures from "./ViewSignatures";

import ShareForm from "components/forms/ShareForm";
import { SidebarDrawerHeader, SidebarDrawerBody } from "components/DocumentSidebarDrawer/styles";
import useGroupTerm from "util/hooks/whiteLabel/useGroupTerm";
import { FormVersionResponse, GetFormVersionActionType, FormVersionDTO } from "store/versionHistory/types";
import { getFormVersion } from "store/versionHistory/actions";


const Version = styled.span`
  align-items: flex-end;
  color: ${({ theme }) => theme.masterColors.mediumGrey};
  display: flex;
  font-size: 0.75rem;
  margin-bottom: 2px;
  margin-left: 5px;
`;

const ShareDocumentWrapper = styled.div`
  margin-top: 16px;
  text-align: left;
`;

interface DocumentDrawerProps {
  document: {
    data: DocumentVM | null;
    // loading: boolean;
    // error: boolean;
  };

  selectors: {
    documentStatusSelector: {
      loading: boolean;
      error: boolean;
    };
  };

  actions: any;
  closeDrawer: () => void;
  documentId: number;
  onClose: () => void;
  show: "document" | "history" | "share";
}

export const DocumentDrawerSections = {
  Document: "document",
  History: "history",
  Share: "share",
};

const DocumentDrawer = ({
  actions,
  closeDrawer,
  document,
  documentId,
  onClose,
  show,
  selectors: {
    documentStatusSelector: { loading: documentStatusSelectorLoading, error: documentStatusSelectorError },
  },
}: DocumentDrawerProps) => {
  // Show View Document, Document History or Share Document
  const [showing, setShowing] = useState<"document" | "history" | "share">("document");
  const [formVersionLoading, setFormVersionLoading] = useState<boolean>(false);
  const [latestFormVersion, setLatestFormVersion] = useState<string | undefined>();

  // Dispatch
  const dispatch = useDispatch<ThunkDispatch<AppState, void, Action>>();

  // Group config terms
  const documentTerm = useGroupTerm("document", "noun", undefined, "Document");
  const documentTermLower = documentTerm.toLowerCase();

  useEffect(() => {
    setShowing(show);
  }, [show]);

  useEffect(() => {
    if (show && documentId && !documentStatusSelectorLoading && !documentStatusSelectorError && !document.data) {
      actions.getDocument(documentId);
    }
  }, [documentId, documentStatusSelectorLoading, documentStatusSelectorError, show, document.data, actions]);

  const responseIds = useMemo(() => get(document, "data.responses", []).map((x) => x.associatedId),
    [document]);

  // Fetch version history for current form
  const fetchVersionHistory = async (id) => {
    setFormVersionLoading(true);
    const res: {
        type: GetFormVersionActionType;
        response: FormVersionResponse;
      } = await dispatch(getFormVersion(id));
    if (res?.type === GetFormVersionActionType.success) {
      if (res.response?.length > 0) {
        const _sortedByDate: FormVersionResponse = orderBy(res.response, [
          (item: FormVersionDTO) => new Date(item.lastModifiedDate)
        ],["desc"]);
        setLatestFormVersion(_sortedByDate[0]?.version);
      }
    }
    setFormVersionLoading(false);
  };

  // When document changes, fetch its form history
  React.useEffect(() => {
    if (document.data?.formSummary.id) {
      fetchVersionHistory(document.data.formSummary.id);
    }
  }, [document.data?.id]);

  return (
    <Drawer
      showCloseIcon={false}
      content={
        <div style={{ width: 420 }}>
          <Loader loading={documentStatusSelectorLoading}>
            {document.data && (
              <>
                <Header
                  className="d-flex justify-content-between px-3 pt-2 pb-3"
                  closeDrawer={closeDrawer}
                  endAdornment={latestFormVersion && <Version>{latestFormVersion}</Version>}
                  text={`${document.data.formSummary.name}`}
                />
                <ActionBar
                  setShowing={setShowing}
                />

                {/* View Document content */}
                {showing === DocumentDrawerSections.Document && 
                  <>
                    <SubmissionStatus document={document.data} />
                    <Responses document={document.data} />
                    <ViewDefenses 
                      documentId={documentId}
                      responseIds={responseIds}
                      document={document.data}
                      inDrawer
                    />
                    <ViewSignatures 
                      participants={get(document, "data.participants", [])}
                    />
                  </>
                }

                {/* Document History content */}
                {showing === DocumentDrawerSections.History && 
                  <div className="text-left">
                    <DocumentHistory document={document.data} />
                  </div>
                }

                {/* Share Document */}
                {showing === DocumentDrawerSections.Share && 
                  <ShareDocumentWrapper>
                    <SidebarDrawerHeader>Share {documentTerm}</SidebarDrawerHeader>
                    <SidebarDrawerBody>
                      Do you want to invite a coworker or manager from your company? When sharing, they’ll be allowed
                      to view the {documentTermLower} and all of your selections.
                    </SidebarDrawerBody>
                    <ShareForm
                      selectedDocument={document.data}
                      toggleShareDocumentVisibility={onClose}
                    />
                  </ShareDocumentWrapper>
                }

              </>
            )}
          </Loader>
        </div>
      }
      anchor="right"
      id="documentDrawer"
      onClose={onClose}
      // onOpen={() => {}}
      open={!!show}
    />
  );
};

const mapStateToProps = (state: AppState, props) => ({
  document: {
    data: getDocumentWithResponses(props.documentId, state.documents),
  },

  selectors: {
    documentStatusSelector: {
      loading: documentStatusSelector({
        documentState: state.documents,
        documentId: props.documentId,
        statusType: "loading",
      }),
      error: documentStatusSelector({
        documentState: state.documents,
        documentId: props.documentId,
        statusType: "error",
      }),
    },
  },
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  actions: {
    getDocument: bindActionCreators(getDocument, dispatch),
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(DocumentDrawer);
