import { StyleSheet, StyleDeclaration } from "aphrodite";
import { CreateStyleSheet } from "util/hooks/useThemedStyleSheet";
import { smallRegular } from "themes/mixins";
import { remCalc } from "themes/helpers";

interface CalendarStyle {
  calendar: StyleDeclaration;
  columnHeader: StyleDeclaration;
}

export const styles: CreateStyleSheet<CalendarStyle> = (theme) => {
  return StyleSheet.create({
    calendar: {
      borderCollapse: "collapse",
    },
    columnHeader: {
      ...smallRegular,
      border: `${remCalc(2)} solid transparent`,
      color: theme.masterColors.darkGrey,
      height: remCalc(28),
      width: remCalc(28),
    },
  });
};
