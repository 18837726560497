import styled from "styled-components";

import { remCalc } from "themes/helpers";
import TextBase from "components/common/Text";

export const DocumentInfo = styled.div``;

export const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`;

const commonTextStyles = `
  margin: 0;
  padding: 0;
`;

export const Title = styled(TextBase)`
  ${commonTextStyles}
  font-size: ${remCalc(20)};
  font-weight: 500;
  margin-bottom: 2px;
  margin-right: 8px;
`;
export const SubTitle1 = styled(TextBase)`
  ${commonTextStyles}
  font-size: ${remCalc(16)};
  font-weight: 400;
  margin-bottom: 2px;

`;
export const SubTitle2 = styled(TextBase)`
  ${commonTextStyles}
  font-size: ${remCalc(14)};
  font-weight: 300;
  color: ${({ theme }) => theme.masterColors.darkGrey};
`;
