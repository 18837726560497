import React from "react";

import { DataSource } from "store/dataSource/types";

import DataSetFields from "./DataSetFields";
import { NewAnswerSource } from "store/builder/types";
import { Property } from "components/clientAdmin/formBuilder/types";
import { QuestionAnswerSourceDTO } from "store/forms/types";

export function buildAnswerSource(
  dataSet: DataSource,
  sourceColumn: string,
  sortBy: string,
  answerSource?: QuestionAnswerSourceDTO
): NewAnswerSource {
  return {
    ...answerSource,
    dataSourceKey: dataSet.dataSourceKey,
    id: null,
    properties: {
      dataSourceId: dataSet.id,
      autoComplete: false,
      detailedSearch: {
        infiniteListSortBy: sortBy,
        ...answerSource?.properties.detailedSearch
      },
      answerField: sourceColumn,
    },
    type: "DATA_SOURCE"
  };
}

interface Props {
  label?: string;
  onChange: (val: NewAnswerSource | null) => void;
  placeholder?: string;
  helperText?: string;
  disabled?: boolean;
  property: Property;
  itemPath: string;
  initialValues?: QuestionAnswerSourceDTO;
}

const AnswerSource = ({ label, onChange, disabled, helperText, property, itemPath, initialValues }: Props) => {
  return (
    <DataSetFields 
      label={label}
      disabled={disabled}
      onUpdate={(dataSet?: DataSource, sourceColumn?: string, sortBy?: string) => {
        if (dataSet && sourceColumn && sortBy) {
          const val = buildAnswerSource(dataSet, sourceColumn, sortBy, initialValues);
          onChange(val);
        } else {
          onChange(null);
        }
      }}
      helperText={helperText}
      detailedSearchProps={property.detailedSearchProps}
      itemPath={itemPath}
      initialValues={initialValues}
    />
  );
};

export default AnswerSource;
