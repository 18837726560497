import React from "react";
import { css } from "aphrodite/no-important";
import { Field, useFormikContext } from "formik";

import { DataSource } from "store/dataSource/types";
import { DisplayConditionDTO } from "store/documents/types";
import {
  FBSection,
  FBItem,
  FBForm,
} from "components/clientAdmin/formBuilder/types";
import { Option } from "components/common/form/Select/Select";

import propertiesStyles from "../../styles";
import baseStyles from "../../../../styles";
import ShowHideField from "./ShowHideField";
import ShowHideCondition from "./ShowHideCondition";
import PrefillCondition from "./PrefillCondition";
import DeleteRuleButton from "./DeleteRuleButton";
import { isFormPublished } from "components/clientAdmin/formBuilder/helpers";

type RuleType = DisplayConditionDTO["action"];

function getRuleTypeValue(ruleType?: RuleType) {
  switch (ruleType) {
    case "PREFILL":
      return "prefill";
    case "SHOW":
    case "HIDE":
      return "showhide";
    default:
      return -1;
  }
}

interface ConditionProps {
  ruleType?: RuleType;
  itemIndex: number;
  conditionIndex: number;
  onRemove: (index: number) => void;
  onUpdateType: (index: number, type: RuleType) => void;
  responseOptions: Option[];
  targetOptions: Option[];
  targetItems: (FBItem | FBSection)[];
  dataSource?: DataSource;
  sourceColumns: { id: string | number; value: string }[];
  itemSubType: string;
}

const Condition = ({
  ruleType,
  itemIndex,
  conditionIndex,
  onRemove,
  onUpdateType,
  responseOptions,
  targetOptions,
  targetItems,
  dataSource,
  sourceColumns,
  itemSubType,
}: ConditionProps) => {
  const { values } = useFormikContext<FBForm>();
  const ps = propertiesStyles();
  const bs = baseStyles();

  function renderRule() {
    switch (ruleType) {
      case "SHOW":
      case "HIDE":
        return (
          <ShowHideCondition
            conditionIndex={conditionIndex}
            responseOptions={responseOptions}
            targetOptions={targetOptions}
            targetItems={targetItems}
            itemSubType={itemSubType}
          />
        );
      case "PREFILL":
        return (
          <PrefillCondition
            conditionIndex={conditionIndex}
            dataSet={dataSource}
            sourceColumns={sourceColumns}
            targetOptions={targetOptions}
            targetItems={targetItems}
          />
        );
      default:
        return;
    }
  }

  const value = getRuleTypeValue(ruleType);
  const isPublished = isFormPublished(values.workflowType);

  return (
    <div className={css(ps.dcRule)}>
      <div className={css([bs.row, ps.dcRow])}>
        <b style={{ color: "#000", fontSize: "0.875rem" }}>
          Rule {itemIndex + 1}
        </b>
        <Field
          as={ShowHideField}
          value={value}
          onChange={(e) =>
            onUpdateType(
              conditionIndex,
              e.target.value === "showhide" ? "SHOW" : "PREFILL"
            )
          }
          disabled={isPublished}
        />
        {!isPublished && (
          <DeleteRuleButton onDelete={() => onRemove(conditionIndex)} />
        )}
      </div>
      {renderRule()}
    </div>
  );
};

export default Condition;
