import styled from "styled-components";

import { remCalc } from "themes/helpers";
import mediaQueries from "themes/mediaQueries";
import Icon from "components/common/Icon";
import Text from "components/common/Text/Text";

export { Section, SectionTitle } from "../../FormController/styles";
export { AssistiveText } from "../CheckboxInput/styles";
export const Caret = styled.div`
  background: transparent;
  width: 0;
  height: 0;
  border-left: 1rem solid transparent;
  border-right: 1rem solid transparent;
  border-bottom: 1rem solid ${({ theme }) => theme.colors.white};
  margin-left: 4%;
`;
export const DetailsContainer = styled.div``;
export const DetailsBlockContainer = styled.div`
  background: ${({ theme }) => theme.colors.activeFill};
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.colors.primary};
  margin-top: 3.5rem;
  position: relative;
`;
export const DetailsTitleContainer = styled.div`
  background: ${({ theme }) => theme.colors.primary};
  border-radius: 4px;
  color: ${({ theme }) => theme.colors.white};
  display: flex;
  font-size: ${remCalc(20)};
  left: 5%;
  max-height: 7rem;
  padding: 10px;
  position: relative;
  top: -1.5rem;
  width: 90%;
`;
export const DetailsTitle = styled.span`
  width: 80%;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const DetailsTitleIcon = styled.i`
  color: ${({ theme }) => theme.colors.white};
  font-size: 1.8rem !important;
  margin: 0 1rem;
  @media (${mediaQueries.maxPhone}) {
    margin: 0 0.4rem 0 0;
  }
`;
export const DetailsWrapper = styled.div`
  padding: 0 2rem 0;
`;
export const DetailsHTML = styled.div`
  margin-bottom: 14px;
`;
export const Label = styled.h5`
  color: ${({ theme }) => theme.colors.black};
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: ${remCalc(15.5)};
`;
export const NoteContainer = styled.div`
  background: ${({ theme }) => theme.colors.white};
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  padding: 1rem 2rem;
`;
export const SubTitle = styled.span`
  display: block;
  font-weight: 600;
  padding: 0.6rem 0.2rem;
`;

export const SectionSeparator = styled.div`
  height: ${remCalc(1)};
  width: 100%;
  background-color: ${({ theme }) => theme.masterColors.mediumGrey};
  margin-top: ${remCalc(40)};
  margin-bottom: ${remCalc(40)};
`;

export const DocWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.colors.white};
  border-radius: 4px;
  border: 1px solid ${(props) => props.theme.colors.lightGrey};
  padding: 12.3px 12.3px 14.7px;
  margin-bottom: 10px;
`;

// TODO globalize -JA
export const FileType = styled(Text)`
  color: ${(props) => props.theme.masterColors.darkGrey};
  letter-spacing: 0.48px;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
`;

// TODO globalize -JA
export const TitleLink = styled.a`
  color: ${(props) => props.theme.colors.link};
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.56px;
  text-decoration: underline;
  line-height: 19px;
`;

export const Description = styled(Text)`
  font-size: 14px;
  letter-spacing: 0.56px;
  line-height: 19px;
  color: ${({ theme }) => theme.colors.black};
`;

export const IconAndSpecs = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 14.03px;
`;

export const StyledIcon = styled(Icon)`
  padding: 0;
  margin: 0 -4.35px;
`;

export const Specs = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const Spec = styled(Text)`
  font-size: 14px;
  letter-spacing: 0.25px;
  line-height: 19px;
  margin-left: 14px;
`;
