import React, { ChangeEvent } from "react";
import styled from "styled-components";

import { FilterSelect } from "components/common/FilterSelect";
import {
  getSubOrdinatesOptions,
  mapFormTypesToFilter,
  timeFilterOptions,
} from "components/helpers/filters";
import { FormTypeDTO } from "store/forms/types";
import { Owners } from "store/documents/types";
import DocumentSearchBar from "components/filters/DocumentSearchBar";
import {
  AutocompleteSuggestion,
  Autocomplete,
} from "components/forms/Autocomplete";
import { SearchDocumentsParams } from "store/documents/types";

import * as S from "../styles";
import { DateRange, DateRangeValue } from "components/common/DateRange";
import { useSelector } from "react-redux";
import { documentsFiltersSelector } from "store/filters/documents/selectors";
import moment from "moment";
import { participantTreeOptionsSelector } from "store/participantTreeFilter/selectors";

const DateRangeWrapper = styled.div`
  margin-left: 16px;
  margin-bottom: 12px;
`;

interface Props {
  formTypes: Array<FormTypeDTO>;
  getOwnerIds: (q?: string) => Promise<Owners>;
  getSearchParams: () => SearchDocumentsParams;
  handleChange: (value: string) => void;
  handleFilterChange: (e: any) => void;
  handleOwnerIdChange: (ownerId: number | null) => void;
  handleSubordinatesFilterChange: (e: ChangeEvent<HTMLSelectElement>) => void;
  mapSuggestions: (suggestions: Owners) => AutocompleteSuggestion[] | undefined;
  onDateRangeChange: (value: DateRangeValue) => void;
  onDateRangeClear: () => void;
  ownerIds: Owners;
  resetOwnerIds: () => void;
}

const DesktopSearchFilters = ({
  formTypes,
  getOwnerIds,
  getSearchParams,
  handleChange,
  handleFilterChange,
  handleOwnerIdChange,
  handleSubordinatesFilterChange,
  mapSuggestions,
  onDateRangeChange,
  onDateRangeClear,
  ownerIds,
  resetOwnerIds,
}: Props) => {
  const filtersSelect = useSelector(documentsFiltersSelector);
  const participantTreeOptions = useSelector(participantTreeOptionsSelector);
  const subordinatesOptions = getSubOrdinatesOptions(participantTreeOptions);
  const { filterParams } = filtersSelect;

  const initialCustomDates = filterParams.dateRange
    ? {
        // @ts-ignore
        from: moment(filterParams.dateRange?.startDate),
        // @ts-ignore
        to: moment(filterParams.dateRange?.endDate),
      }
    : {};

  return (
    <S.DesktopSearchFilters>
      <div className="row w-100" style={{ marginTop: "-20x" }}>
        <div className="col-12 d-flex flex-row justify-content-start align-items-center">
          <div className="mr-2">
            <FilterSelect
              name="documentType"
              value={filterParams.documentType}
              label=""
              options={mapFormTypesToFilter(formTypes) || []}
              handleChange={handleFilterChange}
            />
          </div>
          <div className="ml-2 mr-2">
            <FilterSelect
              name="timeFilter"
              value={filterParams.timeFilterType}
              label=""
              options={timeFilterOptions}
              handleChange={handleFilterChange}
            />
          </div>
          {!participantTreeOptions.hideFilter && (
            <div className="ml-2 mr-2">
              <FilterSelect
                name="subordinatesFilter"
                value={filterParams.subordinatesFilter}
                label=""
                options={subordinatesOptions}
                handleChange={handleSubordinatesFilterChange}
              />
            </div>
          )}
          <div className="ml-2 mr-2" style={{ minWidth: "182px" }}>
            <Autocomplete
              handleOwnerIdChange={handleOwnerIdChange}
              requestOptions={(q?: string) => {
                return getOwnerIds(q);
              }}
              resetSuggestions={resetOwnerIds}
              suggestions={mapSuggestions(ownerIds)}
              documentOwner={filterParams.documentOwner}
              suggestionMinWidth={182}
            />
          </div>
          <div className="ml-2" style={{ minWidth: "150px", width: "100%" }}>
            <DocumentSearchBar
              onChange={handleChange}
              params={() => getSearchParams()}
              query={filterParams.query}
            />
          </div>
        </div>
        {filterParams.timeFilterType === "CUSTOM_RANGE" && (
          <DateRangeWrapper>
            <span className="visually-hidden" id="custom-range-label">
              Custom Range
            </span>
            <DateRange
              handleChange={onDateRangeChange}
              labelId="custom-range-label"
              name="customRange"
              initialValues={initialCustomDates}
              onClear={onDateRangeClear}
            />
          </DateRangeWrapper>
        )}
      </div>
    </S.DesktopSearchFilters>
  );
};

export default DesktopSearchFilters;
