import { MethodTypes, Pagination } from "store/common/types";

export const GET_USERS = {
  REQUEST: "GET_USERS_REQUEST",
  SUCCESS: "GET_USERS_SUCCESS",
  FAILURE: "GET_USERS_FAILURE",
};

export interface usersState {
  loading: {
    getUsers: Partial<MethodTypes>;
  };
  errors: {
    getUsers: Partial<MethodTypes>;
  };
  data: {
    users: UsersResponse;
  };
}

export interface usersActionTypes {
  type: typeof GET_USERS.REQUEST | typeof GET_USERS.SUCCESS | typeof GET_USERS.FAILURE;
  response?: any;
}

export interface UsersResponse extends Pagination {
  content?: User[];
}

export interface User {
  accountLocked?: boolean;
  activated: boolean;
  activationKey?: string;
  authProviderId?: string;
  authProviderType: string;
  authorities: string[];
  createdBy?: string;
  createdDate?: string;
  email: string;
  firstName: string;
  id: number;
  imageUrl: string;
  langKey: string;
  lastModifiedBy: string;
  lastModifiedDate: string;
  lastName: string;
  resetDate: string;
  resetKey: string;
}
