import React from "react";

import { ProfileFieldSelectField as Props } from "../../types";

import DesktopSelectField from "./DesktopSelectField";

import * as S from "./styles";

const ProfileSelectField = (props: Props) => {
  const { name, options, value, onChange, onBlur, onFocus, isDesktop } = props;

  const mappedOptions = options.map((x) => ({
    value: x.value,
    id: x.id,
  }));

  if (isDesktop) {
    return (
      <DesktopSelectField
        {...props}
        options={mappedOptions}
        onChange={(e) => {
          onChange(e);
        }}
      />
    );
  }

  return (
    <S.Row>
      <S.SelectField
        name={name}
        onBlur={onBlur}
        onChange={(e) => onChange(e.currentTarget.value)}
        onFocus={onFocus}
        value={value}
      >
        <S.Option value={-1}></S.Option>
        {options.map((x) => (
          <S.Option key={x.id} value={x.id}>
            {x.value}
          </S.Option>
        ))}
      </S.SelectField>
    </S.Row>
  );
};

export default ProfileSelectField;
