import React, { useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";

import { AppState } from "store";
import { getClientGroups } from "store/clientGroups/actions";
import { ClientGroup } from "../../../store/clientGroups/types";

export type Groups = {
  data: Array<ClientGroup>;
  loading: boolean;
  error: boolean;
};

/**
 * Injects list of all client groups into WrappedComponent
 * @param WrappedComponent
 */
const withClientGroups = (WrappedComponent) => {
  const HOC = ({ actions, groups, ...props }) => {
    useEffect(() => {
      if (!groups.loading && !groups.error && groups.data.length === 0) {
        actions.getClientGroups({ sort: "name,asc", size: 200 });
      };
    }, [actions, groups]);

    return <WrappedComponent groups={groups} {...props} />;
  };

  const mapStateToProps = (state: AppState) => ({
    groups: {
      data: state.clientGroups.data.clientGroups.content,
      loading: state.clientGroups.loading.getClientGroups.GET,
      error: state.clientGroups.errors.getClientGroups.GET || false,
    },
  });

  const mapDispatchToProps = (dispatch: Dispatch) => ({
    actions: {
      getClientGroups: bindActionCreators(getClientGroups, dispatch),
    },
  });

  return connect(mapStateToProps, mapDispatchToProps)(HOC);
};

export default withClientGroups;
