import { GET_QUESTION_BY_ID, questionsState, questionsActionTypes } from "./types";

const initialState: questionsState = {
  loading: {
    getQuestionById: false,
  },
  errors: {
    getQuestionById: false,
  },
  data: {
    questionById: {},
  },
};

export function questionsReducer(state = initialState, action: questionsActionTypes): questionsState {
  const { response, type } = action;

  switch (type) {
    case GET_QUESTION_BY_ID.REQUEST:
      return {
        ...state,
        errors: {
          getQuestionById: false,
        },
        loading: {
          getQuestionById: true,
        },
      };
    case GET_QUESTION_BY_ID.SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          questionById: response,
        },
        loading: {
          getQuestionById: false,
        },
      };
    case GET_QUESTION_BY_ID.FAILURE:
      return {
        ...state,
        errors: {
          getQuestionById: true,
        },
        loading: {
          getQuestionById: false,
        },
      };

    default:
      return state;
  }
}
