import * as React from "react";
import * as R from "ramda";
import Avatar from "@material-ui/core/Avatar";
import ListBase from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import styled from "styled-components";

interface primaryTextItem {
  primary: string | HTMLDivElement | Element | any;
  secondary?: string | HTMLDivElement | Element | any; // What is the actual type though? -- GK
}

interface secondaryTextItem {
  primary?: string;
  secondary?: string;
}

export interface ListItemType {
  actionItem?: any;
  avatar?: any;
  avatarColor?: string[];
  primaryTextItem: primaryTextItem;
  secondaryTextItem?: secondaryTextItem;
  onClick?: () => void;
}

export interface ListProps {
  bottomBorder?: boolean;
  listItems?: ListItemType[];
  avatarColor?: string;
}

const ListWrapper = styled.div`
  width: 100%;
`;

const StyledListItem = styled(({ bottomBorder, ...rest }) => (
  <ListItem
    {...rest}
    classes={{
      root: "root",
    }}
  />
))``;

const StyledListBorderWrapper = styled(({ bottomBorder, ...rest }) => <div {...rest} classes={{ root: "root" }} />)`
  && .root {
    &:hover {
      cursor: pointer;
      // background-color: red;
    }
  }

  margin-right: -15px;
  padding-right: 5px;
  border-bottom: ${(props) => {
    return props.bottomBorder ? `1px solid ${props.theme.masterColors.lightGrey}` : "";
  }};
`;

const StyledAvatar = styled(({ color, ...rest }) => <Avatar {...rest} classes={{ root: "root" }}></Avatar>)`
  &.root {
    background-color: ${(props) =>
    props.color && props.color.length > 0
      ? R.pathOr("red", props.color, props.theme)
      : props.theme.masterColors.lightGrey};
    border-radius: 4px;
    font-size: 2rem;
  }
`;

const List = (props: ListProps) => (
  <>
    <ListWrapper>
      <ListBase>
        {props.listItems &&
          props.listItems.map((e: ListItemType, index: number, arr: any[]) => (
            <StyledListBorderWrapper bottomBorder={props.bottomBorder && index !== arr.length - 1} key={index}>
              <StyledListItem button onClick={e.onClick ? e.onClick : null} disableGutters>
                {e.avatar && (
                  <ListItemAvatar>
                    <StyledAvatar color={e.avatarColor || []}>{e.avatar}</StyledAvatar>
                  </ListItemAvatar>
                )}
                {e.primaryTextItem && (
                  <ListItemText
                    primary={e.primaryTextItem.primary}
                    secondary={e.primaryTextItem.secondary}
                    className="pr-1"
                  />
                )}

                {/*<-- Will probably remove this secondaryTextItem below */}
                {e.secondaryTextItem && (
                  <ListItemText
                    primary={e.secondaryTextItem.primary}
                    secondary={e.secondaryTextItem.secondary}
                    className="align-self-end justify-content-end text-right"
                  />
                )}
                {e.actionItem && <ListItemSecondaryAction>{e.actionItem}</ListItemSecondaryAction>}
              </StyledListItem>
            </StyledListBorderWrapper>
          ))}
      </ListBase>
    </ListWrapper>
  </>
);

export default List;
