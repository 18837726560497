import * as React from "react";
import MediaQuery from "react-responsive";
import { connect } from "react-redux";
import { css } from "aphrodite/no-important";

// import PowerFieldsLogo from "../svg/PowerFieldsLogo";
import * as S from "./styles";
import * as f from "./fields";
import Form from "../forms/Form";
import Loader from "components/common/Loader";
import { AppState } from "../../store";
import { PasswordResetValues } from "./types";
import { ResetContainer, Title, SpanWithMargin } from "./styles";
import { SystemState } from "../../store/system/types";
import { devices } from "themes/mediaQueries";
import { validatePasswordResetKey, resetPassword, clearError, clearStatus } from "../../store/system/actions";
import { Link, TextLink } from "components/common/Link/futureUiKit";

interface PasswordResetProps {
  clearError: () => void;
  clearStatus: () => void;
  history: any;
  location: any;
  resetPassword: (key: string | null, email: string) => void;
  system: SystemState;
  validatePasswordResetKey: any;
}

class PasswordReset extends React.Component<PasswordResetProps> {
  state = {
    errorValidatingKey: false,
    isValidatingKey: true,
    showConfirmPassword: false,
    showPassword: false,
    showTempSuccessButton: false,
    tooltipIsShowing: false,
    username: null,
  };

  componentDidMount(): void {
    if (this.props.system.error !== null) {
      this.props.clearError();
    }
    if (this.props.system.resetStatus !== null) {
      this.props.clearStatus();
    }

    // Validate key
    const search = new URLSearchParams(this.props.location.search);
    const key = search.get("key");
    if (key) {
      this.props.validatePasswordResetKey(key).then((e) => {
        // If key expired
        if (e.status === 404) {
          this.setState({
            errorValidatingKey: true,
            isValidatingKey: false,
          });
        }
        // If key is good
        else if (e.response && e.response.id) {
          this.setState({
            errorValidatingKey: false,
            isValidatingKey: false,
            username: e.response.email,
          });
        }
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    // Show temp success button state briefly
    if (this.props.system.resetStatus === "request" && nextProps.system.resetStatus === "success") {
      this.setState({
        showTempSuccessButton: true,
      });
      setTimeout(() => {
        this.setState({ showTempSuccessButton: false });
      }, 1500);
    }
  }

  // Used to track focused status of tooltip password field outside
  setTooltipShowing = (value) => {
    this.setState({
      tooltipIsShowing: value,
    });
  };

  handleSubmit = (values: PasswordResetValues): void => {
    const search = new URLSearchParams(this.props.location.search);
    const key = search.get("key");
    if (key !== null) {
      return this.props.resetPassword(key, values.newPassword);
    }
  };

  // Show / Hide passwords
  toggleShowPassword = () =>
    this.setState({
      showPassword: !this.state.showPassword,
    });
  toggleShowConfirmPassword = () =>
    this.setState({
      showConfirmPassword: !this.state.showConfirmPassword,
    });

  /**
   * Navigate to a specific endpoint
   * @param endpoint
   */
  navigate = (endpoint: string): void => {
    this.props.history.push(endpoint);
  };

  render() {
    const { history, system } = this.props;
    const logo2x2Fallback = "/assets/logo/Dominion-EnergyLogo@2x.png";
    return (
      <ResetContainer>
        <MediaQuery minWidth={devices.minTablet}>
          <Link className={css(S.styles.logo)} to="/login">
            <img src={logo2x2Fallback} height="68" alt="Dominion Energy" />
          </Link>
        </MediaQuery>
        <MediaQuery maxWidth={devices.maxPhone}>
          <S.MobileHeader>
            <S.MobileHeaderChevron onClick={() => history.push("/login")} />
            <span>Password Reset</span>
          </S.MobileHeader>
        </MediaQuery>
        <Loader
          loading={this.state.isValidatingKey}
          error={
            this.state.errorValidatingKey && (
              <S.ValidationError>
                Your key has expired. Please restart the reset process and try again.
              </S.ValidationError>
            )
          }
        >
          <Title>Reset Your Password</Title>
          <SpanWithMargin>
            Enter a new password for username: <b>{this.state.username}</b>
          </SpanWithMargin>
          <Form
            fields={f.resetFields({
              // Buttons
              buttonSuccessState: this.state.showTempSuccessButton ? (
                <S.ButtonSuccessTempState>
                  <S.ButtonSuccessTempIconContainer>
                    <S.ButtonSuccessTempIcon />
                  </S.ButtonSuccessTempIconContainer>
                </S.ButtonSuccessTempState>
              ) : (
                <S.ButtonSuccessState>
                  <S.ButtonSuccessStateIcon />
                  New Password Saved
                </S.ButtonSuccessState>
              ),
              buttonFailureState: (
                <>
                  <S.ButtonFailureState>
                    <S.ButtonFailureStateIcon />
                    Unable to Save Password
                  </S.ButtonFailureState>
                  <S.TryAgainSubmitButton onClick={() => {
                    this.props.clearError();
                    this.props.clearStatus();
                  }}
                  >try again</S.TryAgainSubmitButton>
                </>
              ),
              // Tooltip focused
              setTooltipShowing: this.setTooltipShowing,
              tooltipIsShowing: this.state.tooltipIsShowing,
              // Other
              showConfirmPassword: this.state.showConfirmPassword,
              showPassword: this.state.showPassword,
              toggleShowConfirmPassword: this.toggleShowConfirmPassword,
              toggleShowPassword: this.toggleShowPassword,
            })}
            accountLocked={false}
            error={system.error}
            externalLoading={system.resetStatus === "request"}
            handleSubmit={this.handleSubmit}
            navigate={this.navigate}
            recaptchaNeeded={false}
            status={system.resetStatus}
            validationSchema={f.resetSchema}
          />
          {system.resetStatus !== "failure" && (
            <S.AdditionalButtonWrapper>
              <TextLink className={css(S.styles.additionalLink)} to="/login">
                Return to login
              </TextLink>
            </S.AdditionalButtonWrapper>
          )}
        </Loader>
      </ResetContainer>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  system: state.system,
});

export default connect(mapStateToProps, { resetPassword, clearError, clearStatus, validatePasswordResetKey })(
  PasswordReset,
);
