import moment, { unitOfTime } from "moment";
import qs from "qs";

import { FormDTO, QuestionAnswerSourceDTO } from "store/forms/types";
import { handleNoun } from "util/hooks/whiteLabel/useGroupTerm";

const dontFetchThese = ["LOCATION", "PARTICIPANT"];

/**
 * Get all unique data sources used to render items in a form
 * @param form
 */
export const getUniqueAnswerSources = (form: FormDTO): Array<QuestionAnswerSourceDTO> => {
  const { sections } = form;
  if (!sections || sections.length === 0) {
    return [];
  }
  
  return sections.reduce((answerSources: Array<QuestionAnswerSourceDTO>, section) => {
    if (section.items) {
      section.items.forEach((item) => {
        if (
          "answerSource" in item &&
          item.answerSource?.dataSourceKey &&
          // these questions will fetch large data sets but we have no good reason to do so on page load
          // since we will fetch again to offer autocomplete suggestions or when the drawer is opened
          !dontFetchThese.includes(item.subType) &&
          !answerSources.find((as) => as.dataSourceKey === item.answerSource?.dataSourceKey)
        ) {
          answerSources.push(item.answerSource);
        }
      });
    }
    return answerSources;
  }, []);
};

interface ShouldRenderLocked {
  queryString?: string | null;
  readOnly?: boolean;
}

/**
 * Checks the given values to determine
 * if we'll render the Document in a locked state
 */
export const shouldRenderLocked = ({ queryString, readOnly }: ShouldRenderLocked) => {
  if (readOnly) return true;
  if (!queryString) return false;
  const obj = qs.parse(queryString, { ignoreQueryPrefix: true }); 

  return obj.locked === "true";
};

export const formatSubmitDeadline = (deadline: string | undefined): string | null => {
  if (!deadline) return null;
  const possibleIncrements: Array<unitOfTime.DurationConstructor> = [
    "days",
    "hours",
    "minutes",
  ];
  let deadlineString: string | null = null;
  for (let i = 0; i < possibleIncrements.length; i++) {
    // calculates the diff between current moment and deadline in current increment
    // always rounds down, truncates any decimals (https://momentjs.com/docs/#/displaying/difference/)
    const dlDiff = moment(deadline).diff(moment.now(), possibleIncrements[i]);
    // negative result means we have passed the deadline
    if (dlDiff < 0) {
      deadlineString = " - Read Only";
      break;
    } else if (dlDiff >= 1) {
      deadlineString = ` - ${dlDiff} ${handleNoun(
        possibleIncrements[i],
        dlDiff > 1 ? "plural" : "singular"
      )} left`;
      break;
    }
    // continue to next possible increment if value is 0
  }
  return deadlineString;
};
