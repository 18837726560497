import React, { ReactElement, RefObject, useMemo } from "react";
import { FormikErrors, FormikTouched } from "formik";
import { get, sortBy } from "lodash";
import { ThunkDispatch } from "redux-thunk";
import { AppState } from "store";
import { Action } from "redux";

import { CheckboxGroup } from "components/forms/CheckboxInput";
import { DocumentQuestionResponseVm } from "store/documents/types";
import { ParticipantField } from "components/forms/MultiInput";
import { SubmissionType } from "store/newDocument/actions";
import { WorkLocationParticipantVM } from "store/participants/types";
import DateInput from "components/forms/DateInput";
import Location from "components/forms/Location";
import Rating from "components/forms/Rating";
import SelectInput from "components/forms/SelectInput";
import SingleLineTextInput from "components/forms/SingleLineTextInput";
import TextArea from "components/forms/TextArea";

import { MultiResponseQuestionProps } from "../../forms/types";

import { FormErrorShape } from "../FormController";
import { FormValues } from "../types";
import * as S from "../styles";
import TouchedQuestionContext from "../context/touchedContext";
import {
  FormDTO,
  SafetyRatingWidgetDTO,
  QuestionAnswerSourceDTO,
  QuestionSelectionsDTO,
} from "store/forms/types";
import { getFlattenedItems } from "../helpers";
import { DataSourceValueDTO } from "store/dataSource/types";
import { useSelector, useDispatch } from "react-redux";
import {
  getDataSources,
  getIsLastDSPage,
  getLastDSPageLoaded,
} from "store/dataSource/selectors";
import {
  clearDataSourceValues,
  getDataSourceValues,
} from "store/dataSource/actions";
import MoreOptionsLoader from "components/forms/MoreOptionsLoader/MoreOptionsLoader";
import { RadioInput } from "components/forms/RadioInput/futureUiKit";
import { uniqBy } from "ramda";

export type FieldErrorType =
  | string
  | FormikErrors<unknown>
  | string[]
  | FormikErrors<unknown>[]
  | undefined;

export interface QuestionProps extends MultiResponseQuestionProps {
  errors: FormErrorShape;
  form: FormDTO;
  participants?: Array<WorkLocationParticipantVM>;
  questionRef?: RefObject<HTMLDivElement>;
  sectionTitle?: string;
  setTouched: (touched: FormikTouched<FormValues>) => void;
  submitCount?: number;
  touched: FormikTouched<FormValues>;
  submissionType?: SubmissionType;
  isRatingsParentQuestion: boolean;
  removeParticipant: (id: number) => void;
}

/**
 * Format a question's data source values into selections
 * @param dataSourceValues  - values of a data source matching a specific key
 * @param answerSource      - contains metadata and properties for a data source
 */
function buildAnswerSourceSelections(
  dataSourceValues: DataSourceValueDTO[],
  answerSource: QuestionAnswerSourceDTO
) {
  const sortParam = answerSource.properties?.detailedSearch?.infiniteListSortBy;
  let dsVals = [...dataSourceValues];
  if (sortParam) {
    dsVals = sortBy(dsVals, `content.${sortParam}`);
  }

  return dsVals.map((dsv) => {
    const title = get(
      dsv.content,
      answerSource.properties.answerField,
      "name not found"
    );
    const value = get(
      dsv.content,
      answerSource.properties.displayField || "",
      title
    );
    const subtitle = dsv.content.clientGroups?.find(
      (group) => group.id === dsv.content.primaryGroupId
    )?.name;

    return {
      id: dsv.id,
      rootId: dsv.id,
      title,
      value,
      subtitle,
      defenseIds: [],
      tags: [],
    };
  });
}

export default function Question({
  errors,
  form,
  question,
  questionRef,
  responses,
  setQuestionResponses,
  submissionType,
  submitCount,
  isRatingsParentQuestion,
  removeParticipant,
}: QuestionProps): ReactElement | null {
  const { touchedQuestions, updateTouchedQuestions } = React.useContext(
    TouchedQuestionContext
  );
  const [dataSourceSuggestions, setDataSourceSuggestions] = React.useState<
    QuestionSelectionsDTO[]
  >([]);
  const [
    loadingMoreDataSuggestions,
    setLoadingMoreDataSuggestions,
  ] = React.useState<boolean>(false);
  const [lastDSQuery, setLastDSQuery] = React.useState<string>("");

  const dispatch = useDispatch<ThunkDispatch<AppState, void, Action>>();

  const dataSources = useSelector(getDataSources);
  const isLastDSPage = useSelector(getIsLastDSPage);
  const lastDSPageLoaded = useSelector(getLastDSPageLoaded);

  const dataSourceValues: DataSourceValueDTO[] = useMemo(() => {
    if (dataSources && question.answerSource?.dataSourceKey) {
      return dataSources[question.answerSource.dataSourceKey] || [];
    } else return [];
  }, [dataSources, question.answerSource?.dataSourceKey]);

  const widgets = getFlattenedItems(form.sections, "WIDGET");
  const parentWidget = question.parentWidgetRootId
    ? widgets.find((widget) => widget.rootId === question.parentWidgetRootId)
    : undefined;

  // shorthand for single response questions
  const setQuestionResponse = (response?: DocumentQuestionResponseVm) => {
    setQuestionResponses(response ? [response] : []);
  };
  const response =
    (responses && responses.length > 0 && responses[0]) || undefined;

  /*
   * rebuild the suggestions after loading more options
   * we do this here instead of simply adding the returned values to dataSourceSuggestions, because
   * the data source is shared between questions and we don't want to skip displaying values in other questions
   *
   * we don't update all questions at once, because we don't want the page to jump
   */

  React.useEffect(() => {
    async function buildDataSourceSuggestions() {
      if (question.answerSource) {
        const selections = buildAnswerSourceSelections(
          dataSourceValues,
          question.answerSource
        );
        setDataSourceSuggestions((prevState: QuestionSelectionsDTO[]) => {
          // Related to ST-466, we should now fetch updated DS values. If
          // the answer is an old value that is no longer exist, retain that
          // value and display it until new value is selected and saved.
          if (
            response &&
            !selections.find(
              (item) =>
                (item.id === response.associatedId &&
                  item.rootId === response.associatedRootId) ||
                item.title === response.answer
            ) &&
            !prevState.find(
              (item) =>
                (item.id === response.associatedId &&
                  item.rootId === response.associatedRootId) ||
                item.title === response.answer
            )
          ) {
            selections.push({
              id: response.associatedId || response.questionId,
              rootId: response.associatedRootId || response.questionRootId,
              title: response.answer,
              value: response.answer,
              subtitle: undefined,
              defenseIds: [],
              tags: [],
            });
          }
          return uniqBy((s) => s.id, [...prevState, ...selections]);
        });
      }
    }

    !loadingMoreDataSuggestions && buildDataSourceSuggestions();
  }, [loadingMoreDataSuggestions, dataSourceValues, question.answerSource]);

  /**
   * **fieldError** is this question's error from Formik's error prop
   */
  const fieldError: FieldErrorType =
    submitCount && submitCount > 0
      ? errors[question.id]
      : touchedQuestions.has(question.id)
      ? errors[question.id]
      : undefined;

  /**
   * some fields don't invoke `setQuestionResponse` onBlur
   * thus we'll use this method to ensure questions
   * are being set to the correct status for validation
   */
  const handleBlur = () => {
    if (!touchedQuestions.has(question.id)) {
      updateTouchedQuestions(question.id);
    }
  };

  /** Fetch the next page of data source values and map the results to suggestions shape */
  async function onLoadMoreSuggestions(query?: string) {
    query = query || "";
    const dataSourceKey = question.answerSource?.dataSourceKey;
    if (dataSourceKey) {
      let page = 0;
      if (query === lastDSQuery) {
        page = (lastDSPageLoaded?.[dataSourceKey] || 0) + 1;
      } else {
        await dispatch(clearDataSourceValues({ keyName: dataSourceKey }));
      }
      const dataSource = dataSources[dataSourceKey];
      if (dataSource) {
        setLoadingMoreDataSuggestions(true);
        await dispatch(
          getDataSourceValues({
            keyName: dataSourceKey,
            page,
            sortBy: get(
              question,
              "answerSource.properties.detailedSearch.infiniteListSortBy"
            ),
            size: 100,
            query,
          })
        );
        setLoadingMoreDataSuggestions(false);
      }
    }
    setLastDSQuery(query);
  }

  /** Question selections derived from data source (if existing) or question selections property */
  const questionSelections = dataSourceSuggestions?.length
    ? dataSourceSuggestions
    : question.selections;

  /** Data has reached the final page of a data source */
  const isFinalDSPage = question.answerSource?.dataSourceKey
    ? isLastDSPage?.[question.answerSource.dataSourceKey]
    : false;

  switch (question.subType) {
    case "DATE":
      return (
        <S.Question ref={questionRef}>
          <DateInput
            error={fieldError}
            handleBlur={handleBlur}
            label={question.title}
            name={question.id.toString()}
            question={question}
            response={response}
            type="date"
            setQuestionResponse={setQuestionResponse}
          />
        </S.Question>
      );

    // commentted out intentionally due to non-existance. DE scrapped this request
    // case "DATE_TIME":
    //   return (
    //     <S.Question ref={questionRef}>
    //       <DateInput
    //         label={question.title}
    //         name={question.id.toString()}
    //         handleBlur={handleBlur}
    //         error={fieldError}
    //         question={question}
    //         response={response}
    //         type="datetime-local"
    //         setQuestionResponse={setQuestionResponse}
    //       />
    //     </S.Question>
    //   );

    case "DROP_DOWN":
      return (
        <S.Question ref={questionRef}>
          <SelectInput
            error={fieldError}
            handleBlur={handleBlur}
            isFinalPage={isFinalDSPage}
            isLoadingMore={loadingMoreDataSuggestions}
            label={question.title}
            name={question.id.toString()}
            onLoadMore={question.answerSource && onLoadMoreSuggestions}
            question={question}
            response={response}
            selections={questionSelections || []}
            setQuestionResponse={setQuestionResponse}
          />
        </S.Question>
      );

    case "LOCATION":
      return (
        <S.Question ref={questionRef}>
          <Location
            error={fieldError}
            isFinalPage={isFinalDSPage}
            isLoadingMore={loadingMoreDataSuggestions}
            name={question.id.toString()}
            handleBlur={handleBlur}
            onLoadMore={onLoadMoreSuggestions}
            placeholder={question.properties?.placeHolderText || ""}
            question={question}
            response={response}
            setQuestionResponse={setQuestionResponse}
          />
        </S.Question>
      );

    case "CHECKBOX":
    case "MULTI_SELECT":
      return (
        <S.Question ref={questionRef}>
          <CheckboxGroup
            error={fieldError}
            isRatingsParentQuestion={isRatingsParentQuestion}
            handleBlur={handleBlur}
            question={question}
            responses={responses}
            selections={questionSelections || []}
            setQuestionResponses={setQuestionResponses}
            variant={question.subType}
          >
            {question.answerSource && (
              <MoreOptionsLoader
                onLoadMore={onLoadMoreSuggestions}
                isLoadingMore={loadingMoreDataSuggestions}
                isFinalPage={isFinalDSPage}
              />
            )}
          </CheckboxGroup>
        </S.Question>
      );

    case "PARTICIPANT":
      return (
        <S.Question ref={questionRef}>
          <ParticipantField
            error={fieldError}
            label={question.title}
            question={question}
            responses={responses}
            setQuestionResponses={setQuestionResponses}
            onLoadMore={onLoadMoreSuggestions}
            isLoadingMore={loadingMoreDataSuggestions}
            isFinalPage={isFinalDSPage}
            removeParticipant={removeParticipant}
          />
        </S.Question>
      );

    case "RADIO_BUTTONS":
      if (!questionSelections) {
        return null;
      }
      return (
        <S.Question ref={questionRef}>
          <RadioInput
            error={fieldError}
            options={
              questionSelections?.map(({ id, rootId, title }) => ({
                id: id,
                rootId: rootId,
                value: title,
                label: title,
              })) || []
            }
            handleBlur={handleBlur}
            label={question.title}
            name={question.id.toString()}
            question={question}
            response={response}
            setQuestionResponse={setQuestionResponse}
          >
            {question.answerSource && (
              <MoreOptionsLoader
                onLoadMore={onLoadMoreSuggestions}
                isLoadingMore={loadingMoreDataSuggestions}
                isFinalPage={isFinalDSPage}
              />
            )}
          </RadioInput>
        </S.Question>
      );

    case "RATING":
      return (
        <S.Question ref={questionRef}>
          <Rating
            parentWidget={parentWidget as SafetyRatingWidgetDTO}
            error={fieldError}
            question={question}
            response={response}
            setQuestionResponse={setQuestionResponse}
          />
        </S.Question>
      );

    case "TEXT_AREA":
      return (
        <S.Question ref={questionRef}>
          <TextArea
            id={question.id}
            error={fieldError}
            handleBlur={handleBlur}
            initialRowsVisible={4}
            label={question.title}
            maxRowsVisible={4}
            name={question.id}
            placeholder={question.properties?.placeHolderText || ""}
            question={question}
            response={response}
            setQuestionResponse={setQuestionResponse}
          />
        </S.Question>
      );

    case "TEXT_LINE":
      return (
        // @TODO need to handle answerSource suggestions
        <S.Question ref={questionRef}>
          <SingleLineTextInput
            error={fieldError}
            onBlur={handleBlur}
            internalVariant="base"
            label={question.title}
            // maxCharacters={50} //@NOTE: NEED API DATA
            name={question.id.toString()}
            placeholder={question.properties?.placeHolderText || ""}
            question={question}
            response={response}
            setQuestionResponse={setQuestionResponse}
            type="text"
          />
        </S.Question>
      );

    case "TIME":
      return (
        <S.Question ref={questionRef}>
          <DateInput
            label={question.title}
            name={question.id.toString()}
            handleBlur={handleBlur}
            error={fieldError}
            question={question}
            response={response}
            type="time"
            setQuestionResponse={setQuestionResponse}
          />
        </S.Question>
      );

    case "YES_OR_NO":
      return (
        <S.Question ref={questionRef}>
          <RadioInput
            error={fieldError}
            options={[
              { value: "true", label: "Yes" },
              { value: "false", label: "No" },
            ]}
            label={question.title}
            name={question.id.toString()}
            question={question}
            response={response}
            setQuestionResponse={setQuestionResponse}
            handleBlur={handleBlur}
          />
        </S.Question>
      );

    // currently unhandled and default cases
    case "HYPERLINK":
    case "BARCODE":
    case "DOCUMENT":
    case "PHOTO":
    case "DRAWING":
    case "MAP_DRAW":
    default:
      return null;
  }
}
