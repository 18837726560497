import React, { useMemo } from "react";
import { get } from "lodash";

import { DocumentVM, DocumentSummary } from "store/documents/types";
import { prettifyDocumentStatus } from "components/helpers";

import * as S from "./styles";
import DocumentHistoryTable from "./helpers/DocumentHistoryTable";
import DocumentInfo from "./DocumentInfo";
import RelatedDocumentsTable from "./helpers/RelatedDocumentsTable";
import Title from "./Title";
import useGroupTerm from "util/hooks/whiteLabel/useGroupTerm";
import { RelatedDocuments } from "./index";
import { formatSubmitDeadline } from "components/document/helpers";

const getSubTitleValues = ({
  document,
}: {
  document: DocumentVM | DocumentSummary;
}): {
  title: string;
  subTitle1: string;
  subTitle2: string;
  deadline: string | undefined;
} => {
  const workOrderId = get(document, "workOrder.workOrderId");
  const workOrderIdSubtitle = workOrderId ? `ID: ${workOrderId}` : "ID: -";

  return {
    title: get(document, "formSummary.type.name", ""),
    // Changed subTitle1 per POW-1832 -- GK
    subTitle1: get(document, "title", ""),
    subTitle2: workOrderIdSubtitle,
    deadline: get(document, "deadline", undefined),
  };
};

export type DocumentHistoryType = "history" | "related";

interface AppProps {
  document: DocumentVM | DocumentSummary;
  type?: DocumentHistoryType;
  relatedDocuments?: RelatedDocuments;
}

const DocumentHistory = ({
  document,
  type = "history",
  relatedDocuments,
}: AppProps) => {
  const { title, subTitle1, subTitle2, deadline } = useMemo(
    () => getSubTitleValues({ document }),
    [document]
  );

  const { isRehuddle } = document;

  // Group config terms
  const documentTerm = useGroupTerm("document", "noun", undefined, "Document");
  const documentsTerm = useGroupTerm("document", "noun", "plural", "Documents");
  const documentsTermLower = documentsTerm.toLowerCase();
  const rehuddleTermUpper = useGroupTerm(
    "refocus",
    "noun",
    undefined,
    "Rehuddle"
  ).toUpperCase();

  // This is content for a little heading section above Document Info
  const historyTitles = useMemo(() => {
    // If View Related Documents content
    if (type === "related") {
      return {
        title: `Related ${documentsTerm}`,
        subTitle: `The following ${documentsTermLower} are related to:`,
      };
    }
    // If Document History content
    return {
      title: `${documentTerm} History`,
      subTitle: "See the different actions your team members completed.",
    };
  }, [type]);

  // Prefix with REHUDDLE
  const documentSubtitle1 = `${
    isRehuddle ? `[${rehuddleTermUpper}] ${subTitle1}` : subTitle1
  }`;

  const deadlineString = formatSubmitDeadline(deadline);

  return (
    <S.DocumentHistory>
      <Title title={historyTitles.title} subTitle={historyTitles.subTitle} />
      <DocumentInfo
        title={title}
        subTitle1={documentSubtitle1}
        subTitle2={subTitle2}
        submissionType={prettifyDocumentStatus(document.status)}
        submitDeadline={document.status !== "SUBMITTED" ? deadlineString : null}
      />
      {relatedDocuments && type !== "history" ? (
        <RelatedDocumentsTable
          document={document}
          relatedDocuments={relatedDocuments}
        />
      ) : (
        <DocumentHistoryTable document={document} type={type} />
      )}
    </S.DocumentHistory>
  );
};

export default DocumentHistory;
