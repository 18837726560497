import React, { useEffect, useState } from "react";
import { Action } from "redux";
import { omit } from "lodash";
import { RouteComponentProps, withRouter } from "react-router";
import { ThunkDispatch } from "redux-thunk";
import { useDispatch, useSelector } from "react-redux";

import { AppState } from "store";
import { ContentWrapper } from "components/common/Wrappers";
import {
  GET_RESOURCE_AUTHORS,
  GET_RESOURCES,
  GetResourceAuthorsAction,
  GetResourcesAction,
  Resource,
} from "store/resources/types";
import { getResources, getResourceAuthors } from "store/resources/actions";
import {
  FilterParams,
  Pagination as PaginationType,
  PaginationParams,
} from "store/common/types";
import { removeFiltersByValue } from "store/common/apiUtilities";
import Breadcrumbs from "components/common/Breadcrumbs";

import { PageHeader, PageTitle, PageActionButton } from "../styles";
import * as S from "./styles";
import ResourcesFilters from "./ResourcesFilters";
import ResourcesTable from "./ResourcesTable";
import ResourceTablePagination from "./ResourceTablePagination";
import usePrevious from "util/hooks/usePrevious";
import ReadOnlyContent from "components/common/permissions/ReadOnlyContent";
import { getMinMaxSubmissionDatesFromTimeFilter } from "components/helpers/filters";
import { CategoryDTO } from "store/categories/types";
import { getUnarchivedResourceCategories } from "store/resourceCategories/actions";
import { GET_RESOURCE_CATEGORIES } from "store/resourceCategories/types";
import { GET_TAG_LIBRARIES, TagDTO, TagVm } from "store/tagLibrary/types";
import { getTags } from "store/tagLibrary/actions";
import { resourcesFiltersSelector } from "store/filters/resources/selectors";
import { ResourceStatus } from "@rtslabs/field1st-fe-common";
import { setResourcesPagination } from "store/filters/resources/actions";

interface FilterAndSortParams extends PaginationParams {
  resourceType?: "OPERATIONAL_EXPERIENCE" | "DEFENSE" | -1;
  createdByEmailAddress?: string | -1;
  beforeDate?: string | number;
}

type MaintainResourcesProps = RouteComponentProps;

const MaintainResources = ({ history }: MaintainResourcesProps) => {
  const [authors, setAuthors] = useState<string[]>([]);
  const [categories, setCategories] = useState<CategoryDTO[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [paginationValues, setPaginationValues] = useState<PaginationType>({});
  const [resources, setResources] = useState<Resource[]>([]);
  const [tags, setTags] = useState<TagDTO[]>([]);

  const filtersSelect = useSelector(resourcesFiltersSelector);
  const [filters, setFilters] = useState<FilterParams>(
    filtersSelect.filterParams
  );
  const dispatch = useDispatch<ThunkDispatch<AppState, void, Action>>();

  const fetchResources = async () => {
    // clear the -1 value filters from the filters object
    const filteredFilters = filters
      ? removeFiltersByValue(filters, -1)
      : undefined;
    setIsLoading(true);
    const res: GetResourcesAction = await dispatch(
      getResources(filtersSelect.paginationParams, filteredFilters)
    );
    if (res?.type === GET_RESOURCES.SUCCESS) {
      setResources(res.response.content);
      setPaginationValues(omit(res.response, ["content"]));
    }
    setIsLoading(false);
  };

  const fetchAuthors = async () => {
    const authorsRes: GetResourceAuthorsAction = await dispatch(
      getResourceAuthors({ size: 200 }) // @TODO hardcoded for now, but we need a way to fetch ALL authors
    );
    if (authorsRes.type === GET_RESOURCE_AUTHORS.SUCCESS) {
      setAuthors(authorsRes.response.content);
    }
  };

  const fetchResourceCategories = async () => {
    const res = await dispatch(
      getUnarchivedResourceCategories({ sort: "title,asc" })
    );
    if (res.type === GET_RESOURCE_CATEGORIES.SUCCESS) {
      setCategories(res.response);
    }
  };

  const fetchTags = async () => {
    const res = await dispatch(
      getTags({ size: 200, sort: "name,asc" }, { archived: false })
    );
    if (res.type === GET_TAG_LIBRARIES.SUCCESS) {
      setTags(res.response.content);
    }
  };

  const onToggleResourceStatus = (
    resourceId: number,
    status: ResourceStatus
  ) => {
    if (status === "DELETED") {
      // when the user confirm delete, refresh the page to be taken off the list and update the total count
      return fetchResources();
    }

    const updatedResources = resources.map((resource) => {
      if (resource.id === resourceId) {
        return { ...resource, status };
      }
      return resource;
    });
    setResources(updatedResources);
  };

  const handleUpdateCategories = (id: number, newCategories: CategoryDTO[]) => {
    const updatedResources = resources.map((resource) => {
      if (resource.id === id) {
        return { ...resource, categories: newCategories };
      } else return resource;
    });
    setResources(updatedResources);
  };

  const handleUpdateTags = (id: number, newTags: TagVm[]) => {
    const updatedResources = resources.map((resource) => {
      if (resource.id === id) {
        return { ...resource, tags: newTags };
      } else return resource;
    });
    setResources(updatedResources);
  };

  const handleUpdateFilters = (filters) => {
    // renamed filters will be removed from the filters spread
    const renamedFilters = ["timeFilterType", "dateRange"];
    const passableFilters = omit(filters, renamedFilters);

    const { minSubmissionDate, maxSubmissionDate } =
      getMinMaxSubmissionDatesFromTimeFilter(
        filters.timeFilterType,
        filters.dateRange
      );

    const mappedFilters = {
      ...passableFilters,
      beforeDate: maxSubmissionDate,
      afterDate: minSubmissionDate,
    };
    setFilters(mappedFilters);
    // return to page 1 when we change the filters
    dispatch(
      setResourcesPagination({ ...filtersSelect.paginationParams, page: 0 })
    );
  };

  const handleSort = (sort: string) => {
    if (sort.indexOf("categories") === -1) {
      dispatch(
        setResourcesPagination({ ...filtersSelect.paginationParams, sort })
      );
    }
  };

  const handleUpdatePaginationParams = (param: PaginationParams) => {
    dispatch(
      setResourcesPagination({ ...filtersSelect.paginationParams, ...param })
    );
  };

  const handleAddResource = () => history.push("/content/resources/new");

  const prevParamsSize = usePrevious(filtersSelect.paginationParams.size);

  // get the resources on mount and when search/pagination params change
  useEffect(() => {
    // When we change the page size, reset page to 0
    if (prevParamsSize !== filtersSelect.paginationParams.size) {
      dispatch(
        setResourcesPagination({ ...filtersSelect.paginationParams, page: 0 })
      );
    } else {
      fetchResources();
    }
  }, [filtersSelect.paginationParams, filters]);

  // fetch the authors and categories on mount
  useEffect(() => {
    fetchAuthors();
    fetchResourceCategories();
    fetchTags();
    handleUpdateFilters(filtersSelect.filterParams);
  }, []);

  // @NOTE: ID IS USED FOR "SCROLL TO" / "BACK TO TOP" LOGIC
  // @TODO: Update with a better solution - Trevor
  return (
    <ContentWrapper id="mainContent">
      <Breadcrumbs
        paths={[
          {
            pathName: "Content",
          },
          {
            pathName: "Resources",
            href: "/content/resources",
          },
        ]}
      />
      <PageHeader>
        <PageTitle>Resources</PageTitle>
        <ReadOnlyContent>
          <PageActionButton onClick={handleAddResource}>
            Add Resource
          </PageActionButton>
        </ReadOnlyContent>
      </PageHeader>
      <ResourcesFilters
        authors={authors}
        categories={categories}
        onUpdateFilters={handleUpdateFilters}
      />
      <S.LoadingWrapper>
        <S.PageLoader loading={isLoading} />
        <ResourcesTable
          onSort={handleSort}
          params={filtersSelect.paginationParams}
          totalElements={paginationValues.totalElements || 0}
          resources={resources}
          categories={categories}
          onToggleResourceStatus={onToggleResourceStatus}
          handleUpdateCategories={handleUpdateCategories}
          handleUpdateTags={handleUpdateTags}
          tags={tags}
        />
      </S.LoadingWrapper>
      <ResourceTablePagination
        params={filtersSelect.paginationParams}
        paginationValues={paginationValues}
        onUpdateParams={handleUpdatePaginationParams}
      />
    </ContentWrapper>
  );
};

export default withRouter(MaintainResources);
