import { WorkLocationsState, GET_WORK_LOCATIONS, WorkLocationsActions } from "./types";

const initialState: WorkLocationsState = {
  loading: false,
  error: false,
  errorMessage: "",
  data: {
    content: [],
  },
};

export function workLocationsReducer(state = initialState, action: WorkLocationsActions): WorkLocationsState {
  switch (action.type) {
    case GET_WORK_LOCATIONS.FAILURE:
      return {
        ...state,
        error: true,
        loading: false,
      };
    case GET_WORK_LOCATIONS.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_WORK_LOCATIONS.SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.response,
      };
    case "RESET_WORK_LOCATIONS":
      return {
        ...state,
        loading: false,
        data: {
          content: [],
        },
      };
    default:
      return state;
  }
}
