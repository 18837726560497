import { CALL_API } from "middleware/api";

import { FIND_WORK_ORDERS_FOR_NEW_DOCUMENT } from "../newDocument/types";

import { SEARCH_WORK_ORDERS } from "./types";

// Search Work Orders
export const searchWorkOrders = (query: string, newDocument?: boolean) => (dispatch, getState) => {
  const authToken = getState().system.authToken;
  const endpoint = `work-orders?query=workOrderId:${query}`;

  const types = newDocument
    ? [
      FIND_WORK_ORDERS_FOR_NEW_DOCUMENT.REQUEST,
      FIND_WORK_ORDERS_FOR_NEW_DOCUMENT.SUCCESS,
      FIND_WORK_ORDERS_FOR_NEW_DOCUMENT.FAILURE,
    ]
    : [SEARCH_WORK_ORDERS.REQUEST, SEARCH_WORK_ORDERS.SUCCESS, SEARCH_WORK_ORDERS.FAILURE];

  return dispatch({
    [CALL_API]: {
      endpoint,
      options: {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${authToken}`,
        },
      },
      types,
    },
  });
};

// Get Work Order by id
export const getWorkOrderById = (workOrderId: number) => (dispatch, getState) => {
  const { authToken } = getState().system;

  return dispatch({
    [CALL_API]: {
      endpoint: `work-orders/${workOrderId}`,
      options: {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${authToken}`,
        },
      },
      types: ["GET_WORK_ORDER_BY_ID_REQUEST", "GET_WORK_ORDER_BY_ID_SUCCESS", "GET_WORK_ORDER_BY_ID_FAILURE"],
    },
  }).then((res) => res)
    .catch((err) => {
      throw new Error(err);
    });
};
