import LinearProgress from "@material-ui/core/LinearProgress";
import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";

import * as S from "./styles";
import { ThemeContext } from "styled-components";

type PasswordRequirementsProps = {
  newPassword: any;
  passwordRegex: {
    DIGIT: RegExp;
    FULL: RegExp;
    LOWERCASE: RegExp;
    SPECIAL: RegExp;
    UPPERCASE: RegExp;
  };
};

const useStyles = () => {
  const theme = useContext(ThemeContext);
  return makeStyles({
    root: {
      height: 8,
      marginBottom: 7,
      marginTop: 5,
      width: "90%",
    },
    barColorPrimary: {
      backgroundColor: theme.colors.success,
    },
  })();
};

// Validations are moved outside so checks are performed once only
const getValidations = (newPassword, passwordRegex) => {
  // This is what will be used to pick icons (checked/unchecked), styles, etc
  let result = {
    atLeastEight: false,
    oneDigit: false,
    oneSymbol: false,
    oneUpperOneLower: false,
  };

  // If params are not falsy
  if (newPassword && passwordRegex) {
    // Check for at least one upper and one lower case
    if (newPassword.search(passwordRegex.UPPERCASE) > -1 && newPassword.search(passwordRegex.LOWERCASE) > -1) {
      result = {
        ...result,
        oneUpperOneLower: true,
      };
    }
    // Check for at least 7 characters
    if (newPassword.length > 7) {
      result = {
        ...result,
        atLeastEight: true,
      };
    }

    // Check for at least 1 digit
    if (newPassword.search(passwordRegex.DIGIT) > -1) {
      result = {
        ...result,
        oneDigit: true,
      };
    }

    // Check for at least 1 special char
    if (newPassword.search(passwordRegex.SPECIAL) > -1) {
      result = {
        ...result,
        oneSymbol: true,
      };
    }
  }
  return result;
};

// This sets the progress bar value, increments are based on number of requirements and 100 is max
const getProgressValue = (validations) => {
  let result = 0;
  let numberOfCheckedValidations = 0;
  if (validations) {
    for (const i in validations) {
      if (validations[i]) {
        numberOfCheckedValidations += 1;
        result += 100 / Object.keys(validations).length;
      }
    }
  }
  // This is to round up weird numbers (3, 7, 11, ..., number of requirements)
  if (result !== 100 && numberOfCheckedValidations === Object.keys(validations).length) {
    result = 100;
  }
  return result;
};

// Pick checked/unchecked icon
const requirementIcon = (checked) => {
  return <i className={`${checked ? "icon-icons8-checkmark" : "icon-icons8-delete_sign"} mr-1`} />;
};

export const PasswordRequirements = ({ newPassword = "", passwordRegex }: PasswordRequirementsProps) => {
  const classes = useStyles();

  const validations = getValidations(newPassword, passwordRegex); // Validations object
  const progressValue = getProgressValue(validations); // Progress bar value

  return (
    <S.PasswordWrapper>
      {/* <S.TooltipArrow /> */}
      <S.PasswordTitle>Password Strength</S.PasswordTitle>
      <LinearProgress classes={classes} variant="determinate" value={progressValue} color="primary" />
      <S.PasswordRequirement fulfilled={validations.atLeastEight}>
        {requirementIcon(validations.atLeastEight)}
        Use 8 or more characters
      </S.PasswordRequirement>
      <S.PasswordRequirement fulfilled={validations.oneUpperOneLower}>
        {requirementIcon(validations.oneUpperOneLower)}
        Use upper and lowercase letters (e.g. Aa)
      </S.PasswordRequirement>
      <S.PasswordRequirement fulfilled={validations.oneDigit}>
        {requirementIcon(validations.oneDigit)}
        Use a number (e.g. 1234)
      </S.PasswordRequirement>
      <S.PasswordRequirement fulfilled={validations.oneSymbol}>
        {requirementIcon(validations.oneSymbol)}
        Use a symbol (e.g. !@#$)
      </S.PasswordRequirement>
    </S.PasswordWrapper>
  );
};
