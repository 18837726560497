
import React, { PropsWithChildren } from "react";
import { css } from "aphrodite/no-important";
import { useThemedStyleSheet } from "util/hooks/useThemedStyleSheet";
import { style } from "./styles";

interface InputDescriptionProps {
  /** This ID should be the same one that's given to the 'aria-describedby' attribute of the input element. */
  id: string;
}

/**
 * A description or instructions for an input or control. Also referred to as "assistive text".
 */
export const InputDescription = ({ children, id }: PropsWithChildren<InputDescriptionProps>) => {
  const styles = useThemedStyleSheet(style);
  return <div className={css(styles.inputDescription)} id={id}>{children}</div>;
};
