import React from "react";

import colors from "themes/colors";

import * as S from "../styles";

const {
  masterColors: { white },
} = colors;

interface Props {
  onClick?: () => void;
  children: React.ReactNode;
  included: boolean;
  loading: boolean;
}

const Ornament = ({ loading, included }: { loading: boolean; included: boolean }) => {
  if (loading) {
    const color = included && white;
    return <S.Loading loading spinnerProps={{ size: 14, color }} />;
  }

  if (included) {
    return <S.Icon variant="include" className="icon icon-icons8-checkmark" />;
  }

  return null;
};

const IncludeButton = ({ onClick, children, included, loading }: Props) => {
  return (
    <S.IncludeButton onClick={!loading && onClick} included={included} disabled={loading}>
      <Ornament loading={loading} included={included} />
      {children}
    </S.IncludeButton>
  );
};

export default IncludeButton;
