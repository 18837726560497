import React from "react";
import { ScaleControlProps, _useMapControl } from "react-map-gl";
import * as S from "./styles";

interface Props extends ScaleControlProps {
  color?: string;
  disabled: boolean;
  onMouseUp?: () => void;
}

// /** MarkerControl component */

export const MarkerControl = (props: Props) => {
  const { containerRef } = _useMapControl({
    onDragStart: (evt) => {
      evt.stopPropagation();
    },
    onClick: (evt) => {
      evt.stopPropagation();
    },
    onDoubleClick: (evt) => {
      evt.stopPropagation();
    },
  });
  const { color, disabled, onMouseUp } = props;
  return (
    <S.Circle onMouseUp={onMouseUp} ref={containerRef}>
      <S.PlaceMarker
        color={color}
        className="icon icon-icons8-place_marker"
        disabled={disabled}
      />
    </S.Circle>
  );
};
