import React, { CSSProperties } from "react";
import { StyleSheet } from "aphrodite";
import { ThemeContext } from "styled-components";
import { remCalc } from "themes/helpers";

export default function(): { [key: string]: CSSProperties } {
  const theme = React.useContext(ThemeContext);

  return StyleSheet.create({
    caretContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: remCalc(30),
      height: remCalc(30),
    },

    caret: {
      display: "inline-block"
    },

    collapsed: {
      transform: "rotate(180deg)",
    }
  });
};
