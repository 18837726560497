import React, { FC } from "react";
import moment from "moment";

import {
  DataItemLabel,
  DataItemText,
  DisableUserButtonsWrapper,
  DisableUserDataItem,
  ModalPromptCloseIcon,
  ModalPromptTitle,
  ModalPromptWrapper,
  ModalPromptSubtitle,
} from "components/clientAdmin/users/styles";
import Loader from "components/common/Loader";
import { CancelButton, SubmitButton } from "components/clientAdmin/styles";
import { LoaderWrapper } from "components/clientAdmin/addUser/styles";
import { CategoryDTO } from "store/categories/types";
import { CategoryStatusOutcomeModal } from "./CategoryStatusOutcomeModal";

export const actionPerformed = (
  archived: CategoryDTO["archived"],
  tense: "past" | "present"
) => {
  const activate = {
    present: "activate",
    past: "activated",
  };
  const archive = {
    present: "archive",
    past: "archived",
  };
  return archived ? activate[tense] : archive[tense];
};

export interface CategoryStatusModalProps {
  onCancel: () => void;
  onSubmit: () => void;
  status: "request" | "failure" | "success" | null;
  category: CategoryDTO | null;
}

export const CategoryStatusModal: FC<CategoryStatusModalProps> = ({
  onCancel,
  onSubmit,
  status,
  category,
}) => {
  const successOrFailure = !!status && ["success", "failure"].includes(status);

  if (successOrFailure) {
    return (
      <CategoryStatusOutcomeModal
        archived={category?.archived}
        onClose={onCancel}
        status={status}
      />
    );
  }

  return (
    <>
      <ModalPromptWrapper>
        <ModalPromptTitle>Are you sure?</ModalPromptTitle>
        <ModalPromptSubtitle>
          You are about to {actionPerformed(category?.archived, "present")} this
          tag:
        </ModalPromptSubtitle>
        <div className="d-flex flex-row">
          <DisableUserDataItem>
            <DataItemLabel>Title</DataItemLabel>
            <DataItemText>{category?.title || "-"}</DataItemText>
          </DisableUserDataItem>

          <DisableUserDataItem>
            <DataItemLabel>Date Added</DataItemLabel>
            <DataItemText>
              {category
                ? moment(category.createdDate).format("MM/DD/YYYY")
                : "-"}
            </DataItemText>
          </DisableUserDataItem>
        </div>

        <DisableUserButtonsWrapper margin="24px 0 0 0">
          <CancelButton disabled={false} onClick={onCancel}>
            cancel
          </CancelButton>
          <SubmitButton disabled={false} onClick={onSubmit}>
            <LoaderWrapper>
              <Loader
                loading={status === "request"}
                className="p-0 mr-3"
                spinnerProps={{ size: 14 }}
              />
            </LoaderWrapper>
            <span>yes</span>
          </SubmitButton>
        </DisableUserButtonsWrapper>

        <ModalPromptCloseIcon onClick={onCancel} />
      </ModalPromptWrapper>
    </>
  );
};
