export type Data = {
  documentId: number;
  participantIds: number[];
};

export const SHARE_DOCUMENT = {
  REQUEST: "SHARE_DOCUMENT_REQUEST",
  SUCCESS: "SHARE_DOCUMENT_SUCCESS",
  FAILURE: "SHARE_DOCUMENT_FAILURE"
};

export interface ShareDocumentState {
  loading: boolean;
  error: boolean;
  errorMessage: string;
  data?: Data;
}

export interface ShareDocumentActions {
  payload?: any;
  response?: any;
  type: string;
}
