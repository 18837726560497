import React from "react";

import * as S from "../styles";
import useGroupTerm from "util/hooks/whiteLabel/useGroupTerm";

export interface PrefillFromWorkOrderProps {
  selectedWorkOrderId: number | null;
  startNewDocument: (prefill?: boolean) => void;
  createDocumentLoading: boolean;
}

/**
 * **PrefillFromWorkOrder** handles rendering the buttons used to create a NewDocument
 */
const PrefillFromWorkOrder = ({
  selectedWorkOrderId,
  startNewDocument,
  createDocumentLoading,
}: PrefillFromWorkOrderProps) => {
  // Group config terms

  const workOrderTerm = useGroupTerm(
    "workOrder",
    "noun",
    "singular",
    "Work Order"
  );
  const workOrderTermUpper = workOrderTerm.toUpperCase();
  return (
    <>
      {selectedWorkOrderId && (
        <S.Button
          disabled={createDocumentLoading}
          onClick={() => startNewDocument(true)}
          fullWidth
        >
          {createDocumentLoading && <S.LoadingAdornment />}
          PRE-FILL WITH {workOrderTermUpper} INFORMATION
        </S.Button>
      )}
    </>
  );
};

export default PrefillFromWorkOrder;
