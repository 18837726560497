import {
  FormsState,
  GET_ALL_FORMS,
  GET_FORM,
  SEARCH_FORMS,
  GetAllFormsResponse,
  GET_ALL_FORM_TYPES
} from "./types";

const initialState: FormsState = {
  loading: {
    searchForms: { GET: false },
    allForms: false,
    formTypes: false,
  },
  error: {
    searchForms: false,
    allForms: false,
    formTypes: false,
  },
  data: {
    allForms: [],
    formTypes: [],
    searchForms: {
      content: [],
    },
  },
};

export function formsReducer(state = initialState, action: GetAllFormsResponse): FormsState {
  switch (action.type) {
    case GET_ALL_FORMS.REQUEST:
      return {
        ...state,
        loading: { ...state.loading, allForms: true },
        error: { ...state.error, allForms: false },
      };
    case GET_ALL_FORMS.FAILURE:
      return {
        ...state,
        loading: { ...state.loading, allForms: false },
        error: { ...state.error, allForms: true },
      };
    case GET_ALL_FORMS.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, allForms: false },
        error: { ...state.error, allForms: false },
        data: {
          ...state.data,
          allForms: action.response,
        },
      };

    case GET_ALL_FORM_TYPES.REQUEST:
      return {
        ...state,
        loading: { ...state.loading, formTypes: true },
        error: { ...state.error, formTypes: false },
        data: {
          ...state.data,
          formTypes: [],
        }
      };
    case GET_ALL_FORM_TYPES.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, formTypes: false },
        data: {
          ...state.data,
          formTypes: action.response
        }
      };
    case GET_ALL_FORM_TYPES.FAILURE:
      return {
        ...state,
        loading: { ...state.loading, formTypes: false },
        error: { ...state.error, formTypes: true }
      };

    case GET_FORM.REQUEST:
      return {
        ...state,
        loading: { ...state.loading, allForms: true },
        error: { ...state.error, allForms: false },
      };
    case GET_FORM.FAILURE:
      return {
        ...state,
        loading: { ...state.loading, allForms: false },
        error: { ...state.error, allForms: true },
      };
    case GET_FORM.SUCCESS:
      const formToAdd = action.response;
      const formType = formToAdd.type;

      // insert this form into the allForms state key, replacing if it exists
      const allFormsExceptThisOne = state.data.allForms.filter((form) => form.id !== formToAdd.id);
      const allFormTypesExceptMine = state.data.formTypes
        ? state.data.formTypes.filter((type) => type.id !== formType.id)
        : [];

      return {
        ...state,
        loading: { ...state.loading, allForms: false },
        error: { ...state.error, allForms: false },
        data: {
          ...state.data,
          allForms: [...allFormsExceptThisOne, formToAdd],
          formTypes: [...allFormTypesExceptMine, formType],
        },
      };

    case SEARCH_FORMS.FAILURE:
      return {
        ...state,
        error: {
          ...state.error,
          searchForms: {
            status: action?.status && typeof action.status === "number" ? action.status.toString() : "400",
            message: action.error || "",
          },
        },
        loading: {
          ...state.loading,
          searchForms: { GET: false },
        },
      };
    case SEARCH_FORMS.REQUEST:
      return {
        ...state,
        error: {
          ...state.error,
          searchForms: false,
        },
        loading: {
          ...state.loading,
          searchForms: { GET: true },
        },
        data: { ...state.data, searchForms: { content: [] } },
      };
    case SEARCH_FORMS.SUCCESS:
      return {
        ...state,
        error: {
          ...state.error,
          searchForms: false,
        },
        loading: {
          ...state.loading,
          searchForms: { GET: false },
        },
        data: {
          ...state.data,

          searchForms: action.response,
        },
      };

    default:
      return state;
  }
}
