import React, { useState } from "react";
import { css } from "aphrodite/no-important";

import { AssistiveLink } from "../../types";
import styles from "./styles";
import { useThemedStyleSheetWithProps } from "util/hooks/useThemedStyleSheet";
import { joinClassNames } from "themes/helpers";
import { StyledAsterisk } from "components/forms/Label/Label";

interface Props extends React.LabelHTMLAttributes<HTMLLabelElement> {
  assistiveLink?: AssistiveLink;
  containerClassName?: string;
  content: string;
  noMargin?: boolean;
  required?: boolean;
  smallLabel?: boolean;
}

/** Basic HTML label element with optional assistive link */
export function Label({
  assistiveLink,
  className,
  containerClassName,
  content,
  htmlFor,
  noMargin,
  required,
  smallLabel,
}: Props) {
  const [isMouseFocused, setIsMouseFocused] = useState(false);
  const s = useThemedStyleSheetWithProps(styles, { assistiveLink, noMargin });

  // If question is required, add a styled asterisk
  const requiredAsterisk = required ? <StyledAsterisk> *</StyledAsterisk>: "";

  const handleAssistiveLinkBlur = () => {
    setIsMouseFocused(false);
  };

  const handleAssistiveLinkClick = () => {
    if (assistiveLink && assistiveLink.onClick) {
      assistiveLink.onClick(isMouseFocused);
    }
  };

  const handleAssistiveLinkMouseDown = () => {
    setIsMouseFocused(true);
  };

  return (
    <div className={joinClassNames(css(s.wrapper), containerClassName)}>
      <label
        className={joinClassNames(css(s.label, smallLabel && s.labelSmall), className, "fieldLabel")}
        htmlFor={htmlFor}
      >
        {content}{requiredAsterisk}
      </label>
      {assistiveLink?.onClick && (
        <button
          type="button"
          className={css(s.button, isMouseFocused && s.buttonMouseFocused)}
          onBlur={handleAssistiveLinkBlur}
          onClick={handleAssistiveLinkClick}
          onMouseDown={handleAssistiveLinkMouseDown}
        >
          {assistiveLink.icon && <i className={`icon icon-icons8-${assistiveLink.icon}`} />}
          {assistiveLink.label}
        </button>
      )}
      {assistiveLink?.url && (
        <a className={css(s.a)} href={assistiveLink.url}>
          {assistiveLink.icon && <i className={`icon icon-icons8-${assistiveLink.icon}`} />}
          {assistiveLink.label}
        </a>
      )}
      {assistiveLink && !(assistiveLink?.onClick || assistiveLink?.url) && (
        <span className={css(s.span)}>
          {assistiveLink.label}
        </span>
      )}
    </div>
  );
}
