import styled from "styled-components";

export const ResponseList = styled.div`
  /* max-width: 415px; */
  display: flex;
  flex-direction: column;
`;

export const OEInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

export const OEDescription = styled.div`
  word-break: break-word;
  hyphens: auto;
  color: ${({ theme }) => theme.masterColors.darkGrey};
`;

export const OETitle = styled.label``;
