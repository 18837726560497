import {
  DELETE_DOCUMENT,
  GET_DOCUMENT,
  GET_DOCUMENT_HISTORY,
  GET_OWNER_IDS,
  GET_RELATED_DOCUMENTS,
  GET_TEMP_FILTER_DOCUMENTS,
  GetDocumentResponse,
  SEARCH_DOCUMENTS,
  SearchDocumentsParams,
  DocumentVM,
} from "./types";
import { CALL_API } from "../../middleware/api";
import { FormDTO } from "../forms/types";
import { APIResponse } from "../common/types";

// TODO replace with a less verbose implementation and move to central location -JA
/** Build a querystring for fetching documents */
export const buildSearchDocsUrl = ({
  clientGroupIds,
  formIds,
  formTypeIds,
  maxSubmissionDate,
  minSubmissionDate,
  ownerIds,
  participantId,
  participantTreeFilter,
  query,
  size,
  sortName = "submissionDate",
  sortOrder = "desc",
  statuses,
  submissionTypes,
}: SearchDocumentsParams) => {
  let queryString = "?";

  if (query && query.length > 2) {
    queryString = `${queryString}&query=${query}`;
  }

  if (sortName && sortOrder) {
    queryString = `${queryString}&sort=${sortName},${sortOrder}`;
  }

  if (minSubmissionDate && !isNaN(Date.parse(minSubmissionDate))) {
    queryString = `${queryString}&minSubmissionDate=${minSubmissionDate}`;
  }

  if (maxSubmissionDate && !isNaN(Date.parse(maxSubmissionDate))) {
    queryString = `${queryString}&maxSubmissionDate=${maxSubmissionDate}`;
  }

  if (ownerIds) {
    queryString = `${queryString}&participantIds=${ownerIds}&participantIdsFilterType=OWNER`;
  }

  if (formTypeIds && formTypeIds !== -1) {
    queryString = `${queryString}&formTypeIds=${formTypeIds}`;
  }

  if (formIds && formIds !== -1) {
    queryString = `${queryString}&formIds=${formIds}`;
  }

  if (submissionTypes) {
    queryString = `${queryString}&submissionTypes=${submissionTypes}`;
  }

  if (clientGroupIds && clientGroupIds !== -1) {
    queryString = `${queryString}&clientGroupIds=${clientGroupIds}`;
  }

  if (size) {
    queryString = `${queryString}&size=${size}`;
  }

  if (participantId) {
    queryString = `${queryString}&participantId=${participantId}`;
  }

  if (participantTreeFilter) {
    queryString = `${queryString}&participantTreeFilter=${participantTreeFilter}`;
  }

  if (statuses && statuses !== -1) {
    queryString = `${queryString}&statuses=${statuses}`;
  }
  if (queryString === "?") {
    return "";
  }
  // remove unnecessary & following ?
  return queryString.replace("&", "");
};

/**
 * Fetches a document, including responses
 * @param documentId ID of the document to retrieve
 */
export const getDocument = (documentId: number) => (dispatch, getState) => {
  const authToken = getState().system.authToken;

  return dispatch({
    loaderKey: documentId,
    [CALL_API]: {
      endpoint: `documents/${documentId}`,
      options: {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${authToken}`,
        },
      },
      types: [GET_DOCUMENT.REQUEST, GET_DOCUMENT.SUCCESS, GET_DOCUMENT.FAILURE],
    },
  }).then((res: GetDocumentResponse) => res);
};

const PUT_DOCUMENT = {
  REQUEST: "PUT_DOCUMENT_REQUEST",
  SUCCESS: "PUT_DOCUMENT_SUCCESS",
  FAILURE: "PUT_DOCUMENT_FAILURE",
};

export interface PutDocumentResponse extends APIResponse {
  response: FormDTO;
}

/**
 * PUT a document to the server
 * @param document
 */
export const putDocument = (document: FormDTO) => (dispatch, getState) => {
  const authToken = getState().system.authToken;
  return dispatch({
    [CALL_API]: {
      endpoint: "documents",
      options: {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${authToken}`,
        },
        body: JSON.stringify(document),
      },
      types: [PUT_DOCUMENT.REQUEST, PUT_DOCUMENT.SUCCESS, PUT_DOCUMENT.FAILURE],
    },
  }).then((res: PutDocumentResponse) => res);
};

export type GetDocument = typeof getDocument;

/**
 * Deletes a document by ID
 * @param documentId ID of the document to delete
 */
export const deleteDocument = (documentId: number) => (dispatch, getState) => {
  const authToken = getState().system.authToken;

  return dispatch({
    loaderKey: documentId,
    [CALL_API]: {
      endpoint: `documents/${documentId}`,
      options: {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
      },
      types: [
        DELETE_DOCUMENT.REQUEST,
        DELETE_DOCUMENT.SUCCESS,
        DELETE_DOCUMENT.FAILURE,
      ],
    },
  });
};

// Search Documents
export const searchDocuments = (props: SearchDocumentsParams) => (
  dispatch,
  getState
) => {
  const { page, infinite } = props;
  const authToken = getState().system.authToken;

  let loaderKey = "searchDocuments";
  let endpoint = "documents";
  if (props.participantId) {
    endpoint = `participants/${props.participantId}/documents`;
  }
  const queryString = buildSearchDocsUrl(props);
  let _page = "";

  // If requesting a page
  // explicitly check for != null so we can pass 0
  if (page != null) {
    if (typeof page === "string" && infinite) {
      loaderKey = `${loaderKey}Infinite`;
      // queryString = `${queryString}&page=${getState().documents.data.searchDocuments.pageable.pageNumber + 1}`;
      _page = `&page=${getState().documents.data.searchDocuments.pageable
        .pageNumber + 1}`;
    } else {
      // queryString = `${queryString}&page=${page}`;
      _page = `&page=${page}`;
    }
  }

  endpoint = `${endpoint}${queryString}${_page}`;

  return dispatch({
    infinite: infinite,
    loaderKey: loaderKey,
    queryString: queryString,
    [CALL_API]: {
      endpoint,
      options: {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${authToken}`,
        },
      },
      types: [
        SEARCH_DOCUMENTS.REQUEST,
        SEARCH_DOCUMENTS.SUCCESS,
        SEARCH_DOCUMENTS.FAILURE,
      ],
    },
  });
};
export type SearchDocuments = typeof searchDocuments;

// Search Documents, get next page
export const searchDocumentsNextPage = () => (dispatch, getState) => {
  const authToken = getState().system.authToken;
  const loaderKey = "searchDocumentsInfinite";
  let endpoint = "documents";

  const queryString = getState().documents.queryStrings.searchDocuments;
  const nextPage =
    getState().documents.data.searchDocuments.pageable.pageNumber + 1;

  // queryString += `&page=${nextPage}`;
  endpoint = `${endpoint}${queryString}&page=${nextPage}`;

  return dispatch({
    infinite: true,
    loaderKey: loaderKey,
    queryString: queryString,
    [CALL_API]: {
      endpoint,
      options: {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${authToken}`,
        },
      },
      types: [
        SEARCH_DOCUMENTS.REQUEST,
        SEARCH_DOCUMENTS.SUCCESS,
        SEARCH_DOCUMENTS.FAILURE,
      ],
    },
  });
};

// Temp mobile filter documents
export const searchTempFilterDocuments = (props: SearchDocumentsParams) => (
  dispatch,
  getState
) => {
  const { page, infinite } = props;
  const authToken = getState().system.authToken;

  let loaderKey = "searchDocuments";
  let endpoint = "documents";
  let queryString = buildSearchDocsUrl(props);

  // If requesting a page
  // explicitly check for != null so we can pass 0
  if (page != null) {
    if (typeof page === "string" && infinite) {
      loaderKey = `${loaderKey}Infinite`;
      queryString = `${queryString}&page=${getState().documents.data
        .searchDocuments.pageable.pageNumber + 1}`;
    } else {
      queryString = `${queryString}&page=${page}`;
    }
  }

  endpoint = `${endpoint}${queryString}`;

  return dispatch({
    loaderKey: loaderKey,
    infinite: infinite,
    [CALL_API]: {
      endpoint,
      options: {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${authToken}`,
        },
      },
      types: [
        GET_TEMP_FILTER_DOCUMENTS.REQUEST,
        GET_TEMP_FILTER_DOCUMENTS.SUCCESS,
        GET_TEMP_FILTER_DOCUMENTS.FAILURE,
      ],
    },
  });
};

export const getOwnerIds = (query?: string) => (dispatch, getState) => {
  const { system } = getState();

  return dispatch({
    loaderKey: "ownerIds",
    [CALL_API]: {
      endpoint: `documents/owners${query ? `?query=${query}` : ""}`,
      options: {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${system.authToken}`,
        },
      },
      types: [
        GET_OWNER_IDS.REQUEST,
        GET_OWNER_IDS.SUCCESS,
        GET_OWNER_IDS.FAILURE,
      ],
    },
  });
};

// Get document history
export const getDocumentHistory = (id: string | number) => (
  dispatch,
  getState
) => {
  const { system } = getState();

  return dispatch({
    [CALL_API]: {
      endpoint: `documents/${id}/history`,
      options: {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${system.authToken}`,
        },
      },
      types: [
        GET_DOCUMENT_HISTORY.REQUEST,
        GET_DOCUMENT_HISTORY.SUCCESS,
        GET_DOCUMENT_HISTORY.FAILURE,
      ],
    },
  });
};

// Get related documents
export const getRelatedDocuments = (id: string | number) => (
  dispatch,
  getState
) => {
  const { system } = getState();

  return dispatch({
    [CALL_API]: {
      endpoint: `documents/${id}/related`,
      options: {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${system.authToken}`,
        },
      },
      types: [
        GET_RELATED_DOCUMENTS.REQUEST,
        GET_RELATED_DOCUMENTS.SUCCESS,
        GET_RELATED_DOCUMENTS.FAILURE,
      ],
    },
  });
};

// Reset owner IDs
export const resetOwnerIds = () => ({
  type: "RESET_OWNER_IDS",
});

export const updateOneDocument = (document: DocumentVM) => (dispatch) => {
  return dispatch({
    type: GET_DOCUMENT.SUCCESS,
    response: document,
  });
};
