import React, { Dispatch, SetStateAction } from "react";
import styled from "styled-components";

import Drawer from "components/common/Drawer";
import DrawerContentWrapper from "components/common/Drawer/components/DrawerContentWrapper";
import PrivacyPolicyContent from "components/privacy/PrivacyPolicyContent";
import StickyHeader from "components/common/Drawer/components/StickyHeader";


interface PrivacySidebarProps {
  isOpen: boolean;
  setIsOpen: (value: SetStateAction<boolean>) => void;
  title?: string;
}

const Body = styled.div`
  margin-top: -0.8125rem;
  text-align: left;
`;

const PrivacySidebar = ({ isOpen, setIsOpen, title = "Privacy Policy" }: PrivacySidebarProps) => {
  const handleClose = () => setIsOpen(false);
  return (
    <Drawer
      content={
        <>
          {/* Header */}
          <StickyHeader
            title={title}
            onClick={handleClose}
            chevronRight={false}
          />
          {/* Content */}
          <DrawerContentWrapper>
            <Body>
              <PrivacyPolicyContent />
            </Body>
          </DrawerContentWrapper>
        </>
      }
      anchor="right"
      disableBackdrop
      paperProps={{ elevation: 0 }}
      id="PrivacySidebar"
      onClose={() => setIsOpen(false)}
      open={isOpen}
      overrideClasses={{
        container: "drawerContainerNoBackdrop",
        content: ""
      }}
      showCloseIcon={false}
    />
  );
};

export default PrivacySidebar;
