import * as React from "react";

import * as S from "./styles";
import { pluralize } from "components/helpers";

interface PercentChartProps {
  data: [
    {
      value: number;
      label: string;
    },
  ];
  total: number;
}

const PercentChart = ({ data, total }: PercentChartProps) => {
  const percentOfTotal = (num) => Math.round((num / total) * 100);

  return (
    <>
      {data.map((r) => (
        <S.PercentChartRow key={r.label}>
          <S.PercentChartNumber>{percentOfTotal(r.value)}%</S.PercentChartNumber>
          <S.MultiSelectStatisticCell>
            <S.PercentBar percent={percentOfTotal(r.value)} />
            <S.Label>{r.label}</S.Label>
            <S.Count>{pluralize("Response", r.value, true)}</S.Count>
          </S.MultiSelectStatisticCell>
        </S.PercentChartRow>
      ))}
    </>
  );
};

export default PercentChart;
