import React from "react";
import { Action } from "redux";
import { useDispatch } from "react-redux";

import { FieldErrorType } from "components/FormController/components/Question";

import { SingleResponseQuestionProps } from "../types";
import { BaseTextInputProps } from "../TextInput/TextInput";
import TextInputWithSuggestions, {
  TextInputSuggestion
} from "components/common/form/TextInput/TextInputWithSuggestions";
import { AppState } from "store";
import { searchDataSourceValues } from "store/dataSource/actions";
import { ThunkDispatch } from "redux-thunk";
import useDebounce from "util/hooks/useDebounce";
import { DataSourceValueDTO } from "store/dataSource/types";

export interface SingleLineTextInputProps
  extends BaseTextInputProps,
    SingleResponseQuestionProps {
  defaultValue?: string | number;
  enhancedComments?: "enabled" | "required-with-response";
  error?: FieldErrorType;
  maxCharacters?: number;
  onBlur?: (value: string) => void;
  type: "email" | "text" | "password" | "url";
}

/** Standard text input utilizing auto-complete (modified from MUI base) */
const SingleLineTextInput = ({
  assistiveLink,
  disabled,
  error = "",
  onBlur,
  question,
  response,
  setQuestionResponse,
  type,
  ...props
}: SingleLineTextInputProps) => {
  const isRequired = question.formProperties?.isRequired;
  const responseAnswer: string = response?.answer || "";
  const [value, setValue] = React.useState(responseAnswer);
  const [suggestions, setSuggestions] = React.useState<Array<TextInputSuggestion>>([]);

  const dispatch = useDispatch<ThunkDispatch<AppState, void, Action>>();

  const answerField = question.answerSource?.properties.answerField;
  const dataSourceKey = question.answerSource?.dataSourceKey;
  const sortBy = question.answerSource?.properties.detailedSearch?.infiniteListSortBy;

  /** if the response is updated outside of this, update the value */
  React.useEffect(() => {
    setValue(responseAnswer);
  }, [ responseAnswer ]);

  /** Debounced call to get data source values and set suggestions from response */
  const setDSSuggestions = useDebounce({
    async method(query: string) {
      if (answerField && dataSourceKey) {
        const res = await dispatch(searchDataSourceValues({
          keyName: dataSourceKey,
          query,
          size: 40,
          sortBy
        }));
        const dsValues = res?.response?.content;
        if (dsValues) {
          return setSuggestions(() => dsValues.reduce((result: Array<TextInputSuggestion>, dsv: DataSourceValueDTO) => {
            if (dsv.content[answerField]?.toLowerCase().includes(query.toLowerCase())) {
              result.push({
                title: dsv.content[answerField],
                id: dsv.id
              });
            }
            return result;
          }, []));
        }
      }
      return setSuggestions([]);
    }
  });

  /* Get and set suggestions on user input */
  React.useEffect(() => {
    if (value.length < 3 || responseAnswer === value) {
      setSuggestions([]);
    } else {
      setDSSuggestions(value);
    }
  }, [responseAnswer, value, dispatch, searchDataSourceValues]);

  /**
   * Clear suggestions and put question response to server on field blur
   * @param event - blur event 
   */
  function handleBlur({ target: { value } }: React.FocusEvent<HTMLInputElement>) {
    onBlur && onBlur(value);
    if (suggestions.length > 0) {
      setSuggestions([]);
    }
    if (responseAnswer !== value) {
      setQuestionResponse(
        value
          ? {
            answer: value,
            questionId: question.id,
            questionRootId: question.rootId,
            timeAnswered: new Date().toISOString(),
          }
          : undefined
      );
    }
  }

  
  // Helper text
  const helperText = React.useMemo(() => 
    question.properties?.assistiveText || undefined, 
  [question.properties?.assistiveText]);

  return (
    <TextInputWithSuggestions
      autoComplete="off"
      assistiveLink={assistiveLink || undefined}
      disabled={disabled}
      error={error}
      helperText={helperText}
      idField="id"
      label={props.label}
      labelField="title"
      suggestions={suggestions}
      onBlur={handleBlur}
      onInputChange={(value) => setValue(value)}
      onSelectSuggestion={(suggestion) => {
        setSuggestions([]);
        setValue(suggestion.title);
        setQuestionResponse(
          suggestion.title
            ? {
              answer: suggestion.title,
              questionId: question.id,
              questionRootId: question.rootId,
              timeAnswered: new Date().toISOString(),
            }
            : undefined
        );
      }}
      name="dataSet" 
      placeholder={props.placeholder}
      type={type}
      value={value}
      required={isRequired}
    />
  );

};

export default SingleLineTextInput;
