import { FilterParams, PaginationParams } from "store/common/types";

export const GROUPS_FILTERS = {
  SET: "GROUPS_FILTERS_SET",
  PARAMS_SET: "GROUPS_FILTERS_PARAMS_SET",
};

export interface GroupsFiltersParams extends FilterParams {
  query: string;
}

export interface GroupsFiltersArgs {
  filterParams: GroupsFiltersParams;
  paginationParams: PaginationParams;
}

export interface SetGroupsFiltersAction {
  type: typeof GROUPS_FILTERS.SET;
  response: GroupsFiltersParams;
}

export interface SetGroupsPaginationAction {
  type: typeof GROUPS_FILTERS.PARAMS_SET;
  response: PaginationParams;
}

export type FiltersActions = SetGroupsFiltersAction;
