import React, { Dispatch, SetStateAction } from "react";

import Drawer from "components/common/Drawer";
import FormTypesDrawerContent from "./components/FormTypesDrawerContent";
import { FormRelationshipTypeDTO, FormTypeDTO } from "store/forms/types";

interface Props {
  formTypes: FormTypeDTO[];
  isDesktop?: boolean;
  isOpen: boolean;
  onSelectFormType: (type: FormTypeDTO) => void;
  relationshipTypes: FormRelationshipTypeDTO[];
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}

const FormTypesDrawer = ({ formTypes, isOpen, setIsOpen, isDesktop, onSelectFormType }: Props) => {
  return (
    <Drawer
      content={
        <FormTypesDrawerContent
          formTypes={formTypes}
          onClose={() => setIsOpen(false)}
          onSelectFormType={onSelectFormType}
        />
      }
      anchor="right"
      disableBackdrop
      paperProps={{ elevation: 0 }}
      id="FormTypesDrawer"
      onClose={() => setIsOpen(false)}
      onOpen={() => {}}
      open={isOpen}
      overrideClasses={{
        container: "drawerContainerNoBackdrop",
        content: isDesktop ? "myDocumentsDrawerDesktop" : "",
      }}
      showCloseIcon={false}
    />
  );
};

export default FormTypesDrawer;
