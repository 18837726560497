import * as React from "react";

import BarChart from "components/common/Charts/BarChart/BarChart";
import DownloadResultsButton from "components/clientAdmin/DownloadResultsButton";
import Loader from "components/common/Loader";
import PercentChart from "components/common/Charts/PercentChart/PercentChart";
import PieChart from "components/common/Charts/PieChart/PieChart";
import { pluralize } from "components/helpers";

import { FormStats } from "store/reporting/singleFormStats/types";

import { H3, SmallText, ListItem } from "../../styles";
import styled, { createGlobalStyle } from "styled-components";
import { QuestionDTO } from "store/forms/types";
import moment from "moment";
import QuestionHasChanged from "./components/QuestionHasChanged";

/**
 * When printing, just show the Summary tab contents and header.
 */
const GlobalPrintStyle = createGlobalStyle`
  @media print {
    .sidebar-navigation,
    #caFormTableSummary,
    #caFormFiltersAndStats,
    .caExportAllResponsesLink { 
      display: none !important; 
    }

    #mainContent { 
      display: block; 
      overflow: visible !important;
    }
  }
`;

const StyledDownloadButton = styled.button`
  background: transparent;
  outline: 0;
  border: 0;
  color: ${({ theme }) => theme.colors.primary};
  text-decoration: underline;
  font-weight: 500;
  padding: 0;
  :focus {
    outline: 0;
  }
`;

interface ResponseSummariesProps {
  formId: number;
  formStats: {
    data: FormStats | null;
    loading: boolean;
    error: boolean;
  };
  minMaxDate?: {
    minSubmissionDate: string;
    maxSubmissionDate: string;
  };
  switchToHistory: () => void;
  visible: boolean;
}

const ResponseSummaries = ({ formId, switchToHistory, formStats, minMaxDate, visible }: ResponseSummariesProps) => {
  if (formStats.loading) {
    return <Loader loading />;
  }

  if (formStats.error) {
    return <>An error occurred when loading the form summary. Refresh the page and try again.</>;
  }

  return formStats.data ? (
    <>
      {visible && <GlobalPrintStyle />}
      {formStats.data.questions.map((response) => {
        const hasChangedForRange = moment(response.question.lastModifiedDate)
          .isBetween(minMaxDate?.minSubmissionDate, minMaxDate?.maxSubmissionDate);
        return <ResponseSummary 
          key={response.question.id} 
          formId={formId} 
          hasChangedForRange={hasChangedForRange}
          switchToHistory={switchToHistory}
          {...response}
        />;
      })}
    </>
  ) : null;
};

type Data = {
  id: string;
  value: number;
  label: string;
};

interface ResponseSummaryType {
  formId: number;
  hasChangedForRange?: boolean;
  question: QuestionDTO;
  topResponses: {
    response: string;
    responseCount: number;
  }[];
  totalResponses?: number;
  switchToHistory: () => void;
}

const ResponseSummary = ({ formId, hasChangedForRange, question, totalResponses, switchToHistory, topResponses }: ResponseSummaryType) => {
  let responseDetails, detailsTitle;
  const dataForCharts: Array<Data> = topResponses.map((r) => ({
    id: r.response,
    value: r.responseCount,
    label: r.response,
  }));

  switch (question.subType) {
    case "TEXT_LINE":
    case "PARTICIPANT":
    case "TEXT_AREA":
    case "DATE":
    case "DATE_TIME":
      responseDetails = <MostPopularResponseDetails responses={topResponses} />;
      detailsTitle = "Most Popular Responses";
      break;

    case "RADIO_BUTTONS":
    case "CHECKBOX":
      detailsTitle = "Results Chart";
      responseDetails = <PieChartResponseDetails data={dataForCharts} totalResponses={totalResponses} />;
      break;

      // case "DROP_DOWN":
      //   detailsTitle = "Results Chart";
      //   responseDetails = <BarChartResponseDetails data={dataForCharts} />;
      //   break;

    case "DROP_DOWN":
    case "MULTI_SELECT":
      detailsTitle = "Results Chart";
      responseDetails = <PercentSelectResponseDetails data={dataForCharts} totalResponses={totalResponses} />;
      break;
    case "OP_EXPERIENCE":
      // TODO implement -- customized BarChart
      responseDetails = null;
      break;
    default:
      responseDetails = null;
      break;
  }

  if (responseDetails) {
    return (
      <>
        <H3 className="mt-4 mb-3">{question.title}</H3>
        <div className="d-flex mb-4">
          <div className="col-4x w-25 mr-3">
            <SmallText className="d-block mb-3">{pluralize("response", totalResponses || 0, true)}</SmallText>

            {!!(totalResponses) && 
              <SmallText className="caExportAllResponsesLink">
                <DownloadResultsButton
                  url={`reports/form-responses/${formId}/${question.id}`}
                  type="text/csv"
                  fileName={`Export_${question.title}.csv`}
                >
                  <StyledDownloadButton>Export All Responses</StyledDownloadButton>
                </DownloadResultsButton>
              </SmallText>
            }
          </div>

          <div className="w-75">
            {/* {hasChangedForRange && <QuestionHasChanged onClick={switchToHistory}/>} */}
            <SmallText className="font-weight-bold d-block mb-3">{detailsTitle}</SmallText>
            {topResponses && topResponses.length > 0 ? responseDetails : <>No responses</>}
          </div>
        </div>
        <hr />
      </>
    );
  }
  return null;
};

const MostPopularResponseDetails = ({ responses }) => (
  <div className="row">
    <div className="col">
      {responses.slice(0, 3).map((popular) => (
        <ListItem key={popular.response}>
          {popular.response} ({popular.responseCount})
        </ListItem>
      ))}
    </div>
    <div className="col">
      {responses.slice(3, 6).map((popular) => (
        <ListItem key={popular.response}>
          {popular.response} ({popular.responseCount})
        </ListItem>
      ))}
    </div>
  </div>
);

const PieChartResponseDetails = ({ data, totalResponses }) => {
  // TODO should I take top 3, top N? All gets super messy.
  const formattedData: Array<Data> = data.slice(0, 3).map((d) => ({
    id: `${d.id} (${d.value})`,
    label: d.label,
    value: d.value,
  }));
  return (
    <div style={{ height: "300px", width: 500 }}>
      <PieChart data={formattedData} sliceLabel={(n) => `${Math.round((n.value / totalResponses) * 100)}%`} />
    </div>
  );
};

const BarChartResponseDetails = ({ data }) => {
  const height = data.length * 50;
  return (
    <div style={{ height: height + 100 }}>
      <BarChart data={data} />
    </div>
  );
};

const PercentSelectResponseDetails = ({ data, totalResponses }) => {
  return <PercentChart data={data} total={totalResponses} />;
};

export default ResponseSummaries;
