import { ThunkAction, ThunkDispatch } from "redux-thunk";

import {
  GET_APP_CONFIG,
  GET_PRESIGNED_APP_CONFIG,
  GET_IMAGE_FROM_PRESIGNED_APP_CONFIG,
} from "./types";
import { CALL_API } from "../../middleware/api";

// Get presigned url
export const getPresignedAppConfig = (
  keyName: string,
  property: string,
  privateUrl: string
): ThunkAction<any, any, any, any> => (
  dispatch: ThunkDispatch<any, any, any>,
  getState
) => {
  const authToken = getState().system.authToken;
  return dispatch({
    keyName: keyName,
    property: property,
    [CALL_API]: {
      endpoint: "application-config/presigned-url/viewable",
      options: {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${authToken}`,
        },
        body: JSON.stringify({
          privateUrl,
        }),
      },
      types: [
        GET_PRESIGNED_APP_CONFIG.REQUEST,
        GET_PRESIGNED_APP_CONFIG.SUCCESS,
        GET_PRESIGNED_APP_CONFIG.FAILURE,
      ],
    },
  });
};

// Get image from presigned url
export const getImageFromUrl = (
  keyName: string,
  property: string,
  url: string
): ThunkAction<any, any, any, any> => (
  dispatch: ThunkDispatch<any, any, any>,
  getState
) => {
  const authToken = getState().system.authToken;
  return dispatch({
    keyName: keyName,
    property: property,
    [CALL_API]: {
      endpoint: `${url}`,
      options: {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/octet-stream",
          Authorization: `Bearer ${authToken}`,
        },
      },
      types: [
        GET_IMAGE_FROM_PRESIGNED_APP_CONFIG.REQUEST,
        GET_IMAGE_FROM_PRESIGNED_APP_CONFIG.SUCCESS,
        GET_IMAGE_FROM_PRESIGNED_APP_CONFIG.FAILURE,
      ],
    },
  });
};

// Get App config
export const getAppConfig = (
  keyName: string
): ThunkAction<any, any, any, any> => (
  dispatch: ThunkDispatch<any, any, any>,
  getState
) => {
  const authToken = getState().system.authToken;
  return dispatch<any>({
    keyName: keyName,
    [CALL_API]: {
      endpoint: `application-configs/${keyName}`,
      options: {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${authToken}`,
        },
      },
      types: [
        GET_APP_CONFIG.REQUEST,
        GET_APP_CONFIG.SUCCESS,
        GET_APP_CONFIG.FAILURE,
      ],
    },
  }).then((e: any) => {
    if (e?.type === GET_APP_CONFIG.SUCCESS) {
      // Fetch logo presigned urls
      if (e.keyName === "logoConfigs") {
        ["logo2x", "logo2x2", "logo2x3"].forEach((s) =>
          dispatch(
            getPresignedAppConfig(
              "logoConfigs",
              s,
              e.response.properties?.[s]?.privateUrl
            )
          )
        );
      }
    }
  });
};

// This is so we download images to the app later. Temporarily I'm gonna just load them in <img/> tags
// if (e.type === GET_PRESIGNED_APP_CONFIG.SUCCESS) {
//   dispatch(getImageFromUrl(e.keyName, e.property, e.response.readableUrl));
// }
