import React from "react";

import * as S from "./styles";

export interface StartNewFormButtonProps {
  disabled?: boolean;
  onClick: () => void;
  ref?: React.RefObject<HTMLButtonElement> | null;
}

/**
 * **StartNewFormButton** is a button type component used within
 * `DashboardBottomNavigation.tsx`. When clicked, it opens
 * the Drawer component which leads into the _Start New Form_ feature.
 */
const StartNewFormButton = React.forwardRef<HTMLButtonElement, StartNewFormButtonProps>((
  { disabled, onClick },
  ref
) => {
  return (
    <>
      <S.StartNewFormButton ref={ref} onClick={onClick} disabled={disabled}>
        <S.StyledIcon className="icon-icons8-add_file" />
      </S.StartNewFormButton>
    </>
  );
});

StartNewFormButton.defaultProps = {
  onClick: () => console.log("CLICKED DASHBOARD BUTTON"),
  disabled: false,
};

export default StartNewFormButton;
