import React from "react";

import DesktopLabel from "../../ProfileField/DesktopLabel";
import RenderGroups from "../../RenderGroups";

import * as S from "./styles";

interface Props {
  groups: Array<string>;
  label: string;
  locked?: boolean;
  name: string;
}

const DesktopOptionField = ({ label, name, groups, locked }: Props) => {
  return (
    <S.DesktopOptionField>
      <DesktopLabel label={label} name={name} locked={locked} />

      <RenderGroups values={groups} isDesktop locked={locked} />
    </S.DesktopOptionField>
  );
};

export default DesktopOptionField;
