import { StyleDeclaration, StyleDeclarationValue, StyleSheet } from "aphrodite";
import { ThemeTypes } from "themes/colors";
import { remCalc } from "themes/helpers";
import { CreateStyleSheet } from "util/hooks/useThemedStyleSheet";
import { screenReaderOnly } from "themes/mixins";

export interface ButtonStyle {
  button?: StyleDeclarationValue;
  buttonChecked?: StyleDeclarationValue;
  buttonCompleted?: StyleDeclarationValue;
  buttonDisabled?: StyleDeclarationValue;
  buttonEnabled?: StyleDeclarationValue;
  buttonLoading?: StyleDeclarationValue;
  buttonWithoutMouseFocus?: StyleDeclarationValue;
  retainer?: StyleDeclarationValue;
}

export interface CircleCheckmarkStyle {
  circledIcon?: StyleDeclarationValue;
  iconCircle?: StyleDeclarationValue;
  iconCircleBeforeLabel?: StyleDeclarationValue;
}

export interface LabelStyle {
  label?: StyleDeclarationValue;
  labelChecked?: StyleDeclarationValue;
  labelCompleted?: StyleDeclarationValue;
  labelDisabled?: StyleDeclarationValue;
  labelEnabled?: StyleDeclarationValue;
  labelLoading?: StyleDeclarationValue;
}

export interface ProgressStyle {
  progress: StyleDeclarationValue;
}

const mediumCapitalFont: StyleDeclaration = {
  fontSize: remCalc(16),
  fontWeight: 500,
  letterSpacing: remCalc(1.44),
  lineHeight: 1.5,
  textTransform: "uppercase",
};

const rowCentered: StyleDeclaration = {
  alignItems: "center",
  display: "inline-flex",
  flexDirection: "row",
  justifyContent: "center",
};

const rowCenteredInline: StyleDeclaration = {
  alignItems: "center",
  display: "inline-flex",
  flexDirection: "row",
  justifyContent: "center",
};

const buttonTransitions = (theme: ThemeTypes): StyleDeclaration => {
  const { duration, timingFunction } = theme.animations.focusIndicatorAppear;
  return {
    transition: `
      background-color ${duration} ${timingFunction},
      border-color ${duration} ${timingFunction},
      box-shadow ${duration} ${timingFunction}
      `,
  };
};

export const alwaysShownLabelStyles: CreateStyleSheet<LabelStyle> = () => {
  return {};
};

export const genericLabelStyles: CreateStyleSheet<LabelStyle> = () => {
  return StyleSheet.create({
    labelChecked: {
      ...screenReaderOnly,
    },
    labelLoading: {
      ...screenReaderOnly,
    },
  });
};

export const hiddenCircleCheckmarkStyles: CreateStyleSheet<CircleCheckmarkStyle> = () => {
  return StyleSheet.create({
    circledIcon: {
      display: "none",
    },
    iconCircle: {
      display: "none",
    },
  });
};

export const linkButtonStyles: CreateStyleSheet<ButtonStyle> = (theme) => {
  const { duration, timingFunction } = theme.animations.focusIndicatorAppear;
  return StyleSheet.create({
    button: {
      background: "none",
      border: 0,
      borderRadius: remCalc(4),
      boxShadow: `0 0 0 ${remCalc(6)} transparent`,
      color: theme.colors.primary,
      cursor: "pointer",
      fontFamily: "Roboto",
      fontSize: remCalc(16),
      lineHeight: 1.5,
      textDecoration: "underline",
      transition: `box-shadow ${duration} ${timingFunction}`,
    },
    buttonDisabled: {
      color: theme.masterColors.darkGreyBlue,
      textDecoration: "underline",
    },
    buttonEnabled: {
      ":hover": {
        color: theme.masterColors.secondary,
      },
    },
    buttonWithoutMouseFocus: {
      ":focus": {
        boxShadow: `0 0 0 ${remCalc(4)} ${theme.colors.focusIndicatorOutline}`,
        outline: 0,
      },
    },
    retainer: rowCentered,
  });
};

export const primaryButtonStyles: CreateStyleSheet<ButtonStyle> = (theme) => {
  return StyleSheet.create({
    button: {
      ...rowCenteredInline,
      ...mediumCapitalFont,
      ...buttonTransitions(theme),
      backgroundColor: theme.colors.primary,
      borderRadius: remCalc(4),
      border: "none",
      boxShadow: `0 1px 3px 0 ${theme.colors.dropShadow}, 0 0 0 ${remCalc(
        6
      )} transparent`,
      color: theme.colors.white,
      padding: `${remCalc(13)} ${remCalc(24)}`,
      position: "relative",
    },
    buttonChecked: {
      backgroundColor: theme.masterColors.secondary,
    },
    buttonCompleted: {
      backgroundColor: theme.masterColors.success,
    },
    buttonDisabled: {
      backgroundColor: theme.masterColors.lightGrey,
      color: theme.masterColors.darkGreyBlue,
    },
    buttonEnabled: {
      ":hover": {
        background: theme.masterColors.secondary,
      },
    },
    buttonLoading: {
      backgroundColor: theme.masterColors.secondary,
    },
    buttonWithoutMouseFocus: {
      ":focus": {
        boxShadow: `0 1px 3px 0 ${theme.colors.dropShadow}, 0 0 0 ${remCalc(
          4
        )} ${theme.colors.focusIndicatorOutline}`,
      },
    },
    retainer: {
      ...rowCentered,
      minHeight: remCalc(24),
      minWidth: remCalc(64),
    },
  });
};

export const removeLinkButtonStyles: CreateStyleSheet<ButtonStyle> = (
  theme
) => {
  const { duration, timingFunction } = theme.animations.focusIndicatorAppear;
  return StyleSheet.create({
    button: {
      background: "none",
      border: 0,
      borderRadius: remCalc(4),
      boxShadow: `0 0 0 ${remCalc(6)} transparent`,
      color: theme.colors.error,
      cursor: "pointer",
      fontFamily: "Roboto",
      fontSize: remCalc(16),
      lineHeight: 1.5,
      textDecoration: "underline",
      transition: `box-shadow ${duration} ${timingFunction}`,
    },
    buttonWithoutMouseFocus: {
      ":focus": {
        boxShadow: `0 0 0 ${remCalc(4)} ${theme.colors.focusIndicatorOutline}`,
        outline: 0,
      },
    },
    retainer: rowCenteredInline,
  });
};

export const secondaryButtonStyles: CreateStyleSheet<ButtonStyle> = (theme) => {
  const borderWidth = "1px";
  return StyleSheet.create({
    button: {
      ...rowCenteredInline,
      ...mediumCapitalFont,
      ...buttonTransitions(theme),
      backgroundColor: theme.colors.white,
      borderRadius: remCalc(4),
      border: `${borderWidth} solid ${theme.masterColors.lightGrey}`,
      boxShadow: `0 0 0 ${remCalc(6)} transparent`,
      color: theme.colors.primary,
      padding: `calc(${remCalc(13)} - ${borderWidth}) calc(${remCalc(
        24
      )} - ${borderWidth})`,
      position: "relative",
    },
    buttonChecked: {
      backgroundColor: theme.colors.activeFill,
    },
    buttonCompleted: {
      backgroundColor: theme.colors.activeFill,
    },
    buttonDisabled: {
      backgroundColor: theme.masterColors.lightGrey,
      borderColor: theme.masterColors.lightGrey,
      color: theme.masterColors.darkGreyBlue,
    },
    buttonEnabled: {
      ":hover": {
        backgroundColor: theme.colors.activeFill,
        borderColor: theme.colors.activeFill,
      },
    },
    buttonLoading: {
      backgroundColor: theme.colors.activeFill,
    },
    buttonWithoutMouseFocus: {
      ":focus": {
        boxShadow: `0 0 0 ${remCalc(4)} ${theme.colors.focusIndicatorOutline}`,
      },
    },
    retainer: {
      ...rowCentered,
      minHeight: remCalc(24),
      minWidth: remCalc(64),
    },
  });
};

export const tertiaryButtonStyles: CreateStyleSheet<ButtonStyle> = (theme) => {
  return StyleSheet.create({
    button: {
      ...rowCenteredInline,
      ...mediumCapitalFont,
      ...buttonTransitions(theme),
      backgroundColor: theme.colors.white,
      borderRadius: remCalc(4),
      border: "none",
      boxShadow: `0 0 0 ${remCalc(6)} transparent`,
      color: theme.colors.error,
      padding: `${remCalc(13)} ${remCalc(24)}`,
      position: "relative",
    },
    buttonChecked: {
      backgroundColor: theme.colors.errorFill,
    },
    buttonCompleted: {
      backgroundColor: theme.colors.errorFill,
    },
    buttonDisabled: {
      backgroundColor: theme.colors.white,
      color: theme.masterColors.darkGreyBlue,
    },
    buttonEnabled: {
      ":hover": {
        backgroundColor: theme.masterColors.errorFill,
      },
    },
    buttonLoading: {
      backgroundColor: theme.colors.errorFill,
    },
    buttonWithoutMouseFocus: {
      ":focus": {
        boxShadow: `0 0 0 ${remCalc(4)} ${theme.colors.focusIndicatorOutline}`,
      },
    },
    retainer: {
      ...rowCentered,
      minHeight: remCalc(24),
      minWidth: remCalc(64),
    },
  });
};

const circledIconSize: StyleDeclaration = {
  fontSize: remCalc(14),
  height: remCalc(10),
  width: remCalc(10),
};

const iconCircleBorder: StyleDeclaration = {
  borderRadius: "50%",
  borderStyle: "solid",
  borderWidth: remCalc(2),
};

const iconCircleSize: StyleDeclaration = {
  height: remCalc(18),
  width: remCalc(18),
};

export const primaryCircleCheckmarkStyles: CreateStyleSheet<CircleCheckmarkStyle> = (
  theme
) => {
  return StyleSheet.create({
    circledIcon: {
      ...circledIconSize,
      position: "relative",
      top: remCalc(-4),
    },
    iconCircle: {
      ...iconCircleBorder,
      ...iconCircleSize,
      borderColor: theme.colors.white,
    },
    iconCircleBeforeLabel: {
      marginRight: remCalc(8),
    },
  });
};

export const secondaryCircleCheckmarkStyles: CreateStyleSheet<CircleCheckmarkStyle> = (
  theme
) => {
  return StyleSheet.create({
    circledIcon: {
      ...circledIconSize,
      position: "relative",
      top: remCalc(-4),
    },
    iconCircle: {
      ...iconCircleBorder,
      ...iconCircleSize,
      borderColor: theme.colors.primary,
    },
    iconCircleBeforeLabel: {
      marginRight: remCalc(8),
    },
  });
};

export const tertiaryCircleCheckmarkStyles: CreateStyleSheet<CircleCheckmarkStyle> = (
  theme
) => {
  return StyleSheet.create({
    circledIcon: {
      ...circledIconSize,
      position: "relative",
      top: remCalc(-4),
    },
    iconCircle: {
      ...iconCircleBorder,
      ...iconCircleSize,
      borderColor: theme.colors.error,
    },
    iconCircleBeforeLabel: {
      marginRight: remCalc(8),
    },
  });
};

export const hiddenProgressStyles: CreateStyleSheet<ProgressStyle> = () => {
  return StyleSheet.create({
    progress: {
      display: "none",
    },
  });
};

export const primaryProgressStyles: CreateStyleSheet<ProgressStyle> = (
  theme
) => {
  return StyleSheet.create({
    progress: {
      color: theme.colors.white,
      padding: 0,
    },
  });
};

export const secondaryProgressStyles: CreateStyleSheet<ProgressStyle> = (
  theme
) => {
  return StyleSheet.create({
    progress: {
      color: theme.colors.primary,
      padding: 0,
    },
  });
};

export const tertiaryProgressStyles: CreateStyleSheet<ProgressStyle> = (
  theme
) => {
  return StyleSheet.create({
    progress: {
      color: theme.colors.error,
      padding: 0,
    },
  });
};
