// Describing the shape of the system's slice of state
export interface ClientConfigsState {
  data: {
    mainNavigation: mainNavigation;
  };
}

export interface mainNavigation {
  properties: {
    activeColor?: string;
    copy: {
      documents?: string;
      menu?: string;
      profile?: string;
      reports?: string;
    };
  };
}

export const GET_CLIENT_CONFIG = {
  FAILURE: "GET_CLIENT_CONFIG_FAILURE",
  REQUEST: "GET_CLIENT_CONFIG_REQUEST",
  SUCCESS: "GET_CLIENT_CONFIG_SUCCESS",
};

export const GET_CLIENT_GROUP_CONFIG = {
  FAILURE: "GET_CLIENT_GROUP_CONFIG_FAILURE",
  REQUEST: "GET_CLIENT_GROUP_CONFIG_REQUEST",
  SUCCESS: "GET_CLIENT_GROUP_CONFIG_SUCCESS",
};

export const UPDATE_CLIENT_GROUP_CONFIG = {
  FAILURE: "UPDATE_CLIENT_GROUP_CONFIG_FAILURE",
  REQUEST: "UPDATE_CLIENT_GROUP_CONFIG_REQUEST",
  SUCCESS: "UPDATE_CLIENT_GROUP_CONFIG_SUCCESS",
};

export interface ClientGroupConfig {
  id: string;
  label: string;
  value: string;
  val?: string; // Looks like `value` was changed to `val` -- GK
  visibleId?: string;
}

export type DefaultGroupConfig = {
  id: number;
  label: string;
  // properties?: any;
  val: string;
  visibleId: string;
}

interface getClientConfig {
  keyName: string;
  response?: any;
  type: string;
}

export type ClientConfigsActionTypes = getClientConfig;
