import React from "react";
import { useMediaQuery } from "react-responsive";

import { DocumentVM } from "store/documents/types";
import ViewDefenses from "components/clientAdmin/documents/ViewDefenses";
import { devices } from "themes/mediaQueries";

import { ReadOnlyMap } from "./Map";
import { ReadOnlyOEs } from "./OEs";
import { ReadOnlyResponses } from "./Responses";

import * as S from "./styles";
import { getFlattenedQuestions } from "../../FormController/helpers";
import { calculateZoom, getDistanceBetweenGeoLocations, getMidPoint } from "../../../util/geo";
import { Viewport } from "../../map/types";
import { LocationMarker } from "../../FormController/types";
import { MapWidgetQuestionDTO } from "../../../store/forms/types";
import Toast from "../../common/Toast";
import Responses from "../../clientAdmin/documents/drawer/ResponseList";
import { RehuddleInfo } from "components/FormController/components/RehuddleInfo";
import ViewSignatures from "components/clientAdmin/documents/ViewSignatures";
import { remCalc } from "../../../themes/helpers";

interface Props {
  document: DocumentVM;
}

export function ReadOnlyDoc ({ document }: Props) {
  const [viewport, setViewport] = React.useState<Viewport | undefined>();

  const questions = getFlattenedQuestions(document.form.sections);
  const oes = document.operationalExperiences;

  /** Map of question ids to map widget questions associated with them */
  const mapWidgetQuestions = document.form.sections.reduce((result: Map<number, MapWidgetQuestionDTO>, s) => {
    for (const i of s.items) {
      if (i.type === "WIDGET" && i.subType === "MAP" && i.questions?.length) {
        for (const q of i.questions) {
          result.set(q.questionRootId, q as MapWidgetQuestionDTO);
        }
      }
    }
    return result;
  }, new Map());

  const responseIds = document.responses?.map((r) => r.associatedId) || [];

  /** Markers for location responses present in the document */
  const markers = questions.reduce((result: Array<LocationMarker>, q) => {
    if (q.subType === "LOCATION" && document.responses) {
      const qResponse = document.responses.find((r) => r.questionId === q.id);
      if (qResponse?.associatedLocation) {
        result.push({
          onUpdate: () => undefined,
          color: mapWidgetQuestions.get(q.id)?.iconColor || undefined,
          geolocation: {
            latitude: qResponse?.associatedLocation?.latitude,
            longitude: qResponse?.associatedLocation?.longitude
          },
        });
      }
    }
    return result;
  }, []);

  /** Should the map be rendered */
  const renderMap = markers.length > 0;

  /* get map viewport if form has location responses */
  if (renderMap && !viewport) {
    const responseGeolocations = markers.map((r) => ({
      lat: r.geolocation?.latitude || null,
      lng: r.geolocation?.longitude || null
    }));
    const midpoint = getMidPoint(responseGeolocations);
    const distance = getDistanceBetweenGeoLocations(responseGeolocations);
    const zoom = calculateZoom(distance);
    if (midpoint.lat && midpoint.lng && zoom) {
      setViewport(() => {
        return {
          width: "100%",
          height: "100%",
          center: {
            latitude: midpoint.lat || 0, // TODO this is nonsensical but I had to do it to stop TS from complaining -JA
            longitude: midpoint.lng || 0
          },
          zoom,
        };
      });
    }
  }

  const isDesktop = useMediaQuery({
    minWidth: devices.minDesktop,
  });

  return (
    <S.ReadOnly
      renderMap={renderMap}
      isDesktop={isDesktop}
    >
      <RehuddleInfo id={document.id} isRehuddle={document.isRehuddle} parentId={document.parentId} padding="30px 0 20px 0"/>

      <div style={{ height: remCalc(75) }}/> {/* offset the document by height of toast */}
      <Toast visible variant="read only">
        {`${document.title} by ${document.owner.name} - Read-Only`}
      </Toast>

      {renderMap && viewport && (
        <ReadOnlyMap
          viewport={viewport}
          mapMarkers={markers}
        />
      )}

      {/* TODO mobile responsiveness should be easy here, may not need multiple components for responses -JA */}
      {!isDesktop ? (
        <Responses document={document}/>
      ) : (
        <ReadOnlyResponses
          document={document}
          questions={questions}
        />
      )}

      {oes && (
        <ReadOnlyOEs oes={oes}/>
      )}

      <ViewDefenses
        documentId={document.id}
        responseIds={responseIds}
        document={document}
      />

      <ViewSignatures
        participants={document.participants}
      />

    </S.ReadOnly>
  );
}
