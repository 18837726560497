import Autocomplete, {
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
} from "@material-ui/lab/Autocomplete";
import {
  AutocompleteInputChangeReason,
  Value,
} from "@material-ui/lab/useAutocomplete";
import { ReactComponent as ArrowDropDown } from "assets/svg/arrow_drop_down.svg";
import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import { joinClassNames } from "themes/helpers";
import useDebounce from "util/hooks/useDebounce";
import { Listbox } from "./Listbox";
import { SelectionList } from "./SelectionList";
import styles from "./styles.module.scss";
import { TextField, TextFieldRef } from "./TextField";
import usePrevious from "util/hooks/usePrevious";
import Label from "components/common/form/Label";
import * as SS from "components/common/form/Select/styles"; // Select's styles

export interface OptionContent {
  id: string;
  label: string;
}

type SearchSelectProps<Multiple extends boolean | undefined> = {
  canSelectMultiple?: Multiple;
  className?: string;
  handleChange: (newValue: SearchSelectValue<Multiple>) => void;
  handleUpdateSearch?: (inputValue: string | null) => void;
  hasError?: boolean;
  error?: string;
  touched?: boolean;
  label?: string;
  id?: string;
  name: string;

  /**
   * Enables debouncing for update search events. This is forced to be true when
   * pagination is used. Specifically, when 'onLoadMore' is set.
   */
  isApiSearch?: boolean;

  isClearDisabled?: boolean;
  isDisabled?: boolean;

  /** 'isEmptyAllowed' only prevents empty fields for single selection. */
  isEmptyAllowed?: boolean;

  isFinalPage?: boolean;
  isLoadingMore?: boolean;
  minCharsForSearch?: number;
  onLoadMore?: () => void;
  options: OptionContent[];
  placeholder?: string;
  value: SearchSelectValue<Multiple>;
};

type SearchSelectValue<Multiple> = Multiple extends false | undefined
  ? OptionContent | null
  : OptionContent[];

export function SearchSelect<Multiple extends boolean | undefined>({
  canSelectMultiple,
  className,
  handleChange,
  handleUpdateSearch,
  hasError,
  touched,
  error,
  id,
  isApiSearch,
  isClearDisabled,
  isDisabled,
  isEmptyAllowed = true,
  isFinalPage,
  isLoadingMore,
  minCharsForSearch = 3,
  onLoadMore,
  options,
  placeholder,
  value,
  name,
  label
}: SearchSelectProps<Multiple>) {
  const textField = useRef<TextFieldRef | null>(null);
  const [inputValue, setInputValue] = useState("");
  const isInputValueControlled = !!handleUpdateSearch;
  const isLoadMoreEnabled = onLoadMore !== undefined;
  const isFilterEnabled = !isLoadMoreEnabled;
  const shouldDebounceSearch = isApiSearch || isLoadMoreEnabled;
  const isOneOfMultipleSelected =
    canSelectMultiple && (value as OptionContent[]).length > 0;
  const debouncedHandleUpdateSearch = useDebounce({
    delayAmount: 500,
    method: (query) => handleUpdateSearch && handleUpdateSearch(query),
  });
  const [isLoadingSensorVisible, setIsLoadingSensorVisible] = useState(false);
  const priorOptions = usePrevious(options);

  const localHandleChange = (
    event: ChangeEvent<{}>,
    value: Value<OptionContent, Multiple, boolean, false>,
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<OptionContent>
  ) => {
    if (reason === "remove-option") {
      // Ignore back space to remove selections. Since our tags are displayed
      // above the caret, this control method wasn't intuitive.
      return;
    }
    handleChange && handleChange(value);
  };

  const handleInputChange = (
    event: React.ChangeEvent<{}>,
    value: string,
    reason: AutocompleteInputChangeReason
  ) => {
    setInputValue(value);
    const handleUpdate = shouldDebounceSearch
      ? debouncedHandleUpdateSearch
      : handleUpdateSearch;
      
    if (handleUpdate) {
      handleUpdate(value.length >= minCharsForSearch ? value : null);
    }
  };

  useEffect(() => {
    if (priorOptions && options.length !== priorOptions.length) {
      if (isLoadingSensorVisible && onLoadMore) {
        // Assume that we just finished loading, because options changed. if the
        // loading sensor is still visible, we won't receive a visibility change
        // event. So, trigger a load manually.
        onLoadMore();
      }
    }
  }, [isLoadingSensorVisible, options, priorOptions]);

  useEffect(() => {
    if (value && "label" in value && !canSelectMultiple) {
      setInputValue(value.label);
    }
  }, [value]);

  return (
    <>
      {label && (
        <Label htmlFor={name}>
          {label}
        </Label>
      )}
      <Autocomplete
        ChipProps={{
          classes: {
            root: styles.chipRoot,
          },
        }}
        classes={{
          endAdornment: styles.endAdornment,
          listbox: styles.listbox,
          noOptions: styles.noOptions,
          option: styles.option,
          paper: styles.paper,
        }}
        className={className}
        clearOnBlur={true}
        disabled={isDisabled}
        disableClearable={isClearDisabled || !isEmptyAllowed}
        disableListWrap={true}
        filterOptions={
          isFilterEnabled ? undefined : (options, state) => options
        }
        getOptionLabel={(option) => option.label}
        getOptionSelected={(option, value) => option.id === value.id}
        id={id}
        inputValue={isInputValueControlled ? inputValue : undefined}
        ListboxComponent={isLoadMoreEnabled ? Listbox : undefined}
        ListboxProps={{
          isFinalPage,
          isLoadingMore,
          onChangeVisibility: setIsLoadingSensorVisible,
          onLoadMore,
        }}
        multiple={canSelectMultiple}
        onChange={localHandleChange}
        onInputChange={isInputValueControlled ? handleInputChange : undefined}
        options={options}
        popupIcon={
          <ArrowDropDown
            className={joinClassNames(
              styles.popupIndicator,
              isDisabled && styles.popupIndicatorDisabled
            )}
          />
        }
        renderInput={(params) => (
          <TextField
            hasError={!!error}
            isExpanded={isOneOfMultipleSelected}
            placeholder={placeholder}
            ref={textField}
            {...params}
          />
        )}
        renderTags={(value, getTagProps) => (
          <SelectionList
            className={styles.selectionList}
            handleChangeSelection={
              handleChange as (selectedOptions: OptionContent[]) => void
            }
            inputElement={textField.current}
            options={value as OptionContent[]}
          />
        )}
        value={value}
      />
      {error && <SS.ErrorText>{error}</SS.ErrorText>}
    </>
  );
}
