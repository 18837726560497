interface TutorialType {
  active?: boolean;
  keyName?: string;
  participantId?: number;
  properties: {
    showTutorial?: boolean;
  };
}

export interface ParticipantConfigsState {
  data: {
    tutorial?: TutorialType;
  };
}

export const GET_PARTICIPANT_CONFIG = {
  FAILURE: "GET_PARTICIPANT_CONFIG_FAILURE",
  REQUEST: "GET_PARTICIPANT_CONFIG_REQUEST",
  SUCCESS: "GET_PARTICIPANT_CONFIG_SUCCESS",
};

export const POST_PARTICIPANT_CONFIG = {
  FAILURE: "POST_PARTICIPANT_CONFIG_FAILURE",
  REQUEST: "POST_PARTICIPANT_CONFIG_REQUEST",
  SUCCESS: "POST_PARTICIPANT_CONFIG_SUCCESS",
};

type getParticipantConfig = {
  active?: boolean;
  keyName: string;
  participantId?: number;
  properties?: any;
  response?: any;
  type: string;
};

export type ParticipantConfigsActionTypes = getParticipantConfig;
