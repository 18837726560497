import React, { useMemo } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";

import {
  CloseDrawerIconWrapper as CloseIconWrapper,
  SidebarDrawerHeader as Header,
  SidebarDrawerWrapper as HeaderWrapper,
} from "components/screens/documents/styles";
import ListSimple from "components/common/ListSimple";
import { AppState } from "store";
import { Chevron } from "components/common/TableUI/Pagination/styles";
import { FormDTO, FormTypeDTO } from "store/forms/types";
import { NavLabel } from "components/DocumentSidebarDrawer/styles";

import { StyledLinkCaretRight } from "components/common/styled/StyledLinkCaretRight";
import StickyHeader from "components/common/Drawer/components/StickyHeader";
import DrawerContentWrapper from "components/common/Drawer/components/DrawerContentWrapper";

export interface Props {
  onClose: () => void;
  onSelectFormTemplate: (type: FormDTO) => void;
  selectedFormType: FormTypeDTO | null;
  title?: string;
}

const Body = styled.div`
  text-align: left;
`;

const FormTemplatesDrawerContent = ({
  selectedFormType,
  onClose,
  onSelectFormTemplate,
  title = "Start Related Form",
}: Props) => {
  // const [OEState, OEDispatchState] = useReducer(OEReducer, initialState);
  const formTemplates = useSelector(
    (state: AppState) => state.forms?.data?.allForms
  );

  const templateList = useMemo(() => {
    if (formTemplates && selectedFormType) {
      return formTemplates
        .filter((e) => e.type.id === selectedFormType.id)
        .sort((a, b) =>
          a.name.localeCompare(b.name, undefined, { sensitivity: "base" })
        )
        .map((template: FormDTO) => ({
          primary: <NavLabel>{template.name}</NavLabel>,
          rightSideItem: <StyledLinkCaretRight />,
          onClick: () => onSelectFormTemplate(template),
        }));
    }
    return false;
  }, [formTemplates]);

  return (
    <>
      <StickyHeader title={title} onClick={onClose} chevronRight={false} />
      {/* Content */}
      <Body>
        <ListSimple listItems={templateList || []} />
      </Body>
    </>
  );
};

export default FormTemplatesDrawerContent;
