import React from "react";
import styled from "styled-components";

import { QuestionDTO, SafetyRatingWidgetDTO } from "store/forms/types";
import { DocumentVM } from "store/documents/types";
import { createMarkup } from "util/index";

import { SmallText, H3 } from "../../styles";

import * as S from "./styles";
import useGroupTerm from "util/hooks/whiteLabel/useGroupTerm";
import moment from "moment";
import { parseTime } from "util/dateUtilities";

const QuestionAndAnswer = styled.div.attrs({
  className: "text-left px-3x py-4",
})`
  border-bottom: solid 1px ${({ theme }) => theme.colors.lightGrey};
  align-self: center;
  width: 100%;
`;
const ResponseText = styled.div`
  color: ${(props) => props.theme.masterColors.darkGrey};
`;

function toDisplayResponse(answer: string, questionType: string): string {
  switch (questionType) {
    case "YES_OR_NO":
      return answer === "true" ? "Yes" : "No";
    case "DATE":
      return moment(answer).format("YYYY-MM-DD");
    case "TIME":
      const timeMoment = parseTime(answer);
      return timeMoment.isValid() ? timeMoment.local().format("HH:mm") : answer;
    default:
      return answer;
  }
}

interface MinResponse {
  answer: string;
  comments?: string | null;
}

function ReadOnlyQuestionResponses({
  document,
  question,
  isRatingsParentQuestion = false,
}: {
  document: DocumentVM;
  question: QuestionDTO;
  isRatingsParentQuestion: boolean;
}) {
  const responses: MinResponse[] =
    document.responses?.filter((r) => r.questionId === question.id) || [];

  if (responses.length === 0) {
    return null; // PPP-1970 requested that we don't display N/A responses of a question.
  }

  const formatQuestionTitle = (
    question: QuestionDTO,
    isRatingsParentQuestion: boolean
  ): string => {
    if (isRatingsParentQuestion && !question.title) {
      return "Which areas do you want to review?";
    } else return question.title;
  };

  return (
    <QuestionAndAnswer key={question.id}>
      <H3 className="mb-3">
        {formatQuestionTitle(question, isRatingsParentQuestion)}
      </H3>
      {responses.map((r) => (
        <div key={r.answer}>
          <div className="d-flex">
            <SmallText className="mr-4">Response</SmallText>
            <ResponseText>
              {toDisplayResponse(r.answer, question.subType)}
            </ResponseText>
          </div>
          {r.comments && (
            <div className="d-flex mb-2">
              <SmallText className="mr-4">Comment</SmallText>
              <ResponseText>{r.comments}</ResponseText>
            </div>
          )}
        </div>
      ))}
    </QuestionAndAnswer>
  );
}

const ResponseList = ({ document }: { document: DocumentVM }) => {
  const operationalExperiences = document.operationalExperiences || []; // OEs
  const items: QuestionDTO[] | undefined = document.form?.sections.reduce(
    (result: QuestionDTO[], section) => {
      section.items.forEach((item) => {
        if (item.type === "QUESTION") {
          result.push(item as QuestionDTO);
        }
      });
      return result;
    },
    []
  );

  const safeyRatingWidgets: SafetyRatingWidgetDTO[] = document.form?.sections.reduce(
    (result: SafetyRatingWidgetDTO[], section) => {
      section.items.forEach((item) => {
        if (item.subType === "SAFETY_RATING") {
          result.push(item as SafetyRatingWidgetDTO);
        }
      });
      return result;
    },
    []
  );

  function isRatingsParentQuestion(
    item: QuestionDTO,
    ratingsWidgets: SafetyRatingWidgetDTO[]
  ) {
    return (
      ratingsWidgets.length > 0 &&
      ratingsWidgets.some((widg) => item.rootId === widg.parentQuestionRootId)
    );
  }

  // Group config terms
  const operationExperiencesTerm = useGroupTerm(
    "operationalExperiences",
    "noun",
    "plural",
    "Operational Experiences"
  );

  return (
    <S.ResponseList>
      {items?.map((question) => (
        <ReadOnlyQuestionResponses
          question={question}
          document={document}
          isRatingsParentQuestion={isRatingsParentQuestion(
            question,
            safeyRatingWidgets
          )}
        />
      ))}
      {/* OEs */}
      {operationalExperiences && operationalExperiences.length > 0 && (
        <QuestionAndAnswer id="OEs">
          <H3 className="mb-3">{operationExperiencesTerm}</H3>
          {operationalExperiences.map((OE) => (
            <div key={`op_ex_${OE.id}`} className="d-flex">
              <SmallText className="mr-4">Included</SmallText>
              <S.OEInfo>
                <S.OETitle>{OE.incident}</S.OETitle>
                <S.OEDescription
                  dangerouslySetInnerHTML={createMarkup(OE.description)}
                />
              </S.OEInfo>
            </div>
          ))}
        </QuestionAndAnswer>
      )}
    </S.ResponseList>
  );
};

export default ResponseList;
