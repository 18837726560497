import { CircularProgress } from "@material-ui/core";
import ButtonBase from "@material-ui/core/Button";
import ListItemBase from "components/common/ListItem";
import { MUIListItem } from "components/common/ListItem/styles";
import { SearchInput as SearchInputBase } from "components/forms/SearchInput";
import React from "react";
import styled from "styled-components";
import { remCalc } from "themes/helpers";
import mediaQueries from "themes/mediaQueries";

const ListItem = styled(ListItemBase)<{ disabled?: boolean }>`
  ${MUIListItem} {
    background: ${({ theme, disabled }) => disabled && theme.colors.lightGrey};
    cursor: ${({ disabled }) => disabled && "not-allowed"};
  }
  margin-bottom: 6px;
`;

// Main wrapper elements

/**
 * wrapper div which contains the title and
 * possibly a close button
 */
const HeaderRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const HeaderCloseButton = styled.button`
  background: none;
  border: none;
  padding-bottom: ${remCalc(10)};
`;
const HeaderCloseIcon = styled.i``;

const Header = styled.h1`
  font-family: "Roboto";
  font-size: 20px;
  margin-bottom: 16px;
  color: ${({ theme }) => theme.colors.header};
`;

const NewForm = styled.div`
  text-align: left;
`;

// SET FORM ID

const SetFormValue = styled.div`
  -ms-overflow-style: none;
  margin-top: 24px;

  ::-webkit-scrollbar {
    display: none;
  }
`;
const FormValueLabel = styled.h2`
  font-size: 16px;
  color: ${({ theme }) => theme.colors.header};
  margin-bottom: 4px;
  font-family: "Roboto";
`;

const Button = styled(({ ...otherProps }) => (
  <ButtonBase {...otherProps} classes={{ root: "root" }} />
))`
  &&.root {
    background-color: ${({ theme }) => theme.colors.primary};
    color: ${({ theme }) =>
      theme.colors.iconFill}; // needs its own key? -Trevor
  }
`;

const GhostButton = styled(
  ({ isLoading, selectedWorkOrderId, ...otherProps }) => (
    <ButtonBase
      {...otherProps}
      classes={{ outlined: "outlined", fullWidth: "fullWidth", root: "root" }}
    />
  )
)`
  &&.root {
    border: 1px solid ${({ theme }) => theme.colors.primary};
    color: ${({ theme }) => theme.colors.primary};
    margin-top: 16px;

    ${({ selectedWorkOrderId }) =>
      selectedWorkOrderId &&
      `
      margin-bottom: 9px;
    `}
  }
`;

const LoadingAdornment = styled(({ ...otherProps }) => {
  return (
    <CircularProgress
      {...otherProps}
      classes={{
        root: "root",
      }}
      size={16}
      color="inherit"
    />
  );
})`
  &&.root {
    margin-left: ${({ after }) => after && "0.6rem"};
    margin-right: 0.6em;
  }
`;

// FORM RESULTS

const ResultLabel = styled.div`
  display: flex;
  flex-direction: column;
`;

export interface ResultLabelItemProps {
  strong?: boolean;
}

const ResultLabelItem = styled.span<ResultLabelItemProps>`
  ${({ strong }) =>
    strong &&
    `
    font-weight: 900;
    color: ${({ theme }) => theme.colors.black};
  `}
`;

const SearchInput = styled(SearchInputBase)`
  margin-bottom: 16px;
`;
const WorkOrderWrapper = styled.div`
  overflow: auto;
  max-height: 550px;

  @media (${mediaQueries.maxTablet}) {
    overflow: auto;
    max-height: 250px;
    margin-bottom: 10px;
  }
`;
export {
  Button,
  FormValueLabel,
  GhostButton,
  Header,
  HeaderCloseButton,
  HeaderCloseIcon,
  HeaderRow,
  ListItem,
  LoadingAdornment,
  NewForm,
  ResultLabel,
  ResultLabelItem,
  SearchInput,
  SetFormValue,
  WorkOrderWrapper,
};
