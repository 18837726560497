import React, { useContext } from "react";
import MediaQuery from "react-responsive";
import { useHistory } from "react-router";

import { devices } from "themes/mediaQueries";
import { NewFormVariant } from "components/forms/NewForm/NewForm";
import { StyledDrawer } from "components/common/Drawer";
import NewForm from "components/forms/NewForm";
import BottomNavigationContext from "components/dashboard/DashboardBottomNavigation/Context";

import * as S from "./styles";

export interface DashboardDrawerContentProps {
  handleClose: () => void;
  variant: NewFormVariant;
}

// first level within **DashboardDrawer** which has the `Handle`, and `NewForm` elements
const DashboardDrawerContent = ({
  handleClose,
  variant,
}: DashboardDrawerContentProps) => {
  const history = useHistory();
  return (
    <S.DrawerContent>
      <NewForm
        variant={variant}
        title="New Document"
        history={history}
        handleClose={handleClose}
      />
    </S.DrawerContent>
  );
};

/**
 * Contains Drawer elements used to start a new Document
 */
const DashboardDrawer = () => {
  const { drawerIsVisible, setDrawerIsVisible } = useContext(
    BottomNavigationContext
  );
  const handleClose = () => setDrawerIsVisible(false);
  return (
    <>
      {/* MOBILE */}

      <MediaQuery maxWidth={devices.maxPhone}>
        <StyledDrawer
          anchor="right"
          isOpen={drawerIsVisible}
          id="DashboardDrawer"
          content={
            <DashboardDrawerContent
              variant="mobile"
              handleClose={handleClose}
            />
          }
          onClose={handleClose}
          onOpen={() => undefined}
        />
      </MediaQuery>

      {/* TABLET */}

      <MediaQuery minWidth={devices.iPad} maxWidth={devices.desktop}>
        <StyledDrawer
          anchor="right"
          isOpen={drawerIsVisible}
          id="DashboardDrawer"
          content={
            <DashboardDrawerContent
              variant="mobile"
              handleClose={handleClose}
            />
          }
          onClose={handleClose}
          onOpen={() => undefined}
        />
      </MediaQuery>

      {/* DESKTOP / FULL VIEW */}

      <MediaQuery minWidth={devices.desktop}>
        {drawerIsVisible && (
          <StyledDrawer
            handleBar={false}
            anchor="right"
            isOpen={drawerIsVisible}
            id="DashboardDrawer"
            content={
              <DashboardDrawerContent
                variant="desktop"
                handleClose={handleClose}
              />
            }
            onClose={handleClose}
            onOpen={() => undefined}
          />
        )}
      </MediaQuery>
    </>
  );
};

export default DashboardDrawer;
