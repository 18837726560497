import React, { useCallback } from "react";
import { useDispatch } from "react-redux";

import { logout } from "store/system/actions";

import * as S from "./styles";

interface Props {
  spacing?: boolean;
  marginTop?: string;
}

const Logout = ({ spacing, marginTop }: Props) => {
  const dispatch = useDispatch();

  const handleLogout = useCallback(() => {
    return dispatch(logout());
  }, [dispatch]);

  return (
    <S.Logout spacing={spacing} marginTop={marginTop}>
      <S.Button onClick={handleLogout}>
        <S.Label>LOG OUT</S.Label>
      </S.Button>
    </S.Logout>
  );
};

export default Logout;
