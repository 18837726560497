import React, { useEffect } from "react";
import { FormikTouched } from "formik";

import SearchableSelect from "components/common/form/SearchableSelect/SearchableSelect";
import { ParticipantProps } from "store/dataSource/types";

import { ShareFormValues } from "../../types";
import { Pagination } from "../../../../../store/common/types";
import { buildFilteredEndpoint } from "../../../../../store/common/apiUtilities";
import { getApiHost } from "../../../../helpers";

interface PaginatedParticipants extends Pagination {
  content: Array<ParticipantProps>;
}

const apiRoot = `${getApiHost()}/api/`;

// tired of dealing with data source redux
export async function getParticipants(
  page,
  query,
  authToken,
): Promise<PaginatedParticipants> {
  const url = apiRoot + buildFilteredEndpoint("participants", {
    sort: "fullName",
    size: 20,
    page,
    query,
  });

  const res = await fetch(url, {
    method: "GET",
    mode: "cors",
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${authToken}`,
    },
  });

  if (!res.ok) {
    throw Error("problem getting data source");
  }
  return res.json();
}

interface Props {
  touched: FormikTouched<ShareFormValues>;
  selectMember: (id: number, fullName: string) => void;
  authToken: string | null;
}

interface Suggestion {
  value: number;
  label: string;
}

// custom filter logic to handle matching name with full name (comma and reversed)
function filterOption({ label }: Suggestion, query: string): boolean {
  label = label.toLowerCase();
  query = query.toLowerCase();
  for (const part of query.split(" ")) {
    if (!label.includes(part)) {
      return false;
    }
  }
  return true;
}

const AddMemberDropdown = ({
  selectMember,
  touched,
  authToken,
}: Props) => {

  // request parameters
  const [page, setPage] = React.useState<number>(0);
  const [query, setQuery] = React.useState<string>("");
  const [debouncedQuery, setDebouncedQuery] = React.useState<string>("");

  // request state info
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [isLastPage, setIsLastPage] = React.useState<boolean>(false);

  // results to display
  const [suggestions, setSuggestions] = React.useState<Suggestion[]>([]);

  useEffect(
    () => {
      // Update debounced value after delay
      const handler = setTimeout(() => {
        setDebouncedQuery(query);
        setPage(0);
      }, 250);
      return () => {
        clearTimeout(handler);
      };
    },
    [query],
  );

  // api call
  React.useEffect(() => {
    setIsLoading(true);
    getParticipants(page, debouncedQuery, authToken)
      .then((res) => {
        setIsLastPage(res.last ?? true);

        const newSuggestions = res.content.map((participant) => ({
          value: participant.id,
          label: participant.fullName,
        }));

        setSuggestions((s) => res.first ? newSuggestions : s.concat(newSuggestions));
      })
      .catch((e) => console.error("failed to fetch participants ", e))
      .finally(() => setIsLoading(false));
  }, [page, debouncedQuery, authToken]);

  return (
    <>
      <SearchableSelect
        label="" // Hard coding label to "" since we are iterating over form state above
        noMargins
        name="members"
        onChange={(id: number, value: string) => {
          return selectMember(id, value);
        }}
        onInputChange={setQuery}
        placeholder={"Add member"}
        preventLocalSetValue
        suggestions={suggestions}
        touched={touched["members"]}
        value={null}
        onMenuScrollToBottom={() => !isLoading && !isLastPage && setPage((p) => p + 1)}
        isLoading={isLoading}
        filterOption={filterOption}
      />
    </>
  );
};

export default AddMemberDropdown;
