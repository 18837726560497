import * as R from "ramda";
import React from "react";
import styled from "styled-components";

import CheckboxBase from "@material-ui/core/Checkbox";
import FormControlBase from "@material-ui/core/FormControl";
import InputLabelBase from "@material-ui/core/InputLabel";
import MenuItemBase from "@material-ui/core/MenuItem";
import OutlinedInputBase from "@material-ui/core/OutlinedInput";
import SelectBase from "@material-ui/core/Select";

import { remCalc, toRgba } from "themes/helpers";

export const CloseButton = styled.button`
  align-items: center;
  background: transparent;
  border: none;
  display: flex;
  margin-left: 0.5rem;
  padding: 0;
`;

export const ButtonIcon = styled.i<{ iconSize?: number }>`
  color: ${({ theme }) => toRgba(theme.colors.black, 0.5)};
  font-size: ${({ iconSize }) => remCalc(iconSize || 24)};
`;

const SelectInput = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 15px;
`;

const Button = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 0.875rem;
  font-weight: 500;
  letter-spacing: 1.25px;
  border-radius: 0.25em;
  border: 1px solid ${({ theme }) => theme.colors.lightGrey};
  margin: 0 1rem 0.5rem 0;
  padding: 0.5rem;
  text-transform: capitalize;
  background-color: transparent;
  color: ${({ theme }) => theme.colors.primary};
  width: fit-content;
`;

const FormControl = styled(({ ...otherProps }) => (
  <FormControlBase
    {...otherProps}
    classes={{
      root: "root",
    }}
  />
))`
  &&.root {
  }
`;

const InputLabel = styled(({ ...otherProps }) => <InputLabelBase {...otherProps} classes={{ root: "root" }} />)`
  &&.root {
    color: ${({ theme }) => theme.colors.formFieldLabel};
    margin-bottom: 8px;
    font-size: 1.25rem;
    font-weight: 400;
  }
`;

const ErrorLabel = styled(({ ...otherProps }) => <InputLabelBase {...otherProps} />)``;

const MenuItem = styled(({ ...otherProps }) => (
  <MenuItemBase
    classes={{
      selected: "selected",
    }}
    {...otherProps}
  />
))`
  &&.selected {
    background-color: ${(props) => props.theme.colors.activeFill};
    font-weight: 500;
  }
`;
const MenuItemMulti = styled(({ ...otherProps }) => (
  <MenuItemBase
    classes={{
      selected: "selected",
    }}
    {...otherProps}
  />
))`
  &&.selected {
    background-color: ${(props) => props.theme.colors.white};
  }
`;
const Checkbox = styled(({ ...otherProps }) => (
  <CheckboxBase
    classes={{
      checked: "checked",
      root: "root",
    }}
    disableRipple
    disableFocusRipple
    {...otherProps}
  />
))`
  &&.root {
    &:hover {
      background-color: transparent;
    }
  }
  &&.checked {
    color: ${(props) => props.theme.colors.primary};
    &:hover {
      background-color: transparent;
    }
  }
`;

const OutlinedInput = styled(({ ...otherProps }) => (
  <OutlinedInputBase
    {...otherProps}
    classes={{
      root: "root",
      input: "input",
      notchedOutline: "notchedOutline",
    }}
  />
))`
  && .notchedOutline {
    border-color: ${({ theme }) => theme.colors.formFieldBorder};
    background: ${({ theme }) => theme.colors.formFieldBackground};
  }
`;

// Sets css properties based on prop value
export const selectHasValue = (value, ifTrue, ifFalse) => {
  if (typeof value === "number") {
    if (value > -1) return ifTrue;
  } else if (value && value.length > 0) return ifTrue;
  return ifFalse;
};

const Select = styled(({ className, customColor, hasInitialValue, value, ...otherProps }) => {
  return (
    <SelectBase
      {...otherProps}
      classes={{
        select: `${className} select`,
        icon: "icon",
        // selectMenu: "selectMenu",
      }}
      value={value}
      MenuProps={{
        classes: {
          paper: `${className} paper`,
        },
        ...otherProps.MenuProps,
      }}
    />
  );
})`
  &&.paper {
    max-height: 18.5rem;
  }
  &&.select {
    color: ${({ customColor, theme }) => R.path(customColor, theme) || theme.colors.darkGrey};
    font-weight: ${({ hasInitialValue }) => !hasInitialValue ? 500 : 400};
    padding-right: 30px;
    :focus {
      background-color: ${({ theme }) => theme.colors.white};
    }
  }
  && .icon {
    color: ${({ customColor, theme }) => R.path(customColor, theme) || theme.colors.darkGrey};
    font-weight: ${({ hasInitialValue }) => !hasInitialValue ? 500 : 400};
  }
`;

export {
  Button,
  Checkbox,
  FormControl,
  InputLabel,
  MenuItem,
  MenuItemMulti,
  OutlinedInput,
  Select,
  SelectInput,
  ErrorLabel,
};
