import { GET_FORM_STATS, formStatsState, formStatsActionTypes } from "./types";
import { setLoadingOrErrorFlag } from "store/common/reducerUtilities";

const initialState: formStatsState = {
  loading: {},
  errors: {},
  data: {},
};

export function formStatsReducer(state = initialState, action: formStatsActionTypes): formStatsState {
  const { response, type } = action;

  switch (type) {
    case GET_FORM_STATS.REQUEST:
      return {
        ...state,
        errors: setLoadingOrErrorFlag(state.errors, action.formId, "GET", false),

        loading: setLoadingOrErrorFlag(state.loading, action.formId, "GET", true),
      };

    case GET_FORM_STATS.SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          [action.formId]: response,
        },
        loading: setLoadingOrErrorFlag(state.loading, action.formId, "GET", false),
      };

    case GET_FORM_STATS.FAILURE:
      return {
        ...state,
        errors: setLoadingOrErrorFlag(state.errors, action.formId, "GET", true),
        loading: setLoadingOrErrorFlag(state.loading, action.formId, "GET", false),
      };

    default:
      return state;
  }
}
