import React from "react";
import styles from "./handleBarStyles.module.scss";
import { joinClassNames } from "themes/helpers";

interface HandleBarProps {
  className?: string;
}

export const HandleBar = ({ className }: HandleBarProps) => {
  return (
    <div className={joinClassNames(styles.container, className)}>
      <div className={styles.handleBar}></div>
    </div>
  );
};
