import React from "react";

import Loader from "components/common/Loader";
import { OperationalExperience } from "store/resources/types";

import * as S from "../styles";
import OECard from "./OECard";
import { FCOperationalExperience } from "../OperationalExperiences";

interface Props {
  displayedOEs: Array<FCOperationalExperience>;
  excludeOE: (OE: OperationalExperience) => void;
  includeOE: (OE: OperationalExperience) => void;
  loading: boolean;
}

function RenderOEs ({ displayedOEs, excludeOE, includeOE, loading }: Props) {
  return (
    <Loader loading={loading} height={"525px"}>
      <S.OperationalExperiences>
        {displayedOEs.map((oe) => (
          <OECard
            key={oe.id}
            oe={oe}
            handleIncludeExcludeOE={() => oe.included ? excludeOE(oe) : includeOE(oe)}
            included={oe.included}
            loading={loading}
          />
        ))}
      </S.OperationalExperiences>
    </Loader>
  );
}

export default RenderOEs;
