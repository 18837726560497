import React, { ReactNode } from "react";
import { css, StyleSheet } from "aphrodite/no-important";


interface Props {
  children: ReactNode;
}

const styles = StyleSheet.create({
  wrapper: {
    padding: "18px",
    textAlign: "left",
  }
}); 

const DrawerContentWrapper = ({ children }: Props) => {
  return <div className={css(styles.wrapper)}>
    {children}
  </div>;
};

export default DrawerContentWrapper;
