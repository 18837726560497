import { StyleDeclaration, StyleSheet } from "aphrodite";
import { CreateStyleSheet } from "util/hooks/useThemedStyleSheet";
import { remCalc } from "themes/helpers";
import {
  bodyRegular,
  focusIndicatorOverlapAppear,
  focusIndicatorDisappear,
  activeBorderOverlapAppear,
} from "themes/mixins";

interface DateButtonStyle {
  calendarIcon: StyleDeclaration;
  calendarIconActive: StyleDeclaration;
  dateButton: StyleDeclaration;
  dateButtonActive: StyleDeclaration;
  dateButtonKeyboardFocused: StyleDeclaration;
  dateButtonMouseFocused: StyleDeclaration;
}

export const styles: CreateStyleSheet<DateButtonStyle> = (theme) => {
  return StyleSheet.create({
    calendarIcon: {
      fill: theme.masterColors.darkGreyBlue,
      height: remCalc(24),
      marginRight: remCalc(8),
      width: remCalc(24),
    },
    calendarIconActive: {
      fill: theme.colors.primary,
    },
    dateButton: {
      ...bodyRegular,
      ...focusIndicatorDisappear(theme, ["border-color"]),
      backgroundColor: theme.colors.white,
      borderColor: theme.masterColors.greyBlue,
      borderRadius: remCalc(4),
      borderStyle: "solid",
      borderWidth: "1px",
      color: theme.colors.black,
      display: "inline-flex",
      margin: 0,
      padding: remCalc(16),
    },
    dateButtonActive: {
      ...activeBorderOverlapAppear(theme),
    },
    dateButtonKeyboardFocused: {
      ":focus": {
        ...focusIndicatorOverlapAppear(theme),
        outline: "none",
      },
    },
    dateButtonMouseFocused: {
      transition: "none",
    },
  });
};
