import React from "react";
import ValidationStatusIndicator from "./ValidationStatusIndicator";
import { css } from "aphrodite/no-important";
import styles from "./styles";
import { FBValidationLevel } from "./useValidation";
import { TextButton } from "components/common/Button";

interface Props {
  errorCount: number;
  alertLevel: FBValidationLevel;
  onClick?: () => void;
}

const ValidationStatus = ({ 
  errorCount,
  alertLevel,
  onClick
}: Props) => {
  const s = styles();

  if (!errorCount) return null;

  const text = `${errorCount} ${alertLevel}s`;
  
  return (
    <div className={css(s.validationStatus)}>
      <ValidationStatusIndicator /> 
      {onClick ? (
        <TextButton
          variant="link"
          onClick={onClick}
        >
          {text}
        </TextButton>
      ) : 
        text
      }
    </div>
  );
};

export default ValidationStatus;
