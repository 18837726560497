import React from "react";
import { css } from "aphrodite/no-important";
import { Field, useFormikContext } from "formik";

import {
  FBForm,
  Property,
  FBItem,
} from "components/clientAdmin/formBuilder/types";
import Checkbox from "components/common/form/Checkbox";
import Select from "components/common/form/Select";

import {
  buildWODisplayConditions,
  WORK_ORDER_PREFILL_OPTIONS,
} from "./helpers";
import styles from "../../styles";
import { isFormPublished } from "components/clientAdmin/formBuilder/helpers";

interface PrefillFromWorkOrderProps {
  property: Property;
  item: FBItem; // @todo we should only allow FBQuestion here
  itemPath: string;
}

export function buildConditionFields(answerField?: string) {
  const fieldArray: string[] | undefined = answerField?.split(".");

  if (fieldArray) {
    const fields = fieldArray.reduce(
      (arr: string[], field: string, index: number) => {
        let newValue: string;
        if (field === "undefined") {
          newValue = ".";
        } else {
          newValue = index > 0 ? `${arr[index - 1]}.${field}` : field;
        }
        return [...arr, newValue];
      },
      []
    );

    return fields;
  }
}

const PrefillFromWorkOrder = ({
  property,
  item,
  itemPath,
}: PrefillFromWorkOrderProps) => {
  const { values, setFieldValue } = useFormikContext<FBForm>();

  const s = styles();

  function handleCheckboxChange(e: React.ChangeEvent<HTMLInputElement>) {
    if (e.target.checked) {
      const displayConditions = buildWODisplayConditions(
        item,
        "",
        values.displayConditions
      );
      setFieldValue("displayConditions", displayConditions);
    } else {
      const filteredConditions = values.displayConditions?.filter(
        (dc) =>
          !(
            dc.action === "WORK_ORDER_PREFILL" &&
            dc.targetRootId === item.rootId
          )
      );
      setFieldValue("displayConditions", filteredConditions || []);
    }
  }

  function handlePrefillFieldChange(e: React.ChangeEvent<HTMLInputElement>) {
    // save the display condition to the form values
    const conditions = buildWODisplayConditions(
      item,
      e.target.value,
      values.displayConditions
    );
    setFieldValue("displayConditions", conditions);
  }

  const condition = values.displayConditions?.find(
    (dc) =>
      dc.action === "WORK_ORDER_PREFILL" && dc.targetRootId === item.rootId
  );

  const isPublished = isFormPublished(values.workflowType);

  return (
    <>
      <Field
        as={Checkbox}
        name={property.name}
        label={property.label}
        containerClassName={css(s.checkboxContainer)}
        className={css(s.checkbox)}
        checked={!!condition}
        onChange={handleCheckboxChange}
        disabled={isPublished}
      />
      {!condition ? null : (
        <Select
          name={itemPath + ".workOrderPrefill"}
          value={condition.prefillAnswerField}
          options={WORK_ORDER_PREFILL_OPTIONS}
          onChange={handlePrefillFieldChange}
          disabled={isPublished}
        />
      )}
    </>
  );
};

export default PrefillFromWorkOrder;
