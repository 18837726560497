import {
  DELETE_DOCUMENT,
  DocumentsState,
  GET_DOCUMENT,
  GET_OWNER_IDS,
  GET_PARTICIPANT_DOCUMENTS,
  GET_TEMP_FILTER_DOCUMENTS,
  SEARCH_DOCUMENTS,
  DocumentsActionTypes,
  ParticipantDocumentsType,
} from "./types";
import { setLoadingOrErrorFlag } from "store/common/reducerUtilities";

const initialState: DocumentsState = {
  loading: {
    participantDocuments: {
      GET: false,
      POST: false,
      PUT: false,
      DELETE: false,
    },
    participantDocumentsInfinite: {
      GET: false,
    },
    searchDocuments: {
      GET: false,
    },
    searchDocumentsInfinite: {
      GET: false,
    },
    tempFilterDocuments: {
      GET: false,
    },
    ownerIds: {
      GET: false,
    },
    singleDocument: {},
    deleteDocument: {},
  },
  error: {
    participantDocuments: false,
    searchDocuments: false,
    ownerIds: false,
    singleDocument: {},
    deleteDocument: {},
  },
  data: {
    ownerIds: {
      content: [],
    },
    participantDocuments: {
      content: [],
      empty: true,
      first: true,
      last: true,
      number: 0,
      numberOfElements: 0,
      pageable: {
        sort: {
          empty: true,
          sorted: false,
          unsorted: true,
        },
        offset: 0,
        pageSize: 10,
        pageNumber: 0,
        paged: true,
        unpaged: false,
      },
      size: 0,
      sort: {
        empty: true,
        sorted: true,
        unsorted: true,
      },
      totalElements: 0,
      totalPages: 0,
    },
    searchDocuments: {
      content: [],
      empty: true,
      first: true,
      last: true,
      number: 0,
      numberOfElements: 0,
      pageable: {
        sort: {
          empty: true,
          sorted: false,
          unsorted: true,
        },
        offset: 0,
        pageSize: 10,
        pageNumber: 0,
        paged: true,
        unpaged: false,
      },
      size: 0,
      sort: {
        empty: true,
        sorted: true,
        unsorted: true,
      },
      totalElements: 0,
      totalPages: 0,
    },
  },
};

const addPageToState = (
  whichType: string,
  response: ParticipantDocumentsType,
  loaderKey: string,
  infiniteScrolling: boolean | undefined,
  state: DocumentsState,
  queryString?: string,
) => {
  if (infiniteScrolling) {
    return appendPage(whichType, response, loaderKey, state, queryString);
  } else {
    return replacePage(whichType, response, loaderKey, state, queryString);
  }
};

const appendPage = (
  whichType: string,
  response: ParticipantDocumentsType,
  loaderKey: string,
  state: DocumentsState,
  queryString?: string,
) => {
  // Is response for next page
  // const currentPage: number =
  //   (state.data.participantDocuments &&
  //     state.data.participantDocuments.pageable &&
  //     state.data.participantDocuments.pageable.pageNumber) ||
  //   0;
  // const responsePage: number = (response.pageable && response.pageable.pageNumber) || 0;

  // append
  const loadingState = returnLoadingStatus(false, "GET", loaderKey, state);
  const currentContent = (state.data[whichType] && state.data[whichType].content) || [];
  const newContent = response.content || [];
  return {
    ...state,
    loading: loadingState,
    data: {
      ...state.data,
      [whichType]: {
        ...response,
        content: [...currentContent, ...newContent],
      },
    },
    queryStrings: {
      ...state.queryStrings,
      [whichType]: queryString,
    },
  };

  // else {
  //   return replacePage(whichType, response, loaderKey, state, queryString);
  // }
};

const replacePage = (
  type: string,
  response: ParticipantDocumentsType,
  loaderKey: string,
  state: DocumentsState,
  queryString?: string,
) => {
  // replace
  const loadingState = returnLoadingStatus(false, "GET", loaderKey, state);
  return {
    ...state,
    loading: loadingState,
    data: {
      ...state.data,
      [type]: response,
    },
    queryStrings: {
      ...state.queryStrings,
      [type]: queryString,
    },
  };
};

const returnLoadingStatus = (value: boolean, method: string, type: string, state: DocumentsState) => {
  return {
    ...state.loading,
    [type]: {
      ...state.loading[type],
      [method]: value,
    },
  };
};

export function documentsReducer(state = initialState, action: DocumentsActionTypes): DocumentsState {
  switch (action.type) {
    case GET_PARTICIPANT_DOCUMENTS.REQUEST:
      return {
        ...state,
        error: {
          ...state.error,
          participantDocuments: false,
        },
      };
    case GET_PARTICIPANT_DOCUMENTS.FAILURE:
      return {
        ...state,
        error: {
          ...state.error,
          participantDocuments: true,
        },
      };
    case GET_PARTICIPANT_DOCUMENTS.SUCCESS:
      return addPageToState(
        "participantDocuments",
        action.response,
        action.loaderKey,
        action.infinite,
        state,
        action.queryString,
      );

    case SEARCH_DOCUMENTS.REQUEST:
      return {
        ...state,
        error: {
          ...state.error,
          searchDocuments: false,
        },
        loading: returnLoadingStatus(true, "GET", action.loaderKey, state),
      };
    case SEARCH_DOCUMENTS.FAILURE:
      return {
        ...state,
        error: {
          ...state.error,
          searchDocuments: true,
        },
        loading: returnLoadingStatus(false, "GET", action.loaderKey, state),
      };
    case SEARCH_DOCUMENTS.SUCCESS:
      return addPageToState(
        "searchDocuments",
        action.response,
        action.loaderKey,
        action.infinite,
        state,
        action.queryString,
      );

      // Temp filter documents
    case GET_TEMP_FILTER_DOCUMENTS.FAILURE:
      return {
        ...state,
        loading: returnLoadingStatus(false, "GET", "tempFilterDocuments", state),
      };
    case GET_TEMP_FILTER_DOCUMENTS.REQUEST:
      return {
        ...state,
        loading: returnLoadingStatus(true, "GET", "tempFilterDocuments", state),
      };
    case GET_TEMP_FILTER_DOCUMENTS.SUCCESS:
      return addPageToState("tempFilterDocuments", action.response, action.loaderKey, action.infinite, state);

    case DELETE_DOCUMENT.REQUEST:
      return {
        ...state,
        loading: setLoadingOrErrorFlag(state.loading, "deleteDocument", action.loaderKey, true),
      };
    case DELETE_DOCUMENT.FAILURE:
      return {
        ...state,
        error: setLoadingOrErrorFlag(state.error, "deleteDocument", action.loaderKey, true),
        loading: setLoadingOrErrorFlag(state.loading, "deleteDocument", action.loaderKey, false),
      };
    case DELETE_DOCUMENT.SUCCESS:
      const deletingDocumentId = action.loaderKey;
      const withoutDeletedRecord = (doc) => doc.id !== deletingDocumentId;
      const participantDocsWithoutDeletedRecord =
        (state.data.participantDocuments && state.data.participantDocuments.content.filter(withoutDeletedRecord)) || [];
      const searchDocsWithoutDeletedRecord =
        (state.data.searchDocuments && state.data.searchDocuments.content.filter(withoutDeletedRecord)) || [];

      return {
        ...state,
        data: {
          ...state.data,
          searchDocuments: {
            ...state.data.searchDocuments,
            content: searchDocsWithoutDeletedRecord,
          },
          participantDocuments: {
            ...state.data.participantDocuments,
            content: participantDocsWithoutDeletedRecord,
          },
        },
        error: setLoadingOrErrorFlag(state.error, "deleteDocument", action.loaderKey, false),
        loading: setLoadingOrErrorFlag(state.loading, "deleteDocument", action.loaderKey, false),
      };

    case GET_DOCUMENT.REQUEST:
      return {
        ...state,
        loading: setLoadingOrErrorFlag(state.loading, "singleDocument", action.loaderKey, true),
      };
    case GET_DOCUMENT.FAILURE:
      return {
        ...state,
        error: setLoadingOrErrorFlag(state.error, "singleDocument", action.loaderKey, true),
        loading: setLoadingOrErrorFlag(state.loading, "singleDocument", action.loaderKey, false),
      };
    case GET_DOCUMENT.SUCCESS:
      const contentWithoutDetailedResponse =
        state.data.participantDocuments && state.data.participantDocuments.content
          ? [...state.data.participantDocuments.content]
          : [];
      contentWithoutDetailedResponse.filter((doc) => doc.id === action.response.id);

      return {
        ...state,
        data: {
          ...state.data,
          participantDocuments: {
            ...state.data.participantDocuments,
            content: [...contentWithoutDetailedResponse, action.response],
          },
        },
        error: setLoadingOrErrorFlag(state.error, "singleDocument", action.loaderKey, false),
        loading: setLoadingOrErrorFlag(state.loading, "singleDocument", action.loaderKey, false),
      };

    case GET_OWNER_IDS.REQUEST:
      return {
        ...state,
        loading: returnLoadingStatus(true, "GET", action.loaderKey, state),
      };
    case GET_OWNER_IDS.FAILURE:
      return {
        ...state,
        error: {
          ...state.error,
          ownerIds: false,
        },
      };
      // Get Owner IDs for filtering
    case GET_OWNER_IDS.SUCCESS:
      return {
        ...state,
        loading: returnLoadingStatus(false, "GET", action.loaderKey, state),
        data: {
          ...state.data,
          ownerIds: action.response,
        },
      };

      // Reset Owner IDs
    case "RESET_OWNER_IDS":
      return {
        ...state,
        data: {
          ...state.data,
          ownerIds: {
            content: [],
          },
        },
      };
    default:
      return state;
  }
}
