import Popover from "@material-ui/core/Popover";
import { css } from "aphrodite/no-important";
import {
  getStartOfToday,
  isAfterDate,
  isBeforeDate,
} from "components/helpers/filters";
import moment, { Moment } from "moment";
import React, { useRef, useState } from "react";
import { joinClassNames } from "themes/helpers";
import { useMuiStyles } from "util/hooks/useMuiStyles";
import { useThemedStyleSheet } from "util/hooks/useThemedStyleSheet";
import { DatePicker } from "../DatePicker";
import { DateButton } from "./DateButton";
import { popoverStyles, styles } from "./styles";

export interface DateRangeValue {
  endDate: Moment | null;
  startDate: Moment | null;
}

type LabelType = {
  text: string;
  className?: string;
};

interface DateRangeProps {
  className?: string;
  handleChange: (range: DateRangeValue) => void;
  labelId: string;
  name: string;
  // Labels that appear on top of each date field
  labels?: {
    labelFrom: LabelType;
    labelTo: LabelType;
  };
  // Initial values
  initialValues?: {
    from?: Moment | null;
    to?: Moment | null;
  };
  onClear?: () => void;
}

export const DateRange = ({
  className,
  handleChange,
  initialValues,
  labelId,
  labels,
  name,
  onClear,
}: DateRangeProps) => {
  // Initial values
  const initialFrom =
    (initialValues?.from && moment(initialValues.from)) || null;
  const initialTo = (initialValues?.to && moment(initialValues.to)) || null;

  const [anchor, setAnchor] = useState<HTMLButtonElement | null>(null);
  const [isChoiceQueued, setIsChoiceQueued] = useState(false);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [isSelectingEndDate, setIsSelectingEndDate] = useState(false);
  const [maxDate, setMaxDate] = useState(getStartOfToday());
  const popoverClasses = useMuiStyles(popoverStyles);
  const [selectedEndDate, setSelectedEndDate] = useState<Moment | null>(
    initialTo && initialTo.isValid() ? initialTo : null
  );
  const [selectedStartDate, setSelectedStartDate] = useState<Moment | null>(
    initialFrom && initialFrom.isValid() ? initialFrom : null
  );
  const [shouldHideAutoFocus, setShouldHideAutoFocus] = useState(false);
  const endButton = useRef<HTMLButtonElement | null>(null);
  const startButton = useRef<HTMLButtonElement | null>(null);
  const themedStyles = useThemedStyleSheet(styles);

  const closePopover = (startDate?: Moment | null, endDate?: Moment | null) => {
    setAnchor(null);
    setIsPopoverOpen(false);
    handleChange({
      startDate: startDate ?? selectedStartDate?.startOf("day") ?? null,
      endDate: endDate ?? selectedEndDate?.endOf("day") ?? null,
    });
  };

  const selectSide = (shouldSelectEndDate: boolean) => {
    setIsSelectingEndDate(shouldSelectEndDate);
    setAnchor(shouldSelectEndDate ? endButton.current : startButton.current);
  };

  const openPopover = (newAnchor: HTMLButtonElement | null) => {
    setAnchor(newAnchor);
    setIsPopoverOpen((priorIsPopoverOpen) => !priorIsPopoverOpen);
    setMaxDate(getStartOfToday());
    setIsChoiceQueued(true);
  };

  const handleClickStartDate = (
    anchor: HTMLButtonElement | null,
    isKeyboardFocused: boolean
  ) => {
    openPopover(anchor);
    setIsSelectingEndDate(false);
    setShouldHideAutoFocus(!isKeyboardFocused);
  };

  const handleClickEndDate = (
    anchor: HTMLButtonElement | null,
    isKeyboardFocused: boolean
  ) => {
    openPopover(anchor);
    setIsSelectingEndDate(true);
    setShouldHideAutoFocus(!isKeyboardFocused);
  };

  const handleSelectionChange = (date: Moment) => {
    let shouldPreventClose = false;
    let startDate: Moment | null = null;
    let endDate: Moment | null = null;

    if (isSelectingEndDate) {
      if (isBeforeDate(date, selectedStartDate)) {
        setSelectedStartDate(date);
        startDate = date;
        setSelectedEndDate(date);
        selectSide(true);
        shouldPreventClose = !isChoiceQueued;
      } else {
        setSelectedEndDate(date);
        endDate = date;
        selectSide(false);
      }
    } else {
      if (isAfterDate(date, selectedEndDate)) {
        setSelectedStartDate(date);
        setSelectedEndDate(date);
        startDate = date;
        endDate = date;
        selectSide(true);
        shouldPreventClose = !isChoiceQueued;
      } else {
        setSelectedStartDate(date);
        startDate = date;
        selectSide(true);
      }
    }

    if (isChoiceQueued || shouldPreventClose) {
      setIsChoiceQueued(false);
    } else {
      closePopover(startDate ?? null, endDate ?? null);
    }
  };

  const showLabels = !!labels; // If labels prop found

  return (
    <>
      <div className={joinClassNames(css(themedStyles.container), className)}>
        {showLabels && (
          <label className={labels?.labelFrom.className}>
            {labels?.labelFrom.text}
          </label>
        )}
        <DateButton
          handleClick={handleClickStartDate}
          isActive={!isSelectingEndDate && isPopoverOpen}
          label="Start date"
          labelId={labelId}
          name={`${name}-startDate`}
          ref={startButton}
          selectedDate={selectedStartDate}
        />
        {(!showLabels && (
          <span className={css(themedStyles.rangeSeparator)}>to</span>
        )) || (
          <label className={labels?.labelTo.className}>
            {labels?.labelTo.text}
          </label>
        )}
        <DateButton
          handleClick={handleClickEndDate}
          isActive={isSelectingEndDate && isPopoverOpen}
          label="End date"
          labelId={labelId}
          name={`${name}-endDate`}
          ref={endButton}
          selectedDate={selectedEndDate}
        />
        {onClear && (
          <div className={css(themedStyles.clearButton)} onClick={onClear}>
            Clear
          </div>
        )}
      </div>
      <Popover
        anchorEl={anchor}
        anchorOrigin={{
          horizontal: "left",
          vertical: "bottom",
        }}
        onClose={() => closePopover()}
        open={isPopoverOpen}
        PaperProps={{ classes: popoverClasses, variant: "outlined" }}
      >
        <DatePicker
          handleSelectionChange={handleSelectionChange}
          isAutoFocusDisabled={!isPopoverOpen}
          isSelectingEndDate={isSelectingEndDate}
          maxDate={maxDate}
          name={name}
          selectedEndDate={selectedEndDate}
          selectedStartDate={selectedStartDate}
          shouldHideAutoFocus={shouldHideAutoFocus}
        />
      </Popover>
    </>
  );
};
