import { StyleSheet, StyleDeclaration } from "aphrodite";
import { CreateStyleSheet } from "util/hooks/useThemedStyleSheet";
import { remCalc } from "themes/helpers";
import { smallRegular } from "themes/mixins";

interface CellStyle {
  button: StyleDeclaration;
  buttonFocused: StyleDeclaration;
  buttonHidden: StyleDeclaration;
  buttonSelected: StyleDeclaration;
  cell: StyleDeclaration;
  cellWithinRange: StyleDeclaration;
}

export const styles: CreateStyleSheet<CellStyle> = (theme) => {
  return StyleSheet.create({
    button: {
      ":disabled": {
        backgroundColor: "transparent",
        color: theme.masterColors.mediumGrey,
      },
      ":hover:not(:disabled)": {
        backgroundColor: theme.masterColors.mediumGrey,
        color: theme.colors.black,
      },
      ...smallRegular,
      backgroundColor: "transparent",
      border: "none",
      borderRadius: remCalc(4),
      color: theme.colors.black,
      height: "100%",
      margin: 0,
      padding: 0,
      width: "100%",
    },
    buttonFocused: {
      ":focus": {
        backgroundColor: theme.masterColors.mediumGrey,
        color: theme.colors.black,
      },
    },
    buttonHidden: {
      ":disabled": {
        color: "transparent",
      },
    },
    buttonSelected: {
      backgroundColor: theme.colors.primary,
      color: theme.colors.white,
    },
    cell: {
      border: `${remCalc(2)} solid transparent`,
      height: remCalc(28),
      margin: 0,
      padding: 0,
      width: remCalc(28),
    },
    cellWithinRange: {
      backgroundColor: theme.colors.activeFill,
    },
  });
};
