import { FilterParams, PaginationParams } from "store/common/types";

export const RESOURCES_FILTERS = {
  SET: "RESOURCES_FILTERS_SET",
  PARAMS_SET: "RESOURCES_FILTERS_PARAMS_SET",
};

export interface ResourcesFiltersParams extends FilterParams {
  lastModifiedByEmail: string | number;
  pastDays: string | number;
  categoryIds: string | number;
  resourceType: string | number;
  lastUpdated: string;
  timeFilterType: string;
  dateRange: string | null;
  status: string | number;
  query: string;
}

export interface ResourcesFiltersArgs {
  filterParams: ResourcesFiltersParams;
  paginationParams: PaginationParams;
}

export interface SetResourcesFiltersAction {
  type: typeof RESOURCES_FILTERS.SET;
  response: ResourcesFiltersParams;
}

export interface SetResourcesPaginationAction {
  type: typeof RESOURCES_FILTERS.PARAMS_SET;
  response: PaginationParams;
}

export type FiltersActions = SetResourcesFiltersAction;
