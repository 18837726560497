import styled from "styled-components";
import { toRgba } from "themes/helpers";

type TutorialWrapperProps = {
  mobile?: boolean;
};

const TutorialWrapper = styled.div<TutorialWrapperProps>`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: ${({ mobile }) => (mobile ? "space-between" : "center")};
  margin-top: ${({ mobile }) => !mobile && "-25px"};
`;

const Image = styled.div`
  display: flex;
  justify-content: center;
  height: 35vh;
  padding: 15px 0 15px 0;
`;

const Header = styled.div`
  color: ${({ theme }) => theme.colors.black};
  font-size: 1.25rem;
  font-weight: 500;
  letter-spacing: 0.26px;
  text-align: left;
`;

const Content = styled.div`
  color: ${({ theme }) => toRgba(theme.colors.black, 0.6)};
  font-size: 0.875rem;
  font-weight: 400;
  letter-spacing: 0.25px;
  padding-bottom: 8px;
  text-align: left;
`;

const Logo = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  padding-top: 16px;
`;

type ButtonWrapperProps = {
  mobile?: boolean;
};
const ButtonsWrapper = styled.div<ButtonWrapperProps>`
  align-items: center;
  display: flex;
  justify-content: flex-end;
  padding-top: 35px;

  position: ${({ mobile }) => !mobile && "absolute"};
  bottom: ${({ mobile }) => !mobile && "44px"};
  right: ${({ mobile }) => !mobile && "55px"};
`;

const Next = styled.button`
  align-items;center;
  background-color: ${({ theme }) => theme.colors.primary};
  border-radius: 4px;
  bow-shadow: 0px 1px 0 3px ${({ theme }) => toRgba(theme.colors.black, 0.2)};
  color: ${({ theme }) => theme.colors.white};
  display: flex;
  justify-content: center;
  padding: 8px 25px;
  text-transform: uppercase;
`;

const Skip = styled.div`
  align-items;center;
  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.masterColors.darkGreyBlue};
  cursor: pointer;
  display: flex;
  justify-content: center;
  padding: 8px 0 8px 20px;
  text-transform: uppercase;
`;

const Dot = styled.div``;

export { ButtonsWrapper, Content, Dot, Header, Image, Logo, Next, Skip, TutorialWrapper };
