import { GET_USERS, usersState, usersActionTypes } from "./types";
import { setLoadingOrErrorFlag } from "store/common/reducerUtilities";

const initialState: usersState = {
  loading: {
    getUsers: {
      GET: false,
      POST: false,
      PUT: false,
    },
  },
  errors: {
    getUsers: {
      GET: false,
      POST: false,
      PUT: false,
    },
  },
  data: {
    users: {},
  },
};

export function usersReducer(state = initialState, action: usersActionTypes): usersState {
  const { response, type } = action;

  switch (type) {
    // Get users
    case GET_USERS.REQUEST:
      return {
        ...state,
        errors: setLoadingOrErrorFlag(state.errors, "getUsers", "GET", false),
        loading: setLoadingOrErrorFlag(state.loading, "getUsers", "GET", true),
      };
    case GET_USERS.SUCCESS:
      return {
        ...state,
        data: {
          users: response,
        },
        loading: setLoadingOrErrorFlag(state.loading, "getUsers", "GET", false),
      };
    case GET_USERS.FAILURE:
      return {
        ...state,
        errors: setLoadingOrErrorFlag(state.errors, "getUsers", "GET", true),
        loading: setLoadingOrErrorFlag(state.loading, "getUsers", "GET", false),
      };

    default:
      return state;
  }
}
