import { makeStyles } from "@material-ui/core";
import { remCalc } from "themes/helpers";

export interface CheckboxTheme {
  color: string;
  disabledColor: string;
  disabledFillColor: string;
  errorColor: string;
  errorFillColor: string;
  focusColor: string;
  hoverColor: string;
  uncheckedColor: string;
}

interface CheckboxStyleProps {
  disabled?: boolean;
  error?: string;
  theme: CheckboxTheme;
}

export interface LabelTheme {
  color: string;
  disabledColor: string;
  errorColor: string;
  hoverColor: string;
}

interface LabelStyleProps {
  disabled?: boolean;
  error?: string;
  theme: LabelTheme;
}

const useCheckboxStyles = (props: CheckboxStyleProps) => {
  const { disabled, error, theme } = props;
  const {
    color,
    disabledColor,
    disabledFillColor,
    errorColor,
    errorFillColor,
    focusColor,
    hoverColor,
    uncheckedColor,
  } = theme;
  return makeStyles({
    root: {
      color: error ? errorColor : uncheckedColor,
      padding: remCalc(8),
      "&$checked": {
        color: error ? errorColor : color,
        "&:hover": {
          backgroundColor: "transparent",
        },
      },
      "&:hover": {
        backgroundColor: "transparent",
        "& .background": {
          fill: disabled ? undefined : hoverColor,
        },
      },
      "&.Mui-focusVisible .MuiIconButton-label": {
        borderRadius: remCalc(4),
        boxShadow: `inset 0 0 0 ${remCalc(4)} ${focusColor}`,
      },
      "&.Mui-disabled .MuiIconButton-label": {
        color: disabledColor,
      },
      "& .background": {
        fill: disabled
          ? disabledFillColor
          : error
          ? errorFillColor
          : "transparent",
      },
    },
    checked: {},
    disabled: {
      borderColor: disabledColor,
      "&$checked": {
        color: disabledColor,
      },
    },
  })(props);
};

const useLabelStyles = (props: LabelStyleProps) => {
  const { disabled, error, theme } = props;
  const { color, disabledColor, errorColor, hoverColor } = theme;
  return makeStyles({
    label: {
      color: error ? errorColor : color,
      "&.Mui-disabled": {
        color: disabledColor,
      },
    },
    root: {
      "&:hover": {
        "& .background": {
          fill: disabled ? undefined : hoverColor,
        },
      },
    },
  })(props);
};

export { useCheckboxStyles, useLabelStyles };
