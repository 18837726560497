import React from "react";
import styled from "styled-components";
import InputLabelBase from "@material-ui/core/InputLabel";

import mediaQueries from "themes/mediaQueries";
import { remCalc } from "themes/helpers";

const Label = styled.div<{ assistiveLink?: boolean }>`
  display: flex;
  align-items: baseline;
  justify-content: ${({ assistiveLink }) => 
    assistiveLink ? "space-between" : "flex-start"};
  margin-bottom: ${remCalc(5)};
`;

const FieldLabel = styled(({ noMargin, smallLabel, ...otherProps }) => (
  <InputLabelBase {...otherProps} classes={{ root: "root" }} />
))`
  &&.root {
    color: ${({ theme }) => theme.colors.formFieldLabel};
    font-size: ${remCalc(20)};
    font-weight: 500;
    margin-top: ${({ noMargin }) => (noMargin ? 0 : "1rem")};
    ${({ smallLabel, theme }) =>
      smallLabel &&
      `
      color: ${theme.masterColors.darkGrey};  
      margin-bottom: ${remCalc(5)};
      font-size: ${remCalc(13)};
      @media (${mediaQueries.minTablet}) {
        font-size: ${remCalc(13)};
      }
      `}
  }
`;

const AssistiveLink = styled.a`
  color: ${({ theme }) => theme.colors.primary};
  font-size: ${remCalc(14)};

  &:focus {
    outline: 0;
    border-radius: ${remCalc(4)};
    box-shadow: 0 0 0 ${remCalc(4)} ${({ theme }) =>
  theme.colors.focusIndicatorOutline};
  }

  @media (${mediaQueries.minTablet}) {
    font-size: ${remCalc(21)};
    display: flex;
    align-items: center;
    text-decoration: none;
  }
`;

// @TODO update to AssistiveLink styles
const AssistiveLinkButton = styled.button<{
  isMouseFocused?: boolean;
  isTablet?: boolean;
}>`
  color: ${({ theme }) => theme.colors.primary};
  font-size: ${remCalc(14)};
  border: none;
  background: none;
  text-decoration: underline;

  ${({ isMouseFocused, theme }) => {
    if (!isMouseFocused) {
      return `
        &:focus {
          outline: 0;
          border-radius: ${remCalc(4)};
          box-shadow: 0 0 0 ${remCalc(4)} ${theme.colors.focusIndicatorOutline};
        }
      `;
    }
  }}
  

  ${({ isTablet, theme }) =>
    isTablet &&
    `
      background-color: ${theme.colors.activeFill};
      border-radius: 24px;
  `}
  @media (${mediaQueries.maxTablet}) {
    font-size: ${remCalc(21)};
  }
`;

const AssistiveIcon = styled.i`
  color: ${({ theme }) => theme.colors.primary};
  text-decoration: none;
  display: none;
  @media (${mediaQueries.minTablet}) {
    display: inline-block;
    font-size: ${remCalc(34)};
  }
`;

export { Label, FieldLabel, AssistiveLink, AssistiveLinkButton, AssistiveIcon };
