import React from "react";

import * as S from "./styles";
import { ErrorText } from "../form/TextInput/styles";

export interface LoaderProps {
  loading: boolean;
  error?: boolean | string | React.ReactNode;
  children?: React.ReactNode;
  className?: string;
  overlay?: boolean;
  spinnerProps?: any;
  height?: string;
}

// @TODO connect to theme

/**
 * **Loader** is a component which helps display status/progress/loading elements
 * on the screen.
 *
 * It can be wrapped around any data that would be hidden if
 * the associated feature was loading.
 *
 * It can also be a standalone component, with no children elements,
 * in which case it simply returns null with `!loading`
 *
 * **Loader** can also handle displaying error elements
 */
const Loader = ({ children, overlay, error, loading, className, spinnerProps, height }: LoaderProps) => {
  if (loading) {
    return (
      <>
        <S.Loader overlay={overlay} loading={loading} height={height} className={className}>
          <S.CircularProgress {...spinnerProps} />
        </S.Loader>
        {/* if displaying as an overlay, display the children underneath */}
        {overlay ? children : null}
      </>
    );
  }

  if (!children) {
    return null;
  }

  return (
    <S.Loader loading={loading} className={className}>
      {error ? (
        <ErrorText>
          {typeof error === "boolean" ? "The application has encountered an unknown error." : error}
        </ErrorText>
      ) : (
        children
      )}
    </S.Loader>
  );
};

Loader.defaultProps = {
  loading: false,
  error: false,
};

export default Loader;
