import React from "react";
import styled from "styled-components";
import InputAdornmentBase from "@material-ui/core/InputAdornment";
import TextFieldBase from "@material-ui/core/TextField";
import InputLabelBase from "@material-ui/core/InputLabel";
import CircularProgressBase from "@material-ui/core/CircularProgress";
import { remCalc } from "themes/helpers";

// MAIN WRAPPER
const SearchInput = styled.div`
  display: flex;
  flex-direction: column;
`;

// ADORNMENTS
const InputAdornment = styled(({ ...otherProps }) => <InputAdornmentBase {...otherProps} classes={{ root: "root" }} />)`
  &&.root {
  }
`;

const SearchIcon = styled.span<{ className?: string; noPaddingLeft?: boolean }>`
  color: ${({ theme }) => theme.masterColors.darkGrey};
  font-size: 24px;
  font-weight: 400;
  padding-left: ${(noPaddingLeft) => (noPaddingLeft && "0") || "12px"};
`;

const AdornmentButton = styled.button`
  background: none;
  border: none;

  color: ${({ theme }) => theme.colors.error};

  &:hover {
    cursor: pointer;
  }

  white-space: nowrap;
`;

const FilterButton = styled.button`
  background: none;
  border: none;

  &:hover {
    cursor: pointer;
  }
`;

const FilterIcon = styled.span.attrs({
  className: "icon icon-icons8-mail_filter",
})`
  color: ${({ theme }) => theme.colors.actionIcon};
  font-size: 1.25rem;
  padding-right: 0;
`;

// LABELS

const InputLabel = styled(({ ...otherProps }) => <InputLabelBase {...otherProps} classes={{ root: "root" }} />)`
  &&.root {
    color: ${({ theme }) => theme.colors.formFieldLabel};
    margin-bottom: 8px;
    font-size: 20px;
  }
`;

const ErrorLabel = styled(({ ...otherProps }) => <InputLabelBase {...otherProps} classes={{ root: "root" }} />)``;

// MUI TEXT FIELD
const TextField = styled(({ rounded, startAdornmentVisible, ...otherProps }) => (
  <TextFieldBase
    {...otherProps}
    classes={{
      root: "root",
    }}
    InputProps={{
      ...otherProps.InputProps,
      classes: {
        input: "input",
        notchedOutline: "notchedOutline",
        adornedStart: "adornedStart",
        root: "root",
        adornedEnd: "adornedEnd",
      },
    }}
  />
))`
  &&.root {
    background-color: ${({ theme }) => theme.colors.formFieldBackground};
    border-radius: ${({ rounded }) => (rounded ? "18pt" : "0px")};
  }

  && .notchedOutline {
    border-radius: ${({ rounded }) => (rounded ? "18pt" : "0px")};
  }

  && .inputAdornedStart {
    padding: 0px;
  }

  && .input {
    height: 36px;
    padding: 4.5px 2px;
    color: ${({ theme }) => theme.colors.formFieldColor};
  }
`;

// MUI TEXT FIELD FOR DASHBOARD DESKTOP SEARCH
const DashboardSearch = styled(
  ({ autoFocus, mobile, rounded, startAdornmentVisible, fullWidth, notchedOutline, ...otherProps }) => (
    <TextFieldBase
      autoFocus={autoFocus}
      {...otherProps}
      classes={{
        root: "root",
      }}
      InputProps={{
        ...otherProps.InputProps,
        classes: {
          input: "input",
          notchedOutline: `${mobile ? "notchedOutline" : ""}`,
          root: "root",
          underline: `${mobile ? "" : "underline"}`,
        },
      }}
    />
  ),
)`
  &&.root {
    background-color: ${({ theme }) => theme.colors.formFieldBackground};
    border-radius: ${({ rounded }) => (rounded ? "18pt" : "0px")};
  }

  && .notchedOutline {
    border-radius: ${({ mobile }) => mobile && "30px"};
  }

  && .inputAdornedStart {
    padding: px;
  }

  && .input {
    color: ${({ theme }) => theme.masterColors.darkGrey};
    font-weight: 400;
    height: 36px;
    padding: 4.5px 2px;
    ::placeholder {
      opacity: 1;
    }
  }

  && .underline {
    &:after {
      border-bottom: 1px solid ${({ theme }) => theme.masterColors.lightGrey};
    }
  }
`;

export const LoadingSpinner = styled(({ ...otherProps }) => (
  <CircularProgressBase {...otherProps} classes={{ root: "root", circle: "circle", svg: "svg" }} />
))`
  &&.root {
    color: ${({ theme }) => theme.colors.primary};
  }

  && .svg {
    margin: 10px;
  }
`;

export {
  AdornmentButton,
  ErrorLabel,
  FilterButton,
  FilterIcon,
  InputAdornment,
  InputLabel,
  SearchIcon,
  SearchInput,
  TextField,
  DashboardSearch,
};
