import React, { useContext, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { get } from "lodash";
import { useHistory, useLocation } from "react-router";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction, {
  BottomNavigationActionProps,
} from "@material-ui/core/BottomNavigationAction";
import styled from "styled-components";

import { getMainNavigationCopy } from "store/clientConfigs/selectors";

import StartNewFormButton from "../StartNewFormButton";
import { Drawer } from "components/common/Drawer/Drawer";

import * as S from "./styles";
import BottomNavigationContext from "./Context";
import { NavigationExpansionDrawerContent } from "./NavigationExpansionDrawerContent";
import PrivacySidebar from "./PrivacySidebar";
import { Action } from "redux";
import { startNewForm } from "store/newDocument/actions";
import { ThunkDispatch } from "redux-thunk";
import { AppState } from "store";

const StyledIcon = styled.i`
  font-size: 1.5rem;
`;

interface BasicActionProps extends BottomNavigationActionProps {
  isActive?: boolean;
}

const BasicAction = styled<React.FC<BasicActionProps>>(
  ({ isActive, ...otherProps }) => <BottomNavigationAction {...otherProps} />
)`
  &&.MuiBottomNavigationAction-root {
    ${({ isActive, theme }) => {
      if (isActive) {
        return `
          color: ${theme.colors.primary};
          fontWeight: 500;
        `;
      } else {
        return "fontWeight: 400;";
      }
    }}
  }
`;

const DashboardBottomNavigation = () => {
  const { drawerIsVisible, setDrawerIsVisible } = useContext(
    BottomNavigationContext
  );
  const dispatch = useDispatch<ThunkDispatch<AppState, void, Action>>();

  const location = useLocation();
  const mainNavigationCopy = useSelector(getMainNavigationCopy);
  const { push } = useHistory();

  // Navigation expansion drawer
  const [navigationDrawerVisible, setNavigationDrawerVisible] = useState<
    boolean
  >(false);

  // Hide drawer when location changes (user navigated away)
  useEffect(() => {
    setNavigationDrawerVisible(false);
  }, [location.pathname]);

  const handleClickNewForm = () => {
    if (!drawerIsVisible) {
      dispatch(
        startNewForm({
          selectedFormTemplateId: null,
          selectedFormTypeId: null,
          selectedWorkOrderId: null,
        })
      );
      setDrawerIsVisible(true);
    }
  };

  // Privacy Policy Sidebar
  const [isPrivacySidebarVisible, setIsPrivacySidebarVisible] = useState<
    boolean
  >(false);

  return (
    <S.BottomNavigation>
      {/* Navigation expansion drawer */}
      <Drawer
        anchor="right"
        content={
          <NavigationExpansionDrawerContent
            togglePrivacySidebarVisibility={setIsPrivacySidebarVisible}
          />
        }
        id="menuExpansionDrawer"
        onClose={() => setNavigationDrawerVisible(false)}
        open={navigationDrawerVisible}
        showCloseIcon={true}
      />

      <BottomNavigation showLabels>
        <BasicAction
          label="Home"
          icon={<StyledIcon className="icon-icons8-a_home" />}
          isActive={location.pathname === "/"}
          onClick={() => push("/")}
        />

        <BasicAction
          label={get(mainNavigationCopy, "documents")}
          icon={<StyledIcon className="icon icon-icons8-document" />}
          isActive={location.pathname === "/documents"}
          onClick={() => push("/documents")}
        />

        <BottomNavigationAction
          label=""
          component={React.forwardRef<HTMLButtonElement>((props, ref) => (
            <StartNewFormButton onClick={handleClickNewForm} ref={ref} />
          ))}
          showLabel={false}
          icon={<div />}
          style={{}}
        />

        <BasicAction
          label={get(mainNavigationCopy, "profile")}
          icon={<StyledIcon className="icon-icons8-user_male_circle" />}
          isActive={location.pathname === "/profile"}
          onClick={() => push("/profile")}
        />

        <BasicAction
          label={get(mainNavigationCopy, "menu")}
          icon={<StyledIcon className="icon icon-icons8-menu" />}
          onClick={() => setNavigationDrawerVisible(true)}
        />
      </BottomNavigation>

      {/* Privacy Policy Sidebar */}
      <PrivacySidebar
        isOpen={isPrivacySidebarVisible}
        setIsOpen={setIsPrivacySidebarVisible}
      />
    </S.BottomNavigation>
  );
};

export default DashboardBottomNavigation;
