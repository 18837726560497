import React from "react";
import moment from "moment";

import { 
  DataItemLabel, 
  DataItemText, 
  DisableUserButtonsWrapper, 
  DisableUserDataItem, 
  ModalPromptCloseIcon,
  ModalPromptTitle, 
  ModalPromptWrapper,
  ModalPromptSubtitle, 
} from "components/clientAdmin/users/styles";
import Loader from "components/common/Loader";
import { CancelButton, SubmitButton } from "components/clientAdmin/styles";
import { LoaderWrapper } from "components/clientAdmin/addUser/styles";
import { TagStatusOutcomeModal } from "./TagStatusOutcomeModal";
import { TagDTO } from "store/tagLibrary/types";
import { actionPerformed } from "../helpers";

export interface TagStatusModalProps {
  onCancel: () => void;
  onSubmit: () => void;
  status: "request" | "failure" | "success" | null;
  tag: TagDTO | null;
}

export const TagStatusModal = ({ onCancel, onSubmit, status, tag }: TagStatusModalProps) => {

  const successOrFailure = status === "success" || status === "failure";

  if (successOrFailure) {
    return <TagStatusOutcomeModal
      archived={tag?.archived}
      onClose={onCancel}
      status={status}
    />;
  }

  return <>
    <ModalPromptWrapper>
      <ModalPromptTitle>Are you sure?</ModalPromptTitle>
      <ModalPromptSubtitle>You are about to {actionPerformed(tag?.archived, "present")} this tag:</ModalPromptSubtitle>
      <div className="d-flex flex-row">
        <DisableUserDataItem>
          <DataItemLabel>
            Name
          </DataItemLabel>
          <DataItemText>
            {tag?.name  || "-"}
          </DataItemText>
        </DisableUserDataItem>

        <DisableUserDataItem>
          <DataItemLabel>
            Uses
          </DataItemLabel>
          <DataItemText>
            {tag?.useCount  || "-"}
          </DataItemText>
        </DisableUserDataItem>

        <DisableUserDataItem>
          <DataItemLabel>
            Date Added
          </DataItemLabel>
          <DataItemText>
            {tag? moment(tag.createdDate).format("MM/DD/YYYY") : "-"}
          </DataItemText>
        </DisableUserDataItem>


      </div>
      
      <DisableUserButtonsWrapper margin="24px 0 0 0">
        <CancelButton disabled={false} onClick={onCancel}>
          cancel
        </CancelButton>
        <SubmitButton disabled={false} onClick={onSubmit}>
          <LoaderWrapper>
            <Loader loading={status === "request"} className="p-0 mr-3" spinnerProps={{ size: 14 }} />
          </LoaderWrapper>
          <span>yes</span>
        </SubmitButton>
      </DisableUserButtonsWrapper>

      <ModalPromptCloseIcon onClick={onCancel} />
    </ModalPromptWrapper>
  </>;

};
