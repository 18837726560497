import React, { FC } from "react";

import { Modal } from "components/common/Modal/futureUiKit";

interface Props {
  closeModal: () => void;
  callback: () => void;
  modalOpen: boolean;
}

export const ConfirmationModal: FC<Props> = ({
  closeModal,
  callback,
  modalOpen,
}) => {
  return (
    <Modal
      action={{
        text: "Delete Resource",
        callback,
        loading: false,
      }}
      alert={{
        variant: "warning",
        title: "Are you sure you want to delete this resource?",
        message:
          "Deleting a resource will remove it from this list. This change cannot be undone.",
        isVisible: true,
      }}
      handleClose={closeModal}
      open={modalOpen}
      title="Are you sure?"
    />
  );
};
