import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router";
import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { useDispatch } from "react-redux";
import * as Yup from "yup";

import { AppState } from "store";
import { ContentWrapper } from "components/common/Wrappers";
import {
  GET_RESOURCE_BY_ID,
  ResourceDTO,
  Resource,
  ResourceValues,
} from "store/resources/types";
import {
  addResource,
  getResourceById,
  saveResource,
  uploadDefenseDocuments,
} from "store/resources/actions";
import Breadcrumbs from "components/common/Breadcrumbs";

import ResourceForm from "./ResourceForm";
import { Form, Formik, FormikHelpers } from "formik";
import { mapInitialResourceValues } from "./helpers";
import Loader from "components/common/Loader";
import Toast from "components/common/Toast";

const validationSchema = () =>
  Yup.object().shape({
    description: Yup.string().required("Description is required"),
    title: Yup.string().required("Title is required"),
    resourceType: Yup.mixed().notOneOf([-1], "Please select a type"),
    notes: Yup.mixed().notRequired(),
  });

const EditResource = () => {
  const [resource, setResource] = useState<ResourceDTO>();
  const [error, setError] = useState<string>();
  const [resourceSummary, setResourceSummary] = useState<Resource>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  // get resource id from url params
  const { id: resourceId } = useParams<{ id?: string }>();
  const dispatch = useDispatch<ThunkDispatch<AppState, void, Action>>();
  const history = useHistory();

  const getResource = async (id: string) => {
    setIsLoading(true);
    const res = await dispatch(getResourceById(id));
    if (res.type === GET_RESOURCE_BY_ID.SUCCESS) {
      setResource({ ...res.response, resourceType: res.resourceType });
      setResourceSummary(res.resourceSummary);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (resourceId) {
      getResource(resourceId);
    }
  }, [resourceId]);

  const currentPathName = resourceId
    ? `Edit Resource${resourceSummary ? `: ${resourceSummary.title}` : ""}`
    : "Add Resource";

  async function handleSubmit(
    values: ResourceValues,
    { setFieldValue }: FormikHelpers<ResourceValues>
  ) {
    // We need local error, setError won't set error on same cycle -- GK
    let _error = false;

    let id = values.id;
    // if there's no id, create the new resource
    if (!id) {
      const arResponse = await dispatch(addResource(values));
      if (arResponse.response) {
        id = arResponse.response.id;
      } else if (arResponse.error) {
        _error = true;
        setError(arResponse.error);
      }
    }

    // if it's a defense and there are files to upload, upload them and re-submit
    if (values.resourceType === "DEFENSE" && id) {
      const newFiles =
        values.defenseDocuments?.filter((doc) => !("url" in doc)) || [];
      const existingFiles =
        values.defenseDocuments?.filter((doc) => "url" in doc) || [];
      const docsResponse = await dispatch(
        uploadDefenseDocuments(id, newFiles as File[])
      );
      // @todo handle errors
      if (docsResponse.uploadedDocuments) {
        const updatedDocs = [
          ...existingFiles,
          ...docsResponse.uploadedDocuments,
        ];
        setFieldValue("defenseDocuments", updatedDocs);
        await dispatch(
          saveResource({ ...values, id, defenseDocuments: updatedDocs })
        );
      }
      if (docsResponse.uploadErrors.length > 0) {
        _error = true;
        setError(docsResponse.uploadErrors.join("\n"));
      }
    }

    // if it's an OE
    if (values.resourceType === "OPERATIONAL_EXPERIENCE" && id) {
      // I think ID doesn't do much here -- GK
      const saveOEResponse = await dispatch(saveResource({ ...values, id }));
      if (saveOEResponse.error) {
        _error = true;
        setError(saveOEResponse.error);
      }
    }

    if (!_error) {
      history.push("/content/resources");
    }
  }

  return (
    <ContentWrapper>
      <Breadcrumbs
        paths={[
          {
            pathName: "Content",
          },
          {
            pathName: "Resources",
            href: "/content/resources",
          },
          {
            pathName: currentPathName,
            href: `/content/resources/${resourceId}`,
          },
        ]}
      />
      <Toast variant="error" onClick={() => setError("")} visible={!!error}>
        {error}
      </Toast>
      <Loader overlay loading={isLoading}>
        <Formik
          initialValues={mapInitialResourceValues(resource)}
          onSubmit={handleSubmit}
          enableReinitialize
          validationSchema={validationSchema}
          // validateOnChange={submitCount > 0}
          // validateOnBlur={submitCount > 0}
        >
          <Form
            id="resourcesForm"
            translate="yes"
            onKeyDown={(e) => {
              if ((e.charCode || e.keyCode) === 13) {
                e.preventDefault();
              }
            }}
          >
            <ResourceForm resourceSummary={resourceSummary} />
          </Form>
        </Formik>
      </Loader>
    </ContentWrapper>
  );
};

export default EditResource;
