import React from "react";

import * as S from "./styles";

export interface DefaultCell {
  color?: string;
  onClick?: () => void;
  value?: any;
}

const DefaultCell = ({ color, onClick, value }: DefaultCell) => {
  return (
    <S.Wrapper color={color} onClick={onClick}>
      {value}
    </S.Wrapper>
  );
};

export default DefaultCell;
