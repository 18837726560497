import { StyleDeclaration, StyleSheet } from "aphrodite";
import { remCalc } from "themes/helpers";
import { inlineRow, inputReset, ThemedMixin } from "themes/mixins";
import { CreateStyleSheet } from "util/hooks/useThemedStyleSheet";

export interface RadioButtonStyle {
  input?: StyleDeclaration;
  inputChecked?: StyleDeclaration;
  inputContainer?: StyleDeclaration;
  inputDisabled?: StyleDeclaration;
  inputEnabled?: StyleDeclaration;
  inputError?: StyleDeclaration;
  inputKeyboardFocused?: StyleDeclaration;
  label?: StyleDeclaration;
  labelChecked?: StyleDeclaration;
  labelKeyboardFocused?: StyleDeclaration;
  labelText?: StyleDeclaration;
  labelTextDisabled?: StyleDeclaration;
  labelTextError?: StyleDeclaration;
}

const focusTransition: ThemedMixin = (theme) => {
  const { duration, timingFunction } = theme.animations.focusIndicatorAppear;
  return {
    boxShadow: `0 0 0 ${remCalc(6)} transparent`,
    transition: `box-shadow ${duration} ${timingFunction}`,
  };
};

const innerCircleChecked: ThemedMixin = (theme) => {
  return {
    backgroundColor: theme.colors.primary,
    transform: "scale(1)",
    transition: "transform .15s",
  };
};

const outerCircle: ThemedMixin = (theme) => {
  return {
    borderColor: theme.masterColors.darkGreyBlue,
    borderRadius: "50%",
    borderStyle: "solid",
    borderWidth: remCalc(2),
  };
};

const innerCircleUnchecked: StyleDeclaration = {
  borderRadius: "50%",
  content: "''",
  display: "block",
  height: remCalc(16),
  left: remCalc(2),
  position: "relative",
  transform: "scale(0)",
  transition: "transform .15s",
  top: remCalc(2),
  width: remCalc(16),
};

export const pillStyle: CreateStyleSheet<RadioButtonStyle> = (theme) => {
  return StyleSheet.create({
    input: {
      ...inputReset,
      ...outerCircle(theme),
      height: remCalc(36),
      width: remCalc(36),
      ":after": {
        ...innerCircleUnchecked,
        height: remCalc(28),
        width: remCalc(28),
      },
      ":focus": {
        outline: "none",
      },
    },
    inputChecked: {
      borderColor: theme.colors.primary,
      ":after": {
        ...innerCircleChecked(theme),
      },
    },
    inputContainer: {
      height: remCalc(36),
    },
    inputDisabled: {
      backgroundColor: theme.masterColors.greyBlue,
      borderColor: theme.masterColors.darkGreyBlue,
      ":after": {
        backgroundColor: theme.masterColors.darkGreyBlue,
      },
      ":checked": {
        ":after": {
          backgroundColor: theme.masterColors.darkGreyBlue,
        },
      },
    },
    inputEnabled: {
      ":hover": {
        backgroundColor: theme.masterColors.lightBlue,
      },
    },
    inputError: {
      backgroundColor: theme.colors.errorFill,
      borderColor: theme.colors.error,
      ":after": {
        backgroundColor: theme.colors.error,
      },
      ":checked": {
        ":after": {
          backgroundColor: theme.colors.error,
        },
      },
    },
    label: {
      ...focusTransition(theme),
      ...inlineRow,
      borderRadius: "999px",
      padding: remCalc(6),
    },
    labelChecked: {
      backgroundColor: theme.masterColors.lightBlue,
    },
    labelKeyboardFocused: {
      ":focus-within": {
        boxShadow: `0 0 0 ${remCalc(4)} ${theme.colors.focusIndicatorOutline}`,
      },
    },
    labelText: {
      fontSize: remCalc(20),
      lineHeight: remCalc(28),
      padding: `0 ${remCalc(24)} 0 ${remCalc(8)}`,
    },
    labelTextDisabled: {
      color: theme.masterColors.darkGreyBlue,
    },
    labelTextError: {
      color: theme.colors.error,
    },
  });
};

export const ratingStyle: CreateStyleSheet<RadioButtonStyle> = (theme) => {
  const { duration, timingFunction } = theme.animations.focusIndicatorAppear;
  return StyleSheet.create({
    input: {
      ...inputReset,
      ...outerCircle(theme),
      backgroundColor: theme.colors.white,
      height: remCalc(36),
      width: remCalc(36),
      ":after": {
        height: remCalc(28),
        width: remCalc(28),
      },
      ":focus": {
        outline: "none",
      },
    },
    inputChecked: {
      backgroundColor: theme.colors.primary,
      borderColor: theme.colors.primary,
    },
    inputContainer: {
      display: "inline-block",
      height: remCalc(36),
      position: "relative",
      width: remCalc(36),
    },
    inputDisabled: {
      backgroundColor: theme.masterColors.greyBlue,
      borderColor: theme.masterColors.darkGreyBlue,
      ":after": {
        backgroundColor: theme.masterColors.darkGreyBlue,
      },
      ":checked": {
        ":after": {
          backgroundColor: theme.masterColors.darkGreyBlue,
        },
      },
    },
    inputError: {
      backgroundColor: theme.colors.errorFill,
      borderColor: theme.colors.error,
      ":after": {
        backgroundColor: theme.colors.error,
      },
      ":checked": {
        ":after": {
          backgroundColor: theme.colors.error,
        },
      },
    },
    label: {
      alignItems: "center",
      backgroundColor: theme.colors.white,
      borderColor: theme.masterColors.darkGrey,
      borderStyle: "solid",
      borderRadius: "0",
      borderRightWidth: "0",
      borderBottomWidth: "1px",
      borderLeftWidth: "1px",
      borderTopWidth: "1px",
      boxShadow: `0 0 0 ${remCalc(5)} transparent, inset 0 0 0 ${remCalc(
        1
      )} transparent`,
      display: "flex",
      flexDirection: "column",
      flex: "1",
      padding: `${remCalc(16)} ${remCalc(12)}`,
      textAlign: "center",
      transition: `border-color ${duration} ${timingFunction}, box-shadow ${duration} ${timingFunction}`,
      ":first-child": {
        borderBottomLeftRadius: remCalc(16),
        borderTopLeftRadius: remCalc(16),
      },
      ":hover": {
        backgroundColor: theme.colors.activeFill,
      },
      ":last-child": {
        borderBottomRightRadius: remCalc(16),
        borderRightWidth: "1px",
        borderTopRightRadius: remCalc(16),
        padding: `${remCalc(16)} ${remCalc(12)}`,
      },
    },
    labelChecked: {
      backgroundColor: theme.masterColors.lightGrey,
      ":focus-within": {
        backgroudnColor: theme.colors.activeFill,
      },
    },
    labelKeyboardFocused: {
      ":focus-within": {
        borderColor: theme.colors.focusIndicatorOutline,
        boxShadow: `0 0 0 ${remCalc(3)} ${
          theme.colors.focusIndicatorOutline
        }, inset 0 0 0 ${remCalc(1)} ${theme.colors.focusIndicatorOutline}`,
        position: "relative",
      },
    },
    labelText: {
      color: theme.colors.black,
      fontSize: remCalc(20),
      lineHeight: remCalc(28),
      paddingTop: remCalc(8),
    },
  });
};

export const standardStyle: CreateStyleSheet<RadioButtonStyle> = (theme) => {
  return StyleSheet.create({
    input: {
      ...focusTransition(theme),
      ...inputReset,
      ...outerCircle(theme),
      backgroundColor: theme.colors.white,
      height: remCalc(24),
      width: remCalc(24),
      ":after": {
        ...innerCircleUnchecked,
      },
      ":focus": {
        outline: "none",
      },
    },
    inputChecked: {
      borderColor: theme.colors.primary,
      ":after": {
        ...innerCircleChecked(theme),
      },
    },
    inputContainer: {
      height: remCalc(24),
    },
    inputDisabled: {
      backgroundColor: theme.masterColors.greyBlue,
      borderColor: theme.masterColors.darkGreyBlue,
      ":after": {
        backgroundColor: theme.masterColors.darkGreyBlue,
      },
      ":checked": {
        ":after": {
          backgroundColor: theme.masterColors.darkGreyBlue,
        },
      },
    },
    inputEnabled: {
      ":hover": {
        backgroundColor: theme.masterColors.lightBlue,
      },
    },
    inputError: {
      backgroundColor: theme.colors.errorFill,
      borderColor: theme.colors.error,
      ":after": {
        backgroundColor: theme.colors.error,
      },
      ":checked": {
        ":after": {
          backgroundColor: theme.colors.error,
        },
      },
    },
    inputKeyboardFocused: {
      ":focus": {
        boxShadow: `0 0 0 ${remCalc(4)} ${theme.colors.focusIndicatorOutline}`,
      },
    },
    label: {
      ...inlineRow,
      padding: remCalc(2),
    },
    labelText: {
      padding: `0 ${remCalc(24)} 0 ${remCalc(8)}`,
    },
    labelTextDisabled: {
      color: theme.masterColors.darkGreyBlue,
    },
    labelTextError: {
      color: theme.colors.error,
    },
  });
};

interface CheckedIconStyle {
  icon: StyleDeclaration;
}

export const checkedIconStyle: CreateStyleSheet<CheckedIconStyle> = (theme) => {
  return StyleSheet.create({
    icon: {
      bottom: 0,
      fill: theme.colors.white,
      left: 0,
      position: "absolute",
      right: 0,
      top: 0,
    },
  });
};
