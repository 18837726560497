import styled from "styled-components";

import Text from "components/common/Text";
import { remCalc, toRgba } from "themes/helpers";

export const Title = styled(Text)`
  margin: 0px 0px 16px 0px;
  padding: 0px;

  font-size: ${remCalc(16)};
  font-weight: 500;
`;

export const DefenseContent = styled.ul`
  margin: 0px;
  padding: 0px;
`;

export const DefenseItem = styled.li<{ inDrawer?: boolean }>`
  list-style: none;
  font-size: 16px;

  display: flex;
  flex-direction: column;

  margin: 0px 0px 32px 0px;

  ${({ inDrawer }) => {
    if (!inDrawer) {
      return `
        justify-content: space-between;
      `;
    }
  }}

`;
export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const Left= styled.div<{ inDrawer?: boolean }>`
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 50%;

  ${({ inDrawer }) => {
    if (inDrawer) {
      return `
        flex-basis: 20%;
      `;
    }
  }}
`;
export const Right= styled.div<{ inDrawer?: boolean }>`
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 50%;

  display: flex;
  flex-direction: column;

  ${({ inDrawer }) => {
    if (inDrawer) {
      return `
        flex-grow: 3;
        flex-shrink: 3;
        flex-basis: 80%;
      `;
    }
  }}
`;


export const DefenseItemLabel = styled(Text)`
  font-size: ${remCalc(14)};
  color: ${({ theme }) => toRgba(theme.colors.black, 0.87)};
`;

export const SelectionTitle = styled(Text)`
  font-size: ${remCalc(16)};
  color: ${({ theme }) => theme.masterColors.darkGrey};
  margin: 0px 0px 7px 0px;
`;

export const DefenseContentText = styled(Text)<{ inDrawer?: boolean }>`
  font-size: ${remCalc(16)};
  color: ${({ theme }) => theme.masterColors.darkGrey};
`;

export const DefenseDescription = styled.div<{ inDrawer?: boolean }>`
  font-size: ${remCalc(16)};
  color: ${({ theme }) => theme.masterColors.darkGrey};
`;

export const ViewDefenses = styled.div<{ inDrawer?: boolean }>`
  text-align: left;
  border-bottom: solid 1px ${({ theme }) => theme.colors.lightGrey};
  max-width: 415px;
  padding: 24px 0px;

  ${({ inDrawer }) => {
    if (!inDrawer) {
      return `
        width: 100%;
        max-width: 100%;
      `;
    }
  }}
`;
