import styled from "styled-components";
import { devices } from "themes/mediaQueries";
import { remCalc } from "themes/helpers";

const PageHeader = styled.h1`
  font-weight: 400;
  font-size: ${remCalc(36)};
  letter-spacing: 0.63px;

  @media (max-width: ${devices.maxTablet}px) {
    font-size: ${remCalc(20)};
    letter-spacing: 0.26px;
  }
`;

const LastUpdated = styled.div`
  font-size: ${remCalc(14)};
  font-style: italic;
  letter-spacing: 0.24px;
`;

const TextBlock = styled.div`
  letter-spacing: 0.24px
  margin-top: 1rem;
  margin-bottom: 1rem;

  @media (max-width: ${devices.maxTablet}px) {
    font-size: ${remCalc(14)};
  }
`;

const Link = styled.a`
  color: ${(props) => props.theme.masterColors.primary};
  text-decoration: underline;
`;

const SectionHeader = styled.h2`
  font-weight: 400;
  font-size: ${remCalc(20)};
  letter-spacing: 0.26px;
  margin-bottom: 1rem;

  @media (max-width: ${devices.maxTablet}px) {
    font-size: ${remCalc(18)};
    letter-spacing: 0.24px;
  }
`;

const SubHeader = styled.h3`
  font-weight: 400;
  font-size: ${remCalc(18)};
  letter-spacing: 0.24px;
  margin-bottom: 1.5rem;

  @media (max-width: ${devices.maxTablet}px) {
    font-size: ${remCalc(16)};
  }
`;

const ListHeading = styled.div`
  margin-bottom: 1.5rem;

  @media (max-width: ${devices.maxTablet}px) {
    font-size: ${remCalc(14)};
  }
`;

const ListBlock = styled.ul`
  margin-top: 1rem;
  margin-bottom: 1rem;

  @media (max-width: ${devices.maxTablet}px) {
    font-size: ${remCalc(14)};
  }
`;

const SignatureWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 3rem;
  margin-bottom: 1rem;
`;

const SignatureRow = styled.div`
  letter-spacing: 0.24px;

  @media (max-width: ${devices.maxTablet}px) {
    font-size: ${remCalc(14)};
  }
`;

export default {
  PageHeader,
  LastUpdated,
  TextBlock,
  Link,
  SectionHeader,
  SubHeader,
  ListHeading,
  ListBlock,
  SignatureWrapper,
  SignatureRow
};

