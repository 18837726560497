import React from "react";

import { MenuItem as MenuItemType, MenuItemIcon, MenuDivider as MenuDividerType, isMenuItem } from "./types";
import * as S from "./styles";
import MenuItem from "./MenuItem";
import MenuDivider from "./MenuDivider";

export type MenuItems = Array<MenuItemType | MenuDividerType>;

interface Props {
  className?: string;
  menuItems: MenuItems;
}

/**
 * Renders a Menu with Button list items.
 * @props.menuItems takes a specific config and
 * uses an enum for the Icon, be sure to check the type.
 */
const Menu = ({ menuItems, className }: Props) => {
  return (
    <S.Menu className={className}>
      {menuItems.map((item) => {
        if (isMenuItem(item)) {
          return <MenuItem key={item.label} menuItem={item} />;
        }
        return <MenuDivider key={item.title} menuDivider={item} />;
      })}
    </S.Menu>
  );
};

export { Menu as default, MenuItemIcon };
