import React from "react";
import styled from "styled-components";
import IconButtonBase from "@material-ui/core/IconButton";
import MoreVertBase from "@material-ui/icons/MoreVert";
import TooltipBase from "@material-ui/core/Tooltip";

import { remCalc } from "themes/helpers";

const ActionButton = styled(({ ...props }) => (
  <IconButtonBase {...props} classes={{ root: "root" }} />
))`
  &&.root:hover {
    background-color: ${({ theme }) => theme.masterColors.lightGrey};
  }

  &&.root.mouseFocus:focus {
    background-color: ${({ theme }) => theme.masterColors.greyBlue};
    box-shadow: none;
  }

  &&.root:focus {
    border-radius: "4px";
    outline: 0;
    box-shadow: 0 1px 3px 0 ${({ theme }) => theme.colors.dropShadow},
      0 0 0 ${remCalc(4)} ${({ theme }) => theme.colors.focusIndicatorOutline};
    background-color: ${({ theme }) => theme.masterColors.lightGrey};
  }
`;

// This is needed so that the tooltip will show when hovering the ActionButton
const ActionButtonWrapper = styled.div`
  display: inline-flex;
  height: ${remCalc(48)};
  width: ${remCalc(48)};
`;

const ActionIcon = styled(({ ...props }) => (
  <MoreVertBase {...props} classes={{ root: "root" }} />
))`
  &&.root {
    color: ${({ theme }) => theme.masterColors.black};
  }
`;

const ActionTooltip = styled((props) => (
  <TooltipBase
    {...props}
    classes={{ popper: props.className, tooltip: "tooltip", arrow: "arrow" }}
  />
))`
  & .tooltip {
    background-color: ${({ theme }) => theme.masterColors.black};
    color: ${({ theme }) => theme.masterColors.white};
    border-color: ${({ theme }) => theme.masterColors.black};
    font-size: ${remCalc(16)};
    font-weight: normal;
    line-height: ${remCalc(24)};
  }

  & .arrow {
    color: ${({ theme }) => theme.masterColors.black};
  }
`;

export default {
  ActionButton,
  ActionButtonWrapper,
  ActionIcon,
  ActionTooltip,
};
