import styled from "styled-components";

import mediaQueries from "themes/mediaQueries";

export const ContentWrapper = styled.div`
  max-width: 990px; // 30px added to offset inside padding
  min-width: 790px; // 30px added to offset inside padding
  padding: 0 15px;
`;

// Pick a better name if you can, but for "mobile" responsive routes (so mostly non-CA specific)
// we need to not have min-width -- GK
export const ResponsiveContentWrapper = styled.div`
  max-width: 990px;
  padding: 0 15px;

  @media (${mediaQueries.maxTablet}) {
    padding: 0 15px 100px 15px; // For bottom navigation
  }
`;
