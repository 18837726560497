import React, { ChangeEvent } from "react";
import { css } from "aphrodite/no-important";
import { useFormikContext } from "formik";

import s from "./styles";
import Text from "components/common/Text";
import { FieldErrorType } from "components/FormController/components/Question";
import { HelperText } from "../../TextInput/styles";
import {
  PillRadioButton,
  RadioButtonProps,
} from "components/common/form/RadioButton/futureUiKit";
import { RadioButtonsGroup } from "components/common/form/RadioButtons/futureUiKit";
import { RadioButtonsGroupProps } from "components/common/form/RadioButtons/futureUiKit";
import { SingleResponseQuestionProps } from "../../types";
import { joinClassNames } from "themes/helpers";
import { FormDTO } from "store/forms/types";
import Comment from "components/forms/Comment";

interface Option {
  value: string;
  label: string;
  id?: number;
  rootId?: number;
}

interface Props extends SingleResponseQuestionProps {
  className?: string;
  disabled?: boolean;
  error?: FieldErrorType;
  options: Array<Option>;
  GroupComponent?: React.ComponentType<RadioButtonsGroupProps>;
  handleBlur?: () => void;
  name: string;
  RadioButtonComponent?: React.ComponentType<RadioButtonProps>;
  stacked?: boolean;
  children?: React.ReactNode;
  label?: string;
  labelId?: string;
}

export function RadioInput({
  className,
  disabled,
  error,
  handleBlur,
  options,
  GroupComponent = RadioButtonsGroup,
  label = "",
  labelId,
  name,
  question,
  RadioButtonComponent = PillRadioButton,
  response,
  setQuestionResponse,
  children,
  stacked = false,
}: Props) {
  const isRequired = question.formProperties?.isRequired;
  const enableComments = question.properties?.enableComments;
  const commentRequired = question.selections?.find(
    (selection) => selection.id === response?.associatedId
  )?.properties?.commentRequired;
  const { errors } = useFormikContext<FormDTO>();

  const handleChange = ({
    target: { value },
  }: ChangeEvent<HTMLInputElement>): void => {
    const option = options.find((label) => label.value === value);
    if (option) {
      setQuestionResponse({
        answer: value,
        associatedId: option.id,
        associatedRootId: option.rootId,
        questionId: question.id,
        questionRootId: question.rootId,
        timeAnswered: new Date().toISOString(),
      });
    }
  };

  const handleChangeComment = (comments: string) => {
    setQuestionResponse({
      ...response,
      answer: response?.answer || "",
      questionId: question.id,
      questionRootId: question.rootId,
      comments,
      timeAnswered: new Date().toISOString(),
    });
  };

  // Helper text
  const helperText = React.useMemo(
    () => question.properties?.assistiveText || undefined,
    [question.properties?.assistiveText]
  );

  return (
    <div
      className={joinClassNames(css(s.container), className)}
      onBlur={handleBlur}
    >
      <GroupComponent
        label={label}
        labelId={labelId}
        name={name.toString()}
        onChange={handleChange}
        options={options.map((label) => {
          return {
            ...label,
            disabled,
          };
        })}
        required={isRequired}
        RadioButtonComponent={RadioButtonComponent}
        row={!stacked}
        value={response?.answer || ""}
        helperText={helperText}
      />
      {children}
      {error && <Text variant="error">{error}</Text>}

      {enableComments && response && (
        <Comment
          className={css(s.commentField)}
          commentValue={response.comments}
          error={
            errors[
              `${question.id}${
                response.associatedId ? `_${response.associatedId}` : ""
              }_comment`
            ]
          }
          enabled={true}
          name={`${name}_comment`}
          handleUpdateComment={handleChangeComment}
          variant="DEFENSE"
          required={commentRequired}
        />
      )}
    </div>
  );
}
