import styled from "styled-components";

const ViewTitle = styled.div.attrs({
  className: "mt-4",
})`
  color: ${(props) => props.theme.colors.black};
  font-size: 1.25rem;
  font-weight: 500;
`;
export default ViewTitle;
