import React from "react";

import * as S from "./styles";

interface Props {
  children: string;
  isSubmitted: boolean;
  className?: string;
}

const Pill = ({ children, className, isSubmitted }: Props) => {
  return (
    <S.Pill isSubmitted={isSubmitted} className={className}>
      <S.Label isSubmitted={isSubmitted}>{children}</S.Label>
    </S.Pill>
  );
};

export default Pill;
