import styled from "styled-components";

type PasswordReqProps = {
  fulfilled: boolean;
  theme: any;
};
export const PasswordRequirement = styled.span`
  align-items: center;
  color: ${(props: PasswordReqProps) =>
    props.fulfilled ? props.theme.masterColors.success : props.theme.masterColors.error};
  display: flex;
  font-size: 1rem;
  font-weight: 400;
  letter-spacing: 0.15px;
  padding: 3px 0;
`;

export const PasswordTitle = styled.span`
  color: ${({ theme }) => theme.masterColors.darkGrey};
  font-size: 0.875rem;
  font-weight: 500;
  letter-spacing: 1.25px;
`;

export const PasswordWrapper = styled.div`
  padding: 12px 15px 12px 10px;
  position: relative;

  &:before {
    content: "";
    position: absolute;
    left: 5px;
    top: -28px;
    // z-index: -1;
    width: 15px;
    height: 15px;
    background-color: ${({ theme }) => theme.colors.white};
    border-right: 2px solid ${({ theme }) => theme.colors.lightGrey};
    border-bottom: 2px solid ${({ theme }) => theme.colors.lightGrey};
    transform: rotate(-135deg) translate(-14px, -7px);
  }
`;

export const TooltipArrow = styled.div`
  position: absolute;
  top: -15px;
  left: 30px;
  border: 1px solid ${({ theme }) => theme.colors.black};

  &:before {
    content: "";
    display: block;
    width: 0;
    height: 0;
    position: absolute;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-right: 8px solid ${({ theme }) => theme.colors.black};
    left: -8px;
    top: 7px;
  }
`;
