import React from "react";
import { PaperProps } from "@material-ui/core/Paper";

import HandleBar from "./components/HandleBar";
import * as S from "./styles";

export interface DrawerProps {
  /**
   * `string` which dictates where the Drawer is anchored.
   * Can only be one of the following options:
   *
   * 1. `bottom`
   * 2. `left`
   * 3. `right`
   * 4. `top`
   */
  anchor: "bottom" | "left" | "right" | "top";
  content?: React.ReactNode;
  disableBackdrop?: boolean;
  disableRestoreFocus?: boolean;
  paperProps?: PaperProps;
  id: string;
  onClose: (e?: any) => void;
  onOpen: () => void;
  isOpen: boolean;
  /**
   * `boolean`, defaults to `true` which renders
   * the "handle" and "close button" elements
   */
  handleBar: boolean;
  className?: string;
  hideCloseButton?: boolean;
  /**
   * used with HandleBar component, boolean which is
   * used to only hide handle element
   */
  hideHandleOnly?: boolean;
  variant?: "permanent" | "persistent" | "temporary";
}

/**
 * Drawer component used with the Dashboard Drawer (Create New Document).
 * This component does not rely on MUI, unlike the other Drawer component.
 */
const Drawer = ({
  className,
  anchor,
  content,
  disableBackdrop,
  disableRestoreFocus,
  id,
  onClose,
  onOpen,
  paperProps,
  isOpen,
  hideHandleOnly,
  handleBar,
  hideCloseButton,
  variant,
}: DrawerProps) => {
  return (
    <S.Drawer className={className}>
      <S.SwipeableDrawer
        variant={variant}
        BackdropProps={{ invisible: disableBackdrop }}
        ModalProps={{ disableRestoreFocus }}
        PaperProps={paperProps}
        id={id}
        anchor={anchor}
        onClose={onClose}
        onOpen={onOpen}
        open={isOpen}
      >
        <S.DrawerBackground>
          {handleBar && (
            <HandleBar
              hideHandle={hideHandleOnly}
              onClose={onClose}
              hideCloseButton={hideCloseButton}
            />
          )}
          {content}
        </S.DrawerBackground>
      </S.SwipeableDrawer>
    </S.Drawer>
  );
};

Drawer.defaultProps = {
  anchor: "bottom",
  onClose: () => {},
  onOpen: () => {},
  isOpen: false,
  handleBar: true,
};

export { Drawer };
