import * as R from "ramda";
import { FormSummaryVm, FormTypeDTO, GET_ALL_FORMS } from "../forms/types";
import {
  CLEAR_SIGNATURE_DATA,
  CLEAR_STATUS,
  CLEAR_WORK_ORDERS,
  ClearStatusActionTypes,
  ClearStatusPayloadTypes,
  CONTINUE_DOCUMENT,
  CREATE_DOCUMENT,
  FETCH_SUMMARIZED_FORMS,
  FIND_WORK_ORDERS_FOR_NEW_DOCUMENT,
  GET_SIGNATURE_URL_FOR_PARTICIPANT,
  GET_SIGNATURE_URL,
  NewDocumentActionTypes,
  NewDocumentState,
  PRE_FILL_DOCUMENT,
  RESET_CURRENT_DOCUMENT,
  START_NEW_FORM,
  STORE_SIGNATURE_DATA,
  UPDATE_REHUDDLE_STARTED,
  UPLOAD_NEW_DOCUMENT,
  UPLOAD_SIGNATURE_TO_S3,
  ParticipantSignature,
  HasIdOrNameOrRoles,
  SUBMIT_DOCUMENT,
} from "./types";
import { areParticipantsEqual } from "components/FormController/helpers";

// helper for `getUniqueFormTypes`
// applied to each mapped value
// to get a value which we'll use
// to filter duplicates
const getUniqueTypesFrom = (x: FormTypeDTO) => {
  return x.id;
};

/**
 * **getUniqueFormTypes** is used to get and transform unique values
 * from the API response for summarized forms. The resulting value
 * is used with the Start New Document feature
 *
 * @param response - API response from `/api/forms?summary=true`
 */
export const getUniqueFormTypes = (
  response?: Array<FormSummaryVm>
): Array<FormTypeDTO> => {
  if (!response) return [];

  return R.uniqBy(
    (formSummary: FormTypeDTO) => getUniqueTypesFrom(formSummary),
    response.map((fs: FormSummaryVm) => {
      return {
        ...fs.type,
      };
    })
  );
};

/**
 * helper function used with the **clearStatus** action
 * which has an action type that corresponds to one of the
 * status type properties and a payload which contains an array
 * of enums that correspond to the children properties
 * of the given status type
 *
 * @actionType "loading" | "success" | "error"
 * @statusType feature name | "ALL"
 *
 * This method is driven by the newDocument action **clearStatus**
 *
 * example which resets all values for `state.loading`:
 * clearStatus("loading", ["ALL"])
 *
 * It should be noted that when **resetStatusStateUsing** is used,
 * we are spreading the results onto the reducer. This way any previous
 * state values are preserved.
 */
export const resetStatusStateUsing = (
  actionType: ClearStatusActionTypes,
  statusTypes: ClearStatusPayloadTypes
): {
  [key: string]: boolean;
} => {
  // "ALL" will reset all keys to false
  // returning to initial state
  if (statusTypes[0] === "ALL") {
    return initialState[actionType];
  }

  // take each status type and construct
  // and obj with values set to false
  // which get spread onto the reducer
  const state = statusTypes.map((status) => ({
    [status]: false,
  }));

  // returning index since the value was created from
  // the argument which was an array
  return state[0];
};

export const initialState: NewDocumentState = {
  loading: {
    fetchSummarizedForms: false,
    workOrders: false,
    createDocument: false,
    submitDocument: false,
    continueDocument: false,
    uploadSignatureImageToS3: false,
    getSignatureUrlForParticipant: false,
  },
  error: {
    fetchSummarizedForms: false,
    workOrders: false,
    createDocument: false,
    submitDocument: false,
    continueDocument: false,
    uploadSignatureImageToS3: false,
    getSignatureUrlForParticipant: false,
  },
  success: {
    fetchSummarizedForms: false,
    workOrders: false,
    submitDocument: false,
    continueDocument: false,
    uploadSignatureImageToS3: false,
    getSignatureUrlForParticipant: false,
  },
  formTypes: [],
  forms: [],
  workOrders: [],
  selectedFormTemplateId: null,
  selectedFormTypeId: null,
  selectedWorkOrderId: null,
  signatureURLs: {
    writable: null,
    readable: null,
  },
  storedSignatureImages: [],
  documentParticipantSignatureUrls: [],
  currentDocument: null,
  isRehuddleStarted: false,
};

export function newDocumentReducer(
  state = initialState,
  action: NewDocumentActionTypes
): NewDocumentState {
  const { type, response, payload, clearStatusPayload } = action;
  switch (type) {
    case FETCH_SUMMARIZED_FORMS.REQUEST:
    case GET_ALL_FORMS.REQUEST:
      return {
        ...state,
        loading: {
          ...state.loading,
          fetchSummarizedForms: true,
        },
        error: {
          ...state.error,
          fetchSummarizedForms: false,
        },
      };
    case FETCH_SUMMARIZED_FORMS.SUCCESS:
    case GET_ALL_FORMS.SUCCESS:
      return {
        ...state,
        loading: {
          ...state.loading,
          fetchSummarizedForms: false,
        },
        forms: response,
      };
    case FETCH_SUMMARIZED_FORMS.FAILURE:
    case GET_ALL_FORMS.FAILURE:
      return {
        ...state,
        loading: {
          ...state.loading,
          fetchSummarizedForms: false,
        },
        error: {
          ...state.error,
          fetchSummarizedForms: true,
        },
      };
    case FIND_WORK_ORDERS_FOR_NEW_DOCUMENT.REQUEST:
      return {
        ...state,
        loading: {
          ...state.loading,
          workOrders: true,
        },
        workOrders: [],
      };
    case FIND_WORK_ORDERS_FOR_NEW_DOCUMENT.SUCCESS:
      return {
        ...state,
        loading: {
          ...state.loading,
          workOrders: false,
        },
        workOrders: response && response.content,
      };

    case FIND_WORK_ORDERS_FOR_NEW_DOCUMENT.FAILURE:
      return {
        ...state,
        loading: {
          ...state.loading,
          workOrders: false,
        },
        error: {
          ...state.error,
          workOrders: true,
        },
      };

    /**
     * Used to store the values which were selected
     * from the "Start New Form" "bottom drawer/modal" UI
     */
    case START_NEW_FORM:
      return {
        ...state,
        selectedFormTemplateId: payload.selectedFormTemplateId,
        selectedFormTypeId: payload.selectedFormTypeId,
        selectedWorkOrderId: payload.selectedWorkOrderId,
      };

    case CREATE_DOCUMENT.REQUEST:
      return {
        ...state,
        loading: {
          ...state.loading,
          createDocument: true,
        },
        error: {
          ...state.error,
          createDocument: false,
        },
      };

    case CREATE_DOCUMENT.SUCCESS:
      return {
        ...state,
        loading: {
          ...state.loading,
          createDocument: false,
        },
        currentDocument: response,
        isRehuddleStarted: !!payload?.isRehuddle,
      };

    case CREATE_DOCUMENT.FAILURE:
      return {
        ...state,
        loading: {
          ...state.loading,
          createDocument: false,
        },
        error: {
          ...state.error,
          createDocument: true,
        },
      };

    case PRE_FILL_DOCUMENT.REQUEST:
      return {
        ...state,
        loading: {
          ...state.loading,
          createDocument: true,
        },
        error: {
          ...state.error,
          createDocument: false,
        },
      };

    case PRE_FILL_DOCUMENT.SUCCESS:
      return {
        ...state,
        loading: {
          ...state.loading,
          createDocument: false,
        },
      };
    case PRE_FILL_DOCUMENT.NO_RESPONSES: {
      return {
        ...state,
        currentDocument: payload,
      };
    }

    case SUBMIT_DOCUMENT.HYDRATE:
    case PRE_FILL_DOCUMENT.HYDRATE_WITH_PREFILLED:
      return {
        ...state,
        currentDocument: payload,
      };

    case PRE_FILL_DOCUMENT.FAILURE:
      return {
        ...state,
        loading: {
          ...state.loading,
          createDocument: false,
        },
        error: {
          ...state.error,
          createDocument: true,
        },
      };

    case UPDATE_REHUDDLE_STARTED:
      return {
        ...state,
        isRehuddleStarted: payload.isRehuddleStarted,
      };

    case UPLOAD_NEW_DOCUMENT.REQUEST:
      return {
        ...state,
        loading: {
          ...state.loading,
          submitDocument: true,
        },
        error: {
          ...state.error,
          submitDocument: false,
        },
      };

    case UPLOAD_NEW_DOCUMENT.SUCCESS:
      return {
        ...state,
        loading: {
          ...state.loading,
          submitDocument: false,
        },
        success: {
          ...state.success,
          submitDocument: true,
        },
      };

    case UPLOAD_NEW_DOCUMENT.FAILURE:
      return {
        ...state,
        loading: {
          ...state.loading,
          submitDocument: false,
        },
        error: {
          ...state.loading,
          submitDocument: true,
        },
      };

    case CLEAR_STATUS.LOADING:
      return {
        ...state,
        loading: {
          ...state.loading,
          ...resetStatusStateUsing("loading", clearStatusPayload),
        },
      };

    case CLEAR_STATUS.SUCCESS:
      return {
        ...state,
        success: {
          ...state.success,
          ...resetStatusStateUsing("success", clearStatusPayload),
        },
      };

    case CLEAR_STATUS.ERROR:
      return {
        ...state,
        error: {
          ...state.error,
          ...resetStatusStateUsing("error", clearStatusPayload),
        },
      };

    case RESET_CURRENT_DOCUMENT:
      return {
        ...initialState,
        formTypes: state.formTypes,
        forms: state.forms,
      };

    case CONTINUE_DOCUMENT.REQUEST:
      return {
        ...state,
        currentDocument: null,
        loading: {
          ...state.loading,
          continueDocument: true,
        },
        error: {
          ...state.error,
          continueDocument: false,
        },
      };

    case CONTINUE_DOCUMENT.SUCCESS:
      return {
        ...state,
        currentDocument: response,
        loading: {
          ...state.loading,
          continueDocument: false,
        },
        success: {
          ...state.error,
          continueDocument: true,
        },
      };

    case CONTINUE_DOCUMENT.FAILURE:
      return {
        ...state,
        currentDocument: null,
        loading: {
          ...state.loading,
          continueDocument: false,
        },
        error: {
          ...state.error,
          continueDocument: true,
        },
      };

    case GET_SIGNATURE_URL.SUCCESS:
      return {
        ...state,
        signatureURLs: {
          writable: response["writableUrl"],
          readable: response["readableUrl"],
        },
      };

    case UPLOAD_SIGNATURE_TO_S3.REQUEST:
      return {
        ...state,
        loading: {
          ...state.loading,
          uploadSignatureImageToS3: true,
        },
        error: {
          ...state.error,
          uploadSignatureImageToS3: false,
        },
      };

    case UPLOAD_SIGNATURE_TO_S3.SUCCESS:
      return {
        ...state,
        loading: {
          ...state.loading,
          uploadSignatureImageToS3: false,
        },
      };

    case UPLOAD_SIGNATURE_TO_S3.FAILURE:
      return {
        ...state,
        loading: {
          ...state.loading,
          uploadSignatureImageToS3: false,
        },
        error: {
          ...state.error,
          uploadSignatureImageToS3: true,
        },
      };

    case GET_SIGNATURE_URL_FOR_PARTICIPANT.REQUEST:
      return {
        ...state,
        loading: {
          ...state.loading,
          getSignatureUrlForParticipant: true,
        },
        error: {
          ...state.error,
          getSignatureUrlForParticipant: false,
        },
      };

    case GET_SIGNATURE_URL_FOR_PARTICIPANT.SUCCESS:
      return {
        ...state,
        loading: {
          ...state.loading,
          getSignatureUrlForParticipant: false,
        },
        documentParticipantSignatureUrls: [
          ...state.documentParticipantSignatureUrls,
          payload,
        ],
      };

    case GET_SIGNATURE_URL_FOR_PARTICIPANT.FAILURE:
      return {
        ...state,
        loading: {
          ...state.loading,
          getSignatureUrlForParticipant: false,
        },
        error: {
          ...state.error,
          getSignatureUrlForParticipant: true,
        },
      };

    case STORE_SIGNATURE_DATA:
      return {
        ...state,
        storedSignatureImages: state.storedSignatureImages.reduce(
          (allImages, currentImage) => {
            if (
              !allImages.find((x) => {
                return areParticipantsEqual(x, currentImage);
              })
            ) {
              /**
               * `payload` is the initial value of `allImages`, so as
               *  we iterate through `state.storedSignatureImages`, if
               *  we find a duplicate it means the newer value is
               *  already in the `allImages` array. If we don't push
               *  duplicates, then newer values will overwrite older
               *  values.
               *
               */
              allImages.push(currentImage);
            }
            return allImages;
          },
          [payload as ParticipantSignature]
        ),
      };

    case CLEAR_SIGNATURE_DATA:
      return {
        ...state,
        storedSignatureImages: state.storedSignatureImages.filter(
          (storedImage) =>
            !areParticipantsEqual(storedImage, payload as HasIdOrNameOrRoles)
        ),
      };

    case CLEAR_WORK_ORDERS:
      return {
        ...state,
        workOrders: [],
      };

    // TEMPORARY UNTIL REFACTOR
    case "UPDATE_CURRENT_DOCUMENT_VALUES":
      return {
        ...state,
        currentDocument: {
          ...state.currentDocument,
          ...action.response,
        },
      };

    default:
      return state;
  }
}
