import React from "react";
import { makeStyles } from "@material-ui/core";

import { Tabs, Tab } from "components/common/Tabs";

const tabsStyles = makeStyles({
  root: {
    overflow: "visible",
    borderBottom: "none",
    marginTop: "1rem"
  },
  fixed: {
    overflow: "visible !important"
  },
  indicator: {
    display: "none"
  }
});

const tabStyles = makeStyles({
  root: {
    maxWidth: "none",
    textTransform: "none",
    overflow: "visible",
    padding: 0,
    opacity: 1,
    textAlign: "left",
    fontWeight: 400,
    lineHeight: "normal",
  },
  wrapper: {
    display: "block"
  }
});

const VerticalTabs = (props) => {
  const classes = tabsStyles();
  return <Tabs {...props} classes={classes} orientation="vertical" />;
};

interface VerticalTabProps {
  children: React.ReactNode;
}

const VerticalTab = ({ children, ...rest }: VerticalTabProps) => {
  const classes = tabStyles();
  return <Tab disableRipple {...rest} classes={classes} label={children} />;
};

export { VerticalTab, VerticalTabs };
