import { CALL_API } from "middleware/api";

import {
  GET_ALL_FORM_TYPE_STATS,
  GetAllFormTypeStatsProps,
  GetAllFormTypeStatsResponse,
} from "./types";

export const getAllFormTypeStats = ({
  clientGroupIds,
  maxSubmissionDate,
  minSubmissionDate,
  onlySubordinates = false,
  participantTreeFilter,
}: GetAllFormTypeStatsProps) => (dispatch, getState) => {
  const { system } = getState();

  let endpoint = "reports/form-types?";

  endpoint += `onlySubordinates=${onlySubordinates}`;
  if (participantTreeFilter) {
    endpoint += `&participantTreeFilter=${participantTreeFilter}`;
  }
  if (clientGroupIds) endpoint += `&clientGroupIds=${clientGroupIds}`;
  if (minSubmissionDate) endpoint += `&minSubmissionDate=${minSubmissionDate}`;
  if (maxSubmissionDate) endpoint += `&maxSubmissionDate=${maxSubmissionDate}`;

  return dispatch({
    [CALL_API]: {
      endpoint: endpoint,
      options: {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${system.authToken}`,
        },
      },
      types: [
        GET_ALL_FORM_TYPE_STATS.REQUEST,
        GET_ALL_FORM_TYPE_STATS.SUCCESS,
        GET_ALL_FORM_TYPE_STATS.FAILURE,
      ],
    },
  })
    .then((res: GetAllFormTypeStatsResponse) => res)
    .catch((err) => {
      throw new Error(err);
    });
};
export type GetAllFormTypeStats = typeof getAllFormTypeStats;
