import { ClientGroup } from "store/clientGroups/types";
import { APIResponse, Pagination } from "../common/types";

export const NAME = "DATA_SOURCE";

// --------------------------------------------------------------------------
// ? ACTIONS
// --------------------------------------------------------------------------

export const GET_DATA_SOURCE_VALUES = {
  REQUEST: `${NAME}/GET_DATA_SOURCE_VALUES/REQUEST`,
  SUCCESS: `${NAME}/GET_DATA_SOURCE_VALUES/SUCCESS`,
  FAILURE: `${NAME}/GET_DATA_SOURCE_VALUES/FAILURE`,
  CLEAR:   `${NAME}/GET_DATA_SOURCE_VALUES/CLEAR`,
};

// --------------------------------------------------------------------------
// ? TYPE DEFINITIONS FOR API VALUES
// The following types describe API response values which are
// directly related to the "Document" feature
// --------------------------------------------------------------------------

type AddressProps = {
  city: string;
  id: number;
  line1: string;
  line2: string;
  line3: string;
  state: string;
  zip: string;
};

type GeolocationProps = {
  latitude: number;
  longitude: number;
};

type GroupsProps = {
  detail: string;
  id: number;
  isCompletedData: boolean;
  name: string;
  source: SourceType;
};

type WorkLocationProps = {
  emergencyAddress: AddressProps;
  geolocation: GeolocationProps;
  id: number;
  isCompletedData: boolean;
  locationId: string;
  locationInformationUrl: string;
  locationType: string;
  name: string;
  nickname: string;
  physicalAddress: AddressProps;
  preferUsersLocation: boolean;
};

type TeamsProps = {
  description: string;
  externalId: string;
  id: number;
  name: string;
};

type ContractingCompanyProps = {
  active?: boolean;
  address1?: string;
  address2?: string;
  address3?: string;
  city?: string;
  description?: string;
  id?: number;
  name: string;
  state?: string;
  title?: string;
  zip?: string;
};

type SourceType = "API" | "USER_GENERATED" | "API_INCOMPLETE";
type ParticipantType = "EMPLOYEE" | "CONTRACTOR";

export type DataSourceValueContent = {
  id: number;
  [keyName: string]: any;
};

export type DataSourceValueDTO = {
  active?: boolean;
  content: DataSourceValueContent;
  dataSourceId: number;
  id: number;
  source?: string;
  uniqueId: string;
  softDeleted: boolean;
};

// ParticipantDTO
export type ParticipantProps = {
  clientGroups?: Array<ClientGroup> | null;
  contractingCompany?: ContractingCompanyProps | null;
  dataSourceId?: number | null;
  email: string;
  firstName?: string | null;
  fullName: string;
  id: number;
  lastName?: string | null;
  name?: string;
  nickname?: string | null;
  participantType: ParticipantType;
  primaryGroupId?: number | null;
  softDeleted?: boolean | null;
  source: SourceType;
  supervisor?: ParticipantProps | null;
  teams?: TeamsProps[] | null;
  workLocation: WorkLocationProps | null;
};

export type ParticipantsDataProps = {
  content: DataSourceValueDTO[];
  totalElements: number;
  last: boolean;
  totalPages: number;
  size: number;
  number: number;
  sort: {
    unsorted: boolean;
    sorted: boolean;
    empty: boolean;
  };
  first: boolean;
  numberOfElements: number;
  empty: boolean;
};

interface Mapping {
  dateFormat: null;
  source: string;
  target: string;
}

interface APIConfig {
  authType: "NONE";
  contentResponseName: null;
  id: 1801;
  lastUpdatedRequestDateFormat: null;
  lastUpdatedRequestName: null;
  mappings: Mapping[];
  maxRecords: null;
  pageRequestLocation: string;
  pageRequestName: string;
  pageRequestOneIndexed: boolean;
  pageResponseLocation: string;
  paginated: boolean;
  requiresCerts: boolean;
  scheduleType: string;
  searchUrl: string;
  sizeRequestName: string;
  sizeRequestValue: number;
  sortRequestName: null;
  sortRequestValue: null;
  startingPage: number;
  totalCountResponseName: string;
  totalPagesResponseName: string;
  url: string;
}

interface Author {
  email: string;
  firstName: string;
  fullName: string;
  id: number;
  lastName: string;
  name: string;
  nickname: string | null;
}

export interface CSVMapping {
  id: number | null;
  columnName: string;
  valueName: string | null;
  valueType: "ARRAY" | "BOOLEAN" | "NUMBER" | "STRING" | null;
  required: boolean;
}

/** DTO for data sources */
export interface DataSource {
  apiConfig: APIConfig | null;
  author: Author | null; // TODO remove null
  csvMappings: CSVMapping[];
  dataSourceKey: string;
  description: string | null;
  id: number | null;
  title: string;
  softDeleteMissing: boolean;
  type: string;
  uniqueIdField: string;
  status: "PUBLISHED" | "DRAFT" | "DISABLED";
}

/** view model for data sources */
export type DataSourceVM = {
  author: string | null;
  dataSourceKey: string;
  description: string | null;
  id: number;
  managed: string;
  status: "PUBLISHED" | "DRAFT" | "DISABLED";
  title: string;
  type: string;
  uses: number;
};

export interface PaginatedDataSource extends Pagination {
  content: Array<DataSourceValueDTO>;
}

export interface PaginatedDataSourceResponse extends APIResponse {
  response?: PaginatedDataSource;
}

export interface DataSourceResponse extends APIResponse {
  response?: DataSource;
}

export interface GetDataSourceTemplateResponse extends APIResponse {
  response?: Blob;
}

export interface PostDataSourceCSVResponse extends APIResponse {
  response?: {
    createdBy: string;
    dataSourceId: number;
    dataSourceKey: string;
    exception: string | null;
    id: number;
    message: string;
    recordsAdded: number | null;
    recordsRetrieved: number | null;
    recordsSoftDeleted: number;
    recordsUpdated: number | null;
    status: "FILE_UPLOAD_SUCCESS" | "FILE_UPLOAD_FAILURE";
    timeApiCallEnded: string | null;
    timeEnded: string | null;
    timeStarted: string | null;
    type: string;
  };
}

// --------------------------------------------------------------------------
// ? TYPE DEFINITIONS REDUX/LOCAL/APP
// The following types describe the reducer and actions
// --------------------------------------------------------------------------

export interface LastModifiedDate {
  [key: string]: string;
}

export interface DataSourceState {
  loading: {
    getDataSourceValues: {
      [dataSourceKey: string]: boolean;
    };
  };
  error: {
    getDataSourceValues: {
      [dataSourceKey: string]: string | undefined;
    };
  };
  success: {
    getDataSourceValues: {
      [dataSourceKey: string]: boolean;
    };
  };
  lastModifiedDate: LastModifiedDate;
  lastPageLoaded: {
    [dataSourceKey: string]: number | undefined;
  };
  totalCount: {
    [dataSourceKey: string]: number | undefined;
  };
  isLastPage: {
    [dataSourceKey: string]: boolean;
  };
  data: {
    /**
     * We fire an action for each unique `dataSourceKey` within the
     * Questions for the current document. We're store the resulting
     * Data Source values under a key with value of the `dataSourceKey`
     */
      [keyName: string]: Array<DataSourceValueDTO>;
  };
}

interface PaginatedDataSourceValuesResponse extends Pagination {
  content: Array<DataSourceValueDTO>;
}

export interface SearchDataSourceValuesAction extends APIResponse {
  type: "SEARCH_DATA_SOURCE_VALUES_REQUEST"
        | "SEARCH_DATA_SOURCE_VALUES_SUCCESS"
        | "SEARCH_DATA_SOURCE_VALUES_FAILURE";
  response: PaginatedDataSourceValuesResponse;
  keyName: string;
}

export interface GetDataSourceValuesAction extends APIResponse {
  type:
    | typeof GET_DATA_SOURCE_VALUES.SUCCESS
    | typeof GET_DATA_SOURCE_VALUES.REQUEST
    | typeof GET_DATA_SOURCE_VALUES.FAILURE
    | typeof GET_DATA_SOURCE_VALUES.CLEAR;
  response: PaginatedDataSourceValuesResponse;
  keyName: string;
}

export type DataSourceActionTypes = GetDataSourceValuesAction;

// --------------------------------------------------------------------------
// ? TYPE DEFINITIONS FOR GLOBAL / APP 
// Types which are used across multiple components
// and directly relates to Data Source values
// --------------------------------------------------------------------------
export type FilterBy = "id" | "name" | "type" | "firstName" | "lastName" | "nickname" | "fullName"
export type SortBy = FilterBy
