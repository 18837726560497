import styled from "styled-components";

import { remCalc, toRgba } from "themes/helpers";
import mediaQueries from "themes/mediaQueries";

export const WrapperForNegMargin = styled.div`
  margin-right: -16px;
  margin-top: -8px;
`;

export const MobileFiltersHeader = styled.div.attrs({})`
  color: ${({ theme }) => toRgba(theme.colors.black, 0.87)};
  font-size: ${remCalc(20)};
  font-weight: 700;
  letter-spacing: 0.26px;
  margin-top: 5px;
  text-align: left;
`;

export const DividerNegMargin = styled.hr`
  margin-left: -15px;
  margin-right: -15px;
`;

export const FilterSection = styled.div``;

export const FilterSectionHeader = styled.div`
  color: ${({ theme }) => theme.colors.black};
  font-size: 0.875rem;
  text-align: left;
`;

type FilterHeaderWrapperType = {
  active?: boolean;
  key?: number;
};
export const FilterHeaderWrapper = styled.div.attrs({
  className: "d-flex flex-row align-items-center",
})<FilterHeaderWrapperType>`
  cursor: pointer;
  background-color: ${(props) => props.active && props.theme.colors.activeFill};
  margin-right: -15px;
  display: flex;
  justify-content: start;
  align-items: center;
`;

export const FilterHeader = styled.div`
  color: ${({ theme }) => theme.masterColors.darkGrey};
  cursor: pointer;
  display: flex;
  font-size: 1rem;
  justify-content: space-between;
  margin-bottom: 10px;
  padding-top: 15px;
  text-align: left;
`;

export const FilterOption = styled.div.attrs({
  className: "col-12 pl-0",
})``;

export const Chevron = styled.i.attrs({
  className: "icon-icons8-expand_arrow",
})`
  font-size: 1.5rem;
  margin-right: 40px;
`;

export const ApplyButtonWrapper = styled.div`
  padding-right: 16px;
  text-align: center;
`;

// Greys aren't changing with theme, it seems like
export const ApplyButton = styled.div`
  border-radius: 4px;
  border: 1px solid ${({ theme }) => toRgba(theme.colors.black, 0.1)};
  color: ${(props) => props.theme.colors.primary};
  font-size: 0.875rem;
  font-weight: 500;
  letter-spacing: 1.25;
  margin: 16px 22px 16px 0;
  padding: 8px 0;
  text-transform: uppercase;
  width: 100%;
`;

export const MyDocuments = styled.div`
  width: 100%;

  @media (${mediaQueries.maxTablet}) {
    padding-bottom: 150px;
  }
`;

export const SearchResults = styled.div`
  max-height: 50vh;

  overflow-y: scroll;
`;
