import React, { useContext } from "react";
import TooltipBase from "@material-ui/core/Tooltip";
import { makeStyles } from "@material-ui/core/styles";
import { ThemeContext } from "styled-components";
import { toRgba } from "themes/helpers";

const useStyles = (props) => {
  const theme = useContext(ThemeContext);
  return makeStyles({
    tooltip: {
      background: theme.colors.white,
      boxShadow: `0px 1px 3px ${toRgba(theme.colors.black, 0.2)}`,
      color: theme.colors.black,
      position: "relative",
      border: `1px solid ${theme.colors.lightGrey}`,
      ...props.styles,
    },
  })(props);
};

function Tooltip(props) {
  const { placement, styles, title } = props;
  const { ...classes } = useStyles({
    styles,
  });

  return <TooltipBase classes={classes} placement={placement} {...props} title={title} />;
}

export default Tooltip;
