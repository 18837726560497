import React, { useMemo } from "react";
import { debounce } from "lodash";
import styled from "styled-components";

import {
  timeFilterOptions,
  statusFilterOptions,
} from "components/helpers/filters";
import { FilterSelect } from "components/common/FilterSelect";
import BaseSearchBar from "components/filters/BaseSearchBar";
import useGroupTerm from "util/hooks/whiteLabel/useGroupTerm";
import { DateRange, DateRangeValue } from "components/common/DateRange";
import { CategoryDTO } from "store/categories/types";
import { useDispatch, useSelector } from "react-redux";
import { resourcesFiltersSelector } from "store/filters/resources/selectors";
import { ResourcesFiltersArgs } from "store/filters/resources/types";
import { setResourcesFilters } from "store/filters/resources/actions";
import { ThunkDispatch } from "redux-thunk";
import { AppState } from "store";
import { Action } from "redux";
import moment from "moment";

const FiltersContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  margin: 1rem 0;
`;

const DateRangeWrapper = styled.div`
  margin-bottom: 32px;
`;

type ResourcesFiltersProps = {
  authors: string[];
  categories: CategoryDTO[];
  onUpdateFilters: (filters: ResourcesFiltersArgs["filterParams"]) => void;
};

const ResourcesFilters = ({
  authors = [],
  categories = [],
  onUpdateFilters,
}: ResourcesFiltersProps) => {
  // Group config terms
  const operationExperiencesTerm = useGroupTerm(
    "operationalExperiences",
    "noun",
    "plural",
    "Operational Experiences"
  );
  const defensesTerm = useGroupTerm("defenses", "noun", "plural", "Defenses");

  // Resource types for filter
  const resourceTypes = useMemo(() => {
    return [
      { id: -1, value: "All Types" },
      { id: "OPERATIONAL_EXPERIENCE", value: operationExperiencesTerm },
      { id: "DEFENSE", value: defensesTerm },
    ];
  }, [operationExperiencesTerm, defensesTerm]);

  const filtersSelect = useSelector(resourcesFiltersSelector);
  const dispatch = useDispatch<ThunkDispatch<AppState, void, Action>>();
  const { filterParams } = filtersSelect;

  const updateFilters = (
    filter: string,
    value: string | DateRangeValue | null
  ) => {
    const resetDateValue =
      filter === "timeFilterType" && value !== "CUSTOM_RANGE";
    const newFilters = {
      ...filterParams,
      [filter]: value,
      ...(resetDateValue && { dateRange: null }),
    };
    dispatch(setResourcesFilters(newFilters));
    onUpdateFilters(newFilters);
  };

  const handleSearch = debounce(
    (query: string) => updateFilters("query", query),
    500
  );

  const mappedAuthors: { id: string | number; value: string }[] = authors.map(
    (author) => ({
      id: author,
      value: author,
    })
  );
  mappedAuthors.unshift({ id: -1, value: "All Last Modified By" });

  const mappedCategories = categories.map((category) => ({
    id: category.id,
    value: category.title,
  }));
  mappedCategories.unshift({ id: -1, value: "All Categories" });

  const initialCustomDates = filterParams.dateRange
    ? {
        // @ts-ignore
        from: moment(filterParams.dateRange?.startDate),
        // @ts-ignore
        to: moment(filterParams.dateRange?.endDate),
      }
    : {};

  return (
    <>
      <FiltersContainer>
        <FilterSelect
          name="resourceType"
          value={filterParams.resourceType}
          label=""
          options={resourceTypes}
          handleChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
            updateFilters("resourceType", e.target.value)
          }
        />
        <FilterSelect
          name="status"
          value={filterParams.status}
          label=""
          options={statusFilterOptions}
          handleChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
            updateFilters("status", e.target.value);
          }}
        />
        <FilterSelect
          name="lastModifiedByEmail"
          value={filterParams.lastModifiedByEmail}
          label=""
          options={mappedAuthors} // @TODO waiting for endpoint to get all authors
          handleChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
            updateFilters("lastModifiedByEmail", e.target.value)
          }
        />
        <FilterSelect
          name="categoryIds"
          value={filterParams.categoryIds}
          label=""
          options={mappedCategories}
          handleChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
            updateFilters("categoryIds", e.target.value)
          }
        />
        <FilterSelect
          name="timeFilterType"
          value={filterParams.timeFilterType}
          label=""
          options={timeFilterOptions}
          handleChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
            updateFilters("timeFilterType", e.target.value)
          }
        />
        <div style={{ width: "398px" }}>
          <BaseSearchBar
            onSearch={handleSearch}
            initialQuery={filterParams.query}
          />
        </div>
      </FiltersContainer>
      {filterParams.timeFilterType === "CUSTOM_RANGE" && (
        <DateRangeWrapper>
          <DateRange
            handleChange={(value: DateRangeValue) => {
              updateFilters("dateRange", value);
            }}
            labelId="customRangeId"
            name="customRange"
            onClear={() => {
              updateFilters("dateRange", null);
              updateFilters("timeFilterType", "ALL_TIME");
            }}
            initialValues={initialCustomDates}
          />
        </DateRangeWrapper>
      )}
    </>
  );
};

export default ResourcesFilters;
