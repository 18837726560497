import styled from "styled-components";

export const DocumentHistory = styled.div``;

export const DocumentHistoryWrapper = styled.div`
  margin-top: -10px; // temporary
  text-align: left;
`;

const cellStyles = `
  padding-top: 0.8125rem;
  padding-bottom: 0.8125rem;`;

const headerStyles = `
  text-transform: uppercase;
  font-size: 0.75rem;
  color: ${({ theme }) => theme.masterColors.darkGrey};
  padding-top: 0.8125rem;
  padding-bottom: 0.8125rem;`;

const colStyles = `
  color: ${({ theme, linkTo }) => linkTo ? theme.colors.primary : theme.masterColors.black};
  cursor: ${({ linkTo }) => linkTo && "pointer"};
  font-size: 0.875rem;
`;

export const Column = styled.div`
  ${cellStyles}
  ${headerStyles}
`;

export const Data = styled.div`
  ${cellStyles}
  ${colStyles}
`;

export const DataLink = styled.a<{linkTo?: string}>`
  ${cellStyles}
  ${colStyles}
  text-decoration: underline;
`;
