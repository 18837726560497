import React from "react";

import * as S from "./styles";

interface Props {
  className?: string;
  closeDrawer?: () => void;
  endAdornment?: string | React.ReactNode;
  right?: boolean;
  text: string | React.ReactNode;
}

/**
 * Handles rendering a Title Bar/ Header component
 * for the Drawer component.
 */
const Header = ({ text, closeDrawer, className = "", right = true, endAdornment }: Props) => (
  <S.H2 className={className || "d-flex justify-content-between px-3 pt-2 pb-3 mb-3"}>
    <S.Chevron right={right} onClick={closeDrawer} />
    <S.TextWrapper>{text}&nbsp;{endAdornment}</S.TextWrapper>
    <span />
  </S.H2>
);

export default Header;
