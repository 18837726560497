import * as React from "react";

import { DocumentQuestionResponseVm } from "store/documents/types";
import { FieldErrorType } from "components/FormController/components/Question";
import { FormDTO, QuestionSelectionsDTO } from "store/forms/types";
import CheckboxInput from "components/forms/CheckboxInput";

import { MultiResponseQuestionProps } from "../../types";

import { CheckBoxVariantType } from "./CheckboxGroup";
import { CommentRefType } from "components/forms/Comment";
import { useFormikContext } from "formik";

interface RenderCheckboxProps extends Partial<MultiResponseQuestionProps> {
  questionId: number;
  questionRootId: number;
  selection: QuestionSelectionsDTO;
  responses: Array<DocumentQuestionResponseVm>;
  setQuestionResponses: MultiResponseQuestionProps["setQuestionResponses"];
  variant: CheckBoxVariantType;
  enableComments: boolean;
  error: FieldErrorType;
  commentError: string;
}

function RenderCheckbox({
  selection,
  responses,
  setQuestionResponses,
  questionId,
  questionRootId,
  variant,
  enableComments,
  error,
  commentError,
}: RenderCheckboxProps) {
  const commentRef = React.useRef<CommentRefType>(null);

  const matchingResponse = React.useMemo(
    () =>
      responses.find(
        (res) =>
          res.associatedId === selection.id || res.answer === selection.title
      ),
    [responses, selection]
  );

  const checked = React.useMemo(
    () =>
      (matchingResponse && matchingResponse.associatedId === selection.id) ||
      matchingResponse?.answer === selection.title ||
      false,
    [matchingResponse, selection]
  );

  const commentValue = React.useMemo(
    () => (matchingResponse && matchingResponse.comments) || "",
    [matchingResponse]
  );

  const commentRequired = selection.properties?.commentRequired;

  const handleChange = React.useCallback(() => {
    if (checked) {
      setQuestionResponses(
        responses.filter(
          (r) => r.associatedId !== selection.id && r.answer !== selection.title
        )
      );
    } else {
      setQuestionResponses(
        responses.concat({
          answer: selection.title,
          associatedId: selection.id,
          associatedRootId: selection.rootId,
          questionId,
          questionRootId,
          timeAnswered: new Date().toISOString(),
        })
      );
    }
  }, [questionId, setQuestionResponses, responses, selection, checked]);

  const handleUpdateComment = React.useCallback(
    (comments = "") => {
      const updatedResponses = responses.map((response) => {
        if (
          response.questionId === questionId &&
          response.associatedId === selection.id
        ) {
          return {
            ...response,
            comments,
            timeAnswered: new Date().toISOString(),
          };
        }
        return response;
      });
      setQuestionResponses(updatedResponses);
    },
    [setQuestionResponses, responses, questionId, selection.id]
  );

  const handleClearComment = () => {
    handleUpdateComment("");
    commentRef.current?.focus();
  };

  return (
    <CheckboxInput
      checked={checked}
      enabledComment={enableComments && checked}
      commentRef={commentRef}
      commentValue={commentValue}
      commentRequired={commentRequired}
      variant={variant}
      onSetActive={handleChange}
      name={selection.id}
      label={selection.title}
      id={selection.id}
      questionId={questionId}
      handleClearComment={handleClearComment}
      handleChange={handleChange}
      error={error}
      commentError={commentError}
      handleUpdateComment={handleUpdateComment}
    />
  );
}

interface Props extends Partial<MultiResponseQuestionProps> {
  questionId?: number;
  questionRootId?: number;
  variant: CheckBoxVariantType;
  enableComments?: boolean;
  selections: Array<QuestionSelectionsDTO>;
  responses: Array<DocumentQuestionResponseVm>;
  setQuestionResponses: MultiResponseQuestionProps["setQuestionResponses"];
  error: FieldErrorType;
}

const RenderCheckboxes = ({
  variant,
  enableComments = false,
  selections,
  responses,
  setQuestionResponses,
  questionId,
  questionRootId,
  error,
  ...props
}: Props) => {
  const { errors } = useFormikContext<FormDTO>();

  if (!questionId || !questionRootId) return <></>;

  return (
    <>
      {selections.map((s) => {
        const commentError = errors[`${questionId}_${s.id}_comment`];
        return (
          <RenderCheckbox
            {...props}
            key={s.id}
            variant={variant}
            enableComments={enableComments}
            selection={s}
            responses={responses}
            setQuestionResponses={setQuestionResponses}
            questionId={questionId}
            questionRootId={questionRootId}
            error={error}
            commentError={commentError}
          />
        );
      })}
    </>
  );
};

export default RenderCheckboxes;
