import { buildFilteredEndpoint } from "store/common/apiUtilities";
import { CALL_API } from "middleware/api";
import {
  DuplicateResourceAction,
  GET_DEFENSE_WRITEABLE_URL,
  GET_RESOURCE_BY_ID_AND_TYPE,
  GET_RESOURCE_BY_ID,
  GET_RESOURCES,
  GetResourceByIdAction,
  GetResourceByIdAndTypeAction,
  Resource,
  Resources,
  ResourceType,
  ResourceValues,
  SAVE_RESOURCE,
  GET_RESOURCE_AUTHORS,
  GetResourceAuthorsAction,
  SubmitResourceAction,
  ADD_RESOURCE,
  ADD_TAG_TO_OE,
  REMOVE_TAG_FROM_OE,
} from "./types";
import { getResourceTitleKey } from "components/clientAdmin/resources/helpers";
import { PaginationParams, FilterParams } from "store/common/types";
import { DefenseDocument } from "store/documents/types";
import { CategoryDTO } from "store/categories/types";
import { ResourceStatus } from "@rtslabs/field1st-fe-common";

export const uploadDefenseAttachment = (
  defenseId: number,
  file: File
) => async (dispatch) => {
  const { response: urlResponse, error } = await dispatch(
    getWriteableDefenseUrl(defenseId)
  );

  // if getWriteableDefenseUrl fails, return the error
  if (error) {
    return { error };
  }

  // if getWriteableDefenseUrl succeeds
  if (urlResponse) {
    const { writableUrl, privateUrl } = urlResponse;

    // upload the file to the S3 url
    const uploadResponse = await uploadFileToS3(file, writableUrl);
    // if the upload succeeds, return the attachment
    if (uploadResponse.status === "success") {
      return {
        attachment: {
          description: "test description", // @TODO should be the first few lines of the file if they exist
          title: file.name,
          url: privateUrl,
        },
      };
      // if the upload fails, return the error
    } else {
      return { error: uploadResponse.error };
    }
  }
};

export const uploadDefenseDocuments = (defenseId: number, files: File[]) => (
  dispatch
): Promise<{
  uploadErrors: string[];
  uploadedDocuments: DefenseDocument[];
}> => {
  const uploadErrors: string[] = [];
  const uploadedDocuments: DefenseDocument[] = [];

  const uploads = files.map(async (file) => {
    // upload the file
    const defenseDocument = await dispatch(
      uploadDefenseAttachment(defenseId, file)
    );
    // if the file upload fails, add the error to the uploadErrors array
    if (defenseDocument.error) {
      uploadErrors.push(defenseDocument.error);
    }
    // if the file upload succeeds, add the document info to the uploadedDocuments array
    else if (defenseDocument.attachment) {
      uploadedDocuments.push(defenseDocument.attachment);
    }
  });

  return Promise.all(uploads).then(() => {
    return {
      uploadErrors: uploadErrors,
      uploadedDocuments,
    };
  });
};

export const addResource = (values: ResourceValues) => (
  dispatch,
  getState
): Promise<SubmitResourceAction> => {
  const { authToken } = getState().system;

  let endpoint;

  if (values.resourceType === "OPERATIONAL_EXPERIENCE") {
    endpoint = "resources/operational-experiences";
  } else if (values.resourceType === "DEFENSE") {
    endpoint = "resources/defenses";
  }

  return dispatch({
    [CALL_API]: {
      endpoint,
      options: {
        method: "POST",
        headers: {
          Authorization: `Bearer ${authToken}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(values),
      },
      types: [ADD_RESOURCE.REQUEST, ADD_RESOURCE.SUCCESS, ADD_RESOURCE.FAILURE],
    },
  });
};

/**
 * Save a resource
 * @param values values to submit
 * @param resourceType type of resource being submitted
 */
export const saveResource = (values: ResourceValues) => (
  dispatch,
  getState
): Promise<SubmitResourceAction> => {
  const { authToken } = getState().system;

  let endpoint;

  if (values.resourceType === "OPERATIONAL_EXPERIENCE") {
    endpoint = "resources/operational-experiences";
  } else if (values.resourceType === "DEFENSE") {
    endpoint = "resources/defenses";
  }

  return dispatch({
    [CALL_API]: {
      endpoint,
      options: {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${authToken}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(values),
      },
      types: [
        SAVE_RESOURCE.REQUEST,
        SAVE_RESOURCE.SUCCESS,
        SAVE_RESOURCE.FAILURE,
      ],
    },
  });
};

/**
 * Get a page of resources
 * @param paginationParams - object of pagination options, {page, size, sort}
 * @param filterParams -  object of filter name/value pairs
 */
export const getResources = (
  paginationParams?: PaginationParams,
  filterParams?: FilterParams
) => (dispatch, getState) => {
  const { authToken } = getState().system;

  const endpoint = buildFilteredEndpoint("resources", {
    ...paginationParams,
    ...filterParams,
  });

  return dispatch({
    [CALL_API]: {
      endpoint,
      options: {
        method: "GET",
        headers: {
          Authorization: `Bearer ${authToken}`,
          Accept: "application/json",
        },
      },
      types: [
        GET_RESOURCES.REQUEST,
        GET_RESOURCES.SUCCESS,
        GET_RESOURCES.FAILURE,
      ],
    },
  })
    .then((res: Resources) => res)
    .catch((err) => {
      throw new Error(err);
    });
};

/** fetch an operational experience by ID */
const getResourceByIdAndType = (id: number, type: ResourceType) => (
  dispatch,
  getState
) => {
  const { authToken } = getState().system;

  let endpoint;
  if (type === "OPERATIONAL_EXPERIENCE") {
    endpoint = "operational-experiences";
  } else {
    endpoint = "defenses";
  }

  return dispatch({
    [CALL_API]: {
      endpoint: `${endpoint}/${id}`,
      options: {
        method: "GET",
        headers: {
          Authorization: `Bearer ${authToken}`,
          Accept: "application/json",
        },
      },
      types: [
        GET_RESOURCE_BY_ID_AND_TYPE.REQUEST,
        GET_RESOURCE_BY_ID_AND_TYPE.SUCCESS,
        GET_RESOURCE_BY_ID_AND_TYPE.FAILURE,
      ],
    },
  })
    .then((res) => res)
    .catch((err) => {
      throw new Error(err);
    });
};

/**
 * Get a resource
 * @param id - ID of the resource
 */
export const getResourceById = (id: string | number) => (
  dispatch,
  getState
) => {
  const { authToken } = getState().system;

  return dispatch({
    [CALL_API]: {
      endpoint: `resources/${id}`,
      options: {
        method: "GET",
        headers: {
          Authorization: `Bearer ${authToken}`,
          Accept: "application/json",
        },
      },
      types: [
        GET_RESOURCE_BY_ID.REQUEST,
        GET_RESOURCE_BY_ID.SUCCESS,
        GET_RESOURCE_BY_ID.FAILURE,
      ],
    },
  })
    .then(async (res: GetResourceByIdAction) => {
      const { response, type } = res;
      let resourceResponse;
      if (type === GET_RESOURCE_BY_ID.SUCCESS) {
        resourceResponse = await dispatch(
          getResourceByIdAndType(response.id, response.resourceType)
        );
      }

      return {
        type,
        response: resourceResponse.response,
        resourceType: response.resourceType,
        resourceSummary: response,
      };
    })
    .catch((err) => {
      throw new Error(err);
    });
};

/**
 * Duplicate a resource
 * @param resource The resource to duplicate
 */
export const duplicateResource = (resource: Resource) => async (dispatch) => {
  const fullResourceResponse: GetResourceByIdAndTypeAction = await dispatch(
    getResourceById(resource.id)
  );

  if (fullResourceResponse.type === GET_RESOURCE_BY_ID.SUCCESS) {
    const fullResource = fullResourceResponse.response;
    const resourceType = resource.resourceType;
    const titleKey = getResourceTitleKey(resource);

    // clear the id so we create a new resource, prepend the title with [Copy of], and set the status to ACTIVE
    const duplicatedResource: ResourceValues = {
      ...fullResource,
      [titleKey]: `[Copy of] ${resource.title}`,
      status: "ACTIVE" as const,
      id: undefined,
      resourceType,
    };

    return dispatch(addResource(duplicatedResource))
      .then((res: DuplicateResourceAction) => res)
      .catch((err) => {
        throw new Error(err);
      });
  }
};

/**
 * Update a resource's status
 * @param resource The resource to update
 * @param status The status to apply
 */
export const updateResourceStatus = (
  resource: Resource,
  status: ResourceStatus
) => async (dispatch) => {
  const fullResourceResponse: GetResourceByIdAndTypeAction = await dispatch(
    getResourceById(resource.id)
  );
  if (fullResourceResponse.type === GET_RESOURCE_BY_ID.SUCCESS) {
    const updatedResource = {
      ...fullResourceResponse.response,
      status,
      resourceType: resource.resourceType,
    };
    return dispatch(saveResource(updatedResource))
      .then((res: DuplicateResourceAction) => res)
      .catch((err) => {
        throw new Error(err);
      });
  }
};

export const updateResourceResourceCategories = (
  resource: Resource,
  categories: CategoryDTO[]
) => async (dispatch) => {
  const fullResourceResponse: GetResourceByIdAndTypeAction = await dispatch(
    getResourceById(resource.id)
  );
  if (fullResourceResponse.type === GET_RESOURCE_BY_ID.SUCCESS) {
    const updatedResource = {
      ...fullResourceResponse.response,
      categories,
      resourceType: resource.resourceType,
    };
    return dispatch(saveResource(updatedResource))
      .then((res: DuplicateResourceAction) => res)
      .catch((err) => {
        throw new Error(err);
      });
  }
};

export const addTagToOE = (resourceId: number, tagId: number) => async (
  dispatch,
  getState
) => {
  const { authToken } = getState().system;
  const endpoint = `operational-experiences/${resourceId}/tags/${tagId}`;
  return dispatch({
    [CALL_API]: {
      endpoint,
      options: {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${authToken}`,
          Accept: "application/json",
        },
      },
      types: [
        ADD_TAG_TO_OE.REQUEST,
        ADD_TAG_TO_OE.SUCCESS,
        ADD_TAG_TO_OE.FAILURE,
      ],
    },
  })
    .then((res) => res)
    .catch((err) => {
      throw new Error(err);
    });
};
export const removeTagFromOE = (resourceId: number, tagId: number) => async (
  dispatch,
  getState
) => {
  const { authToken } = getState().system;
  const endpoint = `operational-experiences/${resourceId}/tags/${tagId}`;
  return dispatch({
    [CALL_API]: {
      endpoint,
      options: {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${authToken}`,
          Accept: "application/json",
        },
      },
      types: [
        REMOVE_TAG_FROM_OE.REQUEST,
        REMOVE_TAG_FROM_OE.SUCCESS,
        REMOVE_TAG_FROM_OE.FAILURE,
      ],
    },
  })
    .then((res) => res)
    .catch((err) => {
      throw new Error(err);
    });
};

export const getResourceAuthors = (params?: FilterParams) => (
  dispatch,
  getState
) => {
  const { authToken } = getState().system;

  const endpoint = buildFilteredEndpoint("resources/authors", params);

  return dispatch({
    [CALL_API]: {
      endpoint,
      options: {
        method: "GET",
        headers: {
          Authorization: `Bearer ${authToken}`,
          Accept: "application/json",
        },
      },
      types: [
        GET_RESOURCE_AUTHORS.REQUEST,
        GET_RESOURCE_AUTHORS.SUCCESS,
        GET_RESOURCE_AUTHORS.FAILURE,
      ],
    },
  })
    .then((res: GetResourceAuthorsAction) => res)
    .catch((err) => {
      throw new Error(err);
    });
};

export const getWriteableDefenseUrl = (defenseId: number) => (
  dispatch,
  getState
) => {
  const { authToken } = getState().system;

  return dispatch({
    [CALL_API]: {
      endpoint: `defenses/${defenseId}/writable-attachment-url`,
      options: {
        method: "GET",
        headers: {
          Authorization: `Bearer ${authToken}`,
          Accept: "application/json",
        },
      },
      types: [
        GET_DEFENSE_WRITEABLE_URL.REQUEST,
        GET_DEFENSE_WRITEABLE_URL.SUCCESS,
        GET_DEFENSE_WRITEABLE_URL.FAILURE,
      ],
    },
  })
    .then((res) => res)
    .catch((err) => {
      throw new Error(err);
    });
};

export const uploadFileToS3 = (file: File, s3Url: string) => {
  return fetch(s3Url, {
    method: "PUT",
    headers: {
      "Content-Type": file.type,
    },
    body: file,
  })
    .then((res) => {
      return {
        status: "success" as const,
        response: res,
      };
    })
    .catch((err) => {
      return {
        status: "error" as const,
        error: err,
      };
    });
};
