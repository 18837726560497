import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";

import Breadcrumbs from "components/common/Breadcrumbs";
import ReadOnlyContent from "components/common/permissions/ReadOnlyContent";
import { ContentWrapper } from "components/common/Wrappers";
import { AppState } from "store";
import {
  FilterParams,
  PaginationParams,
  Pagination as PaginationType,
} from "store/common/types";
import { LoadingWrapper, PageLoader } from "../resources/styles";
import { PageActionButton, PageHeader, PageTitle } from "../styles";
import ResourceCategoryFilters from "./ResourceCategoryFilters";
import ResourceCategoryTable from "./ResourceCategoryTable";
import { CategoryDTO } from "store/categories/types";
import ResourceCategoryTablePagination from "./ResourceCategoryTablePagination";
import { getResourceCategories } from "store/resourceCategories/actions";
import { GET_RESOURCE_CATEGORIES } from "store/resourceCategories/types";
import usePrevious from "util/hooks/usePrevious";
import { omit } from "lodash";
import { resourceCategoriesFiltersSelector } from "store/filters/resourceCategories/selectors";
import { setResourceCategoriesPagination } from "store/filters/resourceCategories/actions";

const initialPaginationParams = {
  page: 0,
  size: 10,
  sort: "title,asc",
};

const ResourceCategories = () => {
  const history = useHistory();
  const filtersSelect = useSelector(resourceCategoriesFiltersSelector);
  const dispatch = useDispatch<ThunkDispatch<AppState, void, Action>>();

  const [filters, setFilters] = useState<FilterParams>(
    filtersSelect.filterParams
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [paginationValues, setPaginationValues] = useState<PaginationType>({});
  const [categories, setCategories] = useState<CategoryDTO[]>([]);
  const [error, setError] = useState<string>("");

  const prevParamsSize = usePrevious(filtersSelect.paginationParams.size);

  const fetchResourceCategories = async () => {
    setIsLoading(true);
    setError("");
    const res = await dispatch(
      getResourceCategories(filtersSelect.paginationParams, filters)
    );
    if (res.type === GET_RESOURCE_CATEGORIES.SUCCESS) {
      setCategories(res.response.content);
      setPaginationValues(omit(res.response, ["content"]));
    }
    if (res.type === GET_RESOURCE_CATEGORIES.FAILURE) {
      setError(res.error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (prevParamsSize !== filtersSelect.paginationParams.size) {
      dispatch(
        setResourceCategoriesPagination({
          ...filtersSelect.paginationParams,
          page: 0,
        })
      );
    } else {
      fetchResourceCategories();
    }
  }, [filtersSelect.paginationParams, filters]);

  const handleUpdatePaginationParams = (param: PaginationParams) =>
    dispatch(
      setResourceCategoriesPagination({
        ...filtersSelect.paginationParams,
        ...param,
      })
    );

  const handleSort = (sort: string) =>
    dispatch(
      setResourceCategoriesPagination({
        ...filtersSelect.paginationParams,
        sort,
      })
    );

  const handleUpdateFilters = (filters: FilterParams) => {
    setFilters(filters);
    dispatch(
      setResourceCategoriesPagination({
        ...filtersSelect.paginationParams,
        page: 0,
      })
    );
  };

  return (
    <ContentWrapper id="mainContent">
      <Breadcrumbs
        paths={[
          {
            pathName: "Content",
          },
          {
            pathName: "Resource Categories",
            href: "/content/resource-categories",
          },
        ]}
      />
      <PageHeader>
        <PageTitle>Resource Categories</PageTitle>
        <ReadOnlyContent>
          <PageActionButton
            onClick={() => history.push("/content/resource-categories/new")}
          >
            Add Category
          </PageActionButton>
        </ReadOnlyContent>
      </PageHeader>
      <ResourceCategoryFilters onUpdateFilters={handleUpdateFilters} />
      <LoadingWrapper>
        <PageLoader loading={isLoading} />
        <ResourceCategoryTable
          onSort={handleSort}
          params={filtersSelect.paginationParams}
          categories={categories}
          totalElements={paginationValues.totalElements || 0}
          onFetch={fetchResourceCategories}
        />
      </LoadingWrapper>
      <ResourceCategoryTablePagination
        params={filtersSelect.paginationParams}
        paginationValues={paginationValues}
        onUpdateParams={handleUpdatePaginationParams}
      />
    </ContentWrapper>
  );
};

export default ResourceCategories;
