import React from "react";

import * as S from "./styles";

export interface TagProps {
  children: string;
  className?: string;
}

const Tag = ({ children, className }: TagProps) => {
  return (
    <S.Tag className={className}>
      <S.Text>{children}</S.Text>
    </S.Tag>
  );
};
export default Tag;
