import styled from "styled-components";

import { remCalc } from "themes/helpers";

export const LockedInput = styled.input`
  display: inline-block;
  width: 100%;
  border: none;
  color: ${({ theme }) => theme.masterColors.mediumGrey};
  font-size: ${remCalc(16)};
  margin-left: 15px;

  &:disabled {
    background-color: transparent;
  }
`;
