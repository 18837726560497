import * as React from "react";
import styled from "styled-components";

import { styles } from "../../themes/materialUI";
import { withStyles, WithStyles } from "@material-ui/core";

import { DashboardSearch } from "../forms/SearchInput";

// @NOTE would like to move this to a styles.ts file but I don't want to completely change the structure
const SearchBarWrapper = styled.div`
  width: 100%;
`;

const StyledDashboardSearch = styled(DashboardSearch)`
  // padding: 25px; // We can maybe let parent add padding
`;

const SearchFilterIcon = styled.span.attrs({
  className: "icon-icons8-mail_filter mb-1",
})`
  padding-right: 10px;
`;

interface EndAdornment {
  icon?: any;
  label?: string;
}

interface SearchBarProps extends WithStyles<typeof styles> {
  autoFocus: boolean;
  // classes?: string;
  disableUnderline?: boolean;
  endAdornment?: EndAdornment;
  error?: any;
  handleBlur(e: React.FocusEvent<any>): void;
  handleBlur<T = string | any>(fieldOrEvent: T): T extends string ? (e: any) => void : void;
  handleChange(e: React.ChangeEvent<any>): void;
  handleChange<T = string | React.ChangeEvent<any>>(
    field: T,
  ): T extends React.ChangeEvent<any> ? void : (e: string | React.ChangeEvent<any>) => void;
  handleFocus?: (e: React.FocusEvent<any>) => void;
  helperText?: any; // For divs and span probably
  hideFilters?: boolean;
  label?: any;
  mobile?: boolean;
  name: string;
  openMobileFilters?: () => void;
  placeholder?: string;
  resetSearch?: () => void;
  style?: any;
  value: string;
  variant?: any;
}

const SearchBar = withStyles(styles)((props: SearchBarProps) => {
  let endAdornment = [
    {
      visible: !!props.value,
      label: <span>Clear Search</span>,
      handleClick: () => {
        props.resetSearch && props.resetSearch();
      },
    },
    {
      visible: !props.hideFilters, // This being `false` doesn't hide it so adding temp logic
      label: <SearchFilterIcon />,
      handleClick: () => {
        props.openMobileFilters && props.openMobileFilters();
      },
      userDefined: false,
    },
  ];
  if (!props.mobile) {
    endAdornment = [
      {
        visible: !!props.value,
        label: <span className="mr-2">Clear Search</span>,
        handleClick: () => {
          props.resetSearch && props.resetSearch();
        },
      },
    ];
  }
  return (
    <SearchBarWrapper>
      <StyledDashboardSearch
        autoFocus={props.autoFocus}
        disableUnderline={props.disableUnderline}
        endAdornment={endAdornment}
        fullWidth
        handleBlur={props.handleBlur}
        handleChange={props.handleChange}
        handleFocus={props.handleFocus}
        helperText={props.helperText}
        label={props.label}
        mobile={props.mobile}
        name={props.name}
        placeholder={props.placeholder}
        rounded
        value={props.value}
        variant={props.mobile ? "outlined" : "standard"}
      />
    </SearchBarWrapper>
  );
});

SearchBar.defaultProps = {
  name: "",
};

export { SearchBar };
