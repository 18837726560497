import React from "react";
import styled from "styled-components";
import SelectBase, {
  SelectProps as SelectPropsBase,
} from "@material-ui/core/Select";
import MenuItemBase from "@material-ui/core/MenuItem";

import mediaQueries from "themes/mediaQueries";
import { remCalc } from "themes/helpers";
import { selectHasValue } from "components/common/FilterSelect/styles";

const SelectInput = styled.div<{ width?: number }>`
  display: flex;
  flex-direction: column;
  ${({ width }) => (width ? `width: ${remCalc(width)};` : "")}
  text-overflow: ellipsis;
`;

interface SelectProps extends SelectPropsBase {
  active?: boolean;
}

const Select = styled<React.FC<SelectProps>>(({ ...otherProps }) => (
  <SelectBase
    {...otherProps}
    classes={{
      outlined: "outlined",
      select: "select",
      icon: "icon",
    }}
  />
))`
  && .select {
    background-color: ${({ theme }) => theme.colors.fieldInputBackground};
    color: ${({ active, value, theme }) =>
      active
        ? theme.colors.primary
        : selectHasValue(
            value,
            theme.colors.formFieldColor,
            theme.masterColors.darkGrey
          )};
    font-size: ${remCalc(14)};

    @media (${mediaQueries.minTablet}) {
      font-size: ${remCalc(14)};
    }
  }
  && .select:focus {
    background-color: transparent;
  }
  && .icon {
    color: ${({ theme }) => theme.colors.formFieldColor};
  }
  && .outlined {
    padding: ${remCalc(12)};
    padding-right: ${remCalc(32)};
  }
  &.error {
    .notchedOutline {
      border-width: 1px;
    }
  }
`;

const MenuItem = styled((props) => (
  <MenuItemBase
    {...props}
    classes={{
      root: "root",
      selected: "selected",
    }}
  />
))`
  &&.root {
    display: ${({ placeholder }) => placeholder && "none"};
    color: ${({ theme }) => theme.colors.darkGrey};
  }
  &&.root:hover,
  &&.Mui-focusVisible {
    ${({ theme }) => {
      return `
        background-color: ${theme.colors.activeFill};
        color: ${theme.colors.black};
      `;
    }}
  }
`;

export { SelectInput, Select, MenuItem };
