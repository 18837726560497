import FileUploadField from "components/common/form/FileUpload";
import { FileUploadFieldProps, UploadedFile } from "components/common/form/FileUpload/FileUploadField";
import { FieldInputProps } from "formik";
import React from "react";
import useGroupTerm from "util/hooks/whiteLabel/useGroupTerm";

type Props = FileUploadFieldProps & FieldInputProps<(File | UploadedFile)[]>

const ResourceDefenseDocUploadField = ({ onUpdateFiles, files, ...fieldProps }: Props) => {
  const defenseTerm = useGroupTerm("defenses", "noun", "singular", "Defense");
  const defenseTermLower = defenseTerm.toLowerCase();
  return (
    <FileUploadField
      files={files}
      helperText="Supported file types: .pdf, .jpg, .png; maximum file size is 500MB"
      label="Attached Files"
      maxFileSize={5e+8}
      onUpdateFiles={onUpdateFiles}
      placeholder={`Attach files to the ${defenseTermLower}`}
      {...fieldProps}
    />
  );
};

export default ResourceDefenseDocUploadField;
