type SanitizeAPIErrorParams = {
  body: string;
  env: string;
  headers: {
    "Accept"?: string;
    "Authorization"?: string;
    "Content-Type": string;
  };
  method: string;
  mode: string;
  state: string;
}
/**
 * Use this to change shape of logged payload for FullStory tracking. Or to pull values out as needed. 
 * For example, pull JWT from body -- GK
 * 
 * @param error - summarized data from `middleware/api`
 */
export const sanitizeAPIError = (error: SanitizeAPIErrorParams) => {
  return {
    // General
    env: error.env,
    mode: error.mode,
    // Request
    requestBody: error.body,
    requestHeaders: {
      "Accept": error.headers?.Accept,
      "Content-Type": error.headers?.["Content-Type"],
    },
    requestMethod: error.method,
    // Response
    responseState: error.state,
  };
};
