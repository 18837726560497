import React from "react";
import styled from "styled-components";
import MediaQuery from "react-responsive";

import { devices } from "themes/mediaQueries";
import DashboardBottomNavigation from "components/dashboard/DashboardBottomNavigation";

import AppNavigation from "./AppNavigation";

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  height: 88vh;
  overflow: hidden;

  @media (min-width: ${devices.minDesktop}px) {
    margin-top: 92px;
  }

  @media (max-width: ${devices.maxTablet}px) {
    margin-top: 55px;
  }
`;

// Why is this not the main ContentWrapper?
const ContentWrapper = styled.div`
  flex: 1;
  // overflow-x: hidden;
  overflow-x: auto;
`;

const AppNavWrapper = ({ children }: { children: React.ReactNode }) => {
  return (
    <Wrapper>
      <MediaQuery minWidth={devices.minDesktop}>
        <AppNavigation />
      </MediaQuery>
      {/* Using this ID in ViewUserProfile */}
      <ContentWrapper id="contentWrapper">{children}</ContentWrapper>
      <MediaQuery maxWidth={devices.maxTablet}>
        <DashboardBottomNavigation />
      </MediaQuery>
    </Wrapper>
  );
};

export default AppNavWrapper;
