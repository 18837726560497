import React from "react";
import BreadcrumbsBase from "@material-ui/core/Breadcrumbs";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { useHistory, useLocation } from "react-router";

import * as S from "./styles";

interface Path {
  href?: string;
  pathName: string;
};

export interface BreadCrumbsProps {
  paths: Path[];
}

const Breadcrumbs = ({ paths }: BreadCrumbsProps) => {
  const { push } = useHistory();
  const { pathname } = useLocation();

  return (
    <S.Wrapper>
      <BreadcrumbsBase
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
      >
        {paths.map(({ href, pathName }, i) => {
          const currentPath = pathname === href;

          if (href && !currentPath) {
            return (
              <S.Link color="inherit" onClick={() => push(href)} key={i}>
                {pathName}
              </S.Link>
            );
          }

          return (
            <S.Crumb key={i} currentPath={currentPath}>
              {pathName}
            </S.Crumb>
          );
        })}
      </BreadcrumbsBase>
    </S.Wrapper>
  );
};

export default Breadcrumbs;
