import { RemoveLinkButton } from "components/common/Button/futureUiKit";
import React from "react";
import SigPad from "signature_pad";
import trimCanvas from "trim-canvas";

import * as S from "./styles";
import { DocumentParticipant } from "store/documents/types";

type Props = {
  // signature_pad's props
  /**
   * disables features for clear/submit buttons
   */
  desktopMode?: boolean;
  velocityFilterWeight?: number;
  minWidth?: number;
  maxWidth?: number;
  minDistance?: number;
  dotSize?: number | Function;
  penColor?: string;
  throttle?: number;
  onBegin?: () => void;
  onEnd?: () => void;
  // props specific to the React wrapper
  width?: number;
  height?: number;
  clearOnResize?: boolean;
  handleConfirm: (signatureData: any, participant: DocumentParticipant) => void;
  /**
   * `boolean` based on the `confirmed` property from
   * the associated stored signature image object within:
   * `store.newDocument.storedSignatureImages`.
   * We'll use this value for styling as well as
   * preventing drawing on the canvas post-confirmation
   */
  confirmation: boolean;
  participant: DocumentParticipant;
  /**
   * `boolean` from __Signatures.tsx__ which signifies when we
   * have reached the final participant and have attempted to advance
   * beyond it. In this case, we'll close **SignaturePad.tsx**
   */
  setRefreshEditMode: (rem: boolean) => void;
  clearSignatureLocally: (participant: DocumentParticipant) => void;
};

type State = {
  sigPad: null | SigPad;
  canvas: null | HTMLCanvasElement;
  padContainer: null | HTMLDivElement;
  /**
   * `boolean` value which signifies if the user
   * has drawn a signature value. We are setting
   * this to true via `sigPad.onEnd` from
   * the `signature_pad` instance.
   *
   * __this.clear__ handles resetting the value
   * back to false
   */
  containsSignature: boolean;
  /**
   * storing canvas width in state since we'll update it based on the viewport
   */
  canvasWidth: number;
};

class SignaturePad extends React.PureComponent<Props, State> {
  private sigPad;
  private canvas;
  private padContainer = React.createRef<HTMLDivElement>();

  constructor(props) {
    super(props);
    this.state = {
      sigPad: null,
      canvas: null,
      padContainer: null,
      containsSignature: false,
      canvasWidth: window.innerWidth > 768 ? 600 : window.innerWidth * 0.8,
    };
  }

  componentDidMount(): void {
    this.sigPad = new SigPad(this.canvas, {
      onEnd: () => this.handleOnStrokeEnd(),
      onBegin: () => this.handleContainsDrawingContent(),
    });
    this.resizeCanvas();
    this.on();
    window.removeEventListener("resize", this.handleGetScreenWidth);
    window.addEventListener("resize", this.handleGetScreenWidth);
  }

  componentWillUnmount(): void {
    this.off();
    window.removeEventListener("resize", this.handleGetScreenWidth);
  }

  handleGetScreenWidth = () => {
    if (window.innerWidth <= 768 && this.state.canvasWidth !== window.innerWidth) {
      return this.setState({ canvasWidth: window.innerWidth * 0.8 });
    }

    if (window.innerWidth > 768 && this.state.canvasWidth !== 600) {
      return this.setState({ canvasWidth: 600 });
    }
  };

  // lets component know that we have signature content
  handleContainsDrawingContent = () => {
    return this.setState({ containsSignature: true });
  };

  handleOnStrokeEnd = () => {
    // update stored signature image in
    // desktop mode
    if (this.props.desktopMode) {
      this.onConfirm(true);
    }
  };

  getCanvas() {
    return this.canvas;
  }

  getTrimmedCanvas() {
    const copy = document.createElement("canvas");
    copy.width = this.canvas.width;
    copy.height = this.canvas.height;
    const ctx = copy.getContext("2d");
    if (ctx) {
      ctx.drawImage(this.canvas, 0, 0);
    }
    return trimCanvas(ctx);
  }

  getSignaturePad() {
    return this.sigPad;
  }

  private resizeCanvas = () => {
    const { width, height } = this.props;
    // don't resize if the canvas has fixed width and height
    if (width && height) {
      return;
    }

    // const canvas = this.canvas;
    /* When zoomed out to less than 100%, for some very strange reason,
      some browsers report devicePixelRatio as less than 1
      and only part of the canvas is cleared then. */
    // const ratio = Math.max(window.devicePixelRatio || 1, 1);
    //
    // canvas.getContext("2d").scale(ratio, ratio);
    this.clear();
  };

  /**
   * uses `this.props.handleConfirm` which
   * takes the `signatureData` and stores in redux
   * until we submit the document, which is when we'll
   * make the API call to write the image to the s3 bucket
   *
   * We also use `attachURL` to store the readable url
   * with the associated participant within __FormController__ state
   * in preparation for the Document Submission
   *
   * @dontLock is used to prevent the component
   * from rendering a "success" state
   */
  onConfirm = (dontLock?: boolean) => {
    const { participant } = this.props;

    const image = this.toDataURL();

    if (!dontLock) {
      this.off();
    }

    this.props.handleConfirm(image, participant);
  };

  render() {
    const { containsSignature } = this.state;
    const { confirmation, participant } = this.props;

    const height = this.props.height || 250;

    return (
      <>
        <S.PadContainer
          ref={this.padContainer}
          width={this.state.canvasWidth}
          confirmation={!this.props.desktopMode && confirmation}
          height={height}
          id={participant.participantId ? `${participant.participantId}` : `${participant.name}`}
          tabIndex={0}
        >
          {!containsSignature && (
            <S.CenterPadElement>
              <S.PadIcon className="icon icon-icons8-autograph" />
              <S.PadIconLabel>Sign Here</S.PadIconLabel>
            </S.CenterPadElement>
          )}
          {!this.props.desktopMode && containsSignature && !confirmation && (
            <S.ClearButton onClick={() => this.clear()} variant="unstyled" zIndex={3}>
              <S.ClearButtonLabel>CLEAR SIGNATURE</S.ClearButtonLabel>
            </S.ClearButton>
          )}
          {!this.props.desktopMode && !confirmation ? (
            <S.ConfirmButton
              width={window.innerWidth > 600 ? undefined : 100}
              onClick={() => this.onConfirm()}
              variant="primary"
              zIndex={4}
            >
              {window.innerWidth > 600 ? "CONFIRM SIGNATURE" : "CONFIRM"}
            </S.ConfirmButton>
          ) : !this.props.desktopMode ? (
            <S.Circle>
              <S.Icon className="icon icon-icons8-checkmark" />
            </S.Circle>
          ) : null}
          <S.Canvas
            ref={(ref) => {
              this.canvas = ref;
            }}
            width={this.state.canvasWidth}
            height={height}
          />
          <S.BottomLine width={this.state.canvasWidth} height={height} />
        </S.PadContainer>
        <S.AssistiveLinkRow>
          <RemoveLinkButton
            onClick={() => {
              this.sigPad.clear();
              this.props.setRefreshEditMode(true);
              this.props.clearSignatureLocally(participant);
            }}
          >
          Clear Signature
          </RemoveLinkButton>
        </S.AssistiveLinkRow>
      </>
    );
  }

  on() {
    return this.sigPad.on();
  }

  off() {
    return this.sigPad.off();
  }

  clear() {
    this.setState({ containsSignature: false });
    return this.sigPad.clear();
  }

  isEmpty() {
    return this.sigPad.isEmpty();
  }

  fromDataURL(dataURL, options) {
    return this.sigPad.fromDataURL(dataURL, options);
  }

  toDataURL(): string | null {
    if (!this.sigPad.isEmpty()) {
      return this.sigPad.toDataURL("image/png");
    }
    return null;
  }

  fromData(pointGroups) {
    return this.sigPad.fromData(pointGroups);
  }

  toData() {
    return this.sigPad.toData();
  }
}

export default SignaturePad;
