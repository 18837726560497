import React from "react";

import { Tab } from "components/common/Tabs/futureUiKit/Tab";

interface Props {
  currentTab: number;
  onChangeTab: (tabIndex: number) => void;
  children?: React.ReactNode;
  tabIndex: number;
}

const FormBuilderTab = ({ tabIndex, currentTab, children, onChangeTab }: Props) => (
  <Tab
    id={`fb-tab-${tabIndex}`}
    handleClick={() => onChangeTab(tabIndex)}
    handleKeyDown={() => onChangeTab(tabIndex)}
    tabPanelId={tabIndex.toString()}
    isSelected={currentTab === tabIndex}
  >
    {children}
  </Tab>
);

export default FormBuilderTab;
