import React, { FocusEvent, useState, ChangeEvent } from "react";
import RadioButtonUnchecked from "@material-ui/icons/RadioButtonUnchecked";

import { FieldErrorType } from "components/FormController/components/Question";
import Label from "components/forms/Label";
import Text from "components/common/Text";

import { SingleResponseQuestionProps } from "../types";
import * as S from "./styles";
import { DocumentQuestionResponseVm } from "store/documents/types";
import { HelperText } from "../TextInput/styles";

export type RadioInputVariant = "DEFAULT" | "RATING";

interface FormControlLabel {
  value: string;
  label: string;
  id?: number;
  rootId?: number;
}

interface Props extends SingleResponseQuestionProps {
  className?: string;
  disabled?: boolean;
  error?: FieldErrorType;
  options: Array<FormControlLabel>;
  handleBlur?: () => void;
  label?: string;
  name: string | number;
  stacked?: boolean;
  variant: RadioInputVariant;
  unselectable?: boolean;
}

type LabelPlacementType = "bottom" | "end" | "start" | "top";

interface LabelPlacementFor {
  [key: string]: LabelPlacementType;
}

const labelPlacementFor: LabelPlacementFor = {
  DEFAULT: "end",
  RATING: "bottom",
};

export function RadioInput({
  className,
  disabled,
  error,
  handleBlur,
  options,
  label,
  name,
  question,
  response,
  setQuestionResponse,
  stacked = false,
  variant = "DEFAULT",
  unselectable = false,
}: Props) {
  const [focusedOption, setFocusedOption] = useState<string | undefined>();

  const localHandleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const {
      target: { value },
    } = event;
    const option = options.find((opt) => opt.value === value)!;
    const questionResponse: DocumentQuestionResponseVm = {
      answer: value,
      associatedId: option.id,
      associatedRootId: option.rootId,
      questionId: question.id,
      questionRootId: question.rootId,
      timeAnswered: new Date().toISOString(),
    };
    setQuestionResponse(value ? questionResponse : undefined);
  };

  const handleFocusVisible = (event: FocusEvent<HTMLButtonElement>) => {
    const {
      target: { value },
    } = event;
    setFocusedOption(value);
  };

  const handleBlurButton = (event: FocusEvent<HTMLButtonElement>) => {
    const {
      target: { value },
    } = event;
    if (value === focusedOption) {
      setFocusedOption(undefined);
    }
  };

  const isChecked = (fcl: FormControlLabel) => {
    return (
      response &&
      ((response.associatedId && response.associatedId === fcl.id) ||
        response.answer === fcl.value)
    );
  };

  const handleClick = (e) => {
    const element = e.target as HTMLInputElement;
    const { value } = element;
    if (value === response?.answer) {
      const clearQuestionResponse: DocumentQuestionResponseVm = {
        answer: "",
        questionId: question.id,
        questionRootId: question.rootId,
        timeAnswered: new Date().toISOString(),
      };
      setQuestionResponse(clearQuestionResponse);
    } else {
      const option = options.find((opt) => opt.value === value)!;
      const questionResponse: DocumentQuestionResponseVm = {
        answer: value,
        associatedId: option.id,
        associatedRootId: option.rootId,
        questionId: question.id,
        questionRootId: question.rootId,
        timeAnswered: new Date().toISOString(),
      };
      setQuestionResponse(value ? questionResponse : undefined);
    }
  };

  // Helper text
  const helperText = React.useMemo(
    () => question.properties?.assistiveText || undefined,
    [question.properties?.assistiveText]
  );

  return (
    <S.RadioInput className={className} onBlur={handleBlur}>
      {label && <Label htmlFor={`${question.id}`}>{label}</Label>}

      <S.Options powerfieldsVariant={variant}>
        <S.FormControl
          variant="outlined"
          powerfieldsVariant={variant}
          error={!!error}
          disabled={disabled}
        >
          <S.RadioGroup
            stacked={stacked}
            name={`${name}`}
            value={response ? response.answer : ""}
            onChange={unselectable ? () => null : localHandleChange}
            powerfieldsVariant={variant}
          >
            {options.map((option) => (
              <S.FormControlLabel
                checked={isChecked(option)}
                control={
                  <S.Radio
                    error={error}
                    disabled={disabled}
                    disableRipple
                    icon={
                      <S.SvgIcon>
                        <RadioButtonUnchecked />
                      </S.SvgIcon>
                    }
                    checkedIcon={
                      <S.CheckIcon className="icon icon-icons8-checkmark" />
                    }
                    onFocusVisible={handleFocusVisible}
                    onBlur={handleBlurButton}
                    onClick={unselectable ? handleClick : () => null}
                  />
                }
                disabled={disabled}
                error={error}
                key={option.id || option.value}
                isInputFocused={option.value === focusedOption}
                label={option.label}
                labelPlacement={labelPlacementFor[variant]}
                powerfieldsVariant={variant}
                value={option.value}
              />
            ))}
          </S.RadioGroup>
        </S.FormControl>
      </S.Options>
      {helperText && <HelperText>{helperText}</HelperText>}
      {error && <Text variant="error">{error}</Text>}
    </S.RadioInput>
  );
}
