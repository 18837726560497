import React from "react";
import styled from "styled-components";
import CardBase from "@material-ui/core/Card";
import CardActionsBase from "@material-ui/core/CardActions";
import CardContentBase from "@material-ui/core/CardContent";
import CardHeaderBase from "@material-ui/core/CardHeader";
import CardMediaBase from "@material-ui/core/CardMedia";
import AvatarBase from "@material-ui/core/Avatar";
import CheckCircleBase from "@material-ui/icons/CheckCircle";
import IconButtonBase from "@material-ui/core/IconButton";
import TypographyBase from "@material-ui/core/Typography";
import MoreVertIconBase from "@material-ui/icons/MoreVert";

interface PillProps {
  readonly isSubmitted: boolean;
}

const Card = styled.div`
  font-family: "Roboto";
  color: ${({ theme }) => theme.colors.header};
  &:hover {
    background-color: ${({ theme }) => theme.masterColors.lightBlue};
    cursor: pointer;
  }
`;
const Pill = styled.div<PillProps>`
  width: max-content;
  min-width: 72px;
  height: 22px;
  padding: 0 6px;
  border: 1px solid
    ${(props) =>
      props.isSubmitted
        ? props.theme.colors.success
        : props.theme.colors.primary};
  border-radius: 16px;
  color: ${(props) =>
    props.isSubmitted
      ? props.theme.colors.success
      : props.theme.colors.primary};
  font-family: "Roboto";
  font-size: 12px;
  background: ${(props) =>
    props.isSubmitted
      ? props.theme.colors.successFill
      : props.theme.colors.activeFill};

  display: flex;
  justify-content: center;
  align-items: center;
`;

const CardTitle = styled.span`
  font-size: 16px;
  font-family: "Roboto";
  color: ${({ theme }) => theme.colors.header};
  margin-bottom: 0px;

  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 186px;
`;
const CardSubTitle = styled.span<{ tertiary?: boolean }>`
  font-size: 14px;
  font-family: "Roboto";
  color: ${({ theme }) => theme.colors.assistiveText};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: ${({ tertiary }) => (tertiary ? "50px" : "77px")};
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

const Row = styled.div<{ spaceBetween?: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: ${({ spaceBetween }) =>
    spaceBetween ? "space-between" : "flex-start"};
`;

const MUICard = styled(({ ...otherProps }) => (
  <CardBase {...otherProps} classes={{ root: "root" }} />
))`
  &&.root {
    position: relative;
    background: ${({ theme }) => theme.colors.background};
    border: 1px solid ${({ theme }) => theme.masterColors.lightGrey};
    border-radius: 4px;
    width: 224px;
    padding: 17px 16px 16px 16px;
    box-shadow: none;

    &:hover {
      background-color: ${({ theme }) => theme.masterColors.lightBlue};
    }
  }
`;
const CardActions = styled(({ ...otherProps }) => (
  <CardActionsBase {...otherProps} />
))``;
const CardContent = styled(({ ...otherProps }) => (
  <CardContentBase
    {...otherProps}
    classes={{
      root: "root",
    }}
  />
))`
  &&.root {
    padding: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const CardHeader = styled(({ ...otherProps }) => (
  <CardHeaderBase
    {...otherProps}
    classes={{
      root: "root",
      action: "action",
      title: "title",
      subheader: "subHeader",
    }}
  />
))`
  &&.root {
    // padding: 17px 16px;
    padding-bottom: 0px;
    padding: 0px;
    margin-bottom: 7px;
  }

  && .action {
    position: absolute;
    right: 0;
  }

  && .title {
    margin-bottom: 5px;
  }

  && .subHeader {
    padding-bottom: 0px;
    margin-bottom: 0px;
  }
`;

const CardMedia = styled(({ ...otherProps }) => (
  <CardMediaBase {...otherProps} />
))``;
const Avatar = styled(({ ...otherProps }) => <AvatarBase {...otherProps} />)``;
const CheckCircle = styled(({ ...otherProps }) => (
  <CheckCircleBase {...otherProps} />
))``;
const IconButton = styled(({ ...otherProps }) => (
  <IconButtonBase {...otherProps} />
))``;
const Typography = styled(({ ...otherProps }) => (
  <TypographyBase {...otherProps} />
))``;
const MoreVertIcon = styled(({ ...otherProps }) => (
  <MoreVertIconBase {...otherProps} />
))`
  color: ${({ theme }) => theme.colors.header};
  cursor: pointer;
`;

// ? STYLES FOR `MapContent.tsx`

const MapContent = styled.div`
  width: 186px;
  height: 52px;
  background: ${({ theme }) => theme.masterColors.lightGrey};
  border-radius: 4px;

  display: flex;
  justify-content: center;
  align-items: center;
`;

export {
  Avatar,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  CardSubTitle,
  CardTitle,
  CheckCircle,
  Column,
  IconButton,
  MapContent,
  MoreVertIcon,
  MUICard,
  Pill,
  Row,
  Typography,
};
