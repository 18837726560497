import React from "react";
import { css } from "aphrodite/no-important";

import Icon from "components/common/Icon";

import propertiesStyles from "../../styles";

interface DeleteRuleButton {
  onDelete: () => void;
}

const DeleteRuleButton = ({ onDelete }: DeleteRuleButton) => {
  const ps = propertiesStyles();
  return (
    <div onClick={onDelete}>
      <Icon className={css(ps.addIcon)} type="minus_sign" color="black" />
    </div>
  );
};

export default DeleteRuleButton;

