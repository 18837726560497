import Breadcrumbs from "components/common/Breadcrumbs";
import { ContentWrapper } from "components/common/Wrappers";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { CategoryDTO } from "store/categories/types";
import {
  LoadingWrapper,
  PageLoader,
} from "components/clientAdmin/resources/styles";
import ResourceCategoryForm from "./ResourceCategoryForm";
import { mapInitialCategoryValues } from "./helpers";
import { getResourceCategoryById } from "store/resourceCategories/actions";
import { GET_RESOURCE_CATEGORY_BY_ID } from "store/resourceCategories/types";
import { useDispatch } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { AppState } from "store";
import { Action } from "redux";

const ResourceCategory = () => {
  const [category, setCategory] = useState<CategoryDTO>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { id: categoryId } = useParams<{ id?: string }>();

  const dispatch = useDispatch<ThunkDispatch<AppState, void, Action>>();

  const currentPathName = categoryId ? "Edit Category" : "Add Category";

  const getCategory = async (id: string) => {
    setIsLoading(true);
    const res = await dispatch(getResourceCategoryById(id));
    if ((res.type = GET_RESOURCE_CATEGORY_BY_ID.SUCCESS)) {
      setCategory(res.response);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (categoryId) {
      getCategory(categoryId);
    }
  }, [categoryId]);

  return (
    <ContentWrapper>
      <Breadcrumbs
        paths={[
          {
            pathName: "Content",
          },
          {
            pathName: "Resource Categories",
            href: "/content/resource-categories",
          },
          {
            pathName: currentPathName,
          },
        ]}
      />
      <LoadingWrapper>
        <PageLoader loading={isLoading} />
        <ResourceCategoryForm
          initialValues={mapInitialCategoryValues(category)}
        />
      </LoadingWrapper>
    </ContentWrapper>
  );
};

export default ResourceCategory;
