import React from "react";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";
import * as yup from "yup";

import { SubmitButton } from "../../forms/Button";
import {
  postDataSource,
  postBasicDSValues,
  putDataSource,
} from "store/dataSource/actions";
import {
  CSVMapping,
  DataSource,
  DataSourceResponse,
} from "store/dataSource/types";
import DelimitedExample from "assets/png/delimited_list_example.png";
import ColumnExample from "assets/png/column_headers_example.png";
import { Option } from "components/common/form/Select/Select";

import S from "./styles";

/**
 * Create a basic value list for basic data source submission
 * @param dStr - string of newline delimited values
 */
const generateBasicList = (dStr: string) =>
  dStr.split("\n").map((d, i) => ({ value: d, displayOrder: i }));

/**
 * Create CSV mapping for advanced data source submission
 * @param dStr - string of newline delimited values
 */
const generateAdvList = (dStr: string) =>
  dStr
    .split("\n")
    .map(
      (d): CSVMapping => ({
        id: null,
        columnName: d.replace("*", ""),
        valueName: null,
        valueType: "STRING",
        required: d[d.length - 1] === "*",
      })
    )
    .filter((cm) => cm.columnName !== "");

/**
 * Returns proper button label for form submit button
 * @param editing   - data source is being edited
 * @param advanced  - data source is advanced
 */
function generateSubmitLabel(editing: boolean, advanced?: boolean) {
  if (advanced) {
    return "SAVE & CONTINUE";
  }
  // basic
  if (editing) {
    return "SAVE";
  }
  return "ADD DATA SET";
}

interface Values {
  title: string;
  dataSourceKey: string;
  description: string;
  dataList: string;
  uniqueId: Option;
}

const validation = yup.object().shape({
  title: yup.string().required("Please enter a title"),
  dataSourceKey: yup.string().required("Please enter a Data Set Source Key"),
  description: yup.string(),
  dataList: yup.string().required("Please enter a list of headers or values"),
  uniqueId: yup.string(),
});

/**
 * Format an incoming data list into a EOL delimited string for rendering / user interaction
 * @param csvMappings
 */
const formatCSVMappings = (csvMappings: CSVMapping[]) =>
  csvMappings.map((m) => m.columnName).join("\n");

interface BasicDSValue {
  value: string;
  displayOrder: number;
}

interface Props {
  dataSource: DataSource | null;
  dataSourceValues?: string[];
  advanced?: boolean;
  setFormComplete?: (c: boolean) => void;
  setDataSource: (ds: DataSource) => void;
  setColumnHeaders?: (cm: CSVMapping[]) => void;
}

const initialColOptions = [{ value: "no unique ID column", id: "none" }];

/** Form utilized in add/edit screens for basic and advanced data sets */
export function DSForm({
  dataSource,
  dataSourceValues,
  advanced,
  setFormComplete,
  setDataSource,
  setColumnHeaders,
}: Props) {
  const history = useHistory();
  const dispatch = useDispatch<ThunkDispatch<null, void, Action>>();

  const [colOptions, setColOptions] = React.useState<Option[]>(
    initialColOptions
  );

  /**
   * Format the request body depending on the type of Data Source being processed.
   * @param values
   */
  function formatRequestBody(values: Values): DataSource {
    let csvMappings: CSVMapping[] = [];
    // if data source is advanced, create csv mappings. else use empty array
    if (advanced) {
      csvMappings = generateAdvList(values.dataList);
    }
    return {
      apiConfig: null,
      author: null,
      csvMappings,
      dataSourceKey: values.dataSourceKey,
      description: values.description,
      id: dataSource?.id || null,
      title: values.title,
      softDeleteMissing: true,
      type: advanced ? "ADVANCED" : "BASIC",
      // this type check is necessary here because Formik coerces uniqueId to string behind the scenes -JA
      uniqueIdField:
        advanced && typeof values.uniqueId === "string"
          ? values.uniqueId
          : "value",
      status: advanced ? "DRAFT" : "PUBLISHED",
    };
  }

  const createNewDS = async (values: Values): Promise<DataSourceResponse> =>
    await dispatch(postDataSource(formatRequestBody(values)));

  const submitBasicDS = (id: number, values: BasicDSValue[]) => {
    dispatch(postBasicDSValues(id, values))
      .then((res) => {
        if (res.response) {
          return history.push("/forms/data-sets");
        }
      })
      .catch((err) => {
        throw new Error(err);
      });
  };

  /** Format incoming data source depending on source type */
  function formatDataList() {
    // advanced data source with csv mappings
    if (advanced && dataSource?.csvMappings) {
      return formatCSVMappings(dataSource.csvMappings);
    }
    // basic data source values
    if (dataSourceValues) {
      return dataSourceValues.join("\n");
    }
    return "";
  }

  const formik = useFormik<Values>({
    enableReinitialize: true,
    initialValues: {
      title: dataSource?.title || "",
      dataSourceKey: dataSource?.dataSourceKey || "",
      description: dataSource?.description || "",
      dataList: formatDataList(),
      uniqueId: colOptions[0],
    },
    onSubmit: async (values) => {
      let ds: DataSourceResponse | null = null;
      try {
        if (!dataSource) {
          // user is creating a new data set
          ds = await createNewDS(values);
        } else {
          ds = await dispatch(putDataSource(formatRequestBody(values)));
        }

        if (ds?.response) {
          setDataSource(ds.response);
          setColumnHeaders && setColumnHeaders(ds.response.csvMappings);
          if (!advanced && ds.response.id) {
            submitBasicDS(ds.response.id, generateBasicList(values.dataList));
          }
        }

        setFormComplete && setFormComplete(true);
      } catch (err) {
        throw new Error(err);
      }
    },
    validationSchema: validation,
  });

  React.useMemo(() => {
    let options = initialColOptions;
    if (formik.values.dataList !== "") {
      options = [
        ...options,
        ...formik.values.dataList.split("\n").map((opt) => ({
          value: opt.replace("*", ""),
          id: opt.replace("*", ""),
        })),
      ].filter((option) => option.value !== "");
    }
    setColOptions(options);
  }, [formik.values.dataList]);

  return (
    <div>
      {advanced && (
        <S.Subtitle>
          Every advanced data set needs a title, a description, and a list of
          column headers. Column headers label the columns of data in your set
          and help map to fields in the CSV. After specifying the items below,
          select Save &amp; Continue.
        </S.Subtitle>
      )}
      <form onSubmit={formik.handleSubmit}>
        {dataSource?.id && (
          <>
            <S.MockFieldLabel>Data Set ID</S.MockFieldLabel>
            <S.MockFieldValue>{dataSource.id}</S.MockFieldValue>
          </>
        )}
        <S.InputWithNote>
          <S.Input
            name="title"
            label="Data Set Title"
            placeholder="Title"
            variant="outlined"
            helperText={!formik.errors.title ? "Assistive text" : ""}
            onChange={formik.handleChange}
            value={formik.values.title}
            touched={formik.touched.title}
            error={formik.errors.title}
            autoComplete="off"
          />
          {!advanced && (
            <S.NoteContainer>
              <S.Note>
                <span>
                  <S.Medium>NOTE</S.Medium>: Title and description are used to
                  identify data set in Form Builder and won&apos;t display
                  anywhere on the Form.
                </span>
              </S.Note>
            </S.NoteContainer>
          )}
        </S.InputWithNote>
        <S.InputWithNote>
          <S.Input
            name="dataSourceKey"
            label="Data Set Source Key"
            placeholder="Data Set Source Key"
            variant="outlined"
            helperText={!formik.errors.dataSourceKey ? "Assistive text" : ""}
            onChange={formik.handleChange}
            value={formik.values.dataSourceKey}
            touched={formik.touched.dataSourceKey}
            error={formik.errors.dataSourceKey}
            autoComplete="off"
          />
          <S.NoteContainer>
            <S.Note>
              <span>
                <S.Medium>NOTE</S.Medium>: The source key is used to add data
                sets in Form Builder as well as create a data list that can
                incorporate multiple data sets.
              </span>
            </S.Note>
          </S.NoteContainer>
        </S.InputWithNote>
        <S.InputWithNote>
          <S.Input
            name="description"
            label="Data Set Description"
            placeholder="Description"
            variant="outlined"
            helperText={!formik.errors.description ? "Assistive text" : ""}
            onChange={formik.handleChange}
            value={formik.values.description}
            touched={formik.touched.description}
            error={formik.errors.description}
            autoComplete="off"
          />
          {!advanced && (
            <S.NoteContainer>
              <S.Note>
                <span>
                  <S.Medium>NOTE</S.Medium>: Don&apos;t use commas, periods, or
                  other elements to separate list items; each item on the list
                  just needs to be on its own line.
                </span>
              </S.Note>
              <S.Note>
                <span>
                  <S.Medium>TIP</S.Medium>: The order that a list is entered is
                  how the list will display on a form.
                </span>
              </S.Note>
            </S.NoteContainer>
          )}
        </S.InputWithNote>
        <S.InputWithNote>
          <S.Input
            name="dataList"
            label={advanced ? "Column Headers" : "Delimited Data List"}
            placeholder={
              advanced
                ? "List the name of each column in your advanced data set on its own line and mark" +
                  " required columns with an (*)"
                : ""
            }
            variant="outlined"
            helperText={
              !formik.errors.dataList
                ? "Each item on your list must be on a new line"
                : ""
            }
            multiline
            rowsMax={18}
            rows={18}
            onChange={formik.handleChange}
            value={formik.values.dataList}
            touched={formik.touched.dataList}
            error={formik.errors.dataList}
            autoComplete="off"
          />
          <S.NoteContainer>
            <S.Note>
              <span>
                <S.Medium>EXAMPLE</S.Medium>:{" "}
                {advanced
                  ? "The list of column headers will be used to generate a .csv template for your advanced data set " +
                    "list. List the name of each column of your data set and mark those columns that require records " +
                    "with a (*)."
                  : "A delimited list can be used throughout the form building process to create answer options for " +
                    "a variety of question types. Below is an example of how a list appears in a form as a dropdown."}
              </span>
              <S.PNG
                src={advanced ? ColumnExample : DelimitedExample}
                alt="Delimited data example"
              />
            </S.Note>
          </S.NoteContainer>
        </S.InputWithNote>
        {advanced && (
          <S.InputWithNote>
            <S.Select
              name="uniqueId"
              label="Unique ID Column"
              value={formik.values.uniqueId}
              onChange={formik.handleChange}
              options={colOptions}
            />
            <S.NoteContainer>
              <S.Note>
                <span>
                  <S.Medium>TIP</S.Medium>: Select &quot;no unique ID
                  column&quot; if applicable and a column will be generated
                  automatically in the template.
                </span>
              </S.Note>
            </S.NoteContainer>
          </S.InputWithNote>
        )}
        <SubmitButton
          width={advanced ? 168 : 140}
          height={2.25}
          isSubmitting={formik.isSubmitting}
        >
          {generateSubmitLabel(!!dataSource, advanced)}
        </SubmitButton>
        <S.CancelButton
          disabled={formik.isSubmitting}
          onClick={() => history.push("/forms/data-sets")}
          variant="cancel"
        >
          CANCEL
        </S.CancelButton>
      </form>
    </div>
  );
}
