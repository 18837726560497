import { ClientGroupConfigsState, ClientGroupConfigsActionTypes } from "./types";
import { GET_CLIENT_GROUP_CONFIG } from "store/clientConfigs/types";
import { GET_ALL_DEFAULT_TERM_DEFINITIONS } from "store/clientGroups/types";

import { parseDefaultGroupTerms } from "./helpers";

const initialState: ClientGroupConfigsState = {
  data: {
    terms: []
  },
};

export function clientGroupConfigsReducer(
  state = initialState, 
  action: ClientGroupConfigsActionTypes
): ClientGroupConfigsState {
  switch (action.type) {
    // If client group has configs
    case GET_CLIENT_GROUP_CONFIG.SUCCESS:
      if (action.config === "terms") {
        return {
          ...state,
          data: {
            ...state.data,
            terms: action.response?.properties.terms,
          }
        };
      }
      return state;
    // If client group has no configs, get default
    case GET_ALL_DEFAULT_TERM_DEFINITIONS.SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          terms: parseDefaultGroupTerms(action.response), // This should always be a non-empty array -- GK
        }
      };
    default:
      return state;
  }
}
