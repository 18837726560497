import { get } from "lodash";

import { ClientGroupConfig } from "store/clientConfigs/types";
import { handleNoun } from "./hooks/whiteLabel/useGroupTerm";
import { AppState } from "store";

export const getGroupConfigTerm = (
  state: AppState, 
  config: string, 
  role: "verb" | "noun", 
  modifier?: "past" | "future" | "plural"
) => {

  const _term = get(state.clientGroupConfigs, ["data", "terms"], [])
    .find((term: ClientGroupConfig) => term.visibleId === config)
?.val
|| "";

  if (role === "noun") {
    return handleNoun(_term, modifier);
  }

  // Default
  return handleNoun(_term, modifier);
  
};
