import { ClientGroup } from "store/clientGroups/types";
import { Person, GeoPoint } from "store/common/types";

export type ContractingCompany = {
  active?: boolean;
  address1?: string;
  address2?: string;
  address3?: string;
  city?: string;
  description?: string;
  id?: number;
  name?: string;
  state?: string;
  title: string;
  zip?: string;
};

export type ClientGroupDTO = {
  detail?: string;
  id?: number;
  isCompletedData?: boolean;
  name?: string;
  source?: "API" | "USER_GENERATED" | "API_INCOMPLETE" | string;
};

export type ParticipantTeam = {
  description?: string;
  externalId?: string;
  id?: number;
  name?: string;
};

export type WorkLocationAddress = {
  city?: string;
  id?: number;
  line1?: string;
  line2?: string;
  line3?: string;
  state?: string;
  zip?: string;
};

export type WorkLocation = {
  emergencyAddress?: WorkLocationAddress;
  geolocation?: GeoPoint;
  id?: number;
  isCompletedData?: boolean;
  locationId?: string;
  locationInformationUrl?: string;
  locationType?: string;
  name?: string;
  nickname?: string;
  physicalAddress?: WorkLocationAddress;
  preferUsersLocation?: boolean;
  supervisor?: Participant;
};
export type WorkLocationParticipantVM = {
  email?: string;
  firstName?: string;
  groupNames?: Array<string>;
  id: number;
  lastName?: string;
  workLocationName?: string;
  signatureType?: "DRAWN" | "TYPED_NAME" | "TYPED_EMAIL" | "TYPED_ANYTHING" | null;
  signatureUrl?: string;
  signatureDate?: string;
  signatureSubmitted?: boolean;
  timeAdded?: string;
};

export interface Participant extends Person {
  contractingCompany?: ContractingCompany;
  groups?: Array<ClientGroup>;
  id: number;
  // isActive?: boolean; // Deprecated
  isCompletedData?: boolean;
  participantType?: "EMPLOYEE" | "CONTRACTOR" | string;
  source?: "API" | "USER_GENERATED" | "API_INCOMPLETE" | string;
  supervisor?: {
    description?: string;
  };
  teams?: Array<ParticipantTeam>;
  workLocation?: WorkLocation;
}

export type AllParticpantsActionType = {
  page?: number;
  query?: string;
  size?: number;
  sort?: string;
};

// ACTION TYPES

export const NAME = "PARTICIPANTS";

export const GET_PARTICIPANTS = {
  REQUEST: `${NAME}/GET_PARTICIPANTS/REQUEST`,
  SUCCESS: `${NAME}/GET_PARTICIPANTS/SUCCESS`,
  FAILURE: `${NAME}/GET_PARTICIPANTS/FAILURE`,
};

export const GET_ALL_PARTICIPANTS = {
  REQUEST: `${NAME}/GET_ALL_PARTICIPANTS/REQUEST`,
  SUCCESS: `${NAME}/GET_ALL_PARTICIPANTS/SUCCESS`,
  FAILURE: `${NAME}/GET_ALL_PARTICIPANTS/FAILURE`,
};

export interface ParticipantsActions {
  type: typeof GET_PARTICIPANTS.REQUEST | typeof GET_PARTICIPANTS.SUCCESS | typeof GET_PARTICIPANTS.FAILURE;

  payload?: any;
  response?: any;
}

// REDUCER

export interface ParticipantState {
  loading: boolean;
  error: boolean;
  errorMessage: string;
  participants: Array<WorkLocationParticipantVM>;
}
