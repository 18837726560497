import { GET_PARTICIPANTS, ParticipantState, ParticipantsActions } from "./types";

const initialState: ParticipantState = {
  loading: false,
  error: false,
  errorMessage: "",
  participants: [],
};

export function participantsReducer(state = initialState, action: ParticipantsActions): ParticipantState {
  const { type, response } = action;

  switch (type) {
    case GET_PARTICIPANTS.REQUEST:
      return {
        ...state,
        loading: true,
        error: false,
        errorMessage: "",
      };

    case GET_PARTICIPANTS.SUCCESS:
      return {
        ...state,
        loading: false,
        participants: response.content ? response.content : response,
      };
    case GET_PARTICIPANTS.FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: response,
      };

    default:
      return state;
  }
}
