import { ClientGroup } from "store/clientGroups/types";
import { Defense, DisplayConditionDTO } from "store/documents/types";
import { APIResponse, Pagination } from "store/common/types";
import {
  EntityProperties,
  ItemType,
  SignatureType,
} from "components/clientAdmin/formBuilder/types";
import { TagDTO } from "store/tagLibrary/types";
import { OperationalExperience } from "store/resources/types";

/* possible types and subtypes (4.28.2020)
QUESTION
  PARTICIPANT
  YES_OR_NO
  TEXT_LINE
  TEXT_AREA
  HYPERLINK
  BARCODE
  DROP_DOWN
  RADIO_BUTTONS
  MULTI_SELECT
  CHECKBOX
  DATE
  TIME
  DATE_TIME
  DOCUMENT
  PHOTO
  DRAWING
  LOCATION
  MAP_DRAW
  RATING
CONTENT
  BLOCK
  LINE_BREAK
  BANNER_MESSAGE
WIDGET
  MAP
  SIGNATURE
  DEFENSES
  OPERATIONAL_EXPERIENCES
  SAFETY_RATING
  DOCUMENT_CREATOR
  SUPERVISOR
*/

/** Base item DTO */
export interface BaseItemDTO {
  id: number;
  rootId: number;
  subType: string;
  type: Omit<ItemType, "SECTION">;
  formProperties?: FormProperties;
  properties?: EntityProperties;
}

/** Item DTO for content blocks and banner messages */
export interface ContentDTO extends BaseItemDTO {
  backgroundColor?: string;
  textColor?: string;
  content: string;
  type: "CONTENT";
}

/** Item DTO for questions */
export interface QuestionDTO extends BaseItemDTO {
  answerSource?: QuestionAnswerSourceDTO;
  hasDefenses?: boolean;
  isGlobalQuestion?: boolean;
  lastModifiedDate?: string;
  parentWidgetRootId?: number;
  participantRole?: "SUPERVISOR" | "ATTENDANT";
  selections?: Array<QuestionSelectionsDTO>;
  title: string;
  type: "QUESTION";
  validations?: Array<ValidationDTO>;
  workflowType?: WorkflowType;
  tags?: TagDTO[];
}

/**
 * Type guard for QuestionDTO
 * @param item
 */
export function isQuestionDTO(item: SectionItem): item is QuestionDTO {
  return item.type === "QUESTION";
}

interface WidgetQuestionDTO {
  answerSource?: QuestionAnswerSourceDTO;
  id?: number;
  questionRootId: number;
}

export interface DefenseWidgetQuestionDTO extends WidgetQuestionDTO {
  hideDefenses?: boolean | string;
}

export interface MapWidgetQuestionDTO extends WidgetQuestionDTO {
  iconColor: string;
}

/** Item DTO for widgets */
export interface BaseWidgetDTO extends BaseItemDTO {
  questions?: Array<WidgetQuestionDTO>;
  type: "WIDGET";
}

/* widget subtypes */

export interface DefensesWidgetDTO extends BaseWidgetDTO {
  autoAppendComment?: boolean;
  questions?: Array<DefenseWidgetQuestionDTO>;
}

export interface MapWidgetDTO extends BaseWidgetDTO {
  questions?: Array<MapWidgetQuestionDTO>;
  includeMap: boolean;
}

export interface OperationalExperiencesWidgetQuestionDTO {
  id?: number;
  questionRootId: number;
  suppressedTags: string[];
}

export interface OEWidgetDTO extends BaseWidgetDTO {
  questions?: Array<OperationalExperiencesWidgetQuestionDTO>;
  filterOutTags?: Array<string>;
  numberRequired: number;
}

export interface SafetyRatingQuestionsDTO extends WidgetQuestionDTO {
  parentSelectionRootId: number;
  sectionRootId: number;
}

export interface SafetyRatingWidgetDTO extends BaseWidgetDTO {
  allowComments: boolean;
  displayDescription: boolean;
  parentQuestionRootId: number;
  questions?: SafetyRatingQuestionsDTO[];
  requireCommentsFor?: Array<string>;
}

export function isSafetyRatingWidgetDTO(
  item: SectionItem
): item is SafetyRatingWidgetDTO {
  return item.type === "WIDGET" && item.subType === "SAFETY_RATING";
}

export interface SignatureWidgetDTO extends BaseWidgetDTO {
  allowedTypes: SignatureType[];
  signatureRequired: boolean;
}

export function isSignatureWidgetDTO(
  item: SectionItem
): item is SignatureWidgetDTO {
  return item.type === "WIDGET" && item.subType === "SIGNATURE";
}

/** Union type of all Widgets */
export type WidgetDTO =
  | DefensesWidgetDTO
  | MapWidgetDTO
  | OEWidgetDTO
  | SafetyRatingWidgetDTO
  | SignatureWidgetDTO;

// Describing the shape of the system's slice of state
export interface FormsState {
  loading: {
    searchForms: {
      GET?: boolean;
    };
    allForms: boolean;
    formTypes: boolean;
  };
  error: {
    searchForms: boolean | { message: string; status: string };
    allForms: boolean | { message: string; status: string };
    formTypes: boolean | { message: string; status: string };
  };
  data: {
    allForms: FormDTO[];
    searchForms?: SearchFormResponse;
    formTypes?: FormTypeDTO[];
  };
}

export interface SearchFormResponse extends Pagination {
  content?: FormDTO[];
}

export interface DocumentSummaryEntry {
  name: string;
  sourceQuestionRootId: number;
  type: string;
}

export type SectionItem =
  // BaseItemDTO
  | QuestionDTO
  | ContentDTO
  | DefensesWidgetDTO
  | MapWidgetDTO
  | OEWidgetDTO
  | SafetyRatingWidgetDTO
  | SignatureWidgetDTO;

export interface SectionDTO {
  id: number;
  rootId: number;
  isLatestVersion?: boolean;
  properties?: EntityProperties & {
    // TODO add these to EntityProperties -JA
    headerDescription?: string;
    headerTitle?: string;
  };
  items: Array<SectionItem>;
  parentWidgetRootId?: number;
  title?: string;
  workflowType?: WorkflowType;
}

export type QuestionSelectionsDTO = {
  id: number;
  rootId: number;
  defenseIds: number[];
  properties?: {
    commentRequired?: boolean;
    [key: string]: unknown;
  };
  title: string;
  tags: TagDTO[];
};

export type ValidationDTO = {
  description?: string;
  errorMessage?: string;
  id?: number;
  title?: string;
};

export type FormProperties = {
  isRequired: boolean;
  isSearchable: boolean;
  isCloneable?: boolean;
};

export interface GetQuestionsResponse extends APIResponse {
  response?: QuestionsDTO;
}

export interface QuestionsDTO extends Pagination {
  content: QuestionDTO[];
}

export type AnswerSourceType =
  | "DATA_SOURCE"
  | "CURRENT_GPS"
  | "CURRENT_PARTICIPANT"
  | "CURRENT_DATE";

export interface QuestionAnswerSourceDTO {
  dataSourceKey?: string | null;
  id: number;
  properties: Partial<EntityProperties> & {
    answerField: string;
    associatedIdField?: string;
    displayField?: string;
    prefillAnswerField?: string;
    prefillAssociatedIdField?: string;
    detailedSearch?: {
      customAnswerEnabled?: boolean;
      enabled?: boolean;
      infiniteListEnabled?: boolean;
      infiniteListSortBy?: string;
      infiniteListTitle?: string;
      linkName?: string;
      recentAnswersEnabled?: boolean;
      recentAnswersTitle?: string;
      searchBy?: string;
      subtitle?: string;
      title?: string;
    };
  };
  type: AnswerSourceType;
}

export const SEARCH_FORMS = {
  FAILURE: "SEARCH_FORMS_FAILURE",
  REQUEST: "SEARCH_FORMS_REQUEST",
  SUCCESS: "SEARCH_FORMS_SUCCESS",
};

// @TODO: remove after we ensure dependencies have been updated
// to use `QuestionAnswerSourceDTO` instead
export type answerSource = QuestionAnswerSourceDTO;

export const GET_ALL_FORMS = {
  FAILURE: "GET_ALL_FORMS_FAILURE",
  REQUEST: "GET_ALL_FORMS_REQUEST",
  SUCCESS: "GET_ALL_FORMS_SUCCESS",
};

export const GET_ALL_FORM_TYPES = {
  FAILURE: "GET_ALL_FORM_TYPES_FAILURE",
  REQUEST: "GET_ALL_FORM_TYPES_REQUEST",
  SUCCESS: "GET_ALL_FORM_TYPES_SUCCESS",
};

export const GET_FORM = {
  FAILURE: "GET_FORM_FAILURE",
  REQUEST: "GET_FORM_REQUEST",
  SUCCESS: "GET_FORM_SUCCESS",
};

export interface GetAllFormsResponse extends APIResponse {
  response?: any;
}

interface PaginatedForms extends Pagination {
  content: FormDTO[];
}

export interface SearchFormsResponse extends APIResponse {
  response?: PaginatedForms;
}

export interface GetFormResponse extends APIResponse {
  response?: FormDTO;
}

export interface SearchFormsParams {
  query: string;
  value?: number;
  // ownerIds?: string | number;
  clientGroupIds: string;
  formTypeId?: string | number;
  sortName?: string;
  sortOrder?: string;
  page?: string | number | null;
  size?: number | null;
}

// --------------------------------------------------------------------------
// ? TYPE DEFINITIONS FOR API VALUES
// The following types describe API response values which are
// directly related to the "Form" feature
// --------------------------------------------------------------------------

export type FormAction = {
  actionType: string;
  doItByDefault: boolean;
  id?: number;
  isRequired: boolean;
  properties?: {
    [key: string]: string;
  };
};

export type DocumentSummaryEntryDTO = {
  name?: string;
  sourceQuestionRootId?: number;
  type?: "TITLE" | "SUMMARY";
};

export interface FormSubmissionConstraintDTO {
  id: number;
  hourLimit?: number;
  timeLimit?: number; // seconds from midnight
  type: "TIME" | "HOUR";
  noSubmissionsUntilNextDay: boolean;
}

export type WorkflowType =
  | "DRAFT"
  | "FINAL"
  | "DEPRECATED"
  | "DEACTIVATED_DRAFT"
  | "DEACTIVATED_FINAL"
  | "DELETED" // should never see this and below
  | "DISCARDED_DRAFT"
  | "DEACTIVATED"; // deprecated (use specific above)

export type DocumentEditPermissions =
  | "DOCUMENT_CREATOR"
  | "DOCUMENT_CREATOR_AND_SUPERVISORS"
  | "DOCUMENT_PARTICIPANTS";

/**
 * part of response from `POST` to `/api/documents`
 */
export type FormDTO = {
  actions?: Array<FormAction>;
  allowedDocumentEditor: DocumentEditPermissions;
  clientGroups?: Array<ClientGroup>;
  defenses?: Array<Defense>;
  displayConditions?: DisplayConditionDTO[];
  documentSummaryEntries?: Array<DocumentSummaryEntryDTO>;
  formSubmissionConstraint?: FormSubmissionConstraintDTO;
  id: number;
  rootId: number;
  isRehuddleEligible?: boolean | null;
  lastModifiedDate?: string;
  name: string;
  properties?: {
    [key: string]: string;
  };
  relationshipTypes?: Array<FormRelationshipTypeDTO>;
  sharingEnabled?: boolean | null;
  sections: Array<SectionDTO>;
  signatureRequired?: boolean;
  startFromWorkOrderEnabled?: boolean | null;
  type: FormTypeDTO;
  workflowType: WorkflowType;
  operationalExperiences: Array<OperationalExperience>;
};

export interface FormVM {
  actions: FormAction[];
  clientGroups: number[];
  displayConditions: DisplayConditionDTO[];
  documentSummaryEntries: DocumentSummaryEntry[];
  name: string;
  questionFormProperties: unknown;
  sections: number[];
  signatureRequired: boolean;
  type: FormTypeDTO;
}

// Form relationship
export type FormRelationshipTypeDTO = {
  displayName: string;
  formId: number;
  formTypeId: number;
  id: number | null;
  softDeleted: boolean;
};

/**
 * response from `GET` to `/api/forms/all`
 */
export type FormSummaryVm = {
  formSubmissionConstraint?: FormSubmissionConstraintDTO;
  id: number;
  isRehuddleEligible: boolean | null;
  name: string;
  properties?: {
    [key: string]: string;
  };
  relationshipTypes: FormRelationshipTypeDTO[];
  sharingEnabled: boolean | null;
  startFromWorkOrderEnabled: boolean | null;
  type: FormTypeDTO;
  workflowType: WorkflowType;
};

export type FormTypeDTO = {
  active: boolean;
  iconColor?: string;
  iconName?: string;
  id: number;
  name: string;
  properties?: {
    [key: string]: string;
  };
};
