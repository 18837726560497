import React from "react";
import { get, isNumber } from "lodash";
import Map from "components/map";

import Icon from "components/common/Icon";

import * as S from "../styles";

export interface MapContent {
  location?: string | null;
  icon?: {
    type: string;
    color: string;
    size?: string; // @TODO: figure out why this doesn't work
  };
}

/**
 * **MapContent** returns a map based on the given location value.
 * If the given location value results in unusable data or is missing
 * then we'll render the fallback content (form type icon, grey background)
 */
const MapContent = ({ icon, location }: MapContent) => {
  if (location) {
    const coords = location
      .replace(/\(|\)/gi, "")
      .split(",")
      .map((c) => Number(c.trim()));
    const longitude = coords[1];
    const latitude = coords[0];
    if (longitude && latitude) {
      return (
        <S.MapContent>
          <img
            style={{ borderRadius: 4, maxWidth: "100%", maxHeight: "100%" }}
            src={`https://api.maptiler.com/maps/streets/static/${longitude},${latitude},10/136x52@2x.png?key=5eMrAi6NUCpm1Rv5zRKC`}
            alt="map"
          />
        </S.MapContent>
      );
    }
  }

  return (
    <S.MapContent>
      <S.MapContent>
        {icon && <Icon type={icon.type} color={icon.color} framed={false} size={icon.size} />}
      </S.MapContent>
    </S.MapContent>
  );
};

export default MapContent;
