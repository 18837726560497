import React from "react";
import { ReactComponent as Cross } from "assets/svg/cross.svg";
import { ActionButton } from "components/common/ActionButton";
import styles from "./styles.module.scss";
import { HandleBar } from "components/common/Drawer/futureUiKit";
import { joinClassNames } from "themes/helpers";

interface DrawerHeaderProps {
  handleClose: () => void;
  shouldShowHandleBar?: boolean;
  subtitle?: string;
  title: string;
  titleId: string;
}

export const DrawerHeader = ({
  handleClose,
  shouldShowHandleBar,
  subtitle,
  title,
  titleId,
}: DrawerHeaderProps) => {
  return (
    <>
      {shouldShowHandleBar && <HandleBar />}
      <div
        className={joinClassNames(
          styles.header,
          !shouldShowHandleBar && styles.headerWithoutHandleBar
        )}
      >
        <div
          className={joinClassNames(
            styles.drawerDetails,
            !shouldShowHandleBar && styles.headerWithoutHandleBar
          )}
        >
          <h2 className={styles.title} id={titleId}>
            {title}
          </h2>
          {subtitle && <span className={styles.subtitle}>{subtitle}</span>}
        </div>
        <ActionButton
          IconComponent={<Cross className="icon-black-24" />}
          onClick={handleClose}
          shouldShowTooltip={false}
          label="Close"
        />
      </div>
    </>
  );
};
