import React, { useRef, useState } from "react";

import { IconForEndAdornment } from "components/common/form/MultiInput/styles";
import { TextInput } from "components/common/form/types";
import Label from "components/common/form/Label";

import * as S from "../../../clientAdmin/resources/styles";
import { HelperText } from "components/forms/TextInput/styles";

export type UploadedFile = {
  title: string | null;
  url: string;
  [key: string]: unknown;
}

const isUploadedFile = (file: File | UploadedFile): file is UploadedFile => "url" in file;

const FileLabel = ({ file }: {file: File | UploadedFile}) => {
  if (isUploadedFile(file)) {
    return <a href={file.url} rel="noopener noreferrer" target="_blank">{file.title}</a>; 
  } else {
    return <span>{file.name}</span>;
  }
};

const AddedFile = ({ file, onRemove }: {file: File | UploadedFile; onRemove: () => void}) => (
  <S.AddedFile>
    <S.AddedFileLabel><FileLabel file={file} /></S.AddedFileLabel>
    <IconForEndAdornment className="icon icon-icons8-delete_sign" onClick={onRemove} />
  </S.AddedFile>
);

export interface FileUploadFieldProps extends TextInput {
  files: (File | UploadedFile)[];
  maxFileSize?: number;
  onUpdateFiles: (files: (File | UploadedFile)[]) => void;
}

const FileUploadField = ({
  files,
  helperText,
  label, 
  maxFileSize,
  name, 
  onUpdateFiles,
  placeholder, 
}: FileUploadFieldProps) => {
  const fileUploaderRef = useRef<HTMLInputElement>(null);
  const [fileUploadError, setFileUploadError] = useState<string>("");

  const handleAddFiles = (e: React.ChangeEvent<HTMLInputElement>) => {
    const targetFiles = e.target.files ? Array.from(e.target.files): [];
    let addedFiles = [...targetFiles];
    if (maxFileSize) {
      let fileSizeError = fileUploadError;
      addedFiles = targetFiles.filter((file) => {
        if(file.size > maxFileSize) {
          fileSizeError += `\n${file.name} is larger than maximum file size`;
          return false;
        }
        return true;
      });
      setFileUploadError(fileSizeError);
    }
    // @todo check if we've already added any of these files and extend the name 
    onUpdateFiles([...files, ...addedFiles]);
  };

  const handleRemoveFile = (removedFile: File | UploadedFile) => {
    let updatedFiles = [...files];

    if (isUploadedFile(removedFile)) { // if it's a previously uploaded document
      updatedFiles = files.filter((file) => (!("title" in file) || file.title !== removedFile.title));
    } else { // if it's a newly added document
      updatedFiles = files.filter((file) => file.name !== removedFile.name);
    }
    onUpdateFiles(updatedFiles);
  };


  return (
    <S.FileUploadFieldWrapper>
      <Label htmlFor={name?.toString() || ""}>
        {label}
      </Label>
      <S.FileUploadItemsContainer>
        <S.FileUploadItems>
          {files?.length ? files.map((file, index) => (
            <AddedFile
              key={index}
              onRemove={() => handleRemoveFile(file)}
              file={file}
            />
          )) : (
            <S.FileUploadFieldPlaceholder htmlFor="file">{placeholder}</S.FileUploadFieldPlaceholder>
          )}
        </S.FileUploadItems>
      </S.FileUploadItemsContainer>
      {helperText && <HelperText>{helperText}</HelperText>}
      {fileUploadError && <S.ErrorText>{fileUploadError}</S.ErrorText>}

      {/* hidden input field to handle the file uploader */}
      <input 
        type="file" 
        id="file" 
        multiple 
        ref={fileUploaderRef} 
        style={{ display: "none" }} 
        onChange={handleAddFiles}
        accept=".pdf,.jpg,.png"
      />

      <S.UploadFileButton htmlFor="file">Choose Files</S.UploadFileButton>
    </S.FileUploadFieldWrapper>
  );
};

export default FileUploadField;
