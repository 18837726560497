const joinClassNames = (
  ...classNames: (string | null | undefined | false)[]
): string => {
  return classNames
    .filter((className): className is string => !!className)
    .join(" ");
};

const remCalc = (value: number) => `${value / 16}rem`;

const hexToDecimal = (value: string) => {
  return parseInt(value, 16).toString(10);
};

/**
 * Converts a CSS rgb function expression 'rgb(r, g, b)' or hex color '#rrggbb'
 * to 'rgba(r, g, b, a)'.
 */
const toRgba = (rgb: string, a: number): string => {
  const prefix = "rgb(";
  let tupleContents: string;
  if (rgb.startsWith(prefix)) {
    tupleContents = rgb.slice(
      rgb.indexOf(prefix) + prefix.length,
      rgb.lastIndexOf(")")
    );
  } else {
    const hexPrefix = "#";
    const hexColor = rgb.slice(rgb.indexOf(hexPrefix) + hexPrefix.length);
    const r = hexToDecimal(hexColor.slice(0, 2));
    const g = hexToDecimal(hexColor.slice(2, 4));
    const b = hexToDecimal(hexColor.slice(4, 6));
    tupleContents = [r, g, b].join(", ");
  }
  return `rgba(${tupleContents}, ${a})`;
};

const rgbToHexColor = (rgb: string) => {
  const prefix = "rgb(";
  const hexColor = rgb
    .slice(rgb.indexOf(prefix) + prefix.length, rgb.lastIndexOf(")"))
    .split(",")
    .map((value) =>
      parseInt(value)
        .toString(16)
        .padStart(2, "0")
    )
    .join("");
  return `#${hexColor}`;
};

const transitionsWithSameTiming = (
  properties: string[],
  duration: string,
  timingFunction: string
): string => {
  return properties
    .map((property) => `${property} ${duration} ${timingFunction}`)
    .join(", ");
};

export {
  joinClassNames,
  remCalc,
  rgbToHexColor,
  toRgba,
  transitionsWithSameTiming,
};
