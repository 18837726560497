import React from "react";
import styled from "styled-components";

import {
  ButtonsWrapper,
  CancelButton,
  SubmitButton,
} from "components/screens/documents/components/RehuddleSidebar/styles";
import { H2 as BaseH2 } from "components/clientAdmin/styles";
import Loader from "components/common/Loader";
import { useSelector } from "react-redux";
import { AppState } from "store";
import StickyHeader from "components/common/Drawer/components/StickyHeader";
import DrawerContentWrapper from "components/common/Drawer/components/DrawerContentWrapper";
import { ErrorText } from "components/common/form/TextInput/styles";

export interface Props {
  formType?: string;
  onClose: () => void;
  onSubmit: () => void;
  title?: string;
  error?: string;
  submissionTimeError?: string;
}

const Body = styled.div`
  text-align: left;
`;

const Title = styled(BaseH2)`
  color: #000000DE;
  font-weight: 500;
  letter-spacing: 0.26px;
  margin-bottom: 3px;
`;

const Description = styled.p`
  color: #000000DE;
  font-size: 0.875rem;
  font-weight: 400;
  letter-spacing: 0.26px;
`;

const InterstitialDrawerContent: React.FC<Props> = ({ 
  error,
  submissionTimeError,
  formType = "Form",
  onClose,
  onSubmit,
  title = ""
}) => {

  // Get loading status
  const loading = useSelector((state: AppState) => 
    state.newDocument?.loading.createDocument || state.newDocument?.loading.submitDocument);

  return (
    <>
      <StickyHeader
        title={title}
        onClick={onClose}
        chevronRight={false}
      />
      {/* Content */}
      <DrawerContentWrapper>
        <Body>
          <Title>{title}</Title>
          <Description>
          This will create a new {formType} that carries over only some key information from 
          the original while providing cleared fields for the rest of the form.
          </Description>
          {submissionTimeError && <ErrorText>{submissionTimeError}</ErrorText>}
          <ButtonsWrapper>
            <SubmitButton
              onClick={onSubmit}
              fullWidth={true}
              height={2.25}
              disabled={loading || !!submissionTimeError}
              loading={loading}
            >
              START DOCUMENT
            </SubmitButton>
            <CancelButton
              onClick={onClose}
              variant={"unstyled-danger"}
              fullWidth={true}
              height={2.25}
              disabled={loading}
            >
            Cancel
            </CancelButton>
          </ButtonsWrapper>
          {error && <ErrorText>{error}</ErrorText>}
        </Body>
      </DrawerContentWrapper>
      
    </>
  );
};

export default InterstitialDrawerContent;
