import styled from "styled-components";

import Text from "components/common/Text";
import { remCalc } from "themes/helpers";

export const ReadOnly = styled.div<{
  renderMap: boolean;
  isDesktop: boolean;
}>`
  width: 100%;

  ${({ isDesktop, renderMap }) => isDesktop ? `
  max-width: 1024px;
  padding: ${renderMap ? "50px" : "0px"} 8px 0px;
  margin: 0 auto ${remCalc(150)} auto;
  ` : `
  padding: ${renderMap ? "100px" : "0px"} 11px 0px;
  `}
`;

export const RenderedValues = styled.ul`
  list-style: none;
  margin: 0px;
  padding: 0px;
`;

export const Response = styled.li`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid ${({ theme }) => theme.masterColors.lightGrey};
  padding: 21px 0px 23px 0px;
`;

export const Label = styled(Text)`
  font-size: ${remCalc(16)};
  font-weight: 500;
  margin: 0px 0px 15px 0px;
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Col = styled.div``;

export const Value = styled(Text)`
  font-size: ${remCalc(16)};
  font-weight: 400;
  color: ${({ theme }) => theme.masterColors.darkGrey};
`;

export const ResponseLabel = styled(Text)`
  font-size: ${remCalc(14)};
  font-weight: 400;
`;

// OEs

export const ViewOEsWrapper = styled(RenderedValues)``;

export const OEsLabel = styled(Label)`
display: inline-block;
  margin: 21px 0px 23px 0px;

`;

export const RenderedOE = styled(Row)`
  margin: 0px 0px 13px 0px;
  width: 100%;
`;

export const RenderedOELeft = styled.div`
  flex-grow: 50%;
  flex-shrink: 50%;
  flex-basis: 50%;
`;
export const RenderedOERight = styled.div`
  flex-grow: 50%;
  flex-shrink: 50%;
  flex-basis: 50%;
  text-align: right;
  text-overflow: wrap;
  white-space: wrap;
  hyphens: auto;
  word-break: break-word;

  color: ${({ theme }) => theme.masterColors.darkGrey};
`;

export const ViewResponses = styled.ul`
  list-style: none;
  margin: 0px;
  padding: 0px;
`;


export const CommentWrapper = styled(Row)`
  margin: 7px 0px 14px 0px
`;

export const RenderedOEDescription = styled.div``;

export const RenderedOETitle = styled.label`
  font-weight: bold;
`;
