import styled from "styled-components";

import { toRgba } from "themes/helpers";

export const WidgetContainer = styled.div`
  height: 264px;
  min-width: 328px;
  border: 1px solid ${({ theme }) => toRgba(theme.colors.black, 0.1)};
  border-radius: 4px;
  padding: 13px;
`;

export const IconWrapper = styled.div`
  margin-top: 1rem;
  span {
    display: block;
  }
`;

export const ChartContainer = styled.div`
  height: 80%;
  width: 100%;
`;
