import React, { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { DocumentParticipant } from "store/documents/types";
import { FormAction } from "store/forms/types";
import { SubmissionType, uploadNewDocument } from "store/newDocument/actions";
import { DocumentFormValuesType } from "./Document";

export function usePutDocument() {
  const [error, setError] = useState<string>("");
  const [submissionSuccessful, setSubmissionSuccessful] = useState<boolean>(
    false
  );
  const dispatch = useDispatch();

  const handlePutDocument = useCallback(
    async (
      values: DocumentFormValuesType,
      documentParticipants: Array<DocumentParticipant>,
      submissionType: SubmissionType,
      id?: number,
      formActions?: Array<FormAction>
    ) => {
      const submissionObject = {
        id: id,
        responses: values.responses,
        documentParticipants,
        submissionType: submissionType || "SUBMIT",
        operationalExperiences: values.operationalExperiences || [],
        formActionRequests: formActions
          ?.filter((action) => !!action.id)
          .map((action) => ({
            actionId: action.id!,
            requestBody: action.actionType,
          })),
      };
      try {
        await dispatch(uploadNewDocument(submissionObject));
        if (submissionType === "SUBMIT" || submissionType === "SAVE_DRAFT") {
          setSubmissionSuccessful(true);
        }
      } catch (e) {
        const errorString = JSON.stringify(e);
        setError(errorString);
      }
    },
    [dispatch]
  );
  return {
    error,
    setError,
    submissionSuccessful,
    handlePutDocument,
  };
}
