import { CALL_API } from "middleware/api";
import { buildFilteredEndpoint } from "store/common/apiUtilities";

import { GET_ALL_FEEDBACK_TYPES } from "./types";

export const getAllFeedbackTypes = () => (dispatch, getState) => {
  const { system } = getState();
  const endpoint = buildFilteredEndpoint("admin/feedback-types/all", { sort: "displayOrder,asc" });
  return dispatch({
    [CALL_API]: {
      endpoint,
      options: {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${system.authToken}`,
        },
      },
      types: [
        GET_ALL_FEEDBACK_TYPES.REQUEST,
        GET_ALL_FEEDBACK_TYPES.SUCCESS,
        GET_ALL_FEEDBACK_TYPES.FAILURE,
      ],
    },
  })
    .then((res) => res.response)
    .catch((err) => {
      throw new Error(err);
    });
};
