import { FBSection, FBItem } from "components/clientAdmin/formBuilder/types";
import { getItemName } from "../../../content/Item";
import { Option } from "components/common/form/Select/Select";


export function getTargetItems(sections: FBSection[]): DCTargetOption[] {
  return sections.flatMap((section: FBSection) => ([
    {
      ...section,
      id: section.rootId,
      sectionIndex: section.position
    },
    ...section.items.map((item) => ({
      ...item,
      id: item.rootId,
      sectionIndex: section.position,
      itemIndex: item.position
    }))
  ]));
}

type DCTargetOption = FBItem & {sectionIndex: number; itemIndex: number} | FBSection & { sectionIndex: number }

function buildTargetOptionTitle(item: DCTargetOption) {
  let title = `Section ${item.sectionIndex + 1}`;
  switch (item.type) {
    case "SECTION":
      break;
    case "WIDGET":
    case "CONTENT":
      title = `${title}, Item ${item.itemIndex + 1}: ${getItemName(item.type, item.subType)}`;
      break;
    default:
      title = `${title}, Item ${item.itemIndex + 1}: ${
        item.title || `Untitled ${getItemName(item.type, item.subType)}`
      }`;
  }
  return title;
}

export function buildTargetOptions(targetItems: DCTargetOption[], item: FBItem | FBSection): Option[] {
  // filter out the source (selected) item
  // and any items which belong to widgets
  const availableItems = targetItems.filter((tItem) => 
    tItem.id !== item.id &&
    tItem.id !== item.parentID &&
    !tItem.parentWidgetRootId
  );
  
  return availableItems.map((item) => ({ 
    id: item.rootId, 
    value: buildTargetOptionTitle(item)
  }));
}
