import React from "react";
import styled from "styled-components";
import PopperBase from "@material-ui/core/Popper";
import MenuItemBase from "@material-ui/core/MenuItem";
import MenuListBase from "@material-ui/core/MenuList";

import ListItemBase from "components/common/ListItem";
import { TextInput as TextInputBase } from "components/forms/TextInput";
import LabelBase from "components/forms/Label";
import { remCalc } from "themes/helpers";
import TextBase, { TextProps } from "components/common/Text";

// @TODO: Update all exports to inline named exports

export const ClearText = styled(TextBase)`
  color: ${({ theme }) => theme.masterColors.error};
`;

const ErrorText = styled<React.FC<Omit<TextProps, "variant">>>((props) => {
  return <TextBase as="div" variant="error" {...props} />;
})`
  margin-left: ${remCalc(16)};
`;

const MultiInput = styled.div<{ ref?: any }>``;

const TextInput = styled(TextInputBase)``;

const Popper = styled(({ ...props }) => (
  <PopperBase {...props} classes={{ root: "root" }} />
))`
  top: -22px;
  
  && .root {
    background: ${({ theme }) => theme.colors.white};
  }
`;

const MenuAnchor = styled.div<{ ref?: any }>``;

const Options = styled(({ parentWidth, ...props }) => (
  <MenuListBase
    {...props}
    classes={{
      root: "root",
    }}
  />
))`
  &&.root {
    width: ${({ parentWidth }) => remCalc(parentWidth)};
    box-shadow: 0px 1px 2px ${({ theme }) => theme.masterColors.mediumGrey};
    border-radius: ${remCalc(4)};
    max-height: 250px;
    overflow-y: scroll;
    overflow-x: none;
  }
`;

const Option = styled(({ ...props }) => (
  <MenuItemBase
    {...props}
    classes={{
      root: "root",
      selected: "selected",
    }}
  />
))`
  &&.root {
    z-index: 1000000000000;
    &:hover {
      background: ${({ theme }) => theme.colors.activeFill};
    }
  }
`;

const ListItems = styled.ul`
  padding: ${remCalc(12)};
  margin: 0;
  /* width: 100%; */

  border: 1px solid ${({ theme }) => theme.masterColors.darkGrey};
  border-radius: ${remCalc(4)};
`;

const ListItemOption = styled(ListItemBase)`
  margin-bottom: ${remCalc(3)};
`;

const Label = styled(LabelBase)`
  margin-bottom: ${remCalc(8)};
`;

const IconForEndAdornment = styled.i`
  color: ${({ theme }) => theme.masterColors.darkGrey};
  &:hover {
    cursor: pointer;
  }
`;

export {
  TextInput,
  Popper,
  Options,
  Option,
  MultiInput,
  MenuAnchor,
  ListItems,
  ListItemOption,
  Label,
  IconForEndAdornment,
  ErrorText,
};
