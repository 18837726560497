import { get } from "lodash";

import { AppState } from "../index";
import { Defense, DocumentVM } from "../documents/types";
import { NewDocumentState } from "./types";
import { OperationalExperience } from "../resources/types";

export const getCurrentDocumentId = ({
  newDocument,
}: AppState): number | undefined => get(newDocument, "currentDocument.id");

export const getDefenses = ({
  currentDocument,
}: NewDocumentState): Array<Defense> =>
  get(currentDocument, ["form", "defenses"], []);

export const selectDocumentOEs = ({
  newDocument,
}: AppState): Array<OperationalExperience> =>
  get(newDocument, "currentDocument.operationalExperiences", []);
