import { StyleSheet, StyleDeclaration } from "aphrodite";

import { AssistiveLink } from "../../types";
import { remCalc } from "themes/helpers";
import { CreateStyleSheetWithProps } from "util/hooks/useThemedStyleSheet";

interface LabelStyle {
  a: StyleDeclaration;
  button: StyleDeclaration;
  buttonMouseFocused: StyleDeclaration;
  label: StyleDeclaration;
  labelSmall: StyleDeclaration;
  span: StyleDeclaration;
  wrapper: StyleDeclaration;
}

interface Props {
  assistiveLink?: AssistiveLink;
  noMargin?: boolean;
}

const styles: CreateStyleSheetWithProps<LabelStyle, Props> = (theme, { assistiveLink, noMargin }) => {
  const color = assistiveLink?.color || theme.colors.primary;
  const { focusIndicatorAppear } = theme.animations;

  return StyleSheet.create({
    a: {
      color,
      fontSize: remCalc(14),
      lineHeight: remCalc(22),
    },

    button: {
      background: "none",
      border: "none",
      borderRadius: remCalc(4),
      boxShadow: `0 0 0 ${remCalc(6)} transparent`,
      color,
      cursor: "pointer",
      fontSize: remCalc(16),
      lineHeight: remCalc(24),
      padding: `0 ${remCalc(8)} 0 ${remCalc(8)}`,
      textDecorationLine: "underline",
      transition: `box-shadow ${focusIndicatorAppear.duration} ${focusIndicatorAppear.timingFunction}`,
      ":focus": {
        boxShadow: `0 0 0 ${remCalc(4)} ${theme.colors.focusIndicatorOutline}`,
      },
      ":hover": {
        color: theme.masterColors.secondary,
      },
    },

    buttonMouseFocused: {
      ":focus": {
        boxShadow: "0",
      },
    },

    span: {
      color,
      display: "block",
      fontSize: remCalc(14),
    },

    label: {
      color: theme.colors.formFieldLabel,
      fontSize: remCalc(20),
      fontWeight: 500,
      marginTop: noMargin ? 0 : remCalc(16),
      marginBottom: 0,
    },

    labelSmall: {
      color: theme.masterColors.darkGrey,
      fontSize: remCalc(13),
      marginButton: remCalc(5),
    },

    wrapper: {
      alignItems: "flex-end",
      display: "flex",
      flexDirection: "row",
      justifyContent: assistiveLink ? "space-between" : "flex-start",
      marginBottom: remCalc(4),
    },
  });
};

export default styles;
