import Portal from "components/common/Portal";
import React from "react";
import * as S from "./styles";

export type ToastVariant = "default" | "success" | "error" | "save" | "new" | "read only";

export interface ToastProps {
  children: React.ReactNode;
  /**
   * 1. `success` - renders a green **Toast** with a check icon + `children`
   * 2. `error` - renders a red **Toast** with
   * 3. `default` - no color styling or added elements, though it should be
   * noted that there are base styles which can be overwritten using `className`
   * 4. `new` - renders a blue **Toast** with a check icon and `children`
   * 5. `read only` - renders a green **Toast** with an info icon and `children`
   * 6. `save` - renders a yellow **Toast** with an info icon and `children`
   *
   * defaults to `default`
   */
  variant: ToastVariant;
  visible: boolean;
  /**
   * invoked when component is clicked
   * optional prop, so you can elect to include
   * a button within `children` instead with its
   * own onClick method
   */
  onClick?: (e?: any) => void;
  /**
   * optional method which is invoked after a timeout
   */
  onDelay?: (e?: any) => void;
  /**
   * used with `onDelay`, sets the amount of time
   * which should elapse before the `onDelay` method
   * is invoked
   * defaults to 4000
   */
  onDelayTime: number;
  /**
   * styles passed to the `S.ToastChildren` element
   * which is a button which wraps around `children`
   */
  className?: any;
  shouldScroll?: boolean;
}

/**
 * reusable component which renders using __Portal__
 * variants:
 * 1. `success` - renders a green **Toast** with a check icon + `children`
 */
const Toast = ({ children, visible, variant, onClick, onDelay, onDelayTime, className, shouldScroll }: ToastProps) => {
  // state used to store the timeout ID so we can clear if the user clicks
  // rather than waits for the timeout to execute
  const [timeoutId, setTimeoutId] = React.useState<number | undefined>(undefined);

  // effect to clear timeoutId on unmount
  React.useEffect(() => {
    return () => {
      if (timeoutId) {
        window.clearTimeout(timeoutId);

        return setTimeoutId(undefined);
      }
    };
  }, [timeoutId]);

  if (!visible) {
    return null;
  }

  // set timeout
  if (onDelay && !timeoutId) {
    setTimeoutId(
      window.setTimeout(() => {
        return onDelay();
      }, onDelayTime),
    );
  }

  // local onClick handler to clear timeout
  const localOnClick = () => {
    window.clearTimeout(timeoutId);

    if (onClick) {
      onClick();
    }
  };

  return (
    <Portal variant="default">
      {/* @NOTE: THIS BOOTSTRAP CLASS IS TEMP */}
      {/* w/o it, there's an issue where the component extends the view width */}
      <S.Toast className="container-fluid" shouldScroll={shouldScroll}>
        <S.ToastChildren variant={variant} onClick={localOnClick} className={className}>
          {(variant === "success" || variant === "new") && <S.ToastIcon className="icon icon-icons8-checkmark" />}
          {/* {variant === "error" && <S.ToastIcon className="icon icon-icons8-info" />} */}
          {variant === "error" && <S.ErrorIcon />}
          {(variant === "save" || variant === "read only") && <S.ToastIcon className="icon icon-icons8-info" />}

          {children}
        </S.ToastChildren>
      </S.Toast>
    </Portal>
  );
};

Toast.defaultProps = {
  variant: "default",
  onDelayTime: 4000,
};

export default Toast;
