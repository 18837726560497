import { StyleDeclaration, StyleSheet } from "aphrodite";
import { remCalc } from "themes/helpers";
import { focusIndicatorAppear, focusIndicatorDisappear } from "themes/mixins";
import { CreateStyleSheet } from "util/hooks/useThemedStyleSheet";

interface NavButtonStyle {
  navButton: StyleDeclaration;
  navButtonKeyboardFocused: StyleDeclaration;
}

export const styles: CreateStyleSheet<NavButtonStyle> = (theme) => {
  return StyleSheet.create({
    navButton: {
      ":hover": {
        backgroundColor: theme.colors.activeFill,
      },
      ...focusIndicatorDisappear(theme),
      border: "none",
      borderRadius: remCalc(4),
      backgroundColor: "transparent",
      color: theme.colors.primary,
      padding: 0,
      height: remCalc(24),
      width: remCalc(24),
    },
    navButtonKeyboardFocused: {
      ":focus": {
        ...focusIndicatorAppear(theme),
      },
    },
  });
};

export interface BackIconStyle {
  icon: StyleDeclaration;
}

export const backIconStyles: CreateStyleSheet<BackIconStyle> = (theme) => {
  return StyleSheet.create({
    icon: {
      fill: theme.colors.primary,
      height: "100%",
      width: "100%",
    },
  });
};

export const forwardIconStyles: CreateStyleSheet<BackIconStyle> = (theme) => {
  return StyleSheet.create({
    icon: {
      fill: theme.colors.primary,
      height: "100%",
      transform: "scaleX(-1)",
      width: "100%",
    },
  });
};
