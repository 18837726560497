
import React, { useMemo, useEffect, useState, useCallback } from "react";
import { Action } from "redux";
import { Formik, Field } from "formik";
import { ThunkDispatch } from "redux-thunk";
import { uniqBy } from "lodash";
import { useDispatch } from "react-redux";
import { withRouter } from "react-router";

import { addUserParticipant, updateUserParticipant } from "store/usersParticipants/actions";
import { AppState } from "store";
import { ClientGroup } from "store/clientGroups/types";
import { getClientGroups } from "store/clientGroups/actions";
import { getParticipantsSummary } from "store/participantsSummary/actions";
import { getUserParticipant } from "store/usersParticipants/actions";
import { getWorkLocations } from "store/workLocations/actions";
import { Label } from "components/common/form/Label/futureUiKit";
import { ParticipantSummary } from "store/participantsSummary/types";
import { RolesAndGroups } from "./components/RolesAndGroups";
import { SearchSelect, OptionContent } from "components/common/form/SearchableSelect/futureUiKit/SearchSelect";
import { UsersParticipant } from "store/usersParticipants/types";
import { WorkLocationContent } from "store/workLocation/types";
import Breadcrumbs from "components/common/Breadcrumbs";
import Loader from "components/common/Loader";
import RadioButton from "components/common/form/RadioButton";
import TextInput from "components/common/form/TextInput";
import Toast from "components/common/Toast";
import useGroupTerm from "util/hooks/whiteLabel/useGroupTerm";

import {
  atLeastOneRoleHasGroup,
  buildBreadcrumbs,
  flattenAuthorities,
  getAuthorities,
  getInitialValues,
  mapClientGroupsOptions,
  mapSupervisorOptions,
  mapWorkLocations,
  updateAuthorities,
  validate,
} from "./helpers";
import { Props, AuthorityDTO } from "./types";

import * as S from "./styles";
import { PageContent } from "../styles";

const AddUser = ({ history, match }: Props) => {
  const dispatch = useDispatch<ThunkDispatch<AppState, void, Action>>();
  // Group config terms
  const supervisorTerm = useGroupTerm("supervisor", "noun", undefined, "Supervisor");
  // Get user id from url
  const params: { id?: number } = match.params;
  const participantId = params.id;

  // current user
  const [currentUser, setCurrentUser] = useState<Partial<UsersParticipant>>({});
  const [currentUserIsLoading, setCurrentUserIsLoading] = useState<boolean>(!!participantId);
  const [currentUserError, setCurrentUserError] = useState<boolean>(false);
  // authorities
  const [authorities, setAuthorities] = useState<{string?: number[]}>({});
  // work locations
  const [searchedWorkLocations, setSearchedWorkLocations] = useState<WorkLocationContent[]>([]);
  const [workLocationPage, setWorkLocationPage] = useState<number>(0);
  const [workLocations, setWorkLocations] = useState<WorkLocationContent[]>([]);
  const [workLocationsLoading, setWorkLocationsLoading] = useState<boolean>(false);
  const [workLocationsQuery, setWorkLocationsQuery] = useState<string>("");
  const [isLastWorkLocationsPage, setIsLastWorkLocationsPage] = useState<boolean>(false);
  // supervisors (participants)
  const [searchedSupervisors, setSearchedSupervisors] = useState<ParticipantSummary[]>([]);
  const [supervisorsPage, setSupervisorsPage] = useState<number>(0);
  const [supervisors, setSupervisors] = useState<ParticipantSummary[]>([]);
  const [supervisorsLoading, setSupervisorsLoading] = useState<boolean>(false);
  const [supervisorsQuery, setSupervisorsQuery] = useState<string>("");
  const [isLastSupervisorsPage, setIsLastSupervisorsPage] = useState<boolean>(false);
  // client groups
  const [searchedClientGroups, setSearchedClientGroups] = useState<ClientGroup[]>([]);
  const [clientGroupsPage, setClientGroupsPage] = useState<number>(0);
  const [clientGroups, setClientGroups] = useState<ClientGroup[]>([]);
  const [clientGroupsLoading, setClientGroupsLoading] = useState<boolean>(false);
  const [clientGroupsQuery, setClientGroupsQuery] = useState<string>("");
  const [isLastClientGroupsPage, setIsLastClientGroupsPage] = useState<boolean>(false);
  // form submission
  const [errorToast, setErrorToast] = useState({ error: "", showing: false });
  const [submitStatus, setSubmitStatus] = useState<string | null>(null);
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);

  /** get user data */
  const getUserData = useCallback(() => {
    if (participantId) {
      dispatch(getUserParticipant(participantId)).then((e) => {
        setCurrentUserIsLoading(false);
        // On success
        if (e.type === "GET_USERS_PARTICIPANT_SUCCESS") {
          // If response is a string
          if (e.response && typeof e.response === "string") {
            const parsedResponse = JSON.parse(e.response) || ""; // Have to parse
            setCurrentUser(parsedResponse);
            setAuthorities(flattenAuthorities(parsedResponse.authorities));
          }
        // On error
        } else if (e.type === "GET_USERS_PARTICIPANT_FAILURE") {
          setCurrentUserError(true);
        }
      });
    }
  }, [dispatch, participantId]);

  /**
   * Fetch work locations and return object with content array and isLastPage
   * @param params work location search params
   */
  async function handleGetParticipants(params) {
    const res = await dispatch(getParticipantsSummary(params));
    return {
      content: res.response?.content || [],
      isLastPage: res.response?.last
    };
  }

  /**
   * Fetch client groups and returns object with content array and isLastPage
   * @param params client group search params
   */
  async function handleGetClientGroups(params) {
    const res = await dispatch(getClientGroups(params));
    return {
      content: res.response?.content || [],
      isLastPage: res.response?.last
    };
  }

  /**
   * Fetch work locations and returns object with content array and isLastPage
   * @param params work location search params
   */
  async function handleGetWorkLocation(params) {
    const res = await dispatch(getWorkLocations(params));
    return {
      content: res.response?.content || [],
      isLastPage: res.response?.last
    };
  }

  // Fetch initial data
  useEffect(() => {
    (async() => {
      // get initial client groups
      const cgRes = await handleGetClientGroups({ page: 0, size: 100, sort: "name,asc" });
      setClientGroups(cgRes.content);
      setIsLastClientGroupsPage(!!cgRes.isLastPage);

      // get initial work locations
      const wlRes = await handleGetWorkLocation({ page: 0, size: 100, sort: "name,asc" });
      setWorkLocations(wlRes.content);
      setIsLastWorkLocationsPage(!!wlRes.isLastPage);
  
      // get initial supervisors
      const supRes = await handleGetParticipants({ page: 0, size: 100, sort: "firstName,asc" });
      setSupervisors(supRes.content);
      setIsLastSupervisorsPage(!!supRes.isLastPage);
    })();
  }, []);

  // Fetch current user data to prefill form
  useEffect(() => getUserData(), [getUserData, participantId]);
  // Initial values for formik
  const initialValues = useMemo(() => getInitialValues(currentUser), [currentUser.email, participantId]);

  /*** 
   * Field handlers (search, infinite loading) 
  ***/

  /** search participants */
  const searchParticipants = async (query: string) => {
    setSupervisorsLoading(true);
    const results = await handleGetParticipants({
      page: 0,
      size: 100,
      sort: "firstName,asc",
      searchText: query,
    });
    setSearchedSupervisors(results.content);
    setSupervisorsLoading(false);
  };

  // search supervisors on query change
  useEffect(() => {
    if (supervisorsQuery?.length > 2) {
      searchParticipants(supervisorsQuery);
    } else {
      setSearchedSupervisors([]);
    }
  }, [supervisorsQuery]);

  // get next page of supervisors, appended to existing list
  useEffect(() => {
    (async () => {
      setSupervisorsLoading(true);
      const results = await handleGetParticipants({
        page: supervisorsPage,
        size: 100,
        sort: "firstName,asc"
      });
      setSupervisors((current) => uniqBy([...current, ...results.content], "id"));
      setIsLastSupervisorsPage(!!results.isLastPage);
      setSupervisorsLoading(false);
    })();
  }, [supervisorsPage]);

  
  /** 
   * search client groups by query string
   * limited to 1 page of 100 results
   */
  const searchClientGroups = async (query: string) => {
    setClientGroupsLoading(true);
    const results = await handleGetClientGroups({
      page: 0,
      size: 100,
      query,
      sort: "name,asc",
    });
    setSearchedClientGroups(results.content);
    setClientGroupsLoading(false);
  };

  // search client croups on query change
  useEffect(() => {
    if (clientGroupsQuery?.length > 2) {
      searchClientGroups(clientGroupsQuery);
    } else {
      setSearchedClientGroups([]);
    }
  }, [clientGroupsQuery]);

  // get next page of work locations, appended to existing list
  useEffect(() => {
    (async () => {
      setClientGroupsLoading(true);
      const results = await handleGetClientGroups({
        page: clientGroupsPage,
        size: 100,
        sort: "name,asc"
      });
      setClientGroups((current) => uniqBy([...current, ...results.content], "id"));
      setIsLastClientGroupsPage(!!results.isLastPage);
      setClientGroupsLoading(false);
    })();
  }, [clientGroupsPage]);

  /** 
   * search client groups by query string
   * limited to 1 page of 100 results
   */
  const searchWorkLocations = async (query: string) => {
    setWorkLocationsLoading(true);
    const results = await handleGetWorkLocation({
      page: 0,
      size: 100,
      query,
      sort: "name,asc",
    });
    setSearchedWorkLocations(results.content);
    setWorkLocationsLoading(false);
  };

  // search client groups on query change
  useEffect(() => {
    if (workLocationsQuery?.length > 2) {
      searchWorkLocations(workLocationsQuery);
    } else {
      setSearchedWorkLocations([]);
    }
  }, [workLocationsQuery]);

  // get next page of client groups, appended to existing list
  useEffect(() => {
    (async () => {
      setWorkLocationsLoading(true);
      const results = await handleGetWorkLocation({
        page: workLocationPage,
        size: 100,
        sort: "name,asc"
      });
      setWorkLocations((current) => uniqBy([...current, ...results.content], "id"));
      setIsLastWorkLocationsPage(!!results.isLastPage);
      setWorkLocationsLoading(false);
    })();
  }, [workLocationPage]);

  /***
   * Form Submission 
  ***/

  /**
   * Submit formik form
   * @param values Form values
   */
  const _handleSubmit = (values) => {
    let _authorities: AuthorityDTO[] = [];
    let participant = {};
    let user = {};

    if (values?.createUser && !atLeastOneRoleHasGroup(authorities)) {
      setErrorToast({
        error: "At least one Group / Role set must be present",
        showing: true,
      });
      return;
    } else {
      setErrorToast({
        error: "",
        showing: false,
      });
    }

    if (values) {
      // Build authorities object
      _authorities = getAuthorities(authorities, clientGroups, values);

      // Build participant (Deprecated)
      participant = {
        source: "USER_GENERATED",
      };

      // Build user (Deprecated)
      user = {
        // activated: true,
        authProviderType: "local",
        status: "ACTIVE",
      };

      const payload = {
        authorities: _authorities,
        createUser: values.createUser,
        email: values.email,
        firstName: values.firstName,
        lastName: values.lastName,
        nickname: values.nickname,
        participant, // To be deprecated
        participantId: currentUser.participantId,
        participantType: "EMPLOYEE",
        primaryGroupId: values.primaryGroupId,
        supervisorId: values.supervisorId || null,
        user, // To be deprecated
        userId: Number(currentUser.userId) || null,
        workLocationId: values.workLocationId || null,
      };

      // API call to submit
      setSubmitLoading(true);
      setSubmitStatus(null);
      dispatch(
        participantId
          ? updateUserParticipant(payload)
          : addUserParticipant(payload)
      ).then((e) => {
        setSubmitLoading(false);
        if (e.response) {
          if (
            e.type === "ADD_USERS_PARTICIPANT_SUCCESS" ||
            e.type === "UPDATE_USERS_PARTICIPANT_SUCCESS"
          ) {
            setSubmitStatus("success");
          } else {
            setSubmitStatus("failure");
          }
        } else {
          setSubmitStatus("failure");
        }
      });
    }
  };
  
  // On successful form submit, go to `people/users`
  useEffect(() => {
    if (submitStatus === "success") {
      history.replace("/people/users");
    } else if (submitStatus === "failure") {
      setErrorToast({
        error: "Failed to submit",
        showing: true,
      });
    }
  }, [history, submitStatus]);

  /*** 
   * Build field options
  ***/

  // Supervisor options
  const supervisorOptions = useMemo(() => {
    if (supervisorsQuery) {
      return mapSupervisorOptions(searchedSupervisors, currentUser);
    } 
    if (supervisors) {
      const result = mapSupervisorOptions(supervisors, currentUser);
      // Add default empty option
      result.unshift({
        id: null,
        label: "No supervisor",
      });
      return result;
    } 
    return [];
  }, [supervisors, searchedSupervisors]);

  // Work location options
  const workLocationOptions = useMemo(() => {
    if (workLocationsQuery) {
      return mapWorkLocations(searchedWorkLocations);
    } 
    if (workLocations) {
      const result = mapWorkLocations(workLocations);
      // Add default empty option
      result.unshift({
        id: null,
        label: "No work location",
      });
      return result;
    }
    return [];
  }, [workLocations, searchedWorkLocations]);

  // Client group options
  const clientGroupOptions = useMemo(() => {
    if (clientGroupsQuery) {
      return mapClientGroupsOptions(searchedClientGroups);
    }
    if (clientGroups) {
      return mapClientGroupsOptions(clientGroups);
    }
    return [];
  }, [clientGroups, searchedClientGroups]);

  // Breadcrumb paths
  const breadcrumbPaths = useMemo(
    () => buildBreadcrumbs(participantId, currentUser),
    [currentUser.firstName, participantId]
  );

  return (
    <PageContent>
      <div className="col-12">
        <Breadcrumbs paths={breadcrumbPaths} />
      </div>
      <div className="col-12 mt-1 mb-3">
        <Loader
          loading={currentUserIsLoading}
          error={currentUserError}
          spinnerProps={{ size: 20 }}
        >
          <Formik
            initialValues={initialValues}
            onSubmit={_handleSubmit}
            enableReinitialize
            validate={validate}
            validateOnChange={false}
            // validateOnBlur={false}
          >
            {(props) => {
              const { errors, handleSubmit, setFieldValue, touched, values } = props;
              return (
                <form onSubmit={handleSubmit}>
                  <div className="pl-0 pr-0" style={{ width: "398px" }}>
                    <Field
                      as={TextInput}
                      error={errors["firstName"]}
                      label="First Name"
                      name="firstName"
                      placeholder="First Name"
                      touched={touched["firstName"]}
                      value={values["firstName"]}
                      variant="outlined"
                    />
                    <Field
                      as={TextInput}
                      error={errors["lastName"]}
                      label="Last Name"
                      name="lastName"
                      placeholder="Last Name"
                      touched={touched["lastName"]}
                      value={values["lastName"]}
                      variant="outlined"
                    />
                    <Field
                      as={TextInput}
                      error={errors["nickname"]}
                      label="Nickname"
                      name="nickname"
                      placeholder="Nickname"
                      touched={touched["nickname"]}
                      value={values["nickname"]}
                      variant="outlined"
                    />
                    <Field
                      as={TextInput}
                      error={errors["email"]}
                      label="Email Address"
                      name="email"
                      placeholder="Email Address"
                      touched={touched["email"]}
                      value={values["email"]}
                      variant="outlined"
                    />

                    <Field
                      as={SearchSelect}
                      label="Work Location"
                      name="workLocationId"
                      handleChange={(value?: OptionContent) => {
                        setFieldValue("workLocationId", value?.id);
                      }}
                      handleUpdateSearch={setWorkLocationsQuery}
                      isApiSearch
                      onLoadMore={() => {
                        if (!isLastWorkLocationsPage && !workLocationsLoading && !workLocationsQuery) {
                          setWorkLocationPage((page) => page + 1);
                        }
                      }}  
                      isFinalPage={isLastWorkLocationsPage}
                      placeholder={"Work Location"}
                      options={workLocationOptions}
                      touched={touched["workLocationId"]}
                      value={workLocationOptions.find((e) => e.id === values["workLocationId"])}
                    />

                    <Field
                      as={SearchSelect}
                      error={errors["supervisorId"]}
                      label={supervisorTerm}
                      name="supervisorId"
                      handleChange={(value?: OptionContent) => {
                        setFieldValue("supervisorId", value?.id);
                      }}
                      handleUpdateSearch={setSupervisorsQuery}
                      isApiSearch
                      onLoadMore={() => {
                        if (!isLastSupervisorsPage && !supervisorsLoading && !supervisorsQuery) {
                          setSupervisorsPage((page) => page + 1);
                        }
                      }}
                      placeholder={supervisorTerm}
                      options={supervisorOptions}
                      touched={touched["supervisorId"]}
                      value={supervisorOptions.find((e) => e.id === values["supervisorId"])}
                    />

                    {
                      !participantId && 
                      <>
                        <Label content="User Type"/>
                        <Field
                          as={RadioButton}
                          checked={values.createUser}
                          name="createUser"
                          label="User (with system login)"
                          onChange={() => {
                            setFieldValue("createUser", true);
                          }}
                        />
                        <Field
                          as={RadioButton}
                          checked={!values.createUser}
                          name="createUser"
                          label="Crew Member Participant (no system login)"
                          onChange={() => {
                            setFieldValue("createUser", false);
                          }}
                        />
                      </>
                    }
                    
                    <Field
                      as={SearchSelect}
                      error={errors["primaryGroupId"]}
                      label="Primary Group"
                      name="primaryGroupId"
                      handleChange={(value?: OptionContent) => {
                        setFieldValue("primaryGroupId", value?.id);
                      }}
                      handleUpdateSearch={setClientGroupsQuery}
                      isApiSearch
                      onLoadMore={() => {
                        if (!isLastClientGroupsPage && !clientGroupsLoading && !clientGroupsQuery) {
                          setClientGroupsPage((page) => page + 1);
                        }
                      }}
                      placeholder={"Select group"}
                      options={clientGroupOptions}
                      touched={touched["primaryGroupId"]}
                      value={clientGroupOptions.find(
                        (e) => e.id === values["primaryGroupId"]
                      )}
                    />
                  </div>
                  
                  {/* This shows if you're creating a user only, doesn't show for participants -- GK */}
                  {values.createUser && 
                    <RolesAndGroups
                      authorities={authorities}
                      groups={clientGroups}
                      updateAuthorities={(role: string, groups: number[], method?: "replace") => {
                        setAuthorities(updateAuthorities(authorities, role, groups, method));
                      }}
                    />
                  }

                  <Toast
                    variant="error"
                    onClick={() => setErrorToast({
                      error: "",
                      showing: false,
                    })}
                    visible={errorToast.showing}
                  >
                    {errorToast.error}
                  </Toast>
                  <br />
                  <S.ButtonsWrapper>
                    <S.AddUserButton disabled={submitLoading}>
                      <S.LoaderWrapper>
                        <Loader
                          loading={submitLoading}
                          className="p-0 mr-3"
                          spinnerProps={{ size: 14 }}
                        />
                      </S.LoaderWrapper>
                      <span>{`${participantId ? "edit" : "add"}`} user </span>
                    </S.AddUserButton>
                    <S.CancelButton
                      disabled={submitLoading}
                      onClick={() => history.push("/people/users")}
                    >
                      cancel
                    </S.CancelButton>
                  </S.ButtonsWrapper>
                </form>
              );
            }}
          </Formik>
        </Loader>
      </div>
    </PageContent>
  );
};

export default withRouter(AddUser);
