import React, { useEffect, useState } from "react";
import { ThemeContext } from "styled-components";
import { css } from "aphrodite/no-important";
import { get } from "lodash";
import { useSelector } from "react-redux";

import { Select } from "components/common/form/Select";
import { Option } from "components/common/form/Select/Select";
import { TextArea } from "components/common/form/TextArea/futureUiKit/TextArea";
import { TextInput } from "components/common/form/TextInput/futureUiKit/TextInput";
import Checkbox from "components/common/form/Checkbox/Checkbox";
import CheckboxGroup from "components/common/form/CheckboxGroup";
import DisabledTextField from "components/common/form/DisabledTextField";
import DateTime from "components/common/form/DateTime";
import Label from "components/common/form/Label";
import MultiInput from "components/common/form/MultiInput";
import RadioButtonsGroup from "components/common/form/RadioButtons";
import textInputStyles from "components/common/form/TextInput/futureUiKit/styles";
import Location from "components/forms/Location";

import {
  FBDefensesWidget,
  FBItem,
  FBRatingsWidget,
  FBMapWidget,
  FBOEWidget,
} from "../../types";
import { MapWidget } from "./MapWidget";
import contentStyles from "./styles";
import DefensesWidget from "./widgets/DefensesWidget";
import OEWidget from "./widgets/OEWidget";
import RatingsWidget from "./widgets/RatingsWidget/RatingsWidget";
import { remCalc } from "../../../../../themes/helpers";
import { useThemedStyleSheetWithProps } from "../../../../../util/hooks/useThemedStyleSheet";
import { AppState } from "store";

interface MoreValuesNotificationProps {
  count: number;
  dsKey?: string | null;
}

const MoreValuesNotification = ({
  count,
  dsKey,
}: MoreValuesNotificationProps) => (
  <div className={css(contentStyles().moreValuesAvailable)}>
    +{count} more options from {dsKey ? `${dsKey} ` : ""}data set
  </div>
);

const stringOrUndefined = (value: unknown) =>
  typeof value === "string" ? value : undefined;

/**
 * Convert ItemSelections to Options for select (drop-down) items
 * @param selections
 */
function fmtSelectOptions(selections: ItemSelection[]): Option[] {
  return selections.slice(0, 10).map((selection) => ({
    value: selection.title,
    id: selection.id || selection.title,
    disabled: selection.disabled,
    optionPlaceholder: selection.optionPlaceholder,
  }));
}

/**
 * Convert ItemSelections to Options for checkbox items
 * @param selections
 */
function fmtCheckboxOptions(selections: ItemSelection[]) {
  return selections.slice(0, 10).map((selection) => ({
    value: String(selection.id),
    label: selection.title,
    disabled: false,
    checked: false,
  }));
}

/**
 * Convert ItemSelections to Options for radio buttons items
 * @param selections
 */
function fmtRadioOptions(selections: ItemSelection[]) {
  return selections.slice(0, 10).map((selection) => ({
    label: selection.title,
    value: String(selection.id),
  }));
}

/**
 * Checks that a string does not match the empty state of a rich text input
 * @param str
 */
function emptyContent(str: string) {
  const trimmedStr = str.trim();
  return trimmedStr.length === 0 || /^<p><\/p>$/.test(trimmedStr);
}

export interface ItemSelection {
  id: number | null;
  rootId: number | null;
  title: string;
  disabled?: boolean;
  optionPlaceholder?: boolean;
}

interface Props {
  item: FBItem;
  selections: Array<ItemSelection>;
  selected?: boolean;
  siblings: Array<FBItem>;
}

export function ItemField({ item, selections, selected, siblings }: Props) {
  const [additionalDSValuesCount, setAdditionalDSValuesCount] = useState<
    number
  >(0);

  const itemTitle = item.title || "Untitled";
  const theme = React.useContext(ThemeContext);

  const itemName = `item_${item.id}`;
  const itemPlaceholder = stringOrUndefined(
    item.properties?.["placeHolderText"]
  );
  const itemHelperText = stringOrUndefined(item.properties?.["assistiveText"]);
  const isRequired = get(item, ["formProperties", "isRequired"]);
  const dataSourceKey = item.answerSource?.dataSourceKey;
  const totalDSValueCount = useSelector(
    (store: AppState) =>
      dataSourceKey && store.dataSource.totalCount[dataSourceKey]
  );

  const cs = contentStyles();
  const ts = useThemedStyleSheetWithProps(textInputStyles, {});

  useEffect(() => {
    if (dataSourceKey && totalDSValueCount && totalDSValueCount > 10) {
      setAdditionalDSValuesCount(totalDSValueCount - 10);
    } else {
      setAdditionalDSValuesCount(0);
    }
  }, [totalDSValueCount, dataSourceKey]);

  if (item.type === "QUESTION") {
    switch (item.subType) {
      case "CHECKBOX":
        return <Checkbox label="Checkbox label" />;
      case "MULTI_SELECT":
        return (
          <>
            <Label htmlFor={item.id.toString()} required={isRequired}>
              {itemTitle}
            </Label>
            <p className={css(cs.note)}>
              Add multi-select choices in the properties panel
            </p>
            {item.properties?.assistiveText && (
              <span className={css(ts.helperText)}>
                {item.properties.assistiveText}
              </span>
            )}
            {selections.length > 0 && (
              <CheckboxGroup
                initialOptions={fmtCheckboxOptions(selections)}
                outlined
              />
            )}
            {!!additionalDSValuesCount && (
              <MoreValuesNotification
                count={additionalDSValuesCount}
                dsKey={dataSourceKey}
              />
            )}
          </>
        );
      case "DATE":
        return (
          <DateTime
            helperText={itemHelperText}
            label={itemTitle}
            name={itemName}
            required={isRequired}
            type="date"
          />
        );
      case "DATE_TIME":
        return (
          <DateTime
            helperText={itemHelperText}
            label={itemTitle}
            name={itemName}
            required={isRequired}
            type="datetime-local"
          />
        );
      case "DROP_DOWN":
        return (
          <>
            <Label htmlFor={item.id.toString()} required={isRequired}>
              {itemTitle}
            </Label>
            <p className={css(cs.note)}>
              Add dropdown choices in the properties panel
            </p>
            <Select
              helperText={itemHelperText}
              name={itemName}
              onChange={() => undefined}
              options={fmtSelectOptions(selections)}
              value={selections[0]?.id}
            >
              {!!additionalDSValuesCount && (
                <div className={css(cs.moreValuesOption)}>
                  <MoreValuesNotification
                    count={additionalDSValuesCount}
                    dsKey={dataSourceKey}
                  />
                </div>
              )}
            </Select>
          </>
        );
      case "LOCATION":
        return (
          <Location
            name={itemName}
            placeholder="Location"
            question={{
              id: item.id,
              rootId: item.rootId,
              formProperties: {
                isRequired: false,
                isSearchable: false,
              },
              type: item.type,
              subType: item.subType,
              title: itemTitle,
              workflowType: "DRAFT",
            }}
            setQuestionResponse={() => null}
          />
        );
      case "PARTICIPANT":
        return (
          <MultiInput
            helperText={itemHelperText}
            idField="id"
            label={itemTitle}
            labelField="title"
            name={itemName}
            onUpdateValues={() => null}
            placeholder={itemPlaceholder}
            required={isRequired}
            assistiveLink={{
              label: "Add Participant",
              onClick: () => null,
            }}
          />
        );
      case "RADIO_BUTTONS":
        return (
          <>
            <Label htmlFor={item.id.toString()} required={isRequired}>
              {itemTitle}
            </Label>
            <p className={css(cs.note)}>
              Add radio button choices in the properties panel
            </p>
            <RadioButtonsGroup
              helperText={itemHelperText}
              name={itemName}
              value="value"
              label=""
              onChange={() => null}
              options={fmtRadioOptions(selections)}
            />
            {!!additionalDSValuesCount && (
              <MoreValuesNotification
                count={additionalDSValuesCount}
                dsKey={dataSourceKey}
              />
            )}
          </>
        );
      case "TEXT_AREA":
        return (
          <TextArea
            helperText={itemHelperText}
            id={item.id.toString()}
            label={itemTitle}
            name={itemName}
            placeholder={itemPlaceholder}
            required={isRequired}
            rows={5}
          />
        );
      case "TEXT_LINE":
        return (
          <TextInput
            helperText={itemHelperText}
            label={itemTitle}
            name={itemName}
            placeholder={itemPlaceholder}
            required={isRequired}
          />
        );
      case "TIME":
        return (
          <DateTime
            helperText={itemHelperText}
            label={itemTitle}
            name={itemName}
            required={isRequired}
            type="time"
          />
        );
      case "YES_OR_NO":
        return (
          <RadioButtonsGroup
            helperText={itemHelperText}
            name={itemName}
            value="value"
            label={itemTitle}
            onChange={() => null}
            options={[
              { value: "Yes", label: "Yes" },
              { value: "No", label: "No" },
            ]}
            required={isRequired}
          />
        );
      default:
        return null;
    }
  }

  if (item.type === "WIDGET") {
    switch (item.subType) {
      case "DEFENSES":
        return <DefensesWidget item={item as FBDefensesWidget} />;
      case "OPERATIONAL_EXPERIENCES":
        return <OEWidget selected={selected} item={item as FBOEWidget} />;
      case "MAP":
        return (
          <MapWidget
            item={item as FBMapWidget}
            siblings={siblings}
            disableControls
          />
        );
      case "SAFETY_RATING":
        return <RatingsWidget item={item as FBRatingsWidget} />;
      case "SIGNATURE":
        return (
          <TextInput
            name={itemName}
            value=""
            placeholder={
              item.properties?.allowEmailSignature
                ? "Enter an email address"
                : 'Press "Add Signature" to sign'
            }
            helperText={`${
              item.properties?.allowEmailSignature
                ? "Enter email address or s"
                : "S"
            }elect “Add Signature” to digitally sign in the field`}
            label="[First Name] [“Nick”] [Last Name]"
            onChange={() => null}
            assistiveLink={{
              label: "Add Signature",
              onClick: () => null,
            }}
          />
        );
      case "DOCUMENT_CREATOR":
        return (
          <DisabledTextField
            label={itemTitle}
            value="[Document creator's name]"
          />
        );
      case "SUPERVISOR":
        return (
          <MultiInput
            helperText={itemHelperText}
            idField="id"
            label={itemTitle}
            labelField="title"
            name={itemName}
            onUpdateValues={() => null}
            placeholder={itemPlaceholder}
            required={isRequired}
            assistiveLink={{
              label: "Add Participant",
              onClick: () => null,
            }}
          />
        );
      default:
        return null;
    }
  }

  if (item.type === "CONTENT") {
    switch (item.subType) {
      case "BLOCK":
        return (
          <div
            style={{ whiteSpace: "pre-wrap" }}
            dangerouslySetInnerHTML={{
              __html: !emptyContent(item.content)
                ? item.content
                : "<p style='font-style: italic; margin-bottom: 0'>A read-only content block will go here</p>",
            }}
          />
        );
      case "LINE_BREAK":
        return <hr style={{ width: "100%" }} />;
      case "BANNER_MESSAGE":
        return (
          <div
            dangerouslySetInnerHTML={{
              __html: !emptyContent(item.content)
                ? item.content
                : "<p style='font-style: italic; margin-bottom: 0'>A banner message will go here</p>",
            }}
            style={{
              backgroundColor:
                "backgroundColor" in item ? item.backgroundColor : "none",
              color:
                "textColor" in item ? item.textColor : theme.masterColors.black,
              borderRadius: remCalc(4),
              border: `1px solid ${theme.colors.lightGrey}`,
              padding: !emptyContent(item.content)
                ? `${remCalc(16)} ${remCalc(16)} 0`
                : remCalc(16),
            }}
          />
        );
      default:
        return null;
    }
  }
  return null;
}
