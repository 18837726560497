import styled from "styled-components";

import { remCalc } from "themes/helpers";

export const DocumentContainer = styled.div`
  height: 100%;
  width: 100%;
  max-width: 64em; //1024px
  margin: 0 auto ${remCalc(150)} auto;
  padding-left: 0.5em; //8px
  padding-right: 0.5em; //8px
`;

export const MapContainer = styled.div`
  width: 100%;
  height: 18em;
  margin-bottom: 1.875em; //30px
`;

