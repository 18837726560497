import React from "react";
import withTheme from "@material-ui/core/styles/withTheme";
import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { useDispatch } from "react-redux";
import { useHistory, useRouteMatch } from "react-router";

import Breadcrumbs from "components/common/Breadcrumbs/Breadcrumbs";
import Loader from "components/common/Loader";
import ReadOnlyContent from "components/common/permissions/ReadOnlyContent";
import { ContentWrapper } from "components/common/Wrappers";
import { DataSource, GetDataSourceTemplateResponse } from "store/dataSource/types";
import { downloadFile } from "../../../util";
import { getDataSourceById, getDataSourceCSV } from "store/dataSource/actions";

import S from "./styles";
import { RouteParams } from "./types";

const nonEmptyRow = (row: string[]) => !(row.length === 1 && row[0] === "");

/** Table showing first ten records of an advanced data source */
const SummaryTable = ({ csvData }: { csvData: string[][] }) =>
  csvData.length > 0 ? (
    <S.TableWrapper>
      <div>
        {csvData.map((row, rowIdx) => nonEmptyRow(row) && (
          rowIdx === 0 ? (
            <S.RowDiv key={`summary_row_${rowIdx}`}>
              {row.map((data) => (
                <S.TH key={`row${rowIdx}data${data}`}>
                  <S.SmallTitle>{data.toUpperCase()}</S.SmallTitle>
                </S.TH>
              ))}
            </S.RowDiv>
          ) : (
            <S.DataRow key={`summary_row_${rowIdx}`}>
              {csvData[0].map((d, i) => (
                <>
                  <S.TD key={`row${rowIdx}data${i}`}>{row[i] || ""}</S.TD>
                </>
              ))}
            </S.DataRow>
          )))}
      </div>
    </S.TableWrapper>
  ) : null;

/** View an Advanced Data Set */
function ViewAdvancedDS() {
  const dispatch = useDispatch<ThunkDispatch<null, void, Action>>();
  const match = useRouteMatch<RouteParams>();
  const history = useHistory();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [dataSource, setDataSource] = React.useState<DataSource | null>(null);
  const [fileSize, setFileSize] = React.useState<number>(0);
  const [dataSourceValues, setDataSourceValues] = React.useState<string[][]>([]);
  const [numRecords, setNumRecords] = React.useState<number>(0);

  const downloadCSV = (id: number, title: string) =>
    dispatch(getDataSourceCSV(id.toString()))
      .then((res: GetDataSourceTemplateResponse) => {
        if (res.response) {
          downloadFile(res.response, title + ".csv");
        }
      })
      .catch((err) => {
        throw new Error(err);
      });

  /** Get data source and CSV if an ID was supplied in params */
  React.useEffect(() => {
    (async () => {
      if (match.params.id) {
        setLoading(true);
        const ds = await dispatch(getDataSourceById(match.params.id));
        if (ds.response) {
          setDataSource(ds.response);
          const dsv = await dispatch(getDataSourceCSV(ds.response.id));
          if (dsv.response) {
            setFileSize(dsv.response.size);
            const dsvtxt = await dsv.response.text();
            const dsvrows = dsvtxt.split("\n");
            setNumRecords(dsvrows.length - 1);
            setDataSourceValues(dsvrows.splice(0, 11).map((row) => row.split(",")));
          }
        }
        setLoading(false);
      }
    })();
  }, []);

  return (
    <ContentWrapper>
      <Breadcrumbs
        paths={[
          { pathName: "Forms" },
          { pathName: "Data Sets", href: "/forms/data-sets" },
          { pathName: `Advanced Data Set${dataSource ? `: ${dataSource.title}` : ""}` }
        ]}
      />
      <Loader loading={loading}>
        {dataSource && (
          <>
            <S.Metric>
              <S.SmallTitle>DATA SET TITLE</S.SmallTitle>
              <S.Title>{dataSource.title}</S.Title>
            </S.Metric>
            <S.RowDiv>
              <S.MetricColumn>
                <S.Metric>
                  <S.SmallTitle>ID</S.SmallTitle>
                  <S.DataItem>{dataSource.id}</S.DataItem>
                </S.Metric>
                <S.Metric>
                  <S.SmallTitle>SOURCE KEY</S.SmallTitle>
                  <S.DataItem>{dataSource.dataSourceKey}</S.DataItem>
                </S.Metric>
              </S.MetricColumn>
              <S.MetricColumn>
                <S.Metric>
                  <S.SmallTitle>COLUMN HEADERS</S.SmallTitle>
                  {dataSource.csvMappings.map((m) => (
                    <S.DataItem key={`column_${m.columnName}`}>{m.columnName}</S.DataItem>
                  ))}
                </S.Metric>
              </S.MetricColumn>
              <S.MetricColumn>
                <S.Metric>
                  <S.SmallTitle>GROUPS</S.SmallTitle>
                  {/* TODO where do groups come from? -JA */}
                </S.Metric>
              </S.MetricColumn>
              <S.MetricColumn>
                <S.Metric>
                  <S.SmallTitle># OF RECORDS</S.SmallTitle>
                  <S.DataItem>{numRecords}</S.DataItem>
                </S.Metric>
                <S.Metric>
                  <S.SmallTitle>FILE SIZE</S.SmallTitle>
                  <S.DataItem>{fileSize}B</S.DataItem>
                </S.Metric>
              </S.MetricColumn>
            </S.RowDiv>
            {/* TODO tabs */}
            <S.PreviewHeader>Data set preview: {dataSourceValues.length - 1} of {numRecords} records</S.PreviewHeader>
            <S.Subtitle>
              The table below is a preview of the first 10 records in your data set. To view the entire spreadsheet,
              download the current data set. You can make changes to the table by selecting to import a new data set.
            </S.Subtitle>
            <S.TableButtons>
              <S.DownloadButton
                width={270}
                height={2.25}
                isSubmitting={false}
                onClick={() =>
                  dataSource?.id && downloadCSV(dataSource.id, dataSource.title.split(" ").join("_") + ".csv")}
              >
                DOWNLOAD CURRENT DATA SET
              </S.DownloadButton>
              <ReadOnlyContent>
                <S.ImportButton
                  onClick={() => dataSource?.id && history.push(`/forms/data-sets/advanced/${dataSource.id}`)}
                >
                IMPORT NEW DATA SET
                </S.ImportButton>
              </ReadOnlyContent>
            </S.TableButtons>
            <SummaryTable csvData={dataSourceValues} />
            <S.TableButtons>
              <S.DownloadButton
                width={270}
                height={2.25}
                isSubmitting={false}
                onClick={() => dataSource?.id && downloadCSV(dataSource.id, dataSource.title)}
              >
                DOWNLOAD CURRENT DATA SET
              </S.DownloadButton>
              <ReadOnlyContent>
                <S.ImportButton
                  onClick={() => dataSource?.id && history.push(`/forms/data-sets/advanced/${dataSource.id}`)}
                >
                IMPORT NEW DATA SET
                </S.ImportButton>
              </ReadOnlyContent>
            </S.TableButtons>
          </>
        )}
      </Loader>
    </ContentWrapper>
  );
}

export default withTheme(ViewAdvancedDS);
