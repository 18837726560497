import React from "react";
import styled from "styled-components";
import { useHistory } from "react-router";

import { ContentWrapper } from "components/common/Wrappers";

const ErrorWrapper = styled.div`
  border-left: 3px solid ${({ theme }) => theme.colors.error};
  margin-top: 30px;
  padding: 15px;
  text-align: left;
`;

const Link = styled.a`
  color: ${({ theme }) => theme.colors.primary} !important;
  cursor: pointer;
  text-decoration: none;
`;

export const RouteNotFound = () => {
  const history = useHistory();
  return <ContentWrapper>
    <ErrorWrapper>
      You are trying to view a page that does not exist. Click
      <Link onClick={() => history.push("/")}>&nbsp;here&nbsp;</Link>to return to Dashboard.
    </ErrorWrapper>
  </ContentWrapper>;
};
