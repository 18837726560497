import { Pagination, APIResponse } from "store/common/types";
import { WorkLocationContent } from "store/workLocation/types";

export interface WorkLocationsState {
  data: WorkLocationsData;
  error: boolean;
  errorMessage: string;
  loading: boolean;
}

export interface WorkLocationsData extends Pagination {
  content?: WorkLocationContent[];
}

export const GET_WORK_LOCATIONS = {
  FAILURE: "GET_WORK_LOCATIONS_FAILURE",
  REQUEST: "GET_WORK_LOCATIONS_REQUEST",
  SUCCESS: "GET_WORK_LOCATIONS_SUCCESS",
};

export interface WorkLocationsResponse extends APIResponse {
  response?: WorkLocationsData;
}

export interface WorkLocationsActions {
  payload?: any;
  response?: any;
  type: typeof GET_WORK_LOCATIONS.REQUEST | typeof GET_WORK_LOCATIONS.SUCCESS | typeof GET_WORK_LOCATIONS.FAILURE;
}
