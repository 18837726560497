import React from "react";

import Button, { ButtonProps } from "./Button";

interface SubmitProps extends ButtonProps {
  isSubmitting: boolean;
  type?: "submit" | "reset" | "button";
}

const SubmitButton = ({
  children,
  variant,
  className,
  onClick,
  height,
  status,
  fullWidth,
  isSubmitting,
  disabled,
  width,
  type = "submit"
}: SubmitProps) => {
  return (
    <Button
      variant={variant}
      className={className}
      onClick={onClick}
      type={type}
      status={status}
      fullWidth={fullWidth}
      height={height}
      loading={isSubmitting}
      disabled={disabled}
      width={width}
    >
      {children}
    </Button>
  );
};

export default SubmitButton;
