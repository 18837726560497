import React, { memo, useContext } from "react";
import { css } from "aphrodite/no-important";
import { ThemeContext } from "styled-components";

import CollapsibleDiv from "components/common/CollapsibleDiv";
import Icon from "components/common/Icon";

import { ActionButton } from "../../../../common/ActionButton";
import { ActionMenu, ActionMenuItem } from "../../../../common/ActionMenu";
import { FBItem, FBSection } from "../../types";
import { ItemsMutator, SectionsMutator } from "../Create";
import Item from "./Item";
import Modal from "../../../../common/Modal/futureUiKit/Modal";
import styles from "./styles";
import useValidation from "../validation/useValidation";
import ValidationStatus from "../validation/ValidationStatus";

interface Props {
  currentItem: FBSection | FBItem | null;
  incrementItemIndex: (item: FBSection | FBItem, position: number, arrLen: number) => void;
  decrementItemIndex: (item: FBSection | FBItem, position: number) => void;
  position: number;
  removeItem: ItemsMutator;
  removeSection: SectionsMutator;
  section: FBSection;
  setCurrentItem: (item: FBItem | FBSection) => void;
  siblings: Array<FBSection>;
}

function Section({
  currentItem,
  incrementItemIndex,
  decrementItemIndex,
  position,
  removeItem,
  removeSection,
  section,
  setCurrentItem,
  siblings,
}: Props) {
  const theme = useContext(ThemeContext);

  const [collapsed, setCollapsed] = React.useState<boolean>(false);
  const [modalOpen, setModalOpen] = React.useState<boolean>(false);
  const [apiStatus, setApiStatus] = React.useState<"initial" | "loading" | "error">("initial");

  const { getErrorsByPath, alertLevel } = useValidation();

  const selected = currentItem?.rootId === section.rootId;
  const hasChildren = section.items.length > 0;
  const firstItem = position === 0;
  const lastItem = position === siblings.length - 1;

  const sectionPath = `sections[${position}]`;
  const sectionErrors = getErrorsByPath(sectionPath);

  const s = styles();

  async function handleDeleteSection() {
    setApiStatus("loading");
    const success = await removeSection(section);
    if (!success) {
      setApiStatus("error");
    } else {
      setApiStatus("initial");
    }
  }

  function handleCloseModal() {
    setModalOpen(false);
    setApiStatus("initial");
  }

  if (section.parentWidgetRootId) {
    // hide sections that are related to widgets
    return null;
  }

  const error = apiStatus === "error";
  const alertCount = sectionErrors?.length;

  return (
    <>
      <Modal
        action={{
          text: error ? "okay" : "yes, delete",
          callback: error ? handleCloseModal : handleDeleteSection,
          loading: apiStatus === "loading"
        }}
        alert={{
          variant: error ? "error" : "warning",
          title: error
            ? "An error occurred"
            : "Are you sure you want to delete this section and all its questions?",
          message: error
            ? "The section could not be deleted. If the issue persists, please contact your system administratore"
            : "This action cannot be undone.",
          isVisible: true
        }}
        handleClose={handleCloseModal}
        open={modalOpen}
        title="Are you sure?"
        cancellable={apiStatus === "initial"}
      />
      <div
        id={section.id.toString()}
        className={css([
          s.section,
          selected && s.selected,
          alertCount && s[`${alertLevel}Item`],
          alertCount && selected && s[`${alertLevel}ItemSelected`]
        ])}
        onClick={(event) => {
          event.stopPropagation();
          setCurrentItem(section);
        }}
      >
        <div className={css(s.sectionContent)}>
          <div className={css([s.row, s.sectionTitleContainer, firstItem && lastItem ? s.padding : ""])}>
            <div className={css(s.row)}>
              <Icon type="drag_reorder" color={theme.masterColors.darkGrey} size="20px" />
              <span className={css(s.sectionTitle)}>{`Section ${position + 1}: ${section.title || "Untitled"}`}</span>
            </div>
            <div className={css(s.row)}>
              {!!alertCount && (
                <ValidationStatus
                  alertLevel={alertLevel}
                  errorCount={alertCount}
                />
              )}
              {!firstItem && (
                <ActionButton
                  onClick={() => decrementItemIndex(section, position)}
                  label="move section up"
                  IconComponent={<i className="icon-icons8-sort_up" style={{ height: 20, width: 20 }} />}
                />
              )}
              {!lastItem && (
                <ActionButton
                  onClick={() => incrementItemIndex(section, position, siblings.length)}
                  label="move section down"
                  IconComponent={<i className="icon-icons8-sort_down" style={{ height: 20, width: 20 }} />}
                />
              )}
              <ActionMenu closeOnClick>
                <ActionMenuItem
                  onClick={() => setCollapsed((currState) => !currState)}
                  label={`${collapsed ? "Expand" : "Collapse"} section`}
                />
                <ActionMenuItem
                  danger
                  onClick={() => setModalOpen(true)}
                  label="Delete section"
                />
              </ActionMenu>
            </div>
          </div>
          {hasChildren ? (
            <CollapsibleDiv collapsed={collapsed}>
              {section.items.map((item, i) => (
                <Item
                  itemPath={`${sectionPath}.items[${i}]`}
                  key={`item_${item.position}_${item.id}`}
                  incrementItemIndex={incrementItemIndex}
                  decrementItemIndex={decrementItemIndex}
                  item={item}
                  position={i}
                  removeItem={removeItem}
                  setCurrentItem={setCurrentItem}
                  selected={currentItem?.rootId === item.rootId}
                  siblings={section.items}
                />
              ))}
            </CollapsibleDiv>
          ) : (
            <span className={css(s.emptySectionMessage)}>
            You have no elements, fields or widgets in this section
            </span>
          )}
        </div>
      </div>
    </>
  );
}

export default memo(Section);
