import React from "react";
import { History } from "history";

import Modal from "../../common/Modal";
import { SubmitButton } from "../../forms/Button";

import S from "./styles";
import { DataSourceVM } from "../../../store/dataSource/types";

interface Props {
  open: boolean;
  closeModal: () => void;
}

interface CreateProps extends Props {
  typeSelected: "basic" | "advanced" | null;
  setTypeSelected: (ts: "basic" | "advanced" | null) => void;
  history: History;
}

export const CreateModal = ({ open, closeModal, typeSelected, setTypeSelected, history }: CreateProps) => (
  <Modal
    border="none"
    height="350px"
    width="446px"
    padding="24px 14px"
    open={open}
    handleClose={closeModal}
    content={
      <div>
        <S.RowDiv>
          <S.ColumnDiv>
            <S.ModalHeader>Select the Type of Data Set</S.ModalHeader>
            <S.ModalText>
                Choose the type of data set you want to create. A simple data set is a basic text list. An advanced
                set has items with multiple properties and can be imported from a .csv.
            </S.ModalText>
          </S.ColumnDiv>
          <S.PointerDiv onClick={() => closeModal()}>
            <S.DarkIcon size="17px" type="delete_sign" />
          </S.PointerDiv>
        </S.RowDiv>
        <S.ToggleContainer>
          <S.ToggleButton selected={typeSelected === "basic"} onClick={() => setTypeSelected("basic")}>
            <S.ToggleContent>
              <S.DarkIcon size="50px" type="align_justify" />
              <span>Basic</span>
            </S.ToggleContent>
          </S.ToggleButton>
          <S.ToggleButton selected={typeSelected === "advanced"} onClick={() => setTypeSelected("advanced")}>
            <S.ToggleContent>
              <S.DarkIcon size="50px" type="table" />
              <span>Advanced</span>
            </S.ToggleContent>
          </S.ToggleButton>
        </S.ToggleContainer>
        <S.ButtonContainer>
          <S.CancelButton onClick={closeModal} variant="cancel">
              CANCEL
          </S.CancelButton>
          <SubmitButton
            onClick={() => history.push(`/forms/data-sets/${typeSelected || "basic"}`)}
            height={2.25}
            width={166}
            isSubmitting={false}
          >
              CREATE DATA SET
          </SubmitButton>
        </S.ButtonContainer>
      </div>
    }
  />
);

interface AffirmationProps extends Props {
  updateSourceStatus: (ds: DataSourceVM, status: "DISABLED" | "PUBLISHED") => void;
  sourceSelected: DataSourceVM | null;
}

export const ConfirmModal = ({ open, closeModal, updateSourceStatus, sourceSelected }: AffirmationProps) => (
  <Modal
    border="none"
    height="170px"
    width="446px"
    padding="24px 14px"
    open={open}
    handleClose={closeModal}
    content={
      <S.ColumnDiv>
        <S.RowDiv>
          <S.ModalHeader>
            Are you sure you want to&nbsp;
            {sourceSelected && sourceSelected.status === "DISABLED" ? "enable" : "disable"}&nbsp;
            this Data Set?&nbsp;
          </S.ModalHeader>
          <S.PointerDiv onClick={() => closeModal()}>
            <S.DarkIcon size="17px" type="delete_sign" />
          </S.PointerDiv>
        </S.RowDiv>
        <S.ButtonContainer>
          <S.CancelButton onClick={closeModal} variant="cancel">
            CANCEL
          </S.CancelButton>
          <SubmitButton
            onClick={() => {
              sourceSelected && updateSourceStatus(
                sourceSelected,
                sourceSelected.status === "DISABLED" ? "PUBLISHED" : "DISABLED"
              );
            }}
            height={2.25}
            width={166}
            isSubmitting={false}
          >
            {sourceSelected && sourceSelected.status === "DISABLED" ? "ENABLE" : "DISABLE"} DATA SET
          </SubmitButton>
        </S.ButtonContainer>
      </S.ColumnDiv>
    }
  />
);
