import React from "react";
import styled from "styled-components";

import FormControlBase from "@material-ui/core/FormControl";
import InputLabelBase from "@material-ui/core/InputLabel";
import TextFieldBase from "@material-ui/core/TextField";

const FormControl = styled(({ ...otherProps }) => (
  <FormControlBase {...otherProps} />
))``;

const InputLabel = styled(({ ...otherProps }) => (
  <InputLabelBase
    {...otherProps}
    classes={{
      root: "root",
    }}
  />
))`
  margin-bottom: 15px;

  &&.root {
    color: ${({ theme }) => theme.colors.formFieldLabel};
    font-size: 20px;
  }
`;

const DateInputField = styled(({ ...otherProps }) => (
  <TextFieldBase
    {...otherProps}
    InputProps={{
      classes: {
        notchedOutline: "notchedOutline",
        input: "input",
        focused: "focused",
        error: "error",
      },
    }}
  />
))`
  && .notchedOutline {
    border-color: ${({ theme }) => theme.masterColors.darkGrey};
  }

  && .input {
    color: ${({ theme }) => theme.colors.formFieldColor};
    position: relative;
  }

  && .focused .notchedOutline {
    border-color: ${({ theme }) => theme.colors.primary};
  }

  && .error .notchedOutline {
    border-color: ${({ theme }) => theme.colors.error};
    border-width: 2px;
  }

  && .error .input {
    color: ${({ theme }) => theme.colors.error};
  }
  && input {
    position: absolute;
    z-index: 5;
    &::-webkit-calendar-picker-indicator {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      right: 0;
      color: transparent;
      background: transparent;
      z-index: 4;
      padding: 0;
    }
  }
`;

const DateInput = styled.div`
  background-color: ${({ theme }) => theme.colors.fieldInputBackground};
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const ErrorLabel = styled(({ ...otherProps }) => (
  <InputLabelBase {...otherProps} />
))``;

export { DateInput, InputLabel, FormControl, DateInputField, ErrorLabel };
