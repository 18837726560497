import styled from "styled-components";
import { tint } from "polished";

import { toRgba } from "themes/helpers";

export const MultiSelectStatisticCell = styled.div`
  background: ${(props) => tint(0.9, props.theme.colors.primary)};
  width: 97%;
  margin: 3px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 4px;
  border: solid 1px ${({ theme }) => toRgba(theme.masterColors.black, 0.12)};
`;

export const PercentBar = styled.div<{ percent: number }>`
  background: ${(props) => tint(0.8, props.theme.colors.primary)};
  width: ${(props) => props.percent}%;
  padding: 1rem;
`;

export const PercentChartRow = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  position: relative;
`;

export const PercentChartNumber = styled.div`
  margin-right: 1rem;
  font-size: 12px;
  letter-spacing: 1.88px;
  font-weight: 500;
  color: ${(props) => props.theme.masterColors.darkGrey};
  width: 3%;
`;

export const Count = styled.div`
  margin-right: 1.2rem;
  font-size: 12px;
  letter-spacing: 1.88px;
  font-weight: 500;
  color: ${(props) => props.theme.masterColors.darkGrey};
  position: absolute;
  right: 0;
`;

export const Label = styled(PercentChartNumber)`
  position: absolute;
  left: 3rem;
  font-size: 0.875rem;
  width: 530px;

  @media (max-width: 1230px) {
    width: 450px;
  }

  @media (max-width: 1125px) {
    width: 390px;
  }

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
