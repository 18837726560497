import * as React from "react";
import styled from "styled-components";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCellBase, {
  TableCellProps as TableCellPropsBase,
} from "@material-ui/core/TableCell";
import TableHeadBase from "@material-ui/core/TableHead";
import TableRowBase from "@material-ui/core/TableRow";
import { SortDirection } from "store/common/types";

const TableHead = styled(TableHeadBase)`
  th {
    color: ${({ theme }) => theme.masterColors.darkGrey};
    font-size: 0.75rem;
    font-weight: 400;
    letter-spacing: 1.88px;
    text-transform: uppercase;
  }
  &&.reports {
    th {
      font-size: 12px;
      color: ${({ theme }) => theme.masterColors.darkGrey};
    }
  }
  &&.reports {
    th {
      font-size: 12px;
      color: ${({ theme }) => theme.masterColors.darkGrey};
    }
  }
`;

const TableCellContent = styled.span<{
  sortable?: boolean;
  active?: boolean;
}>`
  color: ${({ active, theme }) =>
    active ? theme.colors.black : theme.masterColors.darkGrey};
  ${({ active }) => (active ? "font-weight: 700;" : "")}
  cursor: ${({ sortable }) => sortable && "pointer"};
  
  &:hover {
    ${({ sortable, theme }) =>
      sortable ? `color: ${theme.colors.black};` : ""}
  }
`;

const SortCaret = styled.i.attrs({
  className: "icon-icons8-sort_down",
})<{
  sortDirection?: SortDirection;
}>`
  display: inline-block;
  transform: ${({ sortDirection }) =>
    sortDirection === "asc" ? "rotate(180deg)" : ""};
`;

export interface TableCellProps extends Omit<TableCellPropsBase, "onClick"> {
  active?: boolean;
  minWidth?: string;
  maxWidth?: string;
  onClick?: (sortDirection: SortDirection) => void;
  sortDirection?: SortDirection;
  width?: number | string;
}

const TableCell = styled<React.FC<TableCellProps>>(
  ({
    active,
    children,
    minWidth,
    maxWidth,
    onClick,
    sortDirection,
    width,
    ...otherProps
  }) => {
    const handleSortClick = () => {
      // if already sorting desc on this column, toggle to asc.
      if (onClick) {
        if (sortDirection === "desc") {
          onClick("asc");
        } else {
          onClick("desc");
        }
      }
    };
    const sortable = !!sortDirection;
    return (
      <TableCellBase {...otherProps} classes={{ root: "root" }}>
        <TableCellContent
          active={active}
          sortable={sortable}
          onClick={(sortable && handleSortClick) || undefined}
        >
          {children} {sortable && <SortCaret sortDirection={sortDirection} />}
        </TableCellContent>
      </TableCellBase>
    );
  }
)`
  &&.root {
    min-width: ${({ minWidth }) => minWidth};
    max-width: ${({ maxWidth }) => maxWidth};
    padding: 21px 12px;
    text-align: ${({ align }) => align};
    width: ${({ width }) => (typeof width === "number" ? `${width}px` : width)};
  }
`;

export type TableSortDirections = "desc" | "asc" | undefined;

const TableRow = styled(({ ...otherProps }) => (
  <TableRowBase {...otherProps} classes={{ root: "root" }} />
))`
  & .root {
    border-bottom: ${({ noBottomBorder }) => noBottomBorder && 0};
  }
`;

export { Table, TableBody, TableCell, TableHead, TableRow };
