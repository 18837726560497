import React from "react";
import { CSSProperties } from "react";
import { StyleSheet } from "aphrodite";
import { ThemeContext } from "styled-components";
import { remCalc } from "themes/helpers";

export default function(): { [key: string]: CSSProperties } {
  const theme = React.useContext(ThemeContext);

  return StyleSheet.create({
    scaleContainer: {
      fontSize: remCalc(14),
      background: theme.masterColors.lightWhite,
      padding: "1rem",
      display: "flex"
    },
    scaleBody: {
      ":nth-child(1n) p": {
        marginBottom: "0.1rem"
      }
    },
    radioSection: {
      display: "flex",
    },
    radioGroup: {
      flexGrow: 1,
      marginLeft: "1rem",
    },
    radioOption: { // @TODO checked styles
      backgroundColor: theme.colors.formFieldBackground,
      borderColor: theme.masterColors.mediumGrey,
      borderStyle: "solid",
      borderWidth: "1px 1px 1px 0",
      display: "flex",
      flexDirection: "column",
      minHeight: remCalc(85),
      marginLeft: 0,
      marginTop: 0,
      padding: "0.25rem",
      textAlign: "center",
      width: "25%",
      ":first-of-type": {
        borderLeftWidth: "1px",
        borderRadius: "1rem 0px 0px 1rem",
      },
      ":last-of-type": {
        borderRadius: "0 1rem 1rem 0",
      }
    },
    radioOptionLabel: {
      color: theme.colors.darkGrey,
      whiteSpace: "pre-wrap",
    },
    small: {
      fontSize: remCalc(12),
    },
    bold: {
      fontWeight: 700,
    }
  });
}
