import * as React from "react";
import styled from "styled-components";
import { useHistory } from "react-router";

import NavigationList, { NavigationMenuLink } from "./NavigationList";

export interface SidebarNavigationProps {
  children?: React.ReactNode;
  hidePrivacyLinks?: boolean;
  menuData?: NavigationMenuLink[];
}

export interface PrivacyTermsProps {
  disabled?: boolean;
}

const Wrapper = styled.div.attrs({})`
  width: 251px;
  padding: 0 0 0 15px;
  height: 100%;
  font-weight: 500 !important;
  flex-shrink: 0;
`;

const PrivacyTermsWrapper = styled.div.attrs({})`
  display: flex;
  align-items: center;
  position: sticky;
  bottom: 30px;
  margin-bottom: 20px;
`;

export const PrivacyTerms = styled.div.attrs({
  className: "mr-2",
})<PrivacyTermsProps>`
  font-size: 0.75rem;
  color: ${(props) => props.theme.masterColors.mediumGrey};
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
`;

const Separator = styled.span`
  margin-right: 0.5rem;
  color: ${(props) => props.theme.masterColors.darkGrey};
`;

const SidebarNavigation = ({
  hidePrivacyLinks,
  menuData,
  children,
}: SidebarNavigationProps) => {
  const history = useHistory();

  return (
    <Wrapper className="sidebar-navigation d-flex flex-column justify-content-between pt-2">
      <div>
        {children || (menuData && <NavigationList menuData={menuData} />)}
      </div>
      {!hidePrivacyLinks && (
        <div>
          <PrivacyTermsWrapper>
            <Separator>&middot;</Separator>
            <PrivacyTerms onClick={() => history.push("/privacy")}>
              Privacy
            </PrivacyTerms>
            <Separator>&middot;</Separator>
          </PrivacyTermsWrapper>
        </div>
      )}
    </Wrapper>
  );
};

export default SidebarNavigation;
