import moment from "moment";
import {
  DisplayConditionDTO,
  DocumentQuestionResponseVm,
} from "../../store/documents/types";
import { SectionDTO } from "../../store/forms/types";
import { Option } from "components/common/form/Select/Select";
import { QuestionSuggestion } from "components/FormController/types";

export const oldDisplayStatus = (submissionType: string): string => {
  // This uses `document.submissionType` as source and is deprecated
  switch (submissionType) {
    case "ADD_PARTICIPANT":
    case "SUBMIT":
      return "Submitted";
    case "SAVE_DRAFT":
    case "AUTO_SYNC":
      return "In Progress";
    case "NEW":
      return "New";
    default:
      return "-";
  }
};

// Replaces `displayStatus`, changed the name to try be more descriptive
export const prettifyDocumentStatus = (status) => {
  // Source is `document.statuses`
  switch (status) {
    case "DELETED":
      return "Deleted";
    case "SUBMITTED":
    case "SUBMIT":
    case "ADD_PARTICIPANT":
      return "Submitted";
    case "IN_PROGRESS":
    case "SAVE_DRAFT":
    case "AUTO_SYNC":
      return "In Progress";
    case "NEW":
      return "New";
    default:
      return "N/A"; // BE's default case is `NA` -- GK
  }
};

export const prettifyDocumentHistoryStatus = (
  statuses: string,
  documentId: number,
  historyDocumentId: number | null
) => {
  /**
   *
   * @param statuses          - historyType in history
   * @param documentId        - id of the original document
   * @param historyDocumentId - documentId in history
   */

  const isOriginal = documentId !== historyDocumentId;
  const isCreated = documentId === historyDocumentId;

  enum DocumentStatuses {
    created = "Created",
    original = "Original",
    rehuddled = "Rehuddled",
  }

  if (statuses) {
    switch (statuses) {
      case "CREATED":
        if (isOriginal) return DocumentStatuses.original;
        return DocumentStatuses.created;
      case "SUBMITTED":
        if (isOriginal) return DocumentStatuses.original;
        return "Submitted";
      case "REHUDDLE":
        if (isCreated) return DocumentStatuses.created;
        return DocumentStatuses.rehuddled;
      case "DELETE":
        if (isOriginal) return DocumentStatuses.original;
        return "Deleted";
      case "SAVED_AS_DRAFT":
        if (isOriginal) return DocumentStatuses.original;
        return "Saved as Draft";
      default:
        return "-";
    }
  } else {
    return "-";
  }
};

export const displayWhen = (
  submissionDate?: string,
  format?: string
): string => {
  if (!submissionDate) {
    return "";
  }

  if (isToday(submissionDate)) {
    return moment(submissionDate).format("HH:mm");
  }

  return renderHoursPassedSince(submissionDate);
};

export const isToday = (date: string): boolean => {
  const TODAY = moment()
    .clone()
    .startOf("day");
  return moment(date).isSame(TODAY, "d");
};

export const returnLoadingStatus = (
  value: boolean,
  method: string,
  type: string,
  state: any
) => {
  return {
    ...state.loading,
    [type]: {
      ...state.loading[type],
      [method]: value,
    },
  };
};

export const dateToString = (submissionDate: string): string | undefined => {
  if (submissionDate) {
    const now = moment();
    return moment(submissionDate).calendar(now, {
      sameDay: "[Today]",
      lastDay: "[Yesterday]",
      lastWeek: () => {
        const diff = now.diff(submissionDate, "days");
        if (diff === 1) {
          return "[2 days ago]";
        }
        return `${diff} [days ago]`;
      },
      sameElse: () => {
        const diff = now.diff(submissionDate, "weeks");
        if (diff < 4) {
          return `${diff === 0 ? 1 : diff} [${
            diff === 1 || diff === 0 ? "week" : "weeks"
          } ago]`;
        } else {
          return "[28+ days ago]";
        }
      },
    });
  }
};

export const pluralize = (
  word: string,
  count: number,
  includeNumberInResponse = true
) => {
  const pluralized = count === 1 ? word : `${word}s`;

  return includeNumberInResponse && count
    ? `${count.toLocaleString()} ${pluralized}`
    : pluralized;
};

export const stringHash = function(str) {
  let hash = 0;
  if (str.length === 0) {
    return hash;
  }
  for (let i = 0; i < str.length; i++) {
    const char = str.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash = hash & hash; // Convert to 32bit integer
  }
  return hash;
};

/**
 * **renderHoursPassedSince** will take the given
 * arg (date) and return the amount of hours which
 * have passed since that date
 *
 * The returned format should be:
 * - "2 hours ago"
 * - "a day ago"
 *
 * @returns string
 */
export const renderHoursPassedSince = (givenDate: string): string => {
  if (isNaN(Date.parse(givenDate)) || !moment(givenDate).isValid()) {
    return "";
  }

  return moment(givenDate).fromNow();
};

// Makes work order ID prettier
export const prettifyWorkorderID = (id) => {
  const workOrderId = id || "-";
  const workOrderIdLabel =
    typeof workOrderId === "string" ? workOrderId : `#${workOrderId}`;
  return workOrderIdLabel;
};

// test whether any of the responses satisfy the display condition
const conditionMatches = (
  condition: DisplayConditionDTO,
  allResponses: Array<DocumentQuestionResponseVm>
): boolean => {
  if (
    condition.booleanCondition === undefined &&
    condition.sourceConditionRootId === undefined
  ) {
    return false;
  }

  const responses = allResponses.filter(
    (r) => r.questionRootId === condition.sourceQuestionRootId
  );
  if (responses.length === 0) {
    return false;
  }

  if (
    condition.sourceConditionRootId !== undefined &&
    condition.sourceConditionRootId !== null
  ) {
    return responses.some(
      (r) => r.associatedRootId === condition.sourceConditionRootId
    );
  }
  if (condition.booleanCondition !== undefined) {
    return responses.some(
      // @TODO why doesn't the above check work here?
      (r) =>
        condition.booleanCondition !== undefined &&
        r.answer === condition.booleanCondition.toString()
    );
  }
  console.warn("Something is wrong with display condition");
  return false;
};

// generic test if target is visible for display conditions
export const isTargetVisible = (
  targetRootId: number,
  targetType: "QUESTION" | "SECTION" | "WIDGET" | "CONTENT" | "ANSWER",
  responses: Array<DocumentQuestionResponseVm>,
  displayConditions?: Array<DisplayConditionDTO>
) => {
  if (!displayConditions) {
    return true;
  }

  // find the conditions for the question visibility
  const conditions = displayConditions.filter(
    (c) =>
      c.targetType === targetType &&
      c.targetRootId === targetRootId &&
      (c.action === "SHOW" || c.action === "HIDE")
  );

  if (conditions.length === 0) {
    return true;
  }

  // find the common action for the conditions
  const actions = new Set(conditions.map((c) => c.action));
  if (actions.size > 1) {
    console.warn(
      `Display conditions for target ${targetRootId} have mismatched actions - choosing first`
    );
  }
  const action = actions.values().next().value;

  // test the conditions
  const matches = conditions.some((c) => conditionMatches(c, responses));

  switch (action) {
    case "SHOW":
      return matches;
    case "HIDE":
    default:
      return !matches;
  }
};

/**
 * Test the display conditions against the responses to see if question should be displayed
 *
 * @param questionRootId Question in question (lol)
 * @param responses All the responses on the document
 * @param displayConditions All the display conditions on the document
 */
export const isQuestionVisible = (
  questionRootId: number,
  responses: Array<DocumentQuestionResponseVm>,
  displayConditions?: Array<DisplayConditionDTO>
) => {
  return isTargetVisible(
    questionRootId,
    "QUESTION",
    responses,
    displayConditions
  );
};

/**
 * Test the display conditions against the responses to see if section should be displayed
 *
 * @param sectionRootId Section in question
 * @param responses All the responses on the document
 * @param displayConditions All the display conditions on the document
 */
export const isSectionVisible = (
  sectionRootId: number,
  responses: Array<DocumentQuestionResponseVm>,
  displayConditions?: Array<DisplayConditionDTO>
) => {
  return isTargetVisible(
    sectionRootId,
    "SECTION",
    responses,
    displayConditions
  );
};

/**
 * Get the set of visible question ids
 * @param sections Sections that could possibly be shown
 * @param responses All the responses on the document
 * @param displayConditions All the display conditions on the document
 */
export const getVisibleQuestionIds = (
  sections: Array<SectionDTO>,
  responses: Array<DocumentQuestionResponseVm>,
  displayConditions?: Array<DisplayConditionDTO>
): Set<number> => {
  const visibleQuestionIds = new Set<number>();
  sections.forEach((s) => {
    if (isSectionVisible(s.rootId, responses, displayConditions)) {
      s.items.forEach((item) => {
        if (
          item.type === "QUESTION" &&
          isQuestionVisible(item.rootId, responses, displayConditions)
        ) {
          visibleQuestionIds.add(item.id);
        }
      });
    }
  });
  return visibleQuestionIds;
};

/**
 * Extract query parameters into a map
 * @param queryString query string after url
 */
export const extractQueryParameters = (queryString: string): object => {
  const map = {};
  if (!queryString) {
    return map;
  }

  const questionIndex = queryString.indexOf("?");
  if (questionIndex >= 0) {
    queryString = queryString.substr(questionIndex + 1);
  }

  for (const keyValue of queryString.split("&")) {
    const eqIndex = keyValue.indexOf("=");
    if (eqIndex > 0) {
      map[keyValue.substr(0, eqIndex)] = keyValue.substr(eqIndex + 1);
    }
  }

  return map;
};

/**
 * Gets the current API hostname from window location
 *
 * PROD URLs
 * - web: https://<client>.powerfields.io
 * - api: https://<client>.api.powerfields.io
 *
 * NON-PROD URLs
 * - web: https://<client>.<env>.powerfields-dev.io
 * - api: https://<client>.api.<evn>.powerfields-dev.io
 */
export const getApiHost = (): string => {
  // default to dom dev if working locally
  if (window.location.hostname.indexOf("localhost") >= 0) {
    return "https://dom.api.dev.powerfields-dev.io";
    // return "https://dom.api.qa.powerfields-dev.io";
    // return "https://dom.api.uat.powerfields-dev.io";
    // return "http://localhost:8080"; // if backend is local
  }

  const hostArr = window.location.hostname.split(".");
  const client = hostArr[0];
  let env;
  let pfenv;
  // production
  if (hostArr.length === 3) {
    pfenv = hostArr[1];
    // lower environments
  } else if (hostArr.length === 4) {
    env = hostArr[1];
    pfenv = hostArr[2];
  }
  // generate string
  return `https://${client}.api.${(env && env + ".") || ""}${pfenv}.io`;
};

/**
 * Format selection DTOs into options for Select components
 * @param selections
 */
export function fmtSelections(
  selections?: Array<QuestionSuggestion>
): Array<Option> {
  if (!selections) {
    return [];
  }

  return selections.map((selection) => ({
    id: selection.id,
    value: selection.title,
  }));
}
