import {
  FormDTO,
  SectionDTO,
  WorkflowType,
  SectionItem,
} from "store/forms/types";
import {
  FBForm,
  FBQuestion,
  FBItem,
  FBSection,
  FBWidget,
  FBContent,
  ItemType,
} from "./types";
import { getQuestionHasTags } from "./create/properties/customFields/Tags/helpers";
import { MissingWidgetType } from "./create/properties/MissingWidgetBanner";
import {
  NewFormQuestion,
  NewFBForm,
  NewFormSection,
  NewFormWidget,
  NewFormContent,
  NewDisplayCondition,
} from "store/builder/types";
import { toTitleCase } from "util/index";
import { DisplayConditionDTO } from "store/documents/types";
import { sortBy } from "lodash";
import { buildConditionFields } from "./create/properties/customFields/PrefillFromWorkOrder/PrefillFromWorkOrder";

export const getFormStatusString = (status?: WorkflowType) => {
  if (status === "FINAL") {
    return "Published";
  }
  return toTitleCase(status);
};

export function getSecondsFromMidnight(timeString: string) {
  const [hours, minutes] = timeString.split(":");
  return Number(hours) * 3600 + Number(minutes) * 60;
}

export function getTimeFromSeconds(seconds: number) {
  const date = new Date(0);
  date.setSeconds(seconds); // specify value for SECONDS here
  return date.toISOString().substr(11, 8);
}

/** Filters out any display conditions which have not been completed */
function getCompletedDisplayConditions(
  displayConditions?: (DisplayConditionDTO | NewDisplayCondition)[]
): DisplayConditionDTO[] {
  return displayConditions?.filter(
    (dc) =>
      dc.action &&
      dc.targetRootId &&
      dc.targetType &&
      (dc.sourceQuestionRootId || dc.action === "WORK_ORDER_PREFILL")
  ) as DisplayConditionDTO[];
}

/**
 * Converts an existing form question into a Form Builder Item for processing
 * @param formItem
 * @param sectionParentID
 * @param itemIndex
 */
export function unmarshallItem(
  formItem: SectionItem,
  section: SectionDTO,
  itemIndex: number,
  displayConditions?: DisplayConditionDTO[]
): FBItem {
  const item = {
    ...formItem,
    parentID: section.id,
    parentRootID: section.rootId,
    subType: formItem.subType,
    title: "title" in formItem ? formItem.title : "",
    type: formItem.type,
    position: itemIndex,
    properties:
      "properties" in formItem
        ? formItem.properties
        : {
            isCloneable: false,
            isRequired: false,
            isSearchable: false,
          },
    id: formItem.id || -1,
  } as FBItem;

  if (item.type === "WIDGET") {
    return item as FBWidget;
  }
  if (item.type === "CONTENT") {
    return item as FBContent;
  }
  if (item.type === "QUESTION") {
    if (
      item.subType === "PARTICIPANT" &&
      item.answerSource &&
      item.answerSource.type === "CURRENT_PARTICIPANT"
    ) {
      if (
        item.answerSource.properties.prefillAnswerField ===
        "supervisor.fullName"
      ) {
        return { ...item, type: "WIDGET", subType: "SUPERVISOR" };
      } else if (item.answerSource.properties?.readOnly) {
        return { ...item, type: "WIDGET", subType: "DOCUMENT_CREATOR" };
      }
    }

    const displayCondition = displayConditions?.find(
      (dc) =>
        dc.action === "WORK_ORDER_PREFILL" && dc.targetRootId === item.rootId
    );

    if (displayCondition?.prefillAnswerField) {
      item.conditionFields = buildConditionFields(
        displayCondition.prefillAnswerField
      );
    }

    return item as FBQuestion;
  }

  return item as FBQuestion;
}

/**
 * Converts an existing form section into a Form Builder Section for processing
 * @param formSection
 * @param sectionIndex
 */
export function unmarshallSection(
  formSection: SectionDTO,
  sectionIndex: number,
  displayConditions?: DisplayConditionDTO[]
): FBSection {
  // unmarshall each question in the section
  const items = formSection.items.map((fItem, i) =>
    unmarshallItem(fItem, formSection, i, displayConditions)
  );
  return {
    ...formSection,
    id: formSection.id,
    items,
    position: sectionIndex,
    properties: formSection.properties,
    title: formSection.title || "",
    type: "SECTION",
  };
}

/**
 * Converts an existing form to an array of Form Builder Sections for processing
 * @param form
 */
export function unmarshallForm(form: FormDTO): FBForm {
  // unmarshall each section in the form
  const sections = form.sections.map((fSection, i) =>
    unmarshallSection(fSection, i, form.displayConditions)
  );

  // these are not sorted by the backend, but should be sorted here for consistency in form builder
  const displayConditions = sortBy(form.displayConditions || [], ["id"]);

  return { ...form, sections, displayConditions };
}

/***** MARSHALL FORM *****/

/**
 * Converts a Form Builder Item into a form item for processing
 * @param formItem
 * @param defenseQuestions
 */
export function marshallItem(
  formItem: FBItem
): NewFormQuestion | NewFormWidget | NewFormContent {
  if (["DOCUMENT_CREATOR", "SUPERVISOR"].includes(formItem.subType)) {
    return {
      ...formItem,
      subType: "PARTICIPANT",
      type: "QUESTION",
    };
  }
  if (formItem.type === "QUESTION") {
    const selections = formItem.selections?.map((sel) => {
      // we need to strip out the temporary string ids from the new selections
      // they'll match the title because that's what MultiInput returns if no
      // id exists
      if (String(sel.id) === sel.title) {
        return { id: null, title: sel.title };
      }
      return sel;
    });
    return {
      ...formItem,
      selections,
      type: "QUESTION",
    } as FBQuestion;
  }

  return formItem;
}

/**
 * Converts a Form Builder Section into a form section for processing
 * @param formSection
 * @param defenseQuestions
 */
export function marshallSection(
  formSection: FBSection
): NewFormSection | SectionDTO {
  // marshall each question in the section
  const items = formSection.items.map((fItem) => marshallItem(fItem));
  return {
    ...formSection,
    items,
  };
}

/**
 * Converts an updated form to the correct shape to submit to the API
 * @param form
 */
export function marshallForm(form: FBForm): NewFBForm | FormDTO {
  // Form Submission Constraints
  if (form.formSubmissionConstraint) {
    const {
      hourType,
      hourLimit,
      timeLimit,
      type,
      id,
      noSubmissionsUntilNextDay,
    } = form.formSubmissionConstraint;
    // if type is blank, Submissions can always be edited
    if (!type) {
      delete form.formSubmissionConstraint;
    } else {
      // convert the hour limit to ms
      const finalHourLimit =
        hourLimit && (hourType === "days" ? hourLimit * 24 : hourLimit);
      form.formSubmissionConstraint = {
        id,
        type,
        noSubmissionsUntilNextDay,
        hourLimit: type === "HOUR" ? finalHourLimit : undefined,
        timeLimit: type === "TIME" ? timeLimit : undefined,
      };
    }
  }

  // filter out any incomplete display conditions
  // @TODO should we show a warning/validation when a user is about to lose the incompleted display conditions?
  // on unmount/switch item/close form
  const displayConditions: DisplayConditionDTO[] = getCompletedDisplayConditions(
    form.displayConditions
  );

  // marshall each section in the form
  const sections = form.sections.map((fSection) => marshallSection(fSection));

  return { ...form, sections, displayConditions };
}

/**
 * Get a flat array of all the items on the form
 * @param form
 */
export function getAllFormItems(form: FBForm): FBItem[] {
  return form.sections?.flatMap((section: FBSection) => section.items);
}

/**
 * Get a form section item (question/widget/content) from the form by its id
 * @param form Form to search
 * @param id Question id to search for
 */
export const getFormItemById = (
  form: FBForm,
  id: number
): FBItem | undefined => {
  const items: FBItem[] | undefined = getAllFormItems(form);
  return items?.find((item: FBItem) => item.id === id);
};

/**
 * Get a form section item (question/widget/content) from the form by its root id
 * @param form    - form to search
 * @param rootId  - root id to search for
 */
export const getFormItemByRootId = (
  form: FBForm,
  rootId: number
): FBItem | undefined => {
  const items: FBItem[] | undefined = getAllFormItems(form);
  return items?.find((item: FBItem) => item.rootId === rootId);
};

/**
 * Returns true if a question has OEs or Defenses attached to its selections,
 * but the form is missing the associated widget
 *
 * @param form
 * @param item Item to check for OEs/Defenses
 */
export function getMissingWidgets(form: FBForm, item: FBQuestion) {
  const formItems = getAllFormItems(form);
  const missingWidgets: MissingWidgetType[] = [];

  if (getQuestionHasTags(item)) {
    if (
      !formItems.find(
        (item) =>
          item.type === "WIDGET" && item.subType === "OPERATIONAL_EXPERIENCES"
      )
    ) {
      missingWidgets.push("OPERATIONAL_EXPERIENCES");
    }
  }
  if (item.selections?.some((selection) => selection.defenseIds.length > 0)) {
    if (
      !formItems.find(
        (item) => item.type === "WIDGET" && item.subType === "DEFENSES"
      )
    ) {
      missingWidgets.push("DEFENSES");
    }
  }

  return missingWidgets;
}

/**
 * Scroll to an element with the given id
 * @param elementId
 */
export function scrollToElement(elementId: string) {
  const node = document.getElementById(elementId);
  node?.scrollIntoView({
    behavior: "smooth",
    block: "start",
  });
}

/**
 * Get the section index of the currently selected item or section
 * @param selectedItem currently selected item
 * @param sections all sections on the form
 */
export function getSelectedSectionIndex(
  selectedItem: FBItem | FBSection | null,
  sections: FBSection[]
) {
  if (!selectedItem) return -1;
  if (selectedItem.type === "SECTION") {
    return selectedItem.position;
  } else {
    return sections.findIndex(
      (section) => section.id === selectedItem.parentID
    );
  }
}

/**
 *
 * @param itemType item type which is being added
 * @param sectionIndex section index of the currently selected item
 * @param selectedItem currently selected item
 * @param sections all sections on the form
 */
export function getAddedItemIndex(
  itemType: ItemType, // type of added item
  sectionIndex: number | undefined, // section index of currently selected item
  selectedItem: FBItem | FBSection | null, // currently selected item
  sections: FBSection[]
) {
  // adding a section
  if (itemType === "SECTION") {
    return typeof sectionIndex === "number" ? sectionIndex + 1 : undefined;
  }
  // adding an item to a section
  if (selectedItem?.type === "SECTION") {
    if (typeof sectionIndex === "number") {
      return sections[sectionIndex].items.length;
    } else {
      return;
    }
  }
  if (typeof selectedItem?.position === "number") {
    return selectedItem?.position + 1;
  }
}

/**
 * Strip the section and item indices from an item's path
 * @param itemPath - path to an item in formik values, e.g. section[0].items[3]
 */
export function getPathIndices(itemPath: string): [number?, number?] {
  const indices = itemPath
    .replace(/[a-z\[\]]/g, "")
    .split(".")
    .map((idx) => Number(idx));
  if (typeof indices?.[0] === "number") {
    return [indices[0], indices[1]];
  }
  return [undefined, undefined];
}

export const isFormPublished = (workflowType: WorkflowType) => {
  return workflowType === "FINAL";
};
