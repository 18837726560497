import React, { useEffect, useState } from "react";
import { css } from "aphrodite/no-important";
import { useFormikContext, Field } from "formik";
import propertiesStyles from "../styles";
import Select from "components/common/form/Select";
import { FBForm, Property } from "components/clientAdmin/formBuilder/types";
import { get } from "lodash";
import { Option } from "components/common/form/Select/Select";
import { isFormPublished } from "components/clientAdmin/formBuilder/helpers";

interface Props {
  property: Property;
  itemPath: string;
}

const initialItem: Option = {
  id: -1,
  value: "No notifications",
};

export const Notifications = ({ property, itemPath }: Props) => {
  const [participantQuestions, setParticipantQuestions] = useState<Option[]>([
    initialItem,
  ]);
  const { values, setFieldValue } = useFormikContext<FBForm>();
  const notify = get(values, `${itemPath}.${property.name}`) || null;
  function onChange(e: React.ChangeEvent<HTMLInputElement>) {
    // note that the "id" of below is really the rootId of the original questions
    const selectedQuestion = participantQuestions.find(
      (q) => q.id === e.target.value
    );
    setFieldValue(`${itemPath}.${property.name}`, selectedQuestion?.id || null);
  }
  useEffect(() => {
    const pQuestions: Option[] = [initialItem];
    values.sections.forEach((section) => {
      section.items.forEach((item) => {
        if (item.subType === "PARTICIPANT") {
          pQuestions.push({ value: item.title || "", id: item.rootId });
        }
      });
    });
    setParticipantQuestions(pQuestions);
  }, [values]);
  const ps = propertiesStyles();
  const isPublished = isFormPublished(values.workflowType);

  return (
    <div>
      <p className={css(ps.notificationsLabel)}>{property.label}</p>
      <p className={css(ps.notificationsHelperText)}>{property.helperText}</p>
      <Field
        as={Select}
        options={participantQuestions}
        value={notify}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange(e)}
        name={property.name}
        disabled={isPublished}
      />
    </div>
  );
};
