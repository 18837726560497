import styled from "styled-components";
import { remCalc } from "themes/helpers";

export const Tag = styled.div`
  padding: ${remCalc(4)} ${remCalc(11)};
  background-color: ${({ theme }) => theme.colors.activeFill};
  border: 1px solid ${({ theme }) => theme.colors.primary};
  border-radius: ${remCalc(16)};
`;

export const Text = styled.span`
  color: ${({ theme }) => theme.colors.primary};
  font-size: ${remCalc(12)};
`;
