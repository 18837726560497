import { Redirect } from "react-router-dom";
import * as React from "react";
import { useDispatch } from "react-redux";
import { Location } from "history";
import { extractQueryParameters, getApiHost } from "../helpers";
import Button from "components/forms/Button";
import { setIdToken } from "../../store/system/actions";

/**
 * OAuth2Login is a login page that allows users to log in through ADFS and other providers
 * If the id_token query parameter is included (which is true when this page is redirected to from the backend) then
 * the user is redirected to the root path.
 */

export interface OAuth2LoginProps {
  location: Location;
}

// Moved out in src/components/helpers
function extractToken(location: Location): string | undefined {
  if (!location || !location.search) {
    return;
  }
  const queries = extractQueryParameters(location.search);
  return queries["id_token"];
}

// Moved out in src/components/auth/adfs/constants
const oauthBaseRoute = `${getApiHost()}/oauth2/authorize`;

// Moved out in src/components/auth/adfs/helpers
const oauthRedirect = (provider: string) => () => {
  window.location.href = `${oauthBaseRoute}/${provider}`;
};

export const OAuth2Login = function(props: OAuth2LoginProps) {
  const dispatch = useDispatch();
  const { location } = props;
  const idToken = extractToken(location);
  if (idToken) {
    dispatch(setIdToken(idToken));
    return <Redirect to={"/"} />;
  } else {
    // todo make pretty
    // todo add more buttons
    return <Button onClick={oauthRedirect("azure")}>Login through Azure</Button>;
  }
};
