import React from "react";

import { RenderGroups as Props } from "../types";
import * as S from "../styles";

const RenderGroups = ({ values, label, onClick, isDesktop, locked }: Props) => {
  if (!values) return null;
  return (
    <S.RenderGroups onClick={onClick} isDesktop={isDesktop} isLocked={locked}>
      {!isDesktop && <S.UnlockedLabel as="p">{label}</S.UnlockedLabel>}
      <S.GroupList>
        {values.map((x) => (
          <S.GroupLabel locked={locked} key={x}>
            {x}
          </S.GroupLabel>
        ))}
      </S.GroupList>
    </S.RenderGroups>
  );
};

export default RenderGroups;
