import React, {
  forwardRef,
  KeyboardEventHandler,
  MouseEventHandler,
} from "react";
import { joinClassNames } from "themes/helpers";
import { useMouseFocus } from "util/hooks/useMouseFocus";
import styles from "./styles.module.scss";

export interface ListItemContent {
  aside?: string;
  id: string;
  subtitle?: string;
  title: string;
  value?: string;
}

interface ListItemProps {
  className?: string;
  content: ListItemContent;
  handleChange?: (isChecked: boolean) => void;
  id?: string;
  isChecked?: boolean;
  titleId: string;
}

export const ListItem = forwardRef<HTMLLIElement, ListItemProps>(
  ({ className, content, handleChange, id, isChecked, titleId }, ref) => {
    const { eventHandlers, isMouseFocused } = useMouseFocus();
    const { aside, subtitle, title, value } = content;

    const handleClick: MouseEventHandler<HTMLLIElement> = (event) => {
      event.preventDefault();
      if (handleChange) {
        handleChange(!isChecked);
      }
    };

    const handleKeyDown: KeyboardEventHandler<HTMLLIElement> = (event) => {
      const spaceKey = " ";
      if (event.key === spaceKey) {
        event.preventDefault();
        if (handleChange) {
          handleChange(!isChecked);
        }
      }
    };

    return (
      <li
        aria-checked={isChecked}
        aria-labelledby={titleId}
        className={joinClassNames(
          styles.listItem,
          isChecked && styles.listItemChecked,
          !isMouseFocused && styles.listItemKeyboardFocused,
          className
        )}
        id={id}
        onClick={handleClick}
        onKeyDown={handleKeyDown}
        ref={ref}
        role="checkbox"
        tabIndex={0}
        {...eventHandlers}
      >
        <div className={styles.details}>
          <div className={styles.title} id={titleId}>
            {title}
          </div>
          {subtitle && <div className={styles.subtitle}>{subtitle}</div>}
        </div>
        <div className={styles.aside}>{aside}</div>
      </li>
    );
  }
);

export const ListItemNoninteractive = forwardRef<HTMLLIElement, ListItemProps>(
  ({ className, content, id, titleId }, ref) => {
    const { aside, subtitle, title } = content;
    return (
      <li
        className={joinClassNames(
          styles.listItem,
          styles.listItemNoninteractive,
          className
        )}
        id={id}
        ref={ref}
      >
        <div className={styles.details}>
          <div className={styles.title} id={titleId}>
            {title}
          </div>
          {subtitle && <div className={styles.subtitle}>{subtitle}</div>}
        </div>
        <div className={styles.aside}>{aside}</div>
      </li>
    );
  }
);

ListItem.displayName = "ListItem";
ListItemNoninteractive.displayName = "ListItemNoninteractive";
