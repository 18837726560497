import React from "react";
// import CardActionArea from "@material-ui/core/CardActionArea";

import * as S from "./styles";

export interface CardProps {
  /**
   * used to extend styles to outer wrapper
   */
  className?: string;
  /**
   * `React Element` which renders in the body/bottom area of the card
   */
  children?: React.ReactNode;
  cardMedia?: {
    image: string;
    title: string;
  };
  /**
   * `string` which populates the "Pill" element
   */
  status?: string;
  /**
   * `string` which populates the "main title" of the card
   */
  title?: string;
  /**
   * `string` which populates the subheader/sub-title
   */
  subHeader?: string;
  /**
   * `string` which populates the final text element in
   * the "card header". This element is on the same row as
   * the `subHeader`, positioned at the end
   */
  tertiaryHeader?: string;
  /**
   * `function` applied to `MoreVertButton` which
   * also conditionally renders the associated element
   */
  onClick?: () => void;
  cardOnClick?: (e?: any) => void;
  submitDeadline?: string | null;
}

const Card = ({
  className,
  cardMedia,
  cardOnClick,
  title,
  subHeader,
  children,
  status,
  tertiaryHeader,
  onClick,
  submitDeadline,
}: CardProps) => {
  const localOnClick = (e) => {
    e.stopPropagation();

    onClick && onClick();
  };

  return (
    <S.Card className={className} onClick={cardOnClick}>
      {/* <CardActionArea onClick={cardOnClick}> */}
      <S.MUICard>
        {cardMedia && (
          <S.CardMedia image={cardMedia.image} title={cardMedia.title} />
        )}

        {(onClick || title || status || subHeader || tertiaryHeader) && (
          <S.CardHeader
            action={
              <S.IconButton onClick={localOnClick}>
                <S.MoreVertIcon />
              </S.IconButton>
            }
            title={
              status && (
                <S.Pill isSubmitted={status === "Submitted"}>
                  {status}
                  {submitDeadline}
                </S.Pill>
              )
            }
            subheader={
              <S.Column>
                {title && (
                  <S.Row>
                    <S.CardTitle>{title}</S.CardTitle>
                  </S.Row>
                )}
                <S.Column>
                  {subHeader && (
                    <S.Row spaceBetween>
                      <S.CardSubTitle>{subHeader}</S.CardSubTitle>
                      {tertiaryHeader && (
                        <S.CardSubTitle tertiary>
                          {tertiaryHeader}
                        </S.CardSubTitle>
                      )}
                    </S.Row>
                  )}
                </S.Column>
              </S.Column>
            }
          />
        )}

        {children && <S.CardContent>{children}</S.CardContent>}
      </S.MUICard>
      {/* </CardActionArea> */}
    </S.Card>
  );
};

export default Card;
