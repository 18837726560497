import React, { FC, useEffect, useState } from "react";
import { css } from "aphrodite/no-important";

import { Defense } from "store/documents/types";
import { LazyLoadSelect } from "components/common/form/Select";

import styles from "../../drawers/styles";
import { Option } from "components/common/form/Select/Select";
import CustomDefenseForm from "./CustomDefenseForm";

interface Props {
  defense: Defense | null;
  defensesLoading: boolean;
  defenseType: string;
  globalDefenses: Defense[];
  isFinalPage: boolean;
  onLoadMore: () => void;
  onSelectDefense: (defense: Defense) => void;
  disabled?: boolean;
}

const AddDefense: FC<Props> = ({
  defense,
  defensesLoading,
  defenseType,
  globalDefenses,
  isFinalPage,
  onLoadMore,
  onSelectDefense,
  disabled,
}) => {
  const s = styles();
  const [options, setOptions] = useState<Option[]>([]);

  /**
   * Select the defense
   * @param e select event
   */
  function handleSelectDefense(e: React.ChangeEvent<HTMLSelectElement>) {
    const defense = globalDefenses.find(
      (gDefense) => String(gDefense.id) === String(e.target.value)
    );
    if (defense) {
      onSelectDefense(defense);
    }
  }

  /**
   * If the selection already has a defense but it isn't in the existing
   * dropdown options, add the defense to the dropdown options and select it
   */
  useEffect(() => {
    const defenseOptions = globalDefenses
      .filter((gDefense) => gDefense.status !== "ARCHIVED")
      .map((gDefense) => ({
        value: gDefense.title || "Defense does not have title",
        id: gDefense.id,
      }));
    if (defense) {
      if (
        defenseOptions.findIndex((gDefense) => gDefense.id === defense.id) ===
        -1
      ) {
        defenseOptions.unshift({
          value: defense.title || "Defense does not have title",
          id: defense.id,
        });
      }
    }
    defenseOptions.unshift({ value: "Please select a defense", id: -1 });
    setOptions(defenseOptions);
  }, [defense, globalDefenses]);

  const selectedDefense = defense?.id || -1;

  function renderDefenseField() {
    switch (defenseType) {
      case "custom":
        return <CustomDefenseForm />;
      case "global":
        return (
          <LazyLoadSelect
            name="defense"
            label="Global Defense"
            helperText="Assistive Text"
            options={options}
            onLoadMore={onLoadMore}
            isLoadingMore={defensesLoading}
            isFinalPage={isFinalPage}
            onChange={handleSelectDefense}
            value={selectedDefense}
            disabled={disabled}
          />
        );
      default:
        return null;
    }
  }

  return <div className={css(s.addDefense)}>{renderDefenseField()}</div>;
};

export default AddDefense;
