import React from "react";

import * as S from "./styles";

export interface RowsPerPage {
  pageSize?: number;
  onClick: (size: number) => void;
}

const options = [
  {
    id: 10,
    value: 10,
  },
  {
    id: 50,
    value: 50,
  },
  {
    id: 100,
    value: 100,
  },
];

const RowsPerPage = ({ onClick, pageSize }: RowsPerPage) => {
  return (
    <S.Wrapper>
      <S.Label>View: </S.Label>
      <S.SelectInput onChange={(e) => onClick(e.target.value)} options={options} value={pageSize || 10} />
    </S.Wrapper>
  );
};

export default RowsPerPage;
