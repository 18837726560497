import CheckboxBase, {
  CheckboxProps as CheckboxPropsBase,
} from "@material-ui/core/Checkbox";
import CheckedIconBase from "@material-ui/icons/CheckBox";
import FormControlBase from "@material-ui/core/FormControl";
import FormControlLabelBase from "@material-ui/core/FormControlLabel";
import React from "react";
import UnCheckedIconBase from "@material-ui/icons/CheckBoxOutlineBlank";
import styled from "styled-components";

import { FieldErrorType } from "components/FormController/components/Question";

import { remCalc } from "themes/helpers";
import mediaQueries from "themes/mediaQueries";
import { CheckBoxVariantType } from "./components/CheckboxGroup";

export const CheckedIcon = styled(({ ...props }) => (
  <CheckedIconBase
    {...props}
    classes={{
      root: "root",
    }}
  />
))`
  &&.root {
    font-size: ${remCalc(24)};

    @media (${mediaQueries.maxTablet}) {
      font-size: ${remCalc(32)};
    }
  }
`;
export const UnCheckedIcon = styled(({ ...props }) => (
  <UnCheckedIconBase
    {...props}
    classes={{
      root: "root",
    }}
  />
))`
  &&.root {
    font-size: ${remCalc(24)};
    color: ${({ theme }) => theme.masterColors.darkGrey};

    @media (${mediaQueries.maxTablet}) {
      font-size: ${remCalc(32)};
    }
  }
`;

type CheckboxInputType = {
  active?: boolean;
  enabledComment?: boolean;
  error?: FieldErrorType;
  fullWidth?: boolean;
  isCheckboxFocused: boolean;
  variant: CheckBoxVariantType;
};

export const CheckboxInput = styled.div<CheckboxInputType>`
  ${({ fullWidth }) => fullWidth && "width: 100%;"}
  background: ${(props) => props.active && props.theme.colors.activeFill};
  /* height: ${(props) => (!props.enabledComment ? remCalc(68) : "")}; */
  min-height: ${remCalc(56)};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items:space-between;
  padding: ${remCalc(6)} ${remCalc(8)};
  border-style: solid;
  border-width: 1px;
    
  ${({ active, error, isCheckboxFocused, theme }) => {
    if (isCheckboxFocused) {
      return `
          border-color: ${theme.colors.focusIndicatorOutline};
          box-shadow: 0 0 0 ${remCalc(3)} ${theme.colors.focusIndicatorOutline};
        `;
    } else if (error) {
      return `border-color: ${theme.colors.error};`;
    } else if (active) {
      return `border-color: ${theme.colors.primary};`;
    } else {
      return `border-color: ${theme.colors.darkGrey};`;
    }
  }}
    
  ${({ error, theme }) =>
    error && `border-color: ${theme.masterColors.error};`};
  ${({ variant }) => 
    variant === "CHECKBOX_FILTER" && "border: 0; background: transparent"}
  border-radius: ${remCalc(4)};
  margin-bottom: ${remCalc(5)};

  // HANDLE RESPONSIVE STYLES
  @media (${mediaQueries.maxTablet}) {
    min-height: ${remCalc(68)};
    margin-bottom: ${remCalc(8)};
    padding: ${remCalc(16)};
  }

  @media (${mediaQueries.maxPhone}) {
    min-height: ${remCalc(56)};
  }
`;

interface CheckboxProps extends CheckboxPropsBase {
  error?: FieldErrorType;
}

export const Checkbox = styled<React.FC<CheckboxProps>>((props) => (
  <CheckboxBase
    {...props}
    disableRipple
    disableTouchRipple // Ripple has a weird shape
    classes={{
      root: "root",
      checked: "checked",
      indeterminate: "indeterminate",
      colorPrimary: "primary",
    }}
  />
))`
  &&.root {
    color: ${({ theme }) => theme.colors.formFieldBorder};
    padding: 0;
    padding-left: ${remCalc(9.5)};
    padding-right: ${remCalc(18)};
    &:hover {
      background-color: transparent;
    }
  }

  &&.checked {
    color: ${({ theme }) => theme.colors.primary};
    &:hover {
      background-color: transparent;
    }
  }
`;

export const FormControl = styled(({ ...otherProps }) => (
  <FormControlBase
    {...otherProps}
    classes={{
      root: "root",
    }}
  />
))`
  &&.root {
    min-width: min-content;
  }
`;

export const FormControlLabel = styled(
  ({ fullWidth, error, ...otherProps }) => (
    <FormControlLabelBase
      {...otherProps}
      classes={{ root: "root", label: "label" }}
    />
  )
)`
  &&.root {
    margin-bottom: 0px;
  }

  && .label {
    font-size: ${remCalc(16)};
    color: ${({ theme, error }) =>
      error ? theme.masterColors.error : theme.masterColors.black};
    font-family: "Roboto", sans-serif;

    /* MEDIA QUERIES */
    @media (${mediaQueries.maxTablet}) {
      font-size: ${remCalc(24)};
    }

    @media (${mediaQueries.maxPhone}) {
      font-size: ${remCalc(14)};
      overflow: wrap;
      max-width: 80%;
    }
  }
`;

export const LabelRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const AssistiveText = styled.button<{ enabledComment?: boolean }>`
  align-self: flex-end;
  border: none;
  font-family: "Roboto", sans-serif;
  font-size: ${remCalc(16)};
  color: ${({ theme }) => theme.colors.primary};
  padding-right: ${remCalc(16)};
  background: none;
  border-radius: ${remCalc(4)};
  /* padding-bottom helps line up the comment with the checkbox label */
  /* padding-bottom: ${remCalc(10)}; */

  ${({ enabledComment, theme }) =>
    enabledComment &&
    `
    color: ${theme.colors.error};
    text-decoration: underline;
  `}

  /* MEDIA QUERIES */
  @media (${mediaQueries.maxPhone}) {
    font-size: ${remCalc(14)};
  }

  &:hover {
    cursor: pointer;
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 ${remCalc(4)} ${({ theme }) =>
  theme.colors.focusIndicatorOutline};
  }
`;

export const CommentRow = styled.div`
  margin-bottom: ${remCalc(8)};
`;

// ------------------------------------------------------
// CheckboxGroup.tsx
// ------------------------------------------------------

export const CheckboxGroup = styled.div``;

export const CheckboxGroupLabelRow = styled.div<{ assistiveLink?: boolean }>`
  margin-bottom: ${({ assistiveLink }) =>
    assistiveLink ? remCalc(0) : remCalc(15)};
  @media (${mediaQueries.maxTablet}) {
    margin-bottom: ${remCalc(15)};
  }

  @media (${mediaQueries.maxPhone}) {
    margin-bottom: ${({ assistiveLink }) =>
      assistiveLink ? remCalc(0) : remCalc(15)};
  }
`;
