import React, {
  ChangeEventHandler,
  FormEventHandler,
  useState,
  useEffect,
} from "react";
import VisibilitySensor from "react-visibility-sensor";

import { Button } from "components/common/Button/futureUiKit";
import { GeoPoint } from "store/common/types";
import {
  ListItem,
  ListItemContent,
  ListItemNoninteractive,
} from "components/common/ListItem/futureUiKit/ListItem";
import { joinClassNames } from "themes/helpers";
import { OutlinedSearchField } from "components/common/form/SearchField";
import { TextButton } from "components/common/Button";
import Array from "util/array";
import Loader from "components/common/Loader";
import Text from "components/common/Text";
import useDebounce from "util/hooks/useDebounce";

import { DrawerHeader } from "./DrawerHeader";
import styles from "./styles.module.scss";
import { ParticipantProps } from "store/dataSource/types";

export interface DataSourceItem extends ListItemContent {
  associatedLocation?: GeoPoint | null;
  participant?: ParticipantProps;
}

export type SubmitButtonLabel = {
  prefix: string;
  label: string;
};

interface ItemSelectorFormProps {
  allowWriteIn?: boolean;
  className?: string;
  error?: string;
  handleChangeSearch: (query: string) => void;
  handleClose: () => void;
  handleSubmit: (selectedOptions: DataSourceItem[]) => void;
  hasMultiSelect?: boolean;
  isLastPage?: boolean;
  isLoadingMore?: boolean;
  isLoadingSearchResults?: boolean;
  listTitle: string;
  name: string;
  noResultsMessage: string;
  onLoadMore?: () => void;
  searchLabel: string;
  searchPlaceholder?: string;
  options: DataSourceItem[];
  selected: DataSourceItem[];
  shouldShowHandleBar?: boolean;
  submitButtonLabel?: SubmitButtonLabel;
  subtitle?: string;
  title: string;
  titleId: string;
}

export const WRITE_IN_ID = "write-in";

export const ItemSelectorForm = ({
  allowWriteIn,
  className,
  error,
  handleChangeSearch,
  handleClose,
  handleSubmit,
  hasMultiSelect,
  isLastPage,
  isLoadingMore,
  isLoadingSearchResults,
  listTitle,
  name,
  noResultsMessage,
  onLoadMore,
  searchLabel,
  searchPlaceholder,
  options,
  selected,
  shouldShowHandleBar,
  submitButtonLabel,
  subtitle,
  title,
  titleId,
}: ItemSelectorFormProps) => {
  const [query, setQuery] = useState("");
  const [selectedResults, setSelectedOptions] = useState<DataSourceItem[]>([]);
  const listTitleId = `${name}-listTitle`;

  useEffect(() => {
    setSelectedOptions(selected);
  }, []);

  const toggleOption = (result: DataSourceItem) => {
    let selectedOptions;
    if (hasMultiSelect) {
      const foundIndex = Array.findIndexById(selectedResults, result.id);
      selectedOptions =
        foundIndex !== -1
          ? Array.remove(selectedResults, foundIndex)
          : Array.push(selectedResults, result);
    } else {
      selectedOptions = [result];
    }
    setSelectedOptions(selectedOptions);
  };

  const debouncedSearch = useDebounce({
    delayAmount: 500,
    method: handleChangeSearch,
  });

  const localHandleChangeSearch: ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    const newQuery = event.currentTarget.value;
    setQuery(newQuery);
    debouncedSearch(newQuery);
  };

  const handleClickClearSearch = () => {
    setQuery("");
    debouncedSearch("");
  };

  const localHandleSubmit: FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault();
    handleSubmit(selectedResults);
  };

  const getSubmitButtonLabel = () => {
    if (submitButtonLabel) {
      if (selectedResults.length > 1) {
        return (
          <span className={styles.buttonContent}>
            {submitButtonLabel.prefix}
            <span className={styles.selectedCount}>
              {selectedResults.length}
            </span>
            {submitButtonLabel.label}s
          </span>
        );
      }

      return `${submitButtonLabel.prefix} ${submitButtonLabel.label}`;
    }

    return "Submit";
  };

  const writeInResult = {
    id: WRITE_IN_ID,
    title: query,
  };

  return (
    <form
      className={joinClassNames(styles.form, className)}
      onSubmit={localHandleSubmit}
      noValidate
    >
      <DrawerHeader
        handleClose={handleClose}
        shouldShowHandleBar={shouldShowHandleBar}
        subtitle={subtitle}
        title={title}
        titleId={titleId}
      />
      <div className={styles.searchContainer}>
        <OutlinedSearchField
          handleChange={localHandleChangeSearch}
          handleClickClearButton={handleClickClearSearch}
          label={searchLabel}
          name={`${name}-search`}
          placeholder={searchPlaceholder}
          value={query}
        />
        <span className={styles.listTitle} id={listTitleId}>
          {listTitle}
        </span>
      </div>
      <Loader className={styles.loader} loading={isLoadingSearchResults}>
        <ul className={styles.list}>
          {options.length > 0 ? (
            options.map((result) => {
              const handleChange = () => {
                toggleOption(result);
              };

              return (
                <ListItem
                  className={styles.listItem}
                  content={result}
                  handleChange={handleChange}
                  isChecked={!!Array.findById(selectedResults, result.id)}
                  key={result.id}
                  titleId={`${name}-result${result.id}-title`}
                />
              );
            })
          ) : (
            <ListItemNoninteractive
              content={{ id: "noResults", title: noResultsMessage }}
              titleId={`${name}-resultNone-title`}
            />
          )}
        </ul>
        {!isLastPage && !error && (
          <VisibilitySensor
            active={options.length > 0 && !isLoadingMore}
            onChange={(isVisible) => {
              if (isVisible && onLoadMore) {
                onLoadMore();
              }
            }}
            intervalDelay={500}
          >
            <div style={{ height: "1.2rem" }}>
              <Loader loading={isLoadingMore} />
            </div>
          </VisibilitySensor>
        )}
        {error && (
          <div className={styles.error}>
            <Text variant="error">{error}</Text>
            <TextButton
              className={styles.tryAgain}
              variant="link"
              onClick={onLoadMore}
            >
              Try again
            </TextButton>
          </div>
        )}
      </Loader>
      {!!allowWriteIn && !!query.length && (
        <>
          <div className={styles.searchContainer}>
            <span className={styles.listTitle} id={"write-in-listTitle"}>
              Add New Participant
            </span>
          </div>
          <ul className={styles.list}>
            <ListItem
              className={styles.listItem}
              content={writeInResult}
              handleChange={() => {
                toggleOption(writeInResult);
              }}
              isChecked={!!Array.findById(selectedResults, writeInResult.id)}
              titleId={"write-in-result-title"}
            />
          </ul>
        </>
      )}
      <div className={styles.footer}>
        <Button disabled={selectedResults.length === 0} type="submit">
          {getSubmitButtonLabel()}
        </Button>
      </div>
    </form>
  );
};
