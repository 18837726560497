import styled from "styled-components";
import { remCalc, toRgba } from "themes/helpers";
import mediaQueries from "themes/mediaQueries";

const Title = styled.h1`
  font-size: 20px;
  font-weight: 600;
`;
const SubTitle = styled.h2`
  font-size: 14px;
  font-weight: 300;
`;

const DrawerHandle = styled.div`
  background-color: ${({ theme }) => theme.colors.black};
  border-radius: 2.5px;
  height: 5px;
  margin: 16px auto 21px auto;
  opacity: 0.1;
  width: 36px;
`;

const DrawerContent = styled.div`
  margin: 0 auto;
  padding: 16px;
  width: 414px;
  max-width: 100%;
  @media (${mediaQueries.maxPhone}) {
    width: 100vw;
  }
`;
const DrawerTitle = styled.div`
  height: 77px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  text-align: left;
`;

export interface DetachedNewFormProps {
  anchorX: number;
  anchorY: number;
  variant: "tablet" | "desktop";
}

const DetachedNewForm = styled.div<DetachedNewFormProps>`
  position: absolute;
  z-index: 10000000;
  top: ${({ anchorX, anchorY }) => `${anchorY}px`};
  left: ${({ anchorX, anchorY }) => `${anchorX}px`};

  background: ${({ theme }) => theme.colors.white};
  min-width: ${remCalc(414)};
  min-height: ${remCalc(196.97)};
  padding: ${remCalc(8)};
  box-shadow: 0px 15px 25px ${({ theme }) => theme.masterColors.darkGrey};

  transform: translate(-45%, -110%);

  /* handle table styling variations */
  ${({ variant }) =>
    variant === "tablet" &&
    `
    transform: translate(-42%, -110%);
  `}
`;

const BottomArrow = styled.div`
  width: ${remCalc(0)};
  height: ${remCalc(0)};
  z-index: 110000010;

  position: absolute;
  top: 100%;
  left: 0;
  transform: translate(450%, -10%);

  border-top: 20px solid ${({ theme }) => theme.colors.white};
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
`;

const Backdrop = styled.div`
  z-index: 100;
  position: fixed;
  width: 100vh;
  height: 100vh;
  top: 0;
  left: 0;
  background: ${({ theme }) => toRgba(theme.colors.black, 0.3)};
`;

export {
  DrawerHandle,
  DrawerContent,
  DrawerTitle,
  Title,
  SubTitle,
  DetachedNewForm,
  BottomArrow,
  Backdrop,
};
