import React from "react";
import { ContentDTO } from "../../../store/forms/types";
import { css, StyleSheet } from "aphrodite";
import { remCalc } from "../../../themes/helpers";

interface StyleProps {
  backgroundColor?: string;
  textColor?: string;
}

const styles = ({ backgroundColor, textColor }: StyleProps) =>
  StyleSheet.create({
    banner: {
      backgroundColor,
      color: textColor,
      padding: `${remCalc(14)} ${remCalc(16)}`,
      borderRadius: 4,
      marginBottom: remCalc(16),
    },

    block: {
      marginBottom: remCalc(16),
      whiteSpace: "pre-wrap",
    },
  });

interface Props {
  item: ContentDTO;
}

/**
 * Render a custom content item or line break
 * @param item
 * @constructor
 */
export default function Content({ item }: Props) {
  switch (item.subType) {
    case "LINE_BREAK":
      return <hr />;
    case "BANNER_MESSAGE":
      return (
        <div
          className={css(
            styles({
              backgroundColor: item.backgroundColor,
              textColor: item.textColor,
            }).banner
          )}
        >
          <div
            dangerouslySetInnerHTML={{ __html: item.content }}
            className="dangerousBanner"
          />
          <style
            dangerouslySetInnerHTML={{
              __html: ".dangerousBanner > p:last-of-type { margin-bottom: 0 }",
            }}
          />
        </div>
      );
    case "BLOCK":
      return (
        <div
          style={{
            marginBottom: remCalc(16),
            whiteSpace: "pre-wrap",
          }}
        >
          <div
            dangerouslySetInnerHTML={{
              __html: item.content,
            }}
            className="dangerousBlock"
          />
          <style
            dangerouslySetInnerHTML={{
              __html: ".dangerousBlock > p:last-of-type { margin-bottom: 0 }",
            }}
          />
        </div>
      );
    default:
      return null;
  }
}
