import React, { useState, useEffect, useMemo, useCallback, FC } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { uniqBy } from "lodash";
import * as AUS from "components/clientAdmin/addUser/styles";
import * as S from "./styles";
import * as UserStyles from "components/clientAdmin/users/styles";
import BackToTop from "components/common/TableUI/BackToTop";
import Breadcrumbs from "components/common/Breadcrumbs";
import DeleteDocumentButton from "../documents/DeleteDocumentButton";
import DocumentDrawer, {
  DocumentDrawerSections,
} from "components/clientAdmin/documents/DocumentDrawer";
import Loader from "components/common/Loader";
import Modal from "components/common/Modal";
import Pagination, {
  PaginationWrapper,
} from "components/common/TableUI/Pagination";
import Popper from "components/common/Popper";
import RadioButton from "components/common/form/RadioButton";
import RowsPerPage from "components/common/TableUI/RowsPerPage";
import { StatsCard } from "components/common/StatsCard";
import StickyProfileHeader from "./components/StickyProfileHeader";
import SubmissionsCard from "./components/SubmissionsCard";
import TableSummary from "components/common/TableUI/TableSummary";
import UsersSearchBar from "components/filters/UsersSearchBar";
import moment from "moment";
import { AppState } from "store";
import { DeleteDocumentConfirmation } from "../users/modalContent/DeleteDocumentConfirmation";
import { DocumentSummary } from "store/documents/types";
import { FilterSelect } from "components/common/FilterSelect";
import { FormTypeStatsType } from "store/reporting/forms/types";
import {
  PopperChevron,
  PopperRow,
  PopperWrapper,
} from "components/clientAdmin/users/styles";
import {
  SubmitButton,
  StatsWrapper,
  PageContent,
} from "components/clientAdmin/styles";
import { Tab, Tabs, TabPanel, tabA11yProps } from "components/common/Tabs";
import { Text } from "components/clientAdmin/styles";
import { UsersParticipant } from "store/usersParticipants/types";
import { default as CommonText } from "components/common/Text";
import { deleteDocument } from "store/documents/actions";
import { getAllForms, getAllFormTypes } from "store/forms/actions";
import { getClientGroups } from "store/clientGroups/actions";
import { getExportUrl } from "../helpers";
import {
  getMinMaxSubmissionDatesFromDate,
  TimeFilterType,
  timeFilterOptions,
  getMinMaxSubmissionDatesFromTimeFilter,
} from "components/helpers/filters";
import {
  getParticipantSubmissionReports,
  getFormTypeStats,
} from "store/reporting/forms/actions";
import { getUserParticipant } from "store/usersParticipants/actions";
import { searchDocuments } from "store/documents/actions";
import { Group } from "store/common/types";
import useGroupTerm from "util/hooks/whiteLabel/useGroupTerm";
import ReadOnlyContent from "components/common/permissions/ReadOnlyContent";
import { DateRangeValue, DateRange } from "components/common/DateRange";
import { ViewUserTable } from "./ViewUserTable";
import {
  DeleteDocumentMenuItemProps,
  Params,
  Sorting,
  ViewuserProfileProps,
} from "./types";

// constants

const DeleteDocumentMenuItem: FC<DeleteDocumentMenuItemProps> = ({
  document,
  closeMenu,
  openConfirmationModal,
}) => {
  // Group config terms
  const documentTerm = useGroupTerm("document", "noun", undefined, "Document");
  const documentTermLower = documentTerm.toLowerCase();
  return (
    <DeleteDocumentButton document={document}>
      {(deleting, errorDeleting) => (
        <Loader loading={deleting}>
          <S.DeleteDocumentButton
            onClick={() => {
              openConfirmationModal();
              closeMenu();
            }}
          >
            Delete {documentTermLower} (permission required)
            {errorDeleting && (
              <CommonText className="ml-2" variant="error">
                An error occurred
              </CommonText>
            )}
          </S.DeleteDocumentButton>
        </Loader>
      )}
    </DeleteDocumentButton>
  );
};

const groupNameById = (id, groups) => {
  // Find the group name by ID
  let result = "";
  if (id && groups && groups.content) {
    const groupObject = groups.content.find((e) => e.id === id);
    if (groupObject) {
      result = groupObject.name;
    }
  }
  return result;
};

const isGroupInThisRole = (authorities, groupId, authority) => {
  // Check group exists in given authority
  const authorityObject =
    authorities && authorities.find((e) => e.authority === authority);
  let foundGroupId = 0;
  if (authorityObject) {
    foundGroupId = authorityObject.groups.find((e) => e === groupId);
  }
  if (foundGroupId) return true;
  return false;
};

const roles = [
  // {id: "ROLE_CLIENT_ADMIN", value: "Client Admin"},
  { id: "ROLE_CLIENT_REVIEWER", value: "Client Reviewer" },
  { id: "ROLE_FORM_ARCHITECT", value: "Form Architect" },
  { id: "ROLE_CONTENT_EDITOR", value: "Content Editor" },
  { id: "ROLE_GROUP_MANAGER", value: "Group Manager" },
  { id: "ROLE_USER", value: "User" },
];

// Pull all groups from all roles, then make the list unique
const uniqueGroups = (authorities) => {
  const allGroups: number[] = [];
  let uniqueGroups: number[] = [];
  if (authorities) {
    authorities.forEach((authority) => {
      authority.groups.forEach((group) => {
        allGroups.push(group);
      });
    });
    uniqueGroups = uniqBy(allGroups, (e) => e);
  }
  return uniqueGroups;
};

const statusOptions = [
  { id: -1, value: "All Statuses" },
  { id: "NEW", value: "New" },
  { id: "SUBMITTED", value: "Submitted" },
  { id: "IN_PROGRESS", value: "In Progress" },
  // { id: 4, value: "Deleted"},
];

const formTypeOptions = (formTypes) => {
  const result = [
    {
      id: -1,
      value: "All Form Types",
    },
  ];
  if (formTypes) {
    formTypes.forEach((e) =>
      result.push({
        id: e.id,
        value: e.name,
      })
    );
  }
  return result;
};

const generateGroupOptions = (options) => {
  const res = options.map((e) => ({
    id: e.id,
    value: e.name,
  }));
  res.unshift({
    id: -1,
    value: "All Groups",
  });
  return res;
};

const getDocumentsStatsNameIfSubmissionsFilterApplied = (
  dateRange: DateRangeValue | null,
  timeFilterType: TimeFilterType,
  documentsTerm: string
) => {
  const { minSubmissionDate } = getMinMaxSubmissionDatesFromTimeFilter(
    timeFilterType,
    dateRange
  );

  if (minSubmissionDate) {
    return `${documentsTerm} submitted since ${moment(minSubmissionDate).format(
      "MM/DD/YY"
    )}`;
  }

  return documentsTerm;
};

const listGroups = (allGroups, primaryGroup) => {
  const _allGroups = allGroups;
  _allGroups.sort((x, y) =>
    x.id === primaryGroup ? -1 : y.id === primaryGroup ? 1 : 0
  ); // Move primary group to front
  const result: any[] = [""];
  const primaryGroupStar = <S.StarIcon />;
  if (_allGroups) {
    _allGroups.forEach((group, index) => {
      if (primaryGroup === group.id) result.push(primaryGroupStar);
      result.push(
        `${group.name}${(index !== allGroups.length - 1 && ", ") || ""}`
      );
    });
  }
  // If no groups
  if (!result || result.length === 0) {
    return "-";
  }
  // If groups
  return result;
};

const ViewUserProfile = (props: ViewuserProfileProps) => {
  const { dispatch, formTypes, groups, history, userRoles } = props;

  // Group config terms
  const documentsTerm = useGroupTerm("document", "noun", "plural", "Documents");
  const documentsTermLower = documentsTerm.toLowerCase();
  const documentTerm = useGroupTerm("document", "noun", undefined, "Documents");
  const documentTermLower = documentTerm.toLowerCase();
  const supervisorTerm = useGroupTerm(
    "supervisor",
    "noun",
    undefined,
    "Supervisor"
  );

  // Get user id from url
  const params: Params = props.match.params;
  const participantId = params.id;

  // Current tab
  const [tab, setTab] = useState(0);

  // Sort
  const [sorting, setSorting] = useState<Sorting>({
    // Order is by appearance in table
    title: {
      direction: "asc",
    },
    ["clientGroup.name"]: {
      direction: "asc",
    },
    ["formTypeIds"]: {
      direction: "asc",
    },
    startDate: {
      direction: "asc",
    },
    submissionDate: {
      direction: "asc",
    },
    status: {
      direction: "asc",
    },
    primarySort: {
      // Not a column, table's sorted by 1 column at all times
      direction: "desc",
      type: "submissionDate",
    },
  });

  // const sortQuery = useMemo(() => `${sorting.primarySort.type},${sorting.primarySort.direction}`, [
  //   sorting.primarySort.type,
  //   sorting.primarySort.direction,
  // ]);

  const sortBy = (type, direction) => {
    setSorting({
      ...sorting,
      [type]: {
        direction,
      },
      primarySort: {
        direction,
        type,
      },
    });
  };

  // Search params query (URL)
  const [searchParamsQuery, setSearchParamsQuery] = useState({
    clientGroupIds: "",
    formIds: "",
    participantId: participantId || 0,
    statuses: "",
    ...getMinMaxSubmissionDatesFromDate(-1),
  });
  // Current user (not participant)
  const [currentUser, setCurrentUser] = useState<UsersParticipant>();
  // Current participant's submissions
  const [submissions, setSubmissions] = useState({});
  // Are aggregate stats loading
  const [statsLoading, setStatsLoading] = useState(true);
  // Are documents loading
  const [documentsLoading, setDocumentsLoading] = useState(true);

  // Primary group
  const [primaryGroup, setPrimaryGroup] = useState<Group | undefined>();

  // Form type stats
  const [formTypeStats, setFormTypeStats] = useState<FormTypeStatsType[]>();

  // Documents
  const [documents, setDocuments] = useState<DocumentSummary[]>();
  const [currentPage, setCurrentPage] = useState(0);
  const [totalElements, setTotalElements] = useState(0);
  const [size, setSize] = useState(10);
  const [totalPages, setTotalPages] = useState(0);

  // Filters
  const [group, setGroup] = useState(-1);
  const [status, setStatus] = useState(-1);
  const [formType, setFormType] = useState(-1);
  const [query, setQuery] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [dateRange, setDateRange] = useState<DateRangeValue | null>(null);
  const [timeFilterType, setTimeFilterType] = useState<TimeFilterType>(
    "ALL_TIME"
  );

  // Values for stat cards above the documents table
  const statsMap = useMemo(() => {
    const box2and3notAvailable = ![-1, "SUBMITTED"].includes(status); // If submission type is set and not "Submitted"
    return [
      {
        key: "totalDocuments",
        name: getDocumentsStatsNameIfSubmissionsFilterApplied(
          dateRange,
          timeFilterType,
          documentsTerm
        ),
      },
      {
        key: "averageTimeToComplete",
        name: "Average Time to Complete",
        notAvailable: box2and3notAvailable,
        type: "time",
        valueName: "minutes",
      },
      {
        key: "completeRateOfSubmissions",
        name: "Completion Rate of Submissions",
        notAvailable: box2and3notAvailable,
        valueName: "percent",
      },
    ];
  }, [status, dateRange, timeFilterType]);

  // On mount
  useEffect(() => {
    dispatch(getClientGroups({ size: 200, sort: "name,asc" }));
    dispatch(getAllForms());
    dispatch(getAllFormTypes());
  }, [dispatch]);
  useEffect(() => {
    // Get user
    dispatch(getUserParticipant(participantId)).then((e) => {
      // On success
      if (e?.type === "GET_USERS_PARTICIPANT_SUCCESS") {
        // If response is a string
        if (e.response && typeof e.response === "string") {
          // Have to parse the string
          const parsedResponse = JSON.parse(e.response) || "";
          // Get participantId
          const participantId = parsedResponse && parsedResponse.participantId;
          // Store user in the state
          setCurrentUser(parsedResponse);
          // Store user's primary group
          setPrimaryGroup(
            parsedResponse && (parsedResponse.primaryGroup as Group)
          );
          // Store participantId in the state
          setSearchParamsQuery({ ...searchParamsQuery, participantId });

          // Additional request to get form type stats
          dispatch(getFormTypeStats({ participantId })).then((stats) => {
            if (stats.response) {
              setFormTypeStats(stats.response);
            }
          });
          if (participantId) {
            // Additional request for participant documents
            setDocumentsLoading(true);
            dispatch(
              searchDocuments({
                page: currentPage,
                participantId,
                query: "",
                size,
                sortName: sorting.primarySort.type,
                sortOrder: sorting.primarySort.direction,
              })
            ).then((docs) => {
              if (docs.response) {
                setDocuments(docs.response.content);
                setTotalElements(docs.response.totalElements);
                setTotalPages(docs.response.totalPages);
              }
              setDocumentsLoading(false);
            });
            // Additional request for submissions
            setStatsLoading(true);
            dispatch(
              getParticipantSubmissionReports({
                ...searchParamsQuery,
                participantId,
              })
            ).then((s) => {
              setStatsLoading(false);
              // There's a response if request is successful
              if (s.response) {
                // Set current user's aggregate submission stats
                // One of the stats is missing so doing math and adding it at the end
                let completeRateOfSubmissions = 0;
                completeRateOfSubmissions =
                  (s.response.totalSubmissions /
                    (s.response.totalOutstanding +
                      s.response.totalSubmissions)) *
                    100 || 0;
                const newSubmissionStats = s.response;
                newSubmissionStats[
                  "completeRateOfSubmissions"
                ] = completeRateOfSubmissions;

                // Sum 2 attributes below, not sure why they're split on the BE
                newSubmissionStats["totalDocuments"] =
                  Number(s.response.totalSubmissions) +
                  Number(s.response.totalOutstanding);
                setSubmissions(newSubmissionStats);
              }
            });
          }
        }
      }
    });
    // Disabling this rule here, `searchParamsQuery` should not be a dependency
  }, [dispatch]);

  const requestDocuments = useCallback(
    ({ noStats, page }: Partial<{ noStats?: boolean; page?: number }>) => {
      if (currentUser && currentUser.participantId) {
        const participantId = currentUser.participantId;
        const {
          minSubmissionDate,
          maxSubmissionDate,
        } = getMinMaxSubmissionDatesFromTimeFilter(timeFilterType, dateRange);
        setDocumentsLoading(true);
        dispatch(
          searchDocuments({
            clientGroupIds: group,
            formTypeIds: formType,
            maxSubmissionDate,
            minSubmissionDate,
            page, // This should be passed from outside
            participantId,
            query: searchQuery,
            size,
            sortName: sorting.primarySort.type,
            sortOrder: sorting.primarySort.direction,
            statuses: status,
            value: 0,
          })
        ).then((docs) => {
          if (docs.response) {
            setDocuments(docs.response.content);
            setTotalElements(docs.response.totalElements);
            setTotalPages(docs.response.totalPages);
          }
          setDocumentsLoading(false);
        });
        // Additional request for submissions
        if (!noStats) {
          setStatsLoading(true);
          dispatch(
            getParticipantSubmissionReports({
              clientGroupIds: group,
              // formIds: formType,
              formTypeIds: formType,
              maxSubmissionDate,
              minSubmissionDate,
              participantId,
              query: searchQuery,
              statuses: status,
              // value: 0,
            })
          ).then((s) => {
            setStatsLoading(false);
            // There's a response if request is successful only
            if (s.response) {
              // Set current user's aggregate submission stats
              // One of the stats is missing so doing math and adding it at the end
              let completeRateOfSubmissions = 0;
              completeRateOfSubmissions =
                (s.response.totalSubmissions /
                  (s.response.totalOutstanding + s.response.totalSubmissions)) *
                  100 || 0;
              const newSubmissionStats = s.response;
              newSubmissionStats[
                "completeRateOfSubmissions"
              ] = completeRateOfSubmissions;
              // Sum 2 attributes below into a totalDocuments attribute
              newSubmissionStats["totalDocuments"] =
                Number(s.response.totalSubmissions) +
                Number(s.response.totalOutstanding);
              setSubmissions(newSubmissionStats);
            }
          });
        }
      }
    },
    [
      currentPage,
      dispatch,
      size,
      group,
      dateRange,
      timeFilterType,
      status,
      formType,
      searchQuery,
      sorting,
    ]
  );

  // On filter change, get documents/aggregate stats and reset `currentPage`
  useEffect(() => {
    requestDocuments({ page: 0 });
    setCurrentPage(0);
  }, [
    dispatch,
    size,
    group,
    dateRange,
    timeFilterType,
    status,
    formType,
    searchQuery,
  ]);

  // On non-filter change, get documents/aggregate stats but don't reset `currentPage`
  useEffect(() => {
    requestDocuments({ noStats: true, page: currentPage });
  }, [currentPage, dispatch, sorting]);

  const authorities = useMemo(() => currentUser?.authorities, [currentUser]);
  const participant = useMemo(() => currentUser?.participant, [currentUser]);
  const supervisor = useMemo(() => currentUser?.supervisor, [currentUser]);
  const clientGroups = useMemo(() => currentUser?.clientGroups, [currentUser]);

  // Sidedrawer (unused)
  // const [drawerIsOpen, setDrawerIsOpen] = useState<boolean>(false);
  // const [viewingThisDocumentId, setViewingThisDocumentId] = useState<number | null>(null);

  // Is sticky header showing
  const mainContent = document.getElementById("contentWrapper");
  const [stickyHeaderIsShowing, setStickyHeaderIsShowing] = useState(false);
  mainContent &&
    mainContent.addEventListener(
      "scroll",
      () => {
        if (mainContent.scrollTop > 200) {
          if (!stickyHeaderIsShowing) setStickyHeaderIsShowing(true);
        } else {
          if (stickyHeaderIsShowing) setStickyHeaderIsShowing(false);
        }
      },
      true
    );

  // Action menu
  // const [selectedDocumentId, setSelectedDocumentId] = useState<number | null>(null);
  const [showDocumentDrawer, setShowDocumentDrawer] = useState<
    string | boolean
  >(false);
  const [popperIsOpen, setPopperIsOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  // Delete document
  const [deleteDocumentError, setDeleteDocumentError] = useState(false);
  const [deleteDocumentIsLoading, setDeleteDocumentIsLoading] = useState(false);
  const [
    deleteDocumentConfirmationModalIsVisible,
    setDeleteDocumentConfirmationModalIsVisible,
  ] = useState(false);
  const [
    deleteDocumentOutcomeModalIsVisible,
    setDeleteDocumentOutcomeModalIsVisible,
  ] = useState(false);
  const [anchorDocument, setAnchorDocument] = useState<DocumentSummary>();

  // View
  const viewDocument = useCallback(() => {
    // In this table, we'll always view the document in sidebar first -- GK
    if (anchorDocument && anchorDocument.id) {
      setPopperIsOpen(false);
      setShowDocumentDrawer(DocumentDrawerSections.Document); // View doc
    }
  }, [anchorEl]);

  // Unmount
  useEffect(() => {
    return () => {
      mainContent &&
        mainContent.removeEventListener("scroll", () => true, true);
    };
  }, []);

  return (
    <>
      {/* Drawer has Document History, Delete Document and View Document  */}
      <DocumentDrawer
        closeDrawer={() => {
          setShowDocumentDrawer(false);
          // setSelectedDocumentId(null);
        }}
        documentId={anchorDocument && anchorDocument.id}
        show={showDocumentDrawer}
        openDeleteModal={() => {
          setDeleteDocumentConfirmationModalIsVisible(true);
          // setShowDocumentDrawer(false);
          // setSelectedDocumentId(null);
        }}
        onClose={() => {
          setShowDocumentDrawer(false);
          // setSelectedDocumentId(null);
        }}
      />
      {/* Modal for deleting a user */}
      <Modal
        border="none"
        open={deleteDocumentConfirmationModalIsVisible}
        handleClose={() => {
          setDeleteDocumentConfirmationModalIsVisible(false);
        }}
        height="auto"
        width="652px"
        padding="24px 23px"
        content={
          <DeleteDocumentConfirmation
            // user data
            created={anchorDocument && anchorDocument.dateCreated}
            group={
              anchorDocument &&
              anchorDocument.clientGroup &&
              anchorDocument.clientGroup.name
            }
            title={anchorDocument && anchorDocument.title}
            id={anchorDocument && anchorDocument.id}
            // rest
            loading={deleteDocumentIsLoading}
            onCancel={() => setDeleteDocumentConfirmationModalIsVisible(false)}
            onSubmit={() => {
              setDeleteDocumentIsLoading(true);
              dispatch(
                deleteDocument((anchorDocument && anchorDocument.id) || 0)
              ).then((e) => {
                setDeleteDocumentIsLoading(false);
                setDeleteDocumentConfirmationModalIsVisible(false);
                setDeleteDocumentOutcomeModalIsVisible(true);
                if (e.type === "DELETE_DOCUMENT_SUCCESS") {
                  setDeleteDocumentError(false);
                  setShowDocumentDrawer(false); // Close drawer if document is deleted
                } else {
                  setDeleteDocumentError(true);
                }
              });
            }}
          />
        }
      />
      {/* Modal for the outcome when deleting a document */}
      <Modal
        border="none"
        open={deleteDocumentOutcomeModalIsVisible}
        handleClose={() => {
          !deleteDocumentError && requestDocuments({ page: currentPage });
          setDeleteDocumentError(false);
          setDeleteDocumentOutcomeModalIsVisible(false);
        }}
        height="auto"
        width="652px"
        padding="24px 23px"
        content={
          <UserStyles.ModalPromptWrapper>
            <UserStyles.ModalPromptTitle>
              {deleteDocumentError
                ? "Error: Document could not be deleted"
                : "Document has been deleted"}
            </UserStyles.ModalPromptTitle>
            <UserStyles.ModalPromptSubtitle>
              {deleteDocumentError
                ? `A problem has occurred while attempting to delete the selected ${documentTermLower}. Try again later or contact your system administrator for support.`
                : `The selected ${documentTermLower} has been successfully deleted. ${documentTerm} will no longer impact relevant reporting.`}
            </UserStyles.ModalPromptSubtitle>
            <UserStyles.DisableUserButtonsWrapper>
              <SubmitButton
                onClick={() => {
                  !deleteDocumentError &&
                    requestDocuments({ page: currentPage });
                  setDeleteDocumentError(false);
                  setDeleteDocumentOutcomeModalIsVisible(false);
                }}
              >
                <span>close </span>
              </SubmitButton>
            </UserStyles.DisableUserButtonsWrapper>
            <UserStyles.ModalPromptCloseIcon
              onClick={() => {
                !deleteDocumentError && requestDocuments({ page: currentPage });
                setDeleteDocumentError(false);
                setDeleteDocumentOutcomeModalIsVisible(false);
              }}
            />
          </UserStyles.ModalPromptWrapper>
        }
      />
      <Popper
        anchorEl={anchorEl}
        onClose={() => {
          // setPopperIsOpen(false);
        }}
        open={popperIsOpen}
        placement="left"
      >
        <PopperWrapper>
          <PopperRow onClick={() => viewDocument()}>
            <Text fontSize="0.9rem">View {documentTermLower}</Text>
            <PopperChevron />
          </PopperRow>
          <PopperRow
            onClick={() => {
              setPopperIsOpen(false);
              // setSelectedDocumentId(anchorEl && anchorEl.id);
              setShowDocumentDrawer(DocumentDrawerSections.History);
            }}
          >
            <Text fontSize="0.9rem">View {documentTermLower} history</Text>
            <PopperChevron />
          </PopperRow>
          {userRoles?.some((ur) => ur.authority === "ROLE_CLIENT_ADMIN") && (
            <ReadOnlyContent>
              <PopperRow>
                <DeleteDocumentMenuItem
                  document={document}
                  closeMenu={() => setPopperIsOpen(false)}
                  openConfirmationModal={() =>
                    setDeleteDocumentConfirmationModalIsVisible(true)
                  }
                />
                <PopperChevron />
              </PopperRow>
            </ReadOnlyContent>
          )}
        </PopperWrapper>
      </Popper>
      {/* 
        @NOTE: ID IS USED FOR "SCROLL TO" / "BACK TO TOP" LOGIC
        @TODO: Update with a better solution - Trevor
      */}
      <PageContent id="mainContent">
        <div className="col-12">
          <Breadcrumbs
            paths={[
              {
                pathName: "People",
              },
              {
                pathName: "Users",
                href: "/people/users",
              },
              {
                pathName: `User Profile: ${currentUser && currentUser.name}`,
                href: `/people/users/view/${participantId}`,
              },
            ]}
          />
          {/* // Add fixed header here  */}
          {stickyHeaderIsShowing && (
            <StickyProfileHeader
              email={currentUser && currentUser.email}
              firstName={currentUser && currentUser.firstName}
              icon={
                <i
                  className="icon-icons8-pencil ml-2"
                  style={{ fontSize: "0.66rem", cursor: "pointer" }}
                  onClick={() =>
                    history.push(`/people/users/edit-user/${participantId}`)
                  }
                />
              }
              lastName={currentUser?.lastName}
              primaryGroup={primaryGroup?.name}
              supervisor={supervisor?.name}
            />
          )}
        </div>
        <S.ProfileWrapper>
          {/* Profile information  */}
          <S.ProfileInformationWrapper>
            <S.ProfileName>
              {currentUser && currentUser.name && (
                <>
                  {currentUser.name}
                  {userRoles?.some(
                    (ur) => ur.authority === "ROLE_CLIENT_ADMIN"
                  ) && (
                    <ReadOnlyContent>
                      <i
                        className="icon-icons8-pencil ml-2"
                        style={{ fontSize: "1rem", cursor: "pointer" }}
                        onClick={() =>
                          history.push(
                            `/people/users/edit-user/${participantId}`
                          )
                        }
                      />
                    </ReadOnlyContent>
                  )}
                </>
              )}
            </S.ProfileName>
            <S.ProfileContentWrapper>
              <S.ProfileContent>
                <S.ProfileContentHeader>Group</S.ProfileContentHeader>
                <S.ProfileContentValue>
                  {(participant &&
                    participant.id &&
                    listGroups(clientGroups, primaryGroup?.id)) ||
                    "-"}
                </S.ProfileContentValue>
              </S.ProfileContent>
              <S.ProfileContent>
                <S.ProfileContentHeader>
                  {supervisorTerm}
                </S.ProfileContentHeader>
                <S.ProfileContentValue>
                  {(supervisor && supervisor.name) || "-"}
                </S.ProfileContentValue>
              </S.ProfileContent>
              <S.ProfileContent>
                <S.ProfileContentHeader>Email</S.ProfileContentHeader>
                <S.ProfileContentValue active>
                  <a href={`mailto: ${currentUser && currentUser.email}`}>
                    {currentUser && currentUser.email}
                  </a>
                </S.ProfileContentValue>
              </S.ProfileContent>
            </S.ProfileContentWrapper>
          </S.ProfileInformationWrapper>

          {/* Submissions by form */}
          <SubmissionsCard
            submissions={formTypeStats}
            title={`Total Created ${documentsTerm} by Form Type`}
          />
        </S.ProfileWrapper>

        <div className="col-12 mt-5">
          <Tabs
            value={tab}
            onChange={(event, newValue) => setTab(newValue)}
            aria-label="simple tabs example"
          >
            <Tab label={documentsTerm} {...tabA11yProps(0)} />
            {/* <Tab label="User Permissions" {...tabA11yProps(1)} /> */}
          </Tabs>
          <TabPanel value={tab} index={0}>
            <div className="row">
              {/* Filters */}
              <div className="col-12 d-flex flex-row justify-content-between align-items-center mt-3">
                <div className="mr-2">
                  <FilterSelect
                    name="groups"
                    value={group}
                    label=""
                    options={generateGroupOptions(
                      (groups && groups.content) || []
                    )}
                    handleChange={(e) => setGroup(e.target.value)}
                  />
                </div>
                <div className="ml-2 mr-2">
                  <FilterSelect
                    name="formType"
                    value={formType}
                    label=""
                    options={formTypeOptions(formTypes)}
                    handleChange={(e) => setFormType(e.target.value)}
                  />
                </div>
                <div className="ml-2 mr-2">
                  <FilterSelect
                    name="status"
                    value={status}
                    label=""
                    options={statusOptions}
                    handleChange={(e) => setStatus(e.target.value)}
                  />
                </div>
                <div className="ml-2 mr-2">
                  <FilterSelect
                    name="timeFilter"
                    value={timeFilterType}
                    label=""
                    options={timeFilterOptions}
                    handleChange={(e) => setTimeFilterType(e.target.value)}
                  />
                </div>
                <UsersSearchBar
                  onChange={setQuery}
                  onSearch={setSearchQuery}
                  query={query}
                />
              </div>
              {timeFilterType === "CUSTOM_RANGE" && (
                <S.DateRangeWrapper>
                  <span className="visually-hidden" id="custom-range-label">
                    Custom Range
                  </span>
                  <DateRange
                    handleChange={setDateRange}
                    labelId="custom-range-label"
                    name="customRange"
                    onClear={() => {
                      setDateRange(null);
                      setTimeFilterType("ALL_TIME");
                    }}
                  />
                </S.DateRangeWrapper>
              )}
              {/* Stats */}
              <Loader loading={statsLoading}>
                <StatsWrapper>
                  {statsMap.map((e, i) => (
                    <StatsCard
                      header={
                        e.name === "Documents"
                          ? getDocumentsStatsNameIfSubmissionsFilterApplied(
                              dateRange,
                              timeFilterType,
                              documentsTerm
                            )
                          : e.name
                      }
                      history={undefined}
                      stretch
                      key={i}
                      linkTo={""}
                      notAvailable={e.notAvailable}
                      type={e.type}
                      value={
                        submissions[e.key] &&
                        Number(submissions[e.key].toFixed(0))
                      }
                      valueName={e.valueName}
                    />
                  ))}
                </StatsWrapper>
              </Loader>
              {/* Documents  */}
              <div className="col-12 mt-3 mb-3">
                <Loader loading={documentsLoading}>
                  <TableSummary
                    pageSize={size}
                    currentPage={currentPage}
                    totalElements={totalElements}
                    ofWhat={documentsTermLower}
                    exports={["print", "xls", "csv"]}
                    exportUrl={getExportUrl(
                      "documents",
                      query,
                      `sort=${sorting.primarySort.type},${sorting.primarySort.direction}`,
                      {
                        ownerIds: participantId!.toString(),
                      }
                    )}
                  />
                  <ViewUserTable
                    documents={documents}
                    sorting={sorting}
                    sortBy={sortBy}
                    setAnchorDocument={setAnchorDocument}
                    setShowDocumentDrawer={setShowDocumentDrawer}
                    popperIsOpen={popperIsOpen}
                    setPopperIsOpen={setPopperIsOpen}
                    setAnchorEl={setAnchorEl}
                  />
                  {/* // Pagination */}
                  {documents && (
                    <PaginationWrapper>
                      <RowsPerPage
                        pageSize={size}
                        onClick={(size) => setSize(size)}
                      />
                      <Pagination
                        currentPage={currentPage}
                        onClick={(page) => setCurrentPage(page)}
                        totalPages={totalPages}
                      />
                      <BackToTop />
                    </PaginationWrapper>
                  )}
                </Loader>
              </div>
            </div>
          </TabPanel>
          <TabPanel value={tab} index={1}>
            <div>
              <S.ProfilePermissionsCopy>
                The below permissions and primary group selection are in a
                read-only state. To edit these options, click the{" "}
                <i className="icon-icons8-pencil" /> above. If you do not see
                the pencil icon, contact your system administrator for support.
              </S.ProfilePermissionsCopy>
              <S.ProfilePermissionsHeader>
                Groups & Roles
              </S.ProfilePermissionsHeader>
              <AUS.GroupAndRoleWrapper hideBorder noPaddingTop>
                <AUS.GroupAndRoleHeader>
                  <AUS.GroupAndRoleHeaderItem margin="0 30px 0 0" width="50">
                    primary
                  </AUS.GroupAndRoleHeaderItem>
                  <AUS.GroupAndRoleHeaderItem align="flex-start" width="200">
                    group
                  </AUS.GroupAndRoleHeaderItem>
                  <AUS.GroupAndRoleHeaderItem width="150">
                    client reviewer
                  </AUS.GroupAndRoleHeaderItem>
                  <AUS.GroupAndRoleHeaderItem width="150">
                    form architect
                  </AUS.GroupAndRoleHeaderItem>
                  <AUS.GroupAndRoleHeaderItem width="150">
                    content editor
                  </AUS.GroupAndRoleHeaderItem>
                  <AUS.GroupAndRoleHeaderItem width="150">
                    group manager
                  </AUS.GroupAndRoleHeaderItem>
                  <AUS.GroupAndRoleHeaderItem width="150">
                    user
                  </AUS.GroupAndRoleHeaderItem>
                </AUS.GroupAndRoleHeader>
                {uniqueGroups(authorities).map((e, i) => {
                  return (
                    <AUS.GroupAndRoleCellWrapper border={true} key={i}>
                      {/* Primary */}
                      <AUS.GroupAndRoleCell margin="0 30px 0 0" width="50">
                        <RadioButton
                          name={e.toString()}
                          checked={primaryGroup?.id === e}
                          disabled
                        />
                      </AUS.GroupAndRoleCell>
                      {/* Group Name  */}
                      <AUS.GroupAndRoleCell width="200" align={"flex-start"}>
                        {groupNameById(e, groups)}
                      </AUS.GroupAndRoleCell>
                      {/* Roles  */}
                      {roles.map((role, i) => {
                        return (
                          <AUS.GroupAndRoleCell width="150" key={i}>
                            <S.RoleCheckbox
                              name={`${role.id}-${role.value}`}
                              checked={isGroupInThisRole(
                                authorities,
                                e,
                                role.id
                              )}
                              disabled={true}
                            />
                          </AUS.GroupAndRoleCell>
                        );
                      })}
                    </AUS.GroupAndRoleCellWrapper>
                  );
                })}
              </AUS.GroupAndRoleWrapper>
            </div>
          </TabPanel>
        </div>
      </PageContent>
    </>
  );
};

const mapStateToProps = (state: AppState) => {
  return {
    formTypes: state.forms.data.formTypes,
    groups: state.clientGroups.data.clientGroups,
    userRoles: state.user.data?.authorities,
  };
};

export default withRouter(connect(mapStateToProps)(ViewUserProfile));
