import React from "react";

import * as S from "./styles";

export interface FormHeaderProps {
  className?: string;
  position?: "fixed" | "absolute" | "sticky" | "static" | "relative";
  handleClickOnBackArrow?: () => void;
  handleOnClickMenu?: () => void;
  hideBackArrow?: boolean;
  title: string;
  menuOptions?: Array<{
    id: string;
    label: string;
    onClick: () => void;
  }>;
  formProgress?: number;
}

const FormHeader = ({
  className,
  position,
  hideBackArrow,
  title,
  handleOnClickMenu,
  menuOptions,
  handleClickOnBackArrow,
  formProgress,
}: FormHeaderProps) => {
  const [menuOpen, toggleMenuOpen] = React.useState(false);

  const MenuButtonRef = React.useRef(null);

  const handleOnClickMenuInternal = (e) => {
    if (handleOnClickMenu) {
      return handleOnClickMenu();
    }
    MenuButtonRef.current = e.target;
    toggleMenuOpen(!menuOpen);
  };

  return (
    <S.FormHeader className={className}>
      <S.Menu
        id="FormHeader-Menu"
        anchorEl={MenuButtonRef.current}
        keepMounted
        open={menuOpen}
        onClose={() => toggleMenuOpen(!menuOpen)}
      >
        {menuOptions &&
          menuOptions.map((option) => (
            <S.MenuItem key={option.id} onClick={option.onClick}>
              {option.label}
            </S.MenuItem>
          ))}
      </S.Menu>

      <S.AppBar position={position}>
        <S.Toolbar>
          {!hideBackArrow && (
            <S.IconButton onClick={handleClickOnBackArrow}>
              <S.Icon className="icon-icons8-chevron-left" />
            </S.IconButton>
          )}
          <S.Title variant="title">{title}</S.Title>

          <S.IconButton onClick={handleOnClickMenuInternal}>
            <S.MoreVirt />
          </S.IconButton>
        </S.Toolbar>
        <S.LinearProgress value={formProgress} variant="determinate" />
      </S.AppBar>
      <div style={{ height: "80px" }} />
    </S.FormHeader>
  );
};

FormHeader.defaultProps = {
  position: "relative",
  title: "...",
  handleClickOnBackArrow: () => {},
};

export default FormHeader;
