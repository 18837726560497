import { GET_USER, UserState, UserActionTypes, USER_FORM_STATS, UPDATE_USER_INFO } from "./types";

const initialState: UserState = {
  loading: {
    getUser: false,
    getUserFormStats: false,
    updateUserInfo: false,
  },
  error: {
    getUser: false,
    getUserFormStats: false,
    updateUserInfo: false,
  },
  success: {
    getUser: false,
    getUserFormStats: false,
    updateUserInfo: false,
  },
  data: null,
  userFormStats: undefined,
};

export function userReducer(state = initialState, action: UserActionTypes): UserState {
  const { response, type } = action;

  switch (type) {
    case GET_USER.REQUEST:
      return {
        ...state,
        loading: {
          ...state.loading,
          getUser: true,
        },
        error: {
          ...state.error,
          getUser: false,
        },
      };

    case GET_USER.SUCCESS:
      return {
        ...state,
        loading: {
          ...state.loading,

          getUser: false,
        },
        data: response,
      };

    case GET_USER.FAILURE:
      return {
        ...state,
        loading: {
          ...state.loading,

          getUser: false,
        },
        error: {
          ...state.error,
          getUser: true,
        },
      };

    case USER_FORM_STATS.SUCCESS:
      return {
        ...state,
        userFormStats: response,
      };

    case USER_FORM_STATS.FAILURE:
      return {
        ...state,
        error: {
          ...state.error,
          getUserFormStats: true,
        },
      };

    case UPDATE_USER_INFO.REQUEST:
      return {
        ...state,
        loading: {
          ...state.loading,
          updateUserInfo: true,
        },
        error: {
          ...state.error,
          updateUserInfo: false,
        },
        success: {
          ...state.success,
          updateUserInfo: false,
        },
      };
    case UPDATE_USER_INFO.SUCCESS:
      return {
        ...state,
        loading: {
          ...state.loading,
          updateUserInfo: false,
        },
        success: {
          ...state.success,
          updateUserInfo: true,
        },
      };

    case UPDATE_USER_INFO.FAILURE:
      return {
        ...state,
        loading: {
          ...state.loading,
          updateUserInfo: false,
        },
        error: {
          ...state.error,
          updateUserInfo: true,
        },
      };

    default:
      return state;
  }
}
