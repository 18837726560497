import React, { ChangeEventHandler } from "react";
import { css, StyleDeclarationValue } from "aphrodite";

import { useThemedStyleSheet } from "util/hooks/useThemedStyleSheet";

import { createStyles } from "./styles";

interface Props {
  checked?: boolean;
  disabled?: boolean;
  formDisabled?: boolean;
  error?: string;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  label?: string;
  name?: string | undefined;
  value?: string | number;
  variant?: "standard" | "pills";
}

function RadioButton({
  checked,
  disabled,
  formDisabled,
  error,
  onChange,
  label,
  name,
  value,
  variant,
}: Props) {
  const styles = useThemedStyleSheet(createStyles);

  const labelStyles: StyleDeclarationValue[] = [styles.label];

  if (variant === "pills") {
    labelStyles.push(styles.labelPill);
    if (checked) {
      labelStyles.push(styles.labelCheckedPill);
      if (error) {
        labelStyles.push(styles.labelCheckedErrorPill);
      }
      if (disabled) {
        labelStyles.push(styles.labelCheckedDisabledPill);
      }
    }
  }

  return (
    <label className={css(labelStyles)}>
      <input
        checked={checked}
        className={css(
          styles.input,
          variant === "standard" && styles.inputStandard,
          variant === "pills" && styles.inputPill,
          checked && styles.inputChecked,
          !!error && styles.inputError,
          (disabled || formDisabled) && styles.inputDisabled,
          !disabled && !error && styles.inputEnabled
        )}
        disabled={disabled || formDisabled}
        name={name}
        onChange={onChange}
        type="radio"
        value={value}
      />
      <span
        className={css(
          styles.labelText,
          !!error && styles.labelTextError,
          disabled && styles.labelTextDisabled
        )}
      >
        {label}
      </span>
    </label>
  );
}

export default RadioButton;
