import React, { useCallback, useMemo } from "react";

import { WorkOrderType } from "store/workOrders/types";
import { dateToString } from "components/helpers";

import * as S from "./styles";

export interface Props extends Partial<WorkOrderType> {
  iconUrl?: string;
  iconColor?: string;
  isActive?: boolean;
  handleSelectWorkOrder?: (value: number) => void;
}

/**
 * Renders a list item for the given WorkOrder.
 * Conditionally renders elements and if @id is missing
 * then we'll render in a disabled state
 */
const WorkOrderListItem = ({
  description,
  workOrderId,
  location,
  dateOfParticipantCreation,
  isActive,
  handleSelectWorkOrder,
  id
}: Props) => {
  const onClick = useCallback(() => {
    if (!id || !handleSelectWorkOrder) return null;
    handleSelectWorkOrder(id);
  }, [handleSelectWorkOrder, id]);

  const date = useMemo(() => dateOfParticipantCreation && dateToString(dateOfParticipantCreation), [
    dateOfParticipantCreation
  ]);

  return (
    <S.WorkOrderListItem disabled={!id}>
      <S.Clickable isActive={isActive} onClick={onClick}>
        <S.Left>
          <S.Description as="p">{description}</S.Description>
          {workOrderId &&<S.Id as="p">ID: {workOrderId}</S.Id>}
          {location && <S.Location as="p">{location}</S.Location>}
        </S.Left>
        {date && <S.Right>
          <S.Date>{date}</S.Date>
        </S.Right>}
      </S.Clickable>
    </S.WorkOrderListItem>
  );
};

export default WorkOrderListItem;
