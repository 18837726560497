import { StyleSheet, StyleDeclaration } from "aphrodite";

import { remCalc } from "themes/helpers";
import { CreateStyleSheet } from "util/hooks/useThemedStyleSheet";

export interface RadioGroupStyle {
  column?: StyleDeclaration;
  columnItem?: StyleDeclaration;
  radioGroup?: StyleDeclaration;
  row?: StyleDeclaration;
  rowItem?: StyleDeclaration;
}

export const defaultStyle: CreateStyleSheet<RadioGroupStyle> = () => {
  return StyleSheet.create({
    column: {
      flexDirection: "column",
    },
    columnItem: {
      marginBottom: remCalc(4),
      ":last-child": {
        marginBottom: 0,
      },
    },
    radioGroup: {
      display: "flex",
      flexWrap: "wrap",
    },
    row: {
      flexDirection: "row",
    },
    rowItem: {
      marginRight: remCalc(16),
      ":last-child": {
        marginRight: 0,
      },
    },
  });
};

export const ratingGroupStyle: CreateStyleSheet<RadioGroupStyle> = () => {
  return StyleSheet.create({
    column: {
      flexDirection: "column",
    },
    radioGroup: {
      display: "flex",
      flexWrap: "wrap",
    },
    row: {
      flexDirection: "row",
    },
  });
};
