import moment from "moment";

import { FormSubmissionConstraintDTO } from "store/forms/types";

/**
 * Return the form's time of day submission constraint if it has one
 * @param formSubmissionConstraint form.formSubmissionConstraint object
 */
export function getFormTimeLimit(
  formSubmissionConstraint?: FormSubmissionConstraintDTO | null
): string | undefined {
  if (
    formSubmissionConstraint &&
    formSubmissionConstraint.type === "TIME" &&
    formSubmissionConstraint.timeLimit &&
    formSubmissionConstraint.noSubmissionsUntilNextDay
  ) {
    const time = moment()
      .startOf("day")
      .seconds(formSubmissionConstraint.timeLimit);
    return moment(time).toLocaleString();
  }
}

/**
 * Return an error string if the current time is past the form submission
 * time constraint
 *
 * @param formSubmissionConstraint
 */
export function getSubmissionTimeError(
  formSubmissionConstraint?: FormSubmissionConstraintDTO
): string | undefined {
  const formTimeLimit = getFormTimeLimit(formSubmissionConstraint);
  const isPastSubmissionTime = moment().isAfter(moment(formTimeLimit));

  if (isPastSubmissionTime) {
    return `Selected form type cannot be started 
              ${
                formTimeLimit
                  ? `after ${moment(formTimeLimit).format("HH:mm")}`
                  : "until tomorrow"
              }`;
  }
}
