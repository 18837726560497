import styled from "styled-components";

import { remCalc, toRgba } from "themes/helpers";

type ValueProps = {
  clickable?: boolean;
};

type WrapperProps = {
  height?: string;
  stretch?: boolean;
} & ValueProps;

export const Wrapper = styled.div<WrapperProps>`
  border-radius: 4px;
  border: 1px solid ${({ theme }) => toRgba(theme.colors.black, 0.12)};
  ${({ stretch }) => stretch ? "flex-grow: 1;" : ""}
  padding: 16px 16px;
  position: relative;
  width: 288px;
  margin-left: 12.5px;
  margin-right: 12.5px;
  :first-of-type {
    margin-left: 0;
  }
  :last-of-type {
    margin-right: 0;
  }
  height: ${({ height }: WrapperProps) => height || "auto"};
  ${({ clickable }: WrapperProps) => clickable && "cursor: pointer;"}
`;

export const ContentWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

export const Header = styled.div`
  color: ${({ theme }) => toRgba(theme.colors.black, 0.87)};
  font-size: ${remCalc(14)};
  letter-spacing: 0.24px;
`;

export const Value = styled.div<ValueProps>`
  color: ${({ clickable, theme }) => clickable ? theme.colors.primary : toRgba(theme.colors.black, 0.87)};
  font-size: ${remCalc(48)};
  letter-spacing: 0;
`;

export const ValueName = styled.div`
  color: ${({ theme }) => theme.masterColors.darkGrey};
  font-size: ${remCalc(16)};
  letter-spacing: 0.49px;
  margin-left: 8px;
`;

export const ValueWrapper = styled.div`
  align-items: baseline;
  display: flex;
  flex-direction: row;
  justify-content: left;
  line-height: 1.2;
  margin-right: 10px;
`;

export const IconWrapper = styled.div`
  span {
    display: block;
  }
`;
