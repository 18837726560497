import styled from "styled-components";

const StyledLinkCaretRight = styled.i.attrs({
  className: "icon icon-icons8-expand_arrow",
})`
  color: ${(props) => props.theme.masterColors.darkGrey};
  cursor: pointer;
  display: inline-block;
  font-size: 1.5rem;
  transform: rotate(-90deg);
  margin-right: 10px;
  margin-left: 10px;
`;

export { StyledLinkCaretRight };
