import React from "react";
import styled from "styled-components";
import TextFieldBase, {
  OutlinedTextFieldProps,
} from "@material-ui/core/TextField";

import LabelBase from "components/forms/Label";
import TextInputBase from "components/forms/TextInput";
import { remCalc } from "themes/helpers";
import { ThemeTypes } from "themes/colors";
import mediaQueries from "themes/mediaQueries";

import { CommentVariant } from "./Comment";
import Text, { TextProps } from "components/common/Text";

export const Comment = styled.div<{ commentVariant: CommentVariant }>`
  padding-left: ${remCalc(40)};
  ${({ commentVariant }) => {
    if (commentVariant === "RATING") {
      return `
        width: 100%;
        padding-left: 0;
        @media (${mediaQueries.maxTablet}) {
          max-width: ${remCalc(470)};
          align-self: flex-end;
        }
        `;
    }
    if (commentVariant === "DEFENSE") {
      return `
        width: 100%;
        padding-left: 0;
        `;
    }
  }}
`;

export const Label = styled(LabelBase)``;

export const TextInput = styled(TextInputBase)`
  margin-left: 30px;
  padding: 5px;
`;

export const ClearCommentButton = styled.button`
  border: none;
  background: none;
  color: ${({ theme }) => theme.masterColors.error};

  position: absolute;
  top: 10px;
  right: 10px;
`;

export const ErrorText = styled(Text)`
  margin-top: .375rem;
  display: block;
`;

export const Required = styled<React.FC<Omit<TextProps, "variant">>>(
  (props) => <ErrorText {...props} variant="error" />
)`
  align-self: flex-start;
`;

interface CommentTextFieldProps extends OutlinedTextFieldProps {
  commentVariant: CommentVariant;
  isMouseFocused: boolean;
}

export const CommentTextField = styled<React.FC<CommentTextFieldProps>>(
  ({ commentVariant, isMouseFocused, ...otherProps }) => (
    <TextFieldBase
      {...otherProps}
      classes={{
        root: "root",
      }}
      InputProps={{
        ...otherProps.InputProps,
        classes: {
          root: "input-root",
          input: "input",
          notchedOutline: "notchedOutline",
          adornedStart: "adornedStart",
          adornedEnd: "adornedEnd",
          focused: "focused",
          error: "error",
          disabled: "disabled",
          multiline: "multiline",
          inputAdornedEnd: "inputAdornedEnd",
        },
      }}
    />
  )
)`

  && .input {
    width: auto;
    flex-grow: 1;
  }

  && .multiline {
    min-height: ${remCalc(42)};
    background: ${({ theme }) => theme.colors.white};
    border-radius: ${remCalc(42)};
    padding: ${remCalc(6)} ${remCalc(16)};
    display: border-box;

    ${({
      commentVariant,
      theme,
    }: {
      commentVariant: CommentVariant;
      theme: ThemeTypes;
    }) => {
      if (commentVariant === "RATING") {
        return `
          border: ${remCalc(1)} solid ${theme.masterColors.mediumGrey}; 
          border-radius: ${remCalc(4)};
          width: 100%;
        `;
      }
      if (commentVariant === "DEFENSE") {
        return `
          border: ${remCalc(1)} solid ${theme.masterColors.mediumGrey}; 
          width: 100%;
        `;
      }
    }}

    &.focused {
      ${({ isMouseFocused, theme }) => {
        if (isMouseFocused) {
          return `box-shadow: 0 0 0 ${remCalc(2)} ${theme.colors.primary};`;
        } else {
          return `box-shadow: 0 0 0 ${remCalc(4)} ${
            theme.colors.focusIndicatorOutline
          };`;
        }
      }}
    }
  }
  & .input::-webkit-scrollbar {
    width: 0 !important;
  }

  & .notchedOutline {
    border: none;
  }
`;
