import moment from "moment";

export const TIME_FORMAT = "HH:mmZ";

export const parseTime = (answer: string) => {
  const momentWithZone = moment.parseZone(
    answer,
    [moment.ISO_8601, TIME_FORMAT],
    true
  );

  if (momentWithZone.isValid()) {
    return momentWithZone.utc();
  }

  // Legacy times without a time zone offset are assumed to be in EDT time zone.
  return moment
    .parseZone(`${answer}-04:00`, [moment.ISO_8601, TIME_FORMAT], true)
    .utc();
};
