import { StyleSheet } from "aphrodite";
import { remCalc } from "themes/helpers";

export const styles = StyleSheet.create({
  column: {
    flexDirection: "column",
  },
  columnItem: {
    paddingBottom: remCalc(4),
    ":last-child": {
      paddingBottom: 0,
    },
  },
  radioGroup: {
    display: "flex",
    flexWrap: "wrap",
  },
  row: {
    flexDirection: "row",
  },
  rowItem: {
    paddingRight: remCalc(16),
    ":last-child": {
      paddingRight: 0,
    },
  },
});
