import styled from "styled-components";

const WidgetHeader = styled.span`
  color: ${(props) => props.theme.masterColors.darkGrey};
  font-size: 0.75em;
  font-weight: 700;
  letter-spacing: 0.1em;
  text-align: left;
  text-transform: uppercase;
`;

export { WidgetHeader };
