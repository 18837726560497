import React, { PropsWithChildren, useRef, useState, PointerEventHandler } from "react";

import * as S from "./styles";

export const Carousel = ({ children }: PropsWithChildren<{}>) => {
  const carousel = useRef<HTMLDivElement | null>(null);
  const [startMouseX, setStartMouseX] = useState(0);
  const [startScrollLeft, setStartScrollLeft] = useState(0);
  const [isPointerDown, setIsPointerDown] = useState(false);

  const handlePointerDown: PointerEventHandler<HTMLDivElement> = (event) => {
    const target = carousel.current;
    if (event.pointerType !== "mouse" || !target) {
      return;
    }
    setIsPointerDown(true);
    setStartMouseX(event.clientX);
    setStartScrollLeft(target.scrollLeft);
  };

  const handlePointerMove: PointerEventHandler<HTMLDivElement> = (event) => {
    const target = carousel.current;
    if (event.pointerType !== "mouse" || !target || !isPointerDown) {
      return;
    }
    target.setPointerCapture(event.pointerId);
    const deltaX = event.clientX - startMouseX;
    target.scrollLeft = startScrollLeft - deltaX;
  };

  const handlePointerUp: PointerEventHandler<HTMLDivElement> = (event) => {
    const target = carousel.current;
    if (event.pointerType !== "mouse" || !target) {
      return;
    }
    setIsPointerDown(false);
    target.releasePointerCapture(event.pointerId);
  };

  return (
    <S.Cards
      onPointerDown={handlePointerDown}
      onPointerMove={handlePointerMove}
      onPointerUp={handlePointerUp}
      ref={carousel}
    >
      {children}
    </S.Cards>
  );
};
